import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  optimizedAttachmentsActions,
  uploadAttachment,
  deleteAttachment,
} from 'store/actions/attachmentsV2';

import {
  updateFile,
} from 'store/actions/attachments';

import { OBJECT_TYPES } from 'constants';

import Attachments from './Attachments'; // eslint-disable-line import/no-cycle

const mapStateToProps = (state, ownProps) => {
  const {
    attachments: {
      preloader,
      fileCompressionProgress,
    },
  } = state;

  return {
    preloader,
    fileCompressionProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteFile: (objectType, objectId, attachmentId) => dispatch(deleteAttachment(objectType, objectId, attachmentId)),
  uploadFile: (data) => dispatch(uploadAttachment(data)),
  // getFileList: (objectType, objectId) => optimizedAttachmentsActions.getAttachmentsList(objectType, objectId),
  updateAttachmentTitle: debounce(async ({ objectType, objectId, attachmentId }, title) => {
    dispatch(updateFile({
      appModule: OBJECT_TYPES[objectType],
      id: objectId,
      Uuid: attachmentId,
      updateObject: { Title: title },
    }));

    optimizedAttachmentsActions.getAttachmentsList(objectType, objectId);
  }, 1000),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Attachments);
