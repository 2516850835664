import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import { callAlert } from 'ui/Alert';

export const TODOLIST_DRAWER_SHOW = 'TODOLIST_DRAWER_SHOW';
export const TODOLIST_DRAWER_HIDE = 'TODOLIST_DRAWER_HIDE';
export const TODOLIST_SHOW_ITEM = 'TODOLIST_SHOW_ITEM';
export const TODOLIST_SETTINGS_SHOW = 'TODOLIST_SETTINGS_SHOW';
export const TODOLIST_SETTINGS_HIDE = 'TODOLIST_SETTINGS_HIDE';
export const TODOLIST_SAVE_SETTINGS = 'TODOLIST_SAVE_SETTINGS';
export const NEW_REMINDER = 'NEW_REMINDER';
export const REMINDER_DELETING = 'REMINDER_DELETING';
export const CHANGE_IMPORTANCE = 'CHANGE_IMPORTANCE';
export const CHANGE_FULFILLMENT = 'CHANGE_FULFILLMENT';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const SET_INVITE_EMPLOYEE = 'SET_INVITE_EMPLOYEE';
export const SET_INVITE_EMPLOYEES_SUCCESS = 'SET_INVITE_EMPLOYEES_SUCCESS';
export const SET_INVITE_EMPLOYEES_ERROR = 'SET_INVITE_EMPLOYEES_ERROR';
export const SET_INVITERS_GROUP = 'SET_INVITER_GROUP';
export const SET_INVITERS_GROUP_SUCCESS = 'SET_INVITERS_GROUP_SUCCESS';
export const SET_INVITERS_GROUP_ERROR = 'SET_INVITERS_GROUP_ERROR';
export const CHANGE_NUMBER_IN_LIST = 'CHANGE_NUMBER_IN_LIST';

export const changeNumberInListAction = (
  prevNumberInList,
  newNumberInList,
  targetReminderID,
) => async (dispatch, getState) => {
  const {
    EmployeeID,
  } = getState().auth.loggedUser;

  const method = 'POST';
  const url = '/api/todolist/changeNumberInList';

  const result = await apiConnector(url, method, {
    prevNumberInList, newNumberInList, EmployeeID, targetReminderID,
  });

  await dispatch({
    type: CHANGE_NUMBER_IN_LIST,
    result,
  });
};

export const setInvitersGroup = () => ({ type: SET_INVITERS_GROUP });

export const setInvitersGroupSuccess = (config, data) => async (dispatch) => {
  try {
    await dispatch({
      type: SET_INVITERS_GROUP_SUCCESS,
      response: { config, data },
    });
    callAlert.success('The group of people was changed');
  } catch {
    callAlert.error('Something happened when the settings were being set!');
  }
};

export const setInvitersGroupError = () => ({ type: SET_INVITERS_GROUP_ERROR });

export const todolistSaveSettingsAction = (
  checkboxValue,
  radioInputValue,
) => async (dispatch, getState) => {
  try {
    const {
      EmployeeID,
    } = getState().auth.loggedUser;
    const method = 'POST';
    const url = '/api/todolist/saveNewSettings';
    const result = await apiConnector(url, method, { checkboxValue, radioInputValue, EmployeeID });
    dispatch({
      type: TODOLIST_SAVE_SETTINGS,
      result,
    });
    callAlert.success('Settings are saved');
  } catch {
    callAlert.error('Something happened when the settings were being set!');
  }
};

export const setInviteEmployees = () => ({ type: SET_INVITE_EMPLOYEE });

export const setInviteEmployeesSuccess = (config, data) => async (dispatch) => {
  await dispatch({
    type: SET_INVITE_EMPLOYEES_SUCCESS,
    response: { config, data },
  });
};

export const setInviteEmployeesError = () => ({ type: SET_INVITE_EMPLOYEES_ERROR });

export const toDoListDrawerShowAction = () => ({
  type: TODOLIST_DRAWER_SHOW,
});

export const toDoListDrawerHideAction = () => ({
  type: TODOLIST_DRAWER_HIDE,
});

export const toDoListSettingsShowAction = () => ({
  type: TODOLIST_SETTINGS_SHOW,
});

export const toDoListSettingsHideAction = () => ({
  type: TODOLIST_SETTINGS_HIDE,
});

export const postNewReminder = (reminderTitle, numberInList) => async (dispatch, getState) => {
  try {
    const {
      EmployeeID,
    } = getState().auth.loggedUser;
    const creatorID = EmployeeID;
    const method = 'POST';
    const url = '/api/todolist/createReminder';
    const result = await apiConnector(url, method, { reminderTitle, creatorID, numberInList });
    dispatch({
      type: NEW_REMINDER,
      result,
    });
  } catch {
    callAlert.error('Something went wrong when creating a reminder!');
  }
};

export const toDoListSetViewedAction = () => async (dispatch, getState) => {
  try {
    const {
      EmployeeID,
    } = getState().auth.loggedUser;

    const method = 'POST';
    const checkIsCompleteDownOnUrl = '/api/todolist/getEmployeeSettings';
    const isCompletedDownOn = await apiConnector(checkIsCompleteDownOnUrl, method, { EmployeeID });
    const url = '/api/todolist/getReminders';
    const result = await apiConnector(url, method, { EmployeeID, isCompletedDownOn });

    dispatch({
      type: TODOLIST_SHOW_ITEM,
      remindersInf: result.employeeReminderInf,
      assigneeBy: result.assigneeByReminders,
      employeeSettingsInf: result.employeeSettingsInf,
      employeeInvitersList: result.employeeInvitersList,
      toDoListEmployeesSettings: result.toDoListEmployeesSettings,
    });
  } catch {
    callAlert.error('Something went wrong when changing the reminder!');
  }
};

export const reminderDeletingAction = (reminderID) => async (dispatch, getState) => {
  try {
    const {
      EmployeeID,
    } = getState().auth.loggedUser;
    const method = 'POST';
    const url = '/api/todolist/reminderDeletion';
    const result = await apiConnector(url, method, { reminderID, EmployeeID });
    dispatch({
      type: REMINDER_DELETING,
      result,
    });
  } catch {
    callAlert.error('Something went wrong when changing the reminder!');
  }
};

export const reminderChangeImportanceAction = (reminderID, isImportant) => async (dispatch, getState) => { // eslint-disable-line max-len
  try {
    const {
      EmployeeID,
    } = getState().auth.loggedUser;
    const method = 'POST';
    const url = '/api/todolist/specifyImportance';
    const result = await apiConnector(url, method, { reminderID, isImportant, EmployeeID });
    dispatch({
      type: CHANGE_IMPORTANCE,
      result,
    });
  } catch {
    callAlert.error('Something went wrong when changing the reminder!');
  }
};

export const reminderChangeFulfillmentAction = (reminderID, isCompleted) => async (dispatch, getState) => { // eslint-disable-line max-len
  try {
    const {
      EmployeeID,
    } = getState().auth.loggedUser;
    const method = 'POST';
    const url = '/api/todolist/changeFulfillment';
    const result = await apiConnector(url, method, { reminderID, isCompleted, EmployeeID });
    dispatch({
      type: CHANGE_FULFILLMENT,
      result,
    });
  } catch {
    callAlert.error('Something went wrong when changing the reminder!');
  }
};

export const reminderChangeTitleAction = (reminderID, newReminderTitle) => async (dispatch) => {
  try {
    const method = 'POST';
    const url = '/api/todolist/changeReminderTitle';
    const result = await apiConnector(url, method, { reminderID, newReminderTitle });
    dispatch({
      type: CHANGE_TITLE,
      result,
    });
  } catch {
    callAlert.error('Something went wrong when changing the reminder!');
  }
};
