import { connect } from 'react-redux';

import {
  openModal,
} from 'store/actions/modals';
import FileItem from './FileItem';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  openImportModal: () => dispatch(openModal('importWizard')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileItem);
