import validate from 'shared/utils/validators';
import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const create = (email) => async (dispatch) => {
  if (typeof email !== 'string' || !validate.email(email)) {
    return undefined;
  }

  try {
    const response = await requests.emails.create({ email });

    return response.data.emailId;
  } catch (error) {
    console.error(error);
  }
};

export default create;
