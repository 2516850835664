import { createSelector } from 'reselect';

import { optimizedFollowUpRemindersActions } from 'store/actions/followUpReminders';

export default createSelector(
  (state) => state.followUpReminders.reminders,
  (state) => state.followUpReminders.remindersByFilter,
  (_, filterId) => filterId,
  (_, filterId, status) => status,
  (reminders, remindersById, objectFilterId, status) => {
    const filteredIds = remindersById[objectFilterId];

    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((accum, id) => {
      const followUpReminder = reminders[id];

      if (!followUpReminder && id) {
        optimizedFollowUpRemindersActions.getFollowUpReminderById(id);
        return accum;
      }

      if (followUpReminder.valid_to || (status && followUpReminder.status !== status)) {
        return accum;
      }

      accum.push(followUpReminder);
      return accum;
    }, []);

    return result;
  },
);
