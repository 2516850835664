import {
  SET_TASK_LAST_UPDATE_INFO,
} from 'store/actionTypes/tasks';

const setTaskLastUpdateInfo = (data) => ({
  type: SET_TASK_LAST_UPDATE_INFO,
  payload: data,
});

export default setTaskLastUpdateInfo;
