import styled from 'styled-components';

import Tag from 'ui/Tag';

export const StyledTag = styled(Tag)`
  .contact-type-tag-button {
    display: none;
  }

  &&:hover {
    .contact-type-tag-button {
      display: flex;
    }
  }
`;

export default {
  StyledTag,
};
