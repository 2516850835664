import moment from 'moment';

import getContactChangelog from './getContactChangelog';

const getContactChangelogAllTime = (contactId) => (dispatch) => {
  try {
    dispatch(getContactChangelog(contactId)('2020-12-01', moment().add(1, 'd').format('YYYY-MM-DD')));
  } catch (error) {
    console.error(error);
  }
};

export default getContactChangelogAllTime;
