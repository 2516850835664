import { connect } from 'react-redux';

import selectors from 'store/selectors';

import stableStringify from 'json-stable-stringify';
import moment from 'moment';

import { getMonthData, getFilter } from 'sections/Calendar/utils/getMonthData';

import GeneralCalendar from './GeneralCalendar';

// const momentFormat = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

// const startOfMonth = (date) => moment(date).startOf('month').utc();
// const endOfMonth = (date) => moment(date).endOf('month').utc();

// const filters = {
//   showingsFilter: {
//     contact_type: 'not prospects',
//   },
//   pitchesFilter: {
//     contact_type: 'prospects',
//   },
//   closingEscrowsFilter: {
//     no_offset: true,
//     date_fields: [
//       'close_escrow_expires_at',
//     ],
//   },
//   contingenciesDueFilter: {
//     no_offset: true,
//     date_fields: [
//       'initial_documents_expires_at',
//       'seller_disclosure_expires_at',
//       'inspections_expires_at',
//       'appraisal_expires_at',
//       'loan_expires_at',
//       'final_walkthrough_expires_at',
//       'move_out_date_expires_at',
//     ],
//   },
//   followUpRemindersFilter: {
//   },
// };

// const getFilter = (filterName, date) => {
//   const dateFrom = momentFormat(startOfMonth(date));
//   const dateTo = momentFormat(endOfMonth(date));

//   return {
//     ...filters[filterName],
//     date_from: dateFrom,
//     date_to: dateTo,
//     scheduledDate: [dateFrom, dateTo],
//   };
// };

const mapStateToProps = (state, ownProps) => {
  const {
    selectedDate,
  } = ownProps;

  //ToDo: We need to change the way we get differents types of events "REFACTOR"
  // I'm going to take all events from synced Event and categorize the differents event by one column of the entity
  // FollowUpReminders will bw synced events, change all references of this name to synced event
  const showingsFilter = getFilter('showingsFilter', selectedDate);
  const pitchesFilter = getFilter('pitchesFilter', selectedDate);
  const closingEscrowsFilter = getFilter('closingEscrowsFilter', selectedDate);
  const contingenciesDueFilter = getFilter('contingenciesDueFilter', selectedDate);
  const syncedFilter = getFilter('followUpRemindersFilter', selectedDate);

  const closingEscrows = selectors.realtorTransactions.todayContingenciesTransactionsList(
    state,
    stableStringify(closingEscrowsFilter),
    closingEscrowsFilter.date_fields,
    selectedDate,
    'month',
  );
  const showings = selectors.propertyViewings.todayViewings(state, stableStringify(showingsFilter), 'Showing');
  const listingPitches = selectors.propertyViewings.todayViewings(state, stableStringify(pitchesFilter), 'Pitches');
  const contingenciesDue = selectors.realtorTransactions.todayContingenciesTransactionsList(
    state,
    stableStringify(contingenciesDueFilter),
    contingenciesDueFilter.date_fields,
    selectedDate,
    'month',
  );
  //change to synced event
  const synced = selectors.followUpReminders.todayReminders(state, stableStringify(syncedFilter));

  const monthData = {};

  showings.forEach((item) => {
    const eventDate = item.date;
    const eventDateKey = moment(eventDate).format('MM-DD-YYYY');

    if (!monthData[eventDateKey]) {
      monthData[eventDateKey] = {};
    }
    if (!monthData[eventDateKey].showings) {
      monthData[eventDateKey].showings = [];
    }

    monthData[eventDateKey].showings.push(item);
  });

  listingPitches.forEach((item) => {
    const eventDate = item.date;
    const eventDateKey = moment(eventDate).format('MM-DD-YYYY');

    if (!monthData[eventDateKey]) {
      monthData[eventDateKey] = {};
    }
    if (!monthData[eventDateKey].listingPitches) {
      monthData[eventDateKey].listingPitches = [];
    }

    monthData[eventDateKey].listingPitches.push(item);
  });

  synced.forEach((item) => {
    const eventDate = item.date;
    const eventDateKey = moment(eventDate).format('MM-DD-YYYY');

    if (!monthData[eventDateKey]) {
      monthData[eventDateKey] = {};
    }
    if (!monthData[eventDateKey].synced) {
      monthData[eventDateKey].synced = [];
    }

    monthData[eventDateKey].synced.push(item);
  });

  closingEscrows.forEach((item) => {
    const eventDate = item.date;
    const eventDateKey = moment(eventDate).format('MM-DD-YYYY');

    if (!monthData[eventDateKey]) {
      monthData[eventDateKey] = {};
    }
    if (!monthData[eventDateKey].closingEscrows) {
      monthData[eventDateKey].closingEscrows = [];
    }

    monthData[eventDateKey].closingEscrows.push(item);
  });

  contingenciesDue.forEach((item) => {
    const eventDate = item.date;
    const eventDateKey = moment(eventDate).format('MM-DD-YYYY');

    if (!monthData[eventDateKey]) {
      monthData[eventDateKey] = {};
    }
    if (!monthData[eventDateKey].contingenciesDue) {
      monthData[eventDateKey].contingenciesDue = [];
    }

    monthData[eventDateKey].contingenciesDue.push(item);
  });

  return {
    monthData,
  };
};

// const getShowings = (date) => {
//   const showingsFilter = getFilter('showingsFilter', date);
//   optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(showingsFilter), showingsFilter);
// }

// const getPitches = (date) => {
//   const pitchesFilter = getFilter('pitchesFilter', date);
//   optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(pitchesFilter), pitchesFilter);
// }

// const getClosingEscrow = (date) => {
//   const closingEscrowsFilter = getFilter('closingEscrowsFilter', date);

//   optimizedRealtorTransactionsActions.getTransactionsForFilter(
//     stableStringify(closingEscrowsFilter),
//     closingEscrowsFilter,
//   );
// }

// const getContingenciesDue = (date) => {
//   const contingenciesDueFilter = getFilter('contingenciesDueFilter', date);

//   optimizedRealtorTransactionsActions.getTransactionsForFilter(
//     stableStringify(contingenciesDueFilter),
//     contingenciesDueFilter,
//   );
// }

// const getFollowUpReminders = (date) => {
//   const remindersFilter = getFilter('followUpReminders', date);

//   optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(
//     stableStringify(remindersFilter),
//     remindersFilter,
//   );
// }

// const getMonthData = (date) => {
//   getShowings(date);
//   getPitches(date);
//   getClosingEscrow(date);
//   getContingenciesDue(date);
//   getFollowUpReminders(date);
// }

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  getMonthData,
});

export default connect(mapStateToProps, null, mergeProps)(GeneralCalendar);
