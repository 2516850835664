/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Controller,
  useForm,
} from 'react-hook-form';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import FormTextfield from 'shared/inputs/FormTextfield';
import SalePriceInput from 'shared/inputs/SalePriceInput';

import InspectionsSelect from './InspectionsSelect';
import AttachmentsUpload from './AttachmentsUpload';

const NewRepairRequestForm = (props) => {
  const {
    formId,
    onSubmit: propsOnSubmit,

    defaultRequestType,
    defaultDescription,
    defaultEstimatedValue,

    relatedInspectionId,
    inspectionCount,
  } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback((data) => {
    propsOnSubmit({
      ...data,
    });
  }, [
    propsOnSubmit,
  ]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {
              inspectionCount ? (
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="main-text"
                  >
                    Related inspection
                  </Typography>
                  <Controller
                    name="inspection_id"
                    control={control}
                    defaultValue={relatedInspectionId}
                    render={({ field }) => (
                      <InspectionsSelect
                        {...field}
                        handleChange={field.onChange}
                        error={!!errors?.description}
                      />
                    )}
                  />
                </Grid>
              ) : null
          }
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
              >
                Title
              </Typography>
              <Controller
                name="title"
                control={control}
                defaultValue={defaultRequestType}
                render={({ field }) => (
                  <FormTextfield
                    {...field}
                    handleChange={field.onChange}
                    error={!!errors?.description}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
              >
                Description
              </Typography>
              <Controller
                name="description"
                control={control}
                defaultValue={defaultDescription}
                render={({ field }) => (
                  <FormTextfield
                    label=""
                    multiline
                    rows={3}
                    {...field}
                    handleChange={field.onChange}
                    error={!!errors?.description}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
              >
                Estimated value
              </Typography>
              <Controller
                name="value"
                control={control}
                defaultValue={defaultEstimatedValue}
                render={({ field }) => (
                  <SalePriceInput
                    label=""
                    {...field}
                    fullWidth
                    handleChange={field.onChange}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AttachmentsUpload />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

NewRepairRequestForm.defaultProps = {
  propsReason: null,
  defaultRequestType: '',
  defaultDescription: '',
  defaultEstimatedValue: '',
  relatedInspectionId: null,
  inspectionCount: 0,
};

const {
  string,
  func,
  oneOfType,
  number,
} = PropTypes;

NewRepairRequestForm.propTypes = {
  formId: string.isRequired,
  onSubmit: func.isRequired,
  propsReason: string,
  defaultRequestType: oneOfType([string, number]),
  defaultDescription: string,
  defaultEstimatedValue: number,
  relatedInspectionId: number,
  inspectionCount: number,
};

export default NewRepairRequestForm;
