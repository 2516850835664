import {
  SET_REALTOR_UNANSWERED_QUESTIONS,
} from 'store/actionTypes/realtorUnansweredQuestions';

const setByFilter = (items) => ({
  type: SET_REALTOR_UNANSWERED_QUESTIONS,
  payload: items,
});

export default setByFilter;
