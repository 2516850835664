import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { noOp } from 'shared/utility';

import Flex from 'ui/Flex';
import useModal from 'ui/hooks/useModal';

import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';

import PlayerItem from 'shared/modules/PlayerItem';

const AssignKeyContacts = (props) => {
  const {
    keyContactsData,
    onAdd,
    onRemove,

    preset,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const [modalTitle, setModalTitle] = useState('Add Key User');
  const [selectedRoleKey, setSelectedRoleKey] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const openModalPreset = useCallback((key, contactId, role) => () => {
    setModalTitle(`Choose a new ${role}`);
    setSelectedRoleKey(key);
    setSelectedContact(contactId);
    openModal();
  }, [
    openModal,
  ]);

  const onSave = useCallback((newContactIds) => {
    onAdd(selectedRoleKey)(newContactIds.addedContacts[0]);
    closeModal();
  }, [
    onAdd,
    selectedRoleKey,
  ]);

  return (
    <>
      <Flex
        spacing={1}
        flexWrap="nowrap"
        style={{ overflowX: 'auto', paddingBottom: 12 }}
      >
        {
          keyContactsData.map((item) => (
            <PlayerItem
              key={item.key}
              contactId={item.contactId}
              role={item.roleLabel}
              onDelete={onRemove(item.key)}
              onClick={
                item.contactId
                  ? noOp
                  : openModalPreset(
                      item.key,
                      item.contactId,
                      item.roleLabel,
                    )
              }
            />
          ))
        }
        {
          !preset && (
            <AssignKeyContacts
              key="new"
              onClick={openModal}
              contactId={null}
              role="Add Key User"
            />
          )
        }
      </Flex>

      <AssignContactModal
        title={modalTitle}
        isVisible={isModalVisible}
        oneContactSelect
        closeModal={closeModal}
        onSave={onSave}
        withAddContact
        canSelectYourself
        selectedContacts={[selectedContact]}
      />
    </>
  );
};

AssignKeyContacts.defaultProps = {
  keyContactsData: [],
  preset: false,
};

const {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
} = PropTypes;

AssignKeyContacts.propTypes = {
  keyContactsData: arrayOf(shape({
    key: string,
    contactId: number,
    roleLabel: string,
  })),
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  preset: bool,
};

export default AssignKeyContacts;
