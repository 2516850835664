import { connect } from 'react-redux';

import {
  receiveLogout,
  triggerCredentialsModal,
} from 'store/actions/auth';

import CredentialsModal from './CredentialsModal';

const mapStateToProps = (state) => ({
  visible: state.auth.credentialsModal,
});

const mapDispatchToProps = {
  receiveLogout,
  triggerCredentialsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsModal);
