import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';

const StyleButton = (props) => {
  const [classNameState, setClassNameState] = useState('edit-btn');
  const {
    style,
    onToggle,
    active,
    icon,
  } = props;

  useEffect(() => {
    if (active) {
      setClassNameState('edit-btn edit-btn_active');
    } else {
      setClassNameState('edit-btn');
    }
  }, [active]);

  const onClick = useCallback((e) => {
    e.preventDefault();
    onToggle(style);
  }, [style, onToggle]);

  return (
    <>
      <Button
        className={classNameState}
        onMouseDown={onClick}
      >
        <FontAwesomeIcon
          icon={icon}
        />
        {(style === 'header-one') && <span style={{ fontSize: '16px', fontWeight: '600' }}>1</span>}
        {(style === 'header-two') && <span style={{ fontSize: '16px', fontWeight: '600' }}>2</span>}
      </Button>
      {(style === 'header-two') && <div className="edit-toolbar__pipe" />}
      {(style === 'right') && <div className="edit-toolbar__pipe" />}
      {(style === 'ordered-list-item') && <div className="edit-toolbar__pipe" />}
      {(style === 't-right') && <div className="edit-toolbar__pipe" />}
      {(style === 'outdent') && <div className="edit-toolbar__pipe" />}
    </>
  );
};

StyleButton.defaultProps = {
  style: '',
  icon: '',
  active: false,
};

const {
  bool,
  func,
  string,
} = PropTypes;

StyleButton.propTypes = {
  style: string,
  icon: string,
  active: bool,
  onToggle: func.isRequired,
};


export default StyleButton;
