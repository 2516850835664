import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import parseUrl from 'url-parse';

import { Tooltip } from 'antd';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles.scss';

const rootClass = 'link';

const URLThumbnails = (props) => {
  const {
    url,
  } = props;

  const linkObjectName = 'simple-link';

  const parseUrlObject = parseUrl(url);

  const iconWrapperClassnames = classnames(
    `${rootClass}__icon-wrapper`,
    {
      [`${rootClass}__icon-wrapper--${linkObjectName}`]: linkObjectName,
    },
  );

  const titleClassnames = classnames(
    `${rootClass}__title`,
  );

  const onClick = useCallback(
    () => {
      window.open(url);
    },
    [url],
  );

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={url}
    >
      <button
        className={rootClass}
        onClick={onClick}
        type="button"
      >
        <div
          className={iconWrapperClassnames}
        >
          <FontAwesomeIcon
            color="white"
            icon="link"
          />
        </div>
        <span className={titleClassnames}>
          {parseUrlObject.hostname}
        </span>
      </button>
    </Tooltip>

  );
};

URLThumbnails.defaultProps = {
  url: null,
};

const {
  string,
} = PropTypes;

URLThumbnails.propTypes = {
  url: string,
};

export default URLThumbnails;
