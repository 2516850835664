import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchedContact from './SearchedContact';

const mapStateToProps = (state, ownProps) => {
  const contact = selectors.realtorContacts.byId(state, ownProps.contactId);

  return {
    contact,
  };
};

export default connect(mapStateToProps)(SearchedContact);
