/* eslint-disable  */
// TODO - fix dependency cycles
import { // eslint-disable-line import/no-cycle
  getTasksByStatus,
  getTasksByStatusError,
  getTasksByStatusSuccess,
  setTasksToTableSuccess,
} from 'store/actions/tasks-list-view';

import {
  getTaskById,
  getTaskAndRefreshRelatedSummaries,
  selectTask,
  setTasksForEmployee,
  optimizedTasksActions,
} from 'store/actions/tasksV2'

import {
  getLabels
} from 'store/actions/labels'

import { // eslint-disable-line import/no-cycle
  apiConnector,
  customerToUrlConnector,
  getConnector,
  tokenFetch as fetch,
} from 'shared/API';

import { callAlert } from 'ui/Alert';
import { getTeamsFromApi } from 'store/actions/team-setup'; // eslint-disable-line import/no-cycle

import { url as defaultUrl } from '../../paths';

import moment from 'moment';
import { tasks as TASK_CONSTANT } from 'constants';

import {
  SUBTASK_ADDED,
  TASK_ARCHIVED,
  TASK_CREATED,
  TASK_UPDATED,
  WORKLOG_ADDED,
} from '../../constants/tasks.constants';
import { uploadFile } from 'store/actions/attachments';

//util function

const getAllWidgetInformation = (tasksList, tasksProblemsComments, allManagerTasks, EmployeeID) => {
  const {
    DONE,
    ARCHIVE,
  } = TASK_CONSTANT.TASK_STATUS_ID;

  let countNewTasksAssignedToMe = 0;
  let overdueManagerTasksCount = 0;
  let overdueTasksCount = 0;
  let dueTodayTasksCount = 0;
  let userProblemTasksCount = 0;
  let managerProblemTasksCount = 0;
  let totalUserTasksCount = 0;
  let totalManagerTasksCount = 0;
  let unassignedManagerTasksCount = 0;
  let managerRequireFeedbackTasksCount = 0;
  const unacceptableTaskStatusID = [DONE, ARCHIVE];

  tasksList.forEach((task) => {
    const deliveryDate = moment(task.DeliveryDate).format('Y-M-D');
    const isCreatedYesterday = moment(task.CreationDate).isAfter(moment().subtract(1, 'day'));
    const isOverdueTasks = moment(task.DeliveryDate).isBefore(moment());
    const isDueToday = moment(deliveryDate).isSame(moment().format('Y-M-D'));
    allManagerTasks.forEach((managerTask) => {
      if (task.TaskID === managerTask.TaskID
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))
        && task.employees.length === 0) {
        unassignedManagerTasksCount += 1;
      }
      if (task.TaskID === managerTask.TaskID
        && isOverdueTasks
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))) {
        overdueManagerTasksCount += 1;
      }
      if (task.TaskID === managerTask.TaskID && task.TaskStatusID === 4) {
        managerRequireFeedbackTasksCount += 1;
      }
      if (task.TaskID === managerTask.TaskID
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))) {
        tasksProblemsComments.forEach((comment) => {
          totalManagerTasksCount += 1;
          if (Number(comment.CommentedObjectID) === task.TaskID) {
            managerProblemTasksCount += 1;
          }
        });
      }
    });
    task.employees.forEach((invitedEmployee) => {
      if (invitedEmployee.EmployeeID === EmployeeID
        && isCreatedYesterday
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))) {
        countNewTasksAssignedToMe += 1;
      }
      if (invitedEmployee.EmployeeID === EmployeeID
        && isOverdueTasks
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))) {
        overdueTasksCount += 1;
      }
      if (invitedEmployee.EmployeeID === EmployeeID
        && isDueToday
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))) {
        dueTodayTasksCount += 1;
      }
      if (invitedEmployee.EmployeeID === EmployeeID
        && (!unacceptableTaskStatusID.includes(task.TaskStatusID))) {
        tasksProblemsComments.forEach((comment) => {
          if (Number(comment.CommentedObjectID) === task.TaskID) {
            userProblemTasksCount += 1;
          }
        });
        totalUserTasksCount += 1;
      }
    });
  });

  return {
    countNewTasksAssignedToMe,
    overdueManagerTasksCount,
    overdueTasksCount,
    dueTodayTasksCount,
    userProblemTasksCount,
    managerProblemTasksCount,
    totalUserTasksCount,
    totalManagerTasksCount,
    unassignedManagerTasksCount,
    managerRequireFeedbackTasksCount,
  }
}
//

export const CREATE_TASK = 'CREATE_TASK';

const createTaskURL = '/api/tasks/createTask';

export const tasksStatus = (dispatch, getState) => {
  const {
    loggedUser: { EmployeeID },
  } = getState().auth;

  dispatch(getTaskStatusList());

  return fetch(`${defaultUrl}/Tasks/ReturnAllTaskStatuses`)
    .then((res) => res.json())
    .then((json) => {
      dispatch(getTaskStatusListSuccess(json.taskstatuses));

      const availableStatuses = json.taskstatuses.filter(
        (status) => status.TaskStatus !== 'Backlog' && status.TaskStatus !== 'Archive',
      );

      const tables = [];

      availableStatuses.forEach((status) => {
        const { StatusID, TaskStatus } = status;
        tables.push({ name: TaskStatus, id: [StatusID] });
      });

      tables.forEach((status) => {
        const { name, id } = status;
        id.forEach((statusId) => {
          dispatch(getTasksByStatus());

          getConnector(`/api/tasks/byStatus/${statusId}/${EmployeeID}`)
            .then((tasks) => dispatch(getTasksByStatusSuccess(name, tasks)))
            .catch((err) => {
              console.error(err);
              dispatch(getTasksByStatusError(err));
            });
        });
      });

      dispatch(setTasksToTableSuccess(tables));
    })
    .catch((error) => dispatch(getTaskStatusListError()));
};

export const initTasksStatus = () => (dispatch, getState) => {
  // tasksStatus(dispatch, getState);
};

export const initTaskPriorities = () => (dispatch) => {
  dispatch(getAllTaskPriorities());
  getConnector('/api/tasks/priorities')
    .then((priorities) => dispatch(getAllTaskPrioritiesSuccess(priorities)))
    .catch((err) => dispatch(getAllTaskPrioritiesError()));
};

export function createTask(
  user,
  data = {},
  extended = true,
  form,
  isStandard = true,
) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_TASK });
    const connector = isStandard ? apiConnector : customerToUrlConnector;

    const {
      loggedUser: { EmployeeID, CompanyID },
    } = getState().auth;
    const taskData = {
      user: { EmployeeID, CompanyID },
      data: {
        title: data.title || '',
        agents: data.agents || [],
        description: data.description || '',
        ...(data.parentId && { parentId: data.parentId }),
        ...(data.team && { team: data.team }),
        ...(data.status && { status: data.status }),
        ...(data.priority && { priority: data.priority }),
        ...(data.deliveryDate && { deliveryDate: data.deliveryDate }),
        ...(data.labels && { labels: data.labels }),
      },
      extended,
    };
    const teamId = data && data.team;

    return connector(createTaskURL, 'POST', taskData)
      .then((res) => {
        if (data.attachments) {
          const test = data.attachments.map(attachment => {
            return {
              appModule: attachment.appModule,
              attachments: attachment.file,
              id: res[0].TaskID,
            };
          });
          test.forEach(attachment => {
            dispatch(uploadFile(attachment));
          })
        }

        dispatch(createTaskSuccess(EmployeeID, teamId, res, form));
        dispatch(getTaskAndRefreshRelatedSummaries(res[0].TaskID));

        if (teamId) {
          optimizedTasksActions.getTeamTasks(teamId)
        }

        if (!data.skipUpdateModal) {
          dispatch(selectTask(res[0].TaskID));
          dispatch(showTaskModal(true));
        }

        dispatch(getLabels())
        callAlert.success(TASK_CREATED);

        return res[0].TaskID;
      })
      .catch((err) => dispatch(createTaskError()));
  };
}

export const GET_TASKS_DASHBOARD_INF = 'GET_TASKS_DASHBOARD_INF';
export const getTasksDashboardInf = () => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        EmployeeID,
      }
    },
    tasks: {
      tasksList,
    }
  } = getState();

  const url = '/api/tasks/getDashboardInf';
  const result = await apiConnector(
    url,
    'POST',
    {
      EmployeeID,
    },
  );

  const {
    tasksProblemsComments,
    allManagerTasks,
  } = result

  const widgetInformation = getAllWidgetInformation(tasksList, tasksProblemsComments, allManagerTasks, EmployeeID)

  const {
    countNewTasksAssignedToMe,
    overdueManagerTasksCount,
    overdueTasksCount,
    dueTodayTasksCount,
    userProblemTasksCount,
    managerProblemTasksCount,
    totalUserTasksCount,
    totalManagerTasksCount,
    unassignedManagerTasksCount,
    managerRequireFeedbackTasksCount,
  } = widgetInformation

  const otherInformation = [
    {
      value: overdueTasksCount,
      description: 'Overdue Tasks',
      managerViewValue: overdueManagerTasksCount,
      managerViewDescription: 'Overdue Tasks',
    },
    {
      value: dueTodayTasksCount,
      description: 'Due Today',
      managerViewValue: managerRequireFeedbackTasksCount,
      managerViewDescription: 'Require Feedback',
    },
    {
      value: userProblemTasksCount,
      description: 'Problems',
      managerViewValue: managerProblemTasksCount,
      managerViewDescription: 'Problems',
    },
    {
      value: totalUserTasksCount,
      description: 'Total Tasks',
      managerViewValue: totalManagerTasksCount,
      managerViewDescription: 'Total Tasks',
    },
  ];

  dispatch({
    type: GET_TASKS_DASHBOARD_INF,
    allTasksManagerTeamCount: result.allTasksManagerTeamCount,
    tasksProblemsComments: result.tasksProblemsComments,
    allManagerTasks: result.allManagerTasks,
    tasksWidgetOtherInformation: otherInformation,
    countNewTasksAssignedToMe,
    unassignedManagerTasksCount,
  });
};


export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';

export function createTaskSuccess(userId, teamId, data) {
  return {
    type: CREATE_TASK_SUCCESS,
    userId,
    teamId,
    data,
  };
}

export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR';

export function createTaskError() {
  return { type: CREATE_TASK_ERROR };
}

// Update task progress

export const UPDATE_TASK_PROGRESS = 'UPDATE_TASK_PROGRESS';
export const updateTaskProgress = (taskId, progress) => (dispatch, getState) => {
  const url = `/api/tasks/singleTask/updateProgress/${taskId}`;
  const { Name = '', Surname = '' } = getState().auth.loggedUser;

  return apiConnector(url, 'PUT', {
    Progress: progress,
    UserFullName: `${Name} ${Surname}`,
  })
    .then((task) => {
      dispatch(updateTaskProgressSuccess(task));
      dispatch(getTaskAndRefreshRelatedSummaries(taskId))
      callAlert.success(TASK_UPDATED);
    })
    .catch((err) => dispatch(updateTaskProgressError()));
};

export const UPDATE_TASK_PROGRESS_SUCCESS = 'UPDATE_TASK_PROGRESS_SUCCESS';
export const updateTaskProgressSuccess = (task) => ({ type: UPDATE_TASK_PROGRESS_SUCCESS, task });

export const UPDATE_TASK_PROGRESS_ERROR = 'UPDATE_TASK_PROGRESS_ERROR';
export const updateTaskProgressError = () => ({ type: UPDATE_TASK_PROGRESS_ERROR });

export const ADD_SUBTASK = 'ADD_SUBTASK';
export const addSubtask = (task, subtaskId) => (dispatch, getState) => {
  const url = `/api/tasks/singleTask/updateField/${subtaskId}`;
  return apiConnector(url, 'PUT', {
    field: 'ParentID',
    data: task.TaskID,
  })
    .then(() => {
      callAlert.success(SUBTASK_ADDED);
      dispatch(getTaskById(subtaskId))
      dispatch(getTaskById(task.TaskID))
    })
    .catch((err) => {
      dispatch(updateTaskProgressError());
    });
};

// Update task field

export const UPDATE_TASK_FIELD = 'UPDATE_TASK_FIELD';
export const updateTaskField = (id, field, data) => async (dispatch, getState) => {
  const url = `/api/tasks/singleTask/updateField/${id}`;
  const { Name = '', Surname = '' } = getState().auth.loggedUser;

  try {
    await apiConnector(url, 'PUT', {
      field,
      data,
      userFullName: `${Name} ${Surname}`,
    })

    dispatch(getTaskAndRefreshRelatedSummaries(id))
    callAlert.success(TASK_UPDATED);
  }
  catch {
    dispatch(updateTaskProgressError());
  }
};

export const UPDATE_TASK_FIELD_SUCCESS = 'UPDATE_TASK_FIELD_SUCCESS';
export const updateTaskFieldSuccess = (task) => ({
  type: UPDATE_TASK_FIELD_SUCCESS,
  task,
});

export const UPDATE_TASK_FIELD_ERROR = 'UPDATE_TASK_FIELD_ERROR';
export const updateTaskFieldError = () => {
  callAlert.warning('Error occured');
  return { type: UPDATE_TASK_FIELD_ERROR };
};

/* Tasks priorities actions */

export const GET_ALL_TASKS_PRIORITIES = 'GET_ALL_TASKS_PRIORITIES';

export function getAllTaskPriorities() {
  return { type: GET_ALL_TASKS_PRIORITIES };
}

export const GET_ALL_TASKS_PRIORITIES_SUCCESS = 'GET_ALL_TASKS_PRIORITIES_SUCCESS';

export function getAllTaskPrioritiesSuccess(priorities) {
  return {
    type: GET_ALL_TASKS_PRIORITIES_SUCCESS,
    priorities,
  };
}

export const GET_ALL_TASKS_PRIORITIES_ERROR = 'GET_ALL_TASKS_PRIORITIES_ERROR';

export function getAllTaskPrioritiesError() {
  return { type: GET_ALL_TASKS_PRIORITIES_ERROR };
}

/* Task statuses actions  */

export const GET_TASK_STATUS_LIST = 'GET_TASK_STATUS_LIST';

export function getTaskStatusList() {
  return {
    type: GET_TASK_STATUS_LIST,
  };
}

export const GET_TASK_STATUS_LIST_SUCCESS = 'GET_TASK_STATUS_LIST_SUCCESS';

export function getTaskStatusListSuccess(list) {
  return {
    type: GET_TASK_STATUS_LIST_SUCCESS,
    taskStatusList: list,
  };
}

export const GET_TASK_STATUS_LIST_ERROR = 'GET_TASK_STATUS_LIST_ERROR';

export function getTaskStatusListError() {
  return {
    type: GET_TASK_STATUS_LIST_ERROR,
  };
}

/* Worklog actions */

export const CREATE_WORKLOG_ENTRY = 'CREATE_WORKLOG_ENTRY';
export const createWorklogEntry = (id, data) => (dispatch, getState) => {
  const url = `/api/tasks/logWork/${id}`;
  return apiConnector(url, 'POST', {
    data,
  })
    .then((worklog) => {
      dispatch(createWorklogEntrySuccess(worklog));
      callAlert.success(WORKLOG_ADDED);
    })
    .catch((err) => {
      dispatch(createWorklogEntryError());
    });
};

export const CREATE_WORKLOG_ENTRY_SUCCESS = 'CREATE_WORKLOG_ENTRY_SUCCESS';

export function createWorklogEntrySuccess() {
  return {
    type: CREATE_WORKLOG_ENTRY_SUCCESS,
  };
}

export const CREATE_WORKLOG_ENTRY_ERROR = 'CREATE_WORKLOG_ENTRY_ERROR';

export function createWorklogEntryError() {
  return {
    type: CREATE_WORKLOG_ENTRY_ERROR,
  };
}

/* Timer reminder actions */

export const START_TASK_TIMER = 'START_TASK_TIMER';
export const UPDATE_TASK_TIMER = 'UPDATE_TASK_TIMER';
export const STOP_TASK_TIMER_REQUEST = 'STOP_TASK_TIMER_REQUEST';
export const STOP_TASK_TIMER_SUCCESS = 'STOP_TASK_TIMER_SUCCESS';
export const STOP_TASK_TIMER_ERROR = 'STOP_TASK_TIMER_ERROR';
export const START_TASK_REMINDER_INTERVAL = 'START_TASK_REMINDER_INTERVAL';
export const STOP_TASK_REMINDER_INTERVAL = 'STOP_TASK_REMINDER_INTERVAL';
export const SHOW_TASK_REMINDER_MODAL = 'SHOW_TASK_REMINDER_MODAL';
export const HIDE_TASK_REMINDER_MODAL = 'HIDE_TASK_REMINDER_MODAL';

export function startTaskTimer(taskId) {
  return (dispatch, getState) => {
    const taskReminderData = {
      taskId,
      startedAt: Date.now(),
    };
    setTaskReminderData(taskReminderData);
    dispatch(startTaskReminderInterval());
    dispatch({ type: START_TASK_TIMER, payload: taskReminderData });
  };
}

export const updateTaskTimer = (duration, task, diffTime) => async (dispatch, getState) => {
  try {
    const {
      tasksV2: {
        selectedTask,
      },
    } = getState()

    const url = `/api/tasks/singleTask/updateField/${task.TaskID}`;
    const receivedTask = await apiConnector(url, 'PUT', {
      field: 'Duration',
      data: duration,
    })

    const finishedAt = Date.now();
    dispatch(
      createWorklogEntry(receivedTask.TaskID, {
        time: diffTime,
        finishedAt: new Date(finishedAt).toISOString(),
      }),
    );
    dispatch(getTaskById(selectedTask))
    callAlert.success(TASK_UPDATED);
  } catch {
    dispatch(updateTaskProgressError());
  }
}

export const stopTaskTimerAndSendData = () => async (dispatch, getState) => {
  try {
    const {
      tasks: {
        taskReminder: {
          startedAt
        },
      },
      tasksV2: {
        tasks,
        selectedTask,
      },
    } = getState()

    const { Duration } = tasks[selectedTask];

    const taskOldDuration = Duration || 0

    const finishedAt = Date.now();
    const newTaskDuration = Math.round((finishedAt - startedAt) / 1000) + taskOldDuration;
    const url = `/api/tasks/singleTask/updateField/${selectedTask}`;

    await apiConnector(url, 'PUT', {
      field: 'Duration',
      data: newTaskDuration,
    })
    localStorage.removeItem('taskReminder');
    dispatch(
      createWorklogEntry(selectedTask, {
        time: Math.round((finishedAt - startedAt) / 1000),
        finishedAt: new Date(finishedAt).toISOString(),
      }),
    );
    dispatch({ type: STOP_TASK_TIMER_SUCCESS });
    dispatch(stopTaskReminderInterval());
    dispatch(getTaskById(selectedTask))
  } catch {
    dispatch(updateTaskProgressError());
  }
};

export function updateTaskInterval() { }

export function startTaskReminderInterval() {
  return (dispatch, getState) => {
    const taskReminderJson = localStorage.getItem('taskReminder');
    const taskReminderData = JSON.parse(taskReminderJson);
    if (taskReminderData) {
      const oldIntervalId = getState().tasks.taskReminder.intervalId;
      if (oldIntervalId) {
        clearInterval(oldIntervalId);
      }
      const intervalId = setInterval(() => dispatch(checkReminder()), 360000);
      dispatch({
        type: START_TASK_REMINDER_INTERVAL,
        payload: {
          intervalId,
          taskId: taskReminderData.taskId,
          startedAt: taskReminderData.startedAt,
        },
      });
    }
  };
}

export function stopTaskReminderInterval() {
  return (dispatch, getState) => {
    const { intervalId } = getState().tasks.taskReminder;
    if (intervalId) {
      clearInterval(intervalId);
      dispatch({
        type: STOP_TASK_REMINDER_INTERVAL,
        payload: { intervalId },
      });
      clearTaskReminderData();
    }
  };
}

export function hideTaskReminderModal() {
  return { type: HIDE_TASK_REMINDER_MODAL };
}

export function getTaskAndShowTaskReminder(
  taskId,
  startedAt,
  newTaskReminderData,
) {
  return (dispatch) => {
    getConnector(`/api/tasks/${taskId}`)
      .then((res) => {
        if (res) {
          setTaskReminderData(newTaskReminderData);
          dispatch({
            type: SHOW_TASK_REMINDER_MODAL,
            payload: { task: res, startedAt },
          });
        } else {
          dispatch(stopTaskReminderInterval());
        }
      })
      .catch((err) => console.error(err));
  };
}

export function checkReminder() {
  return (dispatch, getState) => {
    const taskReminderJson = localStorage.getItem('taskReminder');
    if (getState().tasks.taskReminder.taskToRemind) return;
    try {
      const taskReminderData = JSON.parse(taskReminderJson);
      if (taskReminderData && typeof taskReminderData === 'object') {
        const { taskId, startedAt, remindedAt } = taskReminderData;
        const dateNow = Date.now();
        if (remindedAt) {
          if ((dateNow - remindedAt) / 3600000 > 1) {
            const newTaskReminderData = {
              taskId,
              startedAt,
              remindedAt: dateNow,
            };
            dispatch(
              getTaskAndShowTaskReminder(taskId, startedAt, newTaskReminderData),
            );
          }
        } else if ((dateNow - startedAt) / 3600000 > 1) {
          const newTaskReminderData = {
            taskId,
            startedAt,
            remindedAt: dateNow,
          };
          dispatch(
            getTaskAndShowTaskReminder(taskId, startedAt, newTaskReminderData),
          );
          setTaskReminderData(newTaskReminderData);
        }
      }
    } catch (error) {
      localStorage.removeItem('taskReminder');
    }
  };
}

function setTaskReminderData(data) {
  localStorage.setItem('taskReminder', JSON.stringify(data));
}

function clearTaskReminderData() {
  localStorage.removeItem('taskReminder');
}

/* Get tasks */

export const GET_TASKS = 'GET_TASKS';
export const getTasks = () => async (dispatch, getState) => {
  try {
    const tasks = await getConnector('/api/tasks')
    await dispatch(getTasksSuccess(tasks));
    const taskId = getState().tasks.selectedTask.task.TaskID;
    if (taskId) {
      dispatch(getTaskData(taskId, false));
    }
  } catch (error) {
    dispatch(getTasksError())
  }
};

export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const getTasksSuccess = (tasks) => ({
  type: GET_TASKS_SUCCESS,
  tasks,
});

export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';
export const getTasksError = () => ({ type: GET_TASKS_ERROR });

/* Change task status */

export const UPDATE_TASK_STATUS = 'CHANGE_TASK_STATUS';
export const updateTaskStatus = (id, status, showSuccessMessage = true) => (dispatch, getState) => {
  apiConnector('/Tasks/ChangeTaskStatus', 'POST', {
    TaskID: id,
    StatusID: status,
  })
    .then((json) => {
      if (status === 6) {
        callAlert.success(TASK_ARCHIVED);
      } else if (showSuccessMessage) {
        callAlert.success(TASK_UPDATED);
      }
      // dispatch(getTasks());
      // dispatch(getCustomerTaskInfoSuccess(json.results));
      dispatch(getTaskAndRefreshRelatedSummaries(id))
    })
    .catch((error) => {
      // dispatch(getCustomerTaskInfoError(error))
    });
};

export const SET_TASK_DATA = 'SET_TASK_DATA';
export const setTaskData = (status, task) => ({
  type: SET_TASK_DATA,
  status,
  task,
});

export const GET_TASK_DATA_BY_TASK_ID = 'GET_TASK_DATA_BY_TASK_ID';
export function getTaskData(TaskID, shouldClear = true) {
  return (dispatch, getState) => {
    const isTutorialRunning = getState().tutorial.isTutorialRunning;
    if (!isTutorialRunning) {
      if (shouldClear) {
        dispatch(clearSelectedTask());
      }
      dispatch({
        type: GET_TASK_DATA_BY_TASK_ID,
        id: TaskID,
      });
    }
  };
}

export const CLEAR_SELECTED_TASK = 'CLEAR_SELECTED_TASK';
export function clearSelectedTask() {
  return {
    type: CLEAR_SELECTED_TASK,
  };
}

export const SHOW_CREATE_TASK_MODAL = 'SHOW_CREATE_TASK_MODAL';

export function showCreateTaskModal(isVisible, data) {
  return {
    type: SHOW_CREATE_TASK_MODAL,
    visibility: isVisible,
    data,
  };
}

export const SHOW_TASK_DETAILS_MODAL = 'SHOW_TASK_DETAILS_MODAL';

export function showTaskModal(isVisible) {
  return (dispatch) => {
    dispatch({
      type: SHOW_TASK_DETAILS_MODAL,
      visibility: isVisible,
    });
  };
}

export const CLEAR_TASK_COMMENTS = 'CLEAR_TASK_COMMENTS';

export function clearTaskComments() {
  return { type: CLEAR_TASK_COMMENTS };
}

export const GET_TASK_COMMENTS = 'GET_TASK_COMMENTS';

export function getTaskComments(id) {
  return (dispatch) => {
    const url = `/api/comments/task/${id}`;
    return getConnector(url)
      .then((res) => dispatch(getTaskCommentsSuccess(res)))
      .catch((err) => callAlert.warning('Error occured'));
  };
}

export const TASK_CLEAR_COMMENTS = 'TASK_CLEAR_COMMENTS';

export function taskClearComments() {
  return {
    type: TASK_CLEAR_COMMENTS,
  };
}

export const GET_TASK_COMMENTS_SUCCESS = 'GET_TASK_COMMENTS_SUCCESS';

export function getTaskCommentsSuccess(list) {
  return {
    type: GET_TASK_COMMENTS_SUCCESS,
    comments: list,
  };
}

export const GET_TASK_CALLS_LOG = 'GET_TASK_CALLS_LOG';

export const getTaskCallsLog = (taskId) => async (dispatch) => {
  const url = `/api/tasks/singleTask/getCallsLog/${taskId}`;

  let result;

  try {
    result = await apiConnector(url, 'GET');
  } catch (error) {
    callAlert.warning('Error getting call/email log entries');
  }

  dispatch(getTaskCallsLogSuccess(result));
};

export const GET_TASK_CALLS_LOG_SUCCESS = 'GET_TASK_CALLS_LOG_SUCCESS';

export function getTaskCallsLogSuccess(data) {
  return {
    type: GET_TASK_CALLS_LOG_SUCCESS,
    log: data,
  };
}

export const ADD_TASK_CALL_LOG = 'ADD_TASK_CALL_LOG';

export const addCallLog = (taskId, data) => async (dispatch) => {
  const url = `/api/tasks/singleTask/addCallLog/${taskId}`;
  const {
    id,
    employeeId,
    duration,
    creationTime,
    notes,
    contactType,
    sentimait,
    leadId,
    title,
    isAcknowledged,
    acknowledgedUserId,
  } = data;

  const body = {
    ID: id,
    EmployeeID: employeeId,
    Duration: duration,
    CreationTime: creationTime,
    Notes: notes,
    Type: 'lead',
    ContactType: contactType,
    Sentimait: sentimait,
    LeadID: leadId,
    Title: title,
    IsAcknowledged: isAcknowledged,
    AcknowledgedUserId: acknowledgedUserId,
  };

  try {
    await apiConnector(url, 'POST', body);
  } catch (error) {
    callAlert.warning('Error submitting call/email log entry');
    return;
  }

  dispatch(getTaskCallsLog(taskId));
};

export const deleteCallLog = (data) => async (dispatch) => {
  const {
    id,
    taskId,
    LeadID,
    Title,
  } = data;
  const url = `/api/tasks/singleTask/deleteCallLog/${taskId}`;

  const body = {
    ID: id,
    LeadID,
    Title,
  };

  try {
    await apiConnector(url, 'POST', body);
  } catch (error) {
    callAlert.warning('Error deleting call/email log entry');
    return;
  }

  dispatch(getTaskCallsLog(taskId));
};

export const SET_TASK_LAST_UPDATE_DATE = 'SET_TASK_LAST_UPDATE_DATE';
export const setTaskLastUpdateDate = (payload) => ({
  type: SET_TASK_LAST_UPDATE_DATE,
  payload,
});

export const GET_TASK_ACTIVITIES = 'GET_TASK_ACTIVITIES';
export const getTaskActivities = (payload) => (dispatch) => {
  const { taskID } = payload;
  getConnector(`/api/common/activities?ObjectType=task&ObjectID=${taskID}`)
    .then((data) => {
      const dataLength = data.length;
      if (data && dataLength) {
        const lastRow = data[dataLength - 1];
        const {
          EmployeeObject: { Name },
        } = lastRow;
        dispatch(
          setTaskLastUpdateDate({
            lastUpdateDate: lastRow.updatedAt,
            lastUpdateAgent: Name,
          }),
        );
      } else {
        dispatch(
          setTaskLastUpdateDate({
            lastUpdateDate: 'N/A',
            lastUpdateAgent: '',
          }),
        );
      }
    })
    .catch((err) => callAlert.warning(err.message));
};

export const UPDATE_TASK_DELVIERY_DATE = 'UPDATE_TASK_DELVIERY_DATE';

export function updateTaskDeliveryDate(id, date) {
  return (dispatch) => fetch(`${defaultUrl}/api/tasks/singleTask/updateTaskDeliveryDate/${id}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify({
      DeliveryDate: date,
    }),
  })
    .then((res) => res.json())
    .then((task) => {
      dispatch(UpdateTaskDeliveryDateSuccess(task));
    })
    .catch((error) => { });
}

export const UPDATE_TASK_DELVIERY_DATE_SUCCESS = 'UPDATE_TASK_DELVIERY_DATE_SUCCESS';

export function UpdateTaskDeliveryDateSuccess(task) {
  return {
    type: UPDATE_TASK_DELVIERY_DATE_SUCCESS,
    task,
  };
}


// export const SET_TASK_LAST_UPDATE_DATE = 'SET_TASK_LAST_UPDATE_DATE';
export const unlinkSubtask = (id, parentId) => async (dispatch) => {
  const url = `/api/tasks/singleTask/updateField/${id}`;

  await apiConnector(url, 'PUT', {
    field: 'ParentID',
    data: null,
  });

  dispatch(getTaskById(id));
  dispatch(getTaskById(parentId));
};

export const SET_TASKS_LABELS = 'SET_TASKS_LABELS';
export const setTasksLabels = (labels) => ({
  type: SET_TASKS_LABELS,
  labels,
});

export const SET_TASK_LABEL = 'SET_TASK_LABEL';
export const setTaskLabel = (label) => ({
  type: SET_TASK_LABEL,
  label,
});
