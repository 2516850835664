import { connect } from 'react-redux';

import requests from 'api/requests';

import {
  getMe,
} from 'store/actions/auth';

import validate from 'shared/utils/validators';

import { callAlert } from 'ui/Alert';

import ProfileDetails from './ProfileDetails';

const updateProfileData = (dbKey, globalDbKey) => (value) => async (employeeId) => {
  try {
    await Promise.all([
      requests.employees.updateEmployeeInfo(employeeId, {
        [dbKey]: value,
      }),
      requests.employees.updateEmployeeInfoInGlobal({
        [globalDbKey]: value,
      }),
    ]);

    callAlert.success('Updated successfully!');
  } catch (error) {
    callAlert.error('Couldn\'t update!');
  }
};

const mapStateToProps = (state) => {
  const {
    auth: {
      loggedUser: {
        Name,
        Surname,
        WorkPhone,
        EmployeeID,
      },
    },
  } = state;

  return {
    firstName: Name,
    lastName: Surname,
    phone: WorkPhone,
    userId: EmployeeID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfileData: (dbKey, globalDbKey) => (value) => async (employeeId) => {
    await updateProfileData(dbKey, globalDbKey)(value)(employeeId);
    dispatch(getMe());
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  updateProfileData: (dbKey, globalDbKey) => (value) => {
    if (dbKey === 'WorkPhone') {
      if (!validate.phone(value)) {
        return;
      }
    }

    dispatchProps.updateProfileData(dbKey, globalDbKey)(value)(stateProps.userId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProfileDetails);
