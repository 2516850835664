import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Breadcrumbs from './Breadcrumbs';

const mapStateToProps = (state) => {
  const contact = selectors.realtorContacts.selected(state);

  return {
    contactId: contact.id,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
