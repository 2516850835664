import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import {
  IconWrapper,
  Avatar,
  StyledLink,
} from './styledItems';

const Image = withMainPropertyImage(({ url }) => {
  if (!url) {
    return (
      <IconWrapper>
        <Icon
          name="property"
          className="property-icon"
          type="custom"
          color="background-flat"
          size="xs"
        />
      </IconWrapper>
    );
  }

  return (
    <Avatar src={url} />
  );
});

const SearchItemTransaction = (props) => {
  const {
    transactionId,
    propertyId,
    property,
    seller,
    buyer,
  } = props;

  return (
    <StyledLink to={`/transactions/${transactionId}`}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Image
            propertyId={propertyId}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          justify="space-around"
        >
          <Grid item>
            <Typography
              variant="title3"
              weight="bold"
              noMargin
            >
              {property?.opportunity_name}
            </Typography>
          </Grid>

          {
            (seller || buyer) ? (
              <Grid item>
                <Flex spacing={2}>
                  {
                    seller && (
                      <Flex
                        spacing={1}
                        spacingStep={4}
                      >
                        <Typography
                          variant="title4"
                          color="descriptor-text"
                          weight="bold"
                          noMargin
                        >
                          {'Seller: '}
                        </Typography>
                        <Typography
                          variant="title4"
                          color="descriptor-text"
                          noMargin
                        >
                          {`${seller.first_name} ${seller.last_name}`}
                        </Typography>
                      </Flex>
                    )
                  }

                  {
                    buyer && (
                      <Flex
                        spacing={1}
                        spacingStep={4}
                      >
                        <Typography
                          variant="title4"
                          color="descriptor-text"
                          weight="bold"
                          noMargin
                        >
                          Buyer:
                        </Typography>
                        <Typography
                          variant="title4"
                          color="descriptor-text"
                          noMargin
                        >
                          {`${buyer.first_name} ${buyer.last_name}`}
                        </Typography>
                      </Flex>
                    )
                  }
                </Flex>
              </Grid>
            ) : null
          }
        </Grid>
      </Grid>
    </StyledLink>
  );
};

SearchItemTransaction.defaultProps = {
  transactionId: null,
  propertyId: null,
  property: {},
};

const {
  shape,
  number,
} = PropTypes;

SearchItemTransaction.propTypes = {
  transactionId: number,
  propertyId: number,
  seller: shape().isRequired,
  buyer: shape().isRequired,
  property: shape({
    address_id: number,
  }),
};

export default SearchItemTransaction;
