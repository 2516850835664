import requests from 'api/requests';

import setProducts from './setProducts';
import setProductsForFilter from './setProductsForFilter';

const getProductsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.crm.products.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setProducts(response.data.products));
    dispatch(setProductsForFilter(filterId)(response.data.products));
  } catch (error) {
    console.error(error);
  }
};

export default getProductsForFilter;
