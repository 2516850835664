import styled from 'styled-components';

export const AntdThemeProvider = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;

  --antd-wave-shadow-color: ${(props) => props.theme.palette.focus};

  /* buttons */
  .ant-btn {
    &-primary {
      background-color: ${(props) => props.theme.palette.focus};
      font-family: ${(props) => props.theme.typography.fontFamily.button};
      font-size:  ${(props) => props.theme.typography.fontSize.button};
      font-weight:  ${(props) => props.theme.typography.fontWeight.button};
      border: none;
      letter-spacing: 0.5px;
      border-radius: 10px;
    }

    &-disabled {
      background-color: ${(props) => props.theme.palette.disabled};
      color: white;
      font-family: ${(props) => props.theme.typography.fontFamily.button};
      font-size:  ${(props) => props.theme.typography.fontSize.button};
      font-weight:  ${(props) => props.theme.typography.fontWeight.button};
      border: none;
      letter-spacing: 0.5px;
      &:hover {
        background-color: ${(props) => props.theme.palette.disabled};
        color: white;
      }
    }
  }

   /* checkboxes */
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: ${(props) => props.theme.palette.focus};
      background-color: ${(props) => props.theme.palette.focus};
    }

    &:hover {
      &::after {
        border-color: ${(props) => props.theme.palette.focus};
      }
    }

    &::after {
      border-color: ${(props) => props.theme.palette.focus};
    }
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${(props) => props.theme.palette.focus};
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &::after {
        background-color: ${(props) => props.theme.palette.focus};
      }
    }
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: ${(props) => props.theme.palette.focus};
  }

  .ant-table-column-sorter {
    &-down,
    &-up {
      &.on {
        color: ${(props) => props.theme.palette.focus} !important;
      }
    }
  }
  /*  */


  /* pagination */
  .ant-pagination-total-text {
    font-size: ${props => props.theme.typography.fontSize['main-text']};
    color: ${props => props.theme.palette['main-text']};
  }

  .ant-pagination-item {
    a {
      font-size: ${props => props.theme.typography.fontSize['main-text']};
      color: ${props => props.theme.palette['main-text']};
    }
    &-active,
    &:focus {
      background: ${(props) => props.theme.palette.focus};
      border-color: ${(props) => props.theme.palette.focus};
      a {
        font-size: ${props => props.theme.typography.fontSize['main-text']};
        color: ${(props) => props.theme.palette['main-text-inversed']};
      }
    }
    &:hover {
      a {
        color: ${(props) => props.theme.palette.focus};
      }
    }
  }

  .ant-pagination {
    &-prev,
    &-next {
      color: ${props => props.theme.palette['main-text']};
      &:hover,
      &:focus {
        .ant-pagination-item-link {
          border-color: ${(props) => props.theme.palette.focus};
          color: ${(props) => props.theme.palette.focus};
        }
      }
    }
  }

  .ant-pagination-disabled {
    &:hover {
      a {
        border-color: ${(props) => props.theme.palette.disabled} !important;
        color: rgba(0,0,0,0.25) !important;
      }
    }
  }
  /*  */

  /* table row */
  .ant-table-row {

    &:hover {
      cursor: pointer;
    }
  }
  /*  */
`;

export default {
  AntdThemeProvider,
};
