import { connect } from 'react-redux';

import {
  updateRepairRequest,
} from 'store/actions/realtorRepairRequests';

import FormTextfield from 'shared/inputs/FormTextfield';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

const mapStateToProps = (state, ownProps) => {
  const {
    description,
  } = ownProps;

  return {
    value: description,
    label: '',
    placeholder: 'Enter description...',
    variant: 'standard',
    disableUnderline: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateRepairRequest(ownProps.requestId)({ description: value }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withDebouncedCallbacks(FormTextfield, [[
  'onChange',
  1000,
]]));
