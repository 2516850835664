/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'oa/components/Modal';
import Button from 'oa/components/Button';
import { userType } from 'oa/types/index';
import ThreeDots from '../ThreeDots/index';
import './style.css';

const WaitingForCallee = (props) => {
  const {
    callee,
    isCallDeclined,
    onCancel,
    onOk,
  } = props;

  const calleeName = callee && callee.Name;
  const title = `Waiting for ${calleeName} to accept your call request.`;

  return (
    <Modal
      isOpen={!!callee}
      onClose={isCallDeclined ? onOk : onCancel}
      overlayClassName="waiting-for-callee-modal"
      className="waiting-for-callee-modal__inner"
      size="sm"
    >
      <Modal.Content
        className="waiting-for-callee-modal__content"
      >
        <Modal.Title
          className="waiting-for-callee-modal__title"
        >
          {title}
        </Modal.Title>

        {
          isCallDeclined
            ? (
              <p className="waiting-for-callee-modal__is-busy">
                {calleeName}
                {' '}
                is unable to take your call as she/he is busy.
              </p>
            ) : (
              <ThreeDots
                className="waiting-for-callee-modal__loader"
              />
            )
        }

        {
          isCallDeclined
            ? (
              <Button
                onClick={onOk}
                className="waiting-for-callee-modal__ok"
              >
                Ok
              </Button>
            ) : (
              <Button
                type="tertiary_default"
                onClick={onCancel}
                className="waiting-for-callee-modal__cancel"
              >
                Cancel
              </Button>
            )
        }
      </Modal.Content>
    </Modal>
  );
};

WaitingForCallee.propTypes = {
  callee: userType,
  isCallDeclined: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default WaitingForCallee;
