import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import NewProspectForm from 'sections/Prospects/forms/NewProspectForm';

const newProspectFormId = 'new-realtor-client-form';

const NewProspectModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    title,
  } = props;

  return (
    <Modal
      theme={theme}
      width={720}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title={title}
      footer={[
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={newProspectFormId}
          onClick={() => {}}
        >
          Create Prospect
        </Button>,
      ]}
    >
      <NewProspectForm formId={newProspectFormId} />
    </Modal>
  );
};

NewProspectModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

NewProspectModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
};

export default NewProspectModal;
