import apiConnector, { endpoints } from 'api';

/**
 * Sends POST request to create the service people.
 * @param {Object} payload - The fields of service people.
 * @returns {Promise} A response from server.
 */
const create = async (payload) => {
  const endpoint = endpoints.client.servicePeople;

  let response;

  try {
    response = await apiConnector.client.post(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default create;
