/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  createContactFromEditPropertyViewingForm,
} from 'store/actions/propertyViewings';

import CreateContactButton from './CreateContactButton';

const mapStateToProps = (state) => {
  const {
    editPropertyViewingForm: {
      newContact: {
        first_name,
        last_name,
        email_primary,
        phone_primary,
      },
      newContactFieldsValidators: {
        isEmailValid,
        doesEmailExist,
        doesPhoneExist,
      },
    },
  } = state;

  const hasRequiredFields = first_name && last_name && email_primary && phone_primary;
  const validationPassed = isEmailValid && !doesEmailExist && !doesPhoneExist;

  const buttonDisabled = !hasRequiredFields || !validationPassed;

  return {
    disabled: buttonDisabled,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(createContactFromEditPropertyViewingForm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateContactButton);
