export const SET_LIKE = 'SET_LIKE';
export const SET_LIKES = 'SET_LIKES';

export const SET_DISLIKE = 'SET_DISLIKE';
export const SET_DISLIKES = 'SET_DISLIKES';

export default {
  SET_LIKE,
  SET_LIKES,

  SET_DISLIKE,
  SET_DISLIKES,
};
