import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CounterItemWithButton from 'shared/components/UI/CounterItemWithButton';

import Grid from 'ui/Grid';

import { map } from 'shared/utility';

const overviewItems = {
  total_repair_requests: {
    name: 'Total',
    withActionButton: false,
  },
  accepted_repair_requests: {
    name: 'Accepted',
    withActionButton: false,
  },
  declined_repair_requests: {
    name: 'Declined',
    withActionButton: false,
  },
};

const Overview = (props) => {
  const {
    data,
  } = props;

  return (
    <Grid container spacing={2}>
      {
        map(data, (value, key) => (
          <Fragment key={key}>
            <Grid item xs="auto">
              <CounterItemWithButton
                title={overviewItems[key].name}
                value={value}
                withActionButton={overviewItems[key].withActionButton}
              />
            </Grid>
          </Fragment>
        ))
      }
    </Grid>
  );
};

const {
  shape,
  number,
} = PropTypes;

Overview.propTypes = {
  data: shape({
    total_repair_requests: number,
    accepted_repair_requests: number,
    declined_repair_requests: number,
  }).isRequired,
};

export default Overview;
