import requests from 'api/requests';

import set from './set';
import setInfiniteScrollMatchingPropertiesForFilter from './setInfiniteScrollMatchingPropertiesForFilter';
import setCountByFilter from './setCountByFilter';

const getInfiniteScrollMatchingPropertiesForFilter = (filterId) => (filter) => (
    offset = 0,
    limit = 50,
    resetState = false,
  ) => async (dispatch) => {
  try {
    const response = await requests.realtor.matchingProperties.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(set(response.data.items));
    dispatch(setInfiniteScrollMatchingPropertiesForFilter(filterId)(response.data.items)(resetState));
    dispatch(setCountByFilter(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getInfiniteScrollMatchingPropertiesForFilter;
