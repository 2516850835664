import styled from 'styled-components';

const TextRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-left: ${(props) => (props.spacing ? `-${props.spacing}px` : '0px')};
  > * {
    margin-left: ${(props) => (props.spacing ? `${props.spacing}px !important` : '0px')};
  }
`;

export default TextRow;
