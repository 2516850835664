import requests from 'api/requests';

import setLineItem from './setLineItem';

const getById = (id) => async (dispatch) => {
  try {
    const response = await requests.lineItems.getById(id);
    dispatch(setLineItem(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getById;
