/* eslint-disable max-len */

import { get } from 'shared/utility';
import checkPermissions from 'shared/utils/checkPermissions';

const permissionsCodes = {
  'Company Settings': 1,
  Symplete: 2,
  Tasks: 4,
  HR: 8,
  'Customer Support': 16,
  Sales: 32,
  Cashflow: 64,
  Surveys: 128,
  CustomerDB: 256,
  'Contact Database': 512,
  Service: 1024,
};
const accessLevels = {
  user: 'UserRole',
  manager: 'ManagerRole',
};
/**
 * @typedef {'user' | 'manager'} AccessLevels
 */
/**
 * @typedef {'Company Settings' | 'Symplete' | 'Tasks' | 'HR' | 'Customer Support' | 'Sales' | 'Cashflow' | 'Surveys' | 'CustomerDB' | 'Contact Database' | 'Service'} Sections
 */
/**
 * @param {AccessLevels} accessLevel
 * @param {Sections} section
 * @returns {Boolean}
 */
const checkEmployeeLevelPermissions = (accessLevel, section, employeeObject) => {
  const role = accessLevels[accessLevel];
  const userPermissions = get(employeeObject, role);
  const requiredPermissions = permissionsCodes[section];
  return checkPermissions(requiredPermissions, userPermissions);
};
/**
 *
 * @param {Sections} section
 * @returns {Boolean}
 */
const checkUserLevelPermissions = (section) => (employeeObject) => checkEmployeeLevelPermissions('user', section, employeeObject);
/**
 *
 * @param {Sections} section
 * @returns {Boolean}
 */
const checkManagerLevelPermissions = (section) => (employeeObject) => checkEmployeeLevelPermissions('manager', section, employeeObject);
/**
 *
 * @returns {Boolean}
 */
const checkOwnerLevelPermissions = () => (employeeObject) => checkEmployeeLevelPermissions('manager', 'Company Settings', employeeObject);

/**
 * Check if user has required permissions;
 */
const checkEmployeePermissions = {
  isUser: checkUserLevelPermissions,
  isManager: checkManagerLevelPermissions,
  isOwner: checkOwnerLevelPermissions,
};

export default checkEmployeePermissions;
