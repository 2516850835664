import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup from 'ui/RadioGroup';

const items = [
  {
    value: 'attorney',
    displayedValue: 'Attorney State',
  },
  {
    value: 'escrow',
    displayedValue: 'Escrow State',
  },
];

const AttorneyEscrowState = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <RadioGroup
      items={items}
      defaultValue={value}
      onChange={onChange}
      flexDirection="row"
      gap="20px"
    />
  );
};

const {
  oneOf,
  func,
} = PropTypes;

AttorneyEscrowState.propTypes = {
  value: oneOf(['attorney', 'escrow']).isRequired,
  onChange: func.isRequired,
};

export default AttorneyEscrowState;
