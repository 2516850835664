import { connect } from 'react-redux';

import {
  createAttachment,
  deleteFile,
  getFileList,
  triggerAvatarCropModal,
} from 'store/actions/attachments';

import getAvailabilityBadgeStatus from 'shared/utils/getAvailabilityBadgeStatus';

import ImageUploadWrapper from './ImageUploadWrapper'; // eslint-disable-line import/no-cycle

const mapStateToProps = (state, ownProps) => {
  const {
    attachments: {
      cropAppModule,
      appModuleList,
      cropModalVisible,
    },
    myCompany: {
      general,
    },
  } = state;

  const {
    objectId,
  } = ownProps;

  const availabilityBadgeStatus = getAvailabilityBadgeStatus(objectId);

  return {
    availabilityBadgeStatus,
    cropAppModule,
    fileLists: appModuleList,
    general,
    visible: cropModalVisible,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    appModule,
    objectId,
    uploadNoSubmit,
    isCrmCompany,
    isPublic = true,
  } = ownProps;

  const fileMeta = {};

  if (appModule === 'company-leads-logo') {
    if (isCrmCompany) {
      fileMeta.crmCompanyId = objectId;
    } else {
      fileMeta.LeadID = objectId;
    }
  }

  if (appModule === 'contact-leads-logo') {
    fileMeta.SalesContactsID = objectId;
  }

  return {
    createAttachment: async (file) => {
      if (uploadNoSubmit) {
        uploadNoSubmit(file);
        return;
      }

      await dispatch(createAttachment(appModule)(objectId)(file, fileMeta, true, isPublic));
    },
    deleteFile: (...params) => dispatch(deleteFile(...params)),
    getFileList: (callback) => dispatch(getFileList(appModule, objectId, true, callback)),
    triggerAvatarCropModal: (...params) => dispatch(triggerAvatarCropModal(...params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadWrapper);
