import connectReducers from 'store/connectReducers';

import { groupBy } from 'shared/utility';

import {
  SET_SHIPPED_ITEM,
  SET_SHIPPED_ITEMS,
  SET_SELECTED_SHIPPED_ITEM,
  SET_SHIPPED_ITEM_ATTACHMENTS,
} from 'store/actionTypes/shippedItems';

const initialState = {
  shippedItems: {},
  selectedShippedItem: null,
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_SELECTED_SHIPPED_ITEM]: (state, action) => ({
    ...state,
    selectedShippedItem: action.payload,
  }),

  [SET_SHIPPED_ITEM]: (state, action) => ({
    ...state,
    shippedItems: {
      ...state.shippedItems,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_SHIPPED_ITEMS]: (state, action) => ({
    ...state,
    shippedItems: {
      ...state.shippedItems,
      ...listToObject(action.payload),
    },
  }),

  [SET_SHIPPED_ITEM_ATTACHMENTS]: (state, action) => ({
    ...state,
    shippedItems: {
      ...state.shippedItems,
      [action.payload.id]: {
        ...state.shippedItems[action.payload.id],
        attachments: groupBy(action.payload.attachments, 'attachmentType'),
      },
    },
  }),
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
