import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import PropertyAddressInput from 'shared/inputs/AddressInput';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      propertyAddress,
    },
  } = state;

  return {
    addressId: propertyAddress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => {
    dispatch(setNewLeadFormField('propertyAddress')(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAddressInput);
