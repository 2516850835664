import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import get from 'api/requests/realtor/contacts/get';

import SaveAsFileButton from 'ui/SaveAsFileButton';

const ExportAs = (props) => {
  const {
    ids,
  } = props;

  const text = useMemo(() => {
    if (!ids || ids.length < 1) {
      return 'Export all contacts';
    }

    return `Export selected ${ids.length} ${ids.length > 1 ? 'items' : 'item'}`;
  }, [
    ids,
  ]);

  const query = useMemo(() => {
    if (!ids || ids.length < 1) {
      return {
        limit: 1e6,
      };
    }

    return { ids };
  }, [
    ids,
  ]);

  return (
    <SaveAsFileButton
      fetchRequest={get}
      query={query}
      extension={['csv', 'tsv']}
      fileName="Realtor_Contacts"
    >
      { text }
    </SaveAsFileButton>
  );
};

const {
  arrayOf,
  number,
} = PropTypes;

ExportAs.defaultProps = {
  ids: null,
};

ExportAs.propTypes = {
  ids: arrayOf(number),
};

export default ExportAs;
