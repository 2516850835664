import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import mime from 'mime';

import { Upload, Icon } from 'antd';

import Typography from 'ui/Typography';

import pdf from './filesTypesImg/pdf.png';
import doc from './filesTypesImg/doc.png';
import txt from './filesTypesImg/txt.png';

import {
  UploadWrapper,
} from './styledItems';

const fileIcons = {
  'application/pdf': pdf,
  'text/plain': txt,
  'application/msword': doc,
};

const tooltip = document.createElement('span');
tooltip.className = 'file-tooltip';

const InlineAttachmentsUpload = (props) => {
  const {
    fileList,
    onUpload,
    onRemove,
    multiple,
    label,
    itemSize,
  } = props;

  const [attachmentsList, setAttachmentsList] = useState([]);

  const showTooltip = useCallback((event) => {
    const fileName = event.target.getElementsByClassName('ant-upload-list-item-name')[0].title;

    tooltip.textContent = fileName;
    event.target.appendChild(tooltip);
  }, []);

  const hideTooltip = useCallback((event) => {
    if (event.target.getElementsByClassName('file-tooltip')[0]) {
      event.target.removeChild(tooltip);
    }
  }, []);

  useEffect(() => {
    const items = document.getElementsByClassName('ant-upload-list-item');
    if (items.length !== 0) {
      for (let i = 0; i < items.length; i += 1) {
        items[i].addEventListener('mouseenter', showTooltip);
        items[i].addEventListener('mouseleave', hideTooltip);
      }
    }
  }, [attachmentsList]);

  useEffect(() => {
    const items = document.getElementsByClassName('ant-upload-list-item');

    return () => {
      if (items.length !== 0) {
        for (let i = 0; i < items.length; i += 1) {
          items[i].removeEventListener('mouseenter', showTooltip);
          items[i].removeEventListener('mouseleave', hideTooltip);
        }
      }
    };
  }, []);

  useEffect(() => {
    const newAttachmentsList = fileList.map((item) => {
      const fileType = mime.getType(item.name);

      return {
        ...item,
        thumbUrl: fileIcons[fileType],
      };
    });

    setAttachmentsList(newAttachmentsList);
  }, [fileList]);

  return (
    <UploadWrapper itemSize={itemSize}>
      <Upload
        accept="image/png, image/jpeg, .pdf, .doc, .txt"
        listType="picture-card"
        fileList={attachmentsList}
        customRequest={onUpload}
        onRemove={onRemove}
        multiple={multiple}
      >
        <div>
          <Icon type="plus" />
          {
            label && (
              <Typography
                variant="h6"
                tag="p"
                weight={500}
                color="highlight"
                noMargin
              >
                {label}
              </Typography>
            )
          }
        </div>
      </Upload>
    </UploadWrapper>
  );
};

InlineAttachmentsUpload.defaultProps = {
  fileList: [],
  multiple: false,
  label: null,
  itemSize: 'm',
};

const {
  func,
  shape,
  arrayOf,
  string,
  bool,
  oneOf,
} = PropTypes;

InlineAttachmentsUpload.propTypes = {
  fileList: arrayOf(shape({
    uid: string,
    name: string,
    url: string,
    thumbUrl: string,
    isMain: bool,
  })),
  onUpload: func.isRequired,
  onRemove: func.isRequired,
  multiple: bool,
  label: string,
  itemSize: oneOf(['xs', 's', 'm', 'l']),
};

export default InlineAttachmentsUpload;
