/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getCallLogs from './getCallLogs';
import getCallLogsForFilter from './getCallLogsForFilter';
import getCallLogsForStoreFilter from './getCallLogsForStoreFilter';

export {
  getCallLogs,
  getCallLogsForFilter,
  getCallLogsForStoreFilter,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCallsActions = {
  getCallLogsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getCallLogsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getCallLogsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getCallLogsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
};
