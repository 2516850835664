import { connect } from 'react-redux';

import requests from 'api/requests';
import createAttachmentFormData from 'shared/utils/attachments/createAttachmentFormData';

import EmailComposer from './EmailComposer';

const uploadAttachment = (tokenId) => async (attachment) => {
  const attachmentFormData = await createAttachmentFormData(
    attachment,
  );

  const attachmentData = await requests.integrations.emailAttachments.upload(tokenId)(attachmentFormData);
  return attachmentData;
};

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  if (contactId) {
    return {
      emailTo: [contactId],
      uploadAttachment,
    };
  }

  return {
    uploadAttachment,
  };
};

export default connect(mapStateToProps)(EmailComposer);
