import updateContact from './updateContact';

const updateSelectedContact = (updateData) => async (dispatch, getState) => {
  const {
    realtorContacts: {
      selectedContact,
    },
  } = getState();

  dispatch(updateContact(selectedContact)(updateData));
};

export default updateSelectedContact;
