import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import useModal from 'ui/hooks/useModal';

import FeedbackForm from 'sections/MatchingProperties/modules/FeedbackForm';

import {
  sentiments,
  sentimentIcons,
} from 'constants/feedback.constants';

const buttonStyles = {
  textTransform: 'capitalize',
};

const Feedback = (props) => {
  const {
    matchId,
    sentiment,
    onLog,
    onShow,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
    Modal,
  } = useModal();

  const log = onLog || openModal;
  const show = onShow || openModal;

  const onClick = useCallback(() => {
    if (sentiment) {
      log();
    } else {
      show();
    }
  }, [
    sentiment,
    onLog,
    onShow,
  ]);

  if (!sentiment) {
    return (
      <>
        <Button
          variant="primary"
          onClick={onClick}
          dense
          fullWidth
          centered
        >
          Log Feedback
        </Button>
        <Modal
          visible={isModalVisible}
          onCancel={closeModal}
          footer={null}
          title="Feedback"
          width={764}
        >
          <FeedbackForm
            matchId={matchId}
            defaultView="edit"
          />
        </Modal>
      </>
    );
  }

  const sentimentName = sentiments[sentiment];

  return (
    <>
      <Button
        variant={sentimentName}
        onClick={onClick}
        dense
        fullWidth
        icon={sentimentIcons[sentimentName]}
        iconColor={sentimentName}
        style={buttonStyles}
        centered
      >
        {sentiments[sentiment]}
        {' '}
        feedback
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        title="Feedback"
        width={764}
      >
        <FeedbackForm
          matchId={matchId}
          defaultView="display"
        />
      </Modal>
    </>
  );
};

Feedback.defaultProps = {
  sentiment: null,
  onLog: null,
  onShow: null,
};

const {
  func,
  number,
  oneOf,
} = PropTypes;

Feedback.propTypes = {
  sentiment: oneOf(Object.keys(sentiments)),
  onLog: func,
  onShow: func,
  matchId: number.isRequired,
};

export default Feedback;
