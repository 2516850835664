import React from 'react';
import PropTypes from 'prop-types';
import './Sidebar.scss';
import MainNavigation from './MainNavigation/index';

const Sidebar = (props) => {
  // TODO: Alt element in logo img need to be configured
  const {
    isExternal,
  } = props;

  return (
    <section
      data-test="sidebar"
      className="sidebar"
    >
      {isExternal ? null : <MainNavigation />}
    </section>
  );
};

Sidebar.defaultProps = {
  isExternal: false,
};

const {
  bool,
} = PropTypes;

Sidebar.propTypes = {
  isExternal: bool,
};

export default Sidebar;
