import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  Input,
  Image,
} from './styledItems';

const RadioImage = (props) => {
  const {
    name,
    onChange,
    disabled,
    src,
    value,
    checked,
  } = props;

  const handleChange = useCallback((event) => {
    if (!disabled) {
      onChange(event.target.value);
    }
  }, [
    onChange,
    disabled,
  ]);

    return (
      <Root>
        <Image
          checked={checked === value}
          src={src}
          onClick={handleChange}
        >
          <Input
            onChange={handleChange}
            type="radio"
            name={name}
            value={value}
            checked={checked === value}
            disabled={disabled}
          />
        </Image>
      </Root>
    );
};

RadioImage.defaultProps = {
  name: null,
  onChange: () => { },
  checked: null,
  value: null,
  disabled: false,
  src: null,
};

const {
  bool,
  func,
  string,
  number,
} = PropTypes;

RadioImage.propTypes = {
  name: string,
  disabled: bool,
  onChange: func,
  checked: number,
  value: number,
  src: string,
};

export default RadioImage;
