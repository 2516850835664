import get from './get';
import getById from './getById';
import create from './create';
import update from './update';
import deletePropertyViewing from './deletePropertyViewing';
import addRelations from './addRelations';
import removeRelations from './removeRelations';
import overwriteRelations from './overwriteRelations';

export default {
  get,
  getById,
  create,
  update,
  deletePropertyViewing,
  addRelations,
  removeRelations,
  overwriteRelations,
};
