import attachments from './attachments';
import articles from './articles';
import account from './account';
import billing from './billing';
import chat from './chat';
import comments from './comments';
import company from './company';
import companyDetails from './companyDetails';
import customers from './customers';
import employees from './employees';
import labels from './labels';
import logs from './logs';
import queues from './queues';
import requestContact from './requestContact';
import systemCheck from './systemCheck';
import tasks from './tasks';
import teams from './teams';
import departments from './departments';
import tickets from './tickets';
import users from './users';
import leads from './leads';
import dictionary from './dictionary';
import crm from './crm';
import meetings from './meetings';
import employeesV2 from './employeesV2';
import fulfillments from './fulfillments';
import customerInvoices from './customerInvoices';
import orders from './orders';
import shippedItems from './shippedItems';
import customerPayments from './customerPayments';
import addresses from './addresses';
import servicePeople from './servicePeople';
import customerEmails from './customerEmails';
import lineItems from './lineItems';
import skus from './skus';
import customerDigitalAccounts from './customerDigitalAccounts';
import deals from './deals';
import serviceProcesses from './serviceProcesses';
import ordersCancelled from './ordersCancelled';
import refunds from './refunds';
import creditNotes from './creditNotes';
import customerInformed from './customerInformed';
import warehouse from './warehouse';
import agreements from './agreements';
import realtor from './realtor';
import likes from './likesDislikes/likes';
import dislikes from './likesDislikes/dislikes';
import followUpReminders from './followUpReminders';
import syncedEvents from './syncedEvents';
import external from './external';
import socialIntegrations from './socialIntegrations';
import auth from './auth';
import calls from './calls';
import dataImport from './dataImport';
import personTitles from './personTitles';
import navbarSettings from './navbarSettings';
import phoneNumbers from './phoneNumbers';
import emails from './emails';

import smartzip from './smartzip';
import globalProperties from './globalProperties';

import integrations from './integrations';

import images from './images';

export default {
  account,
  attachments,
  articles,
  billing,
  chat,
  comments,
  company,
  companyDetails,
  customers,
  employees,
  labels,
  logs,
  queues,
  requestContact,
  systemCheck,
  tasks,
  teams,
  tickets,
  departments,
  users,
  leads,
  dictionary,
  crm,
  meetings,
  employeesV2,
  fulfillments,
  customerInvoices,
  orders,
  shippedItems,
  customerPayments,
  addresses,
  servicePeople,
  customerEmails,
  lineItems,
  skus,
  customerDigitalAccounts,
  deals,
  serviceProcesses,
  ordersCancelled,
  refunds,
  creditNotes,
  customerInformed,
  warehouse,
  agreements,
  realtor,
  likes,
  followUpReminders,
  dislikes,
  external,
  socialIntegrations,
  auth,
  calls,
  dataImport,
  personTitles,
  navbarSettings,
  phoneNumbers,
  emails,

  smartzip,
  globalProperties,

  integrations,
  syncedEvents,

  images,
};
