import {
  SET_REALTOR_ADDENDUM_CHANGES_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorAddendumChanges';

const setForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_ADDENDUM_CHANGES_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setForFilterCount;
