import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import PinnedNoteModal from './PinnedNoteModal';

const PinnedNote = (props) => {
  const {
    content,
    selectedObjectId,
    onNoteChange,
    objectTypeName,
    CustomRoot,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (content && content !== '<p></p>') {
      openModal();
    }
  }, [selectedObjectId]);

  const openModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      {
        !CustomRoot && (
          <Button
            onClick={openModal}
            icon="thumbtack"
            iconType="fontawesome"
            variant="secondary"
          />
        )
      }

      {
        CustomRoot && (
          <CustomRoot onClick={openModal} />
        )
      }

      <PinnedNoteModal
        content={content}
        isVisible={isModalVisible}
        closeModal={closeModal}
        onNoteChange={onNoteChange}
        objectTypeName={objectTypeName}
      />
    </>
  );
};

PinnedNote.defaultProps = {
  content: null,
  selectedObjectId: null,
  CustomRoot: null,
};

const {
  string,
  number,
  func,
} = PropTypes;

PinnedNote.propTypes = {
  content: string,
  selectedObjectId: number,
  onNoteChange: func.isRequired,
  objectTypeName: string.isRequired,
  CustomRoot: func,
};

export default PinnedNote;
