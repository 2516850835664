import styled from 'styled-components';

export const Root = styled.section`
  padding-bottom: 32px;
`;

export const Column = styled.div`
  width: 100%;
`;

export const EmptyImage = styled.img`
  margin: auto auto 20px;
`;

export const EmptyItemsContainer = styled.div`
  width: fit-content;
  margin: auto;
  margin-top: 90px;
  display: grid;
`;
