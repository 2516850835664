import styled from 'styled-components';

export const DescriptionContainer = styled.div`
    display: flex;
    border-radius: 6px;
    background-color:${(props) => props.theme?.palette?.['background-flat'] || 'rgb(242, 246, 254)'};
    justify-content: space-between;
    height: 29px;
`;

export const FullHeightContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 3px;
`;

export default {
  DescriptionContainer,
  FullHeightContainer,
};
