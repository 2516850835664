import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_LEADS_OBJECTS_FILTER_ITEM,
  RESET_REALTOR_LEADS_OBJECTS_FILTER_ITEM,
  CLEAR_REALTOR_LEADS_OBJECTS_FILTER,
} from 'store/actionTypes/realtorLeads';

import { setValue } from 'store/utils';

const initialState = {
  limit: 50,
  offset: 0,
  sortBy: 'countFilter',
  sortDirection: 'desc',
};

const reducers = {
  [SET_REALTOR_LEADS_OBJECTS_FILTER_ITEM]: setValue,

  [RESET_REALTOR_LEADS_OBJECTS_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload]: initialState[action.payload],
  }),

  [CLEAR_REALTOR_LEADS_OBJECTS_FILTER]: (state, action) => initialState,
};

function realtorLeadsFilter(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default realtorLeadsFilter;
