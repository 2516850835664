// TODO - fix
import store from 'store'; // eslint-disable-line import/no-cycle

import { get } from 'shared/utility';
import checkPermissions from 'shared/utils/checkPermissions';

const permissionsCodes = {
  'Company Settings': 1,
  Symplete: 2,
  Tasks: 4,
  HR: 8,
  'Customer Support': 16,
  Sales: 32,
  Cashflow: 64,
  Surveys: 128,
  CustomerDB: 256,
  'Contact Database': 512,
  Service: 1024,
};
const accessLevels = {
  user: 'UserRole',
  manager: 'ManagerRole',
};
/**
 * @typedef {'user' | 'manager'} AccessLevels
 */
/* eslint-disable max-len */
/**
 * @typedef {'Company Settings' | 'Symplete' | 'Tasks' | 'HR' | 'Customer Support' | 'Sales' | 'Cashflow' | 'Surveys' | 'CustomerDB' | 'Contact Database' | 'Service'} Sections
 */
/* eslint-enable max-len */
/**
 * @param {AccessLevels} accessLevel
 * @param {Sections} section
 * @returns {Boolean}
 */
const checkLoggedEmployeeLevelPermissions = (accessLevel, section) => {
  const role = accessLevels[accessLevel];
  const userPermissions = get(store.getState(), `auth.loggedUser.${role}`, 0);
  const requiredPermissions = permissionsCodes[section];
  return checkPermissions(requiredPermissions, userPermissions);
};
/**
 *
 * @param {Sections} section
 * @returns {Boolean}
 */
const checkUserLevelPermissions = (section) => checkLoggedEmployeeLevelPermissions('user', section);
/**
 *
 * @param {Sections} section
 * @returns {Boolean}
 */
const checkManagerLevelPermissions = (section) => checkLoggedEmployeeLevelPermissions('manager', section);
/**
 *
 * @returns {Boolean}
 */
const checkOwnerLevelPermissions = () => checkLoggedEmployeeLevelPermissions('manager', 'Company Settings');

/**
 * Check if logged user has required permissions;
 */
const checkLoggedEmployeePermissions = {
  isUser: checkUserLevelPermissions,
  isManager: checkManagerLevelPermissions,
  isOwner: checkOwnerLevelPermissions,
};

export default checkLoggedEmployeePermissions;
