import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeModal,
} from 'store/actions/modals';

import {
  createFollowUpReminders,
  deleteFollowUpReminders,
  getFollowUpReminderById,
} from 'store/actions/followUpReminders';

import ObjectRemindersModal from 'shared/modals/ObjectRemindersModal';

const mapStateToProps = (state, ownProps) => {
  const {
    objectId,
    objectTypeId,
  } = ownProps;

  const contentOptions = state.modals?.followUpReminders?.contentOptions;

  const reminders = selectors.followUpReminders.reminderList(state, objectTypeId, objectId);

  return {
    reminders,
    title: contentOptions?.objectTitle
      ? `Follow Up Reminders: ${contentOptions.objectTitle}`
      : 'Follow Up Reminders',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onOk: () => {
    dispatch(closeModal('followUpReminders')());
  },
  onCreate: (reminder) => {
    dispatch(createFollowUpReminders(ownProps.objectTypeId)(ownProps.objectId)([reminder]));
  },
  onDelete: async (reminderId) => {
    await dispatch(deleteFollowUpReminders(ownProps.objectTypeId)(ownProps.objectId)([reminderId]));
    dispatch(getFollowUpReminderById(reminderId));
  },
  closeModal: () => {
    dispatch(closeModal('followUpReminders')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectRemindersModal);
