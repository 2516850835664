import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  setFilterItem,
} from 'store/actions/realtorContactsFilter';

import {
  getContactsForStoreFilter,
} from 'store/actions/realtorContacts';

import Search from './Search';

const filterKeyToStatus = {
  all: null,
  active: 1,
  inactive: [4, 6],
  archived: 2,
};

const statusToFilterKey = {
  1: 'active',
  2: 'archived',
};

const getFilterKey = (status) => {
  if (!status) {
    return 'all';
  }
  if (Array.isArray(status) && status.includes(4) && status.includes(6)) {
    return 'inactive';
  }

  return statusToFilterKey[status];
};

const mapStateToProps = (state) => {
  const {
    realtorContactsFilter: {
      status,
    },
  } = state;

  const selectedFilterKey = getFilterKey(status);

  return {
    selectedFilterKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    dispatch(setFilterItem('search')(search));
    dispatch(setFilterItem('pageNumber')(1));

    dispatch(getContactsForStoreFilter());
  }, 500),

  handleStatusFilter: (value) => {
    dispatch(setFilterItem('status')(filterKeyToStatus[value]));
    dispatch(setFilterItem('pageNumber')(1));

    dispatch(getContactsForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
