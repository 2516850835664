import {
  SET_LIKE,
} from 'store/actionTypes/likes-dislikes';

const setLike = (like) => ({
  type: SET_LIKE,
  payload: like,
});

export default setLike;
