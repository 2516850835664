import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import Flex from 'ui/Flex';

import CommentTypes from 'shared/components/CommentTypes';

import {
  SelectorWrapper,
} from './styledItems';

const CommentTypesSelector = (props) => {
  const {
    onTypeChange,
    commentType,
  } = props;

  return (
    <SelectorWrapper>
      <Flex spacing={1}>
        <Typography
          variant="body"
          className="comment-type-select-description"
          weight={400}
          noMargin
        >
          Select comment type:
        </Typography>

        <CommentTypes
          onTypeChange={onTypeChange}
          selectedType={commentType}
        />
      </Flex>
    </SelectorWrapper>

  );
};

CommentTypesSelector.defaultProps = {
  commentType: null,
};

const {
  func,
  string,
} = PropTypes;

CommentTypesSelector.propTypes = {
  onTypeChange: func.isRequired,
  commentType: string,
};

export default CommentTypesSelector;
