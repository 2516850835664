import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import DateTimeInput from 'shared/inputs/DateTimeInput';

const CallLogsCreationDateFilter = (props) => {
  const {
    onChange,
    dateFromValue,
    dateToValue,
  } = props;

  return (
    <Flex
      justifyContent="space-evenly"
      alignItems="center"
      spacing={1}
    >
      <FlexItem>
        From
      </FlexItem>
      <FlexItem>
        <DateTimeInput
          value={dateFromValue}
          handleChange={onChange('dateFrom')}
          label={null}
          flat
        />
      </FlexItem>
      <FlexItem>
        to
      </FlexItem>
      <FlexItem>
        <DateTimeInput
          value={dateToValue}
          handleChange={onChange('dateTo')}
          label={null}
          flat
        />
      </FlexItem>
    </Flex>
  );
};

const {
  func,
  string,
} = PropTypes;

CallLogsCreationDateFilter.propTypes = {
  onChange: func.isRequired,
  dateFromValue: string.isRequired,
  dateToValue: string.isRequired,
};

export default CallLogsCreationDateFilter;
