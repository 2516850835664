import { createSelector } from 'reselect';

import { capitalize } from 'shared/utility';

const sourceTypes = (state) => state.crmSources.sourceTypes;

export default createSelector(
  sourceTypes,
  (items) => (items
    ? items.map((item) => ({
      id: item.id,
      title: capitalize(item.name),
      name: capitalize(item.name),
    }))
    : null),
);
