import apiConnector from 'api';

const updateTaskDeliveryDate = async (
  taskId,
  deliveryDate,
) => {
  const endpoint = `/api/tasks/singleTask/updateTaskDeliveryDate/${taskId}`;
  const body = {
    DeliveryDate: deliveryDate,
  };

  try {
    const result = await apiConnector.client.put(endpoint, body);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default updateTaskDeliveryDate;
