import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

function MenuItem(props) {
  const {
    children,
    onClick,
    style,
    customComponent,
    icon,
    iconType,
    disabled,
  } = props;

  return (
    <Root
      onClick={onClick}
      disabled={disabled}
    >
      <Flex
        spacing={1}
        alignItems="center"
      >
        {
          customComponent || (
            <>
              {icon && (
                <Icon
                  name={icon}
                  type={iconType}
                  color="primary"
                />
              )}
              <Typography
                style={style}
                variant="body"
                noMargin
                align="left"
              >
                {children}
              </Typography>
            </>
          )
        }
      </Flex>
    </Root>
  );
}

MenuItem.defaultProps = {
  style: {},
  icon: null,
  iconType: 'feather',
  customComponent: null,
  disabled: false,
};

const {
  element,
  func,
  shape,
  string,
  bool,
} = PropTypes;

MenuItem.propTypes = {
  children: string.isRequired,
  onClick: func.isRequired,
  style: shape(),
  customComponent: element,
  icon: string,
  iconType: string,
  disabled: bool,
};

export default MenuItem;
