import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 600px;
  margin-bottom: 32px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 175px;
  padding-top: 40px;
  border-radius: 10px;
  background-color: ${(props) => (props.theme.palette?.['background-flat-dark'] || 'lightgray')};
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

export const Card = styled.div`
  width: 95%;
  min-width: 550px;
  margin: -45px auto 0;
  padding: 15px 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 9px 0 rgba(9, 55, 83, 0.1);
`;
