import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import AttachmentsModule from 'shared/AttachmentsV4';

import useAttachments from 'shared/hooks/useAttachments';

const attachmentsObjectType = 31;
const attachmentsType = 12;

const Attachments = (props) => {
  const {
    objectId,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [hasAttachments, setHasAttachments] = useState(false);

  const [list] = useAttachments(attachmentsObjectType, objectId, attachmentsType, false, true);

  const toggleModal = useCallback((isVisible) => (event) => {
    setIsModalVisible(isVisible);
  }, []);

  useEffect(() => {
    setHasAttachments(list?.length > 0);
  }, [
    JSON.stringify(list),
  ]);

  if (!hasAttachments) {
    return null;
  }

  return (
    <>
      <Button
        onClick={toggleModal(true)}
        variant="tertiary"
      >
        <Typography
          noMargin
          textDecoration="underline"
          color="focus"
        >
          View Agreement
        </Typography>
      </Button>

      <Modal
        width={720}
        visible={isModalVisible}
        onCancel={toggleModal(false)}
        footer={null}
        title="Offer Agreement"
      >
        <AttachmentsModule
          objectType={attachmentsObjectType}
          objectId={objectId}
          attachmentType={attachmentsType}
        />
      </Modal>
    </>
);
};

const {
  number,
} = PropTypes;

Attachments.propTypes = {
  objectId: number.isRequired,
};

export default Attachments;
