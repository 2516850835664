import { connect } from 'react-redux';

import getOffer from 'store/actions/external/realtor/offers/get';

import ExternalOffers from './ExternalOffers';

const mapStateToProps = (state) => {
  const {
    realtorOffers: {
      selectedOffer,
    },
  } = state;

  return {
    offerId: selectedOffer?.offerId,
    rootOfferId: selectedOffer?.rootOfferId,
    selectedOffer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOffer: () => dispatch(getOffer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalOffers);
