import styled from 'styled-components';

export const InnerComponentRoot = styled.div``;

export const InnerComponentTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
`;

export const InnerComponentValue = styled.span`
  font-size: 12px;
`;

export default {
  InnerComponentRoot,
  InnerComponentTitle,
  InnerComponentValue,
};
