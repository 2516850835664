import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getCallLogsForFilter from './getCallLogsForFilter';

const getCallLogsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.calls.callLogsFilter(getState());

  try {
    await dispatch(getCallLogsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getCallLogsForStoreFilter;
