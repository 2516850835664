import {
  SET_TASKS,
} from 'store/actionTypes/tasks';

const setTasks = (tasks) => ({
  type: SET_TASKS,
  payload: tasks,
});

export default setTasks;
