const readFile = ({
  readString,
  setPreviewTableData,
  setDelimiter,
}) => (file) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    const content = event.target.result;

    readString(content, {
      worker: true,
      complete: (results) => {
        if (results.data) {
          setPreviewTableData(
            results.data[0], // headers
            results.data.slice(1), // records
          );
          setDelimiter(results.meta.delimiter);
        }
      },
    });
  };

  reader.readAsText(file);
};

export default readFile;
