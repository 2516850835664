import getProducts from './getProducts';
import createProduct from './createProduct';
import updateProduct from './updateProduct';
import removeProduct from './removeProduct';

export default {
  getProducts,
  createProduct,
  updateProduct,
  removeProduct,
};
