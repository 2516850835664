import React from 'react';
import PropTypes from 'prop-types';

import EmailSection from 'sections/Contacts/modules/EmailSection';

import Drawer from 'ui/Drawer';

const EmailDrawer = (props) => {
  const {
    isDrawerOpen,
    toggleDrawer,
    contactId,
    objectId,
    contactType,
    message,
    subject,
    onSend,
  } = props;

  return (
    <Drawer
      visible={isDrawerOpen}
      onClose={toggleDrawer}
      width={1200}
      bodyStyle={{ height: '100%' }}
      autoFocus
    >
      {
        // this is to prevent unnecessary renders when drawer is hidden
        isDrawerOpen
          && (
          <EmailSection
            contactId={contactId}
            contactType={contactType}
            objectId={objectId}
            message={message}
            subject={subject}
            onSend={onSend}
          />
          )
      }
    </Drawer>
  );
};

EmailDrawer.defaultProps = {
  contactId: null,
  objectId: null,
  contactType: null,
  message: '',
  subject: '',
  onSend: null,
};

const {
  number,
  bool,
  string,
  func,
} = PropTypes;

EmailDrawer.propTypes = {
  isDrawerOpen: bool.isRequired,
  toggleDrawer: func.isRequired,
  contactId: number,
  objectId: number,
  contactType: string,
  message: string,
  subject: string,
  onSend: func,
};

export default EmailDrawer;
