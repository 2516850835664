// TODO improve import/prefer-default-export
// eslint-disable-next-line import/prefer-default-export
export const NAVIGATION_TABS = {
  CASHFLOW: 'Cashflow',
  CUSTOMER_SERVICE: 'Customer Support',
  CUSTOMER_DB: 'Customer DB',
  HOME: 'Home',
  HR: 'Team',
  KNOWLEDGE_BASE: 'Knowledge Base',
  SALES: 'Sales',
  SETTINGS: 'Settings',
  SURVEYS: 'Surveys',
  TASKS: 'Tasks',
  CRM: 'Contact Database',
  ARTICLES: 'Articles',
  SERVICE: 'Service',

  today: 'Today',
  communications: 'Comms Hub',
  mls: 'Properties',
  prospects: 'Prospects',
  clients: 'Clients',
  opportunities: 'Clients',
  leads: 'Leads',
  transactions: 'Transactions',
  contacts: 'Contacts',
  offers: 'Offers',
  inAppMarketing: 'Marketing',
  opportunitiesListing: 'Opportunities',
};

export const NAVIGATION_ROUTES = {
  today: '/today',
  communications: '/communications',
  mls: '/mls',
  leads: '/leads',
  transactions: '/transactions',
  contacts: '/contacts',
  clients: '/clients-sellers',
  prospects: '/clients-buyers',
  opportunities: '/clients',
  offers: '/offers',
  inAppMarketing: '/marketing',
  calendar: '/calendar',
  settings: '/settings',
  opportunitiesListing: '/opportunities',
  calls: '/calls',
  integrations: '/integrations',
};

export const PAGES_TITLES = {
  today: 'Today',
  communications: 'Communications Hub',
  mls: 'Properties',
  prospects: 'Prospects',
  clients: 'Clients',
  'clients-buyers': 'Clients',
  'clients-sellers': 'Clients',
  opportunities: 'Opportunities',
  leads: 'Leads',
  transactions: 'Transactions',
  contacts: 'Contacts',
  offers: 'Offers',
  marketing: 'Marketing',
  calendar: 'Calendar',
  settings: 'Settings',
  calls: 'Call Log',
  integrations: 'Integrations',
};
