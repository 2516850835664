import connectReducers from 'store/connectReducers';

import {
  SET_TEAM,
  SET_TEAMS,
  SET_TEAMS_COUNT,
  REMOVE_TEAM,
} from 'store/actionTypes/teams';

const initialState = {
  teams: {},
  teamsCount: 0,
};

const reducers = {
  [SET_TEAM]: (state, action) => {
    const team = action.payload;

    return {
      ...state,
      teams: {
        ...state.teams,
        [team.TeamID]: team,
      },
    };
  },

  [SET_TEAMS]: (state, action) => {
    const teams = action.payload;

    const updatedTeams = teams.reduce((updated, team) => {
      const { TeamID } = team;

      if (!state.teams[TeamID]) {
        updated[TeamID] = team; // eslint-disable-line no-param-reassign
        return updated;
      }

      const updatedTeam = {
        ...state.teams[TeamID],
        ...team,
      };

      updated[TeamID] = updatedTeam; // eslint-disable-line no-param-reassign
      return updated;
    }, {});

    return {
      ...state,
      teams: {
        ...state.teams,
        ...updatedTeams,
      },
    };
  },

  [SET_TEAMS_COUNT]: (state, action) => ({
    ...state,
    teamsCount: action.payload,
  }),

  [REMOVE_TEAM]: (state, action) => {
    const teams = { ...state.teams };
    delete teams[action.payload];

    return {
      ...state,
      teams,
    };
  },
};

function teamsReducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default teamsReducer;
