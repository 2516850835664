import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { Popconfirm } from 'antd';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Flex from 'ui/Flex';

import FollowUpData from 'sections/FollowUps/components/FollowUpData';

const noop = () => {};

const EditFollowUpModal = (props) => {
  const {
    theme,
    isVisible,
    reminderId,
    closeModal,
    deleteReminder,
  } = props;

  const renderFooter = useCallback(() => (
    <Flex
      fullWidth
      justifyContent="space-between"
    >
      <Popconfirm
        title="Are you sure?"
        onConfirm={deleteReminder}
        okText="Yes"
        cancelText="No"
      >
        <Button
          variant="secondary"
          onClick={noop}
        >
          Delete
        </Button>
      </Popconfirm>

      <Button
        onClick={closeModal}
      >
        Ok
      </Button>
    </Flex>
    ), [
    deleteReminder,
    closeModal,
  ]);

  return (
    <Modal
      theme={theme}
      width={600}
      destroyOnClose
      visible={isVisible}
      onCancel={closeModal}
      title="Follow Up Reminder"
      footer={renderFooter()}
    >
      <FollowUpData reminderId={reminderId} />
    </Modal>
  );
};

EditFollowUpModal.defaultProps = {
  theme: null,
};

const {
  bool,
  func,
  shape,
  number,
} = PropTypes;

EditFollowUpModal.propTypes = {
  isVisible: bool.isRequired,
  reminderId: number.isRequired,
  theme: shape(),
  closeModal: func.isRequired,
  deleteReminder: func.isRequired,
};

export default EditFollowUpModal;
