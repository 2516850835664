import {
  optimizedRealtorUnansweredQuestionActions,
} from 'store/actions/realtorUnansweredQuestions';

import withAttachmentsList from 'shared/hocs/withAttachmentsList';

const withUnansweredQuestionAttachments = (Component) => withAttachmentsList(38)({
    callbacks: {
      onAfterUpload: optimizedRealtorUnansweredQuestionActions.getAttachments,
      onAfterRemove: optimizedRealtorUnansweredQuestionActions.getAttachments,
    },
    selectorPath: 'realtorUnansweredQuestions.attachments',
  })(Component);

export default withUnansweredQuestionAttachments;
