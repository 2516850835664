import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

// eslint-disable-next-line consistent-return
const create = (data) => async (dispatch) => {
  try {
    const response = await requests.realtor.repairRequests.create({
      ...data,
    });

    return response.data.id;
  } catch (error) {
    callAlert.error('Could not create');
    console.error(error);
  }
};

export default create;
