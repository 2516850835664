import { SET_CALLER } from 'store/actionTypes/call';

const setCaller = (payload) => (dispatch) => {
  dispatch({
    type: SET_CALLER,
    payload,
  });
};

export default setCaller;
