import store from 'store';

import { uploadAttachment } from 'store/actions/attachmentsV2';

const uploadAgreementUrl = (offerId) => async (linkUrl) => {
    const attachmentsData = {
      objectId: offerId,
      objectType: 31,
      attachmentType: 12,
      linkUrl,
    };

  try {
    await store.dispatch(uploadAttachment(attachmentsData));
  } catch (error) {
    console.error(error);
  }
};

export default uploadAgreementUrl;
