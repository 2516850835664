import getDatabaseName from 'shared/utils/getDatabaseName';

const stubHandle = (action) => (data) => {
  console.log(action, JSON.stringify(data));
};

export default (socket) => (phoneNumber) => {
  const dbName = getDatabaseName();

  const send = `${dbName}:sms:send:${phoneNumber}`; // send from
  const receive = `${dbName}:sms:receive:${phoneNumber}`; // receive to

  socket.on(send, stubHandle('send'));
  socket.on(receive, stubHandle('receive'));
};
