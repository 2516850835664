import React, {
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { usePapaParse } from 'react-papaparse';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import readFile from 'sections/DataImport/utils/readFile';

import Button from 'ui/Button';

const FileItem = (props) => {
  const {
    name,
    fileId,
    openImportModal,
  } = props;

  const {
    readString,
  } = usePapaParse();

  const {
    setUploadedFileId,
    setUploadedFileName,
    getUploadedFile,
    setPreviewTableData,
    setDelimiter,
  } = useContext(DataImportContext);

  const onClick = useCallback(() => {
    setUploadedFileId(fileId);
    setUploadedFileName(name);
    getUploadedFile(fileId, (file) => {
      readFile({
        readString,
        setPreviewTableData,
        setDelimiter,
      })(file);
    });
    openImportModal();
  }, [
    setUploadedFileId,
    openImportModal,
    fileId,
    name,
    setPreviewTableData,
    setDelimiter,
  ]);

  return (
    <Button
      variant="secondary"
      icon="file"
      noWrap
      onClick={onClick}
    >
      {name}
    </Button>
  );
};

const {
  string,
  func,
} = PropTypes;

FileItem.propTypes = {
  name: string.isRequired,
  fileId: string.isRequired,
  openImportModal: func.isRequired,
};

export default FileItem;
