import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Flex from 'ui/Flex';

import ReportModal from 'shared/modals/ReportModal';
import DefaultPage from 'components/ErrorPages/DefaultPage';
import { ERROR_TYPES } from 'store/actions/errors';
import { error } from 'constants';
import './style.scss';

const buttonBlockStyles = {
  marginTop: 16
}

class InternalErrorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { reportError } = this.props;

    reportError && reportError(ERROR_TYPES.INTERNAL_ERROR);
  }

  reload = () => {
    const { 
      onBeforeReload,
      onAfterReload
    } = this.props

    onBeforeReload && onBeforeReload()

    window.location.reload(true);

    onAfterReload && onAfterReload()
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  report = async () => {
    const {
      reportError,
    } = this.props;

    this.toggleModal();

    reportError && await reportError();
  }

  renderButtons = () => (
    <Flex
      spacing={1}
      style={buttonBlockStyles}
    >
      <Button
        variant="primary"
        onClick={this.reload}
      >
        Reload
      </Button>
      <Button
        variant="primary"
        onClick={this.report}
      >
        Report
      </Button>
    </Flex>
  )

  render() {
    const {
      isOpen,
    } = this.state;

    const {
      hasFooter,
      image,
      logo,
      subtitle,
      title,
    } = error.INTERNAL_ERROR;

    return (
      <>
        <DefaultPage
          hasFooter={hasFooter}
          image={image}
          logo={logo}
          subtitle={subtitle}
          title={title}
          renderButtons={this.renderButtons}
        />
        <ReportModal
          isOpen={isOpen}
          toggleModal={this.toggleModal}
          handleReload={this.reload}
        />
      </>
    );
  }
}


InternalErrorComponent.defaultProps = {
  reportError: () => {},
};

const {
  func,
} = PropTypes;

InternalErrorComponent.propTypes = {
  reportError: func,
};

export default InternalErrorComponent;
