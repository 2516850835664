import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

import { AntdThemeProvider } from './styledItems';

const getMuiTheme = (theme) => createMuiTheme({
  palette: {
    primary: {
      main: theme.palette.highlight,
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.focus,
        color: theme.palette['main-text-inversed'],
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 10,
        boxShadow: theme.shadows.dropdown,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.palette.focus,
        borderRadius: 10,
      },
      day: {
        '&:hover': {
          backgroundColor: theme.palette['background-flat'],
          borderRadius: 10,
        },
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: theme.palette['background-flat'],
      },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: theme.palette['background-flat'],
      },
    },
  },
});

const OurThemeProvider = ({ children, theme }) => (
  <StyledThemeProvider theme={theme}>
    <MuiThemeProvider theme={getMuiTheme(theme)}>
      <AntdThemeProvider theme={theme}>
        { children }
      </AntdThemeProvider>
    </MuiThemeProvider>
  </StyledThemeProvider>
);

const {
  element,
  shape,
} = PropTypes;

OurThemeProvider.propTypes = {
  children: element.isRequired,
  theme: shape().isRequired,
};

export default OurThemeProvider;
