import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Typography from 'ui/Typography';

import formatOnMarketTime from 'sections/MLS/modules/PropertiesTable/formatOnMarketTime';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  const dom = property?.on_market_since ? `${formatOnMarketTime(property.on_market_since)} ${formatOnMarketTime(property?.on_market_since) > 1 ? 'days' : 'day'}` : '—';

  return {
    variant: 'title3',
    weight: 500,
    children: `DOM: ${dom}`,
    noMargin: true,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Typography);
