import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tutorial from '../shared/Tutorial';

import ArrowIcon from '../../../assets/images/tickets-tutorial-arrow.png';
import tutorialRoutes from '../tutorialRoutes';

import './TicketsTutorial.scss';

const TicketsTutorial = (props) => {
  const finishTutorial = useCallback(() => {
    const {
      endTutorial,
      hideTutorialLayer,
      history: {
        push,
      },
      setStepIndex,
    } = props;
    endTutorial();
    setStepIndex(0);
    closeTicket();
    hideTutorialLayer();
    push('/customer-service');
  }, []);

  const openTicket = () => {
    const {
      addTutorialTicket,
      showTicketModal,
    } = props;
    addTutorialTicket();
    showTicketModal(true);
  };

  const closeTicket = () => {
    const {
      showTicketModal,
    } = props;
    showTicketModal(false);
  };

  const goToStep = useCallback((index) => {
    const {
      startTutorial,
      endTutorial,
      setStepIndex,
      history: {
        push,
      },
      steps,
    } = props;

    const arrow = document.querySelector('.tutorial-arrows').classList;

    const stepActions = {
      'tutorial-create-queues': () => {
        closeTicket();
        push('/customer-service/queue-setup');
        arrow.remove('active');
        window.history.pushState(null, null, tutorialRoutes.tickets.createQueues);
      },
      'tutorial-agent-submit-ticket': () => {
        closeTicket();
        push('/customer-service/my-tickets');
        arrow.remove('active');
        window.history.pushState(null, null, tutorialRoutes.tickets.agentSubmitTicket);
      },
      'tutorial-customer-ticket-submit': () => {
        endTutorial();
        closeTicket();
        push('/company-settings/company-information/general');
        setTimeout(() => {
          startTutorial();
        }, 1500);
        arrow.remove('active');
        window.history.pushState(null, null, tutorialRoutes.tickets.customerTicketSubmit);
      },
      'tutorial-assign-tickets': () => {
        endTutorial();
        closeTicket();
        push('/customer-service/ticket-assignment');
        startTutorial();
        arrow.add('active');
        window.history.pushState(null, null, tutorialRoutes.tickets.assignTickets);
      },
      'tutorial-process-ticket': () => {
        push('/customer-service/my-tickets');
        endTutorial();
        openTicket();
        setTimeout(() => {
          startTutorial();
        }, 1500);
        arrow.remove('active');
        window.history.pushState(null, null, tutorialRoutes.tickets.processTicket);
      },
    };

    const currentStep = steps[index].name;

    stepActions[currentStep]();

    setStepIndex(index);
  }, []);

  const {
    stepName,
    steps,
  } = props;

  return (
    <div className="tickets-tutorial">
      <Tutorial
        className="tickets-tutorial"
        stepName={stepName}
        steps={steps}
        goToStep={goToStep}
        finishTutorial={finishTutorial}
        name="Tickets"
      />
      <div className="tutorial-arrows">
        <img className="left-arrow" src={ArrowIcon} width="120" alt="pointing arrow" />
        <img className="right-arrow" src={ArrowIcon} width="120" alt="pointing arrow" />
      </div>
    </div>
  );
};

const {
  any,
  string,
  func,
  object,
  arrayOf,
} = PropTypes;

TicketsTutorial.defaultProps = {
  stepName: '',
};

TicketsTutorial.propTypes = {
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  startTutorial: func.isRequired,
  endTutorial: func.isRequired,
  hideTutorialLayer: func.isRequired,
  addTutorialTicket: func.isRequired,
  showTicketModal: func.isRequired,
  stepName: string,
  setStepIndex: func.isRequired,
  steps: arrayOf(any).isRequired,
};

export default TicketsTutorial;
