import requests from 'api/requests';

import setOrder from './setOrder';

const getOrderById = (id) => async (dispatch) => {
  try {
    const response = await requests.orders.getById(id);
    dispatch(setOrder(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOrderById;
