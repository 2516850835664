import requests from 'api/requests';

import setEmployeeTasksSummaryForPeriod from './setEmployeeTasksSummaryForPeriod';

const getEmployeeTasksSummaryForPeriod = (
  employeeId,
  section,
) => async (dispatch) => {
  const {
    period,
    startDate,
    endDate,
  } = section;

  try {
    const summaryResponse = await requests.tasks
      .getEmployeeTasksSummaryForPeriod(employeeId)(startDate, endDate);

    dispatch(setEmployeeTasksSummaryForPeriod(employeeId, period)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getEmployeeTasksSummaryForPeriod;
