import {
  SET_FULFILLMENT_PRODUCT_LINE_ITEMS,
} from 'store/actionTypes/fulfillments';

const setFulfillmentProductLineItems = (fulfillmentId) => (productId) => (status) => (items) => ({
  type: SET_FULFILLMENT_PRODUCT_LINE_ITEMS,
  payload: {
    fulfillmentId,
    productId,
    items,
    status,
  },
});

export default setFulfillmentProductLineItems;
