import {
  SET_ACTIVE_MEETING_PARTICIPANTS,
} from 'store/actionTypes/video-chat';

const setActiveMeetingParticipants = (payload) => ({
  type: SET_ACTIVE_MEETING_PARTICIPANTS,
  payload,
});

export default setActiveMeetingParticipants;
