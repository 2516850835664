import {
  SET_MODAL_OPEN,
} from 'store/actionTypes/modals';

import getModalCallbacks from './getModalCallbacks';

const openModal = (modal) => (modalOptions = {}, contentOptions = {}) => (dispatch) => {
  const modalCallbacks = getModalCallbacks(modalOptions);

  modalCallbacks.onBeforeOpen();

  dispatch({
    type: SET_MODAL_OPEN,
    modal,
    payload: {
      ...modalOptions,
      onBeforeClose: modalCallbacks.onBeforeClose,
      onAfterClose: modalCallbacks.onAfterClose,
      modalProps: modalOptions?.modalProps || {},
      contentOptions,
    },
  });

  modalCallbacks.onAfterOpen();
};

export default openModal;
