import requests from 'api/requests';

import setLeadsObjectsForFilter from './setLeadsObjectsForFilter';
import setLeadsObjectsCountForFilter from './setLeadsObjectsCountForFilter';

const getAllLeadsObjectsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.leads.getAll({
      offset,
      limit,
      ...filter,
    });

    dispatch(setLeadsObjectsForFilter(filterId)(response.data.items));
    dispatch(setLeadsObjectsCountForFilter(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getAllLeadsObjectsForFilter;
