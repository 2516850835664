export const CHAT_DRAWER_VISIBLE_ON = 'CHAT_DRAWER_VISIBLE_ON';
export const CHAT_DRAWER_VISIBLE_OFF = 'CHAT_DRAWER_VISIBLE_OFF';

export const SET_CHATS = 'SET_CHATS';
export const SET_CHAT = 'SET_CHAT';
export const DELETE_CHAT = 'DELETE_CHAT';
export const SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID';

export const APPEND_CHAT_MESSAGE = 'APPEND_CHAT_MESSAGE';
export const SET_CHAT_THREAD = 'SET_CHAT_THREAD';
export const SET_NEW_UNREAD_MESSAGE_COUNT = 'SET_NEW_UNREAD_MESSAGE_COUNT';

export const CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_ON = 'CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_ON';
export const CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_OFF = 'CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_OFF';

export const SHOW_DELETE_CHAT_WARNING = 'SHOW_DELETE_CHAT_WARNING';
export const HIDE_DELETE_CHAT_WARNING = 'HIDE_DELETE_CHAT_WARNING';

export const SHOW_ADD_EMPLOYEE_MODAL = 'SHOW_ADD_EMPLOYEE_MODAL';
export const HIDE_ADD_EMPLOYEE_MODAL = 'HIDE_ADD_EMPLOYEE_MODAL';
