import { connect } from 'react-redux';

import searchContact from 'store/actions/realtorContacts/searchContact';

import ContactsSearch from 'shared/components/UI/RealtorMasterSearch/ContactsSearch';

const mapDispatchToProps = (dispatch) => ({
  onSearch: async (searchQuery) => {
    if (!searchQuery) {
      return [];
    }
    const result = await dispatch(searchContact(searchQuery));
    return result;
  },
});

export default connect(null, mapDispatchToProps)(ContactsSearch);
