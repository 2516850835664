import apiConnector, { endpoints } from 'api';

const getTicketById = async (ticketId) => {
  const endpoint = `${endpoints.client.v2.tickets}/${ticketId}`;

  try {
    const result = await apiConnector.client.get(endpoint);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getTicketById;
