import styled from 'styled-components';

export const TextWrapper = styled.div`
  margin-top: 15px;
`;

export const Extra = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const NoteText = styled.div`
  padding: 12px;
  min-height: 90px;
  border: solid 1px #e1e1e1;
  border-radius: 5px;
  color: #172b4d;
  font-size: 14px;
`;
