import {
  SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT,
} from 'store/actionTypes/crm';

const setLinkedObjectsSummaries = (contactId, summaries) => ({
  type: SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT,
  payload: {
    contactId,
    summaries,
  },
});

export default setLinkedObjectsSummaries;
