import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.crmLeadsFilter;
const selectedLeads = (state) => state.crmLeads.selectedLeadRows;
const selectedLeadsExceptions = (state) => state.crmLeads.selectedLeadRowsExceptions;
const leadsByFilterCount = (state) => state.crmLeads.leadsByFilterCount;

export default createSelector(
  filterObj,
  selectedLeads,
  selectedLeadsExceptions,
  leadsByFilterCount,
  (filter, selectedIds, selectedExceptionsIds, totalCountObj) => {
    if (selectedIds !== 'all') return selectedIds.length;

    const filterId = stableStringify(filter);
    const totalCount = totalCountObj[filterId];

    return totalCount - selectedExceptionsIds.length;
  },
);
