import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Divider from 'ui/HorizontalDivider';
import Typography from 'ui/Typography';
import Container from 'ui/Container';
import Item from 'ui/Item';
import ItemContainer from 'ui/ItemContainer';
import EditableArea from 'ui/EditableArea';
import Textfield from 'ui/Textfield';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import SocialLinks from 'sections/Contacts/modules/SocialLinks';
import SpecialStatuses from 'sections/Contacts/modules/SpecialStatuses';
import Birthday from 'sections/Contacts/modules/Birthday';
import ContactLabelsRaw from 'sections/Contacts/modules/ContactLabels';

import AddressInput from 'shared/inputs/AddressInput';
import withInlineLabel from 'shared/hocs/withInlineLabel';
import withInlineLabel2 from 'shared/hocs/withInlineLabelV2';

const LabeledAdressInput = withInlineLabel(AddressInput);
const ContactLabels = withInlineLabel2(ContactLabelsRaw);

const AddressFieldWrapper = styled.div`
  .places-autocomplete {
    width: 100%;
  }
`;

const Contact = (props) => {
  const {
    contactId,
    firstName,
    lastName,
    email,
    phone,
    socialLinks,
    addressId,
    onChange,
  } = props;

  return (
    <Container spacing={6}>

      <ItemContainer
        xs={12}
        spacing={2}
      >
        <Item>
          <ContactAvatar
            contactId={contactId}
          />
        </Item>

        <ItemContainer
          xs={8}
          spacing={1}
          containerProps={{
            alignItems: 'center',
          }}
        >
          <Item>
            <EditableArea
              getEditComponent={(onBlur) => (
                <Textfield
                  value={firstName}
                  onChange={onChange('first_name')}
                  onBlur={onBlur}
                  autoWidth
                  focusOnRender
                />
              )}
            >
              <Typography
                variant="title1"
                noMargin
                multilineEllipsis
              >
                {firstName}
              </Typography>
            </EditableArea>
          </Item>
          <Item>
            <EditableArea
              getEditComponent={(onBlur) => (
                <Textfield
                  value={lastName}
                  onChange={onChange('last_name')}
                  onBlur={onBlur}
                  autoWidth
                  focusOnRender
                />
              )}
            >
              <Typography
                variant="title1"
                noMargin
                multilineEllipsis
              >
                {lastName}
              </Typography>
            </EditableArea>
          </Item>

          <Item xs={12}>
            <DecoratedFieldWrapper
              iconName="mail"
              iconType="feather"
              size="sm"
              variant="focus"
            >
              <EditableArea
                getEditComponent={(onBlur) => (
                  <Textfield
                    value={email || ''}
                    onChange={onChange('email_primary')}
                    onBlur={onBlur}
                    icon="mail"
                    focusOnRender
                  />
                )}
              >
                <Typography noMargin>
                  {email || '—'}
                </Typography>
              </EditableArea>
            </DecoratedFieldWrapper>
          </Item>

          <Item xs={12}>
            <DecoratedFieldWrapper
              iconName="phone"
              iconType="feather"
              variant="focus"
              size="sm"
            >
              <EditableArea
                getEditComponent={(onBlur) => (
                  <Textfield
                    value={phone || ''}
                    onChange={onChange('phone_primary')}
                    onBlur={onBlur}
                    icon="phone"
                    mask="phone"
                    focusOnRender
                  />
                )}
              >
                <Typography noMargin>
                  {phone || '—'}
                </Typography>
              </EditableArea>
            </DecoratedFieldWrapper>
          </Item>

          <Item xs={12}>
            <DecoratedFieldWrapper
              iconName="gift"
              iconType="feather"
              variant="focus"
              size="sm"
            >
              <Birthday
                contactId={contactId}
              />
            </DecoratedFieldWrapper>
          </Item>

        </ItemContainer>

        <Item xs={12}>
          <AddressFieldWrapper>
            <LabeledAdressInput
              label="Primary Address:"
              handleChange={onChange('address_id')}
              addressId={addressId}
              variant="inline"
              inline
            />
          </AddressFieldWrapper>
        </Item>

        <Item xs={12}>
          <ContactLabels
            contactId={contactId}
            label="Labels:"
            alignItems="center"
            labelProps={{
              weight: 500,
              noMargin: true,
              style: { marginRight: 10 },
            }}
          />
        </Item>

        <Item xs={12}>
          <Divider marginTop={0} marginBottom={7} />
          <SocialLinks
            contactId={contactId}
            firstName={firstName}
            links={socialLinks}
          />
          <Divider marginTop={3} marginBottom={0} />
        </Item>

        <Item xs={12}>
          <SpecialStatuses contactId={contactId} />
        </Item>
      </ItemContainer>
    </Container>
  );
};

Contact.defaultProps = {
  contactId: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  addressId: null,
  socialLinks: [
    {
      name: 'facebook',
      url: null,
      icon: ['fab', 'facebook-f'],
    },
    {
      name: 'twitter',
      url: null,
      icon: ['fab', 'twitter'],
    },
    {
      name: 'linkedin',
      url: null,
      icon: ['fab', 'linkedin-in'],
    },
    {
      name: 'instagram',
      url: null,
      icon: ['fab', 'instagram'],
    },
    {
      name: 'website',
      url: null,
      icon: 'globe',
    },
  ],
};

const {
  number,
  string,
  arrayOf,
  shape,
  oneOfType,
  func,
} = PropTypes;

Contact.propTypes = {
  contactId: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  addressId: number,
  socialLinks: arrayOf(shape({
    name: string,
    url: string,
    icon: oneOfType([
      arrayOf(string),
      string,
    ]),
  })),
  onChange: func.isRequired,
};

export default Contact;
