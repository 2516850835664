import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getProcesses from './getProcesses';
import getProcessById from './getProcessById';
import getProcessAttachments from './getProcessAttachments';

import createProcess from './createProcess';

import setSelectedProcess from './setSelectedProcess';

import updateProcess from './updateProcess';

import createProcessForSelectedFulfillment from './createProcessForSelectedFulfillment';

export { getProcesses };
export { getProcessById };
export { getProcessAttachments };

export { createProcess };

export { setSelectedProcess };

export { updateProcess };

export { createProcessForSelectedFulfillment };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedProcessesActions = {
  getProcessById: memoizeDebounce(
    (id) => store.dispatch(getProcessById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getProcessAttachments: memoizeDebounce(
    (fulfillmentId) => store.dispatch(getProcessAttachments(fulfillmentId)),
    debounceDelay,
    debounceOptions,
  ),
};
