import {
  SET_REALTOR_PROSPECTS,
} from 'store/actionTypes/realtorProspects';

const setProspects = (prospects) => ({
  type: SET_REALTOR_PROSPECTS,
  payload: prospects,
});

export default setProspects;
