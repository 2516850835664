import React, {
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Modal from 'ui/Modal';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import Item from './Item';
import NewItemSection from './NewItemSection';

const EditModal = (props) => {
  const {
    title,
    theme,
    isVisible,
    closeModal,
    items,
    onChange,
    onDelete,
    onCreate,
  } = props;

  return (
    <Modal
      theme={theme}
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      title={title}
      footer={[
        <Button
          variant="primary"
          onClick={closeModal}
        >
          Ok
        </Button>,
      ]}
    >
      <Grid container spacing={6}>
        {
          items.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Item
                id={item.id}
                title={item.title}
                description={item.description}
                onChange={onChange}
                onDelete={onDelete}
              />
            </Grid>
          ))
        }

        <NewItemSection onCreate={onCreate} />
      </Grid>
    </Modal>
  );
};

EditModal.defaultProps = {
  isVisible: false,
  items: [],
  theme: null,
  title: '',
};

const {
  arrayOf,
  shape,
  string,
  bool,
  number,
  func,
} = PropTypes;

EditModal.propTypes = {
  isVisible: bool,
  items: arrayOf(shape({
    id: number,
    title: string,
    description: string,
  })),
  theme: shape(),
  title: string,
  closeModal: func.isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired,
  onCreate: func.isRequired,
};

export default EditModal;
