import { connect } from 'react-redux';

import OfferDetails from './OfferDetails';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  const {
    offerId,
  } = ownProps;

  return {
    offerId,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
