import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchContactInput from './SearchContactInput';

const mapStateToProps = (state, ownProps) => {
  const {
    selectedContactsIds,
  } = ownProps;

  const selectedContacts = selectedContactsIds.map((contactId) => selectors.realtorContacts.byId(state, contactId));

  return {
    selectedContacts,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactInput);
