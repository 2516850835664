import React, {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

import OffersContext from 'sections/OffersV2/context/OffersContext';

const OffersProvider = (props) => {
  const {
    children, // eslint-disable-line react/prop-types
  } = props;

  const [offers, setOffers] = useState(null);

  const getAllOffers = async () => {
    const response = await requests.realtor.offers.get();
    setOffers(response.data);
  };

  const getOfferData = async (propertyId) => {
    const { data } = await requests.realtor.offers.getOffersByPropertyId(propertyId);
    return data;
  };

  useEffect(() => {
    getAllOffers();
  }, []);

  return (
    <OffersContext.Provider value={{
      getAllOffers,
      offers,
      getOfferData,
    }}
    >
      {children}
    </OffersContext.Provider>
  );
};

export default OffersProvider;
