import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  addContactRelations,
} from 'store/actions/realtorContacts';

import { callAlert } from 'ui/Alert';

import ContactLabels from './ContactLabels';

const mapStateToProps = (state, ownProps) => {
  const contact = selectors.realtorContacts.byId(state, ownProps.contactId);
  const allActiveLabels = selectors.dictionary.contactLabels(state);

  const allActiveLabelsIds = allActiveLabels.map((item) => item.id);

  const labels = contact?.labels || [];

  let primaryLabel = null;

  const selectedLabelsId = labels.reduce((accum, item) => {
    if (allActiveLabelsIds.includes(item.label_id)) {
      accum.push(item.label_id);

      if (item.is_primary > 0) {
        primaryLabel = item.label_id;
      }
    }

    return accum;
  }, []);

  const labelToShowInMainView = primaryLabel || selectedLabelsId[0];

  return {
    selectedLabelsId,
    primaryLabel,
    labelToShowInMainView,
    allContactLabels: allActiveLabels,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: async (labels) => {
    try {
      await dispatch(addContactRelations('labels')(ownProps.contactId)(labels));
      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactLabels);
