import React from 'react';

import Grid from '@material-ui/core/Grid';

export default ({
  xs,
  spacing,
  children,
  itemProps,
  containerProps,
}) => (
  <Grid
    xs={xs}
    {...itemProps}
    item
  >
    <Grid
      spacing={spacing}
      {...containerProps}
      container
    >
      {children}
    </Grid>
  </Grid>
);
