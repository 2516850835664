import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import ButtonBase from '@material-ui/core/ButtonBase';

export const Root = styled.div`
  display: flex;
  width: 100%;
`;

export const Button = withStyles({
  root: {
    background: 'white',
    width: 'auto',
    height: '70px',
    color: '#34495e',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: (props) => (props.active ? '#2ecc71' : '#e1e1e1'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1 1',
    margin: '0 5px',
    padding: '12px 10px',
  },
})(ButtonBase);

export default {
  Root,
  Button,
};
