import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './style.css';

const Toast = (props) => {
  const {
    isActive,
    msToClose,
    className,
    onClose,
    children,
  } = props;

  useEffect(() => {
    if (!isActive) return;

    const timerId = setTimeout(onClose, msToClose);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timerId);
  }, [isActive, onClose, msToClose]);

  return ReactDOM.createPortal(
    <div className={classNames('toast', { toast_active: isActive }, className)}>
      {children}
    </div>,
    document.getElementById('root'),
  );
};

Toast.defaultProps = {
  msToClose: 3000,
};

export default Toast;
