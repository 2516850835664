import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_REPAIR_REQUEST,
  SET_REALTOR_REPAIR_REQUESTS,
  SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER,
  SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER_COUNT,
  SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_OPEN,
  SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_CLOSED,
  SET_REALTOR_REPAIR_REQUEST_ATTACHMENTS,
  DELETE_REALTOR_REPAIR_REQUEST,
} from 'store/actionTypes/realtorRepairRequests';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  repairRequests: {},
  repairRequestsByFilter: {},
  repairRequestsByFilterCount: {},

  selectedRepairRequest: null,
  isRepairRequestNotesModalVisible: false,
};

const reducers = {
  [SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    repairRequestsByFilterCount: {
      ...state.repairRequestsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_REPAIR_REQUEST]: (state, action) => ({
    ...state,
    repairRequests: {
      ...state.repairRequests,
      [action.payload.id]: {
        ...action.payload,
        attachments: state.repairRequests[action.payload.id]?.attachments,
      },
    },
  }),

  [SET_REALTOR_REPAIR_REQUESTS]: (state, action) => ({
    ...state,
    repairRequests: {
      ...state.repairRequests,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      repairRequests,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(repairRequests),
    ]);

    const updatedState = {
      ...state,
      repairRequestsByFilter: {
        ...state.repairRequestsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_OPEN]: (state, action) => ({
    ...state,
    isRepairRequestNotesModalVisible: true,
    selectedRepairRequest: action.payload.repairRequestId,
  }),

  [SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_CLOSED]: (state) => ({
    ...state,
    isRepairRequestNotesModalVisible: false,
    selectedRepairRequest: null,
  }),

  [SET_REALTOR_REPAIR_REQUEST_ATTACHMENTS]: (state, action) => ({
    ...state,
    repairRequests: {
      ...state.repairRequests,
      [action.payload.id]: {
        ...state.repairRequests[action.payload.id],
        attachments: action.payload.attachments.map(({ attachmentId }) => attachmentId),
      },
    },
  }),

  [DELETE_REALTOR_REPAIR_REQUEST]: (state, action) => {
    const requestsArray = Object.keys(state.repairRequests).map((key) => state.repairRequests[key]);

    const newRequests = requestsArray.filter((requests) => requests.id !== action.payload);

    const updatedState = {
      ...state,
      repairRequests: {
        ...newRequests,
      },
    };

    return updatedState;
  },
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
