import { connect } from 'react-redux';
import requests from 'api/requests';

import {
  getLoggedUserProfile,
} from 'store/actions/realtorDirectory';

import RealtorProfile from './RealtorProfile';

const mapStateToProps = (state) => {
  const {
    realtorDirectory: {
      userProfile,
    },
  } = state;

  return {
    email: userProfile.email,
    fullName: userProfile.fullName || `${userProfile.firstName || ''} ${userProfile.lastName || ''}`,
    officeName: userProfile.officeName,
    isClaimed: userProfile.isClaimed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  claimProfile: async (email, name) => {
    try {
      await requests.realtor.realtorDirectory.claim(email, name);
    } catch (error) {
      console.error(error);
    }

    await dispatch(getLoggedUserProfile());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  claimProfile: async () => {
    await dispatchProps.claimProfile(stateProps.email, stateProps.fullName);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RealtorProfile);
