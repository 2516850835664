import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Spin,
} from 'antd';

import {
  isEmpty,
} from 'shared/utility';

import Grid from 'ui/Grid';

import InfiniteScroll from 'react-infinite-scroller';

import CenterBox from 'shared/components/UI/CenterBox';

import ContactItem from '../ContactItem';

import {
  ScrollContainer,
} from './styledItems';

const ContactsList = (props) => {
  const {
    contacts,
    loadMore,
    error,
    isScrollContainer,
    oneContactSelect,
    hasMore,
    selectedContacts,
    onAddContact,
    onRemoveContact,
    clearSearch,
  } = props;

  useEffect(() => {
    clearSearch();
    loadMore();

    return () => {
      clearSearch();
    };
  }, []);

  return (
    <ScrollContainer
      isScrollContainer={isScrollContainer}
    >
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<CenterBox><Spin /></CenterBox>}
        useWindow={false}
      >
        <Grid container spacing={2}>
          {
            contacts.map(({ key, id, role }) => (
              <Fragment key={key}>
                <Grid item xs={4}>
                  <ContactItem
                    error={error}
                    contactId={id}
                    role={role}
                    oneContactSelect={oneContactSelect}
                    isEmptyContactSelector={isEmpty(selectedContacts)}
                    isSelected={selectedContacts.includes(id)}
                    onAdd={onAddContact(id)}
                    onRemove={onRemoveContact(id)}
                  />
                </Grid>
              </Fragment>
            ))
          }
        </Grid>
      </InfiniteScroll>
    </ScrollContainer>
  );
};

ContactsList.defaultProps = {
  contacts: [],
  selectedContacts: [],
  hasMore: false,
};

const {
  number,
  bool,
  arrayOf,
  shape,
  func,
} = PropTypes;

ContactsList.propTypes = {
  error: bool.isRequired,
  isScrollContainer: bool.isRequired,
  contacts: arrayOf(shape({
    key: number,
    id: number,
  })),
  hasMore: bool,
  loadMore: func.isRequired,
  oneContactSelect: bool.isRequired,
  selectedContacts: arrayOf(number),
  onAddContact: func.isRequired,
  onRemoveContact: func.isRequired,
  clearSearch: func.isRequired,
};

export default ContactsList;
