import styled from 'styled-components';

export const Root = styled.div`
  width: 520px;
  padding: 10px 5px 15px;
`;

export const PrimaryValueContainer = styled.div`
  margin-bottom: 30px;
`;

export const CreateFieldContainer = styled.div`
  margin-top: 15px;
`;
