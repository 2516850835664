import React, {
  memo,
  useContext,
} from 'react';

import {
  EditorContent,
} from '@tiptap/react';

import Flex from 'ui/Flex';

import editorContext from './context';

const style = { width: '100%' };

const Content = (props) => {
  const { editor } = useContext(editorContext);

  return (
    // <Flex
    //   fullWidth
    // >
    <EditorContent
      style={style}
      editor={editor}
    />
    // </Flex>
  );
};

export default memo(Content);
