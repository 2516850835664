import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state, filter) => filter || state.callLogsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      sort_by: filter.sortBy,
      sort_direction: filter.sortDirection,
      search: filter.search,
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    },
  }),
);
