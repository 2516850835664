import React from 'react';
import PropTypes from 'prop-types';

import DropdownTextfield from 'ui/DropdownTextfield';

const ContactTitleSelect = (props) => {
  const {
    value,
    handleChange,
    options,
  } = props;

  return (
    <DropdownTextfield
      value={value}
      onChange={handleChange}
      options={options}
      label="Title"
      menuItemStyle={{ textTransform: 'capitalize' }}
      style={{ textTransform: 'capitalize' }}
    />
  );
};

ContactTitleSelect.defaultProps = {
  value: undefined,
};

const {
  func,
  number,
  arrayOf,
  shape,
  string,
} = PropTypes;

ContactTitleSelect.propTypes = {
  handleChange: func.isRequired,
  value: number,
  options: arrayOf(shape({
    value: number.isRequired,
    label: string.isRequired,
  })).isRequired,
};

export default ContactTitleSelect;
