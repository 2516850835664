import { connect } from 'react-redux';
import { getUser } from 'store/actions/users';

import Avatar from './Avatar';

const mapStateToProps = (state) => ({
  user: state.auth.loggedUser,
});

const mapDispatchToProps = {
  getUser: getUser('employee'),
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
