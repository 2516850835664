import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import AddendumChanges from 'sections/Transactions/modules/AddendumChanges';

const AddendumChangesModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    contentOptions,
  } = props;

  return (
    <Modal
      theme={theme}
      width={700}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Addendum change requests"
      footer={null}
    >
      <AddendumChanges
        {...contentOptions}
      />
    </Modal>
  );
};

AddendumChangesModal.defaultProps = {
  isVisible: false,
  theme: null,
  contentOptions: {},
};

const {
  func,
  bool,
  shape,
} = PropTypes;

AddendumChangesModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
  contentOptions: shape(),
};

export default AddendumChangesModal;
