import {
  useState,
  useCallback,
} from 'react';

import requests from 'api/requests';

const getConflicts = ({
  setIsLoading,
  setConflicts,
  setError,
}) => async (objectType, fileId, fieldMap) => {
  setIsLoading(true);

  try {
    const conflicts = await requests.dataImport.getFileConflicts(objectType)(fieldMap)(fileId);
    setConflicts(conflicts);
  } catch (error) {
    setError(error);
  } finally {
    setIsLoading(false);
  }
};

const useFileConflicts = () => {
  const [conflicts, setConflicts] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const reset = useCallback(() => {
    setConflicts(null);
    setIsLoading(null);
    setError(false);
  });

  const getFileConflicts = (objectType) => (fieldMap) => (fileId) => getConflicts({
    setConflicts,
    setIsLoading,
    setError,
  })(objectType, fileId, fieldMap);

  return {
    getFileConflicts,
    conflicts,
    isLoading,
    error,
    reset,
  };
};

export default useFileConflicts;
