import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => ({
  ...state.realtorInspectionsFilter,
  transactionId: state.realtorTransactions.selectedTransaction,
});

export default createSelector(
  filterObj,
  (state) => state.realtorTransactions.selectedTransaction,
  (filter, transactionId) => ({
    filterId: stableStringify(filter),
    filter: {
      transactionId,
    },
  }),
);
