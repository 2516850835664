import React, {
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import ContactsList from 'sections/Contacts/modals/AssignContactModal/ContactsList';

import context from 'sections/Contacts/modals/AssignContactModal/ContextProvider/context';

const AllContacts = (props) => {
  const {
    oneContactSelect,
    selectedContacts,
    onAddContact,
    onRemoveContact,
  } = props;

  const {
    contactsList,
    canLoadMoreContacts,
    loadMoreContacts,
  } = useContext(context);

  return (
    <ContactsList
      oneContactSelect={oneContactSelect}
      selectedContacts={selectedContacts}
      onAddContact={onAddContact}
      onRemoveContact={onRemoveContact}
      contactsList={contactsList}
      canLoadMoreContacts={canLoadMoreContacts}
      loadMoreContacts={loadMoreContacts}
    />
  );
};

AllContacts.defaultProps = {
  selectedContacts: [],
};

const {
  number,
  bool,
  arrayOf,
  func,
} = PropTypes;

AllContacts.propTypes = {
  oneContactSelect: bool.isRequired,
  selectedContacts: arrayOf(number),
  onAddContact: func.isRequired,
  onRemoveContact: func.isRequired,
};

export default AllContacts;
