import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Drawer as AntdDrawer,
} from 'antd';

const Drawer = (props) => {
  const {
    children,
    visible,
    hasBackdrop,
    placement,
    onClose,
    width,
    bodyStyle,
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const handleClose = useCallback(() => {
    onClose();
    setIsVisible(false);
  }, [
    onClose,
  ]);

  return (
    <AntdDrawer
      visible={isVisible}
      mask={hasBackdrop}
      placement={placement}
      onClose={handleClose}
      width={width}
      bodyStyle={bodyStyle}
      style={{ zIndex: 999 }}
    >
      {children}
    </AntdDrawer>
  );
};

Drawer.defaultProps = {
  visible: false,
  hasBackdrop: false,
  children: null,
  placement: 'right',
  width: null,
  bodyStyle: null,
};

const {
  arrayOf,
  bool,
  element,
  func,
  oneOf,
  oneOfType,
  string,
  number,
  shape,
} = PropTypes;

Drawer.propTypes = {
  visible: bool,
  hasBackdrop: bool,
  children: oneOfType([element, arrayOf(element)]),
  placement: oneOf(['top', 'right', 'bottom', 'left']),
  onClose: func.isRequired,
  width: oneOfType([string, number]),
  bodyStyle: shape(),
};

export default Drawer;
