import io from 'socket.io-client';

import { sympleteClientApiUrl } from 'paths';

const config = { transports: ['websocket'] };

const socket = io(sympleteClientApiUrl, config);

socket.on('connect', () => {
  const token = localStorage.getItem('token');

  if (token) {
    socket.emit('login', token);
  }
});

export {
  socket, // eslint-disable-line import/prefer-default-export
};
