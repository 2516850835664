import React from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Chip from 'ui/Chip';

import { Root } from './styledItems';

const TabComponent = (props) => {
  const {
    title,
    count,
  } = props;

  return (
    <Root>
      <Typography
        variant="title2"
        noMargin
      >
        {title}
      </Typography>

      {
        count || count === 0
        ? (
            <Chip variant="subtle">{count}</Chip>
          )
        : null
      }
    </Root>
  );
};

TabComponent.defaultProps = {
  title: null,
  count: null,
};

const {
  string,
  number,
} = PropTypes;

TabComponent.propTypes = {
  title: string,
  count: number,
};

export default TabComponent;
