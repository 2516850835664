import connectReducers from 'store/connectReducers';

import {
  SET_ASSIGN_INTERESTED_PARTY_MODAL_OPEN,
  SET_ASSIGN_INTERESTED_PARTY_MODAL_CLOSED,
} from 'store/actionTypes/realtorOpportunities';

const initialState = {
  isVisible: false,
  selectedContacts: [],
  onSave: null,
  contactDealRole: null,
};

const reducers = {
  [SET_ASSIGN_INTERESTED_PARTY_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    selectedContacts: action?.payload?.selectedContacts,
    onSave: action?.payload?.onSave,
    contactDealRole: action?.payload?.contactDealRole,
  }),

  [SET_ASSIGN_INTERESTED_PARTY_MODAL_CLOSED]: (state) => initialState,
};

function assignInterestedPartiesModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default assignInterestedPartiesModal;
