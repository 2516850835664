import apiConnector, { endpoints } from 'api';

/**
 * @typedef {'user' | 'externalCustomer' } UserTypes
 */

const markLogAsSeen = async (commentId) => {
  const endpoint = `${endpoints.client.logs}/seen/${commentId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default markLogAsSeen;
