/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment, react/prop-types */

import React from 'react';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

const withFieldDecorator = (defaultDecoratorProps = {}) => (Component) => (props) => {
  if (!props.decorated) {
    return <Component {...props} />;
  }

  return (
    <DecoratedFieldWrapper
      variant="highlight"
      {...defaultDecoratorProps}
      {...props.decoratorProps}
    >
      <Component
        {...props}
      />
    </DecoratedFieldWrapper>
  );
};

export default withFieldDecorator;
