import { connect } from 'react-redux';

import Representing from './Representing';

const mapStateToProps = (state, ownProps) => {
  const {
    representing,
  } = ownProps;

  return {
    contactId: representing.contactId,
  };
};

export default connect(mapStateToProps)(Representing);
