import updateSelectedLead from './updateSelectedLead';

const updateSelectedLeadPermissions = (permissionsGroup, permissions) => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser,
    },
  } = getState();

  const defaultPermissions = permissionsGroup === 'everyone'
    ? {
      employees: [],
      teams: [],
      offices: [],
    }
    : {
      employees: [
        loggedUser.EmployeeID, // logged user always retains access to the edited lead
      ],
      teams: [],
      offices: [],
    };

  const updatedItems = permissions[permissionsGroup] || [];

  const permissionsUpdate = permissionsGroup === 'everyone' || permissionsGroup === 'onlyMe'
    ? defaultPermissions
    : {
      ...defaultPermissions,
      [permissionsGroup]: [
        ...defaultPermissions[permissionsGroup],
        ...updatedItems,
      ],
    };

  const updateData = {
    permission_type: permissionsGroup === 'everyone' ? 1 : 2,
    permissions: {
      overwrite: permissionsUpdate,
    },
  };

  dispatch(updateSelectedLead(updateData));
};

export default updateSelectedLeadPermissions;
