/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */

/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import { useDebouncedCallback } from 'use-debounce';

import FormTextfield from 'shared/inputs/FormTextfield';

const AddressFilterInput = (props) => {
  const {
    control,
    searchMLS,
  } = props;

  const onSearch = useDebouncedCallback(async (value) => {
    if (!value) {
      return;
    }

    searchMLS();
  }, 800);

  const handleChange = useCallback((onChange) => (value) => {
    onChange(value);
    onSearch(value);
  }, []);

  return (
    <Controller
      name="filters.address"
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <FormTextfield
          label="Search or add by property address, area, state, zip code"
          fullWidth
          onChange={handleChange(field.onChange)}
        />
      )}
    />
  );
};

const {
  shape,
  func,
} = PropTypes;

AddressFilterInput.propTypes = {
  control: shape().isRequired,
  searchMLS: func.isRequired,
};

export default AddressFilterInput;
