import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertySeller from './PropertySeller';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    contactId: property?.seller,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: (contactId) => {
    dispatch(updateProperty(ownProps.propertyId)({ seller: contactId }));
  },
  onRemove: () => {
    dispatch(updateProperty(ownProps.propertyId)({ seller: null }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertySeller);
