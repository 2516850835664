import React, {
  // useState,
  // useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';
// import Button from 'ui/Button';

import CallLogsSearchFilter from 'sections/Calls/modules/CallLogsSearchFilter';
import CallLogsCreationDateFilter from 'sections/Calls/modules/CallLogsCreationDateFilter';
import CallTriggerButton from 'sections/Calls/modules/CallTriggerButton';
import CallLogTable from 'sections/Calls/modules/CallLogTable';

import { Root } from './styledItems';

const CallLog = (props) => {
  const {
  } = props;

  return (
    <Root>
      <Flex spacing={2} flexWrap="wrap">
        <Flex fullWidth spacing={3} alignItems="center">
          <FlexItem xs={11}>
            <CallLogsSearchFilter />
          </FlexItem>

          <FlexItem xs={9}>
            <CallLogsCreationDateFilter />
          </FlexItem>

          <FlexItem>
            <Flex
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <FlexItem>
                <CallTriggerButton />
              </FlexItem>
              {/* <FlexItem>
                <Button
                  icon="plus"
                />
              </FlexItem> */}
            </Flex>
          </FlexItem>
        </Flex>

        <Elevation
          rounded="m"
          // padding="m"
          hideOverflow
        >
          <CallLogTable />
        </Elevation>
      </Flex>
    </Root>
  );
};

const {
  // func,
} = PropTypes;

CallLog.propTypes = {
};

export default CallLog;
