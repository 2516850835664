import {
  SET_TASKS_SUMMARY_FOR_FILTER,
} from 'store/actionTypes/tasks';

const setTasksSummaryForFilter = (filterId) => (tasksSummary) => ({
  type: SET_TASKS_SUMMARY_FOR_FILTER,
  payload: {
    filterId,
    tasksSummary,
  },
});

export default setTasksSummaryForFilter;
