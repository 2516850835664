import apiConnector, { endpoints } from 'api';

const editComment = (commentId) => async (commentBody) => {
  const endpoint = `${endpoints.client.comments}/item/${commentId}`;

  try {
    const result = await apiConnector.client.put(endpoint, commentBody);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default editComment;
