import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import FeedbackSentimentInput from 'sections/MatchingProperties/modules/FeedbackSentimentInput';
import FeedbackNotes from 'sections/MatchingProperties/modules/FeedbackNotes';

const FeedbackForm = (props) => {
  const {
    matchId,
    defaultView,
  } = props;

  return (
    <Flex
      flexDirection="column"
      fullWidth
      spacing={3}
    >
      <Flex
        fullWidth
        spacing={1}
        alignItems="center"
      >
        <Typography
          variant="title3"
          noMargin
          weight="bold"
        >
          General Assessment:
        </Typography>
        <FeedbackSentimentInput
          matchId={matchId}
          defaultView={defaultView}
        />
      </Flex>
      <FlexItem fullWidth>
        <Typography
          variant="title3"
          weight="bold"
        >
          Notes:
        </Typography>
        <FeedbackNotes
          matchId={matchId}
        />
      </FlexItem>
    </Flex>
  );
};

FeedbackForm.defaultProps = {
  defaultView: 'edit',
};

const {
  number,
  oneOf,
} = PropTypes;

FeedbackForm.propTypes = {
  matchId: number.isRequired,
  defaultView: oneOf(['display', 'edit']),
};

export default FeedbackForm;
