import React from 'react';

import TasksTutorial from './TasksTutorial';
import TicketsTutorial from './TicketsTutorial';
import SalesTutorial from './SalesTutorial';
import HrTutorial from './HrTutorial';
import GeneralTutorial from './GeneralTutorial';

import tutorialRoutes from './tutorialRoutes';

const handleUrl = {
  tasks: {
    [tutorialRoutes.tasks.createNewTask]: {
      name: 'tutorial-create-new-task',
    },
    [tutorialRoutes.tasks.createNewSubtask]: {
      name: 'tutorial-create-new-subtask',
    },
    [tutorialRoutes.tasks.archiveTask]: {
      name: 'tutorial-archive-task',
    },
    [tutorialRoutes.tasks.completeTask]: {
      name: 'tutorial-complete-task',
    },
    [tutorialRoutes.tasks.createTeams]: {
      name: 'tutorial-create-teams',
    },
    [tutorialRoutes.tasks.assignTasks]: {
      name: 'tutorial-assign-tasks',
    },
    returnComponent: (name) => <TasksTutorial stepName={name} />,
  },
  tickets: {
    [tutorialRoutes.tickets.createQueues]: {
      name: 'tutorial-create-queues',
    },
    [tutorialRoutes.tickets.agentSubmitTicket]: {
      name: 'tutorial-agent-submit-ticket',
    },
    [tutorialRoutes.tickets.customerTicketSubmit]: {
      name: 'tutorial-customer-ticket-submit',
    },
    [tutorialRoutes.tickets.assignTickets]: {
      name: 'tutorial-assign-tickets',
    },
    [tutorialRoutes.tickets.processTicket]: {
      name: 'tutorial-process-ticket',
    },
    returnComponent: (name) => <TicketsTutorial stepName={name} />,
  },
  sales: {
    [tutorialRoutes.sales.createSalesTeam]: {
      name: 'tutorial-create-sales-team',
    },
    [tutorialRoutes.sales.createNewLead]: {
      name: 'tutorial-create-new-lead',
    },
    [tutorialRoutes.sales.createNewDeal]: {
      name: 'tutorial-create-new-deal',
    },
    // [tutorialRoutes.sales.takeUnassignedLeads]: {
    //   name: 'tutorial-take-unassigned-leads',
    // },
    [tutorialRoutes.sales.updatePitch]: {
      name: 'tutorial-update-pitch',
    },
    [tutorialRoutes.sales.createMeeting]: {
      name: 'tutorial-create-meeting',
    },
    [tutorialRoutes.sales.changePitchProgress]: {
      name: 'tutorial-change-pitch-progress',
    },
    [tutorialRoutes.sales.closeDeal]: {
      name: 'tutorial-close-deal',
    },
    returnComponent: (name) => <SalesTutorial stepName={name} />,
  },
  hr: {
    [tutorialRoutes.hr.addEmployees]: {
      name: 'tutorial-add-employees',
    },
    [tutorialRoutes.hr.createTeams]: {
      name: 'tutorial-create-teams',
    },
    [tutorialRoutes.hr.processAttendance]: {
      name: 'tutorial-process-attendance',
    },
    [tutorialRoutes.hr.attendanceReports]: {
      name: 'tutorial-attendance-reports',
    },
    [tutorialRoutes.hr.clockInOut]: {
      name: 'tutorial-clock-in-out',
    },
    [tutorialRoutes.hr.updateEmployeeRecord]: {
      name: 'tutorial-update-employee-records',
    },
    returnComponent: (name) => <HrTutorial stepName={name} />,
  },
  general: {
    [tutorialRoutes.general.resetPassword]: {
      name: 'tutorial-reset-password',
    },
    [tutorialRoutes.general.deleteUser]: {
      name: 'tutorial-delete-user',
    },
    [tutorialRoutes.general.removeLicenses]: {
      name: 'tutorial-assign-remove-licenses',
    },
    [tutorialRoutes.general.createUser]: {
      name: 'tutorial-create-user',
    },
    [tutorialRoutes.general.buyCancelLicenses]: {
      name: 'tutorial-buy-cancel-licenses',
    },
    returnComponent: (name) => <GeneralTutorial stepName={name} />,
  },
};

export default handleUrl;
