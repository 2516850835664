import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Elevation from 'ui/Elevation';
import Divider from 'ui/Divider';
import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';

import ContactInfoConcise from 'sections/Contacts/modules/ContactInfoConcise';
import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';

import Comments from 'sections/Contacts/modules/CommentsV2';

import ContactLogButton from 'sections/ContactLogs/ContactLogButton';

import Call from 'sections/Contacts/modules/Call';
import EmailButton from 'sections/Contacts/modules/EmailButton';

import useModal from 'ui/hooks/useModal';

const sectionStyle = {
  padding: 10,
};

const avatarProps = {
  label: 'seller',
};

const PropertySeller = (props) => {
  const {
    contactId,
    onAdd,
    onRemove,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const onSave = useCallback((selectedContacts) => {
    onAdd(selectedContacts?.addedContacts?.[0]);
    closeModal();
  }, [
    onAdd,
    closeModal,
  ]);

  const content = !contactId
    ? (
      <Flex
        fullWidth
        fullHeight
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            tag="h2"
            variant="title2"
          >
            Add seller
          </Typography>
          <Tooltip
            title="Add seller"
          >
            <Button
              variant="primary"
              icon="plus"
              onClick={openModal}
            />
          </Tooltip>
        </Flex>
      </Flex>
    )
    : (
      <>
        <div style={sectionStyle}>
          <ContactInfoConcise
            contactId={contactId}
            avatarProps={avatarProps}
          />
        </div>
        <Divider />
        <div style={sectionStyle}>
          <Flex
            flexDirection="column"
            spacing={1}
          >
            <Flex
              spacing={2}
              alignItems="center"
            >
              <ContactLogButton
                objectType="realtor contact"
                objectId={contactId}
                buttonProps={{
                  variant: 'flat',
                }}
              >
                Log Contact
              </ContactLogButton>
              <Comments contactId={contactId} />
              <Tooltip
                title="Edit seller"
              >
                <Button
                  variant="secondary"
                  icon="edit"
                  onClick={openModal}
                />
              </Tooltip>
              <Tooltip
                title="Remove seller"
              >
                <Button
                  variant="secondary"
                  icon="x"
                  onClick={onRemove}
                />
              </Tooltip>
            </Flex>
            <Flex
              spacing={2}
              alignItems="center"
            >
              <Call contactId={contactId} />
              <EmailButton contactId={contactId} />
            </Flex>
          </Flex>
        </div>
      </>
    );

  return (
    <ErrorBoundary>
      <>
        <Elevation
          rounded="s"
          fullHeight
        >
          {content}
        </Elevation>

        <AssignContactModal
          getContainer
          title="Edit seller"
          isVisible={isModalVisible}
          oneContactSelect
          closeModal={closeModal}
          onSave={onSave}
          withAddContact
        />
      </>
    </ErrorBoundary>
  );
};

const {
  func,
  number,
} = PropTypes;

PropertySeller.propTypes = {
  contactId: number.isRequired,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
};

export default PropertySeller;
