import { connect } from 'react-redux';

import requests from 'api/requests';

import selectors from 'store/selectors';

import { createOffer } from 'store/actions/realtorOffers';

import { createSpecialCondition } from 'store/actions/realtorSpecialConditions';

import { createContact } from 'store/actions/realtorContacts';

import {
  getClientById,
  createClient,
} from 'store/actions/realtorClients';

import {
  getProspectById,
  createProspect,
} from 'store/actions/realtorProspects';

import { uploadAttachment } from 'store/actions/attachmentsV2';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import LogOfferForm from './LogOfferForm';

const prospectTypeToContactFields = {
  client: {
    contact: 'buyers',
    agent: 'buyerAgents',
  },
  prospect: {
    contact: 'sellers',
    agent: 'sellerAgents',
  },
};

const mapStateToProps = (state) => {
  const {
    realtorLogOfferForm: {
      /**
       * @deprecated
       * prefer passing contact ids and flags for rendering respective inputs
       */
      type,

      propertyId,

      clientId,
      prospectId,

      attachments,

      onBeforeSubmit,
      onAfterSubmit,

      buyerContactId,
      sellerContactId,
      buyerAgentContactId,
      sellerAgentContactId,

      showBuyerSelector,
      showSellerSelector,
      showBuyerAgentSelector,
      showSellerAgentSelector,

    },
  } = state;

  // TODO - remove after making changes to offers sections in Clients and Prospects
  // this is for reverse compatibility only
  let contactId = null;
  let agentContactId = null;
  let propertyPrice = null;

  if (propertyId) {
    const property = selectors.properties.byId(state, propertyId);

    propertyPrice = property?.expected_price;

    if (type) {
      const contactFieldName = prospectTypeToContactFields[type]?.contact;
      const agentFieldName = prospectTypeToContactFields[type]?.agent;

      if (contactFieldName && Array.isArray(property[contactFieldName])) {
        contactId = property[contactFieldName][0]; // eslint-disable-line prefer-destructuring
      }
      if (agentFieldName && Array.isArray(property[agentFieldName])) {
        agentContactId = property[agentFieldName][0]; // eslint-disable-line prefer-destructuring
      }
    }
  }

  const isClient = type === 'client';
  const isProspect = type === 'prospect';
  //

  return {
    // contactId:
    //   isClient // seller id if logged by buyer side; buyer id if logged by seller side
    //     ? buyerContactId || contactId
    //     : contactId,

    // agentContactId,

    propertyId,
    propertyPrice,

    clientId,
    prospectId,

    onBeforeSubmit,
    onAfterSubmit,

    attachments,

    type,

    showBuyerSelector: showBuyerSelector || isClient,
    showSellerSelector: showSellerSelector || isProspect,
    showBuyerAgentSelector: showBuyerAgentSelector || isClient,
    showSellerAgentSelector: showSellerAgentSelector || isProspect,

    buyerContactId: buyerContactId || (isClient ? contactId : null),
    sellerContactId: sellerContactId || (isProspect ? contactId : null),
    buyerAgentContactId: buyerAgentContactId || (isClient ? agentContactId : null),
    sellerAgentContactId: sellerAgentContactId || (isProspect ? agentContactId : null),
  };
};

const mapDispatchToProps = (dispatch) => ({
  logOffer: async (data) => {
    // create contacts if don't exist

    const {
      contactFieldsToHandle,
    } = data;

    const buyer_contact_id = contactFieldsToHandle.buyer
      ? data.selectedBuyerContactId || (await dispatch(createContact(data.new_contact_buyer)))
      : null;

    const seller_contact_id = contactFieldsToHandle.seller
      ? data.selectedSellerContactId || (await dispatch(createContact(data.new_contact_seller)))
      : null;

    const buyer_agent_contact_id = contactFieldsToHandle.buyerAgent
      ? data.selectedBuyerAgentId || (await dispatch(
          createContact({
            ...data.new_contact_buyer_agent,
            type: 1,
          }),
        ))
      : 0; // zero for current user, will change later

    const seller_agent_contact_id = contactFieldsToHandle.sellerAgent
      ? data.selectedSellerAgentId || (await dispatch(
          createContact({
            ...data.new_contact_seller_agent,
            type: 1,
          }),
        ))
      : 0; // zero for current user, will change later

    // destructurize offer data

    const {
      realtor_client_id,
      realtor_prospect_id,
      selectedPropertyId: realtor_property_id,
      closing_date,
      contingency_period: contingency_period_end,
      expiry_date,
      isAllCash: all_cash,
      offerAmount: amount,
      specialConditions,
      contingenciesTypes,
      isNoContingencies,
      additional_notes: note,
      attachments,
    } = data;

    // find corresponding client or prospect or create new
    let foundOrCreatedClientId;
    let foundOrCreatedProspectId;

    if (!realtor_client_id && !realtor_prospect_id) {
      if (data.type === 'client' && seller_contact_id) {
        const clientsResponse = await requests.realtor.clients.get({
          offset: 0,
          limit: 1,
          propertyId: realtor_property_id,
          contactId: seller_contact_id,
        });
        const clients = clientsResponse.data.items;
        if (clients[0]) {
          foundOrCreatedClientId = clients[0].id;
        } else {
          foundOrCreatedClientId = await dispatch(createClient({
            contact_id: seller_contact_id,
            realtor_property_id,
            listing_price: data.propertyPrice,
            is_exclusive: false,
            is_pocket_listing: false,
          }));
        }
      }
      if (data.type === 'prospect' && buyer_contact_id) {
        const prospectsResponse = await requests.realtor.prospects.get({
          offset: 0,
          limit: 1,
          contactId: buyer_contact_id,
        });
        const prospects = prospectsResponse.data.items;
        if (prospects[0]) {
          foundOrCreatedProspectId = prospects[0].id;
        } else {
          foundOrCreatedProspectId = await dispatch(createProspect({
            contact_id: buyer_contact_id,
            is_exclusive: false,
            is_pocket_listing: false,
          }));
        }
      }
    }

    // create create special condition requests

    const specialConditionsRequests = specialConditions
      .filter((item) => item && item?.length > 1)
      .map(async (item) => dispatch(
          createSpecialCondition({
            name: item,
          }),
        ));

    // form offer data

    const offerData = {
      // client specific fields
      buyer_contact_id,
      buyer_agent_contact_id,
      realtor_client_id: realtor_client_id || foundOrCreatedClientId || null,

      // prospect specific fields
      seller_contact_id,
      seller_agent_contact_id,
      realtor_prospect_id: realtor_prospect_id || foundOrCreatedProspectId || null,

      realtor_property_id,
      closing_date,
      contingency_period_end,
      expiry_date,
      all_cash,
      amount,

      specialConditions: await Promise.all(specialConditionsRequests),

      contingency_inspection:
        !isNoContingencies && contingenciesTypes.includes(1),

      contingency_appraisal:
        !isNoContingencies && contingenciesTypes.includes(2),

      contingency_loan: !isNoContingencies && contingenciesTypes.includes(3),
      note,
    };

    // create offer

    const offerId = await dispatch(
      createOffer({
        ...offerData,
        type: 1,
      }),
    );

    // upload offer attachments

    const uploadAttachmentsRequests = attachments.map(async (item) => {
      const {
        url: attachmentUrl,
        name: attachmentName,
        type: attachmentType,
      } = item;

      const file = await dataUrlToFile(
        attachmentUrl,
        attachmentName,
        attachmentType,
      );

      const attachmentsData = {
        file,
        objectId: offerId,
        objectType: 31,
        attachmentType: 12,
      };

      await dispatch(uploadAttachment(attachmentsData));
    });

    await Promise.all(uploadAttachmentsRequests);

    // update related client and prospect item state

    if (realtor_client_id) {
      dispatch(getClientById(realtor_client_id));
    }

    if (realtor_prospect_id) {
      dispatch(getProspectById(realtor_prospect_id));
    }

    return offerId;
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: async (data) => {
    const offerId = await dispatchProps.logOffer({
      ...data,

      attachments: stateProps.attachments,

      realtor_client_id: stateProps.clientId,
      realtor_prospect_id: stateProps.prospectId,

      type: stateProps.type,

      contactFieldsToHandle: {
        buyer: stateProps.showBuyerSelector,
        seller: stateProps.showSellerSelector,
        buyerAgent: stateProps.showBuyerAgentSelector,
        sellerAgent: stateProps.showSellerAgentSelector,
      },

      propertyPrice: stateProps.propertyPrice,
    });

    if (stateProps.onAfterSubmit) {
      stateProps.onAfterSubmit(offerId);
    }

    if (ownProps.onAfterSubmit) {
      ownProps.onAfterSubmit(offerId);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(LogOfferForm);
