import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Root,
  Link,
} from './styledItems';

const ICONS = {
  facebook: ['fab', 'facebook-f'],
  twitter: ['fab', 'twitter'],
  linkedin: ['fab', 'linkedin-in'],
  instagram: ['fab', 'instagram'],
  website: 'globe',
};

const SocialLinks = (props) => {
  const {
    links,
  } = props;

  return (
    <Root>
      {
        links.map((item) => {
          const {
            url,
            name,
          } = item;

          return (
            <Link href={url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                icon={ICONS[name]}
                style={{
                  color: '#001934',
                  fontSize: '16px',
                }}
              />
            </Link>
          );
        })
      }
    </Root>
  );
};

SocialLinks.defaultProps = {
  links: [],
};

const {
  string,
  shape,
  arrayOf,
} = PropTypes;

SocialLinks.propTypes = {
  links: arrayOf(shape({
    name: string,
    url: string,
  })),
};

export default SocialLinks;
