import list from './list';
import picture from './picture';
import importInfo from './importInfo';
import importContacts from './importContacts';

export default {
  list,
  picture,
  importInfo,
  importContacts,
};
