import { createSelector } from 'reselect';

import { reduce } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

import {
  optimizedAttachmentsActions,
} from 'store/actions/attachmentsV2';

import getImageUrl from 'shared/utils/getImageUrl';

const propertyObjectType = 27;

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.properties.selectedProperty,
  (state) => state.attachmentsV2.attachments,
  (
    properties,
    selectedProperty,
    attachmentsCollection,
  ) => {
    const property = properties[selectedProperty];

    if (!property) {
      if (selectedProperty) {
        optimizedPropertyActions.getPropertyById(selectedProperty);
      }

      return [];
    }

    const propertyAttachments = property.attachments;

    if (!propertyAttachments) {
      optimizedPropertyActions.getPropertyAttachments(selectedProperty);
      return [];
    }

    const images = reduce(propertyAttachments?.image, (accum, { attachmentId }) => {
      const attachment = attachmentsCollection[attachmentId];

      if (!attachment) {
        if (attachmentId) {
          optimizedAttachmentsActions.getAttachmentsList(propertyObjectType, selectedProperty);
        }

        return accum;
      }

      const imageUrl = getImageUrl(attachment.Location, attachment.Url, attachment.Acl);

      accum.push({
        id: attachmentId,
        src: imageUrl,
        uid: attachmentId,
        name: attachment.OriginalName,
        url: imageUrl,
        isMain: attachmentId === property.main_image_id,
      });
      return accum;
    }, []);

    return images;
  },
);
