import apiConnector, { endpoints } from 'api';

const editQueue = (queueId) => async ({ name, description }) => {
  const endpoint = `${endpoints.client.queues}/queue/${queueId}`;

  try {
    const result = await apiConnector.client.put(endpoint, { name, description });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default editQueue;
