import apiConnector, { endpoints } from 'api';

const getLicenseUpdateTotal = async (licenses) => {
  const endpoint = endpoints.client.billing.licenseUpdateTotal;

  try {
    const result = await apiConnector.client.post(endpoint, { licenses });

    return result.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getLicenseUpdateTotal;
