import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import CurrencyInput from 'ui/CurrencyInput';

const ExpectedPurchasePriceInput = (props) => {
  const {
    handleChangeMin,
    handleChangeMax,
    valueMin,
    valueMax,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <CurrencyInput
          label="Min. budget"
          onChange={handleChangeMin}
          minimumValue={0}
          fullWidth
          value={valueMin}
        />
      </Grid>
      <Grid item xs={6}>
        <CurrencyInput
          label="Max. budget"
          onChange={handleChangeMax}
          minimumValue={0}
          currencySymbol="$"
          fullWidth
          value={valueMax}
        />
      </Grid>
    </Grid>
  );
};

const {
  func,
  number,
} = PropTypes;

ExpectedPurchasePriceInput.propTypes = {
  handleChangeMin: func.isRequired,
  handleChangeMax: func.isRequired,
  valueMin: number.isRequired,
  valueMax: number.isRequired,
};

export default ExpectedPurchasePriceInput;
