import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import ExtensionRequestsModal from './ExtensionRequestsModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      extensionRequests,
    },
  } = state;

  return {
    isVisible: extensionRequests?.isVisible,
    contentOptions: extensionRequests?.contentOptions || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('extensionRequests')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionRequestsModal);
