import { connect } from 'react-redux';

import {
  closeNewCounterModal,
} from 'store/actions/newRealtorCounterModal';

import NewCounterModal from './NewCounterModal';

const mapStateToProps = (state) => {
  const {
    realtorNewCounterModal: {
      isVisible,
      offerId,
      onAfterSubmit,
    },
  } = state;

  return {
    isVisible,
    offerId,
    onAfterSubmit,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeNewCounterModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCounterModal);
