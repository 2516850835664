import { createSelector } from 'reselect';

// import { // eslint-disable-line import/no-cycle
//   optimizedProspectActions,
// } from 'store/actions/realtorProspects';

const defaultItem = {
  id: null,
};

export default (id) => createSelector(
  (state) => state.realtorMatchingProperties.matchingProperties,
  (collection) => {
    const item = collection[id];

    if (!item) {
      // if (id) {
      //   optimizedProspectActions.getProspectById(id);
      // }

      return defaultItem;
    }

    return {
      ...item,
      viewing_upcoming_ids: item?.viewing_upcoming_ids ? JSON.parse(item.viewing_upcoming_ids) : null,
      viewing_completed_ids: item?.viewing_completed_ids ? JSON.parse(item.viewing_completed_ids) : null,
    };
  },
);
