import requests from 'api/requests';

import setContact from './setContact';

const getContactById = (contactId) => async (dispatch) => {
  try {
    const response = await requests.crm.contacts.getById(contactId);

    dispatch(setContact(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getContactById;
