import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import createSpecialCondition from './create';
import getRealtorSpecialConditonById from './getById';

export {
  createSpecialCondition,
  getRealtorSpecialConditonById,
};

export default {
  createSpecialCondition,
  getRealtorSpecialConditonById,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorSpecialConditionsActions = {
  getById: memoizeDebounce(
    (id) => store.dispatch(getRealtorSpecialConditonById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
