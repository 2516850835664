import requests from 'api/requests';

import setCustomizationSettings from './setCustomizationSettings';

const getCustomizationSettings = () => async (dispatch) => {
  try {
    const response = await requests.realtor.appCustomizationSettings.get();

    dispatch(setCustomizationSettings(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getCustomizationSettings;
