import { connect } from 'react-redux';

import {
  checkIfAccountExists,
} from 'store/actions/account';

import ContactInformationForm from './ContactInformationForm';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {
    checkIfAccountExists,
  };
};

export default connect(mapStateToProps)(ContactInformationForm);
