import { createSelector } from 'reselect';

import { getUserOptimized } from 'store/actions/users';

export default createSelector(
  (state) => state.users.realtorContact,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      getUserOptimized('realtorContact', id);

      return {
        avatar: null,
        avatarAcl: null,
        avatarUrl: null,
        name: '',
        surname: '',
      };
    }

    return {
      ...item,
    };
  },
);
