import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';

import getById from './getClientById';

const notify = debounce((action, text) => {
  callAlert[action](text);
}, 1000, {
  leading: true,
});

const updateClient = (clientId) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.clients.update(clientId)(updateData);

    notify('success', 'Updated successfully');

    dispatch(getById(clientId));
  } catch (error) {
    console.error(error);
    notify('error', 'Could not update');
  }
};

export default updateClient;
