import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-left: none;
  border-right: none;
`;

export const EmptyImage = styled.img`
  margin: auto auto 20px;
`;

export const EmptyItemsContainer = styled.div`
  width: 100%;
  height: inherit;
  display: grid;
  cursor: pointer;
`;

export const OverflowContainer = styled.div`
  overflow-y: auto;
  height: inherit;
  cursor: pointer;
`;

export default {
  Table,
  EmptyImage,
  EmptyItemsContainer,
  OverflowContainer,
};
