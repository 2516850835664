import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_CLIENT,
  SET_REALTOR_CLIENTS,
  SET_REALTOR_SELECTED_CLIENT,
  SET_REALTOR_CLIENT_SELECTED_VIEW,
  SET_REALTOR_CLIENTS_FOR_FILTER,
  SET_REALTOR_CLIENTS_FOR_FILTER_COUNT,
  SET_REALTOR_CLIENTS_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorClients';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  clients: {},
  selectedClient: null,
  selectedView: 'offers',
  realtorClientsByFilter: {},
  realtorClientsByFilterCount: {},
  overallSummary: null,
};

const reducers = {
  [SET_REALTOR_CLIENT]: (state, action) => ({
    ...state,
    clients: {
      ...state.clients,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_CLIENTS]: (state, action) => ({
    ...state,
    clients: {
      ...state.clients,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_SELECTED_CLIENT]: (state, action) => ({
    ...state,
    selectedClient: action.payload,
  }),

  [SET_REALTOR_CLIENT_SELECTED_VIEW]: (state, action) => ({
    ...state,
    selectedView: action.payload,
  }),

  [SET_REALTOR_CLIENTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      clients,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(clients),
    ]);

    const updatedState = {
      ...state,
      realtorClientsByFilter: {
        ...state.realtorClientsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_CLIENTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    realtorClientsByFilterCount: {
      ...state.realtorClientsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_CLIENTS_OVERALL_SUMMARY]: (state, action) => ({
    ...state,
    overallSummary: action.payload,
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
