import React, { useContext } from 'react';

import CallsContext from 'sections/Calls/context/CallsContext';

import Icon from 'ui/Icon';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const MicrophoneAvailabilityIndicator = () => {
  const {
    isMicrophoneAvailable,
  } = useContext(CallsContext);

  return (
    <Flex
      spacing={1}
      alignItems="center"
    >
      <Icon
        name={isMicrophoneAvailable ? 'mic' : 'mic-off'}
        color={isMicrophoneAvailable ? 'default' : 'error'}
        size="s"
      />

      <Typography
        variant="body2"
        tag="span"
        noMargin
      >
        {
          isMicrophoneAvailable ? 'Microphone is available' : 'Microphone is not available'
        }
      </Typography>
    </Flex>
  );
};

export default MicrophoneAvailabilityIndicator;
