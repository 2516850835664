export const SET_CUSTOMER_INFORMED = 'SET_CUSTOMER_INFORMED';
export const SET_CUSTOMER_INFORMED_MULTIPLE = 'SET_CUSTOMER_INFORMED_MULTIPLE';
export const SET_SELECTED_CUSTOMER_INFORMED = 'SET_SELECTED_CUSTOMER_INFORMED';
export const SET_CUSTOMER_INFORMED_ATTACHMENTS = 'SET_CUSTOMER_INFORMED_ATTACHMENTS';

export default {
  SET_CUSTOMER_INFORMED,
  SET_CUSTOMER_INFORMED_MULTIPLE,
  SET_SELECTED_CUSTOMER_INFORMED,
  SET_CUSTOMER_INFORMED_ATTACHMENTS,
};
