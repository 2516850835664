import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

export default createSelector(
  (state) => state.realtorTransactions.transactions,
  (state, filterId) => state.realtorTransactions.transactionsByFilter[filterId],
  (transactions, filteredIds) => {
    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((accum, transactionId) => {
      const deal = transactions[transactionId];

      if (!deal && transactionId) {
        optimizedRealtorTransactionsActions.getTransactionById(transactionId);
        return accum;
      }

      accum.push(deal);
      return accum;
    }, []);

    return result;
  },
);
