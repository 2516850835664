import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getLineItems from './getLineItems';
import getLineItemById from './getLineItemById';

import createLineItem from './createLineItem';

import setSelectedLineItem from './setSelectedLineItem';

import updateLineItem from './updateLineItem';

export { getLineItems };
export { getLineItemById };

export { createLineItem };

export { setSelectedLineItem };

export { updateLineItem };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedLineItemsActions = {
  getLineItemById: memoizeDebounce(
    (id) => store.dispatch(getLineItemById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
