/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */

import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import {
  Controller,
  useForm,
} from 'react-hook-form';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import DateTimeInput from 'shared/inputs/DateTimeInput';
import FormTextfield from 'shared/inputs/FormTextfield';

import SearchContactInput from 'sections/Transactions/modules/SearchContactInput';
import NewContactArea from 'sections/Transactions/modules/NewContactArea';

import SelectContactArea from 'sections/Transactions/modules/SelectContactArea';

import AttachmentsUpload from './AttachmentsUpload';

import {
  StyledButton,
} from './styledItems';

const LogNewQuestionForm = (props) => {
  const {
    formId,
    onSubmit: propsOnSubmit,

    propsContactId,
    propsDateTime,
    propsQuestion,
    selectedTransaction,
  } = props;

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
  } = useForm();

  const { errors } = formState;

  // contact
  const [selectedContactId, setContact] = useState(null);

  const [otherContactSelectInput, setContactSearchInputVisibility] = useState(false);

  useEffect(() => {
    const selected_contacts = getValues('selected_contacts');

    if (!isEmpty(selected_contacts)) {
      setContactSearchInputVisibility(false);
    }
  }, [formState]);

  useEffect(() => {
    setContact(propsContactId);
  }, [
    propsContactId,
  ]);

  const changeContactSearchInputVisibility = useCallback((event) => {
    event.preventDefault();
    setContactSearchInputVisibility(!otherContactSelectInput);
  }, [otherContactSelectInput]);

  const onSubmit = useCallback((data) => {
    propsOnSubmit({
      ...data,
      selectedContactId,
    });
  }, [
    propsOnSubmit,
    selectedContactId,
  ]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
              >
                Asked by
              </Typography>

              <SelectContactArea
                control={control}
                formName="selected_contacts"
                setValue={setValue}
                transactionId={selectedTransaction}
                oneContactSelect
                required={!otherContactSelectInput}
                errors={errors}
              />

              <StyledButton
                variant="tertiary"
                onClick={changeContactSearchInputVisibility}
              >
                <Typography
                  variant="body2"
                  color="link"
                  noMargin
                >
                  {otherContactSelectInput ? 'hide' : '+ other'}
                </Typography>
              </StyledButton>
            </Grid>

            {
              otherContactSelectInput && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      color="main-text"
                    >
                      Requestor
                    </Typography>

                    <SearchContactInput
                      selectedContactId={selectedContactId}
                      setContact={setContact}
                      label="Select a contact"
                    />
                  </Grid>

                  {
                    !selectedContactId && (
                      <Grid item xs={12}>
                        <NewContactArea
                          errors={errors}
                          objectFormName="new_contact"
                          control={control}
                          decorated={false}
                        />
                      </Grid>
                    )
                  }
                </>
              )
            }

            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
              >
                Question date and time
              </Typography>
              <Controller
                name="request_date"
                control={control}
                defaultValue={propsDateTime || new Date().toString()}
                render={({ field }) => (
                  <DateTimeInput
                    {...field}
                    label=""
                    handleChange={field.onChange}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
              >
                Question
              </Typography>
              <Controller
                name="question"
                control={control}
                defaultValue={propsQuestion}
                render={({ field }) => (
                  <FormTextfield
                    label=""
                    multiline
                    rows={3}
                    {...field}
                    handleChange={field.onChange}
                    error={!!errors?.question}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AttachmentsUpload />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

LogNewQuestionForm.defaultProps = {
  propsContactId: null,
  propsDateTime: null,
  propsQuestion: null,
};

const {
  string,
  func,
  number,
} = PropTypes;

LogNewQuestionForm.propTypes = {
  formId: string.isRequired,
  onSubmit: func.isRequired,
  selectedTransaction: number.isRequired,
  propsContactId: number,
  propsDateTime: string,
  propsQuestion: string,
};

export default LogNewQuestionForm;
