import {
  SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_OPEN,
} from 'store/actionTypes/realtorRepairRequests';

const openRepairRequestNotesModal = (repairRequestId) => ({
  type: SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_OPEN,
  payload: { repairRequestId },
});

export default openRepairRequestNotesModal;
