import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_EXTENSION_REQUEST_FORM_STATE,
} from 'store/actionTypes/realtorTransactions';

const openNewDocumentRequestModal = openModalWithContentAction('newExtensionRequest')(
  SET_EXTENSION_REQUEST_FORM_STATE,
);

export default openNewDocumentRequestModal;
