import requests from 'api/requests';

import set from './set';
import setForFilter from './setForFilter';
import setForFilterCount from './setForFilterCount';

const getForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.documentRequests.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(set(response.data.items));
    dispatch(setForFilter(filterId)(response.data.items));
    dispatch(setForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getForFilter;
