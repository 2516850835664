import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import ObjectRemindersModal from 'shared/modals/ObjectRemindersModal/ObjectRemindersModal';

import useModalsDrafts from 'shared/hooks/useModalsDrafts';

const modalName = 'followUpRemindersNew';

const FollowUpRemindersModalNew = (props) => {
  const {
    isVisible,
    onOk,
    closeModal,
    onCreate,
    objectTypeId,
    objectId,
  } = props;

  const [
    currentObjectTypeId,
    setObjectTypeId,
  ] = useState(objectTypeId);

  const [
    currentObjectId,
    setObjectId,
  ] = useState(objectId);

  const {
    draftData,
    saveDraft,
    clearDraft,
  } = useModalsDrafts(modalName);

  useEffect(() => {
    setObjectTypeId(objectTypeId);
    setObjectId(objectId);
  }, [
    objectTypeId,
    objectId,
  ]);

  useEffect(() => {
    !currentObjectTypeId && setObjectTypeId(draftData?.objectTypeId || null);
    !currentObjectId && setObjectId(draftData?.objectId || null);
  }, [
    currentObjectTypeId,
    currentObjectId,
    JSON.stringify(draftData),
  ]);

  const handleObjectSelect = useCallback((selecteObjectTypeId) => (selectedObjectId) => {
    setObjectTypeId(selecteObjectTypeId);
    setObjectId(selectedObjectId);

    saveDraft(
      {
        objectTypeId: selecteObjectTypeId,
        objectId: selectedObjectId,
      },
      true,
    );
  }, []);

  const handleCreate = useCallback((reminderData) => {
    onCreate(currentObjectTypeId, currentObjectId)(reminderData);
    clearDraft(true);
  }, [
    onCreate,
    currentObjectTypeId,
    currentObjectId,
    clearDraft,
  ]);

  return (
    <ObjectRemindersModal
      isVisible={isVisible}
      title="Create a Follow Up"
      onOk={onOk}
      closeModal={closeModal}
      onObjectSelect={handleObjectSelect}
      onCreate={handleCreate}
      tabsView={false}
      draftHandlers={{ draftData, saveDraft }}
      selectedObjectTypeId={currentObjectTypeId}
      selectedObjectId={currentObjectId}
    />
  );
};

FollowUpRemindersModalNew.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
} = PropTypes;

FollowUpRemindersModalNew.propTypes = {
  isVisible: bool,
  onOk: func.isRequired,
  closeModal: func.isRequired,
  onCreate: func.isRequired,
};

export default FollowUpRemindersModalNew;
