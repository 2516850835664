import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.realtorOpportunitiesFilter,
  (filter) => {
    if (!filter.status && !filter.type) {
      return 'all';
    }
    if (filter.status === 2) {
      return 'archived';
    }
    if (filter.status === 7) {
      return 'new';
    }
    if (filter.type === 'prospect') {
      return 'prospects';
    }
    if (filter.type === 'client') {
      return 'clients';
    }
    return 'all';
  },
);
