import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const TutorialFooterButton = (props) => {
  const {
    appTour,
    appTourStep,
    index,
    steps,
    availableTutorials,
    handleNext,
    nextTutorial,
    finishAppTour,
    finishTutorial,
  } = props;

  if (index < steps.length - 1) {
    return (
      <Button
        type="primary"
        onClick={handleNext}
        data-test="next-tutorial-step-button"
      >
        Next
      </Button>
    );
  }

  if (appTour && index === steps.length - 1) {
    if (appTourStep < availableTutorials.length - 1) {
      return (
        <Button
          type="primary"
          onClick={nextTutorial}
        >
          Next
        </Button>
      );
    }

    if (appTourStep === availableTutorials.length - 1) {
      return (
        <Button
          type="primary"
          onClick={finishAppTour}
        >
          Done
        </Button>
      );
    }
  }

  if (index === steps.length - 1) {
    return (
      <Button
        type="primary"
        onClick={finishTutorial}
        data-test="tutorial-done-button"
      >
        Done
      </Button>
    );
  }

  return null;
};

const {
  any,
  bool,
  func,
  number,
  arrayOf,
} = PropTypes;

TutorialFooterButton.defaultProps = {
  appTour: false,
  appTourStep: 0,
  availableTutorials: [],
};

TutorialFooterButton.propTypes = {
  index: number.isRequired,
  steps: arrayOf(any).isRequired,
  finishTutorial: func.isRequired,
  appTour: bool,
  appTourStep: number,
  availableTutorials: arrayOf(any),
  handleNext: func.isRequired,
  nextTutorial: func.isRequired,
  finishAppTour: func.isRequired,
};

export default TutorialFooterButton;
