import { apiConnector } from 'shared/API';


export const SET_LEADS_LABELS = 'SET_LEADS_LABELS';
export const setAllLeadsLabels = (payload) => ({
  type: SET_LEADS_LABELS,
  payload,
});

export const CLEAR_LEADS_LABELS = 'CLEAR_LEADS_LABELS';
export const clearLeadsLabels = (payload) => ({
  type: CLEAR_LEADS_LABELS,
  payload,
});

export const SET_LEAD_LABELS = 'SET_LEAD_LABELS';
export const setLeadLabels = (payload) => ({
  type: SET_LEAD_LABELS,
  payload,
});

export const getAllLeadsLabels = () => (dispatch, getState) => {
  apiConnector(
    '/api/leads/labels/all',
    'GET',
  ).then(({ result }) => {
    dispatch(setAllLeadsLabels(result));
  });
};

export const getLabelsByLead = (LeadID) => (dispatch, getState) => {
  const id = LeadID || getState().leads.LeadID;

  if (id) {
    apiConnector(
      `/api/leads/labels/get/${id}`,
      'GET',
    ).then(({ result }) => {
      dispatch(setLeadLabels(result));
    });
  }
};


export const createLeadsLabel = (labelObject) => (dispatch, getState) => {
  apiConnector(
    '/api/leads/labels/update/',
    'POST',
    labelObject,
  ).then(({ result }) => {
    dispatch(setAllLeadsLabels(result));
  });
};

export const updateLeadsLabel = (labelID, payload, callback) => (dispatch, getState) => {
  const {
    leads: {
      LeadID,
      allLeadsLabels,
    },
  } = getState();

  const { color } = allLeadsLabels.find((label) => label.id === labelID);

  apiConnector(
    `/api/leads/labels/update/${labelID}`,
    'POST',
    {
      ...payload,
      color,
    },
  ).then(({ result }) => {
    dispatch(setAllLeadsLabels(result));

    dispatch(getLabelsByLead(LeadID));

    if (callback) callback();
  });
};

export const updateLabelsForLead = (labelsIDs) => (dispatch, getState) => {
  const {
    leads: {
      LeadID,
    },
  } = getState();

  apiConnector(
    `/api/leads/labels/bind/${LeadID}`,
    'POST',
    {
      labelsIDs,
    },
  ).then(({ result }) => {
    dispatch(setLeadLabels(result));
  });
};
