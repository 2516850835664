import {
  SET_REALTOR_CLIENTS_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorClients';

const setClientsOverallSummary = (summary) => ({
  type: SET_REALTOR_CLIENTS_OVERALL_SUMMARY,
  payload: summary,
});

export default setClientsOverallSummary;
