import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import UserAvatarArray from '../UserAvatarArray';
import ButtonAvatar from '../ButtonAvatar';

import './UserAvatarArrayWithButton.scss';

const rootClass = 'user-avatar-array-with-button';


const UserAvatarArrayWithButton = (props) => {
  const {
    className,
    onClickItem,
    showButton,
    onClickButton,
    offsetItem,
    defaultOffset,
    children,
    hidePlus,
  } = props;

  return (
    <div
      className={classnames(
        rootClass,
        {
          [className]: className,
        },
      )}
    >
      {
        children && children.length > 0 && (
          <UserAvatarArray
            onClick={onClickItem}
            offsetItem={offsetItem}
            defaultOffset={defaultOffset}
          >
            {children}
          </UserAvatarArray>
        )
      }
      <ButtonAvatar
        onClick={onClickButton}
        showButton={showButton}
        hidePlus={hidePlus}
        className="data-popover-agents"
      />
    </div>
  );
};


UserAvatarArrayWithButton.defaultProps = {
  children: null,
  className: '',
  defaultOffset: 0,
  offsetItem: 10,
  onClickButton: () => {},
  onClickItem: () => {},
  showButton: false,
  hidePlus: false,
};

const {
  arrayOf,
  bool,
  element,
  func,
  number,
  string,
} = PropTypes;

UserAvatarArrayWithButton.propTypes = {
  children: arrayOf(element),
  className: string,
  onClickItem: func,
  showButton: bool,
  onClickButton: func,
  offsetItem: number,
  defaultOffset: number,
  hidePlus: bool,
};

export default UserAvatarArrayWithButton;
