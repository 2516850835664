import styled from 'styled-components';

export const SearchedContactWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  padding: 5px;
  border-radius: 7px;

  .contact-email {
    margin-left: 10px;
  }

  :hover {
    background-color: #f7f9fa;
    cursor: pointer;
  }
`;

export default {
  SearchedContactWrapper,
};
