import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';
import withInlineLabel from 'shared/hocs/withInlineLabel';

import withOptionsMenu from 'ui/withOptionsMenu';

import {
  Label,
} from './styledItems';

const SelectPropertyStatusArea = (props) => {
  const {
    onChange,
    value,
    options,
  } = props;

  const [ownValue, setValue] = useState(value || '');

  useEffect(() => {
    setValue(value);
  }, [
    value,
  ]);

  const Content = useMemo(() => () => (
    ownValue ? (
      <Label
        status={ownValue}
      >
        <Typography
          variant="chip"
          color="main-text-inversed"
          className="property-status-label"
          weight="normal"
          noMargin
        >
          {ownValue}
        </Typography>
      </Label>
    ) : (
      <Typography
        color="disabled"
        style={{
          cursor: 'pointer',
        }}
        fontStyle="italic"
        noMargin
      >
        Click to add
      </Typography>
    )
  ), [
    ownValue,
  ]);

  return withOptionsMenu(Content)({
    onChange,
    value: ownValue,
    options,
  });
};

SelectPropertyStatusArea.defaultProps = {
  label: null,
  onChange: () => {},
  value: null,
  style: {},
  options: [],
};

const {
  arrayOf,
  func,
  shape,
  string,
} = PropTypes;

SelectPropertyStatusArea.propTypes = {
  label: string,
  onChange: func,
  value: string,
  style: shape,
  options: arrayOf(shape()),
};

const decoratorProps = {
  iconName: 'edit',
};

export default withFieldDecorator(decoratorProps)(withInlineLabel(SelectPropertyStatusArea));
