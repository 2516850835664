import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getShippedItems from './getShippedItems';
import getShippedItemById from './getShippedItemById';

import createShippedItem from './createShippedItem';

import setSelectedShippedItem from './setSelectedShippedItem';

import updateShippedItem from './updateShippedItem';
import getShippedItemAttachments from './getShippedItemAttachments';

export { getShippedItems };
export { getShippedItemById };

export { createShippedItem };

export { setSelectedShippedItem };

export { updateShippedItem };
export { getShippedItemAttachments };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedShippedItemsActions = {
  getShippedItemById: memoizeDebounce(
    (id) => store.dispatch(getShippedItemById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
