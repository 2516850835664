import requests from 'api/requests';

import setFulfillments from './setFulfillments';

const getFulfillments = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.fulfillments.get({
      offset,
      limit,
    });

    dispatch(setFulfillments(response.data.fulfillments));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillments;
