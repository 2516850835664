import React from 'react';
import PropTypes from 'prop-types';

import Draggable from 'react-draggable';
/**
 * To allow drag across all the main viewport,
 * add the wrapped component to MainAppView root element
 * before any other children
 */
const DraggableWrapper = (props) => {
  const {
    children,
  } = props;

  return (
    <Draggable
      bounds="parent"
      cancel=".prevent-drag"
    >
      <div
        style={{
          position: 'absolute',
          zIndex: 1e7,
          bottom: 50,
          right: 50,
        }}
      >
        { children }
      </div>
    </Draggable>
  );
};

const {
  element,
} = PropTypes;

DraggableWrapper.propTypes = {
  children: element.isRequired, // Draggable must have children elements
};

export default DraggableWrapper;
