import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import SearchContactInput from 'shared/inputs/SearchContactInput';
import PhoneNumberInput from 'ui/PhoneNumberInput';

const InputField = (props) => {
  const {
    value,
    onChange,
    selectedContact,
    onContactSelect,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [mode, setMode] = useState('number');

  useEffect(() => {
    setInputValue(value);
  }, [
    value,
  ]);

  const onContactDelete = useCallback(() => {
    onContactSelect(null);
    onChange('');
  }, [
    onContactSelect,
    onChange,
  ]);

  const handleTextfieldChange = useCallback((newValue) => {
    if (!newValue) {
      setMode('number');
    }

    setInputValue(newValue);
    onChange(newValue);
  }, [
    onChange,
  ]);

  const onNotNumberInput = useCallback((newValue) => {
    setInputValue(newValue);
    onChange(newValue);
    setMode('name');
  }, [
    onChange,
  ]);

  return (
    <SearchContactInput
      selectedContactId={selectedContact}
      handleChange={onContactSelect}
      handleDelete={onContactDelete}
      label={null}
      onInput={handleTextfieldChange}
      inputValue={inputValue}
      textfieldProps={{
        value: inputValue,
        fullWidth: true,
        placeholder: 'Search contact or number',
        transparent: true,
        textColor: 'contrast',
        focusOnRender: true,
        onNotNumberInput,
      }}
      contactExtraInfo={['phone']}
      chipVariant="focus"
      searchAreaPlacement="left"
      searchAreaBorder
      searchResultsDependentSearchAreaVisibility
      fullWidth
      chipStyle={{ margin: '0 auto', display: 'none' }}
      InputComponent={mode === 'number' ? PhoneNumberInput : null}
    />
  );
};

InputField.defaultProps = {
  selectedContact: null,
};

const {
  func,
  number,
  string,
} = PropTypes;

InputField.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  selectedContact: number,
  onContactSelect: func.isRequired,
};

export default InputField;
