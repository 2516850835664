import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateSelectedPropertyViewing,
  closeViewingNotesModal,
} from 'store/actions/propertyViewings';

import ViewingNotesModal from './ViewingNotesModal';

const mapStateToProps = (state) => {
  const {
    propertyViewings: {
      selectedViewing,
      isViewingNotesModalVisible,
    },
  } = state;

  const viewing = selectors.propertyViewings.byId(state, selectedViewing);

  return {
    isVisible: isViewingNotesModalVisible,
    content: viewing?.notes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeViewingNotesModal());
  },
  onNoteChange: (content, viewingId) => {
    dispatch(updateSelectedPropertyViewing({ notes: content }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewingNotesModal);
