import {
  RESET_REALTOR_UNANSWERED_QUESTIONS_FILTERS,
  RESET_REALTOR_UNANSWERED_QUESTIONS_FILTER_COUNTERS,
} from 'store/actionTypes/realtorUnansweredQuestions';

const resetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_REALTOR_UNANSWERED_QUESTIONS_FILTERS,
  });

  dispatch({
    type: RESET_REALTOR_UNANSWERED_QUESTIONS_FILTER_COUNTERS,
  });
};

export default resetFilters;
