import React from 'react';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  RoadMapItemWrapper,
} from './styledItems';

const roadmap = [
  {
    icon: 'socialMedia',
    label: 'Agent Websites',
  },
  {
    icon: 'rentalProperties',
    label: 'More Lead Generation',
  },
  {
    icon: 'brokerPlatform',
    label: 'Broker Platform',
  },
  {
    icon: 'team',
    label: 'More Community Features',
  },
];

const RoadmapList = (props) => (
  <div>
    <Flex
      flexDirection="column"
      spacing={1}
    >
      <Flex
        spacing={1}
        alignItems="stretch"
      >
        {roadmap.map((item) => (
          <RoadMapItemWrapper>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Icon
                type="custom"
                name={item.icon}
                size={50}
              />

              <Typography
                variant="title2"
                weight="bold"
                align="center"
                noMargin
              >
                {item.label}
              </Typography>
            </Flex>
          </RoadMapItemWrapper>
        ))}
      </Flex>

      <Typography
        variant="subtitle2"
        noMargin
      >
        {'To see more products and features, see our '}
        <a
          target="_blank"
          href="https://symplete.com/coming-soon"
          rel="noreferrer"
        >
          coming soon page
        </a>
        {' on our website.'}
      </Typography>
    </Flex>
  </div>
);

export default RoadmapList;
