import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import EmailSelector from 'sections/Integrations/modules/EmailSelector';

const Sender = (props) => {
  const {
    onChange,
  } = props;

  return (
    <Typography
      variant="title2"
      noMargin
    >
      From:
      {' '}
      <EmailSelector onChange={onChange} />
    </Typography>
  );
};

const {
  func,
} = PropTypes;

Sender.propTypes = {
  onChange: func.isRequired,
};

export default Sender;
