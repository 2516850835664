import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  overwritePropertyRequirementRelations,
} from 'store/actions/propertyRequirements';

import PropertyMultiTypeSelect from 'sections/Leads/inputs/PropertyMultiTypeSelect';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    value: propertyRequirementData?.property_type,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(overwritePropertyRequirementRelations('propertyTypes')(ownProps.itemId)(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PropertyMultiTypeSelect);
