import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Menu from 'ui/Menu';
import MenuItem from 'ui/MenuItem';

const OptionsCell = (props) => {
  const {
    contactId,
    onCall,
    onFollowUp,
    email,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onButtonClick = useCallback((event) => {
    setAnchorEl(event.target);
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleFollowUp = useCallback(() => {
    onFollowUp();
    setMenuOpen(false);
  }, [
    onFollowUp,
  ]);

  return (
    <>
      <Button
        icon="more-vertical"
        color="main-text"
        onClick={onButtonClick}
        variant="tertiary"
      />
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleFollowUp} disabled={!contactId}>
          Set Follow Up
        </MenuItem>
        <MenuItem onClick={onCall}>
          Call
        </MenuItem>
        {
          email && (
            <MenuItem>
              <a
                href={`mailto:${email}`}
                style={{ color: 'inherit' }}
              >
                Email
              </a>
            </MenuItem>
          )
        }
        <MenuItem>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

OptionsCell.defaultProps = {
  email: '',
};

const {
  func,
  string,
  number,
} = PropTypes;

OptionsCell.propTypes = {
  contactId: number.isRequired,
  onCall: func.isRequired,
  onFollowUp: func.isRequired,
  email: string,
};

export default OptionsCell;
