import React, { Component } from 'react';
import TimerReminder from 'shared/components/TimerReminder';
import TaskCard from 'shared/components/TaskCard';
import moment from 'moment';
import { formatTask } from '../utils/getFormattedTask';

class TaskReminder extends Component {
  componentDidMount() {
    this.props.startTaskReminderInterval();
  }

  render() {
    const {
      task, onOk, onCancel, startedAt,
    } = this.props;
    if (!task) return null;
    const calculatedTime = moment(startedAt).fromNow(true);
    const formattedTask = formatTask(task);
    return (
      <TimerReminder
        title="Still working on this task?"
        subtitle={calculatedTime && `${calculatedTime} spent so far`}
        onOk={onOk}
        onCancel={onCancel}
        visible={!!task}
      >
        <TaskCard {...formattedTask} />
      </TimerReminder>
    );
  }
}

export default TaskReminder;
