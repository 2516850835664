import apiConnector, { endpoints } from 'api';

const remove = async (id) => {
  const endpoint = `${endpoints.client.realtor.documentRequests}/${id}`;

  try {
    const result = await apiConnector.client.delete(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default remove;
