/* eslint-disable import/no-cycle */
import newFormProducts from './newFormProducts';
import newFormValue from './newFormValue';
import newFormProductTitle from './newFormProductTitle';
import preparedFormData from './preparedFormData';
import selected from './selected';
import availableEmployees from './availableEmployees';
import selectedFulfillmentOwner from './selectedFulfillmentOwner';
import filter from './filter';
import selectedFulfillmentSalesperson from './selectedFulfillmentSalesperson';
import selectedFulfillmentSteps from './selectedFulfillmentSteps';
import selectedFulfillmentLineItemDetails from './selectedFulfillmentLineItemDetails';
import selectedFulfillmentProducts from './selectedFulfillmentProducts';
import selectedFulfillmentCancelledProducts from './selectedFulfillmentCancelledProducts';
import selectedFulfillmentReturnedProducts from './selectedFulfillmentReturnedProducts';
import selectedFulfillmentOutOfStockProducts from './selectedFulfillmentOutOfStockProducts';
import byId from './byId';
import fulfillmentMainProcessStepsById from './fulfillmentMainProcessStepsById';
import fulfillmentChangelog from './fulfillmentChangelog';
import kanbanLaneData from './kanbanLaneData';
import selectedFulfillmentProcesses from './selectedFulfillmentProcesses';
import fulfillmentProducts from './fulfillmentProducts';
import fulfillmentCancelledProducts from './fulfillmentCancelledProducts';
import fulfillmentReturnedProducts from './fulfillmentReturnedProducts';
import fulfillmentOutOfStockProducts from './fulfillmentOutOfStockProducts';

export default {
  selectedFulfillmentSalesperson,
  newFormProducts,
  newFormValue,
  newFormProductTitle,
  preparedFormData,
  selected,
  availableEmployees,
  selectedFulfillmentOwner,
  filter,
  selectedFulfillmentSteps,
  selectedFulfillmentLineItemDetails,
  selectedFulfillmentProducts,
  selectedFulfillmentCancelledProducts,
  selectedFulfillmentReturnedProducts,
  selectedFulfillmentOutOfStockProducts,
  byId,
  fulfillmentMainProcessStepsById,
  fulfillmentChangelog,
  kanbanLaneData,
  selectedFulfillmentProcesses,
  fulfillmentProducts,
  fulfillmentCancelledProducts,
  fulfillmentReturnedProducts,
  fulfillmentOutOfStockProducts,
};
