import { connect } from 'react-redux';

import requests from 'api/requests';

import selectors from 'store/selectors';

import {
  openLogOfferModal,
  closeLogOfferModalUniversal,
} from 'store/actions/realtorLogOfferModal';

import LogOfferModalUniversal from './LogOfferModalUniversal';

const mapStateToProps = (state) => {
  const {
    modals: {
      logOfferModalUniversal,
    },
  } = state;

  const getPropertyById = (id) => selectors.properties.byId(state, id);

  return {
    isVisible: logOfferModalUniversal?.isVisible || false,
    getPropertyById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeLogOfferModalUniversal()),

  onConfirm: (propertyId, isMyListings, contactId) => {
    dispatch(closeLogOfferModalUniversal());
    dispatch(openLogOfferModal(
      {
        type: isMyListings ? 'client' : 'prospect',
        propertyId,
        sellerContactId: contactId,
        buyerContactId: null,
        showSellerSelector: true,
        showBuyerSelector: true,
        showBuyerAgentSelector: true,
        showSellerAgentSelector: !isMyListings,
      },
    ));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  isVisible: stateProps.isVisible,
  closeModal: dispatchProps.closeModal,

  onConfirm: async (propertyId) => {
    const property = stateProps.getPropertyById(propertyId);
    const isMyListings = property?.listing_agent === 0;

    let contactId;

    if (isMyListings && property.id) {
      const clientsResponse = await requests.realtor.clients.get({
        offset: 0,
        limit: 1,
        propertyId: property.id,
      });
      const clients = clientsResponse.data.items;
      if (clients[0]) {
        contactId = clients[0].contact_id;
      }
    }

    dispatchProps.onConfirm(propertyId, isMyListings, contactId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LogOfferModalUniversal);
