import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import MainProspectInfo from './MainProspectInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    prospectId,
    variant,
  } = ownProps;

  const prospect = selectors.realtorProspects.byId(state, prospectId);

  const {
    contact_id,
    budget_max,
    budget_min,
    viewings_summary,
    offers_summary,
  } = prospect;

  return {
    contactId: contact_id,
    budgetMax: budget_max,
    budgetMin: budget_min,
    viewingsCount: viewings_summary?.upcoming_viewings,
    offers: offers_summary?.total_offers || 0,
    counters: offers_summary?.total_counters || 0,
    variant,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onCreateViewingClick: (contactId) => {
    dispatch(setNewPropertyViewingFormField('buyer')(contactId));

    dispatch(openNewPropertyViewingModal(null, { property: true, sellerAgent: true }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  onCreateViewingClick: () => {
    const { contactId } = stateProps;
    dispatchProps.onCreateViewingClick(contactId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MainProspectInfo);
