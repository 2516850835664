import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchItemTransaction from 'shared/components/UI/SearchItemTransaction';
import SearchCategoryBlock from 'shared/components/UI/SearchCategoryBlock';

import withTransactionPropertyData from './withTransactionPropertyData';

const SearchItemTransactionModule = withTransactionPropertyData(SearchItemTransaction);

const TransactionsSearch = (props) => {
  const [searchResult, setSearchResult] = useState([]);

  const {
    search,
    onSearch,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const data = await onSearch(search);
      setSearchResult(data);
    };

    fetchData();
  }, [search]);

  return (
    <SearchCategoryBlock
      title="transactions"
      iconName="repeat"
    >
      {
        searchResult && searchResult.map((item) => (
          <SearchItemTransactionModule
            transactionId={item.id}
          />
        ))
      }
    </SearchCategoryBlock>
  );
};

TransactionsSearch.defaultProps = {
  search: '',
};

const {
  func,
  string,
} = PropTypes;

TransactionsSearch.propTypes = {
  search: string,
  onSearch: func.isRequired,
};

export default TransactionsSearch;
