import styled from 'styled-components';

import Button from 'ui/Button';

export const StyledButton = styled(Button)`
  margin-top: 6px;
  margin-bottom: 0;
`;

export default {
  StyledButton,
};
