import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: baseline;
  & > * {
    margin: 4px;
  }
`;

export const Title = styled.span`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 25, 52, 0.87)
`;

export const ResetButton = styled.span`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #ff6446;
  &:hover {
    text-decoration: underline;
  }
`;

export default {
  Root,
  Title,
  ResetButton,
};
