import {
  includes,
  isEmpty,
} from 'shared/utility';

import {
  createAddress,
} from 'store/actions/addresses';

import { // eslint-disable-line import/no-cycle
  createContact,
} from 'store/actions/realtorContacts';

import createLead from './createLead';
import createPropertyRequirements from './createPropertyRequirements';
import createPropertyDetails from './createPropertyDetails';
import getLeadById from './getLeadById';
import getLeadByContactId from './getLeadByContactId';
import addLeadRelations from './addLeadRelations';
import getOverallLeadsSummary from './getOverallLeadsSummary';

const tempIds = {
  1: 1, // cold
  2: 2, // warm
  3: 3, // hot
};

const createLeadFromNewLeadForm = () => async (dispatch, getState) => {
  const {
    realtorNewLeadForm,
  } = getState();

  const isSeller = includes(realtorNewLeadForm.types, 1);
  const isBuyer = includes(realtorNewLeadForm.types, 2);
  const isNewUser = isEmpty(realtorNewLeadForm.selectedContact);

  // const propertyAddressId = await dispatch(createAddress(realtorNewLeadForm.propertyAddress));
  const propertyAddressId = realtorNewLeadForm.propertyAddress;
  const preferredAddressId = await dispatch(createAddress(realtorNewLeadForm.preferredLocation));

  const propertyDetailsData = {
    address_id: propertyAddressId,
    property_type: realtorNewLeadForm.sellerPropertyType,
    expected_price: realtorNewLeadForm.expectedSalePrice,
    bedrooms: realtorNewLeadForm.propertyDetails.bedrooms,
    bathrooms: realtorNewLeadForm.propertyDetails.bathrooms,
    parking: realtorNewLeadForm.propertyDetails.garageCapacity,
    floor_area: realtorNewLeadForm.propertyDetails.floorArea,
    plot_area: realtorNewLeadForm.propertyDetails.plotArea,
    temp: realtorNewLeadForm.temp || null,
  };

  const propertyRequirementsData = {
    addresses: [preferredAddressId],
    budget_min: realtorNewLeadForm.expectedPurchasePrice.min,
    budget_max: realtorNewLeadForm.expectedPurchasePrice.max,
    bedrooms_min: realtorNewLeadForm.propertyRequirements.bedrooms,
    bedrooms_max: null,
    bathrooms_min: realtorNewLeadForm.propertyRequirements.bathrooms,
    bathrooms_max: null,
    parking_min: realtorNewLeadForm.propertyRequirements.garageCapacity,
    parking_max: null,
    floor_area_min: realtorNewLeadForm.propertyRequirements.floorArea,
    floor_area_max: null,
    plot_area_min: realtorNewLeadForm.propertyRequirements.plotArea,
    plot_area_max: null,
    property_type: realtorNewLeadForm.buyerPropertyType.map((value) => Number(value)),
  };

  const contactData = {
    title: realtorNewLeadForm.personTitle,
    first_name: realtorNewLeadForm.personFirstName,
    last_name: realtorNewLeadForm.personLastName,
    email_primary: realtorNewLeadForm.mainEmail,
    phone_primary: realtorNewLeadForm.mainPhoneNumber,
    source_id: realtorNewLeadForm.source,
    first_contact_date: realtorNewLeadForm.firstContactDate,
    role: null,
    facebook_url: null,
    linkedin_url: null,
    instagram_url: null,
    twitter_url: null,
    website_url: null,
    is_past_client: realtorNewLeadForm.isPastClient,
    type: isBuyer && isSeller
      ? [13]
      : isBuyer
        ? [11]
        : isSeller
          ? [12]
          : [],
  };

  let propertyId = null;
  let propertyRequirementId = null;
  let contactId = null;
  let leadId = null;

  try {
    if (isSeller) {
      propertyId = await dispatch(createPropertyDetails(propertyDetailsData));
    }

    if (isBuyer) {
      propertyRequirementId = await dispatch(createPropertyRequirements(propertyRequirementsData));
    }

    if (isNewUser) {
      contactId = await dispatch(createContact(contactData));
    } else {
      contactId = realtorNewLeadForm.selectedContact.id;
    }

    const properties = propertyId ? [propertyId] : null;
    const property_requirements = propertyRequirementId ? [propertyRequirementId] : null;

    const leadData = {
      temp: tempIds[realtorNewLeadForm.temp] ? tempIds[realtorNewLeadForm.temp] : null,
      status: 1,
      contact_id: contactId,

      properties,
      property_requirements,
    };

    if (isNewUser) {
      leadId = await dispatch(createLead(leadData));
    } else {
      const lead = await dispatch(getLeadByContactId(contactId));

      if (lead) {
        leadId = lead.id;

        if (isSeller) {
          dispatch(addLeadRelations('properties')(leadId)(properties));
        }

        if (isBuyer) {
          dispatch(addLeadRelations('propertyRequirements')(leadId)(property_requirements));
        }
      } else {
        leadId = await dispatch(createLead(leadData));
      }
    }

    await dispatch(getLeadById(leadId));
    dispatch(getOverallLeadsSummary());

    return leadId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createLeadFromNewLeadForm;
