import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Sidebar from './Sidebar';

const mapStateToProps = (state) => ({
  ticket: state.externalStore.ticketFromUrl,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Sidebar);
