import { createSelector } from 'reselect';

import moment from 'moment';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

import {
  optimizedAddressesActions,
} from 'store/actions/addresses';

import propertyImages from 'store/selectors/properties/propertyImages'; // eslint-disable-line import/no-cycle

const fieldsObj = {
  initial_documents_expires_at: {
    noteField: 'initial_documents_notes',
    label: 'Initial Document',
  },
  seller_disclosure_expires_at: {
    noteField: 'seller_disclosure_notes',
    label: 'Seller Disclosure',
  },
  inspections_expires_at: {
    noteField: 'inspections_notes',
    label: 'Inspections',
  },
  appraisal_expires_at: {
    noteField: 'appraisal_notes',
    label: 'Appraisal',
  },
  loan_expires_at: {
    noteField: 'loan_notes',
    label: 'Loan',
  },
  final_walkthrough_expires_at: {
    noteField: 'final_walkthrough_notes',
    label: 'Final Walkthrough',
  },
  close_escrow_expires_at: {
    noteField: 'close_escrow_notes',
    label: 'Close Escrow',
  },
  move_out_date_expires_at: {
    noteField: 'move_out_date_notes',
    label: 'Move Out',
  },
};

export default createSelector(
  (state) => state,
  (state) => state.realtorTransactions.transactions,
  (state, filterId) => state.realtorTransactions.transactionsByFilter[filterId],
  (state) => state.properties.properties,
  (state) => state.addresses.addresses,
  (state, filterId, transactionTypes) => transactionTypes,
  (state, filterId, transactionTypes, date) => date, // eslint-disable-line max-params
  (state, filterId, transactionTypes, date, comparePeriod) => comparePeriod, // eslint-disable-line max-params
  (
    state,
    transactions,
    filteredIds,
    properties,
    addresses,
    transactionTypes,
    date = new Date(),
    comparePeriod = 'day',
  ) => { // eslint-disable-line max-params
    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((acc, transactionId) => {
      const deal = transactions[transactionId];

      if (!deal && transactionId) {
        optimizedRealtorTransactionsActions.getTransactionById(transactionId);
        return acc;
      }

      const { realtor_property_id } = deal;

      const property = properties[realtor_property_id];

      if (!property && realtor_property_id) {
        optimizedPropertyActions.getPropertyById(realtor_property_id);
        return acc;
      }

      const address_id = property?.address_id;

      const propertyAddress = addresses[address_id];

      if (!propertyAddress) {
        if (address_id) {
          optimizedAddressesActions.getAddressById(address_id);
        }
        return acc;
      }

      const { address } = propertyAddress;

      const images = propertyImages(state, realtor_property_id) || [];

      const attachment = images.find((image) => image.isMain) || images[0];

      transactionTypes.forEach((field) => {
        const {
          label,
          noteField,
        } = fieldsObj[field];

        if (deal[field] && moment(date).isSame(deal[field], comparePeriod)) {
          acc.push({
            labels: [{ title: label, variant: 'followup' }],
            description: deal[noteField] ? deal[noteField] : `${label} for ${address} expires today`,
            attachment,
            date: deal[field],
            objectType: field,
            objectId: transactionId,
          });
        }
      });

      return acc;
    }, []);

    return result;
  },
);
