import styled from 'styled-components';

import Elevation from 'ui/Elevation';

export const Root = styled.div`
  height: 100%;

  .today-summary-selector {
    border: none;
    margin-bottom: 16px;
  }

  .today-summary-selector-item {
    border-radius: 10px;
    height: 60px;
  }
`;

export const PrioritiesElevation = styled(Elevation)`
  height: 100%;
  padding: 16px 16px 0 16px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 400px;
`;

export const ScheduleElevation = styled(Elevation)`
  overflow: hidden;
  padding-top: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 45px;
  margin-bottom: 10px;
`;

export default {
  Root,
  TitleContainer,
  PrioritiesElevation,
  ScheduleElevation,
};
