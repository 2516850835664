/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

const OptionsCell = (props) => {
  const {
    remove,
    archive,
  } = props;

  const onDelete = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    remove();
    closeMenu();
  }, [
    remove,
  ]);

  const onArchive = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    archive();
    closeMenu();
  }, [
    archive,
  ]);

  return (
    <OptionsMenu {...props}>
      {
        ({ closeMenu }) => (
          <>
            <MenuItem onClick={onArchive(closeMenu)}>
              Archive contact
            </MenuItem>
            <MenuItem onClick={onDelete(closeMenu)}>
              Delete contact
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

const {
  func,
} = PropTypes;

OptionsCell.propTypes = {
  remove: func.isRequired,
  archive: func.isRequired,
};

export default OptionsCell;
