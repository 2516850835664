import store, { history } from 'store';

import {
  optimizedRealtorContactsActions,

  deleteContact,
} from 'store/actions/realtorContacts';

const remove = async (contactId) => {
  await store.dispatch(deleteContact(contactId));
  optimizedRealtorContactsActions.getContactsForStoreFilter();
  history.push('/contacts');
};

export default remove;
