import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { createRepairRequest } from 'store/actions/realtorRepairRequests';

import { uploadAttachment } from 'store/actions/attachmentsV2';
import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import {
  getTransactionById,
} from 'store/actions/realtorTransactions';

import NewRepairRequestForm from './NewRepairRequestForm';

const mapStateToProps = (state, ownProps) => {
  const { filterId } = selectors.realtorInspections.inspectionsFilter(state);
  const inspectionsList = selectors.realtorInspections.inspectionsList(state, filterId);

  const {
    newRealtorRepairRequestForm: {
      transactionId,
      defaultRequestType,
      defaultDescription,
      defaultEstimatedValue,

      attachments,

      onBeforeSubmit,
      onAfterSubmit,
    },
  } = state;

  return {
    transactionId: transactionId || ownProps.transactionId,
    defaultRequestType,
    defaultDescription,
    defaultEstimatedValue,

    onBeforeSubmit,
    onAfterSubmit,

    attachments,
    inspectionCount: inspectionsList.length,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logRequest: async (data) => {
    const {
      title,
      description,
      value,
      realtor_deal_id,
      attachments,
      inspection_id,
    } = data;

    const requestId = await dispatch(
      createRepairRequest({
        title,
        description,
        value,
        realtor_deal_id,
        inspection_id,
      }),
    );

    // upload attachments

    const uploadAttachmentsRequests = attachments.map(async (item) => {
      const {
        url: attachmentUrl,
        name: attachmentName,
        type: attachmentType,
      } = item;

      const file = await dataUrlToFile(
        attachmentUrl,
        attachmentName,
        attachmentType,
      );

      const attachmentsData = {
        file,
        objectId: requestId,
        objectType: 36,
        attachmentType: 12,
      };

      await dispatch(uploadAttachment(attachmentsData));
    });

    await Promise.all(uploadAttachmentsRequests);

    return requestId;
  },

  updateTransaction: (transactionId) => {
    dispatch(getTransactionById(transactionId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: async (data) => {
    const requestId = await dispatchProps.logRequest({
      ...data,

      realtor_deal_id: stateProps.transactionId || ownProps.transactionId,
      attachments: stateProps.attachments,
    });

    if (stateProps.onAfterSubmit) {
      stateProps.onAfterSubmit(requestId);
    }

    if (ownProps.onAfterSubmit) {
      ownProps.onAfterSubmit(requestId);
    }

    dispatchProps.updateTransaction(stateProps.transactionId);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NewRepairRequestForm);
