import requests from 'api/requests';

import setContactChangelog from './setContactChangelog';

const getContactChangelog = (contactId) => (from, to) => async (dispatch) => {
  try {
    const response = await requests.crm.contacts.getChangelog(contactId)(from, to);

    dispatch(setContactChangelog({
      contactId,
      changelog: response.data,
    }));
  } catch (error) {
    console.error(error);
  }
};

export default getContactChangelog;
