import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Button from 'ui/Button';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';

import useContact from 'sections/Contacts/hooks/useContact';
import useModal from 'ui/hooks/useModal';

import RelatedContactCard from './ContactCard';
import RelationStatusSelect from './RelationStatusSelect';

import {
  Wrapper,
} from './styledItems';

const AssociatedContacts = (props) => {
  const {
    contactId,
    onAdd,
    onRemove,
    getContact,
  } = props;

  useEffect(() => {
    getContact();
  }, [
    contactId,
  ]);

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const contact = useContact(contactId);

  const [newRelationStatus, setNewRelationStatus] = useState(null);

  const onSave = useCallback((selectedContacts) => {
    onAdd(selectedContacts?.addedContacts?.[0], newRelationStatus);
    setNewRelationStatus(null);
    closeModal();
  }, [
    onAdd,
    closeModal,
    newRelationStatus,
  ]);

  const {
    related_contacts: relatedContacts = [],
  } = contact;

  return (
    <Wrapper>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        fullWidth
        style={{ marginBottom: 10 }}
      >
        <Typography
          variant="title2"
          tag="h4"
          noMargin
        >
          Associated Contacts
        </Typography>

        <Button
          variant="tertiary"
          icon="plus"
          iconColor="focus"
          onClick={openModal}
        />
      </Flex>

      <Flex
        flexDirection="column"
        spacing={2}
        fullWidth
      >
        {
          Array.isArray(relatedContacts) && relatedContacts.map((item) => (
            <FlexItem fullWidth>
              <RelatedContactCard
                contactId={item.related_contact_id}
                relationStatusId={item.relation_status_id}
                onRemove={onRemove(item.relation_id)}
              />
            </FlexItem>
          ))
        }
      </Flex>

      <AssignContactModal
        title="Link Associated Contact"
        description="Related contacts will be shown on both the original and related contact’s profile pages."
        isVisible={isModalVisible}
        oneContactSelect
        withAddContact
        closeModal={closeModal}
        onSave={onSave}
        extraField={{
          ExtraFieldComponent: RelationStatusSelect,
          onChange: setNewRelationStatus,
          value: newRelationStatus,
        }}
      />
    </Wrapper>
  );
};

const {
  number,
  func,
} = PropTypes;

AssociatedContacts.propTypes = {
  contactId: number.isRequired,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  getContact: func.isRequired,
};

export default AssociatedContacts;
