import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import PersonItem from './PersonItem';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.basicById(state, contactId);

  return {
    contactId,
    firstName: contact?.name,
    lastName: contact?.surname,
    avatarUrl: getAvatarUrl(contact?.avatar),
  };
};

export default connect(mapStateToProps)(PersonItem);
