import Modal from './components/Modal';
import Close from './components/Close';
import Title from './components/Title';
import Content from './components/Content';
import './style.css';

Modal.Close = Close;
Modal.Title = Title;
Modal.Content = Content;

export default Modal;
