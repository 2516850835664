import {
  SET_REALTOR_VIEWINGS_FOR_FILTER,
} from 'store/actionTypes/propertyViewings';

const setViewingsForFilter = (filterId) => (viewings) => ({
  type: SET_REALTOR_VIEWINGS_FOR_FILTER,
  payload: {
    filterId,
    viewings,
  },
});

export default setViewingsForFilter;
