/* eslint-disable */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Spin
} from 'antd'

import ImageCrop from 'shared/components/ImageCrop'
import ImageUploadControls from 'shared/components/ImageUploadControls'

import { 
  CropWrapper,
  SpinWrapper,
} from './styledItems'

class ImageUploadWithPreview extends Component {
  constructor (props) {
    super(props)

    const {
      defaultSrc
    } = this.props

    this.state = {
      uncroppedImageUrl: null,
      isImageLoading: false,
      isShowRemoveButton: !!defaultSrc,
    }
  }

  onAdd = (image) => {
    const {
      onImageChange
    } = this.props
 
    const file = image.file

    const fr = new FileReader();

    this.setState({
      isImageLoading: true
    })
    
    fr.onload = () => {
      this.setState({
        uncroppedImageUrl: fr.result,
      })

      onImageChange({
        imageFile: file,
        imageUrl: fr.result
      })
    }

    fr.readAsDataURL(file)
  }

  onCrop = ({imageFile, imageUrl}) => {
    const {
      onImageChange
    } = this.props

    onImageChange({
      imageFile,
      imageUrl
    })
  }

  onImageLoaded = () => {
    this.setState({
      isImageLoading: false,
      isShowRemoveButton: true
    })
  }

  onRemove = () => {
    const {
      onImageChange,
    } = this.props

    this.setState({
      uncroppedImageUrl: null,
      isShowRemoveButton: false
    })
    
    onImageChange({
      imageUrl: null
    })
  }

  render () {
    const {
      circularCrop,
      hideCrop,
      defaultSrc,
      placeholder,
    } = this.props

    const {
      uncroppedImageUrl,
      isImageLoading,
      isShowRemoveButton,
    } = this.state

    const src = uncroppedImageUrl || defaultSrc || placeholder
    const noCrop = hideCrop || !uncroppedImageUrl

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <CropWrapper>
          {
            noCrop && (
              <div style={{ 
                width: "100%",
                height: "100%",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundImage: `url(${src})`
              }}/>
            )
          }
          {
            !noCrop && (
              <ImageCrop
                src={src}
                onCrop={this.onCrop}
                circularCrop={circularCrop}
                onImageLoaded={this.onImageLoaded}
              />
            )
          }
          {
            isImageLoading && (
              <SpinWrapper>
                <Spin size="large" />
              </SpinWrapper>
            )
          }
        </CropWrapper>
        
        <ImageUploadControls 
          onAdd={this.onAdd}
          onRemove={this.onRemove}
          isShowRemoveButton={isShowRemoveButton}
          // showSystemUpload={showSystemUpload}
        />
      </div>
    )
  }
}


ImageUploadWithPreview.defaultProps = {
  defaultSrc: null,
  hideCrop: false,
}

const {
  bool,
  func,
  string,
} = PropTypes

ImageUploadWithPreview.propTypes = {
  defaultSrc: string,
  hideCrop: bool,
  onImageChange: func.isRequired,
  placeholder: string.isRequired,
}

export default ImageUploadWithPreview
