import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import { Root } from './styledItems';

const Avatar = (props) => {
  const {
    src,
    round,
  } = props;

  return (
    <div>
      <Root
        src={src}
        size={110}
        round={round}
      >
        {
          !src && (
            <Icon
              name="user"
              color="focus"
              size="m"
            />
          )
        }
      </Root>
    </div>
  );
};

Avatar.defaultProps = {
  src: null,
  round: false,
};

const {
  bool,
  string,
} = PropTypes;

Avatar.propTypes = {
  /**
   * image source
   */
  src: string,
  round: bool,
};

export default Avatar;
