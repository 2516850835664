import BaseApiConnector from 'api/connectors/BaseApiConnector';

const globalApiUrl = process.env.REACT_APP_SYMPLETE_GLOBAL_API;

class GlobalApiConnector extends BaseApiConnector {
  constructor() {
    super(globalApiUrl);
  }
}

export default GlobalApiConnector;
