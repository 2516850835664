/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
} from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import SalePriceInput from 'shared/inputs/SalePriceInput';

const Price = (props) => {
  const {
    control,
    errors,
  } = props;

  return (
    <Controller
      name="price"
      control={control}
      render={({ field }) => (
        <SalePriceInput
          {...field}
          value={field.value}
          fullWidth
          handleChange={field.onChange}
          label="Price"
          error={!!errors.price}
          helperText={errors.price ? 'This field is required' : ''}
        />
      )}
      rules={{
        required: true,
        min: 1,
      }}
    />
  );
};

Price.defaultProps = {
  price: null,
};

const {
  shape,
  number,
} = PropTypes;

Price.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  price: number,
};

export default Price;
