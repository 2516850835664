import {
  SET_PROPERTIES_FOR_FILTER_COUNT,
} from 'store/actionTypes/properties';

const setPropertiesForFilterCount = (filterId) => (count) => ({
  type: SET_PROPERTIES_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setPropertiesForFilterCount;
