import {
  SET_REALTOR_INFINITE_SCROLL_TRANSACTIONS_FOR_FILTER,
} from 'store/actionTypes/realtorTransactions';

const setInfiniteScrollTransactionsForFilter = (filterId) => (transactions) => ({
  type: SET_REALTOR_INFINITE_SCROLL_TRANSACTIONS_FOR_FILTER,
  payload: {
    filterId,
    transactions,
  },
});

export default setInfiniteScrollTransactionsForFilter;
