import apiConnector from 'api';

const getNumOfWeeksFromOldestCompletedTask = async (query) => {
  const endpoint = '/api/v2/getNumOfWeeksFromOldestCompletedTask';

  try {
    const result = await apiConnector.client.get(endpoint, query);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getNumOfWeeksFromOldestCompletedTask;
