import React from 'react';

const initialSteps = [
  {
    name: 'tutorial-reset-password',
    target: '[data-tutorial="reset-pass-create-user"]',
    title: 'Reset user password',
    content: (
      <ol>
        <li>
          Click into an employee from the Team menu or the Company Settings&nbsp;
          &gt; User Management menu.
        </li>
        <li>Click the actions button and click reset password.</li>
      </ol>),
    disableBeacon: true,
    placement: 'right',
    manager: true,
  },
  {
    name: 'tutorial-delete-user',
    target: '[data-tutorial="delete-user"]',
    title: 'Delete a user',
    content: (
      <ol>
        <li>
          Click into an employee from the Team menu or the Company Settings &gt; User Management menu.
        </li>
        <li>Click the status dropdown on the left side.</li>
        <li>Select deleted.</li>
        <li>Deleted employees can be seen using the toggle on the Employee list view.</li>
      </ol>),
    disableBeacon: true,
    placement: 'right',
    manager: true,
  },
  {
    name: 'tutorial-assign-remove-licenses',
    target: '[data-tutorial="assign-remove-licenses"]',
    title: 'Assign / remove licenses',
    content: (
      <ol>
        <li>
          Click into an employee from the Team menu or go to Company Settings &gt; User Management.
        </li>
        <li>
          Click the licenses that you wish to add to the employee.&nbsp;
          (if no licenses are available you can purchase them in app.)
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'right-end',
    manager: true,
  },
  {
    name: 'tutorial-create-user',
    target: '[data-tutorial="reset-pass-create-user"]',
    title: 'Create a Symplete user',
    content: (
      <ol>
        <li>Click into an employee from the Team menu or Company Settings &gt; User Management.</li>
        <li>Use the filters on the left to see relevant data.</li>
        <li>
          Click the Make User button and select the licenses you would like to assign to the user.
          &nbsp;(if no licenses are available, more can be easily purchased in app.)
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'right',
    manager: true,
  },
  {
    name: 'tutorial-buy-cancel-licenses',
    target: '[data-tutorial="buy-cancel-licenses"]',
    title: 'Buy and cancel licenses',
    content: (
      <ol>
        <li>Click into Company Settings &gt; User Management.</li>
        <li>Click the Add / Remove Licenses button.</li>
        <li>
          Select which product licenses you would like to add or remove from your account.
          <br />
          Note: only unassigned licenses can be removed.
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'top-end',
    offset: 40,
    admin: true,
  },
];

export default initialSteps;
