/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Spin } from 'antd';

import Grid from 'ui/Grid';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import DateField from 'sections/Opportunities/components/DateField';

import AdditionalNotesInput from 'sections/Opportunities/components/AdditionalNotesInput';
import PropertyDetails from 'shared/modules/PropertyDetails';
import ContactCard from 'shared/modules/ContactCard';
import LessContactCard from 'shared/modules/LessContactCard';
import Price from './Price';
import Contingencies from './Contingencies';
import AttachmentsUpload from './AttachmentsUpload';
import SpecialConditions from './SpecialConditions';

import {
  MarginContainer,
  SectionWrapper,
} from './styledItems';

const NewCounterForm = (props) => {
  const {
    onSubmit: propsOnSubmit,
    buyerId,
    buyerAgentId,
    formId,
    originalOffer,
    specialConditions: propsSpecialConditions,

    contactId,
    contactType,
    propertyId,
    date,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [specialConditions, setSpecialConditions] = useState(null);

  const handleSpecialConditionsChange = useCallback((newValue) => {
    setSpecialConditions(newValue);
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      contingency_period_end: originalOffer?.contingency_period_end,
      closing_date: originalOffer?.closing_date,
      expiry_date: originalOffer?.expiry_date,
      move_out_date: originalOffer?.move_out_date,
      price: originalOffer?.amount,
      contingency_inspection: originalOffer?.contingency_inspection,
      contingency_loan: originalOffer?.contingency_loan,
      contingency_appraisal: originalOffer?.contingency_appraisal,
    },
  });

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await propsOnSubmit({ ...data, specialConditions });
    reset();
    setIsLoading(false);
  }, [
    propsOnSubmit,
    reset,
    specialConditions,
  ]);

  useEffect(() => {
    reset({
      contingency_period_end: originalOffer?.contingency_period_end,
      closing_date: originalOffer?.closing_date,
      expiry_date: originalOffer?.expiry_date,
      move_out_date: originalOffer?.move_out_date,
      price: originalOffer?.amount,
      contingency_inspection: originalOffer?.contingency_inspection,
      contingency_loan: originalOffer?.contingency_loan,
      contingency_appraisal: originalOffer?.contingency_appraisal,
    });
  }, [
    originalOffer?.id,
  ]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Spin spinning={isLoading} delay={600}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="check-circle"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Property Details
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <PropertyDetails
                contactId={contactId}
                contactType={contactType}
                propertyId={propertyId}
              />
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="dollar-sign"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Counter Details
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <Flex
                spacing={2}
                flexDirection="column"
              >
                <Price
                  control={control}
                  errors={errors}
                />

                <ContactCard
                  contactId={buyerAgentId}
                  date={date}
                />

                <LessContactCard
                  contactId={buyerId}
                  role="Buyer"
                />

                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="expiry_date"
                  label="Expiry Date:"
                  variant="inline"
                />
              </Flex>
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="search"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Contingencies
              </Typography>
            </DecoratedFieldWrapper>
            <SectionWrapper>
              <Contingencies
                contingencies={originalOffer}
                control={control}
              />
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="clock"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Deadlines
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <Flex
                spacing={2}
                flexDirection="column"
              >
                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="contingency_period_end"
                  label="Contingency Period:"
                  variant="inline"
                />

                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="closing_date"
                  label="Closing Date:"
                  variant="inline"
                />

                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="move_out_date"
                  label="Move Out Date:"
                  variant="inline"
                />
              </Flex>
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="clipboard"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Special Conditions
              </Typography>
            </DecoratedFieldWrapper>
            <SectionWrapper>
              <SpecialConditions
                value={specialConditions || propsSpecialConditions}
                onChange={handleSpecialConditionsChange}
              />
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="edit3"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Final Signed Agreement
              </Typography>
            </DecoratedFieldWrapper>
            <SectionWrapper>
              <Flex
                spacing={2}
                flexDirection="column"
                alignItems="stretch"
              >
                <AttachmentsUpload />
                <AdditionalNotesInput control={control} />
              </Flex>
            </SectionWrapper>
          </Grid>

        </Grid>
      </Spin>
    </form>
  );
};

NewCounterForm.defaultProps = {
  price: null,
};

const {
  func,
  string,
  number,
} = PropTypes;

NewCounterForm.propTypes = {
  onSubmit: func.isRequired,
  price: number,
  formId: string.isRequired,
  buyerId: number.isRequired,
  buyerAgentId: number.isRequired,
};

export default NewCounterForm;
