/* eslint-disable react/jsx-no-bind */

import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form, Drawer, Tooltip, List, Button, Input,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OACallContext from 'components/OACall/OACallContext';
import UserAvatar from 'shared/components/UserAvatarV2';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const VideoChatDrawer = (props) => {
  const {
    form,
  } = props;

  const currentUser = useSelector((state) => state.auth.loggedUser);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const {
    callUser,
    inviteUsers,
    onlineUsers,
    isUserAlreadyInMeeting,
  } = useContext(OACallContext);

  const openDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  const handleCallUser = useCallback((event) => {
    const employeeId = Number(event.target.dataset.employeeId);

    closeDrawer();
    callUser(employeeId);
  }, [closeDrawer, callUser]);

  const handleInviteUsers = useCallback((event) => {
    event.preventDefault();

    form.validateFields((errors, values) => {
      if (errors) return;

      const emailsValidation = validateEmailList(values.emails);

      if (!emailsValidation.isValid) {
        form.setFields({
          emails: {
            value: values.emails,
            errors: [new Error('Emails list is invalid.')],
          },
        });
      } else {
        form.resetFields();
        inviteUsers(emailsValidation.parsedList);
      }
    });
  }, [form, inviteUsers]);

  const availableUsers = onlineUsers.filter((user) => (
    user.EmployeeID !== currentUser.EmployeeID
    && !isUserAlreadyInMeeting(user.EmployeeID)
  ));

  return (
    <>
      <Tooltip
        placement="bottom"
        title="Select user to call."
      >
        <FontAwesomeIcon
          icon="video"
          onClick={openDrawer}
          style={{
            color: '#1e2a37',
            margin: '8px 12px 4px 6px',
          }}
        />
      </Tooltip>

      <Drawer
        title="Online users"
        placement="right"
        width="400"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        className="video-chat-drawer"
      >
        <Form onSubmit={handleInviteUsers}>
          <Form.Item>
            {
              form.getFieldDecorator('emails', {
                rules: [
                  {
                    required: true,
                    message: 'Enter at least one email address.',
                  },
                ],
              })(<Input
                placeholder="Enter comma separated email addresses."
              />)
            }
          </Form.Item>
        </Form>

        <List
          itemLayout="horizontal"
          dataSource={availableUsers}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                className="video-chat-drawer__list-meta"
                avatar={(
                  <UserAvatar
                    size="s"
                    userId={item.EmployeeID}
                    userType={1}
                    noBadge
                  />
                )}
                title={item.Email}
              />
              <Button
                type="primary"
                data-employee-id={item.EmployeeID}
                onClick={handleCallUser}
              >
                Call
              </Button>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

const validateEmailList = (raw) => {
  const list = raw.split(',');

  const formattedList = list.reduce((collection, email) => {
    const formattedEmail = email.replace(/\s/g, '');

    if (formattedEmail) {
      collection.push(formattedEmail);
    }

    return collection;
  }, []);

  const invalidEmail = formattedList.find((email) => (
    !EMAIL_REGEX.test(email)
  ));

  const isListValid = !invalidEmail;

  return {
    isValid: isListValid,
    parsedList: formattedList,
  };
};

VideoChatDrawer.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create({ name: 'invite-users-form-drawer' })(VideoChatDrawer);
