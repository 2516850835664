import apiConnector, { endpoints } from 'api';

const searchArticles = async (text) => {
  const endpoint = `${endpoints.client.articles.articleGeneral}`;

  const result = await apiConnector.client.get(endpoint, {
    searchText: text,
    noOffset: true,
  });

  return result.data;
};

export default searchArticles;
