import {
  SET_ACTIVE_MEETING,
} from 'store/actionTypes/video-chat';

const setActiveMeeting = (payload) => ({
  type: SET_ACTIVE_MEETING,
  payload,
});

export default setActiveMeeting;
