import styled from 'styled-components';

export const Label = styled.div`
  height: 30px;
  cursor: pointer;
  background: ${(props) => props.theme.palette[props.status]};
  border-radius: 15px;
  padding: 0 16px;
  text-align: center;
  display: flex;
  .property-status-label {
    margin-top: auto;
    margin-bottom: auto;
    text-transform: capitalize;
  }
`;

export default {
  Label,
};
