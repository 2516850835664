import {
  RESET_REALTOR_MATCHING_PROPERTIES_FILTERS,
  RESET_REALTOR_MATCHING_PROPERTIES_FILTER_COUNTERS,
} from 'store/actionTypes/realtorMatchingProperties';

const resetFilterBytId = () => (dispatch) => {
  dispatch({
    type: RESET_REALTOR_MATCHING_PROPERTIES_FILTERS,
  });

  dispatch({
    type: RESET_REALTOR_MATCHING_PROPERTIES_FILTER_COUNTERS,
  });
};

export default resetFilterBytId;
