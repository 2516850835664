import requests from 'api/requests';

import setInvoice from './setInvoice';
import getInvoiceAttachments from './getInvoiceAttachments';

const getInvoiceById = (id) => async (dispatch) => {
  try {
    const response = await requests.customerInvoices.getById(id);
    dispatch(setInvoice(response.data));
    dispatch(getInvoiceAttachments(id));
  } catch (error) {
    console.error(error);
  }
};

export default getInvoiceById;
