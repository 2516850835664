import styled from 'styled-components';

export const EditableTextContainer = styled.div`
  .displayed-value {
    display: ${({ showEditor }) => (showEditor ? 'none' : 'block')};
    margin: 0px;

    ${(props) => props.isBordered
       && `
          padding-left: 10px;
          padding-top: 7px;
          border-radius: 5px;
          border: solid 1px #e1e1e1;
        `}
  }

  .text-editor {
    display: ${({ showEditor }) => (showEditor ? 'block' : 'none')};
    .ant-input {
      box-shadow: none;
      border: none;
    }
  }
`;

export const RichTextEditorContainer = styled.div`
  .displayed-value {
    ${(props) => props.isBordered
       && `
          padding-left: 10px;
          padding-top: 7px;
          border-radius: 5px;
          border: solid 1px #e1e1e1;
        `}
  }
`;
