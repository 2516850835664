import { createSelector } from 'reselect';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.newFulfillmentForm.products,
  (products, selectedProducts) => {
    if (!selectedProducts) return null;

    const titles = Object.values(selectedProducts).map((product) => {
      const dataObject = products[product.id];

      if (!dataObject) {
        optimizedCrmProductsActions.getProductById(product.id);
      }

      return dataObject?.name;
    });

    return titles.join(', ');
  },
);
