import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import {
  ResponseArea,
} from './styledItems';

const Heading = ({ children }) => (
  <Typography
    variant="h2"
    tag="h3"
    noMargin
  >
    {children}
  </Typography>
);

const RequestItemDetails = (props) => {
  const {
    response,
    createdAt,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container justify="space-between" alignItems="baseline">
          <Grid item>
            <Heading>
              Response
            </Heading>
          </Grid>

          {
            createdAt && (
              <Grid item>
                <Typography variant="label" tag="p" color="ghost">
                  {moment(createdAt).format('MMM DD, YYYY HH:MMa')}
                </Typography>
              </Grid>
            )
          }
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ResponseArea>
          <Typography>
            {response}
          </Typography>
        </ResponseArea>
      </Grid>
    </Grid>
  );
};

RequestItemDetails.defaultProps = {
  response: null,
  createdAt: null,
};

const {
  string,
} = PropTypes;

RequestItemDetails.propTypes = {
  response: string,
  createdAt: string,
};

export default RequestItemDetails;
