import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_LEADS_FILTER_ITEM,
  RESET_REALTOR_LEADS_FILTER_ITEM,
  CLEAR_REALTOR_LEADS_FILTER,
} from 'store/actionTypes/realtorLeads';

import { setValue } from 'store/utils';

const initialState = {
  sortBy: 'id',
  sortDirection: 'desc',
  pageNumber: 1,
  pageSize: 10,

  // lead details
  status: null,
  source: [],
  leadType: [],
  leadStatus: [],
  createdAtRange: [],
  temp: [],
  propertyValueRange: [],
};

const reducers = {
  [SET_REALTOR_LEADS_FILTER_ITEM]: setValue,

  [RESET_REALTOR_LEADS_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload]: initialState[action.payload],
  }),

  [CLEAR_REALTOR_LEADS_FILTER]: (state, action) => initialState,
};

function realtorLeadsFilter(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default realtorLeadsFilter;
