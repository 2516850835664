import axios from 'axios';
import { endpoints } from 'api';

const clientApiUrl = process.env.REACT_APP_SYMPLETE_CLIENT_API;

const axiosInstance = axios.create({
  baseURL: clientApiUrl,
});

const picture = async ({ contactId, tokenId }) => {
  const endpoint = `${endpoints.client.integrations.contacts}/${contactId}/picture?tokenId=${tokenId}`;
  const token = localStorage.getItem('token');

  try {
    const result = await axiosInstance.get(
      endpoint,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: token,
        },
      },
    );

    if (!result.data) {
      return null;
    }

    const blob = new Blob([result.data]);
    const imageUrl = URL.createObjectURL(blob);

    return imageUrl;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default picture;
