import apiConnector, { endpoints } from 'api';

const claim = async (leadId) => {
  const endpoint = `${endpoints.client.crm.leadActions}/claim/${leadId}`;

  try {
    const result = await apiConnector.client.post(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default claim;
