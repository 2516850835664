import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import EmployeeRepository from 'shared/repositiores/employee/employee';
import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import ImageUploadWrapper from 'shared/components/ImageUploadWrapper'; // eslint-disable-line import/no-cycle
import UserAvatar from 'shared/components/UserAvatarV2';
import { noopFn } from 'shared/utils/index';

import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';
import Menu from 'ui/Menu';
import Button from 'ui/Button';

import {
  MenuItem,
  BorderBox,
} from './styledItems';

import './UserMenu.scss';

const useStyles = makeStyles({
  menu: {
    width: '260px',
  },
  paper: {
    '@media screen and (max-width: 1300px)': {
      left: 'auto !important',
      right: 30,
    },
  },
});

const UserMenu = (props) => {
  const {
    user: propsUser,
    receiveLogout,
    triggerChangeModal,
    getUser,
  } = props;

  const history = useHistory();

  const {
    EmployeeID,
  } = propsUser;

  const [key, setKey] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onButtonClick = useCallback((event) => {
    setAnchorEl(event.target);
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  useEffect(() => {
    setEmployee();
  }, []);

  const setEmployee = useCallback(() => {
    if (propsUser) {
      EmployeeRepository
        .getEmployee(propsUser.EmployeeID)
        .then((user) => {
          setKey(key + 1);
        })
        .catch(noopFn);
    }
  }, [
    propsUser,
    key,
  ]);

  const refreshAvatar = useCallback(() => {
    getUser(EmployeeID);
  }, [
    getUser,
    EmployeeID,
  ]);

  const navigateTo = useCallback((route) => () => {
    history.push(route);
  }, [
    history,
  ]);

  const handleMenuClick = useCallback((func) => () => {
    closeMenu();
    func();
  }, []);

  const classes = useStyles();

  const menu = (
    <Menu
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={closeMenu}
      classes={{
        list: classes.menu,
        paper: classes.paper,
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {/* <MenuItem
        icon="arrow-right-circle"
        iconColor="primary"
        iconOffset={16}
        variant="tertiary"
      >
        Start Tutorial Video
      </MenuItem> */}
      {/* <BorderBox /> */}
      <MenuItem
        icon="lock"
        iconColor="primary"
        iconOffset={16}
        variant="tertiary"
        onClick={handleMenuClick(triggerChangeModal)}
      >
        Change Password
      </MenuItem>
      {
        !isSectionUnderConstruction('settings') && (
          <MenuItem
            icon="settings"
            iconColor="primary"
            iconOffset={16}
            variant="tertiary"
            onClick={handleMenuClick(navigateTo('/settings'))}
          >
            Settings
          </MenuItem>
        )
      }
      <BorderBox />
      {/* <MenuItem
        Billing
      </MenuItem> */}
      {/* <BorderBox /> */}
      <MenuItem
        icon="headphones"
        iconColor="primary"
        iconOffset={16}
        variant="tertiary"
      >
        <a href="mailto:support@symplete.com" style={{ color: 'inherit' }}>
          Contact Support
        </a>
      </MenuItem>
      <MenuItem
        icon="log-out"
        iconColor="primary"
        iconOffset={16}
        variant="tertiary"
        onClick={receiveLogout}
      >
        Log out
      </MenuItem>
    </Menu>
  );

  return (
    <div className="user-menu">
      <Tooltip
        title={(
          <Typography color="main-text-inverse" noMargin>
            Click to change profile picture
          </Typography>
        )}
        placement="bottomRight"
      >
        <div className="user-menu__avatar">
          <ImageUploadWrapper
            appModule="employee-logo"
            cropAppModule="employee-logo"
            visibleModule="employee-logo"
            employee={propsUser}
            objectId={EmployeeID}
            imageActionHooks={{
              onAdd: refreshAvatar,
              onDelete: refreshAvatar,
            }}
          >
            <UserAvatar
              userType={1}
              userId={EmployeeID}
              size="sm"
              round
              noBadge
            />
          </ImageUploadWrapper>
        </div>
      </Tooltip>

      <Button
        icon="chevron-down"
        iconColor="contrast"
        variant="tertiary"
        onClick={onButtonClick}
      />
      {menu}
    </div>
  );
};

const {
  func,
  number,
  shape,
  string,
} = PropTypes;

UserMenu.propTypes = {
  getUser: func.isRequired,

  receiveLogout: func.isRequired,
  triggerChangeModal: func.isRequired,

  user: shape({
    EmployeeID: number,
    Name: string,
    Surname: string,
  }).isRequired,
};

export default UserMenu;
