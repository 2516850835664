export const SET_NEW_FULFILLMENT_MODAL_OPEN = 'SET_NEW_FULFILLMENT_MODAL_OPEN';
export const SET_NEW_FULFILLMENT_MODAL_CLOSED = 'SET_NEW_FULFILLMENT_MODAL_CLOSED';

export const SET_NEW_FULFILLMENT_FORM_FIELD = 'SET_NEW_FULFILLMENT_FORM_FIELD';

export const SET_FULFILLMENT = 'SET_FULFILLMENT';
export const SET_FULFILLMENTS = 'SET_FULFILLMENTS';
export const SET_FULFILLMENTS_FOR_FILTER = 'SET_FULFILLMENTS_FOR_FILTER';
export const SET_FULFILLMENTS_FOR_FILTER_COUNT = 'SET_FULFILLMENTS_FOR_FILTER_COUNT';
export const SET_FULFILLMENTS_SUMMARY_FOR_FILTER = 'SET_FULFILLMENTS_SUMMARY_FOR_FILTER';
export const SET_OVERALL_FULFILLMENTS_SUMMARY = 'SET_OVERALL_FULFILLMENTS_SUMMARY';
export const SET_SELECTED_FULFILLMENT = 'SET_SELECTED_FULFILLMENT';
export const SET_FULFILLMENT_SELECTED_ROWS = 'SET_FULFILLMENT_SELECTED_ROWS';
export const CLEAR_FILTER_SUMMARIES = 'CLEAR_FILTER_SUMMARIES';

export const UPDATE_NEW_FULFILLMENT_FORM_PRODUCT = 'UPDATE_NEW_FULFILLMENT_FORM_PRODUCT';

export const SET_FULFILLMENT_ATTACHMENTS = 'SET_FULFILLMENT_ATTACHMENTS';

export const ADD_NEW_FULFILLMENT_FORM_PRODUCT = 'ADD_NEW_FULFILLMENT_FORM_PRODUCT';
export const REMOVE_NEW_FULFILLMENT_FORM_PRODUCT = 'REMOVE_NEW_FULFILLMENT_FORM_PRODUCT';

export const SET_FULFILLMENT_FILTER_ITEM = 'SET_FULFILLMENT_FILTER_ITEM';
export const RESET_FULFILLMENT_FILTER_ITEM = 'RESET_FULFILLMENT_FILTER_ITEM';
export const CLEAR_FULFILLMENT_FILTER = 'CLEAR_FULFILLMENT_FILTER';

export const SET_FULFILLMENT_CHANGELOG = 'SET_FULFILLMENT_CHANGELOG';
export const SET_FULFILLMENT_PRODUCT_LINE_ITEMS = 'SET_FULFILLMENT_PRODUCT_LINE_ITEMS';

export const SET_FULFILLMENT_DISPLAY_TYPE = 'SET_FULFILLMENT_DISPLAY_TYPE';
