import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedProcessesActions,
} from 'store/actions/serviceProcesses';

const typesDict = {
  1: 'main',
  2: 'shipment',
  3: 'cancelled',
  4: 'returned',
  5: 'outOfStock',
};

const productTypeDict = {
  1: 'tangible',
  2: 'digital',
  3: 'service',
};

export default createSelector(
  (state) => state.serviceProcesses.serviceProcesses,
  (state, id) => id,
  (
    serviceProcesses,
    processId,
  ) => {
    const cancelledSteps = [
      {
        title: 'Order Cancelled',
        weight: 1,
        stepName: 'orderCancelled',
        iconName: 'ban',
        tooltip: 'Order Cancelled',
      },
      {
        title: 'Payment Refunded',
        weight: 2,
        stepName: 'paymentRefunded',
        iconName: 'dollar-sign',
        tooltip: 'Payment Refunded',
      },
      {
        title: 'Credit Note Created',
        weight: 4,
        stepName: 'creditNoteCreated',
        iconName: 'file-invoice-dollar',
        tooltip: 'Credit Note Created',
      },
      {
        title: 'Customer Informed',
        weight: 8,
        stepName: 'customerInformed',
        iconName: 'phone',
        tooltip: 'Customer Informed',
      },
    ];

    const steps = {
      main: {
        tangible: [
          {
            title: 'Upload Agreement',
            weight: 1,
            stepName: 'agreement',
            iconName: 'file-contract',
            tooltip: 'Upload Agreement',
          },
          {
            title: 'Create & Send Invoice',
            weight: 2,
            stepName: 'invoice',
            iconName: 'file-invoice-dollar',
            tooltip: 'Create & Send Invoice',
          },
          {
            title: 'Payment Received',
            weight: 4,
            stepName: 'payment',
            iconName: 'dollar-sign',
            tooltip: 'Payment Received',
          },
          {
            title: 'Order Created',
            weight: 8,
            stepName: 'order',
            iconName: 'plus-circle',
            tooltip: 'Order Created',
          },
          {
            title: 'Item Shipped',
            weight: 16,
            stepName: 'shipping',
            iconName: 'shipping-fast',
            tooltip: 'Item Shipped',
          },
          {
            title: 'Delivery Confirmed',
            weight: 32,
            stepName: 'delivery',
            iconName: 'dolly',
            tooltip: 'Delivery Confirmed',
          },
          {
            title: 'Feedback',
            weight: 64,
            stepName: 'feedback',
            iconName: 'star',
            tooltip: 'Feedback',
          },
        ],

        digital: [
          {
            title: 'Upload Agreement',
            weight: 1,
            stepName: 'agreement',
            iconName: 'file-contract',
            tooltip: 'Upload Agreement',
          },
          {
            title: 'Create & Send Invoice',
            weight: 2,
            stepName: 'invoice',
            iconName: 'file-invoice-dollar',
            tooltip: 'Create & Send Invoice',
          },
          {
            title: 'Payment Received',
            weight: 4,
            stepName: 'payment',
            iconName: 'dollar-sign',
            tooltip: 'Payment Received',
          },
          {
            title: 'Software Setup',
            weight: 8,
          },
          {
            title: 'Account Created',
            weight: 16,
          },
          {
            title: 'Feedback',
            weight: 32,
            stepName: 'feedback',
            iconName: 'star',
            tooltip: 'Feedback',
          },
        ],

        service: [
          {
            title: 'Upload Agreement',
            weight: 1,
            stepName: 'agreement',
            iconName: 'file-contract',
            tooltip: 'Upload Agreement',
          },
          {
            title: 'Create & Send Invoice',
            weight: 2,
            stepName: 'invoice',
            iconName: 'file-invoice-dollar',
            tooltip: 'Create & Send Invoice',
          },
          {
            title: 'Person / Item Reserved',
            weight: 4,
          },
          {
            title: 'Service Date Confirmed',
            weight: 8,
          },
          {
            title: 'Service Completed',
            weight: 16,
          },
          {
            title: 'Payment',
            weight: 32,
          },
          {
            title: 'Feedback',
            weight: 64,
            stepName: 'feedback',
            iconName: 'star',
            tooltip: 'Feedback',
          },
        ],
      },

      shipment: {

      },

      cancelled: {
        tangible: cancelledSteps,
        digital: cancelledSteps,
        service: cancelledSteps,
      },

      returned: {

      },

      outOfStock: {

      },
    };

    const process = serviceProcesses[processId];

    if (!process) {
      if (processId) {
        optimizedProcessesActions.getProcessById(processId);
      }

      return [];
    }

    const processSteps = steps?.[typesDict[process.type]]?.[productTypeDict[process.product_type || 1]];

    if (!processSteps) return [];

    return processSteps.map((step) => {
      const status = (process.process_status & step.weight) > 0 // eslint-disable-line no-bitwise, no-nested-ternary
        ? 'complete'
        : process.process_status > step.weight
          ? 'skipped'
          : null;

      return {
        ...step,
        status,
      };
    });
  },
);
