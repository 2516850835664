import { connect } from 'react-redux';

import {
  openNewContactModal,
} from 'store/actions/newRealtorContactModal';

import {
  openNewPropertyModal,
} from 'store/actions/newRealtorPropertyModal';

import {
  openNewLeadModal,
} from 'store/actions/newRealtorLeadModal';

import {
  openLogOfferModalUniversal,
} from 'store/actions/realtorLogOfferModal';

import {
  openModal,
} from 'store/actions/modals';

import New from './New';

const actions = {
  contact: openNewContactModal,
  property: openNewPropertyModal,
  lead: openNewLeadModal,
  offer: openLogOfferModalUniversal,
  followup: openModal('followUpRemindersNew'),
};

const mapDispatchToProps = (dispatch) => ({
  onSelect: (itemType) => {
    if (actions[itemType]) {
      dispatch(actions[itemType]());
    }
  },
});

export default connect(null, mapDispatchToProps)(New);
