import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
`;

export const TitleWrapper = styled.div`
  margin-right: 5px;
`;

export default {
  TitleWrapper,
  Root,
};
