import {
  SET_REALTOR_LEADS,
} from 'store/actionTypes/realtorLeads';

const setLeads = (leads) => ({
  type: SET_REALTOR_LEADS,
  payload: leads,
});

export default setLeads;
