import get from './get';
import getById from './getById';
import create from './create';
import update from './update';
import deleteOffer from './deleteOffer';
import addRelations from './addRelations';
import removeRelations from './removeRelations';
import overwriteRelations from './overwriteRelations';
import getOpportunitiesOffers from './getOpportunitiesOffers';
import getSummary from './getSummary';
import share from './share';
import getOffersByPropertyId from './getOffersByPropertyId';

export default {
  getSummary,
  get,
  getById,
  create,
  update,
  deleteOffer,
  addRelations,
  removeRelations,
  overwriteRelations,
  getOpportunitiesOffers,
  share,
  getOffersByPropertyId,
};
