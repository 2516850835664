import React from 'react';
import PropTypes from 'prop-types';

import Appbar from './Appbar';

import {
  AppWrapper,
  AppBody,
  AppMain,
  BgWrapper,
  WidthLimit,
} from './styledItems';

const ExternalPage = (props) => {
  const {
    children,
    appbarContent,
    backgroundImage,
  } = props;

  return (
    <BgWrapper>
      <AppWrapper>
        <Appbar>{appbarContent}</Appbar>

        <AppBody>
          <AppMain backgroundImage={backgroundImage}>
            <WidthLimit>
              {
                children
              }
            </WidthLimit>
          </AppMain>
        </AppBody>
      </AppWrapper>
    </BgWrapper>
  );
};

ExternalPage.defaultProps = {
  children: null,
  appbarContent: null,
  backgroundImage: null,
};

const {
  element,
  string,
} = PropTypes;

ExternalPage.propTypes = {
  children: element,
  appbarContent: element,
  backgroundImage: string,
};

export default ExternalPage;
