import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ClientBuyer from './ClientBuyer';

import sortViewings from './sortViewings';

const mapStateToProps = (state, ownProps) => {
  const {
    prospectId,
  } = ownProps;

  const prospect = selectors.realtorProspects.byId(state, prospectId);
  const lanes = selectors.realtorMatchingProperties.kanbanProspect(prospectId)(state);

  const upComingLane = lanes.find((lane) => lane.id === 'showingsUpcoming');
  const sortedViewings = sortViewings(upComingLane.list, state);

  const completedLane = lanes.find((lane) => lane.id === 'showingsCompleted');
  const completedShowing = completedLane.list[0] ? completedLane.list[0].matchId : null;

  return {
    propertyRequirementId: prospect.realtor_property_requirement_id,
    contactId: prospect.contact_id,

    upComingLane: upComingLane.id,
    upComingMatchId: sortedViewings[0] ? sortedViewings[0].matchId : null,

    completedLane: completedLane.id,
    completedMatchId: completedShowing,
  };
};

export default connect(mapStateToProps)(ClientBuyer);
