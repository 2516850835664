/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

import CounterOfferModal from 'sections/OffersV2/modals/CounterOfferModal';
import DeclineModal from 'sections/OffersV2/modals/DeclineModal';
import NewOfferModal from 'sections/OffersV2/modals/NewOfferModal';

const Options = (props) => {
  const {
    onDecline,
    openAcceptCounterModal,
    onDetails,
    propertyId,
    offerId,
    offerType,
    totalCounters,
    offerData,
  } = props;

  const [counterVisible, setCounterVisible] = useState(false);
  const [declineVisible, setDeclineVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);

  const closeModals = () => {
    setCounterVisible(false);
    setDeclineVisible(false);
    setViewVisible(false);
  };

  const decline = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();
    setDeclineVisible(true);
    closeMenu();
  }, [
    onDecline,
  ]);

  const counter = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();
    setCounterVisible(true);
    closeMenu();
  });

  const accept = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();
    openAcceptCounterModal();
    closeMenu();
  }, [
    openAcceptCounterModal,
  ]);

  const view = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();
    setViewVisible(true);
    closeMenu();
  }, [
    onDetails,
  ]);

  return (
    <>
      <OptionsMenu {...props}>
        {
          ({ closeMenu }) => (
            <>
              <MenuItem onClick={decline(closeMenu)}>
                Decline
              </MenuItem>
              <MenuItem onClick={counter(closeMenu)}>
                Counter
              </MenuItem>
              <MenuItem onClick={accept(closeMenu)}>
                Accept
              </MenuItem>
              <MenuItem onClick={view(closeMenu)}>
                View Details
              </MenuItem>
            </>
          )
        }
      </OptionsMenu>

      <CounterOfferModal
        isVisible={counterVisible}
        closeModal={closeModals}
        propertyId={propertyId}
        offerId={offerId}
        offerType={offerType}
        totalCounters={totalCounters}
        offerData={offerData}
      />

      <DeclineModal
        isVisible={declineVisible}
        closeModal={closeModals}
        offerId={offerId}
      />

      <NewOfferModal
        isVisible={viewVisible}
        closeModal={closeModals}
        propertyId={propertyId}
        offerId={offerId}
        totalCounters={totalCounters}
        offerData={offerData}
        updateOnly
      />
    </>
  );
};

Options.defaultProps = {
  onDecline: null,
  openAcceptCounterModal: null,
  onDetails: null,
  totalCounters: null,
  offerData: null,
};

const {
  func,
  number,
  shape,
} = PropTypes;

Options.propTypes = {
  onDecline: func,
  openAcceptCounterModal: func,
  onDetails: func,
  offerId: number.isRequired,
  offerType: number.isRequired,
  propertyId: number.isRequired,
  totalCounters: number,
  offerData: shape({}),
};

export default Options;
