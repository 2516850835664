import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import SearchContactInput from 'shared/inputs/SearchContactInput';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      selectedContact,
    },
  } = state;

  return {
    selectedContactId: selectedContact.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: async (contactId) => {
    dispatch(setNewLeadFormField('selectedContact')({ id: contactId }));
  },

  handleDelete: () => {
    dispatch(setNewLeadFormField('selectedContact')({}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactInput);
