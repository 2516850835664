import moment from 'moment';

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 's',
    m: '1 min',
    mm: '%d min',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 month',
    MM: '%d months',
    y: '1 y',
    yy: '%d y',
  },
});

function getTicketAge(creationDate) {
  const now = moment();
  const age = moment.duration(now.diff(creationDate));
  const ageReadable = age.humanize();

  return ageReadable;
}

export default getTicketAge;
