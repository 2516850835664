import requests from 'api/requests';

import setTasksCountForFilter from './setTasksCountForFilter';
import setTasks from './setTasks';
import setTasksForFilter from './setTasksForFilter';

const getFilterTasks = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const tasksResponse = await requests.tasks.getTasks({
      offset,
      limit,
      ...filter,
    });

    dispatch(setTasksCountForFilter(filterId)(tasksResponse.data.tasksCount));
    dispatch(setTasks(tasksResponse.data.tasks));
    dispatch(setTasksForFilter(filterId)(tasksResponse.data.tasks));
  } catch (error) {
    console.error(error);
  }
};

export default getFilterTasks;
