import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import {
  setFilterItem,
} from 'store/actions/realtorContactsFilter';

import { isEmpty } from 'shared/utility';

import ContactsList from './ContactsList';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorContacts: {
      contactsByFilterCount,
    },
  } = state;

  const {
    contactList,
  } = ownProps;

  if (!isEmpty(contactList)) {
    return {
      contacts: contactList,
      hasMore: false,
      loadMore: () => { },
      isScrollContainer: false,
    };
  }

  const {
    filterId,
  } = selectors.realtorContacts.contactsFilter(state);

  const contacts = selectors.realtorContacts.contactsList(state, filterId);

  const itemsCount = contactsByFilterCount[filterId];

  return {
    contacts,
    isScrollContainer: true,
    hasMore: itemsCount
      ? contacts.length < itemsCount
      : false,
    loadMore: () => {
      optimizedRealtorContactsActions.getContactsForStoreFilter(contacts.length || 0, 20);
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearSearch: () => {
    dispatch(setFilterItem('search')(null));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
