import { createSelector } from 'reselect';

import stableStringify from 'json-stable-stringify';

const contactPhoneFilter = (state) => state.newLeadForm.contactPhoneFilter;

export default createSelector(
  contactPhoneFilter,
  (phoneFilter) => {
    const filter = phoneFilter
      ? {
        mobilePhone: phoneFilter,
      }
      : null;

    const filterId = filter
      ? stableStringify(filter)
      : null;

    return {
      filter,
      filterId,
    };
  },
);
