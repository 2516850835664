import { createSelector } from 'reselect';

const contacts = (state) => state.crmContacts.contacts;
const selectedContact = (state) => state.crmContacts.selectedContact;

export default createSelector(
  contacts,
  selectedContact,
  (items, itemId) => {
    if (!items[itemId]) return null;

    const owner = items[itemId].owners?.employee?.[0];

    return owner;
  },
);
