import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

const inputStyle = {
  width: 80,
};

const LeadConversionGoal = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <Flex spacing={1} alignItems="baseline">
      <Textfield
        value={value}
        onChange={onChange}
        type="number"
        style={inputStyle}
        min={0}
      />
      <Typography noMargin tag="span">
        Days
      </Typography>
    </Flex>
  );
};

LeadConversionGoal.defaultProps = {
  value: 0,
};

const {
  func,
  number,
} = PropTypes;

LeadConversionGoal.propTypes = {
  value: number,
  onChange: func.isRequired,
};

export default LeadConversionGoal;
