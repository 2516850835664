import { connect } from 'react-redux';

import requests from 'api/requests';

import {
  commentMethodsWithObjectUpdate,
} from 'store/actions/comments';

// This dependency cycle is created on purpose to allow infinite thread nesting
import CommentThread from './CommentThread'; // eslint-disable-line import/no-cycle

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      loggedUser,
    },
  } = state;

  const {
    commentedObjectType = 'comment',
    commentedObjectId,
  } = ownProps;

  const comments = state.comments.commentedObjects[commentedObjectType][commentedObjectId];

  return {
    comments,
    isSympleteUser: !!loggedUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    commentedObjectType = 'comment',
    commentedObjectId,
  } = ownProps;

  const commentMethods = commentMethodsWithObjectUpdate(commentedObjectType)(commentedObjectId);

  return {
    getComments: () => dispatch(commentMethods.get),
    postComment: (commentBody) => dispatch(commentMethods.post(commentBody)),
    deleteComment: (commentId) => dispatch(commentMethods.delete(commentId)),
    editComment: ({ commentId, commentText }) => dispatch(commentMethods.edit(commentId)(commentText)),
    acknowledgeComment: (commentId) => dispatch(commentMethods.acknowledge(commentId)),
    markCommentAsSeen: requests.comments.markAsSeen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentThread);
