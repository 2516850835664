import { connect } from 'react-redux';
import { history } from 'store';
import {
  closeTutorialLaunchModal,
  startTutorial,
  hideTutorialLayer,
  showTutorialLayer,
  setAppTourStep,
  toggleAppTour,
} from 'store/actions/tutorial';


import Tutorial from './Tutorial';

const mapStateToProps = (state) => {
  const {
    tutorial: {
      isLaunchModalVisible,
      isTutorialRunning,
      stepIndex,
      appTour,
      appTourStep,
      availableTutorials,
    },
  } = state;

  return {
    isLaunchModalVisible,
    isTutorialRunning,
    stepIndex,
    appTour,
    history,
    appTourStep,
    availableTutorials,
  };
};

const mapDispatchToProps = {
  closeTutorialLaunchModal,
  startTutorial,
  hideTutorialLayer,
  showTutorialLayer,
  setAppTourStep,
  toggleAppTour,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);
