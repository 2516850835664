import connectReducers from 'store/connectReducers';

// eslint-disable-next-line import/no-cycle
import {
  GET_FILE_LIST_SUCCESS,
  FILE_PRELOADER,
  TRIGGER_AVATAR_CROP_MODAL,
} from 'store/actions/attachments';

import {
  SET_FILE_COMPRESSION_PROGRESS,
  CLEAR_FILE_COMPRESSION_PROGRESS,
  SHOW_DELETE_IMAGE_WARNING_MODAL,
  HIDE_DELETE_IMAGE_WARNING_MODAL,
} from 'store/actionTypes/attachments';

const initialState = {
  fileList: [],
  appModuleList: {},
  preloader: false,
  cropModalVisible: false,
  companyCropModalVisible: false,
  cropAppModule: '',
  fileCompressionProgress: null,
  isDeleteImageWarningModalVisible: false,
  openedImageModalInformation: {},
};

const reducers = {
  [SHOW_DELETE_IMAGE_WARNING_MODAL]: (state, action) => ({
    ...state,
    isDeleteImageWarningModalVisible: true,
    openedImageModalInformation: action.openedImageModalInformation,
  }),

  [HIDE_DELETE_IMAGE_WARNING_MODAL]: (state) => ({
    ...state,
    isDeleteImageWarningModalVisible: false,
  }),

  [GET_FILE_LIST_SUCCESS]: (state, action) => {
    const { list, appModuleList } = action;
    return {
      ...state,
      fileList: list,
      appModuleList: {
        ...state.appModuleList,
        ...appModuleList,
      },
      preloader: false,
    };
  },

  [FILE_PRELOADER]: (state, action) => ({ ...state, preloader: action.preloader }),

  [TRIGGER_AVATAR_CROP_MODAL]: (state, action) => ({
    ...state,
    cropModalVisible: action.cropModalVisible,
    cropAppModule: action.cropAppModule,
  }),

  [SET_FILE_COMPRESSION_PROGRESS]: (state, action) => ({
    ...state,
    fileCompressionProgress: action.payload,
  }),

  [CLEAR_FILE_COMPRESSION_PROGRESS]: (state) => ({
    ...state,
    fileCompressionProgress: null,
  }),
};

function attachments(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default attachments;
