import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';

import EditViewingForm from 'sections/MLS/forms/EditViewingForm';

const EditViewingModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    onSave,
  } = props;

  return (
    <Modal
      theme={theme}
      width={720}
      visible={isVisible}
      onCancel={closeModal}
      title="Edit showing"
      footer={[
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>,
      ]}
    >
      <EditViewingForm />
    </Modal>
  );
};

EditViewingModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

EditViewingModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
  onSave: func.isRequired,
};

export default EditViewingModal;
