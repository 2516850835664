import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { throttle } from 'shared/utility';

import Box from './styledBox';

// constants
const fullArrowWidth = 60;
const arrowHalf = 25;
const arrowMargin = 5;
const borderWidth = 1;

const getRect = (elem) => {
  if (typeof elem === 'string') {
    return document.querySelector(elem)?.getBoundingClientRect();
  }
  return elem?.current?.getBoundingClientRect();
};

const PointerBox = (props) => {
  const {
    anchorElement,
    backgroundColor,
    borderColor,
    children,
  } = props;

  const [arrowSide, setArrowSide] = useState(null);
  const [arrowPositionRules, setArrowPositionRules] = useState({});
  const [anchorCoords, setAnchorCoords] = useState(null);

  const boxRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      getAnchorCoords();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [
    anchorElement,
  ]);

  const handleScroll = useCallback((rect) => {
    if (!arrowSide) {
      setAnchorCoords({
        ...rect,
        top: rect.top + window.scrollY,
        bottom: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        right: rect.right + window.scrollX,
      });
      return;
    }
    if (arrowSide === 'top' || arrowSide === 'bottom') {
      if (anchorCoords?.left !== rect.left) {
        setAnchorCoords({
          ...rect,
          top: rect.top + window.scrollY,
          bottom: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          right: rect.right + window.scrollX,
        });
      }
      return;
    }
    if (arrowSide === 'left' || arrowSide === 'right') {
      if (anchorCoords?.bottom !== rect.bottom) {
        setAnchorCoords({
          ...rect,
          top: rect.top + window.scrollY,
          bottom: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          right: rect.right + window.scrollX,
        });
      }
    }
  }, [
    arrowSide,
    anchorCoords,
  ]);

  useEffect(() => {
    const onScroll = throttle(() => {
      const anchor = getRect(anchorElement);
      if (!anchor) {
        return;
      }
      handleScroll(anchor);
    }, 600, { leading: false });

    window.addEventListener('scroll', onScroll, true);

    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [
    anchorElement,
    handleScroll,
  ]);

  const getAnchorCoords = useCallback(() => {
    const anchor = getRect(anchorElement);

    if (!anchor) {
      return;
    }

    setAnchorCoords({
      ...anchor,
      top: anchor.top + window.scrollY,
      bottom: anchor.bottom + window.scrollY,
      left: anchor.left + window.scrollX,
      right: anchor.right + window.scrollX,
    });
  }, [
    anchorElement,
  ]);

  const getArrowPosition = useCallback(() => {
    const selfCoords = getRect(boxRef);

    if (!anchorCoords || !selfCoords) {
      setArrowSide(null);
      return;
    }

    const hasHorizontalIntersection = (
      anchorCoords.right > selfCoords.left
      && anchorCoords.left < selfCoords.right
    );
    const hasVerticalIntersection = (
      anchorCoords.bottom > selfCoords.top
      && anchorCoords.top < selfCoords.bottom
    );

    const isAnchorAbove = anchorCoords.bottom < selfCoords.top && hasHorizontalIntersection;
    const isAnchorBelow = anchorCoords.top > selfCoords.bottom && hasHorizontalIntersection;

    const isAnchorToTheLeft = anchorCoords.right < selfCoords.left && hasVerticalIntersection;
    const isAnchorToTheRight = anchorCoords.left > selfCoords.right && hasVerticalIntersection;

    const anchorCenterX = (anchorCoords.left + anchorCoords.right) / 2;
    const anchorCenterY = (anchorCoords.top + anchorCoords.bottom) / 2;

    const arrowPosition = {};

    if (isAnchorAbove) {
      setArrowSide('top');
      arrowPosition.topOffset = borderWidth;
      arrowPosition.transform = 'scaleX(-1) scaleY(-1)';
      const leftOffset = anchorCenterX - selfCoords.left + arrowHalf;
      if (leftOffset < fullArrowWidth) {
        arrowPosition.leftOffset = fullArrowWidth;
      } else if (leftOffset > selfCoords.width - arrowMargin) {
        arrowPosition.leftOffset = selfCoords.width - arrowMargin;
      } else {
        arrowPosition.leftOffset = anchorCenterX - selfCoords.left + arrowHalf;
      }
    } else if (isAnchorBelow) {
      setArrowSide('bottom');
      arrowPosition.topOffset = selfCoords.height - borderWidth - 2;
      const leftOffset = anchorCenterX - selfCoords.left - arrowHalf;
      if (leftOffset < borderWidth) {
        arrowPosition.leftOffset = borderWidth;
      } else if (leftOffset > selfCoords.width - fullArrowWidth) {
        arrowPosition.leftOffset = selfCoords.width - fullArrowWidth;
      } else {
        arrowPosition.leftOffset = leftOffset;
      }
    } else if (isAnchorToTheLeft) {
      setArrowSide('left');
      arrowPosition.leftOffset = borderWidth;
      arrowPosition.transform = 'scaleY(-1)';
      const topOffset = anchorCenterY - selfCoords.top + arrowHalf;
      if (topOffset < fullArrowWidth) {
        arrowPosition.topOffset = fullArrowWidth;
      } else if (topOffset > selfCoords.height - arrowMargin) {
        arrowPosition.topOffset = selfCoords.height - arrowMargin;
      } else {
        arrowPosition.topOffset = topOffset;
      }
    } else if (isAnchorToTheRight) {
      setArrowSide('right');
      arrowPosition.leftOffset = selfCoords.width - borderWidth;
      arrowPosition.transform = 'scaleX(-1)';
      const topOffset = anchorCenterY - selfCoords.top - arrowHalf;
      if (topOffset < borderWidth) {
        arrowPosition.topOffset = borderWidth;
      } else if (topOffset > selfCoords.height - fullArrowWidth) {
        arrowPosition.topOffset = selfCoords.height - fullArrowWidth;
      } else {
        arrowPosition.topOffset = topOffset;
      }
    } else {
      setArrowSide(null);
      return;
    }

    setArrowPositionRules(arrowPosition);
  }, [
    anchorCoords,
    boxRef,
  ]);

  useEffect(() => {
    getArrowPosition();
  }, [
    getArrowPosition,
    anchorCoords,
  ]);

  return (
    <Box
      ref={boxRef}
      arrowSide={arrowSide}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      {...arrowPositionRules} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </Box>
  );
};

PointerBox.defaultProps = {
  anchorElement: null,
  backgroundColor: '#f5f7f6',
  borderColor: '#3fb1fe',
};

const {
  shape,
  string,
  oneOfType,
  element,
} = PropTypes;

PointerBox.propTypes = {
  /**
   * valid selector or ref
   */
  anchorElement: oneOfType([string, shape({})]),
  backgroundColor: string,
  borderColor: string,
  children: element.isRequired,
};

export default PointerBox;
