/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeOfferDeclineReasonModal,
} from 'store/actions/offerDeclineReasonModal';

import {
  updateOffer,
} from 'store/actions/realtorOffers';

import OfferDeclineReasonModal from './OfferDeclineReasonModal';

const mapStateToProps = (state) => {
  const {
    realtorOfferDeclineReasonModal: {
      isVisible,
      offerId,
      readOnly,
    },
  } = state;

  return {
    isVisible,
    offerId,
    readOnly,
  };
};

// const fields = {
//   decline_reason,
// };

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeOfferDeclineReasonModal()),

  onSave: ({ decline_reason }, offerId) => dispatch(updateOffer(offerId)({
    decline_reason,
    declined_at: new Date(),
  })),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSave: async (data) => {
    dispatchProps.onSave(data, stateProps.offerId);
    dispatchProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OfferDeclineReasonModal);
