import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  TabContext,
  TabPanel,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';

import Modal from 'ui/Modal';
import Flex from 'ui/Flex';

import TabLabel from 'shared/components/UI/TabLabel';

import noOp from 'shared/utils/noOp';

import ObjectRemindersForm from './ObjectRemindersForm';
import ReminderList from './ReminderList';

const useStyles = makeStyles({
  tabPanel: {
    padding: '24px 0 0 0',
  },
});

const ObjectRemindersModal = (props) => {
  const {
    onDelete,
    onCreate,
    isVisible,
    closeModal,
    reminders,
    title,
    onObjectSelect,
    tabsView,
    draftHandlers,
    selectedObjectTypeId,
    selectedObjectId,
  } = props;

  const [stateReminders, setReminders] = useState(reminders);
  const [tab, setTab] = useState('create');

  const handleTabChange = useCallback((_, value) => {
    setTab(value);
  }, []);

  useEffect(() => {
    setReminders(reminders);
  }, [reminders]);

  const onDeleteReminder = useCallback((reminderId) => () => {
    onDelete(reminderId);
  }, [onDelete]);

  const createReminder = useCallback((reminder) => {
    onCreate(reminder);
  }, [
    onCreate,
  ]);

  const onCloseModal = useCallback(() => {
    setReminders([]);
    closeModal();
  }, [
    closeModal,
  ]);

  const classes = useStyles();

  return (
    <Modal
      width={750}
      visible={isVisible}
      onCancel={onCloseModal}
      title={title}
      footer={null}
      destroyOnClose
    >
      {
        tabsView && (
          <TabContext value={tab}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              <Tab
                label={(
                  <TabLabel
                    tabKey="create"
                    isSelected={tab === 'create'}
                    variant="title2"
                    weight={tab === 'create' ? 500 : 400}
                    highlightSelected={false}
                  >
                    Create New
                  </TabLabel>
                )}
                value="create"
              />
              <Tab
                label={(
                  <TabLabel
                    tabKey="scheduled"
                    isSelected={tab === 'scheduled'}
                    variant="title2"
                    weight={tab === 'scheduled' ? 500 : 400}
                    highlightSelected={false}
                    chipValue={String(stateReminders?.length || 0)}
                  >
                    Scheduled Follow Ups
                  </TabLabel>
                )}
                value="scheduled"
              />
            </Tabs>

            <TabPanel
              className={classes.tabPanel}
              value="create"
              index={0}
            >
              <ObjectRemindersForm
                reminders={stateReminders}
                onDeleteReminder={onDeleteReminder}
                createReminder={createReminder}
                onObjectSelect={onObjectSelect}
                selectedObjectTypeId={selectedObjectTypeId}
                selectedObjectId={selectedObjectId}
              />
            </TabPanel>
            <TabPanel
              className={classes.tabPanel}
              value="scheduled"
              index={1}
            >
              <ReminderList
                reminders={stateReminders}
                onDeleteReminder={onDeleteReminder}
              />
            </TabPanel>
          </TabContext>
        )
      }

      {
        !tabsView && (
          <Flex
            fullWidth
            flexDirection="column"
            spacing={2}
          >
            {
              stateReminders && (
                <ReminderList
                  reminders={stateReminders}
                  onDeleteReminder={onDeleteReminder}
                />
              )
            }
            <ObjectRemindersForm
              reminders={stateReminders}
              // createdReminders={createdReminders}
              onDeleteReminder={onDeleteReminder}
              createReminder={createReminder}
              onObjectSelect={onObjectSelect}
              draftHandlers={draftHandlers}
              selectedObjectTypeId={selectedObjectTypeId}
              selectedObjectId={selectedObjectId}
            />
          </Flex>
        )
      }
    </Modal>
  );
};

ObjectRemindersModal.defaultProps = {
  isVisible: false,
  closeModal: noOp,
  onDelete: noOp,
  onCreate: noOp,
  reminders: [],
  onObjectSelect: null,
  tabsView: true,
  title: '',
  draftHandlers: null,
  selectedObjectTypeId: null,
  selectedObjectId: null,
};

const {
  arrayOf,
  bool,
  func,
  number,
  string,
  shape,
} = PropTypes;

ObjectRemindersModal.propTypes = {
  isVisible: bool,
  closeModal: func,
  reminders: arrayOf({
    id: number,
    title: string,
    comment: string,
    scheduled_date: string,
    is_repeat: bool,
    status: number,
  }),
  onObjectSelect: func,
  onDelete: func,
  onCreate: func,
  tabsView: bool,
  title: string,
  draftHandlers: shape({
    draftData: shape({}),
    saveDraft: func,
    clearDraft: func,
  }),
  selectedObjectTypeId: number,
  selectedObjectId: number,
};

export default ObjectRemindersModal;
