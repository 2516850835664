import moment from 'moment';
import { find } from 'shared/utility';
import { compareText } from './common'; // eslint-disable-line import/no-cycle
import { tasks as TASKS_CONSTANTS } from '../../constants/tasks.constants';

const filterTypes = TASKS_CONSTANTS.ALL_TYPES;

export const getIsTaskDueTomorrow = (task) => {
  const today = moment();

  if (task.DeliveryDate) {
    const deadlineDate = moment(task.DeliveryDate).endOf('day');
    return deadlineDate.diff(today, 'days') === 1;
  }

  return false;
};

export const getIsTaskOverdue = (task) => {
  if (!task.DeliveryDate) {
    return false;
  }

  return task.TaskStatusID !== 5 && task.TaskStatusID !== 6 && new Date() > new Date(task.DeliveryDate);
};

export const searchTasks = (tasks, search, team = null, period) => {
  if (!tasks || !tasks.length) {
    return [];
  }

  return tasks.filter((task) => {
    const {
      TaskID, TaskName, ResolvedAt, TaskStatusID,
    } = task;

    let result = true;

    if (!!team && team.TeamName !== 'All Teams') {
      const { TeamID } = task;
      /* if (!!team.TeamID) */ result = TeamID === team.TeamID;
    }

    if (result) {
      result = compareText(TaskID.toString(), search) || compareText(TaskName, search);
    }

    if (result && period && ResolvedAt && (TaskStatusID === 5 || TaskStatusID === 6)) {
      result = moment().isSame(ResolvedAt, period);
    }

    return result;
  });
};

export const getOverdueTasksList = (list) => {
  if (!list) {
    return [];
  }

  return list.filter(getIsTaskOverdue);
};

export const getDueTomorrowTasksList = (list) => {
  if (!list) {
    return [];
  }

  return list.filter(getIsTaskDueTomorrow);
};

export const getProblemTasksList = (list) => {
  if (!list) {
    return [];
  }

  return list.filter((task) => task.IsProblem);
};

export const getTeamsByPermissions = (teamSetup, managerOnly) => {
  let myTeams = managerOnly
    ? getManagerTeams(teamSetup.permissions)
    : teamSetup.permissions;

  myTeams = myTeams ? myTeams.map((team) => team.TeamID) : [];

  return teamSetup.teamSetupList.filter((team) => myTeams.includes(team.TeamID));
};

export const getManagerTeams = (teams) => {
  if (!teams) {
    return [];
  }

  return teams.filter((team) => team.TeamManager);
};

export const filterTasks = (tasks, filter) => {
  let filtered = tasks || [];

  switch (filter) {
    case filterTypes.ALL_TASKS: {
      filtered = filtered.filter((task) => {
        const isArchived = task.TaskStatusID === 6;
        return !task.Resolved && !isArchived;
      });
      break;
    }

    case filterTypes.IN_PROGRESS: {
      filtered = filtered.filter(
        (task) => task.TaskStatusID === filterTypes.IN_PROGRESS,
      );
      break;
    }

    case filterTypes.BACKLOG: {
      filtered = filtered.filter(
        (task) => task.TaskStatusID === filterTypes.BACKLOG,
      );
      break;
    }

    case filterTypes.PROBLEM: {
      filtered = filtered.filter((task) => task.IsProblem);
      break;
    }

    case filterTypes.UNASSIGNED: {
      filtered = filtered.filter((task) => !task.employees.length);
      break;
    }

    case filterTypes.OVERDUE: {
      filtered = setOverdue(filtered);
      break;
    }

    case filterTypes.RESOLVED: {
      filtered = filtered.filter((task) => task.Resolved);
      break;
    }

    case filterTypes.NEW_ASSIGNED: {
      filtered = filtered.filter(
        (task) => task.TaskStatusID === filterTypes.NEW_ASSIGNED,
      );
      break;
    }

    case filterTypes.DUE_THIS_WEEK: {
      filtered = filtered.filter(
        (task) => task.DeliveryDate
          && moment(task.DeliveryDate).isSame(new Date(), 'week'),
      );
      break;
    }

    case filterTypes.DONE_THIS_WEEK: {
      filtered = filtered.filter(
        (task) => task.ResolvedAt && moment(task.ResolvedAt).isSame(new Date(), 'week'),
      );
      break;
    }

    case filterTypes.TOTAL_AGENTS: {
      break;
    }

    default:
      break;
  }
  return filtered;
};

export const setOverdue = (list) => {
  if (!list) {
    return [];
  }

  return list.filter(
    (task) => new Date() > new Date(task.DeliveryDate) && task.DeliveryDate,
  );
};

export const getActiveTeam = (teams, id) => {
  if (id === 0) {
    const reducer = (teamAccumulator, team) => {
      // Returns true if the accumulator does not have an employee with this EmployeeID
      const duplicateElementsFilter = (teamMember) => !(
        teamAccumulator.members.find((accumulatorMember) => accumulatorMember.EmployeeID === teamMember.EmployeeID)
      );

      const newMembers = team.members.filter(duplicateElementsFilter);
      return {
        members: teamAccumulator.members.concat(newMembers),
        tasks: teamAccumulator.tasks.concat(team.tasks),
      };
    };

    const allMembers = teams.reduce(reducer,
      {
        members: [],
        tasks: [],
      });

    const {
      members,
      tasks,
    } = allMembers;

    return {
      members: Array.from(new Set(members)),
      tasks,
    };
  }

  return teams.find((team) => team.TeamID === id);
};
export const mapTeamTasks = (teamTasks, tasks = []) => {
  const list = teamTasks.map((task) => task.TaskID);

  return tasks.filter((task) => list.includes(task.TaskID));
};

export const getMemberTasks = (list, id) => {
  let taskAmount = [];
  if (list) {
    taskAmount = list.filter(({ employees }) => find(employees, { EmployeeID: id }));
  }

  return taskAmount;
};

/* eslint-disable max-len */
export const filters = {
  Backlog: (task) => task.TaskStatusID === 1,
  'In Progress': (task) => task.TaskStatusID === 3,
  'Due this week': (task) => (task.TaskStatusID !== 5 && task.TaskStatusID !== 6) && task.DeliveryDate && moment(task.DeliveryDate).isSame(new Date(), 'week'),
  'Due next week': (task) => (task.TaskStatusID !== 5 && task.TaskStatusID !== 6) && task.DeliveryDate && moment(task.DeliveryDate).isSame(moment().add(1, 'weeks'), 'week'),
  Overdue: getIsTaskOverdue,
  Problem: (task) => task.TaskStatusID !== 5 && task.TaskStatusID !== 6 && task.IsProblem,
  'Done this week': (task) => (task.TaskStatusID === 5 || task.TaskStatusID === 6) && task.ResolvedAt && moment(task.ResolvedAt).isSame(new Date(), 'week'),
  Done: (task) => (task.TaskStatusID === 5 || task.TaskStatusID === 6),
  'Due provided period': (task, startDate, endDate) => (task.TaskStatusID !== 5 && task.TaskStatusID !== 6) && task.DeliveryDate && moment(task.DeliveryDate).isBetween(startDate, endDate, undefined, '[]'),
};
/* eslint-ensable max-len */
