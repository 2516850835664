import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getAgreements from './getAgreements';
import getAgreementById from './getAgreementById';

import createAgreement from './createAgreement';

import setSelectedAgreement from './setSelectedAgreement';

import updateAgreement from './updateAgreement';
import getAgreementAttachments from './getAgreementAttachments';

export { getAgreements };
export { getAgreementById };

export { createAgreement };

export { setSelectedAgreement };

export { updateAgreement };
export { getAgreementAttachments };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedAgreementsActions = {
  getAgreementById: memoizeDebounce(
    (agreementId) => store.dispatch(getAgreementById(agreementId)),
    debounceDelay,
    debounceOptions,
  ),
};
