import apiConnector, { endpoints } from 'api';

const getById = async (dealId) => {
  const endpoint = `${endpoints.client.realtor.deals}/${dealId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getById;
