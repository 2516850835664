import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import EmployeeRepository from 'shared/repositiores/employee/employee';

import ImageUploadWrapper from 'shared/components/ImageUploadWrapper';
import UserAvatar from 'shared/components/UserAvatarV2';
import { noopFn } from 'shared/utils/index';

const Avatar = (props) => {
  const {
    user: propsUser,
    getUser,
  } = props;

  const {
    EmployeeID,
  } = propsUser;

  const [key, setKey] = useState(0);

  useEffect(() => {
    setEmployee();
  }, []);

  const setEmployee = useCallback(() => {
    if (propsUser) {
      EmployeeRepository
        .getEmployee(propsUser.EmployeeID)
        .then((user) => {
          setKey(key + 1);
        })
        .catch(noopFn);
    }
  }, [
    propsUser,
    key,
  ]);

  const refreshAvatar = useCallback(() => {
    getUser(EmployeeID);
  }, [
    getUser,
    EmployeeID,
  ]);

  return (
    <div className="user-menu__avatar">
      <ImageUploadWrapper
        appModule="employee-logo"
        cropAppModule="employee-logo"
        visibleModule="employee-logo"
        employee={propsUser}
        objectId={EmployeeID}
        imageActionHooks={{
            onAdd: refreshAvatar,
            onDelete: refreshAvatar,
          }}
      >
        <UserAvatar
          userType={1}
          userId={EmployeeID}
          size="m"
          noBadge
        />
      </ImageUploadWrapper>
    </div>
  );
};

const {
  func,
  number,
  shape,
  string,
} = PropTypes;

Avatar.propTypes = {
  getUser: func.isRequired,

  user: shape({
    EmployeeID: number,
    Name: string,
    Surname: string,
  }).isRequired,
};

export default Avatar;
