import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import RequestItemsTabs from 'sections/Transactions/components/RequestItemsTabs';
import AddendumChangeItem from 'sections/Transactions/modules/AddendumChangeItem';

const ItemsBlock = ({ items, onAfterUpdate }) => (
  <Grid container spacing={2}>
    {
      items.map((requestId) => (
        <Grid item xs={12}>
          <AddendumChangeItem
            requestId={requestId}
            onAfterUpdate={onAfterUpdate}
          />
        </Grid>
      ))
    }
  </Grid>
);

const AddendumChanges = (props) => {
  const {
    newItems,
    outstandingItems,
    respondedItems,

    onAfterUpdate,
  } = props;

  return (
    <>
      <Typography variant="body2" color="ghost">
        All responses will be emailed to the requestor
      </Typography>

      <RequestItemsTabs
        newItems={(
          <ItemsBlock
            items={newItems}
            onAfterUpdate={onAfterUpdate}
          />
        )}
        outstandingItems={(
          <ItemsBlock
            items={outstandingItems}
            onAfterUpdate={onAfterUpdate}
          />
        )}
        respondedItems={(
          <ItemsBlock
            items={respondedItems}
            onAfterUpdate={onAfterUpdate}
          />
        )}
      />
    </>
  );
};

const noOp = () => {};

AddendumChanges.defaultProps = {
  respondedItems: [],
  newItems: [],
  outstandingItems: [],
  onAfterUpdate: noOp,
};

const {
  arrayOf,
  element,
  func,
} = PropTypes;

AddendumChanges.propTypes = {
  respondedItems: arrayOf(element),
  newItems: arrayOf(element),
  outstandingItems: arrayOf(element),

  onAfterUpdate: func,
};

export default AddendumChanges;
