import { createSelector } from 'reselect';
import checkEmployeePermissions from 'shared/utils/checkEmployeePermissions';

const employees = (state) => state.myCompany.employees;

export default createSelector(
  employees,
  (items) => (items
    ? items.reduce((result, employeeObject) => {
      const hasPermissions = checkEmployeePermissions.isUser('Sales')(employeeObject) || checkEmployeePermissions.isManager('Sales')(employeeObject); // eslint-disable-line max-len

      if (hasPermissions) {
        result.push(employeeObject.EmployeeID);
      }

      return result;
    }, [])
    : null),
);
