import apiConnector, { endpoints } from 'api';

const personTitles = async () => {
  const endpoint = endpoints.client.dictionary.personTitles;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default personTitles;
