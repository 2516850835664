import apiConnector, { endpoints } from 'api';

const getToken = async () => {
  const endpoint = endpoints.external.externalAccess;
  try {
    const result = await apiConnector.client.post(endpoint);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getToken;
