import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import EditableList from 'ui/EditableList';

import usePropertyNotablePoints from 'sections/Properties/hooks/usePropertyNotablePoints';

const PropertyNotablePoints = (props) => {
  const {
    onChange,
    onCreate,
    onDelete,
    modalTitle,
    propertyId,
  } = props;

  const {
    notablePoints,
    getNotablePoints,
  } = usePropertyNotablePoints(propertyId);

  const handleCreate = useCallback(async (data) => {
    await onCreate(data);
    getNotablePoints();
  }, [
    onCreate,
    getNotablePoints,
  ]);

  const handleChange = useCallback((pointId) => (key) => (value) => {
    onChange(pointId)(key)(value)(getNotablePoints);
  }, [
    onChange,
    getNotablePoints,
  ]);

  const handleDelete = useCallback(async (pointId) => {
    await onDelete(pointId);
    getNotablePoints();
  }, [
    onDelete,
    getNotablePoints,
  ]);

  return (
    <EditableList
      {...props}
      items={notablePoints}
      modalTitle={modalTitle}
      onCreate={handleCreate}
      onChange={handleChange}
      onDelete={handleDelete}
    />
  );
};

PropertyNotablePoints.defaultProps = {
  modalTitle: 'Edit Notable Points',
};

const {
  func,
  number,
  string,
} = PropTypes;

PropertyNotablePoints.propTypes = {
  modalTitle: string,
  onChange: func.isRequired,
  onCreate: func.isRequired,
  onDelete: func.isRequired,
  propertyId: number.isRequired,
};

export default PropertyNotablePoints;
