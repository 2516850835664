import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';

import {
  RootWrapper,
  SectionWrapper,
} from './styledItems';

const Appbar = (props) => {
  const {
    children,
  } = props;

  return (
    <RootWrapper>
      <Icon
        name="realtorLogo"
        type="custom"
        size={200}
      />

      <SectionWrapper>
        <Flex
          spacing={2}
          style={{
            flex: 8,
          }}
          alignItems="center"
          justifyContent="flex-end"
        >
          {children}
        </Flex>
      </SectionWrapper>
    </RootWrapper>
  );
};

Appbar.defaultProps = {
  children: null,
};

const {
  element,
} = PropTypes;

Appbar.propTypes = {
  children: element,
};

export default Appbar;
