import { // eslint-disable-line import/no-cycle
  SHOW_CATEGORY_CREATE_MODAL,
  SHOW_SEARCH_FILTER_MODAL,
  GET_HELPER_DATA_ERROR,
  GET_HELPER_DATA_SUCCESS,
  GET_KB_CATEGORIES_SUCCESS,
  GET_KB_CATEGORIES_ERROR,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  GET_KB_ARTICLES_SUCCESS,
  SET_ACTIVE_SUB_CATEGORY,
  SET_ACTIVE_CATEGORY,
  DELETE_KB_CATEGORY_SUCCESS,
  DELETE_KB_CATEGORY_ERROR,
  SHOW_DELETE_CATEGORY_WARNING_MODAL,
  MOVE_ARTICLES_TO_CATEGORY,
  SAVE_SEARCH_FILTER,
  SEARCH_ARTICLES_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  SHOW_CATEGORY_EDIT_MODAL_SUCCESS,
  SORT_ARTICLE_SUCCESS,
  GET_INITIAL_SEARCH_ARTICLES_SUCCESS,
  GET_ARTICLES_FROM_PARENT,
  MOVE_ARTICLES_TO_CATEGORY_SUCCESS,
  SUGGESTION_ARTICLE_HOVER_SUCCESS,
  SHOW_SEARCH_STANDALONE_MODAL,
  TOGGLE_PROMPT_TO_CREATE_CATEGORY,
} from '../actions/knowledgeBase';
import { CREATE_ARTICLE_SUCCESS } from '../actions/articleDetail'; // eslint-disable-line import/no-cycle

const initial_kb_helper_data = {
  categories: [],
  products: [],
  subcategories: [],
  accessibility: [],
  labels: [],
  types: [],
};

const initialState = {
  addCategoryModal: {
    isVisible: false,
    isLoading: false,
  },
  editCategoryModal: {
    isVisible: false,
    isLoading: false,
  },
  searchFilterModal: {
    isVisible: false,
    isLoading: false,
  },
  searchStandaloneModal: {
    isVisible: false,
    isLoading: false,
  },
  deleteCategoryModal: {
    isVisible: false,
    isLoading: false,
  },
  kbHelperData: { ...initial_kb_helper_data },
  categoriesInfo: {
    categories: [],
    active: {},
  },
  articles: [],
  activeSubCategoryId: undefined,
  activeCategoryId: undefined,
  kbSearchFilterData: {
    accessibility: undefined,
    category: undefined,
    collaborators: undefined,
    feedback: [],
    keywords: undefined,
    labels: undefined,
    product: undefined,
    starred: undefined,
    types: undefined,
  },
  searchSuggestions: [],
  suggestionHoverArticle: {},
  editableCategory: {},
  promptToCreateCategory: false,
};

function knowledgeBase(state = initialState, action) {
  switch (action.type) {
    case SHOW_CATEGORY_CREATE_MODAL: {
      let { addCategoryModal } = state;
      // TODO: this workarond should be deleted
      if (!addCategoryModal) addCategoryModal = initialState.addCategoryModal;
      // End of workaround
      return {
        ...state,
        addCategoryModal: { ...addCategoryModal, isVisible: !addCategoryModal.isVisible },
      };
    }
    case GET_ARTICLES_FROM_PARENT: {
      const { categoriesInfo } = state;
      const categories = { ...categoriesInfo.categories };
      const activeCatId = categoriesInfo.active.categoryId;
      const parentArticles = Object.keys(categories).map((i) => categories[i]);
      const parentCategory = parentArticles.filter((category) => category.id === activeCatId);
      const { articles } = parentCategory[0];
      return {
        ...state,
        articles,
      };
    }
    case SHOW_CATEGORY_EDIT_MODAL_SUCCESS: {
      const { editCategoryModal } = state;
      return {
        ...state,
        editCategoryModal: { ...editCategoryModal, isVisible: !editCategoryModal.isVisible },
        editableCategory: action.payload,
      };
    }
    case GET_HELPER_DATA_SUCCESS: {
      return {
        ...state,
        kbHelperData: action.payload || { ...initial_kb_helper_data },
      };
    }
    case GET_HELPER_DATA_ERROR: {
      return {
        ...state,
        kbHelperData: { ...initial_kb_helper_data },
      };
    }
    case SHOW_SEARCH_FILTER_MODAL: {
      const { searchFilterModal } = state;
      return {
        ...state,
        searchFilterModal: { ...searchFilterModal, isVisible: !searchFilterModal.isVisible },
      };
    }
    case SHOW_SEARCH_STANDALONE_MODAL: {
      const { searchStandaloneModal } = state;
      return {
        ...state,
        searchStandaloneModal: { ...searchStandaloneModal, isVisible: !searchStandaloneModal.isVisible },
      };
    }
    case GET_KB_CATEGORIES_SUCCESS: {
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          categoriesInfo: {
            ...state.categoriesInfo,
            categories: action.payload,
          },
        };
      }
      return {
        ...state,
      };
    }
    case MOVE_ARTICLES_TO_CATEGORY_SUCCESS: {
      return {
        ...state,
        categoriesInfo: {
          ...state.categoriesInfo,
          categories: action.payload,
        },
      };
    }
    case GET_KB_CATEGORIES_ERROR: {
      return {
        ...state,
      };
    }
    case CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        categoriesInfo: {
          ...state.categoriesInfo,
          categories: action.payload,
        },
      };
    }
    case CREATE_CATEGORY_ERROR: {
      return {
        ...state,
      };
    }
    case GET_KB_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: action.payload,
      };
    }
    case SORT_ARTICLE_SUCCESS: {
      return {
        ...state,
        articles: action.payload,
      };
    }
    case SET_ACTIVE_SUB_CATEGORY: {
      return {
        ...state,
        activeSubCategoryId: action.payload,
        categoriesInfo: {
          ...state.categoriesInfo,
          active: action.payload,
        },
      };
    }
    case SET_ACTIVE_CATEGORY: {
      return {
        ...state,
        activeSubCategoryId: action.payload,
        categoriesInfo: {
          ...state.categoriesInfo,
          active: {
            categoryId: action.payload,
          },
        },
      };
    }
    case DELETE_KB_CATEGORY_SUCCESS: {
      return {
        ...state,
        categoriesInfo: {
          ...state.categoriesInfo,
          categories: action.payload,
        },
      };
    }
    case DELETE_KB_CATEGORY_ERROR: {
      return {
        ...state,
        // deleteCategoryModal: true,
      };
    }
    case SHOW_DELETE_CATEGORY_WARNING_MODAL: {
      const { deleteCategoryModal } = state;
      return {
        ...state,
        deleteCategoryModal: { ...deleteCategoryModal, isVisible: !deleteCategoryModal.isVisible },
      };
    }
    case MOVE_ARTICLES_TO_CATEGORY: {
      return {
        ...state,
        categoriesInfo: {
          ...state.categoriesInfo,
          categories: action.payload,
        },
      };
    }
    case SAVE_SEARCH_FILTER: {
      return {
        ...state,
        kbSearchFilterData: action.payload,
      };
    }
    case SEARCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        searchSuggestions: action.payload,
      };
    }
    case GET_INITIAL_SEARCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        searchSuggestions: action.payload,
      };
    }
    case SUGGESTION_ARTICLE_HOVER_SUCCESS: {
      return {
        ...state,
        suggestionHoverArticle: action.payload,
      };
    }
    case EDIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        categoriesInfo: {
          ...state.categoriesInfo,
          categories: action.payload,
        },
      };
    }
    case CREATE_ARTICLE_SUCCESS: {
      return {
        ...state,
        categoriesInfo: {
          ...state.categoriesInfo,
          categories: action.payload,
        },
      };
    }
    case TOGGLE_PROMPT_TO_CREATE_CATEGORY: {
      return {
        ...state,
        promptToCreateCategory: !state.promptToCreateCategory,
      };
    }
    default:
      return state;
  }
}

export default knowledgeBase;
