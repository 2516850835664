import emitWithToken from 'shared/sockets/emitWithToken';

export default (socket) => (
  // fromPhoneNumber, 
  toPhoneNumber
) => (text) => {
  emitWithToken(socket)('sms:send')({
    // from: fromPhoneNumber,
    to: toPhoneNumber,
    text,
  });
};
