import getTasks from './getTasks';
import getTaskById from './getTaskById';
import getTasksSummary from './getTasksSummary';
import getOverallTasksSummary from './getOverallTasksSummary';
import updateTaskStatus from './updateTaskStatus';
import updateTaskAssignees from './updateTaskAssignees';
import changeTaskRank from './changeTaskRank';
import getTaskWorklogs from './getTaskWorklogs';
import getTaskLastUpdateInfo from './getTaskLastUpdateInfo';
import getEmployeeTasksSummaryForPeriod from './getEmployeeTasksSummaryForPeriod';
import updateTaskDeliveryDate from './updateTaskDeliveryDate';
import getNumOfWeeksFromOldestCompletedTask from './getNumOfWeeksFromOldestCompletedTask';
import getEmployeeTotalLoggedHoursForDoneTasks from './getEmployeeTotalLoggedHoursForDoneTasks';

export default {
  getTasks,
  getTaskById,
  getTasksSummary,
  updateTaskStatus,
  updateTaskAssignees,
  getOverallTasksSummary,
  changeTaskRank,
  getTaskWorklogs,
  getTaskLastUpdateInfo,
  getEmployeeTasksSummaryForPeriod,
  updateTaskDeliveryDate,
  getNumOfWeeksFromOldestCompletedTask,
  getEmployeeTotalLoggedHoursForDoneTasks,
};
