import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Textfield from 'ui/Textfield';
import Button from 'ui/Button';

const Item = (props) => {
  const {
    id,
    title,
    description,
    onChange,
    onDelete,
  } = props;

  const handleChange = useCallback((key) => (value) => {
    onChange(id)(key)(value);
  }, [
    id,
    onChange,
  ]);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [
    id,
    onDelete,
  ]);

  return (
    <Grid container spacing={1} alignItems="center" justify="space-between">

      <Grid item container spacing={4} xs={11}>
        <Grid item xs={12}>
          <Textfield
            label="Title"
            value={title}
            onChange={handleChange('title')}
          />
        </Grid>
        <Grid item xs={12}>
          <Textfield
            textarea
            label="Description"
            value={description}
            multiline
            onChange={handleChange('description')}
          />
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <Button
          onClick={handleDelete}
          icon="trash"
          variant="secondary"
        />
      </Grid>

    </Grid>
  );
};

Item.defaultProps = {
  title: '',
  description: '',
};

const {
  string,
  number,
  func,
} = PropTypes;

Item.propTypes = {
  id: number.isRequired,
  title: string,
  description: string,
  onChange: func.isRequired,
  onDelete: func.isRequired,
};

export default Item;
