import apiConnector, { endpoints } from 'api';

const upload = (tokenId) => async (attachmentFormData) => {
  const endpoint = `${endpoints.client.integrations.emailAttachments}?tokenId=${tokenId}`;

  try {
    const result = await apiConnector.clientAttachmentsUpload.post(endpoint, attachmentFormData);
    return result.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default upload;
