import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'antd';

import Typography from 'ui/Typography';

import './TagLabel.scss';

import { Tag } from './styledItems';

const rootClass = 'tag-label';

class TagLabel extends PureComponent {
  render() {
    const {
      className,
      tagClassName,
      iconClassName,
      children,
      color,
      closable,
      hasIcon,
      tagHandleClick,
      tagHandleClose,
      iconHandleClick,
    } = this.props;

    return (
      <div className={classnames(
        rootClass,
        {
          [className]: className,
        },
      )}
      >
        <Tag
          color={color}
          onClick={!closable ? tagHandleClick : null}
          className={classnames(
            `${rootClass}__tag`,
            {
              [tagClassName]: tagClassName,
            },
            {
              [`${rootClass}__tag--closable`]: closable,
            },
          )}
          closable={closable}
          onClose={tagHandleClose}
        >
          <Typography variant="tag" color="contrast">
            {children}
          </Typography>
        </Tag>
        {hasIcon && (
          <Icon
            className={classnames(
              `${rootClass}__icon`,
              {
                [iconClassName]: iconClassName,
              },
            )}
            theme="filled"
            type="question-circle"
            onClick={iconHandleClick}
          />
        )}
      </div>
    );
  }
}

export default TagLabel;

TagLabel.defaultProps = {
  className: '',
  tagClassName: '',
  iconClassName: '',
  closable: false,
  hasIcon: false,
  tagHandleClick: () => {},
  tagHandleClose: () => {},
  iconHandleClick: () => {},
};

const {
  string,
  func,
  bool,
} = PropTypes;

TagLabel.propTypes = {
  className: string,
  tagClassName: string,
  iconClassName: string,
  children: string.isRequired,
  color: string.isRequired,
  closable: bool,
  hasIcon: bool,
  tagHandleClick: func,
  tagHandleClose: func,
  iconHandleClick: func,
};
