import styled from 'styled-components';

import Flex from 'ui/Flex';

export const Root = styled(Flex)`
  padding: ${(props) => props.padding || '40px'};
  /* border: 1px dashed ${(props) => props.theme?.palette?.delimiter || 'blue'}; */
  border-radius: 10px;
  &:focus {
    outline: none
  }
  cursor: pointer;
  background-color: ${(props) => props.theme?.palette?.['background-flat'] || 'transparent'};
`;

export default {
  Root,
};
