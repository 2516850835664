import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  color: rgba(107, 136, 164, 0.79);
  width: 8px;
  height: 8px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SpecialConditionsItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;

  button {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .item-value-text {
   margin-left: 12px;
  }
`;
