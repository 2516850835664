import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import noOp from 'shared/utils/noOp';
import getRandomId from 'shared/utils/getRandomId';

import {
  Root,
  Input,
  Label,
} from './styledItems';

const Checkbox = (props) => {
  const {
    checked,
    disabled,
    onChange,
    id,
    label,
    typographyProps,
  } = props;

  const [currentValue, setCurrentValue] = useState(!!checked);

  const myId = id || useMemo(() => getRandomId());

  useEffect(() => {
    if (!disabled) {
      setCurrentValue(!!checked);
    }
  }, [
    checked,
    disabled,
  ]);

  const handleChange = useCallback((event) => {
    if (!disabled) {
      setCurrentValue(event.target.checked);
      onChange(event.target.checked);
    }
  }, [
    onChange,
    disabled,
  ]);

  const box = (
    <Root
      onChange={handleChange}
      checked={currentValue}
      disabled={disabled}
    >
      <Input
        type="checkbox"
        checked={currentValue}
        id={myId}
      />
      {
        currentValue && (
        <Icon
          name="check"
          color="white"
          type="feather"
        />
        )
      }
    </Root>
  );

  if (!label) return box;

  return (
    <Flex
      spacing={1}
      alignItems="center"
    >
      {box}
      <Label for={myId}>
        <Typography
          noMargin
          weight="bold"
          {...typographyProps}
        >
          {label}
        </Typography>
      </Label>
    </Flex>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  onChange: noOp,
  id: null,
  label: null,
  typographyProps: {},
};

const {
  bool,
  func,
  shape,
  string,
} = PropTypes;

Checkbox.propTypes = {
  checked: bool,
  disabled: bool,
  onChange: func,
  id: string,
  label: string,
  typographyProps: shape({}),
};

export default Checkbox;
