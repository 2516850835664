import { createSelector } from 'reselect';

import { forOwn } from 'shared/utility';

import {
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';

export default createSelector(
  (state) => state.lineItems.lineItems,
  (state) => state.crmProducts.products,
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.selectedFulfillment,
  (lineItems, products, fulfillments, selectedFulfillmentId) => {
    const selectedFulfillment = fulfillments[selectedFulfillmentId];

    if (!fulfillments[selectedFulfillmentId]) {
      if (selectedFulfillmentId) {
        optimizedFulfillmentsActions.getFulfillmentById(selectedFulfillmentId);
      }

      return [];
    }

    const selectedProducts = selectedFulfillment?.products;

    if (!selectedProducts) {
      return [];
    }

    const items = [];

    forOwn(selectedProducts, (item, key) => {
      const lineItemId = item.line_item_id;

      // const lineItemObject = lineItems[lineItemId];

      // if (!lineItemObject && lineItemId) {
      //   optimizedLineItemsActions.getLineItemById(lineItemId);
      // }

      const productId = item.product_id;

      const productObject = products[productId];

      if (!productObject && productId) {
        optimizedCrmProductsActions.getProductById(productId);
      }

      items.push({
        ...item,
        rowId: +key + 1,
        title: productObject?.name,
        quantity: item.sub_items_count,
        isExpandable: item.sub_items_count > 1,
      });
    });

    return items;
  },
);
