import get from './get';
import deleteAvatar from './deleteAvatar';
import resetPassword from './resetPassword';
import comparePassword from './comparePassword';
import changeSystemPermission from './changeSystemPermission';

export default {
  get,
  changeSystemPermission,
  deleteAvatar,
  resetPassword,
  comparePassword,
};
