import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

import { // eslint-disable-line import/no-cycle
  optimizedNotablePointActions,
} from 'store/actions/realtorNotablePointsAndDisclosures/notablePoints';

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.realtorNotablePointsAndDisclosures.notablePoints,
  (_, propertyId) => propertyId,
  (
    properties,
    notablePoints,
    id,
  ) => {
    const property = properties[id];

    if (!property) {
      if (id) {
        optimizedPropertyActions.getPropertyById(id);
      }

      return [];
    }

    const notablePointsIds = property.notable_points || [];

    const propertyNotablePoints = notablePointsIds.reduce((accum, pointId) => {
      const notablePoint = notablePoints[pointId];

      if (!notablePoint) {
        if (pointId) {
          optimizedNotablePointActions.getNotablePointById(pointId);
        }

        return accum;
      }

      accum.push({
        id: notablePoint.id,
        title: notablePoint.title,
        description: notablePoint.description,
      });
      return accum;
    }, []);

    return propertyNotablePoints;
  },
);
