import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  display: flex;
  white-space: nowrap;
  border-radius: 10px;
  border: solid 1px ${(props) => props.theme.palette.focus};
  padding: 5px 5px 5px 7px;
  width: fit-content;

  .value-text {
    margin-left: 7px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .description-text {
    margin-left: 6px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.color || props.theme?.palette[props.variant] || '#1e2a37'};
`;

export default {
  Wrapper,
  Icon,
};
