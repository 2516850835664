import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.fulfillmentsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      search: filter.search,
      status: filter.status,
      sort_by: filter.sortBy,
      sort_direction: filter.sortDirection,
      is_active: filter.activeSummaryFilter === 'active' ? true : undefined,
      is_fulfilled: filter.activeSummaryFilter === 'fulfilled' ? true : undefined,
      is_cancellation: filter.activeSummaryFilter === 'cancelled' ? true : undefined,
      is_return: filter.activeSummaryFilter === 'returns' ? true : undefined,
      is_out_of_stock: filter.activeSummaryFilter === 'outOfStock' ? true : undefined,
      is_archived: filter.activeSummaryFilter === 'archived' ? true : undefined,

      // permission_type: filter.permissionType,
      // owned_by_teams: filter.ownedByTeams,
      // owned_by_employees: filter.ownedByEmployees,
      // permissions_for_employees: filter.permissionsForEmployees,
      // permissions_for_teams: filter.permissionsForTeams,
      // permissions_for_offices: filter.permissionsForOffices,
    },
  }),
);
