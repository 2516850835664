import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 7px 15px;
  background-color: rgba(242, 246, 254, 0.71);
  visibility: hidden;
`;
