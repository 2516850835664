import { common } from 'constants';
import { LICENSE_ACTIONS } from 'constants/index';

import checkPermissions from 'shared/utils/checkPermissions';

const LICENSES = Object.values(common.LICENSES);

export const mapLicenses = (employee) => {
  const licenses = {};
  employee.Licenses && employee.Licenses.forEach((license) => {
    const licenseId = license.LicenceID;
    const { permission } = LICENSES.find(({ id }) => id === licenseId);

    licenses[licenseId] = licenseId === 1
      ? LICENSE_ACTIONS.LICENSE
      : LICENSE_ACTIONS.EMPTY;

    if (checkPermissions(permission, employee.UserRole)) {
      licenses[licenseId] = LICENSE_ACTIONS.LICENSE;
    }

    if (checkPermissions(permission, employee.ManagerRole)) {
      licenses[licenseId] = LICENSE_ACTIONS.PM_ROLE;
    }
  });

  return {
    ...employee,
    licenses,
  };
};

export const countPercent = (x, y) => Math.round((x / y) * 100);
