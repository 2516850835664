export const SET_FILE_COMPRESSION_PROGRESS = 'SET_FILE_COMPRESSION_PROGRESS';
export const CLEAR_FILE_COMPRESSION_PROGRESS = 'CLEAR_FILE_COMPRESSION_PROGRESS';
export const SHOW_DELETE_IMAGE_WARNING_MODAL = 'SHOW_DELETE_IMAGE_WARNING_MODAL';
export const HIDE_DELETE_IMAGE_WARNING_MODAL = 'HIDE_DELETE_IMAGE_WARNING_MODAL';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';

export default {
  SET_FILE_COMPRESSION_PROGRESS,
  CLEAR_FILE_COMPRESSION_PROGRESS,
  SHOW_DELETE_IMAGE_WARNING_MODAL,
  HIDE_DELETE_IMAGE_WARNING_MODAL,
  SET_ATTACHMENTS,
};
