import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

// import Label from 'shared/components/UI/SympleteUIKit/Label';
// import Select from 'shared/components/UI/SympleteUIKit/Select';

import ToDoTabColumn from './ToDoTabColumn';

const ToDoTab = (props) => {
  const {
    columnData,
  } = props;

  // const [filterValue, setFilterValue] = useState('allItems');

  // const onChangeFilter = useCallback((newValue) => () => {
  //  setFilterValue(newValue);
  // }, []);

  return (
    <Grid container spacing={2} style={{ height: '100%' }}>
      {/* <Grid item xs={12}>
        <Select
          onChange={onChangeFilter}
          items={[
            {
              name: 'All Items',
              value: 'allItems',
            },
            {
              name: 'New Things',
              itemDescComponent: (
                <Label
                  variant="new"
                >
                  N
                </Label>
              ),
              value: 'newThings',
            },
            {
              name: 'Follow Ups',
              itemDescComponent: (
                <Label
                  variant="followup"
                >
                  F
                </Label>
              ),
              value: 'followUps',
            },
          ]}
          value={filterValue}
        />
        </Grid> */}

      {
        columnData.map(({
          title,
          description,
          items,
          objectKey,
          itemsCount,
          loadMore,
        }) => (
          <Grid item xs={3} style={{ height: '100%' }}>
            <ToDoTabColumn
              title={title}
              description={description}
              items={items}
              itemsCount={itemsCount}
              loadMore={loadMore}
              objectKey={objectKey}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

ToDoTab.propTypes = {
  columnData: arrayOf(shape({
    title: string,
    description: string,
    items: arrayOf(shape()),
    itemsCount: number,
    loadMore: func,
    objectKey: string,
  })).isRequired,
};

export default ToDoTab;
