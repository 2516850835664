import apiConnector from 'api';

/**
 * Gets all technical work planned for the next 48 hours.
 * @returns {Array} Planned technical work
 */
const getPlannedMaintenance = async () => {
  const url = '/planned-system-maintenance';

  const response = await apiConnector.global.get(url);
  const {
    plannedSystemMaintenance,
  } = response.data;

  return plannedSystemMaintenance;
};

export default getPlannedMaintenance;
