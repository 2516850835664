import currencies from './currencies';
import amenities from './amenities';
import realtorSaleTypes from './realtorSaleTypes';
import realtorViewsFromHome from './realtorViewsFromHome';
import realtorSpecialConditions from './realtorSpecialConditions';
import rejectedPropertiesReasons from './rejectedPropertiesReasons';
import realtorRepairTypes from './realtorRepairTypes';
import personTitles from './personTitles';
import contactLabels from './contactLabels';

export default {
  currencies,
  amenities,
  realtorSaleTypes,
  realtorViewsFromHome,
  realtorSpecialConditions,
  rejectedPropertiesReasons,
  realtorRepairTypes,
  personTitles,
  contactLabels,
};
