import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import {
  Root,
} from './styledItems';

const Timer = (props) => {
  const {
    date,
  } = props;

  const [daysHours, setTimerValue] = useState({
    days: 0,
    hours: 0,
  });

  useEffect(() => {
    const now = moment();
    const end = moment(date).utc();
    const duration = moment.duration(end.diff(now));

    const days = duration.asDays();
    duration.subtract(moment.duration(Math.floor(days), 'days'));

    const hours = duration.hours();

    setTimerValue({
      days: days > 0 ? Math.floor(days) : 0,
      hours: days > 0 ? Math.abs(hours) : 0,
    });
  }, [date]);

  return (
    <Root>
      <Flex
        className="timer-flex-container"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="stretch"
      >
        <Flex justifyContent="space-between">
          <Typography
            variant="title1"
            color={daysHours.days === 0 ? 'alert' : 'main-text'}
            weight="normal"
            align="center"
            style={{
              minWidth: 35,
            }}
            noMargin
          >
            {`${daysHours.days}`}
            <Typography
              variant="subtitle"
              color="main-text"
              noMargin
            >
              Days
            </Typography>
          </Typography>
          <Typography
            variant="title1"
            color={daysHours.days === 0 ? 'alert' : 'main-text'}
            weight="normal"
            noMargin
          >
            :
          </Typography>
          <Typography
            variant="title1"
            color={daysHours.days === 0 ? 'alert' : 'main-text'}
            weight="normal"
            align="center"
            style={{
              minWidth: 35,
            }}
            noMargin
          >
            {`${daysHours.hours}`}
            <Typography
              variant="subtitle"
              color="main-text"
              noMargin
            >
              Hours
            </Typography>
          </Typography>
        </Flex>
      </Flex>
    </Root>
  );
};

Timer.defaultProps = {
  date: null,
};

const {
  string,
} = PropTypes;

Timer.propTypes = {
  date: string,
};

export default Timer;
