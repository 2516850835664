import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import {
  receiveLogout,
  triggerPermissionModal,
} from 'store/actions/auth';
import { common } from 'constants';
import './PermissionModal.scss';

// TODO: fix lint errors
/* eslint-disable */

const PermissionModal = (props) => {
  const hr = props.hrAdmin;
  const hrAdmin = hr ? `${hr.Name} ${hr.Surname}` : '-';
  const footer = (
    <div className="premission-modal__footer">
      <Button
        type="primary"
        onClick={() => props.triggerPermissionModal(false)}
      >
        Ok
      </Button>
    </div>
  );

  return (
    <Modal
      width={480}
      title={null}
      closable={false}
      centered
      footer={footer}
      visible={props.visible}
      afterClose={props.receiveLogout}
      wrapClassName="premission-modal"
    >

      <div className="premission-modal__header">
        <img
          className="premission-modal__icon"
          src="/assets/img/401-icon.png"
          alt="premission-modal-icon"
        />
      </div>

      <div className="premission-modal__content">
        {
          `You have no Symplete licenses assigned to your account. Although
         some basic features might work, it's best to contact your Team
         Admin, ${hrAdmin}, to get back up and running.`
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  visible: state.auth.permissionModal,
  hrAdmin: state.myCompany.employees.find((employee) => employee.ManagerRole & common.PERMISSIONS.HRAdmin),
});

const mapDispatchToProps = {
  receiveLogout,
  triggerPermissionModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionModal);
