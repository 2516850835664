import {
  useState,
} from 'react';

import requests from 'api/requests';

const seedData = (setIsLoading, setError) => async () => {
  setIsLoading(true);

  try {
    await requests.realtor.demo.create();
    setError(null);
  } catch (error) {
    setError(error);
  } finally {
    setIsLoading(false);
  }
};

const useDemoDataSeed = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  return {
    seed: seedData(setIsLoading, setError),
    isLoading,
    error,
  };
};

export default useDemoDataSeed;
