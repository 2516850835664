import createSource from './createSource';

const createSourceFromNewContactForm = () => async (dispatch, getState) => {
  const {
    newContactForm,
  } = getState();

  const sourceData = {
    type: newContactForm.sourceType,
    description: newContactForm.sourceDescription,
  };

  try {
    const sourceId = await dispatch(createSource(sourceData));
    return sourceId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createSourceFromNewContactForm;
