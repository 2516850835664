import {
  SET_SELECTED_REALTOR_TRANSACTION,
} from 'store/actionTypes/realtorTransactions';

const setSelectedTransaction = (id) => ({
  type: SET_SELECTED_REALTOR_TRANSACTION,
  payload: id,
});

export default setSelectedTransaction;
