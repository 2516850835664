import compressFile from 'shared/utils/attachments/compressFile';

/**
 *
 * @param {File} attachment
 * @param {Object} attachmentMeta
 * @param {function} onCompressProgress
 */
const createAttachmentFormData = async (attachment, attachmentMeta = {}, onCompressProgress) => {
  const formData = new FormData();

  let compressedAttachment;

  try {
    compressedAttachment = await compressFile(attachment, onCompressProgress);
  } catch (error) {
    compressedAttachment = attachment;
  }

  // This is a hacky workaround to prevent transforming animated gifs to pngs
  if (compressedAttachment.type === 'image/gif') {
    compressedAttachment = new File([compressedAttachment], compressedAttachment.name, {
      type: 'gif',
    });
  }
  //

  formData.append('attachment', compressedAttachment);

  Object.keys(attachmentMeta).forEach((key) => {
    formData.append(key, attachmentMeta[key]);
  });

  return formData;
};

export default createAttachmentFormData;
