import styled from 'styled-components';

import HouseDefault from 'assets/icons/property.svg';

export const Root = styled.div`
  width: 100%;
`;

export const Image = styled.div`
  width: 80px;
  height: 64px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
  box-shadow: ${(props) => (props.checked ? `0 0 0 2px ${props.theme.palette?.focus}` : null) };
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Input = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  `;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  `;
