import requests from 'api/requests';

import setPropertyAttachments from './setPropertyAttachments';

const getPropertyAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(27)(id);
    dispatch(setPropertyAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getPropertyAttachments;
