import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import {
  createFollowUpReminders,
} from 'store/actions/followUpReminders';

import FollowUpRemindersModalNew from './FollowUpRemindersModalNew';

const mapStateToProps = (state, ownProps) => {
  const {
    modals: {
      followUpRemindersNew,
    },
  } = state;

  return {
    isVisible: followUpRemindersNew?.isVisible,
    objectId: followUpRemindersNew?.objectId,
    objectTypeId: followUpRemindersNew?.objectTypeId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onOk: () => {
    dispatch(closeModal('followUpRemindersNew')());
  },
  onCreate: (objectTypeId, objectId) => (reminderData) => {
    dispatch(createFollowUpReminders(objectTypeId)(objectId)([reminderData]));
    dispatch(closeModal('followUpRemindersNew')());
  },
  closeModal: () => {
    dispatch(closeModal('followUpRemindersNew')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpRemindersModalNew);
