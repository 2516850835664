import {
  CLOSE_PAYNENT_MODAL, OPEN_PAYNENT_MODAL, OPEN_PAYNENT_MODAL_SUCCESS,
  OPEN_BILLING_DATE_MODAL, OPEN_BILLING_DATE_MODAL_SUCCESS, CLOSE_BILLING_DATE_MODAL,
  OPEN_CONTACTS_MODAL_SUCCESS, OPEN_CONTACTS_MODAL, CLOSE_CONTACTS_MODAL,
  CLOSE_CARD_MODAL, OPEN_CARD_MODAL, OPEN_CARD_MODAL_SUCCESS,
  GET_CONTACT_SUCCESS, UPDATE_CONTACT_SUCCESS, UPDATE_CONTACT,
  GET_NEXT_PAYMENT, GET_LAST_PAYMENT,
} from '../actions/billing';
import { GET_LICENSE_COMPANY_LOG, GET_LICENSE_COMPANY_LOG_SUCCESS, GET_LICENSE_COMPANY_LOG_ERROR } from '../actions/MyCompany/license-management';

const initialState = {
  overview: {
    payment: {
      paymentModal: false,
      loading: false,
      next: undefined,
      last: undefined,
      status: '',
    },
    billingDate: {
      billingModal: false,
      loading: false,
      data: null,
    },
    contacts: {
      contactsModal: false,
      loading: false,
      data: null,
    },
    card: {
      cardModal: false,
      loading: false,
      data: null,
    },
  },
  transaction: {
    data: [],
  },
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEXT_PAYMENT: {
      return {
        ...state,
        overview: {
          ...state.overview,
          payment: {
            ...state.overview.payment,
            next: action.payload,
          },
        },
      };
    }
    case GET_LAST_PAYMENT: {
      return {
        ...state,
        overview: {
          ...state.overview,
          payment: {
            ...state.overview.payment,
            last: action.payload,
          },
        },
      };
    }
    case CLOSE_PAYNENT_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          payment: {
            ...state.overview.payment,
            paymentModal: false,
          },
        },
      };
    }
    case OPEN_PAYNENT_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          payment: {
            ...state.overview.payment,
            loading: true,
            status: action.payload,
          },
        },
      };
    }
    case OPEN_PAYNENT_MODAL_SUCCESS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          payment: {
            ...state.overview.payment,
            paymentModal: true,
            loading: false,
            // data: action.payload,
          },
        },
      };
    }

    case CLOSE_BILLING_DATE_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          billingDate: {
            ...state.overview.billingDate,
            billingModal: false,
          },
        },
      };
    }
    case OPEN_BILLING_DATE_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          billingDate: {
            ...state.overview.billingDate,
            loading: true,
          },
        },
      };
    }
    case OPEN_BILLING_DATE_MODAL_SUCCESS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          billingDate: {
            ...state.overview.billingDate,
            billingModal: true,
            loading: false,
            data: action.payload,
          },
        },
      };
    }

    case CLOSE_CONTACTS_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          contacts: {
            ...state.overview.contacts,
            contactsModal: false,
          },
        },
      };
    }
    case OPEN_CONTACTS_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          contacts: {
            ...state.overview.contacts,
            loading: true,
          },
        },
      };
    }
    case OPEN_CONTACTS_MODAL_SUCCESS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          contacts: {
            ...state.overview.contacts,
            contactsModal: true,
            loading: false,
            data: action.payload,
          },
        },
      };
    }

    case CLOSE_CARD_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          card: {
            ...state.overview.card,
            cardModal: false,
          },
        },
      };
    }
    case OPEN_CARD_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          card: {
            ...state.overview.card,
            loading: true,
          },
        },
      };
    }
    case OPEN_CARD_MODAL_SUCCESS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          card: {
            ...state.overview.card,
            cardModal: true,
            loading: false,
          },
        },
      };
    }

    case GET_CONTACT_SUCCESS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          card: {
            ...state.overview.card,
            data: action.payload,
          },
        },
      };
    }

    case UPDATE_CONTACT_SUCCESS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          contacts: {
            ...state.overview.contacts,
            contactsModal: false,
          },
          card: {
            ...state.overview.card,
            data: {
              ...state.overview.card.data,
              ...action.payload,
            },
          },
        },
      };
    }

    case GET_LICENSE_COMPANY_LOG: {
      return state;
    }
    case GET_LICENSE_COMPANY_LOG_SUCCESS: {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          data: action.payload,
        },
      };
    }
    case GET_LICENSE_COMPANY_LOG_ERROR: {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          data: [],
        },
      };
    }
    default:
      return state;
  }
};

export default billing;
