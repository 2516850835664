import {
  SET_REALTOR_REPAIR_REQUESTS,
} from 'store/actionTypes/realtorRepairRequests';

const setRepairRequests = (items) => ({
  type: SET_REALTOR_REPAIR_REQUESTS,
  payload: items,
});

export default setRepairRequests;
