import { connect } from 'react-redux';

import {
  loginRequest,
  selectWorkspace,
  triggerRecoverModal,
  hideWorkspaces,
} from 'store/actions/auth';

import LoginWorkflow from './LoginWorkflow';

const mapStateToProps = (state) => {
  const {
    auth: {
      activeTab,
      error,
      externalLogin,
      externalPassword,
      isAuthenticated,
      isFetching,
      recoverModal,
      tempToken,
      twoFactor,
      workspaces,
    },
  } = state;

  return {
    activeTab,
    error,
    externalLogin,
    externalPassword,
    isAuthenticated,
    isFetching,
    recoverModal,
    token: tempToken,
    twoFactor,
    workspaces,
  };
};

const mapDispatchToProps = {
  hideWorkspaces,
  loginRequest,
  selectWorkspace,
  triggerRecoverModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginWorkflow);
