import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
  Button,
} from './styledItems';

const buttons = [
  {
    title: 'Archive',
    desription: 'For leads that have gone cold',
    key: 'archive',
  },
  {
    title: 'Delete',
    desription: 'For data that is no longer needed',
    key: 'delete',
  },
];

const ActionSelector = (props) => {
  const {
    selectedAction,
    onChange,
  } = props;

  return (
    <Root>
      {
        buttons.map((button) => (
          <Button
            active={selectedAction === button.key}
            onClick={onChange(button.key)}
          >
            <Typography
              variant="h5"
              tag="p"
              weight={700}
              noMargin
            >
              {button.title}
            </Typography>
            <Typography
              variant="h6"
              tag="p"
              weight={400}
              noMargin
            >
              {button.desription}
            </Typography>
          </Button>
        ))
      }
    </Root>
  );
};

ActionSelector.defaultProps = {
  selectedAction: null,
  onChange: () => () => {},
};

const {
  string,
  func,
} = PropTypes;

ActionSelector.propTypes = {
  selectedAction: string,
  onChange: func,
};

export default ActionSelector;
