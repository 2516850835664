import React, { Component } from 'react';
import './FilterModal.scss';

import { SearchFilterForm } from './SearchFilterForm/SearchFilterForm';

class SearchFilter extends Component {
  handleCancel = () => {
    this.props.triggerModal();
    const { form } = this.formRef.props;
    form.resetFields();
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.saveSearchFilter(values);
        this.props.triggerModal();
      }
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    return (
      <SearchFilterForm
        wrappedComponentRef={this.saveFormRef}
        onCancel={this.handleCancel}
        onCreate={this.handleCreate}
        data={this.props}
      />
    );
  }
}

export default SearchFilter;
