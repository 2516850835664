import requests from 'api/requests';

import setShippedItem from './setShippedItem';
import getShippedItemAttachments from './getShippedItemAttachments';

const getOrderById = (id) => async (dispatch) => {
  try {
    const response = await requests.shippedItems.getById(id);
    dispatch(setShippedItem(response.data));
    dispatch(getShippedItemAttachments(id));
  } catch (error) {
    console.error(error);
  }
};

export default getOrderById;
