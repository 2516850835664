import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import {
  without,
  isEmpty,
} from 'shared/utility';

import ContactsList from './ContactsList';

const MultiContactsSelect = (props) => {
  const {
    oneContactSelect,
    selectedContacts: selectedContactsProps,
    contactList,
    setValue,
    formName,
    control,
    errors,
    required,
  } = props;

  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    setSelectedContacts(selectedContactsProps || []);
  }, [
    JSON.stringify(selectedContactsProps),
  ]);

  const addContact = useCallback((contactId) => (event) => {
    event.preventDefault();
    setSelectedContacts([
      ...selectedContacts,
      contactId,
    ]);
    setValue(formName, [
      ...selectedContacts,
      contactId,
    ]);
  }, [
    selectedContacts,
    formName,
  ]);

  const removeContact = useCallback((contactId) => (event) => {
    event.preventDefault();
    setSelectedContacts(
      without(selectedContacts, contactId),
    );
    setValue(formName, without(selectedContacts, contactId));
  }, [
    selectedContacts,
  ]);

  return (
    <Controller
      name={formName}
      control={control}
      defaultValue={selectedContactsProps}
      render={({ field }) => (
        <ContactsList
          {...field}
          error={!!errors?.[formName]}
          contactList={contactList}
          selectedContacts={selectedContacts}
          oneContactSelect={oneContactSelect}
          onAddContact={addContact}
          onRemoveContact={removeContact}
        />
      )}
      rules={{
        validate: {
          isEmpty: () => (required ? !isEmpty(selectedContacts) : true),
        },
      }}
    />
  );
};

MultiContactsSelect.defaultProps = {
  oneContactSelect: false,
  selectedContacts: [],
  contactList: [],
  required: false,
};

const {
  bool,
  shape,
  number,
  arrayOf,
  func,
  string,
} = PropTypes;

MultiContactsSelect.propTypes = {
  oneContactSelect: bool,
  selectedContacts: arrayOf(number),
  contactList: arrayOf(shape()),
  required: bool,
  errors: arrayOf(shape()).isRequired,
  setValue: func.isRequired,
  formName: string.isRequired,
  control: arrayOf(shape()).isRequired,
};

export default MultiContactsSelect;
