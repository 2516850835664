import apiConnector, { endpoints } from 'api';

const getTaskById = async (taskId, employeeId = null) => {
  const endpoint = `${endpoints.client.v2.tasks}/${taskId}`;

  try {
    const result = await apiConnector.client.get(endpoint, { employeeId });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getTaskById;
