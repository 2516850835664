import useGoogleAuth from 'sections/Integrations/hooks/useGoogleAuth';

const GOOGLE_SCOPES = [
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/contacts',
].join(' ');

const useGmailAuth = (token) => {
  const {
    auth,
  } = useGoogleAuth(GOOGLE_SCOPES, JSON.stringify({
    auth: token,
    scope: 'contacts',
  }));

  return {
    auth,
  };
};

export default useGmailAuth;
