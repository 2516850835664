import { Node } from '@tiptap/core';

import './styles.scss';

const TemplateItemNode = Node.create({
  name: 'templateItem',
  draggable: true,
  group: 'inline',
  inline: true,
  atom: true,
  content: 'text*',

  renderHTML({ node }) {
    const content = node?.content?.content?.[0]?.text;

    return [
      'span',
      {
        'data-content': content,
        'data-symplete-type': 'templateItem',
        class: 'symplete-text-editor__template-item',
      },
      content,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        attrs: {
          'data-symplete-type': 'templateItem',
        },
      },
    ];
  },
});

export default TemplateItemNode;
