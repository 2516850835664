import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getClientsForFilter from './getClientsForFilter';

const getClientsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.realtorClients.clientsFilter(getState());

  try {
    await dispatch(getClientsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getClientsForStoreFilter;
