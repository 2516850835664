import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeNewCounterModal,
} from 'store/actions/newRealtorCounterModal';

import {
  createOffer,
  getOfferById,
} from 'store/actions/realtorOffers';

import {
  createSpecialCondition,
} from 'store/actions/realtorSpecialConditions';

import {
  getClientById,
} from 'store/actions/realtorClients';

import NewCounterForm from './NewCounterForm';

const mapStateToProps = (state) => {
  const {
    realtorNewCounterModal: {
      rootOfferId,
      offerId,
    },
  } = state;

  const originalOffer = selectors.realtorOffers.byId(state, offerId);

  const specialConditions = originalOffer.special_conditions
    ? originalOffer.special_conditions.map((id) => {
      const item = selectors.realtorSpecialConditions.byId(state, id);

      if (!item) return {};

      return {
        ...item,
        value: item.name,
        isSelected: true,
      };
    })
    : [];

  specialConditions.push({ value: '', isSelected: false, isNew: true });

  const {
    realtor_client_id,
    realtor_prospect_id,
    realtor_property_id,
    expiry_date,
  } = originalOffer;

  return {
    buyerId: originalOffer?.buyer_contact_id,
    buyerAgentId: originalOffer?.buyer_agent_contact_id,
    specialConditions,
    address: originalOffer?.relatedData?.address?.address,
    originalOffer,
    rootOfferId,

    contactId: realtor_client_id || realtor_prospect_id,
    contactType: realtor_client_id ? 'client' : 'prospect',
    propertyId: realtor_property_id,
    date: expiry_date,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (data, attachments) => {
    const {
      closing_date,
      contingency_period_end,
      expiry_date,
      price: amount,

      contingency_appraisal,
      contingency_inspection,
      contingency_loan,

      originalOffer,

      specialConditions,
      rootOfferId,
    } = data;

    const specialConditionsRequests = specialConditions
      ? specialConditions
          .filter((item) => item && (item.isNew || item.isSelected) && item.value)
          .map(async (item) => {
            const id = await dispatch(createSpecialCondition({
              name: item.value,
            }));

            return id;
          })
      : [];

    const offerData = {
      ...originalOffer,
      parent_offer_id: originalOffer?.id,
      type: originalOffer.type === 1 ? 2 : 1,
      closing_date,
      contingency_period_end,
      expiry_date,
      amount,
      specialConditions: await Promise.all(specialConditionsRequests),
      contingency_inspection,
      contingency_appraisal,
      contingency_loan,
    };

    const counterId = await dispatch(createOffer({
      ...offerData,
    }));

    dispatch(getClientById(originalOffer.realtor_client_id));

    dispatch(getOfferById(rootOfferId));

    dispatch(getOfferById(originalOffer.id));
    dispatch(getOfferById(counterId));
    dispatch(closeNewCounterModal());

    if (ownProps.onAfterSubmit) {
      ownProps.onAfterSubmit();
    }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: async (data) => {
    dispatchProps.onSubmit({
      ...data,
      originalOffer: stateProps.originalOffer,
      rootOfferId: stateProps.rootOfferId,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewCounterForm);
