import requests from 'api/requests';

import setAgreementAttachments from './setAgreementAttachments';

const getAgreementAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(24)(id);
    dispatch(setAgreementAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getAgreementAttachments;
