import create from './create';
import getById from './getById';
import update from './update';
import deleteEscrow from './delete';

export default {
  create,
  getById,
  update,
  deleteEscrow,
};
