import { createSelector } from 'reselect';

const employees = (state) => state.myCompany.employees;

const creator = createSelector(
  employees,
  (items) => {
    let companyCreator = {};

    if (items) {
      companyCreator = items.find((item) => item.isAccountCreator);
    }

    return companyCreator;
  },
);

export default creator;
