import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import ContactNote from './ContactNote';

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('contactNote')(value)),
});

export default connect(null, mapDispatchToProps)(ContactNote);
