import { connect } from 'react-redux';

import {
  showLinkToImg,
} from 'store/actions/articleDetail';

import AddImageModal from './AddImageModal';

const mapStateToProps = (state) => ({
  linkToImgModal: state.articleDetail.linkToImgModal,
});

const mapDispatchToProps = (dispatch) => ({
  triggerModal: () => dispatch(showLinkToImg()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddImageModal);
