import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorMatchedActions,
} from 'store/actions/realtorMatched';

const defaultItem = {
  id: null,
};

export default (id) => createSelector(
  (state) => state.realtorMatched.matched,
  (collection) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorMatchedActions.getMatchingById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
    };
  },
);
