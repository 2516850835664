import create from './create';
import getById from './getById';
import search from './search';
import getByField from './getByField';
import get from './get';
import update from './update';
import deleteContact from './deleteContact';
import addRelations from './addRelations';
import removeRelations from './removeRelations';
import getCompleteIdsList from './getCompleteIdsList';

export default {
  create,
  search,
  getById,
  getByField,
  get,
  update,
  deleteContact,
  addRelations,
  removeRelations,
  getCompleteIdsList,
};
