import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

const properties = (state) => state.properties.properties;
const itemsForFilter = (state, filterId) => state.properties.propertiesByFilter[filterId];

export default createSelector(
  properties,
  itemsForFilter,
  (items, filteredIds) => {
    let propertiesList = [];
    let firstSelfListingPropertyKey;
    let firstNotSelfListingPropertyKey;

    if (filteredIds) {
      propertiesList = filteredIds.reduce((accum, id, index) => {
        const property = items[id];

        if (!property && id) {
          optimizedPropertyActions.getPropertyById(id);
          return accum;
        }

        if (!firstSelfListingPropertyKey && property.listing_agent === 0) {
          firstSelfListingPropertyKey = index + 1;
        }
        if (!firstNotSelfListingPropertyKey && property.listing_agent !== 0) {
          firstNotSelfListingPropertyKey = index + 1;
        }

        accum.push({
          key: index + 1,
          ...property,
        });
        return accum;
      }, []);
    }

    return {
      propertiesList,
      firstSelfListingPropertyKey,
      firstNotSelfListingPropertyKey,
    };
  },
);
