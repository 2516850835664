import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import moment from 'moment';

import ScheduleItem from './ScheduleItem';

import {
  Row,
  Column,
  ItemsWrapper,
} from './styledItems';

const TimeSlotRow = (props) => {
  const {
    time,
    items,
    onItemClick,
  } = props;

  const midHourMoment = moment(time).add(30, 'minutes');

  return (
    <>
      <Row>
        <td
          rowSpan="2"
          valign="top"
          align="right"
          width="20%"
        >
          <Typography
            variant="title3"
            color="descriptor-text"
            className="time-row-title"
            noMargin
          >
            {
              moment(time).format('LT')
            }
          </Typography>
        </td>
        <Column>
          <ItemsWrapper>
            {
              items.map((item) => (moment(item.date).isSameOrBefore(midHourMoment, 'minutes') ? (
                <ScheduleItem itemData={item} onClick={onItemClick} />
              ) : null))
            }
          </ItemsWrapper>
        </Column>
      </Row>
      <Row>
        <Column>
          <ItemsWrapper>
            {
              items.map((item) => (moment(item.date).isAfter(midHourMoment, 'minutes') ? (
                <ScheduleItem itemData={item} onClick={onItemClick} />
              ) : null))
            }
          </ItemsWrapper>
        </Column>
      </Row>
    </>
  );
};

TimeSlotRow.defaultProps = {
  onItemClick: null,
};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

TimeSlotRow.propTypes = {
  onItemClick: func,
};

export default TimeSlotRow;
