import apiConnector, { endpoints } from 'api';

const get = async query => {
  const endpoint = endpoints.client.syncedEvents.general;

  try {
    console.log('called syncedEvents endpoint with query: ', query);
    const result = await apiConnector.client.get(endpoint, query);
    console.log('got syncedEvents from BE: ', result);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default get;
