import connectReducers from 'store/connectReducers';

import {
  SET_PROPERTIES_FILTER_ITEM,
  RESET_PROPERTIES_FILTER_ITEM,
  CLEAR_PROPERTIES_FILTER,
} from 'store/actionTypes/properties';

import { setValue } from 'store/utils';

const initialState = {
  sortBy: 'myListings',
  sortDirection: 'desc',
  pageNumber: 1,
  pageSize: 10,
  ownership: null,
  status: null,
  search: null,
};

const reducers = {
  [SET_PROPERTIES_FILTER_ITEM]: setValue,

  [RESET_PROPERTIES_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload]: initialState[action.payload],
  }),

  [CLEAR_PROPERTIES_FILTER]: (state, action) => initialState,
};

function propertiesFilter(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default propertiesFilter;
