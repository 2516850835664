import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  ImageContainer,
} from './styledItems';

const Property = (props) => {
  const {
    address,
    image,
  } = props;

  return (
    <div>
      <Typography>{address}</Typography>

      <ImageContainer src={image}>
        {
          !image && (
            <Icon
              name="property"
              className="property-icon"
              type="custom"
              color="background-flat"
              size={60}
            />
          )
        }
      </ImageContainer>
    </div>
  );
};

Property.defaultProps = {
  image: null,
};

const {
  string,
} = PropTypes;

Property.propTypes = {
  address: string.isRequired,
  image: string,
};

export default Property;
