import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getPropertiesForStoreFilter,
} from 'store/actions/properties';

import PropertiesPreviews from './PropertiesPreviews';

const mapStateToProps = (state) => {
  const {
    filterId,
  } = selectors.properties.propertiesFilter(state);

  const {
    propertiesList,
    firstNotSelfListingPropertyKey,
  } = selectors.properties.propertiesList(state, filterId);

  const myListings = firstNotSelfListingPropertyKey
    ? propertiesList.slice(0, firstNotSelfListingPropertyKey - 1)
    : propertiesList;

  const featuredProperties = firstNotSelfListingPropertyKey
    ? propertiesList.slice(firstNotSelfListingPropertyKey - 1)
    : [];

  return {
    myListings,
    featuredProperties,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProperties: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    dispatch(getPropertiesForStoreFilter(offset, limit));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesPreviews);
