import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import RealtorLeadItemDetails from 'shared/components/UI/RealtorLeadItemDetails';

const mapStateToProps = (state) => {
  const {
    realtorProspects: {
      selectedProspect,
    },
  } = state;

  const prospect = selectors.realtorProspects.byId(state, selectedProspect);
  const connectedItem = selectors.propertyRequirements.byId(state, prospect.realtor_property_requirement_id);

  const contact = selectors.realtorContacts.byId(state, prospect.contact_id);
  const address = selectors.addresses.byId(state, connectedItem.addresses?.[0]);

  const data = {
    contactName: `${contact?.first_name || ''} ${contact?.last_name || ''}`,
    location: address?.address,
    role: 1,
    expectedSalePrice: connectedItem.expected_price,
    expectedPurchasePrice: [connectedItem.budget_min, connectedItem.budget_max],
    propertyType: Array.isArray(connectedItem.property_type) && connectedItem.property_type.map((type) => PROPERTY_TYPES[type]),
  };

  return data;
};

export default connect(mapStateToProps)(RealtorLeadItemDetails);
