import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import withContactDataBasic from 'sections/Contacts/hocs/withContactDataBasic';
import withContactData from 'sections/Contacts/hocs/withContactData';

import RealtorContactAvatar from 'shared/components/UI/RealtorContactAvatar';
import Typography from 'ui/Typography';

const Avatar = withContactDataBasic(RealtorContactAvatar);
const Field = withFieldDecorator({
  variant: 'ghost',
  size: 'sm',
  noMargin: true,
})(Typography);

const EmailField = ({ contactData: { email_primary } }) => (email_primary
    ? (
      <a href={`mailto:${email_primary}`} target="_blank" rel="noreferrer">
        <Field
          decoratorProps={{
            iconName: 'envelope',
          }}
          decorated
          noMargin
          variant="link"
        >
          {email_primary}
        </Field>
      </a>
    )
    : null);

const PhoneField = ({ contactData: { phone_primary } }) => (phone_primary
    ? (
      <a href={`tel:${phone_primary}`} target="_blank" rel="noreferrer">
        <Field
          decoratorProps={{
            iconName: 'phone',
          }}
          decorated
          noMargin
          variant="link"
        >
          {phone_primary}
        </Field>
      </a>
    )
    : null);

const Email = withContactData(EmailField);
const Phone = withContactData(PhoneField);

const Name = withContactData(({ contactData: { first_name: firstName, last_name: lastName } }) => (
  <Typography
    variant="h2"
    tag="p"
    noMargin
  >
    {firstName}
    {' '}
    {lastName}
  </Typography>
));

const Role = ({ children }) => (
  <Typography
    variant="h4"
    tag="p"
    color="highlight"
    noMargin
  >
    {children}
  </Typography>
);

const RequestItemSummaryExpanded = (props) => {
  const {
    contactId,
    contactRole,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
    >
      <Grid item>
        <Grid container alignItems="center" spacing={2}>
          {
            contactId && (
              <Grid item>
                <Avatar
                  contactId={contactId}
                  size={60}
                  round
                />
              </Grid>
            )
          }

          <Grid item>
            <Grid
              container
              spacing={1}
            >
              {
                contactId && (
                  <Grid item xs={12}>
                    <Name contactId={contactId} />
                  </Grid>
                )
              }

              {
                contactRole && (
                  <Grid item xs={12}>
                    <Role>
                      {contactRole}
                    </Role>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1} style={{ paddingTop: 7 }}>
          <Grid item xs={12}>
            <Email contactId={contactId} />
          </Grid>
          <Grid item xs={12}>
            <Phone contactId={contactId} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RequestItemSummaryExpanded.defaultProps = {
  contactId: null,
  contactRole: null,
};

const {
  number,
  string,
} = PropTypes;

RequestItemSummaryExpanded.propTypes = {
  contactId: number,
  contactRole: string,
};

export default RequestItemSummaryExpanded;
