import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { debounce } from 'shared/utility';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import ContactNameInput from './ContactNameInput';

const mapStateToProps = (state, ownProps) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    withSalutations: !!settings.salutations_on,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    dispatch(updateContact(ownProps.contactId)({
      [key]: value,
    }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactNameInput);
