import React from 'react';
import PropTypes from 'prop-types';

import UserAvatar from 'shared/components/UserAvatarV2';

const MentionsEntry = (props) => {
  const {
    mention,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  return (
    // draft-js-mention-plugin docs suggest props spreading
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...parentProps}>
      <div className="mentions-suggestions">
        <div className="mentions-suggestions-avatar">
          <UserAvatar
            key={mention.id}
            userType={1}
            userId={mention.id}
            tooltipPlacement="bottom"
            size="xxs"
          />
        </div>
        <div className="mentions-suggestions-name">
          <div>
            {mention.name}
          </div>
        </div>
      </div>
    </div>
  );
};

const {
  string,
  bool,
  objectOf,
  any,
} = PropTypes;

MentionsEntry.defaultProps = {
  searchValue: string,
  isFocused: true,
};

MentionsEntry.propTypes = {
  mention: objectOf(any).isRequired,
  searchValue: string,
  isFocused: bool,
};

export default MentionsEntry;
