import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import { // eslint-disable-line import/no-cycle
  getTaskAndRefreshRelatedSummaries,
} from 'store/actions/tasksV2';
import { getTeams } from './team-setup'; // eslint-disable-line import/no-cycle
import { getTasks, tasksStatus } from './tasks'; // eslint-disable-line import/no-cycle


export const SET_TASKS_VIEW = 'SET_TASKS_VIEW';

export const OPEN_RESOLVE_TASK_MODAL = 'OPEN_RESOLVE_TASK_MODAL';
export const openResolveTaskModal = () => ({ type: OPEN_RESOLVE_TASK_MODAL });

export const HIDE_RESOLVE_TASK_MODAL = 'HIDE_RESOLVE_TASK_MODAL';
export const hideResolveTaskModal = () => ({ type: HIDE_RESOLVE_TASK_MODAL });

export const SET_TASKS_TAB = 'SET_TASKS_TAB';
export const setTasksTab = (tab) => ({ type: SET_TASKS_TAB, tab });

export const SET_TASK_FILTER = 'SET_TASK_FILTER';

export const SET_TASK_PANELS = 'SET_TASK_PANELS';
export const setTaskPanels = (panels) => ({
  type: SET_TASK_PANELS,
  panels,
});

export const GET_TASKS_BY_STATUS = 'GET_TASKS_BY_STATUS';
export const getTasksByStatus = () => ({ type: GET_TASKS_BY_STATUS });

export const GET_TASKS_BY_STATUS_SUCCESS = 'GET_TASKS_BY_STATUS_SUCCESS';
export const getTasksByStatusSuccess = (name, list) => ({
  type: GET_TASKS_BY_STATUS_SUCCESS,
  name,
  list,
});

export const GET_TASKS_BY_STATUS_ERROR = 'GET_TASKS_BY_STATUS_ERROR';
export const getTasksByStatusError = () => ({
  type: GET_TASKS_BY_STATUS_ERROR,
});

export const SET_TASKS_TO_TABLE_SUCCESS = 'SET_TASKS_TO_TABLE_SUCCESS';
export const setTasksToTableSuccess = (list) => ({
  type: SET_TASKS_TO_TABLE_SUCCESS,
  list,
});

export const SET_TASK_DATA = 'SET_TASK_DATA';
export const setTaskData = (status, task) => ({
  type: SET_TASK_DATA,
  status,
  task,
});

export const RATE_TASK = 'RATE_TASK';
export const rateTask = () => ({ type: RATE_TASK });

export const RATE_TASK_SUCCESS = 'RATE_TASK_SUCCESS';
export const rateTaskSuccess = (status, task, data) => ({
  type: RATE_TASK_SUCCESS,
  status,
  task,
  data,
});

export const RATE_TASK_ERROR = 'RATE_TASK_ERROR';
export const rateTaskError = () => ({ type: RATE_TASK_ERROR });

export const RESOLVE_TASK_BY_MODAL = 'RESOLVE_TASK_BY_MODAL';
export const resolveTaskByModal = () => ({ type: GET_TASKS_BY_STATUS });

export const RESOLVE_TASK_BY_MODAL_SUCCESS = 'RESOLVE_TASK_BY_MODAL_SUCCESS';
export const resolveTaskByModalSuccess = (
  status,
  task,
  newResolvedName,
) => (dispatch) => {
  dispatch({
    type: RESOLVE_TASK_BY_MODAL_SUCCESS,
    status,
    task,
    newResolvedName,
  });
  getTeams(dispatch);
  dispatch(getTasks());
};

export const RESOLVE_TASK_BY_MODAL_ERROR = 'RESOLVE_TASK_BY_MODAL_ERROR';
export const resolveTaskByModalError = () => ({
  type: RESOLVE_TASK_BY_MODAL_ERROR,
});

export const UNRESOLVE_TASK = 'UNRESOLVE_TASK';
export const unresolveTask = (taskId, statusId, newRank = null, employeeId = null) => (dispatch) => {
  dispatch({ type: UNRESOLVE_TASK });

  const url = `/api/tasks/unresolve/${taskId}/${statusId}`;

  const body = {
    EmployeeID: employeeId,
    Rank: newRank,
  };

  apiConnector(url, 'PUT', body)
    .then((res) => dispatch(unresolveTaskSuccess(taskId)))
    .catch((err) => dispatch(unresolveTaskError()));
};

export const UNRESOLVE_TASK_SUCCESS = 'UNRESOLVE_TASK_SUCCESS';
export const unresolveTaskSuccess = (taskId) => (dispatch) => {
  dispatch({ type: UNRESOLVE_TASK_SUCCESS });
  dispatch(getTaskAndRefreshRelatedSummaries(taskId));
};

export const UNRESOLVE_TASK_ERROR = 'UNRESOLVE_TASK_ERROR';
export const unresolveTaskError = () => ({ type: UNRESOLVE_TASK_ERROR });

export const SET_TASKS = 'SET_TASKS';
export const setTasks = () => (dispatch, getState) => {
  dispatch({ type: SET_TASKS });
  getTeams(dispatch);
  dispatch(getTasks());
  tasksStatus(dispatch, getState);
};

export const CHANGE_TASK_STATUS = 'CHANGE_TASK_STATUS';
export const changeTaskStatus = () => ({ type: CHANGE_TASK_STATUS });

export const CHANGE_TASK_STATUS_ERROR = 'CHANGE_TASK_STATUS_ERROR';
export const changeTaskStatusError = () => ({ type: CHANGE_TASK_STATUS_ERROR });

export const TOGGLE_SHOW_ARCHIVED_TASKS = 'TOGGLE_SHOW_ARCHIVED_TASKS';
export const toggleShowArchivedTasks = () => ({ type: TOGGLE_SHOW_ARCHIVED_TASKS });

export const changeTaskStatusApi = (
  task,
  oldStatusId,
  newStatusId,
) => async (dispatch, getState) => {
  const {
    Name,
    Surname,
  } = getState().auth.loggedUser;

  dispatch(changeTaskStatus());

  const body = {
    TaskID: task.TaskID,
    StatusID: newStatusId,
    UserFullName: `${Name} ${Surname}`,
  };

  try {
    await apiConnector('/Tasks/ChangeTaskStatus', 'POST', body);
  } catch (error) {
    changeTaskStatusError(error);
  }
};

export const SET_IS_RESOLVE_MODAL_CANCELED = 'SET_IS_RESOLVE_MODAL_CANCELED';
export const setIsResolveModalCanceled = (isCanceled) => ({
  type: SET_IS_RESOLVE_MODAL_CANCELED,
  isCanceled,
});
