import edit from './edit';
import updateAssignedUsers from './updateAssignedUsers';
import updateManagers from './updateManagers';
import updateMembers from './updateMembers';

import addSubqueue from './addSubqueue';
import editSubqueue from './editSubqueue';
import deleteSubqueue from './deleteSubqueue';

import addSubcategory from './addSubcategory';
import editSubcategory from './editSubcategory';
import deleteSubcategory from './deleteSubcategory';

import getQueues from './getQueues';
import getQueueById from './getQueueById';

export default {
  edit,
  updateAssignedUsers,
  updateManagers,
  updateMembers,

  addSubqueue,
  editSubqueue,
  deleteSubqueue,

  addSubcategory,
  editSubcategory,
  deleteSubcategory,

  getQueues,
  getQueueById,
};
