/* eslint-disable import/no-cycle */
import byId from './byId';
import selected from './selected';
import inspectionsFilter from './inspectionsFilter';
import inspectionsList from './inspectionsList';
import attachments from './attachments';
import inspectionTypes from './inspectionTypes';

export default {
  inspectionTypes,
  byId,
  selected,
  inspectionsList,
  attachments,
  inspectionsFilter,
};
