import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
  TitleWrapper,
} from './styledItems';

const InfoItem = (props) => {
  const {
    title,
    children,
    alignItems,
  } = props;

  return (
    <Root alignItems={alignItems}>
      <TitleWrapper>
        <Typography
          variant="title3"
          weight={500}
          noMargin
        >
          {`${title}:`}
        </Typography>
      </TitleWrapper>
      {children}
    </Root>
  );
};

InfoItem.defaultProps = {
  children: null,
  alignItems: 'center',
};

const {
  string,
  ReactNode,
} = PropTypes;

InfoItem.propTypes = {
  title: string.isRequired,
  children: ReactNode,
  alignItems: string,
};

export default InfoItem;
