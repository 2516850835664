import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${(props) => (props.unsuccessful
    ? props.theme?.palette?.['background-flat'] || 'grey'
    : props.theme?.palette?.background || 'white')};
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme?.palette?.border || 'gray'};
  width: 100%;
  padding: 9px 8px;
  margin: 15px 0;
  overflow: hidden;
  position: relative;

  &&:hover {
    .followup-controls {
      visibility: visible;
    }
  }
`;

export default {
  Card,
};
