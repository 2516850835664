import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { createTransaction } from 'store/actions/realtorTransactions';

import { getOfferById } from 'store/actions/realtorOffers';

import AcceptCounterForm from './AcceptCounterForm';

const getContingencies = (contingencyKeys) => (offer) => Object.keys(offer).reduce(
  (acc, item) => {
    if (contingencyKeys[item] && offer[item]) {
      acc.push(contingencyKeys[item]);
    }

    return acc;
  },
  [],
);

const contingencyKeys = {
  contingency_inspection: 1,
  contingency_appraisal: 2,
  contingency_loan: 3,
};

const mapStateToProps = (state, ownProps) => {
  const {
    realtorAcceptCounterForm: {
      offerId,
      rootOfferId,
      onAfterSubmit,
    },
  } = state;

  const offer = selectors.realtorOffers.byId(state, offerId);

  const contingencies = getContingencies(contingencyKeys)(offer);

  const specialConditions = offer.special_conditions
    ? offer.special_conditions.map((id) => {
        const item = selectors.realtorSpecialConditions.byId(state, id);

        if (!item) return {};

        return {
          value: item.name,
        };
      })
    : [];

  const isRootOffer = offerId === rootOfferId;

  let sellerId = null;
  let buyerId = null;

  if (offer?.realtor_client_id) {
    const {
      contact_id,
    } = selectors.realtorClients.byId(state, offer.realtor_client_id);

    sellerId = contact_id;
  }

  if (offer?.realtor_prospect_id) {
    const {
      contact_id,
    } = selectors.realtorProspects.byId(state, offer.realtor_prospect_id);

    buyerId = contact_id;
  }

  return {
    buyerId: offer?.buyer_contact_id || buyerId,
    buyerAgentId: offer?.buyer_agent_contact_id,
    address: offer?.relatedData?.address?.address,
    sellerId: offer?.seller_contact_id || sellerId,
    sellerAgentId: offer?.seller_agent_contact_id,
    offer,
    contingencies,
    specialConditions,
    rootOfferId,
    isRootOffer,
    onAfterSubmit,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (data, attachments) => {
    const dealId = await dispatch(
      createTransaction({
        // eslint-disable-line no-unused-vars
        root_offer_id: data.rootOfferId,
        final_offer_id: data.offer.id,
        realtor_client_id: data.offer.realtor_client_id,
        realtor_property_id: data.offer.realtor_property_id,
        realtor_prospect_id: data.offer.realtor_prospect_id,
        price: data.offer.amount,
        seller_contact_id: data.offer.seller_contact_id,
        seller_agent_contact_id: data.offer.seller_agent_contact_id,
        buyer_contact_id: data.offer.buyer_contact_id,
        buyer_agent_contact_id: data.offer.buyer_agent_contact_id,
        close_escrow_expires_at: data.offer.closing_date,
        move_out_date_expires_at: data.offer.move_out_date,
      }),
    );

    dispatch(getOfferById(data.offer.id));
    dispatch(getOfferById(data.rootOfferId));

    return dealId;
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: async (data) => {
    const dealId = await dispatchProps.onSubmit({
      ...data,
      offer: stateProps.offer,
      rootOfferId: stateProps.rootOfferId,
    });

    if (stateProps.onAfterSubmit) {
      stateProps.onAfterSubmit(dealId);
    }

    if (ownProps.onAfterSubmit) {
      ownProps.onAfterSubmit(dealId);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AcceptCounterForm);
