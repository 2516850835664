import React, {
  useEffect,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import SearchMLSForm from 'sections/Opportunities/forms/SearchMLSForm';

const searchMLSFormId = 'search-mls-form';

const SearchMLSModal = (props) => {
  const {
    isVisible,
    closeModal,
    onSubmit,
    theme,
    contactId,
  } = props;

  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  }, [submitButtonRef]);

  return (
    <Modal
      theme={theme}
      width={870}
      visible={isVisible}
      onCancel={closeModal}
      title="Search or Add New Property"
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          ref={submitButtonRef}
          type="primary"
          htmlType="submit"
          form={searchMLSFormId}
          variant="primary"
        >
          Done
        </Button>,
      ]}
    >
      <SearchMLSForm
        formId={searchMLSFormId}
        onSubmit={onSubmit}
        contactId={contactId}
      />
    </Modal>
  );
};

SearchMLSModal.defaultProps = {
  isVisible: false,
  theme: null,
  contactId: null,
};

const {
  bool,
  func,
  shape,
  number,
} = PropTypes;

SearchMLSModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
  onSubmit: func.isRequired,
  contactId: number,
};

export default SearchMLSModal;
