import { createSelector } from 'reselect';

const trialPeriodEndSelector = (state) => state.dictionaryV2.currencies;

export default createSelector(
  trialPeriodEndSelector,
  (currencies) => (currencies
    ? currencies.map((item) => ({
      id: item.id,
      title: item.id,
      name: item.name,
      exchangeRate: item.exchange_rate,
    }))
    : null),
);
