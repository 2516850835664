import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateRepairRequest,
  closeRepairRequestNotesModal,
} from 'store/actions/realtorRepairRequests';

import RepairRequestNotesModal from './RepairRequestNotesModal';

const mapStateToProps = (state) => {
  const {
    realtorRepairRequests: {
      selectedRepairRequest,
      isRepairRequestNotesModalVisible,
    },
  } = state;

  const request = selectors.realtorRepairRequests.byId(state, selectedRepairRequest);

  return {
    isVisible: isRepairRequestNotesModalVisible,
    content: request?.notes,
    requestId: selectedRepairRequest,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeRepairRequestNotesModal());
  },
  onNoteChange: (content, requestId) => {
    dispatch(updateRepairRequest(requestId)({ notes: content }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onNoteChange: (content) => {
    dispatchProps.onNoteChange(content, stateProps.requestId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RepairRequestNotesModal);
