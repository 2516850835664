import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_MATCHING,
  SET_REALTOR_MATCHED,
  SET_REALTOR_MATCHED_FOR_FILTER_COUNT,
  SET_REALTOR_MATCHED_FOR_FILTER,
  SET_REALTOR_INFINITE_SCROLL_MATCHED_FOR_FILTER,
} from 'store/actionTypes/realtorMatched';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  matched: {},
  matchedByFilter: {},
  matchedByFilterCount: {},
};

const reducers = {
  [SET_REALTOR_MATCHED_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    matchedByFilterCount: {
      ...state.matchedByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_INFINITE_SCROLL_MATCHED_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      matched,
      resetState,
    } = action.payload;

    const current = !resetState && state.matchedByFilter[filterId] ? state.matchedByFilter[filterId] : [];

    const updatedByIds = new Set([
      ...current,
      ...getIds(matched),
    ]);

    const updatedState = {
      ...state,
      matchedByFilter: {
        ...state.matchedByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_MATCHED_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      matched,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(matched),
    ]);

    const updatedState = {
      ...state,
      matchedByFilter: {
        ...state.matchedByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_MATCHING]: (state, action) => ({
    ...state,
    matched: {
      ...state.matched,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_MATCHED]: (state, action) => ({
    ...state,
    matched: {
      ...state.matched,
      ...listToObjectByKey(action.payload),
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
