import list from './list';
import remove from './remove';

import auth from './auth';

// by data type
import emails from './emails';
import contacts from './contacts';
import calendar from './calendar';

import emailAttachments from './emailAttachments';
import emailTemplates from './emailTemplates';
import emailCampaigns from './emailCampaigns';

export default {
  list,
  remove,

  auth,

  // types
  emails,
  contacts,
  calendar,

  emailAttachments,
  emailTemplates,
  emailCampaigns,
};
