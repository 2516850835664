import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import UnansweredQuestionsModal from './UnansweredQuestionsModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      unansweredQuestions,
    },
  } = state;

  return {
    isVisible: unansweredQuestions?.isVisible,
    contentOptions: unansweredQuestions?.contentOptions || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('unansweredQuestions')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnansweredQuestionsModal);
