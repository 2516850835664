/* eslint-disable import/prefer-default-export */

import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getPropertiesObjects from './getPropertiesObjects';

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedPrioritiesActions = {
  getPropertiesObjects: memoizeDebounce(
    () => store.dispatch(getPropertiesObjects()),
    debounceDelay,
    debounceOptions,
  ),
};
