/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'shared/components/IconButton';
import MeetingUserList from 'oa/components/MeetingUserList/index';
import { meetingParticipantType, thumbnailType } from 'oa/types/index';
import videoPlaceholder from '../img/video-placeholder.svg';
import {
  RootWrapper,
  ControlsWrapper,
  VideoWrapper,
  Placeholder,
  PlaceholderBottom,
  MainVideo,
} from './styledItems';

const iconColor = 'rgba(35, 48, 63, 0.87)';
const iconColorInactive = '#ff6446';

const {
  func,
  bool,
  instanceOf,
  arrayOf,
} = PropTypes;

const SmallModalControls = (props) => {
  const {
    onExit,
    onMic,
    onCamera,
    onExpand,
    isMicActive,
    isCameraActive,
    isCameraAvailable,
    isMicrophoneAvailable,
  } = props;

  const micColor = isMicActive
    ? iconColor
    : iconColorInactive;

  const cameraColor = isCameraActive
    ? iconColor
    : iconColorInactive;

  return (
    <ControlsWrapper className="prevent-drag">
      <IconButton
        iconName="sign-out-alt"
        color={iconColor}
        onClick={onExit}
      />

      {
        isMicrophoneAvailable && (
          <IconButton
            iconName="microphone-alt"
            color={micColor}
            onClick={onMic}
          />
        )
      }

      {
        isCameraAvailable && (
          <IconButton
            iconName="video"
            color={cameraColor}
            onClick={onCamera}
          />
        )
      }

      <IconButton
        iconName="expand-alt"
        color={iconColor}
        onClick={onExpand}
      />
    </ControlsWrapper>
  );
};

SmallModalControls.defaultProps = {
  isMicActive: false,
  isCameraActive: false,
};

SmallModalControls.propTypes = {
  onExit: func.isRequired,
  onMic: func.isRequired,
  onCamera: func.isRequired,
  onExpand: func.isRequired,
  isMicActive: bool,
  isCameraActive: bool,
  isCameraAvailable: bool.isRequired,
  isMicrophoneAvailable: bool.isRequired,
};

const SmallModal = (props) => {
  const {
    onExit,
    onMic,
    onCamera,
    onExpand,
    isMicActive,
    isCameraActive,
    isCameraAvailable,
    isMicrophoneAvailable,
    isUserAloneInMeeting,
    mainStream,
    remoteParticipants,
    thumbnails,
    pinnedParticipant,
    pinParticipant,
    unpinParticipant,
  } = props;

  return (
    <RootWrapper>
      <SmallModalControls
        onExit={onExit}
        onMic={onMic}
        onCamera={onCamera}
        onExpand={onExpand}
        isMicActive={isMicActive}
        isCameraActive={isCameraActive}
        isCameraAvailable={isCameraAvailable}
        isMicrophoneAvailable={isMicrophoneAvailable}
      />
      <VideoWrapper
        onDoubleClick={onExpand}
      >
        {
          isUserAloneInMeeting
            ? (
              <Placeholder>
                Symplete
                <PlaceholderBottom>
                  Communications
                </PlaceholderBottom>
              </Placeholder>
            ) : (
              <>
                <MainVideo
                  srcObject={mainStream}
                  poster={videoPlaceholder}
                  autoPlay
                  playsInline
                  muted
                />

                {
                  remoteParticipants.length > 0 && (
                    <MeetingUserList
                      className="visually-hidden"
                      remoteParticipants={remoteParticipants}
                      thumbnails={thumbnails}
                      pinnedParticipant={pinnedParticipant}
                      unpinParticipant={unpinParticipant}
                      pinParticipant={pinParticipant}
                    />
                  )
                }
              </>
            )
        }
      </VideoWrapper>
    </RootWrapper>
  );
};

SmallModal.defaultProps = {
  isMicActive: false,
  isCameraActive: false,
  mainStream: null,
};

SmallModal.propTypes = {
  onExit: func.isRequired,
  onMic: func.isRequired,
  onCamera: func.isRequired,
  onExpand: func.isRequired,
  isMicActive: bool,
  isCameraActive: bool,
  isUserAloneInMeeting: bool.isRequired,
  mainStream: instanceOf(MediaStream),
  isCameraAvailable: bool.isRequired,
  isMicrophoneAvailable: bool.isRequired,
  remoteParticipants: arrayOf(meetingParticipantType).isRequired,
  thumbnails: arrayOf(thumbnailType).isRequired,
  pinnedParticipant: meetingParticipantType,
  pinParticipant: func.isRequired,
  unpinParticipant: func.isRequired,
};

export default SmallModal;
