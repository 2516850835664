import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

const deleteMatching = (matchingId) => async (dispatch) => {
  try {
    await requests.realtor.matched.remove(matchingId);
  } catch (error) {
    callAlert.error('Could not delete');
  }
};

export default deleteMatching;
