import {
  get,
  without,
  cloneDeep,
} from 'shared/utility';

import connectReducers from 'store/connectReducers';

import {
  SET_TASKS_FOR_EMPLOYEE,
  SET_TASKS_FOR_TEAM,

  SET_TASKS_COUNT_FOR_EMPLOYEE,
  SET_TASKS_COUNT_FOR_TEAM,

  SET_TASKS_SUMMARY_FOR_EMPLOYEE,
  SET_TASKS_SUMMARY_FOR_TEAM,
  SET_OVERALL_TASKS_SUMMARY,

  SET_TASK,
  SET_TASKS,

  SET_TASKS_VIEW,
  SET_SELECTED_TASK,
  ADD_TUTORIAL_TASK,
  REMOVE_TUTORIAL_TASK,
  SET_TASK_WORKLOGS,
  SET_TASK_LAST_UPDATE_INFO,

  DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS,
  DELETE_TASK_FROM_BACKLOG_FILTERED,

  SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD,

  SET_TASKS_FOR_FILTER,
  SET_TASKS_COUNT_FOR_FILTER,
  SET_TASKS_SUMMARY_FOR_FILTER,
  SET_TASK_PLANNER_BACKLOG_FILTER,
  SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER,
  CLEAR_FILTER_SUMMARIES,
  SET_TASK_PLANNER_SEARCH,
  // SET_TASK_PLANNER_BACKLOG_SEARCH,
} from 'store/actionTypes/tasks';

import { tutorialTask } from 'constants';

const initialState = {
  tasks: {},
  selectedTask: null,

  tasksByEmployees: {},
  tasksByTeams: {},
  tasksByFilter: {},

  tasksCountByEmployees: {},
  tasksCountByTeams: {},
  tasksCountByFilter: {},

  tasksSummaryByEmployees: {},
  tasksSummaryByTeams: {},
  tasksSummaryByFilter: {},
  tasksSummaryByEmployeesForTaskPlanner: {},

  tasksSummaryOverall: {},

  view: null,
  viewItemId: null,

  selectedTaskWorklogs: [],
  lastUpdateInfo: {},

  taskPlannerBacklogFilter: {
    search: null,
    dateCreatedFrom: null,
    dateCreatedTo: null,
    filterLabels: [],
    filterPriority: [],
    estimatedCompletionFrom: null,
    estimatedCompletionTo: null,
  },
  activeTaskPlannerBacklogFilter: null,

  taskPlannerSearch: null,
};

const taskListToObject = (tasks = []) => tasks.reduce((tasksObj, item) => {
  // param reassign is used in reduce by design
  tasksObj[item.TaskID] = item; // eslint-disable-line no-param-reassign
  return tasksObj;
}, {});

const getTasksIds = (tasks = []) => tasks.map((item) => item.TaskID);

const reducers = {
  [SET_TASKS_VIEW]: (state, action) => {
    const { view, viewItemId } = action.payload;
    return {
      ...state,
      view,
      viewItemId,
    };
  },

  [SET_SELECTED_TASK]: (state, action) => ({
    ...state,
    selectedTask: action.payload,
  }),

  [SET_TASK]: (state, action) => {
    const task = action.payload;

    return {
      ...state,
      tasks: {
        ...state.tasks,
        [task.TaskID]: task,
      },
    };
  },

  [SET_TASKS]: (state, action) => {
    const tasks = action.payload;
    return {
      ...state,
      tasks: {
        ...state.tasks,
        ...taskListToObject(tasks),
      },
    };
  },

  [SET_TASKS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      tasks = [],
    } = action.payload;

    const filterTasksIds = state.tasksByFilter[filterId] || [];

    const updatedFilterTasksIds = new Set([
      ...filterTasksIds,
      ...getTasksIds(tasks),
    ]);

    const updatedState = {
      ...state,
      tasksByFilter: {
        ...state.tasksByFilter,
        [filterId]: [...updatedFilterTasksIds],
      },
    };

    return updatedState;
  },

  [DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS]: (state, action) => {
    const {
      employeeId,
      taskId,
    } = action;

    const employeesTasksIds = get(state, `tasksByEmployees.${employeeId}`, []);

    const newEmployeesTasksIds = without(employeesTasksIds, taskId);

    return {
      ...state,
      tasksByEmployees: {
        ...state.tasksByEmployees,
        [employeeId]: [...newEmployeesTasksIds],
      },
    };
  },

  [DELETE_TASK_FROM_BACKLOG_FILTERED]: (state, action) => {
    const {
      taskId,
      activeTaskPlannerBacklogFilter,
    } = action;

    const filteredBacklogIds = get(state, `tasksByFilter.${activeTaskPlannerBacklogFilter}`, []);

    const newFilteredBacklogTasksIds = without(filteredBacklogIds, taskId);

    return {
      ...state,
      tasksByFilter: {
        ...state.tasksByFilter,
        [activeTaskPlannerBacklogFilter]: [...newFilteredBacklogTasksIds],
      },
    };
  },

  [SET_TASKS_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      tasks = [],
    } = action.payload;

    const employeesTasksIds = get(state, `tasksByEmployees.${employeeId}`, []);

    const updatedEmployeeTasksIds = new Set([
      ...employeesTasksIds,
      ...getTasksIds(tasks),
    ]);

    return {
      ...state,
      tasksByEmployees: {
        ...state.tasksByEmployees,
        [employeeId]: [...updatedEmployeeTasksIds],
      },
    };
  },

  [SET_TASKS_FOR_TEAM]: (state, action) => {
    const {
      teamId,
      tasks = [],
    } = action.payload;

    const teamsTasksIds = get(state, `tasksByTeams.${teamId}`, []);

    const updatedTeamTasksIds = new Set([
      ...teamsTasksIds,
      ...getTasksIds(tasks),
    ]);

    return {
      ...state,
      tasksByTeams: {
        ...state.tasksByTeams,
        [teamId]: [...updatedTeamTasksIds],
      },
    };
  },

  [SET_TASKS_COUNT_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      tasksCount,
    } = action.payload;

    return {
      ...state,
      tasksCountByEmployees: {
        ...state.tasksCountByEmployees,
        [employeeId]: tasksCount,
      },
    };
  },

  [SET_TASKS_COUNT_FOR_TEAM]: (state, action) => {
    const {
      teamId,
      tasksCount,
    } = action.payload;

    return {
      ...state,
      tasksCountByTeams: {
        ...state.tasksCountByTeams,
        [teamId]: tasksCount,
      },
    };
  },

  [SET_TASKS_COUNT_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      tasksCount,
    } = action.payload;

    return {
      ...state,
      tasksCountByFilter: {
        ...state.tasksCountByFilter,
        [filterId]: tasksCount,
      },
    };
  },

  [SET_TASKS_SUMMARY_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      tasksSummary,
    } = action.payload;

    return {
      ...state,
      tasksSummaryByEmployees: {
        ...state.tasksSummaryByEmployees,
        [employeeId]: tasksSummary,
      },
    };
  },

  [SET_TASKS_SUMMARY_FOR_TEAM]: (state, action) => {
    const {
      teamId,
      tasksSummary,
    } = action.payload;

    return {
      ...state,
      tasksSummaryByTeams: {
        ...state.tasksSummaryByTeams,
        [teamId]: tasksSummary,
      },
    };
  },

  [SET_TASKS_SUMMARY_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      tasksSummary,
    } = action.payload;

    return {
      ...state,
      tasksSummaryByFilter: {
        ...state.tasksSummaryByFilter,
        [filterId]: tasksSummary,
      },
    };
  },

  [SET_OVERALL_TASKS_SUMMARY]: (state, action) => {
    const tasksSummary = action.payload;

    return {
      ...state,
      tasksSummaryOverall: tasksSummary,
    };
  },

  [ADD_TUTORIAL_TASK]: (state) => ({
    ...state,
    tasks: {
      ...state.tasks,
      tutorialTask,
    },
  }),

  [REMOVE_TUTORIAL_TASK]: (state) => {
    const tasks = cloneDeep(state.tasks);
    delete tasks.tutorialTask;

    return {
      ...state,
      tasks,
    };
  },

  [SET_TASK_WORKLOGS]: (state, action) => {
    const worklogs = action.payload;

    return {
      ...state,
      selectedTaskWorklogs: worklogs,
    };
  },

  [SET_TASK_LAST_UPDATE_INFO]: (state, action) => {
    const {
      updatedAt,
      EmployeeID,
    } = action.payload;

    return {
      ...state,
      lastUpdateInfo: {
        lastUpdateDate: updatedAt,
        lastUpdateAgentID: EmployeeID,
      },
    };
  },

  [SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD]: (state, action) => {
    const {
      employeeId,
      period,
      tasksSummary,
    } = action.payload;

    return {
      ...state,
      tasksSummaryByEmployeesForTaskPlanner: {
        ...state.tasksSummaryByEmployeesForTaskPlanner,
        [period]: {
          ...state.tasksSummaryByEmployeesForTaskPlanner[period],
          [employeeId]: tasksSummary,
        },
      },
    };
  },

  [SET_TASK_PLANNER_BACKLOG_FILTER]: (state, action) => {
    const filterUpdate = action.payload;

    return {
      ...state,
      taskPlannerBacklogFilter: {
        ...state.taskPlannerBacklogFilter,
        ...filterUpdate,
      },
    };
  },

  [SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER]: (state, action) => {
    const filterId = action.payload;

    return {
      ...state,
      activeTaskPlannerBacklogFilter: filterId,
    };
  },

  [CLEAR_FILTER_SUMMARIES]: (state) => ({
    ...state,
    tasksSummaryByFilter: {},
    tasksCountByFilter: {},
  }),

  [SET_TASK_PLANNER_SEARCH]: (state, action) => ({
    ...state,
    taskPlannerSearch: action.payload,
  }),
};

const tasks = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default tasks;
