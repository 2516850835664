import { createSelector } from 'reselect';

import {
  optimizedWarehouseActions,
} from 'store/actions/warehouse';

import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';

export default createSelector(
  (state, id) => ({
    packages: state.warehouse.packages,
    id,
    lineItems: state.lineItems.lineItems,
  }),
  ({ packages, id, lineItems }) => {
    if (!id) return null;
    const dataObject = packages[id];
    if (!dataObject) {
      optimizedWarehouseActions.getWarehousePackageById(id);
    }

    const items = dataObject.lineItems || [];

    return dataObject
      ? {
        ...dataObject,
        hasCancelledItems: items.reduce((acc, itemId) => {
          if (acc) return acc;
          const lineItem = lineItems[itemId];
          if (!lineItem) {
            optimizedLineItemsActions.getLineItemById(itemId);
            return acc;
          }
          if (lineItem.status === 3) {
            acc = true; // eslint-disable-line no-param-reassign
          }
          return acc;
        }, false),
      }
      : {};
  },
);
