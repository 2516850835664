import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import DateInputButton from 'shared/inputs/DateInputButton';

const MonthSelector = (props) => {
  const {
    selectedDate,
    onChange,
  } = props;

  const onNextMonth = useCallback(() => {
    const newDate = moment(selectedDate).add(1, 'months').format();
    onChange(newDate, moment(newDate).toDate());
  }, [
    selectedDate,
    onChange,
  ]);

  const onPrevMonth = useCallback(() => {
    const newDate = moment(selectedDate).subtract(1, 'months').format();
    onChange(newDate, moment(newDate).toDate());
  }, [
    selectedDate,
    onChange,
  ]);

  return (
    <Flex
      spacing={1}
    >
      <Button
        onClick={onPrevMonth}
        icon="arrow-left"
        variant="secondary"
      />

      <DateInputButton
        onChange={onChange}
        value={selectedDate}
        displayFormat="MMMM"
        view="month"
      />

      <Button
        onClick={onNextMonth}
        icon="arrow-right"
        variant="secondary"
      />
    </Flex>
  );
};

const {
  func,
  string,
} = PropTypes;

MonthSelector.propTypes = {
  selectedDate: string.isRequired,
  onChange: func.isRequired,
};

export default MonthSelector;
