import {
  useState,
  useCallback,
  useEffect,
} from 'react';

const useRangeValues = (value, onChange) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue([value[0], value[1]]);
  }, [
    value[0],
    value[1],
  ]);

  const handleChange = useCallback((type) => (newValue) => {
    const update = type === 'min'
      ? [(newValue <= currentValue[1] || !currentValue[1]) ? newValue : currentValue[1], currentValue[1]]
      : [currentValue[0], (newValue >= currentValue[0] || !newValue) ? newValue : currentValue[0]];

    setCurrentValue(update);
    onChange(update);
  }, [
    currentValue,
    setCurrentValue,
    onChange,
  ]);

  return {
    min: currentValue[0],
    max: currentValue[1],
    handleChange,
  };
};

export default useRangeValues;
