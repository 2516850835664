import { SET_IS_CALL_DECLINED } from 'store/actionTypes/call';

const setIsCallDeclined = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_CALL_DECLINED,
    payload,
  });
};

export default setIsCallDeclined;
