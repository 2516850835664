import styled from 'styled-components';
import { Avatar } from 'antd';

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Image = styled(Avatar)`
  width: 60px;
  height: 45px;
  line-height: 45px;
  font-size: 25px;
`;
