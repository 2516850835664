import {
  SET_REALTOR_TRANSACTIONS_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorTransactions';

const setOverallSummary = (summary) => ({
  type: SET_REALTOR_TRANSACTIONS_OVERALL_SUMMARY,
  payload: summary,
});

export default setOverallSummary;
