import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';
import withOptionsMenu from 'ui/withOptionsMenu';

const Component = withOptionsMenu(Textfield);

const DropdownTextfield = (props) => (
  <Component
    icon="chevron-down"
    {...props}
  />
);

DropdownTextfield.defaultProps = {
  options: [],
};

const {
  arrayOf,
  number,
  oneOf,
  shape,
  string,
} = PropTypes;

DropdownTextfield.propTypes = {
  options: arrayOf(shape({
    value: oneOf([string, number]),
    label: oneOf([string, number]),
  })),
};

export default DropdownTextfield;
