import React from 'react';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player/file';

const twilioAccountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;

const getRecordingUrl = (recordingSid) => (
  `https://api.twilio.com/2010-04-01/Accounts/${twilioAccountSid}/Recordings/${recordingSid}.mp3`
);

const CallRecordingPlayer = (props) => {
  const {
    recordingSid,
    width,
  } = props;

  return (
    <ReactPlayer
      url={getRecordingUrl(recordingSid)}
      controls
      width={width}
      height="55px"
      style={{ width: '100%', height: '100%' }}
      config={{
        file: {
          forceAudio: true,
        },
        attributes: {
          preload: 'metadata',
        },
      }}
    />
  );
};

CallRecordingPlayer.defaultProps = {
  width: '100%',
};

const { string } = PropTypes;

CallRecordingPlayer.propTypes = {
  recordingSid: string.isRequired,
  width: string,
};

export default CallRecordingPlayer;
