import {
  SET_CRM_SOURCE_TYPES,
} from 'store/actionTypes/crm';

const setSourceTypes = (sourceTypes) => ({
  type: SET_CRM_SOURCE_TYPES,
  payload: sourceTypes,
});

export default setSourceTypes;
