import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Field from 'shared/inputs/PropertyTypeSelect';

const PropertyTypeSelect = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'propertyType',
    control,
  });

  return (
    <Field
      value={value}
      handleChange={onChange}
    />
  );
};

const {
  shape,
} = PropTypes;

PropertyTypeSelect.propTypes = {
  control: shape({}).isRequired,
};

export default PropertyTypeSelect;
