import {
  optimizedRealtorDocumentRequestActions,
} from 'store/actions/realtorDocumentRequests';

import withAttachmentsList from 'shared/hocs/withAttachmentsList';

const withDocumentRequestAttachments = (Component) => withAttachmentsList(37)({
    callbacks: {
      onAfterUpload: optimizedRealtorDocumentRequestActions.getAttachments,
      onAfterRemove: optimizedRealtorDocumentRequestActions.getAttachments,
    },
    selectorPath: 'realtorDocumentRequests.attachments',
  })(Component);

export default withDocumentRequestAttachments;
