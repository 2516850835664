/* eslint-disable react/jsx-no-bind */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import CounterOfferModal from 'sections/OffersV2/modals/CounterOfferModal';
import DeclineModal from 'sections/OffersV2/modals/DeclineModal';

const ViewingOptions = (props) => {
  const {
    closeModal,
    openAcceptCounterModal,

    propertyId,
    offerId,
    offerType,
    totalCounters,
    offerData,
  } = props;

  const [counterVisible, setCounterVisible] = useState(false);
  const [declineVisible, setDeclineVisible] = useState(false);

  const closeModals = () => {
    setCounterVisible(false);
    setDeclineVisible(false);
  };

  const decline = async (event) => {
    // event.stopPropagation();
    setDeclineVisible(true);
    closeModal();
  };

  const counter = async (event) => {
    // event.stopPropagation();
    setCounterVisible(true);
    closeModal();
  };

  const accept = async (event) => {
    // event.stopPropagation();
    openAcceptCounterModal();
    closeModal();
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={decline}
      >
        Decline
      </Button>
      <Button
        variant="secondary"
        onClick={counter}
      >
        Counter
      </Button>
      <Button
        variant="secondary"
        onClick={accept}
      >
        Accept
      </Button>

      <CounterOfferModal
        isVisible={counterVisible}
        closeModal={closeModals}
        propertyId={propertyId}
        offerId={offerId}
        offerType={offerType}
        totalCounters={totalCounters}
        offerData={offerData}
      />

      <DeclineModal
        isVisible={declineVisible}
        closeModal={closeModals}
        offerId={offerId}
      />

    </>
  );
};

const {
  func,
  shape,
  number,
} = PropTypes;

ViewingOptions.propTypes = {
  openAcceptCounterModal: func.isRequired,
  propertyId: number.isRequired,
  closeModal: func.isRequired,
  offerData: shape({}).isRequired,
  totalCounters: number.isRequired,
  offerId: number.isRequired,
  offerType: number.isRequired,
};

export default ViewingOptions;
