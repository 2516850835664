import {
  SET_CRM_OVERALL_LEADS_SUMMARY,
} from 'store/actionTypes/crm';

const setOverallLeadsSummary = (summary) => ({
  type: SET_CRM_OVERALL_LEADS_SUMMARY,
  payload: summary,
});

export default setOverallLeadsSummary;
