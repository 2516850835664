import { connect } from 'react-redux';
import { history } from 'store';
import { showEmployeeGeneralModal } from 'store/actions/dashboard';
import { setEmployeeDetails } from 'store/actions/employeeDetails';
import {
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  setStepIndex,
} from 'store/actions/tutorial';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import initialSteps from './steps';
import filterSteps from '../shared/utils/filterSteps';

import HrTutorial from './HrTutorial';

const mapStateToProps = (state) => {
  const hasManagerPermissions = checkLoggedEmployeePermissions.isManager('HR');
  const steps = filterSteps(initialSteps, hasManagerPermissions);
  const {
    tutorial: {
      isLaunchModalVisible,
      stepIndex,
    },
    dashboard: {
      employeeGeneralModal: {
        isVisible,
      },
    },
    router: {
      location,
    },
  } = state;

  return {
    isLaunchModalVisible,
    stepIndex,
    history,
    location,
    isVisible,
    steps,
  };
};

const mapDispatchToProps = {
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  setStepIndex,
  setEmployeeDetails,
  showEmployeeGeneralModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HrTutorial);
