import { SET_CUSTOMERS_LABELS, CLEAR_CUSTOMERS_LABELS } from 'store/actions/customersLabels';


const initialState = {
  labels: [],
};

function customersLabels(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMERS_LABELS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_CUSTOMERS_LABELS:
      return initialState;
    default:
      return state;
  }
}

export default customersLabels;
