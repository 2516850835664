import connectReducers from 'store/connectReducers';

import {
  SET_CRM_COMPANY,
  SET_CRM_COMPANIES,
  SET_CRM_COMPANIES_FOR_FILTER,
  SET_CRM_COMPANIES_FOR_FILTER_COUNT,
  SET_CRM_SELECTED_COMPANY_ROWS,
  SET_CRM_OVERALL_COMPANIES_SUMMARY,
  SET_CRM_SELECTED_COMPANY,
  SET_CRM_COMPANY_CHANGELOG,
  SET_CRM_COMPANIES_LABELS,
} from 'store/actionTypes/crm';

const initialState = {
  companies: {},
  selectedCompany: null,

  companiesByFilter: {},
  companiesByFilterCount: {},

  overallCompaniesSummary: null,

  labels: null,

  changelog: {},
  selectedCompanyRows: [],
};

const companiesListToObject = (companies = []) => companies.reduce((companyObj, item) => {
  // param reassign is used in reduce by design
  companyObj[item.CompanyID] = item; // eslint-disable-line no-param-reassign
  return companyObj;
}, {});

const labelsListToObject = (labels = []) => labels.reduce((labelsObj, item) => {
  // param reassign is used in reduce by design
  labelsObj[item.id] = { // eslint-disable-line no-param-reassign
    ...item,
    color: `#${item.color}`,
  };
  return labelsObj;
}, {});

const getCompaniesIds = (companies = []) => companies.map((item) => item.CompanyID);

const reducers = {
  [SET_CRM_COMPANY]: (state, action) => ({
    ...state,
    companies: {
      ...state.companies,
      [action.payload.CompanyID]: action.payload,
    },
  }),

  [SET_CRM_COMPANIES]: (state, action) => ({
    ...state,
    companies: {
      ...state.companies,
      ...companiesListToObject(action.payload),
    },
  }),

  [SET_CRM_COMPANIES_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      companies,
    } = action.payload;

    const filterProductIds = state.companiesByFilter[filterId] || [];

    const updatedFilterCompaniesIds = new Set([
      ...filterProductIds,
      ...getCompaniesIds(companies),
    ]);

    const updatedState = {
      ...state,
      companiesByFilter: {
        ...state.companiesByFilter,
        [filterId]: [...updatedFilterCompaniesIds],
      },
    };

    return updatedState;
  },

  [SET_CRM_COMPANIES_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    companiesByFilterCount: {
      ...state.companiesByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_CRM_SELECTED_COMPANY_ROWS]: (state, action) => ({
    ...state,
    selectedCompanyRows: action.payload,
  }),

  [SET_CRM_OVERALL_COMPANIES_SUMMARY]: (state, action) => ({
    ...state,
    overallCompaniesSummary: action.payload,
  }),

  [SET_CRM_SELECTED_COMPANY]: (state, action) => ({
    ...state,
    selectedCompany: action.payload,
  }),

  [SET_CRM_COMPANY_CHANGELOG]: (state, action) => ({
    ...state,
    changelog: {
      ...state.changelog,
      [action.payload.companyId]: action.payload.changelog,
    },
  }),

  [SET_CRM_COMPANIES_LABELS]: (state, action) => ({
    ...state,
    labels: {
      ...state.labels,
      ...labelsListToObject(action.payload),
    },
  }),
};

function crmCompanies(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default crmCompanies;
