import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import {
  Col,
  Row,
} from 'antd';

import Flex from 'ui/Flex';

import CommentActionPanel from 'shared/components/CommentActionPanel';

import UserAvatar from 'shared/components/UserAvatarV2';
import CommentBody from 'shared/components/CommentBodyV2';
import CommentReplyV2 from 'shared/components/CommentReplyV2';

import './styles.scss';

const icons = {
  email: 'mail',
  message: 'message',
  call: 'phone',
  'in person': 'user',
};


const LogItem = (props) => {
  const {
    acknowledgedLabel,
    className,
    commentActionPanelProps,
    commentBodyProps,
    commentReplyProps,
    contactType,
    createdAt,
    creator,
    isAcknowledged,
    isOwner,
    onClick,
    onClickDelete,
    onClickEdit,
    onClickLike,
    onClickReplyCount,
    onClickThread,
    replyCount,
    replyCreators,
    status,
    text,
    title,
  } = props;

  const hasReplies = replyCount > 0;

  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, max-len
      className={classnames(
        'comment-root',
        {
          [className]: className,
        },
      )}
      onClick={onClick}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          spacing={2}
          alignItems="center"
        >
        <UserAvatar
          avatarProps={{
            icon: icons[contactType],
          }}
          noBadge
          size="xs"
        />
        <CommentBody
            commentText={text}
            confirmedText={acknowledgedLabel}
            createdAt={createdAt}
            status={status}
            name={creator.name}
            surname={creator.surname}
            title={title}
            {...commentBodyProps} // eslint-disable-line react/jsx-props-no-spreading
          />
          </Flex>
        
        <Flex alignItems="center">
          {
            hasReplies && (
              <CommentReplyV2
                className="comment-comment-reply"
                commentators={replyCreators}
                onClickReplyCount={onClickReplyCount}
                replyCount={replyCount}
                {...commentReplyProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            )
          }
          <CommentActionPanel
            className="comment-action-panel-visible"
            isOwner={isOwner}
            isConfirmed={isAcknowledged}
            onClickLike={onClickLike}
            onClickThread={onClickThread}
            onClickDelete={onClickDelete}
            onClickEdit={onClickEdit}
            {...commentActionPanelProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        </Flex>
      </Flex>
    </div >
  );
};


const noOp = () => { };

LogItem.defaultProps = {
  acknowledgedLabel: null,
  className: '',
  commentActionPanelProps: {},
  commentBodyProps: {},
  commentReplyProps: {},
  confirmedText: '',
  contactType: null,
  createdAt: '',
  isAcknowledged: false,
  isOwner: false,
  onClick: noOp,
  onClickDelete: null,
  onClickEdit: null,
  onClickLike: null,
  onClickReplyCount: noOp,
  onClickThread: null,
  replyCount: 0,
  replyCreators: [],
  status: '',
  text: '',
  title: '',
};

const {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} = PropTypes;

const creatorShape = shape({
  id: number,
  type: number,
  avatar: string,
  name: string,
  surname: string,
});

LogItem.propTypes = {
  isAcknowledged: bool,
  acknowledgedLabel: string,
  className: string,
  commentActionPanelProps: object, // eslint-disable-line react/forbid-prop-types
  commentBodyProps: object, // eslint-disable-line react/forbid-prop-types
  commentReplyProps: object, // eslint-disable-line react/forbid-prop-types
  confirmedText: string,
  contactType: string,
  createdAt: string,
  creator: oneOfType([creatorShape]).isRequired,
  isOwner: bool,
  onClick: func,
  onClickDelete: func,
  onClickEdit: func,
  onClickLike: func,
  onClickReplyCount: func,
  onClickThread: func,
  replyCount: number,
  replyCreators: arrayOf(creatorShape),
  status: string,
  text: string,
  title: string,
};

export default LogItem;
