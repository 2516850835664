import {
  SET_EXTERNAL_TOKEN_ISSUER_COMPANY_DATA,
} from 'store/actionTypes/externalAccess';

const setTokenIssuerCompanyData = (data) => ({
  type: SET_EXTERNAL_TOKEN_ISSUER_COMPANY_DATA,
  payload: data,
});

export default setTokenIssuerCompanyData;
