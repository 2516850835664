import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {number} contactId
 * @param {Object} data
 * @param {number} data.objectType
 * @param {(number|number[])} data.objectsIds
 */
const linkObjectsToContact = (contactId) => async (data) => {
  const endpoint = `${endpoints.client.crm.linkObjectsToContact}/${contactId}`;

  try {
    const result = await apiConnector.client.post(endpoint, data);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default linkObjectsToContact;
