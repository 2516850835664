import connectReducers from 'store/connectReducers';
// TODO - fix dependency cycles
/* eslint-disable no-restricted-syntax */
import {
  GET_COMMENT_TYPES_SUCCESS,
} from 'store/actionTypes/comments';
// eslint-disable-next-line import/no-cycle
import * as EXTERNAL from 'store/actions/external-store-tasks';

import { // eslint-disable-line import/no-cycle
  CLEAR_SELECTED_TASK,
  CLEAR_TASK_COMMENTS,
  CREATE_TASK_SUCCESS,
  GET_ALL_TASKS_PRIORITIES_SUCCESS,
  GET_TASK_CALLS_LOG_SUCCESS,
  GET_TASK_COMMENTS_SUCCESS,
  GET_TASK_DATA_BY_TASK_ID,
  GET_TASK_STATUS_LIST_SUCCESS,
  GET_TASKS_SUCCESS,
  HIDE_TASK_REMINDER_MODAL,
  SET_TASK_DATA,
  SET_TASK_LAST_UPDATE_DATE,
  SHOW_CREATE_TASK_MODAL,
  SHOW_TASK_DETAILS_MODAL,
  SHOW_TASK_REMINDER_MODAL,
  START_TASK_REMINDER_INTERVAL,
  STOP_TASK_REMINDER_INTERVAL,
  UPDATE_TASK_FIELD_SUCCESS,
  UPDATE_TASK_PROGRESS_SUCCESS,
  GET_TASKS_DASHBOARD_INF,
  SET_TASK_LABEL,
  SET_TASKS_LABELS,
} from '../actions/tasks';


const defaultSelectedTask = {
  task: {},
  customer: {},
  customerTasks: {
    currentTasks: [],
    previousTasks: [],
  },
  comments: [],
  activity: {
    lastUpdateDate: 'N/A',
    lastUpdateAgent: 'N/A',
  },
  log: [],
};

const initialState = {
  tasksList: [],
  taskStatusList: [],
  tasksView: 'board',

  taskReminder: {
    intervalId: null,
    taskId: null,
    taskToRemind: null,
    startedAt: null,
  },
  resolveModal: {
    isOpen: false,
    isLoading: false,
  },
  task: {
    status: null,
    data: null,
  },
  selectedTask: defaultSelectedTask,
  prioritiesList: [],
  commentTypesList: [],
  isModalVisible: false,
  isCreateTaskModalVisible: false,
  createTaskModal: {
    isVisible: false,
    data: null,
  },
  tasksProblemsComments: [],
  allManagerTasks: [],
  allTasksManagerTeamCount: 0,
  tasksWidgetOtherInformation: [],
  countNewTasksAssignedToMe: 0,
};

export const updateTask = (tasks, newTask) => tasks.map((task) => (task.TaskID === newTask.TaskID
  ? newTask
  : task
));

export const updateTasks = (state, task) => {
  const { tasksList, selectedTask } = state;
  return {
    ...state,
    tasksList: updateTask(tasksList, task),
    selectedTask: {
      ...selectedTask,
      task,
    },
  };
};

const reducers = {
  [GET_TASKS_DASHBOARD_INF]: (state, action) => {
    const {
      tasksProblemsComments,
      allManagerTasks,
      allTasksManagerTeamCount,
      tasksWidgetOtherInformation,
      countNewTasksAssignedToMe,
      unassignedManagerTasksCount,
    } = action;

    return {
      ...state,
      allTasksManagerTeamCount,
      tasksProblemsComments,
      allManagerTasks,
      tasksWidgetOtherInformation,
      countNewTasksAssignedToMe,
      unassignedManagerTasksCount,
    };
  },

  [CREATE_TASK_SUCCESS]: (state, action) => {
    const {
      data: [task],
    } = action;
    return {
      ...state,
      tasksList: [...state.tasksList, task],
      createTaskModal: {
        isVisible: false,
        data: null,
      },
    };
  },

  [UPDATE_TASK_FIELD_SUCCESS]: (state, action) => {
    const { task } = action;
    return updateTasks(state, task);
  },

  [UPDATE_TASK_PROGRESS_SUCCESS]: (state, action) => {
    const { task } = action;
    return updateTasks(state, task);
  },

  [GET_TASKS_SUCCESS]: (state, action) => {
    const tasksList = action.tasks.map((item) => ({
      ...item,
      labels: item.labels.map((label) => ({
        id: label.id,
        name: label.name,
        color: `${label.color}`,
        description: label.description,
        relationId: label.labels2objects.id,
      })),
    }));

    return {
      ...state,
      tasksList,
    };
  },

  [GET_ALL_TASKS_PRIORITIES_SUCCESS]: (state, action) => {
    const { priorities } = action;
    return {
      ...state,
      prioritiesList: priorities,
    };
  },

  [GET_TASK_STATUS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    taskStatusList: action.taskStatusList,
  }),

  [SHOW_TASK_REMINDER_MODAL]: (state, action) => ({
    ...state,
    taskReminder: {
      ...state.taskReminder,
      taskToRemind: action.payload.task,
      startedAt: action.payload.startedAt,
    },
  }),

  [HIDE_TASK_REMINDER_MODAL]: (state, action) => ({
    ...state,
    taskReminder: {
      ...state.taskReminder,
      taskToRemind: null,
      startedAt: null,
    },
  }),

  [START_TASK_REMINDER_INTERVAL]: (state, action) => ({
    ...state,
    taskReminder: {
      ...state.taskReminder,
      intervalId: action.payload.intervalId,
      taskId: action.payload.taskId,
      startedAt: action.payload.startedAt,
    },
  }),

  [STOP_TASK_REMINDER_INTERVAL]: (state, action) => ({
    ...state,
    taskReminder: {
      ...state.taskReminder,
      intervalId: null,
      taskId: null,
      startedAt: null,
    },
  }),

  [SET_TASK_DATA]: (state, action) => ({
    ...state,
    task: { status: action.status, data: action.task },
  }),

  [CLEAR_SELECTED_TASK]: (state, action) => ({
    ...state,
    selectedTask: {
      ...state.selectedTask,
      task: {},
    },
  }),

  [GET_TASK_DATA_BY_TASK_ID]: (state, action) => ({
    ...state,
    selectedTask: {
      ...state.selectedTask,
      task: state.tasksList.filter((task) => task.TaskID === action.id)[0],
    },
  }),

  [GET_COMMENT_TYPES_SUCCESS]: (state, action) => ({
    ...state,
    commentTypesList: action.commentTypes,
  }),

  [GET_TASK_COMMENTS_SUCCESS]: (state, action) => ({
    ...state,
    selectedTask: {
      ...state.selectedTask,
      comments: action.comments,
    },
  }),

  [EXTERNAL.GET_CUSTOMER_TASK_COMMENTS_SUCCESS]: (state, action) => ({
    ...state,
    selectedTask: {
      ...state.selectedTask,
      comments: action.comments,
    },
  }),

  [GET_TASK_CALLS_LOG_SUCCESS]: (state, action) => {
    const {
      log,
    } = action;

    return {
      ...state,
      selectedTask: {
        ...state.selectedTask,
        log,
      },
    };
  },

  [CLEAR_TASK_COMMENTS]: (state, action) => ({
    ...state,
    selectedTask: {
      ...state.selectedTask,
      comments: [],
    },
  }),

  [SHOW_TASK_DETAILS_MODAL]: (state, action) => ({
    ...state,
    isModalVisible: action.visibility,
  }),

  [SHOW_CREATE_TASK_MODAL]: (state, action) => ({
    ...state,
    createTaskModal: {
      ...state.createTaskModal,
      isVisible: action.visibility,
      data: action.data,
    },
  }),

  [SET_TASK_LAST_UPDATE_DATE]: (state, action) => {
    const {
      lastUpdateDate,
      lastUpdateAgent,
    } = action.payload;

    return {
      ...state,
      selectedTask: {
        ...state.selectedTask,
        activity: {
          lastUpdateDate,
          lastUpdateAgent,
        },
      },
    };
  },

  [SET_TASKS_LABELS]: (state, action) => ({
    ...state,
    allTasksLabels: action.payload,
  }),

  [SET_TASK_LABEL]: (state, action) => {
    const newTaskLabels = action.payload;
    return ({
      ...state,
      taskLabels: newTaskLabels,
    });
  },
};

const tasks = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default tasks;
