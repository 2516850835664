import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} contactData
 */
const create = async (contactData) => {
  const endpoint = endpoints.client.realtor.contacts;

  try {
    const result = await apiConnector.client.post(endpoint, contactData);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
