import styled from 'styled-components';

export const ItemWrapper = styled.div`
  flex: 1;
  width: 300px;
  padding: 15px;
  border-radius: 10px;
  background-color: ${(props) => props.theme?.palette?.['background-flat']};
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 90px;
  height: 90px;
  margin-right: 15px;
`;
