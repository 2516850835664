import requests from 'api/requests';

import setInvoices from './setInvoices';

const getInvoices = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.customerInvoices.get({
      offset,
      limit,
    });

    dispatch(setInvoices(response.data.invoices));
  } catch (error) {
    console.error(error);
  }
};

export default getInvoices;
