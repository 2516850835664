import styled from 'styled-components';

export const Root = styled.div`
  .object-title {
    margin: auto 0 auto 0;
  }
  padding-left: 24px;
`;

export const InlineContainer = styled.div`
  display: flex;
  gap: ${({ gap }) => gap}px;
`;

export const MarginBox = styled.div`
  margin-top: 8px;
`;

export default {
  Root,
  InlineContainer,
  MarginBox,
};
