import BaseApiConnector from 'api/connectors/BaseApiConnector';

const clientApiUrl = process.env.REACT_APP_SYMPLETE_CLIENT_API;

class ClientApiConnector extends BaseApiConnector {
  constructor(contentType = 'application/json') {
    super(clientApiUrl, contentType);
  }
}

export default ClientApiConnector;
