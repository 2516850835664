import React, { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

import noOp from 'shared/utils/noOp';

import {
  Root,
} from './styledItems';

const Search = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    onFocus,
    defaultStyle,
    focusOnRender,
  } = props;

  const handleSearch = useCallback((value) => {
    onChange(value);
  }, [onChange]);

  return (
    <Root>
      <Textfield
        fullWidth
        className={defaultStyle ? '' : 'master-search-input'}
        value={value}
        onChange={handleSearch}
        onFocus={onFocus}
        ref={ref}
        focusOnRender={focusOnRender}
      />
    </Root>
  );
});

Search.defaultProps = {
  value: '',
  onFocus: noOp,
  defaultStyle: false,
  focusOnRender: false,
};

const {
  bool,
  func,
  string,
} = PropTypes;

Search.propTypes = {
  onChange: func.isRequired,
  onFocus: func,
  value: string,
  defaultStyle: bool,
  focusOnRender: bool,
};

export default Search;
