import {
  SET_REALTOR_FOLLOW_UP_REMINDER,
} from 'store/actionTypes/followUpReminders';

const setFollowUpReminder = (reminder) => ({
  type: SET_REALTOR_FOLLOW_UP_REMINDER,
  payload: reminder,
});

export default setFollowUpReminder;
