import { connect } from 'react-redux';

import { callAlert } from 'ui/Alert';

import { isEmpty } from 'shared/utility';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import selectors from 'store/selectors';

import {
  setNewProspectModalPropertyRequirement,
  closeNewProspectModal,
  setNewProspectModalAttachments,
} from 'store/actions/newRealtorProspectModal';

import { updatePropertyRequirement } from 'store/actions/propertyRequirements';

import {
  createProspect,
} from 'store/actions/realtorProspects';

import {
  // optimizedAttachmentsActions,
  uploadAttachment,
} from 'store/actions/attachmentsV2';

import removeLeadRelations from 'store/actions/realtorLeads/removeLeadRelations';

import NewProspectForm from './NewProspectForm';

const mapStateToProps = (state) => {
  const {
    realtorNewProspectModal: {
      propertyRequirementId,
      leadId,
      attachments,
    },
  } = state;

  const propertyRequirement = selectors.propertyRequirements.byId(state, propertyRequirementId);

  const addresses = propertyRequirement
    ?.addresses
    ?.map((id) => `<li>${selectors.addresses.byId(state, id)?.address}</li>`)
    ?.join('');

    // const addresses = null;

  const lead = selectors.realtorLeads.byId(state, leadId);
  const budget = [propertyRequirement.budget_min, propertyRequirement.budget_max];

  return {
    propertyRequirementId,
    leadId,
    attachments,
    contactId: lead.contact_id,
    address: addresses || null,
    budget,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPropertyRequirementSelect: () => (propertyRequirementId) => {
    dispatch(setNewProspectModalPropertyRequirement(propertyRequirementId));
  },
  onSubmit: async (prospectData, attachments) => {
    const newProspectId = await dispatch(createProspect({
      ...prospectData,
      is_exclusive: 0,
      is_pocket_listing: 0,
    }));
    dispatch(updatePropertyRequirement(prospectData.realtor_property_requirement_id)({
      status: 2,
      budget_min: prospectData?.budget[0],
      budget_max: prospectData?.budget[1],
    }));

    if (prospectData?.leadId) {
      await dispatch(removeLeadRelations('propertyRequirements')(prospectData.leadId)([prospectData.realtor_property_requirement_id]));
    }

    if (!isEmpty(attachments)) {
      const uploadAttachments = async (index) => {
        const attachment = attachments[index];

        const {
          name,
          type,
          url,
        } = attachment;

        const file = await dataUrlToFile(url, name, type);
        const data = {
          file,
          objectId: newProspectId,
          objectType: 30, // realtor prospect
          attachmentType: 7, // agreement
        };

        await dispatch(uploadAttachment(data, false));

        URL.revokeObjectURL(url);

        const nextIndex = index + 1;
        if (nextIndex < attachments.length) {
          await uploadAttachments(nextIndex);
        }
      };

      try {
        // doing recursion here because Promise.all is not suitable for images uploading
        // to AWS in this case
        await uploadAttachments(0);

        // optimizedAttachmentsActions.getAttachmentsList(propertyObjectType, propertyRequirementId);
      } catch (error) {
        console.error(error);
        callAlert.error('Could not upload images');
      }

      dispatch(setNewProspectModalAttachments([]));
    }

    callAlert.success('Created successfully');

    dispatch(closeNewProspectModal());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onPropertyRequirementSelect: dispatchProps.onPropertyRequirementSelect,
  onSubmit: async (data) => {
    const {
      propertyRequirementId,
      contactId,
      attachments,
      leadId,
    } = stateProps;

    await dispatchProps.onSubmit({
      ...data,
      contact_id: contactId,
      realtor_property_requirement_id: propertyRequirementId,
      leadId,
    },
    attachments);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewProspectForm);
