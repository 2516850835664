import styled from 'styled-components';

const sizes = {
  l: 104,
  m: 70,
  s: 50,
  xs: 25,
};

export const UploadWrapper = styled.div`
  .ant-upload-list-picture-card-container {
    width: ${({ itemSize }) => sizes[itemSize]}px;
    height: ${({ itemSize }) => sizes[itemSize]}px;
    margin: 0;
  }
  .ant-upload-list-item {
    width: ${({ itemSize }) => sizes[itemSize]}px;
    height: ${({ itemSize }) => sizes[itemSize]}px;
    padding: 0;
    margin: 0;
  }

  .ant-upload-picture-card-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    vertical-align: middle;
  }

  .ant-upload-list-picture-card {
    gap: 8px;
    display: inline-flex;
    flex-direction: row-reverse;
    margin: 2px 0;
  }

  .ant-upload {
    width: ${({ itemSize }) => sizes[itemSize]}px;
    height: ${({ itemSize }) => sizes[itemSize]}px;
    margin: 2px 8px 2px 0px;
  }

  .file-tooltip {
    position: absolute;
    color: white;
    left: 50%;
    width: max-content;
    top: 5px;
    text-align: center;
    z-index: 1;
    transform: translate(-50%, calc(-100% - 10px));
    background-color: #000000a8;
    border-style: solid;
    border-radius: 6px;
    border-color: #00000000;
  }
`;

export default {
  UploadWrapper,
};
