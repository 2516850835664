import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const statusDict = {
  acknowledged: 'warm',
  responded: 'success',
};

const RequestItemStatusBar = (props) => {
  const {
    status,
    children,
  } = props;

  return (
    <Root
      variant={statusDict[status]}
    >
      <Typography
        variant="h3"
        tag="p"
        noMargin
      >
        {children}
      </Typography>
    </Root>

  );
};

RequestItemStatusBar.defaultProps = {
  status: null,
};

const {
  oneOf,
  string,
} = PropTypes;

RequestItemStatusBar.propTypes = {
  status: oneOf(['acknowledged', 'responded']),
  children: string,
};

export default RequestItemStatusBar;
