import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 14px;
  .date-text {
    margin: auto 8px auto 0;
  }

  .description-text {
    margin: auto 0;
  }

  .delete-reminder-button {
    display: none;
    margin-top: auto;
    margin-bottom: auto;
  }

  :hover {
    background-color: #f9f9fb;

    .delete-reminder-button {
      display: block;
    }  
  }
`;

export default {
  Wrapper,
};
