import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import routes from 'sections/Calls/routes';

import CallLog from 'sections/Calls/pages/CallLog';

const Calls = () => (
  <Switch>
    <Route
      exact
      path={routes.callLog}
      component={CallLog}
    />

    <Redirect to={routes.callLog} />
  </Switch>
);

export default Calls;
