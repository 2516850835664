import apiConnector, { endpoints } from 'api';

const get = async () => {
  const endpoint = endpoints.external.realtor.offers;
  try {
    const result = await apiConnector.external.get(endpoint);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default get;
