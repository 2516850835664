/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment, react/prop-types */

import React from 'react';

import {
  Root,
  Label,
} from './styledItems';

const withFieldDecorator = (Component) => (props) => {
  return (
    <Root alignItems={props?.alignItems}>
      {
        props.label && (
          <Label
            {...props?.labelProps}
          >
            {props.label}
          </Label>
        )
      }
      <Component
        {...props}
      />
    </Root>

  );
};

export default withFieldDecorator;
