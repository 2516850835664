import requests from 'api/requests';

import setTicketsSummaryForCustomer from './setTicketsSummaryForCustomer';

const getCustomerTicketsSummary = (customerId) => async (dispatch) => {
  try {
    const summaryResponse = await requests.tickets.getTicketsSummary('customer')(customerId);

    dispatch(setTicketsSummaryForCustomer(customerId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getCustomerTicketsSummary;
