import requests from 'api/requests';

import resetFilters from './resetFilters';

// eslint-disable-next-line consistent-return
const create = (documentRequestData = {}) => async (dispatch) => {
  try {
    const response = await requests.realtor.extensionRequests.create({
      ...documentRequestData,
      status: 1,
    });

    dispatch(resetFilters());

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default create;
