import connectReducers from 'store/connectReducers';

import {
  SET_FACEBOOK_FIELD,
  SET_EXTERNAL_TOKEN,
} from 'store/actionTypes/socialIntegrations';

const initialState = {
  facebook: {
    userID: null,
    userName: null,
    userPages: [],
  },
  externalToken: null,
};

const reducers = {
  [SET_FACEBOOK_FIELD]: (state, action) => ({
    ...state,
    facebook: {
      ...state.facebook,
      [action.payload.field]: action.payload.value,
    },
  }),
  [SET_EXTERNAL_TOKEN]: (state, action) => ({
    ...state,
    externalToken: action.payload.value,
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
