import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import ExtensionRequests from 'sections/Transactions/modules/ExtensionRequests';

const ExtensionRequestsModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    contentOptions,
  } = props;

  return (
    <Modal
      theme={theme}
      width={700}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Extension requests"
      footer={null}
    >
      <ExtensionRequests
        {...contentOptions}
      />
    </Modal>
  );
};

ExtensionRequestsModal.defaultProps = {
  isVisible: false,
  theme: null,
  contentOptions: {},
};

const {
  func,
  bool,
  shape,
} = PropTypes;

ExtensionRequestsModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
  contentOptions: shape(),
};

export default ExtensionRequestsModal;
