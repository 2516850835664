import get from './get';
import getById from './getById';
import create from './create';
import update from './update';
import deleteProperty from './deleteProperty';
import addRelations from './addRelations';
import removeRelations from './removeRelations';
import overwriteRelations from './overwriteRelations';
import getSummary from './getSummary';
import share from './share';

export default {
  getSummary,
  get,
  getById,
  create,
  update,
  deleteProperty,
  addRelations,
  removeRelations,
  overwriteRelations,
  share,
};
