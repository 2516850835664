import {
  RESET_CRM_LEAD_FILTER_ITEM,
} from 'store/actionTypes/crm';

const resetFilterItem = (key) => ({
  type: RESET_CRM_LEAD_FILTER_ITEM,
  payload: key,
});

export default resetFilterItem;
