import {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setActiveMeeting as setMeeting,
  setActiveMeetingParticipants as setMeetingParticipants,
} from 'store/actions/video-chat/index';
import socket from 'shared/sockets/OASocket';
import MeetingConnection from './services/MeetingConnection';
import useEditMeetingData from './plugins/useEditMeetingData';

const useMeeting = ({ code }) => {
  const dispatch = useDispatch();

  const meetingParticipants = useSelector((state) => (
    state.videoChat.activeMeetingParticipants
  ));

  const [mainStream, setMainStream] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [pinnedParticipant, setPinnedParticipant] = useState(null);
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [isCameraAvailable, setIsCameraAvailable] = useState(false);
  const [isMicrophoneAvailable, setIsMicrophoneAvailable] = useState(false);

  const localCaptureStream = useRef(null);
  const localCameraStream = useRef(null);
  const meetingConnections = useRef([]);

  const editMeetingData = useEditMeetingData();

  const getLocalStream = useCallback(() => (
    localCaptureStream.current || localCameraStream.current
  ), [localCaptureStream, localCameraStream]);

  const leaveMeeting = useCallback(() => {
    const localStream = getLocalStream();

    destroyMeetingConnections({
      meetingConnections: meetingConnections.current,
    });

    if (localStream && localStream.getTracks) {
      localStream.getTracks().forEach((track) => track.stop());
    }

    localCaptureStream.current = null;
    localCameraStream.current = null;
    meetingConnections.current = [];

    setMainStream(null);
    dispatch(setMeetingParticipants([]));
    setThumbnails([]);
    setPinnedParticipant(null);
    setIsVideoEnabled(false);
    setIsAudioEnabled(false);
    setIsCameraAvailable(false);
    setIsMicrophoneAvailable(false);

    dispatch(setMeeting({}));

    socket.emit('leave-meeting');
  }, [meetingConnections, localCaptureStream, localCameraStream, getLocalStream, dispatch]);

  const cleanUpPreviosMeetingData = useCallback(() => {
    destroyMeetingConnections({
      meetingConnections: meetingConnections.current,
    });

    meetingConnections.current = [];

    dispatch(setMeetingParticipants([]));
    setThumbnails([]);
    setPinnedParticipant(null);

    socket.emit('leave-meeting');
  }, [meetingConnections, dispatch]);

  const joinMeeting = useCallback((userData) => {
    const localStream = getLocalStream();

    if (localStream && code) {
      socket.emit('join-meeting', {
        code,
        userData,
      });
    } else {
      // eslint-disable-next-line no-alert
      window.alert('OA app requires the camera to work.');
    }
  }, [getLocalStream, code]);

  const toggleVideo = useCallback(() => {
    const localStream = getLocalStream();

    if (localStream) {
      localStream.getVideoTracks()[0].enabled = !isVideoEnabled;

      setIsVideoEnabled((prevState) => !prevState);
    }
  }, [getLocalStream, isVideoEnabled]);

  const toggleAudio = useCallback(() => {
    const localStream = getLocalStream();

    if (localStream) {
      localStream.getAudioTracks()[0].enabled = !isAudioEnabled;

      setIsAudioEnabled((prevState) => !prevState);
    }
  }, [getLocalStream, isAudioEnabled]);

  const onReceiveRemoteStream = useCallback((params) => {
    const {
      participantSocketId,
      stream,
    } = params;

    setThumbnails((prevState) => {
      let newState;

      const desiredThumbnail = prevState.find((thumbnail) => (
        thumbnail.participantSocketId === participantSocketId
      ));

      if (!desiredThumbnail) {
        newState = [
          ...prevState,
          {
            participantSocketId,
            stream,
          },
        ];
      } else {
        newState = prevState.reduce((collection, item) => {
          let newItem = item;

          if (item.participantSocketId === participantSocketId) {
            newItem = {
              ...item,
              stream,
            };
          }

          collection.push(newItem);

          return collection;
        }, []);
      }

      return newState;
    });
  }, []);

  const presentScreen = useCallback(async () => {
    const displayMediaConstraints = {
      video: {
        cursor: 'always',
      },
      audio: false,
    };

    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia(
        displayMediaConstraints,
      );

      localCaptureStream.current = captureStream;

      const captureVideoTrack = localCaptureStream.current.getVideoTracks()[0];
      const cameraAudioTrack = localCameraStream.current.getAudioTracks()[0];
      const cameraVideoTrack = localCameraStream.current.getVideoTracks()[0];

      localCaptureStream.current.addTrack(cameraAudioTrack);

      captureVideoTrack.addEventListener('ended', () => {
        meetingConnections.current.forEach((meetingConnection) => {
          meetingConnection.replaceTrackForPeerConnection(cameraVideoTrack);
        });

        localCaptureStream.current = null;

        setMainStream(localCameraStream.current);
      });

      meetingConnections.current.forEach((meetingConnection) => {
        meetingConnection.replaceTrackForPeerConnection(captureVideoTrack);
      });

      setMainStream(localCaptureStream.current);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [localCaptureStream, localCameraStream, meetingConnections]);

  const unpinParticipant = useCallback((participantSocketId) => {
    const localStream = getLocalStream();

    setMainStream(localStream);

    setPinnedParticipant(null);
  }, [getLocalStream]);

  const pinParticipant = useCallback((participantSocketId) => {
    const newPinnedParticipant = meetingParticipants.find((participant) => (
      participant.socketId === participantSocketId
    ));

    const desiredThumbnail = thumbnails.find((thumbnail) => (
      thumbnail.participantSocketId === participantSocketId
    ));

    setMainStream(desiredThumbnail.stream);
    setPinnedParticipant(newPinnedParticipant);
  }, [meetingParticipants, thumbnails]);

  useEffect(() => {
    const onGetMeetingParticipants = (participants) => {
      dispatch(setMeetingParticipants(participants));
    };

    socket.on('get-meeting-participants', onGetMeetingParticipants);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.off('get-meeting-participants', onGetMeetingParticipants);
    };
  }, [dispatch]);

  useEffect(() => {
    const onParticipantLeftMeeting = (participantSocketId) => {
      if (
        pinnedParticipant
        && pinnedParticipant.socketId === participantSocketId
      ) {
        unpinParticipant(participantSocketId);
      }

      const meetingConnectionsToDestroy = meetingConnections.current.filter(
        (meetingConnection) => (
          meetingConnection.participantSocketId === participantSocketId
        ),
      );

      destroyMeetingConnections({
        meetingConnections: meetingConnectionsToDestroy,
        params: {
          stopLocalTracks: false,
        },
      });

      meetingConnections.current = meetingConnections.current.filter(
        (meetingConnection) => (
          meetingConnection.isActive
        ),
      );

      setThumbnails((prevState) => {
        const newState = prevState.filter((item) => (
          item.participantSocketId !== participantSocketId
        ));

        return newState;
      });
    };

    socket.on('participant-left-meeting', onParticipantLeftMeeting);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.off('participant-left-meeting', onParticipantLeftMeeting);
    };
  }, [pinnedParticipant, unpinParticipant, meetingConnections]);

  useEffect(() => {
    const onNewParticipantJoined = (participant) => {
      try {
        const localStream = getLocalStream();
        const peerConnectionId = generatePeerConnectionId();
        const participantSocketId = participant.socketId;

        socket.emit('request-for-peer-connection', {
          to: participantSocketId,
          from: socket.id,
          peerConnectionId,
        });

        const connection = new MeetingConnection({
          polite: true,
          skipNegotiation: true, // Add this only for Safari
          localStream,
          peerConnectionId,
          participantSocketId,
          onReceiveRemoteStream,
        });

        meetingConnections.current.push(connection);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occured on creating MeetingConnection', error);
      }
    };

    const onRequestForPeerConnection = (data) => {
      try {
        const connection = new MeetingConnection({
          polite: false,
          localStream: getLocalStream(),
          peerConnectionId: data.peerConnectionId,
          participantSocketId: data.from,
          onReceiveRemoteStream,
        });

        meetingConnections.current.push(connection);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occured on creating MeetingConnection', error);
      }
    };

    socket.on('new-participant-joined', onNewParticipantJoined);
    socket.on('request-for-peer-connection', onRequestForPeerConnection);

    return () => {
      socket.off('new-participant-joined', onNewParticipantJoined);
      socket.off('request-for-peer-connection', onRequestForPeerConnection);
    };
  }, [getLocalStream, meetingConnections, onReceiveRemoteStream]);

  useEffect(() => {
    if (!code) return;

    const getUserMedia = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const hasCamera = devices.some((device) => device.kind === 'videoinput');
      const hasMicrophone = devices.some((device) => device.kind === 'audioinput');

      const mediaConstraints = {
        video: hasCamera ? {
          aspectRatio: {
            ideal: 1.333333,
          },
        } : false,
        audio: hasMicrophone ? {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        } : false,
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          mediaConstraints,
        );

        localCameraStream.current = stream;

        setMainStream(stream);

        setIsVideoEnabled(hasCamera);
        setIsCameraAvailable(hasCamera);

        setIsAudioEnabled(hasMicrophone);
        setIsMicrophoneAvailable(hasMicrophone);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    getUserMedia();
  }, [localCameraStream, code]);

  const remoteParticipants = meetingParticipants.filter((participant) => (
    participant.socketId !== socket.id
  ));

  const isScreenPresenting = mainStream === localCaptureStream.current;

  return {
    editMeetingData,
    joinMeeting,
    leaveMeeting,
    mainStream,
    localStream: getLocalStream(),
    isVideoEnabled,
    isAudioEnabled,
    isCameraAvailable,
    isMicrophoneAvailable,
    toggleVideo,
    toggleAudio,
    presentScreen,
    isScreenPresenting,
    remoteParticipants,
    meetingParticipants,
    thumbnails,
    pinnedParticipant,
    unpinParticipant,
    pinParticipant,
    cleanUpPreviosMeetingData,
  };
};

const generatePeerConnectionId = () => (
  Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-4)
);

const destroyMeetingConnections = (data) => {
  const {
    meetingConnections,
    params,
  } = data;

  meetingConnections.forEach((meetingConnection) => {
    meetingConnection.destroy(params);
  });
};

export default useMeeting;
