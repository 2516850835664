import createFollowUpReminder from './createFollowUpReminder';

import getFollowUpRemindersForFilter from './getFollowUpRemindersForFilter';

import getFilterByToday from 'shared/utils/getFilterByToday';

const createFollowUpReminders = (objectTypeId) => (objectId) => (createdReminders) => async (dispatch) => {
  await Promise.all(createdReminders.map(async (reminderData) => {
    const {
      scheduled_date,
      title,
      comment,
      is_repeat,
      status,
      time_interval_type,
      time_interval_value,
    } = reminderData;

    await createFollowUpReminder(objectTypeId)(objectId)({
      scheduled_date,
      title,
      comment,
      is_repeat,
      status,
      time_interval_type,
      time_interval_value,
    });

    await dispatch(getFollowUpRemindersForFilter()({
      objectTypeId,
      objectId,
    })());

    if (scheduled_date) {
      try {
        const filter = getFilterByToday(scheduled_date)
        await dispatch(getFollowUpRemindersForFilter()(filter)());
      } catch (error) {
        
      }
    }
  }));
};

export default createFollowUpReminders;
