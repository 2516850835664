import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactAvatar from 'shared/components/UI/RealtorContactAvatar';

import {
  Logo,
} from './styledItems';

const AgentInfo = (props) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    avatarUrl,
    companyName,
    logoUrl,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
    >
      <Grid item xs={3}>
        <ContactAvatar
          src={avatarUrl}
          firstName={firstName}
          lastName={lastName}
          onSave={null}
        />
      </Grid>
      <Grid
        item
        container
        spacing={1}
        xs={9}
      >
        <Grid item xs={12}>
          <Typography
            variant="title1"
            noMargin
            style={{ maxWidth: '100%' }}
            ellipsis
          >
            {`${firstName || ''} ${lastName || ''}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {
            logoUrl ? (
              <Logo src={logoUrl} />
            ) : (
              <Typography
                variant="title3"
                noMargin
                style={{ maxWidth: '100%' }}
                ellipsis
              >
                {companyName}
              </Typography>
            )
          }
        </Grid>
        {
          email && (
            <Grid item xs={12}>
              <DecoratedFieldWrapper
                iconName="mail"
                iconType="feather"
                size="sm"
                variant="focus"
              >
                <a href={`mailto:${email}`}>
                  <Typography
                    variant="title3"
                    noMargin
                    style={{ maxWidth: '100%' }}
                    ellipsis
                  >
                    {email}
                  </Typography>
                </a>
              </DecoratedFieldWrapper>
            </Grid>
          )
        }
        {
          phone && (
            <Grid item xs={12}>
              <DecoratedFieldWrapper
                iconName="phone"
                iconType="feather"
                size="sm"
                variant="focus"
              >
                <a href={`tel:${phone}`}>
                  <Typography
                    variant="title3"
                    noMargin
                    style={{ maxWidth: '100%' }}
                    ellipsis
                  >
                    {phone}
                  </Typography>
                </a>
              </DecoratedFieldWrapper>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
};

AgentInfo.defaultProps = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  avatarUrl: null,
  companyName: '',
  logoUrl: null,
};

const {
  string,
} = PropTypes;

AgentInfo.propTypes = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  avatarUrl: string,
  companyName: string,
  logoUrl: string,
};

export default AgentInfo;
