import styled from 'styled-components';

import Elevation from 'ui/Elevation';

export const CardWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

export const StyledCardElevation = styled(Elevation)`
  padding: 8px 0 8px 8px;
  border-top-left-radius: ${({ showObjectName }) => (!showObjectName ? '10px' : null)};
  border-top-right-radius: 10px;
  border-bottom-left-radius: ${({ showObjectName }) => (!showObjectName ? '10px' : null)};
  border-bottom-right-radius: 10px;
  width: 100%;
`;

export const Root = styled.div`
  .card-text {
    margin-top: 5px;
  }

  .description-text {
    margin: auto 0 auto 10px;
  }

  .title-text {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const ObjectNameTag = styled.div`
  background-color: ${(props) => props.theme?.palette?.priority || '#9013fe'};
  width: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
  word-break: break-word;
  text-align: center;
  display: flex;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  .object-name {
    width: 6px;
    margin: auto;
    line-height: 9px;
    font-size: 8px;
  }
`;

export default {
  Root,
  StyledCardElevation,
  CardWrapper,
  ObjectNameTag,
};
