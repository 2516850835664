import moment from 'moment';

import { find } from 'shared/utility';

import { tickets as TICKETS_CONSTANTS } from '../../constants/tickets.constants';
import { compareText } from './common'; // eslint-disable-line import/no-cycle

const filterTypes = TICKETS_CONSTANTS.ALL_TYPES;

export const formatPhone = (...phones) => {
  let activePhones = [];

  // TODO - find a solution for linter issue here
  // This is a known issue
  // https://github.com/eslint/eslint/issues/12117
  for (let phone of phones) { // eslint-disable-line no-unused-vars
    if (phone) {
      phone = phone.replace(/"/g, '');
      phone = phone.replace(/\\/g, '');
      activePhones.push(JSON.parse(phone));
    }
  }
  activePhones = activePhones.reduce((acc, cur) => acc.concat(cur), []);

  return activePhones.length ? activePhones.join(', ') : null;
};

export const getMemberTickets = (list, id) => {
  let ticketAmount = [];
  if (list) {
    ticketAmount = list.filter(({ employees }) => find(employees, { EmployeeID: id }));
  }

  return ticketAmount;
};

export const getActiveQueue = (queues, id) => queues.find((queue) => queue.QueueID === id);

export const setOverdue = (list) => {
  if (!list) {
    return [];
  }

  return list.filter(
    (ticket) => new Date() > new Date(ticket.DeliveryDate) && ticket.DeliveryDate,
  );
};

export const setDue = (list) => list.filter(
  (ticket) => new Date() >= new Date(ticket.DeliveryDate) && ticket.DeliveryDate,
);

export const filterTickets = (tickets, filter) => {
  let filtered = tickets || [];

  switch (filter) {
    case filterTypes.ALL_TICKETS: {
      filtered = filtered.filter((ticket) => {
        const isArchived = ticket.ticketstatus.StatusID === 9;
        return !ticket.Resolved && !isArchived;
      });
      break;
    }
    case filterTypes.IN_PROGRESS: {
      filtered = filtered.filter(
        (ticket) => ticket.ticketstatus.TicketStatus === filterTypes.IN_PROGRESS,
      );
      break;
    }
    case filterTypes.PROBLEM: {
      filtered = filtered.filter((ticket) => ticket.IsProblem);
      break;
    }
    case filterTypes.UNASSIGNED: {
      filtered = filtered.filter((ticket) => !ticket.employees.length);
      break;
    }
    case filterTypes.OVERDUE: {
      filtered = setOverdue(filtered);
      break;
    }
    case filterTypes.RESOLVED: {
      filtered = filtered.filter((ticket) => ticket.Resolved);
      break;
    }
    case filterTypes.NEW_ASSIGNED: {
      filtered = filtered.filter(
        (ticket) => ticket.ticketstatus.TicketStatus === filterTypes.NEW_ASSIGNED,
      );
      break;
    }
    case filterTypes.RESOLVED_THIS_WEEK: {
      filtered = filtered.filter((ticket) => moment(ticket.ResolvedAt).isSame(new Date(), 'week'));
      break;
    }
    case filterTypes.TOTAL_AGENTS: {
      break;
    }

    default:
      break;
  }
  return filtered;
};

export const getCreator = (
  employees,
  id,
) => employees.find((employee) => employee.EmployeeID === id);

export const searchTickets = (tickets, search, queue = null) => {
  if (!tickets) {
    return [];
  }

  return tickets.filter((ticket) => {
    const { TicketID, TicketName, Description } = ticket;

    let result = true;
    if (!!queue && queue.QueueID !== 'All Queues') {
      const { QueueID, Category, SubCategory } = ticket;
      if (queue.QueueID) result = QueueID === queue.QueueID;
      if (queue.category) result = Category === queue.category;
      if (queue.Product) result = SubCategory === queue.Product;
    }

    if (result) {
      result = compareText(TicketID.toString(), search)
        || compareText(TicketName, search)
        || compareText(Description, search);
    }

    return result;
  });
};

export const getManagerQueues = (queues) => {
  if (!queues) {
    return [];
  }

  return queues.filter((queue) => queue.QueueManager);
};

export const getQueuesByPermissions = (queueSetup, managerOnly) => {
  let myQueues = managerOnly
    ? getManagerQueues(queueSetup.permissions)
    : queueSetup.permissions;

  myQueues = myQueues ? myQueues.map((queue) => queue.QueueID) : [];

  return queueSetup.queueSetupList.filter((queue) => myQueues.includes(queue.QueueID));
};

export const mapQueueTickets = (queueTickets, tickets = []) => {
  const list = queueTickets.map((ticket) => ticket.TicketID);

  return tickets.filter((ticket) => list.includes(ticket.TicketID));
};
