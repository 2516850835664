import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_PROSPECT,
  SET_REALTOR_PROSPECTS,
  SET_REALTOR_SELECTED_PROSPECT,
  SET_REALTOR_PROSPECT_SELECTED_VIEW,
  SET_REALTOR_PROSPECTS_FOR_FILTER,
  SET_REALTOR_PROSPECTS_FOR_FILTER_COUNT,
  SET_REALTOR_PROSPECTS_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorProspects';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  prospects: {},
  selectedProspect: null,
  selectedView: 'buyer needs',
  realtorProspectsByFilter: {},
  realtorProspectsByFilterCount: {},
  overallSummary: null,
};

const reducers = {
  [SET_REALTOR_PROSPECT]: (state, action) => ({
    ...state,
    prospects: {
      ...state.prospects,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_PROSPECTS]: (state, action) => ({
    ...state,
    prospects: {
      ...state.prospects,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_SELECTED_PROSPECT]: (state, action) => ({
    ...state,
    selectedProspect: action.payload,
  }),

  [SET_REALTOR_PROSPECT_SELECTED_VIEW]: (state, action) => ({
    ...state,
    selectedView: action.payload,
  }),

  [SET_REALTOR_PROSPECTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      prospects,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(prospects),
    ]);

    const updatedState = {
      ...state,
      realtorProspectsByFilter: {
        ...state.realtorProspectsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_PROSPECTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    realtorProspectsByFilterCount: {
      ...state.realtorProspectsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_PROSPECTS_OVERALL_SUMMARY]: (state, action) => ({
    ...state,
    overallSummary: action.payload,
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
