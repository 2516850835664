import styled from 'styled-components';

export const Wrapper = styled.div`
  input {
    font-size: 14px;
  }
`;

export default {
  Wrapper,
};
