import {
  SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN,
} from 'store/actionTypes/realtorInspections';

const openInspectionNotesModal = (inspectionId) => ({
  type: SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN,
  payload: { inspectionId },
});

export default openInspectionNotesModal;
