import {
  SET_REALTOR_INFINITE_SCROLL_MATCHED_FOR_FILTER,
} from 'store/actionTypes/realtorMatched';

const setInfiniteScrollMatchedForFilter = (filterId) => (matched) => (resetState) => ({
  type: SET_REALTOR_INFINITE_SCROLL_MATCHED_FOR_FILTER,
  payload: {
    filterId,
    matched,
    resetState,
  },
});

export default setInfiniteScrollMatchedForFilter;
