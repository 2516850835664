/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import MlsList from 'shared/components/UI/MlsList';

const MlsSearchResult = (props) => {
  const {
    control,
    searchedData,
  } = props;

  const {
    field: {
      onChange,
    },
  } = useController({
    name: 'selectedProperty',
    control,
  });

  const handleChange = useCallback((itemsArray = []) => {
    onChange(itemsArray);
  }, [
    onChange,
  ]);

  return (
    <MlsList
      listData={searchedData}
      onChangeSelected={handleChange}
    />
  );
};

const {
  shape,
  arrayOf,
  number,
} = PropTypes;

MlsSearchResult.propTypes = {
  control: shape().isRequired,
  searchedData: arrayOf(number).isRequired,
};

export default MlsSearchResult;
