import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorUnansweredQuestionActions,
} from 'store/actions/realtorUnansweredQuestions';

export default (requestId) => createSelector(
  (state) => state.realtorUnansweredQuestions.unansweredQuestions,
  (collection) => {
    const item = collection[requestId];

    if (!item) {
      if (requestId) {
        optimizedRealtorUnansweredQuestionActions.getById(requestId);
      }

      return null;
    }

    return item;
  },
);
