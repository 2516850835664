import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import OfferDetailsMain from 'sections/Opportunities/modules/Offer';
import PropertySummary from 'sections/Offers/modules/PropertySummary';

const OfferDetails = (props) => {
  const {
    offerId,
  } = props;

  return (
    <Flex flexDirection="column" spacing={2}>
      <FlexItem fullWidth>
        <PropertySummary offerId={offerId} />
      </FlexItem>
      <OfferDetailsMain
        header={null}
        offerId={offerId}
      />
    </Flex>
  );
};

const {
  number,
} = PropTypes;

OfferDetails.propTypes = {
  offerId: number.isRequired,
};

export default OfferDetails;
