import requests from 'api/requests';

import setTasksSummaryForEmployee from './setTasksSummaryForEmployee';

const getEmployeeTasksSummary = (employeeId) => async (dispatch) => {
  try {
    const summaryResponse = await requests.tasks.getTasksSummary('employee')(employeeId);

    dispatch(setTasksSummaryForEmployee(employeeId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};


export default getEmployeeTasksSummary;
