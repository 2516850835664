import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import AutocompleteWithAdd from 'shared/components/UI/AutocompleteWithAdd';
import SourceDescriptionModal from './SourceDescriptionModal';
import NewSourceModal from './NewSourceModal';

const getAddTitle = (value) => `+ Add ${value} as custom lead source`;

const SourceSelect = (props) => {
  const {
    options,
    createSourceType,
    createSource,
  } = props;

  const [sourceDescription, setSourceDescription] = useState(null);
  const [value, setValue] = useState({
    id: null,
    name: null,
    title: null,
  });
  const [newCustomSourceName, setCustomSourceName] = useState(null);
  const [isDescriptionModalVisible, setDescriptionModalVisibility] = useState(false);
  const [isNewSourceModalVisible, setNewSourceModalVisibility] = useState(false);

  const handleChangeCustomSourceName = useCallback((customSourceName) => {
    setCustomSourceName(customSourceName);
  }, []);

  const handleChangeSourceDescription = useCallback((description) => {
    setSourceDescription(description);
  }, []);

  const handleSelect = useCallback((sourceType) => {
    if (sourceType) {
      setValue(sourceType);
      setDescriptionModalVisibility(true);
    }
  }, []);

  const handleNewItemClick = useCallback((newValue) => {
    setCustomSourceName(newValue);
    setNewSourceModalVisibility(true);
  }, []);

  const onCancelDescriptionModal = useCallback(() => {
    setValue({
      id: null,
      name: null,
      title: null,
    });
    setSourceDescription(null);
    setDescriptionModalVisibility(false);
  }, []);

  const saveSource = useCallback(() => {
    createSource({
      type: value.id,
      description: sourceDescription,
    });
    setDescriptionModalVisibility(false);
  }, [
    sourceDescription,
    value,
  ]);

  const onCancelNewSourceModal = useCallback(() => {
    setSourceDescription(null);
    setCustomSourceName(null);
    setNewSourceModalVisibility(false);
  }, []);

  const onCustomSourceSave = useCallback(async () => {
    const newSourceTypeId = await createSourceType(newCustomSourceName);

    createSource({
      type: newSourceTypeId,
      description: sourceDescription,
    });

    setNewSourceModalVisibility(false);

    setValue({
      id: newSourceTypeId,
      name: newCustomSourceName,
      title: newCustomSourceName,
    });
  }, [
    newCustomSourceName,
    sourceDescription,
  ]);

  return (
    <>
      <AutocompleteWithAdd
        label="Source"
        options={options}
        onItemSelect={handleSelect}
        getAddTitle={getAddTitle}
        onAddNewItemClick={handleNewItemClick}
        value={value}
      />
      <SourceDescriptionModal
        isVisible={isDescriptionModalVisible}
        onCancel={onCancelDescriptionModal}
        onSave={saveSource}
        handleChangeSourceDescription={handleChangeSourceDescription}
        sourceDescription={sourceDescription}
        sourceName={value.name}
      />
      <NewSourceModal
        isVisible={isNewSourceModalVisible}
        onCancel={onCancelNewSourceModal}
        onSave={onCustomSourceSave}
        handleChangeCustomSourceName={handleChangeCustomSourceName}
        handleChangeSourceDescription={handleChangeSourceDescription}
        sourceDescription={sourceDescription}
        newCustomSourceName={newCustomSourceName}
      />
    </>
  );
};

SourceSelect.defaultProps = {
  options: [],
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

SourceSelect.propTypes = {
  options: arrayOf(shape({
    id: number,
    title: string,
    name: string,
  })),
  createSourceType: func.isRequired,
  createSource: func.isRequired,
};

export default SourceSelect;
