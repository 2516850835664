import getLeadChangelogAllTime from './getLeadChangelogAllTime';

const getSelectedLeadChangelogAllTime = () => (dispatch, getState) => {
  const {
    crmLeads: {
      selectedLead,
    },
  } = getState();

  try {
    dispatch(getLeadChangelogAllTime(selectedLead));
  } catch (error) {
    console.error(error);
  }
};

export default getSelectedLeadChangelogAllTime;
