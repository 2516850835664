import requests from 'api/requests';

import getFollowUpRemindersForFilter from './getFollowUpRemindersForFilter';

const deleteFollowUpReminders = (objectTypeId) => (objectId) => (deletedReminders) => async (dispatch) => {
  await Promise.all(deletedReminders.map(async (reminderId) => {
    await requests.followUpReminders.deleteReminder(reminderId);
  }));

  await dispatch(getFollowUpRemindersForFilter()({
    objectTypeId,
    objectId,
  })());
};

export default deleteFollowUpReminders;
