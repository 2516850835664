import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import {
  Root,
} from './styledItems';

const CircleIcon = (props) => {
  const {
    iconName,
  } = props;

  return (
    <Root>
      <Icon
        name={iconName}
        color="focus"
        size="m"
      />
    </Root>
  );
};

const {
  string,
} = PropTypes;

CircleIcon.propTypes = {
  iconName: string.isRequired,
};

export default CircleIcon;
