import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyAddress from './PropertyAddress';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);
  const address = selectors.addresses.byId(state, property?.address_id);

  return {
    address: address?.address,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAddress);
