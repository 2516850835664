import {
  SET_REALTOR_INSPECTION,
} from 'store/actionTypes/realtorInspections';

const setInspection = (inspection) => ({
  type: SET_REALTOR_INSPECTION,
  payload: inspection,
});

export default setInspection;
