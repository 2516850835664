import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import moment from 'moment';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

import {
  Wrapper,
} from './styledItems';

const ContactCard = (props) => {
  const {
    contactId,
    date,
    role,
  } = props;

  const [daysHours, setIntervalValue] = useState({
    days: 0,
    hours: 0,
  });

  useEffect(() => {
    const now = moment().utc();
    const end = moment(date);
    const duration = moment.duration(end.diff(now));

    const days = duration.asDays();
    duration.subtract(moment.duration(Math.floor(days), 'days'));

    const hours = duration.hours();

    setIntervalValue({
      days: days > 0 ? Math.floor(days) : 0,
      hours: days > 0 ? Math.abs(hours) : 0,
    });
  }, [date]);

  const showSubmitted = !isEmpty(date);

  return (
    <Wrapper>
      <Flex
        spacing={2}
        spacingStep={6}
        alignItems="stretch"
      >
        <ContactAvatar
          contactId={contactId}
          size={55}
        />

        <Flex
          flexDirection="column"
        >
          <Typography
            variant="title3"
            weight="bold"
            noMargin
          >
            {useContactName(contactId)}
          </Typography>

          <Typography
            variant="subtitle"
            color="descriptor-text"
            weight="normal"
            noMargin
          >
            {role}
          </Typography>

          {
            showSubmitted && (
              <Flex spacing={1}>
                <Typography
                  variant="subtitle"
                  weight="normal"
                  noMargin
                >
                  Submitted:
                </Typography>

                <Typography
                  variant="subtitle"
                  color="descriptor-text"
                  weight="normal"
                  noMargin
                >
                  {`${moment(date).format('MMM D, LT')} (${daysHours.days}days ${daysHours.hours}hours)`}
                </Typography>
              </Flex>
            )
          }
        </Flex>
      </Flex>
    </Wrapper>
  );
};

ContactCard.defaultProps = {
  contactId: null,
  date: '',
  role: 'Buyer Agent',
};

const {
  number,
  string,
} = PropTypes;

ContactCard.propTypes = {
  contactId: number,
  date: string,
  role: string,
};

export default ContactCard;
