import {
  SET_REALTOR_MATCHED_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorMatched';

const setMatchedForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_MATCHED_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setMatchedForFilterCount;
