import store from 'store';

/**
 *
 * @param {number[]} seenBy
 * @param {string} employeeId
 */
const hasEmployeeSeenComment = (seenBy = []) => {
  if (!store.getState().auth.loggedUser) {
    return true;
  }

  const {
    auth: {
      loggedUser: {
        EmployeeID: myId,
      },
    },
  } = store.getState();

  const userId = myId;

  if (!myId) {
    return true;
  }

  const hasSeen = seenBy.some((item) => item.UserID === userId);

  return hasSeen;
};

export default hasEmployeeSeenComment;
