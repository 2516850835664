import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedCrmLeadsActions,
} from 'store/actions/crm/crmLeads';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

const leads = (state) => state.crmLeads.leads;
const selectedLead = (state) => state.crmLeads.selectedLead;
const products = (state) => state.crmProducts.products;

export default createSelector(
  leads,
  selectedLead,
  products,
  (leadItems, selectedLeadId, productsDict) => {
    const lead = leadItems[selectedLeadId];
    let leadProducts = [];

    if (!lead) {
      if (selectedLeadId) {
        optimizedCrmLeadsActions.getLeadById(selectedLeadId);
      }
    } else if (lead && lead.products && lead.products.length > 0) {
      leadProducts = lead.products.reduce((acc, item) => {
        const product = productsDict[item.id];

        if (!product) {
          optimizedCrmProductsActions.getProductById(item.id);
          return acc;
        }

        acc.push({
          ...product,
          ...item,
          id: product.id,
        });
        return acc;
      }, []);
    }

    return leadProducts;
  },
);
