import styled from 'styled-components';

export const Row = styled.tr`
  border: solid 1px #ecf2fb;

  .time-row-title {
    margin: 7px;
  }
`;

export const Column = styled.td`
  border: solid 1px #ecf2fb;
  padding: 5px 16px 5px 5px;
  height: 40px;
`;

export const ItemsWrapper = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
`;

export default {
  Row,
  Column,
  ItemsWrapper,
};
