import React from 'react';

import AttachmentsUpload from 'shared/inputs/InlineAttachmentsUpload';

import withExtensionRequestAttachments from 'sections/Transactions/modules/ExtensionRequests/hocs/withExtensionRequestAttachments/index';

const ExtensionRequestAttachments = withExtensionRequestAttachments(AttachmentsUpload);

export default (props) => (
  <ExtensionRequestAttachments
    objectId={props.requestId}
    multiple
    itemSize="m"
  />
);
