import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

const remove = (id) => async (dispatch) => {
  try {
    await requests.realtor.matchingProperties.remove(id);
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default remove;
