import create from './create';
import deleteInspection from './deleteInspection';
import get from './get';
import update from './update';
import getById from './getById';

export default {
  update,
  getById,
  get,
  create,
  deleteInspection,
};
