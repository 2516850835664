import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import {
  InnerComponentRoot,
  InnerComponentTitle,
  InnerComponentValue,
} from './styledItems';

const {
  func,
  string,
} = PropTypes;

const StyledChip = withStyles({
  root: {
    borderRadius: 4,
  },
})(Chip);

const InnerComponent = ({ title, value }) => (
  <InnerComponentRoot>
    <InnerComponentTitle>{title}</InnerComponentTitle>
    <InnerComponentValue>{value}</InnerComponentValue>
  </InnerComponentRoot>
);

InnerComponent.defaultProps = {
  title: null,
  value: null,
};

InnerComponent.propTypes = {
  title: string,
  value: string,
};

const FilterChip = (props) => {
  const {
    title,
    value,
    onDelete,
  } = props;

  const handleDelete = onDelete
    ? useCallback(() => {
      onDelete();
    })
    : false;

  return (
    <StyledChip
      onDelete={handleDelete}
      label={<InnerComponent title={title} value={value} />}
    />
  );
};

FilterChip.defaultProps = {
  onDelete: null,
  title: null,
  value: null,
};

FilterChip.propTypes = {
  onDelete: func,
  title: string,
  value: string,
};

export default FilterChip;
