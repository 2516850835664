const muiTabStyles = {
  tabs: {
    borderBottom: 'solid 1px #D8E5FF',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
      backgroundColor: 'white',
      color: '#367BED',
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    borderBottom: 'none',
    backgroundColor: '#F2F6FE',
  },
  tabWithBorder: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    borderBottom: 'none',
    backgroundColor: '#F2F6FE',
    borderRight: '1px solid #D5E6EF',
  },
  tabSelected: {
    paddingTop: 10,
    paddingBottom: 15,
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: 1,
    borderBottom: 'none',
  },
};

export default muiTabStyles;