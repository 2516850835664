import store from 'store';

import { isEmpty } from 'shared/utility';

/**
 * @param {string} leadId
 * @returns {object}
 */
const getLeadById = (leadId) => {
  const {
    leads: {
      leadsList,
    },
  } = store.getState();

  if (!leadId || isEmpty(leadsList)) {
    return null;
  }

  const lead = leadsList.find((item) => item.LeadID === leadId);

  if (!lead) {
    return null;
  }

  return lead;
};

export default getLeadById;
