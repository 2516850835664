import moment from 'moment';
import pipe from 'ramda/src/pipe';

const commentTabsKeys = {
  allComments: 0,
};

const sentiments = {
  1: 'Positive',
  2: 'Neutral',
  3: 'Negative',
};

const contactTypes = {
  1: 'email',
  2: 'message',
  3: 'call',
  4: 'in person',
};

export const filterCommentsByType = (comments, selectedType) => comments.filter((item) => {
  const allComments = selectedType === 0;
  const matchesType = +item.CommentType === +selectedType;

  return allComments || matchesType;
});

export const normalizeLogs = (logList) => [...logList].map((item) => {
  const {
    LogID,
    ContactType,
    CreatedAt,
    Duration,
    CreatorID,
    CreatorType,
    Notes,
    Sentiment,
    Title,
    IsOwner,
    RepliesCount,
    ReplyCreators,
    AcknowledgedBy,
  } = item;

  if (!ContactType) {
    return null;
  }

  const tempTime = moment.duration(Duration, 'seconds');
  const hours = tempTime.hours() ? `${tempTime.hours()} hr ` : '';
  const minutes = `${tempTime.minutes()} min`;

  const Status = ContactType
    ? `${sentiments[Sentiment]} ${contactTypes[ContactType]} on ${moment(CreatedAt).format('LLL')} for ${hours}${minutes}` // eslint-disable-line max-len
    : null;

  return {
    type: 'log',
    LogID,
    ContactType: contactTypes[ContactType],
    CreatedAt,
    CreatorID,
    CreatorType,
    IsOwner,
    RepliesCount,
    ReplyCreators,
    Status,
    Text: Notes,
    Title,
    AcknowledgedBy,
  };
});

export const normalizeCallRecordings = (callRecordingsList) => [...callRecordingsList].map((item) => {
  const {
    recording_sid, // eslint-disable-line camelcase
    created_at, // eslint-disable-line camelcase
    contact_id, // eslint-disable-line camelcase
  } = item;

  const Status = `Call recording on ${moment(created_at).format('LLL')}`;

  return {
    type: 'call recording',
    RecordingSid: recording_sid,
    CreatedAt: created_at,
    Status,
    ContactId: contact_id,
  };
});

export const addLogListToCommentsList = (selectedType, comments, logList) => {
  const shouldShowAllComments = selectedType === commentTabsKeys.allComments;

  if (shouldShowAllComments) {
    const normalizedLogs = normalizeLogs(logList);
    return [...comments].concat(normalizedLogs);
  }

  return [...comments];
};

export const addCallRecordingsListToCommentsList = (selectedType, comments, callRecordingsList) => {
  const shouldShowAllComments = selectedType === commentTabsKeys.allComments;

  if (shouldShowAllComments) {
    const normalizedCallRecordings = normalizeCallRecordings(callRecordingsList);
    return [...comments].concat(normalizedCallRecordings);
  }

  return [...comments];
};

export const applyFilter = (data) => {
  const clonedData = { ...data };

  clonedData.preparedCommentsList = filterCommentsByType(
    clonedData.comments,
    clonedData.selectedType,
  );

  return clonedData;
};

export const addLogs = (data) => {
  const clonedData = { ...data };

  clonedData.preparedCommentsList = addLogListToCommentsList(
    clonedData.selectedType,
    clonedData.preparedCommentsList,
    clonedData.logList,
  );

  return clonedData;
};

export const addCallRecordings = (data) => {
  const clonedData = { ...data };

  clonedData.preparedCommentsList = addCallRecordingsListToCommentsList(
    clonedData.selectedType,
    clonedData.preparedCommentsList,
    clonedData.callRecordingsList,
  );

  return clonedData;
};

export const cleanData = (data) => data.preparedCommentsList;

export const prepareComments = pipe(
  applyFilter,
  addLogs,
  addCallRecordings,
  cleanData,
);

export default {
  prepareComments,
};
