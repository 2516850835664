import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SectionWrapper = styled.div`
  margin-left: 38px;
  margin-top: 15px;
  .date-field-flex-container {
    width: -webkit-fill-available;
    gap: 16px;
  }
  .date-input-wrapper {
    width: 200px
  }
`;
