import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_LOG_NEW_QUESTION_FORM_STATE,
} from 'store/actionTypes/realtorTransactions';

const openLogNewQuestionModal = openModalWithContentAction('newQuestion')(
  SET_LOG_NEW_QUESTION_FORM_STATE,
);

export default openLogNewQuestionModal;
