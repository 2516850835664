import requests from 'api/requests';

import setTaskWorklogs from './setTaskWorklogs';

const getTaskWorklogs = (taskId) => async (dispatch) => {
  try {
    const worklogsResponse = await requests.tasks.getTaskWorklogs(taskId);

    dispatch(setTaskWorklogs(worklogsResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getTaskWorklogs;
