import requests from 'api/requests';
import { callAlert } from 'ui/Alert';
import store from 'store';

import {
  SET_USER,
  DELETE_USER_AVATAR,
} from 'store/actionTypes/users';

import { memoizeDebounce } from 'shared/utility';

export const getUser = (userType) => (userId) => async (dispatch) => {
  if (userType && userId) {
    try {
      const id = typeof userId === 'object' // TODO: find what passes an object here and fix on that level
        ? userId?.id
        : userId;

      const res = await requests.users.get(userType)(id);
      dispatch(setUser(userType)(id)(res.data));
    } catch (error) {
      console.error(error);
    }
  }
};

export const getUserOptimized = memoizeDebounce((userType, userId) => {
  if (userType && userId) {
    store.dispatch(getUser(userType)(userId));
  }
}, 5000, { leading: true, trailing: false });

export const userMethods = (userType) => (userId) => ({
  get: getUser(userType)(userId),
});

export const setUser = (userType) => (userId) => ({
  name,
  surname,
  role,
  avatar,
  avatarAcl = null,
  avatarUrl = null,
}) => ({
  type: SET_USER,
  payload: {
    userType,
    userId,
    name,
    surname,
    role,
    avatar,
    avatarAcl,
    avatarUrl,
  },
});

export const deleteUserAvatar = () => async (dispatch, getState) => {
  try {
    const {
      attachments: {
        openedImageModalInformation: {
          imageObjectId,
        },
      },
    } = getState();
    await requests.users.deleteAvatar(imageObjectId);
    dispatch(getUser('employee')(imageObjectId));
    dispatch({
      type: DELETE_USER_AVATAR,
    });
  } catch {
    callAlert.error('An error occurred when deleting the user\'s image!');
  }
};

export const changeUserSystemPermission = (value, employeeId) => async (dispatch) => {
  try {
    requests.users.changeSystemPermission(value, employeeId);
    callAlert.success('Changes saved!');
  } catch {
    callAlert.error('An error occurred when deleting the user\'s image!');
  }
};

export default userMethods;
