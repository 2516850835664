import { connect } from 'react-redux';

import {
  openArticle,
} from 'store/actions/articleDetail';

import {
  showSearchStandaloneModal,
  searchArticles,
  onSearchSuggestionArticleHover,
  getInitialSearchArticles,
} from 'store/actions/knowledgeBase';

import TicketKBSearchModal from '../TicketKnowledgeBaseSearchModal';

const mapStateToProps = (state) => ({
  isVisible: state.knowledgeBase.searchStandaloneModal.isVisible,
  searchSuggestions: state.knowledgeBase.searchSuggestions,
  kbSearchFilterData: state.knowledgeBase.kbSearchFilterData,
  suggestionHoverArticle: state.knowledgeBase.suggestionHoverArticle,
});

const mapDispatchToProps = (dispatch) => ({
  onShowSearchStandaloneModal: () => {
    dispatch(showSearchStandaloneModal());
  },
  searchArticles: (searchWord, filter) => {
    dispatch(searchArticles(searchWord, filter));
  },
  onSearchSuggestionArticleHover: (articleId) => {
    dispatch(onSearchSuggestionArticleHover(articleId));
  },
  getInitialSearchArticles: () => {
    dispatch(getInitialSearchArticles());
  },
  openArticle: (id) => {
    dispatch(openArticle(id));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(TicketKBSearchModal);
