import requests from 'api/requests';
import { ADD_MEETING } from 'store/actionTypes/video-chat';

const createMeeting = (payload) => async (dispatch) => {
  let result;

  try {
    const response = await requests.meetings.createMeeting(payload);

    dispatch({
      type: ADD_MEETING,
      payload: response.data,
    });

    result = response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return result;
};

export default createMeeting;
