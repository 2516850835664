import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.div`
  padding-bottom: 5px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #ff526b;
`;

export const Line = styled.div`
  width: 1px;
  height: ${(props) => props.height}px;
  background-color: #ff526b;
`;

export default {
  Root,
  Label,
  Line,
};
