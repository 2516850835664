import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchedProperty from './SearchedProperty';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  const price = property?.expected_price;

  const address = selectors.addresses.byId(state, property?.address_id)?.address;

  const image = selectors.properties.mainPropertyImage(state, ownProps.propertyId);

  return {
    image,
    address,
    price,
  };
};

export default connect(mapStateToProps)(SearchedProperty);
