import { connect } from 'react-redux';

import {
  addPropertyViewingRelations,
} from 'store/actions/propertyViewings';

import {
  closeEditViewingModal,
} from 'store/actions/editRealtorPropertyViewingModal';

import {
  resetEditPropertyViewingForm,
} from 'store/actions/editRealtorPropertyViewingForm';

import EditViewingModal from './EditViewingModal';

const mapStateToProps = (state) => {
  const {
    editPropertyViewingModal: {
      isVisible,
      viewingId,
    },
    editPropertyViewingForm: {
      buyer,
      buyerAgent,
      sellerAgent,
    },
  } = state;

  return {
    isVisible,
    viewingId,
    buyer,
    buyerAgent,
    sellerAgent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeEditViewingModal());
  },
  addBuyer: async (viewingId, buyer) => {
    await dispatch(addPropertyViewingRelations('buyer')(viewingId)({ buyer: [buyer], roleId: 1 }));
    dispatch(resetEditPropertyViewingForm());
  },
  addBuyerAgent: async (viewingId, buyerAgent) => {
    await dispatch(addPropertyViewingRelations('buyerAgent')(viewingId)({ buyerAgent: [buyerAgent], roleId: 3 }));
    dispatch(resetEditPropertyViewingForm());
  },
  addSellerAgent: async (viewingId, sellerAgent) => {
    await dispatch(addPropertyViewingRelations('sellerAgent')(viewingId)({ sellerAgent: [sellerAgent], roleId: 4 }));
    dispatch(resetEditPropertyViewingForm());
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  isVisible: stateProps.isVisible,
  closeModal: dispatchProps.closeModal,
  onSave: async () => {
    const {
      viewingId,
      buyer,
      buyerAgent,
      sellerAgent,
    } = stateProps;

    if (buyer) {
      await dispatchProps.addBuyer(viewingId, buyer);
    }
    if (buyerAgent) {
      await dispatchProps.addBuyerAgent(viewingId, buyerAgent);
    }
    if (sellerAgent) {
      await dispatchProps.addSellerAgent(viewingId, sellerAgent);
    }
    dispatchProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditViewingModal);
