import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import MenuItem from 'ui/MenuItem';
import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';

import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

import FormTextfield from 'shared/inputs/FormTextfield';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';
import withInlineLabel from 'shared/hocs/withInlineLabel';

const useStyles = makeStyles({
  adornment: {
    display: 'flex',
  },
  divider: {
    height: 25,
    marginRight: 10,
  },
});

const CommissionInput = (props) => {
  const {
    onChange,
    onTypeChange,
    value,
    label,
    placeholder,
    variant,
    onBlur,
    onFocus,
    error,
    helperText,

    commissionType,
    disabled,
    onClick,
  } = props;

  const classes = useStyles();

  const [ownValue, setValue] = useState(value || '');
  const [ownType, setType] = useState(commissionType);

  useEffect(() => {
    setValue(value);
  }, [
    value,
  ]);

  useEffect(() => {
    setType(commissionType);
  }, [
    commissionType,
  ]);

  const handleValueChange = useCallback((input) => {
    const inputValue = input?.target?.value ? input.target.value : input;
    setValue(Number(inputValue));
    onChange(Number(inputValue));
  }, [
    onChange,
  ]);

  const handleCommissionTypeChange = useCallback((event) => {
    setType(event.target.value);
    onTypeChange(event.target.value);
  }, [
    onTypeChange,
  ]);

  const TypeSelect = (
    <InputAdornment className={classes.adornment} position="end">
      <Divider className={classes.divider} orientation="vertical" />
      <Select
        value={ownType}
        onChange={handleCommissionTypeChange}
        disableUnderline
      >
        <MenuItem value={1}>%</MenuItem>
        <MenuItem value={2}>$</MenuItem>
      </Select>
    </InputAdornment>
  );

  if (variant === 'inline') {
    return (
      <Flex alignItems="center">
        <Textfield
          disabled={disabled}
          value={ownValue}
          type="number"
          onChange={handleValueChange}
          placeholder={placeholder}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{
            width: 100,
          }}
        />
        {TypeSelect}
      </Flex>
    );
  }

  return (
    <FormTextfield
      variant={variant}
      label={label}
      placeholder={placeholder}
      type="number"
      value={ownValue}
      onChange={handleValueChange}
      onFocus={onFocus}
      onBlur={onBlur}
      endAdornment={TypeSelect}
      error={error}
      helperText={helperText}
      step={0.1}
    />
  );
};

CommissionInput.defaultProps = {
  value: null,
  label: null,
  variant: undefined,
  placeholder: null,
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  onClick: () => {},
  error: false,
  helperText: '',
  commissionType: 1,
};

const {
  func,
  string,
  number,
  oneOf,
  oneOfType,
  bool,
} = PropTypes;

CommissionInput.propTypes = {
  onChange: func.isRequired,
  onTypeChange: func.isRequired,
  onClick: func,
  value: oneOfType([string, number]),
  label: string,
  placeholder: string,
  variant: oneOf(['inline', undefined]),
  onBlur: func,
  onFocus: func,
  error: bool,
  helperText: string,
  commissionType: oneOf([1, 2]),
  disabled: bool,
};

const decoratorProps = {
  iconName: 'user-tie',
};

export default withFieldDecorator(decoratorProps)(withInlineLabel(CommissionInput));
