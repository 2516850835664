import apiConnector, { endpoints } from 'api';

const createDirectChat = async () => {
  const endpoint = endpoints.client.chat.general;

  const result = await apiConnector.client.post(endpoint, { chatType: 1 });
  return result;
};

export default createDirectChat;
