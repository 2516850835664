import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeNewProspectModal,
} from 'store/actions/newRealtorProspectModal';

import NewProspectModal from './NewProspectModal';

const mapStateToProps = (state) => {
  const {
    realtorNewProspectModal: {
      isVisible,
      leadId,
    },
  } = state;

  const lead = selectors.realtorLeads.byId(state, leadId);
  const contact = selectors.realtorContacts.byId(state, lead.contact_id);

  const contactName = `${contact.first_name} ${contact.last_name}`;

  return {
    isVisible,
    title: `Great job! Turn ${contactName} (lead) into ${contactName} (client buyer)?`,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeNewProspectModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProspectModal);
