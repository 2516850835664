import apiConnector, { endpoints } from 'api';

const overwriteRelations = (relationType) => (id) => async (data) => {
  const endpoint = `${endpoints.client.realtor.propertyRelations}/${relationType}/${id}`;

  try {
    await apiConnector.client.put(endpoint, data);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default overwriteRelations;
