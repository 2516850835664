import requests from 'api/requests';

import setFulfillment from './setFulfillment';

const getFulfillmentById = (fulfillmentId) => async (dispatch) => { // eslint-disable-line consistent-return
  try {
    const response = await requests.fulfillments.getById(fulfillmentId);
    dispatch(setFulfillment(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentById;
