import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socket from 'shared/sockets/OASocket';
import {
  updateMeeting,
  createDesiredOutcome,
  updateDesiredOutcome,
  setActiveMeeting,
} from 'store/actions/video-chat/index';

const useEditMeetingData = () => {
  const dispatch = useDispatch();
  const activeMeeting = useSelector((state) => state.videoChat.activeMeeting);

  useEffect(() => {
    const onCallServiceMessage = (data) => {
      if (data.activeMeeting) {
        dispatch(setActiveMeeting(data.activeMeeting));
      }
    };

    socket.on('call-service-message', onCallServiceMessage);

    return () => {
      socket.off('call-service-message', onCallServiceMessage);
    };
  }, [dispatch]);

  const addDesiredOutcome = useCallback((payload) => {
    const { id } = activeMeeting;

    const action = createDesiredOutcome({
      meetingId: id,
      payload,
    });

    dispatch(action);
  }, [activeMeeting, dispatch]);

  const toggleDesiredOutcome = useCallback((id) => {
    const parsedId = Number(id);

    const desiredOutcome = activeMeeting.desiredOutcomes.find((item) => (
      item.id === parsedId
    ));

    const action = updateDesiredOutcome({
      meetingId: activeMeeting.id,
      id: parsedId,
      payload: {
        isCompleted: !desiredOutcome.isCompleted,
      },
    });

    dispatch(action);
  }, [activeMeeting, dispatch]);

  const changeMeetingNotes = useCallback((notes) => {
    const { id } = activeMeeting;

    const action = updateMeeting({
      id,
      payload: {
        notes,
      },
    });

    dispatch(action);
  }, [activeMeeting, dispatch]);

  return {
    addDesiredOutcome,
    toggleDesiredOutcome,
    changeMeetingNotes,
  };
};

export default useEditMeetingData;
