import {
  SET_PROPERTY_REQUIREMENT,
} from 'store/actionTypes/property-requirements';

const setPropertyRequirement = (requirement) => ({
  type: SET_PROPERTY_REQUIREMENT,
  payload: requirement,
});

export default setPropertyRequirement;
