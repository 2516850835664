import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useContactDefaultEmail = (
  contactId,
) => {
  const emails = useReselect(selectors.realtorContacts.contactEmails, contactId);

  const primaryEmail = emails.find((item) => item.is_primary);

  return primaryEmail?.email || emails[0]?.email;
};

export default useContactDefaultEmail;
