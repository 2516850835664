import { createSelector } from 'reselect';

import selectors from 'store/selectors';

import { // eslint-disable-line import/no-cycle
  optimizedOpportunityActions,
} from 'store/actions/realtorOpportunities';

const lanes = {
  new: {
    name: 'New Clients/Prospects',
    getSubheading: (summary) => `${summary.new || 0} ${+summary.new !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: (list = [], summary) => +summary.new > list.length,
  },
  viewings: {
    name: 'Showings',
    getSubheading: (summary) => `${summary.at_viewings || 0} ${+summary.at_viewings !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: (list = [], summary) => +summary.at_viewings > list.length,
  },
  offers: {
    name: 'Offers',
    getSubheading: (summary) => `${summary.at_offers || 0} ${+summary.at_offers !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: (list = [], summary) => +summary.at_offers > list.length,
  },
  deals: {
    name: 'Deals Agreed',
    getSubheading: (summary) => `${summary.at_deals || 0} ${+summary.at_deals !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: (list = [], summary) => +summary.at_deals > list.length,
  },
};

const laneOrder = [
  'new',
  'viewings',
  'offers',
  'deals',
];

export default createSelector(
  (state) => state.realtorOpportunities.opportunities,
  (state) => state.realtorOpportunities.realtorOpportunitiesByFilter,
  (state) => selectors.realtorOpportunities.overallSummary(state),
  (opportunities, realtorOpportunitiesByFilter, summary) => laneOrder.map((id) => {
    const {
      name,
      getSubheading,
      getListHasMore,
    } = lanes[id];

    const filter = selectors.realtorOpportunities.filter(null, {
      stage: id,
    });

    const list = selectors.realtorOpportunities.list(
      {
        realtorOpportunities: {
          opportunities,
          realtorOpportunitiesByFilter,
        },
      },
      filter.filterId,
    );

    const laneData = {
      name,
      id,
      list,
      subheading: getSubheading
        ? getSubheading(summary)
        : null,
      listHasMore: getListHasMore
        ? getListHasMore(list, summary)
        : false,
      loadMore: () => {
        optimizedOpportunityActions.getOpportunitiesForFilter(
          filter.filterId,
          filter.filter,
          list.length,
        );
      },
    };

    return laneData;
  }),
);
