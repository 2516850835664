import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import { searchContact } from 'store/actions/realtorContacts';

import { isEmpty } from 'shared/utility';

// import {
  // Avatar,
  // Chip,
// } from '@material-ui/core';

import Textfield from 'ui/Textfield';
import Chip from 'ui/Chip';
import SearchResult from './SearchResult';

import {
  Wrapper,
  SearchContactArea,
} from './styledItems';

const SearchContactInput = (props) => {
  const {
    handleChange,
    selectedContact,
    handleDelete,
    label,
    textfieldProps,
    onInput: onInputProps,
    inputValue: inputValueProps,
    contactExtraInfo,
    chipVariant,
    searchAreaPlacement,
    searchAreaBorder,
    searchResultsDependentSearchAreaVisibility,
    fullWidth,
    chipStyle,
    InputComponent,
  } = props;

  const {
    first_name,
    last_name,
    email_primary,
    phone_primary,
  } = selectedContact;

  const searchInputRef = useRef(null);
  const foundContactsRef = useRef(null);

  const [searchedContacts, setSearchedContacts] = useState([]);

  const [isSearchAreaVisible, setSearchAreaVisibility] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    onSearch(inputValueProps);
  }, [
    inputValueProps,
  ]);

  const handleClickOutside = useCallback((event) => {
    const isSearchInputClick = searchInputRef.current ? (
      searchInputRef.current.input === event.target
    ) : (
      false
    );

    const isFoundContactsClick = foundContactsRef.current ? (
      foundContactsRef.current.contains(event.target)
    ) : (
      false
    );

    if (
      !isSearchInputClick
      && !isFoundContactsClick
    ) {
      setSearchAreaVisibility(false);
    }
  }, []);

  const onSearchInputFocus = useCallback(() => {
    setSearchAreaVisibility(true);
  }, []);

  const onSearch = useDebouncedCallback(async (value) => {
    if (!value) {
      setSearchedContacts([]);

      return;
    }

    const contacts = await searchContact(value)();

    setSearchedContacts(contacts.map((item) => item.id));
  }, 800);

  const onInput = useCallback((value) => {
    if (onInputProps) {
      onInputProps(value);
    } else {
      onSearch(value);
    }
  }, [
    onSearch,
    onInputProps,
  ]);

  const onContactClick = useCallback((contactId) => () => {
    handleChange(contactId);
  }, [handleChange]);

  const getExtra = () => {
    let result = '';
    if (contactExtraInfo.includes('email') && email_primary) {
      result = `${result} | ${email_primary}`;
    }
    if (contactExtraInfo.includes('phone') && phone_primary) {
      result = `${result} | ${phone_primary}`;
    }

    return result.trim();
  };

  const InputField = InputComponent || Textfield;

  return (
    <Wrapper fullWidth={fullWidth}>
      {
        isEmpty(selectedContact)
          ? (
            <>
              <InputField
                label={label}
                onChange={onInput}
                onFocus={onSearchInputFocus}
                inputRef={searchInputRef}
                {...textfieldProps}
              />
              {
                (searchResultsDependentSearchAreaVisibility ? !isEmpty(searchedContacts) : isSearchAreaVisible) && (
                  <SearchContactArea
                    ref={foundContactsRef}
                    placement={searchAreaPlacement}
                    border={searchAreaBorder}
                  >
                    <SearchResult
                      searchedContacts={searchedContacts}
                      onContactClick={onContactClick}
                      contactExtraInfo={contactExtraInfo}
                    />
                  </SearchContactArea>
                )
              }
            </>
          )
          : (
            <Chip
              onDelete={handleDelete}
              variant={chipVariant}
              style={chipStyle}
            >
              {`${first_name} ${last_name} ${getExtra()}`}
            </Chip>
          )
      }
    </Wrapper>
  );
};

SearchContactInput.defaultProps = {
  label: 'Search existing contacts',
  contactExtraInfo: ['email'],
  chipVariant: 'subtle',
  onInput: null,
  inputValue: null,
  searchAreaPlacement: 'bottom',
  searchAreaBorder: false,
  searchResultsDependentSearchAreaVisibility: false,
  fullWidth: false,
  chipStyle: {},
};

const {
  func,
  shape,
  string,
  arrayOf,
  oneOf,
  bool,
} = PropTypes;

SearchContactInput.propTypes = {
  handleChange: func.isRequired,
  handleDelete: func.isRequired,
  selectedContact: shape({
    first_name: string,
    last_name: string,
    email_primary: string,
  }).isRequired,
  label: string,
  contactExtraInfo: arrayOf(oneOf(['email', 'phone'])),
  chipVariant: oneOf(['focus', 'subtle']),
  onInput: func,
  inputValue: string,
  searchAreaPlacement: oneOf(['top', 'right', 'bottom', 'left']),
  searchAreaBorder: bool,
  searchResultsDependentSearchAreaVisibility: bool,
  fullWidth: bool,
  chipStyle: shape({}),
};

const decoratorProps = {
  iconName: 'user-tie',
};

export default withFieldDecorator(decoratorProps)(SearchContactInput);
