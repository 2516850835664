import { connect } from 'react-redux';

import { setNewContactLogFormField } from 'store/actions/newRealtorContactLogForm';

import NewLogForm from './NewLogForm';

const mapStateToProps = (state) => {
  const {
    realtorNewContactLogForm: {
      eventDate,
      propertyAddress,
      type,
      propertyStatus,
      notes,
    },
  } = state;

  return {
    eventDate,
    propertyAddress,
    type,
    propertyStatus,
    notes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (field) => (value) => {
    dispatch(setNewContactLogFormField(field)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLogForm);
