import apiConnector, { endpoints } from 'api';

const createFromExisting = (fulfillmentId) => async (fulfillmentData) => {
  const endpoint = `${endpoints.client.fulfillmentFromExisting}/${fulfillmentId}`;
  try {
    const result = await apiConnector.client.post(endpoint, fulfillmentData);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default createFromExisting;
