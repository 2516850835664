import requests from 'api/requests';

import setProperty from './setProperty';

const getPropertyById = (propertyId) => async (dispatch) => {
  try {
    const response = await requests.realtor.properties.getById(propertyId);
    dispatch(setProperty(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getPropertyById;
