import apiConnector, { endpoints } from 'api';

const create = (callSid) => async ({
  contactId,
  callerPhoneNumber,
  caleePhoneNumber,
}) => {
  const endpoint = endpoints.client.calls.callRecordings;
  try {
    const result = await apiConnector.client.post(endpoint,
      {
        callSid,
        action: 'start',
        contactId,
        callerPhoneNumber,
        caleePhoneNumber,
      });

    return result.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
