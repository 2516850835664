export const SET_CANCELLED_ORDER = 'SET_CANCELLED_ORDER';
export const SET_CANCELLED_ORDERS = 'SET_CANCELLED_ORDERS';
export const SET_SELECTED_CANCELLED_ORDER = 'SET_SELECTED_CANCELLED_ORDER';
export const SET_CANCELLED_ORDER_ATTACHMENTS = 'SET_CANCELLED_ORDER_ATTACHMENTS';

export default {
  SET_CANCELLED_ORDER,
  SET_CANCELLED_ORDERS,
  SET_SELECTED_CANCELLED_ORDER,
  SET_CANCELLED_ORDER_ATTACHMENTS,
};
