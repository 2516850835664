import connectReducers from 'store/connectReducers';

// eslint-disable-next-line import/no-cycle
import {
  GET_CUSTOMER_TICKET_BY_URL_SUCCESS,
  GET_CUSTOMER_TICKET_COMMENTS_SUCCESS,
  GET_TICKET_STATUS_LIST_SUCCESS,
  TOKEN_TO_STORE,
  TICKET_TOKEN_TO_STORE,
} from 'store/actions/external-store';

// eslint-disable-next-line import/no-cycle
import { SET_TICKET_DATA } from 'store/actions/tickets-list-view';

const initialState = {
  ticketFromUrl: null,
  commentsList: [],
  status: null,
  ticketStatusList: null,
  token: null,
};

const reducers = {
  [GET_CUSTOMER_TICKET_BY_URL_SUCCESS]: (state, action) => ({
    ...state,
    ticketFromUrl: action.ticket,
  }),

  [GET_CUSTOMER_TICKET_COMMENTS_SUCCESS]: (state, action) => ({
    ...state,
    commentsList: action.comments,
  }),

  [SET_TICKET_DATA]: (state, action) => ({ ...state, status: action.status }),

  [GET_TICKET_STATUS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    ticketStatusList: action.statuses,
  }),

  [TOKEN_TO_STORE]: (state, action) => ({ ...state, token: action.token }),

  [TICKET_TOKEN_TO_STORE]: (state, action) => ({ ...state, token: action.token }),
};

function externalStore(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default externalStore;
