import React from 'react';

import AttachmentsUpload from 'shared/inputs/InlineAttachmentsUpload';

import withAddendumChangeAttachments from 'sections/Transactions/modules/AddendumChanges/hocs/withAddendumChangeAttachments/index';

const DocumentRequestAttachments = withAddendumChangeAttachments(AttachmentsUpload);

export default (props) => (
  <DocumentRequestAttachments
    objectId={props.requestId}
    multiple
    itemSize="m"
  />
);
