import products from './products';
import sourceTypes from './sourceTypes';
import sources from './sources';
import companies from './companies';
import contacts from './contacts';
import leads from './leads';

export default {
  products,
  sourceTypes,
  sources,
  companies,
  contacts,
  leads,
};
