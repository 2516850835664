import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getContactsForFilter from './getContactsForFilter';

const getContactsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.crm.contactsFilter(getState());

  try {
    await dispatch(getContactsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getContactsForStoreFilter;
