import connectReducers from 'store/connectReducers';

import {
  SET_EMAIL,
  SET_EMAILS,
} from 'store/actionTypes/emails';

const initialState = {
  emails: {},
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_EMAIL]: (state, action) => ({
    ...state,
    emails: {
      ...state.emails,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_EMAILS]: (state, action) => ({
    ...state,
    emails: {
      ...state.emails,
      ...listToObject(action.payload),
    },
  }),

};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
