/* eslint-disable */
//TODO add prop types
import React, { useEffect, useCallback, useState } from 'react';
import { Alert } from 'antd';
import moment from 'moment';
import classnames from 'classnames';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import logo from 'assets/icons/realtor-logo.svg'

import './Logo.scss';

const Logo = (props) => {
  const {
    getPlannedSystemMaintenance,
    activeTab,
    plannedSystemMaintenance,
  } = props;

  const [windowWidth, setWindowWidth] = useState(null);

  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [
    handleWindowResize,
  ]);

  const isSmallScreen = windowWidth < 500;

  const withText = activeTab === 2;

  useEffect(() => {
    getPlannedSystemMaintenance();
  }, [getPlannedSystemMaintenance]);

  return (
    <div className={
      classnames(
        'logo',
        {
          'logo--with-text': withText,
        },
      )
    }
    >
      <div >
        <Icon
          type="custom"
          name="realtorLogo"
          size={isSmallScreen ? 300 : 450}
        />
      </div>

      {
        plannedSystemMaintenance.length
          ? (
            <Alert
              className="system-maintenance-information"
              message={
                <Typography
                  variant="h3"
                  weight={500}
                  tag="span"
                >
                  Scheduled Maintenance
                </Typography>
              }
              description={
                <Typography
                  color="ghost"
                >
                  We’ll be doing some scheduled system maintenance on
                  {moment(plannedSystemMaintenance[0].startTime).utc().format('MMM Do h:m a')} UTC
                  and expect this to take {plannedSystemMaintenance[0].hours}
                  hours {plannedSystemMaintenance[0].minutes} min.
                  support@symplete.com - (805) 309 2548.
                </Typography>
              }
              type="info"
              showIcon
            />
          )
          : null
      }
    </div>
  );
};

export default Logo;
