import {
  SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorInspections';

const setInspectionsForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setInspectionsForFilterCount;
