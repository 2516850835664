import styled from 'styled-components';

import Typography from 'ui/Typography';

export const Root = styled.div`
  display: flex;
  height: 100%;
  align-items: ${(props) => (props.alignItems || 'baseline')};
`;

export const Label = styled(Typography)`
  margin-right: 5px;
`;

export default {
  Root,
  Label,
};
