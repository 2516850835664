import styled from 'styled-components';
import Video from 'oa/components/Video/index';

export const RootWrapper = styled.div`
  width: 135px;
  background-color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.2)
`;

export const ControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px 7px;
  cursor: default;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 116px;
  border-radius: 8px;
  border: solid 3px #2ecc71;
  overflow: hidden;
`;

export const MainVideo = styled(Video)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Placeholder = styled.h2`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  font-weight: 300;
  line-height: 1.2;
  color: rgba(0, 25, 52, 0.87);
`;

export const PlaceholderBottom = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.85;
  text-align: center;
  color: #34495e;
`;

export default {
  RootWrapper,
  ControlsWrapper,
};
