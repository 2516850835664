import { createSelector } from 'reselect';

import moment from 'moment';

import { getUserOptimized } from 'store/actions/users';
import { optimizedCrmProductsActions } from 'store/actions/crm/crmProducts';
import { optimizedSalesTeamsActions } from 'store/actions/salesTeams';
import { optimizedMyCompanyInformationActions } from 'store/actions/MyCompany/information';

const dateFormat = 'MMMM, DD YYYY';
const timeFormat = 'hh:mm A';

const selectedLead = (state) => state.crmLeads.selectedLead;
const leads = (state) => state.crmLeads.leads;
const changelog = (state) => state.crmLeads.changelog;
const employees = (state) => state.users.employee;
const storeProducts = (state) => state.crmProducts.products;
const salesTeams = (state) => state.salesTeams.teams;
const companyOffices = (state) => state.myCompany.offices;

const temps = {
  1: 'cold',
  2: 'warm',
  3: 'hot',
};

const statuses = {
  1: 'new',
  2: 'repitch',
  3: 'customer',
  4: 'archived',
  5: 'not interested',
};

const descriptions = {
  created: () => 'created lead',

  title: (newValue, oldValue) => `changed lead title from <b>${oldValue}</b> to <b>${newValue}</b>`,

  temp: (newValue, oldValue) => (oldValue
    ? `changed lead temp from <b>${oldValue}</b> to <b>${newValue}</b>`
    : `set lead temp to <b>${newValue}</b>`),

  status: (newValue, oldValue) => {
    if (newValue === 'archived') {
      return 'archived lead';
    }

    if (oldValue === 'archived') {
      return `unarchived lead and set status to <b>${newValue}</b>`;
    }

    return `set lead status from <b>${oldValue}</b> to <b>${newValue}<b/>`;
  },

  claimed_by: (newValue, oldValue) => {
    if (!oldValue) {
      return 'claimed lead';
    }

    if (!newValue) {
      return 'unclaimed lead';
    }

    return null;
  },

  'permission granted': (newValue) => {
    if (newValue) {
      if (newValue.type === 'employee') {
        return `granted permission to <b>${newValue.name}</b>`;
      }
      if (newValue.type === 'team') {
        return `granted permission to everyone on team <b>${newValue.name}</b>`;
      }
      if (newValue.type === 'office') {
        return `granted permission to everyone in <b>${newValue.name}</b> office`;
      }
    }

    return null;
  },

  'permission revoked': (newValue) => {
    if (newValue) {
      if (newValue.type === 'employee') {
        return `revoked permission from <b>${newValue.name}</b>`;
      }
      if (newValue.type === 'team') {
        return `revoked permission from team <b>${newValue.name}</b>`;
      }
      if (newValue.type === 'office') {
        return `revoked permission from <b>${newValue.name}</b> office`;
      }
    }

    return null;
  },

  permission_type: (newValue) => {
    if (newValue === '1') {
      return 'gave permission to everyone';
    }
    if (newValue === '2') {
      return 'restricted common permissions for this lead';
    }

    return null;
  },

  'owner assigned': (newValue) => {
    if (newValue.isSameUser) return 'assigned <b>themself</b> as lead owner';

    return `assigned <b>${newValue.userName}</b> as lead owner`;
  },

  'owner removed': (newValue) => {
    if (newValue.isSameUser) return 'gave up lead ownership';

    return `revoked lead ownership from <b>${newValue.userName}</b>`;
  },

  'product added': (newValue) => `added <b>${newValue.name}</b> as product with the value of <b>${newValue.value} ${newValue.currency}</b>`, // eslint-disable-line max-len

  'product value': (newValue, oldValue) => `changed value of assigned product <b>${newValue.name}</b> from <b>${oldValue.value}</b> to <b>${newValue.value}</b>`, // eslint-disable-line max-len

  'product currency': (newValue, oldValue) => `changed currency of assigned product <b>${newValue.name}</b> from <b>${oldValue.value}</b> to <b>${newValue.value}</b>`, // eslint-disable-line max-len

  product: (newValue, oldValue) => `changed assigned product <b>${oldValue.name}</b> to <b>${newValue.name}</b>`, // eslint-disable-line max-len
};

const getUserName = (userId, users) => {
  const user = users[userId];

  let userName;

  if (!user) {
    getUserOptimized(1, userId);
  } else {
    userName = `${user.name} ${user.surname}`;
  }

  return userName;
};

const getProductName = (productId, products = {}) => {
  const product = products[productId];

  let productName;

  if (!product) {
    optimizedCrmProductsActions.getProductById(productId);
  } else {
    productName = product.name;
  }

  return productName;
};

const getTeamName = (teamId, teams = []) => {
  const team = teams.find((item) => item.SalesTeamId === teamId);

  let teamName;

  if (!team) {
    optimizedSalesTeamsActions.getTeamList();
  } else {
    teamName = team.Title;
  }

  return teamName;
};

const getOfficeName = (officeId, offices = []) => {
  const office = offices.find((item) => item.OfficeID === officeId);

  let officeName;

  if (!office) {
    optimizedMyCompanyInformationActions.getOffices();
  } else {
    officeName = office.OfficeName;
  }

  return officeName;
};

const valueByType = {
  temp: (value) => temps[value] || value,
  status: (value) => statuses[value] || value,

  'permission granted': (value, extraData, event) => {
    if (value) {
      if (value.type === 1) {
        const isSameUser = value.id === event.done_by_id;

        return {
          name: isSameUser
            ? 'themself'
            : getUserName(value.id, extraData.users),

          type: 'employee',
        };
      }

      if (value.type === 2) {
        return {
          name: getTeamName(value.id, extraData.teams),
          type: 'team',
        };
      }

      if (value.type === 3) {
        return {
          name: getOfficeName(value.id, extraData.offices),
          type: 'office',
        };
      }
    }

    return null;
  },

  'permission revoked': (value, extraData, event) => {
    if (value) {
      if (value.type === 1) {
        const isSameUser = value.id === event.done_by_id;

        return {
          name: isSameUser
            ? 'themself'
            : getUserName(value.id, extraData.users),
          type: 'employee',
        };
      }

      if (value.type === 2) {
        return {
          name: getTeamName(value.id, extraData.teams),
          type: 'team',
        };
      }

      if (value.type === 3) {
        return {
          name: getOfficeName(value.id, extraData.offices),
          type: 'office',
        };
      }
    }

    return null;
  },

  'owner assigned': (value, extraData, event) => {
    const isSameUser = value?.id === event.done_by_id;
    const userName = value && !isSameUser && getUserName(value.id, extraData.users);

    return {
      isSameUser,
      userName,
    };
  },

  'owner removed': (value, extraData, event) => {
    const isSameUser = value?.id === event.done_by_id;
    const userName = value && !isSameUser && getUserName(value.id, extraData.users);

    return {
      isSameUser,
      userName,
    };
  },

  'product added': (value, extraData) => {
    if (value) {
      const productName = getProductName(value.product_id, extraData.products);

      return {
        name: productName,
        value: value.value,
        currency: value.currency,
      };
    }

    return null;
  },

  'product value': (value, extraData) => {
    if (value) {
      const productName = getProductName(value.product_id, extraData.products);

      return {
        name: productName,
        value: value.value,
      };
    }

    return null;
  },

  'product currency': (value, extraData) => {
    if (value) {
      const productName = getProductName(value.product_id, extraData.products);

      return {
        name: productName,
        value: value.value,
      };
    }

    return null;
  },

  product: (value, extraData) => {
    if (value) {
      const productName = getProductName(value.product_id, extraData.products);

      return {
        name: productName,
      };
    }

    return null;
  },
};

const formatValueForEventType = (type) => (value, extraData, event) => {
  if (valueByType[type]) return valueByType[type](value, extraData, event);

  return value;
};

const formatEvents = (events, extraData) => events.reduce((acc, event) => {
  if (!descriptions[event.event_type]) {
    return acc;
  }

  const description = descriptions[event.event_type](
    formatValueForEventType(event.event_type)(event.new_value, extraData, event),
    formatValueForEventType(event.event_type)(event.old_value, extraData, event),
  );

  if (!description) return acc;

  const date = moment(event.created_at).format(dateFormat);
  const time = moment(event.created_at).format(timeFormat);
  const name = getUserName(event.done_by_id, extraData.users);

  acc.push({
    date,
    time,
    name,
    description,
  });

  return acc;
}, []);

const groupEventsByDate = (events) => {
  const dates = events.reduce((acc, item) => {
    acc.push(item.date);

    return [...new Set(acc)];
  }, []);

  const groupedEvents = dates.map((date) => ({
    date,
    events: events.filter((event) => event.date === date),
  }));

  return groupedEvents;
};

export default createSelector(
  selectedLead,
  leads,
  changelog,
  employees,
  storeProducts,
  salesTeams,
  companyOffices,

  (leadId, leadsDict, changelogItems = {}, users, products, teams, offices) => {
    if (!leadId || !leadsDict || !leadsDict[leadId]) {
      return [];
    }

    const lead = leadsDict[leadId];

    const created = {
      event_type: 'created',
      created_at: lead.created_at,
      done_by_id: lead.created_by,
    };

    const leadEvents = changelogItems[leadId]
      ? [
        ...changelogItems[leadId],
        created,
      ]
      : [created];

    const formattedEvents = formatEvents(
      leadEvents,
      {
        users,
        products,
        teams,
        offices,
      },
    );
    const groupedEvents = groupEventsByDate(formattedEvents);

    return groupedEvents;
  },
);
