import apiConnector, { endpoints } from 'api';

/**
 * Sends POST request with payload to create "desired outcome".
 * @param {Object} params - The request params.
 * @param {number} params.meetingId - The id of parent meeting.
 * @param {Object} params.payload - The fields of desired outcome.
 * @param {string} params.payload.name - The name of desired outcome.
 * @param {boolean} params.payload.isCompleted - The status of desired outcome.
 * @returns {Promise} A response from server.
 */

const createDesiredOutcome = async ({ meetingId, payload }) => {
  const endpoint = endpoints.OA.meetingDesiredOutcomesPath(meetingId);
  let response;

  try {
    response = await apiConnector.OA.post(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default createDesiredOutcome;
