import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './UserAvatarArray.scss';

class UserAvatarArray extends Component {
  onClick = ({ index }) => () => {
    const {
      onClick: propsOnClick,
    } = this.props;

    propsOnClick({ index });
  }

  getOffset = () => {
    const {
      children,
      defaultOffset,
      offsetItem,
    } = this.props;

    if (!children || children.length === 0) {
      return defaultOffset;
    }

    const size = children[0].props.avatarSize || 24;

    return {
      height: size,
      width: defaultOffset + offsetItem * children.length,
    };
  }

  render() {
    const {
      children,
      className,
      classNameItem,
      defaultOffset,
      offsetItem,
    } = this.props;

    const offset = this.getOffset();
    const rootStyle = {
      width: offset.width,
      height: offset.height + 4,
    };

    return (
      <div
        className={classnames('user_avatar_array-root', className)}
        style={rootStyle}
      >
        {React.Children.map(children, (child, index) => (
          <div
            style={{
              position: 'absolute',
              zIndex: (100 + index),
              right: (defaultOffset + offsetItem * index),
              transform: 'translate(-50%, -50%)',
              top: '50%',
            }}
            className={classnames({
              [classNameItem]: classNameItem,
            })}
          >
            {React.cloneElement(child, { onClick: this.onClick({ index }) })}
          </div>
        ))}
      </div>
    );
  }
}

export default UserAvatarArray;

UserAvatarArray.defaultProps = {
  children: null,
  className: null,
  classNameItem: '',
  defaultOffset: 0,
  offsetItem: 10,
  onClick: () => {},
};

const {
  arrayOf,
  element,
  func,
  number,
  string,
} = PropTypes;

UserAvatarArray.propTypes = {
  children: arrayOf(element),
  className: string,
  classNameItem: string,
  defaultOffset: number,
  offsetItem: number,
  onClick: func,
};
