import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import requests from 'api/requests';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import CallsContext from 'sections/Calls/context/CallsContext';

import { formatPhoneNumber } from 'shared/utils/phoneNumbers';

const PhoneNumber = (props) => {
  const {
    phoneNumber,
  } = props;

  const {
    setSelectedPhone,
    selectedPhone,
    getActivePhones,
  } = useContext(CallsContext);

  const isSelected = useMemo(() => selectedPhone === phoneNumber, [
    selectedPhone,
    phoneNumber,
  ]);

  const selectPhone = useCallback(() => {
    setSelectedPhone(phoneNumber);
  }, [
    phoneNumber,
    setSelectedPhone,
  ]);

  const removePhone = useCallback(async () => {
    await requests.calls.activePhones.remove(phoneNumber);
    getActivePhones();
  }, [
    phoneNumber,
    getActivePhones,
  ]);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      fullWidth
    >
      <Flex spacing={1} alignItems="baseline">
        <Typography
          variant="title2"
          tag="p"
          noMargin
        >
          {formatPhoneNumber(phoneNumber)}
        </Typography>
        {
          isSelected && (
            <Typography
              variant="label"
              color="focus"
              noMargin
            >
              default
            </Typography>
          )
        }
      </Flex>

      <Flex spacing={1}>
        {
          !isSelected && (
            <Button
              variant="secondary"
              onClick={selectPhone}
            >
              Set as default
            </Button>
          )
        }
        <Button
          variant="secondary"
          icon="trash"
          onClick={removePhone}
        />
      </Flex>
    </Flex>
  );
};

const {
  string,
} = PropTypes;

PhoneNumber.propTypes = {
  phoneNumber: string.isRequired,
};

export default PhoneNumber;
