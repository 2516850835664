import {
  SET_OFFER_ATTACHMENTS,
} from 'store/actionTypes/realtorOffers';

const setOfferAttachments = (id, attachments) => ({
  type: SET_OFFER_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setOfferAttachments;
