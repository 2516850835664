import React from 'react';
import PropTypes from 'prop-types';

import TasksTutorial from './TasksTutorial';
import TicketsTutorial from './TicketsTutorial';
import SalesTutorial from './SalesTutorial';
import HrTutorial from './HrTutorial';
import GeneralTutorial from './GeneralTutorial';

import './Tutorials.scss';

import tutorialRoutes from './tutorialRoutes';
import handleUrl from './handleUrl';
import filterTutorials from './shared/utils/filterTutorials';

let currentTutorial = '';
const addTutorialName = (name) => {
  currentTutorial = name;
};

const Tutorials = (props) => {
  const {
    location: {
      pathname,
    },
    isVisible,
    showTutorialLayer,
    appTour,
    setAvailableTutorials,
    history,
    appTourStep,
    availableTutorials,
  } = props;

  const handleTutorial = {
    tasks: () => {
      addTutorialName('tasks');
      return <TasksTutorial />;
    },
    'customer-service': () => {
      addTutorialName('tickets');
      return <TicketsTutorial />;
    },
    sales: () => {
      addTutorialName('sales');
      return <SalesTutorial />;
    },
    team: () => {
      if (currentTutorial === 'general') {
        return <GeneralTutorial />;
      }
      addTutorialName('hr');
      return <HrTutorial />;
    },
    'company-settings': () => {
      if (currentTutorial === 'hr') {
        return <HrTutorial />;
      }
      if (currentTutorial === 'tickets') {
        return <TicketsTutorial />;
      }
      addTutorialName('general');
      return <GeneralTutorial />;
    },
  };

  const renderByUrl = () => {
    let component = null;
    for (const section in handleUrl) { // eslint-disable-line no-unused-vars
      if (handleUrl[section][pathname]) {
        showTutorialLayer();
        addTutorialName(section);
        const step = handleUrl[section][pathname];
        component = handleUrl[section].returnComponent(step.name);
      }
    }
    return component;
  };

  if (appTour && !isVisible) {
    const availableTutorials = filterTutorials(); // eslint-disable-line no-shadow
    setAvailableTutorials(availableTutorials);
    showTutorialLayer();
    history.push(tutorialRoutes[availableTutorials[0]].root);
    const current = availableTutorials[appTourStep];
    return handleUrl[current].returnComponent();
  }

  if (isVisible) {
    if (appTour) {
      const current = availableTutorials[appTourStep];
      return handleUrl[current].returnComponent();
    }
    const section = pathname.split('/')[1];
    if (handleTutorial[section]) {
      return handleTutorial[section]();
    }
  }

  const comp = renderByUrl();
  if (comp) return comp;

  addTutorialName('');
  return null;
};

const {
  any,
  objectOf,
  func,
  shape,
  string,
  bool,
} = PropTypes;

Tutorials.defaultProps = {
  appTour: false,
};

Tutorials.propTypes = {
  location: shape({
    pathname: string,
  }).isRequired,
  isVisible: bool.isRequired,
  showTutorialLayer: func.isRequired,
  setAvailableTutorials: func.isRequired,
  appTour: bool,
  history: objectOf(any), // eslint-disable-line react/forbid-prop-types
};

export default Tutorials;
