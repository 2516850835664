import {
  SET_REALTOR_DIRECTORY_USER_PROFILE,
} from 'store/actionTypes/realtorDirectory';

const setUserProfile = (profile) => ({
  type: SET_REALTOR_DIRECTORY_USER_PROFILE,
  payload: profile,
});

export default setUserProfile;
