import create from './create';
import getById from './getById';
import get from './get';
import update from './update';
import deleteRepairRequest from './deleteRepairRequest';

export default {
  create,
  getById,
  get,
  update,
  deleteRepairRequest,
};
