import {
  SET_PHONE_NUMBER,
} from 'store/actionTypes/phoneNumbers';

const set = (payload) => ({
  type: SET_PHONE_NUMBER,
  payload,
});

export default set;
