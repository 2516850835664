import React from 'react';

import PropTypes from 'prop-types';

// import useContact from 'sections/Contacts/hooks/useContact';

import Flex from 'ui/Flex';
import Divider from 'ui/Divider';

import ContactInfoConcise from 'sections/Contacts/modules/ContactInfoConcise';
import SocialLinks from 'sections/Contacts/modules/SocialLinks';

import BuyerLead from './modules/BuyerLead';
import SellerLead from './modules/SellerLead';
import ClientBuyer from './modules/ClientBuyer';
import ClientSeller from './modules/ClientSeller';

import EmptyPlaceholder from './EmptyPlaceholder';

const SidePanel = (props) => {
  const {
    contactId,
    contactType,
    objectId,
  } = props;

  const avatarProps = {
    label: contactType,
  };

  return (
    <Flex
      spacing={2}
      flexDirection="column"
    >
      {
        contactId
        ? (

          <>
            <ContactInfoConcise
              contactId={contactId}
              avatarProps={avatarProps}
            />

            <Divider />

            <SocialLinks contactId={contactId} />

            {
                (() => {
                  switch (contactType) {
                    case 'seller':
                      return <SellerLead leadId={objectId} />;
                    case 'buyer':
                      return <BuyerLead leadId={objectId} />;
                    case 'client buyer':
                      return <ClientBuyer prospectId={objectId} />;
                    case 'client seller':
                      return <ClientSeller clientId={objectId} />;
                    default:
                      return null; // Contact timeline
                  }
                })()
              }
          </>
          )
        : (
          <EmptyPlaceholder />
          )

      }
    </Flex>
  );
};

SidePanel.defaultProps = {
  objectId: null,
};

const {
  number,
  string,
} = PropTypes;

SidePanel.propTypes = {
  contactId: number.isRequired,
  contactType: string.isRequired,
  objectId: number,
};

export default SidePanel;
