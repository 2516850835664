import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getCreditNotes from './get';
import getCreditNoteById from './getById';
import getCreditNoteAttachments from './getAttachments';

import createCreditNote from './create';

import setSelectedCreditNote from './set';

import updateCreditNote from './update';

export { getCreditNotes };
export { getCreditNoteById };
export { getCreditNoteAttachments };

export { createCreditNote };

export { setSelectedCreditNote };

export { updateCreditNote };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCreditNotesActions = {
  getCreditNoteById: memoizeDebounce(
    (id) => store.dispatch(getCreditNoteById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
