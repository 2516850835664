import connectReducers from 'store/connectReducers';
import { groupBy } from 'shared/utility';

import {
  SET_PROPERTY,
  SET_PROPERTIES,
  SET_SELECTED_PROPERTY,
  SET_PROPERTY_ATTACHMENTS,
  SET_SELECTED_PROPERTY_ROWS,
  SET_PROPERTIES_FOR_FILTER,
  SET_REALTOR_OVERALL_PROPERTIES_SUMMARY,
  SET_PROPERTIES_FOR_FILTER_COUNT,
} from 'store/actionTypes/properties';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  properties: {},
  overallPropertiesSummary: null,
  selectedProperty: null,
  selectedPropertyRows: [],
  propertiesByFilter: {},
  propertiesByFilterCount: {},
};

const reducers = {
  [SET_REALTOR_OVERALL_PROPERTIES_SUMMARY]: (state, action) => ({
    ...state,
    overallPropertiesSummary: action.payload,
  }),

  [SET_PROPERTY]: (state, action) => ({
    ...state,
    properties: {
      ...state.properties,
      [action.payload.id]: {
        ...action.payload,
        attachments: state.properties[action.payload.id]?.attachments,
      },
    },
  }),

  [SET_PROPERTIES]: (state, action) => ({
    ...state,
    properties: {
      ...state.properties,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_SELECTED_PROPERTY]: (state, action) => ({
    ...state,
    selectedProperty: action.payload,
  }),

  [SET_PROPERTY_ATTACHMENTS]: (state, action) => ({
    ...state,
    properties: {
      ...state.properties,
      [action.payload.id]: {
        ...state.properties[action.payload.id],
        attachments: groupBy(action.payload.attachments, 'attachmentType'),
      },
    },
  }),

  [SET_SELECTED_PROPERTY_ROWS]: (state, action) => ({
    ...state,
    selectedPropertyRows: action.payload,
  }),

  [SET_PROPERTIES_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      properties,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(properties),
    ]);

    const updatedState = {
      ...state,
      propertiesByFilter: {
        ...state.propertiesByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_PROPERTIES_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    propertiesByFilterCount: {
      ...state.propertiesByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
