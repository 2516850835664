export const SET_NEW_REALTOR_CLIENT_MODAL_OPEN = 'SET_NEW_REALTOR_CLIENT_MODAL_OPEN';
export const SET_NEW_REALTOR_CLIENT_MODAL_CLOSED = 'SET_NEW_REALTOR_CLIENT_MODAL_CLOSED';

export const SET_NEW_REALTOR_CLIENT_MODAL_PROPERTY = 'SET_NEW_REALTOR_CLIENT_MODAL_PROPERTY';
export const SET_NEW_REALTOR_CLIENT_MODAL_ATTACHMENTS = 'SET_NEW_REALTOR_CLIENT_MODAL_ATTACHMENTS';

export const SET_REALTOR_CLIENT = 'SET_REALTOR_CLIENT';
export const SET_REALTOR_CLIENTS = 'SET_REALTOR_CLIENTS';
export const SET_REALTOR_SELECTED_CLIENT = 'SET_REALTOR_SELECTED_CLIENT';
export const SET_REALTOR_CLIENT_SELECTED_VIEW = 'SET_REALTOR_CLIENT_SELECTED_VIEW';

export const SET_REALTOR_CLIENTS_FILTER_ITEM = 'SET_REALTOR_CLIENTS_FILTER_ITEM';
export const RESET_REALTOR_CLIENTS_FILTER_ITEM = 'RESET_REALTOR_CLIENTS_FILTER_ITEM';
export const CLEAR_REALTOR_CLIENTS_FILTER = 'CLEAR_REALTOR_CLIENTS_FILTER';

export const SET_REALTOR_CLIENTS_FOR_FILTER = 'SET_REALTOR_CLIENTS_FOR_FILTER';
export const SET_REALTOR_CLIENTS_FOR_FILTER_COUNT = 'SET_REALTOR_CLIENTS_FOR_FILTER_COUNT';

export const SET_REALTOR_CLIENTS_OVERALL_SUMMARY = 'SET_REALTOR_CLIENTS_OVERALL_SUMMARY';

export const SET_REALTOR_CLIENT_MATCHING_BUYERS = 'SET_REALTOR_CLIENT_MATCHING_BUYERS';
