import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.realtorContactsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      sort_by: filter.sortBy,
      sort_direction: filter.sortDirection,
      status: filter.status,
      search: filter.search,
      dealRole: filter.dealRole,
    },
  }),
);
