import requests from 'api/requests';

import setTasksCountForEmployee from './setTasksCountForEmployee';
import setTasks from './setTasks';
import setTasksForEmployee from './setTasksForEmployee';

const getEmployeeTasks = (employeeId) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const tasksResponse = await requests.tasks.getTasks({
      offset,
      limit,
      assignedEmployeesIds: [employeeId],
    });

    dispatch(setTasksCountForEmployee(employeeId)(tasksResponse.data.tasksCount));
    dispatch(setTasks(tasksResponse.data.tasks));
    dispatch(setTasksForEmployee(employeeId)(tasksResponse.data.tasks));
  } catch (error) {
    console.error(error);
  }
};

export default getEmployeeTasks;
