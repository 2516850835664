import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withTransactionPropertyData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      transactionId,
    } = ownProps;

    let propertyId;

    const transaction = selectors.realtorTransactions.byId(state, transactionId);

    if (transaction.realtor_property_id) {
      propertyId = transaction.realtor_property_id;
    } else if (transaction.realtor_client_id) {
      const client = selectors.realtorClients.byId(state, transaction.realtor_client_id);
      propertyId = client?.realtor_property_id;
    }

    const property = selectors.properties.byId(state, propertyId);

    return {
      propertyId,
      property,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withTransactionPropertyData;
