import { connect } from 'react-redux';

import SearchPropertyInput from 'shared/inputs/SearchPropertyInput';

const mapStateToProps = (state, ownProps) => {
  const {
    selectedPropertyId,
    label,
  } = ownProps;

  return {
    selectedPropertyId,
    label,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (propertyId) => {
    ownProps.handleChange(propertyId);
  },

  handleDelete: () => {
    ownProps.handleChange(null);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPropertyInput);
