import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

export default createSelector(
  (state) => state.properties.properties,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedPropertyActions.getPropertyById(id);
      }

      return { };
    }

    return {
      active_matches: item?.active_matches,
      total_viewings: item?.viewings_summary?.total_viewings,
      upcoming_viewings: item?.viewings_summary?.upcoming_viewings,
      active_offers: item?.active_offers,
    };
  },
);
