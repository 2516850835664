import { apiConnector } from '../../shared/API';
import { countPercent } from '../../shared/utils/license';

export const OPEN_PAYNENT_MODAL = 'OPEN_PAYNENT_MODAL';
export const opentPaymentModalAction = (data) => ({
  type: OPEN_PAYNENT_MODAL,
  payload: data,
});

export const OPEN_PAYNENT_MODAL_SUCCESS = 'OPEN_PAYNENT_MODAL_SUCCESS';
export const opentPaymentModalSuccessAction = (data) => ({
  type: OPEN_PAYNENT_MODAL_SUCCESS,
  payload: data,
});

export const CLOSE_PAYNENT_MODAL = 'CLOSE_PAYNENT_MODAL';
export const closePaymentModalAction = () => ({
  type: CLOSE_PAYNENT_MODAL,
});

export const opentPaymentModal = (data) => (dispatch) => {
  dispatch(opentPaymentModalAction(data));
  dispatch(opentPaymentModalSuccessAction({}));
};


export const OPEN_BILLING_DATE_MODAL = 'OPEN_BILLING_DATE_MODAL';
export const opentBillingDateModalAction = (data) => ({
  type: OPEN_BILLING_DATE_MODAL,
  payload: data,
});

export const OPEN_BILLING_DATE_MODAL_SUCCESS = 'OPEN_BILLING_DATE_MODAL_SUCCESS';
export const opentBillingDateModalSuccessAction = (data) => ({
  type: OPEN_BILLING_DATE_MODAL_SUCCESS,
  payload: data,
});

export const CLOSE_BILLING_DATE_MODAL = 'CLOSE_BILLING_DATE_MODAL';
export const closeBillingDateModalAction = () => ({
  type: CLOSE_BILLING_DATE_MODAL,
});

export const opentBillingDateModal = (data) => (dispatch) => {
  dispatch(opentBillingDateModalAction(data));
  apiConnector('/api/company/billing-date', 'GET').then((res) => {
    if (res) {
      dispatch(opentBillingDateModalSuccessAction(res));
    }
  });
};

export const updateBillingDate = (data) => (dispatch) => {
  apiConnector('/api/company/billing-date', 'PATCH', { BillingDate: data }).then((res) => {
    if (res) {
      dispatch(closeBillingDateModalAction(res));
    }
  });
};


export const OPEN_CONTACTS_MODAL = 'OPEN_CONTACTS_MODAL';
export const openContactsModalAction = (data) => ({
  type: OPEN_CONTACTS_MODAL,
  payload: data,
});

export const OPEN_CONTACTS_MODAL_SUCCESS = 'OPEN_CONTACTS_MODAL_SUCCESS';
export const openContactsModalSuccessAction = (data) => ({
  type: OPEN_CONTACTS_MODAL_SUCCESS,
  payload: data,
});

export const CLOSE_CONTACTS_MODAL = 'CLOSE_CONTACTS_MODAL';
export const closeContactsModalAction = () => ({
  type: CLOSE_CONTACTS_MODAL,
});

export const opentContactsModal = (data) => (dispatch) => {
  dispatch(openContactsModalAction(data));
  dispatch(openContactsModalSuccessAction({}));
};


export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const updtateContactSuccessAction = (data) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: data,
});

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const updataContactAction = () => ({
  type: UPDATE_CONTACT,
});

export const updateContacts = (data) => (dispatch) => {
  dispatch(updataContactAction());
  apiConnector('/api/company/billing-contact', 'POST', data).then((res) => {
    if (res) {
      dispatch(updtateContactSuccessAction(data));
    }
  });
};


export const GET_CONTACT = 'GET_CONTACT';
export const getContactAction = () => ({
  type: GET_CONTACT,
});

export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const getContactSuccessAction = (data) => ({
  type: GET_CONTACT_SUCCESS,
  payload: data,
});

export const getContacts = () => (dispatch) => {
  dispatch(getContactAction());
  apiConnector('/api/company/billing-contact', 'GET').then((res) => {
    if (res) {
      dispatch(getContactSuccessAction(res));
    }
  });
};


export const OPEN_CARD_MODAL = 'OPEN_CARD_MODAL';
export const openCardModalAction = (data) => ({
  type: OPEN_CARD_MODAL,
  payload: data,
});

export const OPEN_CARD_MODAL_SUCCESS = 'OPEN_CARD_MODAL_SUCCESS';
export const openCardModalSuccessAction = (data) => ({
  type: OPEN_CARD_MODAL_SUCCESS,
  payload: data,
});

export const CLOSE_CARD_MODAL = 'CLOSE_CARD_MODAL';
export const closeCardModalAction = () => ({
  type: CLOSE_CARD_MODAL,
});

export const opentCardModal = (data) => (dispatch) => {
  dispatch(openCardModalAction(data));
  dispatch(openCardModalSuccessAction({}));
};

export const updateCard = (data) => (dispatch) => {
  // let res = await apiConnector(``, 'GET');
  // if (res) {
  dispatch(closeCardModalAction());
  // }
};

export const GET_NEXT_PAYMENT = 'GET_NEXT_PAYMENT';
export const getNextPaymentAction = (data) => ({
  type: GET_NEXT_PAYMENT,
  payload: data,
});

export const getNextPayment = (data) => (dispatch) => {
  apiConnector('/api/company/nextPayment', 'GET').then((res) => {
    if (res) {
      dispatch(getNextPaymentAction(res));
    }
  });
};


export const GET_LAST_PAYMENT = 'GET_LAST_PAYMENT';
export const getLastPaymentAction = (data) => ({
  type: GET_LAST_PAYMENT,
  payload: data,
});

export const getLastPayment = (data) => (dispatch) => {
  apiConnector('/api/company/lastPayment', 'GET').then((res) => {
    if (res) {
      dispatch(getLastPaymentAction(res));
    }
  });
};
