import apiConnector, { endpoints } from 'api';

/**
 * Sends PATCH request with payload to update "desired outcome".
 * @param {Object} params - The request params.
 * @param {number} params.id - The id of desired outcome.
 * @param {number} params.meetingId - The id of parent meeting.
 * @param {Object} params.payload - The fields of desired outcome.
 * @param {string} params.payload.name - The name of desired outcome.
 * @param {boolean} params.payload.isCompleted - The status of desired outcome.
 * @returns {Promise} A response from server.
 */

const updateDesiredOutcome = async ({ meetingId, id, payload }) => {
  const endpoint = endpoints.OA.meetingDesiredOutcomePath(meetingId, id);
  let response;

  try {
    response = await apiConnector.OA.patch(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default updateDesiredOutcome;
