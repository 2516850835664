import {
  SET_REALTOR_SELECTED_OFFER,
} from 'store/actionTypes/realtorOffers';

const setSelectedOffer = (offerId) => ({
  type: SET_REALTOR_SELECTED_OFFER,
  payload: offerId,
});

export default setSelectedOffer;
