import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
} from 'antd';


const ArticleControls = (props) => {
  const {
    onSubmit,
    onCancel,
    richTextEditor,
  } = props;

  return (
    <div className="article-action-buttons">
      {
        !richTextEditor && (
          <>
            <Button
              htmlType="submit"
              onClick={onCancel}
              type="link"
              style={{ color: 'rgba(0, 25, 52, 0.87)' }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              onClick={onSubmit}
              type="link"
            >
              Confirm
            </Button>
          </>
        )
      }
    </div>
  );
};

const {
  func,
  bool,
} = PropTypes;

ArticleControls.defaultProps = {
  richTextEditor: false,
};

ArticleControls.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  richTextEditor: bool,
};

export default ArticleControls;
