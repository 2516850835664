import requests from 'api/requests';

import {
  getLineItemById,
} from 'store/actions/line-items';

import set from './set';

const getById = (id) => async (dispatch) => {
  try {
    const response = await requests.warehouse.getPackageById(id);

    try {
      const { lineItems } = response.data;

      lineItems.forEach((itemId) => {
        dispatch(getLineItemById(itemId));
      });
    } catch (error) {
      console.error(error);
    }

    dispatch(set(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getById;
