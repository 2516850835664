import apiConnector, { endpoints } from 'api';

const importCalendar = async ({ tokenId }) => {
  const endpoint = `${endpoints.client.integrations.calendar}/import`;
  console.log(`called importCalendar for endpoint ${endpoint}`);

  try {
    const result = await apiConnector.client.post(endpoint, { tokenId });
    console.log('gotten result from importCalendar: ', result);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default importCalendar;
