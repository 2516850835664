import requests from 'api/requests';

import moment from 'moment';

import setPropertiesObjects from './setPropertiesObjects';

const getPropertiesObjects = () => async (dispatch) => {
  try {
    const response = await requests.realtor.prioritiesObjects.get({
      date_from: moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
      date_to: moment().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
    });

    dispatch(setPropertiesObjects(response.data.items));
  } catch (error) {
    console.error(error);
  }
};

export default getPropertiesObjects;
