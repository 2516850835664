import React from 'react';
import YouTube from 'react-youtube';

const OverviewVideo = (props) => {
  const opts = {
    height: '321',
    width: '571',
  };

  return (
    <YouTube
      videoId="q4GPyAtTzt4"
      opts={opts}
    />
  );
};

export default OverviewVideo;
