import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Node } from '@tiptap/core';

import {
  ReactNodeViewRenderer,
  NodeViewWrapper,
} from '@tiptap/react';

const style = {
  display: 'inline-block',
  padding: '0 8px',
  backgroundColor: '#376bed',
  color: 'white',
  borderRadius: 8,
};

// TODO For some reason the node is wiped when you start typing after it is inserted
const TemplateItem = (props) => {
  const {
    node,
    editor,
  } = props;

  const [content, setContent] = useState(node?.content?.content?.[0]?.text);

  useEffect(() => {
    editor
      .chain()
      .insertContent(' ')
      .run();
  }, [
    editor,
  ]);

  return (
    <NodeViewWrapper
      as="span"
      data-drag-handle
      draggable
      contentEditable={false}
    >
      <span
        style={style}
        contentEditable={false}
      >
        {content}
      </span>
    </NodeViewWrapper>
  );
};

const {
  func,
  shape,
} = PropTypes;

TemplateItem.propTypes = {
  getPos: func.isRequired,
  node: shape().isRequired,
  editor: shape().isRequired,
};

const TemplateItemNodeReact = Node.create({
  name: 'templateItemReact',
  draggable: true,
  atom: true,
  inline: true,
  group: 'inline',
  content: 'text*',

  renderHTML(...renderParams) {
    const content = renderParams?.[0]?.node?.content?.content?.[0]?.text;

    return ['symplete-template-item-react', { 'data-content': content }, `{{${content}}}`];
  },

  parseHTML() {
    return [
      {
        tag: 'symplete-template-item-react',
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TemplateItem);
  },
});

export default TemplateItemNodeReact;
