import createSource from 'store/actions/crm/crmSources/createSource';

const createSourceFromRealtorNewContactForm = () => async (dispatch, getState) => {
  const {
    realtorNewContactForm,
  } = getState();

  const sourceData = {
    type: realtorNewContactForm.sourceType,
    description: realtorNewContactForm.sourceDescription,
  };

  try {
    const sourceId = await dispatch(createSource(sourceData));
    return sourceId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createSourceFromRealtorNewContactForm;
