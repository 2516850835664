import {
  useState,
  useEffect,
  useCallback,
} from 'react';

const useLocalStorageItem = (key) => {
  const [value, setValue] = useState(localStorage.getItem(key));

  const onEvent = useCallback(() => {
    setValue(localStorage.getItem(key));
  }, [key]);

  useEffect(() => {
    window.addEventListener('localStorageUpdate', onEvent);

    return () => {
      window.removeEventListener('localStorageUpdate', onEvent);
    };
  }, [onEvent]);

  return value;
};

export default useLocalStorageItem;
