import { connect } from 'react-redux';

import SalePriceInput from 'shared/inputs/SalePriceInput';

const mapStateToProps = (state) => ({
  label: 'Listed sale price',
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SalePriceInput);
