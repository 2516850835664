import { createSelector } from 'reselect';

import getLineItem from './byId';

export default createSelector(
  (state) => state,
  (state, items) => items,
  (
    state,
    items,
  ) => items.every(({ line_item_id }) => {
    const lineItem = getLineItem(state, line_item_id);

    return lineItem.process_status === getLineItem(state, items[0].line_item_id).process_status;
  }),
);
