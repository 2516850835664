import requests from 'api/requests';

import setProcesses from './setProcesses';

const get = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.serviceProcesses.get({
      offset,
      limit,
    });

    dispatch(setProcesses(response.data.serviceProcesses));
  } catch (error) {
    console.error(error);
  }
};

export default get;
