import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Summary from './Summary';

const mapStateToProps = (state) => {
  const summary = selectors.realtorOffers.overallSummary(state);

  return {
    need_review: summary?.need_review || 0,
    expiring_today: summary?.expiring_today || 0,
    expiring_this_week: summary?.expiring_this_week || 0,
  };
};

export default connect(mapStateToProps, null)(Summary);
