import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { REALTOR_OFFER_TYPES } from 'constants/realtor.constants';

import OfferOverview from './OfferOverview';

const mapStateToProps = (state, ownProps) => {
  const { offerId } = ownProps;

  const counter = selectors.realtorOffers.byId(state, offerId);

  let contactId;
  let agentId;
  let contactRole;
  let agentRole;

  if (counter?.realtor_client_id) {
    contactId = counter?.buyer_contact_id;
    agentId = counter?.buyer_agent_contact_id;
    contactRole = 'Buyer';
    agentRole = 'Buyer Agent';
  } else {
    contactId = counter?.seller_contact_id;
    agentId = counter?.seller_agent_contact_id;
    contactRole = 'Seller';
    agentRole = 'Seller Agent';
  }

  return {
    offerId,
    userRole: REALTOR_OFFER_TYPES[counter.type],
    contactId,
    agentId,
    contactRole,
    agentRole,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferOverview);
