import {
  SET_NEW_REALTOR_INSPECTION_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorInspections';

const setNewInspectionFormAttachments = (filesList = []) => ({
  type: SET_NEW_REALTOR_INSPECTION_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setNewInspectionFormAttachments;
