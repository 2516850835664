import React, {
  useEffect,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import Elevation from 'ui/Elevation';
import KPIItem from 'ui/KPIItem';
import KPIItemInteractive from 'ui/KPIItemInteractive';

import {
  IndicatorWrapper,
} from './styledItems';

const KPIBar = (props) => {
  const {
    indicators,
    values,
    IndicatorComponent,
    getData,
    onClick,

    interactive,
  } = props;

  useEffect(() => {
    if (getData) {
      getData();
    }
  }, [
    getData,
  ]);

  if (interactive) {
    return (
      <Flex
        alignItems="stretch"
        justifyContent="stretch"
        spacing={2}
        fullWidth
      >
        {
          indicators.map((item) => {
            const {
              key,
              CustomComponent,
            } = item;

            const Content = CustomComponent || IndicatorComponent || KPIItemInteractive;

            return (
              <Fragment key={key}>
                <Content
                  value={values[key]}
                  {...item} // eslint-disable-line react/jsx-props-no-spreading
                  onClick={onClick(key)}
                />
              </Fragment>
            );
          })
        }
      </Flex>
    );
  }

  return (
    <Elevation
      rounded="m"
    >
      <Flex alignItems="stretch">
        {
          indicators.map((item) => {
            const {
              key,
              flex,
              CustomComponent,
            } = item;

            const Content = CustomComponent || IndicatorComponent || KPIItem;

            return (
              <IndicatorWrapper
                key={key}
                flex={flex}
              >
                <Content
                  value={values[key]}
                  {...item} // eslint-disable-line react/jsx-props-no-spreading
                />
              </IndicatorWrapper>
            );
          })
        }
      </Flex>
    </Elevation>
  );
};

KPIBar.defaultProps = {
  indicators: [],
  getData: null,
  interactive: false,
  onClick: () => {},
};

const {
  arrayOf,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
  bool,
} = PropTypes;

KPIBar.propTypes = {
  indicators: arrayOf(shape({
    key: string,
    title: string,
    iconName: string,
    iconType: string,
    iconColor: string,
    flex: number,
  })),
  values: objectOf(arrayOf(shape({
    name: string,
    value: oneOfType([string, number]),
    textColor: string,
  }))).isRequired,
  getData: func,
  interactive: bool,
  onClick: func,
};

export default KPIBar;
