import requests from 'api/requests';

import setProducts from './setProducts';

const getProducts = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.crm.products.get({
      offset,
      limit,
    });

    dispatch(setProducts(response.data.products));
  } catch (error) {
    console.error(error);
  }
};

export default getProducts;
