import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Typography from 'ui/Typography';

import MainTransactionInfo from './MainTransactionInfo';

import {
  StyledCardElevation,
  Root,
  CardWrapper,
  ObjectNameTag,
} from './styledItems';

const TransactionCard = (props) => {
  const {
    transactionId,
    fullCardInformation,
  } = props;

  const history = useHistory();

  const onCardClick = useCallback(() => {
    history.push(`/transactions/${transactionId}`);
  }, [transactionId]);

  return (
    <CardWrapper onClick={onCardClick}>
      {
        fullCardInformation && (
          <ObjectNameTag>
            <Typography
              variant="subtitle2"
              color="main-text-inversed"
              className="object-name"
              weight={400}
              noMargin
            >
              TRANSACTION
            </Typography>
          </ObjectNameTag>
        )
      }

      <StyledCardElevation showObjectName={fullCardInformation}>
        <Root>
          <MainTransactionInfo
            transactionId={transactionId}
            fullCardInformation={fullCardInformation}
          />
        </Root>
      </StyledCardElevation>
    </CardWrapper>
  );
};

TransactionCard.defaultProps = {
  fullCardInformation: false,
};

const {
  number,
  bool,
} = PropTypes;

TransactionCard.propTypes = {
  transactionId: number.isRequired,
  fullCardInformation: bool,
};

export default TransactionCard;
