import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import NewRepairRequestModal from './NewRepairRequestModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      newRepairRequestModal,
    },
  } = state;

  const isVisible = newRepairRequestModal?.isVisible;

  const contentOptions = newRepairRequestModal?.contentOptions;

  return {
    isVisible,
    contentOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal('newRepairRequestModal')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRepairRequestModal);
