import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { isEmpty } from 'shared/utility';

import { Popconfirm } from 'antd';

import Typography from 'ui/Typography';
import Grid from 'ui/Grid';
import EditableArea from 'ui/EditableArea';
import Textfield from 'ui/Textfield';
import Button from 'ui/Button';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactInfo from 'sections/Contacts/modules/ContactInfo';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import ContactAvatar from './ContactAvatar';
import Property from './Property';

const noop = () => {};

const containerStyle = {
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
};

const DayViewCalendarItem = (props) => {
  const {
    sectionTitle,
    contacts,
    dateTime,
    propertyId,
    contingencyTitle,
    notes,
    onChange,
    onDelete,
    showDeleteButton,
  } = props;

  return (
    <Grid
      container
      spacing={4}
      style={containerStyle}
    >
      {
        sectionTitle && (
          <Grid item xs={12}>
            <Typography
              variant="title1"
              style={{ marginTop: 10 }}
            >
              {sectionTitle}
            </Typography>
          </Grid>
        )
      }

      {
        !isEmpty(contacts) && (
          <Grid
            item
            xs={12}
          >
            <DecoratedFieldWrapper
              iconType="feather"
              iconName="bookmark"
              variant="focus"
              align="flex-start"
            >
              <Typography variant="title2">Related To</Typography>
              {
                contacts.map(({ id, label }) => (
                  <Fragment key={id}>
                    <ContactInfo
                      contactId={id}
                      editable={false}
                      short
                      avatar={(<ContactAvatar contactId={id} size="sm" round />)}
                      nameTypographyProps={{
                        variant: 'title2',
                        weight: 400,
                      }}
                      label={label?.text}
                      labelProps={label?.variant ? { variant: label.variant } : {}}
                    />
                  </Fragment>
                ))
              }
            </DecoratedFieldWrapper>
          </Grid>
        )
      }

      <Grid
        item
        xs={12}
      >
        <DecoratedFieldWrapper
          iconType="feather"
          iconName="clock"
          variant="focus"
          align="flex-start"
        >
          <Typography variant="title2">Date & Time</Typography>
          <EditableArea
            getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
              <DateTimeInput
                value={dateTime}
                disablePast
                handleChange={onChange('dateTime')}
                onBlur={onBlur}
                label="Date & Time"
              />
            )}
          >
            <Typography noMargin>
              {
                moment(dateTime).calendar(null, {
                  sameElse: 'MM/DD/YYYY, h:mm A',
                })
              }
            </Typography>
          </EditableArea>
        </DecoratedFieldWrapper>
      </Grid>

      {
        (propertyId && !contingencyTitle) && (
          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconType="feather"
              iconName="home"
              variant="focus"
              align="flex-start"
            >
              <Typography variant="title2">Property</Typography>
              <Property propertyId={propertyId} />
            </DecoratedFieldWrapper>
          </Grid>
        )
      }

      {
        (propertyId && contingencyTitle) && (
          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconType="feather"
              iconName="info"
              variant="focus"
              align="flex-start"
            >
              <Typography variant="title2">Details</Typography>

              <Typography variant="title2">{contingencyTitle}</Typography>
              <Property propertyId={propertyId} />
            </DecoratedFieldWrapper>
          </Grid>
        )
      }

      {
        (notes || notes === '') && (
          <Grid
            item
            xs={12}
          >
            <DecoratedFieldWrapper
              iconType="fontawesome"
              iconName="thumbtack"
              variant="focus"
              align="flex-start"
            >
              <Typography variant="title2">Notes</Typography>
              <EditableArea
                fullWidth
                getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
                  <Textfield
                    value={notes}
                    onChange={onChange('notes')}
                    onBlur={onBlur}
                    fullWidth
                    focusOnRender
                    label="Notes"
                    textarea
                  />
                )}
              >
                <Typography>
                  {notes || '—'}
                </Typography>
              </EditableArea>
            </DecoratedFieldWrapper>
          </Grid>
        )
      }

      {
        showDeleteButton && (
          <Grid item xs={12}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                variant="secondary"
                onClick={noop}
              >
                Delete
              </Button>
            </Popconfirm>
          </Grid>
        )
      }
    </Grid>
  );
};

DayViewCalendarItem.defaultProps = {
  sectionTitle: null,
  contactIds: [],
  propertyId: null,
  notes: null,
  contingencyTitle: null,
  onChange: () => () => {},
  onDelete: () => {},
  showDeleteButton: true,
};

const {
  number,
  string,
  func,
  bool,
  arrayOf,
  shape,
} = PropTypes;

DayViewCalendarItem.propTypes = {
  sectionTitle: string,
  contacts: arrayOf(shape({
    id: number.isRequired,
    label: shape({
      text: string.isRequired,
      variant: string,
    }),
  })),
  propertyId: number,
  notes: string,
  contingencyTitle: string,
  onChange: func,
  onDelete: func,
  showDeleteButton: bool,
  dateTime: string.isRequired,
};

export default DayViewCalendarItem;
