import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedWarehouseActions,
} from 'store/actions/warehouse';

const laneNames = {
  create_order_pickup: 'New Orders',
  in_progress: 'In Progress',
  shipped: 'Shipped',
};

export default createSelector(
  (state, lane) => lane,
  (state) => state.warehouse.warehouseKanban,
  (
    lane,
    warehouseKanban,
  ) => {
    const laneData = {
      id: lane,
      name: laneNames[lane],
      list: warehouseKanban[lane].fulfillmentList.map((id) => ({
        id,
        incompletedSteps: [],
      })),
      listHasMore: warehouseKanban[lane].fulfillmentList.length < warehouseKanban[lane].count,
      loadMore: () => {
        optimizedWarehouseActions.getWarehouseKanbanLaneFulfillments(
          warehouseKanban[lane].fulfillmentList.length,
          50,
          lane,
        );
      },
    };

    return laneData;
  },
);
