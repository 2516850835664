import { connect } from 'react-redux';
import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updatePropertyViewing,
  deletePropertyViewing,
} from 'store/actions/propertyViewings';

import DayViewCalendarItem from 'sections/Calendar/components/DayViewCalendarItem';

const fieldsToUpdate = {
  dateTime: 'date_time',
  notes: 'notes',
};

const mapStateToProps = (state, ownProps) => {
  const {
    viewingId,
    objectType,
  } = ownProps;

  const viewing = selectors.propertyViewings.byId(state, viewingId);

  const viewingContacts = viewing.contacts || [];

  const contacts = viewingContacts.map((contact) => ({
    id: contact?.id || contact,
  }));

  return {
    dateTime: viewing.date_time,
    sectionTitle: objectType === 'listingPitches' ? 'Listing Pitch' : 'Property Viewing',
    contacts,
    propertyId: viewing.property_id,
    notes: viewing.notes || '',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    const field = fieldsToUpdate[key];

    dispatch(updatePropertyViewing(ownProps.viewingId)({
      [field]: value,
    }));
  }, 1000),
  onDelete: async () => {
    await dispatch(deletePropertyViewing(ownProps.viewingId));

    if (ownProps.onAfterDelete) {
      ownProps.onAfterDelete(ownProps.objectType);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DayViewCalendarItem);
