import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import TabLabel from 'shared/components/UI/TabLabel';

const Label = (props) => (
  <TabLabel {...props} variant="h3">
    {props.children}
  </TabLabel>
);

const RequestItemsTabs = (props) => {
  const {
    newItems,
    outstandingItems,
    respondedItems,
  } = props;

  const [tab, setTab] = useState('new');
  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <TabContext value={tab}>
      <Tabs
        variant="fullWidth"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab
          label={<Label isSelected={tab === 'new'}>New</Label>}
          value="new"
        />
        <Tab
          label={(
            <Label isSelected={tab === 'outstanding'}>
              Outstanding
            </Label>
          )}
          value="outstanding"
        />
        <Tab
          label={(
            <Label isSelected={tab === 'responded'}>
              Responded
            </Label>
          )}
          value="responded"
        />
      </Tabs>

      <TabPanel value="new" index={0}>
        {newItems}
      </TabPanel>
      <TabPanel value="outstanding" index={1}>
        {outstandingItems}
      </TabPanel>
      <TabPanel value="responded" index={2}>
        {respondedItems}
      </TabPanel>
    </TabContext>
  );
};

RequestItemsTabs.defaultProps = {
  newItems: null,
  outstandingItems: null,
  respondedItems: null,
};

const {
  arrayOf,
  element,
  oneOfType,
} = PropTypes;

const itemsType = oneOfType([element, arrayOf(element)]);

RequestItemsTabs.propTypes = {
  newItems: itemsType,
  outstandingItems: itemsType,
  respondedItems: itemsType,
};

export default RequestItemsTabs;
