import { createSelector } from 'reselect';

import stableStringify from 'json-stable-stringify';

const contactEmailFilter = (state) => state.newLeadForm.contactEmailFilter;

export default createSelector(
  contactEmailFilter,
  (emailFilter) => {
    const filter = emailFilter
      ? {
        email: emailFilter,
      }
      : null;

    const filterId = filter
      ? stableStringify(filter)
      : null;

    return {
      filter,
      filterId,
    };
  },
);
