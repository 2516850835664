import connectReducers from 'store/connectReducers';

import { groupBy } from 'shared/utility';

import {
  SET_CUSTOMER_PAYMENT,
  SET_CUSTOMER_PAYMENTS,
  SET_SELECTED_CUSTOMER_PAYMENT,
  SET_CUSTOMER_PAYMENT_ATTACHMENTS,
} from 'store/actionTypes/customerPayments';

const initialState = {
  customerPayments: {},
  selectedPayment: null,
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_SELECTED_CUSTOMER_PAYMENT]: (state, action) => ({
    ...state,
    selectedPayment: action.payload,
  }),

  [SET_CUSTOMER_PAYMENT]: (state, action) => ({
    ...state,
    customerPayments: {
      ...state.customerPayments,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_CUSTOMER_PAYMENTS]: (state, action) => ({
    ...state,
    customerPayments: {
      ...state.customerPayments,
      ...listToObject(action.payload),
    },
  }),

  [SET_CUSTOMER_PAYMENT_ATTACHMENTS]: (state, action) => {
    const updatedState = {
      ...state,
      customerPayments: {
        ...state.customerPayments,
        [action.payload.id]: {
          ...state.customerPayments[action.payload.id],
          attachments: groupBy(action.payload.attachments, 'attachmentType'),
        },
      },
    };

    return updatedState;
  },
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
