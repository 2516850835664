import { connect } from 'react-redux';

import {
  setEditPropertyViewingNestedFormField,
} from 'store/actions/editRealtorPropertyViewingForm';

import NewContactArea from './NewContactArea';

const mapStateToProps = (state) => {
  const {
    editPropertyViewingForm: {
      newContact,
    },
  } = state;

  return {
    contact: newContact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInvalidField: (property, value) => {
    dispatch(setEditPropertyViewingNestedFormField('newContactFieldsValidators')(property)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContactArea);
