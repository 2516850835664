import React from 'react';

import PropTypes from 'prop-types';

import Context from 'sections/Integrations/context';

import useIntegrations from 'sections/Integrations/hooks/useIntegrations';

const IntegrationsProvider = (props) => {
  const {
    children,
  } = props;

  const {
    integrations,
    scopes,
    integrationsByScope,
    integrationsByProvider,

    getIntegrations,
    isLoading: integrationsIsLoading,
  } = useIntegrations();

  return (
    <Context.Provider
      value={{
        integrations,
        scopes,
        integrationsByScope,
        integrationsByProvider,

        getIntegrations,
        integrationsIsLoading,
      }}
    >
      { children }
    </Context.Provider>
  );
};

const {
  element,
} = PropTypes;

IntegrationsProvider.propTypes = {
  children: element.isRequired,
};

export default IntegrationsProvider;
