import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  InnerWrapper,
  Title,
} from './styledItems';

const HorizontalDivider = (props) => {
  const {
    text,
    marginTop,
    marginBottom,
  } = props;

  return (
    <Root
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {
        text && (
          <InnerWrapper>
            <Title
              variant="caption"
            >
              {text}
            </Title>
          </InnerWrapper>
        )
      }
    </Root>
  );
};

HorizontalDivider.defaultProps = {
  text: '',
  marginTop: 20,
  marginBottom: 20,
};

const {
  string,
  number,
} = PropTypes;

HorizontalDivider.propTypes = {
  text: string,
  marginTop: number,
  marginBottom: number,
};

export default HorizontalDivider;
