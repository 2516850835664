import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Order from 'ui/Order';

const Prioritization = (props) => {
  const {
    objectTypesItems,
    objectAttributesItems,
    onChange,
  } = props;

  return (
    <>
      <Typography>
        Rank the below work items in order of importance to you.
        This will help schedule your tasks and overall work priorities throughout the day.
      </Typography>

      <Flex
        spacing={4}
        style={{ marginTop: 25 }}
      >
        <div style={{ width: 315 }}>
          <Typography
            variant="title2"
            weight="bold"
            noMargin
            style={{ marginBottom: 10 }}
          >
            By Object
          </Typography>
          <Order
            name="object types prioritization"
            items={objectTypesItems}
            onChange={onChange('object_types_prioritization')}
          />
        </div>

        <div style={{ width: 315 }}>
          <Typography
            variant="title2"
            weight="bold"
            noMargin
            style={{ marginBottom: 10 }}
          >
            By Attribute
          </Typography>
          <Order
            name="object attributes prioritization"
            items={objectAttributesItems}
            onChange={onChange('object_attributes_prioritization')}
          />
        </div>
      </Flex>
    </>
  );
};

const {
  arrayOf,
  shape,
  string,
  func,
} = PropTypes;

Prioritization.propTypes = {
  objectTypesItems: arrayOf(shape({
    key: string.isRequired,
    content: string.isRequired,
  })).isRequired,
  objectAttributesItems: arrayOf(shape({
    key: string.isRequired,
    content: string.isRequired,
  })).isRequired,
  onChange: func.isRequired,
};

export default Prioritization;
