import styled from 'styled-components';

export const ResponseArea = styled.div`
  background : ${(props) => props.theme?.palette?.['background-flat'] || 'cyan'};
  padding: 18px;
  border-radius: 8px;
`;

export default {
  ResponseArea,
};
