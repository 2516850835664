import requests from 'api/requests';

import setInspectionsTypes from './setInspectionsTypes';

const get = () => async (dispatch) => {
  try {
    const response = await requests.realtor.inspectionsTypes.get();

    dispatch(setInspectionsTypes(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default get;
