import styled from 'styled-components';

export const Root = styled.label`
  height: 28px;
  width: 28px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: ${({ theme, value }) => theme?.palette?.[value] || value};
  border: ${(props) => (props.checked ? 'solid 2px #5e80bc' : 'none')};
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
