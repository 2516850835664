/* eslint-disable react/require-default-props */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'oa/components/Button/index';
import ModalContext from '../ModalContext';

const Close = (props) => {
  const {
    className,
  } = props;

  const {
    onClose,
  } = useContext(ModalContext);

  return (
    <Button
      className={classNames('modal-window__close-btn', className)}
      onClick={onClose}
      hideText
    >
      Close modal window
    </Button>
  );
};

Close.propTypes = {
  className: PropTypes.string,
};

export default Close;
