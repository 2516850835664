import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  position: relative; 
`;

export const ItemWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

export default {
  Root,
  ItemWrapper,
};
