import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';

import getById from './getLikeById';

const notify = debounce((action, text) => {
  callAlert[action](text);
}, 1000, {
  leading: true,
});

const updateLike = (likeId) => (updateData) => async (dispatch) => {
  try {
    await requests.likes.update(likeId)(updateData);

    notify('success', 'Updated successfully');

    dispatch(getById(likeId));
  } catch (error) {
    console.error(error);
    notify('error', 'Could not update');
  }
};

export default updateLike;
