import
  {
    capitalize,
    startCase,
    truncate,
  } from 'shared/utility';

export const capitalizeWord = (string) => capitalize(string);

export const capitalizeSentence = (string) => startCase(string);

export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const ellipsize = (string, maxLen = 30) => (

  truncate(string, {
    length: maxLen,
    ommission: '...',
  })

);
