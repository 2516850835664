/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

import NewOfferModal from 'sections/OffersV2/modals/NewOfferModal';

const Options = (props) => {
  const {
    propertyId,
    offerId,
    totalCounters,
    offerData,
  } = props;

  const [viewVisible, setViewVisible] = useState(false);

  const closeModals = () => {
    setViewVisible(false);
  };

  const view = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();
    setViewVisible(true);
    closeMenu();
  });

  return (
    <>
      <OptionsMenu {...props}>
        {
          ({ closeMenu }) => (
            <>
              <MenuItem onClick={view(closeMenu)}>
                View Details
              </MenuItem>
            </>
          )
        }
      </OptionsMenu>

      <NewOfferModal
        isVisible={viewVisible}
        closeModal={closeModals}
        propertyId={propertyId}
        offerId={offerId}
        totalCounters={totalCounters}
        offerData={offerData}
        updateOnly
        hideOptions
      />
    </>
  );
};

Options.defaultProps = {
  propertyId: null,
  offerId: null,
  totalCounters: null,
  offerData: null,
};

const {
  number,
  shape,
} = PropTypes;

Options.propTypes = {
  propertyId: number,
  offerId: number,
  totalCounters: number,
  offerData: shape({}),
};

export default Options;
