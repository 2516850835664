/* eslint-disable max-len */
export default {
  NON_USER: 'Non User',
  ACTIONS_SELECT: [
    {
      label: 'New Employee',
      value: 'NE',
    },
    {
      label: 'Import Employees',
      value: 'IE',
      requiresManagerPermissions: true,
    },
    // TODO disable for BETA time
    // {
    //   label: 'New Time Off Request',
    //   value: 'NTOR'
    // },
    // {
    //   label: 'New Idea',
    //   value: 'NI'
    // },
    {
      label: 'Team Setup',
      value: 'DT',
      requiresManagerPermissions: true,
    },
  ],
  ACTIONS: {
    NE: 'NE',
    IE: 'IE',
    NTOR: 'NTOR',
    NI: 'NI',
    DT: 'DT',
  },
  BUTTON: {
    ACTIONS: 'Actions',
  },
  COUNTERS: [
    {
      key: 'current',
      name: 'Current Employees',
    },
    {
      key: 'available',
      name: 'Available',
    },
    {
      key: 'withoutSympleteLicense',
      name: 'Employees Without Symplete Licenses',
    },
  ],
  COLUMN_WIDTH: 200,
  DO_NOT_IMPORT: {
    name: 'Do Not Import',
    value: null,
  },
  MATCH_HEADER_TEXT:
    'Match your imported file column headings (top row) with Symplete Team employee fields.',
  FILTER_EMPLOYEES: 'Filter Employees',
  FILTER_DRAFT: 'Filter Employee Import Records',
  DELETE_MSG: 'Are you sure? This cannot be undone.',
  IMPORTED: 'Imported',
  DRAFT: 'Draft',
  FINALIZE_IMPORT_INFO: `new employees will be imported. Once confirmed, all new employees will appear in Team, Employees.
  Please note that any duplicate or validation conflicts will not be imported and these will remain draft employees.`,
  DRAFT_VIEW_FILTERS: [
    { value: 'Imported', apiValue: 1 },
    { value: 'Draft', apiValue: 0 },
  ],
  LEAVING_INFO: 'Leaving this page will discard all changes. Are you sure?',
  DEFAULT: {
    EMPLOYEE: '&EmployeeStatusID=1,4',
    DRAFT: '&Imported=0',
    FINALIZE: '&Imported=1',
  },
  DELETE_ERROR_V1:
    'You’ve selected to un-import employees that are less than 24 hours old. They cannot be un-imported, instead, these Imported employees will become Draft employees.',
  DELETE_ERROR_V2:
    'You’ve selected to un-import employees that were either imported more than 24 hours ago or are currently Symplete Users. This cannot be done here.',
  FLAG_ACTIVE_QUERY: '&EmployeeStatusID=8',
  TEAM_MANAGER_MSG: '+ Add Team Manager',
  TEAM_MEMBER_MSG: '+ Add/Edit Team Members',
  HEADERS_LIST: [
    { name: 'Status', value: 'EmployeeStatusID' },
    { name: 'First Name', value: 'Name' },
    { name: 'Middle Name', value: 'MiddleName' },
    { name: 'Last Name', value: 'Surname' },
    { name: 'Work Email', value: 'Email' },
    { name: 'Work Phone', value: 'WorkPhone' },
    { name: 'Personal Skype', value: 'PersonalSkype' },
    { name: 'Personal Descriptions', value: 'PersonalDescription' },
    { name: 'Home Phone', value: 'HomePhone' },
    { name: 'Personal Email', value: 'PersonalEmail' },
    { name: 'Mobile', value: 'Mobile' },
    { name: 'Birthday', value: 'DOB' },
    { name: 'Citizenship', value: 'CitizenshipID' },
    {
      name: 'Social Security Number(SSN)',
      value: 'SSN',
    },
    { name: 'ZIP', value: 'Postcode' },
    { name: 'Address Line 1', value: 'Address1' },
    { name: 'Address Line 2', value: 'Address2' },
    { name: 'City', value: 'City' },
    { name: 'State', value: 'State' },
    { name: 'Country', value: 'Country' },
    { name: 'Role', value: 'Role' },
    { name: 'Department & Team', value: 'DepartmentID' },
    { name: 'Office', value: 'OfficeID' },
    { name: 'Employee ID', value: 'OriginalEmployeeID' },
    { name: 'Role Descriptions', value: 'RoleDescription' },
    {
      name: 'Employment Type',
      value: 'EmploymentTypeID',
    },
    { name: 'Payment Type', value: 'PaymentTypeID' },
    { name: 'Payment Frequency', value: 'PaymentFrequencyID' },
    { name: 'Payment Rate', value: 'Salary' },
    { name: 'Start Date', value: 'StartDate' },
    { name: 'End Date', value: 'LeavingDate' },
    { name: 'Reports To', value: 'ReportsToObject' },
    { name: 'Employee Holidays', value: 'Holidays' },
    { name: 'Employee Benefits', value: 'SumOfBenefits' },
    { name: 'Breaks', value: 'BreakMinutes' },
    { name: 'Breaks Paid', value: 'BreakPaid' },
    { name: 'Overtime', value: 'Overtime' },
    { name: 'Paid Holidays (per year)', value: 'HolidayPaidHour' },
    { name: 'Paid Holidays Starts', value: 'PaidHolidayStarts' },
    { name: 'Paid Sick Leave (per year)', value: 'SickPaidHour' },
    { name: 'Paid Sick Leave Starts', value: 'PaidSickLeaveStarts' },
    { name: 'Time Flexibility', value: 'TimeFlexibility' },
    { name: 'Time Flexibility Minutes', value: 'TimeFlexibilityMin' },
    { name: 'Interviewed By', value: 'interviewedBy' },
    { name: 'Interviewers', value: 'Interviewers' },
    { name: 'Interview Notes', value: 'InterviewNotes' },
    { name: 'Reference Contact Notes', value: 'ReferenceDetails' },
    { name: 'First Name (Emergency)', value: 'NKName' },
    { name: 'Last Name (Emergency)', value: 'NKSurname' },
    { name: 'Relationship To Employee', value: 'NKRelationship' },
    { name: 'Email (Emergency)', value: 'NKEmail' },
    { name: 'Phone (Emergency)', value: 'NKPhone' },
    { name: 'Blood Type', value: 'BloodTypeID' },
    { name: 'First Aid Certified', value: 'FirstAidCertified' },
    { name: 'Fire Certified', value: 'FireCertified' },
    { name: 'Security Certified', value: 'SecurityCertified' },
    { name: 'Evacuation Location', value: 'EvacLocation' },
    { name: 'Special Notes', value: 'MedicalNotes' },
  ],
  MATCH_COUNTERS_PARAMS: '?limit=1&offset=0&Imported=0',
  ADD_DEPARTMENT_MANAGER: 'New department manager successfully added.',
};

export const DEFAULT_ATTENDANCE = {
  RegularHoursOverride: null,
  SickHours: null,
  HolidayHours: null,
  OtherHours: null,
  Notes: null,
};

export const HELP_MSG = 'You will only see employees on this page when they have a start date and hours set for this day in the Employee Details view on the Employees page. If you need further help - let us know!';

export const SAVE_ATTENDANCE_MSG = 'Are you sure you want to update attendance status for all selected employees?';

export const CONFIRM_MSG = 'I’m Sure';

export const DEFAULT_TIME = '00:00:00';

export const NO_DELETED_ON = 'noDeleted=1';

export const NO_DELETED_OFF = 'noDeleted=0';

export const DELETED_STATUS = 8;

export const MA_FILTERS = [
  { key: 'HasUnprocessedHours', name: 'Unprocessed' },
  { key: 'HasRegularHours', name: 'Present' },
  { key: 'HasHolidayHours', name: 'Holiday' },
  { key: 'HasSickHours', name: 'Sick' },
  { key: 'HasOtherHours', name: 'Other' },
];
