import React from 'react';
import PropTypes from 'prop-types';

import ActiveFilters from './ActiveFilters';
import Search from './Search';

const ContactsTableSearchFilter = (props) => {
  const {
    onFilterClick,
  } = props;

  return (
    <div>
      <Search />

      <ActiveFilters />
    </div>
  );
};

ContactsTableSearchFilter.defaultProps = {

};

const {
  func,
} = PropTypes;

ContactsTableSearchFilter.propTypes = {
  onFilterClick: func.isRequired,
};

export default ContactsTableSearchFilter;
