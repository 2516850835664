import React, { Component } from 'react';
import {
  Button,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';

import './RoleModal.scss';

class RoleModal extends Component {
  closeModal = () => {
    const { triggerRoleModal: triggerModal } = this.props;
    triggerModal(false);
  };

  render() {
    const footer = (
      <div className="role-modal__footer">
        <Button
          type="primary"
          onClick={this.closeModal}
        >
          Ok
        </Button>
      </div>
    );

    const { visible, receiveLogout: logout } = this.props;

    return (
      <Modal
        width={360}
        title={null}
        closable={false}
        centered
        footer={footer}
        visible={visible}
        afterClose={logout}
        wrapClassName="role-modal"
      >
        <div className="role-modal__content">
          Your account role has changed. You must login again to apply new permissions.
        </div>
      </Modal>
    );
  }
}

const {
  bool,
  func,
} = PropTypes;

RoleModal.propTypes = {
  triggerRoleModal: func.isRequired,
  visible: bool,
  receiveLogout: func.isRequired,
};

RoleModal.defaultProps = {
  visible: false,
};

export default RoleModal;
