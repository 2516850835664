import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedExternalAccessGeneralActions,
} from 'store/actions/external/general';

export default createSelector(
  (state) => state.externalAccess.issuerUser,
  (state) => state.externalAccess.issuerCompany,
  (user, company) => {
    if (!user || !company) {
      optimizedExternalAccessGeneralActions.getTokenIssuerData();

      return {
        user: {},
        company: {},
      };
    }

    return {
      user,
      company,
    };
  },
);
