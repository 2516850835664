/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Content = (props) => {
  const {
    children,
    className,
  } = props;

  return (
    <section className={classNames('modal-window__content', className)}>
      {children}
    </section>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Content;
