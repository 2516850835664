import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import createSource from './createSource';
import getSourceTypes from './getSourceTypes';
import createSourceType from './createSourceType';
import createSourceFromNewContactForm from './createSourceFromNewContactForm';

import getSourceById from './getSourceById';

export { createSource };
export { getSourceTypes };
export { createSourceType };
export { createSourceFromNewContactForm };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCrmSourcesActions = {
  getSourceTypes: memoizeDebounce(() => store.dispatch(getSourceTypes()), debounceDelay, debounceOptions),
  getSourceById: memoizeDebounce((sourceId) => store.dispatch(getSourceById(sourceId)), debounceDelay, debounceOptions),
};
