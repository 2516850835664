import changeTicketAssignees from './changeTicketAssignees';
import editAgentNote from './editAgentNote';
import getOverallTicketsSummary from './getOverallTicketsSummary';
import getTicketById from './getTicketById';
import getTickets from './getTickets';
import getTicketsSummary from './getTicketsSummary';
import resolveTicket from './resolveTicket';

export default {
  changeTicketAssignees,
  editAgentNote,
  getOverallTicketsSummary,
  getTicketById,
  getTickets,
  getTicketsSummary,
  resolveTicket,
};
