import { // eslint-disable-line import/no-cycle
  createContact,
  getContactById,
  getContactsForStoreFilter,
} from 'store/actions/realtorContacts';

import {
  createPhoneNumber,
} from 'store/actions/phoneNumbers';

import {
  createEmail,
} from 'store/actions/emails';

import {
  uploadAttachment,
} from 'store/actions/attachmentsV2';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

const createContactFromNewContactForm = () => async (dispatch, getState) => {
  const {
    realtorNewContactForm,
  } = getState();

  const contactData = {
    title: realtorNewContactForm.personTitle,
    first_name: realtorNewContactForm.personFirstName,
    last_name: realtorNewContactForm.personLastName,
    email_primary: realtorNewContactForm.mainEmail,
    phone_primary: realtorNewContactForm.mainPhoneNumber,
    role: realtorNewContactForm.jobRole,
    labels: realtorNewContactForm.contactLabels.map((value) => Number(value)),
    source_id: realtorNewContactForm.source,
    first_contact_date: realtorNewContactForm.firstContactDate,
    is_past_client: realtorNewContactForm.isPastClient,
  };

  try {
    let phoneNumberId;
    if (realtorNewContactForm.mainPhoneNumber) {
      phoneNumberId = await dispatch(createPhoneNumber(realtorNewContactForm.mainPhoneNumber));
    }

    let emailId;
    if (realtorNewContactForm.mainEmail) {
      emailId = await dispatch(createEmail(realtorNewContactForm.mainEmail));
    }

    const contactId = await dispatch(createContact({
      ...contactData,
      phoneNumbers: phoneNumberId ? [phoneNumberId] : null,
      emails: emailId ? [emailId] : null,
    }));

    if (realtorNewContactForm.avatar) {
      const {
        name,
        type,
        fileUrl,
      } = realtorNewContactForm.avatar;

      const file = await dataUrlToFile(fileUrl, name, type);

      await dispatch(uploadAttachment({
        file,
        objectId: contactId,
        objectType: 25,
        attachmentType: 1,
      }));

      URL.revokeObjectURL(fileUrl);
    }

    dispatch(getContactsForStoreFilter());
    dispatch(getContactById(contactId));

    return contactId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createContactFromNewContactForm;
