import requests from 'api/requests';

import setSourceTypes from './setSourceTypes';

const getSourceTypes = () => async (dispatch) => {
  try {
    const response = await requests.crm.sourceTypes.get();

    dispatch(setSourceTypes(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getSourceTypes;
