import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Grid from 'ui/Grid';
import Tag from 'ui/Tag';
import Typography from 'ui/Typography';

import RealtorContactAvatar from 'shared/modules/RealtorContactAvatar';

import ContactControls from './ContactControls';
import ControlButtons from './ControlButtons';

import {
  Card,
} from './styledItems';

const FollowUpCard = (props) => {
  const {
    reminderId,
    contactId,
    label,
    title,
    comment,
    scheduledAt,
    status,
    onComplete,
    onDelete,
    onDetails,
    unsuccessful,
  } = props;

  if (!scheduledAt) {
    return null;
  }

  const isScheduledForToday = moment(scheduledAt).isSame(new Date(), 'day');

  return (
    <Card key={reminderId} unsuccessful={unsuccessful}>

      <ControlButtons
        onComplete={status === 6 ? null : onComplete}
        onDelete={onDelete}
        onDetails={onDetails}
        className="followup-controls"
      />

      <Grid
        container
        spacing={2}
      >

        <Grid
          item
          container
          spacing={1}
          xs={2}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <RealtorContactAvatar
              contactId={contactId}
              size="s"
              round
              onSave={null}
            />
          </Grid>
          {
            label && (
              <Grid item>
                <Tag
                  variant="focus"
                  size="s"
                >
                  {label}
                </Tag>
              </Grid>
            )
          }
        </Grid>

        <Grid
          item
          container
          spacing={1}
          xs={10}
        >
          <Grid item xs={9}>
            <Typography
              variant="title3"
              weight="bold"
              noMargin
              style={{ maxWidth: '100%' }}
              ellipsis
            >
              {title}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="title3"
              color={isScheduledForToday ? 'new' : 'default'}
              noMargin
              align="right"
            >
              {isScheduledForToday ? 'Today' : moment(scheduledAt).format('MMM DD, YYYY')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              noMargin
              style={{ maxWidth: '100%', opacity: 0.7 }}
              ellipsis
            >
              {comment || ''}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ContactControls contactId={contactId} />
          </Grid>

        </Grid>

      </Grid>
    </Card>
  );
};

const {
  number,
  string,
  func,
  bool,
} = PropTypes;

FollowUpCard.propTypes = {
  reminderId: number.isRequired,
  contactId: number.isRequired,
  label: string.isRequired,
  title: string.isRequired,
  comment: string.isRequired,
  scheduledAt: string.isRequired,
  status: number.isRequired,
  onComplete: func.isRequired,
  onDelete: func.isRequired,
  onDetails: func.isRequired,
  unsuccessful: bool.isRequired,
};

export default FollowUpCard;
