import { createSelector } from 'reselect';

import {
  optimizedPhoneNumbersActions,
} from 'store/actions/phoneNumbers';

export default createSelector(
  (state, id) => ({
    items: state.phoneNumbers.phoneNumbers,
    id,
  }),
  ({ items, id }) => {
    if (!id) return null;

    const dataObject = items[id];

    if (!dataObject) {
      optimizedPhoneNumbersActions.getPhoneNumberById(id);
    }

    return dataObject || {};
  },
);
