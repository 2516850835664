const get = (modalName) => {
  const modalsDraftsRaw = localStorage.getItem('modalsDrafts');
  const modalsDrafts = modalsDraftsRaw && JSON.parse(modalsDraftsRaw);

  return modalsDrafts?.[modalName] || null;
};

const set = (modalName, data) => {
  const modalsDraftsRaw = localStorage.getItem('modalsDrafts');
  const modalsDrafts = modalsDraftsRaw ? JSON.parse(modalsDraftsRaw) : {};

  modalsDrafts[modalName] = data;

  const newModalsDrafts = JSON.stringify(modalsDrafts);
  localStorage.setItem('modalsDrafts', newModalsDrafts);
};

const add = (modalName, dataToAdd) => {
  const currentData = get(modalName) || {};
  const newData = { ...currentData, ...dataToAdd };
  set(modalName, newData);
};

const clear = (modalName) => {
  set(modalName, null);
};

export default {
  get,
  set,
  add,
  clear,
};
