import {
  SET_NEW_REALTOR_PROSPECT_MODAL_PROPERTY_REQUIREMENT,
} from 'store/actionTypes/realtorProspects';

const setNewProspectModalProperty = (propertyRequirementId = null) => ({
  type: SET_NEW_REALTOR_PROSPECT_MODAL_PROPERTY_REQUIREMENT,
  payload: { propertyRequirementId },
});

export default setNewProspectModalProperty;
