import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';

import RestValues from './RestValues';
import EditArea from './EditArea';

const MultipleValuesInput = (props) => {
  const {
    values,
    onCreate,
    onChange,
    onMakePrimary,
    onRemove,
    isLoading,
    InputComponent,
    inputProps,
    formatDisplayValue,
    dataTypeDisplayName,
    withEditMode,
    bareEllipsisStyles,
    placeholder,
  } = props;

  const primaryValue = values.find((item) => item.isPrimary) || values[0];
  const additionalValues = values.filter((item) => item.id !== primaryValue?.id);

  const getTooltipContent = useCallback(() => (
    <EditArea
      primaryValue={primaryValue}
      additionalValues={additionalValues || []}
      onCreate={onCreate}
      onChange={onChange}
      onMakePrimary={onMakePrimary}
      onRemove={onRemove}
      isLoading={isLoading}
      InputComponent={InputComponent}
      inputProps={inputProps}
      dataTypeDisplayName={dataTypeDisplayName}
    />
  ), [
    isLoading,
    onCreate,
    onChange,
    onMakePrimary,
    onRemove,
    JSON.stringify(primaryValue),
    JSON.stringify(additionalValues),
  ]);

  return (
    <Tooltip
      overlayStyle={{ maxWidth: 600 }}
      trigger="click"
      title={withEditMode ? getTooltipContent() : null}
      placement="bottom"
    >
      <div style={{ width: 'fit-content' }}>
        <Flex
          spacing={1}
          alignItems="center"
        >
          <Typography
            color={primaryValue?.value ? 'main-text' : 'placeholder'}
            fontStyle={primaryValue?.value ? 'normal' : 'italic'}
            noMargin
          >
            {primaryValue?.value ? formatDisplayValue(primaryValue.value) : placeholder}
          </Typography>

          {
            (additionalValues.length > 0) && (
              <RestValues
                values={additionalValues}
                formatDisplayValue={formatDisplayValue}
                bareEllipsisStyles={bareEllipsisStyles}
              />
            )
          }
        </Flex>
      </div>
    </Tooltip>
  );
};

MultipleValuesInput.defaultProps = {
  values: [],
  isLoading: false,
  InputComponent: null,
  inputProps: {},
  formatDisplayValue: (value) => value,
  dataTypeDisplayName: 'email',
  withEditMode: true,
  bareEllipsisStyles: false,
  placeholder: 'Not set',
};

const {
  func,
  number,
  string,
  arrayOf,
  bool,
  shape,
  oneOfType,
} = PropTypes;

MultipleValuesInput.propTypes = {
  isLoading: bool,
  values: arrayOf(shape({
    id: number.isRequired,
    value: oneOfType([number, string]).isRequired,
    isPrimary: bool,
  })),
  onCreate: func.isRequired,
  onChange: func.isRequired,
  onMakePrimary: func.isRequired,
  onRemove: func.isRequired,
  InputComponent: func,
  inputProps: shape({}),
  formatDisplayValue: func,
  dataTypeDisplayName: string,
  withEditMode: bool,
  bareEllipsisStyles: bool,
  placeholder: string,
};

export default MultipleValuesInput;
