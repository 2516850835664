import {
  SET_REALTOR_CLIENTS,
} from 'store/actionTypes/realtorClients';

const setClients = (clients) => ({
  type: SET_REALTOR_CLIENTS,
  payload: clients,
});

export default setClients;
