import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';

import './IntroModal.scss';

class IntroModal extends Component {
  closeModal = () => {
    const { triggerIntroModal: triggerModal } = this.props;
    triggerModal(false);
  };

  startAppTour = () => {
    const {
      appTour,
      toggleAppTour,
    } = this.props;
    this.closeModal();
    if (!appTour) toggleAppTour();
  };

  render() {
    const footer = (
      <div className="intro-modal__footer">
        <Button
          className="intro-modal__kb-button"
          onClick={this.startAppTour}
        >
          Start tutorial
        </Button>
        <Button
          type="primary"
          onClick={this.closeModal}
        >
          Close
        </Button>
      </div>
    );

    const {
      visible,
      userName,
    } = this.props;

    return (
      <Modal
        width={460}
        title={null}
        closable={false}
        maskClosable
        centered
        footer={footer}
        visible={visible}
        wrapClassName="intro-modal"
        onCancel={this.closeModal}
      >

        <h1 className="intro-modal__header">
          { `Welcome, ${userName}.` }
        </h1>

        <div className="intro-modal__content">
          We have aimed to make Symplete as easy to use as possible.
          However, if you need any guidance, please visit the Knowledge Base
          to see our Symplete help articles and how to guides.
        </div>
      </Modal>
    );
  }
}

const {
  bool,
  func,
  string,
} = PropTypes;

IntroModal.propTypes = {
  triggerIntroModal: func.isRequired,
  visible: bool,
  userName: string.isRequired,
  appTour: bool,
  toggleAppTour: func.isRequired,
};

IntroModal.defaultProps = {
  visible: false,
  appTour: false,
};

export default IntroModal;
