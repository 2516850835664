import {
  SET_CRM_SOURCE,
} from 'store/actionTypes/crm';

const setSource = (source) => ({
  type: SET_CRM_SOURCE,
  payload: source,
});

export default setSource;
