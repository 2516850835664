/* eslint-disable react/jsx-no-bind */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import Modal from 'ui/Modal';

import AcceptCounterForm from 'sections/Opportunities/forms/AcceptCounterForm';

import OffersContext from 'sections/OffersV2/context/OffersContext';

const acceptCounterFormId = 'accept-counter-form';

const AcceptCounterModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    title,
  } = props;

  const { getAllOffers } = useContext(OffersContext);

  const handleSuccess = async () => {
    await getAllOffers();
    closeModal();
  };

  return (
    <Modal
      theme={theme}
      width={500}
      visible={isVisible}
      onCancel={closeModal}
      title={title}
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={acceptCounterFormId}
        >
          Accept Offer
        </Button>,
      ]}
    >
      <AcceptCounterForm
        formId={acceptCounterFormId}
        onAfterSubmit={handleSuccess}
      />
    </Modal>
  );
};

AcceptCounterModal.defaultProps = {
  isVisible: false,
  theme: null,
  title: null,
};

const {
  bool,
  func,
  shape,
  string,
} = PropTypes;

AcceptCounterModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
  title: string,
};

export default AcceptCounterModal;
