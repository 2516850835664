import requests from 'api/requests';

import setPropertyRequirements from './setPropertyRequirements';

const getPropertyRequirements = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.propertyRequirements.get({
      offset,
      limit,
    });

    dispatch(setPropertyRequirements(response.data.requirements));
  } catch (error) {
    console.error(error);
  }
};

export default getPropertyRequirements;
