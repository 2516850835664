import connectReducers from 'store/connectReducers';

import {
  SET_CRM_PRODUCT,
  SET_CRM_PRODUCTS,
  SET_CRM_PRODUCTS_FOR_FILTER,
} from 'store/actionTypes/crm';

const initialState = {
  products: {},
  productsByFilter: {},
};

const productsListToObject = (products = []) => products.reduce((productsObj, item) => {
  // param reassign is used in reduce by design
  productsObj[item.id] = item; // eslint-disable-line no-param-reassign
  return productsObj;
}, {});

const getProductIds = (products = []) => products.map((item) => item.id);

const reducers = {
  [SET_CRM_PRODUCT]: (state, action) => ({
    ...state,
    products: {
      ...state.products,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_CRM_PRODUCTS]: (state, action) => ({
    ...state,
    products: {
      ...state.products,
      ...productsListToObject(action.payload),
    },
  }),

  [SET_CRM_PRODUCTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      products,
    } = action.payload;

    const filterProductIds = state.productsByFilter[filterId] || [];

    const updatedFilterProductIds = new Set([
      ...filterProductIds,
      ...getProductIds(products),
    ]);

    const updatedState = {
      ...state,
      productsByFilter: {
        ...state.productsByFilter,
        [filterId]: [...updatedFilterProductIds],
      },
    };

    return updatedState;
  },
};

function crmProducts(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default crmProducts;
