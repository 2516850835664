import connectReducers from 'store/connectReducers';
import { groupBy } from 'shared/utility';

import {
  SET_CUSTOMER_INVOICE,
  SET_CUSTOMER_INVOICES,
  SET_SELECTED_CUSTOMER_INVOICE,
  SET_INVOICE_ATTACHMENTS,
} from 'store/actionTypes/customerInvoices';

const initialState = {
  invoices: {},
  selectedInvoice: null,
};

const invoicesListToObject = (items = []) => items.reduce((invoiceObj, item) => {
  // param reassign is used in reduce by design
  invoiceObj[item.id] = item; // eslint-disable-line no-param-reassign
  return invoiceObj;
}, {});

const reducers = {
  [SET_SELECTED_CUSTOMER_INVOICE]: (state, action) => ({
    ...state,
    selectedInvoice: action.payload,
  }),

  [SET_CUSTOMER_INVOICE]: (state, action) => ({
    ...state,
    invoices: {
      ...state.invoices,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_CUSTOMER_INVOICES]: (state, action) => ({
    ...state,
    invoices: {
      ...state.invoices,
      ...invoicesListToObject(action.payload),
    },
  }),

  [SET_INVOICE_ATTACHMENTS]: (state, action) => ({
    ...state,
    invoices: {
      ...state.invoices,
      [action.payload.id]: {
        ...state.invoices[action.payload.id],
        attachments: groupBy(action.payload.attachments, 'attachmentType'),
      },
    },
  }),
};

function invoicesReducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default invoicesReducer;
