import { createSelector } from 'reselect';

import { optimizedDictionaryActions } from 'store/actions/dictionaryV2';

const realtorSpecialConditions = (state) => state.dictionaryV2.realtorSpecialConditions;

export default createSelector(
  realtorSpecialConditions,
  (collection) => {
    if (!collection) {
      optimizedDictionaryActions.getRealtorSpecialConditionsDictionary();
      return [];
    }

    return collection.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  },
);
