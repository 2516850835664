import styled from 'styled-components';

import Grid from 'ui/Grid';

export const ScrollContainer = styled(Grid)`
  max-height: 80vh;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }
`;

export default {
  ScrollContainer,
};
