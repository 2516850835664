import connectReducers from 'store/connectReducers';

import {
  SET_ACCEPT_COUNTER_FORM_STATE,
  RESET_ACCEPT_COUNTER_FORM_STATE,
  SET_ACCEPT_COUNTER_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorOpportunities';

const noOp = () => {};

const initialState = {
  // callbacks
  onBeforeSubmit: noOp,
  onSubmit: null,
  onAfterSubmit: noOp,

  // defaultValues
  offerId: null,
  rootOfferId: null,

  attachments: [],
};

const reducers = {
  [SET_ACCEPT_COUNTER_FORM_STATE]: (state, action) => ({
    ...initialState,
    ...action.payload,
  }),

  [RESET_ACCEPT_COUNTER_FORM_STATE]: (state) => initialState,

  [SET_ACCEPT_COUNTER_FORM_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.payload.filesList,
  }),
};

function acceptCounterForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default acceptCounterForm;
