import {
  DELETE_REALTOR_REPAIR_REQUEST,
} from 'store/actionTypes/realtorRepairRequests';

const removeRepairRequest = (item) => ({
  type: DELETE_REALTOR_REPAIR_REQUEST,
  payload: item,
});

export default removeRepairRequest;
