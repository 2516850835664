import styled from 'styled-components';

const columnsCount = 24;

const getWidth = (props) => {
  const {
    xs,
    fullWidth,
    gap = 0,
  } = props;

  if (fullWidth) return '100%';
  if (!xs) return 'auto';

  const relativeWidth = xs / columnsCount;

  const percentage = relativeWidth * 100;

  return `calc(${percentage}% - ${gap}px)`;
};

export const Root = styled.div`
  width: ${getWidth};
  align-self: ${(props) => props.alignSelf};
  padding: ${(props) => props.padding || 0};
  ${(props) => (props.flex && `flex: ${props.flex};`)}
  ${(props) => (props.keepSize && 'flex-shrink: 0;')}
  ${(props) => (props.preventStretch && 'min-width: 0;')}
`;

export default {
  Root,
};
