import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Textfield from 'ui/Textfield';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

import {
  Root,
  Controls,
  StyledButton,
  SpecialConditionsItemWrapper,
} from './styledItems';

const SpecialConditionsItem = (props) => {
  const {
    onDelete,
    onChange,
    value: item,
  } = props;

  const handleChange = useCallback((newValue) => {
    onChange({
      ...item,
      value: newValue,
    });
  }, [
    onChange,
    item,
  ]);

  const handleClick = useCallback((isSelected) => (event) => {
    onChange({ ...item, isSelected });
  }, [
    onChange,
    item,
  ]);

  return (
    <Root>
      <SpecialConditionsItemWrapper>
        <Icon
          name="circle"
          color="highlight"
          size="s"
        />
        <Textfield
          onChange={handleChange}
          value={item.value}
          style={{ margin: '5px 10px' }}
        />
        {
          (item.value && item.isNew) && (
            <Button
              variant="tertiary"
              icon="x"
              iconColor="default"
              iconSize="s"
              onClick={onDelete}
              style={{ marginLeft: 10 }}
            />
          )
        }
      </SpecialConditionsItemWrapper>

      {
        (item.value && !item.isNew) && (
          <Controls>
            <StyledButton
              onClick={handleClick(true)}
              color={item.isSelected ? '#2ecc71' : undefined}
              type="button"
            >
              <FontAwesomeIcon
                icon="check"
              />
            </StyledButton>

            <StyledButton
              onClick={handleClick(false)}
              color={!item.isSelected ? '#ff526b' : undefined}
              type="button"
            >
              <FontAwesomeIcon
                icon="times"
              />
            </StyledButton>
          </Controls>
        )
      }
    </Root>
  );
};

const {
  func,
  string,
  shape,
  bool,
} = PropTypes;

SpecialConditionsItem.propTypes = {
  value: shape({
    value: string,
    isSelected: bool,
  }).isRequired,
  onDelete: func.isRequired,
  onChange: func.isRequired,
};

export default SpecialConditionsItem;
