import {
  SET_NEW_REALTOR_PROSPECT_MODAL_ATTACHMENTS,
} from 'store/actionTypes/realtorProspects';

const setNewProspectModalAttachments = (filesList = []) => ({
  type: SET_NEW_REALTOR_PROSPECT_MODAL_ATTACHMENTS,
  payload: { filesList },
});

export default setNewProspectModalAttachments;
