import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyRequirementActions,
} from 'store/actions/propertyRequirements';

export default createSelector(
  (state) => state.propertyRequirements.requirements,
  (_, id) => id,
  (collection, id) => {
    const defaultItem = {
      id: null,
      amenities: [],
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedPropertyRequirementActions.getPropertyRequirementById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
    };
  },
);
