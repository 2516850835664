import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withMainPropertyImage = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      propertyId,
    } = ownProps;

    const image = selectors.properties.mainPropertyImage(state, propertyId);

    return image;
  };

  return connect(mapStateToProps)(Component);
};

export default withMainPropertyImage;
