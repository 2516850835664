import requests from 'api/requests';

import setRepairRequests from './setRepairRequests';

const getRepairRequests = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.repairRequests.get({
      offset,
      limit,
    });

    dispatch(setRepairRequests(response.data.items));
  } catch (error) {
    console.error(error);
  }
};

export default getRepairRequests;
