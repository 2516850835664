import { createSelector } from 'reselect';

import moment from 'moment';

import { getUserOptimized } from 'store/actions/users';

const dateFormat = 'hh:mm A MMM DD YYYY';

const selectedCompany = (state) => state.crmCompanies.selectedCompany;
const companies = (state) => state.crmCompanies.companies;
const changelog = (state) => state.crmCompanies.changelog;
const employees = (state) => state.users.employee;

const timelineEventTypes = {
  'owner assigned': true,
  'owner removed': true,
};

const timelineEventsWithEmployeeAsValue = {
  'owner assigned': true,
  'owner removed': true,
};

const companyEvents2timelineEvents = {
  'owner assigned': 'addPerson',
  'owner removed': 'removePerson',
};

const statuses = {
  archived: '1',
  // 'not interested': '5',
};

// const objectTypes = {
//   1: 'ticket',
//   2: 'task',
//   3: 'lead',
//   6: 'deal',
// };

const eventTitles = {
  'owner assigned': 'Owner assigned',
  'owner removed': 'Owner removed',
  archived: 'Company archived',
  // 'object linked': (type) => `${type} linked`,
  // 'not interested': 'Contact status set to \'Not interested\'',
};

const eventDescriptions = {
  'owner assigned': (who, by, isSame) => (isSame
    ? `${who} assigned themself as Owner`
    : `${who} was assigned as Owner by ${by}`),

  'owner removed': (who, by, isSame) => (isSame
    ? `${who} usassigned themself as Owner`
    : `${who} was unassigned as Owner by ${by}`),

  archived: (by) => `Company was archived by ${by}`,

  // 'object linked': (type, id, by) => `${type} id ${id} was linked to this company by ${by}`,
  // 'not interested': (by) => `Contact status was set to \'Not interested\' by ${by}`,
};

const formatTimelineEvents = (acc, item) => {
  if (timelineEventTypes[item.event_type]) {
    acc.push({
      createdAt: item.created_at,
      type: companyEvents2timelineEvents[item.event_type],
      details: {
        time: moment(item.created_at).format(dateFormat),
        title: eventTitles[item.event_type],
        description: item.newEmployeeName
          ? eventDescriptions[item.event_type](
            item.newEmployeeName,
            item.doneByName,
            item.isSamePerson,
          )
          : null,
      },
    });
  }

  if (item.event_type === 'status') {
    if (item.new_value === statuses.archived) {
      acc.push({
        createdAt: item.created_at,
        type: 'archived',
        details: {
          time: moment(item.created_at).format(dateFormat),
          title: eventTitles.archived,
          description: eventDescriptions.archived(item.doneByName),
        },
      });
    }

    // if (item.new_value === statuses['not interested']) {
    //   acc.push({
    //     createdAt: item.created_at,
    //     type: 'emotionMeh',
    //     details: {
    //       time: moment(item.created_at).format(dateFormat),
    //       title: eventTitles['not interested'],
    //       description: eventDescriptions['not interested'](item.doneByName),
    //     },
    //   });
    // }
  }

  // if (item.event_type === 'object linked') {
  //   acc.push({
  //     createdAt: item.created_at,
  //     type: item.objectTypeName,
  //     details: {
  //       time: moment(item.created_at).format(dateFormat),
  //       title: eventTitles['object linked'](item.objectTypeName),
  //       description: eventDescriptions['object linked'](item.objectTypeName, item.objectId, item.doneByName),
  //     },
  //   });
  // }

  return acc;
};

export default createSelector(
  selectedCompany,
  companies,
  changelog,
  employees,
  (companyId, companiesDict, changelogItems = {}, users) => { // eslint-disable-line max-params
    if (!companyId || !companiesDict || !companiesDict[companyId]) {
      return [];
    }

    const company = companiesDict[companyId];

    const createdByUser = users[company.created_by];

    if (!createdByUser) {
      getUserOptimized(1, company.created_by);
    }

    const created = {
      type: 'created',
      createdAt: company.created_at,
      details: {
        title: 'Company created',
        time: moment(company.created_at).format(dateFormat),
        description: createdByUser
          ? `Company was created by ${createdByUser.name} ${createdByUser.surname}`
          : null,
      },
    };

    const companyEvents = changelogItems[companyId] || [];

    const companyEventsWithUserData = companyEvents.map((item) => {
      const actor = users[item.done_by_id];

      if (!actor) {
        getUserOptimized(1, item.done_by_id);
        return item;
      }

      if (timelineEventsWithEmployeeAsValue[item.event_type]) {
        const newEmployee = users[item.new_value?.id];

        let newEmployeeName;

        if (!newEmployee) {
          getUserOptimized(1, item.new_value?.id);
        } else {
          newEmployeeName = `${newEmployee.name} ${newEmployee.surname}`;
        }

        return {
          ...item,
          doneByName: `${actor.name} ${actor.surname}`,
          newEmployeeName,
          isSamePerson: item.done_by_id === item.new_value?.id,
        };
      }

      // if (item.event_type === 'object linked') {
      //   const {
      //     objectType,
      //     objectId,
      //   } = item.new_value;
      //
      //   const objectTypeName = objectTypes[objectType];
      //
      //   return {
      //     ...item,
      //     doneByName: `${actor.name} ${actor.surname}`,
      //     objectTypeName,
      //     objectId,
      //   };
      // }

      return {
        ...item,
        doneByName: `${actor.name} ${actor.surname}`,
      };
    });

    const timelineEvents = companyEventsWithUserData.reduce(formatTimelineEvents, []);

    const timeline = [created].concat(timelineEvents.reverse()); // this is necessary for correct order

    return timeline;
  },
);
