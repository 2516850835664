import React from 'react';
import PropTypes from 'prop-types';

import {
  NavLink,
} from 'react-router-dom';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import useContactName from 'shared/hooks/contacts/useContactName';

const Representing = (props) => {
  const {
    contactId,
  } = props;

  return (
    <Flex
      alignItems="baseline"
      spacing={1}
    >
      <Typography
        variant="title3"
        weight={500}
        tag="p"
        noMargin
      >
        Representing:
      </Typography>
      <NavLink to={`/contacts/${contactId}`}>
        <Typography
          variant="title3"
          weight={400}
          color="link"
          noMargin
        >
          {useContactName(contactId)}
        </Typography>
      </NavLink>
    </Flex>
  );
};

const {
  number,
} = PropTypes;

Representing.propTypes = {
  contactId: number.isRequired,
};

export default Representing;
