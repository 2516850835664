import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {team|employee} category
 */
const getLeadsSummary = (category) => async (itemId) => {
  const endpoint = `${endpoints.client.v2.leadsSummary}/${category}/${itemId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getLeadsSummary;
