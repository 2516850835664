import {
  SET_REALTOR_TRANSACTIONS_FOR_FILTER,
} from 'store/actionTypes/realtorTransactions';

const setTransactionsForFilter = (filterId) => (transactions) => ({
  type: SET_REALTOR_TRANSACTIONS_FOR_FILTER,
  payload: {
    filterId,
    transactions,
  },
});

export default setTransactionsForFilter;
