import apiConnector, { endpoints } from 'api';

const getUserUnreadMessage = async () => {
  const endpoint = endpoints.client.chat.userMessage;

  const result = await apiConnector.client.get(endpoint);
  return result;
};

export default getUserUnreadMessage;
