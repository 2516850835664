import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { updateProperty } from 'store/actions/properties';

import TempSelect from 'ui/TempSelect';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    temp: property?.temp,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onOptionSelect: (temp) => {
    dispatch(updateProperty(ownProps.propertyId)({ temp }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TempSelect);
