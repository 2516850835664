import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  ROLE_LABELS,
} from 'constants/realtor.constants';

const icons = {
  1: 'investment',
  2: 'briefcase',
};

const iconTypes = {
  1: 'custom',
  2: 'feather',
};

const style = {
  textTransform: 'uppercase',
  letterSpacing: '0.07em',
};

const RoleLabel = (props) => {
  const {
    role,
  } = props;

  return (
    <Flex alignItems="center" spacing={1}>
      <Icon
        size="m"
        name={icons[role]}
        type={iconTypes[role]}
        color="focus"
      />
      <Typography
        variant="tag"
        color="focus"
        style={style}
      >
        {ROLE_LABELS[role] || 'good man'}
      </Typography>
    </Flex>
  );
};

RoleLabel.defaultProps = {

};

const {
  number,
} = PropTypes;

RoleLabel.propTypes = {
  role: number.isRequired,
};

export default RoleLabel;
