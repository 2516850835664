import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorSpecialConditionsActions,
} from 'store/actions/realtorSpecialConditions';

// const defaultItem = {
//   id: null,
//   name: null
// };

export default createSelector(
  (state) => state.realtorSpecialConditions.items,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorSpecialConditionsActions.getById(id);
      }

      return null;
    }

    return {
      ...item,
    };
  },
);
