import requests from 'api/requests';

import setPoint from './setNotablePoint';

const getNotablePointById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.notablePoints.getById(id);
    dispatch(setPoint(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getNotablePointById;
