import React from 'react';
import PropTypes from 'prop-types';

import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import SectionTitle from 'sections/Settings/SectionTitle';

import withTitleImg from 'assets/images/realtor/with-title-illustration.png';
import withoutTitleImg from 'assets/images/realtor/without-title-illustration.png';

import { contingencyOrDueDiligence, attorneyStateOrEscrow, leadConversionGoal } from 'constants/tooltipText.constants';
import Salutations from './Salutations';
import AttorneyEscrowState from './AttorneyEscrowState';
import ContingencyDueDiligence from './ContingencyDueDiligence';
import Prioritization from './Prioritization';
import LeadConversionGoal from './LeadConversionGoal';
import ThemeCustomizer from './ThemeCustomizer';
import CustomizedContactLabels from './CustomizedContactLabels';

const getSalutationsTooltip = () => (
  <div>
    <p>
      When adding new contacts you have the option to enter salutations for that contact, or
      not see the option at all.
    </p>
    <b>ON</b>
    <br />
    <img width="220" src={withTitleImg} alt="with salutations" />
    <br />
    <br />
    <b>OFF</b>
    <br />
    <img width="220" src={withoutTitleImg} alt="without salutations" />
  </div>
);

const marginStyleSmall = { marginBottom: 25 };
const marginStyleLarge = { marginBottom: 70 };

const AppCustomization = (props) => {
  const {
    salutationsState,
  } = props;

  return (
    <>
      <Elevation
        rounded="m"
        padding="m"
        style={marginStyleSmall}
      >
        <Flex
          flexDirection="column"
          spacing={3}
          fullWidth
        >
          <FlexItem fullWidth>
            <SectionTitle
              title="General Customizations"
            />
          </FlexItem>

          <FlexItem>
            <SectionTitle
              title="Customized Contact Labels"
              variant="title2"
              tooltipText={
                `Labels help group your contacts. Here you can create custom labels that
                you can assign to contacts, leads and clients throughout the app.`
              }
            />
            <CustomizedContactLabels />
          </FlexItem>

          <FlexItem fullWidth>
            <SectionTitle
              title={`Salutations: ${salutationsState}`}
              variant="title2"
              tooltipText={getSalutationsTooltip()}
            />
            <Salutations />
          </FlexItem>

          <FlexItem fullWidth>
            <SectionTitle
              title="Attorney State or Escrow State"
              variant="title2"
              tooltipText={attorneyStateOrEscrow}
            />
            <AttorneyEscrowState />
          </FlexItem>

          <FlexItem fullWidth style={marginStyleLarge}>
            <SectionTitle
              title="Contingency or Due Diligence"
              variant="title2"
              tooltipText={contingencyOrDueDiligence}
            />
            <ContingencyDueDiligence />
          </FlexItem>

          <FlexItem fullWidth>
            <SectionTitle
              title="Lead Conversion Goal"
              variant="title2"
              tooltipText={leadConversionGoal}
            />
            <LeadConversionGoal />
          </FlexItem>
        </Flex>
      </Elevation>

      <Elevation
        rounded="m"
        padding="m"
        style={marginStyleSmall}
      >
        <SectionTitle
          title="Prioritization"
          comingSoon
        />
        <Prioritization />
      </Elevation>

      <Elevation
        rounded="m"
        padding="m"
        style={marginStyleSmall}
      >
        <SectionTitle title="Theme Customizer" />
        <ThemeCustomizer />
      </Elevation>

    </>
  );
};

const { oneOf } = PropTypes;

AppCustomization.propTypes = {
  salutationsState: oneOf(['ON', 'OFF']).isRequired,
};

export default AppCustomization;
