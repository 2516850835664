import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import get from './get';

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorInspectionsTypesActions = { // eslint-disable-line import/prefer-default-export
  getInspectionTypes: memoizeDebounce(
    () => store.dispatch(get()),
    debounceDelay,
    debounceOptions,
  ),
};
