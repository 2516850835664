import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactCell from './ContactCell';

const processTags = (tags = [], contact = {}) => {
  const processedTags = [...tags];

  if (contact?.is_vip) {
    processedTags.push('vip');
  }

  return processedTags;
};

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
    tags = [],
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    tags: processTags(tags, contact),
  };
};

export default connect(mapStateToProps)(ContactCell);
