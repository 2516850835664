import React from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import selectors from 'store/selectors';

import RequestItem from 'sections/Transactions/components/RequestItem';

import { updateDocumentRequest } from 'store/actions/realtorDocumentRequests';

import DocumentRequestAttachments from './modules/DocumentRequestAttachments';

const statusMap = {
  1: 'new',
  2: 'acknowledged',
  3: 'responded',
};

const getStatusText = (item) => {
  const {
    status,
    acknowledged_at,
    responded_at,
  } = item;

  if (status === 3) {
    return `Responded on ${moment(responded_at).format('MMM DD, YYYY HH:MMa')}`;
  }

  if (status === 2) {
    return `Acknowledged on ${moment(acknowledged_at).format('MMM DD, YYYY HH:MMa')}`;
  }

  return null;
};

const mapStateToProps = (state, ownProps) => {
  const item = selectors.realtorDocumentRequests.byId(ownProps.requestId)(state);

  return {
    contactId: item?.requestors?.[0],
    title: item?.title,
    // contactRole: 'Agent',
    description: item?.description,
    createdAt: item?.created_at,
    status: statusMap[item?.status] || 'new',
    statusText: getStatusText(item),
    response: item?.response,
    responseCreatedAt: item?.responded_at,
    attachments: <DocumentRequestAttachments requestId={ownProps.requestId} />,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (response) => {
    await dispatch(updateDocumentRequest(ownProps.requestId)({
      status: 3,
      response,
      responded_at: new Date(),
    }));

    // update transaction
    if (ownProps.onAfterUpdate) {
      ownProps.onAfterUpdate();
    }
  },
  onAcknowledge: async () => {
    dispatch(updateDocumentRequest(ownProps.requestId)({
      status: 2,
      acknowledged_at: new Date(),
    }));

    // update transaction
    if (ownProps.onAfterUpdate) {
      ownProps.onAfterUpdate();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestItem);
