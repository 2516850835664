import {
  SET_CRM_CONTACT_LABELS,
} from 'store/actionTypes/crm';

const setContactsLabels = (labels) => ({
  type: SET_CRM_CONTACT_LABELS,
  payload: labels,
});

export default setContactsLabels;
