/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getDisclosureById from './getDisclosureById';
import updateDisclosure from './updateDisclosure';
import deleteDisclosure from './deleteDisclosure';
import addDisclosureRelations from './addDisclosureRelations';
import removeDisclosureRelations from './removeDisclosureRelations';
import createDisclosure from './createDisclosure';

export {
  getDisclosureById,
  updateDisclosure,
  deleteDisclosure,
  addDisclosureRelations,
  removeDisclosureRelations,
  createDisclosure,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedDisclosureActions = {
  getDisclosureById: memoizeDebounce(
    (id) => store.dispatch(getDisclosureById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
