import styled from 'styled-components';

export const BorderBox = styled.div`
  margin: 15px 3px;
  opacity: 0.59;
  border-bottom: solid 1px ${(props) => props?.theme?.palette?.navColorDivider || 'gray'};

  @media screen and (max-height: 950px) {
    margin: 12px 3px;
  }
`;

export default {
  BorderBox,
};
