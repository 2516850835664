import { connect } from 'react-redux';

import {
  updateRepairRequest,
} from 'store/actions/realtorRepairRequests';

import SelectCell from 'shared/components/UI/TableCells/SelectCell';

import { PROPOSED_FIX_TYPES_ARRAY } from 'constants/realtor.constants';

const valuesToColors = {
  1: '#71c59b',
  2: '#ffba4a',
  3: '#f4121d',
};

const mapStateToProps = (state, ownProps) => {
  const {
    fixType,
  } = ownProps;

  return {
    options: PROPOSED_FIX_TYPES_ARRAY,
    value: fixType,
    valuesToColors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateRepairRequest(ownProps.requestId)({ proposed_fix_type_id: value }, ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCell);
