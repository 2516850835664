import requests from 'api/requests';
import { callAlert } from 'ui/Alert';

import createAttachmentFormData from 'shared/utils/attachments/createAttachmentFormData';

import {
  filePreloader,
  setFileCompressionProgress,
  clearFileCompressionProgress,
} from 'store/actions/attachments';

import getAttachmentsList from './getAttachmentsList';

const uploadAttachment = (data, showSuccessMessage = true) => async (dispatch) => {
  const {
    file,
    objectId,
    objectType,
    attachmentType,
    isPublic = false,
    linkUrl,
  } = data;

  try {
    dispatch(filePreloader(true));

    let result;

    if (file) {
      const attachmentMeta = {};

      const attachmentFormData = await createAttachmentFormData(
        file,
        attachmentMeta,
        (progress) => {
          dispatch(setFileCompressionProgress(progress));
        },
      );

      dispatch(clearFileCompressionProgress());

      result = await requests.attachments
        .uploadAttachmentV2(attachmentType)(objectType)(objectId)(attachmentFormData, isPublic);
    }

    if (linkUrl) {
      result = await requests.attachments
        .uploadLinkAttachment(attachmentType)(objectType)(objectId)(linkUrl, isPublic);
    }

    dispatch(filePreloader(false));

    if (showSuccessMessage) {
      callAlert.success('The file was uploaded successfully!');
    }

    dispatch(getAttachmentsList(objectType)(objectId));

    const event = new CustomEvent('attachmentUpload', {
      detail: {
        objectId,
        objectType,
        attachmentType,
      },
    });
    window.dispatchEvent(event);

    return result;
  } catch (err) {
    console.error(err);

    callAlert.error('Error on file upload :(');
    dispatch(filePreloader(false));
    return null;
  }
};

export default uploadAttachment;
