import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Card = styled.div`
  padding: 5px;
  background: ${(props) => (props.isSelected ? props.theme.palette?.focus : props.theme.palette?.['background-flat'])};
  border-radius: 10px;
`;

export const InlineWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;
