import jwtDecode from 'jwt-decode';

const getDatabaseName = (_token = null) => {
  const token = _token || localStorage.getItem('token');

  const { databaseName } = jwtDecode(token);

  return databaseName;
};

export default getDatabaseName;
