import apiConnector, { endpoints } from 'api';

/**
 * @typedef {'task' | 'ticket' | 'lead' | 'comment' | 'log' } CommentedObjectTypes
 */

const getCommentsForObject = (commentedObjectType) => async (commentedObjectId) => {
  const endpoint = `${endpoints.client.comments}/${commentedObjectType}/${commentedObjectId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getCommentsForObject;
