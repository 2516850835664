import apiConnector, { endpoints } from 'api';

const list = async (filters) => {
  const endpoint = endpoints.client.integrations.emails;

  try {
    const result = await apiConnector.client.get(endpoint, filters);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default list;
