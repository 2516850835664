import { getLineItemById } from 'store/actions/line-items';

import getFulfillmentById from './getFulfillmentById';
import clearFilterSummaries from './clearFilterSummaries';

const refreshFulfillment = (id) => async (dispatch) => {
  const fulfillment = await dispatch(getFulfillmentById(id));
  dispatch(clearFilterSummaries());

  fulfillment.line_items.forEach((itemId) => {
    dispatch(getLineItemById(itemId));
  });
};

export default refreshFulfillment;
