import {
  useEffect,
} from 'react';

import forceFocus from 'ui/utils/forceFocus';

const useFocusOnRender = (element, shouldFocusOnRender) => {
  useEffect(() => {
    if (shouldFocusOnRender) {
      forceFocus(element);
    }
  }, []);
};

export default useFocusOnRender;
