import connectReducers from 'store/connectReducers';

import {
  SMALL_VIDEO_CHAT_MODAL_SHOW,
  SMALL_VIDEO_CHAT_MODAL_HIDE,
  LARGE_VIDEO_CHAT_MODAL_SHOW,
  LARGE_VIDEO_CHAT_MODAL_HIDE,
  SET_ACTIVE_MEETING,
  ADD_MEETING,
  ADD_DESIRED_OUTCOME,
  SET_MEETINGS,
  UPDATE_DESIRED_OUTCOME,
  CLOSE_JOIN_MEETING_MODAL,
  CLOSE_MEETING_AGENDA_MODAL,
  CLOSE_MEETING_DETAILS_MODAL,
  OPEN_MEETING_AGENDA_MODAL,
  OPEN_MEETING_DETAILS_MODAL,
  SET_ACTIVE_MEETING_PARTICIPANTS,
} from 'store/actionTypes/video-chat';

const initialState = {
  isSmallVideoChatOpen: false,
  isLargeVideoChatOpen: false,
  isMeetingDetailsModalOpen: false,
  isMeetingAgendaModalOpen: false,
  isJoinMeetingModalOpen: true,
  activeMeeting: {},
  activeMeetingParticipants: [],
  meetings: [],
};

const reducers = {
  [SMALL_VIDEO_CHAT_MODAL_SHOW]: (state) => ({
    ...state,
    isSmallVideoChatOpen: true,
  }),

  [SMALL_VIDEO_CHAT_MODAL_HIDE]: (state) => ({
    ...state,
    isSmallVideoChatOpen: false,
  }),

  [LARGE_VIDEO_CHAT_MODAL_SHOW]: (state) => ({
    ...state,
    isLargeVideoChatOpen: true,
  }),

  [LARGE_VIDEO_CHAT_MODAL_HIDE]: (state) => ({
    ...state,
    isLargeVideoChatOpen: false,
  }),

  [SET_MEETINGS]: (state, action) => ({
    ...state,
    meetings: action.payload,
  }),

  [SET_ACTIVE_MEETING]: (state, action) => ({
    ...state,
    activeMeeting: action.payload,
  }),

  [ADD_MEETING]: (state, action) => ({
    ...state,
    meetings: [
      ...state.meetings,
      action.payload,
    ],
  }),

  [ADD_DESIRED_OUTCOME]: (state, action) => ({
    ...state,
    activeMeeting: {
      ...state.activeMeeting,
      desiredOutcomes: [
        ...state.activeMeeting.desiredOutcomes,
        action.payload,
      ],
    },
  }),

  [UPDATE_DESIRED_OUTCOME]: (state, action) => {
    const newDesiredOutcomes = state.activeMeeting.desiredOutcomes.map(
      (item) => {
        let newItem = item;

        if (item.id === action.payload.id) {
          newItem = action.payload;
        }

        return newItem;
      },
    );

    return {
      ...state,
      activeMeeting: {
        ...state.activeMeeting,
        desiredOutcomes: newDesiredOutcomes,
      },
    };
  },

  [CLOSE_JOIN_MEETING_MODAL]: (state) => ({
    ...state,
    isJoinMeetingModalOpen: false,
  }),

  [CLOSE_MEETING_AGENDA_MODAL]: (state) => ({
    ...state,
    isMeetingAgendaModalOpen: false,
  }),

  [CLOSE_MEETING_DETAILS_MODAL]: (state) => ({
    ...state,
    isMeetingDetailsModalOpen: false,
  }),

  [OPEN_MEETING_AGENDA_MODAL]: (state) => ({
    ...state,
    isMeetingAgendaModalOpen: true,
  }),

  [OPEN_MEETING_DETAILS_MODAL]: (state) => ({
    ...state,
    isMeetingDetailsModalOpen: true,
  }),

  [SET_ACTIVE_MEETING_PARTICIPANTS]: (state, action) => ({
    ...state,
    activeMeetingParticipants: action.payload,
  }),
};

function videoChat(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default videoChat;
