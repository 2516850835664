import requests from 'api/requests';

import { isEmpty } from 'shared/utility';

import { ROLE_LABELS, ROLE_COLORS } from 'constants/realtor.constants';

const getInitials = (firstName = '', lastName = '') => {
  const fname = firstName ? firstName.split('')[0] || '' : '';
  const lname = lastName ? lastName.split('')[0] || '' : '';

  return fname + lname;
};

const getFullname = (firstName = '', lastName = '') => `${firstName || ''} ${lastName || ''}`.trim();

/* eslint-disable camelcase */
const processData = (leads = []) => leads.map((lead = {}) => {
  const {
    id,
    contact_first_name,
    contact_last_name,
    properties,
    property_requirements,
  } = lead;

  const fullName = getFullname(contact_first_name, contact_last_name);
  const initials = getInitials(contact_first_name, contact_last_name);

  let leadRole;
  if (!isEmpty(properties) && !isEmpty(property_requirements)) {
    leadRole = 3;
  } else if (!isEmpty(properties)) {
    leadRole = 2;
  } else if (!isEmpty(property_requirements)) {
    leadRole = 1;
  }

  const labels = leadRole
    ? [{ name: ROLE_LABELS[leadRole], color: ROLE_COLORS[leadRole] }]
    : [];

  return {
    id,
    fullName,
    initials,
    labels,
  };
});
/* eslint-enable camelcase */

// eslint-disable-next-line consistent-return
const searchLeads = (value) => async (dispatch) => {
  try {
    const response = await requests.realtor.leads.search(value);

    return processData(response.data.items);
  } catch (error) {
    console.error(error);
  }
};

export default searchLeads;
