import connectReducers from 'store/connectReducers';
import * as NOTIFICATIONS from 'store/actions/notifications'; // eslint-disable-line import/no-cycle

const initialState = {
  drawerIsVisible: false,
  counterNew: 0,
  total: 0,
  headMenu: [
    { key: 'allApps', isActive: true },
    {
      key: 'config', isActive: false, icon: 'cog', typeId: 1,
    },
    {
      key: 'headphones', isActive: false, icon: 'headphones-alt', typeId: 3,
    },
    {
      key: 'users', isActive: false, icon: 'users', typeId: 2,
    },
    {
      key: 'edit', isActive: false, icon: 'file-alt', typeId: 4,
    },
    {
      key: 'dollar', isActive: false, icon: 'dollar-sign', typeId: 5,
    },
  ],
};

const reducers = {
  [NOTIFICATIONS.NOTIFICATIONS_DRAWER_SHOW]: (state) => ({
    ...state,
    drawerIsVisible: true,
  }),
  [NOTIFICATIONS.NOTIFICATIONS_DRAWER_HIDE]: (state) => ({
    ...state,
    drawerIsVisible: false,
  }),
  [NOTIFICATIONS.NOTIFICATIONS_DRAWER_SET_ITEMS]: (state, action) => {
    const { items, total, push } = action;
    let itemsNext = [];
    if (push) {
      const { items: itemsOld } = state;

      itemsNext = [...itemsOld, ...items];
    } else {
      itemsNext = items;
    }

    let stateNext = {
      ...state,
      items: itemsNext,
    };

    if (total !== undefined) {
      stateNext = {
        ...stateNext,
        total,
      };
    }

    return stateNext;
  },
  [NOTIFICATIONS.NOTIFICATIONS_GET_COUNTER_NEW]: (state, action) => {
    const { counterNew, counterNewByType } = action;
    return {
      ...state,
      counterNew,
      counterNewByType,
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_ITEMS_UNSHIFT]: (state, action) => {
    const { data } = action;
    const { ProductTypeID } = data;
    const { items = [], counterNewByType } = state;
    let { counterNew = 0, total = 0 } = state;
    counterNew += 1;
    total += 1;

    let {
      [ProductTypeID]: {
        value,
      } = {},
    } = counterNewByType;

    value += 1;

    return {
      ...state,
      counterNew,
      total,
      counterNewByType: {
        ...counterNewByType,
        [ProductTypeID]: {
          ID: ProductTypeID,
          value,
        },
      },
      items: [
        {
          ...data,
          DateView: null,
          DateRead: null,
          DateDelete: null,
        },
        ...items,
      ],
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_DRAWER_SET_TYPES]: (state, action) => {
    const { types } = action;
    return {
      ...state,
      types,
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_DELETE_ITEM]: (state, action) => {
    const { id } = action;
    const { items = [] } = state;
    let { total, counterNew, counterNewByType: counterNewByTypeNext } = state;
    let typeId = null;

    items.forEach(({ ID, DateView, ProductTypeID }) => {
      if (!DateView && ID === id && counterNew > 0) {
        counterNew -= 1;
        typeId = ProductTypeID;
      }
    });

    if (total > 0) {
      total -= 1;
    }

    if (typeId) {
      let {
        [typeId]: {
          value,
        } = {},
      } = counterNewByTypeNext;
      value -= 1;

      counterNewByTypeNext = {
        ...counterNewByTypeNext,
        [typeId]: {
          value,
        },
      };
    }

    return {
      ...state,
      items: items.filter(({ ID }) => ID !== id),
      total,
      counterNew,
      counterNewByType: counterNewByTypeNext,
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_TOGGLE_READ]: (state, action) => {
    const { id } = action;
    const { items = [] } = state;
    return {
      ...state,
      items: items.map((el) => {
        const { ID, DateRead } = el;

        if (id === ID) {
          if (!DateRead) {
            return { ...el, DateRead: 1 };
          }
          return { ...el, DateRead: null };
        }

        return el;
      }),
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_MARK_ALL_AS_READ]: (state) => {
    const { items = [] } = state;
    return {
      ...state,
      items: items.map((el) => {
        let { DateRead } = el;
        DateRead = 1;
        return { ...el, DateRead };
      }),
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_CLEAR_ACTIVE_FILTERS]: (state) => {
    const { headMenu } = state;
    return {
      ...state,
      headMenu: headMenu.map((el) => {
        const { key } = el;
        if (key === 'allApps') {
          return { ...el, isActive: true };
        }
        return { ...el, isActive: false };
      }),
    };
  },
  [NOTIFICATIONS.NOTIFICATIONS_TOGGLE_FILTER_ITEM]: (state, action) => {
    const { itemsKey } = action;
    const { headMenu } = state;
    let count = 0;
    let stateNext = {
      ...state,
      headMenu: headMenu.map((el) => {
        const { isActive, key } = el;
        if (key === itemsKey) {
          if (!isActive === true) {
            count += 1;
          }
          return { ...el, isActive: !isActive };
        }
        if (key !== 'allApps' && isActive) {
          count += 1;
        }
        return el;
      }),
    };

    if (count > 0) {
      const { headMenu: headMenuNew } = stateNext;
      stateNext = {
        ...stateNext,
        headMenu: headMenuNew.map((el) => {
          const { key, isActive } = el;
          if (key === 'allApps' && isActive) {
            return { ...el, isActive: !isActive };
          }
          return el;
        }),
      };
    } else {
      const { headMenu: headMenuNew } = stateNext;
      stateNext = {
        ...stateNext,
        headMenu: headMenuNew.map((el) => {
          const { key } = el;
          if (key === 'allApps') {
            return { ...el, isActive: true };
          }
          return el;
        }),
      };
    }

    return stateNext;
  },
};

function notifications(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default notifications;
