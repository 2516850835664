import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from 'shared/components/CRM/UI/Modal';
import NewSourceForm from '../NewSourceForm';

const SourceDescriptionModal = (props) => {
  const {
    isVisible,
    onCancel,
    onSave,
    closeModal,
  } = props;

  const handleSave = useCallback(() => {
    onSave();
    closeModal();
  });

  const handleCancel = useCallback(() => {
    onCancel();
    closeModal();
  });

  return (
    <Modal
      width={400}
      visible={isVisible}
      okText="Save"
      onOk={handleSave}
      onCancel={handleCancel}
    >
      <NewSourceForm />
    </Modal>
  );
};

SourceDescriptionModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
} = PropTypes;

SourceDescriptionModal.propTypes = {
  isVisible: bool,
  onCancel: func.isRequired,
  onSave: func.isRequired,
  closeModal: func.isRequired,
};

export default SourceDescriptionModal;
