import { createSelector } from 'reselect';

import { forOwn } from 'shared/utility';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

import {
  optimizedDealsActions,
} from 'store/actions/deals';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.deals.dealProducts,
  (_, dealId) => dealId,
  (products, dealProducts, dealId) => {
    const selectedProducts = dealProducts[dealId];

    if (!selectedProducts) {
      optimizedDealsActions.getDealProducts(dealId);
      return [];
    }

    const items = [];

    forOwn(selectedProducts, (product, key) => {
      const dataObject = products[product.product_id];

      if (!dataObject && product.product_id) {
        optimizedCrmProductsActions.getProductById(product.product_id);
      }

      items.push({
        ...product,
        rowId: product.id,
        price_per_unit: +product.price_per_unit || 0,
        quantity: product.quantity || product.quantity === 0
          ? +product.quantity
          : 1,
        title: dataObject?.name,
      });
    });

    return items;
  },
);
