import {
  SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD,
} from 'store/actionTypes/realtorContacts';

const setNewContactLogFormField = (key) => (value) => ({
  type: SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD,
  payload: {
    key,
    value,
  },
});

export default setNewContactLogFormField;
