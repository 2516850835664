import { SET_MEETING_FOR_INVITATIONS } from 'store/actionTypes/call';

const setMeetingForInvitations = (payload) => (dispatch) => {
  dispatch({
    type: SET_MEETING_FOR_INVITATIONS,
    payload,
  });
};

export default setMeetingForInvitations;
