import styled from 'styled-components';

export const Title = styled.h2`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: rgba(0, 25, 52, 0.87);
`;

export const Subtitle = styled.p`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #34495e;
`;

export default {
  Title,
  Subtitle,
};
