import styled from 'styled-components';

export const Root = styled.label`
  height: 24px;
  width: 24px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-color: ${(props) => {
    if (props.checked) {
      return props.theme?.palette?.focus || '#367BED';
    }
      return props.theme?.palette?.background || 'white';
  }};
  border: ${(props) => {
    if (props.disabled) {
      return `1px solid ${props.theme?.palette?.disabled || 'gray'}`;
    }

    if (props.checked) {
      return 'none';
    }
      return `1px solid ${props.theme?.palette?.['input-border'] || '#d8e5ff'}`;
  }};
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Checkmark = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: white;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default {
  Root,
  Input,
  Checkmark,
};
