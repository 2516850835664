import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import ConfirmOfferAcceptForm from 'sections/Offers/forms/ConfirmOfferAcceptForm';

const formId = 'confirm-offer-accept-form';

const ConfirmOfferAcceptModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
  } = props;

  return (
    <Modal
      theme={theme}
      width={770}
      visible={isVisible}
      onCancel={closeModal}
      title="Start transaction for this property?"
      footer={[
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={formId}
        >
          Start transaction
        </Button>,
      ]}
    >
      <ConfirmOfferAcceptForm
        formId={formId}
      />
    </Modal>
  );
};

ConfirmOfferAcceptModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

ConfirmOfferAcceptModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
};

export default ConfirmOfferAcceptModal;
