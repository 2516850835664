/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment, react/prop-types */

import React from 'react';
import Typography from 'ui/Typography';

import {
  Root,
  Tag,
  TagWrapper,
} from './styledItems';

const withTags = (tagProps) => (Component) => (props) => {
  const {
    tags = [],
    offset = [0, 0],
    position = 'top-left',
    rotateDeg = 0,
    fullWidth = false,
    isBordered = true,
    borderRadius = {
      topLeft: 3,
      topRight: 3,
      bottomLeft: 3,
      bottomRight: 3,
    },
  } = tagProps;

  return (
    <Root>
      <TagWrapper
        fullWidth={fullWidth}
        rotateDeg={rotateDeg}
        offset={offset}
        position={position}
      >
        {
          tags.map(({ content, color }) => {
            if (typeof content === 'string') {
              return (
                <Tag
                  fullWidth={fullWidth}
                  color={color}
                  borderRadius={borderRadius}
                  isBordered={isBordered}
                >
                  <Typography
                    variant="subtitle2"
                    overrideColor="#ffffff"
                    align="center"
                    weight={400}
                    noMargin
                  >
                    {content}
                  </Typography>
                </Tag>
              );
            }

            return (
              <Tag color={color}>
                {content}
              </Tag>
            );
          })
        }
      </TagWrapper>

      <Component
        {...props}
      />
    </Root>

  );
};

export default withTags;
