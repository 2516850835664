export const SET_REALTOR_REPAIR_REQUEST = 'SET_REALTOR_REPAIR_REQUEST';
export const SET_REALTOR_REPAIR_REQUESTS = 'SET_REALTOR_REPAIR_REQUESTS';
export const SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER = 'SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER';
export const SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER_COUNT = 'SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER_COUNT';
export const SET_REALTOR_SELECTED_REPAIR_REQUEST_ROWS = 'SET_REALTOR_SELECTED_REPAIR_REQUEST_ROWS';
export const SET_REALTOR_REPAIR_REQUESTS_FILTER_ITEM = 'SET_REALTOR_REPAIR_REQUESTS_FILTER_ITEM';
export const RESET_REALTOR_REPAIR_REQUESTS_FILTER_ITEM = 'RESET_REALTOR_REPAIR_REQUESTS_FILTER_ITEM';
export const CLEAR_REALTOR_REPAIR_REQUESTS_FILTER = 'CLEAR_REALTOR_REPAIR_REQUESTS_FILTER';

export const SET_REALTOR_REPAIR_REQUESTS_MODAL_OPEN = 'SET_REALTOR_REPAIR_REQUESTS_MODAL_OPEN';
export const SET_REALTOR_REPAIR_REQUESTS_MODAL_CLOSED = 'SET_REALTOR_REPAIR_REQUESTS_MODAL_CLOSED';

export const SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_OPEN = 'SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_OPEN';
export const SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_CLOSED = 'SET_REALTOR_REPAIR_REQUEST_NOTES_MODAL_CLOSED';

export const SET_REALTOR_REPAIR_REQUEST_ATTACHMENTS = 'SET_REALTOR_REPAIR_REQUEST_ATTACHMENTS';

export const SET_NEW_REALTOR_REPAIR_REQUEST_MODAL_OPEN = 'SET_NEW_REALTOR_REPAIR_REQUEST_MODAL_OPEN';
export const SET_NEW_REALTOR_REPAIR_REQUEST_MODAL_CLOSE = 'SET_NEW_REALTOR_REPAIR_REQUEST_MODAL_CLOSE';

export const SET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE = 'SET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE';
export const RESET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE = 'RESET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE';
export const SET_NEW_REALTOR_REPAIR_REQUEST_FORM_ATTACHMENTS = 'SET_NEW_REALTOR_REPAIR_REQUEST_FORM_ATTACHMENTS';

export const DELETE_REALTOR_REPAIR_REQUEST = 'DELETE_REALTOR_REPAIR_REQUEST';
