import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getDigitalAccounts from './getDigitalAccounts';
import getDigitalAccountById from './getDigitalAccountById';

import createDigitalAccount from './createDigitalAccount';

import updateDigitalAccount from './updateDigitalAccount';

export { getDigitalAccounts };
export { getDigitalAccountById };

export { createDigitalAccount };

export { updateDigitalAccount };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCustomerDigitalAccountsActions = {
  getDigitalAccountById: memoizeDebounce(
    (id) => store.dispatch(getDigitalAccountById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
