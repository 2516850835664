import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchContactInput from './SearchContactInput';

const mapStateToProps = (state, ownProps) => {
  const {
    selectedContactId,
  } = ownProps;

  const selectedContact = selectedContactId === 0
    ? selectors.users.currentUser(state)
    : selectors.realtorContacts.byId(state, selectedContactId);

  return {
    selectedContact: selectedContactId === 0
      ? {
        first_name: selectedContact?.first_name,
        last_name: selectedContact?.last_name,
      }
      : selectedContact.id
        ? selectedContact
        : {},
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactInput);
