/* eslint-disable react/require-default-props */

import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const InviteUsersForm = (props, ref) => {
  const {
    form,
    inputClassName,
    onSubmit,
  } = props;

  useImperativeHandle(ref, () => ({
    form,
  }));

  return (
    <Form onSubmit={onSubmit}>
      <Form.Item>
        {
          form.getFieldDecorator('emails', {
            rules: [
              {
                required: true,
                message: 'Enter at least one email address.',
              },
            ],
          })(<Input
            placeholder="Enter comma separated email addresses"
            className={inputClassName}
          />)
        }
      </Form.Item>
    </Form>
  );
};

InviteUsersForm.propTypes = {
  inputClassName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

export default (
  Form.create({
    name: 'invite-users-form-modal',
  })(forwardRef(InviteUsersForm))
);
