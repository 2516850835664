import {
  SET_REFUND,
} from 'store/actionTypes/refunds';

const set = (payload) => ({
  type: SET_REFUND,
  payload,
});

export default set;
