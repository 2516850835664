import styled from 'styled-components';

export const Wrapper = styled.div`
  * {
    font-family: ${(props) => props?.theme?.typography?.fontFamily?.default};
    font-size: 15px;
    font-weight: 400;
    button[disabled] {
      background-color: ${(props) => props?.theme?.palette?.disabled}
    }
  }
  .react-datetime-picker {
    &__wrapper {
      border: none;
    }
    &__inputGroup {
      &__input,
      &__leadingZero {
        &.focus-visible {
          outline: none
        }
        color: ${(props) => props?.theme?.palette?.['main-text']};
      }
    }
  }
  .react-calendar {
    border: none;
    box-shadow: ${(props) => props.theme?.shadows?.elevated || '0 0 5px rgba(0, 0, 0, 0.5)'};
    border-radius: 10px;
    &__tile {
      &--now {
        background-color: ${(props) => props?.theme?.palette?.['focus-inversed']};
        border-radius: 8px;
      }
      &:enabled:hover {
        background-color: ${(props) => props?.theme?.palette?.['focus-inversed']};
        border-radius: 8px;
      }
      &--active {
        background-color: ${(props) => props?.theme?.palette?.focus};
        border-radius: 8px;
      }
    }
    abbr {
      text-decoration: none;
      text-transform: capitalize;
    }
    &__month-view {
      &__days {
        &__day {
          &--weekend {
            color: ${(props) => props?.theme?.palette?.weekend}
          }
        }
      }
    }
  }
`;

export default {
  Wrapper,
};
