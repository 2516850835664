import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_OPPORTUNITIES_FILTER_ITEM,
  RESET_REALTOR_OPPORTUNITIES_FILTER_ITEM,
  CLEAR_REALTOR_OPPORTUNITIES_FILTER,
} from 'store/actionTypes/realtorOpportunities';

import { setValue } from 'store/utils';

const initialState = {
  sortBy: 'id',
  sortDirection: 'desc',
  pageNumber: 1,
  pageSize: 10,
  search: null,
  status: null,
  type: null,
};

const reducers = {
  [SET_REALTOR_OPPORTUNITIES_FILTER_ITEM]: setValue,

  [RESET_REALTOR_OPPORTUNITIES_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload]: initialState[action.payload],
  }),

  [CLEAR_REALTOR_OPPORTUNITIES_FILTER]: (state, action) => initialState,
};

function opportunitiesFilter(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default opportunitiesFilter;
