import { connect } from 'react-redux';
import selectors from 'store/selectors';

import { updateCustomizationSettings } from 'store/actions/appCustomization';

import Salutations from './Salutations';

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    salutationsState: settings.salutations_on ? 'on' : 'off',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSalutationsStateChange: (value) => {
    dispatch((updateCustomizationSettings({
      salutations_on: value === 'on',
    })));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Salutations);
