import apiConnector, { endpoints } from 'api';

const checkCredentials = async (body) => {
  const endpoint = endpoints.global.auth.login;

  try {
    const result = await apiConnector.global.post(endpoint, body);

    return result?.data;
  } catch (error) {
    console.error(error);

    if (error?.response) {
      return error.response?.data
    } else {
      throw(error)
    }
  }
};

export default checkCredentials;
