import styled from 'styled-components';

const positions = {
  'top left': '0 auto auto 0',
  'top right': '0 0 auto auto',
  'bottom left': 'auto auto 0 0',
  'bottom right': 'auto 0 0 auto',
};

export const Wrapper = styled.div`
  position: relative;
`;

export const TagsContainer = styled.div`
  display: flex;
  z-index: 1000;
  position: absolute;
  margin: 5px;
  column-gap: 2px;
  inset: ${(props) => positions[props.position] || positions['top left']};
  `;

export default {
  Wrapper,
};
