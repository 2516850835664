import {
  SET_REALTOR_INSPECTIONS_FOR_FILTER,
} from 'store/actionTypes/realtorInspections';

const setInspectionsForFilter = (filterId) => (inspections) => ({
  type: SET_REALTOR_INSPECTIONS_FOR_FILTER,
  payload: {
    filterId,
    inspections,
  },
});

export default setInspectionsForFilter;
