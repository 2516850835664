import requests from 'api/requests';

import setPropertyViewings from './setPropertyViewings';
import setViewingsForFilter from './setViewingsForFilter';
import setViewingsForFilterCount from './setViewingsForFilterCount';

const getViewingsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.propertyViewings.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setPropertyViewings(response.data.items));
    dispatch(setViewingsForFilter(filterId)(response.data.items));
    dispatch(setViewingsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getViewingsForFilter;
