import {
  SET_REALTOR_SELECTED_CLIENT,
} from 'store/actionTypes/realtorClients';

const setSelectedClient = (clientId) => ({
  type: SET_REALTOR_SELECTED_CLIENT,
  payload: clientId,
});

export default setSelectedClient;
