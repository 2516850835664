import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/leadIntoProspectModal';

// import { updateProperty } from 'store/actions/properties';
// import { updatePropertyRequirement } from 'store/actions/propertyRequirements';

import LeadIntoProspectModal from './LeadIntoProspectModal';

const mapStateToProps = (state) => {
  const {
    leadIntoProspectModal: {
      isVisible,
      leadId,
      connectedItemType,
      connectedItemId,
    },
  } = state;

  return {
    isVisible,
    leadId,
    connectedItemType,
    connectedItemId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCancel: () => {
    dispatch(closeModal());
  },
  onChange: (itemType, itemId, leadId) => {
    // if (itemType === 'properties') {
    //   dispatch(updateProperty(itemId, leadId)({ status: 2 }));
    // }
    // if (itemType === 'property_requirements') {
    //   dispatch(updatePropertyRequirement(itemId, leadId)({ status: 2 }));
    // }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onCancel: dispatchProps.onCancel,
  onSave: () => {
    const {
      connectedItemType,
      connectedItemId,
      leadId,
    } = stateProps;

    dispatchProps.onChange(connectedItemType, connectedItemId, leadId);

    dispatchProps.onCancel();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LeadIntoProspectModal);
