/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Spin } from 'antd';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import OfferDetails from 'shared/components/OfferDetails';

import PropertyDetails from 'shared/modules/PropertyDetails';
import ContactCard from 'shared/modules/ContactCard';
import LessContactCard from 'shared/modules/LessContactCard';
import OffersContext from 'sections/OffersV2/context/OffersContext';
import LabeledDate from './LabeledDate';
import AttachmentsUpload from './AttachmentsUpload';

import {
  SectionWrapper,
} from './styledItems';

const ConfirmOfferAcceptForm = (props) => {
  const {
    onSubmit: propsOnSubmit,
    formId,
    offerId,
    contactId,
    propertyId,
    contactType,
    buyer,
    buyerAgent,
    date,
  } = props;

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { getAllOffers } = useContext(OffersContext);

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await propsOnSubmit(data);
    await getAllOffers();
    reset();
    setIsLoading(false);
  }, [
    propsOnSubmit,
    reset,
  ]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Spin spinning={isLoading}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <DecoratedFieldWrapper
              iconName="check-circle"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Property Details
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <PropertyDetails
                contactId={contactId}
                contactType={contactType}
                propertyId={propertyId}
              />
            </SectionWrapper>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DecoratedFieldWrapper
              iconName="dollar-sign"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Offer Details
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <OfferDetails
                offerId={offerId}
                relatedContacts={[
                  <ContactCard
                    contactId={buyerAgent}
                    date={date}
                  />,
                  <LessContactCard
                    contactId={buyer}
                    role="Buyer"
                  />,
                ]}
              />
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="calendar"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Transaction Expiry Dates
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <Flex spacing={3}>
                <Flex
                  className="date-field-flex-container"
                  flexDirection="column"
                  alignItems="stretch"
                >
                  <LabeledDate
                    formName="sellerDisclosure"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Seller Disclosure"
                  />
                  <LabeledDate
                    formName="appraisal"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Appraisal"
                  />
                  <LabeledDate
                    formName="walkthrough"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Walkthrough"
                  />
                  <LabeledDate
                    formName="moveInDate"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Move In Date"
                  />
                </Flex>
                <Flex
                  className="date-field-flex-container"
                  flexDirection="column"
                  alignItems="stretch"
                >
                  <LabeledDate
                    formName="inspection"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Inspection"
                  />
                  <LabeledDate
                    formName="loan"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Loan"
                  />
                  <LabeledDate
                    formName="closeEscrow"
                    inputLabel="Enter date"
                    getValues={getValues}
                    setValue={setValue}
                    control={control}
                    title="Close Escrow"
                  />
                </Flex>
              </Flex>
            </SectionWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="edit3"
              iconType="feather"
              variant="highlight"
              noMargin
            >
              <Typography
                variant="title2"
                noMargin
              >
                Final Signed Agreement
              </Typography>
            </DecoratedFieldWrapper>

            <SectionWrapper>
              <AttachmentsUpload
                getValues={getValues}
                setValue={setValue}
                control={control}
              />
            </SectionWrapper>
          </Grid>

        </Grid>
      </Spin>
    </form>
  );
};

ConfirmOfferAcceptForm.defaultProps = {
  offerId: null,
  date: '',
  contactId: null,
  contactType: null,
  propertyId: null,
  buyer: null,
  buyerAgent: null,
};

const {
  func,
  string,
  number,
} = PropTypes;

ConfirmOfferAcceptForm.propTypes = {
  onSubmit: func.isRequired,
  formId: string.isRequired,
  offerId: number,
  date: string,
  contactId: number,
  contactType: string,
  propertyId: number,
  buyer: number,
  buyerAgent: number,
};

export default ConfirmOfferAcceptForm;
