/* eslint-disable import/no-cycle */
import byId from './byId';
import repairRequestsList from './repairRequestsList';
import attachments from './attachments';

export default {
  byId,
  repairRequestsList,
  attachments,
};
