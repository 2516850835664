import apiConnector, { endpoints } from 'api';

const getThread = (chatId) => async (offset, limit) => {
  const endpoint = endpoints.client.chat.general;

  const result = await apiConnector.client.get(`${endpoint}/${chatId}`, { offset, limit });
  return result;
};

export default getThread;
