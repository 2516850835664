import { createSelector } from 'reselect';

const companies = (state) => state.crmCompanies.companies;

export default createSelector(
  companies,
  (items) => (items
    ? Object.values(items).map((item) => ({
      id: item.CompanyID,
      title: item.Title,
      name: item.Title,
      logoSrc: item.LogoUrl,
    }))
    : null),
);
