/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Spin } from 'antd';

import Container from 'ui/Container';
import Item from 'ui/Item';

import Typography from 'ui/Typography';

import ListingPrice from './ListingPrice';
import ListingDates from './ListingDates';
import ListingTerms from './ListingTerms';
import Commission from './Commission';
import HoldOverPeriod from './HoldOverPeriod';
import AttachmentsUpload from './AttachmentsUpload';

const NewClientForm = (props) => {
  const {
    onSubmit: propsOnSubmit,
    address,
    propertyPrice,
    formId,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [commissionTypes, setCommissionTypes] = useState({
    seller_agent_commission: 1,
    buyer_agent_commission: 1,
    coop_commission: 1,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await propsOnSubmit({
      ...data,
      seller_agent_commission_type: commissionTypes.seller_agent_commission,
      buyer_agent_commission_type: commissionTypes.buyer_agent_commission,
      coop_commission_type: commissionTypes.coop_commission,
    });
    reset();
    setIsLoading(false);
  }, [
    propsOnSubmit,
    reset,
    commissionTypes,
  ]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Spin spinning={isLoading} delay={600}>
        <Container spacing={4}>
          <Item xs={12}>
            <Typography
              noMargin
              variant="h4"
              weight={600}
            >
              {address}
            </Typography>
          </Item>

          <Item xs={12}>
            <ListingPrice
              control={control}
              errors={errors}
              propertyPrice={propertyPrice}
            />
          </Item>

          <Item xs={12}>
            <ListingDates
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
            />
          </Item>

          <Item xs={12}>
            <ListingTerms
              control={control}
            />
          </Item>

          <Item xs={12}>
            <Commission
              control={control}
              errors={errors}
              commissionTypes={commissionTypes}
              onTypeChange={setCommissionTypes}
            />
          </Item>

          <Item xs={12}>
            <HoldOverPeriod
              control={control}
            />
          </Item>

          <Item xs={12}>
            <AttachmentsUpload />
          </Item>

        </Container>
      </Spin>
    </form>
  );
};

NewClientForm.defaultProps = {
  address: '',
  propertyPrice: null,
};

const {
  func,
  string,
  number,
} = PropTypes;

NewClientForm.propTypes = {
  onSubmit: func.isRequired,
  onPropertySelect: func.isRequired,
  contactName: string.isRequired,
  leadId: number.isRequired,
  propertyId: number.isRequired,
  propertyPrice: number,
  address: string,
  formId: string.isRequired,
};

export default NewClientForm;
