import create from './create';
import get from './get';
import getById from './getById';
import update from './update';
import getSummary from './getSummary';
import getChangelog from './getChangelog';
import claim from './claim';
import unclaim from './unclaim';
import deleteLead from './deleteLead';
import getByContactId from './getByContactId';
import addRelations from './addRelations';
import removeRelations from './removeRelations';
import search from './search';
import getAll from './getAll';

export default {
  getAll,
  create,
  get,
  getById,
  update,
  getSummary,
  getChangelog,
  claim,
  unclaim,
  deleteLead,
  addRelations,
  removeRelations,
  getByContactId,
  search,
};
