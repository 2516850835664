import apiConnector, { endpoints } from 'api';

/**
 * Sends POST request with payload to create "meeting invitations".
 * @param {Object} params - The request params.
 * @param {number} params.meetingId - The id of parent meeting.
 * @param {Object} params.payload - The useful data for create invitations.
 * @param {Array.<String>} params.payload.emails - The list of invited users.
 * @param {string} params.payload.userCompany - The company of logged user.
 * @param {string} params.payload.userToken - The token of logged user.
 * @returns {Promise} A response from server.
 */

const createInvitations = async ({ meetingId, payload }) => {
  const endpoint = endpoints.OA.meetingInvitationsPath(meetingId);
  let response;

  try {
    response = await apiConnector.OA.post(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default createInvitations;
