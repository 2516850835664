import requests from 'api/requests';

import setLead from './setLead';

const getLeadById = (leadId) => async (dispatch) => {
  try {
    const response = await requests.realtor.leads.getById(leadId);

    dispatch(setLead(response.data));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default getLeadById;
