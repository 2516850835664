import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

const OnboardingStep = (props) => {
  const {
    iconName,
    title,
    description,
    children,
  } = props;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      <Icon
        name={iconName}
        type="custom"
        size={136}
      />

      <Typography
        variant="heading"
        weight="bold"
        align="center"
        noMargin
      >
        {title}
      </Typography>

      {
        typeof description === 'string' ? (
          <Typography
            variant="title2"
            weight="normal"
            align="center"
            noMargin
          >
            {description}
          </Typography>
        ) : description
      }

      {children}
    </Flex>
  );
};

const {
  element,
  oneOf,
  string,
} = PropTypes;

OnboardingStep.propTypes = {
  iconName: string.isRequired,
  title: string.isRequired,
  description: oneOf([string, element]).isRequired,
  children: element.isRequired,
};

export default OnboardingStep;
