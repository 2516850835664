import requests from 'api/requests';

import setOverallLeadsSummary from './setOverallLeadsSummary';

const getOverallLeadsSummary = () => async (dispatch) => {
  try {
    const response = await requests.crm.leads.getSummary();
    dispatch(setOverallLeadsSummary(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOverallLeadsSummary;
