import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  loginRequest,
  setUserReset,
  setExternalCredentials,
} from 'store/actions/auth';

import Login from './Login';

const mapStateToProps = (state) => ({
  isFetching: state.auth.isFetching,
  workspaces: state.auth.workspaces,
  isAuthenticated: state.auth.isAuthenticated,
  activeTab: state.auth.activeTab,
  recoverModal: state.auth.recoverModal,
  token: state.auth.tempToken,
  error: state.auth.error,
});

const mapDispatchToProps = {
  loginRequest,
  setUserReset,
  setExternalCredentials,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Login);
