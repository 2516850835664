import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import pluralize from 'pluralize';

import Typography from 'ui/Typography';
import UserAvatar from 'shared/components/UserAvatarV2';
import UserAvatarArray from 'shared/components/UserAvatarArray';

import './CommentReply.scss';

const CommentReply = (props) => {
  const {
    className,
    commentators,
    onClickItemAvatar,
    onClickReplyCount,
    replyCount,
  } = props;

  return (
    <div
      className={classnames(
        'comment-replyv2-root',
        {
          [className]: className,
        },
      )}
    >
      <UserAvatarArray
        onClick={onClickItemAvatar}
        offsetItem={10}
        defaultOffset={0}
        className="comment-replyv2__avatar-array"
      >
        {
          commentators.slice(0, 3).map((commentator) => (
            <UserAvatar
              key={`${commentator.id}-${commentator.type}`}
              userId={commentator.id}
              userType={commentator.type}
              tooltipPlacement="bottom"
              badgeVisibility="hidden"
              size="xxs"
              noBadge
            />
          ))
        }
      </UserAvatarArray>
      <div
        className="comment-replyv2__typography"
      >
        <Typography
          variant="caption"
          color="focus"
          onClick={onClickReplyCount}
          noMargin
        >
          {`${replyCount} ${pluralize('reply', replyCount)}`}
        </Typography>
      </div>
    </div>
  );
};

export default CommentReply;

CommentReply.defaultProps = {
  className: '',
  commentators: [],
  onClickItemAvatar: () => { },
  onClickReplyCount: () => { },
};

const {
  array,
  func,
  number,
  string,
} = PropTypes;

CommentReply.propTypes = {
  className: string,
  commentators: array, // eslint-disable-line react/forbid-prop-types
  onClickItemAvatar: func,
  onClickReplyCount: func,
  replyCount: number.isRequired,
};
