import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import DropdownTextfield from 'ui/DropdownTextfield';

import {
  PROPERTY_TYPES_ARRAY,
} from 'constants/realtor.constants';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    label: 'Property Type',
    value: property?.property_type,
    options: PROPERTY_TYPES_ARRAY,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateProperty(ownProps.propertyId)({
      property_type: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownTextfield);
