import React, {
  useCallback,
  useContext,
} from 'react';

import { usePapaParse } from 'react-papaparse';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';
import FileDropzoneWithThumbs from 'ui/FileDropzoneWithThumbs';

import readFile from 'sections/DataImport/utils/readFile';

const dropzoneProps = {
  maxFiles: 1,
  accept: 'text/csv',
};

const Upload = (props) => {
  const context = useContext(DataImportContext);

  const {
    setPreviewTableData,
    setDelimiter,
    uploadFile,
    isFileBeingUploaded,
    currentFile,
    uploadedFileName,
    selectedTypes,
    listUploadedFiles,
    isPreviewDataLoading,
  } = context;

  const { readString } = usePapaParse();

  const handleUpload = useCallback(async (file) => {
    if (file && file?.length > 0) {
      try {
        await uploadFile(file[0]);

        readFile({
          readString,
          setPreviewTableData,
          setDelimiter,
        })(file[0]);

        listUploadedFiles();
      } catch (error) {
        console.error('file error', error);
      }
    }
  }, [
    setPreviewTableData,
    setDelimiter,
    selectedTypes,
    listUploadedFiles,
  ]);

  if (currentFile) {
    return (
      <>
        <Typography
          variant="title1"
          align="center"
          style={{ width: '100%' }}
        >
          { uploadedFileName }
        </Typography>
        {
          isPreviewDataLoading && (
            <Flex fullWidth justifyContent="center">
              <Spin />
            </Flex>
          )
        }
      </>
    );
  }

  return (
    <>
      <Typography variant="subtitle1">
        Upload any CSV file.
      </Typography>
      <FileDropzoneWithThumbs
        dropzoneProps={dropzoneProps}
        onChange={handleUpload}
        isLoading={isFileBeingUploaded}
      />
    </>
  );
};

export default Upload;
