import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Flex from 'ui/Flex';
// import Textfield from 'ui/Textfield';
// import Button from 'ui/Button';
import FileDropzoneWithThumbs from 'ui/FileDropzoneWithThumbs';

const Agreement = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeFiles,
      // value: files,
    },
  } = useController({
    name: 'agreementFiles',
    control,
  });

  const {
    field: {
      // onChange: onChangeUrl,
      value: docUrl,
    },
  } = useController({
    name: 'agreementUrl',
    control,
  });

  return (
    <Flex
      spacing={1}
      fullWidth
      flexDirection="column"
    >
      {/* {
        !files?.length && docUrl && (
          <Button
            onClick={() => onChangeUrl(null)}
            variant="secondary"
            typographyProps={{
              ellipsis: true
            }}
            icon="x"
            style={{
              maxWidth: 400
            }}
            iconPosition="right"
          >
            {docUrl}
          </Button>
        )
      }
      {
        !files?.length && !docUrl && (
          <Textfield
            fullWidth
            icon="link"
            iconPosition="right"
            placeholder="Paste document URL eg DotLoop, DocuSign and press Enter"
            onEnter={(event) => {
              onChangeUrl(event?.target?.value ? event.target.value.trim() : null);
            }}
          />
        )
      } */}
      {
        !docUrl && (
          <FileDropzoneWithThumbs
            text="Drop a file or click here to upload"
            onChange={onChangeFiles}
            dropzoneProps={{
              maxFiles: 1,
            }}
          />
        )
      }
    </Flex>
  );
};

const {
  shape,
} = PropTypes;

Agreement.propTypes = {
  control: shape({}).isRequired,
};

export default Agreement;
