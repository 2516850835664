import requests from 'api/requests';

const addLeadRelations = (relationType) => (leadId) => (properties) => async (dispatch) => {
  try {
    await requests.realtor.leads.addRelations(relationType)(leadId)(properties);
  } catch (error) {
    console.error(error);
  }
};

export default addLeadRelations;
