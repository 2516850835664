import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import CompanyNotices from 'shared/modals/CompanyNotices'; // eslint-disable-line import/no-cycle
import { sendMood } from 'store/actions/mood'; // eslint-disable-line import/no-cycle
import MoodUserModal from 'shared/modals/MoodUserModal/MoodUserModal'; // eslint-disable-line import/no-cycle

import {
  openChatDrawer as openChat,
  closeChatDrawer as closeChat,
} from 'store/actions/chat';

import NavIcons from './NavIcons'; // eslint-disable-line import/no-cycle

class NavIconsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notices: false,
      showUserMood: false,
      isRequiredMoodShowed: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { mood, moodSettings, moodLoaded } = this.props;
    const { isRequiredMoodShowed } = this.state;
    if (!isRequiredMoodShowed
      && moodLoaded
      && moodSettings.MoodSettingID
      && moodSettings.MoodSettingTime
      && moodSettings.MoodSettingDay !== -1
      && (moodSettings.MoodSettingDay === 0 || moodSettings.MoodSettingDay === moment().day())
      && (!mood.MoodID || moment(moodSettings.MoodSettingTime, 'HH:mm:ss').isAfter(mood.MoodDate))
      && moment(moodSettings.MoodSettingTime, 'HH:mm:ss').isBefore(moment())
    ) {
      this.setState(() => ({ showUserMood: true, isRequiredMoodShowed: true }));
    }
  }

  triggerNotices = () => this.setState((state) => ({ notices: !state.notices }));

  triggerUserMood = () => {
    this.setState((state) => ({ showUserMood: !state.showUserMood }));
  };

  onSubmitUserMood = (mood) => {
    const { sendMoodAction } = this.props;
    sendMoodAction(mood);
    this.setState(() => ({ showUserMood: false }));
  };

  onCancelUserMood = () => {
    this.setState(() => ({ showUserMood: false }));
  };

  render() {
    const {
      notices,
      showUserMood,
    } = this.state;
    const {
      children,
      user,
      mood,
      moodSettings,
      openChatDrawer,
      closeChatDrawer,
    } = this.props;
    return (
      <>
        <NavIcons
          trigger={this.triggerNotices}
          triggerUserMood={this.triggerUserMood}
          items={children}
          openChatDrawer={openChatDrawer}
          closeChatDrawer={closeChatDrawer}
        />

        <CompanyNotices
          visible={notices}
          onCancel={this.triggerNotices}
          onOk={this.triggerNotices}
        />
        <MoodUserModal
          key={mood.MoodID}
          visible={showUserMood}
          onSubmit={this.onSubmitUserMood}
          onCancel={this.onCancelUserMood}
          user={user}
          mood={mood}
          allowAnonymous={moodSettings.AllowAnonymous}
        />
      </>
    );
  }
}

NavIconsContainer.defaultProps = {
  children: null,
};

const {
  arrayOf,
  shape,
  func,
  bool,
  number,
  string,
} = PropTypes;

NavIconsContainer.propTypes = {
  sendMoodAction: func.isRequired,
  children: arrayOf(shape({})),
  user: shape({
    Name: string,
  }).isRequired,
  mood: shape({
    MoodType: number,
    MoodComment: string,
    HideUserInfo: bool,
  }).isRequired,
  moodSettings: shape({
    MoodSettingID: number,
    CompanyID: number,
    AllowAnonymous: bool,
    MoodSettingDay: number,
    MoodSettingTime: string,
  }).isRequired,
  moodLoaded: bool.isRequired,
  openChatDrawer: func.isRequired,
  closeChatDrawer: func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.loggedUser,
  mood: state.mood.userMood,
  moodSettings: state.mood.moodSettings,
  moodLoaded: state.mood.userMoodLoaded,
});

const mapDispatchToProps = {
  sendMoodAction: sendMood,
  openChatDrawer: openChat,
  closeChatDrawer: closeChat,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavIconsContainer);
