import apiConnector, { endpoints } from 'api';

const setChatUserStatus = async (chatId, employeeId, newUserChatStatus) => {
  const endpoint = `${endpoints.client.chat.members}/${chatId}`;

  await apiConnector.client.patch(endpoint, {
    user_type: 1,
    user_id: employeeId,
    newUserChatStatus,
  });
};

export default setChatUserStatus;
