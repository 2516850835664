import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { PROPERTY_DEAL_STATUSES } from 'constants/realtor.constants';

import { map } from 'shared/utility';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: '8px',
    marginBottom: '4px',
  },
}));

const PropertyStatusSelect = (props) => {
  const {
    value,
    handleChange,
  } = props;

  const onChange = useCallback((event) => {
    handleChange(event.target.value);
  }, [handleChange]);

  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      margin="dense"
      variant="outlined"
      fullWidth
    >
      <InputLabel id="property-status-select-label">Property Status</InputLabel>
      <Select
        value={value}
        defaultValue={value}
        onChange={onChange}
        label="Property Status"
        labelId="property-status-select-label"
      >
        {
          map(PROPERTY_DEAL_STATUSES, (label, key) => (
            <MenuItem value={key}>
              {label}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

PropertyStatusSelect.defaultProps = {
  value: undefined,
};

const {
  func,
  string,
} = PropTypes;

PropertyStatusSelect.propTypes = {
  handleChange: func.isRequired,
  value: string,
};

export default PropertyStatusSelect;
