import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Button from 'ui/Button';
import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Spin from 'ui/Spin';

import {
  Root,
  PrimaryValueContainer,
  CreateFieldContainer,
} from './styledItems';

const inputContainerStyle = { width: 285 };

const EditArea = (props) => {
  const {
    isLoading: isLoadingProps,
    onCreate,
    onChange,
    onMakePrimary,
    onRemove,
    primaryValue,
    additionalValues,
    dataTypeDisplayName,
    InputComponent,
    inputProps,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(isLoadingProps);
  }, [
    isLoadingProps,
  ]);

  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newValue, setNewValue] = useState(null);

  const onClickAdd = useCallback(() => {
    setIsAddingNew(true);
  }, []);

  const onClickCancelAdd = useCallback(() => {
    setIsAddingNew(false);
    setNewValue(null);
  }, []);

  const onCreateNew = useCallback(() => {
    if (onCreate) {
      onCreate(newValue, setIsLoading);
    }
    setIsAddingNew(false);
    setNewValue(null);
  }, [
    newValue,
    onCreate,
  ]);

  const handleChange = useCallback((id) => (value) => {
    if (onChange) {
      onChange(id, value, setIsLoading);
    }
  }, [
    onChange,
  ]);

  const handleMakePrimary = useCallback((id) => () => {
    if (onMakePrimary) {
      onMakePrimary(id, setIsLoading);
    }
  }, [
    onMakePrimary,
  ]);

  const handleRemove = useCallback((id) => () => {
    if (onRemove) {
      onRemove(id, setIsLoading);
    }
  }, [
    onRemove,
  ]);

  const InputField = InputComponent || Textfield;

  return (
    <Root>
      <Spin spinning={isLoading}>
        <div>
          <PrimaryValueContainer>
            <Typography
              weight="bold"
              color="contrast"
              noMargin
              style={{ marginBottom: 10 }}
            >
              {`Primary ${dataTypeDisplayName}`}
            </Typography>

            {
              primaryValue && (
                <InputField
                  {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                  value={primaryValue?.value}
                  onChange={handleChange(primaryValue?.id)}
                />
              )
            }
          </PrimaryValueContainer>

          {
            primaryValue && (
              <div>
                <Typography
                  weight="bold"
                  color="contrast"
                  noMargin
                  style={{ marginBottom: 10 }}
                >
                  {`Additional ${dataTypeDisplayName}s`}
                </Typography>
                <Flex
                  flexDirection="column"
                  spacing={2}
                  fullWidth
                >
                  {
                    additionalValues.map((item) => (
                      <Fragment key={item.id}>
                        <FlexItem>
                          <Flex
                            flexDirection="row"
                            spacing={1}
                          >
                            <FlexItem style={inputContainerStyle}>
                              <InputField
                                {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                                value={item.value}
                                onChange={handleChange(item.id)}
                              />
                            </FlexItem>
                            <FlexItem>
                              <Button
                                variant="secondary"
                                onClick={handleMakePrimary(item.id)}
                              >
                                Make primary
                              </Button>
                            </FlexItem>
                            <FlexItem>
                              <Button
                                icon="trash"
                                onClick={handleRemove(item.id)}
                              />
                            </FlexItem>
                          </Flex>
                        </FlexItem>
                      </Fragment>
                    ))
                  }
                </Flex>
              </div>
            )
          }

          <CreateFieldContainer>
            <>
              {
                !isAddingNew && (
                  <Button
                    icon="plus"
                    onClick={onClickAdd}
                  />
                )
              }
            </>

            <>
              {
                isAddingNew && (
                  <Flex
                    flexDirection="row"
                    spacing={1}
                  >
                    <FlexItem style={inputContainerStyle}>
                      <InputField
                        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                        value={newValue}
                        onChange={setNewValue}
                      />
                    </FlexItem>
                    <FlexItem>
                      <Button
                        onClick={onCreateNew}
                      >
                        Ok
                      </Button>
                    </FlexItem>
                    <FlexItem>
                      <Button
                        variant="secondary"
                        onClick={onClickCancelAdd}
                      >
                        Cancel
                      </Button>
                    </FlexItem>
                  </Flex>
                )
              }
            </>
          </CreateFieldContainer>

        </div>
      </Spin>
    </Root>
  );
};

EditArea.defaultProps = {
  isLoading: false,
  primaryValue: null,
  additionalValues: [],
  dataTypeDisplayName: 'email',
  InputComponent: null,
  inputProps: {},
};

const {
  bool,
  func,
  number,
  arrayOf,
  shape,
  string,
  oneOfType,
} = PropTypes;

const valuePropType = shape({
  id: number.isRequired,
  value: oneOfType([string, number]),
});

EditArea.propTypes = {
  isLoading: bool,
  onCreate: func.isRequired,
  onChange: func.isRequired,
  onMakePrimary: func.isRequired,
  onRemove: func.isRequired,
  primaryValue: valuePropType,
  additionalValues: arrayOf(valuePropType),
  dataTypeDisplayName: string,
  InputComponent: func,
  inputProps: shape({}),
};

export default EditArea;
