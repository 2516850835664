import requests from 'api/requests';

import setAgreements from './setAgreements';

const getAgreements = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.agreements.get({
      offset,
      limit,
    });

    dispatch(setAgreements(response.data.agreements));
  } catch (error) {
    console.error(error);
  }
};

export default getAgreements;
