import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import DateTimeInput from 'shared/inputs/DateTimeInput';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      firstContactDate,
    },
  } = state;

  return {
    value: firstContactDate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('firstContactDate')(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeInput);
