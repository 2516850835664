import connectReducers from 'store/connectReducers';

import {
  SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN,
  SET_PROPERTY_VIEWING_EDIT_MODAL_CLOSED,
} from 'store/actionTypes/propertyViewings';

const initialState = {
  isVisible: false,
  viewingId: null,
  buyerField: false,
  buyerAgentField: false,
  sellerAgentField: false,
};

const reducers = {
  [SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    viewingId: action.payload.viewingId,
    buyerField: !!action.payload.buyerField,
    buyerAgentField: !!action.payload.buyerAgentField,
    sellerAgentField: !!action.payload.sellerAgentField,
  }),

  [SET_PROPERTY_VIEWING_EDIT_MODAL_CLOSED]: () => initialState,
};

function editViewingModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default editViewingModal;
