import { callAlert } from 'ui/Alert';
import { url as pathUrl } from 'paths';

import { commentsModule } from 'constants';
import { // eslint-disable-line import/no-cycle
  apiConnector,
  customerToUrlConnector,
  getConnector,
  noContentTypeFetch as fileFetch,
  tokenFetch as fetch,
} from 'shared/API';

import { getFileList } from 'store/actions/attachments'; // eslint-disable-line import/no-cycle
import { noopFn } from 'shared/utils/index'; // eslint-disable-line import/no-cycle
import { getTasks } from 'store/actions/tasks'; // eslint-disable-line import/no-cycle

export const GET_CUSTOMER_TASK_BY_PASSWORDS = 'GET_CUSTOMER_TASK_BY_PASSWORDS';
export const getCustomerTaskByPasswords = (
  first,
  second,
  customToken,
) => (dispatch) => {
  getConnector(`/Tasks/ReturnTaskByPassword/${first}/${second}`, {
    token: customToken,
  })
    .then((res) => {
      dispatch(getCustomerTask(res.results[0].TaskID, customToken));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const GET_CUSTOMER_TASK_BY_URL = 'GET_CUSTOMER_TASK_BY_URL';
export const getCustomerTask = (id, token) => (dispatch) => getConnector(`/api/tasks/${id}`, { token })
  .then((task) => {
    dispatch(getCustomerTaskSuccess(task));
  })
  .catch((err) => callAlert.warning('Error occured'));

export const GET_CUSTOMER_TASK_BY_URL_SUCCESS = 'GET_CUSTOMER_TASK_BY_URL_SUCCESS';
export function getCustomerTaskSuccess(task) {
  return {
    type: GET_CUSTOMER_TASK_BY_URL_SUCCESS,
    task,
  };
}

export const GET_CUSTOMER_TASK_COMMENTS = 'GET_CUSTOMER_TASK_COMMENTS';
export function getCustomerTaskComments(id, isStandard = true) {
  return (dispatch) => {
    const connector = isStandard ? apiConnector : customerToUrlConnector;
    const url = `/api/comments/task/${id}`;

    return connector(url)
      .then((res) => {
        dispatch(getCustomerTaskCommentsSuccess(res));
        dispatch(getTasks());
      })
      .catch((err) => callAlert.warning('Error occured'));
  };
}

export const GET_CUSTOMER_TASK_COMMENTS_SUCCESS = 'GET_CUSTOMER_TASK_COMMENTS_SUCCESS';
export function getCustomerTaskCommentsSuccess(list) {
  return {
    type: GET_CUSTOMER_TASK_COMMENTS_SUCCESS,
    comments: list,
  };
}

export const customerTaskAddImg = (data, res, isStandard = true) => {
  const formData = new FormData();
  formData.append(commentsModule.ATTACHMENT, data.commentImg);

  return fileFetch(
    `${pathUrl}/api/attachments/workspace/${data.commentType}/${res.CommentID}`,
    {
      method: 'POST',
      body: formData,
    },
    isStandard,
  )
    .then(() => {
      getFileList(data.commentType, data.taskId, isStandard);
    })
    .catch(() => {});
};

export const customerTaskAddComment = (data, isStandard = true) => (dispatch) => {
  const connector = isStandard ? apiConnector : customerToUrlConnector;

  const {
    comment: Comment,
    // commentId,
    commentType,
    taskId,
    // customerId: CustomerID,
    // messageType,
    isPublic,
    ParentCommentID,
    ParentLogID,
  } = data;

  const CommentType = commentType || null;

  const body = {
    Comment,
    CommentType,
    // CustomerID,
    isAcknowledged: false,
    isPrivate: !isPublic,
    ParentCommentID,
    ParentLogID,
  };

  connector(`/api/comments/task/${taskId}`, 'POST', body)
    .then((res) => {
      if (Object.prototype.hasOwnProperty.call(data, 'commentImg') && data.commentImg !== null) {
        customerTaskAddImg(
          { ...data, commentType: 'task-comment' },
          res,
          isStandard,
        )
          .then(() => {
            dispatch(getCustomerTaskComments(data.taskId, isStandard));
          })
          .catch(noopFn);
      } else {
        dispatch(getCustomerTaskComments(data.taskId, isStandard));
      }
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log('ERROR', err));
};

export const customerTaskDeleteComment = (
  data,
  isStandard = true,
) => (dispatch) => {
  const {
    messageType,
    parentId,
    childId,
  } = data;

  fetch(
    `${pathUrl}/api/comments/${messageType}/${parentId}/${childId}`,
    {
      method: 'DELETE',
    },
    isStandard,
  )
    .then((res) => {
      dispatch(getCustomerTaskComments(data.taskId, isStandard));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const customerTaskReplyDeleteComment = (
  data,
  isStandard = true,
) => (dispatch) => {
  fetch(
    `${pathUrl}/api/comments/${data.messageType}/${data.commentedObjectID}/${data.commentId}`,
    {
      method: 'DELETE',
    },
    isStandard,
  )
    .then((res) => {
      dispatch(getCustomerTaskComments(data.taskId, isStandard));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const customerTaskEditComment = (data) => (dispatch) => {
  const {
    childId, commentValue, messageType, parentId, taskId,
  } = data;

  let commentType;

  if (messageType === commentsModule.COMMENT_TYPE_TASK.COMMENT) {
    commentType = commentsModule.COMMENT_TYPE_TASK.TASK;
  } else if (messageType === commentsModule.COMMENT_TYPE_TASK.REPLY) {
    commentType = commentsModule.COMMENT_TYPE_TASK.COMMENT;
  }

  const url = `/api/comments/${commentType}/${parentId}/${childId}`;

  apiConnector(url, 'PUT', { Comment: commentValue })
    .then((res) => dispatch(getCustomerTaskComments(taskId)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const customerTaskResolveComment = (data) => (dispatch) => {
  const {
    childId, messageType, taskId, resolvedUserId: ResolvedUserID,
  } = data;

  let commentType;

  if (messageType === commentsModule.COMMENT_TYPE_TASK.COMMENT) {
    commentType = commentsModule.COMMENT_TYPE_TASK.TASK;
  } else if (messageType === commentsModule.COMMENT_TYPE_TASK.REPLY) {
    commentType = commentsModule.COMMENT_TYPE_TASK.COMMENT;
  }

  const url = `/api/comments/${commentType}/${taskId}/${childId}/resolve`;

  apiConnector(url, 'PUT', { ResolvedUserID })
    .then((res) => dispatch(getCustomerTaskComments(taskId)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE = 'CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE';
export const customerUpdateTaskAcknowledge = (data, isStandard = true) => (
  dispatch,
  getState,
) => {
  const {
    messageType,
    taskId,
    commentId,
    acknowledgedUserId: AcknowledgedUserID,
  } = data.data;

  let urlMessageType;

  if (messageType === commentsModule.COMMENT_TYPE_TASK.COMMENT) {
    urlMessageType = commentsModule.COMMENT_TYPE_TASK.TASK;
  } else if (messageType === commentsModule.COMMENT_TYPE_TASK.REPLY) {
    urlMessageType = commentsModule.COMMENT_TYPE_TASK.COMMENT;
  }

  const connector = isStandard ? apiConnector : customerToUrlConnector;
  const url = `/api/comments/${urlMessageType}/${taskId}/${commentId}`;

  connector(url, 'PUT', { isAcknowledged: true, AcknowledgedUserID })
    .then((res) => {
      dispatch(getTasks());
      dispatch(getCustomerTaskComments(taskId, isStandard));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS = 'CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS';
export const customerUpdateTaskAcknowledgeSuccess = (id, status) => ({
  type: CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS,
  id,
  status,
});

export const SET_TASK_DATA = 'SET_TASK_DATA';
export const setTaskData = (status) => ({
  type: SET_TASK_DATA,
  status,
});

export const getTaskStatusList = (isStandard = true) => (dispatch) => {
  const connector = isStandard ? getConnector : customerToUrlConnector;
  const url = '/Tasks/ReturnAllTaskStatuses';

  return connector(url)
    .then((res) => dispatch(dispatch(getTaskStatusListSuccess(res.taskstatuses))))
    .catch((err) => dispatch(getTaskStatusListError()));
};

export const GET_TASK_STATUS_LIST_SUCCESS = 'GET_TASK_STATUS_LIST_SUCCESS';
export const getTaskStatusListSuccess = (statuses) => ({
  type: GET_TASK_STATUS_LIST_SUCCESS,
  statuses,
});

export const GET_TASK_STATUS_LIST_ERROR = 'GET_TASK_STATUS_LIST_ERROR';
export const getTaskStatusListError = () => ({
  type: GET_TASK_STATUS_LIST_ERROR,
});

export const TOKEN_TO_STORE = 'TOKEN_TO_STORE';
export const tokenToStore = (token) => ({
  type: TOKEN_TO_STORE,
  token,
});

export const TASK_TOKEN_TO_STORE = 'TASK_TOKEN_TO_STORE';
export const taskTokenToStore = (token) => (dispatch) => {
  localStorage.setItem('taskToken', token);
  dispatch({
    type: TASK_TOKEN_TO_STORE,
    token,
  });
};

export const customerResolve = (body) => (dispatch, getState) => {
  const id = getState().externalStore.taskFromUrl.TaskID;
  const { token } = getState().externalStore;

  return customerToUrlConnector(
    `/Tasks/CustomerResolveTask/${id}`,
    'POST',
    body,
  )
    .then(() => dispatch(getCustomerTask(id, token)))
    .catch(() => {});
};
