import React from 'react';
import {
  render,
  hydrate,
} from 'react-dom';

import * as Sentry from '@sentry/react'; // eslint-disable-line no-restricted-syntax
import { Integrations } from '@sentry/tracing';

import Root from './Root';

import './index.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  });
}

if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
    .then((registrations) => {
      for (const registration of registrations) { // eslint-disable-line no-unused-vars
        registration.unregister();
      }
    });
}

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
