import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  Root,
} from './styledItems';

const iconSizes = {
  horizontal: 'm',
  vertical: 'xxl',
};

const SelectorItem = (props) => {
  const {
    isSelected,
    customIconComponent,
    onClick,
    iconName,
    iconType,
    iconColor,
    iconSize,
    children,
    radio,
    variant,
    typographyProps,
  } = props;

  const color = isSelected ? 'main-text' : 'descriptor-text';
  const currentIconColor = isSelected
    ? iconColor || 'focus'
    : 'descriptor-text';

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      onClick();
    }, [
      onClick,
    ],
  );

  return (
    <Root
      isSelected={isSelected}
      onClick={handleClick}
      radio={radio}
      variant={variant}
    >
      { customIconComponent }
      {
        !customIconComponent && iconName && (
          <Icon
            name={iconName}
            color={currentIconColor}
            type={iconType}
            size={iconSize || iconSizes[variant]}
          />
        )
      }
      {
        children
        ? (
          <Typography
            variant="button"
            noMargin
            color={color}
            {...typographyProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            {children}
          </Typography>
          ) : null
      }
    </Root>
  );
};

SelectorItem.defaultProps = {
  customIconComponent: null,
  iconName: null,
  iconType: 'feather',
  iconColor: null,
  iconSize: null,
  isSelected: false,
  radio: false,
  variant: 'horizontal',
  typographyProps: {},
};

const {
  bool,
  element,
  func,
  oneOf,
  shape,
  string,
} = PropTypes;

SelectorItem.propTypes = {
  customIconComponent: element,
  iconName: string,
  iconType: string,
  children: string.isRequired,
  isSelected: bool,
  onClick: func.isRequired,
  iconColor: string,
  iconSize: string,
  radio: bool,
  variant: oneOf(['horizontal', 'vertical']),
  typographyProps: shape(),
};

export default SelectorItem;
