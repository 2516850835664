import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import ExpectedSalePriceInput from 'shared/inputs/SalePriceInput';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      expectedSalePrice,
    },
  } = state;

  return {
    value: expectedSalePrice,
    label: 'Expected sale price',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => dispatch(setNewLeadFormField('expectedSalePrice')(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpectedSalePriceInput);
