import { connect } from 'react-redux';

import { isEmpty } from 'shared/utility';

import selectors from 'store/selectors';

import OfferHistory from './OfferHistory';

const mapStateToProps = (state, ownProps) => {
  const { rootOfferId } = ownProps;

  const offer = selectors.realtorOffers.byIdWithCounters(state, rootOfferId);

  const {
    events: timeline,
    isLoading: isTimelineLoading,
  } = selectors.realtorOffers.offerTimeline(state, rootOfferId);

  const isRootOfferDeclined = !!offer.declined_at;
  let hasDeclinedCounter = false;

  if (!isEmpty(offer.counters)) {
    hasDeclinedCounter = offer.counters.some((counterId) => {
      const counter = selectors.realtorOffers.byId(state, counterId);
      return !!counter.declined_at;
    });
  }

  return {
    timelineEvents: timeline,
    isCancelled: !!offer.cancelled_at,
    isDeclined: isRootOfferDeclined || hasDeclinedCounter,
    isTimelineLoading,
  };
};

export default connect(mapStateToProps)(OfferHistory);
