import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Card = styled.div`
  box-sizing: border-box;
  padding: 5px 7px 7px;
  background: ${(props) => (props.isSelected ? props.theme.palette?.['background-flat'] : 'transparent')};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => (props.isSelected ? props.theme.palette.focus : 'transparent')};
`;

export const InlineWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;
