import { connect } from 'react-redux';

import { showSearchFilterModal, saveSearchFilter } from 'store/actions/knowledgeBase';

import SearchFilter from './SearchFilter';

const mapStateToProps = (state) => ({
  searchFilterModal: state.knowledgeBase.searchFilterModal,
  kbHelperData: state.knowledgeBase.kbHelperData,
  kbSearchFilterData: state.knowledgeBase.kbSearchFilterData,
  categories: state.knowledgeBase.categories,
});

const mapDispatchToProps = (dispatch) => ({
  triggerModal: (onOk, onCancel, message) => dispatch(showSearchFilterModal(onOk, onCancel, message)),
  saveSearchFilter: (filter) => dispatch(saveSearchFilter(filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchFilter);
