import apiConnector, { endpoints } from 'api';

const deleteAttachment = (objectType) => (objectId) => async (attachmentId) => {
  const endpoint = `${endpoints.client.detachFile}/${objectType}/${objectId}/${attachmentId}`;

  try {
    const response = await apiConnector.client.delete(endpoint);
    return response;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default deleteAttachment;
