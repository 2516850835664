import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Property from './Property';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  const address = selectors.addresses.byId(state, property?.address_id)?.address;
  const image = selectors.properties.propertyMainImage(state, propertyId);

  return {
    address,
    image: image?.previewUrl,
  };
};

export default connect(mapStateToProps)(Property);
