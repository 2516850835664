import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchItemProspect from './SearchItemProspect';

const mapStateToProps = (state, ownProps) => {
  const {
    prospect: {
      contact_id,
    },
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contact_id);

  return {
    role: contact.role,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemProspect);
