import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { map } from 'shared/utility';
import InspectionTypeSelect from './InspectionTypeSelect';

const mapStateToProps = (state) => {
  const options = selectors.realtorInspections.inspectionTypes(state);

  return {
    options: map(options, (item) => (
      {
        value: item.id,
        label: item.name,
      }
    )),
  };
};

export default connect(mapStateToProps)(InspectionTypeSelect);
