import React from 'react';
import PropTypes from 'prop-types';

import MuiTabs from '@material-ui/core/Tabs';

const noTabIndicator = {
  style: {
    display: 'none',
  },
};

const Tabs = (props) => {
  const {
    variant,
    scrollable,
  } = props;

  return (
    <MuiTabs
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      variant={scrollable ? 'scrollable' : 'fullWidth'}
      textColor="primary"
      TabIndicatorProps={variant === 'secondary' ? noTabIndicator : undefined}
    />
  );
};

Tabs.defaultProps = {
  variant: 'primary',
  scrollable: false,
};

const {
  oneOf,
  bool,
} = PropTypes;

Tabs.propTypes = {
  variant: oneOf(['primary', 'secondary']),
  scrollable: bool,
};

export default Tabs;
