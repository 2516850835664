/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeCancelTransactionModal,
} from 'store/actions/cancelTransactionModal';

import CancelTransactionModal from './CancelTransactionModal';

const mapStateToProps = (state) => {
  const {
    cancelTransactionModal: {
      isVisible,
      onSave,
    },
  } = state;

  return {
    isVisible,
    onSave,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeCancelTransactionModal()),

  onSubmit: async (data) => {
    console.log(data); // eslint-disable-line no-console

    dispatch(closeCancelTransactionModal());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: async (data) => {
    await dispatchProps.onSubmit(data);

    if (stateProps.onSave) {
      stateProps.onSave();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CancelTransactionModal);
