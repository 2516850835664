import { connect } from 'react-redux';

import logActions from 'store/actions/logs';
import ContactLogButton from './ContactLogButton';

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    objectType,
    objectId,
  } = ownProps;

  const logMethods = logActions(objectType)(objectId);

  return {
    onAddLog: (data) => {
      dispatch(logMethods.post(data));

      if (ownProps.onAfter) {
        ownProps.onAfter();
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(ContactLogButton);
