import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button, Typography, Avatar, Icon,
} from 'antd';
import './ButtonAvatar.scss';

class ButtonAvatar extends PureComponent {
  render() {
    const {
      className,
      onClick,
      color,
      backgroundColor,
      size,
      sizeFont,
      verticalAlign,
      iconName,
      showButton,
      hidePlus,
    } = this.props;

    const style = {
      fontSize: sizeFont,
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      verticalAlign,
    };

    return (
      <div
        className={classnames(
          'button-avatar-root',
          {
            [className]: className,
          },
          {
            'button-avatar-hidden': !showButton,
            'button-avatar-none': hidePlus,
          },
        )}
        onClick={onClick}
      >
        <Avatar
          size={size}
          style={{ color, backgroundColor }}
        >
          <Icon type={iconName} style={style} />
        </Avatar>
      </div>
    );
  }
}

export default ButtonAvatar;

ButtonAvatar.defaultProps = {
  className: '',
  onClick: () => { },
  color: '#6b88a4',
  backgroundColor: '#f3f3f3',
  size: 21,
  sizeFont: 10,
  verticalAlign: 1,
  iconName: 'plus',
  showButton: false,
  hidePlus: false,
};

const {
  string,
  func,
  number,
  bool,
} = PropTypes;

ButtonAvatar.propTypes = {
  className: string,
  onClick: func,
  color: string,
  backgroundColor: string,
  size: number,
  sizeFont: number,
  verticalAlign: number,
  iconName: string,
  showButton: bool,
  hidePlus: bool,
};
