import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_REPAIR_REQUESTS_MODAL_OPEN,
  SET_REALTOR_REPAIR_REQUESTS_MODAL_CLOSED,
} from 'store/actionTypes/realtorRepairRequests';

const noOp = () => {};

const initialState = {
  isVisible: false,
  onBeforeClose: noOp,
  onAfterClose: noOp,
  modalProps: {},
};

const reducers = {
  [SET_REALTOR_REPAIR_REQUESTS_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    ...action.payload,
  }),

  [SET_REALTOR_REPAIR_REQUESTS_MODAL_CLOSED]: (state) => ({
    ...initialState,
  }),
};

function repairRequestsModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default repairRequestsModal;
