import removeDealProduct from './removeDealProduct';

const removeProducts = (relationId) => async (dispatch, getState) => {
  const {
    leads: {
      LeadID: selectedDealId,
    },
  } = getState();

  try {
    await dispatch(removeDealProduct(selectedDealId)(relationId));
  } catch (error) {
    console.error(error);
  }
};

export default removeProducts;
