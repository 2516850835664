import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import { Root } from './styledItems';

const typographyToSize = {
  m: 'tag',
  s: 'tag2',
};

const Tag = (props) => {
  const {
    variant,
    children,
    size,
    className,
    textColor,
    typographyProps,
    noPadding,
    border,
  } = props;

  return (
    <Root
      variant={variant}
      size={size}
      className={className}
      noPadding={noPadding}
      border={border}
    >
      {
        typeof children === 'string' ? (
          <Typography
            variant={typographyToSize[size]}
            color={textColor}
            noWrap
            {...typographyProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            {children}
          </Typography>
        ) : (
          <>
            {children}
          </>
        )
      }
    </Root>
  );
};

Tag.defaultProps = {
  variant: 'focus',
  size: 'm',
  textColor: 'main-text-inversed',
  typographyProps: {},
  noPadding: false,
  className: '',
  border: null,
};

const {
  string,
  oneOf,
  shape,
  bool,
  number,
} = PropTypes;

Tag.propTypes = {
  variant: string,
  children: string.isRequired,
  size: oneOf(['s', 'm']),
  className: string,
  textColor: string,
  typographyProps: shape,
  noPadding: bool,
  border: shape({
    width: number,
    color: string,
    style: string,
  }),
};

export default Tag;
