/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Grid from 'ui/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from 'ui/Typography';

const ListingTerms = (props) => {
  const {
    control,
  } = props;

  const handleChange = useCallback((onChange) => (event) => {
    onChange(event.target.checked);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          weight="bold"
          noMargin
        >
          Listing terms
        </Typography>
      </Grid>

      <Grid item>
        <Controller
          name="is_exclusive"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={(
                <Checkbox
                  {...field}
                  color="primary"
                  checked={field.value}
                  onChange={handleChange(field.onChange)}
                />
              )}
              label={(
                <Typography
                  noMargin
                  variant="body"
                >
                  Exclusive
                </Typography>
              )}
            />
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="is_pocket_listing"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={(
                <Checkbox
                  {...field}
                  color="primary"
                  checked={field.value}
                  onChange={handleChange(field.onChange)}
                />
              )}
              label={(
                <Typography
                  noMargin
                  variant="body"
                >
                  Off-market Listing
                </Typography>
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const {
  shape,
} = PropTypes;

ListingTerms.propTypes = {
  control: shape().isRequired,
};

export default ListingTerms;
