/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getDislikeById from './getDislikeById';
import updateDislike from './updateDislike';
import deleteDislike from './deleteDislike';
import addDislikeRelations from './addDislikeRelations';
import removeDislikeRelations from './removeDislikeRelations';
import createDislike from './createDislike';

export {
  getDislikeById,
  updateDislike,
  deleteDislike,
  addDislikeRelations,
  removeDislikeRelations,
  createDislike,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedDislikesActions = {
  getDislikeById: memoizeDebounce(
    (id) => store.dispatch(getDislikeById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
