import requests from 'api/requests';

import set from './set';
import setByFilter from './setByFilter';
import setCountByFilter from './setCountByFilter';

const get = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.matchingProperties.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(set(response.data?.items));

    dispatch(setByFilter({
      filterId,
      items: response.data?.items,
    }));

    dispatch(setCountByFilter({
      filterId,
      count: response.data?.itemsCount,
    }));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default get;
