import {
  SET_TASK,
} from 'store/actionTypes/tasks';

const setTask = (task) => ({
  type: SET_TASK,
  payload: task,
});

export default setTask;
