import {
  SET_REALTOR_OPPORTUNITIES_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorOpportunities';

const setOpportunitiesForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_OPPORTUNITIES_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setOpportunitiesForFilterCount;
