import React, {useState} from 'react'
import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateFollowUpReminder,
  deleteFollowUpReminder,
} from 'store/actions/followUpReminders';

import { OBJECT_TYPES } from 'constants';

import FollowUpData from './FollowUpData';
import deleteSyncedEvent from 'api/requests/syncedEvents/delete';

const objectTypes2selectors = {
  'realtor lead': selectors.realtorLeads.byId,
  'realtor client': selectors.realtorClients.byId,
  'realtor prospect': selectors.realtorProspects.byId,
};
const mapStateToProps = (state, ownProps) => {
  const {
    reminderId,
  } = ownProps;
  const reminder = selectors.followUpReminders.byId(state, reminderId);
  if (!reminder.id) {
    return {};
  }
  let contactId;

  const relatedObjectType = OBJECT_TYPES[reminder.object_type];

  // if (relatedObjectType === 'realtor contact') {
  //   contactId = reminder.object_id;
  // } else if (!objectTypes2selectors[relatedObjectType]) {
  //   return {};
  // } else {
  //   const relatedObject = objectTypes2selectors[relatedObjectType]
  //     && objectTypes2selectors[relatedObjectType](state, reminder.object_id);

  //   contactId = relatedObject?.contact_id || relatedObject?.realtor_contact_id;
  // }

  return {
    contactId,
    title: reminder.title,
    comment: reminder.comment,
    scheduledAt: reminder.scheduled_date,
    location: reminder.meeting_url,
    event_uid: reminder.event_uid
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    dispatch(updateFollowUpReminder(ownProps.reminderId)({
      [key]: value,
    }));
  }, 1000),
  deleteEvent: async (event_uid) =>  {
    debugger
    await dispatch(deleteSyncedEvent(event_uid));
    if (ownProps.onAfterDelete) {
      ownProps.onAfterDelete('followUpReminders');
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpData);
