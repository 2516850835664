import { createSelector } from 'reselect';

const companyInfo = (state) => state.myCompany.companyInfo;

const billingPeriodType = createSelector(
  companyInfo,
  (item) => item.BillingPeriodType,
);

export default billingPeriodType;
