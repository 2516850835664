import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createExtensionRequest from './create';
import openNewExtensionRequestModal from './openNewExtensionRequestModal';
import addExtensionRequestRelations from './addRelations';

import getExtensionRequestById from './getById';
import getExtensionRequestForFilter from './getForFilter';

import updateExtensionRequest from './update';

import getExtensionRequestAttachments from './getAttachments';

import setFormAttachments from './setFormAttachments';

export {
  createExtensionRequest,
  addExtensionRequestRelations,
  openNewExtensionRequestModal,
  getExtensionRequestById,
  getExtensionRequestForFilter,
  updateExtensionRequest,
  setFormAttachments,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorExtensionRequestActions = {
  getForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getExtensionRequestForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),

  getById: memoizeDebounce(
    (requestId) => store.dispatch(getExtensionRequestById(requestId)),
    debounceDelay,
    debounceOptions,
  ),

  getAttachments: memoizeDebounce(
    (requestId) => store.dispatch(getExtensionRequestAttachments(requestId)),
    debounceDelay,
    debounceOptions,
  ),
};
