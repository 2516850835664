import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import NewInspectionModal from './NewInspectionModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      newInspection,
    },
    realtorNewInspectionForm: {
      isReinspection,
    },
  } = state;

  return {
    isVisible: newInspection?.isVisible,
    isReinspection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('newInspection')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInspectionModal);
