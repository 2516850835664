import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedCrmSourcesActions,
  createSourceType,
} from 'store/actions/crm/crmSources';

import { createSourceFromRealtorNewContactForm } from 'store/actions/realtorContacts';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import SourceSelect from './SourceSelect';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      newCustomSourceName,
      sourceType,
    },
  } = state;

  const sourceTypes = selectors.crm.sourceTypes(state);

  if (!sourceTypes) {
    optimizedCrmSourcesActions.getSourceTypes();
  }

  const sourceTypeObj = sourceTypes && sourceType
    ? sourceTypes.find((item) => item.id === sourceType)
    : null;

  const value = sourceTypeObj
    ? sourceTypeObj.name
    : '';

  return {
    options: sourceTypes,
    newCustomSourceName,
    value,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelect: (sourceTypeId) => {
    dispatch(setNewContactFormField('sourceType')(sourceTypeId));
  },

  addSourceType: async (name) => {
    const newSourceTypeId = await dispatch(createSourceType({ name }));
    dispatch(setNewContactFormField('sourceType')(newSourceTypeId));

    const sourceId = await dispatch(createSourceFromRealtorNewContactForm());

    dispatch(setNewContactFormField('source')(sourceId));
  },

  onAddNewSourceTypeClick: (newSourceTypeName) => {
    dispatch(setNewContactFormField('newCustomSourceName')(newSourceTypeName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceSelect);
