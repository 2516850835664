import requests from 'api/requests';
import setActiveMeeting from './setActiveMeeting';
import shareActiveMeeting from './shareActiveMeeting';

const updateMeeting = ({ id, payload }) => async (dispatch, getState) => {
  try {
    const response = await requests.meetings.updateMeeting({
      id,
      payload,
    });

    dispatch(
      setActiveMeeting(response.data),
    );

    const { activeMeeting } = getState().videoChat;

    shareActiveMeeting(activeMeeting);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default updateMeeting;
