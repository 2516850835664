import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (filter) => filter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      realtor_deal_id: filter.transactionId,
      status: filter.status,
    },
  }),
);
