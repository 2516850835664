import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import { useDispatch } from 'react-redux';

import {
  getObjectAttachments,
} from 'store/actions/attachmentsV2';

const useAttachments = (objectType, objectId, attachmentType, shouldRefresh = false, withListener = false) => {
  const dispatch = useDispatch();

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAttachments = useCallback(async (type, id) => {
    try {
      setLoading(true);
      const response = await dispatch(getObjectAttachments(type)(id)({ attachmentType }));

      setResult(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [
    attachmentType,
  ]);

  useEffect(() => {
    if (objectType && objectId) {
      fetchAttachments(objectType, objectId);
    }
  }, [
    objectType,
    objectId,
    attachmentType,
    shouldRefresh,
  ]);

  const onEvent = useCallback((event) => {
    const {
      detail: {
        objectId: eventObjectId,
        objectType: eventObjectType,
        attachmentType: eventAttachmentType,
      },
    } = event;

    if (eventObjectId === objectId && eventObjectType === objectType && eventAttachmentType === attachmentType) {
      fetchAttachments(eventObjectType, eventObjectId);
    }
  }, [
    objectType,
    objectId,
    attachmentType,
    fetchAttachments,
    withListener,
  ]);

  useEffect(() => {
    if (withListener) {
      window.addEventListener('attachmentUpload', onEvent);
    }

    return () => {
      window.removeEventListener('attachmentUpload', onEvent);
    };
  }, [
    onEvent,
    withListener,
  ]);

  return [result, loading];
};

export default useAttachments;
