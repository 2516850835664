import apiConnector, { endpoints } from 'api';

/**
 * Sends GET request to retrieve the meeting from server.
 * @param {(string|number)} idOrCode - The id or code of meeting.
 * @returns {Promise} A response from server.
 */

const getMeeting = async (idOrCode) => {
  const endpoint = endpoints.OA.meetingPath(idOrCode);
  let response;

  try {
    response = await apiConnector.OA.get(endpoint);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default getMeeting;
