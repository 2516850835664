import React from 'react';
import PropTypes from 'prop-types';

import {
  Upload,
} from 'antd';

import Typography from 'ui/Typography';

import UploadWrapper from './styledItems';

const { Dragger } = Upload;

const AttachmentsUpload = (props) => {
  const {
    fileList,
    onUpload,
    onRemove,
  } = props;

  return (
    <UploadWrapper>
      <Dragger
        listType="picture"
        accept="image/png, image/jpeg, .pdf, .doc, .txt"
        fileList={fileList}
        customRequest={onUpload}
        onRemove={onRemove}
        multiple
      >
        <Typography
          variant="h3"
          tag="p"
          color="focus"
          noMargin
          align="center"
        >
          Upload buyer agreement
        </Typography>
      </Dragger>
    </UploadWrapper>
  );
};

AttachmentsUpload.defaultProps = {
  fileList: [],
};

const {
  func,
  shape,
  arrayOf,
  string,
  bool,
} = PropTypes;

AttachmentsUpload.propTypes = {
  fileList: arrayOf(shape({
    uid: string,
    name: string,
    url: string,
    thumbUrl: string,
    isMain: bool,
  })),
  onUpload: func.isRequired,
  onRemove: func.isRequired,
};

export default AttachmentsUpload;
