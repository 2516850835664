import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const contentPadding = {
  paddingLeft: 36,
};

const FormSection = (props) => {
  const {
    iconName,
    iconColor,
    iconType,
    iconSize,
    children,
    title,
    iconContainerStyle,
  } = props;

  return (
    <Flex
      fullWidth
      spacing={1}
      flexDirection={title ? 'column' : 'row'}
    >
      <Flex
        spacing={1}
        fullWidth={!!title}
      >
        {
          iconName && (
            <div style={iconContainerStyle}>
              <Icon
                name={iconName}
                type={iconType}
                color={iconColor}
                size={iconSize}
              />
            </div>
          )
        }
        {
          title && (
            <Typography
              variant="h3"
              weight="bold"
              noMargin
            >
              {title}
            </Typography>
          )
        }
      </Flex>
      <Flex
        fullWidth
        spacing={2}
        flexDirection="column"
        style={title ? contentPadding : {}}
      >
        {children}
      </Flex>
    </Flex>
  );
};

FormSection.defaultProps = {
  iconName: null,
  iconType: 'feather',
  iconColor: 'focus',
  iconSize: 'l',
  title: null,
  iconContainerStyle: {},
};

const {
  shape,
  string,
} = PropTypes;

FormSection.propTypes = {
  iconName: string,
  iconType: string,
  iconColor: string,
  iconSize: string,
  title: string,
  iconContainerStyle: shape(),
};

export default FormSection;
