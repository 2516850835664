import {
  SET_CUSTOMER_PAYMENT,
} from 'store/actionTypes/customerPayments';

const set = (payload) => ({
  type: SET_CUSTOMER_PAYMENT,
  payload,
});

export default set;
