import apiConnector, { endpoints } from 'api';

const getFolderArticles = async (folderId, query) => {
  const endpoint = `${endpoints.client.articles.articleGeneral}`;

  const result = await apiConnector.client.get(endpoint, {
    folderId,
    ...query,
  });

  return result.data;
};

export default getFolderArticles;
