import newApiConnector, { endpoints } from 'api';

// Gets employees whose birthday is today
const getTodayBirthdayEmployees = async () => {
  const endpoint = endpoints.client.hr.birthdayIsToday;

  const response = await newApiConnector.client.get(endpoint);
  return response.data.result;
};

export default getTodayBirthdayEmployees;
