import get from './get';
import getById from './getById';
import create from './create';
import update from './update';
import deleteNotablePoint from './delete';
import addRelations from './addRelations';
import removeRelations from './removeRelations';

export default {
  get,
  getById,
  create,
  update,
  deleteNotablePoint,
  addRelations,
  removeRelations,
};
