import { createSelector } from 'reselect';

import { isUndefined } from 'shared/utility';

import {
  optimizedWarehouseActions,
} from 'store/actions/warehouse';

export default createSelector(
  (state, fulfillmentId) => ({
    packages: state.warehouse.fulfillmentPackages,
    fulfillmentId,
  }),
  ({ packages, fulfillmentId }) => {
    if (!fulfillmentId) return null;

    const result = packages[fulfillmentId];

    if (isUndefined(result)) {
      optimizedWarehouseActions.getWarehousePackagesForFulfillment(fulfillmentId);
      return [];
    }

    return result;
  },
);
