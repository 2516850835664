/* eslint-disable */
import connectReducers from 'store/connectReducers';
import { createSelector } from 'reselect';
// TODO - fix dependency cycles
// eslint-disable-next-line import/no-cycle
import { SET_CUSTOMER_LABELS } from 'store/actions/customersLabels';
// eslint-disable-next-line import/no-cycle
import {
  GET_TICKET_STATUS_LIST_SUCCESS,
  GET_TICKETS_SUCCESS,
  GET_TICKET_DATA_BY_TICKET_ID,
  SHOW_TICKET_DETAILS_MODAL,
  UPDATE_TICKET_PROGRESS_SUCCESS,
  GET_CUSTOMER_TICKET_INFO_SUCCESS,
  GET_TICKET_COMMENTS_SUCCESS,
  UPDATE_TICKET_FIELD_SUCCESS,
  GET_ALL_PRIORITIES_SUCCESS,
  CREATE_TICKET_SUCCESS,
  CLEAR_TICKET_COMMENTS,
  GET_CALLS_LOG_SUCCESS,
  CLEAR_SELECTED_TICKET,
  UPDATE_TICKET_DELVIERY_DATE_SUCCESS,
  UPDATE_SELECTED_TICKET,
  SET_LAST_UPDATE_DATE,
  GET_TICKETS_DASHBOARD_INF,
  ADD_TUTORIAL_TICKET,
} from 'store/actions/tickets';
// eslint-disable-next-line import/no-cycle
import * as COMMENTS from 'store/actions/comments';
// eslint-disable-next-line import/no-cycle
import * as EXTERNAL from 'store/actions/external-store';
import {
  SET_TICKET_EMPLOYEES_SUCCESS,
  SET_TICKET_CATEGORY_SUCCESS,
  SET_TICKET_AGENT_NOTE,
} from 'store/actionTypes/ticket-details-modal';


const initialState = {
  ticketsList: [],
  ticketStatusList: [],
  members: [],
  lastMemberId: null,
  gui: {}, // TODO: PLACE ALL DOM MANIPULATIONS HERE (dashboard, modals)
  isModalVisible: false,
  ticketUnassigned: {},
  selectedTicket: {
    ticket: {},
    customer: {},
    customerTickets: {
      currentTickets: [],
      previousTickets: [],
    },
    customerLabels: [],
    comments: {},
    activity: {
      lastUpdateDate: 'N/A',
      lastUpdateAgent: 'N/A',
    },
    log: [],
  },
  prioritiesList: [],
  commentTypesList: [],
  allManagerTickets: [],
  countManagerQueue: 0,
  otherDashboardTicketInformation: [],
  totalManagerUnassignedTicketsCount: 0,
  countNewTicketsAssignedToMe: 0,
};

const reducers = {
  [SET_TICKET_AGENT_NOTE]: (state, action) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      ticket: {
        ...state.selectedTicket.ticket,
        AgentNotes: action.newAgentNote
      }
    }
  }),

  [GET_TICKETS_DASHBOARD_INF]: (state, action) => ({
    ...state,
    otherDashboardTicketInformation: action.otherDashboardTicketInformation,
    totalManagerUnassignedTicketsCount: action.totalManagerUnassignedTicketsCount,
    countNewTicketsAssignedToMe: action.countNewTicketsAssignedToMe,
    countManagerQueue: action.countManagerQueue,
  }),

  [GET_TICKETS_SUCCESS]: (state, action) => ({
    ...state,
    ticketsList: action.tickets,
  }),

  [GET_TICKET_STATUS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    ticketStatusList: action.ticketStatusList,
  }),

  [GET_TICKET_DATA_BY_TICKET_ID]: (state, action) => {
    const selectedTicket = {
      ...state.selectedTicket,
      ticket: state.ticketsList.filter(
        (ticket) => ticket.TicketID === action.id,
      )[0],
    };

    return {
      ...state,
      selectedTicket,
    };
  },

  [SHOW_TICKET_DETAILS_MODAL]: (state, action) => ({
    ...state,
    isModalVisible: action.visibility,
  }),

  [GET_CUSTOMER_TICKET_INFO_SUCCESS]: (state, action) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      customer: action.response[0],
      customerTickets: {
        currentTickets: state.ticketsList.filter(
          (ticket) => ticket.CustomerID === action.response[0].CustomerID
            && !ticket.Resolved,
        ),
        previousTickets: state.ticketsList.filter(
          (ticket) => ticket.CustomerID === action.response[0].CustomerID
            && ticket.Resolved,
        ),
      },
    },
  }),

  [GET_TICKET_COMMENTS_SUCCESS]: (state, action) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      comments: action.comments,
    },
  }),

  [GET_CALLS_LOG_SUCCESS]: (state, action) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      log: action.log,
    },
  }),

  [CLEAR_SELECTED_TICKET]: (state) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      ticket: {},
    },
  }),

  [CLEAR_TICKET_COMMENTS]: (state) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      comments: [],
    },
  }),

  [GET_ALL_PRIORITIES_SUCCESS]: (state, action) => {
    const { priorities } = action;
    return {
      ...state,
      prioritiesList: priorities,
    };
  },

  [CREATE_TICKET_SUCCESS]: (state, action) => {
    const {
      data: [ticket],
    } = action;
    return {
      ...state,
      ticketsList: [...state.ticketsList, ticket],
    };
  },

  [SET_TICKET_EMPLOYEES_SUCCESS]: (state, action) => {
    const [newTicket] = action.data;
    const { TicketID } = newTicket;
    const tickets = [
      ...state.ticketsList.filter((ticket) => ticket.TicketID !== TicketID),
      newTicket,
    ];
    return {
      ...state,
      ticketsList: tickets,
      selectedTicket: { ...state.selectedTicket, ticket: newTicket },
    };
  },

  [UPDATE_TICKET_DELVIERY_DATE_SUCCESS]: (state, action) => {
    const { ticket } = action;
    return updateTickets(state, ticket);
  },

  [UPDATE_TICKET_FIELD_SUCCESS]: (state, action) => {
    const { ticket } = action;
    return updateTickets(state, ticket);
  },

  [UPDATE_TICKET_PROGRESS_SUCCESS]: (state, action) => {
    const { ticket } = action;
    return updateTickets(state, ticket);
  },

  [SET_TICKET_CATEGORY_SUCCESS]: (state, action) => {
    const { ticket } = action;
    return updateTickets(state, ticket);
  },

  [COMMENTS]: (state, action) => ({
    ...state,
    commentTypesList: action.commentTypes,
  }),

  [UPDATE_SELECTED_TICKET]: (state, action) => {
    const { selectedTicket } = state;
    const { payload: { category, Product, QueueID } } = action;
    return {
      ...state,
      selectedTicket: {
        ...selectedTicket,
        ticket: {
          ...selectedTicket.ticket,
          QueueID,
          Category: category,
          SubCategory: Product,
        },
      },
    };
  },

  [EXTERNAL]: (state, action) => ({
    ...state,
    selectedTicket: {
      ...state.selectedTicket,
      comments: action.comments,
    },
  }),

  [SET_LAST_UPDATE_DATE]: (state, action) => {
    const { lastUpdateDate, lastUpdateAgent } = action.payload;
    return {
      ...state,
      selectedTicket: {
        ...state.selectedTicket,
        activity: {
          lastUpdateDate,
          lastUpdateAgent,
        },
      },
    };
  },

  [SET_CUSTOMER_LABELS]: (state, action) => {
    const { customerLabels } = action.payload;
    return {
      ...state,
      selectedTicket: {
        ...state.selectedTicket,
        customerLabels,
      },
    };
  },

  [ADD_TUTORIAL_TICKET]: (state, action) => {
    const ticket = {
      TicketID: 2020,
      TicketName: "Ticket Name",
      Description: "<p>This ticket is a sample, it will not appear in your list</p>",
      TicketStatusID: 2,
      QueueID: 4,
      employees: [],
      customer: {
        FirstName: "Demo",
        LastName: "Customer",
        Email: "democustomer@symplete.com",
      },
      ticketstatus: {
        StatusID: 2,
        TicketStatus: 'New Assigned',
      },
    };
    return {
      ...state,
      selectedTicket: {
        ...state.selectedTicket,
        ticket: ticket,
        customer: ticket.customer,
      }
    };
  },
};

export const updateTickets = (state, ticket) => {
  const { ticketsList, selectedTicket } = state;
  return {
    ...state,
    ticketsList: updateTicket(ticketsList, ticket),
    selectedTicket: {
      ...selectedTicket,
      ticket,
    },
  };
};

export const updateTicket = (tickets, newTicket) => tickets.map((ticket) => (
  ticket.TicketID === newTicket.TicketID
    ? newTicket
    : ticket
));

export const getTicketSelector = createSelector(
  (state) => state.tickets.ticketsList,
  (state) => state.tickets.selectedTicket.ticket.TicketID,
  (tickets, id) => tickets.find((ticket) => ticket.TicketID === id),
);

const tickets = (state = initialState, action) => connectReducers(reducers, state, action);

export default tickets;
