import connectReducers from 'store/connectReducers';

import {
  SET_QUEUE,
  SET_QUEUES,
  SET_QUEUES_COUNT,
  REMOVE_QUEUE,
  SET_QUEUES_FOR_MANAGER,
  CLEAR_QUEUE_DATA,
} from 'store/actionTypes/queues';

const initialState = {
  queues: {},
  queuesCount: 0,

  queuesByManager: {},
};

const reducers = {
  [SET_QUEUE]: (state, action) => {
    const queue = action.payload;

    return {
      ...state,
      queues: {
        ...state.queues,
        [queue.QueueID]: queue,
      },
    };
  },

  [SET_QUEUES]: (state, action) => {
    const queues = action.payload;

    const queueUpdate = queues.reduce((updated, queue) => {
      const { QueueID } = queue;

      if (!state.queues[QueueID]) {
        updated[QueueID] = queue; // eslint-disable-line no-param-reassign
        return updated;
      }

      const updatedQueues = {
        ...state.queues[QueueID],
        ...queue,
      };

      updated[QueueID] = updatedQueues; // eslint-disable-line no-param-reassign
      return updated;
    }, {});

    return {
      ...state,
      queues: {
        ...state.queues,
        ...queueUpdate,
      },
    };
  },

  [SET_QUEUES_FOR_MANAGER]: (state, action) => {
    const {
      employeeId,
      queues = [],
    } = action.payload;

    return {
      ...state,
      queuesByManager: {
        ...state.queuesByManager,
        [employeeId]: queues.map((item) => item.QueueID),
      },
    };
  },

  [SET_QUEUES_COUNT]: (state, action) => ({
    ...state,
    queuesCount: action.payload,
  }),

  [REMOVE_QUEUE]: (state, action) => {
    const queues = { ...state.queues };
    delete queues[action.payload];

    return {
      ...state,
      queues,
    };
  },

  [CLEAR_QUEUE_DATA]: () => initialState,
};

function queuesReducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default queuesReducer;
