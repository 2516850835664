import requests from 'api/requests';

import setFulfillments from './setFulfillments';
import setFulfillmentsForFilter from './setFulfillmentsForFilter';
import setFulfillmentsForFilterCount from './setFulfillmentsForFilterCount';

const getFulfillmentsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.fulfillments.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setFulfillments(response.data.fulfillments));
    dispatch(setFulfillmentsForFilter(filterId)(response.data.fulfillments));
    dispatch(setFulfillmentsForFilterCount(filterId)(response.data.fulfillmentsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentsForFilter;
