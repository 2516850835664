import {
  SET_REALTOR_CONTACTS_FOR_FILTER,
} from 'store/actionTypes/realtorContacts';

const setContactsForFilter = (filterId) => (contacts) => ({
  type: SET_REALTOR_CONTACTS_FOR_FILTER,
  payload: {
    filterId,
    contacts,
  },
});

export default setContactsForFilter;
