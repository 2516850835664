import get from './get';
import create from './create';
import getById from './getById';
import update from './update';
import getLinkedObjectsSummaries from './getLinkedObjectsSummaries';
import linkObjectsToContact from './linkObjectsToContact';
import getChangelog from './getChangelog';
import getSummary from './getSummary';

export default {
  get,
  create,
  getById,
  update,
  getLinkedObjectsSummaries,
  linkObjectsToContact,
  getChangelog,
  getSummary,
};
