/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import useContactName from 'shared/hooks/contacts/useContactName';

import {
  SearchedContactWrapper,
} from './styledItems';

const SearchedContact = (props) => {
  const {
    contactId,
    contact,
    onClick,
  } = props;

  const {
    email_primary,
  } = contact;

  const contactName = useContactName(contactId);

  return (
    <SearchedContactWrapper onClick={onClick}>
      <Typography
        variant="body"
        noMargin
      >
        {contactName}
      </Typography>
      <Typography
        variant="body"
        className="contact-email"
        noMargin
      >
        {email_primary}
      </Typography>
    </SearchedContactWrapper>
  );
};

const {
  shape,
  func,
  string,
  number,
} = PropTypes;

SearchedContact.propTypes = {
  contactId: number.isRequired,
  contact: shape({
    email_primary: string,
  }).isRequired,
  onClick: func.isRequired,
};

export default SearchedContact;
