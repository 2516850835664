import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import requests from 'api/requests';

import useDevice from 'sections/Calls/hooks/useDevice';
import useActivePhones from 'sections/Calls/hooks/useActivePhones';

import CallsContext from 'sections/Calls/context/CallsContext';

import sanitizePhoneNumber from 'shared/utils/sanitizePhoneNumber';
import isAValidPhoneNumber from 'shared/utils/isAValidPhoneNumber';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

const CallsProvider = (props) => {
  const {
    children, // eslint-disable-line react/prop-types
  } = props;

  if (isSectionUnderConstruction('calls')) {
    return (
      <>
        {children}
      </>
    );
  }

  const {
    device,
    isLoading,
    isMicrophoneAvailable,

    inputList,
    outputList,
    inputDevice,
    outputDevice,

    setInputDevice,
    setOutputDevice,

    refreshDevicesList,
    stopUsingMic,
    startUsingMic,
  } = useDevice();

  const {
    activePhones,
    isLoading: isPhoneListLoading,
    selectedPhone,
    setSelectedPhone,
    getActivePhones,
  } = useActivePhones();

  const [callTo, setCallTo] = useState('');

  const [call, setCall] = useState(null);
  const [callSid, setCallSid] = useState(null);
  const [callStatus, setCallStatus] = useState('idle');

  const [recordingStatus, setRecordingStatus] = useState('stopped');
  const [recordingStatusPending, setRecordingStatusPending] = useState(false);

  const dial = useCallback(async (event, shouldRecord = false, contactId = null) => {
    if (!isMicrophoneAvailable) {
      return;
    }

    const params = {
      To: sanitizePhoneNumber(callTo),
      From: sanitizePhoneNumber(selectedPhone),
    };

    setCallStatus('calling');

    await startUsingMic();

    const newCall = await device.connect({ params });

    setCall(newCall);

    newCall.on('accept', () => {
      setCallStatus('in call');

      if (shouldRecord) {
        startRecording(contactId, newCall?.parameters?.CallSid);
      }
    });

    newCall.on('disconnect', () => {
      setCallStatus('idle');
      setCall(null);

    requests.calls
      .saveCall(newCall?.parameters?.CallSid)({
        contactId,
        callerPhoneNumber: sanitizePhoneNumber(selectedPhone),
        caleePhoneNumber: sanitizePhoneNumber(callTo),
      });
    });

    newCall.on('cancel', () => {
      setCallStatus('idle');
      setCall(null);
    });

    newCall.on('error', (error) => {
      setCallStatus('idle');
      setCall(null);
    });
  }, [
    device,
    callTo,
    selectedPhone,
    setCallStatus,
    startUsingMic,
    isMicrophoneAvailable,
  ]);

  useEffect(() => {
    if (call?.parameters?.CallSid) {
      setCallSid(call?.parameters?.CallSid);
    } else {
      setCallSid(null);
      setRecordingStatus('stopped');
      setRecordingStatusPending(false);
      stopUsingMic();
    }
  }, [
    call?.parameters?.CallSid,
  ]);

  const hangUp = useCallback(() => {
    call && call.disconnect(); // eslint-disable-line chai-friendly/no-unused-expressions
    setCallStatus('idle');
    setCall(null);
  }, [
    setCallStatus,
    setCall,
    call,
  ]);

  const startRecording = useCallback(async (contactId = null, callSidParam = null) => {
    if (!callSidParam && !callSid) {
      return;
    }
    setRecordingStatusPending(true);

    const recordingStatusResponse = await requests.calls.recordings
      .create(callSidParam || callSid)({
        contactId,
        callerPhoneNumber: sanitizePhoneNumber(selectedPhone),
        caleePhoneNumber: sanitizePhoneNumber(callTo),
      });

    setRecordingStatus(recordingStatusResponse);
    setRecordingStatusPending(false);
  }, [
    callSid,
    callTo,
    selectedPhone,
  ]);

  const updateRecording = useCallback(async (action) => {
    if (!callSid || !action) {
      return;
    }
    setRecordingStatusPending(true);

    const recordingStatusResponse = await requests.calls.recordings.update(callSid)(action);

    setRecordingStatus(recordingStatusResponse);
    setRecordingStatusPending(false);
  }, [
    callSid,
  ]);

  return (
    <CallsContext.Provider value={{
      device,
      isLoading,
      isMicrophoneAvailable,

      inputList,
      outputList,
      inputDevice,
      outputDevice,

      setInputDevice,
      setOutputDevice,

      refreshDevicesList,
      stopUsingMic,

      activePhones,
      isPhoneListLoading,
      selectedPhone,
      setSelectedPhone,
      getActivePhones,

      callTo,
      setCallTo,

      callStatus,
      setCallStatus,

      recordingStatus,
      recordingStatusPending,

      dial,
      hangUp,
      startRecording,
      updateRecording,

      dialButtonDisabled: !isAValidPhoneNumber(sanitizePhoneNumber(callTo)) || !isMicrophoneAvailable,
    }}
    >
      {children}
    </CallsContext.Provider>
  );
};

export default CallsProvider;
