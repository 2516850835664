/* eslint-disable */
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import articlesPublic from './articlesPublic';
import articleDetail from './articleDetail'; // eslint-disable-line import/no-cycle
import attachments from './attachments'; // eslint-disable-line import/no-cycle
import auth from './auth'; // eslint-disable-line import/no-cycle
import billing from './billing'; // eslint-disable-line import/no-cycle
import comments from './comments'; // eslint-disable-line import/no-cycle
import customersLabels from './customersLabels'; // eslint-disable-line import/no-cycle
import dashboard from './dashboard'; // eslint-disable-line import/no-cycle
import dashboardListView from './dashboard-list-view'; // eslint-disable-line import/no-cycle
import dictionary from './dictionary'; // eslint-disable-line import/no-cycle
import employeeDetails from './employeeDetails'; // eslint-disable-line import/no-cycle
import externalStore from './external-store'; // eslint-disable-line import/no-cycle
import globalData from './globalData';
import HR from './HR'
import invoices from './invoices';
import knowledgeBase from './knowledgeBase'; // eslint-disable-line import/no-cycle
import labels from './labels';
import leads from './leads'; // eslint-disable-line import/no-cycle
import licenseManagement from './license-management';
import logs from './logs'; // eslint-disable-line import/no-cycle
import mood from 'store/reducers/mood'; // eslint-disable-line import/no-cycle
import myCompany from './my-company'; // eslint-disable-line import/no-cycle
import notifications from './notifications'; // eslint-disable-line
import payment from './payment';
import queueSetup from './queue-setup'; // eslint-disable-line import/no-cycle
import sales from './sales'; // eslint-disable-line import/no-cycle
import salesTeams from './salesTeams'; // eslint-disable-line import/no-cycle
import tasks from './tasks'; // eslint-disable-line import/no-cycle
import tasksListView from './tasks-list-view'; // eslint-disable-line import/no-cycle
import teamSetup from './team-setup'; // eslint-disable-line import/no-cycle
import tickets from './tickets'; // eslint-disable-line import/no-cycle
import ticketsListView from './tickets-list-view'; // eslint-disable-line import/no-cycle
import todolist from './to-do-list'; // eslint-disable-line
import tutorial from './tutorial';
import users from './users';
import systemCheck from './systemCheck'

import tasksV2 from './tasksV2';
import teamsV2 from './teamsV2';

import ticketsV2 from './ticketsV2';
import queuesV2 from './queuesV2';

import leadsV2 from './leadsV2';

import chat from './chat';

import customers from './customers';

import videoChat from './video-chat';

import articles from './articles';

import taskPlanner from './taskPlanner';
// CRM
import newLeadForm from './crm/newLeadForm'
import newLeadModal from './crm/newLeadModal'
import crmProducts from './crm/crmProducts'
import crmSources from './crm/crmSources'
import crmCompanies from './crm/crmCompanies'
import crmContacts from './crm/crmContacts'
import crmLeads from './crm/crmLeads'
import crmLeadsFilter from './crm/crmLeadsFilter'
import newContactForm from './crm/newContactForm'
import newContactModal from './crm/newContactModal';
import crmContactsFilter from './crm/crmContactsFilter'
import newCompanyForm from './crm/newCompanyForm';
import newCompanyModal from './crm/newCompanyModal';
import crmCompaniesFilter from './crm/crmCompaniesFilter';
// shared dictionary
import dictionaryV2 from './dictionaryV2'

import call from './call';

import fulfillments from './fulfillments/fulfillments';
import fulfillmentsFilter from './fulfillments/fulfillmentsFilter';
import newFulfillmentForm from './fulfillments/newFulfillmentForm'
import newFulfillmentModal from './fulfillments/newFulfillmentModal';

import agreements from './agreements/agreements';
import orders from './orders/orders';
import customerInvoices from './customerInvoices/customerInvoices';
import customerPayments from './customerPayments/customerPayments';
import shippedItems from './shippedItems/shippedItems';
import addresses from './addresses/addresses';
import servicePeople from './servicePeople/servicePeople';
import lineItems from './lineItems/lineItems';
import customerDigitalAccounts from './customerDigitalAccounts/customerDigitalAccounts';

import attachmentsV2 from './attachmentsV2';

import deals from './deals/deals'
import serviceProcesses from './serviceProcesses/serviceProcesses'

import ordersCancelled from './ordersCancelled/ordersCancelled'
import refunds from './refunds/refunds'
import creditNotes from './creditNotes/creditNotes'
import customerInformed from './customerInformed/customerInformed'

//Warehouse
import warehouse from './warehouse/warehouse'

// Realtor
import realtorLeads from './realtorLeads/leads';
import realtorLeadsFilter from './realtorLeads/realtorLeadsFilter';
import realtorLeadsObjectsFilter from './realtorLeads/realtorLeadsObjectsFilter';
import realtorNewLeadForm from './realtorLeads/newLeadForm';
import realtorNewLeadModal from './realtorLeads/newLeadModal';
import archiveDeleteLeadModal from './realtorLeads/archiveDeleteLeadModal';
import leadIntoProspectModal from './realtorLeads/leadIntoProspectModal';
import realtorTransactionsFilter from './realtorTransactions/realtorTransactionsFilter';
import properties from './properties/properties';
import propertiesFilter from './properties/propertiesFilter';
import propertyRequirements from './property-requirements/requirements';
import propertyViewings from './property-viewings/viewings';
import newPropertyViewingModal from './property-viewings/newViewingModal';
import newPropertyViewingForm from './property-viewings/newViewingForm';
import editPropertyViewingModal from './property-viewings/editViewingModal';
import editPropertyViewingForm from './property-viewings/editViewingForm';
import realtorContacts from './realtorContacts/contacts';
import realtorContactsFilter from './realtorContacts/realtorContactsFilter';
import realtorNewContactModal from './realtorContacts/newContactModal';
import realtorNewContactForm from './realtorContacts/newContactForm';
import realtorNewContactLogModal from './realtorContacts/newLogModal';
import realtorNewContactLogForm from './realtorContacts/newLogForm';
import realtorNewPropertyModal from './realtorMls/newPropertyModal';
import realtorNewPropertyForm from './realtorMls/newPropertyForm';
import realtorNotablePointsAndDisclosures from './realtor-notable-points-disclosures/notable-points-disclosures';

import realtorNewClientModal from './realtorClients/newClientModal';
import realtorClients from './realtorClients/clients';
import realtorClientsFilter from './realtorClients/clientsFilter';

import realtorNewProspectModal from './realtorProspects/newProspectModal';
import realtorProspects from './realtorProspects/prospects';
import realtorProspectsFilter from './realtorProspects/prospectsFilter';

import realtorOpportunities from './realtorOpportunities/opportunities';
import realtorOpportunitiesFilter from './realtorOpportunities/opportunitiesFilter';

import realtorNewCounterModal from './realtorOpportunities/newCounterModal';

import realtorAcceptCounterModal from './realtorOpportunities/acceptCounterModal';
import realtorAcceptCounterForm from './realtorOpportunities/acceptCounterForm';

import realtorLogOfferModal from './realtorOpportunities/logOfferModal';
import realtorLogOfferForm from './realtorOpportunities/logOfferForm';

import realtorOfferDeclineReasonModal from './realtorOpportunities/offerDeclineReasonModal';
import realtorSearchMLSModal from './realtorOpportunities/searchMLSModal';
import assignInterestedPartiesModal from './realtorOpportunities/assignInterestedPartiesModal';

import realtorOffers from './realtorOffers/offers';

import realtorSpecialConditions from './realtorSpecialConditions/items'

import realtorMatchingProperties from './realtorMatchingProperties/matchingProperties'
import matchingPropertyRejectionModal from './realtorMatchingProperties/matchingPropertyRejectionModal';

import realtorTransactions from './realtorTransactions/transactions';

import realtorDocumentRequestForm from './realtorTransactions/documentRequestForm';
import realtorDocumentRequests from './realtorTransactions/documentRequests';

import realtorAddendumChangeForm from './realtorTransactions/addendumChangeForm';
import realtorAddendumChanges from './realtorTransactions/addendumChanges';

import logNewQuestionForm from './realtorTransactions/logNewQuestionForm';
import realtorUnansweredQuestions from './realtorTransactions/unansweredQuestions';

import realtorExtensionRequestForm from './realtorTransactions/extensionRequestForm';
import realtorExtensionRequests from './realtorTransactions/extensionRequests';

import cancelTransactionModal from './realtorTransactions/cancelTransactionModal';
import realtorRepairRequests from './realtorRepairRequests/repairRequests';
import newRealtorRepairRequestForm from './realtorRepairRequests/newRepairRequestForm';
import newRealtorRepairRequestModal from './realtorRepairRequests/newRepairRequestModal';
import realtorRepairRequestsModal from './realtorRepairRequests/repairRequestsModal';

import realtorEscrows from './realtorTransactions/escrows';
import realtorLoans from './realtorTransactions/loans';

import realtorInspections from './realtorInspections/inspections';
import realtorInspectionsFilter from './realtorInspections/realtorInspectionsFilter';
import realtorNewInspectionForm from './realtorInspections/newInspectionForm';
import realtorNewInspectionModal from './realtorInspections/newInspectionModal';

import realtorPriorityObjects from './realtorPriorityObjects/realtorPriorityObjects';

import realtorMatched from './realtorMatched/matched';

//
import likes from './likes-dislikes/likes';
import dislikes from './likes-dislikes/dislikes';

import followUpReminders from './followUpReminder/followupReminders';

import modals from './modals/modals';

import socialIntegrations from './socialIntegrations/socialIntegrations';
import externalAccess from './externalAccess/externalAccess';

import realtorDirectory from './realtorDirectory/realtorDirectory';

import appCustomization from './appCustomization/appCustomization';

import calls from './calls/calls';
import callLogsFilter from './calls/callLogsFilter';

import phoneNumbers from './phoneNumbers/phoneNumbers';
import emails from './emails/emails';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  articlesPublic,
  articles,
  articleDetail,
  attachments,
  auth,
  billing,
  appCustomization,
  chat,
  comments,
  customersLabels,
  dashboard,
  dashboardListView,
  dictionary,
  employeeDetails,
  externalStore,
  globalData,
  HR,
  invoices,
  knowledgeBase,
  labels,
  leads,
  licenseManagement,
  logs,
  mood,
  myCompany,
  notifications,
  payment,
  queueSetup,
  sales,
  salesTeams,
  systemCheck,
  tasks,
  tasksListView,
  teamSetup,
  tickets,
  ticketsListView,
  todolist,
  tutorial,
  users,

  tasksV2,
  teamsV2,

  ticketsV2,
  queuesV2,

  leadsV2,

  customers,

  videoChat,

  taskPlanner,

  // CRM
  newLeadForm,
  newLeadModal,
  crmProducts,
  crmSources,
  crmCompanies,
  crmContacts,
  crmLeads,
  crmLeadsFilter,
  newContactForm,
  newContactModal,
  crmContactsFilter,
  newCompanyForm,
  newCompanyModal,
  crmCompaniesFilter,

  // shared dictionary
  dictionaryV2,

  // call
  call,

  //
  fulfillments,
  fulfillmentsFilter,
  newFulfillmentForm,
  newFulfillmentModal,

  //
  customerInvoices,
  orders,
  customerPayments,
  shippedItems,
  addresses,
  agreements,

  attachmentsV2,
  servicePeople,
  lineItems,
  customerDigitalAccounts,

  deals,
  serviceProcesses,

  ordersCancelled,
  refunds,
  creditNotes,
  customerInformed,

  //Warehouse
  warehouse,

  // Realtor
  realtorLeads,
  realtorLeadsFilter,
  realtorLeadsObjectsFilter,
  realtorNewLeadForm,
  realtorNewLeadModal,
  archiveDeleteLeadModal,
  leadIntoProspectModal,
  properties,
  propertiesFilter,
  propertyRequirements,
  propertyViewings,
  newPropertyViewingModal,
  newPropertyViewingForm,
  editPropertyViewingModal,
  editPropertyViewingForm,
  realtorContacts,
  realtorContactsFilter,
  realtorNewContactModal,
  realtorNewContactForm,
  realtorNewContactLogModal,
  realtorNewContactLogForm,
  realtorNotablePointsAndDisclosures,

  // --> MLS
  realtorNewPropertyModal,
  realtorNewPropertyForm,

  //
  likes,
  dislikes,

  // realtor clients
  realtorClientsFilter,
  realtorNewClientModal,
  realtorClients,


  // realtor prospects
  realtorNewProspectModal,
  realtorProspects,
  realtorProspectsFilter,

  // realtor opportunities: clients + prospects
  realtorOpportunities,
  realtorOpportunitiesFilter,
  realtorNewCounterModal,

  realtorAcceptCounterModal,
  realtorAcceptCounterForm,

  realtorLogOfferModal,
  realtorLogOfferForm,

  realtorOffers,
  realtorSpecialConditions,

  // realtor transactions
  realtorEscrows,
  realtorLoans,
  realtorOfferDeclineReasonModal,
  realtorSearchMLSModal,
  assignInterestedPartiesModal,

  realtorMatchingProperties,
  matchingPropertyRejectionModal,

  // realtor transactions
  realtorTransactions,
  cancelTransactionModal,
  realtorTransactionsFilter,

  // realtor inspections
  realtorInspections,
  realtorInspectionsFilter,
  realtorNewInspectionForm,
  realtorNewInspectionModal,

  // realtor repair request
  realtorRepairRequests,
  newRealtorRepairRequestForm,
  newRealtorRepairRequestModal,
  realtorRepairRequestsModal,

  realtorDocumentRequestForm,
  realtorDocumentRequests,

  logNewQuestionForm,
  realtorUnansweredQuestions,

  realtorAddendumChangeForm,
  realtorAddendumChanges,

  realtorExtensionRequestForm,
  realtorExtensionRequests,

  realtorPriorityObjects,

  realtorMatched,

  followUpReminders,

  modals,

  socialIntegrations,
  externalAccess,

  calls,
  callLogsFilter,

  phoneNumbers,
  emails,

  realtorDirectory,
});

const rootReducer = history => (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    // Sic! We need to explicitly assign 'undefined' to clear the state on logout
    state = undefined;
  }

  return appReducer(history)(state, action);
};

export default rootReducer;
/* eslint-enable */
