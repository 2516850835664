import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const style = {
  maxWidth: '100%',
  display: 'block',
};

const NotesCell = (props) => {
  const {
    title,
    description,
  } = props;

  return (
    <div>
      {
        title ? (
          <Typography
            variant="cellLarge"
            weight={500}
            ellipsis
            style={{ ...style, marginBottom: 5 }}
          >
            {title}
          </Typography>
        ) : null
      }
      {
        description ? (
          <Typography
            variant="cell2"
            color="disabled"
            ellipsis
            style={style}
          >
            {description}
          </Typography>
        ) : null
      }
    </div>
  );
};

NotesCell.defaultProps = {
  title: '',
  description: '',
};

const {
  string,
} = PropTypes;

NotesCell.propTypes = {
  title: string,
  description: string,
};

export default NotesCell;
