import apiConnector, { endpoints } from 'api';

const sendTest = (campaignId) => async (data) => {
  const endpoint = `${endpoints.client.integrations.emailCampaignsActions}/sendTest/${campaignId}`;

  try {
    const result = await apiConnector.client.post(endpoint, data);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default sendTest;
