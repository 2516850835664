/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Upload,
} from 'antd';

import { Controller } from 'react-hook-form';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import LinkInput from 'shared/AttachmentsV4/LinkInput';

import {
  UploadWrapper,
  LinkInputContainer,
  ExternalLinksContainer,
  ExternalLink,
} from './styledItems';

const { Dragger } = Upload;

const AttachmentsUpload = (props) => {
  const {
    control,
    getValues,
    setValue,
  } = props;

  const onUpload = useCallback(({ file }) => {
    const fileList = getValues('attachments');
    const fileUrl = URL.createObjectURL(file);

    const fileObject = {
      name: file.name,
      type: file.type,
      uid: file.uid,
      url: fileUrl,
    };

    const newList = fileList.concat([fileObject]);

    setValue('attachments', newList);
  }, [
    getValues,
    setValue,
  ]);

  const onRemove = useCallback(({ uid, url }) => {
    const fileList = getValues('attachments');
    URL.revokeObjectURL(url);

    const newList = fileList.filter((file) => file.uid !== uid);

    setValue('attachments', newList);
  }, [
    getValues,
    setValue,
  ]);

  const onAddLink = useCallback((url) => {
    const fileList = getValues('attachments');

    const fileObject = {
      isExternalLink: true,
      linkUrl: url,
    };

    const newList = fileList.concat([fileObject]);

    setValue('attachments', newList);
  }, [
    getValues,
    setValue,
  ]);

  const filterFiles = useCallback((items) => {
    if (!Array.isArray(items)) {
      return [];
    }

    return items.filter((item) => !item.isExternalLink);
  }, []);

  const filterExternalLinks = useCallback((items) => {
    if (!Array.isArray(items)) {
      return [];
    }

    return items.filter((item) => item.isExternalLink);
  }, []);

  return (
    <UploadWrapper>
      <Controller
        name="attachments"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <div>
            <LinkInputContainer>
              <LinkInput onEnter={onAddLink} />
              <Typography
                noMargin
                weight="bold"
                style={{ marginTop: 15 }}
              >
                or
              </Typography>
            </LinkInputContainer>
            <Dragger
              listType="picture"
              accept="image/png, image/jpeg, .pdf, .doc, .txt"
              fileList={filterFiles(field.value)}
              customRequest={onUpload}
              onRemove={onRemove}
              multiple
            >
              <Flex
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  name="upload"
                  color="deep-blue"
                  size="lg"
                />
                <Typography
                  color="main-text"
                  align="center"
                  noMargin
                >
                  Click or drag listing agreement to this area to upload
                </Typography>
              </Flex>
            </Dragger>

            <ExternalLinksContainer>
              {
                filterExternalLinks(field.value).map((item) => (
                  <ExternalLink href={item.linkUrl} target="_blank" rel="noopener noreferrer">
                    <Typography
                      variant="link"
                    >
                      {item.linkUrl}
                    </Typography>
                  </ExternalLink>
                ))
              }
            </ExternalLinksContainer>
          </div>
        )}
      />
    </UploadWrapper>
  );
};

const {
  func,
  shape,
} = PropTypes;

AttachmentsUpload.propTypes = {
  control: shape().isRequired,
  getValues: func.isRequired,
  setValue: func.isRequired,
};

export default AttachmentsUpload;
