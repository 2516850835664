import {
  SET_FULFILLMENTS_FOR_FILTER_COUNT,
} from 'store/actionTypes/fulfillments';

const setFulfillmentsForFilterCount = (filterId) => (count) => ({
  type: SET_FULFILLMENTS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setFulfillmentsForFilterCount;
