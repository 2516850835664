import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormTextField from 'shared/components/UI/FormTextfield';

import {
  Title,
  Subtitle,
} from './styledItems';

const SourceDescriptionForm = (props) => {
  const {
    sourceName,
    sourceDescription,
    onNameChange,
    onDescriptionChange,
  } = props;

  const handleNameChange = useCallback((event) => {
    onNameChange(event.target.value);
  });

  const handleDescriptionChange = useCallback((event) => {
    onDescriptionChange(event.target.value);
  });

  return (
    <form>
      <Title>
        Custom source:
        {` ${sourceName}`}
      </Title>
      <Subtitle>Set a name and description for this source</Subtitle>
      <FormTextField
        label="Source name"
        onChange={handleNameChange}
        value={sourceName}
      />
      <FormTextField
        multiline
        label="Source description"
        onChange={handleDescriptionChange}
        value={sourceDescription}
      />
    </form>
  );
};

SourceDescriptionForm.defaultProps = {
  sourceName: 'Lead source',
  sourceDescription: '',
};

const {
  func,
  string,
} = PropTypes;

SourceDescriptionForm.propTypes = {
  sourceName: string,
  sourceDescription: string,
  onNameChange: func.isRequired,
  onDescriptionChange: func.isRequired,
};

export default SourceDescriptionForm;
