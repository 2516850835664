import {
  useState,
  useEffect,
} from 'react';

import stringify from 'json-stable-stringify';

import requests from 'api/requests';

import { omit } from 'shared/utility';

import getIds from 'store/utils/getIds';

const useContactsList = (filters, infiniteScroll = false) => {
  const [contacts, setContacts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [currentQueryId, setCurrentQueryId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const filterId = stringify(filters);
  const queryId = stringify(omit(filters, ['limit', 'offset']));

  const getContacts = async (contactFilters) => {
    setIsLoading(true);
    setError(null);

    const sameQuery = queryId === currentQueryId;

    try {
      const response = await requests.realtor.contacts.get(contactFilters);

      const newTotalCount = response?.data?.itemsCount || 0;

      const itemIds = getIds(response?.data?.items);

      const newContacts = infiniteScroll && sameQuery
        ? [
            ...new Set([
              ...contacts,
              ...itemIds,
            ]),
          ]
        : itemIds;

      setContacts(newContacts);
      setTotalCount(newTotalCount);

      setCanLoadMore(newTotalCount > newContacts.length);
    } catch (newError) {
      setError(newError);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setContacts([]);
    setError(null);
    setError(null);
    setTotalCount(0);
    setIsLoading(false);
  };

  useEffect(() => {
    getContacts(filters);
    setCurrentQueryId(queryId);
  }, [
    filterId,
  ]);

  return {
    contacts,
    isLoading,
    totalCount,
    error,
    reset,
    canLoadMore,
  };
};

export default useContactsList;
