const tutorialRoutes = {
  tickets: {
    createQueues: '/customer-service/ticket-assignment/tutorial-create-queues',
    agentSubmitTicket: '/customer-service/my-tickets/tutorial-agent-submit-ticket',
    customerTicketSubmit: '/company-settings/company-information/general/tutorial-customer-ticket-submit',
    assignTickets: '/customer-service/ticket-assignment/tutorial-assign-tickets',
    processTicket: '/customer-service/my-tickets/tutorial-process-ticket',
    root: '/customer-service',
  },
  tasks: {
    createNewTask: '/tasks/tutorial-create-new-task',
    createNewSubtask: '/tasks/tutorial-create-new-subtask',
    archiveTask: '/tasks/tutorial-archive-task',
    completeTask: '/tasks/tutorial-complete-task',
    createTeams: '/tasks/team-setup/tutorial-create-teams',
    assignTasks: '/tasks/team-overview/tutorial-assign-tasks',
    root: '/tasks',
  },
  sales: {
    createSalesTeam: '/sales/sales-teams/tutorial-create-sales-team',
    createNewLead: '/sales/my-leads/tutorial-create-new-lead',
    createNewDeal: '/sales/my-leads/tutorial-create-new-deal',
    // takeUnassignedLeads: '/sales/my-leads/unassigned-leads/tutorial-take-unassigned-leads',
    updatePitch: '/sales/my-leads/tutorial-update-pitch',
    createMeeting: '/sales/my-leads/tutorial-create-meeting',
    changePitchProgress: '/sales/my-leads/tutorial-change-pitch-progress',
    closeDeal: '/sales/my-leads/tutorial-close-deal',
    root: '/sales',
  },
  hr: {
    addEmployees: '/team/employees/tutorial-add-employees',
    createTeams: '/team/departments-teams/tutorial-create-teams',
    processAttendance: '/team/manager-attendance/processing/tutorial-process-attendance',
    attendanceReports: '/team/manager-attendance/reports/tutorial-attendance-reports',
    clockInOut: '/team/my-attendance/tutorial-clock-in-out',
    updateEmployeeRecord: '/team/employees/tutorial-update-employee-records',
    root: '/team',
  },
  general: {
    resetPassword: '/team/employees/tutorial-reset-password',
    deleteUser: '/team/employees/tutorial-delete-user',
    removeLicenses: '/team/employees/tutorial-assign-remove-licenses',
    createUser: '/team/employees/tutorial-create-user',
    buyCancelLicenses: '/company-settings/license-management/tutorial-buy-cancel-licenses',
    root: '/company-settings',
  },
};

export default tutorialRoutes;
