/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import { Controller } from 'react-hook-form';

import PriceRange from 'shared/inputs/PriceRange';

const Budget = (props) => {
  const {
    control,
    errors,
    value,
  } = props;

  return (
    <Flex spacing={2} flexWrap="wrap">
      <Typography
        weight="bold"
        noMargin
      >
        Budget
      </Typography>

      <Controller
        name="budget"
        control={control}
        defaultValue={value}
        render={({ field }) => (
          <FlexItem fullWidth>
            <PriceRange
              {...field}
              fullWidth
              handleChange={field.onChange}
              label="Budget"
              error={!!errors.budget}
              // helperText={errors.budget ? 'This field is required' : ''}
            />
          </FlexItem>
        )}
        rules={{
          required: true,
          min: 1,
        }}
      />
    </Flex>
  );
};

Budget.defaultProps = {
  value: null,
};

const {
  shape,
  number,
} = PropTypes;

Budget.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  value: number,
};

export default Budget;
