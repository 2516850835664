import BaseApiConnector from './BaseApiConnector';

class SmartzipApiConnector extends BaseApiConnector {
  constructor() {
    super(
      process.env.REACT_APP_SMARTZIP_API_URL,
      'application/json',
      'apiKey',
      { api_key: process.env.REACT_APP_SMARTZIP_KEY },
    );
  }
}

export default SmartzipApiConnector;
