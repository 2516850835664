import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_ESCROW,
} from 'store/actionTypes/realtorEscrow';

const initialState = {
  escrows: {},
};

const reducers = {
  [SET_REALTOR_ESCROW]: (state, action) => ({
    ...state,
    escrows: {
      ...state.escrows,
      [action.payload.id]: {
        ...action.payload,
      },
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
