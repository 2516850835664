import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

import { // eslint-disable-line import/no-cycle
  optimizedDisclosureActions,
} from 'store/actions/realtorNotablePointsAndDisclosures/disclosures';

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.realtorNotablePointsAndDisclosures.disclosures,
  (_, propertyId) => propertyId,
  (
    properties,
    disclosures,
    id,
  ) => {
    const property = properties[id];

    if (!property) {
      if (id) {
        optimizedPropertyActions.getPropertyById(id);
      }

      return [];
    }

    const disclosuresIds = property.disclosures || [];

    const propertyDisclosures = disclosuresIds.reduce((accum, disclosureId) => {
      const disclosure = disclosures[disclosureId];

      if (!disclosure) {
        if (disclosureId) {
          optimizedDisclosureActions.getDisclosureById(disclosureId);
        }

        return accum;
      }

      accum.push({
        id: disclosure.id,
        title: disclosure.title,
        description: disclosure.description,
      });
      return accum;
    }, []);

    return propertyDisclosures;
  },
);
