import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedClientActions,
} from 'store/actions/realtorClients';

import { // eslint-disable-line import/no-cycle
  optimizedDisclosureActions,
} from 'store/actions/realtorNotablePointsAndDisclosures/disclosures';

export default createSelector(
  (state) => state.realtorClients.clients,
  (state) => state.realtorNotablePointsAndDisclosures.disclosures,
  (_, clientId) => clientId,
  (
    clients,
    disclosures,
    id,
  ) => {
    const client = clients[id];

    if (!client) {
      if (id) {
        optimizedClientActions.getClientById(id);
      }

      return [];
    }

    const disclosuresIds = client.disclosures || [];

    const clientDisclosures = disclosuresIds.reduce((accum, disclosureId) => {
      const disclosure = disclosures[disclosureId];

      if (!disclosure) {
        if (disclosureId) {
          optimizedDisclosureActions.getDisclosureById(disclosureId);
        }

        return accum;
      }

      accum.push({
        id: disclosure.id,
        title: disclosure.title,
        description: disclosure.description,
      });
      return accum;
    }, []);

    return clientDisclosures;
  },
);
