/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import PropertyParametersMain from 'sections/Properties/components/PropertyParametersMain';

const PropertyParametersFilters = (props) => {
  const {
    control,
    searchMLS,
    setValue,
  } = props;

  const handleChange = useCallback((parameterName) => (value) => {
    setValue('filters.propertyParameters', { [parameterName]: value || null });
    searchMLS();
  }, []);

  return (
    <Controller
      name="filters.propertyParameters"
      control={control}
      defaultValue={{
        bedrooms: 0,
        bathrooms: 0,
        floor_area: 0,
        plot_area: 0,
        parking: 0,
      }}
      render={({ field }) => (
        <PropertyParametersMain
          parameters={field.value}
          onChange={handleChange}
        />
      )}
    />
  );
};

const {
  shape,
  func,
} = PropTypes;

PropertyParametersFilters.propTypes = {
  control: shape().isRequired,
  searchMLS: func.isRequired,
  setValue: func.isRequired,
};

export default PropertyParametersFilters;
