export default {
  // palette: {
  //   highlight: '#FFA500'
  // },
  // typography: {
  //   fontFamily: {
  //     h1: '"Pinyon Script", cursive',
  //     button: 'Estilo'
  //   }
  // }
};
