import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'ui/Typography';

export const Root = styled.div`
  margin: 2px 0;
  padding: 5px;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: ${((props) => props.theme?.palette?.hover || '##edeef0')};;
  }
`;

export const Name = styled(Typography)`
  margin-bottom: 2px;
  text-transform: capitalize;
`;

export default {
  Root,
  Name,
};
