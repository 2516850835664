import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import ClickAwayListener from 'ui/ClickAwayListener';

import useEditMode from 'ui/hooks/useEditMode';

import PlacesAutocomplete from 'shared/components/UI/PlacesAutocompleteV2';

import MultipleLocationChips from 'sections/Addresses/modules/MultipleLocationChips';

const MultipleLocationInput = (props) => {
  const {
    onChange,
    onRemove,
    addressIds,
    canAdd,
    defaultView,
    placeholder,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  const handleChange = useCallback((location) => {
    onChange({
      address: location?.formatted_address,
    });
  }, [
    onChange,
  ]);

  return (
    <Flex
      spacing={1}
      flexWrap="wrap"
      fullWidth
    >
      {
        mode === 'edit' && (
          <FlexItem fullWidth>
            <ClickAwayListener
              onClickAway={setDisplayMode}
            >
              <PlacesAutocomplete
                placeholder={placeholder}
                variant="outlined"
                onSelect={handleChange}
                onChange={handleChange}
              />
            </ClickAwayListener>
          </FlexItem>
        )
      }
      {
        (addressIds || mode === 'display') && (
          <MultipleLocationChips
            onAdd={canAdd && mode === 'display' && setEditMode}
            onRemove={onRemove}
            addressIds={addressIds}
          />
        )
      }
    </Flex>
  );
};

MultipleLocationInput.defaultProps = {
  addressIds: [],
  canAdd: true,
  defaultView: 'edit',
  placeholder: 'Preferred location (city or exact address)',
};

const {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  string,
} = PropTypes;

MultipleLocationInput.propTypes = {
  onChange: func.isRequired,
  onRemove: func.isRequired,
  addressIds: arrayOf(number),
  canAdd: bool,
  defaultView: oneOf(['edit', 'display']),
  placeholder: string,
};

export default MultipleLocationInput;
