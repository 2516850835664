import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getFulfillmentsForFilter from './getFulfillmentsForFilter';

const getFulfillmentsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.fulfillments.filter(getState());

  try {
    await dispatch(getFulfillmentsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getFulfillmentsForStoreFilter;
