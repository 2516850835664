import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import { Empty } from 'antd';

import SearchedProperty from './SearchedProperty';

import {
  Border,
} from './styledItems';

const SearchResult = (props) => {
  const {
    searchedProperties,
    onPropertyClick,
  } = props;

  return (
    <div>
      <>
        {!isEmpty(searchedProperties)
          ? searchedProperties.map((propertyId) => (
            <>
              <SearchedProperty
                onClick={onPropertyClick(propertyId)}
                propertyId={propertyId}
              />
              <Border />
            </>
          ))
          : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
      </>
    </div>
  );
};

const {
  arrayOf,
  func,
  number,
} = PropTypes;

SearchResult.propTypes = {
  searchedProperties: arrayOf(number).isRequired,
  onPropertyClick: func.isRequired,
};

export default SearchResult;
