import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';

import CommentContext from 'shared/contexts/CommentContext';

import RichTextEditor from 'shared/components/RichTextEditor'; // eslint-disable-line import/no-cycle
import Typography from 'ui/Typography';
import CommentControls from 'shared/components/CommentControls';

// This dependency cycle is created on purpose to allow infinite thread nesting
import CommentsList from 'shared/components/CommentsList'; // eslint-disable-line import/no-cycle

import hasUserSeenComment from 'shared/utils/hasUserSeenComment';

import './CommentThread.scss';

class CommentThread extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisibleToCustomer: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isVisible,
      getComments,
    } = this.props;

    if (!prevProps.isVisible && isVisible) {
      getComments();
    }
  }

  postComment = (commentText) => {
    const {
      postComment,
      isSympleteUser,
    } = this.props;

    const {
      isVisibleToCustomer,
    } = this.state;

    const comment = {
      Comment: commentText,
      IsPublic: isVisibleToCustomer || !isSympleteUser,
    };

    postComment(comment);
  }

  editComment = ({ commentId, commentText }) => {
    const {
      editComment,
    } = this.props;

    editComment({ commentId, commentText });
  }

  deleteComment = (commentId) => {
    const {
      deleteComment,
    } = this.props;

    deleteComment(commentId);
  }

  acknowledgeComment = (commentId) => {
    const {
      acknowledgeComment,
    } = this.props;

    acknowledgeComment(commentId);
  }

  markAsSeen = (commentId, seenBy) => {
    const {
      markCommentAsSeen,
    } = this.props;

    if (!hasUserSeenComment(seenBy)) {
      markCommentAsSeen(commentId);
    }
  }

  togglePostPublic = () => {
    this.setState(({ isVisibleToCustomer }) => ({
      isVisibleToCustomer: !isVisibleToCustomer,
    }));
  }

  commentControls = ({ onSubmit, onCancel }) => { // eslint-disable-line no-shadow
    const { isSympleteUser } = this.props;

    // TODO need to find another solution not to use window.location
    const isTicket = window.location.pathname.split('/')[1] === 'customer-service';
    return (
      <CommentControls
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideCustomerVisibilitySelector={!isSympleteUser || !isTicket}
        togglePostPublic={this.togglePostPublic}
      />
    );
  }

  render() {
    const {
      onCancel,
      comments,
      getComments,
      isVisible,
    } = this.props;

    return (
      <>
        <Modal
          visible={isVisible}
          onCancel={onCancel}
          title="Thread Details"
          footer={null}
        >
          <CommentContext.Consumer>
            {
              (parentCommentedObjectType) => (
                <RichTextEditor
                  value=""
                  onClickCancel={onCancel}
                  onClickPost={this.postComment}
                  ControlsComponent={this.commentControls}
                />
              )
            }
          </CommentContext.Consumer>
          <div className="symplete-modal-simple__body">
            <CommentsList
              comments={comments}
              onDelete={this.deleteComment}
              onLike={this.acknowledgeComment}
              onEdit={this.editComment}
              markAsSeen={this.markAsSeen}
              onUpdate={getComments}
            />
          </div>
        </Modal>
      </>
    );
  }
}

CommentThread.defaultProps = {
  isVisible: false,
  comments: [],
  onCancel: () => {},
  isSympleteUser: false,
};

const {
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

const commentCreatorShape = shape({
  CreatorId: number,
  CreatorType: number,
});

const userShape = shape({
  UserId: number,
  UserType: number,
  CreateAt: number,
});

const commentShape = shape({
  Comment: string.isRequired,
  CommentID: number,
  CommentType: number,
  CommentedObjectID: number,
  CommentedObjectType: number,
  CreatedAt: oneOfType([instanceOf(Date), string, number]).isRequired,
  CreatorID: number.isRequired,
  CreatorType: number.isRequired,
  IsOwner: bool,
  IsPrivate: bool,
  RepliesCount: number,
  ReplyCreators: arrayOf(commentCreatorShape),
  SeenBy: arrayOf(userShape),
  AcknowledgedBy: arrayOf(userShape),
  ResolvedBy: arrayOf(userShape),
});

const customItemShape = shape({
  render: element.isRequired,
});

CommentThread.propTypes = {
  acknowledgeComment: func.isRequired,
  comments: arrayOf(oneOfType([commentShape, customItemShape])),
  deleteComment: func.isRequired,
  isVisible: bool,
  onCancel: func,
  postComment: func.isRequired,
  getComments: func.isRequired,
  editComment: func.isRequired,
  markCommentAsSeen: func.isRequired,
  isSympleteUser: bool,
};

export default CommentThread;
