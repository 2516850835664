import {
  SET_REALTOR_MATCHING_PROPERTIES,
} from 'store/actionTypes/realtorMatchingProperties';

const setByFilter = (items) => ({
  type: SET_REALTOR_MATCHING_PROPERTIES,
  payload: items,
});

export default setByFilter;
