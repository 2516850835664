import {
  SET_DIGITAL_ACCOUNT,
} from 'store/actionTypes/customerDigitalAccounts';

const setDigitalAccount = (payload) => ({
  type: SET_DIGITAL_ACCOUNT,
  payload,
});

export default setDigitalAccount;
