import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import requests from 'api/requests';

// const getSympleteDataIndex = (item) => item.sympleteDataIndex;
const getSympleteDataIndex = (acc, item) => {
  acc[item?.sympleteDataIndex] = true;
  return acc;
};

const useImport = ({
  fieldMap,
  uploadedFileId,
  selectedTypes,
  previewDataSource = [],
}) => {
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);

  // const [selectedRows, setSelectedRows] = useState(previewDataSource.map(getSympleteDataIndex));
  const [selectedRows, setSelectedRows] = useState(previewDataSource.reduce(getSympleteDataIndex, {}));

  useEffect(() => {
    // setSelectedRows(previewDataSource.map(getSympleteDataIndex));
    setSelectedRows(previewDataSource.reduce(getSympleteDataIndex, {}));
  }, [
    previewDataSource,
  ]);

  const importData = useCallback(async () => {
    setIsLoading(true);

    const rows = Object.entries(selectedRows).reduce((acc, item) => {
      if (item[1]) acc.push(+item[0]);

      return acc;
    }, []);

    await requests.dataImport.importFromFile(selectedTypes)(fieldMap)(rows)(uploadedFileId);

    setIsLoading(false);
  }, [
    fieldMap,
    uploadedFileId,
    selectedTypes,
    selectedRows,
  ]);

  const reset = useCallback(() => {
    setIsLoading(false);
    setSelectedRows({});
  });

  return {
    isLoading,
    importData,
    selectedRows,
    setSelectedRows,
    reset,
  };
};

export default useImport;
