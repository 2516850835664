import connectReducers from 'store/connectReducers';

import {
  SET_MODAL_OPEN,
  SET_MODAL_CLOSED,
} from 'store/actionTypes/modals';

const initialState = {

};

const reducers = {
  [SET_MODAL_OPEN]: (state, action) => {
    const payload = action.payload || {};

    return {
      ...state,
      [action.modal]: {
        isVisible: true,
        ...payload,
      },
    };
  },

  [SET_MODAL_CLOSED]: (state, action) => {
    const payload = action.payload || {};

    return {
      ...state,
      [action.modal]: {
        isVisible: false,
        ...payload,
      },
    };
  },
};

function modals(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default modals;
