import apiConnector, { endpoints } from 'api';

const property = async (data) => {
  const endpoint = endpoints.client.dataScraping.properties;

  try {
    const result = await apiConnector.client.get(endpoint, data);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default property;
