import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} company
 * @param {string} company.Title
 */
const create = async (company) => {
  const endpoint = endpoints.client.crm.companies;

  try {
    const result = await apiConnector.client.post(endpoint, company);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
