/* eslint-disable max-len, consistent-return */
import apiConnector, { endpoints } from 'api';
import { callAlert } from 'ui/Alert/Alert';

const uploadExternalAttachment = (attachmentType) => (objectType) => (objectId) => async (attachmentData, isPublic = false) => {
  const endpoint = `${endpoints.external.attachFile}/${attachmentType}/${objectType}/${objectId}${isPublic ? '/public' : ''}`;
  try {
    const response = await apiConnector.externalAttachmentsUpload.post(endpoint, attachmentData);
    return response.data;
  } catch (error) {
    callAlert.error('Error uploading attachment');
  }
};

export default uploadExternalAttachment;
