import {
  SET_CRM_PRODUCTS_FOR_FILTER,
} from 'store/actionTypes/crm';

const setProductsForFilter = (filterId) => (products) => ({
  type: SET_CRM_PRODUCTS_FOR_FILTER,
  payload: {
    filterId,
    products,
  },
});

export default setProductsForFilter;
