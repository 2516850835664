import requests from 'api/requests';

import setProspects from './setProspects';

const getProspects = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.prospects.get({
      offset,
      limit,
    });

    dispatch(setProspects(response.data.prospects));
  } catch (error) {
    console.error(error);
  }
};

export default getProspects;
