import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import OfferDetails from 'sections/Offers/modules/OfferDetails';

const OfferDetailsModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    contentOptions,
  } = props;

  return (
    <Modal
      theme={theme}
      width={1120}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Offer Details"
      footer={null}
    >
      <OfferDetails
        {...contentOptions} // eslint-disable-line react/jsx-props-no-spreading
      />
    </Modal>
  );
};

OfferDetailsModal.defaultProps = {
  isVisible: false,
  theme: null,
  contentOptions: {},
};

const {
  func,
  bool,
  shape,
} = PropTypes;

OfferDetailsModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
  contentOptions: shape(),
};

export default OfferDetailsModal;
