import styled from 'styled-components';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

export const CustomFlex = styled(Flex)`
&& {
  padding: 5px;
  background-color: ${(props) => (props.checked ? props.theme.palette.focus : '')};
  border-radius: 10px;
}
`;

export const CustomTypography = styled(Typography)`
&& {
  color: ${(props) => (props.checked ? 'white' : '')};
}
`;
