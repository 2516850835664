import React from 'react';

const initialSteps = [
  {
    name: 'tutorial-add-employees',
    target: '[data-tutorial="add-new-employees"]',
    content: (
      <ol>
        <li>
          Click the action button and either add a single employee or select to import employees.
        </li>
        <li>
          If importing, match your CSV fields with Symplete fields and follow import steps.
        </li>
        <li>New employees will show as New on the Employee list.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Add new employees',
    styles: {
      options: {
        zIndex: 1000,
        arrowColor: '#9013fe',
      },
    },
    manager: true,
  },
  {
    name: 'tutorial-create-teams',
    target: '[data-tutorial="create-teams-departments"]',
    content: (
      <ol>
        <li>Click Actions button and choose Team Setup.</li>
        <li>Click the New Department button.</li>
        <li>
          Set the department name and add teams under that department.&nbsp;
          (departments added here are also added to the company settings area.)
        </li>
        <li>Add Managers and Team Employees.</li>
      </ol>),
    disableBeacon: true,
    placement: 'bottom-end',
    title: 'Create teams & departments',
    manager: true,
    styles: {
      options: {
        arrowColor: '#9013fe',
      },
    },
  },
  {
    name: 'tutorial-process-attendance',
    target: '[data-tutorial="add-new-employees"]',
    content: (
      <ol>
        <li>Click on Manager Attendance.</li>
        <li>View all employees whos attendance have not yet been processed.</li>
        <li>Add hours for the available statuses. (changes are saved in realtime).</li>
      </ol>),
    disableBeacon: true,
    placement: 'bottom',
    title: 'Process attendance',
    offset: 0,
    styles: {
      options: {
        arrowColor: '#9013fe',
      },
    },
    manager: true,
  },
  {
    name: 'tutorial-attendance-reports',
    target: '[data-tutorial="download-attendance-report"]',
    content: (
      <ol>
        <li>Click the reports tab on manager attendance.</li>
        <li>Use the filters on the left to see relevant data.</li>
        <li>Once your data has been selected, download the report.</li>
      </ol>),
    disableBeacon: true,
    placement: 'bottom-end',
    title: 'Download attendance report',
    styles: {
      options: {
        zIndex: 1000,
        arrowColor: '#9013fe',
      },
    },
    manager: true,
  },
  {
    name: 'tutorial-clock-in-out',
    target: '[data-tutorial="clock-in-out"]',
    content: (
      <ol>
        <li>Go to the My Attendance page.</li>
        <li>At the start of the day, clock-in.</li>
        <li>At lunch time, lunch in.</li>
        <li>After lunch, lunch-out.</li>
        <li>Clock-Out at the end of the day and view your day on the reports page.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Clock-In / Clock-Out',
    styles: {
      options: {
        arrowColor: '#9013fe',
      },
    },
  },
  {
    name: 'tutorial-update-employee-records',
    target: '[data-tutorial="update-employee-records"]',
    content: (
      <ol>
        <li>Click an employee in Team to view their records.</li>
        <li>Edits made are saved in realtime.</li>
        <li>Complete as much information as possible to make the system work even better!</li>
      </ol>),
    disableBeacon: true,
    placement: 'right-start',
    offset: 100,
    title: 'Update employee records',
    styles: {
      options: {
        arrowColor: 'rgba(0, 0, 0, 0)',
      },
    },
    manager: true,
  },
];

export default initialSteps;
