import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import SpecialConditionsItem from './SpecialConditionsItem';

const SpecialConditions = (props) => {
  const {
    specialConditions,
  } = props;

  if (specialConditions.length < 1) {
    return (
      <Typography
        color="success"
        weight="bold"
      >
        No special conditions
      </Typography>
    );
  }

  return specialConditions.map((item) => (
    <SpecialConditionsItem value={item.value} />
  ));
};

const {
  arrayOf,
  string,
} = PropTypes;

SpecialConditions.propTypes = {
  specialConditions: arrayOf(string).isRequired,
};

export default SpecialConditions;
