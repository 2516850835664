import getChats from './getChats';
import addChatMember from './addChatMember';
import createDirectChat from './createDirectChat';
import getThread from './getThread';
import createGroupChat from './createGroupChat';
import editChatType from './editChatType';
import setChatUserStatus from './setChatUserStatus';
import deleteChatForAllUsers from './deleteChatForAllUsers';
import markMessageAsRead from './markMessageAsRead';
import getUserUnreadMessage from './getUserUnreadMessage';
import getChatById from './getChatById';

export default {
  getUserUnreadMessage,
  markMessageAsRead,
  deleteChatForAllUsers,
  getChatById,
  editChatType,
  setChatUserStatus,
  createGroupChat,
  getChats,
  addChatMember,
  createDirectChat,
  getThread,
};
