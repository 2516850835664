import apiConnector, { endpoints } from 'api';

const updateProduct = (relationId) => async (updateData) => {
  const endpoint = `${endpoints.client.deals2products}/${relationId}`;

  try {
    const result = await apiConnector.client.patch(endpoint, updateData);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default updateProduct;
