import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const Root = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 120px;
  padding: 16px 8px;
  margin-bottom: 16px;
  margin-left: -5px;
  background-color: ${(props) => props?.theme?.palette?.navColorSecondary || 'gray'};
  border-radius: 12px;
  text-align: center;

  svg,
  p {
    color: ${(props) => props.theme?.palette?.['main-text-inversed'] || 'black'};
    stroke: ${(props) => props.theme?.palette?.['main-text-inversed'] || 'black'};
  }

  svg {
    path {
      /* fill: ${(props) => props.theme?.palette?.['main-text-inversed'] || 'black'}; */
    }
  }

  &.active {
    svg,
    p {
      color: ${(props) => props.theme?.palette?.navColorActiveFont || 'blue'};
      stroke: ${(props) => props.theme?.palette?.navColorActiveFont || 'blue'};
    }

    ${(props) => (props.iconType === 'custom' ? `
      svg {
        path {
          fill: ${props.theme?.palette?.navColorActiveFont || 'blue'};
        }
      }
    ` : null)}
  }

  &.active,
  &:hover {
    /* border-radius: 4px; */
    /* background-color:  ${(props) => props.theme?.palette?.hover || 'lightgray'}; */
  }

  &.disabled {
    svg,
    p {
      color: ${(props) => props.theme?.palette?.disabled || 'gray'};
      stroke: ${(props) => props.theme?.palette?.disabled || 'gray'};
    }

    svg {
      path {
        /* fill: ${(props) => props.theme?.palette?.disabled || 'gray'}; */
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-height: 950px) {
    padding: 12px 8px;
    margin-bottom: 12px;
  }
`;

export const IconWrapper = styled.div`
  min-width: 30px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Root,
  IconWrapper,
};
