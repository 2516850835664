import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useContactDefaultPhoneNumber = (
  contactId,
) => {
  const phoneNumbers = useReselect(selectors.realtorContacts.contactPhoneNumbers, contactId);

  const primaryPhoneNumber = phoneNumbers.find((item) => item.is_primary);

  return primaryPhoneNumber?.e164_format || phoneNumbers[0]?.e164_format;
};

export default useContactDefaultPhoneNumber;
