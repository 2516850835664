import { connect } from 'react-redux';

import selectors from 'store/selectors';

import moment from 'moment';

import InspectionCell from './InspectionCell';

const mapStateToProps = (state, ownProps) => {
  const {
    inspectionId,
  } = ownProps;

  const inspection = selectors.realtorInspections.byId(inspectionId)(state);

  if (!inspectionId || !inspection.id) {
    return {
        value: '—',
      };
}
  const inspectionTypes = selectors.realtorInspections.inspectionTypes(state) || [];

  const types = inspectionTypes?.reduce((acc, item) => {
    acc[item.id] = item.name;
    return acc;
  }, {});

  const value = `${types[inspection.inspection_type_id]}: ${moment(inspection.date_time).format('MMM DD, YYYY')}`;

  return {
    value,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionCell);
