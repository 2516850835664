import requests from 'api/requests';

import setMatched from './setMatched';
import setInfiniteScrollMatchedForFilter from './setInfiniteScrollMatchedForFilter';
import setMatchedForFilterCount from './setMatchedForFilterCount';

const getInfiniteScrollMatchedForFilter = (filterId) => (filter) => (
    offset = 0,
    limit = 50,
    resetState = false,
  ) => async (dispatch) => {
  try {
    const response = await requests.realtor.matched.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setMatched(response.data.items));
    dispatch(setInfiniteScrollMatchedForFilter(filterId)(response.data.items)(resetState));
    dispatch(setMatchedForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getInfiniteScrollMatchedForFilter;
