import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';
import Tag from 'ui/Tag';
import Flex from 'ui/Flex';

import RealtorContactAvatar from 'shared/modules/RealtorContactAvatar';
import ClickableEventWrapper from 'sections/Calendar/modules/ClickableEventWrapper';

import {
  StyledElevation,
  PropertyPhoto,
  InformationWrapper,
  Header,
  AvatarContainer,
} from './styledItems';

const ScheduleItem = (props) => {
  const {
    itemData: {
      labels,
      description,
      attachment,
      date,
      contactId,
      objectType,
      objectId,
    },
    onClick,
  } = props;

  return (
    <ClickableEventWrapper objectType={objectType} objectId={objectId} onClick={onClick}>
      <StyledElevation>
        {
          contactId ? (
            <AvatarContainer>
              <RealtorContactAvatar
                contactId={contactId}
                size="s"
                round
                onSave={null}
                withNameTooltip
              />
            </AvatarContainer>
          ) : (
            <PropertyPhoto src={attachment?.src} />
          )
        }
        <InformationWrapper>
          <Header>
            <Flex spacing={1} alignItems="center">
              {
                labels.map((item) => (
                  <Tag
                    variant={item.variant}
                    size="s"
                  >
                    {item.title}
                  </Tag>
                ))
              }
            </Flex>
            <Typography
              variant="subtitle"
              color="descriptor-text"
              noMargin
            >
              {moment(date).format('LT')}
            </Typography>
          </Header>
          <Typography
            variant="subtitle"
            color="descriptor-text"
            noMargin
          >
            {description}
          </Typography>
        </InformationWrapper>
      </StyledElevation>
    </ClickableEventWrapper>
  );
};

ScheduleItem.defaultProps = {
  onClick: null,
};

const {
  shape,
  string,
  arrayOf,
  number,
  func,
} = PropTypes;

ScheduleItem.propTypes = {
  itemData: shape({
    labels: arrayOf(shape({
      title: string.isRequired,
      variant: string.isRequired,
    })),
    description: string,
    attachment: string,
    date: string,
    contactId: number,
  }).isRequired,
  onClick: func,
};

export default ScheduleItem;
