import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  Label,
  Line,
} from './styledItems';

const TimelineMark = (props) => {
  const {
    label,
    height,
  } = props;

  return (
    <Root>
      <Label>{label}</Label>
      <Line height={height} />
    </Root>
  );
};

TimelineMark.defaultProps = {
  label: null,
  height: 180,
};

const {
  number,
  string,
} = PropTypes;

TimelineMark.propTypes = {
  label: string,
  height: number,
};

export default TimelineMark;
