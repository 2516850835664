import ErrorLogoBlack from '../assets/images/errorpagelogo.png';
import ErrorLogoYellow from '../assets/images/yellow-logo.png';
import NotFound from '../assets/images/404Image.png';
import InternalErrorImage from '../assets/images/500Error.png';

// TODO - reorganize constants so they make sense
// eslint-disable-next-line import/prefer-default-export
export const error = {
  NOT_FOUND: {
    header: '**404 Page**',
    image: NotFound,
    logo: ErrorLogoYellow,
    title: 'Uhoh! Page not found.',
    subtitle: 'The page you are looking for doesn\'t exist or has moved!',
    hasFooter: true,
  },
  INTERNAL_ERROR: {
    header: '**500 Page**',
    image: InternalErrorImage,
    logo: ErrorLogoBlack,
    title: 'Oops! Something went wrong.',
    subtitle: 'An internal server error occured while processing your request. Please help us fix it by reporting this.',
    hasFooter: true,
  },
  WRONG_FILE: {
    header: null,
    logo: ErrorLogoBlack,
    image: NotFound,
    title: 'Uhoh!',
    subtitle: 'You are not authorized to view this file.',
    hasFooter: false,
  },
};
