import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import AreaHighlight from 'ui/AreaHighlight';

import ActivePhonesList from 'sections/Calls/modules/ActivePhonesList';

import SMS from 'sections/Calls/modules/SMS';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

const CallsSettings = (props) => {
  if (isSectionUnderConstruction('calls')) {
    return null;
  }

  const {
    openAddNumberModal,
    openDeviceSetupModal,
  } = props;

  return (
    <Flex
      flexDirection="column"
      spacing={2}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        fullWidth
      >
        <Typography noMargin>
          Connect your mobile numbers to make calls directly from your Symplete account.
        </Typography>
        <Flex spacing={2}>
          <Button
            variant="secondary"
            icon="settings"
            onClick={openDeviceSetupModal}
          >
            Device Settings
          </Button>
          <Button
            variant="primary"
            onClick={openAddNumberModal}
          >
            + Add Number
          </Button>
        </Flex>
      </Flex>
      <ActivePhonesList />

      {
        !isSectionUnderConstruction('sms') && (
          <AreaHighlight>
            <SMS />
          </AreaHighlight>
        )
      }
    </Flex>
  );
};

const {
  func,
} = PropTypes;

CallsSettings.propTypes = {
  openAddNumberModal: func.isRequired,
  openDeviceSetupModal: func.isRequired,
};

export default CallsSettings;
