import * as Sentry from "@sentry/react";

const handleAsyncError = (error) => {
  Sentry.captureException(error, {
    tags: {
      async: 'yes',
    },
  })

  throw new Error(error)
}

export default handleAsyncError