import {
  SET_CRM_CONTACTS_FOR_FILTER_COUNT,
} from 'store/actionTypes/crm';

const setContactsForFilterCount = (filterId) => (count) => ({
  type: SET_CRM_CONTACTS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setContactsForFilterCount;
