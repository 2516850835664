import { createSelector } from 'reselect';
import { map } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

import filterSelector from './filter';

const filterByMaxCompletedStep = (weight, status) => {
  if (weight === 0 && status === 0) {
    return true;
  }
  return (status & weight) > 0 && status < (weight * 2); // eslint-disable-line no-bitwise
};

const filterByMinIncompletedStep = (weight, status) => status < weight;

// eslint-disable-next-line no-bitwise
const filterByAnyCompletedStep = (weights = [], status) => weights.some((weight) => (status & weight) > 0);

// eslint-disable-next-line no-bitwise, eqeqeq
const filterByAnyIncompletedStep = (weights = [], status) => weights.some((weight) => (status & weight) == 0);

// eslint-disable-next-line no-bitwise
const filterByEveryCompletedStep = (weights = [], status) => weights.every((weight) => (status & weight) > 0);

// eslint-disable-next-line no-bitwise
const isStepCompleted = (weight, status) => (status & weight) > 0;

const specificLaneFilters = {
  new_orders: {
    max_completed_step: 0,
  },
  send_invoice: {
    max_completed_step: 1,
  },
  awaiting_payment: {
    max_completed_step: 2,
  },
  create_order_pickup: {
    any_completed_step: [4, 8, 16],
    min_incompleted_step: 32,
  },
  delivery_feedback: {
    any_completed_step: [32, 64],
    any_incompleted_step: [32, 64],
  },
  fulfilled: {
    every_completed_step: [32, 64],
  },
};

const filterToFunc = {
  max_completed_step: filterByMaxCompletedStep,
  min_incompleted_step: filterByMinIncompletedStep,
  any_completed_step: filterByAnyCompletedStep,
  any_incompleted_step: filterByAnyIncompletedStep,
  every_completed_step: filterByEveryCompletedStep,
};

const laneNames = {
  new_orders: 'New Orders',
  send_invoice: 'Send Invoice',
  awaiting_payment: 'Awaiting Payment',
  create_order_pickup: 'Create Order / Pickup',
  delivery_feedback: 'Delivery / Feedback',
  fulfilled: 'Fulfilled',
};

const stepValueToIncompletedMessage = {
  1: 'Agreement step is not completed',
  2: 'Invoice step is not completed',
  4: 'Payment step is not completed',
  8: 'Order step is not completed',
  16: 'Shipping step is not completed',
  32: 'Delivery step is not completed',
  64: 'Feedback step is not completed',
};

export default createSelector(
  (state) => state.fulfillments.fulfillmentsByFilter,
  (state, lane) => lane,
  (state) => state.fulfillments.fulfillmentsSummaryByFilter,
  (state) => state.fulfillments.fulfillments,
  (state) => state,
  (
    fulfillmentsByFilter,
    lane,
    fulfillmentsSummaryByFilter,
    fulfillments,
    state,
  ) => {
    const { filterId, filter } = filterSelector(state);

    const laneFilter = {
      ...filter,
      ...specificLaneFilters[lane],
    };

    if (!fulfillmentsSummaryByFilter[filterId]) {
      optimizedFulfillmentsActions
        .getFulfillmentsSummaryForFilter(filterId, filter);
    }

    const allFulfillments = fulfillmentsByFilter[filterId] || [];

    const laneFulfillments = allFulfillments.reduce((accumulator, fulfillmentId) => {
      const fulfillment = fulfillments[fulfillmentId];
      const status = fulfillment.process_status;

      let passing = true;

      const incompletedSteps = [];

      const laneFilters = specificLaneFilters[lane];

      map(laneFilters, (value, key) => {
        passing = passing && filterToFunc[key](value, status);
      });

      if (passing) {
        map(stepValueToIncompletedMessage, (message, stepWeight) => {
          if (stepWeight < status && !isStepCompleted(stepWeight, status)) {
            incompletedSteps.push(message);
          }
        });

        accumulator.push({
          id: fulfillmentId,
          incompletedSteps,
        });
      }

      return accumulator;
    }, []);

    const hasMore = fulfillmentsSummaryByFilter[filterId]
      ? laneFulfillments.length < fulfillmentsSummaryByFilter[filterId]?.byColumn[lane]
      : true;

    const laneData = {
      id: lane,
      name: laneNames[lane],
      list: laneFulfillments,
      listHasMore: hasMore,
      loadMore: () => {
        optimizedFulfillmentsActions.getFulfillmentsForFilter(
          filterId,
          laneFilter,
          laneFulfillments.length,
        );
      },
    };

    return laneData;
  },
);
