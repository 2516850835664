/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */

import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';
import DateInput from 'shared/inputs/DateInput';

import { Controller } from 'react-hook-form';

import Flex from 'ui/Flex';

const LabeledDate = (props) => {
  const {
    formName,
    inputLabel,
    setValue,
    control,
    title,
  } = props;

  const handleDateInputChange = useCallback((value) => {
    setValue(formName, moment(value).format('YYYY-MM-DD HH:mm:ss'));
  }, [
    setValue,
    formName,
  ]);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Typography
        variant="title3"
        weight="normal"
        noMargin
      >
        {title}
      </Typography>

      <div className="date-input-wrapper">
        <Controller
          name={formName}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <DateInput
              {...field}
              // fullWidth
              handleChange={handleDateInputChange}
              onBlur={() => { }}
              label={inputLabel}
            />
          )}
        />
      </div>

    </Flex>
  );
};

LabeledDate.defaultProps = {
  formName: null,
  inputLabel: null,
  title: null,
};

const {
  func,
  shape,
  string,
} = PropTypes;

LabeledDate.propTypes = {
  formName: string,
  inputLabel: string,
  setValue: func.isRequired,
  control: shape().isRequired,
  title: string,
};

export default LabeledDate;
