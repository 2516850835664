import connectReducers from 'store/connectReducers';
import { keyBy } from 'shared/utility';

import {
  SET_ATTACHMENTS,
} from 'store/actionTypes/attachments';

const initialState = {
  attachments: {},
};

const reducers = {
  [SET_ATTACHMENTS]: (state, action) => {
    const {
      attachments,
    } = action.payload;

    return {
      ...state,
      attachments: {
        ...state.attachments,
        ...keyBy(attachments, (item) => item.Uuid || item.attachmentId),
      },
    };
  },
};

const attachmentsV2 = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default attachmentsV2;
