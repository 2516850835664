import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import CallRecordingPlayer from 'sections/Calls/modules/CallRecordingPlayer';

import formatCallDuration from 'sections/Calls/utils/formatCallDuration';

const DurationCell = (props) => {
  const {
    duration,
    recordingSid,
  } = props;

  return (
    <div>
      {
        duration ? (
          <Typography variant="cell" weight={500}>
            {formatCallDuration(duration)}
          </Typography>
        ) : null
      }
      {
        recordingSid && <CallRecordingPlayer recordingSid={recordingSid} />
      }
    </div>
  );
};

DurationCell.defaultProps = {
  duration: null,
  recordingSid: '',
};

const {
  string,
  number,
} = PropTypes;

DurationCell.propTypes = {
  duration: number,
  recordingSid: string,
};

export default DurationCell;
