import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Spin from 'ui/Spin';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import Avatar from 'shared/components/UI/RealtorContactAvatar';

import requests from 'api/requests';

import {
  Wrapper,
} from './styledItems';

const ContactCard = (props) => {
  const {
    id,
    name,
    email,
    loading,
    className,
    tokenId,
  } = props;

  const [pictureUrl, setPictureUrl] = useState(null);

  useEffect(() => {
    let url;
    async function fetchPicture() {
      url = await requests.integrations.contacts.picture({ contactId: id, tokenId });
      setPictureUrl(url);
    }

    fetchPicture();

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, []);

  return (
    <Wrapper className={className}>
      <Flex alignItems="center" justifyContent="space-between">
        <FlexItem>
          <Flex spacing={2}>
            <Avatar
              src={pictureUrl}
              size="s"
            />
            <div>
              <Typography
                noMargin
                style={{ marginBottom: 3 }}
              >
                {name}
              </Typography>
              <Typography
                noMargin
              >
                {email}
              </Typography>
            </div>
          </Flex>
        </FlexItem>
        <FlexItem>
          <div>
            {
              loading ? (
                <Spin />
              ) : (
                <Icon
                  name="check-circle"
                  color="success"
                  size="xl"
                />
              )
            }
          </div>
        </FlexItem>
      </Flex>
    </Wrapper>
  );
};

ContactCard.defaultProps = {
  email: '',
  loading: false,
};

const {
  number,
  string,
  bool,
} = PropTypes;

ContactCard.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  email: string,
  loading: bool,
  className: string.isRequired,
  tokenId: number.isRequired,
};

export default ContactCard;
