import { apiConnector } from 'shared/API';
import { callAlert } from 'ui/Alert';
import { LEADS_COMPANY_UPDATE, LEADS_GET_DETAILS } from 'store/actions/leads';

export const SET_SALES_COMPANIES_LABELS = 'SET_SALES_COMPANIES_LABELS';
export const setAllSalesCompaniesLabels = (payload) => ({
  type: SET_SALES_COMPANIES_LABELS,
  payload,
});

export const SET_SALES_COMPANY_LABELS = 'SET_SALES_COMPANY_LABELS';
export const setSalesCompanyLabels = (payload) => ({
  type: SET_SALES_COMPANY_LABELS,
  payload,
});

export const getAllSalesCompaniesLabels = () => (dispatch) => {
  apiConnector(
    '/api/leads/company/labels/all',
    'GET',
  ).then(({ result }) => {
    dispatch(setAllSalesCompaniesLabels(result));
  });
};

export const getLabelsBySalesCompany = () => (dispatch, getState) => {
  const {
    leads: {
      leadDetails: {
        CompanyObject,
      },
    },
  } = getState();

  if (CompanyObject) {
    const { CompanyID: SalesCompanyID } = CompanyObject;
    if (SalesCompanyID) {
      apiConnector(
        `/api/leads/company/labels/get/${SalesCompanyID}`,
        'GET',
      ).then(({ result }) => {
        dispatch(setSalesCompanyLabels(result));
      }).catch((e) => {
        callAlert.error(e.message);
      });
    }
  }
};

export const createSalesCompaniesLabel = (labelObject) => (dispatch) => {
  apiConnector(
    '/api/leads/company/labels/update/',
    'POST',
    labelObject,
  ).then(({ result }) => {
    dispatch(setAllSalesCompaniesLabels(result));
  });
};

export const updateSalesCompaniesLabel = (labelID, payload, callback) => (dispatch, getState) => {
  const {
    leads: {
      allSalesCompaniesLabels,
      leadDetails: {
        CompanyObject = {},
      },
    },
  } = getState();

  const { CompanyID: SalesCompanyID } = CompanyObject;

  const { color } = allSalesCompaniesLabels.find((label) => label.id === labelID);

  apiConnector(
    `/api/leads/company/labels/update/${labelID}`,
    'POST',
    {
      ...payload,
      color,
    },
  ).then(({ result }) => {
    dispatch(setAllSalesCompaniesLabels(result));

    dispatch(getLabelsBySalesCompany(SalesCompanyID));

    if (callback) callback();
  });
};

export const updateLabelsForSalesCompany = (labelsIDs) => (dispatch, getState) => {
  const {
    leads: {
      leadDetails: {
        CompanyObject = {},
      },
    },
  } = getState();

  const { CompanyID: SalesCompanyID } = CompanyObject;

  apiConnector(
    `/api/leads/company/labels/bind/${SalesCompanyID}`,
    'POST',
    {
      labelsIDs,
    },
  ).then(({ result }) => {
    dispatch(setSalesCompanyLabels(result));
    // dispatch({
    //   type: LEADS_COMPANY_UPDATE,
    //   result
    // })
  });
};
