import {
  SET_DEAL_PRODUCTS,
} from 'store/actionTypes/deals';

const setDealProducts = (dealId) => (products) => ({
  type: SET_DEAL_PRODUCTS,
  payload: {
    dealId,
    products,
  },
});

export default setDealProducts;
