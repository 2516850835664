/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import { meetingType } from 'oa/types/index';
import Modal from 'oa/components/Modal/index';
import InfoToCopy from './components/InfoToCopy';
import DesiredOutcomes from './components/DesiredOutcomes';
import './style.css';

const MeetingDetailsModal = (props) => {
  const {
    meeting,
    isOpen,
    onClose,
    toggleDesiredOutcome,
  } = props;

  const meetingDesiredOutcomes = meeting.desiredOutcomes || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="meeting-details-modal">
      <Modal.Content>
        <Modal.Title>Meeting Details</Modal.Title>

        <p className="meeting-details-modal__room-name">
          {meeting.name}
        </p>

        <InfoToCopy meeting={meeting} />

        <DesiredOutcomes
          toggleDesiredOutcome={toggleDesiredOutcome}
          desiredOutcomes={meetingDesiredOutcomes}
        />
      </Modal.Content>

      <Modal.Close />
    </Modal>
  );
};

MeetingDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  meeting: meetingType.isRequired,
  toggleDesiredOutcome: PropTypes.func.isRequired,
};

export default MeetingDetailsModal;
