import { apiConnector } from 'shared/API';

export const ERROR_TYPES = {
  NOT_FOUND: 'Not Found',
  FORBIDDEN: 'Forbidden',
  INTERNAL_ERROR: 'Internal Error',
};

export const logError = (error) => async (dispatch) => {
  const url = '/log-error';

  let response;

  try {
    response = await apiConnector(url, 'POST', { error });
    dispatch(reportSuccess(response));
  } catch (err) {
    dispatch(reportError());
  }
};

export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const reportSuccess = (message) => ({
  type: REPORT_SUCCESS,
  message,
});

export const REPORT_ERROR = 'REPORT_ERROR';
export const reportError = () => ({
  type: REPORT_ERROR,
});
