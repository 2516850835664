import React, {
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import Dialpad from 'sections/Calls/modules/Dialpad';
import UniversalContactPhoneInput from 'sections/Calls/modules/UniversalContactPhoneInput';
import DialpadButton from 'sections/Calls/components/DialpadButton';
import DialButton from 'sections/Calls/modules/DialButton';
import CallRecordingControl from 'sections/Calls/modules/CallRecordingControlV2';

import CallsContext from 'sections/Calls/context/CallsContext';

import { formatPhoneNumber } from 'shared/utils/phoneNumbers';
import noOp from 'shared/utils/noOp';

import CallDurationTag from './CallDurationTag';
import SelectedContactControl from './SelectedContactControl';
import { DialpadWrapper } from './styledItems';

const DialpadView = (props) => {
  const {
    contactId,
    setSelectedContact,
    callDuration,
  } = props;

  const {
    callTo,
    setCallTo,
    callStatus,
    //
    selectedPhone,
  } = useContext(CallsContext);

  const onInputFieldChange = useCallback((value) => {
    setCallTo(value);
  }, []);

  const onDialpadKeyClick = useCallback((value) => {
    setCallTo((prev) => (prev ? `${prev}${value}` : `${value}`));
  }, []);

  return (
    <Flex flexDirection="column" spacing={3}>
      <DialpadWrapper>
        <Flex
          fullWidth
          spacing={2}
          spacingStep={5}
          flexWrap="wrap"
          justifyContent="center"
        >
          <FlexItem xs={24}>
            <UniversalContactPhoneInput
              value={callTo}
              onChange={onInputFieldChange}
              selectedContact={contactId}
              onContactSelect={setSelectedContact}
            />
          </FlexItem>

          <FlexItem xs={24}>
            {
              contactId && (
                <SelectedContactControl
                  contactId={contactId}
                />
              )
            }
          </FlexItem>

          <FlexItem xs={24}>
            {
              (callStatus === 'in call' || callStatus === 'calling') && (
                <CallDurationTag
                  callDuration={callDuration}
                />
              )
            }
          </FlexItem>

          <FlexItem xs={24}>
            <Dialpad
              onClick={onDialpadKeyClick}
            />
          </FlexItem>

          <>
            <FlexItem xs={7}>
              <CallRecordingControl
                contactId={contactId}
              />
            </FlexItem>
            <FlexItem xs={7}>
              <DialButton
                variant="dialpad"
                contactId={contactId}
              />
            </FlexItem>
            <FlexItem xs={7} style={{ fontSize: '8px' }}>
              <DialpadButton
                onClick={noOp}
                extraValue="AI DPA (coming soon)"
                disabled
                color="disabled"
              />
            </FlexItem>
          </>
        </Flex>
      </DialpadWrapper>

      <Flex
        spacing={1}
        fullWidth
        justifyContent="center"
        style={{ margin: '12px 0' }}
      >
        <div>
          <Typography
            noMargin
            color="contrast"
          >
            Calling from:
          </Typography>
        </div>
        <div>
          <Typography
            noMargin
            color="contrast"
          >
            {formatPhoneNumber(selectedPhone)}
          </Typography>
        </div>
      </Flex>

    </Flex>
  );
};

DialpadView.defaultProps = {
  contactId: null,
  callDuration: 0,
};

const {
  func,
  number,
} = PropTypes;

DialpadView.propTypes = {
  contactId: number,
  callDuration: number,
  setSelectedContact: func.isRequired,
};

export default DialpadView;
