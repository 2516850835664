import React, {
  useContext,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import CallsContext from 'sections/Calls/context/CallsContext';

import useContact from 'sections/Contacts/hooks/useContact';
import usePrevious from 'shared/hooks/usePrevious';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import { StyledButton } from './styledItems';

const Call = (props) => {
  if (isSectionUnderConstruction('calls')) return null;

  const {
    openModal,
    onAfterRecording,
    showText,
    contactId,
    transparentButton,
  } = props;

  const contact = useContact(contactId);

  const { phone_numbers } = contact;

  const {
    recordingStatus,
  } = useContext(CallsContext);

  const prevRecordingStatus = usePrevious(recordingStatus);

  useEffect(() => {
    if (prevRecordingStatus !== 'stopped' && recordingStatus === 'stopped') {
      onAfterRecording();
    }
  });

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    openModal();
  }, [
    openModal,
  ]);

  return (
    <>
      {
        !transparentButton
        ? (
          <StyledButton
            fullWidth={showText}
            icon="phone"
            centered
            onClick={handleClick}
            disabled={phone_numbers?.length < 1}
          >
            {showText ? 'Call' : null}
          </StyledButton>

          )
        : (
          <Button
            variant="tertiary"
            icon="phone"
            iconSize="xs"
            iconColor="contrast"
            onClick={handleClick}
            disabled={phone_numbers?.length < 1}
          />
          )
      }
    </>
  );
};

Call.defaultProps = {
  showText: false,
  contactId: null,
  transparentButton: false,
};

const {
  func,
  bool,
  number,
} = PropTypes;

Call.propTypes = {
  openModal: func.isRequired,
  onAfterRecording: func.isRequired,
  showText: bool,
  contactId: number,
  transparentButton: bool,
};

export default Call;
