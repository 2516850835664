import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchedContact from './SearchedContact';

const mapStateToProps = (state, ownProps) => {
  const contact = selectors.realtorContacts.byId(state, ownProps.contactId);

  const contactPhoneNumbersRaw = contact?.phone_numbers || [];
  const primaryPhoneNumber = contactPhoneNumbersRaw.find((item) => item.is_primary);
  const defaultPhoneNumberId = primaryPhoneNumber?.phone_number_id || contactPhoneNumbersRaw[0]?.phone_number_id;

  return {
    contact,
    defaultPhoneNumberId,
  };
};

export default connect(mapStateToProps)(SearchedContact);
