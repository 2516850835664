import requests from 'api/requests';

import {
  filePreloader,
} from 'store/actions/attachments';

import setAttachments from './setAttachments';

const getObjectAttachments = (objectType) => (objectId) => (query) => async (dispatch) => {
  dispatch(filePreloader(true));

  if (!objectType || !objectId) {
    dispatch(filePreloader(false));
    return;
  }

  let response;

  try {
    response = await requests.attachments.getObjectAttachmentsWithQuery(objectType)(objectId)(query);

    dispatch(setAttachments(response?.data));
  } catch (error) {
    console.error(error);
  }

  dispatch(filePreloader(false));

  return response?.data;
};

export default getObjectAttachments;
