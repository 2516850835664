import { connect } from 'react-redux';

import {
  openNewContactModal,
} from 'store/actions/newRealtorContactModal';

import ContactsList from './ContactsList';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({
  openNewContactModal: () => dispatch(openNewContactModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
