export const colors = {
  limegreen: '#2ecc71',
  blue: '#1584ff',
};

export const palette = {
  primary: colors.limegreen,
  cold: '#00d3ff',
  warm: '#ffba4a',
  hot: '#ff526b',
  unchecked: '#b8c2c7',
  disabledButton: '#a1bdcb',
  selectedOption: colors.blue,
  avatarBackground: colors.blue,
};

const leadTempIcons = {
  cold: 'snowflake',
  warm: 'fire',
  hot: 'sun',
};

export default {
  colors,
  palette,
  leadTempIcons,
};
