import { connect } from 'react-redux';

import searchProperties from 'store/actions/properties/searchProperties';

import PropertiesSearch from 'shared/components/UI/RealtorMasterSearch/PropertiesSearch';

const mapDispatchToProps = (dispatch) => ({
  onSearch: async (searchQuery) => {
    if (!searchQuery) {
      return [];
    }
    const result = await dispatch(searchProperties({ search: searchQuery }));

    return result;
  },
});

export default connect(null, mapDispatchToProps)(PropertiesSearch);
