import styled from 'styled-components';

import { lighten } from 'polished';

export const Root = styled.div`
  width: 100%;
  .master-search-input {
    background-color: ${(props) => lighten(0.3, props.theme?.palette['background-overlay-light'])};
    input {
      border: none;
      color: ${(props) => props.theme?.palette['main-text-inversed']}
    }
  }
`;

export default {
  Root,
};
