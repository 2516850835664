import { url as defaultUrl } from 'paths';

import { callAlert } from 'ui/Alert';
import { TICKET_UPDATED } from 'constants/tickets.constants';

// TODO - fix dependency cycles
import { apiConnector, tokenFetch as fetch } from 'shared/API'; // eslint-disable-line import/no-cycle
import { setOverdue } from 'shared/utils'; // eslint-disable-line import/no-cycle

import getTicketAndRefreshRelatedSummaries from './ticketsV2/getTicketAndRefreshRelatedSummaries';

const statuses = {
  'New Assigned': 2,
  'In Progress': 3,
  Pending: 5,
};

export const GET_TICKETS_BY_STATUS = 'GET_TICKETS_BY_STATUS';
export const getTicketsByStatus = () => ({ type: GET_TICKETS_BY_STATUS });

export const GET_TICKETS_BY_STATUS_SUCCESS = 'GET_TICKETS_BY_STATUS_SUCCESS';
export const getTicketsByStatusSuccess = (activeTablesData) => ({
  type: GET_TICKETS_BY_STATUS_SUCCESS,
  activeTablesData,
});

export const GET_TICKETS_BY_STATUS_ERROR = 'GET_TICKETS_BY_STATUS_ERROR';
export const getTicketsByStatusError = () => ({ type: GET_TICKETS_BY_STATUS_ERROR });

export const SET_TICKETS_TO_TABLE_SUCCESS = 'SET_TICKETS_TO_TABLE_SUCCESS';
export const setTicketsToTableSuccess = (list) => ({
  type: SET_TICKETS_TO_TABLE_SUCCESS,
  list,
});

export const SET_TICKET_DATA = 'SET_TICKET_DATA';
export const setTicketData = (status, ticket) => ({
  type: SET_TICKET_DATA,
  status,
  ticket,
});

export const RESOLVE_TICKET_BY_MODAL = 'RESOLVE_TICKET_BY_MODAL';
export const resolveTicketByModal = () => ({ type: GET_TICKETS_BY_STATUS });

export const RESOLVE_TICKET_BY_MODAL_SUCCESS = 'RESOLVE_TICKET_BY_MODAL_SUCCESS';
export const resolveTicketByModalSuccess = (status, ticket, newResolvedName) => (dispatch) => {
  dispatch({
    type: RESOLVE_TICKET_BY_MODAL_SUCCESS,
    status,
    ticket,
    newResolvedName,
  });

  // dispatch(getTicketAndRefreshRelatedSummaries(ticket.TicketID));
};

export const RESOLVE_TICKET_BY_MODAL_ERROR = 'RESOLVE_TICKET_BY_MODAL_ERROR';
export const resolveTicketByModalError = () => ({ type: RESOLVE_TICKET_BY_MODAL_ERROR });

export const SHOW_ALL_PANELS = 'SHOW_ALL_PANELS';
export const showAllPanels = () => ({ type: SHOW_ALL_PANELS });

export const SET_PANELS = 'SET_PANELS';
export const setPanels = (panels) => ({
  type: SET_PANELS,
  panels,
});

export const SHOW_ARCHIVED_TICKETS = 'SHOW_ARCHIVED_TICKETS';
export const showArchivedTickets = () => ({ type: SHOW_ARCHIVED_TICKETS });

export const RATE_TICKET = 'RATE_TICKET';
export const rateTicket = () => ({ type: RATE_TICKET });

export const RATE_TICKET_SUCCESS = 'RATE_TICKET_SUCCESS';
export const rateTicketSuccess = (status, ticket, data) => ({
  type: RATE_TICKET_SUCCESS,
  status,
  ticket,
  data,
});

export const RATE_TICKET_ERROR = 'RATE_TICKET_ERROR';
export const rateTicketError = () => ({ type: RATE_TICKET_ERROR });

export const CHANGE_TICKET_STATUS = 'CHANGE_TICKET_STATUS';
export const changeTicketStatus = () => ({ type: CHANGE_TICKET_STATUS });

export const CHANGE_TICKET_STATUS_SUCCESS = 'CHANGE_TICKET_STATUS_SUCCESS';

export const UPDATE_TICKET_STATUS = 'CHANGE_TICKET_STATUS';
export const updateTicketStatus = (id, status, showSuccessMessage = true) => (dispatch, getState) => {
  const { Name = '', Surname = '' } = getState().auth.loggedUser;
  apiConnector(
    '/Tickets/ChangeTicketStatus',
    'POST',
    {
      TicketID: id,
      StatusID: status,
      UserFullName: `${Name} ${Surname}`,
    },
  )
    .then((res) => res.json())
    .then(() => {
      if (showSuccessMessage) {
        callAlert.success(TICKET_UPDATED);
      }
      dispatch(getTicketAndRefreshRelatedSummaries(id));
    // dispatch(getCustomerTicketInfoSuccess(json.results));
    })
    .catch(() => {
    // dispatch(getCustomerTicketInfoError(error))
    });
};

export const CHANGE_TICKET_STATUS_ERROR = 'CHANGE_TICKET_STATUS_ERROR';
export const changeTicketStatusError = () => ({ type: CHANGE_TICKET_STATUS_ERROR });

export const SET_TAB = 'SET_TAB';
export const setTab = (tab) => ({ type: SET_TAB, tab });

export const OPEN_MODAL = 'OPEN_LIST_VIEW_MODAL';
export const openModal = () => ({ type: OPEN_MODAL });

export const HIDE_MODAL = 'HIDE_LIST_VIEW_MODAL';
export const hideModal = () => ({ type: HIDE_MODAL });

export const SET_FILTER = 'SET_FILTER';
export const setFilter = ({ filterType = null, queueType = null }) => (dispatch, getState) => {
  const {
    ticketsListView: {
      activeFilter,
      activeQueue,
      tablesData,
      filterTypes,
    },
  } = getState();

  const filter = filterType || activeFilter;
  const queue = queueType || activeQueue;

  let activeTablesData = tablesData.map((table) => {
    if (filter === filterTypes.newComments) {
      return {
        ...table,
        list: table.list.filter((ticket) => ticket.NewComments),
      };
    } if (filter === filterTypes.overdue) {
      return { ...table, list: setOverdue(table.list) };
    }
    return table;
  });

  if (queue.QueueID !== 'All Queues') {
    activeTablesData = activeTablesData.map((table) => ({
      ...table,
      list: table.list.filter(({ QueueID, Category, SubCategory }) => {
        let result = true;
        if (queue.QueueID) result = QueueID === queue.QueueID;
        if (queue.category) result = Category === queue.category;
        if (queue.Product) result = SubCategory === queue.Product;
        return result;
      }),
    }));
  }

  dispatch({
    type: SET_FILTER,
    activeFilter: filter,
    activeQueue: {
      ...queue,
      name: (!!queue.SubCategoryID && queue.Product)
        || (!!queue.CategoryID && queue.category)
        || (!!queue.QueueID && queue.name),
    },
    activeTablesData,
  });
};

export const UNRESOLVE_TICKET = 'UNRESOLVE_TICKET';
export const unresolveTicket = (ticketId, status) => (dispatch, getState) => {
  const statusId = statuses[status];

  dispatch({ type: UNRESOLVE_TICKET });
  const url = `/api/tickets/unresolve/${ticketId}/${statusId}`;
  apiConnector(url, 'PUT', {})
    .then(() => {
      dispatch(dispatch(getTicketAndRefreshRelatedSummaries(ticketId)));
      dispatch(unresolveTicketSuccess());
    })
    .catch(() => dispatch(unresolveTicketError()));
};

export const UNRESOLVE_TICKET_SUCCESS = 'UNRESOLVE_TICKET_SUCCESS';
export const unresolveTicketSuccess = () => (dispatch) => {
  dispatch({ type: UNRESOLVE_TICKET_SUCCESS });
};

export const UNRESOLVE_TICKET_ERROR = 'UNRESOLVE_TICKET_ERROR';
export const unresolveTicketError = () => ({ type: UNRESOLVE_TICKET_ERROR });

export const SET_ACTIVE_AGENT = 'SET_ACTIVE_AGENT';
export const setActiveAgent = (agent) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_AGENT, agent });
};

export const changeTicketStatusApi = (ticket, oldStatus, newStatus) => (dispatch, getState) => {
  const newStatusId = statuses[newStatus];

  const { Name, Surname } = getState().auth.loggedUser;

  dispatch(changeTicketStatus());
  return fetch(`${defaultUrl}/Tickets/ChangeTicketStatus`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify({
      TicketID: ticket.TicketID,
      StatusID: newStatusId,
      UserFullName: `${Name} ${Surname}`,
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch(getTicketAndRefreshRelatedSummaries(ticket.TicketID));
    })
    .catch((error) => dispatch(changeTicketStatusError()));
};
