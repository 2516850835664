import connectReducers from 'store/connectReducers';

import {
  SET_CALL_LOG,
  SET_CALL_LOGS,
  SET_CALL_LOGS_FOR_FILTER,
  SET_CALL_LOGS_FOR_FILTER_COUNT,
} from 'store/actionTypes/calls';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  callLogs: {},
  callLogsByFilter: {},
  callLogsByFilterCount: {},
};

const reducers = {
  [SET_CALL_LOG]: (state, action) => ({
    ...state,
    callLogs: {
      ...state.callLogs,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_CALL_LOGS]: (state, action) => ({
    ...state,
    callLogs: {
      ...state.callLogs,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_CALL_LOGS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      callLogs,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(callLogs),
    ]);

    const updatedState = {
      ...state,
      callLogsByFilter: {
        ...state.callLogsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_CALL_LOGS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    callLogsByFilterCount: {
      ...state.callLogsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
