/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const List = (props) => {
  const {
    className,
    children,
  } = props;

  return <ul className={classNames('room-actions', className)}>{children}</ul>;
};

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default List;
