/**
 * @param {string} url
 */
const downloadFile = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = true;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadFile;
