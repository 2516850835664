import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px 10px 24px 10px; 
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export default {
  Root,
};
