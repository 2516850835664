import {
  SET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER,
} from 'store/actionTypes/realtorMatchingProperties';

const setCountByFilter = ({ filterId, count }) => ({
  type: SET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER,
  payload: {
    filterId,
    count,
  },
});

export default setCountByFilter;
