import { createSelector } from 'reselect';

import moment from 'moment';

import { getUserOptimized } from 'store/actions/users';
import { optimizedAddressesActions } from 'store/actions/addresses';
import { optimizedLineItemsActions } from 'store/actions/line-items';
import { optimizedCrmProductsActions } from 'store/actions/crm/crmProducts';
// import { optimizedSalesTeamsActions } from 'store/actions/salesTeams';
// import { optimizedMyCompanyInformationActions } from 'store/actions/MyCompany/information';

const dateFormat = 'MMMM, DD YYYY';
const timeFormat = 'hh:mm A';

const descriptions = {
  fulfillment: {
    created: () => 'created fulfillment',
    title: (newValue, oldValue) => `changed fulfillment title from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    eta: (newValue, oldValue) => `changed fulfillment ETA from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    note: (newValue, oldValue) => `changed fulfillment note from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    shipping_cost: (newValue, oldValue) => `changed shipping cost from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,

    permission_type: (newValue) => {
      if (newValue === '1') {
        return 'gave permission to everyone';
      }
      if (newValue === '2') {
        return 'restricted common permissions for this fulfillment';
      }

      return null;
    },

    // 'permission granted': (newValue) => {
    //   if (newValue) {
    //     if (newValue.type === 'employee') {
    //       return `granted permission to <b>${newValue.name}</b>`;
    //     }
    //     if (newValue.type === 'team') {
    //       return `granted permission to everyone on team <b>${newValue.name}</b>`;
    //     }
    //     if (newValue.type === 'office') {
    //       return `granted permission to everyone in <b>${newValue.name}</b> office`;
    //     }
    //   }

    //   return null;
    // },

    // 'permission revoked': (newValue) => {
    //   if (newValue) {
    //     if (newValue.type === 'employee') {
    //       return `revoked permission from <b>${newValue.name}</b>`;
    //     }
    //     if (newValue.type === 'team') {
    //       return `revoked permission from team <b>${newValue.name}</b>`;
    //     }
    //     if (newValue.type === 'office') {
    //       return `revoked permission from <b>${newValue.name}</b> office`;
    //     }
    //   }

    //   return null;
    // },

    // 'owner assigned': (newValue) => {
    //   if (newValue.isSameUser) return 'assigned <b>themself</b> as lead owner';

    //   return `assigned <b>${newValue.userName}</b> as lead owner`;
    // },

    // 'owner removed': (newValue) => {
    //   if (newValue.isSameUser) return 'gave up lead ownership';

    //   return `revoked lead ownership from <b>${newValue.userName}</b>`;
    // },
  },

  invoice: {
    id: () => 'created invoice',
    invoice_number: (newValue, oldValue) => `changed invoice number from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
  },

  payment: {
    id: () => 'created payment',
    transaction_id: (newValue, oldValue) => `changed transaction id from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    amount: (newValue, oldValue) => `changed confirmed payment amount from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    currency: (newValue, oldValue) => `changed confirmed payment currency from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
  },

  order: {
    id: () => 'created order',
    order_number: (newValue, oldValue) => `changed order number from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    delivery_date: (newValue, oldValue) => `changed delivery date from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
  },

  shippedItem: {
    id: () => 'created shipped item',
    tracking_number: (newValue, oldValue) => `changed tracking number from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    delivery_date: (newValue, oldValue) => `changed delivery date from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
    address_id: (newValue, oldValue) => `changed delivery address from <b>${oldValue || 'not set'}</b> to <b>${newValue}</b>`,
  },

  lineItem: {
    id: () => 'created line item',
    price_per_unit: (newValue, oldValue, { productName }) => `changed price per unit from <b>${oldValue}</b> to <b>${newValue}</b> for product <b>${productName}</b>`,
    discount_amount: (newValue, oldValue, { productName }) => `changed discount amount from <b>${oldValue}</b> to <b>${newValue}</b> for product <b>${productName}</b>`,
    discount_type: (newValue, oldValue, { productName }) => `changed discount type from <b>${oldValue}</b> to <b>${newValue}</b> for product <b>${productName}</b>`,
    tracking_number: (newValue, oldValue, { productName }) => `changed tracking number from <b>${oldValue}</b> to <b>${newValue}</b> for product <b>${productName}</b>`,
    serial_number: (newValue, oldValue, { productName }) => `changed serial number from <b>${oldValue}</b> to <b>${newValue}</b> for product <b>${productName}</b>`,
  },

  // 'product added': (newValue) => `added <b>${newValue.name}</b> as product with the value of <b>${newValue.value} ${newValue.currency}</b>`, // eslint-disable-line max-len

  // 'product value': (newValue, oldValue) => `changed value of assigned product <b>${newValue.name}</b> from <b>${oldValue.value}</b> to <b>${newValue.value}</b>`, // eslint-disable-line max-len

  // 'product currency': (newValue, oldValue) => `changed currency of assigned product <b>${newValue.name}</b> from <b>${oldValue.value}</b> to <b>${newValue.value}</b>`, // eslint-disable-line max-len

  // product: (newValue, oldValue) => `changed assigned product <b>${oldValue.name}</b> to <b>${newValue.name}</b>`, // eslint-disable-line max-len
};

const getUserName = (userId, users) => {
  const user = users[userId];

  let userName;

  if (!user) {
    getUserOptimized(1, userId);
  } else {
    userName = `${user.name} ${user.surname}`;
  }

  return userName;
};

const getAddress = (id, addresses) => {
  const address = addresses[id];

  let addressString = 'not set';

  if (!address) {
    optimizedAddressesActions.getAddressById(id);
  } else {
    addressString = address.address;
  }

  return addressString;
};

const getProductName = (productId, products = {}) => {
  const product = products[productId];

  let productName;

  if (!product) {
    optimizedCrmProductsActions.getProductById(productId);
  } else {
    productName = product.name;
  }

  return productName;
};

const getLineItemProductName = (itemId, { lineItems, products }) => {
  const item = lineItems[itemId];

  if (!item) {
    optimizedLineItemsActions.getLineItemById(itemId);
    return null;
  }

  const productName = getProductName(item.product_id, products);

  return productName;
};

// const getTeamName = (teamId, teams = []) => {
//   const team = teams.find((item) => item.SalesTeamId === teamId);

//   let teamName;

//   if (!team) {
//     optimizedSalesTeamsActions.getTeamList();
//   } else {
//     teamName = team.Title;
//   }

//   return teamName;
// };

// const getOfficeName = (officeId, offices = []) => {
//   const office = offices.find((item) => item.OfficeID === officeId);

//   let officeName;

//   if (!office) {
//     optimizedMyCompanyInformationActions.getOffices();
//   } else {
//     officeName = office.OfficeName;
//   }

//   return officeName;
// };

const valueByType = {
  shippedItem: {
    address_id: (value, extraData) => {
      const address = getAddress(value, extraData.addresses);
      return address;
    },
  },

  // 'permission granted': (value, extraData, event) => {
  //   if (value) {
  //     if (value.type === 1) {
  //       const isSameUser = value.id === event.done_by_id;

  //       return {
  //         name: isSameUser
  //           ? 'themself'
  //           : getUserName(value.id, extraData.users),

  //         type: 'employee',
  //       };
  //     }

  //     if (value.type === 2) {
  //       return {
  //         name: getTeamName(value.id, extraData.teams),
  //         type: 'team',
  //       };
  //     }

  //     if (value.type === 3) {
  //       return {
  //         name: getOfficeName(value.id, extraData.offices),
  //         type: 'office',
  //       };
  //     }
  //   }

  //   return null;
  // },

  // 'permission revoked': (value, extraData, event) => {
  //   if (value) {
  //     if (value.type === 1) {
  //       const isSameUser = value.id === event.done_by_id;

  //       return {
  //         name: isSameUser
  //           ? 'themself'
  //           : getUserName(value.id, extraData.users),
  //         type: 'employee',
  //       };
  //     }

  //     if (value.type === 2) {
  //       return {
  //         name: getTeamName(value.id, extraData.teams),
  //         type: 'team',
  //       };
  //     }

  //     if (value.type === 3) {
  //       return {
  //         name: getOfficeName(value.id, extraData.offices),
  //         type: 'office',
  //       };
  //     }
  //   }

  //   return null;
  // },

  // 'owner assigned': (value, extraData, event) => {
  //   const isSameUser = value?.id === event.done_by_id;
  //   const userName = value && !isSameUser && getUserName(value.id, extraData.users);

  //   return {
  //     isSameUser,
  //     userName,
  //   };
  // },

  // 'owner removed': (value, extraData, event) => {
  //   const isSameUser = value?.id === event.done_by_id;
  //   const userName = value && !isSameUser && getUserName(value.id, extraData.users);

  //   return {
  //     isSameUser,
  //     userName,
  //   };
  // },

  // 'product added': (value, extraData) => {
  //   if (value) {
  //     const productName = getProductName(value.product_id, extraData.products);

  //     return {
  //       name: productName,
  //       value: value.value,
  //       currency: value.currency,
  //     };
  //   }

  //   return null;
  // },

  // 'product value': (value, extraData) => {
  //   if (value) {
  //     const productName = getProductName(value.product_id, extraData.products);

  //     return {
  //       name: productName,
  //       value: value.value,
  //     };
  //   }

  //   return null;
  // },

  // 'product currency': (value, extraData) => {
  //   if (value) {
  //     const productName = getProductName(value.product_id, extraData.products);

  //     return {
  //       name: productName,
  //       value: value.value,
  //     };
  //   }

  //   return null;
  // },

  // product: (value, extraData) => {
  //   if (value) {
  //     const productName = getProductName(value.product_id, extraData.products);

  //     return {
  //       name: productName,
  //     };
  //   }

  //   return null;
  // },

  lineItem: {
    discount_type: (value) => {
      if (value === 1) return 'percentage';
      if (value === 2) return 'amount';
      return null;
    },
  },
};

const getItemData = {
  lineItem: (event, extraData) => {
    const productName = getLineItemProductName(event.item_id, extraData);

    return {
      productName,
    };
  },
};

const formatValueForEventType = (itemType) => (type) => (value, extraData, event) => {
  if (valueByType[itemType]?.[type]) return valueByType[itemType][type](value, extraData, event);

  return value;
};

const formatEvents = (events, extraData) => events.reduce((acc, event) => {
  const itemType = event.item_type || 'fulfillment';

  if (!descriptions[itemType]?.[event.event_type]) {
    return acc;
  }

  const itemData = getItemData[itemType]
    ? getItemData[itemType](event, extraData)
    : null;

  const description = descriptions[itemType]?.[event.event_type](
    formatValueForEventType(itemType)(event.event_type)(event.new_value, extraData, event),
    formatValueForEventType(itemType)(event.event_type)(event.old_value, extraData, event),
    itemData,
  );

  if (!description) return acc;

  const date = moment(event.created_at).format(dateFormat);
  const time = moment(event.created_at).format(timeFormat);
  const name = getUserName(event.done_by_id, extraData.users);

  acc.push({
    date,
    time,
    name,
    description,
  });

  return acc;
}, []);

const groupEventsByDate = (events) => {
  const dates = events.reduce((acc, item) => {
    acc.push(item.date);

    return [...new Set(acc)];
  }, []);

  const groupedEvents = dates.map((date) => ({
    date,
    events: events.filter((event) => event.date === date),
  }));

  return groupedEvents;
};

export default createSelector(

  (state, id) => id || state.fulfillments.selectedFulfillment,
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.changelog,
  // extra data
  (state) => state.users.employee,
  (state) => state.addresses.addresses,
  (state) => state.lineItems.lineItems,
  (state) => state.crmProducts.products,
  (
    fulfillmentId,
    fulfillments,
    changelogItems = {},
    users,
    addresses,
    lineItems,
    products,
  ) => {
    if (!fulfillmentId || !fulfillments || !fulfillments[fulfillmentId]) {
      return [];
    }

    const fulfillment = fulfillments[fulfillmentId];

    const created = {
      event_type: 'created',
      created_at: fulfillment.created_at,
      done_by_id: fulfillment.created_by_id,
    };

    const events = changelogItems[fulfillmentId]
      ? [
        ...changelogItems[fulfillmentId],
        created,
      ]
      : [created];

    const formattedEvents = formatEvents(
      events,
      {
        users,
        addresses,
        lineItems,
        products,
      },
    );

    const groupedEvents = groupEventsByDate(formattedEvents);

    return groupedEvents;
  },
);
