import apiConnector from 'api';

const changeTaskRank = (taskId) => async ({
  employeeId,
  rank,
  isTaskPlanner,
}) => {
  try {
    const result = await apiConnector.client.post(`/api/v2/changeTaskRank/${taskId}`, {
      employeeId,
      rank,
      isTaskPlanner,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default changeTaskRank;
