import requests from 'api/requests';

import { debounce } from 'shared/utility';

import { callAlert } from 'ui/Alert';

import getLeadById from 'store/actions/realtorLeads/getLeadById';
import getById from './getPropertyRequirementById';

const notify = debounce((action, text) => {
  callAlert[action](text);
}, 1000, {
  leading: true,
});

const updatePropertyRequirement = (requirementId, leadId = null) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.propertyRequirements.update(requirementId)(updateData);

    notify('success', 'Updated successfully');

    dispatch(getById(requirementId));

    if (leadId) {
      dispatch(getLeadById(leadId));
    }
  } catch (error) {
    console.error(error);
    notify('error', 'Could not update');
  }
};

export default updatePropertyRequirement;
