import {
  SET_SELECTED_PROPERTY,
} from 'store/actionTypes/properties';

const setSelectedProperty = (propertyId) => ({
  type: SET_SELECTED_PROPERTY,
  payload: propertyId,
});

export default setSelectedProperty;
