import {
  SET_TASKS_SUMMARY_FOR_TEAM,
} from 'store/actionTypes/tasks';

const setTasksSummaryForTeam = (teamId) => (tasksSummary) => ({
  type: SET_TASKS_SUMMARY_FOR_TEAM,
  payload: {
    teamId,
    tasksSummary,
  },
});

export default setTasksSummaryForTeam;
