import apiConnector, { endpoints } from 'api';

const getFileConflicts = (objectType) => (fieldMap) => async (fileId) => {
  const endpoint = `${endpoints.client.dataImport.conflicts}/${objectType}`;

  try {
    const response = await apiConnector.client.post(
      endpoint,
      {
        fileId,
        fieldMap,
      },
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getFileConflicts;
