import React, {
  useState,
  useEffect,
} from 'react';

import Menu from 'ui/Menu';

const useMenu = (menuAnchor = null, isOpen = false) => {
  const [anchorEl, setMenuAnchor] = useState(menuAnchor);
  const [isMenuOpen, setMenuVisible] = useState(isOpen);

  useEffect(() => {
    setMenuAnchor(menuAnchor?.current);
  }, [
    setMenuAnchor,
    menuAnchor?.current,
  ]);

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const MenuComponent = (props) => (
    <Menu
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: -8,
        vertical: 8,
      }}
      {...props}
    />
  );

  return {
    isMenuOpen,
    openMenu,
    closeMenu,
    Menu: MenuComponent,
    setMenuAnchor,
  };
};

export default useMenu;
