import { createSelector } from 'reselect';

import {
  optimizedCustomerPaymentsActions,
} from 'store/actions/customer-payments';

export default createSelector(
  (state, id) => ({
    payments: state.customerPayments.customerPayments,
    id,
  }),
  ({ payments, id }) => {
    if (!id) return null;

    const dataObject = payments[id];

    if (!dataObject) {
      optimizedCustomerPaymentsActions.getPaymentById(id);
    }

    return dataObject || {};
  },
);
