import React from 'react';

import { Divider } from 'antd';
import Typography from 'ui/Typography';

// eslint-disable-next-line import/no-cycle
import { getCommentDate } from './utils';

const addDateDividers = (comments) => {
  let day = 'Today';

  const commentsWithDividers = comments.reduce((commentsList, comment) => {
    const {
      CreatedAt,
    } = comment;

    const commentDate = getCommentDate(CreatedAt);

    if (commentDate !== day && commentDate !== 'Today') {
      const divider = {
        render: (
          <Divider>
            <Typography variant="label">
              {commentDate}
            </Typography>
          </Divider>
        ),
      };

      commentsList.push(divider);
      day = commentDate;
    }

    commentsList.push(comment);
    return commentsList;
  }, []);

  return commentsWithDividers;
};

export default addDateDividers;
