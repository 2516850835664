import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const JobRoleInput = (props) => {
  const {
    handleChange,
    value,
  } = props;

  return (
    <Textfield
      label="Job Role"
      onChange={handleChange}
      value={value}
    />
  );
};

JobRoleInput.defaultProps = {
  value: undefined,
};

const {
  func,
  string,
} = PropTypes;

JobRoleInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
};

export default JobRoleInput;
