import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { history } from 'store';

import {
  optimizedRealtorContactsActions,

  updateSelectedContact,
  deleteSelectedContact,
} from 'store/actions/realtorContacts';

import Options from './Options';

const mapStateToProps = (state) => {
  const contact = selectors.realtorContacts.selected(state);

  return {
    status: contact.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onStatusChange: (status) => {
    dispatch(updateSelectedContact({ status }));
  },
  onDelete: async () => {
    await dispatch(deleteSelectedContact());
    optimizedRealtorContactsActions.getContactsForStoreFilter();
    history.push('/contacts');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
