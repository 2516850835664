import requests from 'api/requests';

import getOverallLeadsSummary from './getOverallLeadsSummary';

import { callAlert } from 'ui/Alert';

const deleteLead = (leadId) => async (dispatch) => {
  try {
    await requests.realtor.leads.deleteLead(leadId);
    dispatch(getOverallLeadsSummary())
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteLead;
