import React from 'react';
import PropTypes from 'prop-types';

import Selection from 'ui/Selection';

const options = [
  {
    id: 3,
    iconName: 'hot',
    iconType: 'custom',
    iconColor: 'hot',
  },
  {
    id: 2,
    iconName: 'warm',
    iconType: 'custom',
    iconColor: 'warm',
  },
  {
    id: 1,
    iconName: 'cold',
    iconType: 'custom',
    iconColor: 'cold',
  },
];

const TempSelect = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <Selection
      radio
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

TempSelect.defaultProps = {
  value: null,
};

const {
  func,
  number,
} = PropTypes;

TempSelect.propTypes = {
  value: number,
  onChange: func.isRequired,
};

export default TempSelect;
