import {
  SET_NEW_REALTOR_CLIENT_MODAL_PROPERTY,
} from 'store/actionTypes/realtorClients';

const setNewClientModalProperty = (propertyId = null) => ({
  type: SET_NEW_REALTOR_CLIENT_MODAL_PROPERTY,
  payload: { propertyId },
});

export default setNewClientModalProperty;
