import { connect } from 'react-redux';
import { history } from 'store';

import {
  showTutorialLayer,
  setAvailableTutorials,
} from 'store/actions/tutorial';

import Tutorials from './Tutorials';

const mapStateToProps = (state) => {
  const {
    router: {
      location,
    },
    tutorial: {
      isVisible,
      appTour,
      appTourStep,
      availableTutorials,
    },
  } = state;

  return {
    history,
    location,
    isVisible,
    appTour,
    appTourStep,
    availableTutorials,
  };
};

const mapDispatchToProps = {
  showTutorialLayer,
  setAvailableTutorials,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tutorials);
