import apiConnector, { endpoints } from 'api';

const changeLogin = async (data) => {
  const endpoint = `${endpoints.global.login.change}`;

  try {
    await apiConnector.global.patch(endpoint, data);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default changeLogin;
