import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import Modal from 'ui/Modal';

import NewCounterForm from 'sections/Opportunities/forms/NewCounterForm';

const newCounterFormId = 'new-counter-form';

const NewProspectModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    offerId,
    onAfterSubmit,
  } = props;

  return (
    <Modal
      theme={theme}
      width={770}
      visible={isVisible}
      onCancel={closeModal}
      title="Submit Counter"
      footer={[
        <Button 
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="secondary"
        >
          Email Counter
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={newCounterFormId}
        >
          Counter
        </Button>,
      ]}
    >
      <NewCounterForm
        formId={newCounterFormId}
        offerId={offerId}
        onAfterSubmit={onAfterSubmit}
      />
    </Modal>
  );
};

NewProspectModal.defaultProps = {
  isVisible: false,
  theme: null,
  onAfterSubmit: null,
};

const {
  bool,
  func,
  shape,
  number,
} = PropTypes;

NewProspectModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
  offerId: number.isRequired,
  onAfterSubmit: func,
};

export default NewProspectModal;
