import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateSelectedContact,
  updateContact,
} from 'store/actions/realtorContacts';

import Birthday from './Birthday';

const mapStateToProps = (state, ownProps) => {
  const contact = ownProps.contactId
    ? selectors.realtorContacts.byId(state, ownProps.contactId)
    : selectors.realtorContacts.selected(state);

  return {
    birthday: contact?.date_of_birth,
    disableFuture: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateDateOfBirth: (date) => {
    if (ownProps.contactId) {
      dispatch(updateContact(ownProps.contactId)({ date_of_birth: date }));
    } else {
      dispatch(updateSelectedContact({ date_of_birth: date }));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Birthday);
