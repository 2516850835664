import connectReducers from 'store/connectReducers';
import { groupBy } from 'shared/utility';

import {
  SET_FULFILLMENT,
  SET_FULFILLMENTS,
  SET_FULFILLMENTS_FOR_FILTER,
  SET_FULFILLMENTS_FOR_FILTER_COUNT,
  SET_FULFILLMENTS_SUMMARY_FOR_FILTER,
  SET_OVERALL_FULFILLMENTS_SUMMARY,
  SET_SELECTED_FULFILLMENT,
  SET_FULFILLMENT_SELECTED_ROWS,
  // SET_FULFILLMENT_VIEWERS,
  // SET_FULFILLMENT_CHANGELOG,
  SET_FULFILLMENT_ATTACHMENTS,
  SET_FULFILLMENT_CHANGELOG,
  SET_FULFILLMENT_PRODUCT_LINE_ITEMS,
  SET_FULFILLMENT_DISPLAY_TYPE,
  CLEAR_FILTER_SUMMARIES,
} from 'store/actionTypes/fulfillments';

const initialState = {
  fulfillments: {},
  fulfillmentsByFilter: {},
  fulfillmentsByFilterCount: {},
  fulfillmentsSummaryByFilter: {},
  overallFulfillmentsSummary: null,
  selectedFulfillment: null,
  selectedFulfillmentRows: [],
  // fulfillmentViewers: {},
  changelog: {},
  lineItems: {},
  fulfillmentsDisplayType: 'table',
};

const fulfillmentsListToObject = (items = []) => items.reduce((fulfillmentObj, item) => {
  // param reassign is used in reduce by design
  fulfillmentObj[item.id] = item; // eslint-disable-line no-param-reassign
  return fulfillmentObj;
}, {});

const getFulfilmentIds = (items = []) => items.map((item) => item.id);

const reducers = {
  [SET_FULFILLMENT_DISPLAY_TYPE]: (state, action) => ({
    ...state,
    fulfillmentsDisplayType: action.displayType,
  }),

  [SET_SELECTED_FULFILLMENT]: (state, action) => ({
    ...state,
    selectedFulfillment: action.payload,
  }),

  [SET_FULFILLMENT_SELECTED_ROWS]: (state, action) => ({
    ...state,
    selectedFulfillmentRows: action.payload,
  }),

  [SET_FULFILLMENTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    fulfillmentsByFilterCount: {
      ...state.fulfillmentsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_FULFILLMENT]: (state, action) => ({
    ...state,
    fulfillments: {
      ...state.fulfillments,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_FULFILLMENTS]: (state, action) => ({
    ...state,
    fulfillments: {
      ...state.fulfillments,
      ...fulfillmentsListToObject(action.payload),
    },
  }),

  [SET_FULFILLMENTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      fulfillments,
    } = action.payload;

    const filterFulfillmentsIds = state.fulfillmentsByFilter[filterId] || [];

    const updatedFilterFulfillemntIds = new Set([
      ...filterFulfillmentsIds,
      ...getFulfilmentIds(fulfillments), // this order is important for currect updates
    ]);

    const updatedState = {
      ...state,
      fulfillmentsByFilter: {
        ...state.fulfillmentsByFilter,
        [filterId]: [...updatedFilterFulfillemntIds].sort((a, b) => b - a),
      },
    };

    return updatedState;
  },

  [SET_OVERALL_FULFILLMENTS_SUMMARY]: (state, action) => ({
    ...state,
    overallFulfillmentsSummary: action.payload,
  }),

  [SET_FULFILLMENTS_SUMMARY_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      summary,
    } = action.payload;

    return {
      ...state,
      fulfillmentsSummaryByFilter: {
        ...state.fulfillmentsSummaryByFilter,
        [filterId]: summary,
      },
    };
  },

  // [SET_FULFILLMENT_VIEWERS]: (state, action) => ({
  //   ...state,
  //   fulfillmentViewers: {
  //     [action.payload.leadId]: action.payload.viewers,
  //   },
  // }),

  [SET_FULFILLMENT_CHANGELOG]: (state, action) => ({
    ...state,
    changelog: {
      ...state.changelog,
      [action.payload.fulfillmentId]: action.payload.changelog,
    },
  }),

  [SET_FULFILLMENT_ATTACHMENTS]: (state, action) => ({
    ...state,
    fulfillments: {
      ...state.fulfillments,
      [action.payload.id]: {
        ...state.fulfillments[action.payload.id],
        attachments: groupBy(action.payload.attachments, 'attachmentType'),
      },
    },
  }),

  [SET_FULFILLMENT_PRODUCT_LINE_ITEMS]: (state, action) => {
    const {
      fulfillmentId,
      productId,
      items,
      status,
    } = action.payload;

    const updatedState = {
      ...state,
      lineItems: {
        ...state.lineItems,
        [fulfillmentId]: {
          ...state.lineItems[fulfillmentId],
          [status || 'main']: {
            ...state.lineItems[fulfillmentId]?.[status],
            [productId]: items,
          },
        },
      },
    };

    return updatedState;
  },

  [CLEAR_FILTER_SUMMARIES]: (state) => ({
    ...state,
    fulfillmentsSummaryByFilter: {},
  }),
};

function fulfillmentsReducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default fulfillmentsReducer;
