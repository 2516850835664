import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} paymentData
 */
const create = async (paymentData) => {
  const endpoint = endpoints.client.customerPayments;
  try {
    const result = await apiConnector.client.post(endpoint, paymentData);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
