import { connect } from 'react-redux';
import { getPlannedSystemMaintenance } from 'store/actions/systemCheck';

import Logo from './Logo';

const mapStateToProps = (state) => {
  const {
    auth: {
      activeTab,
    },
    systemCheck: {
      plannedSystemMaintenance,
    },
  } = state;

  return {
    activeTab,
    plannedSystemMaintenance,
  };
};

const mapDispatchToProps = {
  getPlannedSystemMaintenance,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logo);
