import { createSelector } from 'reselect';

const companies = (state) => state.crmCompanies.companies;
const selectedCompany = (state) => state.crmCompanies.selectedCompany;

export default createSelector(
  companies,
  selectedCompany,
  (items, itemId) => {
    if (!items[itemId]) return null;

    const owner = items[itemId].owners?.employee?.[0];

    return owner;
  },
);
