/* eslint-disable */
//eslint fix
import { callAlert } from 'ui/Alert';
import { url } from 'paths';
import {
  apiConnector,
  getConnector,
} from 'shared/API';

import requests from 'api/requests';

import newApiConnector from 'api';

export const EMPLOYEE_GENERAL_MODAL_UPDATE_DATA = 'EMPLOYEE_GENERAL_MODAL_UPDATE_DATA';
export const updateEmployeeGeneralData = (data) => (
  {
    type: EMPLOYEE_GENERAL_MODAL_UPDATE_DATA,
    data,
  }
);

export const SHOW_CREATE_NEW_USER_MODAL = 'SHOW_CREATE_NEW_USER_MODAL';
export const showCreateNewUserModal = (createNewUserModal = false) => (
  {
    type: SHOW_CREATE_NEW_USER_MODAL,
    createNewUserModal,
  }
);

export const SHOW_TICKET_CREATE_MODAL = 'SHOW_TICKET_CREATE_MODAL';
export const showTicketCreateModal = () => ({ type: SHOW_TICKET_CREATE_MODAL });

export const HIDE_TICKET_CREATE_MODAL = 'HIDE_TICKET_CREATE_MODAL';
export const hideTicketCreateModal = () => ({ type: HIDE_TICKET_CREATE_MODAL });

export const SHOW_TASK_CREATE_MODAL = 'SHOW_TASK_CREATE_MODAL';
export const showTaskCreateModal = () => ({ type: SHOW_TASK_CREATE_MODAL });

export const SHOW_CSV_IMPORT_MODAL = 'SHOW_CSV_IMPORT_MODAL';
export const showCsvImportModal = () => ({ type: SHOW_CSV_IMPORT_MODAL });

export const SHOW_EMPLOYEE_GENERAL_MODAL = 'SHOW_EMPLOYEE_GENERAL_MODAL';

export const SHOW_ADD_EMPLOYEE_MODAL = 'SHOW_ADD_EMPLOYEE_MODAL';
export const HIDE_ADD_EMPLOYEE_MODAL = 'HIDE_ADD_EMPLOYEE_MODAL';

/**
 *
 * @param {Boolean} isEmployeeDraft
 * @param {Object} data
 */
export const showEmployeeGeneralModal = (
  isEmployeeDraft = false,
  data = false,
) => ({ type: SHOW_EMPLOYEE_GENERAL_MODAL, isEmployeeDraft, data });

export const showAddEmployeeModal = () => ({ type: SHOW_ADD_EMPLOYEE_MODAL });
export const hideAddEmployeeModal = () => ({ type: HIDE_ADD_EMPLOYEE_MODAL });

export const CHANGE_TICKET_VIEW = 'CHANGE_TICKET_VIEW';
export const changeTicketsView = (view) => ({
  type: CHANGE_TICKET_VIEW,
  view,
});

export const SHOW_QUEUE_SETUP = 'SHOW_QUEUE_SETUP';
export const showQueueSetup = () => ({ type: SHOW_QUEUE_SETUP });

export const CHANGE_DASHBOARD_VIEW = 'CHANGE_DASHBOARD_VIEW';
export const changeDashboardQueuesView = (view) => ({
  type: CHANGE_DASHBOARD_VIEW,
  view,
});

export const CHANGE_PERSONAL_VIEW = 'CHANGE_PERSONAL_VIEW';
export const changeDashboardPersonalView = () => ({
  type: CHANGE_PERSONAL_VIEW,
});

export const SHOW_VALIDATION_MODAL = 'VALIDATION_MODAL';
export const showValidationModal = (value = true) => ({
  type: SHOW_VALIDATION_MODAL,
  value,
});

export const SHOW_CUSTOM_MODAL = 'SHOW_CUSTOM_MODAL';
export const showCustomModal = (
  message = null,
  onOk = null,
  onCancel = null,
) => ({
  type: SHOW_CUSTOM_MODAL, onOk, onCancel, message,
});

export const SHOW_LUNCH_IN_MODAL = 'SHOW_LUNCH_IN_MODAL';
export const showLunchInModal = () => ({ type: SHOW_LUNCH_IN_MODAL });

export const SHOW_CLOCK_OUT_MODAL = 'SHOW_CLOCK_OUT_MODAL';
export const showClockOutModal = () => ({ type: SHOW_CLOCK_OUT_MODAL });

export const SHOW_CLOCK_IN_MODAL = 'SHOW_CLOCK_IN_MODAL';
export const showClockInModal = () => ({ type: SHOW_CLOCK_IN_MODAL });

export const SHOW_LUNCH_OUT_MODAL = 'SHOW_LUNCH_OUT_MODAL';
export const showLunchOutModal = () => ({ type: SHOW_LUNCH_OUT_MODAL });

export const CHANGE_LUNCH_STATUS = 'CHANGE_LUNCH_STATUS';
export const changeLunchStatus = (value) => ({ type: CHANGE_LUNCH_STATUS, value });

export const SEND_FILES = 'SEND_FILES';
export const sendFiles = async (files, specifyUrl, id, DatabaseName) => {
  try {
    const newUrl = `${url}/api/attachments/workspace/${specifyUrl}/${id}`;
    for (const file of files) {
      const formData = new FormData();

      formData.append('attachment', file.originFileObj);

      const response = await newApiConnector.clientAttachmentsUpload.post(
        newUrl,
        formData
      )

      const {
        data: {
          attachment
        }
      } = response

      requests.attachments.createClientExperienceAttachmentsInGlobal(
        attachment,
        specifyUrl,
        DatabaseName
      )
    }
  } catch (error) {
    console.log(error);

    callAlert.error('An error occurred when sending files!')
  }
};

export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const sendFeedbackSuccess = () => {
  callAlert.success('Thanks for helping us make Symplete better.');
  return { type: SEND_FEEDBACK_ERROR };
};

export const SEND_FEEDBACK_ERROR = 'SEND_FEEDBACK_ERROR';
export const sendFeedbackError = () => {
  callAlert.error('Ups something went wrong');
  return { type: SEND_FEEDBACK_ERROR };
};

export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const sendFeedback = (data) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        loggedUser: {
          Email,
          Name,
          CompanyID
        }
      },
      myCompany: {
        general: {
          DatabaseName,
        },
      },
    } = getState();

    const { terms } = data;
    let user = { companyId: CompanyID }; // TODO: find out how the session works on the backend
    if (terms) user = { ...user, email: Email, name: Name };
    data.attachment = null; // TODO: implement screenshot mechanism
    data.pageUrl = null; // TODO: implement router mechanism

    const url = '/api/feedback-bug/feedback';
    const { upload } = data;

    dispatch({ type: SEND_FEEDBACK });

    const res = await apiConnector(url, 'POST', { user, data }, DatabaseName)

    if (upload) {
      sendFiles(upload, 'feedback', res.feedbackId, DatabaseName);
    }

    await newApiConnector.global.post('/feedback/send-feedback', { data: res });

    await dispatch(sendFeedbackSuccess());
  } catch (error) {
    dispatch(sendFeedbackError());
  }
};

export const SEND_BUG = 'SEND_BUG';
export const sendBug = (data) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        loggedUser: {
          Email,
          Name,
          CompanyID
        }
      },
      myCompany: {
        general: {
          DatabaseName,
        },
      },
    } = getState();

    const { terms } = data;
    let user = { companyId: CompanyID }; // TODO find out how the session works on the backend
    if (terms) user = { ...user, email: Email, name: Name };
    data.screenshot = null;
    data.pageUrl = null; // TODO implement router mechanism

    const url = '/api/feedback-bug/bug';
    const { upload } = data;

    dispatch({ type: SEND_BUG });

    const res = await apiConnector(url, 'POST', { user, data });

    if (upload) {
      sendFiles(upload, 'bug', res.bugId, DatabaseName);
    }


    await newApiConnector.global.post('/bugs/send-bug', { data: res });

    await dispatch(sendBugSuccess());
  } catch (error) {
    dispatch(sendBugError());
  }
};

export const SEND_BUG_SUCCESS = 'SEND_BUG_SUCCESS';
export const sendBugSuccess = () => {
  callAlert.success('Thanks for helping us make Symplete better.');

  return { type: SEND_BUG_SUCCESS };
};

export const SEND_BUG_ERROR = 'SEND_BUG_ERROR';
export const sendBugError = () => {
  callAlert.error('Ups something went wrong');

  return { type: SEND_BUG_ERROR };
};

export const GET_ALL_NOTICES = 'GET_ALL_NOTICES';
export const getNotices = () => (dispatch) => {
  const url = '/api/notices';
  return getConnector(url)
    .then((res) => dispatch(getAllTicketsSuccess(res)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const GET_ALL_NOTICES_SUCCESS = 'GET_ALL_NOTICES_SUCCESS';
export function getAllTicketsSuccess(list) {
  return {
    type: GET_ALL_NOTICES_SUCCESS,
    notices: list,
  };
}

export const ADD_NOTICE = 'ADD_NOTICE';
export const addNotice = (NoticeDescription) => (dispatch) => {
  const url = '/api/notices';
  const body = {
    Description: NoticeDescription,
  };
  return apiConnector(url, 'POST', body)
    .then((res) => dispatch(addNoticeSuccess(res)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const ADD_NOTICE_SUCCESS = 'ADD_NOTICE_SUCCESS';
export function addNoticeSuccess(list) {
  return (dispatch) => {
    dispatch(getNotices());
  };
}

export const DELETE_NOTICE = 'DELETE_NOTICE';
export const deleteNotice = (noticeID) => (dispatch) => {
  const url = `/api/notices/${noticeID}`;
  return apiConnector(url, 'DELETE')
    .then((res) => dispatch(deleteNoticeSuccess(res)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const DELETE_NOTICE_SUCCESS = 'DELETE_NOTICE_SUCCESS';
export function deleteNoticeSuccess(list) {
  return (dispatch) => {
    dispatch(getNotices());
  };
}

export const ACKNOWLEDGE_NOTICE = 'ACKNOWLEDGE_NOTICE';
export const acknowledgeNotice = (noticeID) => (dispatch) => {
  const url = `/api/notices/${noticeID}/acknowledge`;
  return apiConnector(url, 'PUT')
    .then((res) => dispatch(deleteNoticeSuccess(res)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const ACKNOWLEDGE_NOTICE_SUCCESS = 'ACKNOWLEDGE_NOTICE_SUCCESS';
export function acknowledgeNoticeSuccess(list) {
  return (dispatch) => {
    dispatch(getNotices());
  };
}

export const SEEN_NOTICE = 'SEEN_NOTICE';
export const seenNotice = (noticeID) => (dispatch) => {
  const url = `/api/notices/${noticeID}/seen`;
  return apiConnector(url, 'PUT')
    .then((res) => dispatch(deleteNoticeSuccess(res)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const SEEN_NOTICE_SUCCESS = 'SEEN_NOTICE_SUCCESS';
export function seenNoticeSuccess(list) {
  return (dispatch) => {
    dispatch(getNotices());
  };
}

export const GET_EMPLOYEE_MENTIONS_SUMMARY = 'GET_EMPLOYEE_MENTIONS_SUMMARY';
export const getEmployeeMentionsSummary = (employeeId) =>  async (dispatch) => {
  try {
    const summaryResponse = await requests.employees.getEmployeeMentionsSummary(employeeId);

    dispatch(setEmployeeMentionsSummary(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export const SET_EMPLOYEE_MENTIONS_SUMMARY = 'SET_EMPLOYEE_MENTIONS_SUMMARY';
export const setEmployeeMentionsSummary = (mentionsSummary) => ({
  type: SET_EMPLOYEE_MENTIONS_SUMMARY,
  payload: {
    mentionsSummary,
  },
});
