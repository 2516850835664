import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

/**
 * @typedef {'Company Settings' | 'Symplete' | 'Tasks' | 'HR' | 'Customer Support' | 'Sales' | 'Cashflow' | 'Surveys' | 'CustomerDB' | 'Contact Database' | 'Service'} LicenceName
 */
/**
 *
 * @param {LicenceName} licenceName
 * @returns {Boolean}
 */
const checkLoggedEmployeeLicenseAccess = (licenceName) => {
  const hasUserAccess = checkLoggedEmployeePermissions.isUser(licenceName);
  const hasManagerAccess = checkLoggedEmployeePermissions.isManager(licenceName);
  const isSiteOwner = checkLoggedEmployeePermissions.isOwner();

  return hasUserAccess || hasManagerAccess || isSiteOwner;
};

export default checkLoggedEmployeeLicenseAccess;
