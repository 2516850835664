import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_CLIENT_MODAL_OPEN,
  SET_NEW_REALTOR_CLIENT_MODAL_CLOSED,
  SET_NEW_REALTOR_CLIENT_MODAL_PROPERTY,
  SET_NEW_REALTOR_CLIENT_MODAL_ATTACHMENTS,
} from 'store/actionTypes/realtorClients';

const initialState = {
  isVisible: false,
  propertyId: null,
  leadId: null,
  attachments: [],
};

const reducers = {
  [SET_NEW_REALTOR_CLIENT_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    propertyId: action.payload.propertyId,
    leadId: action.payload.leadId,
  }),

  [SET_NEW_REALTOR_CLIENT_MODAL_CLOSED]: (state) => ({
    ...initialState,
    attachments: state.attachments,
  }),

  [SET_NEW_REALTOR_CLIENT_MODAL_PROPERTY]: (state, action) => ({
    ...state,
    propertyId: action.payload.propertyId,
  }),

  [SET_NEW_REALTOR_CLIENT_MODAL_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.payload.filesList,
  }),
};

function newClientModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newClientModal;
