import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import validate from 'shared/utils/validators';

import { Controller } from 'react-hook-form';

import Flex from 'ui/Flex';
import Textfield from 'ui/Textfield';

import AvatarUpload from './AvatarUpload';

const errorsMessage = {
  isEmailValid: 'Email not valid!',
  isEmailExist: 'Email exist!',
  isPhoneValid: 'Phone not valid!',
};

const ContactInformationForm = (props) => {
  const {
    control,
    errors,
    formsData,
    defaultEmail,
    checkIfAccountExists,
  } = props;

  return (
    <>
      <Controller
        name="avatar"
        control={control}
        defaultValue={formsData?.avatar}
        render={({ field }) => (
          <AvatarUpload
            onChange={field.onChange}
            value={field.value}
          />
        )}
        rules={{
          required: false,
        }}
      />
      <Flex
        spacing={2}
      >
        <Flex
          flexDirection="column"
          alignItems="stretch"
          justifyContent="start"
          style={{
            width: '283px',
          }}
          spacing={3}
        >
          <Controller
            name="firstName"
            control={control}
            defaultValue={formsData?.firstName}
            render={({ field }) => (
              <Textfield
                label="First Name"
                {...field}
              />
            )}
            rules={{
              required: false,
            }}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={formsData?.email}
            render={({ field }) => (
              <Textfield
                label="Email"
                error={errors?.email ? errorsMessage[errors?.email?.type] : false}
                icon="mail"
                disabled
                {...field}
              />
            )}
            rules={{
              required: false,
              validate: {
                isEmailValid: (value) => validate.email(value),
                isEmailExist: async (value) => !await checkIfAccountExists(value) || defaultEmail === value,
              },
            }}
          />
          <Controller
            name="companyName"
            control={control}
            defaultValue={formsData?.companyName}
            render={({ field }) => (
              <Textfield
                label="Company Name"
                {...field}
              />
            )}
            rules={{
              required: false,
            }}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="stretch"
          justifyContent="start"
          style={{
            width: '283px',
          }}
          spacing={3}
        >
          <Controller
            name="lastName"
            control={control}
            defaultValue={formsData?.lastName}
            render={({ field }) => (
              <Textfield
                label="Last Name"
                {...field}
              />
            )}
            rules={{
              required: false,
            }}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue={formsData?.phone}
            render={({ field }) => (
              <Textfield
                icon="smartphone"
                mask="phone"
                error={errors?.phone ? errorsMessage[errors?.phone?.type] : false}
                {...field}
              />
            )}
            rules={{
              required: false,
              validate: {
                isPhoneValid: (value) => validate.phone(value),
              },
            }}

          />
          <Controller
            name="realtorId"
            control={control}
            defaultValue={formsData?.realtorId}
            render={({ field }) => (
              <Textfield
                label="Realtor ID"
                {...field}
              />
            )}
            rules={{
              required: false,
            }}
          />
        </Flex>
      </Flex>
    </>
  );
};

const {
  shape,
  string,
  func,
} = PropTypes;

ContactInformationForm.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  formsData: shape({
    password: string,
    confirmPassword: string,
  }).isRequired,
  checkIfAccountExists: func.isRequired,
  defaultEmail: string.isRequired,
};

export default ContactInformationForm;
