import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import ScrollContainer from 'react-indiana-drag-scroll';

import { isSafariBrowser } from 'shared/utils/handleBrowsers';

import TimelineItem from 'shared/components/UI/TimelineItem';
import TimelineEdge from 'shared/components/UI/TimelineEdge';
import TimelineMark from 'shared/components/UI/TimelineMark';

import {
  Root,
  ItemWrapper,
} from './styledItems';

import './styles.scss';

const eventIcons = {
  addPerson: 'user-plus',
  removePerson: 'user-minus',
  check: 'check',
  success: 'thumbs-up',
  fail: 'thumbs-down',
  schedule: 'calendar-day',
  emotionHappy: 'smile',
  emotionMeh: 'meh',
  emotionSad: 'frown',
  created: 'plus',
  archived: 'archive',
  contactData: 'id-badge',
  deal: 'dollar-sign',
  lead: 'bullhorn',
  task: 'file-alt',
  ticket: 'headphones-alt',
};

const colors = {
  black: 'rgba(35, 48, 63, 0.87)',
  red: '#ff6446',
  green: '#2ecc71',
};

const eventColors = {
  addPerson: colors.black,
  removePerson: colors.black,
  check: colors.green,
  success: colors.green,
  fail: colors.red,
  schedule: colors.black,
  emotionHappy: colors.black,
  emotionMeh: colors.black,
  emotionSad: colors.black,
  created: colors.black,
  archived: colors.black,
  contactData: colors.black,
  deal: colors.black,
  lead: colors.black,
  task: colors.black,
  ticket: colors.black,
};

const getEdgeLabel = (event1, event2) => {
  if (!event1 || !event2) {
    return null;
  }

  const diff = moment(event2.createdAt).diff(moment(event1.createdAt));

  return moment.duration(diff).humanize();
};

const Timeline = (props) => {
  const container = useRef(null);

  const {
    events: propsEvents,
    timelineName,
    showEdgeLabel,
    showToday,
    showLineBeforeFirst,
    showLineAfterLast,
    scrollToNewer,

    customTimelineItem,
    selectedElement,
    onEventClick,
    selectedItemColor,

    todayMarkLineHeight,
  } = props;

  const TimelineItemComponent = customTimelineItem || TimelineItem;

  useEffect(() => {
    if (container.current) {
      if (scrollToNewer) {
        setTimeout(() => {
          if (isSafariBrowser()) {
            container.current.scrollTo(1000000, 0);
          } else {
            container.current.scrollTo({
              top: 0,
              left: 1000000,
              behavior: 'smooth',
            });
          }
        }, 500);
      }
    }
  }, []);

  const sortedEvents = propsEvents
    .sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
    .map((item, index) => ({
      ...item,
      id: item.id || index,
    }));

  const first = sortedEvents[0];
  const last = sortedEvents[sortedEvents.length - 1];

  const nextEventIds = sortedEvents.reduce((acc, event, index) => {
    acc[event.id] = sortedEvents[index + 1]?.id;
    return acc;
  }, {});

  const extraMarks = [];

  if (showToday) {
    extraMarks.push({
      extraType: 'today',
      createdAt: moment().startOf('day'),
    });
  }

  const events = [
    ...sortedEvents,
    ...extraMarks,
  ].sort((a, b) => moment(a.createdAt) - moment(b.createdAt));

  return (
    <ScrollContainer
      horizontal
      vertical={false}
      innerRef={container}
      ignoreElements=".prevent-drag-scroll"
    >
      <Root>
        {
          events.map((event) => {
            if (event.extraType === 'today') {
              return (
                <ItemWrapper>
                  <TimelineMark
                    label="TODAY"
                    height={todayMarkLineHeight}
                  />
                </ItemWrapper>
              );
            }

            const nextEventId = nextEventIds[event.id];

            const dotId = `timeline-${timelineName.replace(/\s/g, '')}-dot-key-${event.id}`;
            const nextDotId = `timeline-${timelineName.replace(/\s/g, '')}-dot-key-${nextEventId}`;

            const isFirst = first?.id === event.id;
            const isLast = last?.id === event.id;

            const label = event.customLabel || moment(event.createdAt).format('DD MMM YYYY');

            return (
              <>
                <ItemWrapper>
                  <TimelineItemComponent
                    key={event.id}
                    label={label}
                    signIconName={event.customIconName || eventIcons[event.type]}
                    signIconColor={event.customIconColor || eventColors[event.type] || colors.black}
                    customSign={event.customSign}
                    dotId={dotId}
                    isFirst={showLineBeforeFirst && isFirst}
                    isLast={showLineAfterLast && isLast}
                    itemDetails={event.details}
                    type={event.type}
                    price={event.price}
                    isSelected={selectedElement === event.id}
                    anchorSelector={event.anchorSelector}
                    userRole={event.role}
                    counterNumber={event.counterNumber}
                    onClick={onEventClick(event.id)}
                    selectedItemColor={selectedItemColor}
                  />
                </ItemWrapper>
                {
                  !isLast && (
                    <TimelineEdge
                      fromId={dotId}
                      toId={nextDotId}
                      label={showEdgeLabel && getEdgeLabel(event, events.find((item) => item.id === nextEventId))}
                    />
                  )
                }
              </>
            );
          })
        }
      </Root>
    </ScrollContainer>
  );
};

Timeline.defaultProps = {
  timelineName: 'timeline',
  showEdgeLabel: true,
  showToday: true,
  showLineBeforeFirst: true,
  showLineAfterLast: true,
  scrollToNewer: true,
  events: [],
  customTimelineItem: null,
  selectedElement: '',
  onEventClick: () => () => {},
  selectedItemColor: null,
};

const {
  arrayOf,
  bool,
  element,
  number,
  oneOf,
  shape,
  string,
  func,
} = PropTypes;

Timeline.propTypes = {
  /**
   * required if you have more than one timeline in your screen
   */
  timelineName: string,
  /**
   * timeline events
   */
  events: arrayOf(shape({
    id: oneOf([string, number]),
    type: string,
    customIconName: string,
    customIconColor: string,
    customSign: element,
    createdAt: string,
    customLabel: string,
    details: shape({
      time: string,
      title: string,
      description: string,
    }),
  })),
  /**
   * whether to show the duration between event above the line
   */
  showEdgeLabel: bool,
  /**
   * whether to show the today mark
   */
  showToday: bool,
  /**
   * whether to show the line before the first event
   */
  showLineBeforeFirst: bool,
  /**
   * whether to show the line after the last event
   */
  showLineAfterLast: bool,
  /**
   * whether to scroll to the end of the line when first rendered
   */
  scrollToNewer: bool,
  customTimelineItem: element,
  selectedElement: string,
  onEventClick: func,
  selectedItemColor: string,
};

export default Timeline;
