import { createSelector } from 'reselect';

import { forOwn } from 'shared/utility';

import {
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.fulfillments.fulfillments,
  (state, id) => id,
  (products, fulfillments, selectedFulfillmentId) => {
    const selectedFulfillment = fulfillments[selectedFulfillmentId];

    if (!fulfillments[selectedFulfillmentId]) {
      if (selectedFulfillmentId) {
        optimizedFulfillmentsActions.getFulfillmentById(selectedFulfillmentId);
      }

      return [];
    }

    const selectedProducts = selectedFulfillment?.outOfStockProducts;

    if (!selectedProducts) {
      return [];
    }

    const items = [];

    forOwn(selectedProducts, (item, key) => {
      const productId = item.product_id;

      const productObject = products[productId];

      if (!productObject && productId) {
        optimizedCrmProductsActions.getProductById(productId);
      }

      items.push({
        ...item,
        rowId: +key + 1,
        title: productObject?.name,
        quantity: item.sub_items_count,
        isExpandable: item.sub_items_count > 1,
      });
    });

    return items;
  },
);
