/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'oa/components/Modal';
import Button from 'oa/components/Button';
import CopyText from 'oa/components/CopyText';
import { meetingType } from 'oa/types/index';
import './style.css';

const InvitedUsers = (props) => {
  const {
    meeting,
    emails,
    onCancel,
    onOk,
  } = props;

  const title = `You are inviting ${emails.length} people outside of your Symplete account.`;
  const isOpen = emails.length > 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      overlayClassName="invited-users-modal"
      className="invited-users-modal__inner"
    >
      <Modal.Content
        className="invited-users-modal__content"
      >
        <Modal.Title className="invited-users-modal__title">
          {title}
        </Modal.Title>

        <p className="invited-users-modal__info">
          They will receive a email with meeting link and password.
          Or you can share the below.
        </p>

        {
          meeting && (
            <ul className="invited-users-modal__list">
              <li className="invited-users-modal__copy-info">
                <CopyText
                  text={`${window.location.hostname}/rooms/${meeting.code}`}
                />
              </li>
            </ul>
          )
        }

        <p className="invited-users-modal__footer">
          <Button
            type="tertiary_default"
            onClick={onCancel}
            className="invited-users-modal__cancel"
          >
            Cancel
          </Button>

          <Button
            onClick={onOk}
            className="invited-users-modal__ok"
          >
            I’m Sure
          </Button>
        </p>
      </Modal.Content>
    </Modal>
  );
};

InvitedUsers.propTypes = {
  meeting: meetingType,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default InvitedUsers;
