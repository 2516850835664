import { createSelector } from 'reselect';

const servicePeople = (state) => state.servicePeople.servicePeople;

export default createSelector(
  servicePeople,
  (records) => {
    const formattedRecords = Object.values(records);

    let collection = [];

    if (formattedRecords) {
      collection = formattedRecords.map((item) => {
        const title = [
          item.first_name,
          item.last_name,
        ].filter((name) => !!name).join(' ');

        const option = {
          id: item.id,
          title,
        };

        return option;
      });
    }

    return collection;
  },
);
