import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createPropertyRequirements = (propertyRequirementsData) => async (dispatch) => {
  try {
    const response = await requests.realtor.propertyRequirements.create(propertyRequirementsData);

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default createPropertyRequirements;
