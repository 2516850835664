import {
  SET_PROPERTY_VIEWING,
} from 'store/actionTypes/propertyViewings';

const setPropertyViewing = (viewing) => ({
  type: SET_PROPERTY_VIEWING,
  payload: viewing,
});

export default setPropertyViewing;
