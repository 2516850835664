import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';
import Modal from 'ui/Modal';
import Button from 'ui/Button';

import RealtorLeadItemDetails from 'sections/Leads/modules/RealtorLeadItemDetails';
import ActionSelector from './ActionSelector';

const ArchiveDeleteLeadModal = (props) => {
  const {
    leadId,
    connectedItemType,
    connectedItemId,
    isVisible,
    onSave,
    onCancel,
  } = props;

  const isBulk = Array.isArray(leadId);

  return (
    <Modal
      width={850}
      visible={isVisible}
      onCancel={onCancel}
      title="Please select an option"
      footer={[
        <Button
          variant="tertiary"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={onSave}
        >
          Confirm
        </Button>,
      ]}
    >
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          {
            isBulk ? (
              <Typography>
                This will affect all selected leads
              </Typography>
            ) : (
              <RealtorLeadItemDetails
                leadId={leadId}
                connectedItemType={connectedItemType}
                connectedItemId={connectedItemId}
              />
            )
          }
        </Grid>
        <Grid item xs={12}>
          <ActionSelector />
        </Grid>
      </Grid>
    </Modal>
  );
};

ArchiveDeleteLeadModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  number,
  string,
  arrayOf,
  oneOfType,
} = PropTypes;

ArchiveDeleteLeadModal.propTypes = {
  isVisible: bool,
  onSave: func.isRequired,
  onCancel: func.isRequired,
  leadId: oneOfType([number, arrayOf(number)]).isRequired,
  connectedItemType: string.isRequired,
  connectedItemId: number.isRequired,
};

export default ArchiveDeleteLeadModal;
