import { connect } from 'react-redux';

import {
  addContactRelations,
  removeContactRelations,
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import { callAlert } from 'ui/Alert';

import AssociatedContacts from './AssociatedContacts';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: async (relatedContactId, relationStatusId) => {
    try {
      await dispatch(addContactRelations('relatedContacts')(ownProps.contactId)({
        relatedContactId,
        relationStatusId,
      }));

      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }
  },
  onRemove: (relationId) => async () => {
    try {
      await dispatch(removeContactRelations('relatedContacts')(ownProps.contactId)({
        relationId,
      }));

      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }
  },
  getContact: () => {
    optimizedRealtorContactsActions.getContactById(ownProps.contactId);
  },
});

export default connect(null, mapDispatchToProps)(AssociatedContacts);
