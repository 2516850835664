import React from 'react';
import PropTypes from 'prop-types';

import InlineStyleControls from './InlineStyleControls';
import BlockStyleControls from './BlockStyleControls';
import VideoAdd from './VideoAdd';

import {
  FullRichEditorContainer,
  EditToolbarContainer,
} from './styledItems';

const Toolbar = (props) => {
  const {
    editorState,
    toggleInlineStyle,
    toggleBlockType,
    onChange,
    isFullRichEditor,
  } = props;

  return (
    <EditToolbarContainer isFullRichEditor={isFullRichEditor}>
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <FullRichEditorContainer isFullRichEditor={isFullRichEditor}>
        <div className="edit-toolbar__pipe" />
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <VideoAdd
          editorState={editorState}
          onChange={onChange}
        />
      </FullRichEditorContainer>
    </EditToolbarContainer>
  );
};

const {
  func,
  objectOf,
  any,
  bool,
} = PropTypes;

Toolbar.propTypes = {
  editorState: objectOf(any).isRequired,
  toggleInlineStyle: func.isRequired,
  toggleBlockType: func.isRequired,
  onChange: func.isRequired,
  isFullRichEditor: bool.isRequired,
};

export default Toolbar;
