import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

export default createSelector(
  (state) => state.realtorInspections.inspections,
  (state, filterId) => state.realtorInspections.inspectionsByFilter[filterId],
  (state, filterId) => filterId,
  (inspections, filteredIds, filterId) => {
    if (!filteredIds) {
      optimizedRealtorInspectionsActions.getInspectionsForFilter(filterId, JSON.parse(filterId));

      return [];
    }

    const result = filteredIds.reduce((accum, transactionId) => {
      const deal = inspections[transactionId];

      if (!deal && transactionId) {
        optimizedRealtorInspectionsActions.getInspectionById(transactionId);
        return accum;
      }

      accum.push(deal);
      return accum;
    }, []);

    return result;
  },
);
