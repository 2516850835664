import deleteContact from './deleteContact';

const deleteSelectedContact = () => async (dispatch, getState) => {
  const {
    realtorContacts: {
      selectedContact,
    },
  } = getState();

  dispatch(deleteContact(selectedContact));
};

export default deleteSelectedContact;
