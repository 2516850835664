/* eslint-disable */
//TODO - fix eslint
import {
  SET_TICKET_EMPLOYEES_SUCCESS,
} from 'store/actionTypes/ticket-details-modal';
import {
  GET_QUEUE_SETUP_LIST_SUCCESS,
  GET_QUEUE_CATEGORIES_SUCCESS,
  GET_QUEUE_SUBCATEGORIES_SUCCESS,
  OPEN_MODAL,
  HIDE_MODAL,
  ADD_QUEUE_SUCCESS,
  DELETE_QUEUE_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  ADD_SUBCATEGORY_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_SUCCESS,
  SET_ACTIVE_QUEUE,
  GET_ACTIVE_QUEUE,
  SET_QUEUE_MEMBERS_SUCCESS,
  GET_QUEUE_DATA_SUCCESS,
  GET_QUEUE_PERMISSIONS_SUCCESS,
  UPDATE_TICKET_QUEUE,
} from '../actions/queue-setup';
import {
  CREATE_TICKET_SUCCESS,
  UPDATE_TICKET_PROGRESS_SUCCESS,
  UPDATE_TICKET_FIELD_SUCCESS,
  UPDATE_TICKET_DELVIERY_DATE_SUCCESS,
} from '../actions/tickets';
import { updateTicket } from './tickets';

const initialState = {
  queueSetupList: [],
  queueModal: {
    isOpen: false,
    isLoading: false,
  },
  activeQueue: null,
  permissions: [],
};


function queueSetup(state = initialState, action) {
  switch (action.type) {
    case GET_QUEUE_SETUP_LIST_SUCCESS:
      return { ...state, queueSetupList: action.queueSetupList, activeQueue: action.queueSetupList[0].QueueID };
    case GET_QUEUE_CATEGORIES_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return { ...queue, categories: action.queueCategories };
          }
          return queue;
        }),
      };
    case GET_QUEUE_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return {
              ...queue,
              categories: queue.categories.map((category) => {
                if (category.CategoryID === action.categoryId) {
                  return { ...category, subCategories: action.queueSubCategories };
                }
                return category;
              }),
            };
          }
          return queue;
        }),
      };
    case ADD_QUEUE_SUCCESS:
      return { ...state, queueSetupList: [...state.queueSetupList, action.newQueue] };
    case DELETE_QUEUE_SUCCESS:
      return { ...state, queueSetupList: state.queueSetupList.filter((queue) => queue.QueueID !== action.queueId) };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return { ...queue, categories: [...queue.categories, action.newCategory] };
          }
          return queue;
        }),
      };
    case ADD_SUBCATEGORY_SUCCESS:
      const { queueId, categoryId, newSubCategory } = action;
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === queueId) {
            return {
              ...queue,
              categories: queue.categories.map((category) => {
                if (category.CategoryID === categoryId) {
                  return { ...category, subCategories: [...category.subCategories, newSubCategory] };
                }
                return category;
              }),
            };
          }
          return queue;
        }),
      };
    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return {
              ...queue,
              categories: queue.categories.map((category) => {
                if (category.CategoryID === action.categoryId) {
                  return { ...category, Category: action.editCategory };
                }
                return category;
              }),
            };
          }
          return queue;
        }),
      };
    case EDIT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return {
              ...queue,
              categories: queue.categories.map((category) => {
                if (category.CategoryID === action.categoryId) {
                  return {
                    ...category,
                    subCategories: category.subCategories.map((subCategory) => {
                      if (subCategory.SubCategoryID === action.subCategoryId) {
                        return { ...subCategory, Product: action.editSubCategory };
                      }
                      return subCategory;
                    }),
                  };
                }
                return category;
              }),
            };
          }
          return queue;
        }),
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return {
              ...queue,
              categories: queue.categories.filter((category) => category.CategoryID !== action.categoryId),
            };
          }
          return queue;
        }),
      };
    case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === action.queueId) {
            return {
              ...queue,
              categories: queue.categories.map((category) => {
                if (category.CategoryID === action.categoryId) {
                  return {
                    ...category,
                    subCategories: category.subCategories.filter((subCategory) => subCategory.SubCategoryID !== action.subCategoryId),
                  };
                }
                return category;
              }),
            };
          }
          return queue;
        }),
      };
    case OPEN_MODAL:
      return { ...state, queueModal: { ...state.queueModal, isOpen: true } };
    case HIDE_MODAL:
      return { ...state, queueModal: { ...state.queueModal, isOpen: false } };
    case SET_ACTIVE_QUEUE:
      return { ...state, activeQueue: action.activeQueue };
    case GET_ACTIVE_QUEUE:
      return { ...state, activeQueue: state.activeQueue };
    case GET_QUEUE_DATA_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === parseInt(action.queueId)) {
            return {
              ...queue,
              members: action.members,
              tickets: action.tickets,
            };
          }
          return queue;
        }),
      };
    case SET_QUEUE_MEMBERS_SUCCESS:
      return {
        ...state,
        queueSetupList: state.queueSetupList.map((queue) => {
          if (queue.QueueID === parseInt(action.queueId)) {
            return { ...queue, members: action.members.employees };
          }
          return queue;
        }),
      };
    case CREATE_TICKET_SUCCESS:
      return synchronizeQueue(state, action, action.queueId);
    case SET_TICKET_EMPLOYEES_SUCCESS:
      return synchronizeQueue(state, action, action.config.queueId);
    case UPDATE_TICKET_DELVIERY_DATE_SUCCESS:
    case UPDATE_TICKET_FIELD_SUCCESS:
    case UPDATE_TICKET_PROGRESS_SUCCESS: {
      const { ticket } = action;
      return synchronizeQueueTickets(state, ticket);
    }
    case GET_QUEUE_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.permissions };
    default:
      return state;
  }
}

export default queueSetup;

const synchronizeQueue = (state, action, queueId) => {
  const { data: [, newQueue] } = action;
  let newTickets = [];
  let newEmployees = [];
  if (newQueue) {
    const { employees, tickets } = newQueue;
    newTickets = tickets;
    newEmployees = employees;
  }
  return {
    ...state,
    queueSetupList: state.queueSetupList.map((queue) => {
      if (queue.QueueID === queueId) {
        return {
          ...queue,
          tickets: newTickets,
          members: newEmployees,
        };
      }
      return queue;
    }),
  };
};

const synchronizeQueueTickets = (state, newTicket) => {
  const { QueueID } = newTicket;
  const { queueSetupList } = state;
  return {
    ...state,
    queueSetupList: queueSetupList.map((queue) => (queue.QueueID === QueueID ? updateQueue(queue, newTicket) : queue)),
  };
};

const updateQueue = (queue, newTicket) => {
  const { tickets, members } = queue;
  const { employees } = newTicket;
  const ticketEmployees = employees.map((employee) => employee.EmployeeID);
  return {
    ...queue,
    tickets: updateTicket(tickets, newTicket),
    members: members.map((member) => (ticketEmployees.includes(member.EmployeeID) ? updateMembers(member, newTicket) : member)),
  };
};

const updateMembers = (member, newTicket) => {
  const { tickets } = member;
  return {
    ...member,
    tickets: updateTicket(tickets, newTicket),
  };
};
