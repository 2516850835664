import {
  SET_NEW_REALTOR_REPAIR_REQUEST_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorRepairRequests';

const setNewRepairRequestFormAttachments = (filesList = []) => ({
  type: SET_NEW_REALTOR_REPAIR_REQUEST_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setNewRepairRequestFormAttachments;
