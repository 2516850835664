import React from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import DocumentRequestForm from 'sections/Transactions/forms/DocumentRequestForm';

const formId = 'document-request-form';

const DocumentRequestModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
  } = props;

  return (
    <Modal
      theme={theme}
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="New document request"
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          htmlType="submit"
          form={formId}
        >
          Submit
        </Button>,
      ]}
    >
      <DocumentRequestForm
        formId={formId}
        onAfterSubmit={closeModal}
      />
    </Modal>
  );
};

DocumentRequestModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  func,
  bool,
  shape,
} = PropTypes;

DocumentRequestModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
};

export default DocumentRequestModal;
