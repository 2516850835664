import get from './get';
import getById from './getById';
import create from './create';
import updateSyncedEvent from './update';
import deleteSyncedEvent from './delete';

export default {
  get,
  getById,
  create,
  updateSyncedEvent,
  deleteSyncedEvent
};
