import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyImageCell from './PropertyImageCell';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const image = selectors.properties.propertyMainImage(state, propertyId);
  const property = selectors.properties.byId(state, propertyId);

  return {
    image: image?.thumbUrl,
    status: property?.listing_status,
  };
};

export default connect(mapStateToProps)(PropertyImageCell);
