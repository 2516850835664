import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import MultiselectTextfield from 'ui/MultiselectTextfield';

const PropertyMultiTypeSelect = (props) => {
  const {
    onChange,
    value,
    defaultView,
    label,
  } = props;

  const selectedValue = Array.isArray(value)
    ? value.map((key) => ({
      value: `${key}`,
      label: PROPERTY_TYPES[key],
    }))
    : undefined;

  const handleChange = useCallback((newValue) => {
    onChange(newValue.map((item) => item.value));
  }, [
    onChange,
  ]);

  return (
    <MultiselectTextfield
      {...props}
      label={label}
      onChange={handleChange}
      options={PROPERTY_TYPES}
      value={selectedValue}
      defaultView={defaultView}
    />
  );
};

PropertyMultiTypeSelect.defaultProps = {
  value: [],
  onBlur: () => {},
  label: 'Property Type',
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  string,
} = PropTypes;

PropertyMultiTypeSelect.propTypes = {
  onChange: func.isRequired,
  onBlur: func,
  value: arrayOf(number),
  defaultView: oneOf(['edit', 'display']),
  label: string,
};

export default PropertyMultiTypeSelect;
