import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  addPropertyRequirementRelations,
  removePropertyRequirementRelations,
} from 'store/actions/propertyRequirements';

import MultipleLocationInput from 'sections/Addresses/modules/MultipleLocationInput';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    addressIds: propertyRequirementData?.addresses,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: (value) => {
    dispatch(addPropertyRequirementRelations('addresses')(ownProps.itemId)(value));
  },
  onRemove: (value) => {
    dispatch(removePropertyRequirementRelations('addresses')(ownProps.itemId)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleLocationInput);
