import { connect } from 'react-redux';

import requests from 'api/requests';

import commentActions from 'store/actions/comments';
import logActions from 'store/actions/logs';

import { getContactById } from 'store/actions/realtorContacts';

import selectors from 'store/selectors';

import CommentTabsModule from './CommentTabsModule';

const mapStateToProps = (state, ownProps) => {
  const {
    commentedObjectType,
    commentedObjectId,
    contactId,
  } = ownProps;

  const comments = state.comments.commentedObjects[commentedObjectType][commentedObjectId];
  const logList = state.logs.parentObjects[commentedObjectType][commentedObjectId];

  const callRecordings = selectors.realtorContacts.contactCallRecordings(state, contactId);

  return {
    comments,
    logList,
    callRecordings,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    commentedObjectType,
    commentedObjectId,
  } = ownProps;

  const commentMethods = commentActions(commentedObjectType)(commentedObjectId);
  const logMethods = logActions(commentedObjectType)(commentedObjectId);

  return {
    getComments: () => dispatch(commentMethods.get),
    deleteComment: (commentId) => dispatch(commentMethods.delete(commentId)),
    editComment: ({ commentId, commentText }) => dispatch(commentMethods.edit(commentId)(commentText)),
    acknowledgeComment: (commentId) => dispatch(commentMethods.acknowledge(commentId)),
    resolveComment: (commentId) => dispatch(commentMethods.resolve(commentId)),
    markCommentAsSeen: requests.comments.markAsSeen,

    getLogs: () => dispatch(logMethods.get),
    addContactLog: (data) => dispatch(logMethods.post(data)),
    deleteContactLog: (logId) => dispatch(logMethods.delete(logId)),
    acknowledgeContactLog: (logId) => dispatch(logMethods.acknowledge(logId)),

    deleteCallRecording: async (recordingSid, contactId) => {
      try {
        await requests.calls.recordings.remove(recordingSid);
      } catch (err) {
        return;
      }
      dispatch(getContactById(contactId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentTabsModule);
