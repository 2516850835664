import store from 'store'; // eslint-disable-line import/no-cycle

import { memoizeDebounce } from 'shared/utility';

import setDictionary from './setDictionary';
import getCurrencyDictionary from './getCurrencyDictionary';
import getAmenitiesDictionary from './getAmenitiesDictionary';
import getRealtorSaleTypesDictionary from './getRealtorSaleTypesDictionary';
import getRealtorViewsFromHomeDictionary from './getRealtorViewsFromHomeDictionary';
import getRealtorSpecialConditionsDictionary from './getRealtorSpecialConditionsDictionary';
import getRejectedPropertiesReasonsDictionary from './getRejectedPropertiesReasonsDictionary';
import getRealtorRepairTypesDictionary from './getRealtorRepairTypesDictionary';
import getPersonTitlesDictionary from './getPersonTitlesDictionary';
import getContactLabelsDictionary from './getContactLabelsDictionary';

export {
  setDictionary,
  getCurrencyDictionary,
  getAmenitiesDictionary,
  getRealtorSaleTypesDictionary,
  getRealtorViewsFromHomeDictionary,
  getRealtorSpecialConditionsDictionary,
  getRejectedPropertiesReasonsDictionary,
  getRealtorRepairTypesDictionary,
  getPersonTitlesDictionary,
  getContactLabelsDictionary,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedDictionaryActions = {
  getCurrencyDictionary: memoizeDebounce(
    () => {
      store.dispatch(getCurrencyDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getAmenitiesDictionary: memoizeDebounce(
    () => {
      store.dispatch(getAmenitiesDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getRealtorSaleTypesDictionary: memoizeDebounce(
    () => {
      store.dispatch(getRealtorSaleTypesDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getRealtorViewsFromHomeDictionary: memoizeDebounce(
    () => {
      store.dispatch(getRealtorViewsFromHomeDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getRealtorSpecialConditionsDictionary: memoizeDebounce(
    () => {
      store.dispatch(getRealtorSpecialConditionsDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getRejectedPropertiesReasonsDictionary: memoizeDebounce(
    () => {
      store.dispatch(getRejectedPropertiesReasonsDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getRealtorRepairTypesDictionary: memoizeDebounce(
    () => {
      store.dispatch(getRealtorRepairTypesDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getPersonTitlesDictionary: memoizeDebounce(
    () => {
      store.dispatch(getPersonTitlesDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
  getContactLabelsDictionary: memoizeDebounce(
    () => {
      store.dispatch(getContactLabelsDictionary());
    },
    debounceDelay,
    debounceOptions,
  ),
};
