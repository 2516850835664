import connectReducers from 'store/connectReducers';

import { omit } from 'shared/utility';

import {
  SET_NEW_FULFILLMENT_FORM_FIELD,
  UPDATE_NEW_FULFILLMENT_FORM_PRODUCT,
  ADD_NEW_FULFILLMENT_FORM_PRODUCT,
  REMOVE_NEW_FULFILLMENT_FORM_PRODUCT,
  // SET_NEW_FULFILLMENT_FORM_ASSIGNED_USERS,
  // SET_NEW_FULFILLMENT_FORM_ASSIGNED_TEAMS,
} from 'store/actionTypes/fulfillments';

const initialState = {
  // fulfillment data fields
  title: '',
  types: [1],
  status: 1,
  permission_type: 1,
  deal_id: null,
  company_id: null,
  contact_id: null,
  discount_amount: 0,
  discount_type: 1,
  salesperson_id: null,

  is_pre_paid: false,
  transactionId: null,
  invoiceNumber: null,
  invoiceFile: null,

  eta: null,
  shippingCost: 0,

  isCompleteDealSurveyChecked: false,
  /**
   * This is the comment that goes to `fulfillments.comment`.
   * Also, a comment in the `comments` tables should be created
   * with the provided comment type and the reference
   * to the newly created fulfillment object
   */
  initialComment: '',
  commentType: 1,
  // send to API as Object.values
  products: {
    /**
     * [row_id]: {
     *   id: 1,
     *   quantity: 1,
     *   price_per_unit: 1,
     *   currency: 'USD',
     *   description: null,
     *   discount_amount: 0,
     *   discount_type: 1,
     * }
     */
  },
  // type-specific fields
  // create new objects on fulfilment creation
  address: {},
  customerEmail: null,
  accountNumber: null,
  serviceDate: null,
  servicePeople: null,
  orderNumber: null,
  // // helper fields
  // sourceType: null,
  // sourceDescription: null,
  // productNameFilter: null,
  // companyNameFilter: null,
  // contactNameFilter: null,
};

const reducers = {
  [SET_NEW_FULFILLMENT_FORM_FIELD]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),

  [UPDATE_NEW_FULFILLMENT_FORM_PRODUCT]: (state, action) => {
    const {
      id,
      update,
    } = action.payload;

    const products = { ...state.products };

    products[id] = {
      ...products[id],
      ...update,
    };

    return {
      ...state,
      products,
    };
  },

  [ADD_NEW_FULFILLMENT_FORM_PRODUCT]: (state) => {
    const products = { ...state.products };

    const now = new Date();

    const id = `new-product-id-${now.getTime()}`;

    products[id] = {
      id: null,
      quantity: 0,
      price_per_unit: 0,
      currency: 'USD',
      description: null,
      discount_amount: 0,
      discount_type: 1,
    };

    const updatedState = {
      ...state,
      products,
    };

    return updatedState;
  },

  [REMOVE_NEW_FULFILLMENT_FORM_PRODUCT]: (state, action) => {
    const products = { ...state.products };

    const updatedState = {
      ...state,
      products: omit(products, [action.payload.id]),
    };

    return updatedState;
  },

  // [SET_NEW_FULFILLMENT_FORM_ASSIGNED_USERS]: (state, action) => ({
  //   ...state,
  //   assignedUsers: action.payload,
  // }),

  // [SET_NEW_FULFILLMENT_FORM_ASSIGNED_TEAMS]: (state, action) => ({
  //   ...state,
  //   assignedTeams: action.payload,
  // }),
};

function newFulfillmentForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newFulfillmentForm;
