import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

const SectionTitle = (props) => {
  const {
    variant,
    title,
    tooltipText,
    comingSoon,
  } = props;

  return (
    <Flex
      spacing={2}
      style={{ marginBottom: variant === 'title1' ? 25 : 15 }}
    >
      <Typography
        variant={variant}
        weight="bold"
        noMargin
      >
        {title}
      </Typography>
      {
        tooltipText && (
          <Tooltip
            title={tooltipText}
            placement="right"
          >
            <div>
              <Icon
                name="info"
                color="highlight"
              />
            </div>
          </Tooltip>
        )
      }
      {
        comingSoon && (
          <Typography
            variant="title2"
            color="focus"
            weight={400}
            noMargin
          >
            Coming Soon
          </Typography>
        )
      }
    </Flex>
  );
};

SectionTitle.defaultProps = {
  variant: 'title1',
  tooltipText: null,
  comingSoon: false,
};

const {
  string,
  bool,
  oneOfType,
  element,
} = PropTypes;

SectionTitle.propTypes = {
  variant: string,
  title: string.isRequired,
  tooltipText: oneOfType([string, element]),
  comingSoon: bool,
};

export default SectionTitle;
