import {
  SET_REALTOR_MATCHED_FOR_FILTER,
} from 'store/actionTypes/realtorMatched';

const setMatchedForFilter = (filterId) => (matched) => ({
  type: SET_REALTOR_MATCHED_FOR_FILTER,
  payload: {
    filterId,
    matched,
  },
});

export default setMatchedForFilter;
