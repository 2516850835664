import {
  SET_REALTOR_REPAIR_REQUEST_ATTACHMENTS,
} from 'store/actionTypes/realtorRepairRequests';

const setRepairRequestAttachments = (id, attachments) => ({
  type: SET_REALTOR_REPAIR_REQUEST_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setRepairRequestAttachments;
