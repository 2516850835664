import React from 'react';
import PropTypes from 'prop-types';
import StyleButton from '../StyleButton/StyleButton';
import { BLOCK_TYPES } from '../../config';

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <>
      {BLOCK_TYPES.map((type) =>
        // eslint-disable-next-line
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />)}
    </>
  );
};

const {
  func,
  objectOf,
  any,
} = PropTypes;

BlockStyleControls.propTypes = {
  editorState: objectOf(any).isRequired,
  onToggle: func.isRequired,
};

export default BlockStyleControls;
