import { createSelector } from 'reselect';
import { isEmpty } from 'shared/utility';

import { optimizedAppCustomizationSettings } from 'store/actions/appCustomization';

export default createSelector(
  (state) => state.appCustomization.settings,
  (collection) => {
    if (isEmpty(collection)) {
      optimizedAppCustomizationSettings.getCustomizationSettings();
    }

    return collection;
  },
);
