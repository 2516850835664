/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */

import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Controller,
  useForm,
} from 'react-hook-form';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import FormTextfield from 'shared/inputs/FormTextfield';

const CancelTransactionForm = (props) => {
  const {
    formId,
    onSubmit: propsOnSubmit,

    propsReason,
  } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback((data) => {
    propsOnSubmit({
      ...data,
    });
  }, [
    propsOnSubmit,
  ]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="main-text"
                weight={400}
                noMargin
                style={{ marginBottom: 5 }}
              >
                Reason
              </Typography>
              <Controller
                name="reason"
                control={control}
                defaultValue={propsReason}
                render={({ field }) => (
                  <FormTextfield
                    label=""
                    multiline
                    rows={3}
                    {...field}
                    handleChange={field.onChange}
                    error={!!errors?.reason}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

CancelTransactionForm.defaultProps = {
  propsReason: null,
};

const {
  string,
  func,
} = PropTypes;

CancelTransactionForm.propTypes = {
  formId: string.isRequired,
  onSubmit: func.isRequired,
  propsReason: string,
};

export default CancelTransactionForm;
