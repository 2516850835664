import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

import PriceRange from 'ui/PriceRange';

const PriceInput = withDebouncedCallbacks(
  PriceRange,
  [
    ['onChange', 1000, { leading: false }],
  ],
);

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    value: [propertyRequirementData?.budget_min || 0, propertyRequirementData?.budget_max || 0],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ budget_min: value[0] }));
    dispatch(updatePropertyRequirement(ownProps.itemId)({ budget_max: value[1] }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceInput);
