import connectReducers from 'store/connectReducers';

import {
  SET_NEW_LEAD_FORM_FIELD,
  SET_NEW_LEAD_FORM_ASSIGNED_USERS,
  SET_NEW_LEAD_FORM_ASSIGNED_TEAMS,
} from 'store/actionTypes/crm';

const initialState = {
  // lead data fields
  title: null,
  company: null,
  product: null,
  value: null,
  currency: 'USD',
  contact: null,
  temp: null,
  assignedUsers: [],
  assignedTeams: [],
  source: null,

  // helper fields
  sourceType: null,
  sourceDescription: null,
  productNameFilter: null,
  companyNameFilter: null,
  contactNameFilter: null,
};

const reducers = {
  [SET_NEW_LEAD_FORM_FIELD]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),

  [SET_NEW_LEAD_FORM_ASSIGNED_USERS]: (state, action) => ({
    ...state,
    assignedUsers: action.payload,
  }),

  [SET_NEW_LEAD_FORM_ASSIGNED_TEAMS]: (state, action) => ({
    ...state,
    assignedTeams: action.payload,
  }),
};

function newLeadForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newLeadForm;
