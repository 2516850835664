import {
  SET_CALL_LOGS_FOR_FILTER_COUNT,
} from 'store/actionTypes/calls';

const setCallLogsForFilterCount = (filterId) => (count) => ({
  type: SET_CALL_LOGS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setCallLogsForFilterCount;
