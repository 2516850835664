import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

const MakeLead = (props) => {
  const {
    onClick,
    shouldShow,
  } = props;

  if (!shouldShow) {
    return null;
  }

  return (
    <Button
      onClick={onClick}
    >
      Make Lead
    </Button>
  );
};

const {
  func,
  bool,
} = PropTypes;

MakeLead.propTypes = {
  onClick: func.isRequired,
  shouldShow: bool.isRequired,
};

export default MakeLead;
