import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser } from 'store/actions/users';

import {
  changeLoggedUserStatus,
  receiveLogout,
  triggerChangeModal,
} from 'store/actions/auth';

import {
  openModal,
} from 'store/actions/modals';

import UserMenu from 'shared/components/UserMenu';

const mapStateToProps = (state) => ({
  user: state.auth.loggedUser,
});

const mapDispatchToProps = {
  changeLoggedUserStatus,
  receiveLogout,
  triggerChangeModal,
  getUser: getUser('employee'),
  openOnboardingModal: openModal('onboardingModal')({}, {
    firstLogin: false
  }),
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserMenu);
