import {
  SET_REALTOR_MATCHED,
} from 'store/actionTypes/realtorMatched';

const setMatched = (matched) => ({
  type: SET_REALTOR_MATCHED,
  payload: matched,
});

export default setMatched;
