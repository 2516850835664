import { createSelector } from 'reselect';

import { forOwn, isUndefined } from 'shared/utility';

import {
  optimizedWarehouseActions,
} from 'store/actions/warehouse';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.warehouse.pendingOrders,
  (state, fulfillmentId) => fulfillmentId,
  (products, pendingOrders, selectedFulfillmentId) => {
    const orderItems = pendingOrders[selectedFulfillmentId];

    if (isUndefined(orderItems)) {
      if (selectedFulfillmentId) {
        optimizedWarehouseActions.getPendingOrder(selectedFulfillmentId);
      }

      return [];
    }

    if (!orderItems) {
      return [];
    }

    const items = [];

    forOwn(orderItems, (item, key) => {
      const productId = item.product_id;

      const productObject = products[productId];

      if (!productObject && productId) {
        optimizedCrmProductsActions.getProductById(productId);
      }

      items.push({
        ...item,
        rowId: +key + 1,
        title: productObject?.name,
        quantity: item.sub_items ? JSON.parse(item.sub_items).length : 1,
        isExpandable: item.sub_items_count > 1,
        sub_items: item.sub_items ? JSON.parse(item.sub_items) : [],
      });
    });

    return items;
  },
);
