import React, {
  useState,
  useEffect,
} from 'react';

import Modal from 'ui/Modal';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Spin from 'ui/Spin';

import NewPropertyForm from 'sections/Properties/forms/NewPropertyFormScraper';

import requests from 'api/requests';

import useAuthToken from 'shared/hooks/useAuthToken';

const appUrl = process.env.REACT_APP_CLIENT_APP_URL;

const closeIFrame = () => {
  window.parent.postMessage('unload-symplete-bookmarklet', '*');
};

const PropertyScraper = (props) => {
  const token = useAuthToken();

  if (!token) {
 return (
   <Modal
     visible
     footer={null}
     width={800}
      // noCloseIcon
     onCancel={closeIFrame}
   >
     <Typography variant="h1">
       Please log in to your Symplete account
     </Typography>
     <Typography variant="link" tag="a" href={appUrl} target="_blank">
       Symplete Realtor
     </Typography>
   </Modal>
  );
}

  const [scrapingTarget, setScrapingTarget] = useState(null);
  const [parsingResult, setParsingResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const newScrapingTarget = params.get('url');

    setScrapingTarget(newScrapingTarget);
  }, [
    window.location.href,
  ]);

  useEffect(() => {
    if (scrapingTarget) {
      const getData = async () => {
        setIsLoading(true);
        const result = await requests.realtor.dataScraping.property({ url: scrapingTarget });

        return result;
      };

      getData()
        .then((result) => {
          console.log('result', result);
          setParsingResult(result.data);
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    scrapingTarget,
  ]);

  return (
    <div>
      <Modal
        visible
        footer={null}
        width={800}
        // noCloseIcon
        onCancel={closeIFrame}
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={4}
          alignItems="center"
        >
          <div>
            <Typography
              variant="h2"
              align="left"
            >
              Add Property To Symplete
            </Typography>
            <Typography align="center">
              {scrapingTarget}
            </Typography>
          </div>
          <FlexItem
            fullWidth
          >
            {
              isLoading && (
                <Flex
                  fullWidth
                  justifyContent="center"
                >
                  <Spin />
                </Flex>
              )
            }
            {
              !isLoading && parsingResult && (
                <NewPropertyForm
                  defaultValues={parsingResult}
                  onCancel={closeIFrame}
                />
              )
            }
          </FlexItem>
        </Flex>
      </Modal>
    </div>
  );
};

export default PropertyScraper;
