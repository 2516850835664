/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import ClickAwayListener from 'ui/ClickAwayListener';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Typography from 'ui/Typography';
import EditableArea from 'ui/EditableArea';
import Textfield from 'ui/Textfield';
import Tag from 'ui/Tag';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import SocialLinks from 'sections/Contacts/modules/SocialLinks';
import ContactDescription from 'sections/Contacts/modules/ContactDescription';
import ContactLikes from 'sections/Contacts/modules/ContactLikes';
import ContactDisikes from 'sections/Contacts/modules/ContactDislikes';
import ContactLabelsRaw from 'sections/Contacts/modules/ContactLabels';
import ContactPhoneNumbers from 'sections/Contacts/modules/ContactPhoneNumbers';
import ContactEmails from 'sections/Contacts/modules/ContactEmails';
import Call from 'sections/Contacts/modules/Call';
import EmailButton from 'sections/Contacts/modules/EmailButton';

import ContactTitleSelect from 'shared/components/UI/ContactTitleSelect';

import withInlineLabel from 'shared/hocs/withInlineLabelV2';

// import CheckboxTag from 'sections/Contacts/components/CheckboxTag';

import useContactName from 'shared/hooks/contacts/useContactName';

// import Birthday from 'sections/Contacts/modules/Birthday';
// import FirstContact from 'sections/Leads/modules/FirstContact';
// import LastContact from 'sections/Leads/modules/LastContact';
// import NextContact from 'sections/Leads/modules/NextContact';

// import { CONTACT_TYPE_LABELS } from 'constants/realtor.constants';

import {
  EditablesContainer,
} from './styledItems';

const ContactLabels = withInlineLabel(ContactLabelsRaw);

const ContactInfo = (props) => {
  const {
    contactId,
    // type,
    socialLinks,
    // isVip,
    editable,
    short,
    nameTypographyProps,
    label,
    labelProps,
    withSalutations,

    // custom components
    avatar,
    toolbar,

    onChange,
  } = props;

  const {
    titleId,
    firstName,
    lastName,
    fullName,
  } = useContactName(contactId, { returnValue: 'object' });

  return (
    <Flex spacing={6}>
      <FlexItem
        xs={short ? 24 : 8}
      >
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
        >
          {avatar || <ContactAvatar contactId={contactId} />}

          <FlexItem flex={1}>
            <Flex
              spacing={2}
              flexWrap="wrap"
            >
              <FlexItem fullWidth>
                <EditableArea
                  disabled={!editable}
                  getEditComponent={(onBlur) => (
                    <ClickAwayListener
                      onClickAway={onBlur}
                    >
                      <EditablesContainer>
                        {
                          withSalutations && (
                            <ContactTitleSelect
                              value={titleId}
                              handleChange={onChange('title')}
                            />
                          )
                        }
                        <Textfield
                          value={firstName}
                          onChange={onChange('first_name')}
                          label="First Name"
                        />
                        <Textfield
                          value={lastName}
                          onChange={onChange('last_name')}
                          label="Last Name"
                        />
                      </EditablesContainer>
                    </ClickAwayListener>
                  )}
                >
                  <Typography
                    noMargin
                    multilineEllipsis
                    {...nameTypographyProps} // eslint-disable-line react/jsx-props-no-spreading
                  >
                    {fullName}
                  </Typography>
                </EditableArea>
              </FlexItem>

              {
                label && (
                  <FlexItem fullWidth>
                    <Tag {...labelProps}>
                      {' '}
                      // eslint-disable-line react/jsx-props-no-spreading
                      {' '}
                      {label}
                    </Tag>
                  </FlexItem>
                )
              }

              <FlexItem fullWidth>
                <DecoratedFieldWrapper
                  iconName="mail"
                  iconType="feather"
                  size="sm"
                  variant="focus"
                >
                  <ContactEmails
                    contactId={contactId}
                  />
                </DecoratedFieldWrapper>
              </FlexItem>

              <FlexItem fullWidth>
                <DecoratedFieldWrapper
                  iconName="phone"
                  iconType="feather"
                  variant="focus"
                  size="sm"
                >
                  <ContactPhoneNumbers
                    contactId={contactId}
                  />
                </DecoratedFieldWrapper>
              </FlexItem>

            </Flex>
          </FlexItem>

          <FlexItem fullWidth>
            <Flex
              spacing={2}
              alignItems="center"
              fullWidth
            >
              <Call
                showText
                contactId={contactId}
              />
              <EmailButton
                showText
                contactId={contactId}
              />
            </Flex>
          </FlexItem>

          <FlexItem fullWidth>
            <ContactLabels
              contactId={contactId}
              label="Labels:"
              alignItems="center"
              labelProps={{
                weight: 500,
                noMargin: true,
                style: { marginRight: 10 },
              }}
            />
          </FlexItem>

          {
            !short && (
              <FlexItem fullWidth>
                <SocialLinks
                  contactId={contactId}
                  firstName={firstName}
                  links={socialLinks}
                />
              </FlexItem>
            )
          }
        </Flex>
      </FlexItem>

      {
        !short && (
          <FlexItem
            flex={1}
          >
            <Flex
              spacing={4}
              flexWrap="wrap"
            >
              {
                toolbar && (
                  <FlexItem fullWidth>
                    {toolbar}
                  </FlexItem>
                )
              }

              <Flex
                fullWidth
                spacing={4}
              >
                <FlexItem xs={12}>
                  <Typography
                    variant="title2"
                    tag="h4"
                  >
                    Notes
                  </Typography>
                  <div style={{ maxHeight: 140 }}>
                    <ContactDescription
                      contactId={contactId}
                    />
                  </div>
                </FlexItem>

                <FlexItem
                  xs={12}
                >
                  <Flex
                    spacing={2}
                  >
                    <FlexItem xs={12}>
                      <Typography
                        variant="title2"
                        tag="h4"
                      >
                        Likes
                      </Typography>
                      <ContactLikes contactId={contactId} />
                    </FlexItem>

                    <FlexItem xs={12}>
                      <Typography
                        variant="title2"
                        tag="h4"
                      >
                        Dislikes
                      </Typography>
                      <ContactDisikes contactId={contactId} />
                    </FlexItem>
                  </Flex>
                </FlexItem>
              </Flex>
            </Flex>
          </FlexItem>
        )
      }
    </Flex>
  );
};

ContactInfo.defaultProps = {
  contactId: null,
  type: null,
  socialLinks: [
    {
      name: 'facebook',
      url: null,
      icon: ['fab', 'facebook-f'],
    },
    {
      name: 'twitter',
      url: null,
      icon: ['fab', 'twitter'],
    },
    {
      name: 'linkedin',
      url: null,
      icon: ['fab', 'linkedin-in'],
    },
    {
      name: 'instagram',
      url: null,
      icon: ['fab', 'instagram'],
    },
    {
      name: 'website',
      url: null,
      icon: 'globe',
    },
  ],
  avatar: null,
  toolbar: null,
  editable: true,
  short: false,
  nameTypographyProps: {
    variant: 'h1',
  },
  label: null,
  labelProps: {},
};

const {
  arrayOf,
  bool,
  element,
  number,
  oneOfType,
  shape,
  string,
  func,
} = PropTypes;

ContactInfo.propTypes = {
  contactId: number,
  type: arrayOf(number),
  // description: string,
  socialLinks: arrayOf(shape({
    name: string,
    url: string,
    icon: oneOfType([
      arrayOf(string),
      string,
    ]),
  })),
  editable: bool,
  short: bool,
  nameTypographyProps: shape(),
  label: string,
  labelProps: shape(),
  withSalutations: bool.isRequired,
  avatar: element,
  toolbar: element,
  onChange: func.isRequired,
};

export default ContactInfo;
