import { createSelector } from 'reselect';

const leads = (state) => state.crmLeads.leads;
const itemsForFilter = (state, filterId) => state.crmLeads.leadsByFilter[filterId];

const tempDict = {
  1: 'cold',
  2: 'warm',
  3: 'hot',
};

export default createSelector(
  leads,
  itemsForFilter,
  (items, filteredIds) => (filteredIds
    ? filteredIds.map((id) => {
      const lead = items[id];

      return {
        id: lead.id,
        status: tempDict[lead.temp] || 'not set',
        title: lead.title,
        products: lead.products ? lead.products.map((product) => product.id) : null,
        value: lead.value,
        company: lead.company,
        contact: lead.contact,
        activity: '',
        source: lead.source,
        created: lead.created_at,
        addedBy: lead.created_by,
        claimedBy: lead.claimed_by,
      };
    })
    : []),
);
