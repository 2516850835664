import styled from 'styled-components';

export const Wrapper = styled.div`
  .area-title {
    margin-top: auto;
    margin-bottom: auto;
    margin: 11px 0px;
  }

  .section-title {
    margin: 11px 0px;
  }
`;

export default {
  Wrapper,
};
