import styled from 'styled-components';
import { Tag as AntdTag } from 'antd';

export const Tag = styled(AntdTag)`
  background: ${(props) => props.color};
`;

export default {
  Tag,
};
