import {
  searchProperties,
} from 'store/actions/properties';

import setSearchedProperties from './setSearchedProperties';

// eslint-disable-next-line consistent-return
const searchMLSModalAction = (filter) => async (dispatch) => {
  try {
    const searchedData = await dispatch(searchProperties(filter));

    dispatch(setSearchedProperties(searchedData));
  } catch (error) {
    console.error(error);
  }
};

export default searchMLSModalAction;
