import apiConnector, { endpoints } from 'api';

const updateFolder = (folderId) => async (folderInformations) => {
  const endpoint = `${endpoints.client.articles.folderGeneral}/${folderId}`;

  const result = await apiConnector.client.patch(endpoint, folderInformations);

  return result.data;
};

export default updateFolder;
