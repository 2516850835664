import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useAddress = (addressId) => {
  const address = useReselect(selectors.addresses.byId, addressId);

  return address?.address;
};

export default useAddress;
