// TODO apply custom symplete url render
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
} from 'draft-js';

import Editor from 'draft-js-plugins-editor';

import {
  convertToHTML,
  convertFromHTML,
} from 'draft-convert';

import {
  Spin,
} from 'antd';
import { callAlert } from 'ui/Alert';

import { changeDepth, clearEditorContent } from 'draftjs-utils';
import { isHotkey } from 'is-hotkey';
import classnames from 'classnames';

import { url } from 'paths';
import { noContentTypeFetch } from 'shared/API';
import { common } from 'constants';

import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-focus-plugin/lib/plugin.css';
// import 'draft-js-hashtag-plugin/lib/plugin.css';

import Toolbar from './Toolbar';
import MentionsEntry from './MentionsEntry';

import {
  styleMap,
  getBlockStyle,
  rules,
} from './config';

import {
  plugins,
  MentionSuggestions,
  defaultSuggestionsFilter,
} from './plugins';

import './RichTextEditor.scss';

const attachmentsUrl = 'api/attachments/workspace/article';

const RichTextEditor = (props) => {
  const {
    value,
    suggestedEmployees,
    onClickPost,
    onClickCancel,
    className,
    ControlsComponent,
    CustomToolbar,
    createMentions,
    currentObject,
    forceClearOnPost,
    isFullRichEditor,
    isAutoFocus,
    visibility,
    isClearEditorOnSubmit,
    onBlur,
    onChange,
  } = props;

  const textEditorRef = useRef(null);

  useEffect(() => {
    if (visibility && isAutoFocus) {
      textEditorRef.current.focus();
    }
  }, [visibility]);

  const [editorState, setEditorState] = useState(value
    ? EditorState.createWithContent(convertFromHTML(rules)(value)) : EditorState.createEmpty());

  const [mentionedEmployees, setMentionedEmployees] = useState([]);
  const [suggestionsState, setSuggestionsState] = useState([]);
  const [spinningState, setSpinningState] = useState(false);

  // Determine whether placeholder should be displayed (to avoid overlap with lists)
  const blockType = RichUtils.getCurrentBlockType(editorState);
  const isOl = blockType === 'ordered-list-item';
  const isUl = blockType === 'unordered-list-item';
  const placeholderIsVisible = !isOl && !isUl;

  const keyBindingFn = useCallback((event) => { // eslint-disable-line consistent-return
    if (isHotkey('tab', event) || isHotkey('shift+tab', event)) {
      return setEditorState(RichUtils.onTab(event, editorState, 4));
    }
    if (isHotkey('shift+enter', event)) {
      return 'soft-enter';
    }
    if (isHotkey('mod+enter', event)) {
      return 'editor-submit';
    }
    if (isHotkey('mod+]', event)) {
      return 'indent';
    }
    if (isHotkey('mod+[', event)) {
      return 'outdent';
    }
  }, [editorState]);

  const handleKeyCommand = useCallback((command, oldState) => {
    if (command === 'editor-submit') {
      return onSubmit();
    }
    if (command === 'soft-enter') {
      return setEditorState(RichUtils.insertSoftNewline(editorState));
    }
    if (command === 'indent') {
      return setEditorState(changeDepth(editorState, 1, 4));
    }
    if (command === 'outdent') {
      return setEditorState(changeDepth(editorState, -1, 4));
    }
    const newState = RichUtils.handleKeyCommand(oldState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }, [editorState]);

  const onSearchChange = useCallback(({ value }) => { // eslint-disable-line no-shadow
    setSuggestionsState(defaultSuggestionsFilter(value, suggestedEmployees));
  }, []);

  const toggleBlockType = useCallback((blockType) => { // eslint-disable-line no-shadow
    if (blockType === 'indent') {
      setEditorState(changeDepth(editorState, 1, 4));
    } else if (blockType === 'outdent') {
      setEditorState(changeDepth(editorState, -1, 4));
    } else {
      setEditorState(
        RichUtils.toggleBlockType(
          editorState,
          blockType,
        ),
      );
    }
  }, [editorState]);

  const toggleInlineStyle = useCallback((inlineStyle) => {
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      ),
    );
  }, [editorState]);

  const fetchFile = (files) => {
    const nodeId = 0;
    setSpinningState(true);

    const formData = new FormData();
    formData.append('attachment', files[0]);

    return noContentTypeFetch(
      `${url}/${attachmentsUrl}/${nodeId}`,
      {
        method: 'POST',
        body: formData,
      },
    ).then((res) => {
      setSpinningState(false);
      return res.json();
    }).catch(() => {
      setSpinningState(false);
      return callAlert.error(common.ENTITY_TOO_LARGE.MSG);
    });
  };

  const handlePastedFiles = useCallback((files) => {
    const filteredFiles = files
      .filter((file) => (file.type.indexOf('image/') === 0));

    if (!filteredFiles.length) {
      return 'not handled';
    }
    fetchFile(filteredFiles).then((attachment) => {
      if (attachment.url.length > 0) {
        const src = `${url}${attachment.url}`;
        const newEditorState = insertImage(editorState, src, 'internal');
        setEditorState(newEditorState);
      }
    });

    return 'handled';
  }, [editorState]);

  const insertImage = (editorState, src, location) => { // eslint-disable-line no-shadow
    let fullSrc = src;
    const contentState = editorState.getCurrentContent();
    if (location === 'internal') {
      const token = localStorage.getItem('token');
      fullSrc += `?auth=${token}`;
    }
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      { src: fullSrc, location },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const onAddMention = useCallback((mention) => {
    if (!mentionedEmployees.includes(mention.id)) {
      let mentionsArray = mentionedEmployees; // eslint-disable-line prefer-const
      mentionsArray.push(mention.id);
      setMentionedEmployees(mentionsArray);
    }
  }, [mentionedEmployees]);

  const onSubmit = useCallback(async () => {
    const html = convertToHTML(rules)(editorState.getCurrentContent());

    if (mentionedEmployees.length) {
      const comment = await onClickPost(html);
      createMentions(mentionedEmployees, currentObject, comment);
    } else {
      onClickPost(html);
    }
    // clearing input prevents editor from rendering passed in value if opened again
    if (forceClearOnPost) {
      // const emptyEditorState = EditorState.push(editorState, ContentState.createFromText(' '), 'remove-range');
      // setEditorState(emptyEditorState)

      setEditorState(clearEditorContent(editorState));
    }

    if (isClearEditorOnSubmit) {
      setEditorState(value
        ? EditorState.createWithContent(convertFromHTML(rules)(value)) : EditorState.createEmpty());
    }
  }, [editorState]);

  const onBlurAction = useCallback(() => {
    const html = convertToHTML(rules)(editorState.getCurrentContent());
    onBlur(html);
  }, [
    editorState,
  ]);

  useEffect(() => {
    const html = convertToHTML(rules)(editorState.getCurrentContent());

    if (onChange) {
      onChange(html);
    }
  }, [
    onChange,
    editorState,
  ]);

  const onCancel = useCallback(() => {
    setEditorState(value
      ? EditorState.createWithContent(convertFromHTML(rules)(value)) : EditorState.createEmpty());
    onClickCancel();
  }, [editorState]);

  return (
    <Spin tip="Loading..." spinning={spinningState}>
      <div className={
        classnames(
          {
            [className]: className,
          },
        )
      }
      >
        <div
          className={classnames('article-edit')}
        >
          <Toolbar
            isFullRichEditor={isFullRichEditor}
            editorState={editorState}
            onChange={setEditorState}
            toggleInlineStyle={toggleInlineStyle}
            toggleBlockType={toggleBlockType}
          />
          {
            CustomToolbar && (
              <CustomToolbar />
            )
          }
          <Editor
            onBlur={onBlurAction}
            ref={textEditorRef}
            customStyleMap={styleMap}
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            keyBindingFn={keyBindingFn}
            handleKeyCommand={handleKeyCommand}
            onChange={setEditorState}
            placeholder={placeholderIsVisible ? 'Enter some text…' : ''}
            plugins={plugins}
            handlePastedFiles={handlePastedFiles}
          />
          <MentionSuggestions
            onSearchChange={onSearchChange}
            onAddMention={onAddMention}
            suggestions={suggestionsState}
            entryComponent={MentionsEntry}
          />
        </div>
        {
          ControlsComponent && (
            <ControlsComponent
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          )
        }
      </div>
    </Spin>
  );
};

const noOp = () => { };

RichTextEditor.defaultProps = {
  onClickPost: noOp,
  onClickCancel: noOp,
  onBlur: noOp,
  onChange: noOp,
  className: null,
  value: null,
  suggestedEmployees: null,
  CustomToolbar: null,
  ControlsComponent: null,
  forceClearOnPost: false,
  isAutoFocus: false,
  visibility: false,
  isClearEditorOnSubmit: false,
};

const {
  bool,
  func,
  arrayOf,
  objectOf,
  string,
  any,
} = PropTypes;

RichTextEditor.propTypes = {
  isAutoFocus: bool,
  visibility: bool,
  className: string,
  onClickCancel: func,
  onClickPost: func,
  value: string,
  CustomToolbar: func,
  ControlsComponent: func,
  onBlur: func,
  onChange: func,
  isFullRichEditor: bool.isRequired,
  suggestedEmployees: arrayOf(any), // eslint-disable-line react/forbid-prop-types
  createMentions: func.isRequired,
  currentObject: objectOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
  isClearEditorOnSubmit: bool,
  forceClearOnPost: bool,
};

export default RichTextEditor;
