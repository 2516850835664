import requests from 'api/requests';

import setTokenIssuerUserData from './setTokenIssuerUserData';
import setTokenIssuerCompanyData from './setTokenIssuerCompanyData';

// eslint-disable-next-line consistent-return
const getTokenIssuerData = () => async (dispatch) => {
  try {
    const response = await requests.external.general.getTokenIssuerData();

    dispatch(setTokenIssuerUserData(response.data?.userData));
    dispatch(setTokenIssuerCompanyData(response.data?.companyData));
  } catch (error) {
    console.error(error);
  }
};

export default getTokenIssuerData;
