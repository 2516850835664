import { createSelector } from 'reselect';

import {
  optimizedRealtorExtensionRequestActions,
} from 'store/actions/realtorExtensionRequests';

import getFilter from './filter';

export default (filter) => createSelector(
  (state) => state.realtorExtensionRequests.extensionRequestsByFilter,
  () => getFilter(filter),
  (listsByFilter, filterObj) => {
    const list = listsByFilter[filterObj.filterId];

    if (!list) {
      optimizedRealtorExtensionRequestActions.getForFilter(filterObj.filterId, filterObj.filter);
    }

    return list;
  },
);
