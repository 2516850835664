import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createAddendumChange from './create';
import openNewAddendumChangeModal from './openNewAddendumChangeModal';
import addAddendumChangeRelations from './addRelations';

import getAddendumChangeById from './getById';
import getAddendumChangeForFilter from './getForFilter';

import updateAddendumChange from './update';

import getAddendumChangeAttachments from './getAttachments';

import setFormAttachments from './setFormAttachments';

export {
  createAddendumChange,
  addAddendumChangeRelations,
  openNewAddendumChangeModal,
  getAddendumChangeById,
  getAddendumChangeForFilter,
  updateAddendumChange,
  setFormAttachments,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorAddendumChangeActions = {
  getForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getAddendumChangeForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),

  getById: memoizeDebounce(
    (requestId) => store.dispatch(getAddendumChangeById(requestId)),
    debounceDelay,
    debounceOptions,
  ),

  getAttachments: memoizeDebounce(
    (requestId) => store.dispatch(getAddendumChangeAttachments(requestId)),
    debounceDelay,
    debounceOptions,
  ),
};
