import {
  SET_REALTOR_TRANSACTION_ATTACHMENTS,
} from 'store/actionTypes/realtorTransactions';

const setAttachments = (id) => (attachments) => ({
  type: SET_REALTOR_TRANSACTION_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
