import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_MATCHING_PROPERTIES,
  SET_REALTOR_MATCHING_PROPERTIES_BY_FILTER,
  SET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER,
  SET_REALTOR_INFINITE_SCROLL_MATCHING_PROPERTIES_FOR_FILTER,

  RESET_REALTOR_MATCHING_PROPERTIES_BY_FILTER,
  RESET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER,

  RESET_REALTOR_MATCHING_PROPERTIES_FILTERS,
  RESET_REALTOR_MATCHING_PROPERTIES_FILTER_COUNTERS,

} from 'store/actionTypes/realtorMatchingProperties';

import {
  getIds,
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  matchingProperties: {},
  matchingPropertiesByFilter: {},
  matchingPropertiesCountByFilter: {},
};

const reducers = {
  [SET_REALTOR_MATCHING_PROPERTIES]: (state, action) => ({
      ...state,
      matchingProperties: {
        ...state.matchingProperties,
        ...listToObjectByKey(action.payload),
      },
    }),

  [SET_REALTOR_MATCHING_PROPERTIES_BY_FILTER]: (state, action) => {
    const existing = state.matchingPropertiesByFilter[action.payload.filterId] || [];

    const items = [...new Set([
      ...existing,
      ...getIds(action.payload.items),
    ])];

    return {
      ...state,
      matchingPropertiesByFilter: {
        ...state.matchingPropertiesByFilter,
        [action.payload.filterId]: items,
      },
    };
  },

  [SET_REALTOR_INFINITE_SCROLL_MATCHING_PROPERTIES_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      // eslint-disable-next-line no-shadow
      matchingProperties,
      resetState,
    } = action.payload;

    const current = !resetState && state.matchingPropertiesByFilter[filterId]
      ? state.matchingPropertiesByFilter[filterId]
      : [];

    const updatedByIds = new Set([
      ...current,
      ...getIds(matchingProperties),
    ]);

    const updatedState = {
      ...state,
      matchingPropertiesByFilter: {
        ...state.matchingPropertiesByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER]: (state, action) => ({
    ...state,
    matchingPropertiesCountByFilter: {
      ...state.matchingPropertiesCountByFilter,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [RESET_REALTOR_MATCHING_PROPERTIES_BY_FILTER]: (state, action) => ({
    ...state,
    matchingPropertiesByFilter: {
      ...state.matchingPropertiesByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER]: (state, action) => ({
    ...state,
    matchingPropertiesCountByFilter: {
      ...state.matchingPropertiesCountByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_MATCHING_PROPERTIES_FILTERS]: (state, action) => ({
    ...state,
    matchingPropertiesByFilter: {},
  }),

  [RESET_REALTOR_MATCHING_PROPERTIES_FILTER_COUNTERS]: (state, action) => ({
    ...state,
    matchingPropertiesCountByFilter: {},
  }),
};

function matchingProperties(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default matchingProperties;
