import store from 'store';

import requests from 'api/requests';

import getContactById from './getContactById';

const removeContactRelations = (relationType) => (contactId) => (data) => async (dispatch) => {
  try {
    await requests.realtor.contacts.removeRelations(relationType)(contactId)(data);

    store.dispatch(getContactById(contactId));
  } catch (error) {
    console.error(error);
  }
};

export default removeContactRelations;
