import { GET_DEALS } from 'store/actions/sales';

const initialState = {
  deals: [],
};

function deals(state = initialState, action) {
  switch (action.type) {
    case GET_DEALS:
      return {
        ...state,
        deals: action.deals,
      };
    default:
      return state;
  }
}

export default deals;
