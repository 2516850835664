import { connect } from 'react-redux';

import {
  updateRepairRequest,
} from 'store/actions/realtorRepairRequests';

import SalePriceInput from 'shared/inputs/SalePriceInput';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

const mapStateToProps = (state, ownProps) => {
  const {
    estimatedValue,
  } = ownProps;

  return {
    value: estimatedValue,
    label: '',
    variant: 'standard',
    textAlign: 'left',
    disableUnderline: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateRepairRequest(ownProps.requestId)({ value }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withDebouncedCallbacks(SalePriceInput, [[
  'onChange',
  1000,
]]));
