/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import ContingencyItem from './ContingencyItem';

const Contingencies = (props) => {
  const {
    contingencies,
    control,
  } = props;

  const fields = [];

  if (contingencies.contingency_inspection) {
    fields.push(
      <Grid item xs={11}>
        <Controller
          name="contingency_inspection"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <ContingencyItem
              onChange={field.onChange}
              value={field.value}
              label="Inspection"
            />
          )}
        />
      </Grid>,
    );
  }

  if (contingencies.contingency_appraisal) {
    fields.push(
      <Grid item xs={11}>
        <Controller
          name="contingency_appraisal"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <ContingencyItem
              onChange={field.onChange}
              value={field.value}
              label="Appraisal"
            />
          )}
        />
      </Grid>,
    );
  }

  if (contingencies.contingency_loan) {
    fields.push(
      <Grid item xs={11}>
        <Controller
          name="contingency_loan"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <ContingencyItem
              onChange={field.onChange}
              value={field.value}
              label="Loan"
            />
          )}
        />
      </Grid>,
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {
          fields.length > 0
            ? (
              <Grid container spacing={1}>
                {fields}
              </Grid>
            )
            : (
              <Typography color="success">
                No contingencies
              </Typography>
            )
        }
      </Grid>
    </Grid>
  );
};

const {
  shape,
} = PropTypes;

Contingencies.propTypes = {
  control: shape().isRequired,
};

export default Contingencies;
