import { connect } from 'react-redux';
import store from 'store';

import {
  setSelectedContact,
} from 'store/actions/realtorContacts';

import Contact from './Contact';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorContacts: {
      selectedContact,
    },
  } = state;

  const { contactId } = ownProps.match.params;

  if (selectedContact !== contactId) {
    store.dispatch(setSelectedContact(contactId));
  }

  return {};
};

export default connect(mapStateToProps)(Contact);
