import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/archiveDeleteLeadModal';

import {
  updateProperty,
} from 'store/actions/properties';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

import {
  updateLead,
  deleteLead,
  optimizedRealtorLeadsActions,
  setSelectedLeadRows,
  removeLeadRelations,
} from 'store/actions/realtorLeads';

import ArchiveDeleteLeadModal from './ArchiveDeleteLeadModal';

const mapStateToProps = (state) => {
  const {
    archiveDeleteLeadModal: {
      isVisible,
      leadId,
      connectedItemType,
      connectedItemId,
      selectedAction,
    },
  } = state;

  return {
    isVisible,
    leadId,
    connectedItemType,
    connectedItemId,
    selectedAction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCancel: () => {
    dispatch(closeModal());
  },
  onArchive: (itemType, itemId, leadId) => {
    if (Array.isArray(leadId) && !itemType && !itemId) {
      leadId.forEach((id) => {
        dispatch(updateLead(id)({ status: 2 }));
      });

      dispatch(setSelectedLeadRows([]));
      return;
    }

    if (itemType === 'properties') {
      dispatch(updateProperty(itemId, leadId)({ status: 2 }));
      return;
    }
    if (itemType === 'property_requirements') {
      dispatch(updatePropertyRequirement(itemId, leadId)({ status: 2 }));
    }
  },
  onDelete: (itemType, itemId, leadId) => {
    if (Array.isArray(leadId) && !itemType && !itemId) {
      leadId.forEach((id) => {
        dispatch(deleteLead(id));
      });

      dispatch(setSelectedLeadRows([]));
      optimizedRealtorLeadsActions.getLeadsForStoreFilter();
      return;
    }

    if (itemType === 'properties') {
      dispatch(removeLeadRelations('properties')(leadId)([itemId]));
      return;
    }
    if (itemType === 'property_requirements') {
      dispatch(removeLeadRelations('propertyRequirements')(leadId)([itemId]));
    }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onCancel: dispatchProps.onCancel,
  onSave: () => {
    const {
      connectedItemType,
      connectedItemId,
      selectedAction,
      leadId,
    } = stateProps;

    if (selectedAction === 'archive') {
      dispatchProps.onArchive(connectedItemType, connectedItemId, leadId);
    }

    if (selectedAction === 'delete') {
      dispatchProps.onDelete(connectedItemType, connectedItemId, leadId);
    }

    dispatchProps.onCancel();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ArchiveDeleteLeadModal);
