import styled from 'styled-components';
import Typography from 'ui/Typography';

export const Root = styled.div`
  position: relative;
  border-top: 1px solid;
  border-color: ${(props) => props.theme?.palette?.border || 'grey'};
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: -15px;
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Title = styled(Typography)`
  margin: 0;
  padding: 0 10px;
  text-align: center;
  background-color: ${(props) => props.theme?.palette?.background || 'white'};
`;
