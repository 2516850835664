import { connect } from 'react-redux';

import {
  setNewPropertyFormField,
} from 'store/actions/newRealtorPropertyForm';

import ImagesUpload from 'shared/inputs/ImagesUpload';

const mapStateToProps = (state) => {
  const {
    realtorNewPropertyForm: {
      images,
      loading,
      mainImageIndex,
    },
  } = state;

  return {
    fileList: images,
    loading,
    multiple: true,
    mainImageIndexOverwrite: mainImageIndex,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpload: (file, fileList) => {
    const fileUrl = URL.createObjectURL(file);

    const imageObject = {
      name: file.name,
      type: file.type,
      uid: file.uid,
      url: fileUrl,
    };

    dispatch(setNewPropertyFormField('images')(fileList.concat([imageObject])));
  },
  onRemove: ({ uid, url }, fileList) => {
    URL.revokeObjectURL(url);

    const newList = fileList.filter((file) => file.uid !== uid);

    dispatch(setNewPropertyFormField('images')(newList));
  },
  onMainImageChange: ({ index }) => {
    dispatch(setNewPropertyFormField('mainImageIndex')(index));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onUpload: async ({ file }) => {
    const {
      fileList = [],
    } = stateProps;

    if (fileList.length > 4) {
      return;
    }

    dispatchProps.onUpload(file, fileList);
  },
  onRemove: ({ uid, url }) => {
    const {
      fileList = [],
    } = stateProps;

    dispatchProps.onRemove({ uid, url }, fileList);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImagesUpload);
