import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import ContactsList from 'sections/Contacts/pages/ContactsList';
import Contact from 'sections/Contacts/pages/Contact';

import NewLogModal from 'sections/Contacts/modals/NewLogModal';

import routes from 'sections/Contacts/routes';

const Contacts = (props) => (
  <>
    <Switch>
      <Route
        exact
        path={routes.sectionRoot}
        component={ContactsList}
      />
      <Route
        path={routes.contact}
        component={Contact}
      />

      <Redirect to={routes.sectionRoot} />
    </Switch>
    <NewLogModal />
  </>
);

export default Contacts;
