/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import NewAddendumChangeModal from './NewAddendumChangeModal';

const closeNewAddendumChangeModal = closeModal('newAddendumChange');

const mapStateToProps = (state) => {
  const {
    modals: {
      newAddendumChange,
    },
  } = state;

  const isVisible = newAddendumChange?.isVisible;

  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeNewAddendumChangeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAddendumChangeModal);
