/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';

const TextContent = (props) => {
  const {
    prepend,
    append,
    hideText,
    children,
  } = props;

  return (
    <>
      {prepend && <span className="button__prepend">{prepend}</span>}

      {hideText ? <span className="visually-hidden">{children}</span> : children}

      {append && <span className="button__append">{append}</span>}
    </>
  );
};

TextContent.propTypes = {
  prepend: PropTypes.node,
  append: PropTypes.node,
  hideText: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TextContent;
