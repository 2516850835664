import store from 'store';

import { uploadAttachment } from 'store/actions/attachmentsV2';

const uploadAgreementFiles = (offerId) => async (files) => {
  const uploadAttachmentsRequests = files.map(async (file) => {
    const attachmentsData = {
      file,
      objectId: offerId,
      objectType: 31,
      attachmentType: 12,
    };

    await store.dispatch(uploadAttachment(attachmentsData));
  });

  try {
    await Promise.all(uploadAttachmentsRequests);
  } catch (error) {
    console.error(error);
  }
};

export default uploadAgreementFiles;
