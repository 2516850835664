import { connect } from 'react-redux';

import moment from 'moment';

import { map } from 'shared/utility';

import selectors from 'store/selectors';

import InspectionsSelect from './InspectionsSelect';

const getOptions = (list = [], types = {}) => list.map((item) => ({
    id: item.id,
    name: `${types[item.inspection_type_id]}: ${moment(item.date_time).format('MMM DD, YYYY')}`,
  }));

const mapStateToProps = (state) => {
  const {
    filterId,
  } = selectors.realtorInspections.inspectionsFilter(state);

  const inspectionsList = selectors.realtorInspections.inspectionsList(state, filterId);

  const types = selectors.realtorInspections.inspectionTypes(state) || [];

  const options = getOptions(inspectionsList, types?.reduce((acc, item) => {
    acc[item.id] = item.name;
    return acc;
  }, {}));

  return {
    options: map(options, (item) => (
      {
        value: item.id,
        label: item.name,
      }
    )),
  };
};

export default connect(mapStateToProps)(InspectionsSelect);
