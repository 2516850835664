import connectReducers from 'store/connectReducers';

import {
  SET_NOTABLE_POINT,
  SET_NOTABLE_POINTS,

  SET_DISCLOSURE,
  SET_DISCLOSURES,
} from 'store/actionTypes/realtor-notable-points-disclosures';

import {
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  notablePoints: {},
  disclosures: {},
};

const reducers = {
  [SET_NOTABLE_POINT]: (state, action) => ({
    ...state,
    notablePoints: {
      ...state.notablePoints,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_NOTABLE_POINTS]: (state, action) => ({
    ...state,
    notablePoints: {
      ...state.notablePoints,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_DISCLOSURE]: (state, action) => ({
    ...state,
    disclosures: {
      ...state.disclosures,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_DISCLOSURES]: (state, action) => ({
    ...state,
    disclosures: {
      ...state.disclosures,
      ...listToObjectByKey(action.payload),
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
