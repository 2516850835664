import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

const tutorialsToPermissions = [
  {
    tutorial: 'hr',
    permission: 'HR',
  },
  {
    tutorial: 'tickets',
    permission: 'Customer Support',
  },
  {
    tutorial: 'sales',
    permission: 'Sales',
  },
  {
    tutorial: 'tasks',
    permission: 'Tasks',
  },
  {
    tutorial: 'general',
    permission: 'Company Settings',
  },
];

const filterTutorials = () => {
  let availableTutorials = []; // eslint-disable-line prefer-const
  tutorialsToPermissions.forEach((element) => {
    if (element.tutorial === 'general') {
      if (checkLoggedEmployeePermissions.isManager('HR') || checkLoggedEmployeePermissions.isOwner()) {
        availableTutorials.push(element.tutorial);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (checkLoggedEmployeePermissions.isUser(element.permission)) {
        availableTutorials.push(element.tutorial);
      }
    }
  });
  return availableTutorials;
};


export default filterTutorials;
