import requests from 'api/requests';

import setDisclosure from './setDisclosure';

const getDisclosureById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.disclosures.getById(id);
    dispatch(setDisclosure(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getDisclosureById;
