// export const SET_NEW_REALTOR_OPPORTUNITY_MODAL_OPEN = 'SET_NEW_REALTOR_OPPORTUNITY_MODAL_OPEN';
// export const SET_NEW_REALTOR_OPPORTUNITY_MODAL_CLOSED = 'SET_NEW_REALTOR_OPPORTUNITY_MODAL_CLOSED';

// // eslint-disable-next-line max-len
// export const SET_NEW_REALTOR_OPPORTUNITY_MODAL_PROPERTY_REQUIREMENT = 'SET_NEW_REALTOR_OPPORTUNITY_MODAL_PROPERTY_REQUIREMENT';
// export const SET_NEW_REALTOR_OPPORTUNITY_MODAL_ATTACHMENTS = 'SET_NEW_REALTOR_OPPORTUNITY_MODAL_ATTACHMENTS';

export const SET_REALTOR_OPPORTUNITY = 'SET_REALTOR_OPPORTUNITY';
export const SET_REALTOR_OPPORTUNITIES = 'SET_REALTOR_OPPORTUNITIES';

export const SET_REALTOR_OPPORTUNITIES_FILTER_ITEM = 'SET_REALTOR_OPPORTUNITIES_FILTER_ITEM';
export const RESET_REALTOR_OPPORTUNITIES_FILTER_ITEM = 'RESET_REALTOR_OPPORTUNITIES_FILTER_ITEM';
export const CLEAR_REALTOR_OPPORTUNITIES_FILTER = 'CLEAR_REALTOR_OPPORTUNITIES_FILTER';

export const SET_REALTOR_OPPORTUNITIES_FOR_FILTER = 'SET_REALTOR_OPPORTUNITIES_FOR_FILTER';
export const SET_REALTOR_OPPORTUNITIES_FOR_FILTER_COUNT = 'SET_REALTOR_OPPORTUNITIES_FOR_FILTER_COUNT';

export const SET_REALTOR_OPPORTUNITIES_OVERALL_SUMMARY = 'SET_REALTOR_OPPORTUNITIES_OVERALL_SUMMARY';

//
export const SET_NEW_COUNTER_MODAL_OPEN = 'SET_NEW_COUNTER_MODAL_OPEN';
export const SET_NEW_COUNTER_MODAL_CLOSED = 'SET_NEW_COUNTER_MODAL_CLOSED';
export const SET_NEW_COUNTER_MODAL_ATTACHMENTS = 'SET_NEW_COUNTER_MODAL_ATTACHMENTS';

export const SET_ACCEPT_COUNTER_MODAL_CLOSED = 'SET_ACCEPT_COUNTER_MODAL_CLOSED';
export const SET_ACCEPT_COUNTER_MODAL_OPEN = 'SET_ACCEPT_COUNTER_MODAL_OPEN';
export const SET_ACCEPT_COUNTER_MODAL_ATTACHMENTS = 'SET_ACCEPT_COUNTER_MODAL_ATTACHMENTS';

export const SET_ACCEPT_COUNTER_FORM_STATE = 'SET_ACCEPT_COUNTER_FORM_STATE';
export const RESET_ACCEPT_COUNTER_FORM_STATE = 'RESET_ACCEPT_COUNTER_FORM_STATE';
export const SET_ACCEPT_COUNTER_FORM_ATTACHMENTS = 'SET_ACCEPT_COUNTER_FORM_ATTACHMENTS';

export const SET_LOG_OFFER_MODAL_OPEN = 'SET_LOG_OFFER_MODAL_OPEN';
export const SET_LOG_OFFER_MODAL_CLOSE = 'SET_LOG_OFFER_MODAL_CLOSE';

export const SET_LOG_OFFER_FORM_STATE = 'SET_LOG_OFFER_FORM_STATE';
export const RESET_LOG_OFFER_FORM_STATE = 'RESET_LOG_OFFER_FORM_STATE';
export const SET_LOG_OFFER_FORM_ATTACHMENTS = 'SET_LOG_OFFER_FORM_ATTACHMENTS';

export const SET_OFFER_DECLINE_REASON_MODAL_CLOSE = 'SET_OFFER_DECLINE_REASON_MODAL_CLOSE';
export const SET_OFFER_DECLINE_REASON_MODAL_OPEN = 'SET_OFFER_DECLINE_REASON_MODAL_OPEN';

export const SET_SEARCH_MLS_MODAL_CLOSE = 'SET_SEARCH_MLS_MODAL_CLOSE';
export const SET_SEARCH_MLS_MODAL_OPEN = 'SET_SEARCH_MLS_MODAL_OPEN';
export const SET_MLS_MODAL_SEARCH_DATA = 'SET_MLS_MODAL_SEARCH_DATA';

export const SET_ASSIGN_INTERESTED_PARTY_MODAL_OPEN = 'SET_ASSIGN_INTERESTED_PARTY_MODAL_OPEN';
export const SET_ASSIGN_INTERESTED_PARTY_MODAL_CLOSED = 'SET_ASSIGN_INTERESTED_PARTY_MODAL_CLOSED';
