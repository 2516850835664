const allowedImageFormats = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'bmp',
];

const allowedVideoFormats = [
  'mp4',
  'webm',
];

const allowedFormats = {
  all: [
    ...allowedImageFormats,
    ...allowedVideoFormats,
    'pdf',
    'csv',
    'docx',
    'doc',
  ],
  image: allowedImageFormats,
  video: allowedVideoFormats,
};

const isAllowedFormat = (type) => (attachment) => {
  if (!allowedFormats[type]) return false;

  const fileFormat = attachment.name.slice(attachment.name.lastIndexOf('.') + 1);

  return allowedFormats[type].includes(fileFormat);
};

export default isAllowedFormat;
