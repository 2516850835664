/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import CurrencyInput from 'ui/CurrencyInput';

const OfferAmount = (props) => {
  const {
    control,
    checkEquality,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'offerAmount',
    control,
  });

  const handleChange = (val) => {
    onChange(val);
    checkEquality('amount', val);
  };

  return (
    <CurrencyInput
      label="Purchase Price"
      onChange={handleChange}
      value={value}
    />
  );
};

const {
  shape,
  func,
} = PropTypes;

OfferAmount.propTypes = {
  control: shape({}).isRequired,
  checkEquality: func.isRequired,
};

export default OfferAmount;
