import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import withInlineLabel from 'shared/hocs/withInlineLabelV2';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import Attachments from 'shared/AttachmentsV4';

const Field = withInlineLabel(Typography);

const OfferDetailsMainInformation = (props) => {
  const {
    closingDate,
    contigencyPeriod,
    offerId,
    contingencies,
    specialConditions,
    moveOutDate,
    openNotesModal,
    hasNote,
    shouldShowAttachments,
  } = props;

  return (
    <Flex
      spacing={4}
      flexWrap="wrap"
    >
      <Field
        label="Closing Date:"
        variant="title4"
        tag="span"
        color="main-text"
        labelProps={{
            variant: 'title4',
            weight: 'bold',
            noWrap: true,
            noMargin: true,
          }}
        noMargin
        noWrap
      >
        {`${moment(closingDate).format('MMMM D, YYYY')} `}
        <Typography
          label="Closing Date:"
          variant="title4"
          tag="span"
          color={moment(closingDate).diff(moment(), 'days') > 0 ? 'success' : 'alert'}
          noMargin
        >
          {`(${moment(closingDate).diff(moment(), 'days')})`}
        </Typography>
      </Field>

      <Field
        variant="title4"
        color="main-text"
        label="Contigency Period:"
        labelProps={{
            variant: 'title4',
            weight: 'bold',
            noWrap: true,
            noMargin: true,
          }}
        noMargin
      >
        {`${moment(contigencyPeriod).diff(moment(), 'days')} days`}
      </Field>

      <Field
        variant="title4"
        color="main-text"
        label="Contigencies:"
        labelProps={{
            variant: 'title4',
            weight: 'bold',
            noWrap: true,
            noMargin: true,
          }}
        noMargin
      >
        {contingencies.length > 0 ? contingencies.join(', ') : '—'}
      </Field>

      <Field
        variant="title4"
        color="main-text"
        label="Special Conditions:"
        labelProps={{
            variant: 'title4',
            weight: 'bold',
            noWrap: true,
            noMargin: true,
          }}
        noMargin
      >
        {specialConditions.length > 0 ? specialConditions.join(', ') : '—'}
      </Field>

      <Field
        variant="title4"
        color="main-text"
        label="Move Out Date:"
        labelProps={{
            variant: 'title4',
            weight: 'bold',
            noWrap: true,
            noMargin: true,
          }}
        noMargin
      >
        {moveOutDate ? moment(moveOutDate).format('MMMM D, YYYY') : '—'}
      </Field>

      <Flex fullWidth justifyContent="space-between">

        {
          shouldShowAttachments && (
            <FlexItem xs={20}>
              <Typography
                variant="title4"
                weight="bold"
              >
                Offer & Signature
              </Typography>
              <Attachments
                objectId={offerId}
                objectType={31}
                attachmentType={12}
              />
            </FlexItem>
          )
        }

        <FlexItem xs={2}>
          <Typography
            variant="title4"
            weight="bold"
          >
            Notes
          </Typography>

          <Button
            onClick={openNotesModal}
            icon="file"
            variant={hasNote ? 'primary' : 'tertiary'}
          />
        </FlexItem>

      </Flex>

    </Flex>
  );
};

OfferDetailsMainInformation.defaultProps = {
  contingencies: [],
  specialConditions: [],
  hasNote: false,
  shouldShowAttachments: true,
};

const {
  string,
  arrayOf,
  bool,
  number,
  func,
} = PropTypes;

OfferDetailsMainInformation.propTypes = {
  closingDate: string.isRequired,
  moveOutDate: string.isRequired,
  contigencyPeriod: string.isRequired,
  contingencies: arrayOf(string),
  specialConditions: arrayOf(string),
  offerId: number.isRequired,
  hasNote: bool,
  openNotesModal: func.isRequired,
  shouldShowAttachments: bool,
};

export default OfferDetailsMainInformation;
