import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import ObjectSelector from './ObjectSelector';
import NewReminderFields from './NewReminderFields';

import {
  Wrapper,
} from './styledItems';

const ObjectRemindersForm = (props) => {
  const {
    setReminderFields,
    createReminder,
    // createdReminders,
    reminderForm,
    onObjectSelect,
    disableDateSelection,
    draftHandlers,
    selectedObjectTypeId,
    selectedObjectId,
  } = props;

  return (
    <form>
      <Wrapper>
        <Grid container spacing={2}>
          {
            onObjectSelect && (
              <Grid item xs={12}>
                <ObjectSelector
                  onSelect={onObjectSelect}
                  selectedObjectTypeId={selectedObjectTypeId}
                  selectedObjectId={selectedObjectId}
                />
              </Grid>
            )
          }

          <Grid item xs={12}>
            <NewReminderFields
              setReminderFields={setReminderFields}
              reminderForm={reminderForm}
              // createdReminders={createdReminders}
              createReminder={createReminder}
              disableDateSelection={disableDateSelection}
              draftHandlers={draftHandlers}
            />
          </Grid>

        </Grid>
      </Wrapper>
    </form>
  );
};

ObjectRemindersForm.defaultProps = {
  draftHandlers: null,
  selectedObjectTypeId: null,
  selectedObjectId: null,
};

const {
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string,
} = PropTypes;

ObjectRemindersForm.propTypes = {
  reminders: arrayOf({
    id: number,
    title: string,
    comment: string,
    scheduled_date: string,
    is_repeat: bool,
    status: number,
  }).isRequired,
  objectTitle: string.isRequired,
  setReminderFields: func.isRequired,
  onDeleteReminder: func.isRequired,
  createReminder: func.isRequired,
  reminderForm: shape({
    timeIntervalType: number,
    timeIntervalValue: number,
    isRepeat: bool,
    scheduledDate: string,
    title: string,
    comment: string,
  }).isRequired,
  createdReminders: arrayOf(object).isRequired, // eslint-disable-line react/forbid-prop-types
  draftHandlers: shape({
    draftData: shape({}),
    saveDraft: func,
    clearDraft: func,
  }),
  selectedObjectTypeId: number,
  selectedObjectId: number,
};

export default ObjectRemindersForm;
