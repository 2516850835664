import get from './get';
import list from './list';
import create from './create';
import remove from './remove';
import update from './update';
import duplicate from './duplicate';
import getSummary from './getSummary';
import getSampleMessage from './getSampleMessage';
import actions from './actions';

export default {
  get,
  list,
  create,
  remove,
  update,
  duplicate,
  getSummary,
  getSampleMessage,
  actions,
};
