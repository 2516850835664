/* eslint-disable react/require-default-props */

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { meetingType, meetingParticipantType } from 'oa/types/index';
import Toast from 'oa/components/Toast/index';
import copyTextToClipboard from 'oa/lib/copyTextToClipboard';
// import createPopupCoords from 'oa/lib/createPopupCoords';
import RoomActions from 'oa/components/RoomActions';
import useOnClickOutside from 'oa/hooks/useOnClickOutside';
import {
  optimizedGetEmployees,
} from 'store/actions/MyCompany/common';
import {
  getMe,
} from 'store/actions/auth';
import OACallContext from 'components/OACall/OACallContext';
import { meetingPath } from 'oa/helpers/routes';
import InviteUsersModal from './InviteUsersModal';

// TODO: create dropdown component for this logic.
const MoreActions = (props) => {
  const {
    presentScreen,
    openMeetingDetails,
    openMeetingAgenda,
    meeting,
    isScreenPresenting,
    meetingParticipants,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dropdownBodyCoords, setDropdownBodyCoords] = useState({});
  const [isRoomLinkCopied, setIsRoomLinkCopied] = useState(false);
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
  const dropdownBody = useRef(null);
  const togglerRef = useRef(null);
  const currentUser = useSelector((state) => state.auth.loggedUser);
  const dispatch = useDispatch();
  const meetingLink = `${window.location.host}${meetingPath(meeting.code)}`;

  const handleOutsideClick = useCallback((event) => {
    if (togglerRef.current.contains(event.target)) return;
    setIsDropdownOpen(false);
  }, [togglerRef]);

  useOnClickOutside(dropdownBody, handleOutsideClick);

  const handleToastClose = useCallback(() => {
    setIsRoomLinkCopied(false);
  }, []);

  // TODO: if parent of dropdownBody changes coords, create popup coords again
  // useEffect(() => {
  //   const coords = createPopupCoords(dropdownBody.current);
  //   setDropdownBodyCoords(coords);
  // }, [dropdownBody]);

  useEffect(() => {
    if (!currentUser) {
      try {
        dispatch(getMe());
        optimizedGetEmployees();
      } catch (error) {
        console.error('An error occured while requesting user', error);
      }
    }
  }, [currentUser, dispatch]);

  const openInviteUsersModal = useCallback(() => {
    setIsInviteUsersModalOpen(true);
  }, []);

  const closeInviteUsersModal = useCallback(() => {
    setIsInviteUsersModalOpen(false);
  }, []);

  const copyRoomLink = useCallback(() => {
    copyTextToClipboard(meetingLink);
    setIsRoomLinkCopied(true);
  }, [meetingLink]);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((state) => !state);
  }, []);

  const toggleFullscreen = useCallback(() => {
    const isFullscreenAvailable = document.fullscreenEnabled;

    if (!isFullscreenAvailable) return;

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }, []);

  const renderInviteUsersModal = useCallback((modalProps) => (
    <InviteUsersModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
      isVisible={isInviteUsersModalOpen}
      onClose={closeInviteUsersModal}
      meeting={meeting}
      meetingParticipants={meetingParticipants}
    />
  ), [
    isInviteUsersModalOpen,
    closeInviteUsersModal,
    meeting,
    meetingParticipants,
  ]);

  return (
    <RoomActions.Item
      className="room-more-actions"
      icon="info-circle"
      allyText="See more actions"
      onClick={toggleDropdown}
      btnRef={togglerRef}
    >
      <div
        ref={dropdownBody}
        className={classNames('room-more-actions__body', {
          'room-more-actions__body_opened': isDropdownOpen,
        })}
        style={dropdownBodyCoords}
      >
        <ul className="room-more-actions__list">
          <li className="room-more-actions__item">
            <button
              type="button"
              className="room-more-actions__btn"
              onClick={copyRoomLink}
            >
              Copy meeting link
            </button>

            <a
              className="room-more-actions__room-link"
              href={meetingLink}
            >
              {meetingLink}
            </a>

            <Toast isActive={isRoomLinkCopied} onClose={handleToastClose}>
              Copied.
            </Toast>
          </li>
          <li
            className="room-more-actions__item room-more-actions__item_separator"
            role="separator"
          />

          {
            currentUser && (
              <li className="room-more-actions__item">
                <button
                  type="button"
                  className="room-more-actions__btn"
                  onClick={openInviteUsersModal}
                >
                  Call Another User
                </button>

                <OACallContext.Consumer>
                  {renderInviteUsersModal}
                </OACallContext.Consumer>
              </li>
            )
          }

          {
            !isScreenPresenting && (
              <li className="room-more-actions__item">
                <button
                  type="button"
                  className="room-more-actions__btn"
                  onClick={presentScreen}
                >
                  Present screen
                </button>
              </li>
            )
          }
          <li className="room-more-actions__item">
            <button
              type="button"
              className="room-more-actions__btn"
              onClick={openMeetingAgenda}
            >
              Notes and Agenda
            </button>
          </li>
          <li className="room-more-actions__item">
            <button
              type="button"
              className="room-more-actions__btn"
              onClick={openMeetingDetails}
            >
              Meeting Details
            </button>
          </li>
          <li
            className="room-more-actions__item room-more-actions__item_separator"
            role="separator"
          />
          <li className="room-more-actions__item">
            <button
              type="button"
              className="room-more-actions__btn"
              onClick={toggleFullscreen}
            >
              Full Screen
            </button>
          </li>
        </ul>
      </div>
    </RoomActions.Item>
  );
};

MoreActions.propTypes = {
  presentScreen: PropTypes.func.isRequired,
  openMeetingDetails: PropTypes.func.isRequired,
  openMeetingAgenda: PropTypes.func.isRequired,
  meeting: meetingType.isRequired,
  isScreenPresenting: PropTypes.bool.isRequired,
  meetingParticipants: PropTypes.arrayOf(meetingParticipantType).isRequired,
};

export default MoreActions;
