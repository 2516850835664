import socket from 'shared/sockets/OASocket';

const shareActiveMeeting = (activeMeeting) => {
  socket.emit('call-service-message', {
    to: activeMeeting.code,
    activeMeeting,
  });
};

export default shareActiveMeeting;
