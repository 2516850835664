import requests from 'api/requests';

import setTicketsSummaryForEmployee from './setTicketsSummaryForEmployee';

const getEmployeeTicketsSummary = (employeeId) => async (dispatch) => {
  try {
    const summaryResponse = await requests.tickets.getTicketsSummary('employee')(employeeId);

    dispatch(setTicketsSummaryForEmployee(employeeId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getEmployeeTicketsSummary;
