import apiConnector, { endpoints } from 'api';

const getArticle = async (articleId) => {
  const endpoint = `${endpoints.client.articles.articleGeneral}/${articleId}`;

  const result = await apiConnector.client.get(endpoint);

  return result.data;
};

export default getArticle;
