import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import Icon from 'ui/Icon';

import {
  Root,
  AddActionButton,
  MainActionButton,
} from './styledItems';

const CounterItemWithButton = (props) => {
  const {
    title,
    value,
    onMainAreaClick,
    onAddButtonClick,
    withActionButton,
  } = props;

  return (
    <Root>
      <MainActionButton
        onClick={onMainAreaClick}
      >
        <Typography
          variant="h2"
          tag="p"
          weight={500}
          className="information-text"
          noMargin
        >
          {value}
        </Typography>

        <Typography
          variant="h6"
          weight={300}
          color="descriptor-text"
          className="information-title-text"
          noMargin
        >
          {title}
        </Typography>
      </MainActionButton>

      {
        withActionButton && (
          <AddActionButton
            onClick={onAddButtonClick}
          >
            <Icon
              name="plus"
              size="lg"
              color="focus"
            />
          </AddActionButton>
        )
      }
    </Root>
  );
};

const noOp = () => {};

CounterItemWithButton.defaultProps = {
  onMainAreaClick: noOp,
  onAddButtonClick: noOp,
  withActionButton: false,
};

const {
  bool,
  func,
  number,
  string,
} = PropTypes;

CounterItemWithButton.propTypes = {
  title: string.isRequired,
  value: number.isRequired,
  onAddButtonClick: func,
  withActionButton: bool,
  onMainAreaClick: func,
};

export default CounterItemWithButton;
