import requests from 'api/requests';

import setTicketsSummaryForQueue from './setTicketsSummaryForQueue';

const getQueueTicketsSummary = (queueId) => async (dispatch) => {
  try {
    const summaryResponse = await requests.tickets.getTicketsSummary('queue')(queueId);

    dispatch(setTicketsSummaryForQueue(queueId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getQueueTicketsSummary;
