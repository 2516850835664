// TODO - fix dependency cycles
import { getConnector, apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import { LICENSE_ACTIONS, SYMPLETE_ID } from 'constants/index';
import { callAlert } from 'ui/Alert';

const base = '/company';

class LicenseRepository {
  static getLicenses = () => getConnector(`${base}/licenses`);

  static addLicense = async (employeeId, licenseId, isFreeLicense, isShowMessage) => {
    try {
      await apiConnector(`${base}/employees/employees2license`, 'POST', {
        EmployeeID: employeeId,
        LicenceID: licenseId,
        isFreeLicense,
      });

      if (isShowMessage) {
        callAlert.success('License successfully added to user!');
      }
    } catch (error) {
      callAlert.error('There was an error adding the user!');
    }
  }

  // WTF??? Why does this method accept both a number and an object as employeeId?
  static removeLicense = async (employeeId, licenseId) => {
    try {
      await apiConnector(`${base}/employees2license`, 'DELETE', {
        EmployeeID: employeeId,
        LicenceID: licenseId,
      });
      callAlert.success('License successfully removed from user!');
    } catch (error) {
      callAlert.error('There was an error removed the user!');
    }
  }

  static buyLicense = (licenses, card) => apiConnector(`${base}/license/buy`, 'POST', { licenses, card });

  /**
   *
   * @param {Number} employeeId
   * @param {Number} licenceID
   * @param {UserRole | ManagerRole} [roleName] - Name of the field from DB table
   * @return {Promise<*>}
   */
  static updateRole = (employeeId, licenceID, roleName) => apiConnector(`${base}/userrole`, 'POST', {
    employeeId,
    licenceID,
    roleName,
  });

  static removeLicenses = (employee) => {
    const clearLicenses = employee.Licenses.map(
      ({ LicenceID }) => LicenseRepository.removeLicense(employee.EmployeeID, LicenceID),
    );

    return Promise.all(clearLicenses);
  };

  /* static removeLicenseRole = (employee, UserRole) => {
    let role = employee.UserRole;

    const removeRole = PERM_ROLES.map(binary => {
      if (role & binary) {
        role -= binary;

        return LicenseRepository.updateRole(employee.EmployeeID, role);
      }

      return undefined;
    });

    return Promise.all(removeRole);
  }; */

  static setLicense = async (employee, license, type, isShowMessage) => {
    const id = employee.EmployeeID;

    const isSympleteLicense = license.id === SYMPLETE_ID;
    const isFreeLicense = license.availableFree > 0;

    if (isSympleteLicense) {
      switch (type) {
        case LICENSE_ACTIONS.EMPTY: {
          await LicenseRepository.removeLicenses(employee);
          break;
        }
        case LICENSE_ACTIONS.LICENSE: {
          await LicenseRepository.removeLicenses(employee);
          await LicenseRepository.addLicense(id, license.id, isFreeLicense, isShowMessage);
          break;
        }
        default:
          break;
      }
      return;
    }

    const hasSympleteLicense = employee.Licenses.find(
      ({ LicenceID }) => LicenceID === SYMPLETE_ID,
    );

    if (hasSympleteLicense) {
      switch (type) {
        case LICENSE_ACTIONS.EMPTY: {
          await LicenseRepository.updateRole(id, license.id);
          break;
        }
        case LICENSE_ACTIONS.LICENSE: {
          await LicenseRepository.updateRole(id, license.id, 'UserRole');
          break;
        }
        case LICENSE_ACTIONS.PM_ROLE: {
          await LicenseRepository.updateRole(id, license.id, 'ManagerRole');
          break;
        }
        default:
          break;
      }
      return;
    }

    switch (type) {
      case LICENSE_ACTIONS.EMPTY: {
        await LicenseRepository.removeLicense(id, license.id);
        break;
      }
      case LICENSE_ACTIONS.LICENSE: {
        await LicenseRepository.addLicense(id, license.id, isFreeLicense);
        break;
      }
      case LICENSE_ACTIONS.PM_ROLE: {
        await LicenseRepository.updateRole(id, license.id, 'ManagerRole');
        break;
      }
      case LICENSE_ACTIONS.EX_ROLE: {
        await LicenseRepository.updateRole(id, license.id, 'ExecutiveRole');
        break;
      }
      default:
        break;
    }
  };
}

export default LicenseRepository;
