export const SET_NOTABLE_POINT = 'SET_NOTABLE_POINT';
export const SET_NOTABLE_POINTS = 'SET_NOTABLE_POINTS';

export const SET_DISCLOSURE = 'SET_DISCLOSURE';
export const SET_DISCLOSURES = 'SET_DISCLOSURES';

export default {
  SET_NOTABLE_POINT,
  SET_NOTABLE_POINTS,

  SET_DISCLOSURE,
  SET_DISCLOSURES,
};
