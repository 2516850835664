import {
  GET_EMPLOYEE_NOTES,
  SET_EMPLOYEE_DETAILS,
  SET_EMPLOYEE_ACTIVITY,
  CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL,
  CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR,
  SHOW_DELETING_EMPLOYEE_WARNING,
  HIDE_DELETING_EMPLOYEE_WARNING,
  DELETE_EMPLOYEE_ACTIONS,
} from 'store/actionTypes/employeeDetails';

const initialState = {
  notes: [],
  EmployeeID: 0,
  activity: [],
  isDeletingEmployeeWarningModalVisible: false,
  deletingEmployeeInformation: {},
};

function employeeDetails(state = initialState, action) {
  switch (action.type) {
    case DELETE_EMPLOYEE_ACTIONS: {
      return {
        ...state,
      };
    }

    case SHOW_DELETING_EMPLOYEE_WARNING: {
      return {
        ...state,
        isDeletingEmployeeWarningModalVisible: true,
        deletingEmployeeInformation: action.deletingEmployeeInformation,
      };
    }

    case HIDE_DELETING_EMPLOYEE_WARNING: {
      return {
        ...state,
        isDeletingEmployeeWarningModalVisible: false,
      };
    }

    case CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR: {
      return {
        ...state,
      };
    }

    case CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL: {
      return {
        ...state,
      };
    }

    case GET_EMPLOYEE_NOTES: {
      return {
        ...state,
        notes: action.payload,
      };
    }

    case SET_EMPLOYEE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_EMPLOYEE_ACTIVITY: {
      return {
        ...state,
        activity: action.payload,
      };
    }

    default:
      return state;
  }
}

export default employeeDetails;
