/* eslint-disable */
// FlySoft
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors - and refactor

import {
  Form,
  Row,
} from 'antd';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Selection from 'ui/Selection';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import { isEmpty } from 'shared/utility';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormTextfield from 'shared/inputs/FormTextfield';
import Typography from 'ui/Typography';
import DropdownTextfield from 'ui/DropdownTextfield';
import Textfield from 'ui/Textfield';

import './LoggingContactModal.scss';

const rootClass = 'logging-contact';

const contactTypes = {
  email: 1,
  message: 2,
  call: 3,
  'in person': 4,
}


const buttons = [
  {
    id: contactTypes.email,
    label: 'Email',
  },
  {
    id: contactTypes.message,
    label: 'Message',
  },
  {
    id: contactTypes.call,
    label: 'Call',
  },
  {
    id: contactTypes['in person'],
    label: 'In Person',
  },
];

const sentimentOptions = [
  {
    value: 1,
    label: 'Positive',
  },
  {
    value: 2,
    label: 'Neutral',
  },
  {
    value: 3,
    label: 'Negative',
  },
]


const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 28 },
    sm: { span: 18 },
  },
  labelAlign: 'left',
};

const initialState = {
  employeeId: null,
  createdAt: moment(),
  duration: {
    hours: 0,
    minutes: 0
  },
  sentiment: 1,
  contactType: contactTypes.email,
  contactLogId: 0,
  notes: '',
  title: '',
};

const LoggingContactForm = Form.create()(
  class extends Component {
    constructor(props) {
      super(props)
      this.state = initialState
    }

    componentDidMount() {
      const { data } = this.props;

      if (!isEmpty(data)) {
        this.setState({
          contactLogId: data.LogID,
          employeeId: data.CreatorID,
          duration: data.Duration,
          createdAt: data.CreatedAt,
          contactType: data.ContactType,
          sentiment: data.Sentiment,
          notes: data.Notes,
          title: data.Title,
        });
      }
    }

    componentDidUpdate(prevProps) {
      const { data } = this.props;

      if (prevProps.data !== data && !isEmpty(data)) {
        this.setState({
          contactLogId: data.LogID,
          employeeId: data.CreatorID,
          duration: data.Duration,
          createdAt: data.CreationTime,
          contactType: data.ContactType,
          sentiment: data.Sentiment,
          notes: data.Notes,
          title: data.Title,
        });
      }
    }

    resetFields = () => {
      this.setState(initialState);
    }

    handleCancel = () => {
      const {
        handleLoggingContactModal,
        form,
      } = this.props;

      form.resetFields();
      this.resetFields();
      handleLoggingContactModal();
    }

    handleSubmit = (event) => {
      event.preventDefault();
      const {
        updateContactLog,
        form,
        handleLoggingContactModal,
      } = this.props;

      const {
        createdAt,
        duration,
        sentiment,
        contactType,
        // contactLogId,
      } = this.state;

      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const contactLog = {
            CreatedAt: createdAt,
            Duration: (duration.hours * 3600) + (duration.minutes * 60),
            Sentiment: sentiment,
            ContactType: contactType,
            Notes: values.notes,
            Title: values.title,
          }

          updateContactLog(contactLog);
          form.resetFields();
          this.resetFields();
          handleLoggingContactModal();
        }
      });
    }

    onTypeChange = (value) => {
      this.setState({
        leadType: value,
      });
    }

    onContactTypeChange = (value) => {
      this.setState({
        contactType: value,
      });
    }

    onChangeCreationTime = (createdAt) => this.setState({ createdAt });

    onDurationChange = (type) => (value) => {
      const {
        duration
      } = this.state
      this.setState({
        duration: {
          ...duration,
          [type]: value
        }
      })
    };

    selectSentiment = (sentiment) => {
      this.setState({ sentiment });
    }

    render() {
      const {
        form: {
          getFieldDecorator,
        },
        isSalesModal,
      } = this.props;

      const {
        contactType,
        sentiment,
        duration,
        createdAt,
        notes,
        title,
      } = this.state;

      return (
        <Form {...formItemLayout} hideRequiredMark className="form-body">
          <div className={`${rootClass}__field`}>
            <Row>
              <Typography variant="title3">
                What
              </Typography>
              {getFieldDecorator('title', {
                initialValue: title,
              })(<FormTextfield
                value={title}
                label="Enter contact event title"
              />)}
            </Row>

            <Row>
              <Typography variant="title3">Type</Typography>
              <Selection
                value={contactType}
                onChange={this.onContactTypeChange}
                radio
                options={buttons}
              />
            </Row>

            <Row>
              <Typography variant="title3">When</Typography>
              <DateTimeInput
                value={createdAt}
                label="Select Date"
                handleChange={this.onChangeCreationTime}
              />
            </Row>

            <Row>
              <Typography variant="title3">Duration</Typography>
              <Flex spacing={3}>
                <Flex
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    variant="body"
                    noMargin
                  >
                    Hours:
                  </Typography>
                  <Textfield
                    autoWidth
                    min={0}
                    type="number"
                    value={duration.hours}
                    onChange={this.onDurationChange('hours')}
                  />
                </Flex>
                <Flex
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    variant="body"
                    noMargin
                  >
                    Minutes:
                  </Typography>
                  <Textfield
                    autoWidth
                    min={0}
                    type="number"
                    value={duration.minutes}
                    onChange={this.onDurationChange('minutes')}
                  />
                </Flex>

              </Flex>
            </Row>

            <Row>
              <Typography variant="title3">Sentiment</Typography>
              <DropdownTextfield
                value={sentiment}
                options={sentimentOptions}
                onChange={this.selectSentiment}
              />
            </Row>

            <Row>
              <Typography variant="title3">Description</Typography>
              {
                getFieldDecorator('notes', {
                  initialValue: notes,
                })(
                  <Textfield
                    label="Write the meeting notes or paste an email"
                    value={notes}
                    textarea
                  />
                )
              }
            </Row>
          </div>
          <Flex
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              onClick={this.handleCancel}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSubmit}
            >
              Yes
            </Button>
          </Flex>
        </Form>
      );
    }
  },
);

export default class LoggingContactModal extends React.Component {
  handleCancel = () => {
    const {
      handleLoggingContactModal,
    } = this.props;

    handleLoggingContactModal();
  }

  render() {
    const {
      isModalVisible,
      handleLoggingContactModal,
      updateContactLog,
      contactLogId,
      data,
      isSalesModal,
    } = this.props;

    return (
      <Modal
        title="Log Contact"
        visible={isModalVisible}
        footer={null}
        onCancel={this.handleCancel}
      >
        <Typography variant="subtitle1">
          Logging a contact will post it into the comments section of this item
        </Typography>
        <LoggingContactForm
          handleLoggingContactModal={handleLoggingContactModal}
          updateContactLog={updateContactLog}
          contactLogId={contactLogId}
          data={data}
          isSalesModal={isSalesModal}
        />
      </Modal>
    );
  }
}

LoggingContactModal.defaultProps = {
  className: '',
  companies: [],
  assignedSalesperson: [],
  employees: [],
  data: {},
};

const {
  string,
  object,
  func,
} = PropTypes;

LoggingContactModal.propTypes = {
  className: string,
  updateContactLog: func.isRequired,
  handleLoggingContactModal: func.isRequired,
  onClose: func.isRequired,
  data: object,
};
