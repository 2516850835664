import React from 'react';

import withPlayer from 'shared/hocs/withPlayer';

import PlayerItem from './PlayerItem';

const Item = (props) => {
  const {
    name,
    surname,
    personTitle,
    avatar,
  } = props;

  const surnameFirstLetter = surname ? surname.split('')[0] || '' : '';

  return (
    <PlayerItem
      {...props}
      name={`${personTitle} ${name} ${surnameFirstLetter}${surnameFirstLetter ? '.' : ''}`.trim()}
      hasAvatar={!!avatar}
    />
  );
};

export default withPlayer(Item);
