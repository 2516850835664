import {
  SET_CALL_LOGS_FILTER_ITEM,
} from 'store/actionTypes/calls';

const setFilterItem = (key) => (value) => ({
  type: SET_CALL_LOGS_FILTER_ITEM,
  payload: {
    key,
    value,
  },
});

export default setFilterItem;
