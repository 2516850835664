import moment from 'moment';

function getTaskDeadline(deadlineDate, status) {
  if (status === 5) return 'completed';
  if (!deadlineDate) return null;

  const now = moment().utc();

  const deadLineMoment = moment(deadlineDate).utc();

  if (moment(now).isAfter(deadLineMoment, 'day')) {
    return 'OVERDUE';
  }

  const age = moment.duration(now.diff(deadLineMoment.endOf('day')));
  const ageReadable = age.humanize();

  return `due in ${ageReadable}`;
}

export default getTaskDeadline;
