import React, {
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import RealtorContactAvatar from 'shared/modules/RealtorContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

import {
  Root,
} from './styledItems';

const SearchItemContactLead = (props) => {
  const {
    contactId,
    labels,
    role,
  } = props;

  const description = useMemo(() => role && (
  <Grid item>
    <Typography
      variant="title4"
      weight="bold"
      color="focus"
      noMargin
    >
      {role}
    </Typography>
  </Grid>
    ),
    [
      role,
      labels,
    ]);

  return (
    <Root>

      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <RealtorContactAvatar
            size="s"
            contactId={contactId}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          justify="space-around"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography
              variant="title3"
              weight="bold"
              textTransform="capitalize"
              noMargin
            >
              {useContactName(contactId)}
            </Typography>
          </Grid>

          {description}
        </Grid>
      </Grid>

    </Root>
  );
};

SearchItemContactLead.defaultProps = {
  contactId: null,
  labels: [],
  role: '',
};

const {
  string,
  arrayOf,
  shape,
  number,
} = PropTypes;

SearchItemContactLead.propTypes = {
  contactId: number,
  labels: arrayOf(shape({
    name: string,
    color: string,
  })),
  role: string,
};

export default SearchItemContactLead;
