import {
  SET_REALTOR_PROSPECTS_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorProspects';

const setProspectsOverallSummary = (summary) => ({
  type: SET_REALTOR_PROSPECTS_OVERALL_SUMMARY,
  payload: summary,
});

export default setProspectsOverallSummary;
