/* eslint-disable max-lines */
// TODO - refactor this; break into smaller files
import { callAlert } from 'ui/Alert';
import moment from 'moment';
import {
  commentsModule,
  RESOLVED,
  FEEDBACK_SUBMITTED,
  TUTORIAL_TICKET_ID,
} from 'constants';

import { socket } from 'shared/API/sockets';

// eslint-disable-next-line import/no-cycle

import {
  TICKET_ESCALATED,
  TICKET_UPDATED,
} from 'constants/tickets.constants';

// eslint-disable-next-line import/no-cycle
import {
  apiConnector,
  getConnector,
  tokenFetch as fetch,
  customerToUrlConnector,
} from 'shared/API';

import { url as defaultUrl } from 'paths';

import { // eslint-disable-line import/no-cycle
  hideModal as hideTicketModal,
  rateTicket,
  rateTicketError,
  rateTicketSuccess,
  resolveTicketByModal,
  resolveTicketByModalError,
  // resolveTicketByModalSuccess,
} from 'store/actions/tickets-list-view';

// eslint-disable-next-line import/no-cycle
import {
  setTicketEmployees,
  setTicketEmployeesError,
  setTicketEmployeesSuccess,
} from 'store/actions/ticket-details-modal';

import getTicketAndRefreshRelatedSummaries from 'store/actions/ticketsV2/getTicketAndRefreshRelatedSummaries';

// eslint-disable-next-line import/no-cycle
import {
  customerTicketAddImg,
  getCustomerTicketCommentsSuccess,
} from './external-store';

export const GET_TICKETS_DASHBOARD_INF = 'GET_TICKETS_DASHBOARD_INF';
export const getTicketsDashboardInf = () => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        EmployeeID,
      },
    },
    // queueSetup: {
    //   queueSetupList,
    // },
    // tickets: {
    //   ticketsList,
    // },
  } = getState();

  const url = '/api/tickets/getTicketsDashboardInf';
  const result = await apiConnector(
    url,
    'POST',
    {
      EmployeeID,
    },
  );

  const {
    countManagerQueue,
  } = result;

  dispatch({
    type: GET_TICKETS_DASHBOARD_INF,
    countManagerQueue,
  });
};

export const GET_TICKETS = 'GET_TICKETS';
export const getTickets = () => async (dispatch, getState) => {
  dispatch({ type: GET_TICKETS });
  try {
    const tickets = await getConnector('/api/tickets');
    await dispatch(getTicketsSuccess(tickets));
    const ticketId = getState().tickets.selectedTicket.ticket.TicketID;
    if (ticketId && ticketId !== TUTORIAL_TICKET_ID) {
      dispatch(getTicketData(ticketId, false));
    }
  } catch (error) {
    dispatch(getTicketsError());
  }
};

export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const getTicketsSuccess = (tickets) => ({
  type: GET_TICKETS_SUCCESS,
  tickets,
});

export const GET_TICKETS_ERROR = 'GET_TICKETS_ERROR';
export const getTicketsError = () => ({ type: GET_TICKETS_ERROR });

export const GET_CUSTOMER_TICKET_INFO = 'GET_CUSTOMER_TICKET_INFO';

export function getCustomerTicketInfo(id) {
  return (dispatch) => fetch(`${defaultUrl}/Tickets/ReturnTicketByCustomer/${id}`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch(getCustomerTicketInfoSuccess(json.results));
    })
    .catch((error) => dispatch(getCustomerTicketInfoError(error)));
}

export const GET_CUSTOMER_TICKET_INFO_SUCCESS = 'GET_CUSTOMER_TICKET_INFO_SUCCESS';
export function getCustomerTicketInfoSuccess(response) {
  return {
    type: GET_CUSTOMER_TICKET_INFO_SUCCESS,
    response,
  };
}

export const GET_CUSTOMER_TICKET_INFO_ERROR = 'GET_CUSTOMER_TICKET_INFO_ERROR';

export function getCustomerTicketInfoError(error) {
  return {
    type: GET_CUSTOMER_TICKET_INFO_ERROR,
    error,
  };
}

export const GET_TICKET_STATUS_LIST = 'GET_TICKET_STATUS_LIST';

export function getTicketStatusList() {
  return {
    type: GET_TICKET_STATUS_LIST,
  };
}

export const GET_TICKET_STATUS_LIST_SUCCESS = 'GET_TICKET_STATUS_LIST_SUCCESS';

export function getTicketStatusListSuccess(list) {
  return {
    type: GET_TICKET_STATUS_LIST_SUCCESS,
    ticketStatusList: list,
  };
}

export const GET_TICKET_STATUS_LIST_ERROR = 'GET_TICKET_STATUS_LIST_ERROR';

export function getTicketStatusListError() {
  return {
    type: GET_TICKET_STATUS_LIST_ERROR,
  };
}

export const SHOW_TICKET_DETAILS_MODAL = 'SHOW_TICKET_DETAILS_MODAL';

export const showTicketModal = (isVisible) => (dispatch) => {
  if (isVisible === true) {
    dispatch(clearTicketComments());
  } else {
    dispatch(initTicketsStatus());
  }

  dispatch({
    type: SHOW_TICKET_DETAILS_MODAL,
    visibility: isVisible,
  });
};

export const GET_TICKET_DATA_BY_TICKET_ID = 'GET_TICKET_DATA_BY_TICKET_ID';

export const getTicketData = (ticketId, shouldClear = true) => (dispatch) => {
  if (shouldClear) {
    dispatch(clearSelectedTicket());
  }
  dispatch({
    type: GET_TICKET_DATA_BY_TICKET_ID,
    id: ticketId,
  });
};

export const ASSIGN_TICKET = 'ASSIGN_TICKET';

export const CLEAR_SELECTED_TICKET = 'CLEAR_SELECTED_TICKET';

export function clearSelectedTicket() {
  return {
    type: CLEAR_SELECTED_TICKET,
  };
}

export const UPDATE_TICKET_DELVIERY_DATE = 'UPDATE_TICKET_DELVIERY_DATE';

export function updateTicketDeliveryDate(id, date) {
  return (dispatch) => fetch(
    `${defaultUrl}/api/tickets/singleTicket/updateTicketDeliveryDate/${id}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({
        DeliveryDate: date,
      }),
    },
  )
    .then((res) => res.json())
    .then((ticket) => {
      dispatch(UpdateTicketDeliveryDateSuccess(ticket));
    })
    .catch((error) => dispatch(getCustomerTicketInfoError(error)));
}

export const UPDATE_TICKET_DELVIERY_DATE_SUCCESS = 'UPDATE_TICKET_DELVIERY_DATE_SUCCESS';

export function UpdateTicketDeliveryDateSuccess(ticket) {
  return {
    type: UPDATE_TICKET_DELVIERY_DATE_SUCCESS,
    ticket,
  };
}

export const GET_TICKET_COMMENTS = 'GET_TICKET_COMMENTS';

export const getTicketComments = (id, isCustomerView = false) => async (dispatch) => {
  const url = `/api/comments/ticket/${id}`;
  const connector = isCustomerView
    ? customerToUrlConnector
    : getConnector;

  const onSuccess = isCustomerView
    ? getCustomerTicketCommentsSuccess
    : getTicketCommentsSuccess;

  try {
    const res = await connector(url);
    dispatch(onSuccess(res));
  } catch (error) {
    callAlert.warning('Error occured');
  }
};

export const TICKET_CLEAR_COMMENTS = 'TICKET_CLEAR_COMMENTS';

export function ticketClearComments() {
  return {
    type: TICKET_CLEAR_COMMENTS,
  };
}

export const GET_TICKET_COMMENTS_SUCCESS = 'GET_TICKET_COMMENTS_SUCCESS';

export function getTicketCommentsSuccess(list) {
  return {
    type: GET_TICKET_COMMENTS_SUCCESS,
    comments: list,
  };
}

export const UPDATE_TICKET_FIELD = 'UPDATE_TICKET_FIELD';
export const updateTicketField = (id, field, data) => (dispatch, getState) => {
  const url = `/api/tickets/singleTicket/updateField/${id}`;
  const { Name = '', Surname = '' } = getState().auth.loggedUser;
  return apiConnector(url, 'PUT', {
    field,
    data,
    userFullName: `${Name} ${Surname}`,
  })
    .then((ticket) => {
      dispatch(updateTicketFieldSuccess(ticket));
      dispatch(getTicketAndRefreshRelatedSummaries(id));

      callAlert.success(TICKET_ESCALATED);
    })
    .catch((err) => {
      dispatch(updateTicketProgressError());
    });
};

export const UPDATE_TICKET_FIELD_SUCCESS = 'UPDATE_TICKET_FIELD_SUCCESS';
export const updateTicketFieldSuccess = (ticket) => ({
  type: UPDATE_TICKET_FIELD_SUCCESS,
  ticket,
});

export const UPDATE_TICKET_FIELD_ERROR = 'UPDATE_TICKET_FIELD_ERROR';
export const updateTicketFieldError = () => {
  callAlert.warning('Error occured');
  return { type: UPDATE_TICKET_FIELD_ERROR };
};

export function updateTicketAcknowledge(data) {
  if (data.messageType === commentsModule.COMMENT_TYPE.COMMENT) {
    data.messageType = commentsModule.COMMENT_TYPE.TICKET; // eslint-disable-line no-param-reassign
  } else if (data.messageType === commentsModule.COMMENT_TYPE.REPLY) {
    data.messageType = commentsModule.COMMENT_TYPE.COMMENT; // eslint-disable-line no-param-reassign
  }
  return (dispatch, getState) => {
    const url = `/api/comments/${data.messageType}/${data.parentId}/${data.childId}`;
    return apiConnector(url, 'PUT', { isAcknowledged: true })
      .then((res) => {
        checkCommentsAcknowledgement(data, getState, dispatch);
        dispatch(getTicketComments(data.ticketId));
      })
      .catch((err) => callAlert.warning('Error occured'));
  };
}

export const UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS = 'UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS';

export function updateTicketAcknowledgeSuccess(id, status) {
  return { type: UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS, id, status };
}

export const UPDATE_TICKET_PROGRESS = 'UPDATE_TICKET_PROGRESS';
export const updateTicketProgress = (ticketId, progress) => (dispatch, getState) => {
  const url = `/api/tickets/singleTicket/updateProgress/${ticketId}`;
  const { Name = '', Surname = '' } = getState().auth.loggedUser;

  return apiConnector(url, 'PUT', {
    Progress: progress,
    UserFullName: `${Name} ${Surname}`,
  })
    .then((ticket) => {
      dispatch(updateTicketProgressSuccess(ticket));
      dispatch(getTicketAndRefreshRelatedSummaries(ticketId));
      callAlert.success(TICKET_UPDATED);
    })
    .catch((err) => dispatch(updateTicketProgressError()));
};

export const UPDATE_TICKET_PROGRESS_SUCCESS = 'UPDATE_TICKET_PROGRESS_SUCCESS';
export const updateTicketProgressSuccess = (ticket) => ({
  type: UPDATE_TICKET_PROGRESS_SUCCESS,
  ticket,
});

export const UPDATE_TICKET_PROGRESS_ERROR = 'UPDATE_TICKET_PROGRESS_ERROR';
export const updateTicketProgressError = () => ({ type: UPDATE_TICKET_PROGRESS_ERROR });

export const ticketAddComment = (data) => (dispatch) => {
  let commentType;
  let id;
  const body = {
    Comment: data.comment,
    isPrivate: data.status,
    CommentType: data.commentType ? data.commentType : null,
  };

  if (data.messageType === commentsModule.COMMENT_TYPE.COMMENT) {
    commentType = commentsModule.COMMENT_TYPE.TICKET;
    id = data.ticketId;
  } else if (data.messageType === commentsModule.COMMENT_TYPE.REPLY) {
    commentType = commentsModule.COMMENT_TYPE.COMMENT;
    id = data.commentId;
  }
  apiConnector(`/api/comments/${commentType}/${id}`, 'POST', body)
    .then((res) => {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('commentImg') && data.commentImg !== null) {
        customerTicketAddImg(
          { ...data, commentType: 'ticket-comment' },
          res,
        ).then(() => {
          dispatch(getTicketComments(data.ticketId));
        });
      } else {
        dispatch(getTicketComments(data.ticketId));
      }

      dispatch(addTicketNotification(data.messageType, data.ticketId));
      dispatch(newCommentsReplies(data.ticketId, data.messageType, true));
      dispatch(getTicketAndRefreshRelatedSummaries(data.ticketId));
    })
    .catch(() => { });
};

export function newCommentsReplies(id, commentType, data) {
  let field;
  if (commentType === 'comment') {
    field = 'NewComments';
  } else if (commentType === 'reply') {
    field = 'NewReplies';
  }
  const url = `/api/tickets/singleTicket/updateField/${id}`;
  return (dispatch, getState) => {
    const { Name = '', Surname = '' } = getState().auth.loggedUser;
    return apiConnector(url, 'PUT', {
      field,
      data,
      userFullName: `${Name} ${Surname}`,
    }).catch((err) => dispatch(newCommentsRepliesError()));
  };
}

export const NEW_COMMENTS_REPLIES_ERROR = 'NEW_COMMENTS_REPLIES_ERROR';
export const newCommentsRepliesError = () => ({
  type: NEW_COMMENTS_REPLIES_ERROR,
});

export const ADD_TICKET_NOTIFICATION = 'ADD_TICKET_NOTIFICATION';
export const addTicketNotification = (type, id) => ({
  type: ADD_TICKET_NOTIFICATION,
  notification: type,
  ticketId: id,
});

export const REMOVE_TICKET_NOTIFICATION = 'REMOVE_TICKET_NOTIFICATION';

export function removeTicketNotification(type, id) {
  return {
    type: REMOVE_TICKET_NOTIFICATION,
    notification: type,
    ticketId: id,
  };
}

export const ticketDeleteComment = (data) => {
  const ticketDataObject = {
    ticketId: data.ticketId,
    commentId: data.commentId,
    deleting: true,
  };
  return (dispatch, getState) => fetch(
    `${defaultUrl}/api/comments/${data.messageType}/${data.ticketId}/${data.commentId}`,
    {
      method: 'DELETE',
    },
  )
    .then((res) => {
      checkCommentsAcknowledgement(ticketDataObject, getState, dispatch);
      dispatch(getTicketComments(data.ticketId));
      dispatch(getTicketAndRefreshRelatedSummaries(data.ticketId));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const ticketReplyDeleteComment = (data) => {
  const ticketDataObject = {
    ticketId: data.ticketId,
    commentId: data.commentId,
    deleting: true,
  };

  return (dispatch, getState) => fetch(
    `${defaultUrl}/api/comments/${data.messageType}/${data.commentedObjectID}/${data.commentId}`,
    {
      method: 'DELETE',
    },
  )
    .then((res) => {
      checkCommentsAcknowledgement(ticketDataObject, getState, dispatch);
      dispatch(getTicketComments(data.ticketId));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

// TODO: Need to be optimized
export const CHECK_COMMENTS_ACKNOWLEDGEMENT = 'CHECK_COMMENTS_ACKNOWLEDGEMENT';

export function checkCommentsAcknowledgement(
  ticketDataObject,
  getState,
  dispatch,
) {
  const { tickets } = getState();
  if (ticketDataObject.deleting) {
    let commentsCounter = 0;
    let repliesCounter = 0;
    tickets.selectedTicket.comments.map((comment) => {
      if (
        comment.isAcknowledged === 0
        && comment.replies.length !== 0
        && comment.CommentID !== ticketDataObject.commentId
      ) {
        commentsCounter += 1;
      }
      if (comment.replies.length !== 1) {
        comment.replies.map((reply) => {
          if (
            reply.isAcknowledged === 0
            && reply.ParentCommentID !== ticketDataObject.commentId
          ) {
            repliesCounter += 1;
          }
          return reply;
        });
      }
      return comment;
    });
    if (commentsCounter === 0) {
      removeCommentsIcon(tickets, ticketDataObject.ticketId, dispatch);
    }
    if (repliesCounter === 0) {
      removeRepliesIcon(tickets, ticketDataObject.ticketId, dispatch);
    }
  } else {
    const notAcknowledgedComments = tickets.selectedTicket.comments.filter(
      (comment) => comment.isAcknowledged === 0
        && comment.CommentID !== ticketDataObject.commentId,
    );
    if (notAcknowledgedComments.length === 0) {
      removeCommentsIcon(tickets, ticketDataObject.ticketId, dispatch);
    }

    let repliesCounter = tickets.selectedTicket.comments.length;
    tickets.selectedTicket.comments.map((comment) => {
      const notAcknowledgedReplies = comment.replies.filter(
        (reply) => reply.isAcknowledged === 0
          && reply.CommentID !== ticketDataObject.commentId,
      );
      if (notAcknowledgedReplies.length !== 0) {
        repliesCounter += 1;
      }
      return comment;
    });

    if (repliesCounter === tickets.selectedTicket.comments.length) {
      removeRepliesIcon(tickets, ticketDataObject.ticketId, dispatch);
    }
  }
}

export const REMOVE_COMMENTS_ICON = 'REMOVE_COMMENTS_ICON';

export function removeCommentsIcon(tickets, ticketId, dispatch) {
  tickets.selectedTicket.ticket.NewComments = 0; // eslint-disable-line no-param-reassign
  dispatch(updateTicketField(ticketId, 'NewComments', 0));
  dispatch(removeTicketNotification('comment', ticketId));
}

export const REMOVE_REPLIES_ICON = 'REMOVE_REPLIES_ICON';

export function removeRepliesIcon(tickets, ticketId, dispatch) {
  tickets.selectedTicket.ticket.NewReplies = 0; // eslint-disable-line no-param-reassign
  dispatch(updateTicketField(ticketId, 'NewReplies', 0));
  dispatch(removeTicketNotification('reply', ticketId));
}

export const GET_ALL_PRIORITIES = 'GET_ALL_PRIORITIES';

export function getAllPriorities() {
  return { type: GET_ALL_PRIORITIES };
}

export const GET_ALL_PRIORITIES_SUCCESS = 'GET_ALL_PRIORITIES_SUCCESS';

export function getAllPrioritiesSuccess(priorities) {
  return {
    type: GET_ALL_PRIORITIES_SUCCESS,
    priorities,
  };
}

export const GET_ALL_PRIORITIES_ERROR = 'GET_ALL_PRIORITIES_ERROR';

export function getAllPrioritiesError() {
  return { type: GET_ALL_PRIORITIES_ERROR };
}

export const CREATE_TICKET = 'CREATE_TICKET';

export function createTicket() {
  return { type: CREATE_TICKET };
}

export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';

export const createTicketSuccess = (form) => {
  form.resetFields();
};

export const CREATE_TICKET_ERROR = 'CREATE_TICKET_ERROR';

export function createTicketError() {
  return { type: CREATE_TICKET_ERROR };
}

export const CLEAR_TICKET_COMMENTS = 'CLEAR_TICKET_COMMENTS';

export function clearTicketComments() {
  return { type: CLEAR_TICKET_COMMENTS };
}

export const TICKET_EDIT_COMMENT = 'TICKET_EDIT_COMMENT';
export const ticketEditComment = (data) => (dispatch) => {
  let commentType;
  if (data.messageType === commentsModule.COMMENT_TYPE.COMMENT) {
    commentType = commentsModule.COMMENT_TYPE.TICKET;
  } else if (data.messageType === commentsModule.COMMENT_TYPE.REPLY) {
    commentType = commentsModule.COMMENT_TYPE.COMMENT;
  }
  const url = `/api/comments/${commentType}/${data.parentId}/${data.childId}`;
  return apiConnector(url, 'PUT', { Comment: data.commentValue })
    .then((res) => dispatch(getTicketComments(data.ticketId)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const ADD_CALL_LOG = 'ADD_CALL_LOG';

export function addCallLog(ticketId, data) {
  return (dispatch) => fetch(`${defaultUrl}/api/tickets/singleTicket/addCallLog/${ticketId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      ID: data.id,
      EmployeeID: data.employeeId,
      CreationTime: data.creationTime,
      Duration: data.duration,
      Notes: data.notes,
      Type: 'lead',
      ContactType: data.contactType,
      Sentimait: data.sentimait,
      LeadID: data.leadId,
      Title: data.title,
      IsAcknowledged: data.isAcknowledged,
      AcknowledgedUserId: data.acknowledgedUserId,
    }),
  })
    .then((res) => {
      dispatch(getCallsLog(ticketId));
    })
    .catch((err) => callAlert.warning('Error occured'));
}

export function deleteCallLog(data) {
  return (dispatch) => fetch(`${defaultUrl}/api/tickets/singleTicket/deleteCallLog/${data.ticketId}`, { // eslint-disable-line max-len
    method: 'POST',
    body: JSON.stringify({
      ID: data.id,
      LeadID: data.LeadId,
      Title: data.Title,
    }),
  })
    .then((res) => {
      dispatch(getCallsLog(data.ticketId));
    })
    .catch((err) => callAlert.warning('Error occured'));
}

export const GET_CALLS_LOG = 'GET_CALLS_LOG';

export function getCallsLog(ticketId) {
  return (dispatch) => fetch(`${defaultUrl}/api/tickets/singleTicket/getCallsLog/${ticketId}`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch(getCallsLogSuccess(json));
    })
    .catch((error) => dispatch(getCustomerTicketInfoError(error)));
}

export const GET_CALLS_LOG_SUCCESS = 'GET_CALLS_LOG_SUCCESS';

export function getCallsLogSuccess(data) {
  return {
    type: GET_CALLS_LOG_SUCCESS,
    log: data,
  };
}

export const UPDATE_SELECTED_TICKET = 'UPDATE_SELECTED_TICKET';
export const updateSelectedTicket = (payload) => (dispatch, getState) => {
  const {
    ticketID,
    CategoryID: categoryId,
    SubCategoryID: subCategoryId,
    QueueID: queueId,
  } = payload;
  const { Name = '', Surname = '' } = getState().auth.loggedUser;

  return fetch(`${defaultUrl}/api/tickets/updateCategory/${ticketID}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      categoryId,
      subCategoryId,
      queueId,
      UserFullName: `${Name} ${Surname}`,
    }),
  })
    .then((res) => {
      dispatch(getTicketAndRefreshRelatedSummaries(ticketID));
      // dispatch({
      //   type: UPDATE_SELECTED_TICKET,
      //   payload,
      // });
    })
    .catch((err) => callAlert.warning(err.message));
};

export const GET_TICKET_ACTIVITIES = 'GET_TICKET_ACTIVITIES';
export const getTicketActivities = (payload) => (dispatch) => {
  const { ticketID } = payload;
  getConnector(`/api/common/activities?ObjectType=ticket&ObjectID=${ticketID}`)
    .then((data) => {
      const dataLength = data.length;
      if (data && dataLength) {
        const lastRow = data[dataLength - 1];
        const {
          EmployeeObject: { Name },
        } = lastRow;
        dispatch(
          setLastUpdateDate({
            lastUpdateDate: lastRow.updatedAt,
            lastUpdateAgent: Name,
          }),
        );
      } else {
        dispatch(
          setLastUpdateDate({
            lastUpdateDate: 'N/A',
            lastUpdateAgent: '',
          }),
        );
      }
    })
    .catch((err) => callAlert.warning(err.message));
};

export const SET_LAST_UPDATE_DATE = 'SET_LAST_UPDATE_DATE';
export const setLastUpdateDate = (payload) => ({
  type: SET_LAST_UPDATE_DATE,
  payload,
});

export const initTicketsStatus = () => (dispatch, getState) => {
  ticketsStatus(dispatch, getState);
};

export const initTicketPriorities = () => (dispatch) => {
  dispatch(getAllPriorities());
  getConnector('/api/tickets/priorities')
    .then((priorities) => dispatch(getAllPrioritiesSuccess(priorities)))
    .catch((err) => dispatch(getAllPrioritiesError()));
};

export const ticketsStatus = (dispatch, getState) => {
  dispatch(getTicketStatusList());

  return fetch(`${defaultUrl}/Tickets/ReturnAllTicketStatuses`)
    .then((res) => res.json())
    .then((json) => {
      dispatch(getTicketStatusListSuccess(json.ticketstatuses));
    })
    .catch((error) => dispatch(getTicketStatusListError()));
};

export const assignTicket = (ticketId, assigneeId, assignType) => async (dispatch, getState) => {
  const {
    myCompany: {
      employees,
    },
    queueSetup: {
      activeQueue,
    },
  } = getState();

  const url = `/api/tickets/assignEmployee/${activeQueue}/${ticketId}`;

  dispatch(setTicketEmployees);

  let response;

  try {
    response = await apiConnector(url, 'PUT', { userList: [assigneeId], type: assignType });

    dispatch(setTicketEmployeesSuccess({ queueId: activeQueue }, response));

    socket.emit('ticket:assign:update', {
      token: localStorage.getItem('token'),
    });

    const assignee = employees.find((item) => item.EmployeeID === assigneeId);

    const assigneeName = assignee
      ? `${assignee.Name} ${assignee.Surname}`
      : 'another user';

    dispatch(getTicketAndRefreshRelatedSummaries(ticketId));
    callAlert.success(`Ticket ${ticketId} was ${assignType === 'replace' ? 'moved' : 'added'} and has been assigned to ${assigneeName}`); // eslint-disable-line max-len
  } catch (error) {
    dispatch(setTicketEmployeesError);
    callAlert.error(error.message);
  }
};

export const resolveTicketApi = (status, ticket, data) => (dispatch) => {
  const apiUrl = `${defaultUrl}/Tickets/EmployeeResolveTicket/${ticket.TicketID}`;

  const body = {
    TicketName: ticket.TicketName,
    completed: moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss'),
  };

  // const newResolvedName = 'resolved';

  dispatch(resolveTicketByModal());

  return fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then(() => {
      dispatch(getTicketAndRefreshRelatedSummaries(ticket.TicketID));
      // dispatch(resolveTicketByModalSuccess(status, ticket, newResolvedName));
      callAlert.success(RESOLVED);
      dispatch(rateTicket());
      fetch(`${defaultUrl}/Tickets/RateTicket/${ticket.TicketID}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({ Customer: ticket.CustomerID, Rating: data }),
      })
        .then((res) => {
          res.json();
        })
        .then(() => {
          dispatch(rateTicketSuccess(status, ticket, data));
          dispatch(hideTicketModal());
          dispatch(showTicketModal(false));
          callAlert.success(FEEDBACK_SUBMITTED);
        })
        .catch(() => dispatch(rateTicketError()));
    })
    .catch(() => dispatch(resolveTicketByModalError()));
};

// TODO - refactor
export const assignUsersToTicket = (
  ticketId,
  queueId,
  userList,
  ticketNumber,
  newAssignedUserName,
) => async (dispatch, getState) => { // eslint-disable-line max-params
  // Here we update ticket status to 'New Assigned' if its status is 'Unassigned'
  // this is an appaling solution, find a better one
  const ticket = getState().tickets.ticketsList.find((item) => item.TicketID === ticketId);

  if (ticket && ticket.TicketStatusID === 1) {
    const { Name, Surname } = getState().auth.loggedUser;

    await apiConnector('/Tickets/ChangeTicketStatus', 'POST', {
      TicketID: ticketId,
      StatusID: 2,
      UserFullName: `${Name} ${Surname}`,
    });
  }
  //

  const url = `/api/tickets/assignEmployee/${queueId}/${ticketId}`;

  apiConnector(url, 'PUT', { userList })
    .then((res) => {
      dispatch(setTicketEmployeesSuccess({ queueId }, res));
      socket.emit('ticket:assign:update', {
        token: localStorage.getItem('token'),
      });

      socket.emit('ticket:moved', {
        ticketNumber,
        newAssignedUserName,
        token: localStorage.getItem('token'),
      });

      dispatch(getTicketAndRefreshRelatedSummaries(ticketId));
    })
    .catch(() => dispatch(setTicketEmployeesError));
};

export const ADD_TUTORIAL_TICKET = 'ADD_TUTORIAL_TICKET';
export const addTutorialTicket = () => ({
  type: ADD_TUTORIAL_TICKET,
});

/* eslint-enable max-lines */
