import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  Wrapper,
} from './styledItems';

const BaseControl = (props) => {
  const {
    value,
    customIcon,
    icon,
    description,
    variant,
    iconColor,
    iconType,
    iconSize,
  } = props;

  return (
    <Wrapper>
      <Icon
        name={customIcon || icon}
        color={variant}
        type={iconType}
        size={iconSize}
      />
      {
        (value || value === 0) && (
          <Typography
            variant="body"
            className="value-text"
            noMargin
          >
            {value}
          </Typography>
        )
      }
      {
        description && (
          <Typography
            variant="body"
            className="description-text"
            noMargin
          >
            {description}
          </Typography>
        )
      }
    </Wrapper>
  );
};

BaseControl.defaultProps = {
  value: 0,
  icon: 'bed',
  customIcon: null,
  description: null,
  iconColor: null,
  variant: null,
  iconSize: 'm',
};

const {
  number,
  oneOf,
  string,
} = PropTypes;

BaseControl.propTypes = {
  value: number,
  icon: oneOf([
    'bed',
    'home',
    'bath',
    'chart-area',
    'car',
  ]),
  customIcon: string,
  description: string,
  iconColor: string,
  variant: string,
  iconSize: string,
};

export default BaseControl;
