import apiConnector, { endpoints } from 'api';

const addSubcategory = (subqueueId) => async (subcategoryName) => {
  const endpoint = `${endpoints.client.subqueues}/AddSubCategoryToCategory`;

  try {
    const result = await apiConnector.client.post(endpoint, {
      CategoryID: subqueueId,
      SubCategory: subcategoryName,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default addSubcategory;
