import {
  SET_CRM_PRODUCTS,
} from 'store/actionTypes/crm';

const setProducts = (products) => ({
  type: SET_CRM_PRODUCTS,
  payload: products,
});

export default setProducts;
