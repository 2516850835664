export const SET_SERVICE_PROCESS = 'SET_SERVICE_PROCESS';
export const SET_SERVICE_PROCESSES = 'SET_SERVICE_PROCESSES';
export const SET_SELECTED_SERVICE_PROCESS = 'SET_SELECTED_SERVICE_PROCESS';
export const SET_SERVICE_PROCESS_ATTACHMENTS = 'SET_SERVICE_PROCESS_ATTACHMENTS';

export default {
  SET_SERVICE_PROCESS,
  SET_SERVICE_PROCESSES,
  SET_SELECTED_SERVICE_PROCESS,
  SET_SERVICE_PROCESS_ATTACHMENTS,
};
