import React, {
  useContext,
  useCallback,
  useMemo,
} from 'react';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Spin from 'ui/Spin';

const Controls = (props) => {
  const {
    onEnd,
  } = props;

  const context = useContext(DataImportContext);

  const {
    currentStep,
    goToNext,
    goToPrev,

    selectedTypes,
    previewDataSource,

    importData,
    isImportInProgress,

    selectedRows,
    fieldMap,
  } = context;

  const isDisabledNext = useMemo(() => currentStep.isDisabledNext(context), [
    currentStep,
    selectedTypes,
    previewDataSource,
    selectedRows,
    fieldMap,
  ]);

  const finalize = useCallback(async () => {
    await importData();
    onEnd(selectedTypes);
  }, [
    importData,
    onEnd,
    selectedTypes,
  ]);

  return (
    <Flex
      spacing={1}
      fullWidth
      justifyContent="flex-end"
      alignItems="center"
    >
      {
        currentStep.prev && (
          <Button
            onClick={goToPrev}
            variant="primary"
          >
            Back
          </Button>
        )
      }
      {
        currentStep.next && (
          <Button
            onClick={goToNext}
            variant="primary"
            disabled={isDisabledNext}
          >
            Next
          </Button>
        )
      }
      {
        !currentStep.next && (
          <>
            {
              isImportInProgress && <Spin />
            }
            {
              !isImportInProgress && (
                <Button
                  onClick={finalize}
                  variant="primary"
                  disabled={isDisabledNext}
                >
                  Confirm & Continue
                </Button>
              )
            }
          </>
        )
      }
    </Flex>
  );
};

export default Controls;
