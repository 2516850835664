import React, {
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import ImageUploadWrapper from 'shared/components/ImageUploadWrapper';
import Typography from 'ui/Typography';

import { common } from 'constants';
import { url } from 'paths';

import {
  Wrapper,
  DividerContainer,
  Divider,
} from './styledItems';

const Logo = (props) => {
  const {
    getGeneral,
    deleteCompanyLogo,
    general,
    user,
    croppedLogo,
  } = props;

  useEffect(() => {
    const token = localStorage.getItem('token');

    getGeneral({ token });
  }, []);

  const onAdd = useCallback(() => {
    getGeneral();
  }, []);

  const onLogoDelete = useCallback(() => {
    deleteCompanyLogo();
  }, []);

  const getThumbUrl = useCallback((thumbUrl) => {
    const token = localStorage.getItem('token');
    return `${thumbUrl}?auth=${token}`;
  }, []);

  let logo = null;
  const logoUrl = general && general.logoUrl;

  if (logoUrl) {
    const fileSrc = `${url}${logoUrl}`;
    logo = getThumbUrl(fileSrc);
  }

  const src = croppedLogo || logo || common.DEFAULT_LOGO;

  return (
    <Wrapper>
      <ImageUploadWrapper
        appModule="company-logo"
        circularCrop={false}
        employee={user}
        imageActionHooks={{
          onAdd,
          onDelete: onLogoDelete,
        }}
        objectId={user.CompanyID}
        visibleModule="company-logo"
      >
        <img
          src={src}
        />
      </ImageUploadWrapper>

      <Typography
        color="focus-inversed"
        noMargin
        variant="caption2"
      >
        Powered by Symplete
      </Typography>

      <DividerContainer>
        <Divider />
      </DividerContainer>
    </Wrapper>
  );
};

Logo.defaultProps = {
  general: {
    logoUrl: null,
  },
  croppedLogo: null,
};

const {
  func,
  shape,
  string,
} = PropTypes;

Logo.propTypes = {
  getGeneral: func.isRequired,
  deleteCompanyLogo: func.isRequired,
  general: shape({
    logoUrl: string,
  }),
  user: shape({
    CompanyID: string,
  }).isRequired,
  croppedLogo: string,
};

export default Logo;
