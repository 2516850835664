import {
  optimizedRealtorExtensionRequestActions,
} from 'store/actions/realtorExtensionRequests';

import withAttachmentsList from 'shared/hocs/withAttachmentsList';

const withExtensionRequestAttachments = (Component) => withAttachmentsList(40)({
    callbacks: {
      onAfterUpload: optimizedRealtorExtensionRequestActions.getAttachments,
      onAfterRemove: optimizedRealtorExtensionRequestActions.getAttachments,
    },
    selectorPath: 'realtorExtensionRequests.attachments',
  })(Component);

export default withExtensionRequestAttachments;
