import React from 'react';

const initialSteps = [
  {
    name: 'tutorial-create-sales-team',
    target: '[data-tutorial="create-sales-team"]',
    content: (
      <ol>
        <li>Click the New Team button.</li>
        <li>Set team name and description.</li>
        <li>Add Managers and Team Employees.</li>
        <li>Add sub teams if needed and repeat step 3.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Create sales team',
    styles: {
      options: {
        zIndex: 1000,
      },
    },
    manager: true,
  },
  {
    name: 'tutorial-create-new-lead',
    target: '[data-tutorial="create-new-lead"]',
    content: (
      <ol>
        <li>Click the New button and select Lead.</li>
        <li>Fill out the lead details.</li>
        <li>Once completed, the lead will be seen on the Leads page.</li>
        <li>Leads that are claimed by sales people automatically become deals.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Create new sales lead',
  },
  {
    name: 'tutorial-create-new-deal',
    target: '[data-tutorial="create-new-deal"]',
    content: (
      <ol>
        <li>Click the Deal button.</li>
        <li>
          Fill out the deals details.
          <ol>
            <li>Set a Company and deals value.</li>
            <li>Assign a sales person.</li>
          </ol>
        </li>
        <li>Confirm deals to see it on Manager Overview or individual’s list.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Create new sales deal',
    spotlightPadding: 0,
    offset: 0,
  },
  // {
  //   name: 'tutorial-take-unassigned-leads',
  //   target: '[data-tutorial="take-unassigned-leads"]',
  //   content: (
  //     <ol>
  //       <li>Click Unassigned deals link on the My Deals page.</li>
  //       <li>
  //         Drag deals from the unassigned list to the right dark area to add to
  //         your deals list.
  //       </li>
  //       <li>Click the My Deals link or menu item to see you newly assigned deals.</li>
  //     </ol>),
  //   disableBeacon: true,
  //   placement: 'right',
  //   title: 'Take unassigned deals',
  //   styles: {
  //     options: {
  //       zIndex: 1000,
  //       arrowColor: 'rgba(0, 0, 0, 0)',
  //     },
  //   },
  // },
  {
    name: 'tutorial-update-pitch',
    target: '[data-tutorial="update-pitch-details"]',
    content: (
      <ol>
        <li>Click into a pitch to view its details.</li>
        <li>Click below the title to update key information.</li>
        <li>Click into other areas to edit them.</li>
        <li>Click the arrow at the bottom to open the comment and file section of this pitch.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Update pitch details',
    spotlightPadding: 20,
  },
  {
    name: 'tutorial-create-meeting',
    target: '[data-tutorial="create-sales-meeting"]',
    content: (
      <ol>
        <li>Click into a pitch to view its details.</li>
        <li>Click the add meeting text or the + icon top right in the pitch.</li>
        <li>Create your meeting to view it on the pitch or on your My Deals page.</li>
      </ol>),
    disableBeacon: true,
    placement: 'right',
    title: 'Create a sales meeting',
  },
  {
    name: 'tutorial-change-pitch-progress',
    target: '[data-tutorial="change-pitch-progress"]',
    content: (
      <ol>
        <li>Click into a pitch to view its details.</li>
        <li>
          Click the pitch progress bar to change progress.
          <ol>
            <li>Note some progress statuses have sub statuses such as New Analysis.</li>
          </ol>
        </li>
        <li>Provide details on the progress change.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Change pitch progress',
  },
  {
    name: 'tutorial-close-deal',
    target: '[data-tutorial="close-sales-deal"]',
    content: (
      <ol>
        <li>Click into a pitch to view its details.</li>
        <li>Click the WON or LOST choices.</li>
        <li>Complete the won or lost details to make sure you record what happened on the deal.</li>
        <li>Deal details can be viewed in the activities area of the pitch.</li>
      </ol>),
    disableBeacon: true,
    placement: 'left-start',
    title: 'Close a sales deal',
  },
];

export default initialSteps;
