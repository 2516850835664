import {
  useState,
  useEffect,
  useCallback,
} from 'react';

const getMappingKPIs = (fieldMap = {}) => Object.values(fieldMap).reduce((acc, item) => {
  if (item === 'notes') acc.notes += 1;
  if (item !== 'notes' && item !== 'noImport') acc.mapped += 1;
  return acc;
}, {
  notes: 0,
  mapped: 0,
});

const useSteps = ({
  rawData = [],
  selectedRows = [],
  fieldMap = {},
}) => {
  const [totalRows, setTotalRows] = useState(rawData.length);
  const [importedRows, setImportedRows] = useState(selectedRows.filter(Boolean).length || 0);
  const [mappingKPIs, setMappingKPIs] = useState(getMappingKPIs(fieldMap));

  useEffect(() => {
    setTotalRows(rawData.length);
  }, [
    rawData,
  ]);

  useEffect(() => {
    setImportedRows(selectedRows.filter(Boolean).length);
  }, [
    selectedRows,
  ]);

  useEffect(() => {
    setMappingKPIs(getMappingKPIs(fieldMap));
  }, [
    fieldMap,
  ]);

  const reset = useCallback(() => {
    setTotalRows(0);
    setImportedRows(0);
    setMappingKPIs({});
  });

  return {
    totalRows,
    importedRows,
    ignoredRows: totalRows - importedRows,
    mappingKPIs,
    reset,
  };
};

export default useSteps;
