import connectReducers from 'store/connectReducers';

import {
  SET_NEW_COUNTER_MODAL_OPEN,
  SET_NEW_COUNTER_MODAL_CLOSED,
  SET_NEW_COUNTER_MODAL_ATTACHMENTS,
} from 'store/actionTypes/realtorOpportunities';

const initialState = {
  isVisible: false,
  offerId: null,
  rootOfferId: null,
  attachments: [],
  onAfterSubmit: null,
};

const reducers = {
  [SET_NEW_COUNTER_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    offerId: action.payload.offerId,
    rootOfferId: action.payload.rootOfferId,
    onAfterSubmit: action.payload.onAfterSubmit,
  }),

  [SET_NEW_COUNTER_MODAL_CLOSED]: (state) => ({
    ...initialState,
    attachments: state.attachments,
  }),

  [SET_NEW_COUNTER_MODAL_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.payload.filesList,
  }),
};

function newCounterModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newCounterModal;
