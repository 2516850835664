import requests from 'api/requests';

import getAgreementById from './getAgreementById';

const updateAgreement = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.agreements.update(id)(updateData);

    dispatch(getAgreementById(id));
  } catch (error) {
    console.error(error);
  }
};

export default updateAgreement;
