import React from 'react';
import PropTypes from 'prop-types';

import SpaceBetweenBox from 'shared/components/UI/SpaceBetweenBox';
import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import LogEventDateInput from 'sections/Contacts/inputs/LogEventDateInput';
import PropertyAddressInput from 'sections/Contacts/inputs/PropertyAddressInput';
import PropertyStatusSelect from 'sections/Contacts/inputs/PropertyStatusSelect';

import ContactTypeSelect from './ContactTypeSelect';
import NotesInput from './NotesInput';

import {
  Wrapper,
} from './styledItems';

const NewLogForm = (props) => {
  const {
    onChange,
    eventDate,
    propertyAddress,
    type,
    propertyStatus,
    notes,
  } = props;

  return (
    <Wrapper>
      <SpaceBetweenBox>
        <Typography variant="h1">
          Log Event
        </Typography>
      </SpaceBetweenBox>

      <form>
        <DecoratedFieldWrapper
          iconName="calendar-day"
          iconColor="#1584ff"
        >
          <LogEventDateInput
            value={eventDate}
            handleChange={onChange('eventDate')}
          />
        </DecoratedFieldWrapper>

        <DecoratedFieldWrapper
          iconName="map-marker-alt"
          iconColor="#1584ff"
        >
          <PropertyAddressInput
            addressId={propertyAddress}
            handleChange={onChange('propertyAddress')}
          />
        </DecoratedFieldWrapper>

        <DecoratedFieldWrapper
          iconName="user-tie"
          iconColor="#1584ff"
        >
          <ContactTypeSelect
            value={type}
            handleChange={onChange('type')}
          />
        </DecoratedFieldWrapper>

        <DecoratedFieldWrapper
          iconName="building"
          iconColor="#1584ff"
        >
          <PropertyStatusSelect
            value={propertyStatus}
            handleChange={onChange('propertyStatus')}
          />
        </DecoratedFieldWrapper>

        <DecoratedFieldWrapper
          iconName="file-alt"
          iconColor="#1584ff"
        >
          <NotesInput
            value={notes}
            handleChange={onChange('notes')}
          />
        </DecoratedFieldWrapper>
      </form>
    </Wrapper>
  );
};

const {
  func,
  string,
  number,
} = PropTypes;

NewLogForm.propTypes = {
  onChange: func.isRequired,
  eventDate: string.isRequired,
  propertyAddress: string.isRequired,
  type: number.isRequired,
  propertyStatus: number.isRequired,
  notes: string.isRequired,
};

export default NewLogForm;
