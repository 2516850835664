import {
  SET_LOG_NEW_QUESTION_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorTransactions';

const setAttachments = (filesList = []) => ({
  type: SET_LOG_NEW_QUESTION_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setAttachments;
