import { connect } from 'react-redux';

import CancelTransactionForm from './CancelTransactionForm';

const mapStateToProps = (state) => {
  const {
  } = state;

  const propsReason = null;

  return {
    propsReason,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
});

export default connect(mapStateToProps, null, mergeProps)(CancelTransactionForm);
