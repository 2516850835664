export const SET_REALTOR_UNANSWERED_QUESTION = 'SET_REALTOR_UNANSWERED_QUESTION';
export const SET_REALTOR_UNANSWERED_QUESTIONS = 'SET_REALTOR_UNANSWERED_QUESTIONS';
export const SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER = 'SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER';
export const SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT = 'SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT';
export const RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER = 'RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER';
// eslint-disable-next-line max-len
export const RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT = 'RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT';
export const RESET_REALTOR_UNANSWERED_QUESTIONS_FILTERS = 'RESET_REALTOR_UNANSWERED_QUESTIONS_FILTERS';
export const RESET_REALTOR_UNANSWERED_QUESTIONS_FILTER_COUNTERS = 'RESET_REALTOR_UNANSWERED_QUESTIONS_FILTER_COUNTERS';
export const SET_REALTOR_UNANSWERED_QUESTION_ATTACHMENTS = 'SET_REALTOR_UNANSWERED_QUESTION_ATTACHMENTS';
