import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${(props) => (props.theme.palette?.['background-flat'] || 'lightgrey')};
`;

export default {
  Root,
};
