import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.properties.selectedProperty,
  (state) => state.propertyViewings.viewings,
  (
    properties,
    selectedProperty,
    viewings,
  ) => {
    const property = properties[selectedProperty];

    if (!property) {
      if (selectedProperty) {
        optimizedPropertyActions.getPropertyById(selectedProperty);
      }

      return {
        events: [],
        numOfFutureEvents: 0,
      };
    }

    const events = Array.isArray(property.viewings) && property.viewings.reduce((accum, viewingId, index) => {
      const viewing = viewings[viewingId];

      if (!viewing) {
        if (viewingId) {
          optimizedPropertyViewingActions.getPropertyViewingById(viewingId);
        }

        return accum;
      }

      accum.push({
        key: index + 1,
        ...viewing,
      });
      return accum;
    }, []);

    const numOfFutureEvents = property.viewings_summary?.upcoming_viewings || 0;

    return {
      events: events || [],
      numOfFutureEvents,
    };
  },
);
