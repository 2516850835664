import deleteCompanyLogoInGlobal from './deleteCompanyLogoInGlobal';
import createCompanyLogoInGlobal from './createCompanyLogoInGlobal';
import createClientExperienceAttachmentsInGlobal from './createClientExperienceAttachmentsInGlobal';
import uploadAttachment from './uploadAttachment';
import getObjectAttachments from './getObjectAttachments';
import getObjectAttachmentsWithQuery from './getObjectAttachmentsWithQuery';
import uploadAttachmentV2 from './uploadAttachmentV2';
import deleteAttachmentV2 from './deleteAttachmentV2';
import getFileList from './getFileList';
import uploadLinkAttachment from './uploadLinkAttachment';

export default {
  deleteCompanyLogoInGlobal,
  createCompanyLogoInGlobal,
  createClientExperienceAttachmentsInGlobal,
  uploadAttachment,
  getObjectAttachments,
  getObjectAttachmentsWithQuery,
  uploadAttachmentV2,
  deleteAttachmentV2,
  getFileList,
  uploadLinkAttachment,
};
