import connectReducers from 'store/connectReducers';

import {
  OPEN_TUTORIAL_LAUNCH_MODAL,
  CLOSE_TUTORIAL_LAUNCH_MODAL,
  START_TUTORIAL,
  END_TUTORIAL,
  SHOW_TUTORIAL_LAYER,
  HIDE_TUTORIAL_LAYER,
  SET_STEP_INDEX,
  TOGGLE_APP_TOUR,
  SET_AVAILABLE_TUTORIALS,
  SET_APP_TOUR_STEP,
} from 'store/actionTypes/tutorial';

const initialState = {
  isVisible: false,
  stepIndex: 0,
  isLaunchModalVisible: false,
  isTutorialRunning: false,
  appTour: false,
  availableTutorials: [],
  appTourStep: 0,
};

const reducers = {
  [SHOW_TUTORIAL_LAYER]: (state, action) => ({
    ...state,
    isVisible: true,
  }),
  [HIDE_TUTORIAL_LAYER]: (state, action) => ({
    ...state,
    isVisible: false,
  }),
  [OPEN_TUTORIAL_LAUNCH_MODAL]: (state, action) => ({
    ...state,
    isLaunchModalVisible: true,
  }),
  [CLOSE_TUTORIAL_LAUNCH_MODAL]: (state, action) => ({
    ...state,
    isLaunchModalVisible: false,
  }),
  [START_TUTORIAL]: (state, action) => ({
    ...state,
    isTutorialRunning: true,
  }),
  [END_TUTORIAL]: (state, action) => ({
    ...state,
    isTutorialRunning: false,
  }),
  [SET_STEP_INDEX]: (state, action) => ({
    ...state,
    stepIndex: action.payload,
  }),
  [TOGGLE_APP_TOUR]: (state, action) => ({
    ...state,
    appTour: !state.appTour,
  }),
  [SET_AVAILABLE_TUTORIALS]: (state, action) => ({
    ...state,
    availableTutorials: action.payload,
  }),
  [SET_APP_TOUR_STEP]: (state, action) => ({
    ...state,
    appTourStep: action.payload,
  }),
};

const tutorial = (state = initialState, action) => connectReducers(reducers, state, action);

export default tutorial;
