import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const SignedAgreement = (props) => {
  const {
  } = props;

  return (
    <>
      <Typography variant="title1" weight="bold">
        Signed Agreement
      </Typography>

      <div style={{ height: 300, width: '100%', backgroundColor: 'lightblue' }} />
    </>
  );
};

SignedAgreement.defaultProps = {
};

const {
  number,
  string,
  func,
} = PropTypes;

SignedAgreement.propTypes = {
};

export default SignedAgreement;
