import { connect } from 'react-redux';

import selectors from 'store/selectors';
import { getContactLabelsDictionary } from 'store/actions/dictionaryV2';
import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import MultiselectTextfield from 'ui/MultiselectTextfield';

let isUpdating = false;

const mapStateToProps = (state) => {
  const allLabels = selectors.dictionary.contactLabels(state, 'all');

  const allCustomLabels = allLabels.reduce((accum, item) => {
    if (item.category === 'custom') {
      accum.push({
        value: item.id,
        label: item.name,
      });
    }

    return accum;
  }, []);

  const selectedOptions = allLabels.reduce((accum, item) => {
    if (item.category === 'custom' && item.isActive) {
      accum.push({
        value: item.id,
        label: item.name,
      });
    }

    return accum;
  }, []);

  return {
    options: allCustomLabels,
    value: selectedOptions,
    creatable: true,
    isLoading: isUpdating,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: async (values) => {
    isUpdating = true;

    const activeValues = values.map((item) => item.value);

    try {
      await requests.realtor.contactLabels.update(activeValues);
      callAlert.success('Updated successfully');
    } catch (err) {
      callAlert.error('Could not update');
    }
    isUpdating = false;

    await dispatch(getContactLabelsDictionary());
  },
  onCreate: async (newOption) => {
    isUpdating = true;

    try {
      await requests.realtor.contactLabels.create({
        name: newOption.label,
        category: 'custom',
        is_active: true,
      });
      callAlert.success('Updated successfully');
    } catch (err) {
      callAlert.error('Could not update');
    }
    isUpdating = false;

    await dispatch(getContactLabelsDictionary());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiselectTextfield);
