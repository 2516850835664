import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import InputGroup from 'ui/InputGroup';
import Textfield from 'ui/Textfield';
import DropdownTextfield from 'ui/DropdownTextfield';

const options = [
  {
    value: 'all',
    label: 'All Contacts',
  },
  // {
  //   value: 'active',
  //   label: 'Active',
  // },
  // {
  //   value: 'inactive',
  //   label: 'Inactive',
  // },
  {
    value: 'archived',
    label: 'Archived',
  },
];

const Search = (props) => {
  const {
    onSearch,
    handleStatusFilter,
    selectedFilterKey,
  } = props;

  const handleSearch = useCallback((value) => {
    onSearch(value);
  }, [
    onSearch,
  ]);

  return (
    <InputGroup
      flat
    >
      <DropdownTextfield
        flat
        value={selectedFilterKey}
        onChange={handleStatusFilter}
        options={options}
      />
      <Textfield
        flat
        onChange={handleSearch}
        icon="search"
        fullWidth
      />
    </InputGroup>
  );
};

Search.defaultProps = {
  selectedFilterKey: 'all',
};

const {
  func,
  string,
} = PropTypes;

Search.propTypes = {
  onSearch: func.isRequired,
  handleStatusFilter: func.isRequired,
  selectedFilterKey: string,
};

export default Search;
