import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

const FollowUp = (props) => {
  const {
    openLeadRemindersModal,
    getLeadReminders,
    followUpsCount,
  } = props;

  useEffect(() => {
    getLeadReminders();
  }, []);

  const handleClick = useCallback((event) => {
    event.preventDefault();
    openLeadRemindersModal();
  }, [openLeadRemindersModal]);

  return (
    <Button
      onClick={handleClick}
      icon="phone-forwarded"
      variant="primary"
    >
      {`Follow Ups (${followUpsCount})`}
    </Button>
  );
};

FollowUp.defaultProps = {
  followUpsCount: 0,
};

const {
  func,
  number,
} = PropTypes;

FollowUp.propTypes = {
  openLeadRemindersModal: func.isRequired,
  getLeadReminders: func.isRequired,
  followUpsCount: number,
};

export default FollowUp;
