/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Tag from 'ui/Tag';

import withTags from 'ui/hocs/withTags';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import ContactNameInput from 'sections/Contacts/modules/ContactNameInput';
import ContactLabelsRaw from 'sections/Contacts/modules/ContactLabels';
import ContactPhoneNumbers from 'sections/Contacts/modules/ContactPhoneNumbers';
import ContactEmails from 'sections/Contacts/modules/ContactEmails';

import withInlineLabel from 'shared/hocs/withInlineLabelV2';

const ContactLabels = withInlineLabel(ContactLabelsRaw);

const overFlowStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const ContactInfoConcise = (props) => {
  const {
    contactId,
    editable,
    nameTypographyProps,
    label,
    labelProps,
    labels,

    // custom components
    avatar,
    avatarProps,
  } = props;

  let vip;

  if (labels) {
    vip = labels.some((item) => item.label_id === 14);
  }

  const TaggedContactAvatar = withTags(ContactAvatar)(vip ? 'vip' : null, { size: 's' });

  return (
    <ErrorBoundary>
      <Flex
        spacing={2}
        flexWrap="wrap"
      >
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
        >
          {
            avatar || (
              <TaggedContactAvatar
                contactId={contactId}
                {...avatarProps} // eslint-disable-line react/jsx-props-no-spreading
              />
            )
          }

          <FlexItem flex={1}>
            <Flex
              spacing={1}
              flexWrap="wrap"
            >
              <FlexItem
                fullWidth
                style={overFlowStyle}
              >
                <ContactNameInput
                  contactId={contactId}
                  displayTypographyProps={nameTypographyProps}
                  defaultView="display"
                />
              </FlexItem>

              {
                label && (
                  <FlexItem fullWidth>
                    <Tag
                      {...labelProps} // eslint-disable-line react/jsx-props-no-spreading
                    >
                      {label}
                    </Tag>
                  </FlexItem>
                )
              }

              <FlexItem
                fullWidth
                style={overFlowStyle}
              >
                <DecoratedFieldWrapper
                  iconName="mail"
                  iconType="feather"
                  size="sm"
                  variant="focus"
                >
                  <ContactEmails
                    contactId={contactId}
                    withEditMode={editable}
                    bareEllipsisStyles
                  />
                </DecoratedFieldWrapper>
              </FlexItem>

              <FlexItem fullWidth>
                <DecoratedFieldWrapper
                  iconName="phone"
                  iconType="feather"
                  variant="focus"
                  size="sm"
                >
                  <ContactPhoneNumbers
                    contactId={contactId}
                    withEditMode={editable}
                    bareEllipsisStyles
                  />
                </DecoratedFieldWrapper>
              </FlexItem>
            </Flex>
          </FlexItem>

          <FlexItem fullWidth>
            <ContactLabels
              contactId={contactId}
              alignItems="center"
              labelProps={{
                weight: 500,
                noMargin: true,
                style: { marginRight: 10 },
              }}
            />
          </FlexItem>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

ContactInfoConcise.defaultProps = {
  contactId: null,
  labels: null,
  email: null,
  avatar: null,
  editable: true,
  nameTypographyProps: {
    variant: 'title1',
  },
  label: null,
  labelProps: {},
  isPastClient: false,
  avatarProps: {},
};

const {
  arrayOf,
  bool,
  element,
  number,
  shape,
  string,
} = PropTypes;

ContactInfoConcise.propTypes = {
  contactId: number,
  labels: arrayOf({}),
  email: string,
  editable: bool,
  nameTypographyProps: shape(),
  label: string,
  labelProps: shape(),
  avatar: element,
  isPastClient: bool,
  avatarProps: shape(),
};

export default ContactInfoConcise;
