import connectReducers from 'store/connectReducers';

import {
  SET_FULFILLMENT_FILTER_ITEM,
  RESET_FULFILLMENT_FILTER_ITEM,
  CLEAR_FULFILLMENT_FILTER,
} from 'store/actionTypes/fulfillments';

const initialState = {
  search: null,
  status: [], // int
  pageNumber: 1,
  pageSize: 10,
  activeSummaryFilter: null,
  selectedCustomFilterValue: 0,

  sortBy: 'id',
  sortDirection: 'desc',

  // permissionType: [], // int
  // ownedByTeams: [], // int
  // ownedByEmployees: [], // int
  // permissionsForEmployees: [], // int
  // permissionsForTeams: [], // int
  // permissionsForOffices: [], // int
};

const reducers = {
  [SET_FULFILLMENT_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),

  [RESET_FULFILLMENT_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload]: initialState[action.payload],
  }),

  [CLEAR_FULFILLMENT_FILTER]: (state, action) => initialState,
};

function fulfillmentsFilter(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default fulfillmentsFilter;
