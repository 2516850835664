import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} contact
 * @param {string} contact.FirstName
 * @param {string} contact.LastName
 */
const create = async (contact) => {
  const endpoint = endpoints.client.crm.contacts;

  try {
    const result = await apiConnector.client.post(endpoint, contact);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
