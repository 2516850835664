import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_PROPERTY_MODAL_OPEN,
  SET_NEW_REALTOR_PROPERTY_MODAL_CLOSED,
} from 'store/actionTypes/realtorMls';

import {
  setVisibilityOn,
  setVisibilityOff,
} from 'store/utils';

const initialState = {
  isVisible: false,
};

const reducers = {
  [SET_NEW_REALTOR_PROPERTY_MODAL_OPEN]: setVisibilityOn,

  [SET_NEW_REALTOR_PROPERTY_MODAL_CLOSED]: setVisibilityOff,
};

function newPropertyModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newPropertyModal;
