import {
  SET_PROPERTY,
} from 'store/actionTypes/properties';

const setProperty = (property) => ({
  type: SET_PROPERTY,
  payload: property,
});

export default setProperty;
