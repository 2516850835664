import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Popover, Button } from 'antd';
import Typography from 'ui/Typography';
import Avatar from '@material-ui/core/Avatar';

import {
  Root,
  Row,
  Data,
} from './styledItems';

const buttonStyle = { padding: '0 0 0 3px', fontSize: 11, height: 'auto' };

const ContactPopover = ({ contact }) => {
  const {
    email_primary,
    phone_primary,
    first_name,
    last_name,
  } = contact;

  const getPopoverContent = useCallback(() => (
    <Root>
      <Typography
        variant="h5"
        weight="bold"
        noMargin
      >
        Contact Already Exists
      </Typography>
      <Row>
        <Avatar>
          {first_name?.[0]?.toUpperCase() + last_name?.[0]?.toUpperCase()}
        </Avatar>
        <Data>
          <Typography noMargin>{`${first_name || ''} ${last_name || ''}`}</Typography>
          <Typography noMargin>{`Email: ${email_primary || 'N/A'}`}</Typography>
          <Typography noMargin>{`Phone: ${phone_primary || 'N/A'}`}</Typography>
        </Data>
      </Row>
    </Root>
  ), [
    phone_primary,
    email_primary,
    first_name,
    last_name,
  ]);

  return (
    <Popover
      content={getPopoverContent()}
    >
      <Button
        type="link"
        style={buttonStyle}
      >
        <Typography
          textDecoration="underline"
          // color="additional"
          noMargin
        >
          View
        </Typography>
      </Button>
    </Popover>
  );
};

const {
  number,
  string,
  shape,
} = PropTypes;

ContactPopover.defaultProps = {
  contact: {},
};

ContactPopover.propTypes = {
  contact: shape({
    id: number,
    email_primary: string,
    first_name: string,
    last_name: string,
  }),
};

export default ContactPopover;
