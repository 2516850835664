/* eslint-disable react/require-default-props */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import socket from 'shared/sockets/OASocket';
import { userType, meetingType, meetingParticipantType } from 'oa/types/index';
import WaitingForCallee from './components/WaitingForCallee/index';
import IncomingCall from './components/IncomingCall/index';
import InvitedUsers from './components/InvitedUsers/index';
import OACallContext from './OACallContext';

const OACall = (props) => {
  const {
    children,
    loggedUser,

    setActiveMeeting,
    expandModal,
    createMeeting,

    onlineUsers,
    callee,
    caller,
    isCallDeclined,
    invitedEmails,
    meetingForInvitations,
    activeMeeting,
    activeMeetingParticipants,

    setOnlineUsers,
    setCallee,
    setCaller,
    setIsCallDeclined,
    setInvitedEmails,
    createInvitations,
    setMeetingForInvitations,
  } = props;

  // TODO: move to global state
  const [meetingFromCaller, setMeetingFromCaller] = useState(null);

  const onGetConnectedClients = useCallback((clients) => {
    setOnlineUsers(clients);
  }, [setOnlineUsers]);

  const onIncomingClientCall = useCallback((data) => {
    const newCaller = onlineUsers.find((onlineUser) => (
      onlineUser.socketIds.includes(data.from)
    ));

    if (data.meeting) {
      setMeetingFromCaller(data.meeting);
    }

    setCaller(newCaller);
  }, [onlineUsers, setCaller]);

  const onCancelClientCall = useCallback(() => {
    setCaller(null);
  }, [setCaller]);

  const onClientCallDeclined = useCallback(() => {
    if (caller) {
      setCaller(null);
    } else if (callee) {
      setIsCallDeclined(true);
    }
  }, [caller, callee, setCaller, setIsCallDeclined]);

  const onClientCallAccepted = useCallback((data) => {
    if (caller) {
      setCaller(null);
    } else if (callee) {
      setCallee(null);
      setActiveMeeting(data.meeting);
      expandModal();
    }
  }, [callee, caller, setActiveMeeting, expandModal, setCaller, setCallee]);

  useEffect(() => {
    socket.on('get-connected-clients', onGetConnectedClients);
    socket.on('incoming-client-call', onIncomingClientCall);
    socket.on('cancel-client-call', onCancelClientCall);
    socket.on('client-call-declined', onClientCallDeclined);
    socket.on('client-call-accepted', onClientCallAccepted);

    return () => {
      socket.off('get-connected-clients', onGetConnectedClients);
      socket.off('incoming-client-call', onIncomingClientCall);
      socket.off('cancel-client-call', onCancelClientCall);
      socket.off('client-call-declined', onClientCallDeclined);
      socket.off('client-call-accepted', onClientCallAccepted);
    };
  }, [
    onGetConnectedClients,
    onIncomingClientCall,
    onCancelClientCall,
    onClientCallDeclined,
    onClientCallAccepted,
  ]);

  useEffect(() => {
    if (!loggedUser) return;

    const isUserAlreadyOnline = onlineUsers.find((onlineUser) => (
      onlineUser.EmployeeID === loggedUser.EmployeeID
    ));

    if (isUserAlreadyOnline) return;

    socket.emit('add-client', loggedUser);
  }, [loggedUser, onlineUsers]);

  const callUsers = useCallback(({ employeeIds, meeting }) => {
    const users = onlineUsers.filter((onlineUser) => (
      employeeIds.includes(onlineUser.EmployeeID)
    ));

    if (users.length === 0) return;

    const to = users.reduce((collection, user) => collection.concat(user.socketIds), []);

    socket.emit('call-clients', {
      from: socket.id,
      to,
      meeting,
    });
  }, [onlineUsers]);

  const callUser = useCallback((employeeId) => {
    const user = onlineUsers.find((onlineUser) => (
      onlineUser.EmployeeID === employeeId
    ));

    if (user) {
      const callData = {
        from: socket.id,
        to: user.socketIds,
      };

      if (activeMeeting.code) {
        callData.meeting = activeMeeting;
      }

      socket.emit('call-client', callData);

      setCallee(user);
    }
  }, [onlineUsers, setCallee, activeMeeting]);

  const cancelCall = useCallback(() => {
    socket.emit('cancel-client-call', {
      from: socket.id,
      to: callee.socketIds,
    });

    setCallee(null);
  }, [callee, setCallee]);

  const declineCall = useCallback(() => {
    const currentClient = onlineUsers.find((onlineUser) => (
      onlineUser.EmployeeID === loggedUser.EmployeeID
    ));

    const siblingSocketIds = currentClient.socketIds.filter((socketId) => (
      socketId !== socket.id
    ));

    socket.emit('client-call-declined', {
      from: socket.id,
      to: [
        ...caller.socketIds,
        ...siblingSocketIds,
      ],
    });

    setMeetingFromCaller(null);
    setCaller(null);
  }, [onlineUsers, loggedUser, caller, setCaller]);

  const resetCallStates = useCallback(() => {
    setIsCallDeclined(false);
    setCallee(null);
  }, [setIsCallDeclined, setCallee]);

  const closeInvitedUsersModal = useCallback(() => {
    setInvitedEmails([]);
  }, [setInvitedEmails]);

  const acceptCall = useCallback(async () => {
    const callerSocketIds = caller.socketIds;

    let meetingToJoin;

    if (meetingFromCaller) {
      meetingToJoin = meetingFromCaller;
    } else {
      meetingToJoin = await createMeeting({
        name: `The call #${Date.now()}`,
        code: generateMeetingCode(),
        password: null,
        desiredOutcomes: [],
      });
    }

    const currentClient = onlineUsers.find((onlineUser) => (
      onlineUser.EmployeeID === loggedUser.EmployeeID
    ));

    const siblingSocketIds = currentClient.socketIds.filter((socketId) => (
      socketId !== socket.id
    ));

    socket.emit('client-call-accepted', {
      from: socket.id,
      to: [
        ...callerSocketIds,
        ...siblingSocketIds,
      ],
      meeting: meetingToJoin,
    });

    setActiveMeeting(meetingToJoin);
    expandModal();

    setCaller(null);
  }, [
    meetingFromCaller,
    onlineUsers,
    loggedUser,
    caller,
    createMeeting,
    setActiveMeeting,
    expandModal,
    setCaller,
  ]);

  const inviteUsers = useCallback(async (emails) => {
    const createdMeeting = await createMeeting({
      name: `The call #${Date.now()}`,
      code: generateMeetingCode(),
      password: null,
      desiredOutcomes: [],
    });

    setMeetingForInvitations(createdMeeting);
    setInvitedEmails(emails);
  }, [createMeeting, setMeetingForInvitations, setInvitedEmails]);

  const inviteUsersToMeeting = useCallback((data) => {
    setMeetingForInvitations(data.meeting);
    setInvitedEmails(data.emails);
  }, [setMeetingForInvitations, setInvitedEmails]);

  const confirmUsersInvitation = useCallback(async () => {
    await createInvitations({
      meetingId: meetingForInvitations.id,
      payload: {
        emails: invitedEmails,
        userCompany: loggedUser.CompanyName,
        userToken: localStorage.getItem('token'),
      },
    });

    setInvitedEmails([]);
    setMeetingForInvitations(null);
  }, [
    createInvitations,
    meetingForInvitations,
    invitedEmails,
    loggedUser,
    setInvitedEmails,
    setMeetingForInvitations,
  ]);

  const isUserAlreadyInMeeting = useCallback((employeeId) => {
    const user = onlineUsers.find((onlineUser) => (
      employeeId === onlineUser.EmployeeID
    ));

    if (!user) return false;

    const participantsSocketIds = activeMeetingParticipants.map((participant) => (
      participant.socketId
    ));

    const doesSocketIdMatch = participantsSocketIds.some((socketId) => (
      user.socketIds.includes(socketId)
    ));

    return doesSocketIdMatch;
  }, [onlineUsers, activeMeetingParticipants]);

  const contextProps = {
    callUser,
    callUsers,
    inviteUsers,
    inviteUsersToMeeting,
    isUserAlreadyInMeeting,
    onlineUsers,
  };

  return (
    <OACallContext.Provider value={contextProps}>
      {children}

      <WaitingForCallee
        callee={callee}
        isCallDeclined={isCallDeclined}
        onCancel={cancelCall}
        onOk={resetCallStates}
      />

      <IncomingCall
        caller={caller}
        onCancel={declineCall}
        onOk={acceptCall}
      />

      <InvitedUsers
        meeting={meetingForInvitations}
        emails={invitedEmails}
        onCancel={closeInvitedUsersModal}
        onOk={confirmUsersInvitation}
      />
    </OACallContext.Provider>
  );
};

const generateMeetingCode = () => (
  Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-4)
);

OACall.propTypes = {
  children: PropTypes.node.isRequired,
  loggedUser: userType,

  setActiveMeeting: PropTypes.func.isRequired,
  expandModal: PropTypes.func.isRequired,
  createMeeting: PropTypes.func.isRequired,

  onlineUsers: PropTypes.arrayOf(userType).isRequired,
  activeMeeting: meetingType,
  activeMeetingParticipants: PropTypes.arrayOf(meetingParticipantType).isRequired,
  callee: userType,
  caller: userType,
  isCallDeclined: PropTypes.bool.isRequired,
  invitedEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  meetingForInvitations: meetingType,

  setOnlineUsers: PropTypes.func.isRequired,
  setCallee: PropTypes.func.isRequired,
  setCaller: PropTypes.func.isRequired,
  setIsCallDeclined: PropTypes.func.isRequired,
  setInvitedEmails: PropTypes.func.isRequired,
  createInvitations: PropTypes.func.isRequired,
  setMeetingForInvitations: PropTypes.func.isRequired,
};

export default OACall;
