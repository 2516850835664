import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import AutocompleteWithAdd from 'shared/components/UI/AutocompleteWithAdd';
import SourceDescriptionModal from './SourceDescriptionModal';
import NewSourceModal from './NewSourceModal';

const getAddTitle = (value) => `+ Add ${value} as custom lead source`;

const SourceSelect = (props) => {
  const {
    options,
    onSelect,
    addSourceType,
    onAddNewSourceTypeClick,
    newCustomSourceName,
    value,
  } = props;

  const [isDescriptionModalVisible, setDescriptionModalVisibility] = useState(false);
  const [isNewSourceModalVisible, setNewSourceModalVisibility] = useState(false);

  const handleSelect = useCallback((sourceType) => {
    if (sourceType) {
      onSelect(sourceType.id);
      setDescriptionModalVisibility(true);
    }
  }, [
    setDescriptionModalVisibility,
  ]);

  const handleNewItemClick = useCallback((newValue) => {
    onAddNewSourceTypeClick(newValue);
    setNewSourceModalVisibility(true);
  });

  const closeDescriptionModal = useCallback(() => {
    setDescriptionModalVisibility(false);
  }, [
    setDescriptionModalVisibility,
  ]);

  const closeNewSourceModal = useCallback(() => {
    setNewSourceModalVisibility(false);
  }, [
    setNewSourceModalVisibility,
  ]);

  const onCustomSourceSave = useCallback(() => {
    addSourceType(newCustomSourceName);
  });

  return (
    <>
      <AutocompleteWithAdd
        label="Source"
        options={options}
        onItemSelect={handleSelect}
        getAddTitle={getAddTitle}
        onAddNewItemClick={handleNewItemClick}
        value={value}
      />
      <SourceDescriptionModal
        isVisible={isDescriptionModalVisible}
        closeModal={closeDescriptionModal}
      />
      <NewSourceModal
        isVisible={isNewSourceModalVisible}
        closeModal={closeNewSourceModal}
        onSave={onCustomSourceSave}
      />
    </>
  );
};

SourceSelect.defaultProps = {
  options: [],
  newCustomSourceName: null,
  value: '',
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

SourceSelect.propTypes = {
  options: arrayOf(shape({
    id: number,
    title: string,
  })),
  onSelect: func.isRequired,
  addSourceType: func.isRequired,
  onAddNewSourceTypeClick: func.isRequired,
  newCustomSourceName: string,
  value: string,
};

export default SourceSelect;
