import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

const deleteContact = (contactId) => async (dispatch) => {
  try {
    await requests.realtor.contacts.deleteContact(contactId);
    callAlert.success('Deleted');
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteContact;
