import { connect } from 'react-redux';

import URLThumbnails from './URLThumbnails'; // eslint-disable-line import/no-cycle

const mapStateToProps = async (state, ownProps) => {
  const {
    url,
  } = ownProps;

  return {
    url,
  };
};

export default connect(mapStateToProps)(URLThumbnails);
