import {
  RESET_REALTOR_DOCUMENT_REQUESTS_FILTERS,
  RESET_REALTOR_DOCUMENT_REQUESTS_FILTER_COUNTERS,
} from 'store/actionTypes/realtorDocumentRequests';

const resetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_REALTOR_DOCUMENT_REQUESTS_FILTERS,
  });

  dispatch({
    type: RESET_REALTOR_DOCUMENT_REQUESTS_FILTER_COUNTERS,
  });
};

export default resetFilters;
