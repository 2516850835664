import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';
import withOptionsMenu from 'ui/withOptionsMenu';

import useContactPhoneNumbers from 'shared/hooks/contacts/useContactPhoneNumbers';

import {
  getOptions,
} from './utils';

/* eslint-disable react/destructuring-assignment, react/jsx-props-no-spreading, react/prop-types */
const InnerComponent = (props) => (
  <Flex
    alignItems="center"
    spacing={1}
    style={props.style}
  >
    <FlexItem>
      <Typography
        {...props}
        color={props.color}
        variant={props.variant}
        tag="p"
        noMargin
      >
        {props.value}
      </Typography>
    </FlexItem>
    <FlexItem>
      <Icon
        name="chevron-down"
        color={props.color}
        size="s"
      />
    </FlexItem>
  </Flex>
);
/* eslint-enable react/destructuring-assignment, react/jsx-props-no-spreading, react/prop-types */

const Menu = withOptionsMenu(InnerComponent);

const ContactPhoneNumberSelector = (props) => {
  const {
    contactId,
    value,
    onSelect,
    disabled,
    color,
    variant,
    style,
  } = props;

  const contactPhoneNumbers = useContactPhoneNumbers(contactId);
  const options = getOptions(contactPhoneNumbers);

  return (
    <Menu
      value={value}
      options={options}
      onChange={onSelect}
      disabled={disabled}
      color={color}
      variant={variant}
      style={style}
    />
  );
};

ContactPhoneNumberSelector.defaultProps = {
  value: null,
  disabled: false,
  color: 'default',
  variant: 'body',
  style: {},
};

const {
  number,
  string,
  bool,
  func,
  shape,
} = PropTypes;

ContactPhoneNumberSelector.propTypes = {
  contactId: number.isRequired,
  value: string,
  onSelect: func.isRequired,
  disabled: bool,
  color: string,
  variant: string,
  style: shape({}),
};

export default ContactPhoneNumberSelector;
