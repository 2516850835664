import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: solid 1px #97979747;
  padding: 5px 0;
  .address-text {
    margin-bottom: 5px !important;
  }
`;

export const CenteredBox = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

export default {
  Wrapper,
  CenteredBox,
};
