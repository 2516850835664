import apiConnector, { endpoints } from 'api';

const create = async (offerData) => {
  const endpoint = endpoints.external.realtor.offers;
  try {
    const result = await apiConnector.external.post(endpoint, offerData);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
