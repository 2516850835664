import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchItemContact from 'shared/components/UI/SearchItemContact';

const mapStateToProps = (state, ownProps) => {
  const {
    objectId,
  } = ownProps;

  const lead = selectors.realtorLeads.byId(state, objectId);

  return {
    leadId: objectId,
    contactId: lead?.contact_id,
    labels: lead?.labels,
  };
};

export default connect(mapStateToProps)(SearchItemContact);
