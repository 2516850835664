import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getOpportunitiesForFilter from './getOpportunitiesForFilter';

const getOpportunitiesForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.realtorOpportunities.filter(getState());

  try {
    await dispatch(getOpportunitiesForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getOpportunitiesForStoreFilter;
