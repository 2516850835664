import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import noOp from 'shared/utils/noOp';

import Flex from 'ui/Flex';
import FileDropzone from 'ui/FileDropzone';

import Thumb from 'ui/SelectorItem';

const sizeProps = {
  s: {
    thumbIconSize: 's',
    thumbTypographyProps: {
      variant: 'body2',
    },
  },
  lg: {
    thumbIconSize: null,
    thumbTypographyProps: {},
  },
};

const FileDropzoneWithThumbs = (props) => {
  const {
    dropzoneProps,
    onChange,
    isLoading,
    size,
    files: filesProps,
  } = props;

  const {
    maxFiles = 10,
  } = dropzoneProps;

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(filesProps);
  }, [
    JSON.stringify(filesProps),
  ]);

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }));

    const merged = [...files, ...newFiles];

    let mergedWithMaxFiles = [];

    if (merged.length <= maxFiles) {
      mergedWithMaxFiles = merged;
    } else {
      mergedWithMaxFiles = merged.slice(-maxFiles);
    }

    setFiles(mergedWithMaxFiles);
    onChange(mergedWithMaxFiles);
  });

  const removeFile = useCallback((index) => () => {
    const filesCopy = [...files];

    filesCopy.splice(index, 1);

    setFiles(filesCopy);
    onChange(filesCopy);
  });

  const {
    thumbIconSize,
    thumbTypographyProps,
  } = sizeProps[size];

  return (
    <Flex
      spacing={2}
      flexDirection="column"
      fullWidth
    >
      {
        (files.length < maxFiles || isLoading) && (
          <FileDropzone
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            size={size}
            dropzoneProps={{
              ...dropzoneProps,
              // accept: 'image/*',
              onDrop,
            }}
          />
        )
      }
      {
        !isLoading && (
          <Flex spacing={1} justifyContent="center" fullWidth>
            {
              files.map((file, index) => (
                <Thumb
                  iconName="file"
                  iconSize={thumbIconSize}
                  variant="vertical"
                  key={JSON.stringify(file)}
                  onClick={removeFile(index)}
                  typographyProps={thumbTypographyProps}
                >
                  {file.name}
                </Thumb>
                ))
            }
          </Flex>
        )
      }
    </Flex>
  );
};

FileDropzoneWithThumbs.defaultProps = {
  dropzoneProps: {
    maxFiles: 10,
  },
  onChange: noOp,
  isLoading: false,
  size: 'lg',
  files: [],
};

const {
  bool,
  func,
  number,
  shape,
  oneOf,
  arrayOf,
} = PropTypes;

FileDropzoneWithThumbs.propTypes = {
  dropzoneProps: shape({
    maxFiles: number,
  }),
  onChange: func,
  isLoading: bool,
  size: oneOf(['s', 'lg']),
  files: arrayOf(shape({})),
};

export default FileDropzoneWithThumbs;
