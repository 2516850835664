import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SentimentInput from 'ui/SentimentInput';

import {
  updateMatchingProperty,
} from 'store/actions/realtorMatchingProperties';

const mapStateToProps = (state, ownProps) => {
  const matchingProperty = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  return {
    sentiment: matchingProperty?.feedback_sentiment,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateMatchingProperty(ownProps.matchId)({
      feedback_sentiment: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SentimentInput);
