import { connect } from 'react-redux';

import { isEmpty } from 'shared/utility';

import {
  createDocumentRequest,
  addDocumentRequestRelations,
} from 'store/actions/realtorDocumentRequests';

import { createContact } from 'store/actions/realtorContacts';

import { uploadAttachment } from 'store/actions/attachmentsV2';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import {
  getTransactionById,
} from 'store/actions/realtorTransactions';

import DocumentRequestForm from './DocumentRequestForm';

const mapStateToProps = (state) => {
  const {
    realtorDocumentRequestForm: {
      contactId,
      dateTime,
      description,
      title,

      onAfterSubmit,

      attachments,
    },
    realtorTransactions: {
      selectedTransaction,
    },
  } = state;

  return {
    contactId,
    dateTime,
    description,
    title,

    onAfterSubmit,

    attachments,
    selectedTransaction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createDocumentRequest: async (data) => {
    const {
      selectedContactId,
      selected_contacts,
      new_contact,
      attachments,
      title,
      description,
      transaction_id,
      request_date,
    } = data;

    const requestor_contact_id = isEmpty(selected_contacts)
      ? selectedContactId || await dispatch(createContact(new_contact)) : null;

    const newDocumentRequestId = await dispatch(createDocumentRequest({
      title,
      description,
      request_date,
      realtor_deal_id: transaction_id,
    }));

    if (!isEmpty(selected_contacts)) {
      await dispatch(addDocumentRequestRelations('contact')(newDocumentRequestId)(selected_contacts));
    }

    if (requestor_contact_id) {
      await dispatch(addDocumentRequestRelations('contact')(newDocumentRequestId)([requestor_contact_id]));
    }

    // upload attachments

    const uploadAttachmentsRequests = attachments.map(async (item) => {
      const {
        url: attachmentUrl,
        name: attachmentName,
        type: attachmentType,
      } = item;

      const file = await dataUrlToFile(
        attachmentUrl,
        attachmentName,
        attachmentType,
      );

      const attachmentsData = {
        file,
        objectId: newDocumentRequestId,
        objectType: 37,
        attachmentType: 12,
      };

      await dispatch(uploadAttachment(attachmentsData));
    });

    await Promise.all(uploadAttachmentsRequests);

    return newDocumentRequestId;
  },

  updateTransaction: (transactionId) => {
    dispatch(getTransactionById(transactionId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: async (data) => {
    await dispatchProps.createDocumentRequest({
      ...data,
      transaction_id: stateProps.selectedTransaction,
      attachments: stateProps.attachments,
    });

    if (ownProps.onAfterSubmit) {
      ownProps.onAfterSubmit();
    }

    if (stateProps.onAfterSubmit) {
      stateProps.onAfterSubmit();
    }

    dispatchProps.updateTransaction(stateProps.selectedTransaction);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DocumentRequestForm);
