import create from './create';
import getById from './getById';
import update from './update';
import deleteById from './deleteById';

export default {
  create,
  getById,
  update,
  deleteById,
};
