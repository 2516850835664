import requests from 'api/requests';

import setFulfillmentProductLineItems from './setFulfillmentProductLineItems';

const getFulfillmentProductLineItems = (fulfillmentId) => (productId) => (status) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.fulfillments.getLineItemDetails(fulfillmentId)(productId)({
      status,
      offset,
      limit,
    });

    dispatch(setFulfillmentProductLineItems(fulfillmentId)(productId)(status)(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentProductLineItems;
