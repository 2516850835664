import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} product
 * @param {string} product.name
 */
const create = async (product) => {
  const endpoint = endpoints.client.crm.products;

  try {
    const result = await apiConnector.client.post(endpoint, product);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
