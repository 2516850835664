import styled from 'styled-components';

import Flex from 'ui/Flex';

export const Root = styled(Flex)`
  background-color: ${(props) => props?.theme?.palette?.['background-flat'] || 'blue'};
  padding: 8px;
  border-radius: 4px;
  height: 70px;
`;

export default {
  Root,
};
