import requests from 'api/requests';

import setContactsLabels from '../setContactsLabels';

const getContactsLabels = () => async (dispatch) => {
  try {
    const response = await requests.labels.getLabels(7);

    dispatch(setContactsLabels(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getContactsLabels;
