import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

const deleteInspection = (inspectionId) => async (dispatch) => {
  try {
    await requests.realtor.inspections.deleteInspection(inspectionId);
    callAlert.success('Deleted');
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteInspection;
