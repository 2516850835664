import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  width: 176px;
  padding: 5px;
  position: relative;
  img {
    max-width: 150px;
    max-height: 50px;
    display: block;
  }
`;
