import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { isObject } from 'shared/utility';

import Flex from 'ui/Flex';
import Chip from 'ui/Chip';
import Tooltip from 'ui/Tooltip';

const ChipList = (props) => {
  const {
    onAdd,
    onRemove,
    items,
    CustomChip,
    ellipsis,
    variant,
  } = props;

  const ChipComponent = CustomChip || Chip;

  let itemsToShow = items;
  let ellipsisItems = [];

  if (ellipsis && items) {
    itemsToShow = items.slice(0, ellipsis);
    ellipsisItems = items.slice(ellipsis);
  }

  const handleDelete = useCallback((item) => () => {
    onRemove(item);
  }, [
    onRemove,
  ]);

  const listChips = (list) => (
    list?.map((item) => (
      <ChipComponent
        key={isObject(item) ? item.value : item}
        item={item}
        onDelete={onRemove ? handleDelete(item) : null}
        variant={variant}
      >
        {isObject(item) ? item.label : item}
      </ChipComponent>
    ))
  );

  return (
    <Flex
      spacing={1}
      flexWrap="wrap"
    >
      {
        listChips(itemsToShow)
      }
      {
        ellipsisItems.length ? (
          <Tooltip
            placement="right"
            title={(
              <Flex
                flexDirection="column"
                spacing={1}
              >
                { listChips(ellipsisItems) }
              </Flex>
            )}
          >
            <div>
              <Chip
                variant={variant}
              >
                +
                { ellipsisItems.length }
              </Chip>
            </div>
          </Tooltip>
        ) : null
      }
      {
        onAdd && (
          <Chip
            variant={variant}
            onClick={onAdd}
          >
            +
          </Chip>
        )
      }
    </Flex>
  );
};

ChipList.defaultProps = {
  onAdd: null,
  onRemove: null,
  items: [],
  ellipsis: null,
  CustomChip: null,
  variant: 'subtle',
};

const {
  element,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

ChipList.propTypes = {
  onAdd: func,
  onRemove: func,
  items: oneOfType(
    [
      shape({
        label: oneOfType([string, element]),
        value: oneOfType([string, number]),
      }),
      string,
      number,
    ],
  ),
  CustomChip: func,
  ellipsis: number,
  variant: oneOf(['focus', 'subtle']),
};

export default ChipList;
