import apiConnector, { endpoints } from 'api';

const setBillingPeriodType = async (type) => {
  const endpoint = `${endpoints.global.payment.setBillingPeriodType}/${type}`;

  try {
    const result = await apiConnector.global.put(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default setBillingPeriodType;
