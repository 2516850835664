import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import AddressInput from 'sections/Addresses/modules/AddressInput';

const mapStateToProps = (state, ownProps) => {
  const contact = selectors.realtorContacts.byId(state, ownProps.contactId);

  return {
    addressId: contact?.address_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (addressId) => {
    dispatch(updateContact(ownProps.contactId)({
      address_id: addressId,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressInput);
