export const url = process.env.REACT_APP_SYMPLETE_CLIENT_API
  ? process.env.REACT_APP_SYMPLETE_CLIENT_API
  : '';

export const globalUrl = process.env.REACT_APP_SYMPLETE_GLOBAL_API
  ? process.env.REACT_APP_SYMPLETE_GLOBAL_API
  : '';

export const sympleteClientApiUrl = process.env.REACT_APP_SYMPLETE_CLIENT_API
  ? process.env.REACT_APP_SYMPLETE_CLIENT_API
  : '';
