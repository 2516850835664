export const GET_TEAM_SETUP_LIST = 'GET_TEAM_SETUP_LIST';
export const GET_TEAM_SETUP_LIST_SUCCESS = 'GET_TEAM_SETUP_LIST_SUCCESS';
export const GET_TEAM_SETUP_LIST_ERROR = 'GET_TEAM_SETUP_LIST_ERROR';
export const GET_TEAM_CATEGORIES = 'GET_TEAM_CATEGORIES';
export const GET_TEAM_CATEGORIES_SUCCESS = 'GET_TEAM_CATEGORIES_SUCCESS';
export const GET_TEAM_CATEGORIES_ERROR = 'GET_TEAM_CATEGORIES_ERROR';
export const GET_TEAM_SUBCATEGORIES = 'GET_TEAM_SUBCATEGORIES';
export const GET_TEAM_SUBCATEGORIES_SUCCESS = 'GET_TEAM_SUBCATEGORIES_SUCCESS';
export const GET_TEAM_SUBCATEGORIES_ERROR = 'GET_TEAM_SUBCATEGORIES_ERROR';
export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_ERROR = 'ADD_TEAM_ERROR';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const EIDT_CATEGORY = 'EIDT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EIDT_CATEGORY_ERROR = 'EIDT_CATEGORY_ERROR';
export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export const ADD_SUBCATEGORY_SUCCESS = 'ADD_SUBCATEGORY_SUCCESS';
export const ADD_SUBCATEGORY_ERROR = 'ADD_SUBCATEGORY_ERROR';
export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY';
export const EDIT_SUBCATEGORY_SUCCESS = 'EDIT_SUBCATEGORY_SUCCESS';
export const EDIT_SUBCATEGORY_ERROR = 'EDIT_SUBCATEGORY_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';
export const DELETE_SUBCATEGORY_SUCCESS = 'DELETE_SUBCATEGORY_SUCCESS';
export const DELETE_SUBCATEGORY_ERROR = 'DELETE_SUBCATEGORY_ERROR';
export const OPEN_MODAL = 'OPEN_TEAM_MODAL';
export const HIDE_MODAL = 'HIDE_TEAM_MODAL';
export const SET_ACTIVE_TEAM = 'SET_ACTIVE_TEAM';
export const GET_ACTIVE_TEAM = 'GET_ACTIVE_TEAM';
export const GET_TEAM_DATA = 'GET_TEAM_DATA';
export const GET_TEAM_DATA_SUCCESS = 'GET_TEAM_DATA_SUCCESS';
export const GET_TEAM_DATA_ERROR = 'GET_TEAM_DATA_ERROR';
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const SET_TEAM_MEMBERS_SUCCESS = 'SET_TEAM_MEMBERS_SUCCESS';
export const SET_TEAM_MEMBERS_ERROR = 'SET_TEAM_MEMBERS_ERROR';
export const GET_TEAM_PERMISSIONS = 'GET_TEAM_PERMISSIONS';
export const GET_TEAM_PERMISSIONS_SUCCESS = 'GET_TEAM_PERMISSIONS_SUCCESS';
export const GET_TEAM_PERMISSIONS_ERROR = 'GET_TEAM_PERMISSIONS_ERROR';
export const UPDATE_TASK_TEAM = 'UPDATE_TASK_TEAM';

export default {
  GET_TEAM_SETUP_LIST,
  GET_TEAM_SETUP_LIST_SUCCESS,
  GET_TEAM_SETUP_LIST_ERROR,
  GET_TEAM_CATEGORIES,
  GET_TEAM_CATEGORIES_SUCCESS,
  GET_TEAM_CATEGORIES_ERROR,
  GET_TEAM_SUBCATEGORIES,
  GET_TEAM_SUBCATEGORIES_SUCCESS,
  GET_TEAM_SUBCATEGORIES_ERROR,
  ADD_TEAM,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_ERROR,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  EIDT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EIDT_CATEGORY_ERROR,
  ADD_SUBCATEGORY,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_SUBCATEGORY_ERROR,
  EDIT_SUBCATEGORY,
  EDIT_SUBCATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_SUBCATEGORY,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_ERROR,
  OPEN_MODAL,
  HIDE_MODAL,
  SET_ACTIVE_TEAM,
  GET_ACTIVE_TEAM,
  GET_TEAM_DATA,
  GET_TEAM_DATA_SUCCESS,
  GET_TEAM_DATA_ERROR,
  SET_TEAM_MEMBERS,
  SET_TEAM_MEMBERS_SUCCESS,
  SET_TEAM_MEMBERS_ERROR,
  GET_TEAM_PERMISSIONS,
  GET_TEAM_PERMISSIONS_SUCCESS,
  GET_TEAM_PERMISSIONS_ERROR,
  UPDATE_TASK_TEAM,
};
