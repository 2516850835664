/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


class ImageCrop extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      crop: {
        aspect: props.circularCrop 
          ? 1 
          : null,
      },
    }
  }
  
  onImageLoaded = (image) => {
    const {
      onImageLoaded: propsOnImageLoaded
    } = this.props

    propsOnImageLoaded()

    image.setAttribute('crossOrigin', 'anonymous');
    
    this.imageRef = image;
  }

  onCropComplete = async (crop) => {
    const {
      onCrop
    } = this.props

    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );

      const croppedImageUrl = croppedImage.fileUrl;
      const croppedImageFile = new File([croppedImage.blob], 'name', {
        type: 'image/jpeg',
        lastModified: Date.now(),
        cropped: true,
      });

      onCrop({
        imageFile: croppedImageFile,
        imageUrl: croppedImageUrl,
      })
    }
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
   
    canvas.width = crop.width;
    canvas.height = crop.height;
   
    const ctx = canvas.getContext('2d', { alpha: false });

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,

      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }

        const updatedBlob = blob.slice();

        updatedBlob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(updatedBlob);
        resolve({
          fileUrl: this.fileUrl,
          blob: updatedBlob,
        });
      }, 'image/jpeg');
    });
  }

  render () {
    const {
      circularCrop,
      src,
    } = this.props
  
    const {
      crop
    } = this.state

    return (
      <ReactCrop
        src={src}
        crop={crop}
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
        circularCrop={circularCrop}
      />
    )
  }
}


ImageCrop.defaultProps = {
  circularCrop: false,
  src: null,
  onImageLoaded: () => {}
}

const {
  bool,
  func,
  string,
} = PropTypes

ImageCrop.propTypes = {
  circularCrop: bool,
  onCrop: func.isRequired,
  src: string,
  onImageLoaded: func,
}

export default ImageCrop
