import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import Xarrow from 'react-xarrows';

import Typography from 'ui/Typography';
import { Label } from './styledItems';

const MyComponent = (props) => {
  const {
    fromId,
    toId,
    label,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <Xarrow
      start={fromId}
      end={toId}
      headSize={0}
      color={theme.palette['background-flat']}
      label={{
        middle: (
          <Label>
            <Typography variant="caption2">
              {label}
            </Typography>
          </Label>
        ),
      }}
    />
  );
};

MyComponent.defaultProps = {
  label: null,
};

const {
  string,
} = PropTypes;

MyComponent.propTypes = {
  fromId: string.isRequired,
  toId: string.isRequired,
  label: string,
};

export default MyComponent;
