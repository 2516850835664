import selectors from 'store/selectors';

export default (state, type, id) => {
  const getters = {
    client: selectors.realtorClients.byId,
    prospect: selectors.realtorProspects.byId,
  };

  const item = getters[type](state, id);

  return item;
};
