import {
  SET_REALTOR_INFINITE_SCROLL_MATCHING_PROPERTIES_FOR_FILTER,
} from 'store/actionTypes/realtorMatchingProperties';

const setInfiniteScrollMatchingPropertiesForFilter = (filterId) => (matchingProperties) => (resetState) => ({
  type: SET_REALTOR_INFINITE_SCROLL_MATCHING_PROPERTIES_FOR_FILTER,
  payload: {
    filterId,
    matchingProperties,
    resetState,
  },
});

export default setInfiniteScrollMatchingPropertiesForFilter;
