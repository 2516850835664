import store from 'store'; // eslint-disable-line import/no-cycle

import requests from 'api/requests';

import { memoizeDebounce } from 'shared/utility';

import {
  SET_QUEUE,
  SET_QUEUES,
  SET_QUEUES_COUNT,
  SET_QUEUES_FOR_MANAGER,
  REMOVE_QUEUE,
} from 'store/actionTypes/queues';
import clearQueueData from './clearQueueData';

export { clearQueueData };

const debounceDelay = 3000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const getQueueById = (queueId) => async (dispatch) => {
  try {
    const queueResponse = await requests.queues.getQueueById(queueId);

    dispatch(setQueue(queueResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export const getAllQueues = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const queuesResponse = await requests.queues.getQueues({
      offset,
      limit,
    });

    dispatch(setQueuesCount(queuesResponse.data.queuesCount));
    dispatch(setQueues(queuesResponse.data.queues));
  } catch (error) {
    console.info(error);
  }
};

export const getQueuesForManagerManager = (employeeId) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const queuesResponse = await requests.queues.getQueues({
      offset,
      limit,
      managerIds: [employeeId],
    });

    dispatch(setQueues(queuesResponse.data.queues));
    dispatch(setQueuesForManager(employeeId)(queuesResponse.data.queues));
  } catch (error) {
    console.info(error);
  }
};

export const optimizedQueueActions = {
  getAllQueues: memoizeDebounce(() => store.dispatch(getAllQueues()), debounceDelay, debounceOptions),
  getQueueById: memoizeDebounce((queueId) => store.dispatch(getQueueById(queueId)), debounceDelay, debounceOptions),
};

export const setQueue = (queue) => ({
  type: SET_QUEUE,
  payload: queue,
});

export const setQueues = (queues) => ({
  type: SET_QUEUES,
  payload: queues,
});

export const setQueuesForManager = (employeeId) => (queues) => ({
  type: SET_QUEUES_FOR_MANAGER,
  payload: {
    employeeId,
    queues,
  },
});

export const setQueuesCount = (queuesCount) => ({
  type: SET_QUEUES_COUNT,
  payload: queuesCount,
});

export const removeQueue = (id) => ({
  type: REMOVE_QUEUE,
  payload: id,
});

export default {
  getAllQueues,
  setQueues,
};
