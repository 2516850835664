// eslint-disable-next-line import/no-cycle
import EmployeeDictionary from 'shared/repositiores/dictionaries/employee';

export const SET_DICTIONARY = 'SET_DICTIONARY';

export function setDictionariesState(value, key) {
  return {
    type: 'SET_DICTIONARY',
    key,
    value,
  };
}

export const getDictionary = () => (dispatch, getState) => {
  const {
    globalData: {
      countries,
    },
  } = getState();

  dispatch(setDictionariesState(countries, 'countries'));

  EmployeeDictionary.getDepartments()
    .then((res) => {
      dispatch(setDictionariesState(res, 'departments'));
    })
    .catch();

  EmployeeDictionary.getStatess()
    .then((res) => {
      dispatch(setDictionariesState(res, 'statess'));
    })
    .catch();

  EmployeeDictionary.getPaymentType()
    .then((res) => {
      dispatch(setDictionariesState(res, 'paymentType'));
    })
    .catch();

  EmployeeDictionary.getPaymentFrequency()
    .then((res) => {
      dispatch(setDictionariesState(res, 'paymentFrequency'));
    })
    .catch();

  EmployeeDictionary.getHolidays()
    .then((res) => {
      dispatch(setDictionariesState(res, 'holidays'));
    })
    .catch();

  EmployeeDictionary.getBenefits()
    .then((res) => {
      dispatch(setDictionariesState(res, 'benefits'));
    })
    .catch();

  EmployeeDictionary.getRole()
    .then((res) => {
      dispatch(setDictionariesState(res, 'employmentType'));
    })
    .catch();

  EmployeeDictionary.getBloodType()
    .then((res) => {
      dispatch(setDictionariesState(res, 'bloodType'));
    })
    .catch();

  EmployeeDictionary.getOffice()
    .then((res) => {
      dispatch(setDictionariesState(res, 'office'));
    })
    .catch();

  EmployeeDictionary.getEmployeeStatuses()
    .then((res) => {
      dispatch(setDictionariesState(res, 'employeeStatuses'));
    })
    .catch();
};
