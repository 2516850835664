import {
  SET_NEW_COUNTER_MODAL_ATTACHMENTS,
} from 'store/actionTypes/realtorOpportunities';

const setNewCounterModalAttachments = (filesList = []) => ({
  type: SET_NEW_COUNTER_MODAL_ATTACHMENTS,
  payload: { filesList },
});

export default setNewCounterModalAttachments;
