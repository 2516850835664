import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import ObjectRemindersForm from 'shared/modals/ObjectRemindersModal/ObjectRemindersForm/ObjectRemindersForm';

const FollowUp = (props) => {
  const {
    defaultDate,
    onSave,
  } = props;

  const [
    objectTypeId,
    setObjectTypeId,
  ] = useState(null);

  const [
    objectId,
    setObjectId,
  ] = useState(null);

  const handleObjectSelect = useCallback((selecteObjectTypeId) => (selectedObjectId) => {
    setObjectTypeId(selecteObjectTypeId);
    setObjectId(selectedObjectId);
  });

  const createReminder = useCallback((reminder) => {
    const newReminder = {
      ...reminder,
      scheduled_date: defaultDate.format(),
    };
    onSave(objectTypeId, objectId)(newReminder);
  }, [
    onSave,
    defaultDate,
    objectTypeId,
    objectId,
  ]);

  return (
    <ObjectRemindersForm
      createReminder={createReminder}
      onObjectSelect={handleObjectSelect}
      reminderForm={{
        scheduledDate: defaultDate,
      }}
      disableDateSelection
    />
  );
};

const {
  func,
  object,
} = PropTypes;

FollowUp.propTypes = {
  defaultDate: object.isRequired,
  onSave: func.isRequired,
};

export default FollowUp;
