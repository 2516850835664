import { createSelector } from 'reselect';

import {
  optimizedRealtorDocumentRequestActions,
} from 'store/actions/realtorDocumentRequests';

export default (requestId) => createSelector(
  (state) => state.realtorDocumentRequests.documentRequests,
  (collection) => {
    const item = collection[requestId];

    if (!item) {
      if (requestId) {
        optimizedRealtorDocumentRequestActions.getById(requestId);
      }

      return null;
    }

    return item;
  },
);
