import selectors from 'store/selectors';

const sortViewings = (list, state) => {
  const upComingViewings = [];

  // get viewings linked to matched property and create array of {matchId, viewingTime}
  list.forEach((item) => {
    const matchedProperty = selectors.realtorMatchingProperties.byId(item.matchId)(state);
    const viewings = matchedProperty.viewing_upcoming_ids;

    viewings.forEach((viewing) => {
      const nextViewing = selectors.propertyViewings.byId(state, viewing);
      upComingViewings.push({
        matchId: item.matchId,
        viewingTime: nextViewing.date_time,
      });
    });
  });

  // sort viewings by date and time and push null values to bottom
  const sortedViewings = upComingViewings.sort(
    (a, b) => (a.viewingTime === null) - (b.viewingTime === null)
      || +(a.viewingTime > b.viewingTime)
      || -(a.viewingTime < b.viewingTime),
    );

  return sortedViewings;
};

export default sortViewings;
