/* eslint-disable react/require-default-props */

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copyTextToClipboard from 'oa/lib/copyTextToClipboard';
import Toast from 'oa/components/Toast/index';
import Button from 'oa/components/Button/index';
import './style.css';

const CopyText = (props) => {
  const {
    children,
    text,
    toastMessage,
    className,
  } = props;

  const [isTextCopied, setIsTextCopied] = useState(false);

  const handleCopyClick = useCallback(() => {
    copyTextToClipboard(text);
    setIsTextCopied(true);
  }, [text]);

  const handleToastClose = useCallback(() => {
    setIsTextCopied(false);
  }, []);

  return (
    <div className={classNames('copy-text', className)}>
      <div className="copy-text__inner truncate">{children || text}</div>

      <Button
        className="copy-text__btn"
        append={<FontAwesomeIcon icon={['far', 'copy']} />}
        onClick={handleCopyClick}
        hideText
      >
        Copy
        {' '}
        {text}
      </Button>

      <Toast isActive={isTextCopied} onClose={handleToastClose}>
        {toastMessage}
      </Toast>
    </div>
  );
};

CopyText.defaultProps = {
  toastMessage: 'Copied.',
};

CopyText.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  toastMessage: PropTypes.string,
  className: PropTypes.string,
};

export default CopyText;
