import connectReducers from 'store/connectReducers';

import {
  GET_COMMENT_TYPES_SUCCESS,
  SET_COMMENTS_FOR_OBJECT,
} from 'store/actionTypes/comments';

const initialState = {
  commentTypesList: [],
  commentedObjects: {
    ticket: {},
    task: {},
    lead: {},
    log: {},
    comment: {},
    fulfillment: {},
    'realtor lead': {},
    'realtor property': {},
    'realtor property requirement': {},
    'realtor contact': {},
    'realtor prospect': {},
    'realtor client': {},
  },
};

const reducers = {
  [GET_COMMENT_TYPES_SUCCESS]: (state, action) => ({ ...state, commentTypesList: action.commentTypes.priorities }),

  [SET_COMMENTS_FOR_OBJECT]: (state, action) => {
    const {
      commentedObjectType,
      commentedObjectId,
      commentsList,
    } = action.payload;

    const objectTypeComments = { ...state.commentedObjects[commentedObjectType] };

    objectTypeComments[commentedObjectId] = commentsList;

    return {
      ...state,
      commentedObjects: {
        ...state.commentedObjects,
        [commentedObjectType]: objectTypeComments,
      },
    };
  },
};

function comments(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default comments;
