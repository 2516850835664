import requests from 'api/requests';

import setMany from './setMany';
import setFulfillmentPackages from './setFulfillmentPackages';

const get = (fulfillmentId) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.warehouse.getPackages({
      fulfillmentWarehouseId: fulfillmentId,
      offset,
      limit,
    });
    dispatch(setMany(response.data));
    dispatch(setFulfillmentPackages(fulfillmentId)(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default get;
