import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const PropertyAddress = (props) => {
  const {
    address,
  } = props;

  return (
    <Typography variant="title2" noMargin>
      {address}
    </Typography>
  );
};

PropertyAddress.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

PropertyAddress.propTypes = {

};

export default PropertyAddress;
