import connectReducers from 'store/connectReducers';

import {
  SET_CRM_LEAD,
  SET_CRM_LEADS,
  SET_CRM_LEADS_FOR_FILTER,
  SET_CRM_LEADS_FOR_FILTER_COUNT,
  SET_CRM_OVERALL_LEADS_SUMMARY,
  SET_CRM_SELECTED_LEAD,
  SET_CRM_SELECTED_LEAD_ROWS,
  SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS,
  SET_CRM_LEAD_CHANGELOG,
  SET_CRM_LEAD_VIEWERS,
} from 'store/actionTypes/crm';

const initialState = {
  leads: {},
  leadsByFilter: {},
  leadsByFilterCount: {},
  overallLeadsSummary: null,
  selectedLead: null,
  changelog: {},
  selectedLeadRows: [],
  // this is mot used at the moment,
  // but we'll keep it in case we want to enhance table selection
  selectedLeadRowsExceptions: [],
  leadViewers: {},
};

const leadsListToObject = (leads = []) => leads.reduce((leadObj, item) => {
  // param reassign is used in reduce by design
  leadObj[item.id] = item; // eslint-disable-line no-param-reassign
  return leadObj;
}, {});

const getLeadsIds = (leads = []) => leads.map((item) => item.id);

const reducers = {
  [SET_CRM_LEAD_VIEWERS]: (state, action) => ({
    ...state,
    leadViewers: {
      [action.payload.leadId]: action.payload.viewers,
    },
  }),

  [SET_CRM_SELECTED_LEAD]: (state, action) => ({
    ...state,
    selectedLead: action.payload,
  }),

  [SET_CRM_SELECTED_LEAD_ROWS]: (state, action) => ({
    ...state,
    selectedLeadRows: action.payload,
  }),

  [SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS]: (state, action) => ({
    ...state,
    selectedLeadRowsExceptions: action.payload,
  }),

  [SET_CRM_LEADS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    leadsByFilterCount: {
      ...state.leadsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_CRM_LEAD]: (state, action) => ({
    ...state,
    leads: {
      ...state.leads,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_CRM_LEADS]: (state, action) => ({
    ...state,
    leads: {
      ...state.leads,
      ...leadsListToObject(action.payload),
    },
  }),

  [SET_CRM_LEADS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      leads,
    } = action.payload;

    // const filterProductIds = state.leadsByFilter[filterId] || [];

    const updatedFilterCompaniesIds = new Set([
      ...getLeadsIds(leads), // this order is important for currect updates
      // ...filterProductIds,
    ]);

    const updatedState = {
      ...state,
      leadsByFilter: {
        ...state.leadsByFilter,
        [filterId]: [...updatedFilterCompaniesIds],
      },
    };

    return updatedState;
  },

  [SET_CRM_OVERALL_LEADS_SUMMARY]: (state, action) => ({
    ...state,
    overallLeadsSummary: action.payload,
  }),

  [SET_CRM_LEAD_CHANGELOG]: (state, action) => ({
    ...state,
    changelog: {
      ...state,
      [action.payload.leadId]: action.payload.changelog,
    },
  }),
};

function crmCompanies(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default crmCompanies;
