import styled from 'styled-components';
import { lighten } from 'polished';

import Flex from 'ui/Flex';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled(Flex)`
  background-color: ${(props) => lighten(0.3, props.theme?.palette?.[props.variant] || '#00FF00')};
  padding: 3px 3px 3px 5px;
  border-radius: 6px;
  margin: 5px 0;
`;
