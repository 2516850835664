import connectReducers from 'store/connectReducers';

import {
  find,
  omit,
  isUndefined,
  isNull,
} from 'shared/utility';

import moment from 'moment';

import {
  SET_ARTICLE_PARENT_FOLDER,
  SET_ARTICLE_CHILDREN_FOLDER,
  SET_ACTIVE_ARTICLE_MAIN_VIEW,
  GET_USER_FOLDERS,
  SET_ARTICLES_DISPLAY_TYPE,
  CLOSE_NEW_ARTICLE_MODAL,
  OPEN_NEW_ARTICLE_MODAL,
  OPEN_NEW_FOLDER_MODAL,
  CLOSE_NEW_FOLDER_MODAL,
  OPEN_EDIT_FOLDER_MODAL,
  CLOSE_EDIT_FOLDER_MODAL,
  SET_PERMISSIONS_MODAL_SELECTED_TYPE,
  OPEN_ARTICLE_DETAILS_MODAL,
  CLOSE_ARTICLE_DETAILS_MODAL,
  SET_ARTICLE_FILTER,
  CREATE_ARTICLE_FOLDER,
  SET_NEW_FOLDER_FORM_FIELD,
  SET_PERMISSIONS_MODAL_DATA,
  SET_FOLDER,
  GET_FOLDER_ARTICLES,
  SET_EDIT_FOLDER_FORM_FIELD,
  UPDATE_ARTICLE_FOLDER,
  DELETE_ARTICLE_FOLDER,
  SET_NEW_ARTICLE_FORM_FIELD,
  CREATE_ARTICLE,
  SET_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  SET_ACTIVE_ARTICLE,
  SET_EDIT_ARTICLE_FORM_FIELD,
  GET_FAVORITE_FOLDER_ARTICLES,
  REFRESH_FOLDER_ARTICLE,
  SEARCH_ARTICLES,
} from 'store/actionTypes/articles';

const defaultArticleInformations = {
  id: null,
  title: null,
  purpose: null,
  text: null,
  isFavorite: false,
  isHelpful: null,
  folderId: null,
  createdBy: null,
  createdAt: null,
  updatedBy: null,
  updatedAt: null,
  keyWords: null,
  articleType: null,
  permission: {
    type: 1,
    permissionSpecifyUsers: [],
    permissionOffices: [],
  },
};

const defaultFolderInformations = {
  id: null,
  name: null,
  type: null,
  relatedAppId: null,
  folderParentId: null,
  createdById: null,
  createdAt: null,
  articleCount: 0,
  permission: {
    type: 1,
    permissionSpecifyUsers: [],
    permissionOffices: [],
  },
  children: [],
};

const defaultFolderFieldInformations = {
  folderName: '',
  relatedAppId: null,
  folderParentId: 0,
  permission: {
    type: 1,
    permissionData: null,
  },
};

const defaultArticleFormFields = {
  articleName: '',
  folderLocation: null,
  permission: {
    type: 1,
    permissionData: null,
  },
};

const initialState = {
  searchedArticles: [],
  activeParentFolderId: null,
  parentActiveFolder: defaultFolderInformations,
  activeChildrenFolderId: null,
  childrenActiveFolder: defaultFolderInformations,
  mainViewType: null,
  articlesFolders: {},
  displayType: 'line',
  isNewArticleModalVisible: false,
  isNewFolderModalVisible: false,
  isEditFolderModalVisible: false,
  isViewingPermissionsModalVisible: false,
  openedPermissionObject: null,
  isArticleDetailsModalVisible: false,
  selectedPermissionType: 'myCompany',
  permissionOffices: null,
  permissionUser: null,
  articleFilters: {
    sortBy: 'created_at',
    sortDirection: 'DESC',
  },
  newFolderFormFields: defaultFolderFieldInformations,
  newArticleFormFields: defaultArticleFormFields,
  folderArticles: [],
  favoriteArticles: [],
  editFolderFormFields: {},
  editArticleFormFields: {},
  activeArticle: defaultArticleInformations,
};

const reducers = {
  [SEARCH_ARTICLES]: (state, actions) => ({
    ...state,
    searchedArticles: actions.searchedArticles,
  }),

  [REFRESH_FOLDER_ARTICLE]: (state, actions) => ({
    ...state,
    folderArticles: [],
    favoriteArticles: [],
  }),

  [GET_FAVORITE_FOLDER_ARTICLES]: (state, actions) => ({
    ...state,
    favoriteArticles: actions.favoriteArticles,
  }),

  [SET_ACTIVE_ARTICLE]: (state, actions) => {
    const {
      article,
    } = actions;

    return {
      ...state,
      activeArticle: article,
    };
  },

  [DELETE_ARTICLE]: (state, actions) => {
    const {
      articleId,
    } = actions;

    const newArticles = state.folderArticles.filter(({ id }) => id !== articleId);
    const newFavorite = state.favoriteArticles.filter(({ id }) => id !== articleId);

    return {
      ...state,
      folderArticles: newArticles,
      favoriteArticles: newFavorite,
    };
  },

  [UPDATE_ARTICLE]: (state, actions) => {
    const {
      editArticleFormFields,
      userId,
      newArticleInformation,
    } = actions;

    const {
      activeArticle,
      folderArticles,
      favoriteArticles,
    } = state;

    const {
      folderLocation,
      permission,
      title,
      purpose,
      text,
      isArchive,
      keyWords,
      articleType,
    } = editArticleFormFields;

    const hasPublicArticleObjectUpdates = [
      title,
      purpose,
      text,
      isArchive,
      folderLocation,
      keyWords,
      articleType,
    ].reduce((acc, item) => {
      if (acc) return acc;
      return !isUndefined(item);
    }, false);

    const updatedArticles = folderArticles.map((item) => {
      if (item.id === newArticleInformation.id) {
        return newArticleInformation;
      }
      return item;
    });
    const updatedFavorite = favoriteArticles.map((item) => {
      if (item.id === newArticleInformation.id) {
        return newArticleInformation;
      }
      return item;
    });

    if (folderLocation) {
      const newArticles = folderArticles.filter(({ id }) => id !== activeArticle.id);
      const newFavorite = favoriteArticles.filter(({ id }) => id !== activeArticle.id);

      return {
        ...state,
        folderArticles: newArticles,
        favoriteArticles: newFavorite,
        editArticleFormFields: {},
        activeArticle: {
          ...activeArticle,
          updatedBy: userId,
          updatedAt: moment().format('Y-M-D H:mm:ss'),
        },
      };
    }

    if (permission) {
      return {
        ...state,
        editArticleFormFields: {},
        folderArticles: updatedArticles,
        favoriteArticles: updatedFavorite,
        activeArticle: {
          ...activeArticle,
          ...editArticleFormFields,
          updatedBy: hasPublicArticleObjectUpdates ? userId : activeArticle.updatedBy,
          updatedAt: hasPublicArticleObjectUpdates ? moment().format('Y-M-D H:mm:ss') : activeArticle.updatedBy,
          permission: {
            type: permission.type,
            permissionSpecifyUsers: permission.type === 4 ? permission.permissionData : [],
            permissionOffices: permission.type === 2 ? permission.permissionData : [],
          },
        },
      };
    }

    return {
      ...state,
      editArticleFormFields: {},
      folderArticles: updatedArticles,
      favoriteArticles: updatedFavorite,
      activeArticle: {
        ...activeArticle,
        ...editArticleFormFields,
        updatedBy: hasPublicArticleObjectUpdates ? userId : activeArticle.updatedBy,
        updatedAt: hasPublicArticleObjectUpdates ? moment().format('Y-M-D H:mm:ss') : activeArticle.updatedBy,
      },
    };
  },

  [GET_FOLDER_ARTICLES]: (state, actions) => {
    const {
      articles: actionsArticles,
    } = actions;

    const newArticles = state.folderArticles.concat(actionsArticles);

    return {
      ...state,
      folderArticles: newArticles,
    };
  },

  [SET_ARTICLE]: (state, actions) => {
    const {
      articleInformations,
    } = actions;

    const newArticles = [articleInformations, ...state.folderArticles];

    return {
      ...state,
      folderArticles: newArticles,
    };
  },

  [CREATE_ARTICLE]: (state, actions) => ({
    ...state,
  }),

  [SET_NEW_ARTICLE_FORM_FIELD]: (state, actions) => ({
    ...state,
    newArticleFormFields: {
      ...state.newArticleFormFields,
      [actions.key]: actions.value,
    },
  }),

  [DELETE_ARTICLE_FOLDER]: (state, actions) => {
    const {
      deletedFolderId,
      deletedFolderType,
    } = actions;

    if (deletedFolderType === 1) {
      return {
        ...state,
        articlesFolders: omit(state.articlesFolders, [String(deletedFolderId)]),
        mainViewType: null,
      };
    }
    const parentFolder = state.articlesFolders[state.activeParentFolderId];
    const newParentChildren = parentFolder.children.filter(({ id }) => id !== deletedFolderId);

    return {
      ...state,
      articlesFolders: {
        ...state.articlesFolders,
        [state.activeParentFolderId]: {
          ...state.articlesFolders[state.activeParentFolderId],
          children: newParentChildren,
        },
      },
      mainViewType: null,
    };
  },

  [UPDATE_ARTICLE_FOLDER]: (state, actions) => {
    const {
      changedFolderId,
      editFolderFormFields,
      folderType,
    } = actions;

    if (folderType === 1) {
      return {
        ...state,
        parentActiveFolder: {
          ...state.parentActiveFolder,
          ...editFolderFormFields,
          permission: editFolderFormFields.permission ? {
            ...editFolderFormFields.permission,
            permissionSpecifyUsers: editFolderFormFields.permission.type === 4 ? (
              editFolderFormFields.permission.permissionData
            ) : (
              state.parentActiveFolder.permission.permissionSpecifyUsers
            ),
            permissionOffices: editFolderFormFields.permission.type === 2 ? (
              editFolderFormFields.permission.permissionData
            ) : (
              state.parentActiveFolder.permission.permissionOffices
            ),
          } : {
            ...state.parentActiveFolder.permission,
          },
        },
        articlesFolders: {
          ...state.articlesFolders,
          [changedFolderId]: {
            ...state.articlesFolders[changedFolderId],
            ...editFolderFormFields,
          },
        },
      };
    }
    if (editFolderFormFields.folderParentId) {
      const oldParentFolder = state.articlesFolders[state.activeParentFolderId];

      const newParentFolder = state.articlesFolders[editFolderFormFields.folderParentId];

      const oldParentChildrenAfterChange = oldParentFolder.children.filter(({ id }) => id !== changedFolderId);

      const newParentChildrenAfterChange = newParentFolder.children;

      const changedChildren = find(oldParentFolder.children, { id: changedFolderId });

      newParentChildrenAfterChange.unshift({
        ...changedChildren,
        ...editFolderFormFields,
      });

      return {
        ...state,
        childrenActiveFolder: {
          ...state.childrenActiveFolder,
          ...editFolderFormFields,
          permission: editFolderFormFields.permission ? {
            ...editFolderFormFields.permission,
            permissionSpecifyUsers: editFolderFormFields.permission.type === 4 ? (
              editFolderFormFields.permission.permissionData
            ) : (
              state.childrenActiveFolder.permission.permissionSpecifyUsers
            ),
            permissionOffices: editFolderFormFields.permission.type === 2 ? (
              editFolderFormFields.permission.permissionData
            ) : (
              state.childrenActiveFolder.permission.permissionOffices
            ),
          } : {
            ...state.childrenActiveFolder.permission,
          },
        },
        articlesFolders: {
          ...state.articlesFolders,
          [editFolderFormFields.folderParentId]: {
            ...state.articlesFolders[editFolderFormFields.folderParentId],
            children: newParentChildrenAfterChange,
          },
          [state.activeParentFolderId]: {
            ...state.articlesFolders[state.activeParentFolderId],
            children: oldParentChildrenAfterChange,
          },
        },
      };
    }
    const parentFolder = state.articlesFolders[state.activeParentFolderId];

    const newChildren = parentFolder.children.map((child) => {
      if (child.id === changedFolderId) {
        return {
          ...child,
          ...editFolderFormFields,
        };
      }

      return child;
    });

    return {
      ...state,
      childrenActiveFolder: {
        ...state.childrenActiveFolder,
        ...editFolderFormFields,
        permission: editFolderFormFields.permission ? {
          ...editFolderFormFields.permission,
          permissionSpecifyUsers: editFolderFormFields.permission.type === 4 ? (
            editFolderFormFields.permission.permissionData
          ) : (
            state.childrenActiveFolder.permission.permissionSpecifyUsers
          ),
          permissionOffices: editFolderFormFields.permission.type === 2 ? (
            editFolderFormFields.permission.permissionData
          ) : (
            state.childrenActiveFolder.permission.permissionOffices
          ),
        } : {
          ...state.childrenActiveFolder.permission,
        },
      },
      articlesFolders: {
        ...state.articlesFolders,
        [state.activeParentFolderId]: {
          ...state.articlesFolders[state.activeParentFolderId],
          children: newChildren,
        },
      },
    };
  },

  [SET_EDIT_ARTICLE_FORM_FIELD]: (state, actions) => ({
    ...state,
    editArticleFormFields: {
      ...state.editArticleFormFields,
      [actions.key]: actions.value,
    },
  }),

  [SET_EDIT_FOLDER_FORM_FIELD]: (state, actions) => ({
    ...state,
    editFolderFormFields: {
      ...state.editFolderFormFields,
      [actions.key]: actions.value,
    },
  }),

  [SET_FOLDER]: (state, actions) => {
    const {
      folderInformations,
    } = actions;

    if (folderInformations.type === 1) {
      return {
        ...state,
        articlesFolders: {
          [folderInformations.id]: {
            ...folderInformations,
          },
          ...state.articlesFolders,
        },
      };
    }
    const newChildren = [folderInformations, ...state.articlesFolders[folderInformations.parentFolderId].children];
    return {
      ...state,
      articlesFolders: {
        [folderInformations.parentFolderId]: {
          ...state.articlesFolders[folderInformations.parentFolderId],
          children: newChildren,
        },
        ...state.articlesFolders,
      },
    };
  },

  [SET_PERMISSIONS_MODAL_DATA]: (state, actions) => ({
    ...state,
    [actions.dataType]: actions.permissionData,
  }),

  [SET_NEW_FOLDER_FORM_FIELD]: (state, actions) => ({
    ...state,
    newFolderFormFields: {
      ...state.newFolderFormFields,
      [actions.key]: actions.value,
    },
  }),

  [CREATE_ARTICLE_FOLDER]: (state, action) => ({
    ...state,
    selectedPermissionType: 'myCompany',
    permissionOffices: null,
    permissionUser: null,
  }),

  [SET_ARTICLE_FILTER]: (state, action) => ({
    ...state,
    articleFilters: action.articleFilters,
    folderArticles: [],
  }),

  [OPEN_ARTICLE_DETAILS_MODAL]: (state, action) => ({
    ...state,
    isArticleDetailsModalVisible: true,
    editArticleFormFields: {
      ...state.editArticleFormFields,
      folderLocation: state.activeArticle.folderId,
      keyWords: state.activeArticle.keyWords,
      articleType: state.activeArticle.articleType,
    },
  }),

  [CLOSE_ARTICLE_DETAILS_MODAL]: (state, action) => ({
    ...state,
    isArticleDetailsModalVisible: false,
    editArticleFormFields: {},
  }),

  [SET_PERMISSIONS_MODAL_SELECTED_TYPE]: (state, action) => ({
    ...state,
    selectedPermissionType: action.selectedPermissionType,
  }),

  [OPEN_EDIT_FOLDER_MODAL]: (state, action) => ({
    ...state,
    isEditFolderModalVisible: true,
  }),

  [CLOSE_EDIT_FOLDER_MODAL]: (state, action) => ({
    ...state,
    isEditFolderModalVisible: false,
    editFolderFormFields: {},
  }),

  [OPEN_NEW_FOLDER_MODAL]: (state, action) => ({
    ...state,
    isNewFolderModalVisible: true,
  }),

  [CLOSE_NEW_FOLDER_MODAL]: (state, action) => ({
    ...state,
    isNewFolderModalVisible: false,
    newFolderFormFields: defaultFolderFieldInformations,
  }),

  [OPEN_NEW_ARTICLE_MODAL]: (state, action) => ({
    ...state,
    isNewArticleModalVisible: true,
    newArticleFormFields: {
      ...state.newArticleFormFields,
      folderLocation: state.activeChildrenFolderId ? state.activeChildrenFolderId : state.activeParentFolderId,
    },
  }),

  [CLOSE_NEW_ARTICLE_MODAL]: (state, action) => ({
    ...state,
    isNewArticleModalVisible: false,
    newArticleFormFields: defaultArticleFormFields,
  }),

  [SET_ARTICLES_DISPLAY_TYPE]: (state, action) => ({
    ...state,
    displayType: action.displayType,
  }),

  [GET_USER_FOLDERS]: (state, action) => ({
    ...state,
    articlesFolders: action.articlesFolders,
  }),

  [SET_ACTIVE_ARTICLE_MAIN_VIEW]: (state, action) => ({
    ...state,
    mainViewType: action.mainViewType,
  }),

  [SET_ARTICLE_PARENT_FOLDER]: (state, action) => {
    const {
      folderInformations,
      folderId,
    } = action;

    if (!isNull(folderId)) {
      return {
        ...state,
        activeParentFolderId: folderId,
        parentActiveFolder: folderId === 0 ? {
          ...defaultFolderInformations,
          ...folderInformations,
        } : folderInformations,
        folderArticles: [],
      };
    }
    return {
      ...state,
      activeParentFolderId: null,
      parentActiveFolder: defaultFolderInformations,
      folderArticles: [],
    };
  },

  [SET_ARTICLE_CHILDREN_FOLDER]: (state, action) => {
    const {
      folderInformations,
      folderId,
    } = action;

    if (folderId) {
      return {
        ...state,
        activeChildrenFolderId: folderId,
        childrenActiveFolder: folderInformations,
        folderArticles: [],
      };
    }
    return {
      ...state,
      activeChildrenFolderId: null,
      childrenActiveFolder: defaultFolderInformations,
      folderArticles: [],
    };
  },
};

function articles(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default articles;
