import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';

const errorsMessage = {
  required: 'This field is required!',
  isIdentical: 'Passwords are not identical!',
};

const ChangePasswordForm = (props) => {
  const {
    control,
    errors,
    formsData,
    getValues,
  } = props;

  const compareToFirstPassword = useCallback((confirmPassword) => {
    const password = getValues('password');

    if (password && password !== confirmPassword) {
      return false;
    }

    return true;
  }, [getValues]);

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      justifyContent="start"
      style={{
        width: '283px',
      }}
      spacing={2}
    >
      <div>
        <Typography
          variant="subtitle"
          style={{
            margin: '0 0 4px 11px',
          }}
          noMargin
        >
          Password
        </Typography>
        <Controller
          name="password"
          control={control}
          defaultValue={formsData?.password}
          render={({ field }) => (
            <Textfield.Password
              {...field}
              icon={null}
              error={errors?.password ? errorsMessage[errors?.password?.type] : false}
            />
          )}
          rules={{
            required: true,
          }}
        />
      </div>
      <div>
        <Typography
          variant="subtitle"
          style={{
            margin: '0 0 4px 11px',
          }}
          noMargin
        >
          Confirm Password
        </Typography>
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue={formsData?.confirmPassword}
          render={({ field }) => (
            <Textfield.Password
              {...field}
              icon={null}
              error={errors?.confirmPassword ? errorsMessage[errors?.confirmPassword?.type] : false}
            />
          )}
          rules={{
            required: true,
            validate: {
                isIdentical: (value) => compareToFirstPassword(value),
              },
          }}
        />
      </div>
    </Flex>
  );
};

const {
  string,
  func,
  shape,
} = PropTypes;

ChangePasswordForm.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  formsData: shape({
    password: string,
    confirmPassword: string,
  }).isRequired,
  getValues: func.isRequired,
};

export default ChangePasswordForm;
