import { connect } from 'react-redux';

import { setNewContactFormField } from 'store/actions/newRealtorContactForm';

import UploadImageButton from 'shared/components/UI/UploadImageButton';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      avatar,
    },
  } = state;

  return {
    fileUrl: avatar?.fileUrl || null,
    buttonText: 'Upload profile picture',
    appModule: 'realtor-contact-avatar',
    cropAppModule: 'realtor-contact-avatar',
    visibleModule: 'realtor-contact-avatar',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpload: async (file) => {
    const fileUrl = URL.createObjectURL(file);

    dispatch(setNewContactFormField('avatar')({
      name: file.name,
      type: file.type,
      fileUrl,
    }));
  },
  clearAvatar: (fileUrl) => () => {
    URL.revokeObjectURL(fileUrl);
    dispatch(setNewContactFormField('avatar')(null));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImageButton);
