import changeAccountCreatorInformationInGlobal from './changeAccountCreatorInformationInGlobal';
import updateEmployeeInfo from './updateEmployeeInfo';
import getTodayBirthdayEmployees from './getTodayBirthdayEmployees';
import getEmployeeMentionsSummary from './getEmployeeMentionsSummary';
import clearMentions from './clearMentions';
import getEmployeeAttachments from './getEmployeeAttachments';
import getTodayEmployeeAttendance from './getTodayEmployeeAttendance';
import updateEmployeeInfoInGlobal from './updateEmployeeInfoInGlobal';

export default {
  updateEmployeeInfoInGlobal,
  changeAccountCreatorInformationInGlobal,
  updateEmployeeInfo,
  getTodayBirthdayEmployees,
  getEmployeeMentionsSummary,
  clearMentions,
  getEmployeeAttachments,
  getTodayEmployeeAttendance,
};
