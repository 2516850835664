import apiConnector, { endpoints } from 'api';

const commentTypes = {
  Problem: 4,
  Question: 1,
  Regular: 0,
};

const clearMentions = async (employeeId, type) => {
  const endpoint = `${endpoints.client.employees.clearMentions}/${employeeId}/${commentTypes[type]}`;

  try {
    const result = await apiConnector.client.put(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default clearMentions;
