import requests from 'api/requests';

import setFollowUpReminder from './setFollowUpReminder';

const getFollowUpReminderById = (reminderId) => async (dispatch) => {
  try {
    const response = await requests.followUpReminders.getById(reminderId);
    dispatch(setFollowUpReminder(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getFollowUpReminderById;
