import {
  SET_REALTOR_CLIENTS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorClients';

const setClientsForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_CLIENTS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setClientsForFilterCount;
