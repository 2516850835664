import React from 'react';
import PropTypes from 'prop-types';

import DropdownTextfield from 'ui/DropdownTextfield';
import Typography from 'ui/Typography';

import { CONTACT_RELATION_STATUSES_ARRAY } from 'constants/realtor.constants';

const RelationStatusSelect = (props) => {
  const {
    onChange,
    value,
  } = props;

  return (
    <div>
      <Typography
        variant="title3"
        weight={500}
      >
        Select Relation Status
      </Typography>
      <DropdownTextfield
        value={value}
        onChange={onChange}
        options={CONTACT_RELATION_STATUSES_ARRAY}
      />
    </div>
  );
};

const {
  number,
  func,
} = PropTypes;

RelationStatusSelect.propTypes = {
  onChange: func.isRequired,
  value: number.isRequired,
};

export default RelationStatusSelect;
