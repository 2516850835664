import React from 'react';

const initialSteps = [
  {
    name: 'tutorial-create-queues',
    target: '[data-tutorial="create-queues"]',
    content: (
      <ol>
        <li>Click the Queue Setup button.</li>
        <li>
          Set queue and sub-queue names and add Managers and Team Employees.
        </li>
        <li>If you have access to a queue, you can see it on the Ticket Assignment page.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Create queues & teams',
    manager: true,
  },
  {
    name: 'tutorial-agent-submit-ticket',
    target: '[data-tutorial="submit-ticket"]',
    content: (
      <ol>
        <li>Click the New Ticket button.</li>
        <li>Fill out the ticket details.</li>
        <li>Submit ticket. The ticket will appear in the queue that it was assigned.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    title: 'Submit ticket as Agent',
  },
  {
    name: 'tutorial-customer-ticket-submit',
    target: '[data-tutorial="customer-ticket-submission"]',
    content: (
      <ol>
        <li>Add the provided email to your forwarding address as per instructions.</li>
        <li>
          Embed the support form onto the support page on your website.
          <br />
          Tickets sent from this form or to the email address will appear in the Email/Other queue.
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'top',
    title: 'Customer ticket submission',
    admin: true,
  },
  {
    name: 'tutorial-assign-tickets',
    target: '[data-tutorial="assigning-tickets"]',
    content: (
      <ol>
        <li>Select a queue from the left.</li>
        <li>View all unassigned and other tickets in the middle column.</li>
        <li>
          Drag tickets from the queues to queue members on the right.
          <br />
          You can also click into a ticket to assign new team members.
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'right-start',
    title: 'Assigning tickets',
    styles: {
      options: {
        arrowColor: 'rgba(0, 0, 0, 0)',
      },
    },
    manager: true,
  },
  {
    name: 'tutorial-process-ticket',
    target: '[data-tutorial="processing-tickets-archive-task"]',
    content: (
      <ol>
        <li>Open a ticket.</li>
        <li>
          Use the resolve ticket button to quickly resolve the ticket or use&nbsp;
          the drop down to select another status.
        </li>
        <li>You can also drag tickets into their various statuses on the My Ticket boards.</li>
      </ol>),
    disableBeacon: true,
    placement: 'left-start',
    title: 'Processing tickets',
  },
];

export default initialSteps;
