import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedProspectActions,
} from 'store/actions/realtorProspects';

const defaultItem = {
  id: null,
};

export default createSelector(
  (state) => state.realtorProspects.prospects,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedProspectActions.getProspectById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
      // preApprovalAmount: 10000000,
    };
  },
);
