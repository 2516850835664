import { connect } from 'react-redux';

import selectors from 'store/selectors';
import { getPersonTitlesDictionary } from 'store/actions/dictionaryV2';
import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import MultiselectTextfield from 'ui/MultiselectTextfield';

let isUpdating = false;

const mapStateToProps = (state) => {
  const titles = selectors.dictionary.personTitles(state);

  const allOptions = titles.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const selectedOptions = titles.reduce((accum, item) => {
    if (item.isActive) {
      accum.push({
        value: item.id,
        label: item.name,
      });
    }

    return accum;
  }, []);

  return {
    options: allOptions,
    value: selectedOptions,
    creatable: true,
    isLoading: isUpdating,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: async (values) => {
    isUpdating = true;

    const activeValues = values.map((item) => item.value);

    try {
      await requests.personTitles.update(activeValues);
      callAlert.success('Updated successfully');
    } catch (err) {
      callAlert.error('Could not update');
    }
    isUpdating = false;

    await dispatch(getPersonTitlesDictionary());
  },
  onCreate: async (newOption) => {
    isUpdating = true;

    try {
      await requests.personTitles.create(newOption.label);
      callAlert.success('Updated successfully');
    } catch (err) {
      callAlert.error('Could not update');
    }
    isUpdating = false;

    await dispatch(getPersonTitlesDictionary());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiselectTextfield);
