import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import SelectorItem from 'ui/SelectorItem';

const spacings = {
  horizontal: 1,
  vertical: 4,
};

const Selection = (props) => {
  const {
    options,
    value,
    onChange,
    radio,
    variant,
    itemTypographyProps,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const handleClick = useCallback((optionId) => (isSelected) => (event) => {
    if (radio) {
      if (!isSelected) {
        setCurrentValue(optionId);
        onChange(optionId);
      }
      return;
    }

    let newValue;

    if (isSelected) {
      newValue = value.filter((item) => item !== optionId);
    } else {
      newValue = [...value, optionId];
    }

    setCurrentValue(newValue);
    onChange(newValue);
  }, [
    onChange,
    value,
    radio,
  ]);

  return (
    <Flex spacing={spacings[variant]}>
      {
        options.map((option) => {
          const isSelected = radio
            ? currentValue === option.id
            : currentValue.includes(option.id);

          return (
            <SelectorItem
              variant={variant}
              key={option.id}
              customIconComponent={option.customIconComponent}
              iconName={option.iconName}
              iconType={option.iconType}
              iconColor={option.iconColor}
              isSelected={isSelected}
              onClick={handleClick(option.id)(isSelected)}
              radio={radio}
              typographyProps={itemTypographyProps}
            >
              {option.label}
            </SelectorItem>
          );
        })
      }
    </Flex>
  );
};

Selection.defaultProps = {
  radio: false,
  value: [],
  variant: 'horizontal',
  itemTypographyProps: {},
};

const {
  arrayOf,
  bool,
  element,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Selection.propTypes = {
  options: arrayOf(shape({
    id: oneOfType([string, number]),
    customIconComponent: element,
    iconName: string,
    iconType: string,
    iconColor: string,
    label: string.isRequired,
  })).isRequired,
  value: arrayOf(oneOfType([string, number])),
  onChange: func.isRequired,
  radio: bool,
  variant: oneOf(['horizontal', 'vertical']),
  itemTypographyProps: shape(),
};

export default Selection;
