import React, { Component } from 'react';
import { Modal, Input, Form } from 'antd';

import SingleNotice from './SingleNotice/SingleNotice';
import './CompanyNotices.scss';

const FormItem = Form.Item;

const CompanyNotices = Form.create()(
  class extends Component {
    shouldComponentUpdate(nextProps) {
      if (this.props.visible !== nextProps.visible && nextProps.visible) {
        this.props.notices.map((notice) => {
          if (notice.NoticeObject.CreatedBy !== this.props.loggedUser.EmployeeID
            && notice.isAcknowledged === false) {
            this.props.seenNotice(notice.NoticeID);
          }
          return notice;
        });
      }
      return true;
    }

    addNotice = (e) => {
      if (e.target.value !== '') {
        this.props.addNotice(e.target.value);
      }

      this.props.form.resetFields();
    };

    acknowledgeNotice = (noticeID) => this.props.acknowledgeNotice(noticeID);

    deleteNotice = (noticeID) => this.props.deleteNotice(noticeID);

    mapNotices(notices, employees, loggedUser) {
      return notices.map((notice) => {
        if (!notice.isAcknowledged) {
          return (
            <SingleNotice
              notice={notice.NoticeObject}
              employees={employees}
              loggedUser={loggedUser}
              seenNotice={this.seenNotice}
              acknowledgeNotice={this.acknowledgeNotice}
              deleteNotice={this.deleteNotice}
              key={notice.NoticeID}
            />
          );
        }
        return null;
      }).reverse();
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const {
        visible, onCancel, onOk, notices, employees, loggedUser,
      } = this.props;
      const noticesList = notices ? this.mapNotices(notices, employees, loggedUser) : [];

      return (
        <Modal
          visible={visible}
          onCancel={onCancel}
          onOk={onOk}
          title={(
            <div className="company-notices__title">
              Company Notices
            </div>
          )}
        >
          <div className="company-notices">
            <FormItem>
              {
                  getFieldDecorator('companyNotices', {
                    initialValue: '',
                  })(
                    <Input
                      className="company-notices__input"
                      onPressEnter={this.addNotice}
                      placeholder="Add a new company notice (seen by all)"
                    />,
                  )
                }
            </FormItem>
            <div className="company-notices__list">
              {noticesList}
            </div>
          </div>
        </Modal>
      );
    }
  },
);

export default CompanyNotices;
