import {
  SET_NEW_REALTOR_CLIENT_MODAL_ATTACHMENTS,
} from 'store/actionTypes/realtorClients';

const setNewClientModalAttachments = (filesList = []) => ({
  type: SET_NEW_REALTOR_CLIENT_MODAL_ATTACHMENTS,
  payload: { filesList },
});

export default setNewClientModalAttachments;
