import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedClientActions,
} from 'store/actions/realtorClients';

export default createSelector(
  (state) => state.realtorClients.overallSummary,
  (summary) => {
    if (!summary) {
      optimizedClientActions.getClientsOverallSummary(null);

      return {};
    }

    return summary;
  },
);
