import { 
  memoize, 
  debounce, 
  values 
} from 'shared/utility';

function memoizeDebounce(func, wait = 0, options = {}) {
  const mem = memoize(
    () => debounce(func, wait, options),
    // this is to create a cache key using all arguments;
    // see https://justinnoel.dev/2019/06/06/lodash-memoize-with-a-resolver/
    (...args) => values(args).join('_'),
  );

  return function () {
    mem
      .apply(this, arguments) // eslint-disable-line prefer-rest-params
      .apply(this, arguments); // eslint-disable-line prefer-rest-params
  };
}

export default memoizeDebounce;
