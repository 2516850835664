import {
  SET_TASKS_FOR_EMPLOYEE,
} from 'store/actionTypes/tasks';

const setTasksForEmployee = (employeeId) => (tasks) => ({
  type: SET_TASKS_FOR_EMPLOYEE,
  payload: {
    employeeId,
    tasks,
  },
});

export default setTasksForEmployee;
