/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import { meetingType } from 'oa/types/index';
import Modal from 'oa/components/Modal/index';
import Notes from './components/Notes';
import DesiredOutcomes from './components/DesiredOutcomes';
import './style.css';

const MeetingAgendaModal = (props) => {
  const {
    meeting,
    isOpen,
    onClose,
    addDesiredOutcome,
    toggleDesiredOutcome,
    changeMeetingNotes,
  } = props;

  const meetingDesiredOutcomes = meeting.desiredOutcomes || [];
  const meetingNotes = meeting.notes || '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      className="meeting-agenda-modal"
    >
      <Modal.Content>
        <Modal.Title>Meeting Notes and Agenda</Modal.Title>

        <div className="meeting-agenda-modal__columns">
          <Notes
            meetingNotes={meetingNotes}
            changeMeetingNotes={changeMeetingNotes}
          />
          <DesiredOutcomes
            addDesiredOutcome={addDesiredOutcome}
            toggleDesiredOutcome={toggleDesiredOutcome}
            desiredOutcomes={meetingDesiredOutcomes}
          />
        </div>
      </Modal.Content>

      <Modal.Close />
    </Modal>
  );
};

MeetingAgendaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addDesiredOutcome: PropTypes.func.isRequired,
  toggleDesiredOutcome: PropTypes.func.isRequired,
  meeting: meetingType.isRequired,
  changeMeetingNotes: PropTypes.func.isRequired,
};

export default MeetingAgendaModal;
