import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import KPIItem from 'ui/KPIItem';
import Divider from 'ui/Divider';

import RequirementsAddressList from 'sections/Properties/modules/RequirementsAddressList';
import RequirementsPriceRangeDisplay from 'sections/Properties/modules/RequirementsPriceRangeDisplay';
import RequirementsParametersMain from 'sections/Properties/modules/RequirementsParametersMain';
import RequirementsPropertyTypeSelect from 'sections/Properties/modules/RequirementsPropertyTypeSelect';

import PreApprovalControl from 'sections/Properties/modules/RequirementsPreApproval/PreApprovalControl';
import MatchingPropertyCard from 'sections/Prospects/modules/MatchingPropertyCardV2';

const ClientBuyer = (props) => {
  const {
    propertyRequirementId,

    upComingLane,
    upComingMatchId,

    completedLane,
    completedMatchId,

    prospectId,
  } = props;

  return (
    <Flex
      flexDirection="column"
      fullWidth
      spacing={3}
    >
      <Typography
        variant="title2"
        noMargin
      >
        Property Requirements
      </Typography>

      <FlexItem fullWidth>
        <Flex
          spacing={1}
          fullWidth
        >
          <KPIItem
            iconName="dollar-sign"
            variant="flat"
            tooltip="Budget"
          >
            <RequirementsPriceRangeDisplay
              itemId={propertyRequirementId}
            />
          </KPIItem>
          <KPIItem
            iconName="bookmark"
            variant="flat"
            tooltip="Pre-approval"
            fullWidth
          >
            <PreApprovalControl
              propertyRequirementId={propertyRequirementId}
            />
          </KPIItem>
        </Flex>
      </FlexItem>

      <FlexItem fullWidth>
        <KPIItem
          iconName="map-pin"
          variant="flat"
        >
          <RequirementsAddressList
            defaultView="display"
            itemId={propertyRequirementId}
          />
        </KPIItem>
      </FlexItem>

      <FlexItem fullWidth>
        <KPIItem
          iconName="home"
          variant="flat"
        >
          <RequirementsPropertyTypeSelect
            itemId={propertyRequirementId}
            defaultView="display"
            label={null}
            dense
            chipListProps={{
              ellipsis: 1,
            }}
          />
        </KPIItem>
      </FlexItem>

      <Divider />

      <FlexItem fullWidth>
        <RequirementsParametersMain
          itemId={propertyRequirementId}
          defaultView="display"
        />
      </FlexItem>

      <Divider />

      <FlexItem fullWidth>
        <Typography
          variant="title2"
        >
          Next Showing
        </Typography>
        {
          upComingMatchId
          ? (
            <MatchingPropertyCard
              lane={upComingLane}
              prospectId={prospectId}
              matchId={upComingMatchId}
              hideOptions
            />

            )
          : (
            <Typography
              color="descriptor-text"
            >
              No upcoming showings
            </Typography>
            )
        }
      </FlexItem>

      <FlexItem fullWidth>
        <Typography
          variant="title2"
        >
          Last Completed Showing
        </Typography>

        {
          completedMatchId
          ? (
            <MatchingPropertyCard
              lane={completedLane}
              prospectId={prospectId}
              matchId={completedMatchId}
            />

            )
          : (
            <Typography
              color="descriptor-text"
            >
              No completed showings
            </Typography>
            )
        }
      </FlexItem>

    </Flex>
  );
};

const {
  number,
  oneOf,
} = PropTypes;

ClientBuyer.propTypes = {
  propertyRequirementId: number.isRequired,
  upComingMatchId: number.isRequired,
  prospectId: number.isRequired,
  upComingLane: oneOf([
    'assigned',
    'showingsUpcoming',
    'showingsCompleted',
    'offers',
  ]).isRequired,
  completedLane: oneOf([
    'assigned',
    'showingsUpcoming',
    'showingsCompleted',
    'offers',
  ]).isRequired,
  completedMatchId: number.isRequired,
};

export default ClientBuyer;
