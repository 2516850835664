import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Typography from 'ui/Typography';

import RealtorLeadItemDetails from 'sections/Leads/modules/RealtorLeadItemDetails';

import { PRODUCT_ICONS } from 'constants/icons.constants';

import {
  Modal,
} from './styledItems';

const iconStyle = {
  fontSize: 18,
  marginLeft: 5,
};

const LeadIntoProspectModal = (props) => {
  const {
    leadId,
    connectedItemType,
    connectedItemId,
    isVisible,
    onSave,
    onCancel,
  } = props;

  const isBulk = Array.isArray(leadId);

  const title = isBulk
    ? 'Turn selected leads into prospects?'
    : 'Turn this lead into a prospect?';

  return (
    <Modal
      width={850}
      visible={isVisible}
      okText="Confirm"
      onCancel={onCancel}
      onOk={onSave}
      title={title}
    >
      <Grid container spacing={2} direction="column">
        {
          !isBulk && (
            <Grid item xs={12}>
              <RealtorLeadItemDetails
                leadId={leadId}
                connectedItemType={connectedItemType}
                connectedItemId={connectedItemId}
              />
            </Grid>
          )
        }
        <Grid item xs={12}>
          <Typography noMargin>
            {`${isBulk ? 'The selected leads' : 'This lead'} will be moved to the Prospect menu
            which can be found in the main menu bar under the icon:`}
            <FontAwesomeIcon
              icon={PRODUCT_ICONS.prospects}
              style={iconStyle}
            />
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};

LeadIntoProspectModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  number,
  string,
} = PropTypes;

LeadIntoProspectModal.propTypes = {
  isVisible: bool,
  onSave: func.isRequired,
  onCancel: func.isRequired,
  leadId: number.isRequired,
  connectedItemType: string.isRequired,
  connectedItemId: number.isRequired,
};

export default LeadIntoProspectModal;
