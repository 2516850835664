import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  Icon,
} from './styledItems';

const IconButton = (props) => {
  const {
    iconName,
    iconSize,
    onClick,
    color,
    variant,
    type,
  } = props;

  return (
    <Root onClick={onClick} type={type}>
      <Icon
        icon={iconName}
        size={iconSize}
        color={color}
        variant={variant}
      />
    </Root>
  );
};

IconButton.defaultProps = {
  iconSize: 'lg',
  color: null,
  variant: null,
  onClick: () => {},
  type: 'button',
};

const {
  func,
  object,
  oneOfType,
  string,
} = PropTypes;

IconButton.propTypes = {
  iconName: oneOfType([string, object]).isRequired,
  iconSize: string,
  onClick: func,
  color: string,
  variant: string,
  type: string,
};

export default IconButton;
