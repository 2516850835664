import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';
import { createSourceFromNewLeadForm } from 'store/actions/realtorLeads';

import SourceDescriptionModal from './SourceDescriptionModal';

const mapDispatchToProps = (dispatch) => ({
  onSave: async () => {
    const sourceId = await dispatch(createSourceFromNewLeadForm());
    dispatch(setNewLeadFormField('source')(sourceId));
  },
  onCancel: () => {
    dispatch(setNewLeadFormField('sourceDescription')(null));
    dispatch(setNewLeadFormField('sourceType')(null));
  },
});

export default connect(null, mapDispatchToProps)(SourceDescriptionModal);
