import { createSelector } from 'reselect';
import { isEmpty } from 'shared/utility';

export default createSelector(
  (state) => state.realtorLeadsFilter,
  (filter) => {
    if (!filter.status && isEmpty(filter.leadType)) {
      return 'all';
    }
    if (filter.status === 1) {
      return 'active';
    }
    if (filter.status === 2) {
      return 'archived';
    }
    if (filter.status === 7) {
      return 'new';
    }
    if (Array.isArray(filter.leadType) && filter.leadType.includes('1')) {
      return 'buyers';
    }
    if (Array.isArray(filter.leadType) && filter.leadType.includes('2')) {
      return 'sellers';
    }
    return 'all';
  },
);
