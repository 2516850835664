import apiConnector from 'api';

/**
 * Creates a new entry about the company logo in the global database
 * @param {object} newLogoInformation New logo information
 * @param {string} DatabaseName Name of the client database
 */
const createCompanyLogoInGlobal = (newLogoInformation, DatabaseName) => {
  const endpoint = '/attachments/create-company-logo';
  apiConnector.global.post(
    endpoint,
    {
      result: newLogoInformation,
      DatabaseName,
      contentType: 'company logo',
    },
  );
};

export default createCompanyLogoInGlobal;
