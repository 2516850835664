import { callAlert } from 'ui/Alert';
import { apiConnector } from 'shared/API';

export const GET_NOTES = 'GET_NOTES';

export function getNotesSuccess(notes) {
  return {
    type: 'GET_NOTES',
    payload: notes,
  };
}

export const getNotes = (
  data,
) => (dispatch) => {
  apiConnector('/api/leads/notes/get', 'POST', data)
    .then(({ result }) => {
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Get notes: ${e.message}`);
    });
};

export const createNote = (
  data,
) => (dispatch) => {
  apiConnector('/api/leads/notes/create', 'POST', data)
    .then(({ result }) => {
      callAlert.success('Note created!');
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Create note: ${e.message}`);
    });
};

export const editNote = (
  data,
) => (dispatch) => {
  apiConnector('/api/leads/notes/edit', 'POST', data)
    .then(({ result }) => {
      callAlert.success('Note edited!');
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Edit note: ${e.message}`);
    });
};

export const deleteNote = (
  data,
) => (dispatch) => {
  apiConnector('/api/leads/notes/delete', 'POST', data)
    .then(({ result }) => {
      callAlert.success('Note deleted!');
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Delete note: ${e.message}`);
    });
};
