import apiConnector, { endpoints } from 'api';

const getFolders = async () => {
  const endpoint = `${endpoints.client.articles.folderGeneral}`;

  const result = await apiConnector.client.get(endpoint);

  return result;
};

export default getFolders;
