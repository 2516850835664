import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createContact = (contactData) => async (dispatch) => {
  try {
    const response = await requests.realtor.contacts.create(contactData);

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default createContact;
