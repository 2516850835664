import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import Label from 'shared/components/UI/Label';

import {
  Wrapper,
} from './styledItems';

const reminderStatuses = {
  6: {
    name: 'COMPLETED',
    color: 'success',
  },
  1: {
    name: 'SCHEDULED',
    color: 'focus',
  },
};

const ReminderItem = (props) => {
  const {
    onDelete,
    reminder: {
      scheduled_date,
      title,
      comment,
      is_repeat,
      status,
    },
  } = props;

  let reminderLabel = reminderStatuses[status].name;

  if (status === 1) {
    reminderLabel = is_repeat
      ? `${reminderLabel } - REPEATING`
      : reminderLabel;
  }

  return (
    <Wrapper>
      <Grid
        container
        spacing={1}
        justify="space-between"
        style={{
          padding: '5px 14px 1px 8px',
        }}
      >
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography
                variant="h4"
                weight="bold"
                className="date-text"
                noMargin
              >
                {moment(scheduled_date).format('dddd MMMM D — LT')}
              </Typography>
              <Label
                label={reminderLabel}
                color={reminderStatuses[status].color}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <p>
                <Typography
                  tag="span"
                  className="description-text"
                  noMargin
                >
                  {title || 'Title not specified!'}
                </Typography>
                <Typography
                  tag="span"
                  className="description-text"
                  noMargin
                >
                  {comment ? ` — ${comment}` : ' — Comment not specified!'}
                </Typography>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="tertiary"
            icon="trash"
            className="delete-reminder-button"
            onClick={(event) => {
              event.preventDefault();
              onDelete();
            }}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const {
  bool,
  func,
  number,
  shape,
  string,
} = PropTypes;

ReminderItem.propTypes = {
  onDelete: func.isRequired,
  reminder: shape({
    title: string,
    comment: string,
    scheduled_date: string,
    is_repeat: bool,
    status: number,
  }).isRequired,
};

export default ReminderItem;
