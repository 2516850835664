/* eslint-disable react/jsx-no-bind */
import React, {
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import useMenu from 'ui/hooks/useMenu';

const OptionsCell = (props) => {
  const {
    children,
    buttonProps,
  } = props;

  const anchorEl = useRef(null);

  const {
    openMenu,
    closeMenu,
    Menu,
  } = useMenu(anchorEl);

  return (
    <>
      <Button
        buttonRef={anchorEl}
        icon="more-vertical"
        color="main-text"
        onClick={openMenu}
        variant="tertiary"
        {...buttonProps}
      />
      <Menu>
        {children({ closeMenu })}
      </Menu>
    </>
  );
};

OptionsCell.defaultProps = {
  buttonProps: {},
};

const {
  func,
  shape,
} = PropTypes;

OptionsCell.propTypes = {
  children: func.isRequired,
  buttonProps: shape({}),
};

export default OptionsCell;
