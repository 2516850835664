import {
  xor,
  filter,
} from 'shared/utility';

import { callAlert } from 'ui/Alert';

import requests from 'api/requests';

import setActiveChatId from './setActiveChatId';
import getChats from './getChats';

const setActiveGroupChatWithEmployees = (employeesId) => async (dispatch, getState) => {
  try {
    const {
      chat: {
        chatRooms,
      },
    } = getState();

    const existingChatRoom = filter(chatRooms, (value) => {
      if (value.type === 2) {
        const chatMembers = value.members.map((member) => member.user_id);
        return xor(chatMembers, employeesId).length === 0;
      }
      return false;
    });

    if (existingChatRoom.length > 0) {
      dispatch(setActiveChatId(existingChatRoom[0].id));

      return null;
    }
    const newChat = await requests.chat.createGroupChat();
    const newChatId = newChat.data;

    await Promise.all(
      employeesId.map(async (employeeId) => {
        await requests.chat.addChatMember(newChatId)({
          userType: 1,
          userId: employeeId,
        });
      }),
    );

    await dispatch(getChats());

    dispatch(setActiveChatId(newChatId));

    return newChatId;
  } catch {
    callAlert.error('Error!');
    return null;
  }
};

export default setActiveGroupChatWithEmployees;
