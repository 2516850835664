import {
  useState,
} from 'react';

import {
  PRODUCT_ICONS,
} from 'constants/icons.constants';

const options = [
  {
    id: 'contacts',
    label: 'Contacts',
    iconName: PRODUCT_ICONS.contacts,
  },
  // {
  //   id: 'leads',
  //   label: 'Leads',
  //   iconName: PRODUCT_ICONS.leads,
  // },
  // {
  //   id: 'clients',
  //   label: 'Clients',
  //   iconName: PRODUCT_ICONS.clients,
  // },
  {
    id: 'properties',
    label: 'Properties',
    iconName: PRODUCT_ICONS.properties,
  },
  // {
  //   id: 'calendar',
  //   label: 'Calendar',
  //   iconName: PRODUCT_ICONS.calendar,
  // },
];

const useObjectTypes = (types = []) => {
  const [selectedTypes, setSelectedTypes] = useState(types);

  return {
    selectedTypes,
    setSelectedTypes,
    objectTypesOptions: options,
  };
};

export default useObjectTypes;
