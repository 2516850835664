import addendumChanges from './addendumChanges';
import clients from './clients';
import contacts from './contacts';
import disclosures from './disclosures';
import documentRequests from './documentRequests';
import escrows from './escrows';
import extensionRequests from './extensionRequests';
import inspections from './inspections';
import inspectionsTypes from './inspectionsTypes';
import leads from './leads';
import loans from './loans';
import matchingProperties from './matchingProperties';
import notablePoints from './notablePoints';
import offers from './offers';
import opportunities from './opportunities';
import properties from './properties';
import propertyRequirements from './propertyRequirements';
import propertyViewings from './propertyViewings';
import prospects from './prospects';
import repairRequests from './repairRequests';
import sources from './sources';
import sourceTypes from './sourceTypes';
import specialConditions from './specialConditions';
import transactions from './transactions';
import unansweredQuestions from './unansweredQuestions';
import prioritiesObjects from './prioritiesObjects';
import marketing from './marketing';
import matched from './matched';
import realtorDirectory from './realtorDirectory';
import appCustomizationSettings from './appCustomizationSettings';
import contactLabels from './contactLabels';
import demo from './demo';
import dataScraping from './dataScraping';

export default {
  addendumChanges,
  prioritiesObjects,
  clients,
  contacts,
  disclosures,
  documentRequests,
  escrows,
  extensionRequests,
  inspections,
  inspectionsTypes,
  leads,
  loans,
  matchingProperties,
  matched,
  notablePoints,
  offers,
  opportunities,
  properties,
  propertyRequirements,
  propertyViewings,
  prospects,
  repairRequests,
  sources,
  sourceTypes,
  specialConditions,
  transactions,
  unansweredQuestions,
  marketing,
  realtorDirectory,
  appCustomizationSettings,
  contactLabels,
  demo,
  dataScraping,
};
