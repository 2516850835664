import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import {
  Root,
} from './styledItems';

const Flex = (props) => {
  const {
    children,
    justifyContent,
    className,
    alignItems,
    flexWrap,
    spacing,
    spacingStep,
    style,
    fullWidth,
    fullHeight,
    flexDirection,
    flex,
    preventStretch,
    padding,
  } = props;

  const gap = spacing * spacingStep;

  const items = React.Children.toArray(children);

  const itemGap = gap * (items.length - 1) / items.length;

  return (
    <Root
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      className={classnames({
        [className]: className,
      })}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      gap={gap}
      style={style}
      flexDirection={flexDirection}
      flex={flex}
      preventStretch={preventStretch}
      padding={padding}
    >
      {items.map((item) => React.cloneElement(item, { gap: itemGap }))}
    </Root>
  );
};

Flex.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  spacing: 0,
  spacingStep: 8,
  children: [],
  flexDirection: 'row',
  className: null,
  fullWidth: false,
  fullHeight: false,
  flex: null,
  style: {},
  preventStretch: true,
  padding: null,
};

const {
  arrayOf,
  bool,
  element,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

Flex.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  justifyContent: string,
  alignItems: string,
  flexWrap: string,
  spacing: number,
  spacingStep: number,
  flexDirection: string,
  className: string,
  fullWidth: bool,
  fullHeight: bool,
  flex: string,
  style: shape({}),
  preventStretch: bool,
  padding: string,
};

export default Flex;
