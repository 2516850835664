import requests from 'api/requests';

import { isEmpty } from 'shared/utility';

// eslint-disable-next-line consistent-return
const checkExistingContact = (field) => (value) => async (dispatch) => {
  try {
    const response = await requests.realtor.contacts.getByField(field, value);

    return !isEmpty(response.data.items)
      ? response.data.items[0]
      : null;
  } catch (error) {
    console.error(error);
  }
};

export default checkExistingContact;
