import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'ui/Alert';
import ContactPopover from './ContactPopover';

const ExistingContactAlert = (props) => {
  const {
    contact,
    text,
  } = props;

  return (
    <Alert
      variant="error"
      message={(
        <>
          {text}
          <ContactPopover contact={contact} />
        </>
      )}
    />
  );
};

const {
  string,
  shape,
} = PropTypes;

ExistingContactAlert.defaultProps = {
  contact: {},
  text: null,
};

ExistingContactAlert.propTypes = {
  text: string,
  contact: shape({
    email_primary: string,
    phone_primary: string,
    first_name: string,
    last_name: string,
  }),
};

export default ExistingContactAlert;
