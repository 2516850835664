import apiConnector, { endpoints } from 'api';

const getByField = async (field, value) => {
  const endpoint = `${endpoints.client.realtor.contacts}`;

  try {
    const result = await apiConnector.client.get(endpoint, {
      [field]: value,
    });

    return result;
  } catch (error) {
    console.error(error);
    // throw (error);
  }
};

export default getByField;
