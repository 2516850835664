import { union } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  createContact,
} from 'store/actions/realtorContacts';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

const defaultNewContact = {
  title: '1',
  first_name: '',
  last_name: '',
  email_primary: '',
  phone_primary: '',
  first_contact_date: '',
};

const defaultValidators = {
  isEmailValid: false,
  doesEmailExist: true,
  doesPhoneExist: true,
};

const createContactFromNewPropertyViewingForm = (setContactAfterCreation = true) => async (dispatch, getState) => {
  try {
    const {
      newPropertyViewingForm,
    } = getState();

    const {
      contacts,
      newContact,
    } = newPropertyViewingForm;

    const newContactId = await dispatch(createContact(newContact));

    if (setContactAfterCreation) {
      const newViewingContacts = union(contacts, [newContactId]);
      dispatch(setNewPropertyViewingFormField('contacts')(newViewingContacts));
    }

    dispatch(setNewPropertyViewingFormField('newContact')(defaultNewContact));
    dispatch(setNewPropertyViewingFormField('newContactFieldsValidators')(defaultValidators));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createContactFromNewPropertyViewingForm;
