import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import Typography from 'ui/Typography';

const ContactControls = (props) => {
  const {
    contactId,
    name,
    email,
    phone,
  } = props;

  return (
    <Typography
      variant="body2"
      color="focus"
      noMargin
      style={{ maxWidth: '100%' }}
      ellipsis
    >
      <Link to={`/contacts/${contactId}`}>{name}</Link>
      {' '}
      {phone && '|'}
      {' '}
      <a href={`tel:${phone || ''}`}>{phone || ''}</a>
      {' '}
      {email && '|'}
      {' '}
      <a href={`mailto:${email || ''}`}>{email || ''}</a>
    </Typography>
  );
};

const {
  number,
  string,
} = PropTypes;

ContactControls.propTypes = {
  contactId: number.isRequired,
  name: string.isRequired,
  email: string.isRequired,
  phone: string.isRequired,
};

export default ContactControls;
