import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import ExistingContactAlert from 'shared/components/UI/ExistingContactAlert';
import PhoneNumberInput from 'ui/PhoneNumberInput';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

const PhoneInput = (props) => {
  const {
    handleChange,
    value,
    checkPhoneExistence,
    onInvalidPhone,
    doesPhoneExist,
    alertText,
    label,
    error,
    defaultValue,
    disabled,
  } = props;

  const [contact, setContact] = useState({});

  const [currentValue, setCurrentValue] = useState(value);

  const [currentPhoneExists, setCurrentPhoneExists] = useState(doesPhoneExist);

  useEffect(() => {
    setCurrentPhoneExists(doesPhoneExist);
  }, [
    doesPhoneExist,
  ]);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const onBlur = useCallback(async () => {
    const existingContact = await checkPhoneExistence(currentValue);

    if (existingContact) {
      setContact(existingContact);

      // setCurrentPhoneExists(true);
      // onInvalidPhone('doesPhoneExist', true);
      setCurrentPhoneExists(false);
      onInvalidPhone('doesPhoneExist', false);
    } else {
      setCurrentPhoneExists(false);
      onInvalidPhone('doesPhoneExist', false);
    }
  }, [
    value,
    onInvalidPhone,
    checkPhoneExistence,
  ]);

  const onChange = useCallback((value) => {
    const newValue = value;

    handleChange(newValue);
    setCurrentValue(newValue);
  }, [
    handleChange,
  ]);

  return (
    <Flex
      spacing={2}
      flexWrap="wrap"
    >
      <FlexItem fullWidth>
        <PhoneNumberInput
          label={label}
          value={currentValue || defaultValue || ''}
          onBlur={onBlur}
          onChange={onChange}
          icon="smartphone"
          // mask="phone"
          // showMask
          error={error}
          disabled={disabled}
        />
      </FlexItem>
      {
        currentPhoneExists && (
          <FlexItem fullWidth>
            <ExistingContactAlert
              text={alertText}
              contact={contact}
            />
          </FlexItem>
        )
      }
    </Flex>
  );
};

PhoneInput.defaultProps = {
  label: 'Phone',
  alertText: 'Phone already exists in the database.',
  doesPhoneExist: false,
  onInvalidPhone: () => {},
  checkPhoneExistence: () => null,
  error: false,
  disabled: false,
  defaultValue: null,
};

const {
  func,
  string,
  bool,
  oneOfType,
} = PropTypes;

PhoneInput.propTypes = {
  alertText: string,
  doesPhoneExist: bool,
  handleChange: func.isRequired,
  checkPhoneExistence: func,
  onInvalidPhone: func,
  value: string.isRequired,
  label: string,
  error: oneOfType([string, bool]),
  disabled: bool,
  defaultValue: string,
};

const decoratorProps = {
  iconName: 'phone',
  iconType: 'feather',
};

export default withFieldDecorator(decoratorProps)(PhoneInput);
