import {
  SET_REALTOR_LEADS_OBJECTS_FOR_FILTER,
} from 'store/actionTypes/realtorLeads';

const setLeadsObjectsForFilter = (filterId) => (leadsObjectsByFilter) => ({
  type: SET_REALTOR_LEADS_OBJECTS_FOR_FILTER,
  payload: {
    leadsObjectsByFilter,
    filterId,
  },
});

export default setLeadsObjectsForFilter;
