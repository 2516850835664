import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  useLocation,
} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from 'ui/Grid';
import Button from 'ui/Button';

import useLocalStorageItem from 'shared/hooks/useLocalStorageItem';
import updateLocalStorage from 'shared/utils/updateLocalStorageWithCustomEvent';

import ExternalPage from 'shared/components/ExternalPage';

import Property from './modules/Property';
import Presenter from './modules/Presenter';
import OfferOverview from './modules/OfferOverview';
import SignedAgreement from './modules/SignedAgreement';
import OfferHistory from './modules/OfferHistory';

const useStyles = makeStyles({
  root: {
    width: '100%',
    justifyContent: 'center',
    margin: 0,
  },
});

const getExternalToken = (pathname) => {
  if (!pathname) return null;

  const token = pathname.split('/').pop();

  return token;
};

const AppbarButtons = (
  <>
    <Button
      variant="tertiary"
      color="contrast"
    >
      What is Symplete Realtor?
    </Button>
    <Button
      variant="tertiary"
      color="contrast"
    >
      View Demo
    </Button>
    <Button
      variant="primary"
    >
      Start Trial
    </Button>
  </>
);

const ExternalOffers = (props) => {
  const {
    offerId,
    rootOfferId,
    getOffer,
  } = props;

  const externalToken = useLocalStorageItem('externalToken');
  const location = useLocation();

  useEffect(() => {
    const token = getExternalToken(location.pathname);
    updateLocalStorage('set', 'externalToken', token);
  }, [
    location?.pathname,
  ]);

  useEffect(() => {
    if (externalToken) {
      getOffer();
    }
  }, [
    externalToken,
  ]);

  const classes = useStyles();

  if (!offerId) {
    return <ExternalPage appbarContent={AppbarButtons} />;
  }

  return (
    <ExternalPage appbarContent={AppbarButtons}>
      <Grid
        container
        spacing={3}
        className={classes.root}
      >

        <Grid
          item
          container
          xs={5}
          spacing={3}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Property offerId={offerId} />
          </Grid>
          <Grid item xs={12}>
            <Presenter />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={7}
          spacing={3}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <OfferOverview offerId={offerId} />
          </Grid>
          <Grid item xs={12}>
            <SignedAgreement />
          </Grid>
          <Grid item xs={12}>
            <OfferHistory
              rootOfferId={rootOfferId}
              selectedOfferId={offerId}
            />
          </Grid>
        </Grid>

      </Grid>
    </ExternalPage>
  );
};

const {
  number,
  func,
} = PropTypes;

ExternalOffers.propTypes = {
  offerId: number.isRequired,
  rootOfferId: number.isRequired,
  getOffer: func.isRequired,
};

export default ExternalOffers;
