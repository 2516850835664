import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import {
  Wrapper,
} from './styledItems';

const LessContactCard = (props) => {
  const {
    contactId,
    name,
    role,
    lastName,
  } = props;

  return (
    <Wrapper>
      <Flex
        spacing={2}
        spacingStep={6}
        className="less-contact-flex-container"
        alignItems="stretch"
      >
        <ContactAvatar
          contactId={contactId}
          size={40}
        />

        <Flex
          flexDirection="column"
          // justifyContent="space-evenly"
        >
          <Typography
            variant="title3"
            weight="bold"
            noMargin
          >
            {`${name || ''} ${lastName || ''}`}
          </Typography>

          <Typography
            variant="subtitle"
            color="descriptor-text"
            weight="normal"
            style={{
              textTransform: 'capitalize',
            }}
            noMargin
          >
            {role}
          </Typography>
        </Flex>
      </Flex>
    </Wrapper>

  );
};

LessContactCard.defaultProps = {
  contactId: null,
  name: null,
  lastName: null,
  role: null,
};

const {
  number,
  string,
} = PropTypes;

LessContactCard.propTypes = {
  contactId: number,
  name: string,
  lastName: string,
  role: string,
};

export default LessContactCard;
