import React from 'react';
import PropTypes from 'prop-types';

import TimelineItemSign from 'shared/components/UI/TimelineItemSign';

import {
  Root,
  TopWrapper,
  Dot,
  Stick,
  Label,
} from './styledItems';

const TimelineItem = (props) => {
  const {
    label,
    signIconName,
    signIconColor,
    customSign,
    dotId,
    isFirst,
    isLast,
    itemDetails,
  } = props;

  return (
    <Root>
      {
        (signIconName || customSign) && (
          <TopWrapper>
            <TimelineItemSign
              customSign={customSign}
              signIconName={signIconName}
              signIconColor={signIconColor}
              event={itemDetails}
            />
            <Stick />
          </TopWrapper>
        )
      }
      <Dot
        isFirst={isFirst}
        isLast={isLast}
        id={dotId}
      />
      {
        label && (
          <Label>{label}</Label>
        )
      }
    </Root>
  );
};

TimelineItem.defaultProps = {
  label: null,
  signIconColor: 'black',
  signIconName: null,
  customSign: null,
  itemDetails: null,
  isFirst: false,
  isLast: false,
};

const {
  bool,
  element,
  shape,
  string,
} = PropTypes;

TimelineItem.propTypes = {
  label: string,
  signIconColor: string,
  signIconName: string,
  customSign: element,
  itemDetails: shape({
    title: string,
    time: string,
    description: string,
  }),
  dotId: string.isRequired,
  isFirst: bool,
  isLast: bool,
};

export default TimelineItem;
