import requests from 'api/requests';

import { getCustomerTicket } from 'store/actions/external-store';
import setTicket from './setTicket';

import getQueueTicketsSummary from './getQueueTicketsSummary';
import getOverallTicketsSummary from './getOverallTicketsSummary';
import getEmployeeTicketsSummary from './getEmployeeTicketsSummary';
import getCustomerTicketsSummary from './getCustomerTicketsSummary';

import clearFilterSummaries from './clearFilterSummaries';

const getTicketAndRefreshRelatedSummaries = (ticketId) => async (dispatch, getState) => {
  const externalStore = getState().externalStore || {};
  const { token, ticketFromUrl } = externalStore;

  let ticket;

  try {
    const response = await requests.tickets.getTicketById(ticketId);

    ticket = response.data;

    dispatch(setTicket(ticket));

    if (token && ticketFromUrl) {
      dispatch(getCustomerTicket(ticketFromUrl.TicketID, token));
    }
  } catch (error) {
    console.error(error);
    return;
  }

  const {
    Assignees = [],
    CustomerID,
    QueueID,
  } = ticket;

  dispatch(getOverallTicketsSummary());

  if (QueueID) {
    dispatch(getQueueTicketsSummary(QueueID));
  }

  if (CustomerID) {
    dispatch(getCustomerTicketsSummary(CustomerID));
  }

  Assignees.forEach((id) => {
    dispatch(getEmployeeTicketsSummary(id));
  });

  dispatch(clearFilterSummaries());
};

export default getTicketAndRefreshRelatedSummaries;
