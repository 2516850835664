import { get } from 'shared/utility';

import connectReducers from 'store/connectReducers';

import {
  SET_SELECTED_TICKET,
  SET_TICKET,
  SET_TICKETS,

  SET_TICKETS_FOR_EMPLOYEE,
  SET_TICKETS_FOR_QUEUE,
  SET_TICKETS_FOR_FILTER,

  SET_TICKETS_COUNT_FOR_EMPLOYEE,
  SET_TICKETS_COUNT_FOR_QUEUE,
  SET_TICKETS_COUNT_FOR_FILTER,

  SET_OVERALL_TICKETS_SUMMARY,
  SET_TICKETS_SUMMARY_FOR_EMPLOYEE,
  SET_TICKETS_SUMMARY_FOR_QUEUE,
  SET_TICKETS_SUMMARY_FOR_FILTER,
  SET_TICKETS_SUMMARY_FOR_CUSTOMER,

  SET_EMPLOYEE_TICKETS_BOARD_VIEW_FILTER,
  SET_ACTIVE_EMPLOYEE_VIEW_FILTER,

  CLEAR_FILTER_SUMMARIES,

  SET_MANAGER_DASHBOARD_SELECTED_QUEUE,
  SET_MANAGER_DASHBOARD_SEARCH,
  SET_MANAGER_DASHBOARD_FILTER,

  SET_CUSTOMER_DETAILS_SELECTED_TICKET_TYPE,

  CLEAR_TICKET_DATA,
} from 'store/actionTypes/tickets';

const initialState = {
  // currently selected items
  selectedTicket: null,
  managerDashboardSelectedQueue: null,
  managerDashboardSearch: null,
  managerDashboardFilter: null,

  // all ticket objects
  tickets: {},

  // sorted ticket ids
  ticketsByEmployees: {},
  ticketsByQueues: {},
  ticketsByFilter: {},

  // counts
  ticketsCountByEmployees: {},
  ticketsCountByQueues: {},
  ticketsCountByFilter: {},

  // summaries
  ticketsSummaryOverall: {},
  ticketsSummaryByEmployees: {},
  ticketsSummaryByQueues: {},
  ticketsSummaryByFilter: {},
  ticketsSummaryByCustomer: {},
  //

  // filters
  activeEmployeeViewFilter: null,

  employeeTicketViewFilter: {
    selectedQueue: {
      name: 'All Queues',
      QueueID: null,
    },
    search: null,
  },
  //

  customerDetailsSelectedTicketType: 'submitted',
};

const ticketListToObject = (tickets = []) => tickets.reduce((ticketsObj, item) => {
  // param reassign is used in reduce by design
  ticketsObj[item.TicketID] = item; // eslint-disable-line no-param-reassign
  return ticketsObj;
}, {});

const getTicketsIds = (tickets = []) => tickets.map((item) => item.TicketID);

const reducers = {
  [SET_SELECTED_TICKET]: (state, action) => ({
    ...state,
    selectedTicket: action.payload,
  }),

  [SET_TICKET]: (state, action) => {
    const ticket = action.payload;

    return {
      ...state,
      tickets: {
        ...state.tickets,
        [ticket.TicketID]: ticket,
      },
    };
  },

  [SET_TICKETS]: (state, action) => {
    const tickets = action.payload;
    return {
      ...state,
      tickets: {
        ...state.tickets,
        ...ticketListToObject(tickets),
      },
    };
  },

  [SET_TICKETS_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      tickets = [],
    } = action.payload;

    const employeesTicketsIds = get(state, `ticketsByEmployees.${employeeId}`, []);

    const updatedEmployeeTicketsIds = new Set([
      ...employeesTicketsIds,
      ...getTicketsIds(tickets),
    ]);

    return {
      ...state,
      ticketsByEmployees: {
        ...state.ticketsByEmployees,
        [employeeId]: [...updatedEmployeeTicketsIds],
      },
    };
  },

  [SET_TICKETS_FOR_QUEUE]: (state, action) => {
    const {
      queueId,
      tickets = [],
    } = action.payload;

    const queuesTicketsIds = get(state, `ticketsByQueues.${queueId}`, []);

    const updatedQueueTicketsIds = new Set([
      ...queuesTicketsIds,
      ...getTicketsIds(tickets),
    ]);

    return {
      ...state,
      ticketsByQueues: {
        ...state.ticketsByQueues,
        [queueId]: [...updatedQueueTicketsIds],
      },
    };
  },

  [SET_TICKETS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      tickets = [],
    } = action.payload;

    const filterTicketsIds = state.ticketsByFilter[filterId] || [];

    const updatedFilterTicketsIds = new Set([
      ...filterTicketsIds,
      ...getTicketsIds(tickets),
    ]);

    const updatedState = {
      ...state,
      ticketsByFilter: {
        ...state.ticketsByFilter,
        [filterId]: [...updatedFilterTicketsIds],
      },
    };

    return updatedState;
  },

  // count
  [SET_TICKETS_COUNT_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      ticketsCount,
    } = action.payload;

    return {
      ...state,
      ticketsCountByEmployees: {
        ...state.ticketsCountByEmployees,
        [employeeId]: ticketsCount,
      },
    };
  },

  [SET_TICKETS_COUNT_FOR_QUEUE]: (state, action) => {
    const {
      queueId,
      ticketsCount,
    } = action.payload;

    return {
      ...state,
      ticketsCountByQueues: {
        ...state.ticketsCountByQueues,
        [queueId]: ticketsCount,
      },
    };
  },

  [SET_TICKETS_COUNT_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      ticketsCount,
    } = action.payload;

    return {
      ...state,
      ticketsCountByFilter: {
        ...state.ticketsCountByFilter,
        [filterId]: ticketsCount,
      },
    };
  },

  //

  // summaries
  [SET_OVERALL_TICKETS_SUMMARY]: (state, action) => {
    const ticketsSummary = action.payload;

    return {
      ...state,
      ticketsSummaryOverall: ticketsSummary,
    };
  },

  [SET_TICKETS_SUMMARY_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      ticketsSummary,
    } = action.payload;

    return {
      ...state,
      ticketsSummaryByEmployees: {
        ...state.ticketsSummaryByEmployees,
        [employeeId]: ticketsSummary,
      },
    };
  },

  [SET_TICKETS_SUMMARY_FOR_QUEUE]: (state, action) => {
    const {
      queueId,
      ticketsSummary,
    } = action.payload;

    return {
      ...state,
      ticketsSummaryByQueues: {
        ...state.ticketsSummaryByQueues,
        [queueId]: ticketsSummary,
      },
    };
  },

  [SET_TICKETS_SUMMARY_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      ticketsSummary,
    } = action.payload;

    return {
      ...state,
      ticketsSummaryByFilter: {
        ...state.ticketsSummaryByFilter,
        [filterId]: ticketsSummary,
      },
    };
  },

  [SET_TICKETS_SUMMARY_FOR_CUSTOMER]: (state, action) => {
    const {
      customerId,
      ticketsSummary,
    } = action.payload;

    return {
      ...state,
      ticketsSummaryByCustomer: {
        ...state.ticketsSummaryByCustomer,
        [customerId]: ticketsSummary,
      },
    };
  },

  [CLEAR_FILTER_SUMMARIES]: (state) => ({
    ...state,
    ticketsSummaryByFilter: {},
  }),
  //

  [SET_EMPLOYEE_TICKETS_BOARD_VIEW_FILTER]: (state, action) => {
    const filterUpdate = action.payload;

    return {
      ...state,
      employeeTicketViewFilter: {
        ...state.employeeTicketViewFilter,
        ...filterUpdate,
      },
    };
  },

  [SET_ACTIVE_EMPLOYEE_VIEW_FILTER]: (state, action) => {
    const filterId = action.payload;

    return {
      ...state,
      activeEmployeeViewFilter: filterId,
    };
  },

  [SET_MANAGER_DASHBOARD_SELECTED_QUEUE]: (state, action) => ({
    ...state,
    managerDashboardSelectedQueue: action.payload,
  }),

  [SET_MANAGER_DASHBOARD_SEARCH]: (state, action) => ({
    ...state,
    managerDashboardSearch: action.payload,
  }),

  [SET_MANAGER_DASHBOARD_FILTER]: (state, action) => ({
    ...state,
    managerDashboardFilter: action.payload,
  }),

  [SET_CUSTOMER_DETAILS_SELECTED_TICKET_TYPE]: (state, action) => ({
    ...state,
    customerDetailsSelectedTicketType: action.payload,
  }),

  [CLEAR_TICKET_DATA]: () => initialState,
};

const tickets = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default tickets;
