import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';

import classnames from 'classnames';
import './Typography.scss';

const tags = {

};

class Typography extends Component {
  constructor(props) {
    super(props);

    this.getTag = this.getTag.bind(this);
  }

  getTag() {
    const {
      tag,
      variant,
    } = this.props;

    if (tag) {
      return tag;
    }

    if (tags[variant]) {
      return tags[variant];
    }

    return 'span';
  }


  render() {
    const Tag = this.getTag();
    const {
      children,
      variant,
      className,
      onClick,
    } = this.props;

    if (!children) {
      return null;
    }

    return (
      <Tag
        className={classnames(
          `symplete-typography--${variant}`,
          {
            [className]: className,
          },
        )}
        onClick={onClick}
      >
        {children}
      </Tag>
    );
  }
}

export default Typography;

Typography.defaultProps = {
  variant: 'default',
  className: '',
  onClick: () => { },
};

const {
  string,
  number,
  oneOfType,
  element,
  array,
} = PropTypes;

Typography.propTypes = {
  children: oneOfType([string, number, element, array]),
  variant: string,
  tag: string,
  className: string,
  onClick: func,
};
