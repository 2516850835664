import { connect } from 'react-redux';

import getOffersViewItems from 'store/actions/realtorOffers/getOffersViewItems';

import { openModal } from 'store/actions/modals';

import {
  openOfferDeclineReasonModal,
} from 'store/actions/offerDeclineReasonModal';

import ViewingOptions from './ViewingOptions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDecline: () => dispatch(openOfferDeclineReasonModal(ownProps.offerId, true)),

  openAcceptCounterModal: () => {
    dispatch(openModal('acceptOfferModal')({
      callbacks: {
        onAfterClose: () => {
          dispatch(getOffersViewItems());
        },
      },
    }, {
      offerId: ownProps.offerId,
      rootOfferId: ownProps.rootOfferId,
    }));
  },

});

export default connect(null, mapDispatchToProps)(ViewingOptions);
