import React from 'react';
import PropTypes from 'prop-types';

import { useDropzone } from 'react-dropzone';

import Icon from 'ui/Icon';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const sizeProps = {
  s: {
    iconSize: 'm',
    typographyVariant: 'body2',
    padding: '10px 20px',
  },
  lg: {
    iconSize: 'xxl',
    typographyVariant: 'body',
    padding: '40px',
  },
};

const FileDropzone = (props) => {
  const {
    dropzoneProps = {},
    text,
    isLoading,
    size,
  } = props;

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    ...dropzoneProps,
  });

  const {
    iconSize,
    typographyVariant,
    padding,
  } = sizeProps[size];

  return (
    <Root
      {...getRootProps({ className: 'dropzone' })} // eslint-disable-line react/jsx-props-no-spreading
      flexDirection="column"
      alignItems="center"
      spacing={2}
      fullWidth
      padding={padding}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      {
        !isLoading && (
          <Icon
            size={iconSize}
            color="focus"
            name="inbox"
          />
        )
      }
      {
        isLoading && (
          <Spin />
        )
      }
      <Typography
        align="center"
        noMargin
        color="primary"
        variant={typographyVariant}
      >
        { text }
      </Typography>
    </Root>
  );
};

FileDropzone.defaultProps = {
  dropzoneProps: {},
  text: 'Click to upload or drop a file here.',
  isLoading: false,
  size: 'lg',
};

const {
  bool,
  shape,
  string,
  oneOf,
} = PropTypes;

FileDropzone.propTypes = {
  /**
   * any valid react-dropzone props
   */
  dropzoneProps: shape(),
  /**
   * dropzone text
   */
  text: string,
  isLoading: bool,
  size: oneOf(['s', 'lg']),
};

export default FileDropzone;
