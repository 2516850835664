import styled from 'styled-components';

import { Button } from 'antd';

export const StyledButton = styled(Button)`
  border-color: #1584FF;
  color: #1584FF;
  width: 100%;
  margin-top: 10px;
  margin-left: 40px;
`;

export default {
  StyledButton,
};
