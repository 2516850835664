import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Radio from 'ui/Radio';

import {
  Root,
} from './styledItems';

const RadioGroup = (props) => {
  const {
    items,
    name,
    defaultValue,
    onChange,
    flexDirection,
    gap,
    customRadioComponent,
    disabled,
  } = props;

  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback((value) => {
    setCurrentValue(value);
    onChange(value);
  }, [onChange]);

  const RadioComponent = customRadioComponent || Radio;

  return (
    <Root
      flexDirection={flexDirection}
      gap={gap}
    >
      {
        items.map(({
 value, displayedValue, disabled: disabledItem, src,
}) => (
  <RadioComponent
    name={name}
    disabled={disabled || disabledItem}
    value={value}
    displayedValue={displayedValue}
    onChange={handleChange}
    checked={disabled ? false : currentValue === value}
    src={src}
  />
        ))
      }
    </Root>
  );
};

RadioGroup.defaultProps = {
  items: [],
  name: null,
  defaultValue: null,
  onChange: () => {},
  flexDirection: 'column',
  gap: '8px',
  customRadioComponent: null,
  disabled: false,
};

const {
  arrayOf,
  bool,
  func,
  shape,
  string,
  oneOf,
  element,
} = PropTypes;

RadioGroup.propTypes = {
  items: arrayOf(shape({
    value: string,
    disabled: bool,
  })),
  name: string,
  defaultValue: string,
  onChange: func,
  flexDirection: oneOf(['column', 'row']),
  gap: string,
  customRadioComponent: element,
  disabled: bool,
};

export default RadioGroup;
