import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchItemContact from 'shared/components/UI/SearchItemContact';

const mapStateToProps = (state, ownProps) => {
  const {
    objectId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, objectId);

  return {
    contactId: objectId,
    role: contact?.role,
  };
};

export default connect(mapStateToProps)(SearchItemContact);
