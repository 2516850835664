import apiConnector, { endpoints } from 'api';

const reset = async () => {
  const endpoint = endpoints.client.realtor.demo;
  try {
    const result = await apiConnector.client.put(endpoint);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default reset;
