import requests from 'api/requests';

import setFulfillmentsSummaryForFilter from './setFulfillmentsSummaryForFilter';

const getFulfillmentsSummaryForFilter = (filterId) => (filter) => async (dispatch) => {
  try {
    const summaryResponse = await requests.fulfillments.getSummaryForFilter(filter);

    dispatch(setFulfillmentsSummaryForFilter(filterId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentsSummaryForFilter;
