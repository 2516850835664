import { connect } from 'react-redux';

import {
  expandModal,
  closeAllModals,
} from 'store/actions/video-chat';
import SmallChatModal from './SmallChatModal';

const mapStateToProps = (state) => {
  const {
    videoChat: {
      isSmallVideoChatOpen,
    },
  } = state;

  return {
    isVisible: isSmallVideoChatOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onExpand: () => {
    dispatch(expandModal());
  },
  onExit: () => {
    ownProps.leaveMeeting();
    dispatch(closeAllModals());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SmallChatModal);
