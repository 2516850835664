/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import NewDocumentRequestModal from './NewDocumentRequestModal';

const closeDocumentRequestModal = closeModal('newDocumentRequestModal');

const mapStateToProps = (state) => {
  const {
    modals: {
      newDocumentRequestModal,
    },
  } = state;

  const isVisible = newDocumentRequestModal?.isVisible;

  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeDocumentRequestModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDocumentRequestModal);
