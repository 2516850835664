import requests from 'api/requests';

import setOffersViewItems from './setOffersViewItems';

const getOffersViewItems = () => async (dispatch) => {
  try {
    const response = await requests.realtor.offers.getOpportunitiesOffers();

    dispatch(setOffersViewItems(response.data.items));
  } catch (error) {
    console.error(error);
  }
};

export default getOffersViewItems;
