import React from 'react';
import PropTypes from 'prop-types';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripePromise = publishableKey && loadStripe(publishableKey);

class StripeProvider extends React.Component {
  componentDidMount() {
    const {
      getStripePaymentMethods,
    } = this.props;

    getStripePaymentMethods();
  }

  render() {
    const {
      children,
    } = this.props;

    if (!stripePromise) return children;

    return (
      <Elements stripe={stripePromise}>
        {children}
      </Elements>
    );
  }
}

StripeProvider.defaultProps = {
  children: null,
};

const {
  arrayOf,
  element,
  func,
  oneOfType,
} = PropTypes;

StripeProvider.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  getStripePaymentMethods: func.isRequired,
};

export default StripeProvider;
