import connectReducers from 'store/connectReducers';

import {
  SET_COUNTRIES_STATE,
} from 'store/actionTypes/globalData';

const initialState = {
  countries: [],
};

const reducers = {
  [SET_COUNTRIES_STATE]: (state, action) => {
    const {
      payload: {
        countries,
      },
    } = action;

    const processedCountries = countries.map((country) => ({
      name: country.CountryName,
      code: country.CountryCode,
    }));

    return {
      ...state,
      countries: processedCountries,
    };
  },
};

const globalData = (state = initialState, action) => connectReducers(reducers, state, action);

export default globalData;
