import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_LOAN,
} from 'store/actionTypes/realtorLoans';

const initialState = {
  loans: {},
};

const reducers = {
  [SET_REALTOR_LOAN]: (state, action) => ({
    ...state,
    loans: {
      ...state.loans,
      [action.payload.id]: {
        ...action.payload,
      },
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
