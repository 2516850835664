import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  clearFilter,
  resetFilterItem,
} from 'store/actions/crm/crmLeadsFilter';

import {
  getLeadsForStoreFilter,
} from 'store/actions/crm/crmLeads';
import ActiveFilters from './ActiveFilters';

const mapStateToProps = (state) => {
  const activeFilters = selectors.crm.activeFilterChips(state);

  return {
    activeFilters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onDelete: (key) => {
    dispatch(resetFilterItem(key));
    dispatch(getLeadsForStoreFilter());
  },
  onClear: () => {
    dispatch(clearFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveFilters);
