import {
  SET_TASKS_COUNT_FOR_FILTER,
} from 'store/actionTypes/tasks';

const setTasksCountForFilter = (filterId) => (tasksCount) => ({
  type: SET_TASKS_COUNT_FOR_FILTER,
  payload: {
    filterId,
    tasksCount,
  },
});

export default setTasksCountForFilter;
