import styled from 'styled-components';

export const List = styled.ul`
  padding: 2px 5px 5px 2px;
  margin-bottom: 0px;
  list-style-type: none;

  li {
    margin-bottom: 10px;
  }
`;

export default {
  List,
};
