import styled from 'styled-components';

import DefaultModal from 'ui/Modal';

export const Modal = styled(DefaultModal)`
  .ant-modal-footer {
    border-top: none;
    .ant-btn {
      border: none;
      font-weight: 700;
      &-primary {
        min-width: 90px;
        background-color: #1584ff;
      }
      &:hover {
        color: #1584ff;
        &.ant-btn-primary {
          color: white
        }
      }
    }
  }
`;
export default {
  Modal,
};
