import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import CommentsModule from 'sections/Communications/modules/Comments';

import useModal from 'ui/hooks/useModal';

const Comments = (props) => {
  const {
    contactId,
  } = props;

  const {
    Modal,
    openModal,
    closeModal,
    isModalVisible,
  } = useModal();

  return (
    <>
      <Button
        onClick={openModal}
        variant="flat"
      >
        Comments
      </Button>
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        width={840}
      >
        <CommentsModule
          objectId={contactId}
          objectType="realtor contact"
        />
      </Modal>
    </>
  );
};

const {
  number,
} = PropTypes;

Comments.propTypes = {
  contactId: number.isRequired,
};

export default Comments;
