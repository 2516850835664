import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => props.gap}px;
  position: relative;
  flex-direction: ${(props) => props.flexDirection};
  ${(props) => props.fullWidth && 'width: 100%;'}
  ${(props) => props.fullHeight && 'height: 100%;'}
  ${(props) => (props.flex && `flex: ${props.flex};`)}
  ${(props) => (props.preventStretch && 'min-width: 0;')}
  ${(props) => (props.padding && `padding: ${props.padding};`)}
`;

export default {
  Root,
};
