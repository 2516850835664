import apiConnector, { endpoints } from 'api';

const deleteRelation = (labelId) => (labeledObjectType) => async (labeledObjectId) => {
  const endpoint = `${endpoints.client.labels}/relation`;

  const body = {
    labelId,
    labeledObjectType,
    labeledObjectId,
  };

  try {
    const result = await apiConnector.client.delete(endpoint, body);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default deleteRelation;
