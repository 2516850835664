import apiConnector, { endpoints } from 'api';

const addSubqueue = (queueId) => async (subqueueName) => {
  const endpoint = `${endpoints.client.subqueues}/AddQueueCategory`;

  try {
    const result = await apiConnector.client.post(endpoint, {
      QueueID: queueId,
      Category: subqueueName,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default addSubqueue;
