const forceFocus = (inputElement) => {
  if (inputElement?.current) {
    if (inputElement?.current?.input) { // works with react-numeric
      inputElement.current.input.focus();
    } else if (inputElement?.current?.inputElement) {
      // eslint-disable-next-line chai-friendly/no-unused-expressions
      inputElement?.current?.inputElement.focus(); // works with react-text-mask
    } else if (inputElement?.current?.focus) {
      inputElement.current.focus();
    }
  }
};

export default forceFocus;
