import styled from 'styled-components';

export const SelectWrapper = styled.div`
  min-width: 150px;
  padding-left: 5px;
`;

export default {
  SelectWrapper,
};
