import updateProperty from './updateProperty';

const updateSelectedProperty = (updateData) => async (dispatch, getState) => {
  const {
    properties: {
      selectedProperty,
    },
  } = getState();

  await dispatch(updateProperty(selectedProperty)(updateData));
};

export default updateSelectedProperty;
