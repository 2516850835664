import requests from 'api/requests';

import setTransactions from './setTransactions';
import setTransactionsForFilter from './setTransactionsForFilter';
import setTransactionsForFilterCount from './setTransactionsForFilterCount';

const getTransactionsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.transactions.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setTransactions(response.data.items));
    dispatch(setTransactionsForFilter(filterId)(response.data.items));
    dispatch(setTransactionsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getTransactionsForFilter;
