/* eslint-disable */
// FlySoft
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
import React, { Component } from 'react';

import {
  Input,
  Row,
  Radio,
  Avatar,
  List,
  Popover,
  Button,
} from 'antd';
import Highlighter from 'react-highlight-words';
import ReactHtmlParser from 'react-html-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import './SearchFilter.scss';

const RadioButton = Radio.Button;

const RadioGroup = Radio.Group;

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.state = {
      suggestions: undefined,
      loading: false,
      isSearchActive: false,
      showedStarredArticle: [],
      showedLastSeenArticles: [],
      showMoreBtn: true,
      popoverVisibility: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * handle click for closing article search preview
   * @param event
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && this.searchInput
      && (!this.wrapperRef.contains(event.target) || !this.searchInput.current.input === event.target)
      && !this.state.popoverVisibility) {
      this.setState({ isSearchActive: false });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  /**
   * fetch articles for search suggestions
   */
  fetchArticles = () => {
    const searchText = this.searchInput.current.input.value;
    const { isSearchActive } = this.state;
    const { getInitialSearchArticles, searchArticles, kbSearchFilterData } = this.props;

    if (!isSearchActive) {
      this.setState({ isSearchActive: true });
    }

    this.setState({
      highlightWords: searchText,
    });
    if (searchText !== '') {
      searchArticles(searchText, kbSearchFilterData);
    } else {
      getInitialSearchArticles();
    }
  };

  /**
   * get helpful rate
   * @param helpful
   * @returns helpful rate
   */
  getHelpfull = (helpful) => {
    if (helpful === null) {
      return;
    }
    if (helpful <= 40) {
      return (
        <span
          className="info-feedback_nh info-feedback"
        >
          Not Helpful
        </span>
      );
    } if (helpful > 40 && helpful < 90) {
      return (
        <span
          className="info-feedback_rh info-feedback"
        >
          Reasonably Helpful
        </span>
      );
    } if (helpful > 90) {
      return (
        <span
          className="info-feedback_vh info-feedback"
        >
          Very Helpful
        </span>
      );
    }
  };

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  renderPopoverContent = (suggestionHoverArticle) => {
    const {
      title, purpose, is_helpful, text, isStarred,
    } = suggestionHoverArticle;
    return (
      <div className="search-suggestion">
        <div className="search-suggestion__header">
          <div className="search-suggestion-title-wrap">
            <div className="search-suggestion__header__name">{title}</div>
            <div className="search-suggestion__header__purpose">{purpose}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              isStarred && (
                <div className="starred" style={{ marginRight: '15px' }}>
                  <FontAwesomeIcon
                    icon="star"
                    className="star"
                    style={{ color: '#ffcc00', fontSize: '16px' }}
                  />
                </div>
              )
            }
            <div className="search-suggestion__header__rate">
              <div>
                <FontAwesomeIcon
                  icon="thumbs-up"
                  className=""
                  style={{ transition: '0.2s all ease-in' }}
                  color={is_helpful !== null && is_helpful === 1 ? '#52c41a' : '#a1bdcb'}
                />
              </div>
              <span className="helpful-title">Helpful?</span>
              <div>
                <FontAwesomeIcon
                  icon="thumbs-down"
                  style={{ transition: '0.2s all ease-in' }}
                  color={is_helpful !== null && is_helpful === 0 ? '#fa8c16' : '#a1bdcb'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="search-suggestion__body">
          { ReactHtmlParser(text) }
        </div>
      </div>
    );
  };


  /**
   * render search suggestion item
   * @param searchSuggestions
   * @returns {*}
   */
  renderSearchItem = (searchSuggestions) => (
    <List
      itemLayout="horizontal"
      dataSource={searchSuggestions}
      renderItem={(suggestion) => (
        <List.Item
          onMouseEnter={() => this.props.onSearchSuggestionArticleHover(suggestion.id)}
          onClick={() => this.props.openArticle(suggestion.id)}
          className="suggestion-item-list"
        >
          <Popover
            content={this.renderPopoverContent(this.props.suggestionHoverArticle)}
            placement="right"
            overlayClassName="suggestion-preview-container"
            onVisibleChange={(visible) => this.setState({ popoverVisibility: visible })}
          >
            <div className="suggestion-item">
              <Highlighter
                highlightClassName="highlighterText"
                className="suggestion-item__title"
                searchWords={[this.state.highlightWords]}
                autoEscape
                textToHighlight={suggestion.title}
              />
              <Highlighter
                highlightClassName="highlighterText"
                className="suggestion-item__subtitle"
                searchWords={[this.state.highlightWords]}
                autoEscape
                textToHighlight={suggestion.purpose}
                highlightStyle={{ backgroundColor: '#50e3c2' }}
              />
              <div className="suggestion-item__info">
                { this.getHelpfull(suggestion.helpfulRate) }
                <span
                  className="item-year"
                  style={{ marginLeft: '0' }}
                >
                  {`${moment(suggestion.created_at).toNow(true)} ago`}
                </span>
                <Avatar
                  size={20}
                  style={{ backgroundColor: '#6b88a4', zIndex: 2 }}
                  icon="user"
                />
              </div>
            </div>
          </Popover>
        </List.Item>
      )}
    />
  );


  render() {
    const {
      isSearchActive,
      showMoreBtn,
    } = this.state;

    const {
      onShowSearchFilterModal,
      searchSuggestions,
    } = this.props;

    let starredArticle = searchSuggestions
      ? searchSuggestions.starredArticle
        ? [...searchSuggestions.starredArticle]
        : null
      : null;

    let lastSeenArticles = searchSuggestions
      ? searchSuggestions.lastSeenArticles
        ? [...searchSuggestions.lastSeenArticles]
        : null
      : null;

    if (starredArticle && showMoreBtn) {
      starredArticle = starredArticle.splice(0, 3);
      lastSeenArticles = lastSeenArticles.splice(0, 3);
    }

    return (
      <Row>
        <div className="filter-row">
          <div className="filter-row__inner">
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <div className="search-input-wrap">
                <Input
                  ref={this.searchInput}
                  className="main-search"
                  placeholder="Search"
                  onChange={this.fetchArticles}
                  onFocus={() => {
                    this.setState({ isSearchActive: true });
                    this.fetchArticles();
                  }}
                />
                {isSearchActive && (
                  <div className="suggestion-results-wrap" ref={this.setWrapperRef}>
                    <div className="suggestion-results">
                      <div className="suggestion-title">
                        <span>{starredArticle ? 'STARRED' : 'SEARCH RESULTS'}</span>
                      </div>
                      {
                        this.renderSearchItem(
                          starredArticle || searchSuggestions,
                        )
                      }
                      {
                        lastSeenArticles && (
                          <>
                            <div className="suggestion-title">
                              <span>RECENTLY VIEWED</span>
                            </div>
                            {this.renderSearchItem(lastSeenArticles)}
                          </>
                        )
                      }
                      {
                        starredArticle && this.props.searchSuggestions.starredArticle
                        && (this.props.searchSuggestions.starredArticle.length > 3
                          || this.props.searchSuggestions.lastSeenArticles.length > 3)
                        && showMoreBtn
                          ? (
                            <div className="showMoreSearch">
                              <Button
                                type="primary"
                                ghost
                                style={{ border: 'none', boxShadow: 'none' }}
                                onClick={() => {
                                  this.setState({
                                    showedStarredArticle: starredArticle,
                                    showedLastSeenArticles: lastSeenArticles,
                                    showMoreBtn: false,
                                  });
                                }}
                              >
                                Show more
                              </Button>
                            </div>
                          )
                          : null
                      }
                    </div>
                  </div>
                )}
              </div>
              {/*<div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                <FontAwesomeIcon
                  style={{ height: '100%' }}
                  icon="filter"
                  className="datatable-header__filter"
                  onClick={onShowSearchFilterModal}
                />
              </div>*/}
            </div>
            {/* unscope func
            uncomment this for add radiobuttons */}

            {/* <div style={{width: '90%'}}> */}
            {/*  <div className={'filter-radio'}> */}
            {/*    <div className="filter-radio__inner"> */}
            {/*      <RadioGroup defaultValue="all" className={'filter-radio-group'}> */}
            {/*        <RadioButton value="all" className={'product-btn'}>All</RadioButton> */}
            {/*        <RadioButton value="hr" className={'product-btn'}>HR</RadioButton> */}
            {/*        <RadioButton value="tickets" className={'product-btn'}>Tickets</RadioButton> */}
            {/*        <RadioButton value="tasks" className={'product-btn'}>Tasks</RadioButton> */}
            {/*        <RadioButton value="sales" className={'product-btn'}>Sales</RadioButton> */}
            {/*        <RadioButton value="cashflow" className={'product-btn'}>Cashflow</RadioButton> */}
            {/*        <RadioButton value="surveys" className={'product-btn'}>Surveys</RadioButton> */}
            {/*      </RadioGroup> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </div> */}

          </div>
        </div>
      </Row>
    );
  }
}

export default SearchFilter;
