import refreshFulfillment from './refreshFulfillment';

const refreshSelectedFulfillment = () => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  await dispatch(refreshFulfillment(selectedFulfillment));
};

export default refreshSelectedFulfillment;
