import { common } from 'constants';

const {
  USER_AVAILABILITY_CODES_TO_BADGE_MAP,
} = common;

const getAvailabilityStatusByCode = (statusCode) => {
  const status = USER_AVAILABILITY_CODES_TO_BADGE_MAP[statusCode]
    ? USER_AVAILABILITY_CODES_TO_BADGE_MAP[statusCode]
    : USER_AVAILABILITY_CODES_TO_BADGE_MAP.default;

  return status;
};

export default getAvailabilityStatusByCode;
