import { createSelector } from 'reselect';

import {
  optimizedOrderActions,
} from 'store/actions/orders';

export default createSelector(
  (state, id) => ({
    orders: state.orders.orders,
    id,
  }),
  ({ orders, id }) => {
    if (!id) return null;

    const dataObject = orders[id];

    if (!dataObject) {
      optimizedOrderActions.getOrderById(id);
    }

    return dataObject || {};
  },
);
