import apiConnector, { endpoints } from 'api';

const getSummaryForFilter = async (query) => {
  const endpoint = endpoints.client.fulfillmentsSummary;

  try {
    const result = await apiConnector.client.get(endpoint, query);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getSummaryForFilter;
