import requests from 'api/requests';

import setOpportunities from './setOpportunities';

const getOpportunities = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.opportunities.get({
      offset,
      limit,
    });

    dispatch(setOpportunities(response.data.items));
  } catch (error) {
    console.error(error);
  }
};

export default getOpportunities;
