import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Button from '../Button';
import Typography from '../Typography';
import './TimerReminder.scss';

class TimerReminder extends Component {
  render() {
    const {
      cancelButtonText,
      okButtonText,
      children,
      onCancel,
      subtitle,
      visible,
      title,
      onOk,
    } = this.props;

    return (
      <Modal
        className="symplete-timer-reminder"
        closable={false}
        visible={visible}
        title={
          title && (
            <>
              <Typography tag="div" variant="modal-title">
                {title}
              </Typography>
              {subtitle && (
                <Typography tag="div" variant="modal-subtitle">
                  {subtitle}
                </Typography>
              )}
            </>
          )
        }
        footer={[
          <Button key={1} type="ghost" onClick={onCancel}>
            {cancelButtonText}
          </Button>,
          <Button key={2} type="primary" variant="wide" onClick={onOk}>
            {okButtonText}
          </Button>,
        ]}
      >
        {children}
      </Modal>
    );
  }
}

export default TimerReminder;

TimerReminder.defaultProps = {
  okButtonText: 'I Am',
  cancelButtonText: 'I’m Not - Stop Timer',
  onOk: () => {},
  onCancel: () => {},
};

const { bool, func, string } = PropTypes;

TimerReminder.propTypes = {
  okButtonText: string,
  cancelButtonText: string,
  onOk: func,
  onCancel: func,
  title: string.isRequired,
  subtitle: string,
  visible: bool,
};
