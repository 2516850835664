/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import DraggableWrapper from 'shared/components/OA/DraggableWrapper';
import OASmallModal from 'shared/components/OA/SmallModal';
import { meetingParticipantType, thumbnailType } from 'oa/types/index';

const SmallChatModal = (props) => {
  const {
    isVisible,
    onExpand,
    onExit,
    isUserAloneInMeeting,
    mainStream,
    onMic,
    onCamera,
    isMicActive,
    isCameraActive,
    isCameraAvailable,
    isMicrophoneAvailable,
    remoteParticipants,
    thumbnails,
    pinnedParticipant,
    pinParticipant,
    unpinParticipant,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <DraggableWrapper>
      <OASmallModal
        onExpand={onExpand}
        onExit={onExit}
        isUserAloneInMeeting={isUserAloneInMeeting}
        mainStream={mainStream}
        onMic={onMic}
        onCamera={onCamera}
        isMicActive={isMicActive}
        isCameraActive={isCameraActive}
        isCameraAvailable={isCameraAvailable}
        isMicrophoneAvailable={isMicrophoneAvailable}
        remoteParticipants={remoteParticipants}
        thumbnails={thumbnails}
        pinnedParticipant={pinnedParticipant}
        pinParticipant={pinParticipant}
        unpinParticipant={unpinParticipant}
      />
    </DraggableWrapper>
  );
};

SmallChatModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  instanceOf,
  arrayOf,
} = PropTypes;

SmallChatModal.propTypes = {
  isVisible: bool,
  onExpand: func.isRequired,
  onExit: func.isRequired,
  isUserAloneInMeeting: bool.isRequired,
  mainStream: instanceOf(MediaStream),
  onMic: func.isRequired,
  onCamera: func.isRequired,
  isMicActive: bool.isRequired,
  isCameraActive: bool.isRequired,
  isCameraAvailable: bool.isRequired,
  isMicrophoneAvailable: bool.isRequired,
  remoteParticipants: arrayOf(meetingParticipantType).isRequired,
  thumbnails: arrayOf(thumbnailType).isRequired,
  pinnedParticipant: meetingParticipantType,
  pinParticipant: func.isRequired,
  unpinParticipant: func.isRequired,
};

export default SmallChatModal;
