import {
  useSelector,
} from 'react-redux';

const useReselect = (selector, ...args) => {
  const state = useSelector((state) => state);

  return selector(state, ...args);
};

export default useReselect;
