import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import selectors from 'store/selectors';

import ContactInfo from './ContactInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);
  const settings = selectors.appCustomization.settings(state);

  return {
    contactId: contact?.id,
    email: contact?.email_primary,
    phone: contact?.phone_primary,
    type: contact?.type,
    isVip: !!contact?.is_vip,
    isPastClient: !!contact?.is_past_client,
    // labels: contact?.labels,
    withSalutations: !!settings.salutations_on,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    dispatch(updateContact(ownProps.contactId)({
      [key]: value,
    }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
