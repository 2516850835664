// TODO - fix dependency cycles
import { // eslint-disable-line import/no-cycle
  apiConnector,
} from 'shared/API';
import { socket } from 'shared/API/sockets';

import { // eslint-disable-line import/no-cycle
  getOverdueTasksList,
  getDueTomorrowTasksList,
  getProblemTasksList,
} from 'shared/utils';

import { callAlert } from 'ui/Alert';

import { // eslint-disable-line import/no-cycle
  optimizedTasksActions,
  getTaskAndRefreshRelatedSummaries,
} from 'store/actions/tasksV2';

import { // eslint-disable-line import/no-cycle
  setTaskEmployees,
  setTaskEmployeesError,
} from './task-details-modal';

export const setTasksView = (view) => ({
  type: 'SET_TASKS_VIEW',
  view,
});

export const setTaskFilter = ({ filterType = null, teamType = null }) => (
  dispatch,
  getState,
) => {
  const {
    tasksListView: {
      activeFilter,
      activeTeam,
      tablesData,
      filterTypes,
    },
  } = getState();

  const filter = filterType || activeFilter;
  const team = teamType || activeTeam;

  let activeTablesData = tablesData.map((table) => {
    if (filter === filterTypes.problems) {
      return {
        ...table,
        list: getProblemTasksList(table.list),
      };
    }

    if (filter === filterTypes.overdue) {
      return { ...table, list: getOverdueTasksList(table.list) };
    }

    if (filter === filterTypes.dueTomorrow) {
      return { ...table, list: getDueTomorrowTasksList(table.list) };
    }

    // if (filter === filterTypes.activeTasks) {
    //   return { ...table, list: table.list.filter(item => item.TaskStatusID !== 2)};
    // }

    return table;
  });

  if (team.TeamID !== 'All Teams') {
    activeTablesData = activeTablesData.map((table) => ({
      ...table,
      list: table.list.filter(({ TeamID, Category, SubCategory }) => {
        let result = true;
        if (team.TeamID) result = TeamID === team.TeamID;
        // if (!!team.category) result = Category === team.category;
        // if (!!team.Product) result = SubCategory === team.Product;
        return result;
      }),
    }));
  }

  dispatch({
    type: 'SET_TASK_FILTER',
    activeFilter: filter,
    activeTeam: {
      ...team,
      name:
        (!!team.SubCategoryID && team.Product)
        || (!!team.CategoryID && team.category)
        || (!!team.TeamID && team.name),
    },
    activeTablesData,
  });
};

export const SET_ACTIVE_EMPLOYEE = 'SET_ACTIVE_EMPLOYEE';
export const setActiveEmployee = (employee) => (dispatch, getState) => {
  dispatch({ type: SET_ACTIVE_EMPLOYEE, employee });
};

export const handleTaskReassignment = ({
  employeeId,
  taskId,
  teamId,
  reassignType,
  task,
}) => async (dispatch, getState) => {
  const {
    myCompany: {
      employees = [],
    },
  } = getState();

  const url = `/api/tasks/assignEmployee/${teamId || 0}/${taskId}`;

  dispatch(setTaskEmployees);

  try {
    await apiConnector(url, 'PUT', { userList: [employeeId], type: reassignType });
  } catch (error) {
    dispatch(setTaskEmployeesError);
    callAlert.error(error.message);
    return;
  }

  dispatch(getTaskAndRefreshRelatedSummaries(taskId));

  if (reassignType === 'replace') {
    task.Assignees.forEach((id) => {
      optimizedTasksActions.getEmployeeTasksSummary(id);
    });
  }

  socket.emit('task:assign:update', {
    token: localStorage.getItem('token'),
  });

  const { Name: employeeName } = employees.find(
    (employee) => employee.EmployeeID === employeeId,
  );

  callAlert.success(
    `Task ${taskId} has been ${
      reassignType === 'replace'
        ? 'moved'
        : 'added'
    } and assigned to ${employeeName}`,
  );
};
