import styled from 'styled-components';

import Typography from 'ui/Typography';

export const Root = styled.div`
  /* margin-top: 18px; */
  position: relative;
`;

export const Label = styled(Typography)`
  position: absolute;
  pointer-events: none;
  opacity: ${(props) => (props.active ? 1 : 0.4)};

  ${(props) => (props.active
    ? `
        left: 13px;
        top: -9px;
        transition-duration: 0.15s;
        transform: initial;
        z-index: 1000;
        padding: 0 4px;
        background-color: white;
      `
    : `
        left: ${props.icon && props.iconPosition === 'left' ? '33px' : '17px'};
        top: 13px;
        transition-duration: 0.1s;
        background-color: white;
      `)
  }
`;

export const ChipRoot = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  background: ${(props) => props.theme.palette['background-flat-dark'] || 'black'};
  border-radius: 15px;
  padding-left: 16px;
  padding-right: 11px;
  margin: 3px;
`;

export default {
  Root,
  Label,
  ChipRoot,
};
