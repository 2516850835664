import newApiConnector from 'api';

/**
 * Changes the company name in admin applications
 * @param {string} newCompanyName New company name
 * @param {string} databaseName Name of the client database
 */
const changeCompanyNameInGlobal = async (newCompanyName, databaseName) => {
  const globalEndpointURL = '/client/change-company-name';

  await newApiConnector.global.post(globalEndpointURL, {
    newCompanyName,
    databaseName,
  });
};

export default changeCompanyNameInGlobal;
