import getTaskAge from '../../Tickets/utils/getTicketAge';
import getTaskDeadline from './getTaskDeadline';

function getFormattedTasks(tasks, teamList) {
  const { currentTickets, previousTickets } = tasks;

  const allTasks = currentTickets.concat(previousTickets);

  const formattedTasks = allTasks.map((task) => formatTask(task, teamList));

  return formattedTasks;
}

export function formatTask(
  rawTask,
) {
  const {
    Progress: progress,
    TaskID: taskID,
    TaskName: title,
    CreationDate,
    TaskStatusID,
    DeliveryDate,
    // Subtasks,
    EscalateDate: escalateDate,
    Assignees: agents,
    CommentsSummary,
    Labels: labels,
  } = rawTask;

  // const team = teamList ? getSelectedTeam(rawTicket, teamList) : null;

  const ticketAge = getTaskAge(CreationDate);
  const deadline = getTaskDeadline(DeliveryDate, TaskStatusID);

  const events = {
    problem: CommentsSummary.UnresolvedProblems !== 0,
    action: CommentsSummary.UnresolvedActions !== 0,
    question: CommentsSummary.UnresolvedQuestions !== 0,
    comment: CommentsSummary.UnseenComments !== 0,
  };

  return {
    agents: agents.map((item) => ({ id: item })),
    progress,
    // team: team && team.name,
    // status,
    taskID,
    title,
    ticketAge,
    deadline,
    events,
    escalateDate,
    // Subtasks,
    label: labels[0],
  };
}

export default getFormattedTasks;
