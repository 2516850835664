import {
  SET_REALTOR_DOCUMENT_REQUEST_ATTACHMENTS,
} from 'store/actionTypes/realtorDocumentRequests';

const setAttachments = (id, attachments) => ({
  type: SET_REALTOR_DOCUMENT_REQUEST_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
