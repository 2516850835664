import {
  RESET_REALTOR_ADDENDUM_CHANGES_FILTERS,
  RESET_REALTOR_ADDENDUM_CHANGES_FILTER_COUNTERS,
} from 'store/actionTypes/realtorAddendumChanges';

const resetFilters = () => (dispatch) => {
  dispatch({
    type: RESET_REALTOR_ADDENDUM_CHANGES_FILTERS,
  });

  dispatch({
    type: RESET_REALTOR_ADDENDUM_CHANGES_FILTER_COUNTERS,
  });
};

export default resetFilters;
