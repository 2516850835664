import newApiConnector, { endpoints } from 'api';
/**
 * Updates employee information in global
 * @param {object} fields New values of the changed fields
 */
const updateEmployeeInfoInGlobal = async (fields) => {
  const endpoint = `${endpoints.global.client.updateInformation}`;

  await newApiConnector.global.post(endpoint, fields);
};

export default updateEmployeeInfoInGlobal;
