import { Value } from 'slate';
// TODO - improve import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  CLEAR_ARTICLE_DETAIL,
  MOVE_ARTICLE_MODAL,
  OPEN_ARTICLE_SUCCESS,
  REMOVE_RELATED_ARTICLE,
  SEARCH_ARTICLES_SUCCESS,
  SET_ACTIVE_ARTICLE_ID,
  SET_ARTICLE_CONTENT_TEXT,
  SET_ARTICLE_DETAIL_NAME,
  SET_ARTICLE_PURPOSE,
  SET_HELPFUL_SUCCESS,
  SET_RELATED_ARTICLE,
  SHOW_ARTICLE_DETAILS_MODAL,
  SHOW_LINK_TO_IMG,
  SHOW_LINK_TO_URL,
  OPEN_RELATED_ARTICLE_SUCCESS,
  CLOSE_RELATED_ARTICLE_SUCCESS,
  CLEAR_RELATED_ARTICLE_SUCCESS,
  SHOW_RELATED_ARTICLE_MODAL,
  STAR_ARTICLE_SUCCESS,
  SET_ACTIVE_ARTICLE_REQUEST_STATUS,
  CLEAR_ACTIVE_ARTICLE_REQUEST_STATUS,
} from '../actions/articleDetail';

const initialArticle = {
  title: '',
  purpose: '',
  text: Value.fromJSON({
    object: 'value',
    document: {
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          nodes: [
            {
              object: 'text',
              leaves: [
                {
                  text: 'Enter your text here',
                },
              ],
            },
          ],
        },
      ],
    },
  }),
  categorieId: undefined,
  subcategorieId: undefined,
  accesabilityId: undefined,
  keywords: undefined,
  productId: undefined,
  typeId: undefined,
  articles: undefined,
  isStared: null,
  is_helpful: null,
  status: false,
};

const initialState = {
  isArticleModalVisible: false,
  articleModalMode: 'new',
  addRelatedArticleModal: {
    isVisible: false,
    isLoading: false,
  },
  linkToUrlModal: {
    isVisible: false,
    isLoading: false,
  },
  linkToImgModal: {
    isVisible: false,
    isLoading: false,
  },
  moveArticleModal: {
    isVisible: false,
    isLoading: false,
  },
  purposeArticles: [],
  relatedArticles: [],
  openedRelatedArticleModal: [],
  sympleteObjects: [{
    id: 1,
    name: 'New Client Survey',
    purpose: 'Survey Template',
    icon: '',
  }],
  article: { ...initialArticle },
  activeArticleId: undefined,
  activeArticleRequestStatus: null,
  articleTextServer: '',
};

function articleDetail(state = initialState, action) {
  switch (action.type) {
    case SHOW_ARTICLE_DETAILS_MODAL: {
      return {
        ...state,
        isArticleModalVisible: action.visibility,
        articleModalMode: action.articleModalMode,
      };
    }
    case STAR_ARTICLE_SUCCESS: {
      const { article } = state;
      return {
        ...state,
        article: {
          ...article,
          isStared: !article.isStared,
        },
      };
    }
    case SET_HELPFUL_SUCCESS: {
      const { article } = state;
      return {
        ...state,
        article: {
          ...article,
          is_helpful: action.payload,
        },
      };
    }
    case SHOW_LINK_TO_URL: {
      const { linkToUrlModal } = state;
      return {
        ...state,
        linkToUrlModal: {
          ...linkToUrlModal,
          isVisible: !linkToUrlModal.isVisible,
        },
      };
    }
    case SHOW_LINK_TO_IMG: {
      const { linkToImgModal } = state;
      return {
        ...state,
        linkToImgModal: {
          ...linkToImgModal,
          isVisible: !linkToImgModal.isVisible,
        },
      };
    }
    case OPEN_RELATED_ARTICLE_SUCCESS: {
      const { openedRelatedArticleModal } = state;
      openedRelatedArticleModal[action.payload.id] = action.payload;
      return {
        ...state,
        openedRelatedArticleModal: {
          ...openedRelatedArticleModal,
        },
      };
    }
    case CLOSE_RELATED_ARTICLE_SUCCESS: {
      const { openedRelatedArticleModal } = state;
      if (openedRelatedArticleModal[action.payload]) {
        delete openedRelatedArticleModal[action.payload];
      }
      return {
        ...state,
        openedRelatedArticleModal: {
          ...openedRelatedArticleModal,
        },
      };
    }
    case CLEAR_RELATED_ARTICLE_SUCCESS: {
      return {
        ...state,
        openedRelatedArticleModal: {},
      };
    }
    case SHOW_RELATED_ARTICLE_MODAL: {
      const { addRelatedArticleModal } = state;
      return {
        ...state,
        addRelatedArticleModal: {
          ...addRelatedArticleModal,
          isVisible: !addRelatedArticleModal.isVisible,
        },
      };
    }
    case MOVE_ARTICLE_MODAL: {
      const { moveArticleModal } = state;
      return {
        ...state,
        moveArticleModal: {
          ...moveArticleModal,
          isVisible: !moveArticleModal.isVisible,
        },
      };
    }
    case SEARCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        purposeArticles: action.payload,
      };
    }
    case SET_ACTIVE_ARTICLE_ID: {
      return {
        ...state,
        activeArticleId: action.payload,
      };
    }
    case SET_RELATED_ARTICLE: {
      const { relatedArticles } = state;
      return {
        ...state,
        relatedArticles: relatedArticles.concat(action.payload),
      };
    }
    case REMOVE_RELATED_ARTICLE: {
      const { relatedArticles } = state;
      return {
        ...state,
        relatedArticles: relatedArticles.filter((article) => action.payload !== article.id),
      };
    }
    case SET_ARTICLE_DETAIL_NAME: {
      const { article } = state;
      return {
        ...state,
        article: {
          ...article,
          title: action.payload,
        },
      };
    }
    case SET_ARTICLE_PURPOSE: {
      const { article } = state;
      return {
        ...state,
        article: {
          ...article,
          purpose: action.payload,
        },
      };
    }
    case SET_ARTICLE_CONTENT_TEXT: {
      const { article } = state;
      return {
        ...state,
        article: {
          ...article,
          text: action.payload,
        },
      };
    }

    /* eslint-disable camelcase */
    // TODO improve max-params
    case OPEN_ARTICLE_SUCCESS: {
      const { article } = state;
      const articleRes = { ...action.payload };
      const {
        basicInfo: {
          id,
          title,
          purpose,
          text,
          accesability_id,
          keywords,
          type_id,
          product_id,
          is_helpful,
          isStared,
          categorie_id,
          subcategorie_id,
          creator_name,
          creator_surname,
          created_at,
          updated_at,
          updater_name,
          updater_surname,
        },
        relatedArticles,
      } = articleRes;

      let helpful = null;
      if (is_helpful === 1) {
        helpful = true;
      } else if (is_helpful === 0) {
        helpful = false;
      }

      return {
        ...state,
        article: {
          ...article,
          id,
          text,
          title,
          purpose,
          keywords,
          isStared,
          created_at,
          updated_at,
          creator_name,
          updater_name,
          status: true,
          updater_surname,
          typeId: type_id,
          creator_surname,
          is_helpful: helpful,
          productId: product_id,
          categorieId: categorie_id,
          subcategorieId: subcategorie_id,
          accesabilityId: accesability_id,
        },
        relatedArticles,
        articleTextServer: text,
      };
    }
    case CLEAR_ARTICLE_DETAIL: {
      return {
        ...state,
        article: { ...initialArticle },
        purposeArticles: [],
        relatedArticles: [],
        activeArticleId: undefined,
      };
    }
    case SET_ACTIVE_ARTICLE_REQUEST_STATUS: {
      return {
        ...state,
        activeArticleRequestStatus: action.payload,
      };
    }
    case CLEAR_ACTIVE_ARTICLE_REQUEST_STATUS: {
      return {
        ...state,
        activeArticleRequestStatus: null,
      };
    }
    default:
      return state;
  }
}

export default articleDetail;
