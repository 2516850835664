import requests from 'api/requests';

import setRepairRequests from './setRepairRequests';
import setRepairRequestsForFilter from './setRepairRequestsForFilter';
import setRepairRequestsForFilterCount from './setRepairRequestsForFilterCount';

const getRepairRequestsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.repairRequests.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setRepairRequests(response.data.items));
    dispatch(setRepairRequestsForFilter(filterId)(response.data.items));
    dispatch(setRepairRequestsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getRepairRequestsForFilter;
