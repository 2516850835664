import {
  SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER,
} from 'store/actionTypes/propertyViewings';

const setInfiniteScrollMatchedForFilter = (filterId) => (showings) => (resetState) => ({
  type: SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER,
  payload: {
    filterId,
    showings,
    resetState,
  },
});

export default setInfiniteScrollMatchedForFilter;
