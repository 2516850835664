import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_DOCUMENT_REQUEST_FORM_STATE,
} from 'store/actionTypes/realtorTransactions';

const openNewDocumentRequestModal = openModalWithContentAction('newDocumentRequestModal')(
  SET_DOCUMENT_REQUEST_FORM_STATE,
);

export default openNewDocumentRequestModal;
