/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getNotablePointById from './getNotablePointById';
import updateNotablePoint from './updateNotablePoint';
import deleteNotablePoint from './deleteNotablePoint';
import addNotablePointRelations from './addNotablePointRelations';
import removeNotablePointRelations from './removeNotablePointRelations';
import createNotablePoint from './createNotablePoint';

export {
  getNotablePointById,
  updateNotablePoint,
  deleteNotablePoint,
  addNotablePointRelations,
  removeNotablePointRelations,
  createNotablePoint,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedNotablePointActions = {
  getNotablePointById: memoizeDebounce(
    (id) => store.dispatch(getNotablePointById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
