import {
  SET_WAREHOUSE_OUT_OF_STOCK,
} from 'store/actionTypes/warehouse';

const set = (fulfillmentId) => (items) => ({
  type: SET_WAREHOUSE_OUT_OF_STOCK,
  payload: {
    fulfillmentId,
    items,
  },
});

export default set;
