import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedProcessesActions,
} from 'store/actions/serviceProcesses';

export default createSelector(
  (state) => state.serviceProcesses.serviceProcesses,
  (_, id) => id,
  (collection, id) => {
    const defaultProcess = {
      id: null,
      title: null,
      note: null,
      invoices: [],
      payments: [],
      line_items: [],
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedProcessesActions.getProcessById(id);
      }

      return defaultProcess;
    }

    return item;
  },
);
