import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getFulfillments from './getFulfillments';
import getFulfillmentById from './getFulfillmentById';
import getFulfillmentsForFilter from './getFulfillmentsForFilter';
import getFulfillmentsForStoreFilter from './getFulfillmentsForStoreFilter'; // eslint-disable-line import/no-cycle

import getFulfillmentsSummaryForFilter from './getFulfillmentsSummaryForFilter';
import getOverallFulfillmentsSummary from './getOverallFulfillmentsSummary';

import createFulfillment from './createFulfillment';
import createFulfillmentFromNewFulfillmentForm from './createFulfillmentFromNewFulfillmentForm'; // eslint-disable-line import/no-cycle

import setSelectedFulfillment from './setSelectedFulfillment';
import setSelectedFulfillmentRows from './setSelectedFulfillmentRows';

import updateFulfillment from './updateFulfillment';
import updateSelectedFulfillment from './updateSelectedFulfillment';
import updateFulfillmentPermissions from './updateFulfillmentPermissions';
import updateSelectedFulfillmentPermissions from './updateSelectedFulfillmentPermissions';

import getFulfillmentAttachments from './getFulfillmentAttachments';
import getSelectedFulfillmentChangelogAllTime from './getSelectedFulfillmentChangelogAllTime';
import refreshFulfillment from './refreshFulfillment';
import refreshSelectedFulfillment from './refreshSelectedFulfillment';

import getFulfillmentProductLineItems from './getFulfillmentProductLineItems';
import getSelectedFulfillmentProductLineItems from './getSelectedFulfillmentProductLineItems';
import updateSelectedFulfillmentLineItems from './updateSelectedFulfillmentLineItems';
import removeSelectedFulfillmentLineItem from './removeSelectedFulfillmentLineItem';
import removeSelectedFulfillmentTopLevelLineItem from './removeSelectedFulfillmentTopLevelLineItem';

import createFulfillmentFromSelected from './createFulfillmentFromSelected';
import createFulfillmentFromExisting from './createFulfillmentFromExisting';

import setFulfillmentsDisplayType from './setFulfillmentsDisplayType';

export { getFulfillmentsForFilter };
export { getFulfillmentsForStoreFilter };
export { getFulfillments };
export { getFulfillmentById };

export { createFulfillment };
export { createFulfillmentFromNewFulfillmentForm };
export { getOverallFulfillmentsSummary };

export { setSelectedFulfillment };
export { setSelectedFulfillmentRows };

export { updateFulfillment };
export { updateSelectedFulfillment };
export { updateFulfillmentPermissions };
export { updateSelectedFulfillmentPermissions };

export { refreshSelectedFulfillment };

export { getFulfillmentProductLineItems };
export { getSelectedFulfillmentProductLineItems };
export { updateSelectedFulfillmentLineItems };
export { removeSelectedFulfillmentLineItem };
export { removeSelectedFulfillmentTopLevelLineItem };

export { createFulfillmentFromSelected };
export { createFulfillmentFromExisting };

export { setFulfillmentsDisplayType };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedFulfillmentsActions = {
  getFulfillmentsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getFulfillmentsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getFulfillmentById: memoizeDebounce(
    (fulfillmentId) => store.dispatch(getFulfillmentById(fulfillmentId)),
    debounceDelay,
    debounceOptions,
  ),
  getOverallFulfillmentsSummary: memoizeDebounce(
    () => store.dispatch(getOverallFulfillmentsSummary()),
    debounceDelay,
    debounceOptions,
  ),
  getFulfillmentsSummaryForFilter: memoizeDebounce(
    (filterId, filter) => store.dispatch(getFulfillmentsSummaryForFilter(filterId)(filter)),
    debounceDelay,
    debounceOptions,
  ),
  getFulfillmentsForStoreFilter: memoizeDebounce(
    () => store.dispatch(getFulfillmentsForStoreFilter()),
    debounceDelay,
    debounceOptions,
  ),
  getFulfillmentAttachments: memoizeDebounce(
    (fulfillmentId) => store.dispatch(getFulfillmentAttachments(fulfillmentId)),
    debounceDelay,
    debounceOptions,
  ),
  getSelectedFulfillmentChangelogAllTime: memoizeDebounce(
    () => store.dispatch(getSelectedFulfillmentChangelogAllTime()),
    debounceDelay,
    debounceOptions,
  ),
  getSelectedFulfillmentProductLineItems: memoizeDebounce(
    (productId, status, offset, limit) => store.dispatch(
      getSelectedFulfillmentProductLineItems(productId)(status)(offset, limit),
    ),
    debounceDelay,
    debounceOptions,
  ),
  refreshFulfillment: memoizeDebounce(
    (id) => store.dispatch(
      refreshFulfillment(id),
    ),
    debounceDelay,
    debounceOptions,
  ),
};
