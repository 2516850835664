import apiConnector, { endpoints } from 'api';

/**
 * Deleting a user profile picture
 * @param {number} userId userId
 * */

const deleteAvatar = async (userId) => {
  const endpoint = `${endpoints.client.user.image}/${userId}`;

  await apiConnector.client.delete(endpoint);
};

export default deleteAvatar;
