/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
} from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Container from 'ui/Container';
import Item from 'ui/Item';
import Textfield from 'ui/Textfield';

import Typography from 'ui/Typography';

const HoldOverPeriod = (props) => {
  const {
    control,
  } = props;

  return (
    <Container spacing={2} alignItems="baseline">
      <Item xs={12}>
        <Typography
          weight="bold"
          noMargin
        >
          Hold Over Period
        </Typography>
      </Item>

      <Item xs={4}>
        <Controller
          name="hold_over_period"
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <Textfield
              {...field}
              type="number"
              min={0}
            />
          )}
          rules={{
            required: true,
          }}
        />
      </Item>

      <Item xs={8}>
        <Typography
          variant="body2"
          tag="span"
          noMargin
        >
          months
        </Typography>
      </Item>

    </Container>
  );
};

const {
  shape,
} = PropTypes;

HoldOverPeriod.propTypes = {
  control: shape().isRequired,
};

export default HoldOverPeriod;
