import {
  SET_REALTOR_OFFERS_VIEW_ITEMS,
} from 'store/actionTypes/realtorOffers';

const setOffersViewItems = (items) => ({
  type: SET_REALTOR_OFFERS_VIEW_ITEMS,
  payload: items,
});

export default setOffersViewItems;
