import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: 1px solid ${(props) => props?.theme?.palette?.delimiter || 'blue'};
`;

export const ListItem = styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 70px;
  padding: 0 15px;
  border-bottom: 1px solid ${(props) => props?.theme?.palette?.delimiter || 'blue'};
`;

export default {
  List,
  ListItem,
};
