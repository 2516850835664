const EDITOR_CONFIG = {
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ],
  modules: {
    toolbar: [
      { header: [1, 2, false] },
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      'link',
      'image',
      'clean',
    ],
  },
};

export default EDITOR_CONFIG;
