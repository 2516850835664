import {
  SET_PROPERTIES_FILTER_ITEM,
} from 'store/actionTypes/properties';

const setFilterItem = (key) => (value) => ({
  type: SET_PROPERTIES_FILTER_ITEM,
  payload: {
    key,
    value,
  },
});

export default setFilterItem;
