import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Flex from 'ui/Flex';

import Avatar from 'shared/components/UI/RealtorContactAvatar/RealtorContactAvatar';
import ImageUploadWrapper from 'shared/components/ImageUploadWrapper';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

const AvatarUpload = (props) => {
  const {
    onChange,
    value,
  } = props;

  const saveNewAvatar = useCallback(async (rawFile) => {
    const fileUrl = URL.createObjectURL(rawFile);

    const file = await dataUrlToFile(fileUrl, rawFile.name, rawFile.type);

    onChange({
      file,
      src: fileUrl,
    });
  }, [onChange]);

  const deleteAvatar = useCallback(() => onChange(null), [onChange]);

  return (
    <>
      <Avatar
        src={value?.src}
        size="m"
        round
      />
      <Flex
        spacing={2}
      >
        <ImageUploadWrapper
          appModule="contact-leads-logo"
          uploadNoSubmit={saveNewAvatar}
        >
          <Button variant="primary">
            + Upload Profile Photo
          </Button>
        </ImageUploadWrapper>
        <Button
          variant="secondary"
          icon="trash2"
          onClick={deleteAvatar}
        />
      </Flex>
    </>
  );
};

const {
  func,
  shape,
  string,
} = PropTypes;

AvatarUpload.propTypes = {
  onChange: func.isRequired,
  value: shape({
    file: shape(),
    src: string,
  }).isRequired,
};

export default AvatarUpload;
