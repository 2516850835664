import requests from 'api/requests';

import setTaskLastUpdateInfo from './setTaskLastUpdateInfo';

const getTaskLastUpdateInfo = (taskId) => async (dispatch) => {
  try {
    const response = await requests.tasks.getTaskLastUpdateInfo(taskId);

    dispatch(setTaskLastUpdateInfo(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getTaskLastUpdateInfo;
