import store from 'store';
import selectors from 'store/selectors';

const getEmail = (item) => {
  if (Number.isNaN(+item)) {
    if (typeof item === 'string') {
      return {
        email: item,
      };
    }
    return item;
  }

  const state = store.getState();

  const emails = selectors.realtorContacts.contactEmails(state, item);

  const primaryEmail = emails.find((emailObj) => emailObj.is_primary);

  return {
    email: primaryEmail?.email || emails[0]?.email,
  };
};

export default getEmail;
