import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const TitleCell = (props) => {
  const {
    value,
  } = props;

  return (
    <Typography
      variant="cell"
      weight={500}
      noMargin
      style={{ textTransform: 'capitalize' }}
    >
      {value}
    </Typography>
  );
};

const {
  string,
} = PropTypes;

TitleCell.propTypes = {
  value: string.isRequired,
};

export default TitleCell;
