import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 176px;
  padding: 5px;
  position: relative;
  img {
    max-width: 150px;
    max-height: 50px;
    display: block;
    margin-bottom: 5px;
  }
`;

export const DividerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  height: 50%;
  border-right: 1px solid;
  border-color: ${(props) => props.theme?.palette?.['divider-light'] || 'rgba(255, 255, 255, 0.3)'};
`;
