import { createSelector } from 'reselect';

import stableStringify from 'json-stable-stringify';

import { optimizedFollowUpRemindersActions } from 'store/actions/followUpReminders';

export default createSelector(
  (state) => state.followUpReminders.reminders,
  (state) => state.followUpReminders.remindersByFilter,
  (_, objectTypeId, objectId) => stableStringify({
    objectTypeId,
    objectId,
  }),
  (reminders, remindersById, objectFilterId) => {
    const filteredIds = remindersById[objectFilterId];

    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((accum, id) => {
      const followUpReminder = reminders[id];

      if (!followUpReminder && id) {
        optimizedFollowUpRemindersActions.getFollowUpReminderById(id);
        return accum;
      }

      if (!followUpReminder.valid_to) {
        accum.push(followUpReminder);
      }
      return accum;
    }, []);

    return result;
  },
);
