import {
  SET_STRIPE_CUSTOMER_SECRET,
  SET_STRIPE_CUSTOMER_ID,
  SET_STRIPE_PAYMENT_METHODS,
  SET_SELECTED_PAYMENT_METHOD,
  SET_BILLING_CONTACTS,

  SET_YEARLY_CONFIRMATION_ON,
  SET_YEARLY_CONFIRMATION_OFF,
  SET_MONTHLY_CONFIRMATION_ON,
  SET_MONTHLY_CONFIRMATION_OFF,
} from 'store/actionTypes/payment';

import {
  getBasicCompanyDetails,
} from 'store/actions/MyCompany/information';

import apiConnector from 'api';
import requests from 'api/requests';

export const createStripeIntentAndGetCustomerSecret = () => async (dispatch) => {
  const route = '/payment/create-intent';

  try {
    const response = await apiConnector.global.get(route);

    const {
      data: {
        setupIntent: {
          client_secret, // eslint-disable-line camelcase
        },
      },
    } = response;

    dispatch({
      type: SET_STRIPE_CUSTOMER_SECRET,
      payload: client_secret,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStripeCustomerId = () => async (dispatch) => {
  const route = '/payment/stripe-customer-id';

  try {
    const response = await apiConnector.global.get(route);

    const {
      data: {
        StripeID,
      },
    } = response;

    dispatch({
      type: SET_STRIPE_CUSTOMER_ID,
      payload: StripeID,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getStripePaymentMethods = () => async (dispatch) => {
  const route = '/payment/stripe-payment-methods';

  try {
    const response = await apiConnector.global.get(route);

    const {
      data: {
        primary,
        secondary,
      },
    } = response;

    dispatch({
      type: SET_STRIPE_PAYMENT_METHODS,
      payload: {
        primary,
        secondary,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const makePrimaryPaymentMethod = (paymentMethodId) => async (dispatch) => {
  const route = '/payment/set-primary-payment-method';

  const params = {
    paymentMethodId,
  };

  try {
    await apiConnector.global.get(route, params);
    dispatch(getStripePaymentMethods());
  } catch (error) {
    console.error(error);
  }
};

export const setSelectedPaymentMethod = (
  paymentMethodType,
  paymentMethodId,
) => (dispatch, getState) => {
  if (paymentMethodType === 'primary') {
    const {
      payment: {
        stripePaymentMethods: {
          primary,
        },
      },
    } = getState();

    dispatch({
      type: SET_SELECTED_PAYMENT_METHOD,
      payload: primary,
    });
  }

  if (paymentMethodType === 'secondary') {
    const {
      payment: {
        stripePaymentMethods: {
          secondary,
        },
      },
    } = getState();

    const selectedMethod = secondary.find((item) => item.id === paymentMethodId);

    dispatch({
      type: SET_SELECTED_PAYMENT_METHOD,
      payload: selectedMethod,
    });
  }
};

export const deletePaymentMethod = (paymentMethodId) => async (dispatch) => {
  const route = '/payment/delete-payment-method';

  const params = {
    paymentMethodId,
  };

  try {
    await apiConnector.global.get(route, params);
    dispatch(getStripePaymentMethods());
  } catch (error) {
    console.error(error);
  }
};

export const getBillingContacts = () => async (dispatch) => {
  const route = '/api/billing-contacts';

  try {
    const response = await apiConnector.client.get(route);

    const {
      data: {
        billingContacts,
      },
    } = response;

    dispatch({
      type: SET_BILLING_CONTACTS,
      payload: billingContacts.map((item) => item.EmployeeID),
    });
  } catch (error) {
    console.error(error);
  }
};

export const setBillingContacts = (billingContacts) => async (dispatch) => {
  const route = '/api/billing-contacts';

  try {
    await apiConnector.client.post(route, { billingContacts });
    dispatch(getBillingContacts());
  } catch (error) {
    console.error(error);
  }
};

export const showYearlyBillingConfirmation = () => ({
  type: SET_YEARLY_CONFIRMATION_ON,
});

export const hideYearlyBillingConfirmation = () => ({
  type: SET_YEARLY_CONFIRMATION_OFF,
});

export const showMonthlyBillingConfirmation = () => ({
  type: SET_MONTHLY_CONFIRMATION_ON,
});

export const hideMonthlyBillingConfirmation = () => ({
  type: SET_MONTHLY_CONFIRMATION_OFF,
});

export const switchToYearlyBilling = () => async (dispatch) => {
  await requests.billing.setBillingPeriodType('yearly');
  dispatch(getBasicCompanyDetails());
};

export const switchToMonthlyBilling = () => async (dispatch) => {
  await requests.billing.setBillingPeriodType('monthly');
  dispatch(getBasicCompanyDetails());
};

export default {
  createStripeIntentAndGetCustomerSecret,
  deletePaymentMethod,
  getBillingContacts,
  getStripeCustomerId,
  getStripePaymentMethods,
  makePrimaryPaymentMethod,
  setBillingContacts,
  showYearlyBillingConfirmation,
  hideYearlyBillingConfirmation,
  showMonthlyBillingConfirmation,
  hideMonthlyBillingConfirmation,
  switchToYearlyBilling,
  switchToMonthlyBilling,
};
