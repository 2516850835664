import connectReducers from 'store/connectReducers';

import {
  SET_SERVICE_PEOPLE,
  SET_SERVICE_PEOPLE_FOR_FILTER,
  SET_SERVICE_PEOPLE_FULL_NAME_FILTER,
} from 'store/actionTypes/servicePeople';

const getServicePeopleIds = (servicePeople = []) => servicePeople.map((item) => item.id);

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const initialState = {
  servicePeople: {},
  servicePeopleFullNameFilter: null,
  servicePeopleByFilter: {},
};

const reducers = {
  [SET_SERVICE_PEOPLE]: (state, action) => ({
    ...state,
    servicePeople: listToObject(action.payload),
  }),

  [SET_SERVICE_PEOPLE_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      servicePeople,
    } = action.payload;

    const filterServicePeopleIds = state.servicePeopleByFilter[filterId] || [];

    const updatedFilterServicePeopleIds = new Set([
      ...filterServicePeopleIds,
      ...getServicePeopleIds(servicePeople),
    ]);

    const updatedState = {
      ...state,
      servicePeopleByFilter: {
        ...state.servicePeopleByFilter,
        [filterId]: [...updatedFilterServicePeopleIds],
      },
    };

    return updatedState;
  },

  [SET_SERVICE_PEOPLE_FULL_NAME_FILTER]: (state, action) => ({
    ...state,
    servicePeopleFullNameFilter: action.payload.value,
  }),
};

const servicePeople = (state = initialState, action) => (
  connectReducers(reducers, state, action)
);

export default servicePeople;
