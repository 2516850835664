import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { debounce } from 'shared/utility';

import {
  setFilterItem,
  handleCustomFilter,
  clearFilter,
} from 'store/actions/propertiesFilter';

import {
  getPropertiesForStoreFilter,
} from 'store/actions/properties';

import SearchFilter from 'ui/SearchFilter';

const mapStateToProps = (state) => {
  const {
    propertiesFilter,
  } = state;

  const filterKey = selectors.properties.propertiesFilterKey(state);

  return {
    searchValue: propertiesFilter.search || '',
    customFilter: filterKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    dispatch(setFilterItem('search')(search));
    dispatch(getPropertiesForStoreFilter());
  }, 500),
  handleCustomFilter: (key) => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getPropertiesForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
