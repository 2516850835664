import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { Popconfirm } from 'antd';

import Typography from 'ui/Typography';
import Grid from 'ui/Grid';
import EditableArea from 'ui/EditableArea';
import Textfield from 'ui/Textfield';
import Button from 'ui/Button';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactInfo from 'sections/Contacts/modules/ContactInfo';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import ContactAvatar from './ContactAvatar';
import Outcome from './Outcome';

const noop = () => {};

const FollowUpData = (props) => {
  const {
    reminderId,
    contactId,
    title,
    comment,
    scheduledAt,
    location,
    onChange,
    deleteEvent,
    showDeleteButton,
    withMainTitle,
    event_uid
  } = props;

  return (
    <Grid
      container
      spacing={4}
    >
      {
        withMainTitle && (
          <Grid container xs={12} style={{ justifyContent: 'space-between' }}>
            <Typography
              variant="title1"
              style={{ marginTop: 10 }}
            >
              Follow Up Reminder
            </Typography>
            <Grid
              container
              xs={4}
              style={{ justifyContent: 'end' }}>
              <Button
                variant="secondary"
                onClick={noop}
                icon="edit"
                style={{ margin: 5 }}>
              </Button>
              {
                showDeleteButton && (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {deleteEvent(event_uid)}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      variant="secondary"
                      onClick={noop}
                      icon="trash2"
                      iconColor="red"
                      style={{ margin: 5 }}>
                    </Button>
                  </Popconfirm>
                )
              }
            </Grid>
          </Grid>
        )
      }

      {/* <Grid
        item
        xs={12}
      >
        <DecoratedFieldWrapper
          iconType="feather"
          iconName="bookmark"
          variant="focus"
          align="flex-start"
        >
          <Typography variant="title2">Related To</Typography>
          <ContactInfo
            contactId={contactId}
            editable={false}
            short
            avatar={(<ContactAvatar contactId={contactId} size="sm" round />)}
            nameTypographyProps={{
              variant: 'title2',
              weight: 400,
            }}
          />
        </DecoratedFieldWrapper>
      </Grid> */}

      <Grid
        item
        xs={6}
      >
        <DecoratedFieldWrapper
          iconType="feather"
          iconName="clock"
          variant="focus"
          align="flex-start"
        >
          <Typography variant="title2">Date & Time</Typography>
          <EditableArea
            getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
              <DateTimeInput
                value={scheduledAt}
                disablePast
                handleChange={onChange('scheduled_date')}
                onBlur={onBlur}
                label="Reminder date"
              />
            )}
          >
            <Typography noMargin>
              {
                moment(scheduledAt).calendar(null, {
                  sameElse: 'MM/DD/YYYY, h:mm A',
                })
              }
            </Typography>
          </EditableArea>
        </DecoratedFieldWrapper>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <DecoratedFieldWrapper
          iconType="feather"
          iconName="clock"
          variant="focus"
          align="flex-start"
        >
          <Typography variant="title2">Location</Typography>
          <EditableArea
            fullWidth
            getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
              <Textfield
                value={title}
                onChange={onChange('title')}
                onBlur={onBlur}
                fullWidth
                focusOnRender
                label="Title"
                style={{ marginBottom: 15 }}
              />
            )}
          >
            <Typography weight="bold">
              {location}
            </Typography>
          </EditableArea>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DecoratedFieldWrapper
          iconType="feather"
          iconName="info"
          variant="focus"
          align="flex-start"
        >
          <Typography variant="title2">Details</Typography>
          <EditableArea
            fullWidth
            getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
              <Textfield
                value={title}
                onChange={onChange('title')}
                onBlur={onBlur}
                fullWidth
                focusOnRender
                label="Title"
                style={{ marginBottom: 15 }}
              />
            )}
          >
            <Typography weight="bold">
              {title || '—'}
            </Typography>
          </EditableArea>

          <EditableArea
            fullWidth
            getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
              <Textfield
                value={comment}
                onChange={onChange('comment')}
                onBlur={onBlur}
                fullWidth
                focusOnRender
                label="Comment"
                textarea
                style={{ marginTop: 15 }}
              />
            )}
          >
            <Typography color="descriptor-text">
              {comment || '—'}
            </Typography>
          </EditableArea>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DecoratedFieldWrapper
          iconType="feather"
          iconName="help-circle"
          variant="focus"
          align="flex-start"
        >
          <Typography variant="title2">Log Outcome</Typography>
          <Outcome
            reminderId={reminderId}
          />
        </DecoratedFieldWrapper>
      </Grid>
    </Grid>
  );
};

FollowUpData.defaultProps = {
  showDeleteButton: false,
  withMainTitle: false,
};

const {
  number,
  string,
  func,
  bool,
} = PropTypes;

FollowUpData.propTypes = {
  reminderId: number.isRequired,
  contactId: number.isRequired,
  title: string.isRequired,
  comment: string.isRequired,
  scheduledAt: string.isRequired,
  onChange: func.isRequired,
  deleteReminder: func.isRequired,
  showDeleteButton: bool,
  withMainTitle: bool,
};

export default FollowUpData;
