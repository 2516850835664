import requests from 'api/requests';

import setPropertyRequirement from './setPropertyRequirement';

const getPropertyRequirementById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.propertyRequirements.getById(id);
    dispatch(setPropertyRequirement(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getPropertyRequirementById;
