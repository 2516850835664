import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import RequestItemsTabs from 'sections/Transactions/components/RequestItemsTabs';
import DocumentRequestItem from 'sections/Transactions/modules/DocumentRequestItem';

const ItemsBlock = ({ items, onAfterUpdate }) => (
  <Grid container spacing={2}>
    {
      items.map((requestId) => (
        <Grid item xs={12}>
          <DocumentRequestItem
            requestId={requestId}
            onAfterUpdate={onAfterUpdate}
          />
        </Grid>
      ))
    }
  </Grid>
);

const DocumentRequests = (props) => {
  const {
    respondedDocumentRequests,
    newDocumentRequests,
    outstandingDocumentRequests,

    onAfterUpdate,
  } = props;

  return (
    <>
      <Typography variant="body2" color="ghost">
        All responses will be emailed to the requestor
      </Typography>

      <RequestItemsTabs
        newItems={(
          <ItemsBlock
            items={newDocumentRequests}
            onAfterUpdate={onAfterUpdate}
          />
        )}
        outstandingItems={(
          <ItemsBlock
            items={outstandingDocumentRequests}
            onAfterUpdate={onAfterUpdate}
          />
        )}
        respondedItems={(
          <ItemsBlock
            items={respondedDocumentRequests}
            onAfterUpdate={onAfterUpdate}
          />
        )}
      />
    </>
  );
};

const noOp = () => {};

DocumentRequests.defaultProps = {
  respondedDocumentRequests: [],
  newDocumentRequests: [],
  outstandingDocumentRequests: [],
  onAfterUpdate: noOp,
};

const {
  arrayOf,
  element,
  func,
} = PropTypes;

DocumentRequests.propTypes = {
  respondedDocumentRequests: arrayOf(element),
  newDocumentRequests: arrayOf(element),
  outstandingDocumentRequests: arrayOf(element),

  onAfterUpdate: func,
};

export default DocumentRequests;
