import requests from 'api/requests';

import setDislike from './setDislike';

const getDislikeById = (id) => async (dispatch) => {
  try {
    const response = await requests.dislikes.getById(id);
    dispatch(setDislike(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getDislikeById;
