import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import Typography from 'ui/Typography';

import {
  Root,
  IconWrapper,
} from './styledItems';

const MainMenuItem = (props) => {
  const {
    iconName,
    children,
    disabled,
    to,
    iconType,
    isSmall,
  } = props;

  return (
    <Root
      to={to}
      disabled={disabled}
      iconType={iconType}
    >
      {
        iconName && (
          <IconWrapper>
            <Icon
              name={iconName}
              size={isSmall ? 'm' : 'xl'}
              type={iconType}
            />
          </IconWrapper>
        )
      }
      <Typography
        variant={isSmall ? 'title3' : 'title2'}
        weight={400}
        tag="p"
        noMargin
      >
        {children}
      </Typography>
    </Root>
  );
};

MainMenuItem.defaultProps = {
  children: null,
  iconName: null,
  disabled: false,
  isActive: false,
  iconType: 'feather',
  isSmall: false,
};

const {
  bool,
  string,
} = PropTypes;

MainMenuItem.propTypes = {
  /**
   * label text
   */
  children: string,
  /**
   * fortawesome icon name
   * no icon will show if not provided
   */
  iconName: string,
  /**
   * redirection path
   */
  to: string.isRequired,
  /**
   * is disabled
   */
  disabled: bool,
  /**
   * is active/selected
   */
  isActive: bool, // eslint-disable-line react/no-unused-prop-types
  iconType: string,
  isSmall: bool,
};

export default MainMenuItem;
