import apiConnector, { endpoints } from 'api';

const selectWorkspace = (tempToken) => async (body) => {
  const endpoint = endpoints.global.auth.selectWorkspace;

  try {
    const result = await apiConnector.global.post(endpoint, body, tempToken);
    return result?.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default selectWorkspace;
