import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import WriteNewButton from 'shared/components/WriteNewButtonV2';
import CommentTabsModule from 'shared/components/CommentTabsModule';
import RichTextEditor from 'shared/components/RichTextEditor';
import CommentControls from 'shared/components/CommentControls';
import CommentTypesSelector from 'shared/components/CommentTypesSelector';
import Typography from 'ui/Typography';

import handleDrafts from './handleDrafts';

import {
  WriteNewWrapper,
} from './styledItems';

import './Comments.scss';

const rootClass = 'comments-module-root';

const commentTypesMap = {
  question: 1,
  action: 2,
  summary: 3,
  problem: 4,
  idea: 5,
};

const commentControls = ({ onSubmit, onCancel }) => (
  <CommentControls
    onSubmit={onSubmit}
    onCancel={onCancel}
    hideCustomerVisibilitySelector
  />
);

class CommentsArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
      commentType: 'comment',
      initialTextValue: null,
    };
  }

  componentDidMount() {
    const {
      objectId,
      objectType,
    } = this.props;

    const commentDraft = handleDrafts.get(objectType, objectId);

    if (commentDraft) {
      this.setState({
        showEditor: true,
        commentType: commentDraft?.commentType,
        initialTextValue: commentDraft?.commentContent,
      });
    }
  }

  toggleEditor = () => {
    this.setState(({ showEditor }) => ({ showEditor: !showEditor }));
  };

  handleSubmitPost = async (comment) => {
    const {
      addComment,
      objectId,
      objectType,
    } = this.props;

    const {
      commentType,
    } = this.state;

    const post = {
      comment,
      commentType: commentTypesMap[commentType],
      objectId,
      objectType,
      isVisibleToCustomer: false,
    };

    const commentResponse = await addComment(post);

    handleDrafts.clear(objectType, objectId);

    this.setState({
      showEditor: false,
      commentType: 'comment',
      initialTextValue: null,
    });

    return commentResponse;
  };

  handleCancel = () => {
    const {
      objectType,
      objectId,
    } = this.props;

    handleDrafts.clear(objectType, objectId);
    this.setState({
      showEditor: false,
      commentType: 'comment',
      initialTextValue: null,
    });
  }

  onCommentTypeChange = (commentType) => {
    this.setState({
      commentType,
    });
  };

  onTextEditorBlur = (commentContent) => {
    const {
      objectId,
      objectType,
    } = this.props;

    const {
      commentType,
    } = this.state;

    if (commentContent && commentContent !== '<p></p>') {
      handleDrafts.set({
        objectId,
        objectType,
        commentType,
        commentContent,
      });
    } else {
      handleDrafts.clear(objectType, objectId);
    }
  };

  commentTypesSelector = () => (
    <CommentTypesSelector
      onTypeChange={this.onCommentTypeChange}
      commentType={this.state.commentType} // eslint-disable-line react/destructuring-assignment
    />
  );

  render() {
    const {
      className,
      objectId,
      objectType,
      contactId,
    } = this.props;

    const {
      showEditor,
      initialTextValue,
    } = this.state;

    return (
      <>
        <div
          className={classnames(rootClass, {
            [className]: className,
          })}
        >
          <Typography
            variant="title2"
            weight={600}
          >
            Comments
          </Typography>

          {!showEditor && (
            <WriteNewWrapper>
              <WriteNewButton
                onClick={this.toggleEditor}
              >
                <Typography
                  variant="body"
                  color="descriptor-text"
                  noMargin
                >
                  Write new comment here
                </Typography>
              </WriteNewButton>
            </WriteNewWrapper>
          )}

          {showEditor && (
            <RichTextEditor
              className={`${rootClass}__editor`}
              onClickCancel={this.handleCancel}
              onClickPost={this.handleSubmitPost}
              ControlsComponent={commentControls}
              CustomToolbar={this.commentTypesSelector}
              onBlur={this.onTextEditorBlur}
              value={initialTextValue}
            />
          )}
          <CommentTabsModule
            commentedObjectType={objectType}
            commentedObjectId={objectId}
            contactId={contactId}
          />
        </div>
      </>
    );
  }
}

CommentsArea.defaultProps = {
  className: '',
  contactId: null,
};

const {
  func,
  number,
  string,
} = PropTypes;

CommentsArea.propTypes = {
  addComment: func.isRequired,
  className: string,
  objectType: string.isRequired,
  objectId: number.isRequired,
  contactId: number,
};

commentControls.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
};

export default CommentsArea;
