import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import EditableList from 'ui/EditableList';

import usePropertyDisclosures from 'sections/Properties/hooks/usePropertyDisclosures';

const PropertyDisclosures = (props) => {
  const {
    onChange,
    onCreate,
    onDelete,
    modalTitle,
    propertyId,
  } = props;

  const {
    disclosures,
    getDisclosures,
  } = usePropertyDisclosures(propertyId);

  const handleCreate = useCallback(async (data) => {
    await onCreate(data);
    getDisclosures();
  }, [
    onCreate,
    getDisclosures,
  ]);

  const handleChange = useCallback((disclosureId) => (key) => (value) => {
    onChange(disclosureId)(key)(value)(getDisclosures);
  }, [
    onChange,
    getDisclosures,
  ]);

  const handleDelete = useCallback(async (disclosureId) => {
    await onDelete(disclosureId);
    getDisclosures();
  }, [
    onDelete,
    getDisclosures,
  ]);

  return (
    <EditableList
      {...props}
      items={disclosures}
      modalTitle={modalTitle}
      onCreate={handleCreate}
      onChange={handleChange}
      onDelete={handleDelete}
    />
  );
};

PropertyDisclosures.defaultProps = {
  modalTitle: 'Edit disclosures',
};

const {
  func,
  number,
  string,
} = PropTypes;

PropertyDisclosures.propTypes = {
  modalTitle: string,
  onChange: func.isRequired,
  onCreate: func.isRequired,
  onDelete: func.isRequired,
  propertyId: number.isRequired,
};

export default PropertyDisclosures;
