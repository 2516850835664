export const SET_REALTOR_INSPECTION = 'SET_REALTOR_INSPECTION';
export const SET_REALTOR_INSPECTIONS = 'SET_REALTOR_INSPECTIONS';
export const SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT = 'SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT';
export const SET_REALTOR_INSPECTIONS_FOR_FILTER = 'SET_REALTOR_INSPECTIONS_FOR_FILTER';
export const SET_INSPECTION_REQUEST_ATTACHMENTS = 'SET_INSPECTION_REQUEST_ATTACHMENTS';

export const SET_REALTOR_INSPECTIONS_FILTER_ITEM = 'SET_REALTOR_INSPECTIONS_FILTER_ITEM';
export const RESET_REALTOR_INSPECTIONS_FILTER_ITEM = 'RESET_REALTOR_INSPECTIONS_FILTER_ITEM';
export const CLEAR_REALTOR_INSPECTIONS_FILTER = 'CLEAR_REALTOR_INSPECTIONS_FILTER';

export const SET_INSPECTIONS_TYPES = 'SET_INSPECTIONS_TYPES';

export const SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN = 'SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN';
export const SET_INSPECTION_REQUEST_NOTES_MODAL_CLOSED = 'SET_INSPECTION_REQUEST_NOTES_MODAL_CLOSED';

export const SET_NEW_REALTOR_INSPECTION_MODAL_OPEN = 'SET_NEW_REALTOR_INSPECTION_MODAL_OPEN';
export const SET_NEW_REALTOR_INSPECTION_MODAL_CLOSE = 'SET_NEW_REALTOR_INSPECTION_MODAL_CLOSE';

export const SET_NEW_REALTOR_INSPECTION_FORM_STATE = 'SET_NEW_REALTOR_INSPECTION_FORM_STATE';
export const RESET_NEW_REALTOR_INSPECTION_FORM_STATE = 'RESET_NEW_REALTOR_INSPECTION_FORM_STATE';
export const SET_NEW_REALTOR_INSPECTION_FORM_ATTACHMENTS = 'SET_NEW_REALTOR_INSPECTION_FORM_ATTACHMENTS';

export default {
  SET_REALTOR_INSPECTION,
  SET_REALTOR_INSPECTIONS,
  SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT,
  SET_REALTOR_INSPECTIONS_FOR_FILTER,

  SET_REALTOR_INSPECTIONS_FILTER_ITEM,
  RESET_REALTOR_INSPECTIONS_FILTER_ITEM,
  CLEAR_REALTOR_INSPECTIONS_FILTER,

  SET_INSPECTIONS_TYPES,

  SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN,
  SET_INSPECTION_REQUEST_NOTES_MODAL_CLOSED,

  SET_NEW_REALTOR_INSPECTION_MODAL_OPEN,
  SET_NEW_REALTOR_INSPECTION_MODAL_CLOSE,

  SET_NEW_REALTOR_INSPECTION_FORM_STATE,
  RESET_NEW_REALTOR_INSPECTION_FORM_STATE,
  SET_NEW_REALTOR_INSPECTION_FORM_ATTACHMENTS,
};
