import {
  SET_REALTOR_CONTACT,
} from 'store/actionTypes/realtorContacts';

const set = (contact) => ({
  type: SET_REALTOR_CONTACT,
  payload: contact,
});

export default set;
