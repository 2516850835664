import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import selectors from 'store/selectors';

import SpecialStatuses from './SpecialStatuses';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    isVip: contact?.is_vip,
    isPastClient: contact?.is_past_client,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    dispatch(updateContact(ownProps.contactId)({
      [key]: value,
    }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialStatuses);
