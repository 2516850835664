import {
  SET_APP_CUSTOMIZATION_SETTINGS,
} from 'store/actionTypes/appCustomization';

const setCustomizationSettings = (settings) => ({
  type: SET_APP_CUSTOMIZATION_SETTINGS,
  payload: settings,
});

export default setCustomizationSettings;
