import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormTextfield from 'shared/components/UI/FormTextfield';

const NotesInput = (props) => {
  const {
    value,
    handleChange,
  } = props;

  const onChange = useCallback((event) => {
    handleChange(event.target.value);
  }, [handleChange]);

  return (
    <FormTextfield
      label="Notes"
      value={value}
      onChange={onChange}
      multiline
    />
  );
};

NotesInput.defaultProps = {
  value: undefined,
};

const {
  func,
  string,
} = PropTypes;

NotesInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
};

export default NotesInput;
