import apiConnector, { endpoints } from 'api';

/**
 * Gets the list of attachemnts ids for the given object
 * @param {string} objectTypeId
 * @returns
 */
const getObjectAttachments = (objectTypeId) => async (objectId) => {
  if (!objectTypeId || !objectId) {
    throw new Error('No object type or object ID for attachment');
  }

  const endpoint = `${endpoints.client.attachments2objects}/${objectTypeId}/${objectId}`;

  try {
    const response = await apiConnector.client.get(endpoint);
    return response;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getObjectAttachments;
