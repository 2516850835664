import {
  SET_MLS_MODAL_SEARCH_DATA,
} from 'store/actionTypes/realtorOpportunities';

const setSearchedProperties = (properties) => ({
  type: SET_MLS_MODAL_SEARCH_DATA,
  payload: {
    properties,
  },
});

export default setSearchedProperties;
