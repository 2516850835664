import { connect } from 'react-redux';
import { history } from 'store';
import { showEmployeeGeneralModal } from 'store/actions/dashboard';
import {
  closeTutorialLaunchModal,
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  setStepIndex,
} from 'store/actions/tutorial';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import initialSteps from './steps';
import filterSteps from '../shared/utils/filterSteps';

import GeneralTutorial from './GeneralTutorial';

const mapStateToProps = (state) => {
  const hasHrManagerPermissions = checkLoggedEmployeePermissions.isManager('HR');
  const hasAdminPermissions = checkLoggedEmployeePermissions.isOwner();

  const steps = filterSteps(initialSteps, hasHrManagerPermissions, hasAdminPermissions);

  const {
    tutorial: {
      isLaunchModalVisible,
      isTutorialRunning,
    },
    dashboard: {
      employeeGeneralModal: {
        isVisible,
      },
    },
    router: {
      location,
    },
  } = state;

  return {
    isLaunchModalVisible,
    isTutorialRunning,
    history,
    location,
    isVisible,
    steps,
  };
};

const mapDispatchToProps = {
  closeTutorialLaunchModal,
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  showEmployeeGeneralModal,
  setStepIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTutorial);
