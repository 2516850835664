import { getConnector, apiConnector } from 'shared/API';

const base = '/api/teams/';

class TeamRepository {
  static getTeams = () => getConnector(`${base}all`);

  static getTeam = (teamId) => getConnector(base + teamId);

  static addTeam = (team) => apiConnector(base, 'POST', team);

  static editTeam = (teamId, team) => apiConnector(base + teamId, 'PUT', team);

  static deleteTeam = (teamId) => apiConnector(base + teamId, 'DELETE');

  static updateManagers = (teamId, managers) => apiConnector(`${base}updateTeamManagers/${teamId}`, 'PUT', managers);

  static updateMembers = (teamId, members) => apiConnector(`${base}updateTeamMembers/${teamId}`, 'PUT', members);
}

export default TeamRepository;
