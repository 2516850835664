/* eslint-disable max-params, max-len */

import axios from 'axios';

class BaseApiConnector {
  /**
   *
   * @param {string} baseURL API connection string
   *
   * @param {'token' | 'token'} authenticationType authentication type: 'token' when using employee view, 'token' for external customer view
   */
  constructor(baseURL, contentType = 'application/json', authenticationType = 'token', authenticationOptions = {}) {
    this.axiosInstance = axios.create({
      baseURL,
    });

    this.authenticationType = authenticationType;
    this.contentType = contentType;
    this.authenticationOptions = authenticationOptions;
  }

  token() {
    return localStorage.getItem(this.authenticationType);
  }

  headers(customToken = null) {
    const headers = {
      'Content-Type': this.contentType,
      ...((this.authenticationType === 'token' || this.authenticationType === 'externalToken') && { authorization: customToken || this.token() }),
    };

    return headers;
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} params query parameters
   */
  get(endpoint, params) {
    const headers = this.headers();

    const requestConfig = {
      headers,
    };

    const requestConfigWithParams = {
      ...requestConfig,
      params: {
        ...params,
        ...(this.authenticationType === 'apiKey' && this.authenticationOptions),
      },
    };

    return this.axiosInstance.get(
      endpoint,
      requestConfigWithParams,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} data request body
   */
  post(endpoint, data, customToken) {
    const requestConfig = {
      headers: this.headers(customToken),
    };

    return this.axiosInstance.post(
      endpoint,
      data,
      requestConfig,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} data request body
   */
  put(endpoint, data) {
    const requestConfig = {
      headers: this.headers(),
    };

    return this.axiosInstance.put(
      endpoint,
      data,
      requestConfig,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} data request body
   */
  patch(endpoint, data) {
    const requestConfig = {
      headers: this.headers(),
    };

    return this.axiosInstance.patch(
      endpoint,
      data,
      requestConfig,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   */
  delete(endpoint, data) {
    return this.axiosInstance.delete(
      endpoint,
      {
        headers: this.headers(),
        data,
      },
    );
  }
}

export default BaseApiConnector;
