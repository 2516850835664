import { createSelector } from 'reselect';

const data = (state) => ({
  id: state.newLeadForm.sourceType,
  options: state.crmSources.sourceTypes,
});

export default createSelector(
  data,
  ({ id, options }) => {
    if (!options) return null;

    const sourceType = options.find((item) => item.id === id);

    if (!sourceType) return null;

    return sourceType.name;
  },
);
