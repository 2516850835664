import { connect } from 'react-redux';

import {
  getContactsForStoreFilter,
} from 'store/actions/realtorContacts';

import ImportWizard from './ImportWizard';

const onEnd = {
  contacts: getContactsForStoreFilter,
};

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEnd: (dataType) => {
    ownProps.onEnd();
    if (onEnd[dataType]) {
      dispatch(onEnd[dataType]());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportWizard);
