import apiConnector, { endpoints } from 'api';

const share = async (propertyId) => {
  const endpoint = endpoints.client.realtor.shareProperty;
  try {
    const result = await apiConnector.client.post(endpoint, { propertyId });

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default share;
