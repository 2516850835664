import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE,
} from 'store/actionTypes/realtorRepairRequests';

const openRepairRequestModal = openModalWithContentAction('newRepairRequestModal')(
  SET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE,
);

export default openRepairRequestModal;
