import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import CompanyNameInput from './CompanyNameInput';

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('companyName')(value)),
});

export default connect(null, mapDispatchToProps)(CompanyNameInput);
