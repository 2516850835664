import { composeDecorators } from 'draft-js-plugins-editor';
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
// import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createLinkifyPlugin from './linkifyPlugin';

const videoPlugin = createVideoPlugin();

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  focusPlugin.decorator,
);


const imagePlugin = createImagePlugin({ decorator });

// import ObjectLink from 'shared/components/ObjectLink';

const mentionPlugin = createMentionPlugin({
  mentionPrefix: '@',
});
// const hashtagPlugin = createHashtagPlugin();

const { MentionSuggestions } = mentionPlugin;

const linkifyPlugin = createLinkifyPlugin();

const plugins = [mentionPlugin, linkifyPlugin, imagePlugin, focusPlugin, resizeablePlugin, videoPlugin];

export {
  plugins,
  MentionSuggestions,
  defaultSuggestionsFilter,
};
