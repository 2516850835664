import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';

import getLeadById from 'store/actions/realtorLeads/getLeadById';
import getById from './getPropertyById';
import getPropertiesForStoreFilter from './getPropertiesForStoreFilter'; // eslint-disable-line import/no-cycle
import getOverallSummary from './getOverallSummary';

const notify = debounce((action, text) => {
  callAlert[action](text);
}, 1000, {
  leading: true,
});

const updateProperty = (propertyId, leadId = null) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.properties.update(propertyId)(updateData);

    notify('success', 'Updated successfully');

    dispatch(getById(propertyId));
    if (leadId) {
      dispatch(getLeadById(leadId));
    }

    // update list of properties & KPI's when a change occurs
    dispatch(getPropertiesForStoreFilter());
    dispatch(getOverallSummary());
  } catch (error) {
    console.error(error);
    notify('error', 'Could not update');
  }

  return propertyId;
};

export default updateProperty;
