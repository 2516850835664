import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import CallDeviceSetup from './CallDeviceSetup';

const mapStateToProps = (state) => {
  const {
    modals: {
      callDeviceSetup,
    },
  } = state;
  return {
    isVisible: callDeviceSetup?.isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => { dispatch(closeModal('callDeviceSetup')()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(CallDeviceSetup);
