import { connect } from 'react-redux';

import TitleCell from './TitleCell';

const mapStateToProps = (state, ownProps) => {
  const {
    title,
  } = ownProps;

  return {
    value: title,
  };
};

export default connect(mapStateToProps)(TitleCell);
