import { connect } from 'react-redux';

import {
  createAddress,
} from 'store/actions/addresses';

import MultipleLocationInput from './MultipleLocationInput';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: async (addressData) => {
    const addressId = await dispatch(createAddress(addressData));
    ownProps.onAdd([addressId]);
  },
});

export default connect(null, mapDispatchToProps)(MultipleLocationInput);
