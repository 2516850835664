import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateOffer,
} from 'store/actions/realtorOffers';

import {
  openNewCounterModal,
} from 'store/actions/newRealtorCounterModal';

import {
  openOfferDeclineReasonModal,
} from 'store/actions/offerDeclineReasonModal';

import getOffersViewItems from 'store/actions/realtorOffers/getOffersViewItems';

import { REALTOR_OFFER_TYPES } from 'constants/realtor.constants';

import { openModal } from 'store/actions/modals';

import CounterBox from './CounterBox';

const mapStateToProps = (state, ownProps) => {
  const { counterId } = ownProps;

  const counter = selectors.realtorOffers.byId(state, counterId);

  let contactId;
  let agentId;
  let contactRole;
  let agentRole;

  if (counter?.realtor_client_id) {
    contactId = counter?.buyer_contact_id;
    agentId = counter?.buyer_agent_contact_id;
    contactRole = 'Buyer';
    agentRole = 'Buyer Agent';
  } else {
    contactId = counter?.seller_contact_id;
    agentId = counter?.seller_agent_contact_id;
    contactRole = 'Seller';
    agentRole = 'Seller Agent';
  }

  return {
    counterId,
    anchor: `counter-${counter.id}`,
    userRole: REALTOR_OFFER_TYPES[counter.type],
    contactId,
    agentId,
    contactRole,
    agentRole,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNoteChange: (value) => {
    dispatch(updateOffer(ownProps.counterId)({ note: value }));
  },
  openCreateOfferDeclineReasonModal: () => dispatch(openOfferDeclineReasonModal(ownProps.counterId, false)),
  openViewOfferDeclineReasonModal: () => dispatch(openOfferDeclineReasonModal(ownProps.counterId, true)),
  onDecline: () => {
    dispatch(updateOffer(ownProps.counterId)({ declined_at: new Date() }));
  },
  openNewCounterModal: () => {
    const onAfterSubmit = () => dispatch(getOffersViewItems());

    dispatch(openNewCounterModal(ownProps.counterId, ownProps.rootOfferId, onAfterSubmit));
  },
  openAcceptCounterModal: () => {
    dispatch(openModal('acceptOfferModal')({}, {
      offerId: ownProps.counterId,
      rootOfferId: ownProps.rootOfferId,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterBox);
