import { connect } from 'react-redux';

import selectors from 'store/selectors';

import NewOfferModal from 'sections/Offers/modals/NewOfferModal';

const mapStateToProps = (state, ownProps) => {
  const {
    offerId,
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);
  const offerAttachments = selectors.realtorOffers.attachments(state, offerId);

  return {
    buyer: property?.buyer,
    buyerAgent: property?.listing_agent === 0 ? null : 0,
    seller: property?.seller,
    sellerAgent: property?.listing_agent,

    representing: property?.listing_agent === 0
      ? 'seller'
      : 'buyer',

    attachments: offerAttachments[0],

  };
};

export default connect(mapStateToProps, null)(NewOfferModal);
