import { connect } from 'react-redux';

import {
  deleteRepairRequest,
} from 'store/actions/realtorRepairRequests';

import DeleteButton from 'shared/components/UI/TableCells/DeleteButton';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: () => {
    const { requestId, transactionId } = ownProps;
    dispatch(deleteRepairRequest(requestId, transactionId));
  },
});

export default connect(null, mapDispatchToProps)(DeleteButton);
