import { createSelector } from 'reselect';

import {
  optimizedRefundsActions,
} from 'store/actions/refunds';

export default createSelector(
  (state, id) => ({
    refunds: state.refunds.refunds,
    id,
  }),
  ({ refunds, id }) => {
    if (!id) return null;

    const dataObject = refunds[id];

    if (!dataObject) {
      optimizedRefundsActions.getRefundById(id);
    }

    return dataObject || {};
  },
);
