import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE,
  RESET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE,
  SET_NEW_REALTOR_REPAIR_REQUEST_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorRepairRequests';

const noOp = () => {};

const initialState = {
  // callbacks
  onBeforeSubmit: noOp,
  onSubmit: null,
  onAfterSubmit: noOp,

  // defaultValues
  transactionId: null,
  defaultRequestType: null,
  defaultDescription: null,
  defaultEstimatedValue: null,

  attachments: [],
};

const reducers = {
  [SET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE]: (state, action) => ({
    ...initialState,
    ...action.payload,
  }),

  [RESET_NEW_REALTOR_REPAIR_REQUEST_FORM_STATE]: (state) => initialState,

  [SET_NEW_REALTOR_REPAIR_REQUEST_FORM_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.payload.filesList,
  }),
};

function newRepairRequestForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newRepairRequestForm;
