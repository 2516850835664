import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import ContactLabelsInput from './ContactLabelsInput';

const mapStateToProps = (state) => {
  const allActiveLabels = selectors.dictionary.contactLabels(state);

  const options = allActiveLabels.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return {
    options,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => {
    dispatch(setNewContactFormField('contactLabels')(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactLabelsInput);
