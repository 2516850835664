import {
  SET_OVERALL_TASKS_SUMMARY,
} from 'store/actionTypes/tasks';

const setOverallTasksSummary = (tasksSummary) => ({
  type: SET_OVERALL_TASKS_SUMMARY,
  payload: tasksSummary,
});

export default setOverallTasksSummary;
