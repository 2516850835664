import React from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

const CallTriggerButton = (props) => {
  if (isSectionUnderConstruction('calls')) {
    return null;
  }

  const {
    openModal,
  } = props;

  return (
    <Button
      onClick={openModal}
      variant="secondary"
      color="main-text"
      icon="phone-outgoing"
      iconColor="focus"
    >
      Open Dialer
    </Button>
  );
};

const {
  func,
} = PropTypes;

CallTriggerButton.propTypes = {
  openModal: func.isRequired,
};

export default CallTriggerButton;
