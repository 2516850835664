import apiConnector, { endpoints } from 'api';

const getById = async (contactId) => {
  if (!contactId) {
    throw new Error('invalid arguments');
  }

  const endpoint = `${endpoints.client.realtor.contacts}/${contactId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    // throw (error);
  }
};

export default getById;
