import styled from 'styled-components';

import Flex from 'ui/Flex';

export const Root = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 5px;
  margin-top: 15px;
  border-top: 1px solid ${(props) => props?.theme?.palette?.border || 'gray'};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const TagsContainer = styled(Flex)`
  text-transform: uppercase;
`;
