import requests from 'api/requests';

import setAddresses from './setAddresses';

const get = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.addresses.get({
      offset,
      limit,
    });

    dispatch(setAddresses(response.data.addresses));
  } catch (error) {
    console.error(error);
  }
};

export default get;
