import {
  SET_FULFILLMENT,
} from 'store/actionTypes/fulfillments';

const setFulfillments = (fulfillment) => ({
  type: SET_FULFILLMENT,
  payload: fulfillment,
});

export default setFulfillments;
