import { createSelector } from 'reselect';

import {
  optimizedAgreementsActions,
} from 'store/actions/agreements';

export default createSelector(
  (state, id) => ({
    agreements: state.agreements.agreements,
    id,
  }),
  ({ agreements, id }) => {
    if (!id) return null;

    const dataObject = agreements[id];

    if (!dataObject) {
      optimizedAgreementsActions.getAgreementById(id);
    }

    return dataObject || {};
  },
);
