import handleDrafts from 'shared/hooks/useModalsDrafts/handleDrafts';

const handleModalFormCache = (modalName, storeObjectKey) => (action = 'set') => async (dispatch, getState) => {
  if (action === 'clear') {
    handleDrafts.clear(modalName);
  } else {
    const store = getState();
    const formData = store[storeObjectKey];

    handleDrafts.set(modalName, formData);
  }
};

export default handleModalFormCache;
