import {
  ADD_TUTORIAL_TASK,
} from 'store/actionTypes/tasks';

const addTutorialTask = () => (dispatch) => {
  dispatch({
    type: ADD_TUTORIAL_TASK,
  });
};

export default addTutorialTask;
