import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import Timeline from 'shared/components/UI/Timeline';
import TimelineItem from 'sections/Opportunities/modules/Offer/TimelineItem';

const loadingStateTimeline = [{
  type: 'loading',
  id: 'loading',
}];

const noop = () => {};

const OfferHistory = (props) => {
  const {
    timelineEvents,
    isTimelineLoading,
    isCancelled,
    isDeclined,
    selectedOfferId,
  } = props;

  const [selectedId, setSelectedId] = useState(null);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (isCancelled) {
      return;
    }
    setSelectedId(selectedOfferId);
  }, [
    selectedOfferId,
    isCancelled,
  ]);

  return (
    <Flex
      spacing={2}
      flexWrap="wrap"
    >
      <Typography variant="title1" weight="bold">
        Offer History
      </Typography>

      <FlexItem fullWidth>
        <Timeline
          events={isTimelineLoading ? loadingStateTimeline : timelineEvents}
          showLineBeforeFirst={false}
          showLineAfterLast={!isCancelled && !isDeclined}
          customTimelineItem={TimelineItem}
          selectedElement={selectedId}
          onEventClick={noop}
          selectedItemColor={theme?.palette.focus}
          todayMarkLineHeight={75}
        />
      </FlexItem>
    </Flex>
  );
};

OfferHistory.defaultProps = {
  timelineEvents: [],
  isCancelled: false,
  isDeclined: false,
  isTimelineLoading: false,
  selectedOfferId: null,
};

const {
  arrayOf,
  shape,
  bool,
  number,
} = PropTypes;

OfferHistory.propTypes = {
  timelineEvents: arrayOf(shape()),
  isCancelled: bool,
  isDeclined: bool,
  isTimelineLoading: bool,
  selectedOfferId: number,
};

export default OfferHistory;
