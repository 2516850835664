import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useContactName = (
  contactId,
  params,
) => {
  const contactName = useReselect(selectors.realtorContacts.contactName, contactId, params);

  return contactName;
};

export default useContactName;
