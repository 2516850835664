import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import MenuItem from 'ui/MenuItem';
import Menu from 'ui/Menu';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';
import Container from 'ui/Container';

const tempDict = {
  1: 'cold',
  2: 'warm',
  3: 'hot',
};

const TempSelect = (props) => {
  const {
    temp,
    onOptionSelect,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onButtonClick = useCallback((event) => {
    setAnchorEl(event.target);
    setMenuOpen(true);
  });

  const handleMenuSelect = useCallback((selectedTemp) => () => {
    onOptionSelect(selectedTemp);
    setMenuOpen(false);
  });

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, [
    setMenuOpen,
  ]);

  return (
    <>
      <Button
        onClick={onButtonClick}
        iconType="custom"
        variant="secondary"
        icon={tempDict[+temp] || 'cold'}
        iconColor={tempDict[+temp] || 'placeholder'}
      />
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={handleMenuSelect(3)}
          customComponent={(
            <Container alignItems="center">
              <Icon
                name="hot"
                size="m"
                color="hot"
                type="custom"
              />
              <Typography
                variant="body"
                noMargin
                style={{ marginLeft: 9, marginTop: 2 }}
              >
                Hot
              </Typography>
            </Container>
          )}
        />

        <MenuItem
          onClick={handleMenuSelect(2)}
          customComponent={(
            <Container alignItems="center">
              <Icon
                name="warm"
                size="m"
                color="warm"
                type="custom"
              />
              <Typography
                variant="body"
                noMargin
                style={{ marginLeft: 9, marginTop: 2 }}
              >
                Warm
              </Typography>
            </Container>
          )}
        />

        <MenuItem
          onClick={handleMenuSelect(1)}
          customComponent={(
            <Container alignItems="center">
              <Icon
                name="cold"
                size="m"
                color="cold"
                type="custom"
              />
              <Typography
                variant="body"
                noMargin
                style={{ marginLeft: 9, marginTop: 2 }}
              >
                Cold
              </Typography>
            </Container>
          )}
        />
      </Menu>
    </>
  );
};

TempSelect.defaultProps = {
  temp: null,
};

const {
  func,
  oneOf,
} = PropTypes;

TempSelect.propTypes = {
  temp: oneOf(['cold', 'warm', 'hot']),
  onOptionSelect: func.isRequired,
};

export default TempSelect;
