import {
  SET_REALTOR_OVERALL_FOLLOW_UP_REMINDERS_SUMMARY,
} from 'store/actionTypes/followUpReminders';

const setOverallSummary = (summary) => ({
  type: SET_REALTOR_OVERALL_FOLLOW_UP_REMINDERS_SUMMARY,
  payload: summary,
});

export default setOverallSummary;
