import moment from 'moment';
import pipe from 'ramda/src/pipe';

// eslint-disable-next-line import/no-cycle
import addDateDividers from './addDateDividers';

const TODAY = moment().startOf('day');
const YESTERDAY = moment().subtract(1, 'days').startOf('day');

const isToday = (date) => moment(date).isSame(TODAY, 'd');

const isYesterday = (date) => moment(date).isSame(YESTERDAY, 'd');

const isThisWeek = (date) => moment(date).isSame(new Date(), 'week');

export const getCommentDate = (createdAt) => {
  if (isToday(createdAt)) return 'Today';
  if (isYesterday(createdAt)) return 'Yesterday';

  const diff = moment().diff(createdAt, 'days');
  if (isThisWeek(createdAt)) return `${moment().subtract(diff, 'days').startOf('day').format('dddd')}`;

  return moment(createdAt).format('MMM DD, YYYY');
};

export const sortCommentsByDate = (comments) => [...comments].sort((a, b) => (new Date(b.CreatedAt) - new Date(a.CreatedAt))); // eslint-disable-line max-len

export const prepareComments = pipe(
  sortCommentsByDate,
  addDateDividers,
);

export default {
  getCommentDate,
  sortCommentsByDate,
  prepareComments,
};
