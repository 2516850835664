import { callAlert } from 'ui/Alert';

import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle

export const SEND_MOOD = 'SEND_MOOD';
export const sendMood = (data) => async (dispatch, getState) => {
  const {
    EmployeeID, CompanyID, office: { OfficeID }, DepartmentID,
  } = getState().auth.loggedUser;
  const user = {
    CompanyID, OfficeID, EmployeeID, DepartmentID,
  };

  const url = '/api/mood';
  const method = data.MoodID ? 'PUT' : 'POST';
  dispatch({ type: SEND_MOOD });
  try {
    const result = await apiConnector(url, method, { user, data });
    dispatch(sendMoodSuccess());
    dispatch(getUserMoodSuccess(result));
  } catch (e) {
    dispatch(sendMoodError());
  }
};

export const UPDATE_MOOD_SUCCESS = 'UPDATE_MOOD_SUCCESS';
export const updateMoodSuccess = () => ({ type: UPDATE_MOOD_SUCCESS });

export const UPDATE_MOOD = 'UPDATE_MOOD';
export const updateMood = (data) => async (dispatch) => {
  const user = {
    CompanyID: data.CompanyID,
    OfficeID: data.OfficeID,
    EmployeeID: data.EmployeeID,
    DepartmentID: data.DepartmentID,
  };

  const url = '/api/mood';
  dispatch({ type: UPDATE_MOOD });
  await apiConnector(url, 'PUT', {
    user,
    data: {
      MoodID: data.MoodID,
      MoodType: data.MoodType,
      MoodDate: data.MoodDate,
      MoodComment: data.MoodComment,
      MoodManagerComment: data.MoodManagerComment,
      HideUserInfo: !!data.HideUserInfo,
    },
  });
  dispatch(updateMoodSuccess());
};

export const SEND_MOOD_SUCCESS = 'SEND_MOOD_SUCCESS';
export const sendMoodSuccess = () => ({ type: SEND_MOOD_SUCCESS });

export const SEND_MOOD_ERROR = 'SEND_MOOD_ERROR';
export const sendMoodError = () => {
  callAlert.error('Ups something went wrong');
  return { type: SEND_MOOD_ERROR };
};

export const GET_USER_MOOD = 'GET_USER_MOOD';
export const getUserMood = () => async (dispatch) => {
  const url = '/api/mood/current';
  try {
    const result = await apiConnector(url);
    dispatch(getUserMoodSuccess(result || {}));
  } catch (e) {
    dispatch(getUserMoodSuccess({}));
  }
};

export const GET_USER_MOOD_SUCCESS = 'GET_USER_MOOD_SUCCESS';
export function getUserMoodSuccess(data) {
  return {
    type: GET_USER_MOOD_SUCCESS,
    mood: data,
  };
}

export const GET_LATEST_MOOD_BY_OFFICES = 'GET_LATEST_MOOD_BY_OFFICES';
export const getLatestMoodByOffices = (data) => async (dispatch) => {
  const url = `/api/mood/search/latest/by/offices${data.DepartmentID ? `?DepartmentID=${data.DepartmentID}` : ''}`;
  const result = await apiConnector(url);
  dispatch(getLatestMoodByOfficesSuccess(result));
};

export const GET_LATEST_MOOD_BY_OFFICES_SUCCESS = 'GET_LATEST_MOOD_BY_OFFICES_SUCCESS';
export function getLatestMoodByOfficesSuccess(data) {
  return {
    type: GET_LATEST_MOOD_BY_OFFICES_SUCCESS,
    mood: data,
  };
}

export const GET_LATEST_MOOD_BY_DEPARTMENTS = 'GET_LATEST_MOOD_BY_DEPARTMENTS';
export const getLatestMoodByDepartments = (data) => async (dispatch) => {
  const url = `/api/mood/search/latest/by/departments${data.OfficeID ? `?OfficeID=${data.OfficeID}` : ''}`;
  const result = await apiConnector(url);
  dispatch(getLatestMoodByDepartmentsSuccess(result));
};

export const GET_LATEST_MOOD_BY_DEPARTMENTS_SUCCESS = 'GET_LATEST_MOOD_BY_DEPARTMENTS_SUCCESS';
export function getLatestMoodByDepartmentsSuccess(data) {
  return {
    type: GET_LATEST_MOOD_BY_DEPARTMENTS_SUCCESS,
    mood: data,
  };
}

export const GET_LATEST_MOOD_BY_EMPLOYEES = 'GET_LATEST_MOOD_BY_EMPLOYEES';
export const getLatestMoodByEmployees = (data) => async (dispatch) => {
  const officeId = data.OfficeID || '';
  const departmentID = data.DepartmentID || '';
  const url = `/api/mood/search/latest/by/employees?OfficeID=${officeId}&DepartmentID=${departmentID}`;
  const result = await apiConnector(url);
  dispatch(getLatestMoodByEmployeesSuccess(result));
};

export const GET_LATEST_MOOD_BY_EMPLOYEES_SUCCESS = 'GET_LATEST_MOOD_BY_EMPLOYEES_SUCCESS';
export function getLatestMoodByEmployeesSuccess(data) {
  return {
    type: GET_LATEST_MOOD_BY_EMPLOYEES_SUCCESS,
    mood: data,
  };
}

export const GET_MOOD_STATISTIC_BY_DEPARTMENT = 'GET_MOOD_STATISTIC_BY_DEPARTMENT';
export const getMoodStatisticByDepartment = (data) => async (dispatch) => {
  const officeId = data.OfficeID || '';
  const departmentID = data.DepartmentID || '';
  const url = `/api/mood/statistic/by/department?OfficeID=${officeId}&DepartmentID=${departmentID}`;
  const result = await apiConnector(url);
  dispatch(getMoodStatisticByDepartmentSuccess(result));
};

export const GET_MOOD_STATISTIC_BY_DEPARTMENT_SUCCESS = 'GET_MOOD_STATISTIC_BY_DEPARTMENT_SUCCESS';
export function getMoodStatisticByDepartmentSuccess(data) {
  return {
    type: GET_MOOD_STATISTIC_BY_DEPARTMENT_SUCCESS,
    statistic: data,
  };
}

export const GET_MOOD_STATISTIC_BY_OFFICE = 'GET_MOOD_STATISTIC_BY_OFFICE';
export const getMoodStatisticByOffice = (data) => async (dispatch) => {
  const officeId = data.OfficeID || '';
  const departmentID = data.DepartmentID || '';
  const url = `/api/mood/statistic/by/office?OfficeID=${officeId}&DepartmentID=${departmentID}`;
  const result = await apiConnector(url);
  dispatch(getMoodStatisticByOfficeSuccess(result));
};

export const GET_MOOD_STATISTIC_BY_OFFICE_SUCCESS = 'GET_MOOD_STATISTIC_BY_OFFICE_SUCCESS';
export function getMoodStatisticByOfficeSuccess(data) {
  return {
    type: GET_MOOD_STATISTIC_BY_OFFICE_SUCCESS,
    statistic: data,
  };
}

export const GET_MOOD_STATISTIC_BY_EMPLOYEE = 'GET_MOOD_STATISTIC_BY_EMPLOYEE';
export const getMoodStatisticByEmployee = (data) => async (dispatch) => {
  const officeId = data.OfficeID || '';
  const departmentID = data.DepartmentID || '';
  const employeeID = data.EmployeeID || '';
  const url = `/api/mood/statistic/by/employee?OfficeID=${officeId}&DepartmentID=${departmentID}&EmployeeID=${employeeID}`;
  const result = await apiConnector(url);
  dispatch(getMoodStatisticByEmployeeSuccess(result));
};

export const GET_MOOD_STATISTIC_BY_EMPLOYEE_SUCCESS = 'GET_MOOD_STATISTIC_BY_EMPLOYEE_SUCCESS';
export function getMoodStatisticByEmployeeSuccess(data) {
  return {
    type: GET_MOOD_STATISTIC_BY_EMPLOYEE_SUCCESS,
    statistic: data,
  };
}

export const GET_MOOD_BY_DAY = 'GET_MOOD_BY_DAY';
export const getMoodByDay = (data) => async (dispatch) => {
  const officeId = data.OfficeID || '';
  const departmentID = data.DepartmentID || '';
  const employeeID = data.EmployeeID || '';
  const interval = data.interval || 30;
  const url = `/api/mood/search/by/day?Interval=${interval}&OfficeID=${officeId}&DepartmentID=${departmentID}&EmployeeID=${employeeID}`;
  const result = await apiConnector(url);
  dispatch(getMoodByDaySuccess(result));
};

export const GET_MOOD_BY_DAY_SUCCESS = 'GET_MOOD_BY_DAY_SUCCESS';
export function getMoodByDaySuccess(data) {
  return {
    type: GET_MOOD_BY_DAY_SUCCESS,
    mood: data,
  };
}

export const GET_MOOD = 'GET_MOOD';
export const getMood = (data) => async (dispatch) => {
  const officeId = data.OfficeID || '';
  const departmentID = data.DepartmentID || '';
  const employeeID = data.EmployeeID || '';
  const interval = data.interval || 30;
  const url = `/api/mood/search/?Interval=${interval}&OfficeID=${officeId}&DepartmentID=${departmentID}&EmployeeID=${employeeID}`;
  const result = await apiConnector(url);
  dispatch(getMoodSuccess(result));
};

export const GET_MOOD_SUCCESS = 'GET_MOOD_SUCCESS';
export function getMoodSuccess(data) {
  return {
    type: GET_MOOD_SUCCESS,
    mood: data,
  };
}

export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const saveMoodSettings = (data) => async (dispatch, getState) => {
  const {
    CompanyID,
  } = getState().auth.loggedUser;
  const user = {
    CompanyID,
  };

  const url = '/api/mood/settings';
  const method = data.MoodSettingID ? 'PUT' : 'POST';
  dispatch({ type: SAVE_SETTINGS });
  try {
    const result = await apiConnector(url, method, { user, data });
    dispatch(saveMoodSettingsSuccess());
    dispatch(getMoodSettingsSuccess(result));
  } catch (e) {
    dispatch(saveSettingsError());
  }
};

export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const saveMoodSettingsSuccess = () => ({ type: SAVE_SETTINGS_SUCCESS });

export const SAVE_SETTINGS_ERROR = 'SAVE_SETTINGS_ERROR';
export const saveSettingsError = () => {
  callAlert.error('Ups something went wrong');
  return { type: SAVE_SETTINGS_ERROR };
};

export const GET_MOOD_SETTINGS = 'GET_MOOD_SETTINGS';
export const getMoodSettings = () => async (dispatch) => {
  const url = '/api/mood/settings';
  const result = await apiConnector(url);
  if (result) {
    dispatch(getMoodSettingsSuccess(result));
  }
};

export const GET_MOOD_SETTINGS_SUCCESS = 'GET_MOOD_SETTINGS_SUCCESS';
export function getMoodSettingsSuccess(data) {
  return {
    type: GET_MOOD_SETTINGS_SUCCESS,
    moodSettings: data,
  };
}
