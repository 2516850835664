import {
  useState,
  useCallback,
} from 'react';

const useSteps = (steps, startAt) => {
  const [currentStep, setStep] = useState(steps[startAt]);

  const goToNext = useCallback(() => {
    if (currentStep.next) setStep(steps[currentStep.next]);
  }, [
    currentStep,
  ]);

  const goToPrev = useCallback(() => {
    if (currentStep.prev) setStep(steps[currentStep.prev]);
  }, [
    currentStep,
  ]);

  const reset = useCallback(() => {
    setStep(steps[startAt]);
  }, [
    steps,
    startAt,
  ]);

  return {
    currentStep,
    goToNext,
    goToPrev,
    setStep,
    reset,
  };
};

export default useSteps;
