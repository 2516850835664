import React from 'react';

import moment from 'moment';

import ErrorBoundary from 'ui/ErrorBoundary';

import ContactCell from 'sections/Calls/modules/CallLogTable/ContactCell';
import SentimentCell from 'sections/Calls/modules/CallLogTable/SentimentCell';
import OptionsCell from 'sections/Calls/modules/CallLogTable/OptionsCell';
import PhoneNumberCell from 'sections/Calls/modules/CallLogTable/PhoneNumberCell';
import DurationCell from 'sections/Calls/modules/CallLogTable/DurationCell';
import NotesCell from 'sections/Calls/modules/CallLogTable/NotesCell';

import TableHead from 'ui/TableHead';
import Typography from 'ui/Typography';

const columns = ({
  hoveredRecord,
  onSort,
}) => ([
  {
    title: () => (
      <TableHead>
        Contact
      </TableHead>
      ),
    dataIndex: 'contact_id',
    key: 'contact',
    width: 4,
    render: (value, entry) => (
      <ErrorBoundary>
        {
          value
            ? (
              <ContactCell
                contactId={value}
              />
            )
            : (
              <Typography variant="cellLarge">
                Unknown Contact
              </Typography>
            )
        }
      </ErrorBoundary>
    ),
  },
  {
    title: () => (
      <TableHead onSort={onSort('created_at')}>
        Date & Time
      </TableHead>
      ),
    width: 2,
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value, entry) => (value ? (
      <Typography variant="cell">
        {moment(value).format('MM/DD/YY hh:mma')}
      </Typography>
    ) : null),
  },
  {
    title: () => (
      <TableHead>
        Phone
      </TableHead>
      ),
    width: 2,
    dataIndex: 'phone_number',
    key: 'phone_number',
    render: (value, entry) => (
      <PhoneNumberCell
        number={value}
        contactId={entry?.contact_id}
      />
    ),
  },
  {
    title: () => (
      <TableHead>
        Call Notes
      </TableHead>
      ),
    width: 4,
    dataIndex: 'notes',
    key: 'notes',
    render: (value, entry) => (
      <NotesCell
        title={entry?.title}
        description={entry?.notes}
      />
    ),
  },
  {
    title: () => (
      <TableHead>
        Sentiment
      </TableHead>
      ),
    width: 1.5,
    dataIndex: 'sentiment',
    key: 'sentiment',
    render: (value, entry) => (value ? (
      <SentimentCell value={value} />
    ) : null),
    align: 'center',
  },
  {
    title: () => (
      <TableHead>
        Duration
      </TableHead>
      ),
    width: 3,
    dataIndex: 'duration',
    key: 'duration',
    render: (value, entry) => (
      <DurationCell
        duration={value}
        recordingSid={entry?.recording_sid}
      />
    ),
  },
  {
    title: () => (
      null
    ),
    dataIndex: 'contact_id',
    key: 'options',
    width: 1,
    render: (value, entry) => (
      <ErrorBoundary>
        <OptionsCell
          contactId={value}
        />
      </ErrorBoundary>
    ),
  },
]);

export default columns;
