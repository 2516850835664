import apiConnector, { endpoints } from 'api';

const getChats = async () => {
  const endpoint = endpoints.client.chat.general;

  const result = await apiConnector.client.get(endpoint);
  return result;
};

export default getChats;
