import {
  SET_INSPECTIONS_TYPES,
} from 'store/actionTypes/realtorInspections';

const setInspections = (inspectionsTypes) => ({
  type: SET_INSPECTIONS_TYPES,
  payload: inspectionsTypes,
});

export default setInspections;
