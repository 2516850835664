import get from './get';
import create from './create';
import getById from './getById';
import getSummary from './getSummary';
import update from './update';

export default {
  get,
  create,
  getById,
  getSummary,
  update,
};
