import {
  SET_CUSTOMER_PAYMENT_ATTACHMENTS,
} from 'store/actionTypes/customerPayments';

const setPaymentAttachments = (id, attachments) => ({
  type: SET_CUSTOMER_PAYMENT_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setPaymentAttachments;
