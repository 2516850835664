import { connect } from 'react-redux';

import NewSourceForm from 'shared/components/CRM/Widgets/NewSourceForm';

const mapStateToProps = (state, ownProps) => {
  const {
    sourceDescription,
    newCustomSourceName,
  } = ownProps;

  return {
    sourceName: newCustomSourceName || '',
    sourceDescription: sourceDescription || '',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNameChange: (sourceName) => {
    ownProps.handleChangeCustomSourceName(sourceName);
  },
  onDescriptionChange: (sourceDescription) => {
    ownProps.handleChangeSourceDescription(sourceDescription);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSourceForm);
