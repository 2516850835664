/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment, react/prop-types */

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import MenuItem from 'ui/MenuItem';
import Menu from 'ui/Menu';

import { BaseWrapper } from './styledItems';

const withOptionsMenu = (Component) => (props) => {
  const {
    onChange,
    menuBlur = () => {},
    isOpen = false,
    options,
    value,
    menuItemStyle,
    multiple = false,
    disabled,
    customOptions = null,
  } = props;

  const inputRef = useRef(null);

  const [currentValue, setCurrentValue] = useState(value);
  const [anchorEl, setAnchorEl] = useState(inputRef);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setAnchorEl(inputRef.current);

    if (isOpen) {
      setMenuOpen(true);
    }
  }, [
    inputRef,
    isOpen,
  ]);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const onButtonClick = useCallback(() => {
    if (disabled) {
      return;
    }

    setMenuOpen(true);
  }, [
    disabled,
  ]);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
    menuBlur();
  }, [menuBlur]);

  const onOptionClick = useCallback((newValue) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    onChange(newValue);

    if (!multiple) {
      closeMenu();
    }
  }, [
    onChange,
    multiple,
  ]);

  const displayValue = !customOptions && options.find((item) => `${item.value}` === `${currentValue}`)?.label;

  return (
    <>
      <BaseWrapper
        onClick={onButtonClick}
        ref={inputRef}
      >
        <Component
          {...props}
          value={displayValue}
          rawValue={currentValue}
        />
      </BaseWrapper>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: -8,
          vertical: 8,
        }}
        style={{ zIndex: 99999999 }}
      >
        {
          customOptions && customOptions({ closeMenu })
        }
        {
          !customOptions && options.map((item) => (
            <MenuItem
              key={item.value}
              onClick={onOptionClick(item.value)}
              style={menuItemStyle}
            >
              {item.label}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

export default withOptionsMenu;
