/* eslint-disable max-lines */
// TODO - refactor

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { get, isUndefined, isNull } from 'shared/utility';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import { callAlert } from 'ui/Alert';
import { common } from 'constants';
import { url } from 'paths';
import { noContentTypeFetch } from 'shared/API';

import classnames from 'classnames';

import {
  Editor,
  getEventRange,
  getEventTransfer,
} from 'slate-react';

import Html from 'slate-html-serializer';
import imageExtensions from 'image-extensions';
import videoExtensions from 'video-extensions';
import { isKeyHotkey } from 'is-hotkey';
import isUrl from 'is-url';

import LinkToURL from 'components/KnowledgeBase/ArticleDetails/Modals/LinkToURL'; // eslint-disable-line import/no-cycle
import AddImageModal from 'components/KnowledgeBase/ArticleDetails/Modals/AddImageModal'; // eslint-disable-line import/no-cycle

import {
  renderMark,
  renderNode,
} from './RenderElement/RenderElement';

import rules from './config';
import CommentTypes from '../CommentTypes';

import './ArticleEditor.scss';

// Create initial value...

const DEFAULT_NODE = 'paragraph';
const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlinedHotkey = isKeyHotkey('mod+u');
const isCodeHotkey = isKeyHotkey('mod+`');
const DEFAULT_VALUE = '<p></p>';

// Create a new serializer instance with our `rules` from above.
const html = new Html({ rules });

const attachmentsUrl = 'api/attachments/workspace/article';

const products = [
  'hr',
  'sales',
  'customer-service',
  'tasks',
];

const checkIfObjectLink = (link) => {
  // splice to remove protocol
  const [host, product] = link.split('/').splice(2);

  const sameHost = window.location.host === host;
  const hasProduct = products.includes(product);

  const isObjectLink = sameHost && hasProduct;

  return isObjectLink;
};

const schema = {
  blocks: {
    image: {
      isVoid: true,
    },
    video: {
      isVoid: true,
    },
  },
  inlines: {
    objectLink: {
      isVoid: true,
    },
    link: {
      isVoid: true,
    },
  },
  highlight: {
    isAtomic: true,
  },
};

class ArticleEditor extends Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      searchVisibility: false,
      spinning: false,
      value: value ? html.deserialize(value) : html.deserialize(DEFAULT_VALUE),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value, article } = this.props;
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: html.deserialize(value) });
    }
    if (prevProps.article.status !== article.status) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: html.deserialize(article.text) });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleUpdateArticleText = (value, timer) => {
    const {
      activeArticleId,
      article,
      articleModalMode,
      editArticle,
    } = this.props;

    let expire = timer;

    if (articleModalMode === 'new' || value === article.text) return;

    if (!isUndefined(this.editArticleRequest)) clearInterval(this.editArticleRequest);

    if (isUndefined(expire)) expire = 500;

    this.editArticleRequest = setTimeout(() => {
      editArticle('text', value, activeArticleId);
    }, expire);
  }

  /**
   * handle click outside editor for serialize and save content
   * @param event
   */
  handleClickOutside = (event) => {
    const { value } = this.state;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      const { saveEditorContent } = this.props;
      const string = html.serialize(value);
      this.handleUpdateArticleText(string, 0);
      saveEditorContent(string);
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  setSearchInputRef = (node) => {
    this.searchInputRef = node;
  };

  handleSearchIconClick = () => {
    this.setState((state) => ({
      searchVisibility: !state.searchVisibility,
    }), () => {
      if (this.searchInputRef) this.searchInputRef.focus();
    });
  };

  hasLinks = (type) => {
    const { value } = this.state;
    return value.inlines.some((inline) => inline.type === type);
  };

  hasMark = (type) => {
    const { value } = this.state;
    return value.activeMarks.some((mark) => mark.type === type);
  };

  hasBlock = (type) => {
    const { value } = this.state;
    return value.blocks.some((node) => node.type === type);
  };

  /**
   * check is url image
   * @param path
   * @ret || !isUrl(`http://${text}`u)rns {*}
   */
  isImage = (path) => imageExtensions.includes(this.getExtension(path))

  isVideo = (path) => videoExtensions.includes(this.getExtension(path))

  getExtension = (path) => new URL(path).pathname.split('.').pop()

  ref = (editor) => {
    this.editor = editor;
  };

  wrapLink = (editor, href) => {
    editor.wrapInline({
      type: 'link',
      data: { href },
    });

    editor.moveToEnd();
  };

  fetchFile = (file) => {
    const {
      activeArticleId,
    } = this.props;

    let nodeId = activeArticleId;
    if (isNull(nodeId)) nodeId = 0;

    this.setState({ spinning: true });

    const formData = new FormData();
    formData.append('attachment', file);

    return noContentTypeFetch(
      `${url}/${attachmentsUrl}/${nodeId}`,
      {
        method: 'POST',
        body: formData,
      },
    ).then((res) => {
      this.setState({ spinning: false });
      return res.json();
    }).catch(() => {
      this.setState({ spinning: false });
      return callAlert.error(common.ENTITY_TOO_LARGE.MSG);
    });
  };

  fetchImage = (editor, file, target) => {
    this.fetchFile(file).then((attachment) => {
      if (attachment.url.length > 0) {
        const src = `${url}${attachment.url}`;
        editor.command(this.insertImage, { src, loc: 'internal' }, target);
      }
    });
  };

  fetchVideo = (editor, file, target) => {
    this.fetchFile(file).then((attachment) => {
      if (attachment.url.length > 0) {
        const {
          type,
        } = file;
        const src = `${url}${attachment.url}`;
        this.insertVideo(editor, {
          src,
          loc: 'internal',
          type,
        }, target);
      }
    });
  };

  insertLink = (editor, data) => {
    const {
      link,
      thumbnailInf,
    } = data;

    editor.insertInline({
      type: 'link',
      data: { link, thumbnailInf },
    });

    editor.moveToStartOfNextText().focus();
    editor.insertText(' '); // to fix cursor position
  }

  insertObjectLink = (editor, data) => {
    const {
      link,
    } = data;

    editor.insertInline({
      type: 'objectLink',
      data: { link },
    });

    editor.moveToStartOfNextText().focus();
    editor.insertText(' '); // to fix cursor position
  }

  insertFile = (editor, data, target) => {
    const {
      vid,
      src,
      loc,
      type,
    } = data;

    if (target) {
      editor.select(target);
    }

    if (vid === 'image') {
      editor.insertBlock({
        type: vid,
        data: {
          src,
          loc,
        },
      });
    } else if (vid === 'video') {
      editor.insertBlock({
        type: vid,
        data: {
          src,
          loc,
          type,
        },
      });
    }
  };

  insertImage = (editor, data, target) => {
    const {
      src,
      loc,
    } = data;
    this.insertFile(editor, {
      vid: 'image',
      src,
      loc,
      type: null,
    }, target);
  };

  insertVideo = (editor, data, target) => {
    const {
      src,
      loc,
      type,
    } = data;
    this.insertFile(editor, {
      vid: 'video',
      src,
      loc,
      type,
    }, target);
  };

  renderLinkButton = (type, icon) => {
    const { showLinkToUrl } = this.props;
    const isActive = this.hasLinks('link');

    return (
      <Button
        onMouseDown={showLinkToUrl}
        className={isActive ? 'edit-btn_active edit-btn' : 'edit-btn'}
      >
        <FontAwesomeIcon
          icon={icon}
        />
      </Button>
    );
  };

  renderImageButton = (type, icon) => {
    const isActive = this.hasLinks(type);
    const { showLinkToImg } = this.props;

    return (
      <Button
        onMouseDown={
          showLinkToImg
        }
        className={isActive ? 'edit-btn_active edit-btn' : 'edit-btn'}
      >
        <FontAwesomeIcon
          icon={icon}
        />
      </Button>
    );
  };

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type);

    return (
      <Button
        // TODO - fix
        // eslint-disable-next-line react/jsx-no-bind
        onMouseDown={(event) => this.onClickMark(event, type)}
        className={isActive ? 'edit-btn_active edit-btn' : 'edit-btn'}
      >
        <FontAwesomeIcon
          icon={icon}
        />
      </Button>
    );
  };

  renderBlockButton = (type, icon) => {
    let isActive = this.hasBlock(type);

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { value: { document, blocks } } = this.state;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        isActive = this.hasBlock('list-item') && parent && parent.type === type;
      }
    }

    return (
      <Button
        // TODO - fix
        // eslint-disable-next-line react/jsx-no-bind
        onMouseDown={(event) => this.onClickBlock(event, type)}
        className={isActive ? 'edit-btn_active edit-btn' : 'edit-btn'}
      >
        <FontAwesomeIcon
          icon={icon}
        />
        {(type === 'heading-one') && <span style={{ fontSize: '16px', fontWeight: '600' }}>1</span>}
        {(type === 'heading-two') && <span style={{ fontSize: '16px', fontWeight: '600' }}>2</span>}
      </Button>
    );
  };

  onClickImage = (link) => {
    const { editor } = this;
    const src = link;
    if (!src) return;
    editor.command(this.insertImage, { src, loc: 'external' });
  };

  onClickLink = (link) => {
    const { editor } = this;
    const { value } = editor;
    const hasLinks = this.hasLinks('link');

    if (hasLinks) {
      // editor.command(unwrapLink)
    } else if (value.selection.isExpanded) {
      const href = link;
      if (href == null) {
        return;
      }

      editor.command(this.wrapLink, href);
    } else {
      const href = link;

      if (href == null) {
        return;
      }

      // text for empty link name
      const text = link;

      if (text == null) {
        return;
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(this.wrapLink, href);
    }
  };

  onChangeEditor = (payload) => {
    const value = get(payload, 'value', '');
    const {
      onChange,
      saveEditorContent,
    } = this.props;

    const string = html.serialize(value);
    onChange(string);

    this.setState({ value });

    if (string.replace(/<[^>]*>/g, '').length > 0) {
      this.handleUpdateArticleText(string);
    }

    saveEditorContent(string);
  };

  onKeyDown = (event, editor, next) => {
    let mark;

    if (isBoldHotkey(event)) {
      mark = 'bold';
    } else if (isItalicHotkey(event)) {
      mark = 'italic';
    } else if (isUnderlinedHotkey(event)) {
      mark = 'underlined';
    } else if (isCodeHotkey(event)) {
      mark = 'code';
    } else {
      return next();
    }

    event.preventDefault();
    return editor.toggleMark(mark);
  };

  onClickMark = (event, type) => {
    const { editor } = this;
    event.preventDefault();
    editor.toggleMark(type);
  };

  /**
   * action when clicking on toolbar button
   * @param event
   * @param type of node
   */
  onClickBlock = (event, type) => {
    event.preventDefault();

    let isActive;
    const fixedType = type;

    const { editor } = this;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons.
    if (fixedType !== 'bulleted-list' && fixedType !== 'numbered-list') {
      isActive = this.hasBlock(fixedType);
      const isList = this.hasBlock('list-item');

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : fixedType)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : fixedType);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item');
      const isType = value.blocks
        .some((block) => !!document.getClosest(block.key, (parent) => parent.type === fixedType));

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        editor
          .unwrapBlock(
            fixedType === 'bulleted-list' ? 'numbered-list' : 'bulleted-list',
          )
          .wrapBlock(fixedType);
      } else {
        editor.setBlocks('list-item').wrapBlock(fixedType);
      }
    }
  };

  onDropOrPaste = async (event, editor, next) => {
    const {
      getThumbnailInf,
    } = this.props;

    const target = getEventRange(event, editor);
    if (!target && event.type === 'drop') return next();

    const transfer = getEventTransfer(event);
    const { type, text, files } = transfer;

    if (type === 'files') {
      // eslint-disable-next-line no-unused-vars
      for (const file of files) {
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          editor.command(this.fetchImage, file, target);
        } else if (mime === 'video') {
          editor.command(this.fetchVideo, file, target);
        }
      }
      return false;
    }

    if (type === 'html' || type === 'text') {
      if (!isUrl(text)) return next();

      if (this.isImage(text)) {
        editor.command(
          this.insertImage,
          { src: text, loc: 'external' },
          target,
        );
      } else if (this.isVideo(text)) {
        editor.command(
          this.insertVideo,
          { src: text, loc: 'external' },
          target,
        );
      } else {
        const isObjectLink = checkIfObjectLink(text);

        if (isObjectLink) {
          editor.command(
            this.insertObjectLink,
            { link: text },
          );
        } else if (isUrl(text) && !checkIfObjectLink(text)) {
          const thumbnailInf = await getThumbnailInf(text);
          editor.command(
            this.insertLink,
            {
              link: text,
              thumbnailInf,
            },
          );
        } else return next();
      }

      return false;
    }

    return next();
  };

  /**
   * search content in editor content
   * @param event
   */
  onSearchChange = (event) => {
    const { editor } = this;
    const { value } = editor;
    const string = event.target.value;
    const texts = value.document.getTexts();
    const decorations = [];

    texts.forEach((node) => {
      const { key, text } = node;
      const parts = text.split(string);
      let offset = 0;

      parts.forEach((part, i) => {
        if (i !== 0) {
          decorations.push({
            anchor: { key, offset: offset - string.length },
            focus: { key, offset },
            mark: { type: 'mark' },
          });
        }

        offset = offset + part.length + string.length;
      });
    });

    // Make the change to decorations without saving it into the undo history,
    // so that there isn't a confusing behavior when undoing.
    editor.withoutSaving(() => {
      editor.setDecorations(decorations);
    });
  };

  onSearchClose = () => {
    this.setState({
      searchVisibility: false,
    });
  }

  renderSearchButton = () => {
    const { searchVisibility } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <Button
          className={classnames(
            'search-btn', 'edit-btn',
            {
              'edit-btn_active': searchVisibility,
            },
          )}
          onClick={this.handleSearchIconClick}
        >
          <FontAwesomeIcon
            icon="search"
          />
        </Button>
        <Input
          ref={this.setSearchInputRef}
          type="search"
          placeholder="Search the text..."
          onChange={this.onSearchChange}
          className={classnames('search-in-edit', {
            'search-in-edit_active': searchVisibility,
          })}
          onBlur={this.onSearchClose}
        />
      </div>
    );
  };

  checkPostPublic = () => {
    const {
      togglePostPublic,
    } = this.props;

    togglePostPublic();
  };

  isValueEmpty = (value) => !value.document.findDescendant((node) => node.text !== '');

  onSubmit = () => {
    const { onClickPost } = this.props;
    const { value } = this.state;

    if (this.isValueEmpty(value)) {
      callAlert.error('Text is empty');
      return;
    }

    onClickPost(html.serialize(value));

    this.setState({
      value: html.deserialize(DEFAULT_VALUE),
    });
  };

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  render() {
    const {
      autoFocus,
      buttons,
      className,
      isVisibleToCustomer,
      mini,
      onTypeChange,
      showDefaultControls,
      small,
    } = this.props;

    const {
      spinning,
      value,
    } = this.state;

    return (
      <Spin tip="Loading..." spinning={spinning}>
        <div className={
          classnames(
            {
              [className]: className,
            },
          )
        }
        >
          <div
            ref={this.setWrapperRef}
            className={classnames('article-edit', {
              'article-edit--mini': mini,
              'article-edit--small': small,
            })}
          >
            <div className="edit-toolbar">
              {small
                ? (
                  <div className="edit-toolbar-small">
                    {this.renderMarkButton('bold', 'bold')}
                    {this.renderMarkButton('strike', 'strikethrough')}
                    {this.renderMarkButton('italic', 'italic')}
                    {this.renderMarkButton('mark', 'highlighter')}
                    <div className="edit-toolbar__pipe" />
                  </div>
                )
                : (
                  <>
                    {this.renderMarkButton('bold', 'bold')}
                    {this.renderMarkButton('strike', 'strikethrough')}
                    {this.renderMarkButton('italic', 'italic')}
                    {this.renderMarkButton('mark', 'highlighter')}
                    {/* {this.renderLinkButton('link', 'link')} */}
                    <div className="edit-toolbar__pipe" />
                    {this.renderBlockButton('heading-one', 'heading')}
                    {this.renderBlockButton('heading-two', 'heading')}
                    <div className="edit-toolbar__pipe" />
                    {this.renderBlockButton('align-left', 'align-left')}
                    {this.renderBlockButton('align-center', 'align-center')}
                    {this.renderBlockButton('align-right', 'align-right')}
                    {this.renderBlockButton('align-justify', 'align-justify')}
                    <div className="edit-toolbar__pipe" />
                    {this.renderBlockButton('numbered-list', 'list-ol')}
                    {this.renderBlockButton('bulleted-list', 'list-ul')}
                    <div className="edit-toolbar__pipe" />
                    {this.renderSearchButton()}
                  </>
                )}
            </div>

            {
              !mini && (
                <Row className="select-comments-type" gutter={16} type="flex" justify="start">
                  <Col>
                    Select comments type:
                  </Col>
                  <Col>
                    <CommentTypes buttons={buttons} onTypeChange={onTypeChange} />
                  </Col>
                </Row>
              )
            }

            <Editor
              className="edit-body"
              autoFocus={autoFocus}
              autoCorrect={false}
              spellCheck={false}
              tabIndex={0}
              placeholder="Enter some text..."
              ref={this.ref}
              value={value}
              onChange={this.onChangeEditor}
              onKeyDown={this.onKeyDown}
              renderNode={renderNode}
              renderMark={renderMark}
              schema={schema}
              onDrop={this.onDropOrPaste}
              onPaste={this.onDropOrPaste}
            />

            <LinkToURL link={this.onClickLink} />
            <AddImageModal link={this.onClickImage} />
          </div>
          {
            !mini && (
              <Row
                className="post-public-checkbox"
                gutter={2}
                type="flex"
                justify="start"
              >
                <Col>
                  <Checkbox
                    onChange={this.checkPostPublic}
                    checked={isVisibleToCustomer}
                  >
                    Visible to customer
                  </Checkbox>
                </Col>
                <Col>
                  <Tooltip placement="bottom" title="If checked, this post will be visible to the customer">
                    <div style={{
                      textDecoration: 'underline',
                      verticalAlign: 'top',
                      fontSize: '10px',
                    }}
                    >
                      ?
                    </div>
                  </Tooltip>
                </Col>
              </Row>
            )
          }

          {
            (!mini || showDefaultControls) && (
              <div className="post-action-buttons">
                <Button
                  type="primary"
                  onClick={this.onSubmit}
                >
                  Post
                </Button>

                <Button
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </div>
            )
          }
        </div>
      </Spin>
    );
  }
}

const noOp = () => {};

ArticleEditor.defaultProps = {
  onClickPost: noOp,
  togglePostPublic: noOp,
  onCancel: noOp,
  onChange: noOp,
  article: null,
  activeArticleId: null,
  articleModalMode: null,
  className: null,
  isVisibleToCustomer: false,
  value: null,
  mini: false,
  buttons: [],
  autoFocus: false,
  showDefaultControls: false,
  small: false,
};

const {
  bool,
  func,
  object,
  string,
  array,
} = PropTypes;

// TODO - improve proptypes
ArticleEditor.propTypes = {
  // redux props
  saveEditorContent: func.isRequired,
  editArticle: func.isRequired,
  showLinkToUrl: func.isRequired,
  showLinkToImg: func.isRequired,
  getThumbnailInf: func.isRequired,
  article: object, // eslint-disable-line react/forbid-prop-types
  activeArticleId: string,
  articleModalMode: string,

  // custom
  className: string,
  onCancel: func,
  onClickPost: func,
  onTypeChange: func.isRequired,
  togglePostPublic: func,
  isVisibleToCustomer: bool,
  value: string,
  mini: bool,
  onChange: func,
  autoFocus: bool,
  buttons: PropTypes.oneOfType([array]),
  showDefaultControls: bool,
  small: bool,
};

export default ArticleEditor;
