import React from 'react';

import Grid from '@material-ui/core/Grid';

export default ({ children, ...rest }) => (
  <Grid
    {...rest}
    container
  >
    {children}
  </Grid>
);
