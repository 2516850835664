import React from 'react';
import PropTypes from 'prop-types';
import { desiredOutcomeType } from 'oa/types/index';
import Todos from 'oa/components/Todos/index';

const DesiredOutcomes = (props) => {
  const {
    toggleDesiredOutcome,
    desiredOutcomes,
  } = props;

  return (
    <div className="meeting-details-group meeting-details-modal__group">
      <h3 className="meeting-details-group__header">
        Agenda / Desired Outcomes
      </h3>

      <Todos todos={desiredOutcomes} toggleTodo={toggleDesiredOutcome}>
        <Todos.List className="meeting-details-group__list">
          {desiredOutcomes.map((item, index) => (
            <Todos.Item
              key={item.id}
              className="meeting-details-group__item"
              id={item.id}
              name={item.name}
              index={index}
              isCompleted={item.isCompleted}
            />
          ))}
        </Todos.List>
      </Todos>
    </div>
  );
};

DesiredOutcomes.propTypes = {
  toggleDesiredOutcome: PropTypes.func.isRequired,
  desiredOutcomes: PropTypes.arrayOf(desiredOutcomeType).isRequired,
};

export default DesiredOutcomes;
