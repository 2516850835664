import { connect } from 'react-redux';

import selectors from 'store/selectors';

import actions from 'sections/Prospects/actions';
import showingActions from 'sections/Showings/actions';

import Button from 'ui/Button';

const mapStateToProps = (state, ownProps) => {
  const matchingProperty = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  const {
    realtor_property_id: propertyId,
    realtor_contact_id: contactId,
  } = matchingProperty;

  return {
    propertyId,
    contactId,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  variant: 'primary',
  dense: true,
  fullWidth: true,
  children: 'Book Showing',
  ...ownProps,
  onClick: () => {
    showingActions.onCreateShowing({
      contactId: stateProps.contactId,
      propertyId: stateProps.propertyId,
      onSave: () => actions.kanban.updateMatchingProperty(ownProps.matchId, ownProps.prospectId)({ status: 2 }),
    });
  },
});

export default connect(mapStateToProps, null, mergeProps)(Button);
