import connectReducers from 'store/connectReducers';

import {
  SET_CRM_COMPANY_FILTER_ITEM,
  RESET_CRM_COMPANY_FILTER_ITEM,
  CLEAR_CRM_COMPANY_FILTER,
} from 'store/actionTypes/crm';

const initialState = {
  revenue_range: [], // [start, end]
  employees_range: [], // [start, end]
  search: null,
  industry_type: [], // int
  labels: [], // int
  company_type: [], // int
  permissionType: [], // int
  ownedByTeams: [], // int
  ownedByEmployees: [], // int
  permissionsForEmployees: [], // int
  permissionsForTeams: [], // int
  permissionsForOffices: [], // int
  status: [], // int
  pageNumber: 1,
  pageSize: 10,
  isOrphaned: null,
  isActive: null,
  isInactive: null,
  // last_contact:
  //   name: last_contact
  //   schema:
  //     type: object
  //     properties:
  //       sign:
  //         type: string
  //         enum:
  //           - more
  //           - less
  //       value:
  //         type: integer
  //       unit:
  //         type: string
  //         enum:
  //           - minute
  //           - hour
  //           - day
  //           - week
  //           - month
  //           - year
};

const reducers = {
  [SET_CRM_COMPANY_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),

  [RESET_CRM_COMPANY_FILTER_ITEM]: (state, action) => ({
    ...state,
    [action.payload]: initialState[action.payload],
  }),

  [CLEAR_CRM_COMPANY_FILTER]: (state, action) => initialState,
};

function crmCompaniesFilter(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default crmCompaniesFilter;
