import {
  SET_CALL_LOGS_FOR_FILTER,
} from 'store/actionTypes/calls';

const setCallLogsForFilter = (filterId) => (logs) => ({
  type: SET_CALL_LOGS_FOR_FILTER,
  payload: {
    filterId,
    callLogs: logs,
  },
});

export default setCallLogsForFilter;
