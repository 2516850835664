import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import sms from 'shared/sockets/sms';

import sanitizePhoneNumber from 'shared/utils/sanitizePhoneNumber';

import Button from 'ui/Button';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import PhoneNumberInput from 'ui/PhoneNumberInput';

import CallsContext from 'sections/Calls/context/CallsContext';

const SMS = () => {
  // const {
    // selectedPhone,
    // setSelectedPhone,
  // } = useContext(CallsContext);

  const [selectedPhone, setSelectedPhone] = useState('');

  // useEffect(() => {
  //   sms.handle(selectedPhone);
  // }, [
  //   selectedPhone,
  // ]);

  const [toPhone, setToPhone] = useState('');
  const [text, setText] = useState('');

  const handleSend = useCallback(() => {
    sms.handle(selectedPhone);

    sms.send(
      // sanitizePhoneNumber(selectedPhone),
      sanitizePhoneNumber(toPhone),
    )(text);
  }, [
    selectedPhone,
    toPhone,
    text,
  ]);

  return (
    <Flex
      flexDirection="column"
      spacing={2}
      fullWidth
    >
      <Typography variant="title2" noMargin>
        SMS
      </Typography>
      {/* <Typography align="right" noMargin>
        From
        {' '}
        {selectedPhone}
      </Typography> */}
      <PhoneNumberInput
        value={toPhone}
        onChange={setToPhone}
        style={{ width: '100%' }}
        label={null}
        placeholder="To"
      />
      <Textfield
        textarea
        value={text}
        onChange={setText}
        fullWidth
      />
      <Button
        style={{ alignSelf: 'flex-end' }}
        variant="primary"
        onClick={handleSend}
      >
        Send
      </Button>
    </Flex>
  );
};

export default SMS;
