import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} source
 * @param {number} source.type
 * @param {string} source.description
 */
const create = async (source) => {
  const endpoint = endpoints.client.crm.sources;

  try {
    const result = await apiConnector.client.post(endpoint, source);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
