import { connect } from 'react-redux';

import {
  searchMLSModalAction,
} from 'store/actions/searchMLSModal';

import SearchMLSForm from './SearchMLSForm';

const mapStateToProps = (state) => {
  const {
    realtorSearchMLSModal: {
      searchedData,
    },
  } = state;

  return {
    searchedData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchProperty: async (filters) => {
    const {
      address,
      propertyType,
      propertyParameters,
    } = filters;

    const { contactId } = ownProps;

    await dispatch(searchMLSModalAction({
      property_types: propertyType,
      ...propertyParameters,
      address,
      excludingContactIdMatches: contactId || undefined,
      limit: 10,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMLSForm);
