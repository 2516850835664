import {
  SET_REALTOR_CLIENT,
} from 'store/actionTypes/realtorClients';

const setClient = (client) => ({
  type: SET_REALTOR_CLIENT,
  payload: client,
});

export default setClient;
