import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.crmContactsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      search: filter.search,
      status: filter.status,
      company: filter.company,
      labels: filter.labels,
      contact_type: filter.contactType,
      source: filter.source,
      source_type: filter.sourceType,
      permission_type: filter.permissionType,
      last_contact_range: filter.lastContactRange,
      owned_by_teams: filter.ownedByTeams,
      owned_by_employees: filter.ownedByEmployees,
      permissions_for_employees: filter.permissionsForEmployees,
      permissions_for_teams: filter.permissionsForTeams,
      permissions_for_offices: filter.permissionsForOffices,
      is_orphaned: filter.isOrphaned,
      is_active: filter.isActive,
      is_inactive: filter.isInactive,
    },
  }),
);
