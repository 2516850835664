import requests from 'api/requests';

import setCallLogs from './setCallLogs';

const getCallLogs = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.calls.logs.get({
      offset,
      limit,
    });

    dispatch(setCallLogs(response.data.callLogs));
  } catch (error) {
    console.error(error);
  }
};

export default getCallLogs;
