import { connect } from 'react-redux';

import {
  openModal,
} from 'store/actions/modals';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import MakeLead from './MakeLead';

const mapStateToProps = (state, ownProps) => ({
    shouldShow: true,
  });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(setNewLeadFormField('selectedContact')({ id: ownProps.contactId }));
    dispatch(setNewLeadFormField('restrictContactChange')(true));
    dispatch(openModal('newLeadFormSimple')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MakeLead);
