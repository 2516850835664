/* eslint-disable import/no-cycle */
// TODO - fix dependency cycles

import connectReducers from 'store/connectReducers';

import { LOGOUT_SUCCESS } from 'store/actions/auth';

import {
  CHANGE_DASHBOARD_VIEW,
  CHANGE_LUNCH_STATUS,
  CHANGE_PERSONAL_VIEW,
  CHANGE_TICKET_VIEW,
  EMPLOYEE_GENERAL_MODAL_UPDATE_DATA,
  GET_ALL_NOTICES_SUCCESS,
  SHOW_CLOCK_IN_MODAL,
  SHOW_CLOCK_OUT_MODAL,
  SHOW_CREATE_NEW_USER_MODAL,
  SHOW_CSV_IMPORT_MODAL,
  SHOW_CUSTOM_MODAL,
  SHOW_EMPLOYEE_GENERAL_MODAL,
  SHOW_LUNCH_IN_MODAL,
  SHOW_LUNCH_OUT_MODAL,
  SHOW_QUEUE_SETUP,
  SHOW_TASK_CREATE_MODAL,
  SHOW_TICKET_CREATE_MODAL,
  SHOW_VALIDATION_MODAL,
  SHOW_ADD_EMPLOYEE_MODAL,
  HIDE_ADD_EMPLOYEE_MODAL,
  HIDE_TICKET_CREATE_MODAL,
  SET_EMPLOYEE_MENTIONS_SUMMARY,
} from 'store/actions/dashboard';

import {
  CREATE_TICKET_ERROR,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET,
} from 'store/actions/tickets';

const initialState = {
  addTicketModal: {
    isVisible: false,
    isLoading: false,
  },
  addTaskModal: {
    isVisible: false,
    isLoading: false,
  },
  csvImportModal: false,
  employeeGeneralModal: {
    isVisible: false,
    isLoading: false,
    isEmployeeDraft: false,
    data: null,
  },
  createNewUserModal: false,
  changeTicketsView: 'board',
  showQueueSetup: false,
  changeManagerialDashboardQueuesView: true,
  changeManagerialDashboardPersonalView: false,
  validationModal: {
    isVisible: false,
    isLoading: false,
  },
  customModal: {
    isVisible: false,
    isLoading: false,
    onOk: null,
    onCancel: null,
    message: null,
  },
  lunchInModal: {
    isVisible: false,
    isLoading: false,
  },
  lunchOutModal: {
    isVisible: false,
    isLoading: false,
  },
  clockOut: {
    isVisible: false,
    isLoading: false,
  },
  clockIn: {
    isVisible: false,
    isLoading: false,
  },
  noticesList: [],
  lunchIn: false,
  isAddEmployeeModalVisible: false,
  mentionsSummary: {},
};

const reducers = {
  [SHOW_ADD_EMPLOYEE_MODAL]: (state, action) => ({
    ...state,
    isAddEmployeeModalVisible: true,
  }),

  [HIDE_ADD_EMPLOYEE_MODAL]: (state, action) => ({
    ...state,
    isAddEmployeeModalVisible: false,
  }),

  [SHOW_TICKET_CREATE_MODAL]: (state, action) => {
    const { addTicketModal } = state;
    return {
      ...state,
      addTicketModal: {
        ...addTicketModal,
        isVisible: !addTicketModal.isVisible,
      },
    };
  },

  [HIDE_TICKET_CREATE_MODAL]: (state) => {
    const { addTicketModal } = state;
    return {
      ...state,
      addTicketModal: {
        ...addTicketModal,
        isVisible: false,
      },
    };
  },

  [SHOW_TASK_CREATE_MODAL]: (state, action) => {
    const { addTaskModal } = state;
    return {
      ...state,
      addTaskModal: {
        ...addTaskModal,
        isVisible: !addTaskModal.isVisible,
      },
    };
  },

  [SHOW_CSV_IMPORT_MODAL]: (state, action) => {
    const { csvImportModal } = state;
    return {
      ...state,
      csvImportModal: !csvImportModal,
    };
  },

  [SHOW_EMPLOYEE_GENERAL_MODAL]: (state, action) => ({
    ...state,
    employeeGeneralModal: {
      ...state.employeeGeneralModal,
      isVisible: !state.employeeGeneralModal.isVisible,
      data: action.data,
      isEmployeeDraft: action.isEmployeeDraft,
    },
  }),

  [EMPLOYEE_GENERAL_MODAL_UPDATE_DATA]: (state, action) => {
    const { employeeGeneralModal } = state;
    return {
      ...state,
      employeeGeneralModal: {
        ...employeeGeneralModal,
        data: action.data,
      },
    };
  },

  [SHOW_CREATE_NEW_USER_MODAL]: (state, action) => ({
    ...state,
    createNewUserModal: action.createNewUserModal,
  }),

  [CHANGE_TICKET_VIEW]: (state, action) => {
    const { view } = action;
    return {
      ...state,
      changeTicketsView: view,
    };
  },

  [SHOW_QUEUE_SETUP]: (state, action) => ({
    ...state,
    showQueueSetup: !state.showQueueSetup,
  }),

  [CHANGE_DASHBOARD_VIEW]: (state, action) => ({
    ...state,
    changeManagerialDashboardQueuesView: !state.changeManagerialDashboardQueuesView,
    changeManagerialDashboardPersonalView: !state.changeManagerialDashboardPersonalView,
  }),

  [CHANGE_PERSONAL_VIEW]: (state, action) => ({
    ...state,
    changeManagerialDashboardPersonalView: !state.changeManagerialDashboardPersonalView,
    changeManagerialDashboardQueuesView: !state.changeManagerialDashboardQueuesView,
  }),

  [CREATE_TICKET]: (state, action) => {
    const { addTicketModal } = state;
    return {
      ...state,
      addTicketModal: { ...addTicketModal, isLoading: true },
    };
  },

  [CREATE_TICKET_SUCCESS]: (state, action) => {
    const { addTicketModal } = state;
    return {
      ...state,
      addTicketModal: { ...addTicketModal, isLoading: false },
    };
  },

  [CREATE_TICKET_ERROR]: (state, action) => {
    const { addTicketModal } = state;
    return {
      ...state,
      addTicketModal: { ...addTicketModal, isLoading: false },
    };
  },

  [SHOW_CUSTOM_MODAL]: (state, action) => {
    let { customModal } = state;
    customModal = { ...customModal, ...action };
    return {
      ...state,
      customModal: { ...customModal, isVisible: !customModal.isVisible },
    };
  },

  [SHOW_LUNCH_IN_MODAL]: (state, action) => {
    const { lunchInModal } = state;
    return {
      ...state,
      lunchInModal: { ...lunchInModal, isVisible: !lunchInModal.isVisible },
    };
  },

  [SHOW_LUNCH_OUT_MODAL]: (state, action) => {
    const { lunchOutModal } = state;
    return {
      ...state,
      lunchOutModal: { ...lunchOutModal, isVisible: !lunchOutModal.isVisible },
    };
  },

  [SHOW_VALIDATION_MODAL]: (state, action) => {
    const { validationModal } = state;
    return {
      ...state,
      validationModal: {
        ...validationModal,
        isVisible: !validationModal.isVisible,
      },
    };
  },

  [SHOW_CLOCK_OUT_MODAL]: (state, action) => {
    const { clockOut } = state;
    return {
      ...state,
      clockOut: { ...clockOut, isVisible: !clockOut.isVisible },
    };
  },

  [SHOW_CLOCK_IN_MODAL]: (state, action) => {
    const { clockIn } = state;
    return {
      ...state,
      clockIn: { ...clockIn, isVisible: !clockIn.isVisible },
    };
  },

  [GET_ALL_NOTICES_SUCCESS]: (state, action) => ({
    ...state,
    noticesList: action.notices,
  }),

  [CHANGE_LUNCH_STATUS]: (state, action) => {
    const { lunchIn } = state;

    return {
      ...state,
      lunchIn: action.hasOwnProperty('value') // eslint-disable-line no-prototype-builtins
        ? action.value
        : !lunchIn,
    };
  },

  [LOGOUT_SUCCESS]: (state, action) => ({ ...state, showQueueSetup: false }),

  [SET_EMPLOYEE_MENTIONS_SUMMARY]: (state, action) => {
    const mentionsSummary = action.payload;

    return {
      ...state,
      ...mentionsSummary,
    };
  },
};

function dashboard(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default dashboard;
