import { set as setValueAtPath, get as getValueAtPath } from 'shared/utility';

const get = (objectType, objectId) => {
  const commentsDraftsRaw = localStorage.getItem('commentsDrafts');
  const commentsDrafts = commentsDraftsRaw && JSON.parse(commentsDraftsRaw);

  if (!commentsDrafts) {
    return null;
  }

  return getValueAtPath(commentsDrafts, `"${objectType}"."${objectId}"`);
};

const set = ({
  objectId,
  objectType,
  commentType = null,
  commentContent = null,
}) => {
  const commentsDraftsRaw = localStorage.getItem('commentsDrafts');
  const commentsDrafts = commentsDraftsRaw ? JSON.parse(commentsDraftsRaw) : {};

  const valueToSet = commentType && commentContent ? {
    commentType,
    commentContent,
  } : null;

  setValueAtPath(
    commentsDrafts,
    `"${objectType}"."${objectId}"`,
    valueToSet,
  );

  const newCommentsDrafts = JSON.stringify(commentsDrafts);
  localStorage.setItem('commentsDrafts', newCommentsDrafts);
};

const clear = (objectType, objectId) => {
  set({ objectType, objectId });
};

export default {
  get,
  set,
  clear,
};
