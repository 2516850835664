import apiConnector, { endpoints } from 'api';

const getRawFile = async (id) => {
  try {
    const response = await apiConnector.client.get(
      `${endpoints.client.dataImport.upload}/${id}`,
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getRawFile;
