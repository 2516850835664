import { createSelector } from 'reselect';

import { optimizedDictionaryActions } from 'store/actions/dictionaryV2';

const viewsFromHome = (state) => state.dictionaryV2.realtorViewsFromHome;

export default createSelector(
  viewsFromHome,
  (collection) => {
    if (!collection) {
      optimizedDictionaryActions.getRealtorViewsFromHomeDictionary();
      return [];
    }

    return collection.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  },
);
