import { connect } from 'react-redux';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

import PreApprovalForm from './PreApprovalForm';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => (value) => {
    dispatch(updatePropertyRequirement(ownProps.propertyRequirementId)({
      [key]: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreApprovalForm);
