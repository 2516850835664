import { connect } from 'react-redux';

import searchLeads from 'store/actions/realtorLeads/searchLeads';

import LeadsSearch from 'shared/components/UI/RealtorMasterSearch/LeadsSearch';

const mapDispatchToProps = (dispatch) => ({
  onSearch: async (searchQuery) => {
    if (!searchQuery) {
      return [];
    }
    const result = await dispatch(searchLeads(searchQuery));
    return result;
  },
});

export default connect(null, mapDispatchToProps)(LeadsSearch);
