import {
  SET_CRM_CONTACT_CHANGELOG,
} from 'store/actionTypes/crm';

const setContactChangelog = ({ contactId, changelog }) => ({
  type: SET_CRM_CONTACT_CHANGELOG,
  payload: {
    contactId,
    changelog,
  },
});

export default setContactChangelog;
