import React, {
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import useContactName from 'shared/hooks/contacts/useContactName';

const ContactCell = (props) => {
  const {
    contactId,
    tags,
  } = props;

  const tagItems = useMemo(() => (tags.length > 0
    ? (
      <Flex spacing={1}>
        {
          tags.map((tag) => (
            <Typography
              color="leadTag"
              variant="tag"
              noMargin
              weight="bold"
            >
              {tag.toUpperCase()}
            </Typography>
          ))
        }
      </Flex>
    )
    : null),
  [
    tags,
  ]);

  const contactName = useContactName(contactId);

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <ContactAvatar
        contactId={contactId}
        size="sm"
      />
      <Flex spacing={1} flexDirection="column">
        <Tooltip title={contactName}>
          <Typography
            ellipsis
            weight="bold"
            variant="cellLarge"
            noWrap
          >
            {contactName}
          </Typography>
        </Tooltip>
        <Flex spacing={1}>
          { tagItems }
        </Flex>
      </Flex>
    </Flex>
  );
};

ContactCell.defaultProps = {
  tags: [],
};

const {
  arrayOf,
  number,
  string,
} = PropTypes;

ContactCell.propTypes = {
  contactId: number.isRequired,
  tags: arrayOf(string),
};

export default ContactCell;
