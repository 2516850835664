import React from 'react';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import IntegrationItem from 'sections/Integrations/components/IntegrationItem';

import Google from 'sections/Integrations/modules/IntegrationsProductivity/Google';
import ICloud from 'sections/Integrations/modules/IntegrationsProductivity/ICloud';

const placeholderItems = [
  {
    name: 'Outlook',
    icon: 'integrationsOutlook',
  },
  {
    name: 'Yahoo!',
    icon: 'integrationsYahoo',
  },
];

const IntegrationsProductivity = (props) => (
  <Flex
    spacing={3}
    alignItems="stretch"
  >
    <FlexItem
      flex={1}
      fullHeight
    >
      <Google />
    </FlexItem>
    <FlexItem
      flex={1}
      fullHeight
    >
      <ICloud />
    </FlexItem>
    {
      placeholderItems.map((item) => (
        <FlexItem
          flex={1}
          fullHeight
        >
          <IntegrationItem
            name={item.name}
            icon={item.icon}
            items={item.items}
          />
        </FlexItem>
      ))
    }
  </Flex>
);

export default IntegrationsProductivity;
