import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import PersonItem from './PersonItem';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
    isCurrentUser,
  } = ownProps;

  const contact = isCurrentUser
    ? selectors.users.currentUser(state)
    : selectors.realtorContacts.byId(state, contactId);

  const name = `${contact?.first_name || ''} ${contact?.last_name || ''}`;

  return {
    contactId: contact?.id,
    firstName: contact?.first_name,
    lastName: contact?.last_name,
    name: isCurrentUser
      ? `${name} — you`
      : name,
    avatarUrl: getAvatarUrl(contact?.avatar),
  };
};

export default connect(mapStateToProps)(PersonItem);
