import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getPhoneNumberById from './getPhoneNumberById';

const update = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.phoneNumbers.update(id)(updateData);

    callAlert.success('Updated successfully!');

    dispatch(getPhoneNumberById(id));
  } catch (error) {
    console.error(error);
    callAlert.error('Could not update!');
  }
};

export default update;
