import { connect } from 'react-redux';

import {
  setFilterItem,
} from 'store/actions/callLogsFilter';

import {
  getCallLogsForStoreFilter,
} from 'store/actions/calls';

import CallLogsCreationDateFilter from './CallLogsCreationDateFilter';

const mapStateToProps = (state) => {
  const {
    callLogsFilter,
  } = state;

  return {
    dateFromValue: callLogsFilter.dateFrom,
    dateToValue: callLogsFilter.dateTo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
    dispatch(getCallLogsForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLogsCreationDateFilter);
