import {
  REMOVE_TUTORIAL_TASK,
} from 'store/actionTypes/tasks';

const removeTutorialTask = () => (dispatch) => {
  dispatch({
    type: REMOVE_TUTORIAL_TASK,
  });
};

export default removeTutorialTask;
