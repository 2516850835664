import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getOpportunitiesOverallSummary from '../realtorOpportunities/getOpportunitiesOverallSummary';

const deleteProspect = (prospectId) => async (dispatch) => {
  try {
    await requests.realtor.prospects.deleteProspect(prospectId);
    dispatch(getOpportunitiesOverallSummary());
    callAlert.success('Deleted');
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteProspect;
