import { apiConnector } from 'shared/API';


export const SET_CUSTOMERS_LABELS = 'SET_CUSTOMERS_LABELS';
export const setCustomersLabels = (payload) => ({
  type: SET_CUSTOMERS_LABELS,
  payload,
});

export const CLEAR_CUSTOMERS_LABELS = 'CLEAR_CUSTOMERS_LABELS';
export const clearCustomersLabels = (payload) => ({
  type: CLEAR_CUSTOMERS_LABELS,
  payload,
});

export const SET_CUSTOMER_LABELS = 'SET_CUSTOMER_LABELS';
export const setCustomerLabels = (payload) => ({
  type: SET_CUSTOMER_LABELS,
  payload,
});

export const loadCustomersLabels = (force = false) => (dispatch, getState) => {
  const {
    customersLabels: {
      labels,
    },
  } = getState();

  if (labels.length === 0 || force) {
    apiConnector(
      '/api/customerlabels/',
      'GET',
    ).then(({ result }) => {
      dispatch(setCustomersLabels({
        labels: result,
      }));
    });
  }
};

export const loadLabelsByCustomer = (customerID) => (dispatch) => {
  apiConnector(
    `/api/customerlabels/${customerID}`,
    'GET',
  ).then(({ result }) => {
    dispatch(setCustomerLabels({
      customerLabels: result,
    }));
  });
};

export const createCustomersLabel = (labelObject) => (dispatch, getState) => {
  const {
    customersLabels: {
      labels,
    },
  } = getState();

  apiConnector(
    '/api/customerlabels/update/',
    'POST',
    labelObject,
  ).then(({ result }) => {
    dispatch(setCustomersLabels({
      labels: result,
    }));
  });
};

export const updateCustomersLabel = (labelID, payload, callback) => (dispatch, getState) => {
  const {
    customersLabels: {
      labels,
    },
    tickets: {
      selectedTicket: {
        customer: {
          CustomerID,
        },
      },
    },
  } = getState();

  const { color } = labels.find((label) => label.id === labelID);

  apiConnector(
    `/api/customerlabels/update/${labelID}`,
    'POST',
    {
      ...payload,
      color,
    },
  ).then(({ result }) => {
    dispatch(setCustomersLabels({
      labels: result,
    }));

    dispatch(loadLabelsByCustomer(CustomerID));

    if (callback) callback();
  });
};

export const updateLabelsForCustomer = (labelsIDs) => (dispatch, getState) => {
  const {
    tickets: {
      selectedTicket: {
        customer: {
          CustomerID,
        },
      },
    },
  } = getState();

  apiConnector(
    `/api/customerlabels/${CustomerID}/labels/`,
    'POST',
    {
      labelsIDs,
    },
  ).then(({ result }) => {
    dispatch(setCustomerLabels({
      customerLabels: result,
    }));
  });
};
