/* eslint-disable react/jsx-no-bind */

import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Checkbox from 'ui/Checkbox';
import Flex from 'ui/Flex';

const Contingencies = (props) => {
  const {
    control,
    checkEquality,
  } = props;

  const [optionsVisible, setOptionsVisibilty] = useState(true);

  const {
    field: {
      onChange: onChangeInspection,
      value: contingencyInspection,
    },
  } = useController({
    name: 'contingencyInspection',
    control,
  });

  const {
    field: {
      onChange: onChangeAppraisal,
      value: contingencyAppraisal,
    },
  } = useController({
    name: 'contingencyAppraisal',
    control,
  });

  const {
    field: {
      onChange: onChangeLoan,
      value: contingencyLoan,
    },
  } = useController({
    name: 'contingencyLoan',
    control,
  });

  const toggleVisibility = useCallback(() => {
    onChangeInspection(false);
    onChangeAppraisal(false);
    onChangeLoan(false);
    setOptionsVisibilty(!optionsVisible);
  }, [
    setOptionsVisibilty,
    onChangeInspection,
    onChangeAppraisal,
    onChangeLoan,
    optionsVisible,
  ]);

  const handleChangeLoan = (val) => {
    onChangeLoan(val);
    checkEquality('contingency_loan', val ? 1 : 0);
  };

  const handleChangeAppraisal = (val) => {
    onChangeAppraisal(val);
    checkEquality('contingency_appraisal', val ? 1 : 0);
  };

  const handleChangeInspection = (val) => {
    onChangeInspection(val);
    checkEquality('contingency_inspection', val ? 1 : 0);
  };

  return (
    <>
      <Checkbox
        label="No Contingencies"
        onChange={toggleVisibility}
      />
      <Flex
        alignItems="center"
        spacing={2}
        style={optionsVisible ? { visibility: 'visible' } : { visibility: 'hidden' }}
      >
        <Checkbox
          label="Inspection"
          checked={contingencyInspection}
          onChange={handleChangeInspection}
        />
        <Checkbox
          label="Appraisal"
          checked={contingencyAppraisal}
          onChange={handleChangeAppraisal}
        />
        <Checkbox
          label="Loan"
          checked={contingencyLoan}
          onChange={handleChangeLoan}
        />
      </Flex>
    </>
  );
};

const {
  shape,
  func,
} = PropTypes;

Contingencies.propTypes = {
  control: shape({}).isRequired,
  checkEquality: func.isRequired,
};

export default Contingencies;
