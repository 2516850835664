import { getConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import apiConnector from 'api';

class EmployeeDictionary {
  static getAvailability = async () => {
    try {
      const response = await apiConnector.client.get('/api/common/userAvailability');
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static getDepartments = () => getConnector('/company/departments');

  static getCountries = () => getConnector('/api/common/countries');

  static getStatess = () => getConnector('/api/common/states');

  static getPaymentType = () => getConnector('/api/common/paymentType');

  static getPaymentFrequency = () => getConnector('/api/common/paymentFrequency');

  static getHolidays = () => getConnector('/api/common/holidays');

  static getBenefits = () => getConnector('/api/common/benefits');

  static getRole = () => getConnector('/api/common/role');

  static getBloodType = () => getConnector('/api/common/bloodType');

  static getOffice = () => getConnector('/company/office');

  static getEmployeeStatuses = () => getConnector('/api/common/employeestatuses');

  static getStatuses = async () => {
    try {
      const response = await apiConnector.client.get('/api/common/employeestatuses');
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static getCounters = async () => {
    try {
      const response = await apiConnector.client.get('/api/common/employees/actions/counters');
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static getGeneralCounters = () => getConnector('/api/common/employees/actions/general-counters');
}

export default EmployeeDictionary;
