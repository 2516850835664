import apiConnector, { endpoints } from 'api';

const postRelation = (labelId) => (labeledObjectType) => async (labeledObjectId) => {
  const endpoint = `${endpoints.client.labels}/${labelId}/${labeledObjectType}/${labeledObjectId}`;

  try {
    const result = await apiConnector.client.post(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default postRelation;
