import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import validate from 'shared/utils/validators';

import Field from 'ui/Textfield';

const AgentEmail = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
    fieldState: {
      invalid,
    },
  } = useController({
    name: 'agentEmail',
    control,
    rules: {
      validate: (input) => {
        if (!input) {
          return true;
        }
        return validate.email(input);
      },
    },
  });

  return (
    <Field
      value={value}
      onChange={onChange}
      label="Email"
      error={invalid && 'Invalid email'}
    />
  );
};

const {
  shape,
} = PropTypes;

AgentEmail.propTypes = {
  control: shape({}).isRequired,
};

export default AgentEmail;
