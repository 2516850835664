import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { get } from 'shared/utility';

import {
  optimizedAttachmentsActions,
  uploadAttachment,
  deleteAttachment,
} from 'store/actions/attachmentsV2';

const withAttachmentsList = (objectType) => (attachmentOptions = {}) => (Component) => {
  const callbacks = attachmentOptions?.callbacks || {};
  const attachmentType = attachmentOptions?.attachmentType || 12;
  const selectorPath = attachmentOptions?.selectorPath || null;

  const mapStateToProps = (state, ownProps) => {
    if (!selectorPath) {
      return {
        fileList: [],
      };
    }

    const {
      objectId,
    } = ownProps;

    const attachments = get(selectors, selectorPath)(objectId)(state);

    return {
      fileList: attachments,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    onUpload: async (file) => {
      const {
        objectId,
      } = ownProps;

      const data = {
        file,
        objectId,
        objectType,
        attachmentType,
      };

      await dispatch(uploadAttachment(data));
      optimizedAttachmentsActions.getAttachmentsList(objectType, objectId);

      callbacks?.onAfterUpload && callbacks.onAfterUpload(objectId);
    },
    onRemove: async (file) => {
      const {
        objectId,
      } = ownProps;

      await dispatch(deleteAttachment(objectType, objectId, file.id));
      optimizedAttachmentsActions.getAttachmentsList(objectType, objectId);

      callbacks?.onAfterRemove && callbacks.onAfterRemove(objectId);
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onUpload: async ({ file }) => {
      if (stateProps.fileList.length > (attachmentOptions?.max || 4)) {
        return;
      }

      await dispatchProps.onUpload(file);
    },
  });

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
};

export default withAttachmentsList;
