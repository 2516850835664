import React, {
  useCallback
} from 'react';
import PropTypes, { string } from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';

import RichTextEditor from 'shared/components/RichTextEditor';
import CommentControls from 'shared/components/CommentControls';

import './styles.scss';

const commentControls = ({ onSubmit, onCancel }) => (
  <CommentControls
    onSubmit={onSubmit}
    onCancel={onCancel}
    hideCustomerVisibilitySelector
  />
);

const CommentEditModal = (props) => {
  const {
    isVisible,
    onCancel,
    editComment,
    selectedCommentText,
  } = props;

  return (
    <Modal
      visible={isVisible}
      title="Edit comment"
      onCancel={onCancel}
      footer={null}
    >
      <RichTextEditor
        value={selectedCommentText}
        onClickCancel={onCancel}
        onClickPost={editComment}
        ControlsComponent={commentControls}
      />
    </Modal>
  );
};

CommentEditModal.defaultProps = {
  isVisible: false,
  selectedCommentText: null,
};

const {
  bool,
  func,
} = PropTypes;

CommentEditModal.propTypes = {
  isVisible: bool,
  onCancel: func.isRequired,
  editComment: func.isRequired,
  selectedCommentText: string,
};

commentControls.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
};

export default CommentEditModal;
