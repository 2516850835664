import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import LeadTypeSelect from './LeadTypeSelect';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      types,
    },
  } = state;

  return {
    selectedTypes: types,
  };
};

const mapDispatchToProps = {
  onSelect: setNewLeadFormField('types'),
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadTypeSelect);
