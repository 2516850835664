export const SMALL_VIDEO_CHAT_MODAL_SHOW = 'SMALL_VIDEO_CHAT_MODAL_SHOW';
export const SMALL_VIDEO_CHAT_MODAL_HIDE = 'SMALL_VIDEO_CHAT_MODAL_HIDE';
export const LARGE_VIDEO_CHAT_MODAL_SHOW = 'LARGE_VIDEO_CHAT_MODAL_SHOW';
export const LARGE_VIDEO_CHAT_MODAL_HIDE = 'LARGE_VIDEO_CHAT_MODAL_HIDE';

export const SET_MEETINGS = 'SET_MEETINGS';
export const SET_ACTIVE_MEETING = 'SET_ACTIVE_MEETING';
export const ADD_MEETING = 'ADD_MEETING';
export const ADD_DESIRED_OUTCOME = 'ADD_DESIRED_OUTCOME';
export const UPDATE_DESIRED_OUTCOME = 'UPDATE_DESIRED_OUTCOME';

export const CLOSE_JOIN_MEETING_MODAL = 'CLOSE_JOIN_MEETING_MODAL';
export const CLOSE_MEETING_AGENDA_MODAL = 'CLOSE_MEETING_AGENDA_MODAL';
export const CLOSE_MEETING_DETAILS_MODAL = 'CLOSE_MEETING_DETAILS_MODAL';
export const OPEN_MEETING_AGENDA_MODAL = 'OPEN_MEETING_AGENDA_MODAL';
export const OPEN_MEETING_DETAILS_MODAL = 'OPEN_MEETING_DETAILS_MODAL';

export const SET_ACTIVE_MEETING_PARTICIPANTS = 'SET_ACTIVE_MEETING_PARTICIPANTS';

export default {
  SMALL_VIDEO_CHAT_MODAL_SHOW,
  SMALL_VIDEO_CHAT_MODAL_HIDE,
  LARGE_VIDEO_CHAT_MODAL_SHOW,
  LARGE_VIDEO_CHAT_MODAL_HIDE,
  SET_MEETINGS,
  SET_ACTIVE_MEETING,
  ADD_MEETING,
  ADD_DESIRED_OUTCOME,
  UPDATE_DESIRED_OUTCOME,
  CLOSE_JOIN_MEETING_MODAL,
  CLOSE_MEETING_AGENDA_MODAL,
  CLOSE_MEETING_DETAILS_MODAL,
  OPEN_MEETING_AGENDA_MODAL,
  OPEN_MEETING_DETAILS_MODAL,
  SET_ACTIVE_MEETING_PARTICIPANTS,
};
