import React from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const SubTitle = (props) => {
  const {
    children,
  } = props;

  return (
    <Typography
      variant="subtitle1"
      textTransform="uppercase"
      weight="bold"
    >
      {children}
    </Typography>
  );
};

const {
  string,
} = PropTypes;

SubTitle.propTypes = {
  children: string.isRequired,
};

export default SubTitle;
