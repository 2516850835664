import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import RealtorContactAvatar from 'shared/modules/RealtorContactAvatar';

import Typography from 'ui/Typography';

import useContactName from 'shared/hooks/contacts/useContactName';

import {
  Root,
} from './styledItems';

const SearchItemProspect = (props) => {
  const {
    role,
    client,
  } = props;

  return (
    <Root>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <RealtorContactAvatar
            size="s"
            contactId={client.contact_id}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          justify="space-around"
        >
          <Grid item>
            <Typography
              variant="title3"
              weight="bold"
              noMargin
            >
              {useContactName(client.contact_id)}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="title4"
              weight="bold"
              color="focus"
              noMargin
            >
              {role}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

SearchItemProspect.defaultProps = {
  client: {},
};

const {
  shape,
  string,
  number,
} = PropTypes;

SearchItemProspect.propTypes = {
  client: shape({
    address_id: number,
  }),
  role: string.isRequired,
};

export default SearchItemProspect;
