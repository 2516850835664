import {
  SET_TICKETS_SUMMARY_FOR_CUSTOMER,
} from 'store/actionTypes/tickets';

const setTicketsSummaryForCustomer = (customerId) => (ticketsSummary) => ({
  type: SET_TICKETS_SUMMARY_FOR_CUSTOMER,
  payload: {
    customerId,
    ticketsSummary,
  },
});

export default setTicketsSummaryForCustomer;
