import { connect } from 'react-redux';

import {
  openModal,
  closeModal,
} from 'store/actions/modals';

import {
  openNewRepairRequestModal,
} from 'store/actions/realtorRepairRequests';

import RepairRequests from './RepairRequests';

const openRepairRequestsModal = openModal('repairRequests');
const closeRepairRequestsModal = closeModal('repairRequests');

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNewClick: () => {
    dispatch(
      closeRepairRequestsModal(),
    );

    dispatch(openNewRepairRequestModal({
      transactionId: ownProps.transactionId,
      onAfterSubmit: () => {
        dispatch(openRepairRequestsModal());
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairRequests);
