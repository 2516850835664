import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import MainClientInfo from './MainClientInfo';

import {
  Root,
  StyledCardElevation,
} from './styledItems';

const ClientCard = (props) => {
  const {
    clientId,
    variant,
  } = props;

  const history = useHistory();

  const onCardClick = useCallback((event) => {
    history.push(`/clients/${clientId}`);
  }, [clientId]);

  return (
    <StyledCardElevation onClick={onCardClick}>
      <Root>
        <MainClientInfo
          clientId={clientId}
          variant={variant}
        />
      </Root>
    </StyledCardElevation>
  );
};

ClientCard.defaultProps = {
  variant: 'viewed',
};

const {
  number,
  string,
} = PropTypes;

ClientCard.propTypes = {
  clientId: number.isRequired,
  variant: string,
};

export default ClientCard;
