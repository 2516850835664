import styled from 'styled-components';
import { Avatar } from 'antd';

export const Image = styled(Avatar)`
  width: 60px;
  height: 45px;
  margin: auto;
  line-height: 45px;
  font-size: 25px;
`;

export default {
  Image,
};
