import React from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import Form from 'sections/Transactions/forms/NewRepairRequestForm';

const formId = 'new-repair-request-form';

const NewRepairRequestModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    contentOptions,
  } = props;

  return (
    <Modal
      theme={theme}
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Log repair request"
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          htmlType="submit"
          form={formId}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        {...contentOptions}
        formId={formId}
        onAfterSubmit={closeModal}
      />
    </Modal>
  );
};

NewRepairRequestModal.defaultProps = {
  isVisible: false,
  theme: null,
  contentOptions: {},
};

const {
  func,
  bool,
  shape,
} = PropTypes;

NewRepairRequestModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
  contentOptions: shape(),
};

export default NewRepairRequestModal;
