import { connect } from 'react-redux';

import TempSelect from 'sections/Leads/components/TempSelect';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      temp,
    },
  } = state;

  return {
    value: temp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (temp) => dispatch(setNewLeadFormField('temp')(temp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TempSelect);
