import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import {
  getMonthData,
} from 'sections/Calendar/utils/getMonthData';

import NewEntry from './NewEntry';

const mapStateToProps = (state) => {
  const {
    modals: {
      newCalendarEntry,
    },
  } = state;

  return {
    isVisible: newCalendarEntry?.isVisible,
    date: newCalendarEntry?.modalProps?.date,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    getMonthData();
    dispatch(closeModal('newCalendarEntry')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewEntry);
