import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import DocumentRequestsModal from './DocumentRequestsModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      documentRequestsModal,
    },
  } = state;

  return {
    isVisible: documentRequestsModal?.isVisible,
    contentOptions: documentRequestsModal?.contentOptions || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('documentRequestsModal')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRequestsModal);
