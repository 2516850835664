import requests from 'api/requests';

import createFulfillmentFromExisting from './createFulfillmentFromExisting';

// eslint-disable-next-line consistent-return
const createFulfillmentFromSelected = (fulfillmentData) => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  try {
    const fulfillmentId = await dispatch(createFulfillmentFromExisting(selectedFulfillment)(fulfillmentData));

    return fulfillmentId;
  } catch (error) {
    console.info(error);
  }
};

export default createFulfillmentFromSelected;
