import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getTransactionsForFilter from './getTransactionsForFilter';
import getInfiniteScrollTransactionsForFilter from './getInfiniteScrollTransactionsForFilter'; // eslint-disable-line import/no-cycle
import getTransactionsForStoreFilter from './getTransactionsForStoreFilter'; // eslint-disable-line import/no-cycle
import createTransaction from './create';
import deleteTransaction from './deleteTransaction';
import getTransactionById from './getTransactionById';
import setSelectedTransaction from './setSelectedTransaction';
import updateTransaction from './updateTransaction';
import updateSelectedTransaction from './updateSelectedTransaction';
import getOverallTransactionsSummary from './getOverallSummary';

import getTransactionAttachments from './getAttachments';

export {
  getTransactionById,
  createTransaction,
  deleteTransaction,
  setSelectedTransaction,
  updateTransaction,
  updateSelectedTransaction,
  getTransactionAttachments,
  getOverallTransactionsSummary,
  getTransactionsForStoreFilter,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorTransactionsActions = {
  getTransactionsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(
        getTransactionsForFilter(filterId)(filter)(offset, limit),
      ),
    debounceDelay,
    debounceOptions,
  ),
  getInfiniteScrollTransactionsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(
        getInfiniteScrollTransactionsForFilter(filterId)(filter)(offset, limit),
      ),
    debounceDelay,
    debounceOptions,
  ),
  getTransactionsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getTransactionsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getTransactionById: memoizeDebounce(
    (transactionId) => store.dispatch(getTransactionById(transactionId)),
    debounceDelay,
    debounceOptions,
  ),
  getOverallSummary: memoizeDebounce(
    () => store.dispatch(getOverallTransactionsSummary()),
    debounceDelay,
    debounceOptions,
  ),
  getAttachments: (transactionId) => store.dispatch(getTransactionAttachments(transactionId)),
};
