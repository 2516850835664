import React from 'react';
import { Row } from 'antd';
import Typography from 'ui/Typography';
import './BanPage.scss';

const BanPage = () => (
  <div className="ban-page">
    <img
      src="/assets/img/symplete-dark.png"
      alt=""
      className="ban-page__logo"
    />

    <Row
      type="flex"
      className="ban-page__content"
    >
      <Typography
        color="ghost"
        variant="h3"
        weight={500}
      >
        This IP has been permanently blocked from using Symplete.
      </Typography>
    </Row>

    <Row
      type="flex"
      className="ban-page__content"
    >
      <Typography
        color="ghost"
        variant="h3"
        weight={500}
      >
        Please contact support to proceed.
      </Typography>
    </Row>
  </div>
);

export default BanPage;
