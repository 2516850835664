import { connect } from 'react-redux';

import {
  setNewContactFormField,
  setNewContactNestedFormField,
} from 'store/actions/newRealtorContactForm';

import {
  checkExistingContact,
} from 'store/actions/realtorContacts';

// import validate from 'shared/utils/validators';

import PhoneInput from 'shared/inputs/PhoneInput';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      mainPhoneNumber,
      fieldsValidators: {
        doesPhoneExist,
      },
    },
  } = state;

  return {
    value: mainPhoneNumber,
    doesPhoneExist,
    alertText: 'Phone already exists in database.',
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('mainPhoneNumber')(
    // validate.phone(value)
    value
  )),
  setNotValidPhone: (property, value) => dispatch(setNewContactNestedFormField('fieldsValidators')(property)(value)),
  checkPhoneExistence: async (value) => {
    // const phoneNumber = validate.phone(value);
    const phoneNumber = value;

    const contact = await dispatch(checkExistingContact('phone_primary')(phoneNumber));

    return contact;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInput);
