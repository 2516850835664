import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
} from './styledItems';

const FlexItem = (props) => {
  const {
    children,
    xs,
    fullWidth,
    alignSelf,
    padding,
    gap,
    style,
    flex,
    keepSize,
    preventStretch,
  } = props;

  return (
    <Root
      xs={xs}
      fullWidth={fullWidth}
      alignSelf={alignSelf}
      padding={padding}
      gap={gap}
      style={style}
      flex={flex}
      keepSize={keepSize}
      preventStretch={preventStretch}
    >
      {children}
    </Root>
  );
};

FlexItem.defaultProps = {
  xs: null,
  fullWidth: false,
  alignSelf: 'initial',
  gap: 0,
  style: {},
  flex: null,
  preventStretch: true,
  keepSize: false,
  padding: null,
  children: null,
};

const {
  bool,
  element,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

FlexItem.propTypes = {
  xs: number,
  fullWidth: bool,
  alignSelf: string,
  padding: string,
  gap: number,
  flex: oneOfType([string, number]),
  preventStretch: bool,
  children: element,
  style: shape({}),
  keepSize: bool,
};

export default FlexItem;
