import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import CurrencyInput from 'ui/CurrencyInput';
import DateInput from 'shared/inputs/DateInput';

import PreApprovalDocuments from 'sections/Properties/modules/RequirementsPreApproval/PreApprovalDocuments';
import withPropertyRequirementData from 'sections/Properties/hocs/withPropertyRequirementData';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

const Amount = withDebouncedCallbacks(CurrencyInput, [
  ['onChange', 1000],
]);
const Lender = withDebouncedCallbacks(Textfield, [
  ['onChange', 1000],
]);
const Notes = withDebouncedCallbacks(Textfield, [
  ['onChange', 1000],
]);

const PreApprovalForm = (props) => {
  const {
    propertyRequirementId,
    propertyRequirement,

    onChange,
  } = props;

  return (
    <ErrorBoundary>
      <Flex spacing={8}>
        <FlexItem xs={16}>
          <PreApprovalDocuments
            propertyRequirementId={propertyRequirementId}
          />
        </FlexItem>
        <FlexItem xs={8}>
          <Flex
            spacing={2}
            flexWrap="wrap"
          >
            <FlexItem fullWidth>
              <Amount
                label="Amount"
                value={propertyRequirement?.pre_approval_amount}
                onChange={onChange('pre_approval_amount')}
              />
            </FlexItem>
            <FlexItem fullWidth>
              <DateInput
                decorated
                label="Expiry date"
                value={propertyRequirement?.pre_approval_expiry_date}
                handleChange={onChange('pre_approval_expiry_date')}
                onReset={() => onChange('pre_approval_expiry_date')(null)}
              />
            </FlexItem>

            <Lender
              fullWidth
              label="Lender"
              value={propertyRequirement?.pre_approval_lender}
              onChange={onChange('pre_approval_lender')}
            />

            <FlexItem fullWidth>
              <Typography variant="title3">
                Notes
              </Typography>
              <Notes
                fullWidth
                textarea
                value={propertyRequirement?.pre_approval_notes}
                onChange={onChange('pre_approval_notes')}
              />
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    </ErrorBoundary>
  );
};

PreApprovalForm.defaultProps = {
  propertyRequirement: null,
};

const {
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

PreApprovalForm.propTypes = {
  onChange: func.isRequired,
  propertyRequirementId: number.isRequired,
  propertyRequirement: shape({
    pre_approval_amount: number,
    pre_approval_lender: string,
    pre_approval_notes: string,
    pre_approval_expiry_date: oneOfType([string, number]),
  }),
};

export default withPropertyRequirementData(PreApprovalForm);
