import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateFollowUpReminder,
} from 'store/actions/followUpReminders';

import Outcome from './Outcome';

const mapStateToProps = (state, ownProps) => {
  const {
    reminderId,
  } = ownProps;

  const reminder = selectors.followUpReminders.byId(state, reminderId);

  return {
    completionComment: reminder?.completion_comment,
    isUnsuccessful: reminder?.is_unsuccessful,
    isCompleted: reminder?.status === 6,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onComplete: (comment, isUnsuccessful) => {
    dispatch(updateFollowUpReminder(ownProps.reminderId)({
      status: 6,
      completion_comment: comment,
      is_unsuccessful: isUnsuccessful,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Outcome);
