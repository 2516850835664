import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedClientActions,
} from 'store/actions/realtorClients';

export default createSelector(
  (state) => state.realtorClients.clients,
  (state, filterId) => state.realtorClients.realtorClientsByFilter[filterId],
  (items, filteredIds) => (filteredIds
    ? filteredIds.reduce((acc, id) => {
      const client = items[id];

      if (!client && id) {
        optimizedClientActions.getClientById(id);
        return acc;
      }

      acc.push({
        ...client,
        type: 'client'
      });
      return acc;
    }, [])
    : []),
);
