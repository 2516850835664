import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  .timer-flex-container {
    margin-bottom: auto;
    margin-top: auto;
  }
`;

export default {
  Root,
};
