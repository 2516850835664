import apiConnector, { endpoints } from 'api';

const getOutOfStockOrder = async (fulfillmentId) => {
  const endpoint = `${endpoints.client.warehouseOrder}/${fulfillmentId}`;

  try {
    const result = await apiConnector.client.get(endpoint, {
      status: 5,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getOutOfStockOrder;
