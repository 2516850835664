import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

export default createSelector(
  (state) => state.properties.overallPropertiesSummary,
  (summary) => {
    if (!summary) {
      optimizedPropertyActions.getOverallSummary();

      return {};
    }

    return summary;
  },
);
