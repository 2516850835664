import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withLeadData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      leadId,
    } = ownProps;

    const leadData = selectors.realtorLeads.byId(state, leadId);

    return {
      leadData,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withLeadData;
