import { connect } from 'react-redux';

import selectors from 'store/selectors';

import GroupedLabels from './GroupedLabels';

const mapStateToProps = (state, ownProps) => {
  const allContactLabels = selectors.dictionary.contactLabels(state);

  return {
    allContactLabels,
  };
};

export default connect(mapStateToProps)(GroupedLabels);
