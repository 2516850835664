import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import NewViewingForm from 'sections/MLS/forms/NewViewingForm';
import FlexItem from 'ui/FlexItem/FlexItem';

const Showing = (props) => {
  const {
    defaultDate,
    onSave,
  } = props;

  return (
    <Flex
      spacing={2}
      flexDirection="column"
      fullWidth
    >
      <NewViewingForm
        dateTime={defaultDate}
        showPropertyField
        showContactField
      />
      <Flex
        justifyContent="flex-end"
        fullWidth
        spacing={2}
      >
        <FlexItem>
          <Button
            variant="secondary"
            onClick={onSave}
          >
            Cancel
          </Button>
        </FlexItem>
        <FlexItem></FlexItem>
        <FlexItem>
          <Button
            variant="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </FlexItem>
      </Flex>
    </Flex>
  );
};

const {
  func,
  object,
} = PropTypes;

Showing.propTypes = {
  defaultDate: object.isRequired,
  onSave: func.isRequired,
};

export default Showing;
