import apiConnector, { endpoints } from 'api';

/**
 * @typedef {'task' | 'ticket' | 'lead' | 'comment' | 'log' | 'fulfillment' } CommentedObjectTypes
 */

const postComment = (commentedObjectType) => (commentedObjectId) => async (commentBody) => {
  const endpoint = `${endpoints.client.logs}/${commentedObjectType}/${commentedObjectId}`;

  try {
    const result = await apiConnector.client.post(endpoint, commentBody);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default postComment;
