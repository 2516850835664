import { createSelector } from 'reselect';

import {
  optimizedCreditNotesActions,
} from 'store/actions/creditNotes';

export default createSelector(
  (state, id) => ({
    creditNotes: state.creditNotes.creditNotes,
    id,
  }),
  ({ creditNotes, id }) => {
    if (!id) return null;

    const dataObject = creditNotes[id];

    if (!dataObject) {
      optimizedCreditNotesActions.getCreditNoteById(id);
    }

    return dataObject || {};
  },
);
