import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_PROSPECT_MODAL_OPEN,
  SET_NEW_REALTOR_PROSPECT_MODAL_CLOSED,
  SET_NEW_REALTOR_PROSPECT_MODAL_PROPERTY_REQUIREMENT,
  SET_NEW_REALTOR_PROSPECT_MODAL_ATTACHMENTS,
} from 'store/actionTypes/realtorProspects';

const initialState = {
  isVisible: false,
  propertyRequirementId: null,
  leadId: null,
  attachments: [],
};

const reducers = {
  [SET_NEW_REALTOR_PROSPECT_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    propertyRequirementId: action.payload.propertyRequirementId,
    leadId: action.payload.leadId,
  }),

  [SET_NEW_REALTOR_PROSPECT_MODAL_CLOSED]: (state) => ({
    ...initialState,
    attachments: state.attachments,
  }),

  [SET_NEW_REALTOR_PROSPECT_MODAL_PROPERTY_REQUIREMENT]: (state, action) => ({
    ...state,
    propertyRequirementId: action.payload.propertyRequirementId,
  }),

  [SET_NEW_REALTOR_PROSPECT_MODAL_ATTACHMENTS]: (state, action) => ({
    ...state,
    attachments: action.payload.filesList,
  }),
};

function newProspectModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newProspectModal;
