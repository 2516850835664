/* eslint-disable camelcase */
import { connect } from 'react-redux';

import OfferDeclineReasonForm from './OfferDeclineReasonForm';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorOffers: {
      offers,
    },
  } = state;

  const {
    offerId,
  } = ownProps;

  const {
    decline_reason,
  } = offers[offerId];

  return {
    decline_reason,
  };
};

export default connect(mapStateToProps, null)(OfferDeclineReasonForm);
