import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Selection from 'ui/Selection';

const defaultOptions = [
  {
    id: 'comment',
    label: 'Comment',
  },
  {
    id: 'question',
    label: 'Question',
    iconName: 'help-circle',
    iconColor: 'warning',
    iconType: 'feather',
  },
  {
    id: 'action',
    label: 'Action',
    iconName: 'film',
    iconType: 'feather',
  },
  {
    id: 'problem',
    label: 'Problem',
    iconName: 'alert-triangle',
    iconColor: 'alert',
    iconType: 'feather',
  },
];
const CommentTypes = (props) => {
  const {
    onTypeChange,
    selectedType,
  } = props;

  const [commentType, setCommentType] = useState('comment');

  const changeCommentType = useCallback((value) => {
    if (!onTypeChange) {
      return;
    }

    onTypeChange(value);
    setCommentType(value);
  }, [onTypeChange]);

  useEffect(() => {
    if (selectedType) {
      setCommentType(selectedType);
    }
  }, [selectedType]);

  return (
    <Selection
      options={defaultOptions}
      value={commentType}
      onChange={changeCommentType}
      radio
    />
  );
};

CommentTypes.defaultProps = {
  selectedType: null,
};

const {
  func,
  string,
} = PropTypes;

CommentTypes.propTypes = {
  onTypeChange: func.isRequired,
  selectedType: string,
};

export default CommentTypes;
