import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyImage from './PropertyImage';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const image = selectors.properties.propertyMainImage(state, propertyId);
  const property = selectors.properties.byId(state, propertyId);

  return {
    image: image?.thumbUrl,
    isMyListing: property?.listing_agent === 0,
  };
};

export default connect(mapStateToProps)(PropertyImage);
