import { createSelector } from 'reselect';

import {
  optimizedCustomerInformedActions,
} from 'store/actions/customerInformed';

export default createSelector(
  (state, id) => ({
    events: state.customerInformed.events,
    id,
  }),
  ({ events, id }) => {
    if (!id) return null;

    const dataObject = events[id];

    if (!dataObject) {
      optimizedCustomerInformedActions.getCustomerInformedById(id);
    }

    return dataObject || {};
  },
);
