import requests from 'api/requests';

import setAgreement from './setAgreement';
import getAgreementAttachments from './getAgreementAttachments';

const getAgreementById = (id) => async (dispatch) => {
  try {
    const response = await requests.agreements.getById(id);
    dispatch(setAgreement(response.data));
    dispatch(getAgreementAttachments(id));
  } catch (error) {
    console.error(error);
  }
};

export default getAgreementById;
