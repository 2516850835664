import styled from 'styled-components';
import Button from 'ui/Button';

export const StyledButton = styled(Button)`
&& {
  background-color: ${(props) => props.theme.palette.dialButton};

  &:hover:enabled{
    background-color: ${(props) => props.theme.palette.dialButtonHover || props.theme.palette.dialButton
    };
  }
}
`;

export default {
  StyledButton,
};
