import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getProspectsForFilter from './getProspectsForFilter';

const getProspectsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.realtorProspects.prospectsFilter(getState());

  try {
    await dispatch(getProspectsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getProspectsForStoreFilter;
