import apiConnector, { endpoints } from 'api';

const addRelations = (relationType) => (id) => async (data) => {
  const endpoint = `${endpoints.client.realtor.propertyRequirementRelations}/${relationType}/${id}`;

  try {
    await apiConnector.client.patch(endpoint, data);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default addRelations;
