/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'shared/components/UserAvatarV2';
import { userType } from 'oa/types/index';
import Modal from 'oa/components/Modal';
import Button from 'oa/components/Button';
import './style.css';

const IncomingCall = (props) => {
  const {
    caller,
    onCancel,
    onOk,
  } = props;

  const callerName = caller && caller.Name;
  const callerId = (caller && caller.EmployeeID) || null;
  const callerRole = caller && caller.Role;

  const title = `${callerName} is calling...`;

  return (
    <Modal
      isOpen={!!caller}
      onClose={onCancel}
      overlayClassName="incoming-call-modal"
      className="incoming-call-modal__inner"
      size="sm"
    >
      <Modal.Content
        className="incoming-call-modal__content"
      >
        <Modal.Title className="incoming-call-modal__title">
          {title}
        </Modal.Title>

        <div className="incoming-call-modal__caller-info">
          <UserAvatar
            size={100}
            className="incoming-call-modal__caller-photo"
            userId={callerId}
            userType={1}
            noBadge
          />

          <b className="incoming-call-modal__caller-name">
            {callerName}
          </b>

          <span className="incoming-call-modal__caller-role">
            {callerRole}
          </span>
        </div>

        <p className="incoming-call-modal__footer">
          <Button
            type="tertiary_default"
            onClick={onCancel}
            className="incoming-call-modal__cancel"
          >
            Not now, I’m busy
          </Button>

          <Button
            onClick={onOk}
            className="incoming-call-modal__ok"
          >
            Join Call
          </Button>
        </p>
      </Modal.Content>
    </Modal>
  );
};

IncomingCall.propTypes = {
  caller: userType,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

export default IncomingCall;
