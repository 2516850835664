import { connect } from 'react-redux';

import {
  closeNewContactLogModal,
} from 'store/actions/newRealtorContactLogModal';

import NewLogModal from './NewLogModal';

const mapStateToProps = (state) => {
  const {
    realtorNewContactLogModal: {
      isVisible,
    },
  } = state;

  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeNewContactLogModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLogModal);
