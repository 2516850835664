import { connect } from 'react-redux';

import NewContactArea from 'shared/inputs/NewContactArea';

const mapStateToProps = (state) => {
  const {
    realtorNewPropertyForm: {
      newSellerAgent,
    },
  } = state;

  const values = {
    ...newSellerAgent,
    personTitle: newSellerAgent?.person_title,
  };

  return {
    values,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(NewContactArea);
