import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import NewContactModal from 'sections/Contacts/modals/NewContactModal';

const AddContact = (props) => {
  const {
    onSuccess,
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  const handleSuccess = useCallback((newContactId) => {
    onSuccess(newContactId);
    setIsVisible(false);
  }, [
    onSuccess,
  ]);

  return (
    <>
      <Button
        variant="primary"
        noWrap
        onClick={() => setIsVisible(true)}
      >
        Create New
      </Button>

      <NewContactModal
        isVisible={isVisible}
        closeModal={() => setIsVisible(false)}
        onSuccess={handleSuccess}
      />
    </>
  );
};

AddContact.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

AddContact.propTypes = {

};

export default AddContact;
