import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchItemProperty from 'shared/components/UI/SearchItemProperty';
import SearchCategoryBlock from 'shared/components/UI/SearchCategoryBlock';

import withPropertyData from 'sections/Properties/hocs/withPropertyData';

const SearchItemPropertyModule = withPropertyData(SearchItemProperty);

const PropertiesSearch = (props) => {
  const [searchResult, setSearchResult] = useState([]);

  const {
    search,
    onSearch,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const data = await onSearch(search);
      setSearchResult(data);
    };

    fetchData();
  }, [search]);

  return (
    <SearchCategoryBlock
      title="properties"
      iconName="home"
    >
      {
        searchResult && searchResult.map((property) => (
          <SearchItemPropertyModule
            propertyId={property.id}
          />
          ))
      }
    </SearchCategoryBlock>
  );
};

PropertiesSearch.defaultProps = {
  search: '',
};

const {
  func,
  string,
} = PropTypes;

PropertiesSearch.propTypes = {
  search: string,
  onSearch: func.isRequired,
};

export default PropertiesSearch;
