import Todos from './components/Todos';
import CreationForm from './components/CreationForm';
import List from './components/List';
import Item from './components/Item';
import './style.css';

Todos.CreationForm = CreationForm;
Todos.List = List;
Todos.Item = Item;

export default Todos;
