import { connect } from 'react-redux';

import searchProspects from 'store/actions/realtorProspects/searchProspects';

import ProspectsSearch from 'shared/components/UI/RealtorMasterSearch/ProspectsSearch';

const mapDispatchToProps = (dispatch) => ({
  onSearch: async (searchQuery) => {
    if (!searchQuery) {
      return [];
    }
    const result = await dispatch(searchProspects({ search: searchQuery }));

    return result;
  },
});

export default connect(null, mapDispatchToProps)(ProspectsSearch);
