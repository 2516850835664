/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Spin } from 'antd';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import CounterPrice from 'sections/Opportunities/modules/CounterPrice';
import AdditionalNotesInput from 'sections/Opportunities/components/AdditionalNotesInput';
import DateArea from './DateArea';
import Contingencies from './Contingencies';
import AttachmentsUpload from './AttachmentsUpload';
import PersonItem from './PersonItem';
import SpecialConditions from './SpecialConditions';

import {
  SectionWrapper,
  MarginContainer,
  SpaceBox,
} from './styledItems';

const AcceptCounterForm = (props) => {
  const {
    onSubmit: propsOnSubmit,
    buyerId,
    sellerId,
    buyerAgentId,
    sellerAgentId,
    address,
    formId,
    offer,
    contingencies,
    specialConditions,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await propsOnSubmit({ ...data });
    reset();
    setIsLoading(false);
  }, [
    propsOnSubmit,
    reset,
  ]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Spin spinning={isLoading} delay={600}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="home"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <Typography
                  noMargin
                  variant="h4"
                  weight={600}
                >
                  {address}
                </Typography>
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="tag"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <CounterPrice
                  value={offer.amount}
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          {
            buyerId && (
              <Grid item xs={12}>
                <DecoratedFieldWrapper
                  iconName="user-tie"
                  variant="highlight"
                  noMargin
                >
                  <SectionWrapper>
                    <PersonItem
                      contactId={buyerId}
                      label={{ title: 'buyer', color: '#367BED' }}
                    />
                  </SectionWrapper>
                </DecoratedFieldWrapper>
              </Grid>
            )
          }

          {
            (buyerAgentId || buyerAgentId === 0) && (
              <Grid item xs={12}>
                <DecoratedFieldWrapper
                  iconName="user-tie"
                  variant="highlight"
                  noMargin
                >
                  <SectionWrapper>
                    <PersonItem
                      isCurrentUser={buyerAgentId === 0}
                      contactId={buyerAgentId}
                      label={{ title: 'buyer agent', color: '#9013FE' }}
                    />
                  </SectionWrapper>
                </DecoratedFieldWrapper>
              </Grid>
            )
          }

          {
            sellerId && (
              <Grid item xs={12}>
                <DecoratedFieldWrapper
                  iconName="user-tie"
                  variant="highlight"
                  noMargin
                >
                  <SectionWrapper>
                    <PersonItem
                      contactId={sellerId}
                      label={{ title: 'seller', color: '#77a8f8' }}
                    />
                  </SectionWrapper>
                </DecoratedFieldWrapper>
              </Grid>
            )
          }

          {
            (sellerAgentId || sellerAgentId === 0) && (
              <Grid item xs={12}>
                <DecoratedFieldWrapper
                  iconName="user-tie"
                  variant="highlight"
                  noMargin
                >
                  <SectionWrapper>
                    <PersonItem
                      isCurrentUser={sellerAgentId === 0}
                      contactId={sellerAgentId}
                      label={{ title: 'seller agent', color: '#FF526B' }}
                    />
                  </SectionWrapper>
                </DecoratedFieldWrapper>
              </Grid>
            )
          }

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="search"
              variant="highlight"
              noMargin
              align="flex-start"
            >
              <SectionWrapper>
                <Contingencies
                  contingenciesTypes={contingencies}
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="clock"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <DateArea
                  value={offer.contingency_period_end}
                  label="Contingency Period"
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="calendar-day"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <DateArea
                  value={offer.closing_date}
                  label="Closing Date"
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="hourglass-end"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <DateArea
                  value={offer.expiry_date}
                  label="Expiry Date"
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="clipboard-list"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <Typography
                  weight="bold"
                  className="area-title"
                  tag="span"
                  noMargin
                >
                  {'Special Conditions: '}
                </Typography>
              </SectionWrapper>
            </DecoratedFieldWrapper>

            <MarginContainer>
              <SpecialConditions specialConditions={specialConditions} />
            </MarginContainer>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="people-carry"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <DateArea
                  value={offer.move_out_date}
                  label="Move Out Date"
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="file-alt"
              variant="highlight"
              noMargin
              align="flex-start"
              iconMarginTop="5px"
            >
              <SectionWrapper>
                <AttachmentsUpload />
              </SectionWrapper>
            </DecoratedFieldWrapper>

            <SpaceBox />

            <MarginContainer>
              <AdditionalNotesInput control={control} />
            </MarginContainer>
          </Grid>

        </Grid>
      </Spin>
    </form>
  );
};

AcceptCounterForm.defaultProps = {
  address: '',
  price: null,
};

const {
  bool,
  func,
  string,
  number,
} = PropTypes;

AcceptCounterForm.propTypes = {
  onSubmit: func.isRequired,
  price: number,
  address: string,
  formId: string.isRequired,
  buyerId: number.isRequired,
  buyerAgentId: number.isRequired,
};

export default AcceptCounterForm;
