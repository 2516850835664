import requests from 'api/requests/index';

const createInvitations = ({ meetingId, payload }) => async () => {
  try {
    await requests.meetings.createInvitations({ meetingId, payload });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default createInvitations;
