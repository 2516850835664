import apiConnector, { endpoints } from 'api';

/**
 * Change user system permission
 * @param {string} value new system permission
 * @param {number} userId userId
 * */

const changeSystemPermission = async (value, userId) => {
  const endpoint = `${endpoints.client.user.systemPermission}/${userId}`;

  await apiConnector.client.put(endpoint, { value });
};

export default changeSystemPermission;
