import { connect } from 'react-redux';

import selectors from 'store/selectors';

import EditModal from './EditModal';

const mapStateToProps = (state, ownProps) => {
  const allContactLabels = selectors.dictionary.contactLabels(state);

  const options = allContactLabels.map((item) => ({
    value: item.id,
    label: item.name,
    category: item.category === 'custom' ? 'my labels' : item.category,
  }));

  return {
    options,
  };
};

export default connect(mapStateToProps)(EditModal);
