import {
  useState,
  useCallback,
  useEffect,
} from 'react';

import handleDrafts from './handleDrafts';

const useModalsDrafts = (modalName) => {
  const [draftData, setDraftData] = useState(null);

  const getDraftData = useCallback(() => {
    setDraftData(handleDrafts.get(modalName));
  }, [
    modalName,
  ]);

  const updateDraftData = useCallback((data, add = false) => {
    if (add) {
      handleDrafts.add(modalName, data);
    } else {
      handleDrafts.set(modalName, data);
    }

    getDraftData();
  }, [
    modalName,
    getDraftData,
  ]);

  const clearDraftData = useCallback((withTimeout = false) => {
    if (withTimeout) {
      setTimeout(() => {
        handleDrafts.clear(modalName);
        getDraftData();
      }, 1000);
    } else {
      handleDrafts.clear(modalName);
      getDraftData();
    }
  }, [
    modalName,
    getDraftData,
  ]);

  useEffect(() => {
    getDraftData();
  }, [
    modalName,
  ]);

  return {
    draftData,
    saveDraft: updateDraftData,
    clearDraft: clearDraftData,
  };
};

export default useModalsDrafts;
