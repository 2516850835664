import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedProcessesActions,
} from 'store/actions/serviceProcesses';

import { // eslint-disable-line import/no-cycle
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

import { // eslint-disable-line import/no-cycle
  optimizedLineItemsActions,
} from 'store/actions/line-items';

export default createSelector(
  (state) => state.serviceProcesses.serviceProcesses,
  (state) => state.lineItems.lineItems,
  (state) => state.crmProducts.products,
  (_, id) => id,
  (collection, lineItems, crmProducts, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedProcessesActions.getProcessById(id);
      }

      return '';
    }

    const products = item.line_items.reduce((acc, current) => {
      const lineItem = lineItems[current];

      if (!lineItem) {
        optimizedLineItemsActions.getLineItemById(current);

        return acc;
      }

      const lineItemProduct = crmProducts[lineItem.product_id];

      if (!lineItemProduct) {
        optimizedCrmProductsActions.getProductById(lineItem.product_id);

        return acc;
      }

      const { name } = lineItemProduct;

      if (!acc[name]) {
        acc[name] = 1;
      } else {
        acc[name] += 1;
      }

      return acc;
    }, {});

    const title = Object.keys(products).reduce((acc, current, index) => {
      acc += `${current} × ${products[current]}`; // eslint-disable-line no-param-reassign

      if (index !== Object.keys(products).length - 1) {
        acc += ', '; // eslint-disable-line no-param-reassign
      }

      return acc;
    }, '');

    return title;
  },
);
