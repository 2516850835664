import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tutorial from '../shared/Tutorial';

import tutorialRoutes from '../tutorialRoutes';

import './GeneralTutorial.scss';

const GeneralTutorial = (props) => {
  const { isVisible } = props;
  const openEmployeeModal = () => {
    const {
      showEmployeeGeneralModal,
    } = props;
    if (!isVisible) showEmployeeGeneralModal();
  };

  const closeEmployeeModal = () => {
    const {
      showEmployeeGeneralModal,
    } = props;
    if (isVisible) showEmployeeGeneralModal();
  };

  const goToStep = useCallback((index) => {
    const {
      startTutorial,
      endTutorial,
      setStepIndex,
      history: {
        push,
      },
      steps,
    } = props;

    const stepActions = {
      'tutorial-reset-password': () => {
        if (!isVisible) {
          push('/team/employees');
          openEmployeeModal();
          endTutorial();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        setTimeout(() => {
          const name = document.querySelector('[data-tutorial="employee-info"] .employee-name');
          const status = document.querySelector('[data-tutorial="delete-user"]');
          const button = document.querySelector('[data-tutorial="reset-pass-create-user"]');
          name.innerText = 'Demo Employee';
          status.innerText = 'Employed ⌄';
          button.innerText = 'Actions';
        }, 100);
        window.history.pushState(null, null, tutorialRoutes.general.resetPassword);
      },
      'tutorial-delete-user': () => {
        if (!isVisible) {
          push('/team/employees');
          openEmployeeModal();
          endTutorial();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        setTimeout(() => {
          const name = document.querySelector('[data-tutorial="employee-info"] .employee-name');
          const status = document.querySelector('[data-tutorial="delete-user"]');
          const button = document.querySelector('[data-tutorial="reset-pass-create-user"]');
          name.innerText = 'Demo Employee';
          status.innerText = 'Employed ⌄';
          button.innerText = 'Actions';
        }, 100);
        window.history.pushState(null, null, tutorialRoutes.general.deleteUser);
      },
      'tutorial-assign-remove-licenses': () => {
        if (!isVisible) {
          push('/team/employees');
          openEmployeeModal();
          endTutorial();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        setTimeout(() => {
          const name = document.querySelector('[data-tutorial="employee-info"] .employee-name');
          const status = document.querySelector('[data-tutorial="delete-user"]');
          const button = document.querySelector('[data-tutorial="reset-pass-create-user"]');
          name.innerText = 'Demo Employee';
          status.innerText = 'Employed ⌄';
          button.innerText = 'Actions';
        }, 100);
        window.history.pushState(null, null, tutorialRoutes.general.removeLicenses);
      },
      'tutorial-create-user': () => {
        if (!isVisible) {
          push('/team/employees');
          openEmployeeModal();
          endTutorial();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        setTimeout(() => {
          const name = document.querySelector('[data-tutorial="employee-info"] .employee-name');
          const status = document.querySelector('[data-tutorial="delete-user"]');
          const button = document.querySelector('[data-tutorial="reset-pass-create-user"]');
          name.innerText = 'Demo Employee';
          status.innerText = 'Employed ⌄';
          button.innerText = 'Actions';
        }, 100);
        setTimeout(() => {
          const button = document.querySelector('[data-tutorial="reset-pass-create-user"]');
          button.innerText = 'Non User - Make User';
        }, 300);
        window.history.pushState(null, null, tutorialRoutes.general.createUser);
      },
      'tutorial-buy-cancel-licenses': () => {
        push('/company-settings/user-management');
        closeEmployeeModal();
        endTutorial();
        startTutorial();
        window.history.pushState(null, null, tutorialRoutes.general.buyCancelLicenses);
      },
    };

    const currentStep = steps[index].name;

    stepActions[currentStep]();

    setStepIndex(index);
  }, [isVisible]);

  const finishTutorial = useCallback(() => {
    const {
      endTutorial,
      hideTutorialLayer,
      history: {
        push,
      },
      setStepIndex,
    } = props;
    endTutorial();
    setStepIndex(0);
    closeEmployeeModal();
    hideTutorialLayer();
    push('/company-settings');
  }, [isVisible]);

  const {
    stepName,
    steps,
  } = props;

  return (
    <Tutorial
      className="system-tutorial"
      stepName={stepName}
      steps={steps}
      goToStep={goToStep}
      finishTutorial={finishTutorial}
      name="System"
    />
  );
};

const {
  any,
  bool,
  func,
  string,
  objectOf,
  arrayOf,
} = PropTypes;

GeneralTutorial.defaultProps = {
  isVisible: false,
  stepName: '',
};

GeneralTutorial.propTypes = {
  history: objectOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
  startTutorial: func.isRequired,
  endTutorial: func.isRequired,
  hideTutorialLayer: func.isRequired,
  isVisible: bool,
  showEmployeeGeneralModal: func.isRequired,
  stepName: string,
  setStepIndex: func.isRequired,
  steps: arrayOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
};

export default GeneralTutorial;
