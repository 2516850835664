import connectReducers from 'store/connectReducers';

// import testData from 'store/testData/realtor/property-requirements';

import {
  SET_PROPERTY_REQUIREMENT,
  SET_PROPERTY_REQUIREMENTS,
} from 'store/actionTypes/property-requirements';

import {
  listToObjectByKey,
  // getIds,
} from 'store/utils';

const initialState = {
  requirements: {},
};

const reducers = {
  [SET_PROPERTY_REQUIREMENT]: (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_PROPERTY_REQUIREMENTS]: (state, action) => ({
    ...state,
    requirements: {
      ...state.requirements,
      ...listToObjectByKey(action.payload),
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
