import requests from 'api/requests';

import setFulfillmentChangelog from './setFulfillmentChangelog';

const getFulfillmentChangelog = (fulfillmentId) => (from, to) => async (dispatch) => {
  try {
    const response = await requests.fulfillments.getChangelog(fulfillmentId)(from, to);

    dispatch(setFulfillmentChangelog({
      fulfillmentId,
      changelog: response.data,
    }));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentChangelog;
