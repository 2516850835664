import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import NewSourceForm from 'shared/components/CRM/Widgets/NewSourceForm';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      newCustomSourceName,
      sourceDescription,
    },
  } = state;

  return {
    sourceName: newCustomSourceName || '',
    sourceDescription: sourceDescription || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onNameChange: (sourceName) => {
    dispatch(setNewLeadFormField('newCustomSourceName')(sourceName));
  },
  onDescriptionChange: (sourceDescription) => {
    dispatch(setNewLeadFormField('sourceDescription')(sourceDescription));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSourceForm);
