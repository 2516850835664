import React from 'react';
import PropTypes from 'prop-types';

import StyleButton from '../StyleButton/StyleButton';
import { INLINE_STYLES } from '../../config';

const InlineStyleControls = (props) => {
  const { editorState } = props;
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <>
      {INLINE_STYLES.map((type) =>
        // eslint-disable-next-line
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />)}
    </>
  );
};

const {
  func,
  objectOf,
  any,
} = PropTypes;

InlineStyleControls.propTypes = {
  editorState: objectOf(any).isRequired,
  onToggle: func.isRequired,
};

export default InlineStyleControls;
