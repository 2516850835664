/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import { share } from 'store/actions/realtorOffers';

import LogOfferModal from './LogOfferModal';

const titles = {
  client: 'Log new offer',
  prospect: 'Make new offer',
};

const labels = {
  client: 'Log offer',
  prospect: 'Make offer',
};

const mapStateToProps = (state) => {
  const {
    modals: {
      logOfferModal,
    },
  } = state;

  const title = titles[logOfferModal?.contentOptions?.type] || 'Log new offer';
  const label = labels[logOfferModal?.contentOptions?.type] || 'Log offer';

  return {
    isVisible: logOfferModal?.isVisible,
    title,
    label,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('logOfferModal')()),
  shareOffer: (offerId) => (emails) => {
    dispatch(share(offerId)(emails));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LogOfferModal);
