/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Spin } from 'antd';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import Budget from './Budget';
import ListingDates from './ListingDates';
// import ListingTerms from './ListingTerms';
// import Commission from './Commission';
// import HoldOverPeriod from './HoldOverPeriod';
import AttachmentsUpload from './AttachmentsUpload';

const NewProspectForm = (props) => {
  const {
    onSubmit: propsOnSubmit,
    address,
    budget,
    formId,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [commissionTypes, setCommissionTypes] = useState({
    seller_agent_commission: 1,
    buyer_agent_commission: 1,
    coop_commission: 1,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await propsOnSubmit({
      ...data,
      seller_agent_commission_type: commissionTypes.seller_agent_commission,
      buyer_agent_commission_type: commissionTypes.buyer_agent_commission,
      coop_commission_type: commissionTypes.coop_commission,
    });
    reset();
    setIsLoading(false);
  }, [
    propsOnSubmit,
    reset,
    commissionTypes,
  ]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Spin spinning={isLoading} delay={600}>
        <Flex spacing={4} flexWrap="wrap">
          <Typography
            noMargin
            variant="h4"
            weight={600}
            tag="ul"
            parseHtml
          >
            {address}
          </Typography>

          <FlexItem fullWidth>
            <Budget
              control={control}
              errors={errors}
              value={budget}
            />
          </FlexItem>

          <ListingDates
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
          />

          {/* <ListingTerms
            control={control}
          />

          <Commission
            control={control}
            errors={errors}
            commissionTypes={commissionTypes}
            onTypeChange={setCommissionTypes}
          /> */}

          <FlexItem fullWidth>
            <AttachmentsUpload />
          </FlexItem>

          {/* <HoldOverPeriod
            control={control}
          /> */}
        </Flex>
      </Spin>
    </form>
  );
};

NewProspectForm.defaultProps = {
  address: '',
  budget: null,
};

const {
  func,
  string,
  number,
} = PropTypes;

NewProspectForm.propTypes = {
  onSubmit: func.isRequired,
  onPropertyRequirementSelect: func.isRequired,
  contactName: string.isRequired,
  leadId: number.isRequired,
  propertyRequirementId: number.isRequired,
  budget: number,
  address: string,
  formId: string.isRequired,
};

export default NewProspectForm;
