import apiConnector, { endpoints } from 'api';

const addChatMember = (chatId) => async ({ userType, userId }) => {
  const endpoint = `${endpoints.client.chat.members}/${chatId}`;

  const result = await apiConnector.client.put(endpoint, { user_type: userType, user_id: userId });
  return result;
};

export default addChatMember;
