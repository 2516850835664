import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getLeadsForFilter from './getLeadsForFilter';

const getLeadsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.crm.leadsFilter(getState());

  try {
    await dispatch(getLeadsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getLeadsForStoreFilter;
