import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import TicketIcons from 'shared/components/TicketIcons';
import CommentActionPanel from 'shared/components/CommentActionPanel';

import UserAvatar from 'shared/components/UserAvatarV2';
import CommentBody from 'shared/components/CommentBodyV2';
import CommentReply from 'shared/components/CommentReplyV2';

import Flex from 'ui/Flex';
import Modal from 'ui/Modal';
import Button from 'ui/Button'

import './Comment.scss';

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolveModalVisible: false,
    };
  }

  triggerResolveModal = () => {
    this.setState((state) => ({
      resolveModalVisible: !state.resolveModalVisible,
    }));
  }

  onClickResolve = () => {
    const { onClickResolve } = this.props;
    this.triggerResolveModal();
    onClickResolve();
  }

  render() {
    const {
      acknowledgedLabel,
      className,
      commentActionPanelProps,
      commentBodyProps,
      commentReplyProps,
      createdAt,
      creator,
      isAcknowledged,
      isOwner,
      isResolved,
      onClick,
      onClickDelete,
      onClickEdit,
      onClickLike,
      onClickReplyCount,
      onClickThread,
      replyCount,
      replyCreators,
      resolvedLabel,
      status,
      text,
      ticketIconsProps,
      title,
      type,
    } = this.props;

    const { resolveModalVisible } = this.state;

    const hasReplies = replyCount > 0;

    return (
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, max-len
        className={classnames(
          'comment-root',
          {
            [className]: className,
          },
        )}
        onClick={onClick}
      >
        <Flex 
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            spacing={2}
            alignItems="center"
          >
            {type && !isResolved
              && (
                <TicketIcons
                  events={
                    {
                      problem: type === 'problem',
                      action: type === 'action',
                      question: type === 'question',
                    }
                  }
                  size="sm"
                  onClick={this.triggerResolveModal}
                  {...ticketIconsProps} // eslint-disable-line react/jsx-props-no-spreading
                />
              )}

            <UserAvatar
              userId={creator.id}
              userType={creator.type}
              size="xs"
              noBadge
            />
            <CommentBody
              commentText={text}
              confirmedText={acknowledgedLabel}
              createdAt={createdAt}
              resolvedText={resolvedLabel}
              status={status}
              name={creator.name}
              surname={creator.surname}
              title={title}
              {...commentBodyProps} // eslint-disable-line react/jsx-props-no-spreading
            />
          </Flex>
          <Flex alignItems="center">
            {
              hasReplies && (
                <CommentReply
                  className="comment-comment-reply"
                  commentators={replyCreators}
                  onClickReplyCount={onClickReplyCount}
                  replyCount={replyCount}
                  {...commentReplyProps} // eslint-disable-line react/jsx-props-no-spreading
                />
              )
            }
            <CommentActionPanel
              className="comment-action-panel-visible"
              isOwner={isOwner}
              isConfirmed={isAcknowledged}
              onClickLike={onClickLike}
              onClickThread={onClickThread}
              onClickDelete={onClickDelete}
              onClickEdit={onClickEdit}
              {...commentActionPanelProps} // eslint-disable-line react/jsx-props-no-spreading
            />
          </Flex>
        </Flex>
        <Modal
          title={`Resolve this ${type}?`}
          visible={resolveModalVisible}
          centered
          onCancel={this.onClickResolve}
          footer={[
            <Button
              onClick={this.triggerResolveModal}
              variant="secondary"
            >
              No
            </Button>,
            <Button
              variant="primary"
              onClick={this.onClickResolve}
            >
              Yes
            </Button>,
          ]}
        />
      </div>
    );
  }
}

const noOp = () => { };

Comment.defaultProps = {
  acknowledgedLabel: null,
  className: '',
  commentActionPanelProps: {},
  commentBodyProps: {},
  commentReplyProps: {},
  confirmedText: '',
  createdAt: '',
  isAcknowledged: false,
  isOwner: false,
  isResolved: false,
  onClick: noOp,
  onClickDelete: null,
  onClickEdit: null,
  onClickLike: null,
  onClickReplyCount: noOp,
  onClickResolve: noOp,
  onClickThread: null,
  replyCount: 0,
  replyCreators: [],
  resolvedLabel: '',
  status: '',
  text: '',
  ticketIconsProps: {},
  title: '',
  type: null,
};

const {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

const creatorShape = shape({
  id: number,
  type: number,
  avatar: string,
  name: string,
  surname: string,
});

Comment.propTypes = {
  isAcknowledged: bool,
  acknowledgedLabel: string,
  className: string,
  commentActionPanelProps: object, // eslint-disable-line react/forbid-prop-types
  commentBodyProps: object, // eslint-disable-line react/forbid-prop-types
  commentReplyProps: object, // eslint-disable-line react/forbid-prop-types
  confirmedText: string,
  createdAt: string,
  creator: oneOfType([creatorShape]).isRequired,
  isOwner: bool,
  isResolved: bool,
  onClick: func,
  onClickDelete: func,
  onClickEdit: func,
  onClickLike: func,
  onClickReplyCount: func,
  onClickResolve: func,
  onClickThread: func,
  replyCount: number,
  replyCreators: arrayOf(creatorShape),
  resolvedLabel: string,
  status: string,
  text: string,
  ticketIconsProps: object, // eslint-disable-line react/forbid-prop-types
  title: string,
  type: oneOf(['problem', 'action', 'question']),
};

export default Comment;
