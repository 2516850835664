import requests from 'api/requests';

import getById from './getById';

const update = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.warehouse.updatePackage(id)(updateData);

    dispatch(getById(id));
  } catch (error) {
    console.error(error);
  }
};

export default update;
