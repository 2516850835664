import { connect } from 'react-redux';

import { callAlert } from 'ui/Alert';

import { isEmpty } from 'shared/utility';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import selectors from 'store/selectors';

import {
  setNewClientModalProperty,
  closeNewClientModal,
  setNewClientModalAttachments,
} from 'store/actions/newRealtorClientModal';

import {
  updateProperty,
} from 'store/actions/properties';

import {
  createClient,
} from 'store/actions/realtorClients';

import removeLeadRelations from 'store/actions/realtorLeads/removeLeadRelations';

import {
  // optimizedAttachmentsActions,
  uploadAttachment,
} from 'store/actions/attachmentsV2';

import NewClientForm from './NewClientForm';

const mapStateToProps = (state) => {
  const {
    realtorNewClientModal: {
      propertyId,
      leadId,
      attachments,
    },
  } = state;

  const property = selectors.properties.byId(state, propertyId);
  const address = selectors.addresses.byId(state, property.address_id);

  const lead = selectors.realtorLeads.byId(state, leadId);
  const contact = selectors.realtorContacts.byId(state, lead.contact_id);

  return {
    propertyId,
    leadId,
    attachments,
    contactId: lead.contact_id,
    address: address?.address,
    contactName: `${contact.first_name} ${contact.last_name}`,
    propertyPrice: property.expected_price,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPropertySelect: () => (propertyId) => {
    dispatch(setNewClientModalProperty(propertyId));
  },
  onSubmit: async (clientData, attachments) => {
    const newClientId = await dispatch(createClient(clientData));

    await dispatch(updateProperty(clientData.realtor_property_id)({
      // status: 2,
      listing_agent: 0,
      seller: clientData?.contact_id,
    }));

    if (clientData?.leadId) {
      await dispatch(removeLeadRelations('properties')(clientData.leadId)([clientData.realtor_property_id]));
    }

    if (!isEmpty(attachments)) {
      const uploadAttachments = async (index) => {
        const attachment = attachments[index];

        const {
          name,
          type,
          url,
        } = attachment;

        const file = await dataUrlToFile(url, name, type);
        const data = {
          file,
          objectId: newClientId,
          objectType: 29, // realtor client
          attachmentType: 7, // agreement
        };

        await dispatch(uploadAttachment(data, false));

        URL.revokeObjectURL(url);

        const nextIndex = index + 1;
        if (nextIndex < attachments.length) {
          await uploadAttachments(nextIndex);
        }
      };

      try {
        // doing recursion here because Promise.all is not suitable for images uploading
        // to AWS in this case
        await uploadAttachments(0);

        // optimizedAttachmentsActions.getAttachmentsList(propertyObjectType, propertyId);
      } catch (error) {
        console.error(error);
        callAlert.error('Could not upload images');
      }

      dispatch(setNewClientModalAttachments([]));
    }

    callAlert.success('Created successfully');

    dispatch(closeNewClientModal());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onPropertySelect: dispatchProps.onPropertySelect,
  onSubmit: async (data) => {
    const {
      propertyId,
      contactId,
      attachments,
      leadId,
    } = stateProps;

    await dispatchProps.onSubmit(
      {
        ...data,
        contact_id: contactId,
        realtor_property_id: propertyId,
        leadId,
      },
      attachments,
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewClientForm);
