import connectReducers from 'store/connectReducers';

import HRTypes from 'store/actionTypes/HRTypes';

const initialState = {
  sickEmployeeHours: 0,
  holidaysEmployeeHours: 0,
  unprocessedEmployeesCount: 0,
  sickEmployeesCount: 0,
  holidayEmployeesCount: 0,
};

const reducers = {
  [HRTypes.GET_HR_DASHBOARD_INf]: (state, action) => ({
    ...state,
    sickEmployeeHours: action.sickEmployeeHours,
    holidaysEmployeeHours: action.holidaysEmployeeHours,
    unprocessedEmployeesCount: action.unprocessedEmployeesCount,
    sickEmployeesCount: action.sickEmployeesCount,
    holidayEmployeesCount: action.holidayEmployeesCount,
    presentEmployeeCount: action.presentEmployeeCount,
  }),
};


function HR(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default HR;
