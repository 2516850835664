import { createSelector } from 'reselect';

import {
  isEmpty,
  map,
} from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorInspectionsTypesActions,
} from 'store/actions/realtorInspectionsTypes';

export default createSelector(
  (state) => state.realtorInspections.inspectionsTypes,
  (inspectionTypes) => {
    if (isEmpty(inspectionTypes)) {
      optimizedRealtorInspectionsTypesActions.getInspectionTypes();
      return [];
    }

    const result = map(inspectionTypes, (item) => item);

    return result;
  },
);
