import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getMatchedForFilter from './getMatchedForFilter';
import getInfiniteScrollMatchedForFilter from './getInfiniteScrollMatchedForFilter'; // eslint-disable-line import/no-cycle
import createMatching from './create';
import deleteMatching from './deleteMatching';
import getMatchingById from './getMatchingById';
import updateMatching from './updateMatching';

export {
  getMatchingById,
  createMatching,
  deleteMatching,
  updateMatching,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorMatchedActions = {
  getMatchedForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(
        getMatchedForFilter(filterId)(filter)(offset, limit),
      ),
    debounceDelay,
    debounceOptions,
  ),
  getInfiniteScrollMatchedForFilter: memoizeDebounce(
    (filterId, filter, offset, limit, resetStore) => store.dispatch(
        getInfiniteScrollMatchedForFilter(filterId)(filter)(offset, limit, resetStore),
      ),
    debounceDelay,
    debounceOptions,
  ),
  getMatchingById: memoizeDebounce(
    (id) => store.dispatch(getMatchingById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
