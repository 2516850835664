import apiConnector, { endpoints } from 'api';

const removeTopLevelLineItem = (fulfillmentId) => async (productId) => {
  const endpoint = `${endpoints.client.fulfillmentLineItemDetails}/${fulfillmentId}/${productId}`;

  try {
    const result = await apiConnector.client.delete(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default removeTopLevelLineItem;
