import {
  SET_ARCHIVE_DELETE_LEAD_MODAL_SELECTED_ACTION,
} from 'store/actionTypes/realtorLeads';

/**
* @param {('archive'|'delete')} value
*/
const setSelectedAction = (value) => ({
  type: SET_ARCHIVE_DELETE_LEAD_MODAL_SELECTED_ACTION,
  payload: {
    key: 'selectedAction',
    value,
  },
});

export default setSelectedAction;
