import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedRealtorRepairRequestsActions,
} from 'store/actions/realtorRepairRequests';

import {
  optimizedAttachmentsActions,
  uploadAttachment,
  deleteAttachment,
} from 'store/actions/attachmentsV2';

import AttachmentsUpload from 'shared/inputs/InlineAttachmentsUpload';

const repairRequestObjectType = 36;

const mapStateToProps = (state, ownProps) => {
  const {
    requestId,
  } = ownProps;

  const attachments = selectors.realtorRepairRequests.attachments(state, requestId);

  return {
    fileList: attachments,
    multiple: true,
    itemSize: 's',
  };
};

const mapDispatchToProps = (dispatch, { requestId }) => ({
  onUpload: async (file) => {
    const data = {
      file,
      objectId: requestId,
      objectType: repairRequestObjectType,
      attachmentType: 12,
    };

    await dispatch(uploadAttachment(data));
    optimizedAttachmentsActions.getAttachmentsList(repairRequestObjectType, requestId);
    optimizedRealtorRepairRequestsActions.getRepairRequestAttachments(requestId);
  },
  onRemove: async (file) => {
    await dispatch(deleteAttachment(repairRequestObjectType, requestId, file.id));
    optimizedAttachmentsActions.getAttachmentsList(repairRequestObjectType, requestId);
    optimizedRealtorRepairRequestsActions.getRepairRequestAttachments(requestId);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onUpload: async ({ file }) => {
    if (stateProps.fileList.length > 2) {
      return;
    }
    await dispatchProps.onUpload(file);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AttachmentsUpload);
