import {
  SET_DISLIKE,
} from 'store/actionTypes/likes-dislikes';

const setDislike = (dislike) => ({
  type: SET_DISLIKE,
  payload: dislike,
});

export default setDislike;
