import requests from 'api/requests';

import setSource from './setSource';

const getSourceById = (sourceId) => async (dispatch) => {
  try {
    const response = await requests.crm.sources.getById(sourceId);

    dispatch(setSource(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getSourceById;
