/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { Controller } from 'react-hook-form';

import Item from 'ui/Item';
import Container from 'ui/Container';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';

import DateInput from 'shared/inputs/DateInput';

const ListingDates = (props) => {
  const {
    control,
    errors,
    setValue,
    getValues,
  } = props;

  const [daysSpread, setDaysSpread] = useState(90);

  const handleDaysInputChange = useCallback((value) => {
    const startDate = getValues('listing_start_date');
    const newEndDate = moment(startDate).add(value, 'days').format();
    setValue('listing_end_date', newEndDate, { shouldValidate: true });
    setDaysSpread(value);
  }, [
    setValue,
    getValues,
  ]);

  const handleStartDateChange = useCallback((value) => {
    setValue('listing_start_date', value, { shouldValidate: true });
    handleDaysInputChange(daysSpread);
  }, [
    daysSpread,
    setValue,
  ]);

  const handleEndDateChange = useCallback((value) => {
    const startDate = getValues('listing_start_date');
    setValue('listing_end_date', value, { shouldValidate: true });
    const difference = moment(value).diff(startDate, 'days');
    setDaysSpread(String(difference));
  }, [
    setValue,
    getValues,
  ]);

  return (
    <Container
      spacing={1}
      alignItems="baseline"
      justifyContent="space-between"
    >

      <Item xs={4}>
        <Controller
          name="listing_start_date"
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              // fullWidth
              handleChange={handleStartDateChange}
              onBlur={() => {}}
              label="Listing start date"
              error={!!errors.listing_start_date}
              helperText={errors.listing_start_date ? 'This field is required' : ''}
            />
          )}
          rules={{
            // required: true,
          }}
        />
      </Item>

      <Item>
        <Typography
          variant="body"
          tag="span"
          noMargin
        >
          +
        </Typography>
      </Item>

      <Item>
        <Textfield
          type="number"
          autoWidth
          value={daysSpread}
          disabled={!getValues('listing_start_date')}
          onChange={handleDaysInputChange}
          // formatter={(value) => `+ ${value}`}
          min={0}
        />
      </Item>

      <Item>
        <Typography
          variant="body2"
          tag="span"
          noMargin
        >
          days or
        </Typography>
      </Item>

      <Item xs={4}>
        <Controller
          name="listing_end_date"
          control={control}
          render={({ field }) => (
            <DateInput
              {...field}
              disabled={!getValues('listing_start_date')}
              handleChange={handleEndDateChange}
              label="Listing end date"
              error={!!errors.listing_end_date}
              helperText={errors.listing_end_date ? 'This field is required' : ''}
            />
          )}
          rules={{
            // required: true,
          }}
        />
      </Item>
    </Container>
  );
};

const {
  func,
  shape,
} = PropTypes;

ListingDates.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  setValue: func.isRequired,
  getValues: func.isRequired,
};

export default ListingDates;
