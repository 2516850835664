import React from 'react';
import PropTypes from 'prop-types';

import { Root } from './styledItems';

const AreaHighlight = (props) => {
  const {
    variant,
    children,
  } = props;

  return (
    <Root variant={variant}>
      {children}
    </Root>
  );
};

AreaHighlight.defaultProps = {
  variant: 'under-construction',
  children: null,
};

const {
  element,
  string,
} = PropTypes;

AreaHighlight.propTypes = {
  variant: string,
  children: element,
};

export default AreaHighlight;
