import {
  SET_PROPERTY_VIEWING_EDIT_FORM_NESTED_FIELD,
} from 'store/actionTypes/propertyViewings';

const setEditPropertyViewingNestedFormField = (parentKey) => (key) => (value) => ({
  type: SET_PROPERTY_VIEWING_EDIT_FORM_NESTED_FIELD,
  payload: {
    parentKey,
    key,
    value,
  },
});

export default setEditPropertyViewingNestedFormField;
