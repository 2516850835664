import {
  SET_REALTOR_EXTENSION_REQUESTS,
} from 'store/actionTypes/realtorExtensionRequests';

const setByFilter = (items) => ({
  type: SET_REALTOR_EXTENSION_REQUESTS,
  payload: items,
});

export default setByFilter;
