import { connect } from 'react-redux';
import { getStripePaymentMethods } from 'store/actions/payment';
import StripeProvider from './StripeProvider';


const mapStateToProps = () => ({});


const mapDispatchToProps = {
  getStripePaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeProvider);
