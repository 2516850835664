import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import JobRoleInput from './JobRoleInput';

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('jobRole')(value)),
});

export default connect(null, mapDispatchToProps)(JobRoleInput);
