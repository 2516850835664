import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getPropertyById from 'store/actions/properties/getPropertyById';

const deletePropertyViewing = (viewingId, propertyId = null) => async (dispatch) => {
  try {
    await requests.realtor.propertyViewings.deletePropertyViewing(viewingId);

    callAlert.success('Deleted successfully');

    if (propertyId) {
      dispatch(getPropertyById(propertyId));
    }
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deletePropertyViewing;
