import updateTransaction from './updateTransaction';

const updateSelectedTransaction = (updateData) => async (dispatch, getState) => {
  const {
    realtorTransactions: {
      selectedTransaction,
    },
  } = getState();

  await dispatch(updateTransaction(selectedTransaction)(updateData));
};

export default updateSelectedTransaction;
