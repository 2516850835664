import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';

import {
  Wrapper,
} from './styledItems';

const ControlButtons = (props) => {
  const {
    onComplete,
    onDelete,
    onDetails,
    className,
  } = props;

  return (
    <Wrapper className={className}>
      <Flex spacing={1} justifyContent="right">
        {
          onComplete && (
            <Tooltip
              title="Complete"
            >
              <Button
                onClick={onComplete}
                icon="check-circle"
              />
            </Tooltip>
          )
        }
        <Tooltip
          title="Details"
        >
          <Button
            onClick={onDetails}
            icon="info"
          />
        </Tooltip>
        <Tooltip
          title="Delete"
        >
          <Button
            onClick={onDelete}
            icon="trash"
          />
        </Tooltip>
      </Flex>
    </Wrapper>
  );
};

ControlButtons.defaultProps = {
  onComplete: null,
};

const {
  func,
  string,
} = PropTypes;

ControlButtons.propTypes = {
  onComplete: func,
  onDelete: func.isRequired,
  onDetails: func.isRequired,
  className: string.isRequired,
};

export default ControlButtons;
