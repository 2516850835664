import apiConnector, { endpoints } from 'api';

const realtorViewsFromHome = async () => {
  const endpoint = endpoints.client.dictionary.realtorViewsFromHome;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default realtorViewsFromHome;
