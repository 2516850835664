import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import { searchProperties } from 'store/actions/properties';

import { isEmpty } from 'shared/utility';

// import {
//   // Avatar,
//   Chip,
// } from '@material-ui/core';

// import FormTextfield from 'shared/components/UI/FormTextfield';
import Textfield from 'ui/Textfield';
import Chip from 'ui/Chip';
import SearchResult from './SearchResult';

import {
  Wrapper,
  SearchPropertiesArea,
} from './styledItems';

const SearchPropertyInput = (props) => {
  const {
    handleChange,
    selectedProperty,
    handleDelete,
    label,
  } = props;

  const {
    address,
    price,
  } = selectedProperty;

  const searchInputRef = useRef(null);
  const foundPropertiesRef = useRef(null);

  const [searchedProperties, setSearchedProperties] = useState([]);

  const [isSearchAreaVisible, setSearchAreaVisibility] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = useCallback((event) => {
    const isSearchInputClick = searchInputRef.current ? (
      searchInputRef.current.input === event.target
    ) : (
      false
    );

    const isFoundPropertiesClick = foundPropertiesRef.current ? (
      foundPropertiesRef.current.contains(event.target)
    ) : (
      false
    );

    if (
      !isSearchInputClick
      && !isFoundPropertiesClick
    ) {
      setSearchAreaVisibility(false);
    }
  }, []);

  const onSearchInputFocus = useCallback(() => {
    setSearchAreaVisibility(true);
  }, []);

  const onSearch = useDebouncedCallback(async (value) => {
    if (!value) {
      setSearchedProperties([]);

      return;
    }

    const properties = await searchProperties({
      address: value,
      limit: 10,
    })();

    setSearchedProperties(properties.map((item) => item.id));
  }, 800);

  const onInput = useCallback((value) => {
    onSearch(value);
  }, [onSearch]);

  const onPropertyClick = useCallback((propertyId) => () => {
    handleChange(propertyId);
  }, [handleChange]);

  return (
    <Wrapper>
      {
        isEmpty(selectedProperty) ? (
          <>
            <Textfield
              label={label}
              onChange={onInput}
              onFocus={onSearchInputFocus}
              inputRef={searchInputRef}
            />
            {
              isSearchAreaVisible && (
                <SearchPropertiesArea ref={foundPropertiesRef}>
                  <SearchResult
                    searchedProperties={searchedProperties}
                    onPropertyClick={onPropertyClick}
                  />
                </SearchPropertiesArea>
              )
            }
          </>
        ) : (
          <Chip
            onDelete={handleDelete}
            variant="subtle"
          >
            {`${address} | ${price}`}
          </Chip>
        )
      }
    </Wrapper>
  );
};

SearchPropertyInput.defaultProps = {
  label: 'Search existing properties by address',
  selectedProperty: {},
};

const {
  func,
  shape,
  string,
} = PropTypes;

SearchPropertyInput.propTypes = {
  handleChange: func.isRequired,
  handleDelete: func.isRequired,
  selectedProperty: shape({
    address: string,
    price: string,
  }),
  label: string,
};

const decoratorProps = {
  iconName: 'home',
  noMargin: true,
  iconType: 'feather',
};

export default withFieldDecorator(decoratorProps)(SearchPropertyInput);
