import connectReducers from 'store/connectReducers';

import {
  SET_USER,
  DELETE_USER_AVATAR,
} from 'store/actionTypes/users';

const initialState = {
  employee: {},
  customer: {},
  salesContact: {},
  realtorContact: {},
};

const reducers = {
  [DELETE_USER_AVATAR]: (state) => ({
    ...state,
  }),

  [SET_USER]: (state, action) => {
    const {
      userType,
      userId,
      name,
      surname,
      role,
      avatar,
      avatarAcl,
      avatarUrl,
    } = action.payload;

    const usersOfType = { ...state[userType] };

    usersOfType[userId] = {
      name,
      surname,
      role,
      avatar,
      avatarAcl,
      avatarUrl,
    };

    return {
      ...state,
      [userType]: usersOfType,
    };
  },
};

function comments(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default comments;
