import apiConnector, { endpoints } from 'api';

const realtorSaleTypes = async () => {
  const endpoint = endpoints.client.dictionary.realtorSaleTypes;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default realtorSaleTypes;
