import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import CircleIcon from 'shared/components/UI/CircleIcon';

import {
  Root,
  InlineContainer,
  MarginBox,
} from './styledItems';

const ObjectDescriptionArea = (props) => {
  const {
    title,
    iconName,
    iconColor,
    descriptionItems,
  } = props;

  return (
    <Root>
      <InlineContainer gap={8}>
        {
          iconName && (
            <CircleIcon
              iconName={iconName}
              iconColor={iconColor}
            />
          )
        }

        <Typography
          variant="title2"
          tag="h4"
          className="object-title"
        >
          {title}
        </Typography>
      </InlineContainer>

      <MarginBox />

      <InlineContainer gap={15}>
        {descriptionItems.map(({
          name,
          textColor,
          value,
          valueComponent,
        }) => (
          <InlineContainer gap={4}>
            <Typography
              variant="body"
              tag="span"
              color="descriptor-text"
              weight="normal"
              className="object-title"
            >
              {`${name}:`}
            </Typography>
            {
              valueComponent || (
                <Typography
                  variant="title1"
                  tag="span"
                  weight="normal"
                  className="object-title"
                  color={textColor}
                >
                  {value}
                </Typography>
              )
            }
          </InlineContainer>
        ))}
      </InlineContainer>
    </Root>
  );
};

ObjectDescriptionArea.defaultProps = {
  iconName: null,
  iconColor: null,
};

const {
  arrayOf,
  number,
  oneOfType,
  string,
} = PropTypes;

ObjectDescriptionArea.propTypes = {
  title: string.isRequired,
  iconColor: string,
  iconName: string,
  descriptionItems: arrayOf({
    name: string,
    value: oneOfType([number, string]),
    textColor: string,
  }).isRequired,
};

export default ObjectDescriptionArea;
