import styled from 'styled-components';
import Typography from 'ui/Typography';

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: ${((props) => props.theme?.palette?.highlight || '#1584ff')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: 3px;
  text-transform: uppercase;
  margin-right: 3px;
`;

export const Initials = styled.div`
  color: white;
  font-size: 17px;
  font-weight: ${((props) => props.theme?.typography?.fontWeight?.bold || 'bold')};
  font-family: ${((props) => props.theme?.typography?.fontFamily?.primary || 'Roboto, Arial, Helvetica, sans-serif;')};
`;

export const Name = styled(Typography)`
  margin-bottom: 2px;
  text-transform: capitalize;
`;
