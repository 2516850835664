import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

import ErrorBoundary from 'ui/ErrorBoundary';

import {
  Root,
} from './styledItems';

const KPIItem = (props) => {
  const {
    value,
    title,
    iconName,
    iconType,
    iconColor,
    iconSize,
    children,
    variant,
    tooltip,
    textVariant,
    customStyles,
  } = props;

  const content = (
    <Root
      spacing={2}
      alignItems="center"
      variant={variant}
      style={customStyles}
    >
      {
        iconName && (
          <Icon
            color={iconColor}
            name={iconName}
            type={iconType}
            base={variant === 'flat' && 'circle'}
            baseColor={variant === 'flat' && 'white'}
            size={iconSize}
          />
        )
      }
      {
        ((value != null || title != null) && !children) && (
          <Flex
            spacing={1}
            alignItems="center"
          >
            {
              value != null && (
                <Typography
                  variant={textVariant || 'title2'}
                  tag="p"
                  noMargin
                  color="main-text"
                >
                  {value}
                </Typography>
              )
            }

            {
              title != null && (
                <Typography
                  variant={textVariant || 'title2'}
                  noMargin
                  color="main-text"
                  noWrap
                >
                  {title}
                </Typography>
              )
            }
          </Flex>
        )
      }
      {
        children
      }
    </Root>
  );

  if (tooltip) {
    return (
      <ErrorBoundary>
        <Tooltip title={tooltip}>
          {content}
        </Tooltip>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      {content}
    </ErrorBoundary>
  );
};

KPIItem.defaultProps = {
  iconName: null,
  iconType: undefined,
  iconColor: 'focus',
  iconSize: 's',
  title: null,
  variant: 'transparent',
  tooltip: null,
  textVariant: null,
  children: null,
  customStyles: null,
};

const {
  element,
  number,
  oneOf,
  oneOfType,
  string,
  shape,
} = PropTypes;

KPIItem.propTypes = {
  value: oneOfType([string, number]).isRequired,
  title: string,
  iconName: string,
  iconType: string,
  iconColor: string,
  variant: oneOf(['transparent', 'flat']),
  children: element,
  iconSize: oneOf(['s', 'm', 'l']),
  tooltip: oneOfType([string, element]),
  textVariant: string,
  customStyles: shape({}),
};

export default KPIItem;
