import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import formatTime from 'sections/Calls/utils/formatCallDuration';

const CallEndedView = (props) => {
  const {
    callDuration,
    onCancel,
  } = props;

  return (
    <div>
      <Typography color="contrast" style={{ marginBottom: 25 }}>
        {
          `Call lasted for ${formatTime(callDuration)} min.
          Your recording has been saved to the comments section of this person’s profile in your Symplete account.`
        }
      </Typography>

      <Flex justifyContent="flex-end">
        <Button
          onClick={onCancel}
          variant="secondary"
        >
          Ok
        </Button>
      </Flex>
    </div>
  );
};

const {
  number,
  func,
} = PropTypes;

CallEndedView.propTypes = {
  callDuration: number.isRequired,
  onCancel: func.isRequired,
};

export default CallEndedView;
