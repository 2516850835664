import connectReducers from 'store/connectReducers';

// import testLeads from 'store/testData/realtor/contacts';

import {
  SET_REALTOR_CONTACT,
  SET_REALTOR_CONTACTS,
  SET_REALTOR_CONTACTS_FOR_FILTER,
  SET_REALTOR_CONTACTS_FOR_FILTER_COUNT,
  SET_REALTOR_OVERALL_CONTACTS_SUMMARY,
  SET_REALTOR_SELECTED_CONTACT,
  SET_REALTOR_SELECTED_CONTACT_ROWS,
  SET_REALTOR_CONTACT_CHANGELOG,
  SET_REALTOR_CONTACT_VIEWERS,
} from 'store/actionTypes/realtorContacts';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  contacts: {},
  contactsByFilter: {},
  contactsByFilterCount: {},
  overallContactsSummary: null,
  selectedContact: null,
  changelog: {},
  selectedContactRows: [],
  contactViewers: {},
};

const reducers = {
  [SET_REALTOR_CONTACT_VIEWERS]: (state, action) => ({
    ...state,
    contactViewers: {
      [action.payload.contactId]: action.payload.viewers,
    },
  }),

  [SET_REALTOR_SELECTED_CONTACT]: (state, action) => ({
    ...state,
    selectedContact: action.payload,
  }),

  [SET_REALTOR_SELECTED_CONTACT_ROWS]: (state, action) => ({
    ...state,
    selectedContactRows: action.payload,
  }),

  [SET_REALTOR_CONTACTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    contactsByFilterCount: {
      ...state.contactsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_CONTACT]: (state, action) => ({
    ...state,
    contacts: {
      ...state.contacts,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_CONTACTS]: (state, action) => ({
    ...state,
    contacts: {
      ...state.contacts,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_CONTACTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      contacts,
    } = action.payload;

    // const filterProductIds = state.contactsByFilter[filterId] || [];

    const updatedByIds = new Set([
      ...getIds(contacts), // this order is important for currect updates
      // ...filterProductIds,
    ]);

    const updatedState = {
      ...state,
      contactsByFilter: {
        ...state.contactsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_OVERALL_CONTACTS_SUMMARY]: (state, action) => ({
    ...state,
    overallContactsSummary: action.payload,
  }),

  [SET_REALTOR_CONTACT_CHANGELOG]: (state, action) => ({
    ...state,
    changelog: {
      ...state,
      [action.payload.contactId]: action.payload.changelog,
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
