import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import validate from 'shared/utils/validators';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import ExistingContactAlert from 'shared/components/UI/ExistingContactAlert';
import Textfield from 'ui/Textfield';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

const EmailInput = (props) => {
  const {
    value,
    handleChange,
    checkEmailExistence,
    onInvalidEmail,
    isEmailValid,
    doesEmailExist,
    alertText,
    label,
    error,
    disabled,
    defaultValue,
  } = props;

  const [contact, setContact] = useState({});

  const [currentValue, setCurrentValue] = useState(value);

  const [currentIsEmailValid, setCurrentIsEmailValid] = useState(isEmailValid);
  const [currentEmailExists, setCurrentEmailExists] = useState(doesEmailExist);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  useEffect(() => {
    setCurrentIsEmailValid(isEmailValid);
  }, [
    isEmailValid,
  ]);

  useEffect(() => {
    setCurrentEmailExists(doesEmailExist);
  }, [
    doesEmailExist,
  ]);

  const test = async (currentValue) => {
    const isValid = validate.email(currentValue);

    if (isValid) {
      const existingContact = await checkEmailExistence(currentValue);

      if (existingContact) {
        setContact(existingContact);
        setCurrentEmailExists(true);
        onInvalidEmail('doesEmailExist', true);
      }
    } else {
      setCurrentEmailExists(false);
      onInvalidEmail('doesEmailExist', false);
    }
  };

  useEffect(() => {
    test(currentValue);
  }, [
    currentValue,
    // onInvalidEmail,
  ]);

  const onBlur = useCallback(async (event) => {
    const isValid = validate.email(event.target.value);

    setCurrentIsEmailValid(isValid);
    onInvalidEmail('isEmailValid', isValid);

    const existingContact = await checkEmailExistence(event.target.value);

    if (isValid && existingContact) {
      setContact(existingContact);

      setCurrentEmailExists(true);

      onInvalidEmail('doesEmailExist', true);
    } else {
      setCurrentEmailExists(false);

      onInvalidEmail('doesEmailExist', false);
    }
  }, [
    onInvalidEmail,
  ]);

  const onChange = useCallback((inputValue) => {
    setCurrentValue(inputValue);
    handleChange(inputValue);
  }, [
    handleChange,
  ]);

  return (
    <Flex flexWrap="wrap" spacing={2}>
      <FlexItem fullWidth>
        <Textfield
          label={label}
          value={currentValue || defaultValue}
          error={error || (!currentIsEmailValid && 'Not a valid email address')}
          onChange={onChange}
          onBlur={onBlur}
          icon="mail"
          disabled={disabled}
        />
      </FlexItem>
      {
        currentEmailExists && (
          <FlexItem fullWidth>
            <ExistingContactAlert
              text={alertText}
              contact={contact}
            />
          </FlexItem>
        )
      }
    </Flex>

  );
};

EmailInput.defaultProps = {
  label: 'Email',
  value: undefined,
  alertText: 'Email already exists in the database.',
  doesEmailExist: false,
  isEmailValid: true,
  onInvalidEmail: () => {},
  checkEmailExistence: () => null,
  error: false,
  disabled: false,
  defaultValue: null,
};

const {
  bool,
  func,
  string,
  oneOfType,
} = PropTypes;

EmailInput.propTypes = {
  alertText: string,
  handleChange: func.isRequired,
  checkEmailExistence: func,
  onInvalidEmail: func,
  doesEmailExist: bool,
  isEmailValid: bool,
  label: string,
  value: string,
  error: oneOfType([string, bool]),
  disabled: bool,
  defaultValue: string,
};

const decoratorProps = {
  iconName: 'mail',
  iconType: 'feather',
};

export default withFieldDecorator(decoratorProps)(EmailInput);
