import { connect } from 'react-redux';
import store from 'store';
import userActions from 'store/actions/users';


import Comment from './Comment';

const userTypeNames = {
  1: 'employee',
  2: 'customer',
};

const getUserName = (userType) => (userId) => (users) => {
  const user = users[userType][userId];

  if (!user) {
    store.dispatch(userActions(userType)(userId).get);
    return {
      name: '',
      surname: '',
    };
  }

  return {
    name: user.name,
    surname: user.surname,
  };
};

const getAcknowledgedLabel = (acknowledgedBy = [], users = { customer: {}, employee: {} }) => {
  if (acknowledgedBy.length === 0) {
    return null;
  }

  const firstUser = acknowledgedBy[0];

  const { name, surname } = getUserName(userTypeNames[firstUser.UserType])(firstUser.UserID)(users);

  const others = acknowledgedBy.length > 1
    ? `and ${acknowledgedBy.length - 1} more`
    : '';

  return `Acknowledged by ${name} ${surname} ${others}`;
};

const getResolvedLabel = (resolvedBy = [], users = { customer: {}, employee: {} }) => {
  if (resolvedBy.length === 0) {
    return null;
  }

  const firstUser = resolvedBy[0];

  const { name, surname } = getUserName(userTypeNames[firstUser.UserType])(firstUser.UserID)(users);

  return `Resolved by ${name} ${surname}`;
};

const mapStateToProps = (state, ownProps) => {
  const {
    users,
  } = state;

  const {
    creator,
    acknowledgedBy,
    resolvedBy,
  } = ownProps;

  const userTypeName = userTypeNames[creator.type];
  let user = users[userTypeName][creator.id];

  if (!user) {
    store.dispatch(userActions(userTypeName)(creator.id).get);
    user = {
      name: '',
      surname: '',
    };
  }

  const isAcknowledged = acknowledgedBy.length > 0;
  const acknowledgedLabel = getAcknowledgedLabel(acknowledgedBy, users);

  const isResolved = resolvedBy.length > 0;
  const resolvedLabel = getResolvedLabel(resolvedBy, users);

  return {
    creator: {
      ...creator,
      ...user,
    },
    isAcknowledged,
    acknowledgedLabel,
    isResolved,
    resolvedLabel,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
