/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getPropertyViewingById from './getPropertyViewingById';
import updatePropertyViewing from './updatePropertyViewing';
import updateSelectedPropertyViewing from './updateSelectedPropertyViewing';
import deletePropertyViewing from './deletePropertyViewing';
import addPropertyViewingRelations from './addPropertyViewingRelations';
import removePropertyViewingRelations from './removePropertyViewingRelations';
import overwritePropertyViewingRelations from './overwritePropertyViewingRelations';
import setSelectedPropertyViewing from './setSelectedPropertyViewing';
import createPropertyViewing from './createPropertyViewing';
import getInfiniteScrollShowingsForFilter from './getInfiniteScrollShowingsForFilter'; // eslint-disable-line import/no-cycle

import createContactFromNewPropertyViewingForm from './createContactFromNewPropertyViewingForm';
import createPropertyViewingFromNewPropertyViewingForm from './createPropertyViewingFromNewPropertyViewingForm';
import createContactFromEditPropertyViewingForm from './createContactFromEditPropertyViewingForm';

import openViewingNotesModal from './openViewingNotesModal';
import closeViewingNotesModal from './closeViewingNotesModal';

import getViewingsForFilter from './getViewingsForFilter';

export {
  getPropertyViewingById,
  updatePropertyViewing,
  updateSelectedPropertyViewing,
  deletePropertyViewing,
  addPropertyViewingRelations,
  removePropertyViewingRelations,
  overwritePropertyViewingRelations,
  setSelectedPropertyViewing,
  createPropertyViewing,

  createContactFromNewPropertyViewingForm,
  createPropertyViewingFromNewPropertyViewingForm,
  createContactFromEditPropertyViewingForm,

  openViewingNotesModal,
  closeViewingNotesModal,

  getInfiniteScrollShowingsForFilter,
};

const debounceDelay = 500;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedPropertyViewingActions = {
  getInfiniteScrollShowingsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit, resetStore) => store.dispatch(
        getInfiniteScrollShowingsForFilter(filterId)(filter)(offset, limit, resetStore),
      ),
    debounceDelay,
  debounceOptions,
  ),
  getViewingsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getViewingsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getPropertyViewingById: memoizeDebounce(
    (id) => store.dispatch(getPropertyViewingById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
