import apiConnector, { endpoints } from 'api';

const getTeamById = async (taskId) => {
  const endpoint = `${endpoints.client.v2.teams}/${taskId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getTeamById;
