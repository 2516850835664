import requests from 'api/requests';

import setUserProfile from './setUserProfile';

const getLoggedUserProfile = () => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        Email,
      },
    },
  } = getState();

  try {
    const response = await requests.realtor.realtorDirectory.find(Email);

    dispatch(setUserProfile(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getLoggedUserProfile;
