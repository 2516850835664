import apiConnector, { endpoints } from 'api';

const deleteLoan = async (id) => {
  const endpoint = `${endpoints.client.realtor.loans}/${id}`;

  try {
    await apiConnector.client.delete(endpoint);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default deleteLoan;
