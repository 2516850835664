export const SET_ARTICLE_PARENT_FOLDER = 'SET_ARTICLE_PARENT_FOLDER';
export const SET_ARTICLE_CHILDREN_FOLDER = 'SET_ARTICLE_CHILDREN_FOLDER';
export const SET_ACTIVE_ARTICLE_MAIN_VIEW = 'SET_ACTIVE_ARTICLE_MAIN_VIEW';
export const GET_USER_FOLDERS = 'GET_USER_FOLDERS';
export const SET_ARTICLES_DISPLAY_TYPE = 'SET_ARTICLES_DISPLAY_TYPE';
export const CLOSE_NEW_ARTICLE_MODAL = 'CLOSE_NEW_ARTICLE_MODAL';
export const OPEN_NEW_ARTICLE_MODAL = 'OPEN_NEW_ARTICLE_MODAL';
export const OPEN_NEW_FOLDER_MODAL = 'OPEN_NEW_FOLDER_MODAL';
export const CLOSE_NEW_FOLDER_MODAL = 'CLOSE_NEW_FOLDER_MODAL';
export const OPEN_EDIT_FOLDER_MODAL = 'OPEN_EDIT_FOLDER_MODAL';
export const CLOSE_EDIT_FOLDER_MODAL = 'CLOSE_EDIT_FOLDER_MODAL';
export const OPEN_VIEW_PERMISSIONS_MODAL = 'OPEN_VIEW_PERMISSIONS_MODAL';
export const CLOSE_VIEW_PERMISSIONS_MODAL = 'CLOSE_VIEW_PERMISSIONS_MODAL';
export const SET_PERMISSIONS_MODAL_SELECTED_TYPE = 'SET_PERMISSIONS_MODAL_SELECTED_TYPE';
export const OPEN_ARTICLE_DETAILS_MODAL = 'OPEN_ARTICLE_DETAILS_MODAL';
export const CLOSE_ARTICLE_DETAILS_MODAL = 'CLOSE_ARTICLE_DETAILS_MODAL';
export const SET_ARTICLE_FILTER = 'SET_ARTICLE_FILTER';
export const CREATE_ARTICLE_FOLDER = 'CREATE_ARTICLE_FOLDER';
export const SET_NEW_FOLDER_FORM_FIELD = 'SET_NEW_FOLDER_FORM_FIELD';
export const SET_PERMISSIONS_MODAL_DATA = 'SET_PERMISSIONS_MODAL_DATA';
export const SET_FOLDER = 'SET_FOLDER';
export const SET_EDIT_FOLDER_FORM_FIELD = 'SET_EDIT_FOLDER_FORM_FIELD';
export const UPDATE_ARTICLE_FOLDER = 'UPDATE_ARTICLE_FOLDER';
export const DELETE_ARTICLE_FOLDER = 'DELETE_ARTICLE_FOLDER';
export const SET_NEW_ARTICLE_FORM_FIELD = 'SET_NEW_ARTICLE_FORM_FIELD';
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const SET_ARTICLE = 'SET_ARTICLE';
export const GET_FOLDER_ARTICLES = 'GET_FOLDER_ARTICLES';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE';
export const SET_EDIT_ARTICLE_FORM_FIELD = 'SET_EDIT_ARTICLE_FORM_FIELD';
export const GET_FAVORITE_FOLDER_ARTICLES = 'GET_FAVORITE_FOLDER_ARTICLES';
export const REFRESH_FOLDER_ARTICLE = 'REFRESH_FOLDER_ARTICLE';
export const SEARCH_ARTICLES = 'SEARCH_ARTICLES';

export default {
  GET_FAVORITE_FOLDER_ARTICLES,
  REFRESH_FOLDER_ARTICLE,
  SET_EDIT_ARTICLE_FORM_FIELD,
  SET_ACTIVE_ARTICLE,
  DELETE_ARTICLE,
  UPDATE_ARTICLE,
  GET_FOLDER_ARTICLES,
  SET_ARTICLE,
  CREATE_ARTICLE,
  OPEN_NEW_FOLDER_MODAL,
  CLOSE_NEW_FOLDER_MODAL,
  SET_ARTICLE_PARENT_FOLDER,
  SET_ARTICLE_CHILDREN_FOLDER,
  SET_ACTIVE_ARTICLE_MAIN_VIEW,
  GET_USER_FOLDERS,
  SET_ARTICLES_DISPLAY_TYPE,
  CLOSE_NEW_ARTICLE_MODAL,
  OPEN_NEW_ARTICLE_MODAL,
  OPEN_EDIT_FOLDER_MODAL,
  CLOSE_EDIT_FOLDER_MODAL,
  OPEN_VIEW_PERMISSIONS_MODAL,
  CLOSE_VIEW_PERMISSIONS_MODAL,
  SET_PERMISSIONS_MODAL_SELECTED_TYPE,
  OPEN_ARTICLE_DETAILS_MODAL,
  CLOSE_ARTICLE_DETAILS_MODAL,
  SET_ARTICLE_FILTER,
  CREATE_ARTICLE_FOLDER,
  SET_NEW_FOLDER_FORM_FIELD,
  SET_PERMISSIONS_MODAL_DATA,
  SET_FOLDER,
  SET_EDIT_FOLDER_FORM_FIELD,
  UPDATE_ARTICLE_FOLDER,
  DELETE_ARTICLE_FOLDER,
  SET_NEW_ARTICLE_FORM_FIELD,
  SEARCH_ARTICLES,
};
