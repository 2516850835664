import styled from 'styled-components';

const Divider = styled.div`
  height: ${(props) => (props.vertical ? '100%' : '1px')};
  width: ${(props) => (props.vertical ? '1px' : '100%')};
  background-color: ${(props) => (props.color
    ? props?.theme?.palette?.[props.color]
    : (props?.theme?.palette?.divider || 'blue'))
  };
`;

export default Divider;
