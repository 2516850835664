import { connect } from 'react-redux';

import AttachmentsModule from 'shared/AttachmentsV4';

// keeping this in case we need the state later
const mapStateToProps = (state, ownProps) => ({
  objectType: 28,
  objectId: ownProps.propertyRequirementId,
  attachmentType: 20,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsModule);
