import { callAlert } from 'ui/Alert';
import { get } from 'shared/utility';
// TODO - fix dependency cycles
import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import getEmployeeById from 'shared/utils/getEmployeeById';
import LicenseRepository from 'shared/repositiores/myCompany/license';
import checkEmployeePermissions from 'shared/utils/checkEmployeePermissions';
import {
  showDeletingSiteOwnerLicenseWarning,
} from 'store/actions/license-management';

import requests from 'api/requests';

import {
  CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL,
  GET_EMPLOYEE_NOTES,
  SET_EMPLOYEE_DETAILS,
  SET_EMPLOYEE_ACTIVITY,
  CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR,
  SHOW_DELETING_EMPLOYEE_WARNING,
  HIDE_DELETING_EMPLOYEE_WARNING,
  DELETE_EMPLOYEE_ACTIONS,
} from 'store/actionTypes/employeeDetails';

export const changeAccountCreatorInformationInGlobal = (
  fieldType,
  fieldValue,
  employeeID,
) => (dispatch, getState) => {
  try {
    const {
      myCompany: {
        general: {
          DatabaseName,
        },
      },
    } = getState();

    const targetEmployeeInformation = getEmployeeById(employeeID);

    if (targetEmployeeInformation.isAccountCreator) {
      requests.employees.changeAccountCreatorInformationInGlobal(
        fieldType,
        fieldValue,
        DatabaseName,
      );

      dispatch({
        type: CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL,
      });
    }
  } catch {
    dispatch({
      type: CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR,
    });
  }
};

export function getNotesSuccess(notes) {
  return {
    type: GET_EMPLOYEE_NOTES,
    payload: notes,
  };
}

export function setDetailsSuccess(data) {
  return {
    type: SET_EMPLOYEE_DETAILS,
    payload: data,
  };
}

export const getNotes = (
  data,
) => (dispatch) => {
  apiConnector('/api/employee/notes/get', 'POST', data)
    .then(({ result }) => {
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Get notes: ${e.message}`);
    });
};

export const createNote = (
  data,
) => (dispatch) => {
  apiConnector('/api/employee/notes/create', 'POST', data)
    .then(({ result }) => {
      callAlert.success('Note created!');
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Create note: ${e.message}`);
    });
};

export const editNote = (
  data,
) => (dispatch) => {
  apiConnector('/api/employee/notes/edit', 'POST', data)
    .then(({ result }) => {
      callAlert.success('Note edited!');
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Edit note: ${e.message}`);
    });
};

export const deleteNote = (
  data,
) => (dispatch) => {
  apiConnector('/api/employee/notes/delete', 'POST', data)
    .then(({ result }) => {
      callAlert.success('Note deleted!');
      dispatch(getNotesSuccess(result));
    })
    .catch((e) => {
      callAlert.error(`Delete note: ${e.message}`);
    });
};

export const setEmployeeDetails = (data) => (dispatch) => {
  dispatch(setDetailsSuccess(data));
};

export const getActivity = () => (dispatch, getState) => {
  let employeeID = getState().employeeDetails.EmployeeID;

  if (employeeID === 0) {
    employeeID = get(getState(), 'auth.loggedUser.EmployeeID');
  }

  if (!employeeID) return;
  apiConnector(`/api/common/activities?ObjectType=employee&ObjectID=${employeeID}`)
    .then((data) => {
      dispatch({
        type: SET_EMPLOYEE_ACTIVITY,
        payload: data,
      });
    })
    .catch(() => {
    });
};

export const logActivity = (
  msg = '',
  comment = '',
) => (dispatch, getState) => {
  let employeeID = getState().employeeDetails.EmployeeID;

  if (employeeID === 0) {
    employeeID = get(getState(), 'auth.loggedUser.EmployeeID');
  }

  if (!employeeID) return;

  apiConnector('/api/employee/logActivity', 'POST', {
    employeeID,
    msg,
    comment,
  })
    .then(({ result }) => {
      dispatch(getActivity());
    })
    .catch((e) => {
      callAlert.error('logActivity error');
    });
};

export const sendEmail = (employeeName = '', oldEmail) => (dispatch, getState) => {
  const {
    loggedUser: {
      Name = '',
      Surname = '',
    },
  } = getState().auth;

  apiConnector('/api/employee/sendEmail', 'POST', {
    username: `${Name} ${Surname}`,
    employeeName,
    email: oldEmail,
  })
    .then(({ result }) => {
      callAlert.success('Users email was changed. Info message was send to old user email');
    })
    .catch((e) => {
    });
};

export const showDeleteEmployeeWarningModal = (employeeId, data) => (dispatch) => {
  dispatch({
    type: SHOW_DELETING_EMPLOYEE_WARNING,
    deletingEmployeeInformation: {
      employeeId,
      data,
    },
  });
};

export const hideDeleteEmployeeWarningModal = () => ({
  type: HIDE_DELETING_EMPLOYEE_WARNING,
});

export const setEmployeeDeleteStatus = (employeeId, data) => async (dispatch) => {
  try {
    const employee = getEmployeeById(employeeId);

    const isTargetEmployeeSiteOwner = checkEmployeePermissions.isOwner()(employee);

    if (isTargetEmployeeSiteOwner) {
      dispatch(showDeletingSiteOwnerLicenseWarning());
      return;
    }

    LicenseRepository.removeLicenses(employee);

    const url = '/api/common/';
    await apiConnector(`${url}employees/${employeeId}`, 'PUT', data, {
      extended: true,
    });

    dispatch({
      type: DELETE_EMPLOYEE_ACTIONS,
    });

    callAlert.success('Changes saved!');
  } catch {
    callAlert.error('An error occurred when deleting an employee!');
  }
};
