import {
  SET_WAREHOUSE_PACKAGE,
} from 'store/actionTypes/warehouse';

const set = (payload) => ({
  type: SET_WAREHOUSE_PACKAGE,
  payload,
});

export default set;
