import apiConnector, { endpoints } from 'api';

const upload = async (file) => {
  const formData = new FormData();

  formData.append('file', file);

  try {
    const response = await apiConnector.clientAttachmentsUpload.post(
      endpoints.client.dataImport.upload,
      formData,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default upload;
