import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateClient,
} from 'store/actions/realtorClients';

import CurrencyInput from 'ui/CurrencyInput';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks/index';

const mapStateToProps = (state, ownProps) => {
  const client = selectors.realtorClients.byId(state, ownProps.clientId);
  return {
    value: client?.listing_price,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateClient(ownProps.clientId)({
      listing_price: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withDebouncedCallbacks(CurrencyInput, [['onChange', 1000]]));
