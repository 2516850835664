import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertyParametersMain from 'sections/Properties/components/PropertyParametersMain';

const mapStateToProps = (state, ownProps) => {
  const propertyData = selectors.properties.byId(state, ownProps.propertyId);

  return {
    parameters: {
      bedrooms: propertyData?.bedrooms,
      bathrooms: propertyData?.bathrooms,
      floor_area: propertyData?.floor_area,
      plot_area: propertyData?.plot_area,
      parking: propertyData?.parking,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => (value) => {
    dispatch(updateProperty(ownProps.propertyId)({
      [key]: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyParametersMain);
