import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import MainProspectInfo from './MainProspectInfo';

import {
  StyledCardElevation,
  Root,
} from './styledItems';

const ProspectCard = (props) => {
  const {
    prospectId,
    variant,
  } = props;

  const history = useHistory();

  const onCardClick = useCallback(() => {
    history.push(`/prospects/${prospectId}`);
  }, [prospectId]);

  return (
    <StyledCardElevation onClick={onCardClick}>
      <Root>
        <MainProspectInfo
          prospectId={prospectId}
          variant={variant}
        />
      </Root>
    </StyledCardElevation>
  );
};

ProspectCard.defaultProps = {
  variant: 'viewed',
};

const {
  number,
  string,
} = PropTypes;

ProspectCard.propTypes = {
  prospectId: number.isRequired,
  variant: string,
};

export default ProspectCard;
