import apiConnector, { endpoints } from 'api';

const editLabel = (labelId) => (labelName) => async (labelDescription) => {
  const endpoint = `${endpoints.client.labels}/${labelId}`;

  try {
    const result = await apiConnector.client.put(endpoint, { labelName, labelDescription });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default editLabel;
