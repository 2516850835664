/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import EmailComposer from 'sections/Integrations/modules/EmailComposer';
import SidePanel from 'sections/Contacts/modules/SidePanel';

import { head } from 'shared/utility';

const EmailSection = (props) => {
  const {
    contactId,
    contactType,
    objectId,
    message,
    subject,
    onSend,
  } = props;

  const [seletedEmailToContacts, setSeletedEmailToContacts] = useState(contactId ? [contactId] : []);

  return (
    <Flex
      fullwidth
      spacing={2}
      flexWrap="wrap"
      alignItems="stretch"
      fullHeight
    >
      <FlexItem
        xs={16}
      >
        <Elevation
          rounded="s"
          padding="m"
          fullHeight
        >
          <EmailComposer
            emailTo={seletedEmailToContacts}
            onChangeEmailTo={setSeletedEmailToContacts}
            message={message}
            subject={subject}
            onSend={onSend}
          />
        </Elevation>
      </FlexItem>

      <FlexItem
        xs={8}
      >
        <Elevation
          rounded="s"
          padding="m"
          fullHeight
          style={{ overflowY: 'auto', maxHeight: '97vh' }}
        >
          <SidePanel
            contactId={head(seletedEmailToContacts)}
            contactType={contactType}
            objectId={objectId}
          />
        </Elevation>

      </FlexItem>

    </Flex>
  );
};

EmailSection.defaultProps = {
  contactId: null,
  contactType: null,
  objectId: null,
  message: '',
  subject: '',
  onSend: null,
};

const {
  string,
  number,
  func,
} = PropTypes;

EmailSection.propTypes = {
  contactId: number,
  objectId: number,
  contactType: string,
  message: string,
  subject: string,
  onSend: func,
};

export default EmailSection;
