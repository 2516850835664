import {
  SET_REALTOR_MATCHING_PROPERTIES_BY_FILTER,
} from 'store/actionTypes/realtorMatchingProperties';

const setByFilter = ({ filterId, items }) => ({
  type: SET_REALTOR_MATCHING_PROPERTIES_BY_FILTER,
  payload: {
    filterId,
    items,
  },
});

export default setByFilter;
