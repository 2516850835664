import styled from 'styled-components';

export const Label = styled.div`
  position: relative;
  top: 16px;
`;

export default {
  Label,
};
