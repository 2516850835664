import styled from 'styled-components';

const sizeToIconWrapperWidth = {
  xxxs: 21,
  xxs: 32,
  xs: 32,

  s: 32,
  sm: 32,

  m: 50,
  md: 50,

  l: 32,
  lg: 32,
};

export const Root = styled.div`
  display: flex;
  align-items: ${(props) => props.align || 'center'};
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-content: ${(props) => (props.iconWrapperJustify || 'center')};
  width: ${(props) => sizeToIconWrapperWidth[props.size]}px;
  margin-right: ${(props) => (props.size === 'lg' ? '6px' : 0)};
  margin-top: ${(props) => (props.iconMarginTop || 0)};
`;

export const InputWrapper = styled.div`
  flex: 1;
  text-align: left;
  min-width: 0;
`;

export default {
  Root,
  IconWrapper,
  InputWrapper,
};
