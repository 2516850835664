export const SET_SELECTED_TICKET = 'SET_SELECTED_TICKET';
export const SET_TICKET = 'SET_TICKET';
export const SET_TICKETS = 'SET_TICKETS';

export const SET_TICKETS_FOR_EMPLOYEE = 'SET_TICKETS_FOR_EMPLOYEE';
export const SET_TICKETS_FOR_QUEUE = 'SET_TICKETS_FOR_QUEUE';

export const SET_TICKETS_COUNT_FOR_EMPLOYEE = 'SET_TICKETS_COUNT_FOR_EMPLOYEE';
export const SET_TICKETS_COUNT_FOR_QUEUE = 'SET_TICKETS_COUNT_FOR_QUEUE';

export const SET_OVERALL_TICKETS_SUMMARY = 'SET_OVERALL_TICKETS_SUMMARY';
export const SET_TICKETS_SUMMARY_FOR_EMPLOYEE = 'SET_TICKETS_SUMMARY_FOR_EMPLOYEE';
export const SET_TICKETS_SUMMARY_FOR_QUEUE = 'SET_TICKETS_SUMMARY_FOR_QUEUE';
export const SET_TICKETS_SUMMARY_FOR_CUSTOMER = 'SET_TICKETS_SUMMARY_FOR_CUSTOMER';

export const SET_EMPLOYEE_TICKETS_BOARD_VIEW_FILTER = 'SET_EMPLOYEE_TICKETS_BOARD_VIEW_FILTER';

export const SET_TICKETS_FOR_FILTER = 'SET_TICKETS_FOR_FILTER';
export const SET_TICKETS_COUNT_FOR_FILTER = 'SET_TICKETS_COUNT_FOR_FILTER';
export const SET_TICKETS_SUMMARY_FOR_FILTER = 'SET_TICKETS_SUMMARY_FOR_FILTER';

export const CLEAR_FILTER_SUMMARIES = 'CLEAR_FILTER_SUMMARIES';

export const SET_ACTIVE_EMPLOYEE_VIEW_FILTER = 'SET_ACTIVE_EMPLOYEE_VIEW_FILTER';

export const SET_MANAGER_DASHBOARD_SELECTED_QUEUE = 'SET_MANAGER_DASHBOARD_SELECTED_QUEUE';
export const SET_MANAGER_DASHBOARD_SEARCH = 'SET_MANAGER_DASHBOARD_SEARCH';
export const SET_MANAGER_DASHBOARD_FILTER = 'SET_MANAGER_DASHBOARD_FILTER';

export const SET_CUSTOMER_DETAILS_SELECTED_TICKET_TYPE = 'SET_CUSTOMER_DETAILS_SELECTED_TICKET_TYPE';

export const CLEAR_TICKET_DATA = 'CLEAR_TICKET_DATA';

export default {
  SET_SELECTED_TICKET,
  SET_TICKET,
  SET_TICKETS,

  SET_TICKETS_FOR_EMPLOYEE,
  SET_TICKETS_FOR_QUEUE,

  SET_TICKETS_COUNT_FOR_EMPLOYEE,
  SET_TICKETS_COUNT_FOR_QUEUE,

  SET_OVERALL_TICKETS_SUMMARY,
  SET_TICKETS_SUMMARY_FOR_EMPLOYEE,
  SET_TICKETS_SUMMARY_FOR_QUEUE,

  SET_EMPLOYEE_TICKETS_BOARD_VIEW_FILTER,

  SET_TICKETS_FOR_FILTER,
  SET_TICKETS_COUNT_FOR_FILTER,
  SET_TICKETS_SUMMARY_FOR_FILTER,

  CLEAR_FILTER_SUMMARIES,

  SET_ACTIVE_EMPLOYEE_VIEW_FILTER,

  SET_MANAGER_DASHBOARD_SELECTED_QUEUE,
  SET_MANAGER_DASHBOARD_SEARCH,
  SET_MANAGER_DASHBOARD_FILTER,

  SET_CUSTOMER_DETAILS_SELECTED_TICKET_TYPE,

  CLEAR_TICKET_DATA,
};
