import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import AssignKeyContacts from 'sections/Contacts/modules/AssignKeyContacts';

const KeyPeople = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeSeller,
      value: seller,
    },
  } = useController({
    name: 'seller',
    control,
  });

  const {
    field: {
      onChange: onChangeBuyer,
      value: buyer,
    },
  } = useController({
    name: 'buyer',
    control,
  });

  const {
    field: {
      onChange: onChangeSellerAgent,
      value: sellerAgent,
    },
  } = useController({
    name: 'sellerAgent',
    control,
  });

  const {
    field: {
      onChange: onChangeBuyerAgent,
      value: buyerAgent,
    },
  } = useController({
    name: 'buyerAgent',
    control,
  });

  const callbacks = {
    buyer: onChangeBuyer,
    buyerAgent: onChangeBuyerAgent,
    seller: onChangeSeller,
    sellerAgent: onChangeSellerAgent,
  };

  const onAdd = useCallback((role) => (contactId) => {
    callbacks[role](contactId);
  }, [
    callbacks,
  ]);

  const onRemove = useCallback((role) => () => {
    callbacks[role](null);
  }, [
    callbacks,
  ]);

  const keyContactsData = [
    { key: 'buyer', roleLabel: 'buyer', contactId: buyer },
    { key: 'buyerAgent', roleLabel: 'buyer agent', contactId: buyerAgent },
    { key: 'seller', roleLabel: 'seller', contactId: seller },
    { key: 'sellerAgent', roleLabel: 'seller agent', contactId: sellerAgent },
  ];

  return (
    <AssignKeyContacts
      keyContactsData={keyContactsData}
      preset
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};

const {
  shape,
} = PropTypes;

KeyPeople.propTypes = {
  control: shape({}).isRequired,
};

export default KeyPeople;
