import {
  SET_ACCEPT_COUNTER_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorOpportunities';

const setLogOfferFormAttachments = (filesList = []) => ({
  type: SET_ACCEPT_COUNTER_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setLogOfferFormAttachments;
