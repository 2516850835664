import { url as apiUrl } from 'paths';
import { isEmpty, isString } from 'shared/utility';

/**
 * @param {string} url
 * @returns {string}
 * @function
 */
const getAvatarUrlWithAuth = (url) => {
  const token = localStorage.getItem('token');
  return `${url}?auth=${token}`;
};

/**
 * @param {string} imageLocation
 * @returns {string}
 * @function
 */
const getAvatarUrl = (imageLocation) => {
  if (isEmpty(imageLocation)) {
    return null;
  }

  if (!isString(imageLocation)) {
    throw new Error('Avatar location must be a string');
  }

  const fileSrc = `${apiUrl}${imageLocation}`;
  const avatarUrl = getAvatarUrlWithAuth(fileSrc);

  return avatarUrl;
};

export default getAvatarUrl;
