import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
  overwritePropertyRelations,
} from 'store/actions/properties';

const relationFields = new Set([
  'views',
]);

const withPropertyData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      propertyId,
    } = ownProps;

    const property = selectors.properties.byId(state, propertyId);

    return {
      property,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (fieldName) => (value) => {
      if (relationFields.has(fieldName)) {
        dispatch(overwritePropertyRelations(fieldName)(ownProps.propertyId)(value));
      } else {
        dispatch(updateProperty(ownProps.propertyId)({ [fieldName]: value }));
      }
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withPropertyData;
