import apiConnector, { endpoints } from 'api';

const byZip = async (zip) => {
  const endpoint = `${endpoints.global.smartzipProperties.byZip}/${zip}`;

  try {
    const result = await apiConnector.global.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default byZip;
