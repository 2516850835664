import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';

import hasUserSeenComment from 'shared/utils/hasUserSeenComment';

import LoggingContactModal from 'shared/modals/LoggingContactModal/LoggingContactModal';

import CommentContext from 'shared/contexts/CommentContext';

import CommentTabs from 'shared/components/CommentTabs';
import CommentsList from 'shared/components/CommentsList';

import { prepareComments } from './utils';

import './Comments.scss';

class CommentTabsModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: 0,
      callsLogVisible: false,
      callsLogData: null,
    };
  }

  componentDidMount() {
    this.getComments();
    this.getLogs();
  }

  onTabClick = (selectedType) => {
    this.setState({
      selectedType,
    });
  }

  // comments
  getComments = () => {
    const {
      getComments,
    } = this.props;

    getComments();
  }

  acknowledgeComment = (commentId) => {
    const {
      acknowledgeComment,
    } = this.props;

    acknowledgeComment(commentId);
  }

  deleteTicketComment = (commentId) => {
    const { deleteComment } = this.props;
    deleteComment(commentId);
  }

  editComment = async ({
    commentId,
    commentText,
  }) => {
    const {
      editComment,
    } = this.props;

    const commentResponse = await editComment({
      commentId,
      commentText,
    });
    return commentResponse;
  }

  resolveComment = (commentId) => {
    const {
      resolveComment,
    } = this.props;

    resolveComment(commentId);
  }

  markAsSeen = (commentId, seenBy) => {
    const {
      markCommentAsSeen,
    } = this.props;

    if (!hasUserSeenComment(seenBy)) {
      markCommentAsSeen(commentId);
    }
  }
  //

  // logs
  getLogs = () => {
    const {
      getLogs,
    } = this.props;

    getLogs();
  }

  handleCallLogClick = (comment) => () => {
    this.setState({
      callsLogVisible: true,
      callsLogData: comment,
    });
  }

  handleCallLogModalClose = () => {
    this.setState({
      callsLogVisible: false,
      callsLogData: null,
    });
  }

  addContactLog = (values) => {
    const {
      addContactLog,
    } = this.props;

    addContactLog(values);
  };

  deleteLog = (logId) => () => {
    const { deleteContactLog } = this.props;

    deleteContactLog(logId);
  }

  acknowledgeLog = (logId) => () => {
    const { acknowledgeContactLog } = this.props;

    acknowledgeContactLog(logId);
  }
  //

  // call recordings
  deleteCallRecording = (recordingSid, contactId) => () => {
    const { deleteCallRecording } = this.props;

    deleteCallRecording(recordingSid, contactId);
  }

  render() {
    const {
      comments,
      logList,
      callRecordings,
      commentedObjectType,
    } = this.props;

    const {
      callsLogVisible,
      callsLogData,
      selectedType,
    } = this.state;

    const preparedComments = prepareComments({
      selectedType,
      comments,
      logList,
      callRecordingsList: callRecordings,
    });

    return (
      <>
        <CommentTabs
          onTabClick={this.onTabClick}
          handleCallLogClick={this.handleCallLogClick(null)}
          selectedTab={selectedType}
        >
          <CommentContext.Provider value={commentedObjectType}>
            <CommentsList
              comments={preparedComments}
              onDelete={this.deleteTicketComment}
              onLike={this.acknowledgeComment}
              onEdit={this.editComment}
              markAsSeen={this.markAsSeen}
              onUpdate={this.getComments}
              onResolve={this.resolveComment}
              customCallbacks={{
                log: {
                  onDelete: this.deleteLog,
                  onLike: this.acknowledgeLog,
                },
                callRecording: {
                  onDelete: this.deleteCallRecording,
                },
              }}
            />
          </CommentContext.Provider>
        </CommentTabs>

        <LoggingContactModal
          isModalVisible={callsLogVisible}
          type={callsLogData ? 'show' : 'add'}
          handleLoggingContactModal={this.handleCallLogModalClose}
          data={callsLogData}
          updateContactLog={this.addContactLog}
        />
      </>
    );
  }
}

CommentTabsModule.defaultProps = {
  comments: [],
  logList: [],
  callRecordings: [],
};

const {
  array,
  func,
} = PropTypes;

CommentTabsModule.propTypes = {
  comments: array, // eslint-disable-line react/forbid-prop-types
  logList: array, // eslint-disable-line react/forbid-prop-types
  callRecordings: array, // eslint-disable-line react/forbid-prop-types

  getComments: func.isRequired,

  acknowledgeComment: func.isRequired,
  deleteComment: func.isRequired,
  editComment: func.isRequired,
  markCommentAsSeen: func.isRequired,
  resolveComment: func.isRequired,

  getLogs: func.isRequired,
  addContactLog: func.isRequired,
  acknowledgeContactLog: func.isRequired,
  deleteContactLog: func.isRequired,

  commentedObjectType: string.isRequired,

  deleteCallRecording: func.isRequired,
};

export default CommentTabsModule;
