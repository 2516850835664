import { apiConnector } from 'shared/API';

// eslint-disable-next-line import/prefer-default-export
export const getThumbnailInf = (insertedURL) => async () => {
  const method = 'POST';
  const url = '/api/thumbnails/getThumbnailInf';

  const thumbnailInf = await apiConnector(url, method, { url: insertedURL });

  return thumbnailInf;
};
