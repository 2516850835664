import apiConnector, { endpoints } from 'api';

const create = async (data) => {
  const endpoint = endpoints.client.integrations.emailCampaigns;

  try {
    const result = await apiConnector.client.post(endpoint, data);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
