import React, {
  useContext,
  useCallback,
} from 'react';

import MultiselectTextfield from 'ui/MultiselectTextfield';

import context from 'sections/Contacts/modals/AssignContactModal/ContextProvider/context';
import useContactLabelsOptions from 'sections/Contacts/hooks/useContactLabelsOptions';

const LabelsSelector = (props) => {
  const {
    setFilterValue,
  } = useContext(context);

  const options = useContactLabelsOptions();

  const handleChange = useCallback((values) => {
    const labels = values.map((item) => item.value);
    setFilterValue('labels')(labels);
  }, [
    setFilterValue,
  ]);

  return (
    <MultiselectTextfield
      options={options}
      onChange={handleChange}
      flat
      dense
    />
  );
};

export default LabelsSelector;
