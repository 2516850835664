/* eslint-disable import/no-cycle */
import filter from './filter';
import list from './list';
import overallSummary from './overallSummary';
import kanban from './kanban';
import byTypeAndId from './byTypeAndId';
import opportunitiesFilterKey from './opportunitiesFilterKey';

export default {
  filter,
  list,
  overallSummary,
  kanban,
  byTypeAndId,
  opportunitiesFilterKey,
};
