import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { formatPhoneNumber } from 'shared/utils/phoneNumbers';

import PhoneNumberCell from './PhoneNumberCell';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
    number,
  } = ownProps;

  const getContactDefaultPhone = () => {
    const contactPhoneNumbers = selectors.realtorContacts.contactPhoneNumbers(state, contactId);

    const primaryPhoneNumber = contactPhoneNumbers.find((item) => item.is_primary);

    return primaryPhoneNumber?.e164_format || contactPhoneNumbers[0]?.e164_format;
  };

  let value = number;

  if (!value && contactId) {
    value = getContactDefaultPhone();
  }

  return {
    value: value ? formatPhoneNumber(value) : null,
  };
};

export default connect(mapStateToProps)(PhoneNumberCell);
