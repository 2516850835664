import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Avatar from 'shared/components/UI/RealtorContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

const ContactInfo = (props) => {
  const {
    contactId,
    avatarImageSrc,
  } = props;

  const {
    firstName,
    lastName,
    fullName,
  } = useContactName(contactId, { returnValue: 'object' });

  if (!avatarImageSrc && !firstName && !lastName) return null;

  return (
    <Flex
      alignItems="center"
    >
      <Avatar
        src={avatarImageSrc}
        firstName={firstName}
        lastName={lastName}
        size={40}
      />
      <div style={{ marginLeft: 10 }}>
        <Typography
          weight="bold"
          noMargin
          ellipsis
          style={{ maxWidth: 150 }}
        >
          {fullName}
        </Typography>
      </div>
    </Flex>
  );
};

const { string, number } = PropTypes;

ContactInfo.propTypes = {
  avatarImageSrc: string.isRequired,
  contactId: number.isRequired,
};

export default ContactInfo;
