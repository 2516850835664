import requests from 'api/requests';

import setTasksSummaryForFilter from './setTasksSummaryForFilter';

const getFilterTasksSummary = (filterId) => (filter) => async (dispatch) => {
  try {
    const summaryResponse = await requests.tasks.getOverallTasksSummary(filter);

    dispatch(setTasksSummaryForFilter(filterId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getFilterTasksSummary;
