import { createSelector } from 'reselect';

import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

export default (propertyId) => createSelector(
  (state) => state.realtorMatchingProperties.matchingPropertiesByFilter,
  (state) => state.realtorMatchingProperties.matchingPropertiesCountByFilter,
  (state) => (id) => selectors.realtorMatchingProperties.byId(id)(state),
  (
    matchingPropertiesByFilter,
    matchingPropertiesCountByFilter,
    getMatchingProperty,
  ) => {
    const list = selectors.realtorMatchingProperties.byFilter({
      property_id: propertyId,
    })(
      // offset,
      // limit
    )({
      realtorMatchingProperties: {
        matchingPropertiesByFilter,
        matchingPropertiesCountByFilter,
      },
    });

    let matches = [];

    if (Array.isArray(list.items)) {
      matches = list.items.map((id) => getMatchingProperty(id));
    }

    return matches;
  },
);
