import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.crmCompaniesFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      search: filter.search,
      status: filter.status,
      labels: filter.labels,
      industry_type: filter.industry_type,
      type: filter.company_type,
      revenue_range: filter.revenue_range,
      employees_range: filter.employees_range,
      permission_type: filter.permissionType,
      owned_by_teams: filter.ownedByTeams,
      owned_by_employees: filter.ownedByEmployees,
      permissions_for_employees: filter.permissionsForEmployees,
      permissions_for_teams: filter.permissionsForTeams,
      permissions_for_offices: filter.permissionsForOffices,
      is_orphaned: filter.isOrphaned,
      is_active: filter.isActive,
      is_inactive: filter.isInactive,
    },
  }),
);
