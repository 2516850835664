import { connect } from 'react-redux';

import getEmployeeById from 'shared/utils/getEmployeeById';

import LogItem from './LogItem';

const userTypes = {
  employee: 1,
  customer: 2,
};

const getUserName = (userType) => (userId) => {
  if (userType === userTypes.employee) {
    const id = +userId;

    const employee = getEmployeeById(id);

    if (!employee) {
      return null;
    }

    return {
      name: employee.Name,
      surname: employee.Surname,
    };
  }

  if (userType === userTypes.customer) {
    return {
      name: 'John',
      surname: 'Doe',
    };
  }

  return {
    name: '',
    surname: '',
  };
};

const getAcknowledgedLabel = (acknowledgedBy = []) => {
  if (acknowledgedBy.length === 0) {
    return null;
  }

  const firstUser = acknowledgedBy[0];

  const { name, surname } = getUserName(firstUser.UserType)(firstUser.UserID);

  const others = acknowledgedBy.length > 1
    ? `and ${acknowledgedBy.length - 1} more`
    : '';

  return `Acknowledged by ${name} ${surname} ${others}`;
};

// const getResolvedLabel = (resolvedBy = []) => {
//   if (resolvedBy.length === 0) {
//     return null;
//   }

//   const firstUser = resolvedBy[0];

//   const { name, surname } = getUserName(firstUser.UserType)(firstUser.UserID);

//   return `Resolved by ${name} ${surname}`;
// };

const mapStateToProps = (state, ownProps) => {
  const {
    creator,
    acknowledgedBy = [],
    // resolvedBy = [],
  } = ownProps;

  const creatorWithName = {
    ...creator,
    ...getUserName(creator.type)(creator.id),
  };

  const isAcknowledged = acknowledgedBy.length > 0;
  const acknowledgedLabel = getAcknowledgedLabel(acknowledgedBy);

  // const isResolved = resolvedBy.length > 0;
  // const resolvedLabel = getResolvedLabel(resolvedBy);

  return {
    creator: creatorWithName,
    isAcknowledged,
    acknowledgedLabel,
    // isResolved,
    // resolvedLabel,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LogItem);
