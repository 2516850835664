import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useContactLabelsOptions = () => {
  const allLabels = useReselect(selectors.dictionary.contactLabels);

  if (!allLabels || !Array.isArray(allLabels)) {
    return [];
  }

  const options = allLabels.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return options;
};

export default useContactLabelsOptions;
