import React from 'react';
import PropTypes from 'prop-types';

import {
  Upload,
} from 'antd';

import Typography from 'ui/Typography';

import UploadWrapper from './styledItems';

const { Dragger } = Upload;

const AttachmentsUpload = (props) => {
  const {
    fileList,
    onUpload,
    onRemove,
    multiple,
    label,
  } = props;

  return (
    <UploadWrapper>
      <Dragger
        listType="picture"
        accept="image/png, image/jpeg, .pdf, .doc, .txt"
        fileList={fileList}
        customRequest={onUpload}
        onRemove={onRemove}
        multiple={multiple}
      >
        <Typography
          variant="h6"
          tag="p"
          weight={500}
          color="highlight"
          noMargin
        >
          {label}
        </Typography>
      </Dragger>
    </UploadWrapper>
  );
};

AttachmentsUpload.defaultProps = {
  fileList: [],
  multiple: false,
  label: 'Click or drag files to this area to upload',
};

const {
  func,
  shape,
  arrayOf,
  string,
  bool,
} = PropTypes;

AttachmentsUpload.propTypes = {
  fileList: arrayOf(shape({
    uid: string,
    name: string,
    url: string,
    thumbUrl: string,
    isMain: bool,
  })),
  onUpload: func.isRequired,
  onRemove: func.isRequired,
  multiple: bool,
  label: string,
};

export default AttachmentsUpload;
