/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { meetingParticipantType, thumbnailType } from 'oa/types/index';
import Thumbnail from './components/Thumbnail';
import './style.css';

const MeetingUserList = (props) => {
  const {
    className,
    remoteParticipants,
    thumbnails,
    pinnedParticipant,
    unpinParticipant,
    pinParticipant,
  } = props;

  return (
    <section className={classNames(
      'meeting-user-list',
      'meeting-user-list_located_bottom',
      className,
    )}
    >
      <h2 className="visually-hidden">Meeting user list</h2>

      <ul className="meeting-user-list__inner">
        {thumbnails.map((thumbnail) => {
          const {
            participantSocketId,
            stream,
          } = thumbnail;

          const participant = remoteParticipants.find((remoteParticipant) => (
            remoteParticipant.socketId === participantSocketId
          ));

          if (!participant) return null;

          return (
            <Thumbnail
              key={participantSocketId}
              participant={participant}
              stream={stream}
              pinnedParticipant={pinnedParticipant}
              unpinParticipant={unpinParticipant}
              pinParticipant={pinParticipant}
            />
          );
        })}
      </ul>
    </section>
  );
};

MeetingUserList.propTypes = {
  className: PropTypes.string,
  remoteParticipants: PropTypes.arrayOf(meetingParticipantType).isRequired,
  thumbnails: PropTypes.arrayOf(thumbnailType).isRequired,
  pinnedParticipant: meetingParticipantType,
  unpinParticipant: PropTypes.func.isRequired,
  pinParticipant: PropTypes.func.isRequired,
};

export default MeetingUserList;
