import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const PhoneNumberCell = (props) => {
  const {
    value,
  } = props;

  return (
    <Typography variant="cell">
      {value || '—'}
    </Typography>
  );
};

PhoneNumberCell.defaultProps = {
  value: '',
};

const {
  string,
} = PropTypes;

PhoneNumberCell.propTypes = {
  value: string,
};

export default PhoneNumberCell;
