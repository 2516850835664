import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

const usePropertyNotablePoints = (propertyId) => {
  const [notablePoints, setNotablePoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getNotablePoints = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await requests.realtor.notablePoints.get({
        objectId: propertyId,
        objectTypeId: 27,
      });

      setNotablePoints(result?.data?.items);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    propertyId,
  ]);

  useEffect(() => {
    getNotablePoints();
  }, [
    propertyId,
  ]);

  return {
    notablePoints,
    isLoading,
    error,
    getNotablePoints,
  };
};

export default usePropertyNotablePoints;
