import apiConnector, { endpoints } from 'api';

// NB: se le pega a /import-info?tokenId=13
//                                       ^ id interno de la tabla de tokens de nylas
// Esto funciona bien! ~ falta agregar el modal de Error al importar si el token expira o alguna cosa asi
// hay un google_refresh_token en algun lugar de nylasSettings que tal vez refresca el token expirado --> ToDo: chequear con token valido pero expirado?
const importInfo = async ({ tokenId }) => {
  const endpoint = `${endpoints.client.integrations.calendar}/import-info`;
  console.log(`called CALENDAR importInfo for endpoint ${endpoint}`);

  try {
    const result = await apiConnector.client.get(endpoint, { tokenId });
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default importInfo;
