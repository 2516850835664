import { connect } from 'react-redux';

import {
  updateRepairRequest,
} from 'store/actions/realtorRepairRequests';

import SelectCell from 'shared/components/UI/TableCells/SelectCell';

const options = [
  {
    id: 1,
    name: 'completed',
  },
  {
    id: 5,
    name: 'pending',
  },
];

const valuesToColors = {
  1: '#71c59b',
  5: '#97a0ab',
};

const mapStateToProps = (state, ownProps) => {
  const {
    status,
  } = ownProps;

  return {
    options,
    value: status || 5,
    valuesToColors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateRepairRequest(ownProps.requestId)({ status: value }, ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCell);
