import { connect } from 'react-redux';

import SourceDescriptionForm from 'shared/components/CRM/Widgets/SourceDescriptionForm';

const mapStateToProps = (state, ownProps) => {
  const {
    sourceDescription,
    sourceName,
  } = ownProps;

  return {
    sourceName,
    sourceDescription: sourceDescription || '',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (sourceDescription) => {
    ownProps.handleChangeSourceDescription(sourceDescription);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceDescriptionForm);
