import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Badge,
  Tooltip,
} from 'antd';

import RealtorContactAvatar from 'shared/components/UI/RealtorContactAvatar';

const getOffset = (size) => {
  const r = size / 2;
  const rSin45 = 0.71 * r;

  return [`${rSin45 - r}px`, `${rSin45 + r}px`];
};

const placementArray = [
  'top', 'left', 'right', 'bottom',
  'topLeft', 'topRight', 'bottomLeft', 'bottomRight',
  'leftTop', 'leftBottom', 'rightTop', 'rightBottom',
];

const sizes = {
  xs: 32,
  s: 36,
  sm: 50,
  m: 116,
};

const badgeSizes = {
  xxs: 6,
  xs: 7,
  s: 8,
  sm: 10,
  m: 11,
  l: 14,
  xl: 14,
};

const UserAvatar = (props) => {
  const {
    avatarClassName,
    avatarImageSrc,
    availabilityBadgeStatus,
    badgeClassName,
    badgeCount,
    badgeDot,
    badgeProps,
    name,
    src,
    showBadge,
    surname,
    tooltipClassName,
    tooltipPlacement,
    tooltipProps,
    customTooltipTitle,
    noBadge,
    size,
    isLoggedUserAvatar,
    isNameLetterAvatar,
    round,
  } = props;

  const avatarSize = sizes[size] || size;
  const badgeSize = badgeSizes[size];

  const offset = getOffset(avatarSize);
  const tooltipTitle = customTooltipTitle || `${name || ''} ${surname || ''}`;

  const badgeStyle = {
    height: badgeSize,
    minWidth: badgeSize,
    visibility: showBadge ? 'visible' : 'hidden',
  };

  const [status, setStatus] = useState(null);

  useEffect(() => {
    setStatus(
      badgeProps.status
        ? badgeProps.status
        : availabilityBadgeStatus,
    );
  }, [
    badgeProps.status,
    availabilityBadgeStatus,
  ]);

  const imageSrc = src || avatarImageSrc;

  const avatarComponent = () => (
    <RealtorContactAvatar
      src={imageSrc}
      firstName={name}
      lastName={surname}
      size={size}
      round={round}
      className={avatarClassName}
      isNameLetterAvatar={isNameLetterAvatar}
    />
  );

  return (
    <Tooltip
      className={classnames({
        [tooltipClassName]: tooltipClassName,
      })}
      placement={tooltipPlacement}
      title={tooltipTitle}
      trigger={isLoggedUserAvatar && !customTooltipTitle ? 'none' : 'hover'}
      {...tooltipProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {
        noBadge
          ? avatarComponent()
          : (
            <Badge
              className={classnames({
                [badgeClassName]: badgeClassName,
              })}
              dot={badgeDot}
              count={badgeCount}
              offset={offset}
              style={badgeStyle}
              {...badgeProps} // eslint-disable-line react/jsx-props-no-spreading
              status={status}
            >
              {avatarComponent()}
            </Badge>
          )
      }
    </Tooltip>
  );
};

export default UserAvatar;

UserAvatar.defaultProps = {
  avatarClassName: '',
  avatarImageSrc: '',
  avatarProps: {},
  isNameLetterAvatar: false,
  avatarSize: 30,
  availabilityBadgeStatus: 'default',
  badgeClassName: '',
  badgeCount: '',
  badgeDot: true,
  badgeProps: {},
  badgeSize: 8,
  className: '',
  name: '',
  onClick: () => { },
  onUserAvatarClick: null,
  showBadge: true,
  src: null,
  surname: '',
  tooltipClassName: '',
  tooltipPlacement: 'bottom',
  tooltipProps: {},
  tooltipVisible: false,
  noBadge: false,
  size: 'xs',
  isLoggedUserAvatar: false,
  customTooltipTitle: null,
  round: false,
};

const {
  bool,
  func,
  number,
  object,
  oneOf,
  string,
} = PropTypes;

UserAvatar.propTypes = {
  isLoggedUserAvatar: bool,
  isNameLetterAvatar: bool,
  customTooltipTitle: string,
  avatarClassName: string,
  avatarImageSrc: string,
  avatarProps: object, // eslint-disable-line react/forbid-prop-types
  avatarSize: number,
  availabilityBadgeStatus: string,
  badgeClassName: string,
  badgeCount: string,
  badgeDot: bool,
  badgeProps: object, // eslint-disable-line react/forbid-prop-types
  badgeSize: number,
  className: string,
  name: string,
  onClick: func,
  onUserAvatarClick: func,
  showBadge: bool,
  src: string,
  surname: string,
  tooltipClassName: string,
  tooltipPlacement: oneOf(placementArray),
  tooltipProps: object, // eslint-disable-line react/forbid-prop-types
  tooltipVisible: bool, // Unused prop???
  noBadge: bool,
  size: oneOf([
    'xxs',
    'xs',
    's',
    'sm',
    'm',
    'l',
    'xl',
    100,
    58,
  ]),
  round: bool,
};
