export const SET_NEW_LEAD_FORM_FIELD = 'SET_NEW_LEAD_FORM_FIELD';
export const SET_NEW_LEAD_FORM_ASSIGNED_USERS = 'SET_NEW_LEAD_FORM_ASSIGNED_USERS';
export const SET_NEW_LEAD_FORM_ASSIGNED_TEAMS = 'SET_NEW_LEAD_FORM_ASSIGNED_TEAMS';

export const SET_NEW_LEAD_MODAL_OPEN = 'SET_NEW_LEAD_MODAL_OPEN';
export const SET_NEW_LEAD_MODAL_CLOSED = 'SET_NEW_LEAD_MODAL_CLOSED';

export const SET_CRM_PRODUCT = 'SET_CRM_PRODUCT';
export const SET_CRM_PRODUCTS = 'SET_CRM_PRODUCTS';
export const SET_CRM_PRODUCTS_FOR_FILTER = 'SET_CRM_PRODUCTS_FOR_FILTER';

export const SET_CRM_SOURCE_TYPES = 'SET_CRM_SOURCE_TYPES';
export const SET_CRM_SOURCE = 'SET_CRM_SOURCE';

export const SET_CRM_COMPANY = 'SET_CRM_COMPANY';
export const SET_CRM_COMPANIES = 'SET_CRM_COMPANIES';
export const SET_CRM_COMPANIES_FOR_FILTER = 'SET_CRM_COMPANIES_FOR_FILTER';
export const SET_CRM_COMPANIES_FOR_FILTER_COUNT = 'SET_CRM_COMPANIES_FOR_FILTER_COUNT';
export const SET_CRM_SELECTED_COMPANY_ROWS = 'SET_CRM_SELECTED_COMPANY_ROWS';
export const SET_CRM_OVERALL_COMPANIES_SUMMARY = 'SET_CRM_OVERALL_COMPANIES_SUMMARY';
export const SET_CRM_COMPANY_CHANGELOG = 'SET_CRM_COMPANY_CHANGELOG';
export const SET_CRM_SELECTED_COMPANY = 'SET_CRM_SELECTED_COMPANY';
export const SET_CRM_COMPANIES_LABELS = 'SET_CRM_COMPANIES_LABELS';

export const SET_CRM_CONTACT = 'SET_CRM_CONTACT';
export const SET_CRM_CONTACTS = 'SET_CRM_CONTACTS';
export const SET_CRM_CONTACTS_FOR_FILTER = 'SET_CRM_CONTACTS_FOR_FILTER';
export const SET_CRM_CONTACTS_FOR_FILTER_COUNT = 'SET_CRM_CONTACTS_FOR_FILTER_COUNT';
export const SET_CRM_OVERALL_CONTACTS_SUMMARY = 'SET_CRM_OVERALL_CONTACTS_SUMMARY';

export const SET_NEW_CONTACT_FORM_FIELD = 'SET_NEW_CONTACT_FORM_FIELD';
export const SET_NEW_CONTACT_FORM_ASSIGNED_USERS = 'SET_NEW_CONTACT_FORM_ASSIGNED_USERS';
export const RESET_NEW_CONTACT_FORM = 'RESET_NEW_CONTACT_FORM';

export const SET_CRM_CONTACT_LABELS = 'SET_CRM_CONTACT_LABELS';
export const SET_CRM_SELECTED_CONTACT = 'SET_CRM_SELECTED_CONTACT';
export const SET_CRM_SELECTED_CONTACT_ROWS = 'SET_CRM_SELECTED_CONTACT_ROWS';

export const SET_NEW_COMPANY_FORM_FIELD = 'SET_NEW_COMPANY_FORM_FIELD';
export const SET_NEW_COMPANY_FORM_ASSIGNED_USERS = 'SET_NEW_COMPANY_FORM_ASSIGNED_USERS';
export const RESET_NEW_COMPANY_FORM = 'RESET_NEW_COMPANY_FORM';
export const SET_NEW_COMPANY_MODAL_OPEN = 'SET_NEW_COMPANY_MODAL_OPEN';
export const SET_NEW_COMPANY_MODAL_CLOSED = 'SET_NEW_COMPANY_MODAL_CLOSED';

export const SET_CRM_CONTACT_CHANGELOG = 'SET_CRM_CONTACT_CHANGELOG';

// eslint-disable-next-line max-len
export const SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT = 'SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT';

export const SET_NEW_CONTACT_MODAL_OPEN = 'SET_NEW_CONTACT_MODAL_OPEN';
export const SET_NEW_CONTACT_MODAL_CLOSED = 'SET_NEW_CONTACT_MODAL_CLOSED';

export const SET_CRM_LEAD = 'SET_CRM_LEAD';
export const SET_CRM_LEADS = 'SET_CRM_LEADS';
export const SET_CRM_LEADS_FOR_FILTER = 'SET_CRM_LEADS_FOR_FILTER';
export const SET_CRM_LEADS_FOR_FILTER_COUNT = 'SET_CRM_LEADS_FOR_FILTER_COUNT';
export const SET_CRM_LEAD_VIEWERS = 'SET_CRM_LEAD_VIEWERS';

export const SET_CRM_LEAD_FILTER_ITEM = 'SET_CRM_LEAD_FILTER_ITEM';
export const RESET_CRM_LEAD_FILTER_ITEM = 'RESET_CRM_LEAD_FILTER_ITEM';
export const CLEAR_CRM_LEAD_FILTER = 'CLEAR_CRM_LEAD_FILTER';

export const SET_CRM_CONTACT_FILTER_ITEM = 'SET_CRM_CONTACT_FILTER_ITEM';
export const RESET_CRM_CONTACT_FILTER_ITEM = 'RESET_CRM_CONTACT_FILTER_ITEM';
export const CLEAR_CRM_CONTACT_FILTER = 'CLEAR_CRM_CONTACT_FILTER';

export const SET_CRM_COMPANY_FILTER_ITEM = 'SET_CRM_COMPANY_FILTER_ITEM';
export const RESET_CRM_COMPANY_FILTER_ITEM = 'RESET_CRM_COMPANY_FILTER_ITEM';
export const CLEAR_CRM_COMPANY_FILTER = 'CLEAR_CRM_COMPANY_FILTER';

export const SET_CRM_OVERALL_LEADS_SUMMARY = 'SET_CRM_OVERALL_LEADS_SUMMARY';

export const SET_CRM_SELECTED_LEAD = 'SET_CRM_SELECTED_LEAD';
export const SET_CRM_SELECTED_LEAD_ROWS = 'SET_CRM_SELECTED_LEAD_ROWS';
export const SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS = 'SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS';

export const SET_CRM_LEAD_CHANGELOG = 'SET_CRM_LEAD_CHANGELOG';

export default {
  SET_NEW_LEAD_FORM_FIELD,
  SET_NEW_LEAD_FORM_ASSIGNED_USERS,
  SET_NEW_LEAD_FORM_ASSIGNED_TEAMS,

  SET_NEW_LEAD_MODAL_OPEN,
  SET_NEW_LEAD_MODAL_CLOSED,

  SET_CRM_PRODUCT,
  SET_CRM_PRODUCTS,
  SET_CRM_PRODUCTS_FOR_FILTER,

  SET_CRM_SOURCE_TYPES,

  SET_CRM_COMPANY,
  SET_CRM_COMPANIES,
  SET_CRM_COMPANIES_FOR_FILTER,
  SET_CRM_COMPANIES_FOR_FILTER_COUNT,
  SET_CRM_SELECTED_COMPANY_ROWS,
  SET_CRM_OVERALL_COMPANIES_SUMMARY,
  SET_CRM_COMPANY_CHANGELOG,
  SET_CRM_SELECTED_COMPANY,
  SET_CRM_COMPANIES_LABELS,

  SET_CRM_CONTACT,
  SET_CRM_CONTACTS,
  SET_CRM_CONTACTS_FOR_FILTER,
  SET_CRM_OVERALL_CONTACTS_SUMMARY,
  SET_CRM_CONTACTS_FOR_FILTER_COUNT,
  SET_NEW_CONTACT_FORM_FIELD,
  RESET_NEW_CONTACT_FORM,

  SET_NEW_CONTACT_FORM_ASSIGNED_USERS,
  SET_CRM_CONTACT_LABELS,
  SET_CRM_SELECTED_CONTACT,
  SET_CRM_SELECTED_CONTACT_ROWS,
  SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT,
  SET_CRM_CONTACT_CHANGELOG,

  SET_NEW_COMPANY_FORM_FIELD,
  SET_NEW_COMPANY_FORM_ASSIGNED_USERS,
  RESET_NEW_COMPANY_FORM,
  SET_NEW_COMPANY_MODAL_OPEN,
  SET_NEW_COMPANY_MODAL_CLOSED,

  SET_NEW_CONTACT_MODAL_OPEN,
  SET_NEW_CONTACT_MODAL_CLOSED,

  SET_CRM_LEAD,
  SET_CRM_LEADS,
  SET_CRM_LEADS_FOR_FILTER,
  SET_CRM_LEADS_FOR_FILTER_COUNT,
  SET_CRM_LEAD_VIEWERS,

  SET_CRM_LEAD_FILTER_ITEM,
  RESET_CRM_LEAD_FILTER_ITEM,
  CLEAR_CRM_LEAD_FILTER,

  SET_CRM_CONTACT_FILTER_ITEM,
  RESET_CRM_CONTACT_FILTER_ITEM,
  CLEAR_CRM_CONTACT_FILTER,

  SET_CRM_COMPANY_FILTER_ITEM,
  RESET_CRM_COMPANY_FILTER_ITEM,
  CLEAR_CRM_COMPANY_FILTER,

  SET_CRM_OVERALL_LEADS_SUMMARY,

  SET_CRM_SELECTED_LEAD,
  SET_CRM_SELECTED_LEAD_ROWS,
  SET_CRM_SELECTED_LEAD_ROWS_EXCEPTIONS,

  SET_CRM_LEAD_CHANGELOG,
};
