import getFolders from './getFolders';
import createFolder from './createFolder';
import getFolder from './getFolder';
import updateFolder from './updateFolder';
import deleteFolder from './deleteFolder';
import createArticle from './createArticle';
import getFolderArticles from './getFolderArticles';
import updateArticle from './updateArticle';
import getArticle from './getArticle';
import deleteArticle from './deleteArticle';
import searchArticles from './searchArticles';
import getPublicData from './getPublicData';
import updatePublicArticle from './updatePublicArticle';

export default {
  updatePublicArticle,
  getPublicData,
  getFolderArticles,
  createArticle,
  updateFolder,
  getFolders,
  createFolder,
  getFolder,
  deleteFolder,
  updateArticle,
  getArticle,
  deleteArticle,
  searchArticles,
};
