import { connect } from 'react-redux';

import RepairRequests from 'sections/Transactions/modules/RepairRequests';

const mapStateToProps = (state) => {
  const {
    realtorTransactions: {
      selectedTransaction,
    },
  } = state;

  return {
    transactionId: selectedTransaction,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RepairRequests);
