import { getContext } from 'shared/utils/common'; // eslint-disable-line import/no-cycle
import { socket } from 'shared/API/sockets';

const monitorUserAvailabilty = (onAvailablityChange) => {
  const token = localStorage.getItem('token');

  const context = getContext(token);
  const route = `${context}:employee:availability`;

  socket.on(route, ({ employee, status }) => {
    onAvailablityChange({
      availabilityStatus: status,
      employeeId: employee,
    });
  });
};

export default monitorUserAvailabilty;
