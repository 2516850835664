/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

import Typography from 'ui/Typography';

import OrderItem from './OrderItem';

import {
  MainWrapper,
  OrderNumbersContainer,
  DroppableContainer,
  DraggableContainer,
} from './styledItems';

const getOrderNumbers = (count) => Array.from({ length: count }, (v, k) => k).map((k) => (k + 1));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Order = (props) => {
  const {
    name,
    items,
    onChange,
    disabled,
  } = props;

  const [itemsState, setItemsState] = useState(items);

  useEffect(() => {
    setItemsState(items);
  }, [
    JSON.stringify(items),
  ]);

  const onDragEnd = useCallback((result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      itemsState,
      result.source.index,
      result.destination.index,
    );

    setItemsState(newItems);

    if (onChange) {
      onChange(newItems);
    }
  }, [
    itemsState,
    onChange,
  ]);

  const itemsCount = itemsState.length;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <MainWrapper>
        <OrderNumbersContainer>
          {
            getOrderNumbers(itemsCount).map((ordinalNumber) => (
              <Typography
                variant="title1"
                weight="bold"
                noMargin
                style={{ marginBottom: 8 }}
              >
                {ordinalNumber}
              </Typography>
            ))
          }
        </OrderNumbersContainer>

        <Droppable
          droppableId={name}
          isDropDisabled={disabled}
        >
          {(provided, snapshot) => (
            <DroppableContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemsState.map((item, index) => (
                <Draggable
                  key={item.key}
                  draggableId={item.key}
                  index={index}
                  isDragDisabled={disabled}
                >
                  {(provided, snapshot) => ( // eslint-disable-line no-shadow
                    <DraggableContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                    >
                      <OrderItem>
                        {item.content}
                      </OrderItem>
                    </DraggableContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </DroppableContainer>
          )}
        </Droppable>
      </MainWrapper>
    </DragDropContext>
  );
};

Order.defaultProps = {
  onChange: null,
  disabled: false,
};

const {
  arrayOf,
  shape,
  string,
  func,
  bool,
} = PropTypes;

Order.propTypes = {
  items: arrayOf(shape({
    key: string.isRequired,
    content: string.isRequired,
  })).isRequired,
  onChange: func,
  name: string.isRequired,
  disabled: bool,
};

export default Order;
