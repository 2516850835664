/**
 * @callback findPlaceFromQueryCallback
 * @see [Places Service | PlaceResult]{@link https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult}
 * @param {google.maps.places.PlaceResult[]} [PlaceResult] - Defines information about a Place.
 */

/**
 * @typedef {google.maps.places.FindPlaceFromQueryRequest} google.maps.places.FindPlaceFromQueryRequest
 * @see [Places Service | FindPlaceFromQueryRequest]{@link https://developers.google.com/maps/documentation/javascript/reference/places-service#FindPlaceFromQueryRequest}
 * @property {string[]} [fields=['ALL']] - Fields to be included in the response, which will be billed for.
 * @property {string} query - The request's query. For example, the name or address of a place
 */

/**
 * @see [Places Service | Find Place from Query]{@link https://developers.google.com/maps/documentation/javascript/places#find_place_from_query}
 * @param {google.maps.places.PlacesService} placesService
 * @param {google.maps.places.FindPlaceFromQueryRequest} request
 * @param {findPlaceFromQueryCallback} callback
 */
export const findPlaceFromQuery = (
  placesService,
  request,
  callback,
) => {
  placesService.findPlaceFromQuery(
    request,
    (results, status) => {
      // eslint-disable-next-line no-undef
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        callback(results);
      }
    },
  );
};

/**
 * @typedef {'BICYCLING'|'DRIVING'|'TRANSIT'|'WALKING'} google.maps.TravelMode
 */

/**
 * @typedef {google.maps.DistanceMatrixRequest} google.maps.DistanceMatrixRequest
 * @property {google.maps.LatLng[]} origins - An array containing origin address LatLng from which to calculate distance and time. Required.
 * @property {google.maps.LatLng[]} destinations - An array containing destination address LatLng to which to calculate distance and time. Required.
 * @property {google.maps.TravelMode} [travelMode='DRIVING'] - Type of routing requested. Required.
 * @property {google.maps.DrivingOptions} drivingOptions - Settings that apply only to requests where travelMode is DRIVING. This object will have no effect for other travel modes.
 */

/**
 * @callback getDistanceMatrixCallback
 * @see [Distance Matrix Service | DistanceMatrixResponse]{@link https://developers.google.com/maps/documentation/javascript/reference/distance-matrix#DistanceMatrixResponse}
 * @param {google.maps.DistanceMatrixResponse} DistanceMatrixResponse
 * @param {google.maps.DistanceMatrixResponseRow[]} DistanceMatrixResponse.rows - The rows of the matrix, corresponding to the origin addresses
 * @param {string[]} DistanceMatrixResponse.destinationAddresses - The formatted destination addresses.
 * @param {string[]} DistanceMatrixResponse.originAddresses - The formatted origin addresses
 */

/**
 * @see [Distance Matrix Service | Distance Matrix Requests]{@link https://developers.google.com/maps/documentation/javascript/distancematrix#distance_matrix_requests}
 * @param {google.maps.DistanceMatrixService} distanceMatrixService
 * @param {google.maps.DistanceMatrixRequest} payload
 * @param {getDistanceMatrixCallback} callback
 */
export const getDistanceMatrix = (
  distanceMatrixService,
  payload,
  callback,
) => {
  distanceMatrixService.getDistanceMatrix(
    payload,
    (results, status) => {
    // eslint-disable-next-line no-undef
      if (status === google.maps.DistanceMatrixStatus.OK) {
        callback(results);
      }
    },
  );
};

/**
 * @callback getPlacePredictionsCallback
 * @see [Autocomplete Service | AutocompletePrediction]{@link https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service}
 * @param {google.maps.places.AutocompletePrediction[]} [AutocompletePrediction] - Defines information about a prediction.
 */

/**
 * @typedef {google.maps.places.AutocompletionRequest} google.maps.places.AutocompletionRequest
 * @see [Autocomplete Service | AutocompletionRequest]{@link https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService.getPlacePredictions}
 * @property {string} input - The user entered input string.
 */

/**
 * @see [Autocomplete Service | Get Place Predictions]{@link https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService.getPlacePredictions}
 * @param {google.maps.places.AutocompleteService} AutocompleteService
 * @param {google.maps.places.AutocompletionRequest} request
 * @param getPlacePredictionsCallback} callback
 */
export const getPlacePredictions = (
  placesService,
  request,
  callback,
) => {
  placesService.getPlacePredictions(
    request,
    (results, status) => {
      // eslint-disable-next-line no-undef
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        callback(results);
      }
    },
  );
};
