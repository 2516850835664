/* eslint-disable  react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Selection from 'ui/Selection';

import {
  List,
} from './styledItems';

const ContingenciesTypesSelect = (props) => {
  const {
    control,
  } = props;

  return (
    <Controller
      name="contingenciesTypes"
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <List>
          <Selection
            options={[
              {
                id: 1,
                label: 'Inspection',
              },
              {
                id: 2,
                label: 'Appraisal',
              },
              {
                id: 3,
                label: 'Loan',
              },
            ]}
            value={field.value}
            onChange={field.onChange}
          />
        </List>
      )}
    />
  );
};

const {
  shape,
} = PropTypes;

ContingenciesTypesSelect.propTypes = {
  control: shape().isRequired,
};

export default ContingenciesTypesSelect;
