import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Joyride from 'react-joyride';

import {
  Button,
  Steps,
  Modal,
} from 'antd';
import { callAlert } from 'ui/Alert';
import TutorialFooterButton from './TutorialFooterButton';

import tutorialRoutes from '../../tutorialRoutes';

const { Step } = Steps;

const cancelButtonType = { type: 'link' };

const arrowColor = {
  System: '#1584ff',
  HR: '#9013fe',
  Sales: '#3bc559',
  Tasks: '#1584ff',
  Tickets: '#ffba4a',
};

const Tutorial = (props) => {
  const [isEndTutorialModalVisible, setIsEndTutorialModalVisible] = useState(false);

  const {
    stepName,
    steps,
    isTutorialRunning,
    stepIndex,
    goToStep,
    name,
    isLaunchModalVisible,
    closeTutorialLaunchModal,
    startTutorial,
    finishTutorial,
    hideTutorialLayer,
    className,
    appTour,
    history,
    showTutorialLayer,
    appTourStep,
    setAppTourStep,
    availableTutorials,
    toggleAppTour,
  } = props;

  const tooltipStyles = {
    options: {
      arrowColor: arrowColor[name],
      overlayColor: 'rgba(0, 0, 0, 0)',
      zIndex: 1005,
    },
    spotlight: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const nextTutorial = useCallback(() => {
    const next = availableTutorials[appTourStep + 1];
    finishTutorial();
    showTutorialLayer();
    history.push(tutorialRoutes[next].root);
    setAppTourStep(appTourStep + 1);
  }, [appTourStep, stepIndex]);

  const finishAppTour = useCallback(() => {
    if (appTour) {
      toggleAppTour();
    }
    finishTutorial();
    history.push('/dashboard');
    setAppTourStep(0);
  }, [appTour, appTourStep]);

  useEffect(() => {
    if (stepName) {
      const index = steps.findIndex((el) => el.name === stepName);
      if (index > -1) {
        goToStep(index);
        setTimeout(() => {
          startTutorial();
        }, 3000);
      } else {
        callAlert.error('Sorry, you do not have access to this tutorial');
        finishTutorial();
      }
    }
    if (appTour) {
      showTutorialLayer();
      startTutorial();
      goToStep(0);
    }
  }, []);

  const handleOk = useCallback(() => {
    // start tutorial
    if (isLaunchModalVisible) {
      goToStep(0);
      closeTutorialLaunchModal();
      startTutorial();

      // finish tutorial
    } else if (isEndTutorialModalVisible) {
      if (appTour) {
        finishAppTour();
      } else finishTutorial();
    }
  }, [isLaunchModalVisible, isEndTutorialModalVisible, appTour]);

  const handleCancel = useCallback(() => {
    // dismiss start of tutorial
    if (isLaunchModalVisible) {
      closeTutorialLaunchModal();
      hideTutorialLayer();

      // dismiss cancel tutorial modal
    } else if (isEndTutorialModalVisible) {
      setIsEndTutorialModalVisible(false);
    }
  }, [isLaunchModalVisible, isEndTutorialModalVisible]);

  const openEndModal = useCallback(() => {
    setIsEndTutorialModalVisible(true);
  }, []);

  const handleNext = useCallback(() => {
    goToStep(stepIndex + 1);
  }, [stepIndex, goToStep]);

  const modalTitle = `Launch ${name} Tutorial?`;
  const customClassName = 'tutorial-tooltip '.concat(className);

  return (
    <div className="tutorial">
      <Joyride
        steps={steps}
        continuous
        run={isTutorialRunning}
        stepIndex={stepIndex}
        disableOverlay={false}
        disableOverlayClose
        disableScrolling
        spotlightClicks={false}
        styles={tooltipStyles}
        tooltipComponent={({ // eslint-disable-line react/jsx-no-bind
          continuous,
          index,
          step, // eslint-disable-line no-shadow
          backProps,
          closeProps,
          primaryProps,
          skipProps,
          tooltipProps,
        }) => (
          // props spreading according to Joyride docs https://docs.react-joyride.com/custom-components#example-with-styled-components-1
          <div
            {...tooltipProps} // eslint-disable-line react/jsx-props-no-spreading
            className={customClassName}
            data-test="tutorial-tooltip"
          >
            <div className="tutorial-tooltip-body">
              <div className="icon-section">
                <div className="oval" />
              </div>
              <div className="content-section">
                <div
                  className="steps-title"
                  data-test="tutorial-step-title"
                >
                  {step.title}
                </div>
                <div className="steps-content">
                  {step.content}
                </div>
              </div>
            </div>
            <div className="tutorial-tooltip-footer">
              <Button
                type="link"
                onClick={openEndModal}
              >
                End
              </Button>
              <Steps
                progressDot
                current={index}
                onChange={goToStep}
              >
                {steps.map((item) => (
                  <Step key={item.name} />
                ))}
              </Steps>
              <TutorialFooterButton
                index={index}
                appTour={appTour}
                appTourStep={appTourStep}
                steps={steps}
                availableTutorials={availableTutorials}
                handleNext={handleNext}
                nextTutorial={nextTutorial}
                finishAppTour={finishAppTour}
                finishTutorial={finishTutorial}
              />
            </div>
          </div>
        )}
      />

      <Modal
        className="tutorial-modal test-start-tutorial-confirmation-modal"
        title={modalTitle}
        visible={isLaunchModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={550}
        mask={false}
        okText="Launch"
        cancelButtonProps={cancelButtonType}
      >
        <p>
          This will launch the&nbsp;
          <span>{name}</span>
          &nbsp;tutorial. You can end it at any time.
          {// Commented out as we moved to new Articles
            // <br />
            // To view all tutorials or text articles instead, please go to the&nbsp;
            // <Link
            //  to="/knowledge-base"
            //  onClick={handleCancel}
            // >
            //  Knowledge Base
            // </Link>
            // .
          }

        </p>
      </Modal>
      <Modal
        className="tutorial-modal"
        wrapClassName="tutorial-end"
        title="Are you sure you want to end this tutorial?"
        visible={isEndTutorialModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={550}
        mask={false}
        okText="End"
        cancelButtonProps={cancelButtonType}
      >
        <p>
          You can relaunch a tutorial any time within the specific product help menu.
          {// Commented out as we moved to new Articles
          //  <br />
          // To view all tutorials or text articles instead, please go to the&nbsp;
          // <Link
          //  to="/knowledge-base"
          //  onClick={handleOk}
          // >
          //  Knowledge Base
          // </Link>
          // .
          }

        </p>
      </Modal>
    </div>
  );
};

const {
  any,
  bool,
  func,
  number,
  string,
  arrayOf,
  objectOf,
} = PropTypes;

Tutorial.defaultProps = {
  isLaunchModalVisible: false,
  isTutorialRunning: false,
  stepIndex: 0,
  stepName: '',
  appTour: false,
  appTourStep: 0,
  availableTutorials: [],
};

Tutorial.propTypes = {
  stepIndex: number,
  isLaunchModalVisible: bool,
  isTutorialRunning: bool,
  name: string.isRequired,
  className: string.isRequired,
  steps: arrayOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
  closeTutorialLaunchModal: func.isRequired,
  startTutorial: func.isRequired,
  hideTutorialLayer: func.isRequired,
  showTutorialLayer: func.isRequired,
  goToStep: func.isRequired,
  finishTutorial: func.isRequired,
  stepName: string,
  appTour: bool,
  history: objectOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
  appTourStep: number,
  setAppTourStep: func.isRequired,
  availableTutorials: arrayOf(any), // eslint-disable-line react/forbid-prop-types
  toggleAppTour: func.isRequired,
};

export default Tutorial;
