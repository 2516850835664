import apiConnector, { endpoints } from 'api';

const message = (messageId) => async (tokenId) => {
  const endpoint = `${endpoints.client.integrations.emails}/${messageId}`;

  try {
    const result = await apiConnector.client.get(endpoint, { tokenId });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default message;
