import React from 'react';
import PropTypes from 'prop-types';

import MuiTab from '@material-ui/core/Tab';
import styled from 'styled-components';

const SecondaryStyledTab = styled(MuiTab)`
  && {
    border-left: 0.5px solid;
    border-right: 0.5px solid;
    border-color: ${(props) => props.theme?.palette?.border || 'darkgrey'};
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }

    background-color: ${(props) => props.theme?.palette?.['background-flat'] || 'grey'};
    &:focus, &.Mui-selected {
      background-color: ${(props) => props.theme?.palette?.background || 'white'};
    }
  }
`;

const StyledTab = styled(MuiTab)`
  && {
    ${(props) => (props.withBottomBorder ? `
        border-bottom: 2px solid;
        border-color: ${props.theme?.palette?.border || 'darkgrey'}};
      ` : '')}
  }
`;

const Tab = (props) => {
  const { variant } = props; // eslint-disable-line react/prop-types

  const TabComponent = variant === 'secondary' ? SecondaryStyledTab : StyledTab;

  return (
    <TabComponent
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};

Tab.defaultProps = {
  variant: 'primary',
  withBottomBorder: false,
};

const { oneOf, bool } = PropTypes;

Tab.propTypes = {
  variant: oneOf(['primary', 'secondary']),
  withBottomBorder: bool,
};

export default Tab;
