import requests from 'api/requests';

import getContactById from './getContactById';

const updateContact = (contactId) => (contactData) => async (dispatch) => {
  try {
    await requests.crm.contacts.update(contactId)(contactData);
    dispatch(getContactById(contactId));
  } catch (error) {
    console.error(error);
  }
};

export default updateContact;
