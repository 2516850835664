import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';

import CommentActionPanel from 'shared/components/CommentActionPanel';
import CommentBody from 'shared/components/CommentBodyV2';
import CallRecordingPlayer from 'sections/Calls/modules/CallRecordingPlayer';

import './styles.scss';

const CallRecordingLogItem = (props) => {
  const {
    callRecordingSid,
    status,
    className,
    createdAt,
    onClick,
    onClickDelete,
  } = props;

  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, max-len
      className={classnames(
        'comment-root',
        {
          [className]: className,
        },
      )}
      onClick={onClick}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          spacing={2}
          alignItems="center"
        >
          <Icon
            name="phone"
            color="primary"
            base="circle"
            baseColor="background-flat"
          />
          <CommentBody
            createdAt={createdAt}
            status={status}
            name="Call Logged"
          />
          <div style={{ width: 450 }}>
            <CallRecordingPlayer
              recordingSid={callRecordingSid}
            />
          </div>
        </Flex>

        <Flex alignItems="center">
          <CommentActionPanel
            className="comment-action-panel-visible"
            onClickDelete={onClickDelete}
            isOwner
          />
        </Flex>
      </Flex>
    </div>
  );
};

const noOp = () => { };

CallRecordingLogItem.defaultProps = {
  className: '',
  createdAt: '',
  onClick: noOp,
  onClickDelete: null,
  callRecordingSid: null,
  status: null,
};

const {
  func,
  string,
} = PropTypes;

CallRecordingLogItem.propTypes = {
  className: string,
  createdAt: string,
  onClick: func,
  onClickDelete: func,
  callRecordingSid: string,
  status: string,
};

export default CallRecordingLogItem;
