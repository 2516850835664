import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Icon from 'ui/Icon';

import {
  Root,
  IconWrapper,
  Wrapper,
} from './styledItems';

const RealtorPropertyPhoto = (props) => {
  const {
    src,
    size,
    className,
  } = props;

  return (
    <Wrapper
      className={classnames({
        [className]: className,
      })}
    >
      {
        src ? (
          <Root
            src={src}
            size={size}
          />
        ) : (
          <IconWrapper size={size}>
            <Icon
              name="property"
              className="property-icon"
              type="custom"
              color="background-flat"
              size="xl"
            />
          </IconWrapper>
        )
      }
    </Wrapper>
  );
};

RealtorPropertyPhoto.defaultProps = {
  src: null,
  className: null,
  size: 133,
};

const {
  string,
  number,
} = PropTypes;

RealtorPropertyPhoto.propTypes = {
  /**
   * image source
   */
  src: string,
  size: number,
  className: string,
};

export default RealtorPropertyPhoto;
