import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_OFFER,
  SET_REALTOR_OFFERS,
  SET_OFFER_ATTACHMENTS,
  SET_REALTOR_OFFERS_VIEW_ITEMS,
  SET_REALTOR_OFFERS_OVERALL_SUMMARY,
  SET_REALTOR_SELECTED_OFFER,
} from 'store/actionTypes/realtorOffers';

import {
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  offers: {},
  offersViewItems: [],
  overallSummary: null,
  selectedOffer: null,
};

const reducers = {
  [SET_REALTOR_OFFERS_OVERALL_SUMMARY]: (state, action) => ({
    ...state,
    overallSummary: action.payload,
  }),

  [SET_REALTOR_OFFER]: (state, action) => ({
    ...state,
    offers: {
      ...state.offers,
      [action.payload.id]: {
        ...action.payload,
        attachments: state.offers[action.payload.id]?.attachments,
      },
    },
  }),

  [SET_REALTOR_OFFERS]: (state, action) => ({
    ...state,
    offers: {
      ...state.offers,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_OFFER_ATTACHMENTS]: (state, action) => ({
    ...state,
    offers: {
      ...state.offers,
      [action.payload.id]: {
        ...state.offers[action.payload.id],
        attachments: action.payload.attachments.map(({ attachmentId }) => attachmentId),
      },
    },
  }),

  [SET_REALTOR_OFFERS_VIEW_ITEMS]: (state, action) => ({
    ...state,
    offersViewItems: action.payload,
  }),

  [SET_REALTOR_SELECTED_OFFER]: (state, action) => ({
    ...state,
    selectedOffer: action.payload,
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
