/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { meetingParticipantType } from 'oa/types/index';
import './style.css';

const MeetingNameList = (props) => {
  const {
    meetingParticipants,
    className,
  } = props;

  return (
    <section className={classNames('meeting-name-list', className)}>
      <h2 className="visually-hidden">Meeting usernames list</h2>

      <ul className="meeting-name-list__inner">
        {meetingParticipants.map((participant) => (
          <li key={participant.socketId} className="meeting-name-list__item">
            <h3 className="meeting-name-list__name">
              {participant.username}
            </h3>
          </li>
        ))}
      </ul>
    </section>
  );
};

MeetingNameList.propTypes = {
  className: PropTypes.string,
  meetingParticipants: PropTypes.arrayOf(meetingParticipantType).isRequired,
};

export default MeetingNameList;
