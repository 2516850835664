import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

export default createSelector(
  (state) => state.followUpReminders.reminders,
  (_, id) => id,
  (collection, id) => {
    const defaultItem = {
      id: null,
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedFollowUpRemindersActions.getFollowUpReminderById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
    };
  },
);
