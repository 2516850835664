import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
  Input,
  Wrapper,
} from './styledItems';

const RadioColor = (props) => {
  const {
    name,
    onChange,
    disabled,
    checked,
    value,
    displayedValue,
  } = props;

  const handleChange = useCallback((event) => {
    if (!disabled) {
      onChange(event.target.value);
    }
  }, [
    onChange,
    disabled,
  ]);

  if (displayedValue) {
    return (
      <Wrapper>
        <Root
          disabled={disabled}
          checked={checked}
          value={value}
        >
          <Input
            onChange={handleChange}
            type="radio"
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
          />
        </Root>
        <Typography
          noMargin
          style={{ marginLeft: 7 }}
        >
          {displayedValue}
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Root
      disabled={disabled}
      checked={checked}
      value={value}
    >
      <Input
        onChange={handleChange}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
    </Root>
  );
};

RadioColor.defaultProps = {
  name: null,
  onChange: () => { },
  checked: false,
  value: false,
  disabled: false,
  displayedValue: null,
};

const {
  bool,
  func,
  string,
} = PropTypes;

RadioColor.propTypes = {
  name: string,
  disabled: bool,
  onChange: func,
  checked: bool,
  value: bool,
  displayedValue: string,
};

export default RadioColor;
