import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Overview from './Overview';

const mapStateToProps = (state, ownProps) => {
    const {
      transactionId,
    } = ownProps;

    const transaction = selectors.realtorTransactions.byId(state, transactionId);

    return {
      data: transaction.repair_requests_summary || {},
    };
  };

export default connect(mapStateToProps)(Overview);
