import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorRepairRequestsActions,
} from 'store/actions/realtorRepairRequests';

const defaultItem = {
  id: null,
};

export default createSelector(
  (state) => state.realtorRepairRequests.repairRequests,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorRepairRequestsActions.getRepairRequestById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
    };
  },
);
