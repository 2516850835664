export const commentsModule = {
  TICKET: 'TICKET',
  TASK: 'TASK',
  EXTERNAL: 'external',
  ATTACHMENT: 'attachment',
  COMMENT_TYPE: {
    COMMENT: 'comment',
    REPLY: 'reply',
    TICKET: 'ticket',
    PROBLEM: 'IsProblem',
  },
  COMMENT_TYPE_TASK: {
    COMMENT: 'comment',
    REPLY: 'reply',
    TASK: 'task',
    PROBLEM: 'IsProblem',
  },
  COMMENT_TYPE_ID: {
    PROBLEM: 4,
  },
  DEFAULT_TYPE: 'select type',
  ICON: {
    AVATAR: 'avatar',
    PHONE: 'phone',
  },
};
