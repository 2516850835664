import store from 'store';

import { isEmpty } from 'shared/utility';

/**
 * @param {string} employeeId
 * @returns {string}
 */
const getAvailabilityCodeByEmployeeId = (employeeId) => {
  const {
    myCompany: {
      employees,
    },
  } = store.getState();

  if (!employeeId || isEmpty(employees)) {
    return null;
  }

  const employee = employees.find((item) => item.EmployeeID === employeeId);

  if (!employee) {
    return null;
  }

  const {
    useravailability,
  } = employee;

  return useravailability;
};

export default getAvailabilityCodeByEmployeeId;
