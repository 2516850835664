import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';
import setFulfillmentPackages from './setFulfillmentPackages';
import getFulfillmentPackages from './getFulfillmentPackages';

const refresh = (fulfillmentId) => async (dispatch) => {
  try {
    dispatch(setFulfillmentPackages(fulfillmentId)([]));
    const packages = await dispatch(getFulfillmentPackages(fulfillmentId)());

    packages.forEach((pckg) => {
      pckg.lineItems.forEach((item) => {
        optimizedLineItemsActions.getLineItemById(item);
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export default refresh;
