import apiConnector, { endpoints } from 'api';

const getFolders = async (routData) => {
  const {
    databaseName,
    objectId,
    objectType,
  } = routData;

  const endpoint = `/public/${databaseName}/${objectType}/${objectId}`;

  const result = await apiConnector.global.get(endpoint);

  return result.data;
};

export default getFolders;
