import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ScrollContainer = styled.div`
  height: ${(props) => props.rows * 102 + props.rows * 16}px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  &::-webkit-scrollbar-track {
    background-color: rgba(128, 128, 128, 0.1);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 3px;
  }
`;
