import apiConnector, { endpoints } from 'api';

const getFolder = async (folderId) => {
  const endpoint = `${endpoints.client.articles.folderGeneral}/${folderId}`;

  const result = await apiConnector.client.get(endpoint);

  return result.data;
};

export default getFolder;
