import connectReducers from 'store/connectReducers';

import { uniqBy } from 'shared/utility';

import {
  CHAT_DRAWER_VISIBLE_ON,
  CHAT_DRAWER_VISIBLE_OFF,

  SET_CHATS,
  SET_CHAT,
  DELETE_CHAT,
  SET_ACTIVE_CHAT_ID,

  APPEND_CHAT_MESSAGE,
  SET_CHAT_THREAD,
  SET_NEW_UNREAD_MESSAGE_COUNT,

  CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_ON,
  CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_OFF,

  SHOW_DELETE_CHAT_WARNING,
  HIDE_DELETE_CHAT_WARNING,

  SHOW_ADD_EMPLOYEE_MODAL,
  HIDE_ADD_EMPLOYEE_MODAL,
} from 'store/actionTypes/chat';

const initialState = {
  isVisible: false,
  isEmployeeSelectVisible: false,
  direct: {
    employee: {},
    customer: {},
  },
  group: [],
  chatRooms: {},
  chatThreads: {},
  chatThreadsLength: {},
  activeChatId: null,
  deleteChatModalWarningVisibility: false,
  newMessageCount: 0,
  isAddEmployeeModalVisible: false,
};

const reducers = {
  [SET_NEW_UNREAD_MESSAGE_COUNT]: (state, action) => ({
    ...state,
    newMessageCount: action.payload.newMessageCount,
  }),

  [SHOW_ADD_EMPLOYEE_MODAL]: (state, action) => ({
    ...state,
    isAddEmployeeModalVisible: true,
  }),

  [HIDE_ADD_EMPLOYEE_MODAL]: (state, action) => ({
    ...state,
    isAddEmployeeModalVisible: false,
  }),

  [SHOW_DELETE_CHAT_WARNING]: (state, action) => ({
    ...state,
    deleteChatModalWarningVisibility: true,
  }),

  [HIDE_DELETE_CHAT_WARNING]: (state, action) => ({
    ...state,
    deleteChatModalWarningVisibility: false,
  }),

  [CHAT_DRAWER_VISIBLE_ON]: (state, action) => ({
    ...state,
    isVisible: true,
  }),

  [CHAT_DRAWER_VISIBLE_OFF]: (state, action) => ({
    ...state,
    isVisible: false,
    activeChatId: null,
  }),

  [CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_ON]: (state, action) => ({
    ...state,
    isEmployeeSelectVisible: true,
  }),

  [CHAT_SELECT_EMPLOYEE_MODAL_VISIBLE_OFF]: (state, action) => ({
    ...state,
    isEmployeeSelectVisible: false,
  }),

  [DELETE_CHAT]: (state, action) => {
    const {
      chatId,
    } = action.payload;

    const newChatRooms = state.chatRooms;
    delete newChatRooms[chatId];

    return {
      ...state,
      chatRooms: newChatRooms,
      activeChatId: null,
    };
  },

  [SET_CHAT]: (state, action) => {
    const {
      newChatData,
    } = action.payload;

    return {
      ...state,
      chatRooms: {
        ...state.chatRooms,
        [newChatData.id]: newChatData,
      },
    };
  },

  [SET_CHATS]: (state, action) => {
    const rawChats = action.payload;

    const {
      direct,
      group,
      chatRooms,
    } = rawChats.reduce((acc, item) => {
      acc.chatRooms[item.id] = item;

      if (item.type === 1) {
        if (item.members.user_type === 1) {
          acc.direct.employee[item.members.user_id] = item.id;
        }

        if (item.members.user_type === 2) {
          acc.direct.customer[item.members.user_id] = item.id;
        }
      }

      if (item.type === 2) {
        acc.group.push(item.id);
      }

      return acc;
    }, {
      direct: {
        employee: {},
        customer: {},
      },
      group: [],
      chatRooms: {},
    });

    return {
      ...state,
      direct,
      group,
      chatRooms,
    };
  },

  [SET_ACTIVE_CHAT_ID]: (state, action) => ({
    ...state,
    chatThreads: {},
    activeChatId: action.payload,
  }),

  [APPEND_CHAT_MESSAGE]: (state, action) => {
    const {
      chatId,
    } = action.payload;

    const threads = { ...state.chatThreads };

    const thread = threads[chatId]
      ? [...threads[chatId]]
      : [];

    thread.push(action.payload.message);

    threads[chatId] = thread;

    return {
      ...state,
      chatThreads: threads,
      chatThreadsLength: {
        ...state.chatThreadsLength,
        [chatId]: state.chatThreadsLength[chatId]
          ? state.chatThreadsLength[chatId] + 1
          : 1,
      },
    };
  },

  [SET_CHAT_THREAD]: (state, action) => {
    const {
      chatId,
      thread,
      total,
    } = action.payload;

    const threads = { ...state.chatThreads };

    let existingThread = threads[chatId]
      ? [...threads[chatId]]
      : [];

    existingThread = uniqBy(existingThread.concat(thread), 'id');

    threads[action.payload.chatId] = existingThread;

    return {
      ...state,
      chatThreads: threads,
      chatThreadsLength: {
        ...state.chatThreadsLength,
        [chatId]: total,
      },
    };
  },
};

function chat(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default chat;
