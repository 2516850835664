import apiConnector, { endpoints } from 'api';

const getEmployeeMentionsSummary = async (employeeId) => {
  const endpoint = `${endpoints.client.employees.mentions}/${employeeId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getEmployeeMentionsSummary;
