import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getCallLogsForStoreFilter,
} from 'store/actions/calls';

import {
  setFilterItem,
} from 'store/actions/callLogsFilter';

import CallLogTable from './CallLogTable';

const mapStateToProps = (state) => {
  const {
    calls: {
      callLogsByFilterCount,
    },
    callLogsFilter: {
      pageNumber,
      pageSize,
      // sortBy,
      // sortDirection,
    },
  } = state;

  const {
    filterId,
  } = selectors.calls.callLogsFilter(state);

  const logList = selectors.calls.callLogsList(state, filterId);

  return {
    itemsCount: callLogsByFilterCount[filterId],
    logList,
    pageNumber,
    pageSize,
    sortBy: 'created_at',
    sortDirection: 'desc',
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLogs: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    dispatch(getCallLogsForStoreFilter(offset, limit));
  },
  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLogTable);
