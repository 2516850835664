import apiConnector, { endpoints } from 'api';

const getById = (leadId) => async (from, to) => {
  const endpoint = `${endpoints.client.crm.leadChangelog}/${leadId}`;

  try {
    const result = await apiConnector.client.get(endpoint, {
      date_range: [from, to],
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getById;
