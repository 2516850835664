import styled from 'styled-components';

import Flex from 'ui/Flex';

const getBackground = (props) => {
  if (props.variant === 'flat') return props.theme?.palette?.backgroundFlat;

  return 'transparent';
};

const getPadding = (props) => {
  if (props.variant === 'flat') return '4px 12px';

  return '0';
};

export const Root = styled(Flex)`
  min-height: 44px;
  border-radius: 8px;
  background-color: ${getBackground};
  padding: ${getPadding};
`;

export default {
  Root,
};
