import styled from 'styled-components';

import Button from 'ui/Button';

export const MenuItem = styled(Button)`
  width: 100%;
  padding: 5px 15px;
  justify-content: flex-start;
  &:hover {
    background-color: ${(props) => props?.theme?.palette?.['background-flat'] || 'red'};
  }
  &:active {
    background-color: ${(props) => props?.theme?.palette?.['background-hover'] || 'green'};
    transition: 0.2s
  }
`;

export const BorderBox = styled.div`
  margin: 10px 15px;
  border-bottom: solid 1px;
  border-color: ${(props) => props?.theme?.palette?.border || 'grey'};
`;
