import apiConnector from 'api';

/**
 * Deletes an entry about the company's logo in the global database
 * @param {string} logoUuid Uuid of the logo to delete
 */
const deleteCompanyLogoInGlobal = (logoUuid) => {
  const endpoint = '/attachments/delete-company-logo';
  apiConnector.global.post(endpoint, { uuid: logoUuid });
};

export default deleteCompanyLogoInGlobal;
