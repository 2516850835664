import { connect } from 'react-redux';
import {
  openMeetingDetailsModal,
  closeMeetingDetailsModal,
  openMeetingAgendaModal,
  closeMeetingAgendaModal,
} from 'store/actions/video-chat/index';
import VideoChat from './VideoChat';

const mapStateToProps = (state) => {
  const {
    activeMeeting,
    isMeetingDetailsModalOpen,
    isMeetingAgendaModalOpen,
  } = state.videoChat;

  const {
    loggedUser,
  } = state.auth;

  return {
    activeMeeting,
    isMeetingDetailsModalOpen,
    isMeetingAgendaModalOpen,
    loggedUser,
  };
};

const mapDispatchToProps = {
  openMeetingDetailsModal,
  closeMeetingDetailsModal,
  openMeetingAgendaModal,
  closeMeetingAgendaModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoChat);
