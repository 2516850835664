import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createDocumentRequest from './create';
import openNewDocumentRequestModal from './openNewDocumentRequestModal';
import addDocumentRequestRelations from './addRelations';

import getDocumentRequestById from './getById';
import getDocumentRequestForFilter from './getForFilter';

import updateDocumentRequest from './update';

import getDocumentRequestAttachments from './getAttachments';

export {
  createDocumentRequest,
  addDocumentRequestRelations,
  openNewDocumentRequestModal,
  getDocumentRequestById,
  getDocumentRequestForFilter,
  updateDocumentRequest,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorDocumentRequestActions = {
  getForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getDocumentRequestForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),

  getById: memoizeDebounce(
    (requestId) => store.dispatch(getDocumentRequestById(requestId)),
    debounceDelay,
    debounceOptions,
  ),

  getAttachments: memoizeDebounce(
    (requestId) => store.dispatch(getDocumentRequestAttachments(requestId)),
    debounceDelay,
    debounceOptions,
  ),
};
