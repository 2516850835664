import apiConnector, { endpoints } from 'api';

/**
 * Sends PATCH request with payload to update "meeting".
 * @param {Object} params - The request params.
 * @param {number} params.id - The id of meeting.
 * @param {Object} params.payload - The fields of meeting.
 * @param {string} params.payload.name - The name of meeting.
 * @param {string} params.payload.code - The code of meeting.
 * @param {?string} params.payload.password - The password of meeting.
 * @param {Array} params.payload.desiredOutcomes - The array of desired outcomes.
 * @returns {Promise} A response from server.
 */

const updateMeeting = async ({ id, payload }) => {
  const endpoint = endpoints.OA.meetingPath(id);
  let response;

  try {
    response = await apiConnector.OA.patch(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default updateMeeting;
