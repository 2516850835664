import { polyfill } from 'mobile-drag-drop';

import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

const isSafari = (name) => name.toLowerCase() === 'safari';
const isFirefox = (name) => name.toLowerCase() === 'firefox';

export const isSafariBrowser = () => {
  const { name } = browser.getBrowser();

  return isSafari(name);
};

export const isFirefoxBrowser = () => {
  const { name } = browser.getBrowser();

  return isFirefox(name);
};

export const isValidBrowser = () => {
  // Bowser browser checker has 8 times more downloads than prev present one...
  const { name, version } = browser.getBrowser();

  if (isSafari(name) && parseInt(version, 10) <= 10) {
    return false;
  }

  return true;
};

export const is8thSafariOrLess = () => {
  const { name, version } = browser.getBrowser();

  if (isSafari(name) && parseInt(version, 10) <= 8) {
    return true;
  }

  return false;
};

export const optionallyApplyDragNDropPolyfill = () => {
  const { name, version } = browser.getBrowser();

  if (isSafari(name) && parseInt(version, 10) === 11) {
    polyfill();
  }
};
