import { connect } from 'react-redux';

import { createPropertyViewingFromNewPropertyViewingForm } from 'store/actions/propertyViewings';

import {
  closeNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  resetNewPropertyViewingForm,
} from 'store/actions/newRealtorPropertyViewingForm';

import NewViewingModal from './NewViewingModal';

const mapStateToProps = (state) => {
  const {
    newPropertyViewingModal: {
      isVisible,
    },
    newPropertyViewingForm,
    newPropertyViewingForm: {
      onSave,
    },
  } = state;

  return {
    isVisible,
    onSave: () => {
      onSave && onSave(newPropertyViewingForm);
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeNewPropertyViewingModal());
    dispatch(resetNewPropertyViewingForm());
  },
  onSave: async () => {
    await dispatch(createPropertyViewingFromNewPropertyViewingForm());
    dispatch(resetNewPropertyViewingForm());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSave: async () => {
    await dispatchProps.onSave();

    if (stateProps.onSave) {
      stateProps.onSave();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(NewViewingModal);
