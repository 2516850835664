import { createSelector } from 'reselect';

const employees = (state) => state.myCompany.employees;
const employeeId = (state, id) => id;

export default createSelector(
  employees,
  employeeId,
  (items, itemId) => {
    const employee = items.find((item) => item.EmployeeID === itemId);

    if (!employee) return null;

    return `${employee.Name} ${employee.Surname}`;
  },
);
