import styled from 'styled-components';

import { palette } from 'shared/components/CRM/UI/variables';

export const Wrapper = styled.div`
  width: fit-content;
`;

export const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.palette.avatarBackground || 'blue'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: ${(props) => (props.round ? '50%' : '8px')};
  text-transform: uppercase;
  overflow: hidden;
`;

export const Initials = styled.div`
  color: white;
  font-size: ${(props) => props.size * 0.413}px;
  font-weight: bold;
  font-family: Roboto, Arial, Helvetica, sans-serif;
`;

export const IconWrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => (props.round ? '50%' : '8px')};
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  /* background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'}; */
`;

export const RoleLabelWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export default {
  Root,
  Wrapper,
  Initials,
  IconWrapper,
  RoleLabelWrapper,
};
