export const SET_PROPERTY_VIEWING = 'SET_PROPERTY_VIEWING';
export const SET_PROPERTY_VIEWINGS = 'SET_PROPERTY_VIEWINGS';
export const SET_SELECTED_PROPERTY_VIEWING = 'SET_SELECTED_PROPERTY_VIEWING';

export const SET_NEW_PROPERTY_VIEWING_MODAL_CLOSED = 'SET_NEW_PROPERTY_VIEWING_MODAL_CLOSED';
export const SET_NEW_PROPERTY_VIEWING_MODAL_OPEN = 'SET_NEW_PROPERTY_VIEWING_MODAL_OPEN';
export const SET_NEW_PROPERTY_VIEWING_FORM_FIELD = 'SET_NEW_PROPERTY_VIEWING_FORM_FIELD';
export const SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD = 'SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD';
export const RESET_NEW_PROPERTY_VIEWING_FORM = 'RESET_NEW_PROPERTY_VIEWING_FORM';

export const SET_PROPERTY_VIEWING_EDIT_MODAL_CLOSED = 'SET_PROPERTY_VIEWING_EDIT_MODAL_CLOSED';
export const SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN = 'SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN';
export const SET_PROPERTY_VIEWING_EDIT_FORM_FIELD = 'SET_PROPERTY_VIEWING_EDIT_FORM_FIELD';
export const SET_PROPERTY_VIEWING_EDIT_FORM_NESTED_FIELD = 'SET_PROPERTY_VIEWING_EDIT_FORM_NESTED_FIELD';
export const RESET_PROPERTY_VIEWING_EDIT_FORM = 'RESET_PROPERTY_VIEWING_EDIT_FORM';

export const SET_VIEWING_NOTES_MODAL_OPEN = 'SET_VIEWING_NOTES_MODAL_OPEN';
export const SET_VIEWING_NOTES_MODAL_CLOSED = 'SET_VIEWING_NOTES_MODAL_CLOSED';

export const SET_REALTOR_VIEWINGS_FOR_FILTER = 'SET_REALTOR_VIEWINGS_FOR_FILTER';
export const SET_REALTOR_VIEWINGS_FOR_FILTER_COUNT = 'SET_REALTOR_VIEWINGS_FOR_FILTER_COUNT';

export const SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER = 'SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER';

export default {
  SET_PROPERTY_VIEWING,
  SET_PROPERTY_VIEWINGS,
  SET_SELECTED_PROPERTY_VIEWING,

  SET_NEW_PROPERTY_VIEWING_MODAL_CLOSED,
  SET_NEW_PROPERTY_VIEWING_MODAL_OPEN,
  SET_NEW_PROPERTY_VIEWING_FORM_FIELD,
  SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD,
  RESET_NEW_PROPERTY_VIEWING_FORM,

  SET_PROPERTY_VIEWING_EDIT_MODAL_CLOSED,
  SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN,
  SET_PROPERTY_VIEWING_EDIT_FORM_FIELD,
  SET_PROPERTY_VIEWING_EDIT_FORM_NESTED_FIELD,
  RESET_PROPERTY_VIEWING_EDIT_FORM,

  SET_VIEWING_NOTES_MODAL_OPEN,
  SET_VIEWING_NOTES_MODAL_CLOSED,

  SET_REALTOR_VIEWINGS_FOR_FILTER,
  SET_REALTOR_VIEWINGS_FOR_FILTER_COUNT,

  SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER,
};
