import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import DropdownTextfield from 'ui/DropdownTextfield';

const styles = { textTransform: 'capitalize' };

const InspectionsSelect = (props) => {
  const {
    onChange,
    value,
    options,
    error,
  } = props;

  const [ownValue, setValue] = useState(value || '');

  useEffect(() => {
    setValue(value);
  }, [
    value,
  ]);

  const handleChange = useCallback((value) => {
    setValue(value);
    onChange(value);
  }, [
    onChange,
  ]);

  return (
    <DropdownTextfield
      error={error}
      fullWidth
      value={ownValue}
      onChange={handleChange}
      options={options}
      style={styles}
      menuItemStyle={styles}
    />
  );
};

InspectionsSelect.defaultProps = {
  value: null,
  options: [],
  error: false,
};

const {
  func,
  string,
  number,
  oneOfType,
  arrayOf,
  shape,
  bool,
} = PropTypes;

InspectionsSelect.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, number]),
  options: arrayOf(shape({
    id: number,
    name: oneOfType([string, number]),
  })),
  error: bool,
};

export default InspectionsSelect;
