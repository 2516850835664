import { connect } from 'react-redux';
import { getUserOptimized } from 'store/actions/users';

import { userTypeNames } from 'constants/employee.constants';
import getAvailabilityBadgeStatus from 'shared/utils/getAvailabilityBadgeStatus';
import getAvatarUrl from 'shared/utils/getAvatarUrl';

import UserAvatar from './UserAvatar';

const userTypes = {
  employee: 1,
  customer: 2,
  salesContact: 3,
};

const mapStateToProps = (state, ownProps) => {
  const {
    users,
    auth: {
      loggedUser,
    },
  } = state;

  const isSympleteUser = !!loggedUser;

  const {
    userId,
    userType,
    noBadge,
  } = ownProps;

  if (!userType) {
    return {
      noBadge: true,
    };
  }

  const isLoggedUserAvatar = loggedUser && loggedUser.EmployeeID === userId;

  const userTypeName = userTypeNames[userType];
  const user = users[userTypeName][userId];

  if (!user) {
    getUserOptimized(userTypeName, userId);

    return {
      avatarImageSrc: '',
      name: '',
      surname: '',
    };
  }

  const avatarImageSrc = getAvatarUrl(user.avatar);

  const calculatedNoBadge = noBadge || userType === userTypes.customer || !isSympleteUser;

  let availabilityBadgeStatus = null;

  if (!calculatedNoBadge) {
    availabilityBadgeStatus = getAvailabilityBadgeStatus(userId);
  }

  return {
    avatarImageSrc,
    name: user.name,
    surname: user.surname,
    noBadge: calculatedNoBadge,
    availabilityBadgeStatus,
    isLoggedUserAvatar,
  };
};

export default connect(mapStateToProps)(UserAvatar);
