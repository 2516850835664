import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

const defaultLead = {
  id: null,
  temp: null,
  status: null,
  contact_id: null,
  source_id: null,
  first_contacted_at: null,
  properties: [],
  property_requirements: [],
};

export default createSelector(
  (state) => state.realtorLeads.leads,
  (state) => state.realtorLeads.selectedLead,
  (collection, selectedId) => {
    const item = collection[selectedId];

    if (!item) {
      if (selectedId) {
        optimizedRealtorLeadsActions.getLeadById(selectedId);
      }

      return defaultLead;
    }

    return item;
  },
);
