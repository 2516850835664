import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import ContactTitleSelect from 'shared/components/UI/ContactTitleSelect';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      personTitle,
    },
  } = state;

  return {
    value: personTitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => {
    dispatch(setNewContactFormField('personTitle')(value))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactTitleSelect);
