import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import createContact from './createContact';
import searchContact from './searchContact';
import checkExistingContact from './checkExistingContact';
import createContactFromNewContactForm from './createContactFromNewContactForm'; // eslint-disable-line import/no-cycle
import createSourceFromRealtorNewContactForm from './createSourceFromRealtorNewContactForm';

import getContacts from './getContacts';
import getContactById from './getContactById';
import getContactsForFilter from './getContactsForFilter';
import getContactsForStoreFilter from './getContactsForStoreFilter'; // eslint-disable-line import/no-cycle

import setSelectedContact from './setSelectedContact';
import setSelectedContactRows from './setSelectedContactRows';

import deleteContact from './deleteContact';
import deleteSelectedContact from './deleteSelectedContact';
import updateContact from './updateContact';
import updateSelectedContact from './updateSelectedContact';
import addContactRelations from './addContactRelations';
import addSelectedContactRelations from './addSelectedContactRelations';
import removeContactRelations from './removeContactRelations';

export { checkExistingContact };
export { createContact };
export { searchContact };
export { createSourceFromRealtorNewContactForm };
export { createContactFromNewContactForm };

export {
  getContacts,
  getContactById,
  getContactsForFilter,
  getContactsForStoreFilter,
  setSelectedContact,
  setSelectedContactRows,
  updateContact,
  updateSelectedContact,
  deleteContact,
  deleteSelectedContact,
  addContactRelations,
  addSelectedContactRelations,
  removeContactRelations,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorContactsActions = {
  getContactsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getContactsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getContactById: memoizeDebounce(
    (contactId) => store.dispatch(getContactById(contactId)),
    debounceDelay,
    debounceOptions,
  ),
  getContactsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getContactsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
};
