import React from 'react';
import PropTypes from 'prop-types';
import './defaultPage.scss';


const DefaultPage = (props) => {
  const {
    hasFooter,
    image,
    logo,
    renderButtons,
    subtitle,
    title,
  } = props;

  return (
    <div className="page-wrapper">
      <header className="page-wrapper-head">
        <div
          className="page-wrapper-head-logo"
          style={{ background: `url(${logo}) no-repeat` }}
        />
      </header>
      <div className="page-wrapper-body">
        <div
          className="page-wrapper-body-image"
          style={{ background: `url(${image}) no-repeat` }}
        />
        <h2 className="page-wrapper-body-title">
          {title}
        </h2>
        <h4 className="page-wrapper-body-subtitle">
          {subtitle}
        </h4>
        {renderButtons()}
      </div>
      {
        hasFooter && (
        <footer className="page-wrapper-footer">
          <a
            href="mailto:support@symplete.com"
            className="page-wrapper-footer-mail"
          >
            support@symplete.com
          </a>
          {
            ' | '
          }
          <a
            href="tel:+8053092548"
            className="page-wrapper-footer-phone"
          >
            (805) 309-2548
          </a>
        </footer>
        )
      }
    </div>
  );
};


DefaultPage.defaultProps = {
  hasFooter: false,
  image: '',
  logo: '',
  renderButtons: () => {},
  subtitle: '',
  title: '',
};

const {
  bool,
  string,
  func,
} = PropTypes;

DefaultPage.propTypes = {
  hasFooter: bool,
  image: string,
  logo: string,
  renderButtons: func,
  subtitle: string,
  title: string,
};

export default DefaultPage;
