import apiConnector, { endpoints } from 'api';

const getTaskLastUpdateInfo = async (taskId) => {
  const query = `?ObjectType=task&ObjectID=${taskId}&last=true`;
  const endpoint = `${endpoints.client.activities}${query}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getTaskLastUpdateInfo;
