import React, {
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Spin from 'ui/Spin';
import Flex from 'ui/Flex';

import CallsContext from 'sections/Calls/context/CallsContext';
import DialpadButton from 'sections/Calls/components/DialpadButton';

const recordingStatusDescriptions = {
  stopped: 'Record',
  'in-progress': 'Stop',
  paused: 'Record',
};

const recordingStatusIcons = {
  stopped: 'dot-circle',
  'in-progress': 'stop-circle',
  paused: 'dot-circle',
};

const CallRecordingControl = (props) => {
  const {
    contactId,
  } = props;

  const context = useContext(CallsContext);

  const {
    startRecording,
    updateRecording,
    recordingStatus,
    recordingStatusPending,
    callStatus,
  } = context;

  const onStart = useCallback(() => {
    startRecording(contactId);
  }, [
    startRecording,
    contactId,
  ]);

  const onUpdate = useCallback((action) => () => {
    updateRecording(action);
  }, [
    updateRecording,
  ]);

  const onClick = useCallback(() => {
    if (recordingStatus === 'stopped') {
      onStart();
    } else if (recordingStatus === 'paused') {
      onUpdate('resume')();
    } else if (recordingStatus === 'in-progress') {
      onUpdate('pause')();
    }
  }, [
    recordingStatus,
    onStart,
    onUpdate,
  ]);

  if (recordingStatusPending) {
    return (
      <Flex
        justifyContent="center"
      >
        <Spin />
      </Flex>
    );
  }

  const buttonDisabled = callStatus !== 'in call';

  return (
    <DialpadButton
      onClick={onClick}
      iconName={recordingStatusIcons[recordingStatus]}
      extraValue={recordingStatusDescriptions[recordingStatus]}
      justifyContent="center"
      disabled={buttonDisabled}
      color={buttonDisabled ? 'disabled' : 'contrast'}
      round
    />
  );
};

CallRecordingControl.defaultProps = {
  contactId: null,
};

const { number } = PropTypes;

CallRecordingControl.propTypes = {
  contactId: number,
};

export default CallRecordingControl;
