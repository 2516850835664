import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background-color: ${(props) => props?.theme?.palette?.['background-flat'] || 'gray'};
`;

export const OrderNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const DraggableContainer = styled.div`
  user-select: none;
  margin-bottom: 8px;
`;

export const DroppableContainer = styled.div`
  width: 100%;
`;
