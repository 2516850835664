import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { updateContact } from 'store/actions/realtorContacts';

import DateInfoItem from 'sections/Leads/components/DateInfoItem';

const mapStateToProps = (state, ownProps) => {
  const { contactId } = ownProps;
  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    label: 'Last Contact:',
    value: contact?.last_contact_date,
    disableFuture: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (date) => {
    const { contactId } = ownProps;

    dispatch(updateContact(contactId)({ last_contact_date: date }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DateInfoItem);
