import React from 'react';
import PropTypes from 'prop-types';

import RadioGroup from 'ui/RadioGroup';

const items = [
  {
    value: 'contingency',
    displayedValue: 'Contingency',
  },
  {
    value: 'due_diligence',
    displayedValue: 'Due Diligence',
  },
];

const ContingencyDueDiligence = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <RadioGroup
      items={items}
      defaultValue={value}
      onChange={onChange}
      flexDirection="row"
      gap="20px"
    />
  );
};

const {
  oneOf,
  func,
} = PropTypes;

ContingencyDueDiligence.propTypes = {
  value: oneOf(['contingency', 'due_diligence']).isRequired,
  onChange: func.isRequired,
};

export default ContingencyDueDiligence;
