import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  StyledModal,
  Text,
  Title,
} from './styledItems';

const DeleteImageWarningModal = (props) => {
  const {
    isDeleteImageWarningModalVisible,
    openedImageModalInformation,
    hideDeleteImageWarningModal,
    triggerAvatarCropModal,
    deleteFile,
    deleteCompanyLogo,
    deleteUserAvatar,
  } = props;

  const deleteImageModuleActions = {
    'employee-logo': deleteUserAvatar,
    'company-logo': deleteCompanyLogo,
    'company-leads-logo': () => {},
    'contact-leads-logo': () => {},
  };

  const onOkClick = useCallback(async () => {
    const {
      appModule,
      imageObjectId,
      fileId,
    } = openedImageModalInformation;
    await deleteFile({
      appModule,
      id: imageObjectId,
      fileId,
    });
    deleteImageModuleActions[appModule]();
    triggerAvatarCropModal(false, appModule);
    hideDeleteImageWarningModal();
  }, [
    openedImageModalInformation,
  ]);

  const onCancelClick = useCallback(() => {
    hideDeleteImageWarningModal();
  }, []);

  return (
    <StyledModal
      visible={isDeleteImageWarningModalVisible}
      onOk={onOkClick}
      zIndex={9999}
      onCancel={onCancelClick}
      okText="I'm Sure"
      centered
    >
      <Title>
        Are you sure?
      </Title>
      <Text>
        Removing the current image will reset this image back to default.
      </Text>
    </StyledModal>
  );
};
const {
  bool,
  func,
  shape,
  string,
  number,
} = PropTypes;

DeleteImageWarningModal.propTypes = {
  isDeleteImageWarningModalVisible: bool.isRequired,
  hideDeleteImageWarningModal: func.isRequired,
  openedImageModalInformation: shape({
    appModule: string,
    imageObjectId: number,
    fileId: number,
  }).isRequired,
  triggerAvatarCropModal: func.isRequired,
  deleteFile: func.isRequired,
  deleteCompanyLogo: func.isRequired,
  deleteUserAvatar: func.isRequired,
};

export default DeleteImageWarningModal;
