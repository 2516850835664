import apiConnector, { endpoints } from 'api';

const updateSyncedEvent = async body => {
  const endpoint = endpoints.client.syncedEvents.general;

  try {
    const result = await apiConnector.client.patch(endpoint, body);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default updateSyncedEvent;
