import React, {
  useCallback,
  useContext,
} from 'react';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Flex from 'ui/Flex';
import Selection from 'ui/Selection';

const ObjectTypeSelector = (props) => {
  const context = useContext(DataImportContext);

  const {
    selectedTypes,
    setSelectedTypes,
    objectTypesOptions,
  } = context;

  const onSetSelectedTypes = useCallback((newValue) => {
    setSelectedTypes(newValue);
  }, [
    setSelectedTypes,
  ]);

  return (
    <Flex
      justifyContent="center"
      fullWidth
    >
      <Selection
        radio
        variant="vertical"
        options={objectTypesOptions}
        value={selectedTypes}
        onChange={onSetSelectedTypes}
      />
    </Flex>
  );
};

export default ObjectTypeSelector;
