import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_DIRECTORY_USER_PROFILE,
} from 'store/actionTypes/realtorDirectory';

const initialState = {
  userProfile: {},
};

const reducers = {
  [SET_REALTOR_DIRECTORY_USER_PROFILE]: (state, action) => ({
    ...state,
    userProfile: {
      ...action.payload,
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
