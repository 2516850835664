import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import DefaultPage from 'components/ErrorPages/DefaultPage';
import { error } from 'constants';
import { ERROR_TYPES } from 'store/actions/errors';


class FilePermission extends React.Component {
  componentDidMount() {
    const { reportError } = this.props;

    reportError && reportError(ERROR_TYPES.FORBIDDEN);
  }

  goBack = () => {
    const { history } = this.props;

    history.goBack();
  }

  renderButtons = () => (
    <Button
      htmlType="button"
      type="primary"
      className="page-wrapper-body-button--back"
      onClick={this.goBack}
      shape="round"
      size="small"
    >
      Go Back
    </Button>
  )

  render() {
    const {
      hasFooter,
      image,
      logo,
      subtitle,
      title,
    } = error.WRONG_FILE;

    return (
      <DefaultPage
        image={image}
        logo={logo}
        title={title}
        subtitle={subtitle}
        hasFooter={hasFooter}
        renderButtons={this.renderButtons}
      />
    );
  }
}


FilePermission.defaultProps = {
  reportError: () => {},
};

const {
  shape,
  func,
} = PropTypes;

FilePermission.propTypes = {
  history: shape({
    goBack: func,
  }).isRequired,
  reportError: func,
};

export default FilePermission;
