import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import getAllLeadsObjectsForFilter from './getAllLeadsObjectsForFilter';

const getAllLeadsObjectsForStoreFilter = (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    filter,
    filterId,
  } = selectors.realtorLeads.leadsObjectsFilter(getState());

  try {
    await dispatch(getAllLeadsObjectsForFilter(filterId)(filter)(offset, limit));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getAllLeadsObjectsForStoreFilter;
