import {
  useState,
  useCallback,
} from 'react';

import { invert } from 'shared/utility';

const nonUniqueFieldsSet = new Set([
  'noImport',
  'notes',
]);

const addressOptions = [
  {
    value: 'address',
    label: 'Full address',
  },
  {
    value: 'symplete_address_destructured_country',
    label: 'Country',
  },
  {
    value: 'symplete_address_destructured_city',
    label: 'City',
  },
  {
    value: 'symplete_address_destructured_state',
    label: 'State',
  },
  {
    value: 'symplete_address_destructured_street',
    label: 'Street',
  },
  {
    value: 'symplete_address_destructured_building',
    label: 'Building',
  },
  {
    value: 'symplete_address_destructured_apartment',
    label: 'Apartment/suite',
  },
  {
    value: 'symplete_address_destructured_zip',
    label: 'Zip/postal code',
  },
  {
    value: 'symplete_address_destructured_line_1',
    label: 'Address line 1',
  },
  {
    value: 'symplete_address_destructured_line_2',
    label: 'Address line 2',
  },
];

const contactOptions = [
  {
    value: 'first_name',
    label: 'First Name',
  },
  {
    value: 'last_name',
    label: 'Last Name',
  },
  {
    value: 'email_primary',
    label: 'Email',
  },
  {
    value: 'phone_primary',
    label: 'Phone',
  },
  {
    value: 'role',
    label: 'Role',
  },
  {
    value: 'facebook_url',
    label: 'Facebook',
  },
  {
    value: 'linkedin_url',
    label: 'LinkedIn',
  },
  {
    value: 'instagram_url',
    label: 'Instagram',
  },
  {
    value: 'twitter_url',
    label: 'Twitter',
  },
  {
    value: 'website_url',
    label: 'Website',
  },
];

const propertyOptions = [
  {
    value: 'expected_price',
    label: 'Expected price',
  },
  {
    value: 'bedrooms',
    label: '# of bedrooms',
  },
  {
    value: 'bathrooms',
    label: '# of bathrooms',
  },
  {
    value: 'parking',
    label: '# of parking spots',
  },
  {
    value: 'floor_area',
    label: 'Floor area, sqft',
  },
  {
    value: 'plot_area',
    label: 'Plot area, acres',
  },
  {
    value: 'year_build',
    label: 'Year built',
  },
];

const notesOptions = [
  {
    value: 'notes',
    label: 'Other notes',
  },
];

const optionsByType = {
  contacts: [
    {
      value: 'noImport',
      label: 'Do not import',
    },
    ...contactOptions,
    ...addressOptions,
    ...notesOptions,
  ],
  leads: [
    {
      value: 'noImport',
      label: 'Do not import',
    },
    ...contactOptions,
    ...addressOptions,
    ...notesOptions,
  ],
  clients: [
    {
      value: 'noImport',
      label: 'Do not import',
    },
    ...contactOptions,
    ...addressOptions,
    ...notesOptions,
  ],
  properties: [
    {
      value: 'noImport',
      label: 'Do not import',
    },
    ...addressOptions,
    ...propertyOptions,
    ...notesOptions,
  ],
};

const fieldLabelDict = [
  ...contactOptions,
  ...addressOptions,
  ...propertyOptions,
  ...notesOptions,
].reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

const useFieldMatch = () => {
  const [uniqueFields, setUniqueFields] = useState({});
  const [nonUniqueFields, setNonUniqueFields] = useState({});
  const [fieldMap, setFieldMap] = useState({});

  const reset = useCallback(() => {
    setUniqueFields({});
    setNonUniqueFields({});
    setFieldMap({});
  });

  const matchRawToSymplete = useCallback((rawField) => (sympleteField) => {
    if (nonUniqueFieldsSet.has(sympleteField)) {
      const newNonUnique = {
        ...nonUniqueFields,
        [rawField]: sympleteField,
      };

      setFieldMap({
        ...uniqueFields,
        ...newNonUnique,
      });

      setNonUniqueFields(newNonUnique);
    } else { // to make sure we map raw fields to Symplete fields 1-1, e.g. only one field can be a first name field
      const invertedUnique = invert(uniqueFields);

      const newUnique = invert({
        ...invertedUnique,
        [sympleteField]: rawField,
      });

      setFieldMap({
        ...nonUniqueFields,
        ...newUnique,
      });

      setUniqueFields(newUnique);
    }
  }, [
    nonUniqueFields,
    uniqueFields,
  ]);

  return {
    fieldMap,
    fieldMatchOptionsByType: optionsByType,
    matchRawToSymplete,
    fieldLabelDict,
    reset,
  };
};

export default useFieldMatch;
