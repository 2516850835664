export const OPEN_TUTORIAL_LAUNCH_MODAL = 'OPEN_TUTORIAL_LAUNCH_MODAL';
export const CLOSE_TUTORIAL_LAUNCH_MODAL = 'CLOSE_TUTORIAL_LAUNCH_MODAL';
export const START_TUTORIAL = 'START_TUTORIAL';
export const END_TUTORIAL = 'END_TUTORIAL';
export const SHOW_TUTORIAL_LAYER = 'SHOW_TUTORIAL_LAYER';
export const HIDE_TUTORIAL_LAYER = 'HIDE_TUTORIAL_LAYER';
export const SET_STEP_INDEX = 'SET_STEP_INDEX';
export const TOGGLE_APP_TOUR = 'TOGGLE_APP_TOUR';
export const SET_AVAILABLE_TUTORIALS = 'SET_AVAILABLE_TUTORIALS';
export const SET_APP_TOUR_STEP = 'SET_APP_TOUR_STEP';
