import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'shared/utility';
import { Card, Slider } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';
import TagLabel from 'shared/components/TagLabel';
import Typography from 'ui/Typography';

import TicketIcons from '../TicketIcons';
import UserAvatar from '../UserAvatarV2';
import UserAvatarArrayWithButton from '../UserAvatarArrayWithButton';

import { TitleText } from './styledItems';

import './TaskCard.scss';

const {
  array,
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

const eventShape = shape({
  action: bool,
  problem: bool,
  question: bool,
});

const agentShape = shape({
  avatar: string,
  name: string,
  surname: string,
});

const statuses = [
  'Backlog',
  'Todo',
  'In Progress',
  'Require Feedback',
  'Done',
  'Archive',
];

const options = {
  icons: {
    spacing: 7,
    size: 'sm',
  },
  avatar: {
    offsetItem: 15,
    defaultOffset: -25,
  },
};

const CardActions = ({ events, Subtasks, compact }) => {
  const {
    icons: { size, spacing },
  } = options;

  return (
    <div
      className={classnames({
        'task-card__actions': true,
        'task-card__actions__compact': compact,
      })}
    >
      <TicketIcons events={events} size={compact ? 'xs' : size} spacing={spacing} />
      {Subtasks && Subtasks.length && !compact ? (
        <CardSubtasks
          done={Subtasks.filter((task) => task.Resolved).length}
          count={Subtasks.length}
        />
      ) : null}
    </div>
  );
};

CardActions.defaultProps = {
  Subtasks: null,
  compact: false,
};

CardActions.propTypes = {
  events: eventShape.isRequired,
  Subtasks: array, // eslint-disable-line react/forbid-prop-types
  compact: bool,
};

const CardTitle = ({ title, deadline, status, compact }) => { // eslint-disable-line object-curly-newline
  const renderDeadline = (deadlineDate) => {
    if (!deadlineDate || compact) {
      return null;
    } if (deadlineDate === 'OVERDUE' && status === 'Done') {
      return (
        <span className="ticket-age ticket-age--done"> (Done) </span>
      );
    } if (deadlineDate === 'OVERDUE') {
      return (
        <span className="ticket-age ticket-age--overdue">
          (Overdue)
        </span>
      );
    }
    return (
      <span className="ticket-age">
        (
        {deadlineDate}
        )
      </span>
    );
  };

  return (
    <div
      className={classnames({
        'task-card__title': !compact,
        'task-card__title task-card__title__compact': compact,
      })}
    >
      <TitleText
        compact={compact}
        variant="h4"
      >
        {createHtmlMarkup(title)}
      </TitleText>
      {renderDeadline(deadline)}
    </div>
  );
};

CardTitle.defaultProps = {
  compact: false,
};

CardTitle.propTypes = {
  deadline: string.isRequired,
  status: string.isRequired,
  title: string.isRequired,
  compact: bool,
};

const CardSubtasks = ({ count, done }) => (
  <div className="task-card__subtasks">
    <div className="task-card__subtasks-icon">
      <FontAwesomeIcon icon="list" />
    </div>
    <div className="task-card__subtasks-count">
      {done}
      {' '}
      /
      {count}
    </div>
  </div>
);

CardSubtasks.defaultProps = {
  count: 0,
  done: 0,
};

CardSubtasks.propTypes = {
  count: number,
  done: number,
};

const CardFooter = ({
  agents,
  label,
  taskID,
  team,
  compact,
  events,
}) => {
  const {
    avatar: {
      defaultOffset,
      offsetItem,
    },
  } = options;

  return (
    <div
      className={classnames({
        'task-card__footer': !compact,
        'task-card__footer task-card__footer__compact': compact,
      })}
    >
      <div className="task-card__footer-meta">
        <Typography
          variant="h5"
          tag="span"
        >
          {taskID}
        </Typography>
        {team && (
          <Typography
            variant="h6"
            tag="span"
          >
            {team}
          </Typography>
        )}
      </div>

      <div
        className={classnames({
          'task-small-label-and-users': true,
          'task-small-label-and-users__compact': compact,
        })}
      >
        {
          compact && (
            <CardActions events={events} compact />
          )
        }
        {
          !isEmpty(label) && (
            <TagLabel
              color={`${label.color}`}
              className={classnames({ 'task-card__footer__label-compact': compact })}
            >
              { label.name }
            </TagLabel>
          )
        }
        {
          !compact && (
            <UserAvatarArrayWithButton
              offsetItem={offsetItem}
              defaultOffset={defaultOffset}
            >
              {agents.map(({ id }) => (
                <UserAvatar
                  key={id}
                  userType={1}
                  userId={id}
                  tooltipPlacement="bottom"
                  badgeVisibility="hidden"
                  size="xxs"
                />
              ))}
            </UserAvatarArrayWithButton>
          )
        }
      </div>
    </div>
  );
};

CardFooter.defaultProps = {
  agents: [],
  label: {},
  compact: false,
  events: {},
};

CardFooter.propTypes = {
  agents: array, // eslint-disable-line react/forbid-prop-types
  label: shape({}),
  taskID: string.isRequired,
  team: string.isRequired,
  compact: bool,
  events: eventShape,
};

class TaskCard extends PureComponent {
  onCardClick = () => {
    const { onCardClick, taskID } = this.props;
    return onCardClick(taskID);
  };

  tipFormatter = (progress) => `${progress}%`

  render() {
    const {
      className,
      progress,
      status,
      overlayText,
      blocked,
      isTimerActive,
      compact,
    } = this.props;
    return (
      <Card
        className={classnames('task-card', {
          [className]: className,
        })}
        onClick={this.onCardClick}
      >
        {blocked && (
          <div className="task-card__overlay">
            <div>Object is momentary unavailable</div>
            <div>{overlayText}</div>
          </div>
        )}
        {isTimerActive && (
          <div className="task-card__check-icon">
            <FontAwesomeIcon icon="check" size="2x" />
          </div>
        )}
        <Slider
          value={progress}
          dots={false}
          tipFormatter={this.tipFormatter}
          className={status}
        />
        <CardTitle
          // TODO - refactor to remove spreading
          {...this.props} // eslint-disable-line react/jsx-props-no-spreading
        />
        {
          !compact && (
            <CardActions
              {...this.props} // eslint-disable-line react/jsx-props-no-spreading
            />
          )
        }
        <CardFooter
          {...this.props} // eslint-disable-line react/jsx-props-no-spreading
        />
      </Card>
    );
  }
}

TaskCard.defaultProps = {
  agents: [],
  blocked: false,
  className: null,
  events: {
    problem: false,
    action: false,
    question: false,
  },
  isTimerActive: false,
  onCardClick: () => {},
  overlayText: '',
  progress: 0,
  status: 'Todo',
  ticketAge: '',
  title: 'N/A',
  compact: false,
};

TaskCard.propTypes = {
  agents: arrayOf(agentShape),
  blocked: bool,
  className: string,
  events: eventShape,
  isTimerActive: bool,
  onCardClick: func,
  overlayText: string,
  progress: number,
  status: oneOf(statuses),
  taskID: number.isRequired,
  ticketAge: oneOfType([number, string]),
  title: string,
  compact: bool,
};

export default TaskCard;
