const navbarColors = [
    { name: 'defaultNav', color: '#141736' },
    { name: 'midBlueNav', color: '#0271A4' },
    { name: 'greenNav', color: '#248a3d' },
    { name: 'purpleNav', color: '#3634a3' },
    { name: 'redNav', color: '#B2023A' },
    { name: 'violetNav', color: '#8944ab' },
    { name: 'tealNav', color: '#008199' },
    { name: 'lightBlueNav', color: '#4387F5' },
  ];

export default navbarColors;
