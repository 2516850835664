import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Avatar from 'shared/components/UI/RealtorContactAvatar/RealtorContactAvatar';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Spin from 'ui/Spin';

import {
  ProfileWrapper,
  LocationTagWrapper,
} from './styledItems';

const RealtorProfile = (props) => {
  const {
    src,
    fullName,
    officeName,
    isClaimed,
    claimProfile,
  } = props;

  const [loading, setLoading] = useState(false);

  const onClaim = useCallback(async () => {
    setLoading(true);
    await claimProfile();
    setLoading(false);
  }, [
    claimProfile,
  ]);

  return (
    <>
      <ProfileWrapper>
        <Flex
          justifyContent="stretch"
          alignItems="stretch"
          spacing={1}
        >
          <Avatar
            src={src}
            size={65}
            round
          />
          <Flex
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Typography
              variant="title3"
              weight="bold"
              noMargin
            >
              {fullName}
            </Typography>

            {
              officeName && (
                <LocationTagWrapper>
                  <Typography
                    variant="title4"
                    color="focus"
                    noMargin
                  >
                    {officeName}
                  </Typography>
                </LocationTagWrapper>
              )
            }
          </Flex>
        </Flex>
      </ProfileWrapper>

      <Spin spinning={loading}>
        <Button
          variant="primary"
          disabled={isClaimed}
          onClick={onClaim}
        >
          {
            isClaimed ? 'Profile Claimed' : 'Claim My Profile'
          }
        </Button>
      </Spin>
    </>
  );
};

const {
  string,
  bool,
  func,
} = PropTypes;

RealtorProfile.propTypes = {
  src: string.isRequired,
  fullName: string.isRequired,
  officeName: string.isRequired,
  isClaimed: bool.isRequired,
  claimProfile: func.isRequired,
};

export default RealtorProfile;
