import { connect } from 'react-redux';
import CompanyNotices from './CompanyNotices';
import {
  addNotice, deleteNotice, acknowledgeNotice, seenNotice,
} from '../../../store/actions/dashboard';

const mapStateToProps = (state) => ({
  loggedUser: state.auth.loggedUser,
  employees: state.myCompany.employees,
  notices: state.dashboard.noticesList,
});

const mapDispatchToProps = {
  addNotice,
  deleteNotice,
  acknowledgeNotice,
  seenNotice,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyNotices);
