import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const Label = (props) => {
  const {
    label,
    color,
  } = props;

  return (
    <Root
      color={color}
    >
      <Typography variant="label" color="main-text-inversed">
        {label}
      </Typography>
    </Root>
  );
};

Label.defaultProps = {
  label: 'TEXT',
  color: '#1584ff',
};

const {
  string,
} = PropTypes;

Label.propTypes = {
  label: string,
  color: string,
};

export default Label;
