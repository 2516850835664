import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProspect,
} from 'store/actions/realtorProspects';

const withProspectData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const prospect = selectors.realtorProspects.byId(state, ownProps.prospectId);

    return {
      prospect,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (fieldName) => (value) => {
      dispatch(updateProspect(ownProps.prospectId)({ [fieldName]: value }));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withProspectData;
