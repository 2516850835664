/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import PropertyMultiTypeSelect from 'sections/Leads/inputs/PropertyMultiTypeSelect';

const PropertyTypeFilter = (props) => {
  const {
    control,
    searchMLS,
  } = props;

  const handleChange = useCallback((onChange) => (value) => {
    onChange(value);
  }, []);

  return (
    <Controller
      name="filters.propertyType"
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <PropertyMultiTypeSelect
          onBlur={searchMLS}
          onChange={handleChange(field.onChange)}
        />
      )}
    />
  );
};

const {
  shape,
  func,
} = PropTypes;

PropertyTypeFilter.propTypes = {
  control: shape().isRequired,
  searchMLS: func.isRequired,
};

export default PropertyTypeFilter;
