import getFulfillmentProductLineItems from './getFulfillmentProductLineItems';

const getSelectedFulfillmentProductLineItems = (productId) => (status) => (offset = 0, limit = 50) => async (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  try {
    dispatch(getFulfillmentProductLineItems(selectedFulfillment)(productId)(status)(offset, limit));
  } catch (error) {
    console.error(error);
  }
};

export default getSelectedFulfillmentProductLineItems;
