import {
  SET_OFFER_DECLINE_REASON_MODAL_OPEN,
} from 'store/actionTypes/realtorOpportunities';

const openOfferDeclineReasonModal = (offerId, readOnly) => ({
  type: SET_OFFER_DECLINE_REASON_MODAL_OPEN,
  payload: {
    offerId,
    readOnly,
  },
});

export default openOfferDeclineReasonModal;
