import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import ListInput from 'ui/ListInput';

import SpecialConditionsItem from './SpecialConditionsItem';

const SpecialConditions = (props) => {
  const {
    value,
    onChange,
  } = props;

  const handleChange = useCallback((inputIndex) => (newValue) => {
    const specialConditionsList = [...value];

    specialConditionsList[inputIndex] = newValue;

    if (inputIndex === specialConditionsList.length - 1 && newValue.value) {
      specialConditionsList.push({ value: '', isSelected: false, isNew: true });
    }

    onChange(specialConditionsList);
  }, [
    value,
    onChange,
  ]);

  return (
    <ListInput
      value={value}
      onChange={onChange}
      customListItem={SpecialConditionsItem}
      customHandleChange={handleChange}
    />
  );
};

const {
  func,
  arrayOf,
  shape,
} = PropTypes;

SpecialConditions.propTypes = {
  value: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
};

export default SpecialConditions;
