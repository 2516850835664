import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorRepairRequestsActions,
} from 'store/actions/realtorRepairRequests';

export default createSelector(
  (state) => state.realtorRepairRequests.repairRequests,
  (state, filterId) => state.realtorRepairRequests.repairRequestsByFilter[filterId],
  (repairRequests, filteredIds) => {
    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((accum, id) => {
      const repairRequest = repairRequests[id];

      if (!repairRequest && id) {
        optimizedRealtorRepairRequestsActions.getRepairRequestById(id);
        return accum;
      }

      accum.push(repairRequest);
      return accum;
    }, []);

    return result;
  },
);
