import connectReducers from 'store/connectReducers';
import { groupBy } from 'shared/utility';

import {
  SET_AGREEMENT,
  SET_AGREEMENTS,
  SET_SELECTED_AGREEMENT,
  SET_AGREEMENT_ATTACHMENTS,
} from 'store/actionTypes/agreements';

const initialState = {
  agreements: {},
  selectedAgreement: null,
};

const agreementsListToObject = (items = []) => items.reduce((invoiceObj, item) => {
  // param reassign is used in reduce by design
  invoiceObj[item.id] = item; // eslint-disable-line no-param-reassign
  return invoiceObj;
}, {});

const reducers = {
  [SET_SELECTED_AGREEMENT]: (state, action) => ({
    ...state,
    selectedAgreement: action.payload,
  }),

  [SET_AGREEMENT]: (state, action) => ({
    ...state,
    agreements: {
      ...state.agreements,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_AGREEMENTS]: (state, action) => ({
    ...state,
    agreements: {
      ...state.agreements,
      ...agreementsListToObject(action.payload),
    },
  }),

  [SET_AGREEMENT_ATTACHMENTS]: (state, action) => ({
    ...state,
    agreements: {
      ...state.agreements,
      [action.payload.id]: {
        ...state.agreements[action.payload.id],
        attachments: groupBy(action.payload.attachments, 'attachmentType'),
      },
    },
  }),
};

function agreementsReducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default agreementsReducer;
