import {
  CHAT_DRAWER_VISIBLE_ON,
  CHAT_DRAWER_VISIBLE_OFF,
} from 'store/actionTypes/chat';

export const openChatDrawer = () => (dispatch) => {
  dispatch({
    type: CHAT_DRAWER_VISIBLE_ON,
  });
};

export const closeChatDrawer = () => (dispatch) => {
  dispatch({
    type: CHAT_DRAWER_VISIBLE_OFF,
  });
};
