import newApiConnector, { endpoints } from 'api';
/**
 * Sends an email with contact request from onboarding process to Symplete support
 * @param {object} contactData Contact values
 */
const onboardingRequestContact = async (contactData) => {
  const endpoint = endpoints.global.requestContact.onboarding;

  await newApiConnector.global.post(endpoint, contactData);
};

export default onboardingRequestContact;
