/* eslint-disable react/require-default-props */

import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import AssignModal from 'shared/components/AssignUserV2/AssignModal/AssignModal';
import { userType, meetingType } from 'oa/types/index';
import InviteUsersForm from './InviteUsersForm';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const InviteUsersModal = (props) => {
  const {
    inviteUsersToMeeting,
    callUsers,
    isUserAlreadyInMeeting,
    onlineUsers,
    isVisible,
    onClose,
    meeting,
  } = props;

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const formRef = useRef(null);

  const availableUsersIds = onlineUsers.reduce((ids, onlineUser) => {
    if (!isUserAlreadyInMeeting(onlineUser.EmployeeID)) {
      ids.push(onlineUser.EmployeeID);
    }

    return ids;
  }, []);

  const handleInviteUsers = () => {
    const { form } = formRef.current;

    form.validateFields((errors, values) => {
      if (errors) return;

      const emailsValidation = validateEmailList(values.emails);

      if (!emailsValidation.isValid) {
        form.setFields({
          emails: {
            value: values.emails,
            errors: [new Error('Emails list is invalid.')],
          },
        });
      } else {
        form.resetFields();
        inviteUsersToMeeting({
          emails: emailsValidation.parsedList,
          meeting,
        });
      }
    });
  };

  const handleFormSubmit = useCallback((event) => {
    event.preventDefault();
    handleInviteUsers();
  }, [handleInviteUsers]);

  const renderTopPanel = useCallback((usersFormProps) => (
    <InviteUsersForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...usersFormProps}
      wrappedComponentRef={formRef}
      onSubmit={handleFormSubmit}
    />
  ), [formRef, handleFormSubmit]);

  const onOk = useCallback(() => {
    if (selectedUserIds.length === 0) {
      handleInviteUsers();
    } else {
      onClose();

      callUsers({
        employeeIds: selectedUserIds,
        meeting,
      });

      setSelectedUserIds([]);
    }
  }, [
    selectedUserIds,
    handleInviteUsers,
    onClose,
    callUsers,
    meeting,
  ]);

  return (
    <AssignModal
      title="Select a user to start chat"
      okText="Add to call"
      isModalVisible={isVisible}
      handleSelectedUsersChange={setSelectedUserIds}
      selectedUsersIds={selectedUserIds}
      onClickOk={onOk}
      closeModal={onClose}
      availableUsersIds={availableUsersIds}
      renderTopPanel={renderTopPanel}
      notCloseOnOk
    />
  );
};

const validateEmailList = (raw) => {
  const list = raw.split(',');

  const formattedList = list.reduce((collection, email) => {
    const formattedEmail = email.replace(/\s/g, '');

    if (formattedEmail) {
      collection.push(formattedEmail);
    }

    return collection;
  }, []);

  const invalidEmail = formattedList.find((email) => (
    !EMAIL_REGEX.test(email)
  ));

  const isListValid = !invalidEmail;

  return {
    isValid: isListValid,
    parsedList: formattedList,
  };
};

InviteUsersModal.propTypes = {
  inviteUsersToMeeting: PropTypes.func.isRequired,
  callUsers: PropTypes.func.isRequired,
  isUserAlreadyInMeeting: PropTypes.func.isRequired,
  onlineUsers: PropTypes.arrayOf(userType).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  meeting: meetingType.isRequired,
};

export default InviteUsersModal;
