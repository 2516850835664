/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import FormTextfield from 'shared/inputs/FormTextfield';

const OfferDeclineReasonInput = (props) => {
  const {
    control,
  } = props;

  return (
    <Controller
      name="decline_reason"
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <FormTextfield
          label="Leave a note about why this offer is being declined"
          multiline
          rows={3}
          {...field}
          handleChange={field.onChange}
        />
      )}
    />
  );
};

const {
  shape,
} = PropTypes;

OfferDeclineReasonInput.propTypes = {
  control: shape().isRequired,
};

export default OfferDeclineReasonInput;
