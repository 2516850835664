import getDownloadUrl from 'shared/utils/getImageUrl';
import getPreviewUrl from 'shared/utils/getPreviewUrl';
import getThumbnailUrl from 'shared/utils/getThumbnailUrl';

const imageExtensions = {
  bmp: true,
  gif: true,
  jpg: true,
  jpeg: true,
  png: true,
  svg: true,
};

const videoExtensions = {
  webm: true,
  mp4: true,
};

const videoThumbPath = '/assets/img/playicon.png';
const fileThumbPath = '/assets/img/file.png';

const getFileExtension = (filename) => filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;

// eslint-disable-next-line max-params
const getThumbImage = (attachmentId, attachmentUrl, acl, extension) => {
  let thumbUrl;

  if (imageExtensions[extension]) {
    thumbUrl = getThumbnailUrl(attachmentId, attachmentUrl, acl);
  } else if (videoExtensions[extension]) {
    thumbUrl = videoThumbPath;
  } else {
    thumbUrl = fileThumbPath;
  }

  return thumbUrl;
};

const processAttachment = ((file) => {
  if (file.isExternalLink) {
    return {
      isExternalLink: true,
      url: file.url || file.Url,
      attachmentId: file.attachmentId || file.Uuid,
    };
  }
  const attachmentId = file.attachmentId || file.Uuid;
  const originalName = file.originalName || file.OriginalName;
  const acl = file.acl || file.Acl;
  const attachmentUrl = file.url || file.Url;

  const extension = getFileExtension(originalName);

  const defaultUrl = getDownloadUrl(attachmentId, attachmentUrl, acl);
  const previewUrl = getPreviewUrl(attachmentId, attachmentUrl, acl);
  const thumbUrl = getThumbImage(attachmentId, attachmentUrl, acl, extension);

  return {
    attachmentId,
    thumbUrl,
    previewUrl,
    url: defaultUrl,
    name: originalName,
    extension,
    size: file.size || file.Size,
    title: file.title || file.Title || '',
  };
});

export default processAttachment;
