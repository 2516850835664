import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateFollowUpReminder,
  deleteFollowUpReminder,
  getFollowUpReminderById,
} from 'store/actions/followUpReminders';

import {
  openModal,
} from 'store/actions/modals';

import { OBJECT_TYPES } from 'constants';

import FollowUpCard from 'sections/FollowUps/components/FollowUpCard';

const objectTypes2selectors = {
  'realtor lead': selectors.realtorLeads.byId,
  'realtor client': selectors.realtorClients.byId,
  'realtor prospect': selectors.realtorProspects.byId,
};

const objectTypes2labels = {
  'realtor lead': 'Lead',
  'realtor client': 'Client',
  'realtor prospect': 'Prospect',
  'realtor contact': 'Contact',
};

const mapStateToProps = (state, ownProps) => {
  const { reminderId } = ownProps;

  const reminder = selectors.followUpReminders.byId(state, reminderId);
  if (!reminder.id) {
    return {};
  }

  let contactId;

  const relatedObjectType = OBJECT_TYPES[reminder.object_type];

  if (relatedObjectType === 'realtor contact') {
    contactId = reminder.object_id;
  } else if (!objectTypes2selectors[relatedObjectType]) {
    return {};
  } else {
    const relatedObject = objectTypes2selectors[relatedObjectType]
      && objectTypes2selectors[relatedObjectType](state, reminder.object_id);

    contactId = relatedObject?.contact_id || relatedObject?.realtor_contact_id;
  }

  return {
    contactId,
    label: objectTypes2labels[relatedObjectType],
    title: reminder.title,
    comment: reminder.comment,
    scheduledAt: reminder.scheduled_date,
    status: reminder.status,
    unsuccessful: reminder.is_unsuccessful,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onComplete: () => {
    dispatch(updateFollowUpReminder(ownProps.reminderId)({ status: 6 }));
  },
  onDelete: async () => {
    await dispatch(deleteFollowUpReminder(ownProps.reminderId));
    dispatch(getFollowUpReminderById(ownProps.reminderId));
  },
  onDetails: () => {
    dispatch(openModal('editFollowUpReminder')({}, { reminderId: ownProps.reminderId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpCard);
