import React from 'react';
import PropTypes from 'prop-types';

import { isUndefined } from 'shared/utility';

import Flex from 'ui/Flex';

import BaseIndicator from 'shared/components/UI/PropertyParameters/BaseIndicator';

const indicatorKeys = [
  'floorArea',
  'bedrooms',
  'bathrooms',
  'plotArea',
  'garageCapacity',
];

const indicatorIcons = {
  bedrooms: 'bed',
  bathrooms: 'bathroom',
  floorArea: 'height',
  plotArea: 'maximize',
  garageCapacity: 'garage',
};
const indicatorIconTypes = {
  bedrooms: 'custom',
  bathrooms: 'custom',
  floorArea: 'custom',
  plotArea: 'feather',
  garageCapacity: 'custom',
};

const indicatorDescriptions = {
  floorArea: 'sqft',
  plotArea: 'acre',
};

const PropertyParametersIndicators = (props) => {
  const {
    data,
    showDescription,
    indicatorProps,
  } = props;

  return (
    <Flex spacing={1}>
      {
        indicatorKeys.reduce((acc, key) => {
          if (!isUndefined(data[key])) {
            acc.push(
              <BaseIndicator
                value={data[key]}
                icon={indicatorIcons[key]}
                iconType={indicatorIconTypes[key]}
                description={showDescription ? indicatorDescriptions[key] : null}
                {...indicatorProps}
                variant="focus"
              />,
            );
          }

          return acc;
        }, [])
      }
    </Flex>
  );
};

PropertyParametersIndicators.defaultProps = {
  data: {},
  showDescription: true,
  indicatorProps: {},
};

const {
  bool,
  number,
  objectOf,
  shape,
} = PropTypes;

PropertyParametersIndicators.propTypes = {
  data: objectOf(number),
  showDescription: bool,
  indicatorProps: shape(),
};

export default PropertyParametersIndicators;
