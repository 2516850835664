import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import SelectedTransactionRepairRequests from 'sections/Transactions/modules/SelectedTransactionRepairRequests';

const RepairRequestModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
  } = props;

  return (
    <Modal
      theme={theme}
      width={1400}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Repair Requests"
      footer={null}
    >
      <SelectedTransactionRepairRequests />
    </Modal>
  );
};

RepairRequestModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  func,
  bool,
  shape,
} = PropTypes;

RepairRequestModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
};

export default RepairRequestModal;
