import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import UserAvatar from 'shared/components/UserAvatarV2';

import './styles.scss';

const rootClassName = 'assign-list-item';

const AssignListItem = (props) => {
  const {
    userId,
    name,
    role,
    onClick,
    isSelected,
    hideAvatar,
  } = props;

  const rootClass = classnames(rootClassName, {
    [`${rootClassName}--is-selected`]: isSelected,
  });

  return (
    <button
      type="button"
      className={rootClass}
      onClick={onClick}
    >
      {
        !hideAvatar && (
          <UserAvatar
            userType={1}
            userId={userId}
            size="xs"
          />
        )
      }
      <div className={`${rootClassName}__content`}>
        <div className={`${rootClassName}__name`}>
          {name}
        </div>
        {
          role && (
            <div className={`${rootClassName}__role`}>
              {role}
            </div>
          )
        }
      </div>
    </button>
  );
};

AssignListItem.defaultProps = {
  isSelected: false,
  name: null,
  role: null,
  hideAvatar: false,
};

const {
  bool,
  func,
  number,
  string,
} = PropTypes;

AssignListItem.propTypes = {
  isSelected: bool,
  name: string,
  onClick: func.isRequired,
  role: string,
  userId: number.isRequired,
  hideAvatar: bool,
};

export default AssignListItem;
