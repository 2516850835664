import { connect } from 'react-redux';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import Appbar from './Appbar';

const getProductFromPathname = (pathname) => {
  const section = pathname.split('/')[1];

  return section;
};

const requiredPermissions = {
  team: 'HR',
  'customer-service': 'Customer Support',
  sales: 'Sales',
  tasks: 'Tasks',
  'knowledge-base': 'Symplete',
  'company-settings': 'Company Settings',
  'mood-dashboard': 'HR',
};

const mapStateToProps = (state, ownProps) => {
  const section = getProductFromPathname(ownProps.pathname);

  const showSectionTools = requiredPermissions[section]
    ? checkLoggedEmployeePermissions.isUser(requiredPermissions[section])
    : true;

  return {
    section,
    showSectionTools,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
