import requests from 'api/requests';

import getById from './getDislikeById';

const removeDislikeRelations = (relationType) => (dislikeId) => (data) => async (dispatch) => {
  try {
    await requests.dislikes.removeRelations(relationType)(dislikeId)(data);

    dispatch(getById(dislikeId));
  } catch (error) {
    console.error(error);
  }
};

export default removeDislikeRelations;
