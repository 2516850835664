import { connect } from 'react-redux';

import selectors from 'store/selectors';

import OffersItem from './OffersItem';

const mapStateToProps = (state, ownProps) => {
  const {
    realtor_property_id,
    price,
  } = ownProps;

  const property = selectors.properties.byId(state, realtor_property_id);

  const address = selectors.addresses.byId(state, property?.address_id)?.address;
  const isInMyListings = property?.listing_agent === 0;

  const image = selectors.properties.propertyMainImage(state, realtor_property_id);

  return {
    address,
    isInMyListings,
    price: price || property?.expected_price,
    image: image?.previewUrl,
  };
};

export default connect(mapStateToProps)(OffersItem);
