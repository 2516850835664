import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';
import DateTimePicker from 'react-datetime-picker';

import {
  Wrapper,
} from './styledItems';

const DateTimeInput = (props) => {
  const {
    handleChange,
    value,
    // label,
    error,
    disablePast,
  } = props;

  const [dateValue, setDateValue] = useState(value || null);

  useEffect(() => {
    setDateValue(value || null);
  }, [
    value,
  ]);

  const onChange = useCallback((dateTime) => {
    setDateValue(dateTime);
    handleChange(dateTime);
  }, [
    handleChange,
  ]);

  const minDate = useMemo(() => (disablePast ? new Date() : null), [
    disablePast,
  ]);

  return (
    <Wrapper>
      <DateTimePicker
        onChange={onChange}
        value={dateValue}
        disableClock
        minDate={minDate}
        format="MM-dd-y hh:mm a"
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        yearPlaceholder="YYYY"
        hourPlaceholder="hh"
        minutePlaceholder="mm"
      />
    </Wrapper>
  );
};

DateTimeInput.defaultProps = {
  value: null,
  disablePast: false,
  label: 'First contact date',
  error: false,
};

const {
  func,
  string,
  bool,
} = PropTypes;

DateTimeInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
  error: bool,
  disablePast: bool,
  label: string,
};

export default DateTimeInput;
