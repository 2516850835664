import requests from 'api/requests';
import { common } from 'constants';

import {
  filePreloader,
} from 'store/actions/attachments';

import setAttachments from './setAttachments';

const getAttachmentsList = (objectType) => (objectId) => async (dispatch) => {
  let parentObjectType;

  if (Number.isInteger(objectType)) {
    parentObjectType = common.OBJECT_TYPES[objectType];
  } else {
    parentObjectType = objectType;
  }

  dispatch(filePreloader(true));

  if (!parentObjectType || !objectId) {
    dispatch(filePreloader(false));
    return;
  }

  let result;

  try {
    result = await requests.attachments.getFileList(parentObjectType)(objectId);

    dispatch(setAttachments(result));
  } catch (error) {
    console.error(error);
  }

  dispatch(filePreloader(false));
  return result;
};

export default getAttachmentsList;
