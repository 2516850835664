import {
  SET_REALTOR_SPECIAL_CONDITION,
} from 'store/actionTypes/realtorSpecialConditions';

const set = (item) => ({
  type: SET_REALTOR_SPECIAL_CONDITION,
  payload: item,
});

export default set;
