import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Agreement from './Agreement';

const mapStateToProps = (state, ownProps) => {
  const {
    offerId,
  } = ownProps;

  const attachments = selectors.realtorOffers.attachments(state, offerId);

  return {
    file: attachments[0],
  };
};

export default connect(mapStateToProps, null)(Agreement);
