import { connect } from 'react-redux';

import selectors from 'store/selectors';

import LessContactCard from 'shared/components/LessContactCard';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  const {
    last_name,
    first_name,
  } = contact;

  return {
    contactId,
    name: first_name,
    lastName: last_name,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LessContactCard);
