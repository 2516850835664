import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'ui/Tooltip';
import { Tile } from './styledItems';

const AttachmentTile = (props) => {
  const {
    file,
    onClick,
  } = props;

  return (
    <Tooltip title={file.title || file.name}>
      <Tile
        onClick={onClick}
        thumbUrl={file.thumbUrl}
      />
    </Tooltip>
  );
};

AttachmentTile.defaultProps = {
  onClick: () => {},
};

const {
  func,
  shape,
  string,
} = PropTypes;

AttachmentTile.propTypes = {
  file: shape({
    title: string,
    name: string,
    thumbUrl: string,
  }).isRequired,
  onClick: func,
};

export default AttachmentTile;
