import create from './create';
import get from './get';
import getById from './getById';
import deleteReminder from './deleteReminder';
import update from './update';
import getSummary from './getSummary';

export default {
  create,
  deleteReminder,
  get,
  getById,
  update,
  getSummary,
};
