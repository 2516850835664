import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const ContactLastNameInput = (props) => {
  const {
    handleChange,
    value,
  } = props;

  return (
    <Textfield
      label="Last Name"
      onChange={handleChange}
      value={value}
    />
  );
};

ContactLastNameInput.defaultProps = {
  value: undefined,
};

const {
  func,
  string,
} = PropTypes;

ContactLastNameInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
};

export default ContactLastNameInput;
