export const SET_REALTOR_MATCHING_PROPERTIES = 'SET_REALTOR_MATCHING_PROPERTIES';
export const SET_REALTOR_MATCHING_PROPERTIES_BY_FILTER = 'SET_REALTOR_MATCHING_PROPERTIES_BY_FILTER';
export const SET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER = 'SET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER';
// eslint-disable-next-line max-len
export const SET_REALTOR_INFINITE_SCROLL_MATCHING_PROPERTIES_FOR_FILTER = 'SET_REALTOR_INFINITE_SCROLL_MATCHING_PROPERTIES_FOR_FILTER';
export const SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_OPEN = 'SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_OPEN';
// eslint-disable-next-line max-len
export const SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_CLOSED = 'SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_CLOSED';

export const RESET_REALTOR_MATCHING_PROPERTIES_BY_FILTER = 'RESET_REALTOR_MATCHING_PROPERTIES_BY_FILTER';
export const RESET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER = 'RESET_REALTOR_MATCHING_PROPERTIES_COUNT_BY_FILTER';

export const RESET_REALTOR_MATCHING_PROPERTIES_FILTERS = 'RESET_REALTOR_MATCHING_PROPERTIES_FILTERS';
export const RESET_REALTOR_MATCHING_PROPERTIES_FILTER_COUNTERS = 'RESET_REALTOR_MATCHING_PROPERTIES_FILTER_COUNTERS';
