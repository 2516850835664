import { connect } from 'react-redux';

import {
  createFollowUpReminders,
} from 'store/actions/followUpReminders';
import FollowUp from './FollowUp';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSave: (objectTypeId, objectId) => (reminderData) => {
    dispatch(createFollowUpReminders(objectTypeId)(objectId)([reminderData]));
    ownProps.onComplete();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUp);
