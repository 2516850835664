import apiConnector, { endpoints } from 'api';

/**
 * @typedef {'employee' | 'customer' | 'salesContact' } UserTypes
 */

const getUserData = (userType) => async (userId) => {
  const endpoint = `${endpoints.client.users}/${userType}/${userId}`;

  // temporarily disabling this - need to add error handlers
  if (!userType || !userId) {
    throw new Error('User type or id not provided');
  }

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    // throw (error);
  }
};

export default getUserData;
