import { createSelector } from 'reselect';

import { optimizedDictionaryActions } from 'store/actions/dictionaryV2';

const reasons = (state) => state.dictionaryV2.rejectedPropertiesReasons;

export default createSelector(
  reasons,
  (collection) => {
    if (!collection) {
      optimizedDictionaryActions.getRejectedPropertiesReasonsDictionary();
      return [];
    }

    return collection.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  },
);
