import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles } from '@material-ui/core/styles';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '20px !important',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // marginBottom: -1,
    // minHeight: 0,
    '&$expanded': {
      // display: 'none',
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      // display: 'none',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionRoot = (props) => {
  const {
    details,
    summary,
    summaryExpanded,
    expanded,
    onExpand,
  } = props;

  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(isExpanded);
  }, [
    expanded,
  ]);

  const handleExpand = useCallback((event, value) => {
    onExpand(value);
    setIsExpanded(value);
  }, [
    onExpand,
  ]);

  const summaryContent = isExpanded
    ? (summaryExpanded || summary)
    : summary;

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        expandIcon={(
          <FontAwesomeIcon
            icon="angle-down"
          />
        )}
      >
        {
          summaryContent
        }
      </AccordionSummary>
      <AccordionDetails>
        {
          details
        }
      </AccordionDetails>
    </Accordion>
  );
};

AccordionRoot.defaultProps = {
  expanded: false,
  summaryExpanded: null,
};

const {
  bool,
  element,
  func,
  oneOfType,
  string,
} = PropTypes;

AccordionRoot.propTypes = {
  expanded: bool,
  summary: oneOfType(string, element).isRequired,
  summaryExpanded: oneOfType(string, element),
  details: oneOfType(string, element).isRequired,
  onExpand: func,
};

export default AccordionRoot;
