import requests from '../../../api/requests';

// eslint-disable-next-line consistent-return
const getPreferredNavbarColor = async () => {
  try {
    const response = await requests.navbarSettings.get();
    const [color] = response.data;
    return color.preferred_navigation_color_object;
  } catch (error) {
    console.error(error);
  }
};

export default getPreferredNavbarColor;
