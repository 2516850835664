import {
  SET_SELECTED_PROPERTY_ROWS,
} from 'store/actionTypes/properties';

const setSelectedPropertyRows = (ids) => ({
  type: SET_SELECTED_PROPERTY_ROWS,
  payload: ids,
});

export default setSelectedPropertyRows;
