import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getCustomizationSettings from './getCustomizationSettings';

const updateCustomizationSettings = (updateData) => async (dispatch) => {
  try {
    await requests.realtor.appCustomizationSettings.update(updateData);

    callAlert.success('Updated successfully!');

    dispatch(getCustomizationSettings());
  } catch (error) {
    console.error(error);
    callAlert.error('Could not update!');
  }
};

export default updateCustomizationSettings;
