import { connect } from 'react-redux';

import { createContact } from 'store/actions/realtorContacts';

import { createInspection } from 'store/actions/realtorInspections';

import { uploadAttachment } from 'store/actions/attachmentsV2';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import NewInspectionForm from './NewInspectionForm';

const mapStateToProps = (state) => {
  const {
    realtorNewInspectionForm: {
      transactionId,
      defaultInspectionType,
      defaultNote,
      defaultInspectionDate,
      defaultInspectorId,
      isReinspection,

      attachments,

      onBeforeSubmit,
      onAfterSubmit,
    },
  } = state;

  return {
    transactionId,
    defaultInspectionType,
    defaultNote,
    defaultInspectionDate,
    defaultInspectorId,
    isReinspection,

    onBeforeSubmit,
    onAfterSubmit,

    attachments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createInspection: async ({
    selectedInspectorId,
    transactionId,
    inspectionType,
    notes,
    inspectionDate,
    attachments,
    newInspector,
    isReinspection,
  }) => {
    const inspector_contact_id = selectedInspectorId
    || (await dispatch(createContact(newInspector)));

    const newInspectionId = await dispatch(createInspection({
      realtor_deal_id: transactionId,
      inspection_type_id: inspectionType,
      inspector_contact_id,
      notes,
      date_time: inspectionDate,
      is_reinspection: isReinspection,
    }));

    // upload attachments

    const uploadAttachmentsRequests = attachments.map(async (item) => {
      const {
        url: attachmentUrl,
        name: attachmentName,
        type: attachmentType,
      } = item;

      const file = await dataUrlToFile(
        attachmentUrl,
        attachmentName,
        attachmentType,
      );

      const attachmentsData = {
        file,
        objectId: newInspectionId,
        objectType: 34,
        attachmentType: 12,
      };

      await dispatch(uploadAttachment(attachmentsData));
    });

    await Promise.all(uploadAttachmentsRequests);

    return newInspectionId;
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: async (data) => {
    const inspectionId = await dispatchProps.createInspection({
      ...data,

      transactionId: stateProps.transactionId,
      realtor_deal_id: stateProps.transactionId,
      attachments: stateProps.attachments,
    });

    if (stateProps.onAfterSubmit) {
      stateProps.onAfterSubmit(inspectionId);
    }

    if (ownProps.onAfterSubmit) {
      ownProps.onAfterSubmit(inspectionId);
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NewInspectionForm);
