import { // eslint-disable-line import/no-cycle
  createContact,
} from 'store/actions/realtorContacts';

import {
  setEditPropertyViewingFormField,
} from 'store/actions/editRealtorPropertyViewingForm';

const defaultNewContact = {
  title: '1',
  first_name: '',
  last_name: '',
  email_primary: '',
  phone_primary: '',
  first_contact_date: '',
};

const defaultValidators = {
  isEmailValid: false,
  doesEmailExist: true,
  doesPhoneExist: true,
};

const createContactFromEditPropertyViewingForm = () => async (dispatch, getState) => {
  try {
    const {
      editPropertyViewingForm,
    } = getState();

    const {
      newContact,
      newContactFieldsValidators: {
        isEmailValid,
        doesEmailExist,
        doesPhoneExist,
      },
    } = editPropertyViewingForm;

    if (!isEmailValid || doesEmailExist || doesPhoneExist) {
      return;
    }

    await dispatch(createContact(newContact));

    dispatch(setEditPropertyViewingFormField('newContact')(defaultNewContact));
    dispatch(setEditPropertyViewingFormField('newContactFieldsValidators')(defaultValidators));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createContactFromEditPropertyViewingForm;
