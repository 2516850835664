import React from 'react';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Button from 'ui/Button';

// import adamAvatar from 'assets/images/realtor/adam-avatar.png';

import {
  ItemWrapper,
  // ProfileImage,
} from './styledItems';

const onBookCall = () => {
  window.open('https://meetings.hubspot.com/symplete/symplete-sales-team-calendar', '_blank');
};

const Welcome = (props) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    spacing={3}
    style={{ marginTop: 40 }}
  >
    {/* <div>
      <Typography
        variant="heading"
        weight="bold"
        align="center"
        style={{ marginBottom: 10 }}
      >
        We want to hear from you.
      </Typography>
      <Typography
        variant="title2"
        weight="normal"
        align="center"
        noMargin
      >
        Adam has been assigned as your dedicated point of contact.
      </Typography>
    </div> */}

    <Flex
      justifyContent="center"
      spacing={2}
      alignItems="stretch"
    >
      {/* <ItemWrapper>
        <ProfileImage src={adamAvatar} width="90" alt="Adam avatar" />
        <div>
          <Typography
            variant="title2"
            weight="bold"
            tag="p"
            noMargin
            style={{ marginBottom: 10 }}
          >
            Adam Rouillard
          </Typography>
          <a
            href="mailto:adam@symplete.com"
          >
            <Typography>
              adam@symplete.com
            </Typography>
          </a>
          <a
            href="tel:4244280097"
          >
            <Typography>
              (424) 428-0097
            </Typography>
          </a>
        </div>
      </ItemWrapper> */}

      <div>
        <Typography
          variant="title1"
          weight="bold"
          tag="h3"
        >
          Contact Us
        </Typography>
        <ItemWrapper>
          <div>
            <Typography
              variant="title2"
              weight="bold"
              tag="p"
              noMargin
              style={{ marginBottom: 10 }}
            >
              General Support Details
            </Typography>
            <a
              href="mailto:support@symplete.com"
            >
              <Typography>
                support@symplete.com
              </Typography>
            </a>
            <a
              href="tel:8886641952"
            >
              <Typography>
                (888) 664 1952
              </Typography>
            </a>
          </div>
        </ItemWrapper>
      </div>

      <Flex
        flexDirection="column"
      >
        <Typography
          variant="title1"
          weight="bold"
          tag="h3"
        >
          Book a Call
        </Typography>

        <ItemWrapper>
          <Flex fullWidth justifyContent="center">
            <Button
              variant="primary"
              onClick={onBookCall}
              fullWidth
            >
              Book a call
            </Button>
          </Flex>
        </ItemWrapper>
      </Flex>
    </Flex>

    <Typography
      variant="title2"
      weight="normal"
      align="center"
      noMargin
    >
      If you have any feedback for us, please do not hesitate to reach out.
      We are continuously developing and improving Symplete
    </Typography>

  </Flex>
  );

export default Welcome;
