import requests from 'api/requests';
import setActiveMeeting from './setActiveMeeting';

const fetchMeeting = (idOrCode) => async (dispatch) => {
  try {
    const response = await requests.meetings.getMeeting(idOrCode);

    dispatch(
      setActiveMeeting(response.data),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default fetchMeeting;
