import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

const NewItemSection = (props) => {
  const {
    onCreate,
  } = props;

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const handleCreate = useCallback(() => {
    onCreate({
      title,
      description,
    });

    setTitle('');
    setDescription('');
  }, [
    title,
    description,
    onCreate,
  ]);

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography
          variant="title2"
        >
          Add new
        </Typography>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={12}>
          <Textfield
            label="Title"
            value={title}
            onChange={setTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <Textfield
            textarea
            label="Description"
            value={description}
            multiline
            onChange={setDescription}
          />
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Button
          onClick={handleCreate}
          disabled={!title}
          variant="secondary"
          icon="plus"
        >
          Add
        </Button>
      </Grid>

    </Grid>
  );
};

const {
  func,
} = PropTypes;

NewItemSection.propTypes = {
  onCreate: func.isRequired,
};

export default NewItemSection;
