import { connect } from 'react-redux';
import selectors from 'store/selectors';

import { updateCustomizationSettings } from 'store/actions/appCustomization';

import ContingencyDueDiligence from './ContingencyDueDiligence';

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    value: settings.contingency_or_due_diligence,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => {
    dispatch((updateCustomizationSettings({
      contingency_or_due_diligence: value,
    })));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContingencyDueDiligence);
