import requests from 'api/requests';

import {
  SET_LOGS_FOR_OBJECT,
} from 'store/actionTypes/logs';

export const getLogs = (parentObjectType) => (parentObjectId) => async (dispatch) => {
  const res = await requests.logs.getForObject(parentObjectType)(parentObjectId);

  dispatch(setLogsForObject(parentObjectType)(parentObjectId)(res.data));
};

export const postLog = (parentObjectType) => (parentObjectId) => (commentBody) => async (dispatch) => {
  try {
    await requests.logs.post(parentObjectType)(parentObjectId)(commentBody);
    dispatch(getLogs(parentObjectType)(parentObjectId));
  } catch (error) {
    console.error(error);
  }
};

export const editLog = (parentObjectType) => (parentObjectId) => (commentId) => (commentText) => async (dispatch) => {
  const comment = {
    Comment: commentText,
  };

  try {
    await requests.logs.edit(commentId)(comment);
    dispatch(getLogs(parentObjectType)(parentObjectId));
  } catch (error) {
    console.error(error);
  }
};

export const deleteLog = (parentObjectType) => (parentObjectId) => (commentId) => async (dispatch) => {
  try {
    await requests.logs.delete(commentId);
    dispatch(getLogs(parentObjectType)(parentObjectId));
  } catch (error) {
    console.error(error);
  }
};

export const acknowledgeLog = (parentObjectType) => (parentObjectId) => (commentId) => async (dispatch) => {
  try {
    await requests.logs.acknowledge(commentId);
    dispatch(getLogs(parentObjectType)(parentObjectId));
  } catch (error) {
    console.error(error);
  }
};

// export const markCommentAsSeen = (parentObjectType) => (parentObjectId) => (commentId) => async (dispatch) => {
//   const markAsSeenMethod = isSympleteUser
//     ? acknowledgeLogAsUser
//     : acknowledgeLogAsCustomer

//   await markAsSeenMethod(commentId);
// }

export const logMethodsWithObjectUpdate = (parentObjectType) => (parentObjectId) => ({
  get: getLogs(parentObjectType)(parentObjectId),
  post: postLog(parentObjectType)(parentObjectId),
  edit: editLog(parentObjectType)(parentObjectId),
  delete: deleteLog(parentObjectType)(parentObjectId),
  acknowledge: acknowledgeLog(parentObjectType)(parentObjectId),
});

export const setLogsForObject = (parentObjectType) => (parentObjectId) => (logList) => ({
  type: SET_LOGS_FOR_OBJECT,
  payload: {
    parentObjectType,
    parentObjectId,
    logList,
  },
});

export default logMethodsWithObjectUpdate;
