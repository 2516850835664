import requests from 'api/requests';

import setClient from './setClient';

const getClientById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.clients.getById(id);
    dispatch(setClient(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getClientById;
