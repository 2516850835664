import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeSearchMLSModal,
} from 'store/actions/searchMLSModal';

import {
  createMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

import { getProspectById } from 'store/actions/realtorProspects';

import SearchMLSModal from './SearchMLSModal';

const mapStateToProps = (state) => {
  const {
    realtorSearchMLSModal: {
      isVisible,
    },
    realtorProspects: {
      selectedProspect,
    },
  } = state;

  const prospect = selectors.realtorProspects.byId(state, selectedProspect);

  return {
    isVisible,
    prospect,
    contactId: prospect?.contact_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeSearchMLSModal()),

  onSubmit: (prospect) => async (data) => {
    const requests = data.map(async (item) => {
      await dispatch(createMatchingProperty({
        realtor_contact_id: prospect.contact_id,
        realtor_property_id: item,
        status: 1,
      }));
    });

    await Promise.all(requests);

    dispatch(getProspectById(prospect.id));
    dispatch(resetMatchingPropertyFilters());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: async (data) => {
    await dispatchProps.onSubmit(stateProps.prospect)(data);
    dispatchProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SearchMLSModal);
