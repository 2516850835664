import apiConnector, { endpoints } from 'api';

const amenities = async () => {
  const endpoint = endpoints.client.dictionary.amenities;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default amenities;
