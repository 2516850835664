import React from 'react';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import DataImportButton from 'sections/DataImport/modules/DataImportButton';
import UploadedFiles from 'sections/DataImport/modules/UploadedFiles';

import DataImportProvider from 'sections/DataImport/DataImportProvider';

import ImportWizardModal from 'sections/DataImport/modals/ImportWizard';

const ImportSettings = (props) => {
  const {

  } = props;

  return (
    <DataImportProvider>
      <Flex
        spacing={2}
        flexDirection="column"
        fullWidth
      >
        <Flex justifyContent="space-between" fullWidth>
          <Typography>
            Import your Contacts or Properties into your Symplete account.
          </Typography>
          <DataImportButton>
            Import Data
          </DataImportButton>
        </Flex>
        <UploadedFiles />
      </Flex>

      <ImportWizardModal />
    </DataImportProvider>
  );
};

export default ImportSettings;
