import {
  SET_OVERALL_FULFILLMENTS_SUMMARY,
} from 'store/actionTypes/fulfillments';

const setOverallFulfillmentsSummary = (summary) => ({
  type: SET_OVERALL_FULFILLMENTS_SUMMARY,
  payload: summary,
});

export default setOverallFulfillmentsSummary;
