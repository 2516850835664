import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import CurrencyInput from 'ui/CurrencyInput';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks/index';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    value: property[ownProps?.priceType || 'expected_price'],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updateProperty(ownProps.propertyId)({
      [ownProps?.priceType || 'expected_price']: value,
    }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDebouncedCallbacks(CurrencyInput, [['onChange', 1000]]));
