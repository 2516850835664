export const CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL = 'CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL';
export const CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR = 'CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR';
export const GET_EMPLOYEE_NOTES = 'GET_EMPLOYEE_NOTES';
export const SET_EMPLOYEE_DETAILS = 'SET_EMPLOYEE_DETAILS';
export const SET_EMPLOYEE_ACTIVITY = 'SET_EMPLOYEE_ACTIVITY';
export const SHOW_DELETING_EMPLOYEE_WARNING = 'SHOW_DELETING_EMPLOYEE_WARNING';
export const HIDE_DELETING_EMPLOYEE_WARNING = 'HIDE_DELETING_EMPLOYEE_WARNING';
export const DELETE_EMPLOYEE_ACTIONS = 'DELETE_EMPLOYEE_ACTIONS';

export default {
  CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL,
  GET_EMPLOYEE_NOTES,
  SET_EMPLOYEE_DETAILS,
  SET_EMPLOYEE_ACTIVITY,
  CHANGE_EMPLOYEE_INFORMATION_IN_GLOBAL_ERROR,
  SHOW_DELETING_EMPLOYEE_WARNING,
  HIDE_DELETING_EMPLOYEE_WARNING,
  DELETE_EMPLOYEE_ACTIONS,
};
