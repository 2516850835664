import {
  SET_ATTACHMENTS,
} from 'store/actionTypes/attachments';

const setAttachments = (attachments) => ({
  type: SET_ATTACHMENTS,
  payload: {
    attachments,
  },
});

export default setAttachments;
