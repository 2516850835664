import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

const withContactDataBasic = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      contactId,
    } = ownProps;

    const {
      avatar,
      name,
      surname,
    } = selectors.realtorContacts.basicById(state, contactId);

    const avatarImageSrc = getAvatarUrl(avatar);

    return {
      src: avatarImageSrc,
      firstName: name,
      lastName: surname,
      contactId,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withContactDataBasic;
