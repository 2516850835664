import { createSelector } from 'reselect';

const products = (state) => state.crmProducts.products;

export default createSelector(
  products,
  (items) => (items
    ? Object.values(items).map((item) => ({
      id: item.id,
      title: item.name,
      name: item.name,
    }))
    : null),
);
