import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

export default createSelector(
  (state) => state.followUpReminders.overallRemindersSummary,
  (summary) => {
    if (!summary) {
      optimizedFollowUpRemindersActions.getOverallSummary();

      return {};
    }

    return summary;
  },
);
