/* eslint-disable react/require-default-props */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { remove, isEqual } from 'shared/utility';

import {
  Col,
  Input,
  List,
  Modal,
  Row,
} from 'antd';

import classNames from 'classnames';

import AssignListItem from '../AssignListItem';

import './AssignModal.scss';

const { Item } = List;
const { Search } = Input;

class AssignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initUsers: [],
      selectedUsersIds: props.selectedUsersIds,
      availableUsersIds: props.availableUsersIds,
      // filteredUsers: [],
      // key: null,
      // searchKey: null,
      // searchVal: '',
    };
  }

  componentDidUpdate(prevProps) {
    const {
      isModalVisible,
      availableUsersIds,
      selectedUsersIds,
    } = this.props;

    const hasModalOpened = !prevProps.isModalVisible && isModalVisible;
    const availableUsersIdsChanged = !isEqual(
      prevProps.availableUsersIds,
      availableUsersIds,
    );

    if (hasModalOpened || availableUsersIdsChanged) {
      this.setState({
        availableUsersIds,
        selectedUsersIds,
      });
    }
  }

  toggleUser = (user) => () => {
    const {
      handleSelectedUsersChange,
      singleSelectionOnly,
    } = this.props;

    const {
      selectedUsersIds,
    } = this.state;

    let selectedUsersCopy = [...selectedUsersIds];
    const isUserAlreadySelected = selectedUsersCopy.some((item) => item === user);

    if (isUserAlreadySelected) {
      remove(selectedUsersCopy, (item) => item === user);
    } else if (singleSelectionOnly) {
      selectedUsersCopy = [user];
    } else {
      selectedUsersCopy.push(user);
    }

    this.setState({
      selectedUsersIds: selectedUsersCopy,
    });

    handleSelectedUsersChange(selectedUsersCopy);
  }

  handleOk = () => {
    const {
      onClickOk,
      notCloseOnOk,
    } = this.props;

    onClickOk();

    if (!notCloseOnOk) {
      this.closeModal();
    }
  };

  handleCancel = () => {
    this.resetAssignees();
    this.closeModal();
  }

  filterList = (searchVal) => {
    // const {
    //   availableUsersIds,
    //   searchKey,
    // } = this.state;

    // const filteredUsers = availableUsersIds.filter((user) => {
    //   const text = user[searchKey];

    //   if (!text) {
    //     return false;
    //   }

    //   return text.toLowerCase().includes(searchVal.toLowerCase());
    // });

    // this.setState({
    //   searchVal,
    //   filteredUsers,
    // });
  }

  resetAssignees = () => {
    const {
      selectedUsersIds,
      availableUsersIds,
    } = this.props;

    this.setState({
      selectedUsersIds,
      availableUsersIds,
    });
  }

  closeModal = () => {
    const {
      closeModal: propsCloseModal,
    } = this.props;

    propsCloseModal();
  }

  renderAssignListItem = (user) => {
    const {
      CustomListItem,
    } = this.props;

    const {
      selectedUsersIds,
    } = this.state;

    const isSelected = selectedUsersIds.includes(user);

    if (CustomListItem) {
      return (
        <Item className="assign-list__item">
          <CustomListItem
            isSelected={isSelected}
            id={user}
            onClick={this.toggleUser(user)}
          />
        </Item>
      );
    }

    return (
      <Item className="assign-list__item">
        <AssignListItem
          isSelected={isSelected}
          user={user}
          onClick={this.toggleUser(user)}
        />
      </Item>
    );
  }

  render() {
    const {
      isModalVisible,
      title,
      okText,
      renderTopPanel,
      zIndex,
    } = this.props;

    const {
      availableUsersIds,
    } = this.state;

    const listDataSource = availableUsersIds;

    return (
      <Modal
        title={title}
        zIndex={zIndex}
        okText={okText}
        visible={isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        wrapClassName="add-user-modal"
      >
        <Row>
          <Col>
            {
              renderTopPanel
                ? (
                  renderTopPanel({
                    inputClassName: 'add-user-modal__search',
                  })
                )
                : (
                  <Search
                    className="add-user-modal__search"
                    placeholder="Enter user name"
                  // onChange={(event) => ( // eslint-disable-line react/jsx-no-bind
                  //   this.filterList(event.target.value)
                  // )}
                  // value={searchVal}
                  />
                )
            }
          </Col>
        </Row>

        <List
          className={classNames(
            'assign-list', 'user-list', 'assign-list__modal',
            // {
            //   'user-list--add': addUser,
            // },
          )}
          grid={{ column: 2 }}
          dataSource={listDataSource}
          renderItem={this.renderAssignListItem}
        />
      </Modal>
    );
  }
}

AssignModal.defaultProps = {
  onClickOk: null,
  // usersLimit: 0,
  isModalVisible: false,
  selectedUsersIds: [],
  availableUsersIds: [],
  title: 'Assign user',
  CustomListItem: null,
  singleSelectionOnly: false,
  zIndex: null,
};

const {
  arrayOf,
  bool,
  func,
  number,
  // shape,
  string,
} = PropTypes;

AssignModal.propTypes = {
  // limit selected users. set 1 to single select
  // usersLimit: number,
  zIndex: number,
  availableUsersIds: arrayOf(number),
  closeModal: func.isRequired,
  handleSelectedUsersChange: func.isRequired,
  isModalVisible: bool,
  onClickOk: func,
  selectedUsersIds: arrayOf(number),
  title: string,
  CustomListItem: func,
  singleSelectionOnly: bool,
  okText: string,
  notCloseOnOk: bool,
  renderTopPanel: func,
};

export default AssignModal;
