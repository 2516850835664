import {
  SET_REALTOR_SELECTED_LEAD_PROPERTY,
} from 'store/actionTypes/realtorLeads';

/**
 * @param {Object} payload
 * @param {('properties'|'property_requirements')} payload.type
 * @param {number} payload.id
 * @param {number | string} payload.leadId
 */
const setSelectedLeadProperty = ({ type = null, id = null, leadId = null }) => ({
  type: SET_REALTOR_SELECTED_LEAD_PROPERTY,
  payload: { type, id, leadId },
});

export default setSelectedLeadProperty;
