import {
  SET_REALTOR_PROSPECTS_FOR_FILTER,
} from 'store/actionTypes/realtorProspects';

const setProspectsForFilter = (filterId) => (prospects) => ({
  type: SET_REALTOR_PROSPECTS_FOR_FILTER,
  payload: {
    filterId,
    prospects,
  },
});

export default setProspectsForFilter;
