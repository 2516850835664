import React, {
  useContext,
  useMemo,
} from 'react';

import ObjectTypeSelector from 'sections/DataImport/modules/ObjectTypeSelector';
import Upload from 'sections/DataImport/modules/Upload';
import Preview from 'sections/DataImport/modules/Preview';
import Match from 'sections/DataImport/modules/Match';
import Overwrite from 'sections/DataImport/modules/Overwrite';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

const stepComponents = {
  'object type': ObjectTypeSelector,
  upload: Upload,
  preview: Preview,
  match: Match,
  overwrite: Overwrite,
};

const CurrentStep = (props) => {
  const {
    currentStep,
  } = useContext(DataImportContext);

  const Step = useMemo(() => stepComponents[currentStep?.id], [
    currentStep?.id,
  ]);

  return (
    <Step />
  );
};

export default CurrentStep;
