import {
  SET_FULFILLMENTS_SUMMARY_FOR_FILTER,
} from 'store/actionTypes/fulfillments';

const setFulfillmentsSummaryForFilter = (filterId) => (summary) => ({
  type: SET_FULFILLMENTS_SUMMARY_FOR_FILTER,
  payload: {
    filterId,
    summary,
  },
});

export default setFulfillmentsSummaryForFilter;
