import { createSelector } from 'reselect';

import {
  forOwn,
  isArray,
} from 'shared/utility';

import {
  optimizedWarehouseActions,
} from 'store/actions/warehouse';

import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state, id) => state.warehouse.packages[id],
  (state, id) => id,
  (state) => state.lineItems.lineItems,
  (state) => state.crmProducts.products,
  (pckg, id, lineItemsCollection, products) => {
    if (!id) return [];

    if (!pckg) {
      optimizedWarehouseActions.getPackageById(id);
      return [];
    }

    const { lineItems } = pckg;

    if (!isArray(lineItems)) {
      return [];
    }

    const itemsByProduct = lineItems.reduce((acc, itemId) => {
      const item = lineItemsCollection[itemId];

      if (!item) {
        optimizedLineItemsActions.getLineItemById(itemId);
        return acc;
      }

      if (!products[item.product_id]) {
        optimizedCrmProductsActions.getProductById(item.product_id);
        return acc;
      }

      let { name } = products[item.product_id];

      if (item.status === 3) {
        name = `${name} — cancelled`;
      }

      if (acc[name]) {
        acc[name].count += 1;
        acc[name].ids.push(itemId);
      } else {
        acc[name] = {
          count: 1,
          ids: [itemId],
        };

        if (item.status === 3 && !acc[name].status) {
          acc[name].status = 'error';
        }
      }

      return acc;
    }, {});

    const result = [];

    forOwn(itemsByProduct, (value, key) => {
      result.push({
        label: `${key} × ${value.count}`,
        ids: value.ids,
        status: value.status,
      });
    });

    return result;
  },
);
