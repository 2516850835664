import { connect } from 'react-redux';

import {
  setSelectedAction,
} from 'store/actions/archiveDeleteLeadModal';

import ActionSelector from './ActionSelector';

const mapStateToProps = (state) => {
  const {
    archiveDeleteLeadModal: {
      selectedAction,
    },
  } = state;

  return {
    selectedAction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (actionType) => () => {
    dispatch(setSelectedAction(actionType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionSelector);
