import styled from 'styled-components';

const heights = {
  s: 16,
  m: 30,
};

const paddings = {
  s: 11,
  m: 21,
};

const radii = {
  s: 8,
  m: 15,
};

export const Root = styled.div`
  display: flex;
  height: ${(props) => heights[props.size]}px;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(props) => (props.noPadding ? 0 : paddings[props.size])}px;
  border-radius: ${(props) => radii[props.size]}px;

  // eslint-disable-next-line no-confusing-arrow
  background-color: ${
    (props) => (CSS.supports('background-color', props.variant)
      ? props.variant 
      : props.theme?.palette?.[props.variant] || 'gray')
  };

  ${(props) => props.border && (
    `
      border-width: ${props.border.width || 1}px;
      border-style: ${props.border.style || 'solid'};
      border-color: ${props.theme?.palette?.[props.border.color] || props.border.color || 'gray'};
    `
  )}
`;

export default {
  Root,
};
