import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import { // eslint-disable-line import/no-cycle
  optimizedDislikesActions,
} from 'store/actions/likes-dislikes/dislikes';

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (state) => state.dislikes.dislikes,
  (_, contactId) => contactId,
  (
    contacts,
    dislikes,
    id,
  ) => {
    const contact = contacts[id];

    if (!contact) {
      if (id) {
        optimizedRealtorContactsActions.getContactById(id);
      }

      return [];
    }

    const dislikesIds = contact.dislikes || [];

    const contactDislikes = dislikesIds.reduce((accum, dislikeId) => {
      const dislike = dislikes[dislikeId];

      if (!dislike) {
        if (dislikeId) {
          optimizedDislikesActions.getDislikeById(dislikeId);
        }

        return accum;
      }

      accum.push({
        id: dislike.id,
        title: dislike.title,
        description: dislike.description,
      });
      return accum;
    }, []);

    return contactDislikes;
  },
);
