import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import PropertyImage from './PropertyImage';
import PropertyAddress from './PropertyAddress';
import PropertyDOM from './PropertyDOM';
import Representing from './Representing';

const PropertySummary = (props) => {
  const {
    propertyId,
    representing
  } = props;

  return (
    <Flex spacing={2}>
      <FlexItem xs={6}>
        <PropertyImage propertyId={propertyId} />
      </FlexItem>
      <Flex
        spacing={1}
        flexDirection="column"
      >
        <PropertyAddress propertyId={propertyId} />
        <Representing representing={representing} />
        <PropertyDOM propertyId={propertyId} />
      </Flex>
    </Flex>
  );
};

PropertySummary.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

PropertySummary.propTypes = {

};

export default PropertySummary;
