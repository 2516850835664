import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedLineItemsActions,
} from 'store/actions/line-items';

export default createSelector(
  (state) => state.lineItems.lineItems,
  (_, id) => id,
  (collection, id) => {
    const defaultItem = {
      id: null,
      payments: [],
      process_status: null,
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedLineItemsActions.getLineItemById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
      payments: item.payments ? JSON.parse(item.payments) : [],
      packages: item.packages ? JSON.parse(item.packages) : [],
    };
  },
);
