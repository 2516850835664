import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';

import Typography from 'ui/Typography';
import TextRow from 'shared/components/UI/TextRow';

import './CommentBody.scss';

const CommentBody = (props) => {
  const {
    className,
    commentText,
    confirmedText,
    createdAt,
    name,
    resolvedText,
    status,
    title,
    surname,
  } = props;

  const mainText = createHtmlMarkup(commentText);

  return (
    <div
      className={classnames(
        'comment-body-root',
        {
          [className]: className,
        },
      )}
    >
      <TextRow spacing={10}>
        <Typography
          variant="title3"
          noMargin
        >
          {name}
          {' '}
          {surname}
        </Typography>
        <Typography
          variant="subtitle2"
          color="descriptor-text"
          noMargin
        >
          {createdAt}
        </Typography>
        <Typography
          variant="subtitle2"
          color="descriptor-text"
          noMargin
        >
          {status}
        </Typography>
      </TextRow>
      {
        confirmedText && (
          <Typography
            variant="subtitle2"
            color="focus"
            noMargin
          >
            {confirmedText}
          </Typography>
        )
      }
      {
        resolvedText && (
          <Typography
            variant="subtitle2"
            color="focus"
            noMargin
          >
            {resolvedText}
          </Typography>
        )
      }
      <div className="comment-text-container">
        {title && status && (
          <Typography
            variant="caption"
            tag="span"
            color="descriptor-text"
            noMargin
          >
            {`${title}: `}
          </Typography>
        )}
        {mainText}
      </div>
    </div>
  );
};

CommentBody.defaultProps = {
  className: null,
  commentText: null,
  confirmedText: null,
  createdAt: null,
  name: null,
  resolvedText: null,
  status: '',
  surname: '',
  title: null,
};

const {
  string,
} = PropTypes;

CommentBody.propTypes = {
  className: string,
  commentText: string,
  confirmedText: string,
  createdAt: string,
  name: string,
  resolvedText: string,
  status: string,
  surname: string,
  title: string,
};

export default CommentBody;
