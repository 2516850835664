import { createSelector } from 'reselect';

import propertyImages from './propertyImages';

const propertyMainImage = createSelector(
  propertyImages,
  (images) => {
    const mainImageIndex = images.findIndex((image) => image.isMain);
    const image = images[mainImageIndex > 0 ? mainImageIndex : 0];

    return image;
  },
);

export default propertyMainImage;
