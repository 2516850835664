import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getContactById from './getContactById';
// import getContactChangelogAllTime from './getContactChangelogAllTime';

const updateContact = (contactId) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.contacts.update(contactId)(updateData);

    callAlert.success('Updated successfully');

    dispatch(getContactById(contactId));
    // dispatch(getContactChangelogAllTime(contactId));
  } catch (error) {
    console.error(error);
    callAlert.error('Could not update');
  }
};

export default updateContact;
