import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';

const BuyerDetails = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeBuyerFirstName,
      value: buyerFirstName,
    },
  } = useController({
    name: 'buyerFirstName',
    control,
  });

  const {
    field: {
      onChange: onChangeBuyerLastName,
      value: buyerLastName,
    },
  } = useController({
    name: 'buyerLastName',
    control,
  });

  return (
    <Flex spacing={2}>
      <Textfield
        placeholder="First name"
        onChange={onChangeBuyerFirstName}
        value={buyerFirstName}
      />
      <Textfield
        placeholder="Last name"
        onChange={onChangeBuyerLastName}
        value={buyerLastName}
      />
    </Flex>
  );
};

const {
  shape,
} = PropTypes;

BuyerDetails.propTypes = {
  control: shape({}).isRequired,
};

export default BuyerDetails;
