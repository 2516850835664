import React, {
  useCallback,
  useContext,
} from 'react';

import { debounce } from 'shared/utility';

import context from 'sections/Contacts/modals/AssignContactModal/ContextProvider/context';

import Textfield from 'ui/Textfield';

const Search = () => {
  const {
    setFilterValue,
  } = useContext(context);

  const handleSearch = useCallback(debounce((value) => {
    setFilterValue('search')(value);
  }, 500), [
    setFilterValue,
  ]);

  return (
    <Textfield
      fullWidth
      placeholder="Search contacts"
      onChange={handleSearch}
      iconPosition="left"
      icon="search"
      flat
    />
  );
};

export default Search;
