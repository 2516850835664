import { connect } from 'react-redux';

import {
  setEditPropertyViewingFormField,
  setEditPropertyViewingNestedFormField,
} from 'store/actions/editRealtorPropertyViewingForm';

import EditViewingForm from './EditViewingForm';

const mapStateToProps = (state) => {
  const {
    editPropertyViewingModal: {
      buyerField,
      buyerAgentField,
      sellerAgentField,
    },
  } = state;

  return {
    showBuyerField: buyerField,
    showBuyerAgentField: buyerAgentField,
    showSellerAgentField: sellerAgentField,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (fieldName) => (value) => {
    dispatch(setEditPropertyViewingFormField(fieldName)(value));
  },
  handleChangeNested: (parentName) => (fieldName) => (value) => {
    dispatch(setEditPropertyViewingNestedFormField(parentName)(fieldName)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditViewingForm);
