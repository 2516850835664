import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import PropertyTypeSelect from 'shared/inputs/PropertyTypeSelect';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      sellerPropertyType,
    },
  } = state;

  return {
    value: sellerPropertyType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewLeadFormField('sellerPropertyType')(Number(value))),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTypeSelect);
