import { connect } from 'react-redux';
import selectors from 'store/selectors';

import NewContactArea from './NewContactArea';

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    withSalutations: !!settings.salutations_on,
  };
};

export default connect(mapStateToProps)(NewContactArea);
