import requests from 'api/requests';

import setLeadChangelog from './setLeadChangelog';

const getLeadChangelog = (leadId) => (from, to) => async (dispatch) => {
  try {
    const response = await requests.crm.leads.getChangelog(leadId)(from, to);

    dispatch(setLeadChangelog({
      leadId,
      changelog: response.data,
    }));
  } catch (error) {
    console.error(error);
  }
};

export default getLeadChangelog;
