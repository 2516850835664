import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import DialpadButton from 'sections/Calls/components/DialpadButton';

import {
  buttonsList,
} from './constants';

const noop = () => {};

const Dialpad = (props) => {
  const {
    onClick,
    currentButtonDown: currentButtonDownProps,
    disabled,
  } = props;

  const [currentButtonDown, setCurrentButtonDown] = useState(null);

  useEffect(() => {
    setCurrentButtonDown(currentButtonDownProps);
  }, [
    currentButtonDownProps,
  ]);

  const onDialpadKeyClick = useCallback((value) => {
    onClick(value);
  }, [
    onClick,
  ]);

  const onDialpadKeyDown = useCallback((key) => () => {
    setCurrentButtonDown(key);
  }, []);

  const onDialpadKeyUp = useCallback(() => {
    setCurrentButtonDown(null);
  }, []);

  return (
    <Flex
      fullWidth
      spacing={2}
      spacingStep={5}
      flexWrap="wrap"
      justifyContent="center"
    >
      {
        buttonsList.map((item) => (
          <Fragment key={item.key}>
            <FlexItem xs={7}>
              <DialpadButton
                onClick={onDialpadKeyClick}
                onLongClick={item.value === '0' ? onDialpadKeyClick : noop}
                onMouseDown={onDialpadKeyDown(item.value)}
                onMouseUp={onDialpadKeyUp}
                value={item.value}
                extraValue={item.extraValue}
                buttonDown={currentButtonDown === item.value || currentButtonDown === item.extraValue}
                disabled={disabled}
              />
            </FlexItem>
          </Fragment>
        ))
      }
    </Flex>
  );
};

Dialpad.defaultProps = {
  disabled: false,
  currentButtonDown: null,
};

const {
  func,
  bool,
  string,
} = PropTypes;

Dialpad.propTypes = {
  disabled: bool,
  onClick: func.isRequired,
  currentButtonDown: string,
};

export default Dialpad;
