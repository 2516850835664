import React, {
  useCallback,
  useState,
} from 'react';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import Spin from 'ui/Spin';

import ContactsImportAnimation from 'sections/Integrations/components/ContactsImportAnimation';

import useModal from 'ui/hooks/useModal';
import useContactsImportInfo from 'sections/Integrations/hooks/useContactsImportInfo';

import requests from 'api/requests';

const useContactsImport = ({
  tokenId,
  name,
  icon,
}) => {
  const [importStarted, setImportStarted] = useState(false);
  const [importInProgress, setImportInProgress] = useState(false);

  const {
    isModalVisible,
    openModal,
    closeModal,
    Modal,
  } = useModal();

  const {
    presentationContacts,
    allContactsCount,
    isLoading,
    // error,
  } = useContactsImportInfo({
    tokenId: isModalVisible ? tokenId : null,
  });

  const start = useCallback(async () => {
    try {
      setImportStarted(true);
      setImportInProgress(true);

      await requests.integrations.contacts.importContacts({ tokenId });
    } catch (error) {
      console.info(error);
    } finally {
      setImportInProgress(false);
    }
  }, [
    tokenId,
  ]);

  const modal = (
    <Modal
      title="Importing your contacts into Symplete"
      visible={isModalVisible}
      onCancel={closeModal}
      footer={
        !importStarted ? (
          <Button
            variant="primary"
            onClick={start}
            disabled={isLoading}
          >
            Start
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={closeModal}
            disabled={importInProgress}
          >
            Finish
          </Button>
        )
      }
    >
      <Spin spinning={isLoading}>
        {
          !importStarted && (
            <Flex
              spacing={1}
              flexDirection="column"
              alignItems="center"
            >
              <Icon
                name={icon}
                type="custom"
                size={100}
              />
              <Typography
                variant="title3"
                weight="bold"
                noMargin
                textCenter
              >
                {`${allContactsCount} contacts found in ${name}.`}
              </Typography>
              <Typography
                noMargin
                textCenter
              >
                Import all contacts?
              </Typography>
            </Flex>
          )
        }
        {
          importStarted && (
            <ContactsImportAnimation
              contacts={presentationContacts}
              allContactsCount={allContactsCount}
              tokenId={tokenId}
              importInProgress={importInProgress}
            />
          )
        }
      </Spin>
    </Modal>
  );

  return {
    openContactsImportModal: openModal,
    modal,
  };
};

export default useContactsImport;
