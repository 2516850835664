import React from 'react';

import PropTypes from 'prop-types';

import { FINANCING_TYPES } from 'constants/realtor.constants';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const FinanceType = (props) => {
  const {
    value,
  } = props;

  return (
    <>
      {
        FINANCING_TYPES[value] === 'Cash'
        ? (
          <Flex
            flexDirection="column"
            spacing={1}
          >
            <Typography
              variant="cell2"
              color="success"
              weight="bold"
            >
              ALL CASH!
            </Typography>
            <Typography
              variant="cell"
              noMargin
              color="success"
            >
              {FINANCING_TYPES[value]}
            </Typography>
          </Flex>
          )
        : (
          <Typography
            variant="cell"
          >
            {FINANCING_TYPES[value]}
          </Typography>
          )
      }

    </>
  );
};

const {
  number,
} = PropTypes;

FinanceType.propTypes = {
  value: number.isRequired,
};

export default FinanceType;
