import React from 'react';
import PropTypes from 'prop-types';

import Selection from 'ui/Selection';

const TempSelect = (props) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <Selection
      radio
      value={value}
      onChange={onChange}
      options={[
        {
          id: 3,
          label: 'Hot',
          iconName: 'hot',
          iconType: 'custom',
          iconColor: 'hot',
        },
        {
          id: 2,
          label: 'Warm',
          iconName: 'warm',
          iconType: 'custom',
          iconColor: 'warm',
        },
        {
          id: 1,
          label: 'Cold',
          iconName: 'cold',
          iconType: 'custom',
          iconColor: 'cold',
        },
      ]}
    />
  );
};

TempSelect.defaultProps = {
  value: 1,
};

const {
  func,
  number,
} = PropTypes;

TempSelect.propTypes = {
  value: number,
  onChange: func.isRequired,
};

export default TempSelect;
