import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';

import thunk from 'redux-thunk';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

// TODO - fix dependency cycles
import reducers from './reducers'; // eslint-disable-line import/no-cycle

export const history = createBrowserHistory();

const middleware = () => {
  // eslint-disable-next-line no-underscore-dangle
  if (window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production') {
    return compose(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        createRavenMiddleware(Raven, {}),
      ),
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  }

  return applyMiddleware(
    routerMiddleware(history),
    thunk,
    createRavenMiddleware(Raven, {}),
  );
};

const store = createStore(reducers(history), middleware());
export default store;
