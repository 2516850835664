import apiConnector, { endpoints } from 'api';

/**
 * Sends GET request to retrieve previous monthly payment.
 * @param {Object} queryParams - Request query params.
 * @returns {Promise} A response from server.
 */
const getPreviousMonthlyPayment = async (queryParams) => {
  const endpoint = endpoints.client.billing.previousMonthlyPayment;
  let response;

  try {
    response = await apiConnector.client.get(endpoint, queryParams);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default getPreviousMonthlyPayment;
