import { connect } from 'react-redux';

import {
  saveEditorContent,
  editArticle,
  showLinkToUrl,
  showLinkToImg,
} from 'store/actions/articleDetail';

import { getThumbnailInf } from 'store/actions/thumbnails';

import ArticleEditor from './ArticleEditor';

const mapStateToProps = (state) => ({
  article: state.articleDetail.article,
  activeArticleId: state.articleDetail.activeArticleId,
  articleModalMode: state.articleDetail.articleModalMode,
  articleTextServer: state.articleDetail.articleTextServer,
});

const mapDispatchToProps = (dispatch) => ({
  saveEditorContent: (content) => dispatch(saveEditorContent(content)),
  editArticle: (param, value, id) => dispatch(editArticle(param, value, id)),
  showLinkToUrl: () => dispatch(showLinkToUrl()),
  showLinkToImg: () => dispatch(showLinkToImg()),
  getThumbnailInf: (url) => dispatch(getThumbnailInf(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditor);
