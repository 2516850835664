import { connect } from 'react-redux';

import { openRepairRequestNotesModal } from 'store/actions/realtorRepairRequests';

import NoteCell from 'shared/components/UI/TableCells/NoteCell';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(openRepairRequestNotesModal(ownProps.requestId));
  },
});

export default connect(null, mapDispatchToProps)(NoteCell);
