import apiConnector, { endpoints } from 'api';

const getEmployeeAttachments = async (employeeId) => {
  const endpoint = `${endpoints.client.attachments}/employee/${employeeId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getEmployeeAttachments;
