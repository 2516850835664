import {
  SET_SHIPPED_ITEM_ATTACHMENTS,
} from 'store/actionTypes/shippedItems';

const setShippedItemAttachments = (id, attachments) => ({
  type: SET_SHIPPED_ITEM_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setShippedItemAttachments;
