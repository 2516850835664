import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SellerLead from './SellerLead';

const mapStateToProps = (state, ownProps) => {
  const options = selectors.realtorLeads.leadDetailsOptions(state, ownProps.leadId);
  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  const property = selectors.properties.byId(state, options[0].itemId);
  const address = selectors.addresses.byId(state, property.address_id);

  return {
    contactId: lead?.contact_id,
    propertyId: options[0].itemId,
    address: address?.address,
  };
};

export default connect(mapStateToProps, null)(SellerLead);
