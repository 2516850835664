import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Typography from 'ui/Typography';

import MainLeadInfo from './MainLeadInfo';

import {
  Root,
  StyledCardElevation,
  ObjectNameTag,
  CardWrapper,
} from './styledItems';

const LeadCard = (props) => {
  const {
    objectId,
    type,
    leadId,
    fullCardInformation,
  } = props;

  const history = useHistory();

  const onCardClick = useCallback(() => {
    history.push(`/leads/${leadId}`);
  }, [leadId]);

  return (
    <CardWrapper onClick={onCardClick}>
      {
        fullCardInformation && (
          <ObjectNameTag>
            <Typography
              variant="subtitle2"
              color="main-text-inversed"
              className="object-name"
              weight={500}
              noMargin
            >
              LEAD
            </Typography>
          </ObjectNameTag>
        )
      }

      <StyledCardElevation showObjectName={fullCardInformation}>
        <Root>
          <MainLeadInfo
            objectId={objectId}
            type={type}
            fullCardInformation={fullCardInformation}
            leadId={leadId}
          />
        </Root>
      </StyledCardElevation>
    </CardWrapper>

  );
};

LeadCard.defaultProps = {
  fullCardInformation: false,
};

const {
  number,
  string,
  bool,
} = PropTypes;

LeadCard.propTypes = {
  objectId: number.isRequired,
  type: string.isRequired,
  leadId: number.isRequired,
  fullCardInformation: bool,
};

export default LeadCard;
