import { connect } from 'react-redux';

import {
  optimizedPrioritiesActions,
} from 'store/actions/prioritiesObjects';

import PrioritiesObjects from './PrioritiesObjects';

const mapStateToProps = (state) => {
  const {
    realtorPriorityObjects: {
      prioritiesObjects,
    },
  } = state;

  return {
    prioritiesObjects,
  };
};

const mapDispatchToProps = () => ({
  getPrioritiesObjects: () => {
    optimizedPrioritiesActions.getPropertiesObjects();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrioritiesObjects);
