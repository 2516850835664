import {
  SET_REALTOR_LEAD,
} from 'store/actionTypes/realtorLeads';

const setLeads = (lead) => ({
  type: SET_REALTOR_LEAD,
  payload: lead,
});

export default setLeads;
