import apiConnector from 'api';

const get = async () => {
  const endpoint = '/client/my-info';

  const response = await apiConnector.global.get(endpoint);

  return response.data;
};

export default get;
