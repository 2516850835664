import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import AcceptCounterModal from './AcceptCounterModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      acceptCounterModal,
    },
    realtorAcceptCounterForm: {
      offerId,
      rootOfferId,
    },
  } = state;

  const isRootOffer = offerId === rootOfferId;

  return {
    isVisible: acceptCounterModal?.isVisible,
    title: `Accept ${isRootOffer ? 'Offer' : 'Counter'}`,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('acceptCounterModal')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptCounterModal);
