import {
  SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER,
} from 'store/actionTypes/tasks';

const setActiveTaskPlannerBacklogFilter = (filterId) => ({
  type: SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER,
  payload: filterId,
});

export default setActiveTaskPlannerBacklogFilter;
