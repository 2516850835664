import { connect } from 'react-redux';

import {
  setActiveMeeting,
  expandModal,
  createMeeting,
} from 'store/actions/video-chat/index';

import {
  setOnlineUsers,
  setCallee,
  setCaller,
  setIsCallDeclined,
  setInvitedEmails,
  createInvitations,
  setMeetingForInvitations,
} from 'store/actions/call';

import OACall from './OACall';

const mapStateToProps = (state) => {
  const {
    auth: {
      loggedUser,
    },
    call: {
      onlineUsers,
      callee,
      caller,
      isCallDeclined,
      invitedEmails,
      meetingForInvitations,
    },
    videoChat: {
      activeMeeting,
      activeMeetingParticipants,
    },
  } = state;

  return {
    loggedUser,
    onlineUsers,
    callee,
    caller,
    isCallDeclined,
    invitedEmails,
    meetingForInvitations,
    activeMeeting,
    activeMeetingParticipants,
  };
};

const mapDispatchToProps = {
  setActiveMeeting,
  expandModal,
  createMeeting,
  setOnlineUsers,
  setCallee,
  setCaller,
  setIsCallDeclined,
  setInvitedEmails,
  createInvitations,
  setMeetingForInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(OACall);
