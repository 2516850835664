import { connect } from 'react-redux';

import {
  postComment,
} from 'store/actions/comments';

import CommentsArea from 'shared/components/CommentsArea';

const mapStateToProps = (state) => {
  const {
    realtorContacts: {
      selectedContact,
    },
  } = state;

  return {
    objectId: Number(selectedContact),
    objectType: 'realtor contact',
    contactId: Number(selectedContact),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addComment: (data) => {
    const comment = {
      Comment: data.comment,
      CommentType: data.commentType,
      IsPublic: false,
    };

    return dispatch(postComment('realtor contact')(data.objectId)(comment));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentsArea);
