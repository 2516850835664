/* eslint-disable react/require-default-props */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { refType } from 'oa/types/index';
import './style.css';

const Checkbox = (props) => {
  const {
    htmlId,
    name,
    label,
    checked,
    onChange,
    className,
    value,
    checkboxRef,
  } = props;

  return (
    <div className={classNames('checkbox', className)}>
      <input
        className="checkbox__input visually-hidden"
        type="checkbox"
        id={htmlId}
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        ref={checkboxRef}
      />

      <label htmlFor={htmlId} className="checkbox__label">
        <span className="checkbox__icon" aria-hidden="true" />
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  htmlId: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  checkboxRef: refType,
};

export default Checkbox;
