import { isEmpty } from 'shared/utility';
import { callAlert } from 'ui/Alert';
import requests from 'api/requests';
// eslint-disable-next-line import/no-cycle
import {
  apiConnector,
  getConnector,
  noContentTypeFetch,
} from 'shared/API';
import newApiConnector from 'api';
import { url } from 'paths';
// eslint-disable-next-line import/no-cycle
import { toDoListSetViewedAction } from 'store/actions/to-do-list';
import { auth } from 'constants/auth.constants';

import { memoizeDebounce } from 'shared/utility';
// eslint-disable-next-line import/no-cycle
import store from 'store';

export const PARTIALLY_UPDATE_EMPLOYEE = 'PARTIALLY_UPDATE_EMPLOYEE';
export const partiallyUpdateEmployee = (payload) => ({
  type: PARTIALLY_UPDATE_EMPLOYEE,
  payload,
});

export const updateEmployeeSectionPermission = (params) => async (dispatch) => {
  const {
    employeeId,
    id,
    payload,
  } = params;

  try {
    const response = await requests.employeesV2.sectionPermissions.update({
      employeeId,
      id,
      payload,
    });

    dispatch(partiallyUpdateEmployee(response.data));
  } catch (error) {
    callAlert.error('An error occurred while updating the employee.');
  }
};

export const SET_EMPLOYEE_AVAILABILITY = 'SET_USER_AVAILABILITY';
export const setEmployeeAvailability = ({ employeeId, availabilityStatus }) => ({
  type: SET_EMPLOYEE_AVAILABILITY,
  availabilityStatus,
  employeeId,
});

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const getEmployees = (options = '') => (dispatch) => {
  dispatch({ type: GET_EMPLOYEES });

  return getConnector(`/api/common/employees${options}`)
    .then((res) => dispatch(getEmployeesSuccess(res.result, res.totalCount)))
    .catch(() => dispatch(getEmployeesError()));
};

export const optimizedGetEmployees = memoizeDebounce((options) => {
  store.dispatch(getEmployees(options));
}, 5000, { leading: true, trailing: false });

export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const getEmployeesSuccess = (employees, total) => ({
  type: GET_EMPLOYEES_SUCCESS,
  employees,
  total,
});

export const GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR';
export const getEmployeesError = () => ({ type: GET_EMPLOYEES_ERROR });

export const GET_EMPLOYEES_COUNT_SUCCESS = 'GET_EMPLOYEES_COUNT_SUCCESS';
export const getEmployeesCountSuccess = (count) => ({
  type: GET_EMPLOYEES_COUNT_SUCCESS,
  count,
});

export const GET_EMPLOYEES_COUNT_ERROR = 'GET_EMPLOYEES_COUNT_ERROR';
export const getEmployeesCountError = () => ({
  type: GET_EMPLOYEES_COUNT_ERROR,
});

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const addEmployee = (
  employee,
  form,
  closeModal = null,
  isShowMessage = true,
) => async (dispatch) => {
  try {
    dispatch({ type: ADD_EMPLOYEE });
    // PLEASE DO NOT DO THIS
    // eslint-disable-next-line prefer-const
    let { Avatar = null, ...data } = employee;

    const formData = new FormData();

    if (!isEmpty(Avatar)) {
      formData.append('attachment', Avatar[0].originFileObj);
      const file = await noContentTypeFetch(
        `${url}/api/attachments/workspace/avatar/0`,
        {
          method: 'POST',
          body: formData,
        },
      ).then((res) => res.json());

      Avatar = file.url;
    }

    const newEmployee = await apiConnector('/company/create-employee', 'POST', {
      ...data,
      Avatar,
    });

    if (newEmployee && newEmployee.employeeId && formData && !isEmpty(Avatar)) {
      noContentTypeFetch(
        `${url}/api/attachments/workspace/employee-logo/${newEmployee.employeeId}`,
        {
          method: 'POST',
          body: formData,
        },
      ).then((response) => response.json());
    }

    dispatch(addEmployeeSuccess(form, closeModal, isShowMessage));

    return newEmployee.employeeId;
  } catch (error) {
    dispatch(addEmployeeError());
    return null;
  }
};

export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const addEmployeeSuccess = (form, closeModal, isShowMessage = true) => (dispatch) => {
  dispatch({ type: ADD_EMPLOYEE_SUCCESS });
  form.resetFields();
  dispatch(getEmployees());

  if (isShowMessage) {
    callAlert.success(auth.MESSAGES.SUCCESS.ADD_EMPLOYEE);
  }

  dispatch(toDoListSetViewedAction());
  if (closeModal) {
    closeModal();
  }
};

export const ADD_EMPLOYEE_ERROR = 'ADD_EMPLOYEE_ERROR';
export const addEmployeeError = () => ({ type: ADD_EMPLOYEE_ERROR });

export const GET_LOGGED_USER = 'GET_LOGGED_USER';
export const getLoggedUser = () => (dispatch, getState) => {
  dispatch({ type: GET_LOGGED_USER });
  const { EmployeeID } = getState().auth.loggedUser;

  getConnector(`/api/common/employees/${EmployeeID}`)
    .then((user) => dispatch(getLoggedUserSuccess(user)))
    .catch(() => dispatch(getLoggedUserError()));
};

export const GET_LOGGED_USER_SUCCESS = 'GET_LOGGED_USER_SUCCESS';
export const getLoggedUserSuccess = (user) => ({
  type: GET_LOGGED_USER_SUCCESS,
  user,
});

export const GET_LOGGED_USER_ERROR = 'GET_LOGGED_USER_ERROR';
export const getLoggedUserError = () => ({ type: GET_LOGGED_USER_ERROR });

export const REQUEST_ACCESS = 'REQUEST_ACCESS';

export const requestAccess = (adminUser, loggedUser, productName) => async (dispatch) => {
  const body = {
    mail: adminUser[0].Email,
    firstName: adminUser[0].Name,
    userFullName: `${loggedUser.Name} ${loggedUser.Surname}`,
    productName,
  };
  const requestPath = '/company/license/request-access';
  const response = await newApiConnector.client.post(requestPath, body);
  if (response.status === 201) {
    callAlert.success('Email with access request sent!');
    dispatch(requestAccessSuccess());
  } else {
    callAlert.error('Email was not sent!');
    dispatch(requestAccessError());
  }
};

export const REQUEST_ACCESS_SUCCESS = 'REQUEST_ACCESS_SUCCESS';

export function requestAccessSuccess() {
  return {
    type: REQUEST_ACCESS_SUCCESS,
  };
}

export const REQUEST_ACCESS_ERROR = 'REQUEST_ACCESS_ERROR';

export function requestAccessError() {
  return {
    type: REQUEST_ACCESS_ERROR,
  };
}
