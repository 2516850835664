import {
  GET_PLANNED_SYSTEM_MAINTENANCE,
} from 'store/actionTypes/systemCheck';
import request from 'api/requests';
import { callAlert } from 'ui/Alert';

export const getPlannedSystemMaintenance = () => async (dispatch) => {
  try {
    const plannedSystemMaintenance = await request.systemCheck.getPlannedMaintenance();

    dispatch({
      type: GET_PLANNED_SYSTEM_MAINTENANCE,
      plannedSystemMaintenance,
    });
  } catch {
    callAlert.error('An error occurred while getting information about system maintenance!');
  }
};

export default {
  getPlannedSystemMaintenance,
};
