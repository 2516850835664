import { connect } from 'react-redux';
import { history } from 'store';

import {
  closeTutorialLaunchModal,
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  setStepIndex,
} from 'store/actions/tutorial';

import {
  addTutorialLead,
  handlePitchDetailsModal,
} from 'store/actions/leads';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import initialSteps from './steps';
import filterSteps from '../shared/utils/filterSteps';

import SalesTutorial from './SalesTutorial';

const mapStateToProps = (state) => {
  const hasManagerPermissions = checkLoggedEmployeePermissions.isManager('Sales');
  const steps = filterSteps(initialSteps, hasManagerPermissions);

  const {
    tutorial: {
      isLaunchModalVisible,
      stepIndex,
    },
    leads: {
      isPitchModalVisible,
    },
    router: {
      location,
    },
  } = state;

  return {
    isLaunchModalVisible,
    history,
    location,
    isPitchModalVisible,
    stepIndex,
    steps,
  };
};

const mapDispatchToProps = {
  closeTutorialLaunchModal,
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  addTutorialLead,
  handlePitchDetailsModal,
  setStepIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesTutorial);
