import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getOffersViewItems from 'store/actions/realtorOffers/getOffersViewItems';

import OffersList from './OffersList';

const mapStateToProps = (state) => {
  const {
    actionColumnItems,
    awaitingResponseColumnItems,
  } = selectors.realtorOffers.offersView(state);

  return {
    actionColumnItems,
    awaitingResponseColumnItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItems: () => {
    dispatch(getOffersViewItems());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
