import requests from 'api/requests';
import { callAlert } from 'ui/Alert';

import {
  filePreloader,
} from 'store/actions/attachments';

import getAttachmentsList from './getAttachmentsList';

const deleteAttachment = (objectType, objectId, attachmentId) => async (dispatch) => {
  dispatch(filePreloader(true));

  try {
    await requests.attachments.deleteAttachmentV2(objectType)(objectId)(attachmentId);

    callAlert.success('File deleted!');
  } catch (e) {
    callAlert.error('Could not delete this file!');
    console.error(e);
  }

  await dispatch(getAttachmentsList(objectType)(objectId));
  dispatch(filePreloader(false));
};

export default deleteAttachment;
