import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Progress from 'ui/Progress';

import Card from './ContactCard';

import {
  Container,
  StyledSpin,
} from './styledItems';

const animationItems = [1, 2, 3, 4, 5];

const prepareContacts = (arr) => arr.map((item) => ({
  id: item.id,
  name: `${item.given_name || ''} ${item.surname || ''}`,
  email: item.emails[0]?.email || '',
}));

const ContactsImportAnimation = (props) => {
  const {
    contacts,
    allContactsCount,
    tokenId,
    importInProgress,
  } = props;

  const [loadedItems, setLoadedItems] = useState([]);
  const [preparedContacts, setPreparedContacts] = useState([]);

  useEffect(() => {
    setPreparedContacts(prepareContacts(contacts));
  }, [
    contacts?.length,
  ]);

  useEffect(() => {
    const startLoop = () => {
      animationItems.forEach((item) => {
        const elem = document.querySelector(`.contact-import-card-${item}`);

        if (elem) {
          setTimeout(() => {
            elem.classList.add('done-importing');
            setLoadedItems((arr) => [...arr, `contact-import-card-${item}`]);
          }, item * 2000);
        }
      });
    };

    setTimeout(() => {
      startLoop();
    }, 2000);
  }, []);

  let progressPercent = Math.round(loadedItems.length * (100 / preparedContacts.length));
  let importedContactsCount = Math.round(allContactsCount * (progressPercent / 100));

  if (progressPercent === 100 && importInProgress) {
    progressPercent = 99;
  }
  if (importedContactsCount === allContactsCount && importInProgress) {
    importedContactsCount = allContactsCount - 1;
  }

  return (
    <div>
      <Typography
        align="right"
        noMargin
        style={{ paddingRight: 10, marginBottom: 8 }}
      >
        {`${importedContactsCount} / ${allContactsCount} contacts imported`}
      </Typography>

      <Progress percent={progressPercent} />

      <Container>
        {
          preparedContacts.map((item, index) => (
            <Card
              className={`contact-import-card contact-import-card-${index + 1}`}
              id={item.id}
              name={item.name}
              email={item.email}
              loading={!loadedItems.includes(`contact-import-card-${index + 1}`)}
              tokenId={tokenId}
            />
          ))
        }
        {
          importInProgress && <StyledSpin />
        }
      </Container>
    </div>
  );
};

ContactsImportAnimation.defaultProps = {
  contacts: [],
  allContactsCount: 0,
};

const {
  arrayOf,
  shape,
  number,
  string,
  bool,
} = PropTypes;

ContactsImportAnimation.propTypes = {
  contacts: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    email: string,
  })),
  allContactsCount: number,
  tokenId: number.isRequired,
  importInProgress: bool.isRequired,
};

export default ContactsImportAnimation;
