/**
 *
 * @param {Number} required required permission code
 * @param {Number} assigned assigned permission code
 *
 * @returns {Boolean} do assigned permission match required permissions or not
 */
const checkPermissions = (required, assigned) => !!(required & assigned); // eslint-disable-line no-bitwise, max-len
// We are deliberately using bitwise operators here

export default checkPermissions;
