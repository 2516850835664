import requests from 'api/requests';

import setOffer from 'store/actions/realtorOffers/setOffer';
import setSelectedOffer from 'store/actions/realtorOffers/setSelectedOffer';
import setClient from 'store/actions/realtorClients/setClient';
import {
  setProperty,
  setPropertyAttachments,
} from 'store/actions/properties';
import setAddress from 'store/actions/addresses/setAddress';
import setContacts from 'store/actions/realtorContacts/setContacts';
import { setUser } from 'store/actions/users';
import setDictionary from 'store/actions/dictionaryV2/setDictionary';
import setAttachments from 'store/actions/attachmentsV2/setAttachments';

// eslint-disable-next-line consistent-return
const get = () => async (dispatch) => {
  try {
    const response = await requests.external.realtor.offers.get();

    const contacts = response.data?.contacts || [];

    dispatch(setContacts(contacts));
    contacts.forEach((contact) => {
      dispatch(setUser('realtorContact')(contact.id)({
        name: contact.first_name,
        surname: contact.last_name,
        role: contact.role,
        avatar: contact.avatar,
      }));
    });

    dispatch(setProperty(response.data?.property));
    dispatch(setAddress(response.data?.address));

    dispatch(setPropertyAttachments(response.data?.property?.id, response.data?.propertyImages));
    dispatch(setAttachments(response.data?.propertyImages));

    if (response.data?.client?.id) {
      dispatch(setClient(response.data.client));
    }

    dispatch(setOffer(response.data?.rootOffer));

    const offerCounters = response.data?.offerCounters || [];
    offerCounters.forEach((counter) => {
      dispatch(setOffer(counter));
    });

    dispatch(setSelectedOffer({
      offerId: response.data?.selectedOfferId,
      rootOfferId: response.data?.rootOfferId,
    }));

    dispatch(setDictionary('realtorSpecialConditions')(response.data?.specialConditionsDictionary));
  } catch (error) {
    console.error(error);
  }
};

export default get;
