import {
  SET_LOG_OFFER_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorOpportunities';

const setLogOfferFormAttachments = (filesList = []) => ({
  type: SET_LOG_OFFER_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setLogOfferFormAttachments;
