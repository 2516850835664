import {
  useState,
} from 'react';

import Modal from 'ui/Modal';

const useModal = (isVisible = false) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  return {
    isModalVisible,
    openModal,
    closeModal,
    Modal,
  };
};

export default useModal;
