export const SET_TASKS_FOR_EMPLOYEE = 'SET_TASKS_FOR_EMPLOYEE';
export const SET_TASKS_FOR_TEAM = 'SET_TASKS_FOR_TEAM';
export const SET_TASKS_COUNT_FOR_EMPLOYEE = 'SET_TASKS_COUNT_FOR_EMPLOYEE';
export const SET_TASKS_COUNT_FOR_TEAM = 'SET_TASKS_COUNT_FOR_TEAM';
export const SET_TASKS_SUMMARY_FOR_EMPLOYEE = 'SET_TASKS_SUMMARY_FOR_EMPLOYEE';
export const SET_TASKS_SUMMARY_FOR_TEAM = 'SET_TASKS_SUMMARY_FOR_TEAM';
export const SET_TASK = 'SET_TASK';
export const SET_TASKS = 'SET_TASKS';
export const SET_TASKS_VIEW = 'SET_TASKS_VIEW';
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
export const SET_OVERALL_TASKS_SUMMARY = 'SET_OVERALL_TASKS_SUMMARY';
export const ADD_TUTORIAL_TASK = 'ADD_TUTORIAL_TASK';
export const REMOVE_TUTORIAL_TASK = 'REMOVE_TUTORIAL_TASK';
export const SET_TASK_WORKLOGS = 'SET_TASK_WORKLOGS';
export const SET_TASK_LAST_UPDATE_INFO = 'SET_TASK_LAST_UPDATE_INFO';
export const DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS = 'DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS';
export const DELETE_TASK_FROM_BACKLOG_FILTERED = 'DELETE_TASK_FROM_BACKLOG_FILTERED';

export const SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD = 'SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD';

export const SET_TASKS_FOR_FILTER = 'SET_TASKS_FOR_FILTER';
export const SET_TASKS_COUNT_FOR_FILTER = 'SET_TASKS_COUNT_FOR_FILTER';
export const SET_TASKS_SUMMARY_FOR_FILTER = 'SET_TASKS_SUMMARY_FOR_FILTER';
export const SET_TASK_PLANNER_BACKLOG_FILTER = 'SET_TASK_PLANNER_BACKLOG_FILTER';
export const CLEAR_FILTER_SUMMARIES = 'CLEAR_FILTER_SUMMARIES';
export const SET_TASK_PLANNER_SEARCH = 'SET_TASK_PLANNER_SEARCH';
export const SET_TASK_PLANNER_BACKLOG_SEARCH = 'SET_TASK_PLANNER_BACKLOG_SEARCH';
export const SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER = 'SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER';

export default {
  SET_TASKS_FOR_EMPLOYEE,
  SET_TASKS_FOR_TEAM,
  SET_TASKS_COUNT_FOR_EMPLOYEE,
  SET_TASKS_COUNT_FOR_TEAM,
  SET_TASKS_SUMMARY_FOR_EMPLOYEE,
  SET_TASKS_SUMMARY_FOR_TEAM,
  SET_TASK,
  SET_TASKS,
  SET_TASKS_VIEW,
  SET_SELECTED_TASK,
  SET_OVERALL_TASKS_SUMMARY,
  ADD_TUTORIAL_TASK,
  REMOVE_TUTORIAL_TASK,
  SET_TASK_WORKLOGS,
  SET_TASK_LAST_UPDATE_INFO,
  DELETE_EMPLOYEE_TASK_FROM_TASKS_BOARDS,
  DELETE_TASK_FROM_BACKLOG_FILTERED,
  SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD,

  SET_TASKS_FOR_FILTER,
  SET_TASKS_COUNT_FOR_FILTER,
  SET_TASKS_SUMMARY_FOR_FILTER,
  SET_TASK_PLANNER_BACKLOG_FILTER,
  CLEAR_FILTER_SUMMARIES,
  SET_TASK_PLANNER_SEARCH,
  SET_TASK_PLANNER_BACKLOG_SEARCH,
  SET_ACTIVE_TASK_PLANNER_BACKLOG_FILTER,
};
