import React, { useContext } from 'react';

import Table from 'ui/Table';

import context from 'sections/Integrations/context';

import columns from './columns';

const IntegrationsTable = (props) => {
  const {
    integrations,
    getIntegrations,
    integrationsIsLoading: isLoading,
  } = useContext(context);

  return (
    <Table
      key="integrations-table"
      dataSource={integrations || []}
      columns={columns({
        onRemove: getIntegrations,
      })}
      loading={isLoading}
      tableLayout="fixed"
      size="middle"
    />
  );
};

export default IntegrationsTable;
