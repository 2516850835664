import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_INSPECTION,
  SET_REALTOR_INSPECTIONS,
  SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT,
  SET_REALTOR_INSPECTIONS_FOR_FILTER,
  SET_INSPECTIONS_TYPES,
  SET_INSPECTION_REQUEST_ATTACHMENTS,
  SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN,
  SET_INSPECTION_REQUEST_NOTES_MODAL_CLOSED,
} from 'store/actionTypes/realtorInspections';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  inspections: {},
  inspectionsByFilter: {},
  inspectionsByFilterCount: {},
  inspectionsTypes: {},

  selectedInspection: null,
  isInspectionNotesModalVisible: false,
};

const reducers = {
  [SET_INSPECTION_REQUEST_NOTES_MODAL_OPEN]: (state, action) => ({
    ...state,
    isInspectionNotesModalVisible: true,
    selectedInspection: action.payload.inspectionId,
  }),

  [SET_INSPECTION_REQUEST_NOTES_MODAL_CLOSED]: (state) => ({
    ...state,
    isInspectionNotesModalVisible: false,
    selectedInspection: null,
  }),

  [SET_REALTOR_INSPECTIONS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    inspectionsByFilterCount: {
      ...state.inspectionsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_INSPECTIONS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      inspections,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(inspections),
    ]);

    const updatedState = {
      ...state,
      inspectionsByFilter: {
        ...state.inspectionsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_INSPECTION]: (state, action) => ({
    ...state,
    inspections: {
      ...state.inspections,
      [action.payload.id]: action.payload,
      attachments: state.inspections[action.payload.id]?.attachments,
    },
  }),

  [SET_INSPECTIONS_TYPES]: (state, action) => ({
    ...state,
    inspectionsTypes: {
      ...state.inspectionsTypes,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_INSPECTIONS]: (state, action) => ({
    ...state,
    inspections: {
      ...state.inspections,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_INSPECTION_REQUEST_ATTACHMENTS]: (state, action) => ({
    ...state,
    inspections: {
      ...state.inspections,
      [action.payload.id]: {
        ...state.inspections[action.payload.id],
        attachments: action.payload.attachments.map(({ attachmentId }) => attachmentId),
      },
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
