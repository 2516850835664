import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import { searchContact } from 'store/actions/realtorContacts';

import Textfield from 'ui/Textfield';

import SearchResult from './SearchResult';

import {
  Wrapper,
  SearchContactArea,
  StyledChip,
} from './styledItems';

const SearchContactInput = (props) => {
  const {
    handleChange,
    selectedContacts,
    handleDelete,
    label,
  } = props;

  const searchInputRef = useRef(null);
  const foundContactsRef = useRef(null);

  const [searchedContacts, setSearchedContacts] = useState([]);

  const [isSearchAreaVisible, setSearchAreaVisibility] = useState(false);

  const [searhInput, setSearchInput] = useState(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = useCallback((event) => {
    const isSearchInputClick = searchInputRef.current ? (
      searchInputRef.current.input === event.target
    ) : (
      false
    );

    const isFoundContactsClick = foundContactsRef.current ? (
      foundContactsRef.current.contains(event.target)
    ) : (
      false
    );

    if (
      !isSearchInputClick
      && !isFoundContactsClick
    ) {
      setSearchAreaVisibility(false);
    }
  }, []);

  const onSearchInputFocus = useCallback(() => {
    setSearchAreaVisibility(true);
  }, []);

  const onSearch = useDebouncedCallback(async (value) => {
    if (!value) {
      setSearchedContacts([]);

      return;
    }

    const contacts = await searchContact(value)();

    setSearchedContacts(contacts.map((item) => item.id));
  }, 800);

  const onInput = useCallback((value) => {
    setSearchInput(value);

    onSearch(value);
  }, [onSearch]);

  const onContactClick = useCallback((contactId) => () => {
    handleChange(contactId);
    setSearchInput('');
    setSearchAreaVisibility(false);
  }, [handleChange]);

  /* eslint-disable camelcase */
  return (
    <Wrapper>
      <Textfield
        value={searhInput}
        label={label}
        onChange={onInput}
        onFocus={onSearchInputFocus}
        inputRef={searchInputRef}
      />
      {
        isSearchAreaVisible && (
          <SearchContactArea ref={foundContactsRef}>
            <SearchResult
              searchedContacts={searchedContacts}
              onContactClick={onContactClick}
            />
          </SearchContactArea>
        )
      }
      {
        selectedContacts.map((contact) => {
          const {
            id,
            first_name = '',
            last_name = '',
            email_primary,
          } = contact;

          return (
            <Fragment key={id}>
              <StyledChip
                onDelete={handleDelete(id)}
                variant="subtle"
              >
                {`${first_name} ${last_name}${email_primary ? ` | ${ email_primary}` : ''}`}
              </StyledChip>
            </Fragment>
          );
        })
      }
    </Wrapper>
  );
};
/* eslint-enable camelcase */

SearchContactInput.defaultProps = {
  label: 'Search existing contacts',
  selectedContacts: [],
};

const {
  func,
  arrayOf,
  shape,
  string,
  number,
} = PropTypes;

SearchContactInput.propTypes = {
  handleChange: func.isRequired,
  handleDelete: func.isRequired,
  selectedContacts: arrayOf(shape({
    id: number,
    first_name: string,
    last_name: string,
    email_primary: string,
  })),
  label: string,
};

const decoratorProps = {
  iconName: 'user-tie',
};

export default withFieldDecorator(decoratorProps)(SearchContactInput);
