import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  width: 100%;
  padding: 5px 7px 7px;
  border-radius: 8px;
  background-color: ${(props) => props?.theme?.palette?.background || 'white'};
  box-shadow: 0 2px 9px 0 rgba(9, 55, 83, 0.1);
`;
