import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Field from 'sections/Addresses/modules/AddressInput';

const AddressInput = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'address',
    control,
  });

  const handleChange = useCallback((data) => {
    onChange(data?.address);
  }, [
    onChange,
  ]);

  return (
    <Field
      value={value}
      onChange={handleChange}
      fullWidth
      raw
    />
  );
};

const {
  shape,
} = PropTypes;

AddressInput.propTypes = {
  control: shape({}).isRequired,
};

export default AddressInput;
