import {
  SET_PROPERTIES_FOR_FILTER,
} from 'store/actionTypes/properties';

const setPropertiesForFilter = (filterId) => (properties) => ({
  type: SET_PROPERTIES_FOR_FILTER,
  payload: {
    filterId,
    properties,
  },
});

export default setPropertiesForFilter;
