import styled from 'styled-components';
import { Progress } from 'antd';

const StyledProgress = styled(Progress)`
  .ant-progress-bg {
    background-color: ${(props) => props.theme.palette?.focus || 'blue'};
  }
  &&.ant-progress-status-success .ant-progress-bg {
    background-color: ${(props) => props.theme.palette?.success || 'green'};
  }
  &&.ant-progress-status-success .ant-progress-text {
    color: ${(props) => props.theme.palette?.success || 'green'};
  }
`;

export default StyledProgress;
