import React, {
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroller';

import Spin from 'ui/Spin';
import CenterBox from 'shared/components/UI/CenterBox';

import HorizontalDivider from 'ui/HorizontalDivider';

import FollowUpCard from 'sections/FollowUps/modules/FollowUpCard';

import {
  getItemDateSection,
  isSameSection,
} from './utils';

const FollowUpTabPanel = (props) => {
  const {
    items,
    loadMore,
    itemsCount,
    withSections,
    clearList,
  } = props;

  useEffect(() => {
    if (!items.length) {
      loadMore(0, 10)();
    }
    return () => {
      clearList();
    };
  }, []);

  const renderItemsWithSections = useCallback(() => {
    if (!Array.isArray(items) || items.length === 0) {
      return null;
    }

    let currentSection;
    let isDividerAtTheTop = true;

    return items.map((item) => {
      const currentItemDate = item?.scheduled_date;

      if (isSameSection(currentItemDate, currentSection)) {
        return <FollowUpCard reminderId={item.id} />;
      }

      currentSection = getItemDateSection(currentItemDate);

      if (!currentSection) {
        return null;
      }

      const itemsToRender = (
        <>
          <HorizontalDivider
            text={currentSection}
            marginTop={isDividerAtTheTop ? 10 : 40}
            marginBottom={30}
          />
          <FollowUpCard reminderId={item.id} />
        </>
      );

      isDividerAtTheTop = false;

      return itemsToRender;
    });
  }, [
    items,
  ]);

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore(items?.length, 10)}
      hasMore={itemsCount > items?.length}
      loader={<CenterBox><Spin /></CenterBox>}
      useWindow={false}
    >
      {
        withSections ? renderItemsWithSections() : items?.map((item) => <FollowUpCard reminderId={item.id} />)
      }
    </InfiniteScroll>
  );
};

FollowUpTabPanel.defaultProps = {
  withSections: false,
  clearList: () => {},
};

const {
  number,
  string,
  func,
  arrayOf,
  shape,
  bool,
} = PropTypes;

FollowUpTabPanel.propTypes = {
  items: arrayOf(shape({
    id: number.isRequired,
    scheduled_date: string.isRequired,
  })).isRequired,
  loadMore: func.isRequired,
  itemsCount: func.isRequired,
  withSections: bool,
  clearList: func,
};

export default FollowUpTabPanel;
