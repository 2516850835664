import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_CONTACT_LOG_MODAL_CLOSED,
  SET_NEW_REALTOR_CONTACT_LOG_MODAL_OPEN,
} from 'store/actionTypes/realtorContacts';

import {
  setVisibilityOn,
  setVisibilityOff,
} from 'store/utils';

const initialState = {
  isVisible: false,
};

const reducers = {
  [SET_NEW_REALTOR_CONTACT_LOG_MODAL_OPEN]: setVisibilityOn,

  [SET_NEW_REALTOR_CONTACT_LOG_MODAL_CLOSED]: setVisibilityOff,
};

function newLogModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newLogModal;
