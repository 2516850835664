import styled from 'styled-components';

export const Wrapper = styled.div`
  .offer-property-details-photo {
    margin-top: auto;
    margin-bottom: auto;
    div {
      width: 125px;
      height: 75px;
    }
  }
`;

export default {
  Wrapper,
};
