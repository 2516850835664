import { connect } from 'react-redux';

import {
  openModal,
} from 'store/actions/modals';

import ShowFullProfileButton from './ShowFullProfileButton';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(openModal('profileDetails')({}, { contactId: ownProps.contactId }));
  },
});

export default connect(null, mapDispatchToProps)(ShowFullProfileButton);
