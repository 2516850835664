import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertyListingAgent from './PropertyListingAgent';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    contactId: property.listing_agent,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: (contactId) => {
    dispatch(updateProperty(ownProps.propertyId)({ listing_agent: contactId }));
  },
  onRemove: () => {
    dispatch(updateProperty(ownProps.propertyId)({ listing_agent: null }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyListingAgent);
