import { connect } from 'react-redux';

import PriceText from 'shared/components/UI/PriceText';

const mapStateToProps = (state, ownProps) => {
  const {
    value,
  } = ownProps;

  return {
    value,
  };
};

export default connect(mapStateToProps)(PriceText);
