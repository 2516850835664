/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getOpportunities from './getOpportunities';
import getOpportunitiesForFilter from './getOpportunitiesForFilter';
import getOpportunitiesForStoreFilter from './getOpportunitiesForStoreFilter';
import getOpportunitiesOverallSummary from './getOpportunitiesOverallSummary';

export {
  getOpportunities,
  getOpportunitiesForFilter,
  getOpportunitiesForStoreFilter,
  getOpportunitiesOverallSummary,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedOpportunityActions = {
  getOpportunitiesForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getOpportunitiesForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getOpportunitiesForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getOpportunitiesForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getOpportunitiesOverallSummary: memoizeDebounce(
    () => store.dispatch(getOpportunitiesOverallSummary()),
    debounceDelay,
    debounceOptions,
  ),
};
