import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getCustomerInformedEvents from './get';
import getCustomerInformedById from './getById';
import getCustomerInformedAttachments from './getAttachments';

import createCustomerInformed from './create';

import setSelectedCustomerInformed from './set';

import updateCustomerInformed from './update';

export { getCustomerInformedEvents };
export { getCustomerInformedById };
export { getCustomerInformedAttachments };

export { createCustomerInformed };

export { setSelectedCustomerInformed };

export { updateCustomerInformed };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCustomerInformedActions = {
  getCustomerInformedById: memoizeDebounce(
    (id) => store.dispatch(getCustomerInformedById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
