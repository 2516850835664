import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (_, contactId) => contactId,
  (
    contacts,
    id,
  ) => {
    if (!id) {
      return [];
    }

    const contact = contacts[id];

    if (!contact) {
      if (id) {
        optimizedRealtorContactsActions.getContactById(id);
      }

      return [];
    }

    const callRecordings = contact.call_recordings || [];

    return callRecordings;
  },
);
