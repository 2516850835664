import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertySelect from './PropertySelect';

const mapStateToProps = (state, ownProps) => {
  const {
    properties,
  } = ownProps;

  const imageItems = properties?.map((property) => {
    const image = selectors.properties.propertyMainImage(state, property.realtor_property_id);
    const propertyObject = selectors.properties.byId(state, property.realtor_property_id);
    const address = selectors.addresses.byId(state, propertyObject.address_id);

    return {
      name: property.realtor_property_id,
      value: property.realtor_property_id,
      needsResponseCount: property.needsResponse.length,
      src: image?.thumbUrl,
      address: address?.address,
    };
  });

  return {
    imageItems,
  };
};

export default connect(mapStateToProps)(PropertySelect);
