import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getSummary from './getOverallSummary';
import getFollowUpReminderById from './getFollowUpReminderById';

const deleteFollowUpReminder = (reminderId) => async (dispatch) => {
  try {
    await requests.followUpReminders.deleteReminder(reminderId);

    callAlert.success('Deleted');

    dispatch(getFollowUpReminderById(reminderId));
    dispatch(getSummary());
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteFollowUpReminder;
