import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ImageContainer = styled.div`
  width: 100%;
  max-width: 400px;
  height: 215px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.theme.palette?.['background-flat-dark'] || 'lightgray')};
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;
