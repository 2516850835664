import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';
import withInlineLabel from 'shared/hocs/withInlineLabel';

import MultiselectTextfield from 'ui/MultiselectTextfield';
import Textfield from 'ui/Textfield';
import EditableArea from 'ui/EditableArea';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import DropdownTextfield from 'ui/DropdownTextfield';

import Chip from 'ui/Chip';

import {
  ChipWrapper,
} from './styledItems';

const FormEditableField = (props) => {
  const {
    label,
    onChange,
    value,
    fieldType,
    placeholder,
    style,
    options,
    fullWidth,
    min,
    max,
    step,
  } = props;

  const [ownValue, setValue] = useState(value || '');

  useEffect(() => {
    setValue(value);
  }, [
    value,
  ]);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    onChange(newValue);
  }, [
    onChange,
  ]);

  const handleBlurTextField = useCallback((closeEditMode) => (event) => {
    if (fieldType === 'number') {
      if (event.target.value !== String(value)) {
        onChange(event.target.value);
      }
    } else if (event.target.value !== value) {
        onChange(event.target.value);
      }
    closeEditMode();
  }, [
    onChange,
    value,
    fieldType,
  ]);

  const handleChipDelete = useCallback((event) => {
    event.stopPropagation();
    onChange(null);
  }, [onChange]);

  const handleMultiChipDelete = useCallback((key) => (event) => {
    event.stopPropagation();
    const newValue = value.filter((index) => index !== Number(key));

    onChange(newValue);
  }, [
    onChange,
    value,
  ]);

  const handleBlurMultiSelectField = useCallback((closeEditMode) => (value) => {
    onChange(value.map((item) => item.value));
    closeEditMode();
  }, [
    onChange,
    value,
  ]);

  if (fieldType === 'multiSelect') {
    const selectedValue = Array.isArray(value)
      ? value.map((key) => ({
        value: `${key}`,
        label: options[key],
      }))
      : undefined;

    return (
      <EditableArea
        fullWidth={fullWidth}
        getEditComponent={(onBlur) => (
          <MultiselectTextfield
            onBlur={handleBlurMultiSelectField(onBlur)}
            options={options}
            value={selectedValue}
          />
      )}
      >
        {value ? (
          <Flex spacing={1}>
            {
              selectedValue.map(({ label, value }) => (
                <ChipWrapper>
                  <Chip
                    variant="subtle"
                    onDelete={handleMultiChipDelete(value)}
                    className="select-form-chip"
                  >
                    {label}
                  </Chip>
                </ChipWrapper>
              ))
            }
          </Flex>
        ) : (
          <Typography
            color="disabled"
            style={{
              cursor: 'pointer',
            }}
            fontStyle="italic"
            noMargin
          >
            Click to add
          </Typography>
        )}
      </EditableArea>
    );
  }

  if (fieldType === 'select') {
    return (
      <EditableArea
        label={label}
        getEditComponent={(onBlur) => (
          <DropdownTextfield
            onChange={handleChange}
            value={ownValue}
            options={options}
            menuBlur={onBlur}
            isOpen
            focusOnRender
            menuItemStyle={style}
            style={style}
            label={label}
          />
      )}
      >
        {value ? (
          <ChipWrapper>
            <Chip
              variant="subtle"
              onDelete={handleChipDelete}
              className="select-form-chip"
            >
              {value}
            </Chip>
          </ChipWrapper>
        ) : (
          <Typography
            color="disabled"
            style={{
              cursor: 'pointer',
            }}
            fontStyle="italic"
            noMargin
          >
            Click to add
          </Typography>
        )}
      </EditableArea>
    );
  }

  if (fieldType === 'text' || fieldType === 'number') {
    return (
      <EditableArea getEditComponent={(onBlur) => (
        <Textfield
          type={fieldType}
          variant="inline"
          onChange={handleChange}
          onBlur={handleBlurTextField(onBlur)}
          focusOnRender
          placeholder={placeholder}
          value={ownValue}
          min={min}
          max={max}
          step={step}
        />
      )}
      >
        {ownValue ? (
          <Typography
            variant="inline"
            style={{
              cursor: 'pointer',
            }}
            noMargin
          >
            {ownValue}
          </Typography>
        ) : (
          <Typography
            color="disabled"
            style={{
              cursor: 'pointer',
            }}
            fontStyle="italic"
            noMargin
          >
            Click to add
          </Typography>
        )}
      </EditableArea>
    );
  }

  return null;
};

FormEditableField.defaultProps = {
  label: null,
  onChange: () => {},
  value: null,
  fieldType: null,
  placeholder: null,
  style: {},
  options: [],
  fullWidth: false,
  min: null,
  max: null,
  step: 1,
};

const {
  string,
  shape,
  arrayOf,
  func,
  bool,
  number,
} = PropTypes;

FormEditableField.propTypes = {
  label: string,
  onChange: func,
  value: string,
  fieldType: string,
  placeholder: string,
  style: shape,
  options: arrayOf(shape()),
  fullWidth: bool,
  min: number,
  max: number,
  step: number,
};

const decoratorProps = {
  iconName: 'edit',
};

export default withFieldDecorator(decoratorProps)(withInlineLabel(FormEditableField));
