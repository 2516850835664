// TODO: move to usePropertyNotablePoints hook

import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  createNotablePoint,
  updateNotablePoint,
  removeNotablePointRelations,
} from 'store/actions/realtorNotablePointsAndDisclosures/notablePoints';

import PropertyNotablePoints from './PropertyNotablePoints';

const mapStateToProps = (state, ownProps) => ({
    selectedProperty: ownProps.propertyId,
  });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate: async (disclosureData) => {
    await dispatch(createNotablePoint({
      ...disclosureData,
      object_type: 27,
      object_id: ownProps.propertyId,
    }));
  },

  onChange: debounce(async (disclosureId, disclosureData, onAfter) => {
    await dispatch(updateNotablePoint(disclosureId)({
      ...disclosureData,
      object_type: 27,
      object_id: ownProps.propertyId,
    }));
    onAfter();
  }, 1000),

  onDelete: async (disclosureId) => {
    await dispatch(removeNotablePointRelations('objects')(disclosureId)({
      object_type: 27,
      object_id: ownProps.propertyId,
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onChange: (disclosureId) => (key) => (value) => (onAfter) => {
    dispatchProps.onChange(
      disclosureId,
      {
        [key]: value,
      },
      onAfter,
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyNotablePoints);
