import apiConnector, { endpoints } from 'api';

/**
 * Update employee permissions for application sections.
 * @param {Object} params - The function params.
 * @param {number} params.employeeId - The employee id.
 * @param {number} params.id - The id of section permission.
 * @param {Object} params.payload - The payload to send to server.
 * @param {string} params.payload.accessType - The type of access we want to use for employee.
 * @returns {Promise} A response from server.
 */

const update = async (params) => {
  const {
    employeeId,
    id,
    payload,
  } = params;

  const {
    employees,
    sectionPermissions,
  } = endpoints.client.employeesV2;

  const endpoint = `${employees}/${employeeId}/${sectionPermissions}/${id}`;
  let response;

  try {
    response = await apiConnector.client.patch(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default update;
