import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InternalError from '../ErrorPages/InternalError';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.info('');
    console.info('');
    console.info('Error (root Error Boundary):', error);
    console.info('');
    console.info('');
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      hasError,
    } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return <InternalError />;
    }

    return children;
  }
}

const {
  element,
} = PropTypes;

ErrorBoundary.propTypes = {
  children: element.isRequired,
};

export default ErrorBoundary;
