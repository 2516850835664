import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import {
  Root,
  Image,
  IconWrapper,
  Label,
} from './styledItems';

const PropertyImage = (props) => {
  const {
    image,
    isMyListing,
  } = props;

  return (
    <Root>
      {
        image ? (
          <Image
            status={status}
            src={image}
          />
        ) : (
          <IconWrapper>
            <Icon
              name="property"
              className="property-icon"
              type="custom"
              color="background-flat"
              size="xl"
            />
          </IconWrapper>
        )
      }
      <Label
        status={isMyListing ? 'my listing' : 'featured'}
      >
        {isMyListing ? 'My Listing' : 'Featured'}
      </Label>
    </Root>
  );
};

PropertyImage.defaultProps = {
  image: null,
  status: null,
};

const {
  string,
} = PropTypes;

PropertyImage.propTypes = {
  image: string,
  status: string,
};

export default React.memo(PropertyImage);
