import requests from 'api/requests';

import setOffer from './setOffer';

const getOfferWithCountersById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.offers.getById(id, { counters: true });
    dispatch(setOffer(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOfferWithCountersById;
