import apiConnector, { endpoints } from 'api';

const bulkScore = async (query) => {
  const endpoint = endpoints.smartzip.bulkScore;

  try {
    const result = await apiConnector.smartzip.get(endpoint, query);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default bulkScore;
