import { createSelector } from 'reselect';

import {
  optimizedCustomerDigitalAccountsActions,
} from 'store/actions/customerDigitalAccounts';

export default createSelector(
  (state, id) => ({
    accounts: state.customerDigitalAccounts.accounts,
    id,
  }),
  ({ accounts, id }) => {
    if (!id) return null;

    const dataObject = accounts[id];

    if (!dataObject) {
      optimizedCustomerDigitalAccountsActions.getDigitalAccountById(id);
    }

    return dataObject || {};
  },
);
