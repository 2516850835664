import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createFollowUpReminder = (objectTypeId) => (objectId) => async (reminderData) => {
  try {
    const response = await requests.syncedEvent.create(objectId)(objectTypeId)(reminderData);

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default createFollowUpReminder;
