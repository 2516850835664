import apiConnector, { endpoints } from 'api';

const updateLineItems = (fulfillmentId) => (productId) => async (updateData) => {
  const endpoint = `${endpoints.client.fulfillmentLineItemDetails}/${fulfillmentId}/${productId}`;

  try {
    const result = await apiConnector.client.patch(endpoint, updateData);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default updateLineItems;
