import React, {
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import OutgoingNumberValidator from 'sections/Calls/modules/OutgoingNumberValidator';

import CallsContext from 'sections/Calls/context/CallsContext';

const AddPhoneNumber = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
  } = props;

  const {
    getActivePhones,
  } = useContext(CallsContext);

  const onCancel = useCallback(() => {
    getActivePhones();
    closeModal();
  }, [
    getActivePhones,
    closeModal,
  ]);

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      theme={theme}
      title="Add a new phone number"
      width={560}
    >
      <OutgoingNumberValidator />
    </Modal>
  );
};

AddPhoneNumber.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
} = PropTypes;

AddPhoneNumber.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
};

export default AddPhoneNumber;
