import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} data
 */
const addToWaitlist = async (data) => {
  const endpoint = endpoints.global.realtorWaitlist;
  try {
    const result = await apiConnector.global.post(endpoint, data);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default addToWaitlist;
