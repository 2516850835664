import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';

const Notes = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'notes',
    control,
  });

  return (
    <Textfield
      fullWidth
      textarea
      placeholder="Notes"
      onChange={onChange}
      value={value}
    />
  );
};

const {
  shape,
} = PropTypes;

Notes.propTypes = {
  control: shape({}).isRequired,
};

export default Notes;
