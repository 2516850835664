import connectReducers from 'store/connectReducers';

import {
  SET_NEW_COMPANY_FORM_FIELD,
  SET_NEW_COMPANY_FORM_ASSIGNED_USERS,
  RESET_NEW_COMPANY_FORM,
} from 'store/actionTypes/crm';

const initialState = {
  // company data fields
  Title: null,
  logo: null,
  PrimaryLocation: null,
  PrimaryLocationLat: null,
  PrimaryLocationLng: null,
  WebsiteUrl: null,
  industry_type: null,
  permission_type: 1,
  permissions: {
    employees: [],
    teams: [],
    offices: [],
  },
  permissionGroup: 'everyone',
  assignedUsers: [],

  // helper fields
  companyNameFilter: null,
  contactNameFilter: null,
};

const reducers = {
  [SET_NEW_COMPANY_FORM_FIELD]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),

  [SET_NEW_COMPANY_FORM_ASSIGNED_USERS]: (state, action) => ({
    ...state,
    assignedUsers: action.payload,
  }),

  [RESET_NEW_COMPANY_FORM]: () => initialState,
};

function newCompanyForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newCompanyForm;
