export const questions = [
  {
    id: 'hairdressers_name',
    question: 'What was your first hairdressers name?',
  },
  {
    id: 'favorite_teacher',
    question: 'What is the name of your favorite teacher?',
  },
  {
    id: 'your_spouse',
    question: 'In what city did you meet your spouse?',
  },
  {
    id: 'childhood_home',
    question: 'On what road was your childhood home?',
  },
  {
    id: 'dream_job',
    question: 'What is your dream job?',
  },
  {
    id: 'maiden_name',
    question: "What is your mother's maiden name?",
  },
  {
    id: 'where_travel_to',
    question: 'What place do you most want to travel to?',
  },
  {
    id: 'childhood_nickname',
    question: 'What was your childhood nickname?',
  },
  {
    id: 'siblings_middle_name',
    question: "What is your sibling's middle name?",
  },
  {
    id: 'first_job',
    question: 'In what city or town was your first job?',
  },
  {
    id: 'first_car',
    question: 'What was the make and model of your first car?',
  },
  {
    id: 'first_and_last_name',
    question: "What is your oldest cousin's first and last name?",
  },
];
