import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getRefunds from './get';
import getRefundById from './getById';
import getRefundAttachments from './getAttachments';

import createRefund from './create';

import setSelectedRefund from './set';

import updateRefund from './update';

export { getRefunds };
export { getRefundById };
export { getRefundAttachments };

export { createRefund };

export { setSelectedRefund };

export { updateRefund };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRefundsActions = {
  getRefundById: memoizeDebounce(
    (id) => store.dispatch(getRefundById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
