import React from 'react';

import PropTypes from 'prop-types';

import { memoizeDebounce } from 'shared/utility';

import useFilters from 'shared/hooks/useFilters';
import useContactsList from 'sections/Contacts/hooks/useContactsList';

import Context from './context';

const isInfiniteScroll = true;

const loadContacts = memoizeDebounce((offset, setFilterValue) => {
  setFilterValue('offset')(offset);
},
1000,
{
  leading: true,
  trailing: false,
});

const ContextProvider = (props) => {
  const {
    children,
    highlightedContactStatus,
  } = props;

  const {
    filters: contactsListFilters,
    setValue: setFilterValue,
    reset: resetFilters,
  } = useFilters({
    limit: 12,
  });

  const {
    filters: highlightedContactsListFilters,
    setValue: setHighlightedFilterValue,
    reset: resetHighlightedFilters,
  } = useFilters({
    contactStatus: highlightedContactStatus,
    limit: 12,
  });

  const {
    contacts: contactsList,
    isLoading: isContactsListLoading,
    totalCount: contactsListTotalCount,
    error: contactsListError,
    reset: resetContactsList,
    canLoadMore: canLoadMoreContacts,
  } = useContactsList(
    contactsListFilters,
    isInfiniteScroll,
  );

  const {
    contacts: highlightedContactsList,
    isLoading: isHiglightedContactsListLoading,
    totalCount: highLightedContactsListTotalCount,
    error: highlightedContactsListError,
    reset: resetHighlightedContactsList,
    canLoadMore: canLoadMoreHighlightedContacts,
  } = useContactsList(
    highlightedContactsListFilters,
    isInfiniteScroll,
  );

  const loadMoreContacts = () => {
    loadContacts(contactsList?.length, setFilterValue);
  };

  const loadMoreHighlightedContacts = () => {
    loadContacts(contactsList?.length, setHighlightedFilterValue);
  };

  return (
    <Context.Provider value={{
      // all contacts
      contactsListFilters,
      setFilterValue,
      resetFilters,

      contactsList,
      isContactsListLoading,
      contactsListTotalCount,
      contactsListError,
      resetContactsList,
      canLoadMoreContacts,
      loadMoreContacts,

      // contacts with selected status
      highlightedContactsListFilters,
      setHighlightedFilterValue,
      resetHighlightedFilters,

      highlightedContactsList,
      isHiglightedContactsListLoading,
      highLightedContactsListTotalCount,
      highlightedContactsListError,
      resetHighlightedContactsList,
      canLoadMoreHighlightedContacts,
      loadMoreHighlightedContacts,
    }}
    >
      { children }
    </Context.Provider>
  );
};

ContextProvider.defaultProps = {
  highlightedContactStatus: null,
};

const {
  element,
  oneOf,
} = PropTypes;

ContextProvider.propTypes = {
  children: element.isRequired,
  highlightedContactStatus: oneOf([
    'buyer lead',
    'seller lead',
    'buyer client',
    'seller client',
  ]),
};

export default ContextProvider;
