import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { map } from 'shared/utility';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const SelectCell = (props) => {
  const {
    onChange,
    value,
    disabled,
    options,
    valuesToColors,
  } = props;

  const [ownValue, setValue] = useState(value || '');

  useEffect(() => {
    setValue(value);
  }, [
    value,
  ]);

  const handleChange = useCallback((event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  }, [
    onChange,
  ]);

  return (
    <Select
      value={ownValue}
      onChange={handleChange}
      disabled={disabled}
      disableUnderline
      style={{
        textTransform: 'capitalize',
        color: valuesToColors[ownValue],
      }}
    >
      {
        map(options, (item) => (
          <MenuItem
            value={item.id}
            style={{
              textTransform: 'capitalize',
              color: valuesToColors[item.id],
            }}
          >
            {item.name}
          </MenuItem>
        ))
      }
    </Select>
  );
};

SelectCell.defaultProps = {
  value: null,
  disabled: false,
  options: [],
  valuesToColors: {},
};

const {
  func,
  string,
  number,
  oneOfType,
  bool,
  arrayOf,
  shape,
} = PropTypes;

SelectCell.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, number]),
  disabled: bool,
  options: arrayOf(shape({
    id: number,
    name: oneOfType([string, number]),
  })),
  valuesToColors: shape({}),
};

export default SelectCell;
