import React, {
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Button from 'ui/Button';
import Spin from 'ui/Spin';
import Icon from 'ui/Icon';

import DialpadButton from 'sections/Calls/components/DialpadButton';

import CallsContext from 'sections/Calls/context/CallsContext';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 15px;
  background-color: #fe3b30;
`;

const DialButton = (props) => {
  const {
    recordCall,
    contactId,
    variant,
  } = props;

  const context = useContext(CallsContext);

  const {
    isLoading,
    callStatus,
    dial,
    hangUp,
    dialButtonDisabled,
  } = context;

  const onDial = useCallback((event) => {
    dial(event, recordCall, contactId);
  }, [
    dial,
    recordCall,
    contactId,
  ]);

  if (isLoading) return <Spin />;

  if (variant === 'dialpad') {
    return (
      <DialpadButton
        onClick={(callStatus === 'in call' || callStatus === 'calling') ? hangUp : onDial}
        backgroundColor={(callStatus === 'in call' || callStatus === 'calling') ? 'error' : 'dialButton'}
        iconName="phone-call"
        color="contrast"
        justifyContent="center"
        disabled={dialButtonDisabled}
        round
      />
    );
  }

  return (
    <>
      {callStatus === 'idle' && (
        <Button
          variant="primary"
          onClick={onDial}
          icon="phone-call"
          disabled={dialButtonDisabled}
        >
          Call
        </Button>
      )}
      {callStatus === 'calling' && (
        <Button
          variant="tertiary"
          onClick={hangUp}
        >
          <IconWrapper>
            <Icon
              type="feather"
              name="phone-off"
              color="contrast"
            />
          </IconWrapper>
        </Button>
      )}
      {callStatus === 'in call' && (
        <Button
          variant="tertiary"
          onClick={hangUp}
        >
          <IconWrapper>
            <Icon
              type="feather"
              name="phone-off"
              color="contrast"
            />
          </IconWrapper>
        </Button>
      )}
    </>
  );
};

DialButton.defaultProps = {
  recordCall: false,
  contactId: null,
  variant: 'default',
};

const {
  bool,
  number,
  oneOf,
} = PropTypes;

DialButton.propTypes = {
  recordCall: bool,
  contactId: number,
  variant: oneOf(['default', 'dialpad']),
};

export default DialButton;
