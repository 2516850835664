import connectReducers from 'store/connectReducers';

import {
  SET_CRM_SOURCE_TYPES,
  SET_CRM_SOURCE,
} from 'store/actionTypes/crm';

const initialState = {
  sources: {},
  sourceTypes: null,
};

const reducers = {
  [SET_CRM_SOURCE]: (state, action) => ({
    ...state,
    sources: {
      ...state.sources,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_CRM_SOURCE_TYPES]: (state, action) => ({
    ...state,
    sourceTypes: action.payload,
  }),
};

function crmSources(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default crmSources;
