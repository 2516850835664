import apiConnector, { endpoints } from 'api';

const propertyDetails = async (id) => {
  const endpoint = endpoints.smartzip.propertyDetails;

  console.log(endpoints.smartzip.propertyDetails, id);

  try {
    const result = await apiConnector.smartzip.get(endpoint, {
      property_id: id,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default propertyDetails;
