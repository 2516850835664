import {
  SET_TASK_WORKLOGS,
} from 'store/actionTypes/tasks';

const setTaskWorklogs = (worklogs) => ({
  type: SET_TASK_WORKLOGS,
  payload: worklogs,
});

export default setTaskWorklogs;
