import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ImageCarousel from './ImageCarousel';

const mapStateToProps = (state, ownProps) => {
  const { propertyId, externalImages } = ownProps;

  const images = propertyId
    ? selectors.properties.propertyImages(state, propertyId)
    : selectors.properties.selectedPropertyImages(state);

  return {
    images,
    propertyId,
    externalImages,
  };
};

export default connect(mapStateToProps)(ImageCarousel);
