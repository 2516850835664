import React, {
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import CallsContext from 'sections/Calls/context/CallsContext';
import Typography from 'ui/Typography';
import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import ContactPhoneNumberSelector from 'sections/Contacts/components/ContactPhoneNumberSelector';

import useContactName from 'shared/hooks/contacts/useContactName';
import useContactDefaultPhoneNumber from 'shared/hooks/contacts/useContactDefaultPhoneNumber';

import {
  ContactAvatarWrapper,
} from './styledItems';

const SelectedContactControl = (props) => {
  const {
    contactId,
  } = props;

  const {
    callTo,
    setCallTo,
    callStatus,
  } = useContext(CallsContext);

  const contactName = useContactName(contactId);
  const defaultPhoneNumberValue = useContactDefaultPhoneNumber(contactId);

  useEffect(() => {
    setCallTo(defaultPhoneNumberValue);
  }, [
    defaultPhoneNumberValue,
  ]);

  return (
    <ContactAvatarWrapper>
      <ContactAvatar
        contactId={contactId}
        size="sm"
        round
      />
      <Typography
        color="contrast"
        weight="bold"
        variant="title2"
        tag="p"
        noMargin
        style={{ marginTop: 10, marginBottom: 7 }}
      >
        {contactName}
      </Typography>

      {
        defaultPhoneNumberValue && (
          <ContactPhoneNumberSelector
            contactId={contactId}
            value={callTo}
            onSelect={setCallTo}
            disabled={callStatus === 'in call' || callStatus === 'calling'}
            variant="title3"
            color="contrast"
            style={{ marginBottom: 5 }}
          />
        )
      }
    </ContactAvatarWrapper>
  );
};

const {
  number,
} = PropTypes;

SelectedContactControl.propTypes = {
  contactId: number.isRequired,
};

export default SelectedContactControl;
