import {
  SET_SELECTED_TASK,
} from 'store/actionTypes/tasks';

import {
  getComments,
} from 'store/actions/comments';

const selectTask = (taskId) => (dispatch) => {
  dispatch(getComments('task')(taskId));

  dispatch({
    type: SET_SELECTED_TASK,
    payload: taskId,
  });
};

export default selectTask;
