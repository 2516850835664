import React, {
  useContext,
  useCallback,
} from 'react';

import DropdownTextfield from 'ui/DropdownTextfield';

import context from 'sections/Contacts/modals/AssignContactModal/ContextProvider/context';

const options = [
  {
    label: 'Buyer leads',
    value: 'buyer lead',
  },
  {
    label: 'Seller leads',
    value: 'seller lead',
  },
  {
    label: 'Buyer clients',
    value: 'buyer client',
  },
  {
    label: 'Seller clients',
    value: 'seller client',
  },
];

const StatusSelector = (props) => {
  const {
    highlightedContactsListFilters,
    setHighlightedFilterValue,
  } = useContext(context);

  const handleChange = useCallback((newValue) => {
    setHighlightedFilterValue('contactStatus')(newValue);
  }, [
    setHighlightedFilterValue,
  ]);

  return (
    <DropdownTextfield
      options={options}
      value={highlightedContactsListFilters.contactStatus}
      onChange={handleChange}
    />
  );
};

export default StatusSelector;
