/* eslint-disable react/require-default-props */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { meetingType, userType } from 'oa/types/index';
import socket from 'shared/sockets/OASocket';
import useMeeting from 'shared/hooks/useMeeting';
import usePrevious from 'shared/hooks/usePrevious';
import getImageUrl from 'shared/utils/getImageUrl';
import LargeChatModal from './LargeChatModal';
import SmallChatModal from './SmallChatModal';

const VideoChat = (props) => {
  const {
    activeMeeting,
    isMeetingDetailsModalOpen,
    isMeetingAgendaModalOpen,
    loggedUser,
    openMeetingDetailsModal,
    closeMeetingDetailsModal,
    openMeetingAgendaModal,
    closeMeetingAgendaModal,
  } = props;

  const previousActiveMeeting = usePrevious(activeMeeting);
  const logoSrc = loggedUser ? getImageUrl(loggedUser.logoUrl) : null;

  const {
    editMeetingData,
    joinMeeting,
    leaveMeeting,
    mainStream,
    localStream,
    isVideoEnabled,
    isAudioEnabled,
    isCameraAvailable,
    isMicrophoneAvailable,
    toggleVideo,
    toggleAudio,
    presentScreen,
    isScreenPresenting,
    remoteParticipants,
    meetingParticipants,
    thumbnails,
    pinnedParticipant,
    unpinParticipant,
    pinParticipant,
    cleanUpPreviosMeetingData,
  } = useMeeting({ code: activeMeeting.code });

  const isUserAloneInMeeting = remoteParticipants.length === 0;

  useEffect(() => {
    if (!activeMeeting.code || !localStream || !loggedUser) return;

    if (
      previousActiveMeeting
      && previousActiveMeeting.code
      && previousActiveMeeting.code !== activeMeeting.code
    ) {
      cleanUpPreviosMeetingData();
    }

    const joinedUser = meetingParticipants.find((participant) => (
      participant.socketId === socket.id
    ));

    if (joinedUser) return;

    const userData = {
      username: loggedUser.Name,
      role: loggedUser.Role,
      company: loggedUser.CompanyName,
    };

    joinMeeting(userData);
  }, [
    activeMeeting,
    loggedUser,
    joinMeeting,
    meetingParticipants,
    localStream,
    cleanUpPreviosMeetingData,
    previousActiveMeeting,
  ]);

  return (
    <>
      <LargeChatModal
        mainStream={mainStream}
        onMic={toggleAudio}
        onCamera={toggleVideo}
        isMicActive={isAudioEnabled}
        isCameraActive={isVideoEnabled}
        isCameraAvailable={isCameraAvailable}
        isMicrophoneAvailable={isMicrophoneAvailable}
        meetingTitle="Active Meeting"
        logoSrc={logoSrc}
        leaveMeeting={leaveMeeting}
        meetingParticipants={meetingParticipants}
        remoteParticipants={remoteParticipants}
        thumbnails={thumbnails}
        pinnedParticipant={pinnedParticipant}
        unpinParticipant={unpinParticipant}
        pinParticipant={pinParticipant}
        meeting={activeMeeting}
        changeMeetingNotes={editMeetingData.changeMeetingNotes}
        addDesiredOutcome={editMeetingData.addDesiredOutcome}
        toggleDesiredOutcome={editMeetingData.toggleDesiredOutcome}
        isMeetingAgendaOpen={isMeetingAgendaModalOpen}
        closeMeetingAgenda={closeMeetingAgendaModal}
        openMeetingAgenda={openMeetingAgendaModal}
        isMeetingDetailsOpen={isMeetingDetailsModalOpen}
        openMeetingDetails={openMeetingDetailsModal}
        closeMeetingDetails={closeMeetingDetailsModal}
        presentScreen={presentScreen}
        isScreenPresenting={isScreenPresenting}
        isUserAloneInMeeting={isUserAloneInMeeting}
      />
      <SmallChatModal
        mainStream={mainStream}
        onMic={toggleAudio}
        onCamera={toggleVideo}
        isMicActive={isAudioEnabled}
        isCameraActive={isVideoEnabled}
        leaveMeeting={leaveMeeting}
        isUserAloneInMeeting={isUserAloneInMeeting}
        isCameraAvailable={isCameraAvailable}
        isMicrophoneAvailable={isMicrophoneAvailable}
        remoteParticipants={remoteParticipants}
        thumbnails={thumbnails}
        pinnedParticipant={pinnedParticipant}
        pinParticipant={pinParticipant}
        unpinParticipant={unpinParticipant}
      />
    </>
  );
};

VideoChat.propTypes = {
  activeMeeting: meetingType.isRequired,
  loggedUser: userType,
  isMeetingDetailsModalOpen: PropTypes.bool.isRequired,
  isMeetingAgendaModalOpen: PropTypes.bool.isRequired,
  openMeetingDetailsModal: PropTypes.func.isRequired,
  closeMeetingDetailsModal: PropTypes.func.isRequired,
  openMeetingAgendaModal: PropTypes.func.isRequired,
  closeMeetingAgendaModal: PropTypes.func.isRequired,
};

export default VideoChat;
