import styled from 'styled-components';

export const CropWrapper = styled.div`
  position: relative;
  height: 350px;
  width: 350px;
  margin-bottom: 20px;
`;

export const SpinWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75)
`;

export default {
  CropWrapper,
  SpinWrapper,
};
