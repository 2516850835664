import styled from 'styled-components';

import { lighten } from 'polished';

export const Box = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: ${({
    theme,
    color,
  }) => lighten(0.385, theme?.palette?.[color])};
`;
