import requests from 'api/requests';

import getOverallSummary from './getOverallSummary'

// eslint-disable-next-line consistent-return
const create = (offerData) => async (dispatch) => {
  try {
    const response = await requests.realtor.offers.create(offerData);

    dispatch(getOverallSummary())

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default create;
