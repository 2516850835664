import React, { Component } from 'react';
import {
  Row, Col, Form, Modal, Input,
} from 'antd';

import './AddImageModalForm.scss';


export const AddImageModalForm = Form.create()(
  class extends Component {
    setFormLabel = (children) => (
      <span className="filter-label-text">{children}</span>
    );

    state = {
      data: [],
      searchValue: undefined,
      categories: [],
    };

    render() {
      const { onCancel, onCreate, data } = this.props;

      const { getFieldDecorator } = this.props.form;

      const {
        linkToImgModal: { isVisible },
      } = data;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      };

      return (
        <div>
          <Modal
            title="Add image"
            okText="Add"
            visible={isVisible}
            onCancel={() => {
              onCancel();
            }}
            onOk={() => {
              onCreate();
              this.props.data.link(this.state.url);
            }}
            wrapClassName="create-category-modal"
          >
            <Form {...formItemLayout} className="filter-form" hideRequiredMark layout="vertical">

              <Form.Item
                colon={false}
                label={this.setFormLabel('URL')}
              >
                <Row gutter={8}>
                  <Col span={18}>
                    {getFieldDecorator('url', {
                      rules: [{ required: false, message: 'Please enter image source url.' }],
                    })(
                      <Input
                        onChange={(e) => { this.setState({ url: e.target.value }); }}
                        placeholder="Enter url here"
                      />,
                    )}
                  </Col>
                </Row>
              </Form.Item>

            </Form>
          </Modal>
        </div>
      );
    }
  },
);
