import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';

const WarningModal = (props) => {
  const {
    isVisible,
    closeModal,
    description,
  } = props;

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      title="You have selected too many contacts"
      footer={[
        <Button
          variant="primary"
          onClick={closeModal}
        >
          Ok
        </Button>,
      ]}
      noCloseIcon
    >
      <Flex
        fullWidth
        spacing={2}
      >
        <FlexItem xs={6}>
          <Icon
            name="alert-triangle"
            color="error"
            size="l"
          />
        </FlexItem>
        <FlexItem>
          <Typography>
            {description}
          </Typography>
        </FlexItem>
      </Flex>
    </Modal>
  );
};

WarningModal.defaultProps = {
  description: '',
};

const {
  string,
  func,
  bool,
} = PropTypes;

WarningModal.propTypes = {
  isVisible: bool.isRequired,
  closeModal: func.isRequired,
  description: string,
};

export default WarningModal;
