import React, {
  useCallback,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const numsArr = ['+', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

const InputComponent = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    label,
    onNotNumberInput,
  } = props;

  const handleChange = useCallback((inputValue, event) => {
    if (onNotNumberInput && inputValue && inputValue.length === 1 && !numsArr.includes(inputValue)) {
      onNotNumberInput(inputValue);
      return;
    }
    onChange(event);
  }, [
    onChange,
  ]);

  return (
    <Textfield
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      inputProps={{
        ref,
      }}
      label={label}
      value={value}
      onChange={handleChange}
    />
  );
});

InputComponent.defaultProps = {
  value: '',
  label: '',
  onNotNumberInput: null,
};

const {
  string,
  func,
} = PropTypes;

InputComponent.propTypes = {
  value: string,
  onChange: func.isRequired,
  label: string,
  onNotNumberInput: func,
};

export default InputComponent;
