import apiConnector from 'api';

/**
 * Creates an entry about the user's Bug or Feedback attachment to the global database
 * @param {object} attachmentInformation New logo information
 * @param {string} contentType Type of form used
 * @param {string} DatabaseName Name of the client database
 u  */
const createClientExperienceAttachmentsInGlobal = async (attachmentInformation, contentType, DatabaseName) => {
  const endpoint = `/attachments/${contentType}/create`;

  await apiConnector.global.post(endpoint, {
    attachment: attachmentInformation,
    DatabaseName,
    contentType,
  });
};

export default createClientExperienceAttachmentsInGlobal;
