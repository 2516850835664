/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

const Options = (props) => {
  const {
    tokenId,
    onRemove,
  } = props;

  const onDelete = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();

    await requests.integrations.remove(tokenId);

    onRemove && onRemove();

    closeMenu();
  }, [
    tokenId,
    onRemove,
  ]);

  return (
    <OptionsMenu {...props}>
      {
        ({ closeMenu }) => (
          <>
            <MenuItem onClick={onDelete(closeMenu)}>
              Remove
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

Options.defaultProps = {
  onRemove: null,
};

const {
  func,
  number,
} = PropTypes;

Options.propTypes = {
  tokenId: number.isRequired,
  onRemove: func,
};

export default Options;
