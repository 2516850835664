import requests from 'api/requests';

import setWarehouseKanban from './setWarehouseKanban';

const get = (offset = 0, limit = 50) => (lane) => async (dispatch) => {
  try {
    const response = await requests.warehouse.getWarehouseItems({
      offset,
      limit,
      lane,
    });

    dispatch(setWarehouseKanban(lane, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default get;
