import requests from 'api/requests';

import setProcess from './setProcess';

const getProcessById = (id) => async (dispatch) => {
  try {
    const response = await requests.serviceProcesses.getById(id);

    dispatch(setProcess(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getProcessById;
