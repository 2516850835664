import connectReducers from 'store/connectReducers';

const noOp = () => {};

class FormReducer {
  constructor(
    SET_FORM_STATE_ACTION,
    RESET_FORM_STATE_ACTION,
    SET_FORM_ATTACHMENTS_ACTION,
    defaultValues = {},
  ) {
    this.initialState = {
      // callbacks
      onBeforeSubmit: noOp,
      onSubmit: null,
      onAfterSubmit: noOp,

      // defaultValues
      attachments: [],
      ...defaultValues,
    };

    this.reducers = {
      [SET_FORM_STATE_ACTION]: (state, action) => ({
        ...this.initialState,
        ...action.payload,
      }),

      [RESET_FORM_STATE_ACTION]: (state) => this.initialState,

      [SET_FORM_ATTACHMENTS_ACTION]: (state, action) => ({
        ...state,
        attachments: action.payload.filesList,
      }),
    };

    this.getReducers = this.getReducers.bind(this);
  }

  getReducers(state, action) {
    const currentState = state || this.initialState;

    return connectReducers(this.reducers, currentState, action);
  }
}

export default FormReducer;
