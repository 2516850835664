import styled from 'styled-components';

import Grid from 'ui/Grid';

export const BorderedGrid = styled(Grid)`
  :not(:last-child) {
    border-right: solid 1px ${(props) => props.theme?.palette?.delimiter || '#f4faff'};
  }
  :not(:first-child) {
    padding-left: 15px
  }
`;

export default {
  BorderedGrid,
};
