import styled from 'styled-components';

export const UploadWrapper = styled.div`
  .ant-upload-drag {
    height: 114px;
    border-radius: 10px;
    border: 0;
    background-color: ${(props) => props?.theme?.palette?.['background-flat']};
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
    border: 0;
  }
`;

export default {
  UploadWrapper,
};
