export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const SET_PROPERTY_ATTACHMENTS = 'SET_PROPERTY_ATTACHMENTS';
export const SET_SELECTED_PROPERTY_ROWS = 'SET_SELECTED_PROPERTY_ROWS';

export const SET_PROPERTIES_FOR_FILTER = 'SET_PROPERTIES_FOR_FILTER';
export const SET_PROPERTIES_FOR_FILTER_COUNT = 'SET_PROPERTIES_FOR_FILTER_COUNT';
export const SET_PROPERTIES_FILTER_ITEM = 'SET_PROPERTIES_FILTER_ITEM';
export const RESET_PROPERTIES_FILTER_ITEM = 'RESET_PROPERTIES_FILTER_ITEM';
export const CLEAR_PROPERTIES_FILTER = 'CLEAR_PROPERTIES_FILTER';

export const SET_REALTOR_OVERALL_PROPERTIES_SUMMARY = 'SET_REALTOR_OVERALL_PROPERTIES_SUMMARY';

export default {
  SET_PROPERTY,
  SET_PROPERTIES,
  SET_SELECTED_PROPERTY,
  SET_SELECTED_PROPERTY_ROWS,

  SET_PROPERTIES_FOR_FILTER,
  SET_PROPERTIES_FOR_FILTER_COUNT,
  SET_PROPERTIES_FILTER_ITEM,
  RESET_PROPERTIES_FILTER_ITEM,
  CLEAR_PROPERTIES_FILTER,

  SET_REALTOR_OVERALL_PROPERTIES_SUMMARY,
};
