import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedOfferActions,
} from 'store/actions/realtorOffers';

import { REALTOR_OFFER_TYPES } from 'constants/realtor.constants';

export default createSelector(
  (state) => state.realtorOffers.offers,
  (_, id) => id,
  (collection, id) => {
    const originalOffer = collection[id];

    if (!originalOffer) {
      if (id) {
        optimizedOfferActions.getOfferWithCountersById(id);
      }

      return {
        isLoading: true,
        events: [],
      };
    }

    const offerCounters = originalOffer.counters || [];

    const timelineCounters = offerCounters.reduce((accum, counterId, index) => {
      const counter = collection[counterId];

      if (!counter) {
        if (counterId) {
          optimizedOfferActions.getOfferWithCountersById(counterId);
        }

        return accum;
      }

      accum.push({
        id: counter.id,
        createdAt: counter.created_at,
        price: counter.amount,
        anchorSelector: `counter-${counter.id}`,
        role: REALTOR_OFFER_TYPES[counter.type],
        counterNumber: index + 1,
      });

      if (counter.declined_at) {
        accum.push({
          id: `${counter.id}-declined`,
          createdAt: counter.declined_at,
          anchorSelector: `counter-${counter.id}-declined`,
          role: REALTOR_OFFER_TYPES[counter.type],
          counterNumber: index + 1,
          type: 'declined',
        });
      }

      if (counter.accepted_at) {
        accum.push({
          id: `${counter.id}-accepted`,
          createdAt: counter.accepted_at,
          anchorSelector: `counter-${counter.id}-accepted`,
          role: REALTOR_OFFER_TYPES[counter.type],
          counterNumber: index + 1,
          type: 'accepted',
        });
      }

      return accum;
    }, []);

    const isLoading = timelineCounters.length < offerCounters.length;

    if (originalOffer.declined_at) {
      timelineCounters.unshift({
        id: `${originalOffer.id}-declined`,
        createdAt: originalOffer.declined_at,
        anchorSelector: `counter-${originalOffer.id}-declined`,
        role: REALTOR_OFFER_TYPES[originalOffer.type],
        type: 'declined',
      });
    }

    timelineCounters.unshift({
      id: originalOffer.id,
      createdAt: originalOffer.created_at,
      price: originalOffer.amount,
      anchorSelector: `counter-${originalOffer.id}`,
      role: REALTOR_OFFER_TYPES[originalOffer.type],
    });

    if (originalOffer.cancelled_at) {
      timelineCounters.push({
        id: 'cancelled',
        type: 'cancelled',
        createdAt: originalOffer.cancelled_at,
      });
    }

    if (originalOffer.accepted_at) {
      timelineCounters.push({
        id: 'accepted',
        type: 'accepted',
        createdAt: originalOffer.accepted_at,
      });
    }

    return {
      isLoading,
      events: timelineCounters,
    };
  },
);
