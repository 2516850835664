import apiConnector, { endpoints } from 'api';

const getSummary = async (query) => {
  const endpoint = endpoints.client.followUpReminders.summary;

  try {
    const result = await apiConnector.client.get(endpoint, query);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getSummary;
