/* eslint-disable camelcase */
import React from 'react';

import Grid from 'ui/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from 'ui/Typography';

import poweredByGoogle from 'assets/images/poweredByGoogle.png';

const mapMarkerIconStyles = {
  fontSize: 16,
  color: '#172b4d',
};

const newMapMarkerIconStyles = {
  ...mapMarkerIconStyles,
  color: '#367BED',
};

const Option = (option) => {
  const {
    formatted_address = '',
    main_text = '',
    secondary_text = '',
    newItem = false,
    lastRow = false,
  } = option;

  if (lastRow) {
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-end"
      >
        <img
          alt="powered by Google"
          height={12}
          src={poweredByGoogle}
        />
      </Grid>
    );
  }

  if (newItem) {
 return (
   <Grid
     container
     alignItems="center"
     spacing={2}
   >
     <Grid item>
       <FontAwesomeIcon
         icon="map-marker-alt"
         style={newMapMarkerIconStyles}
       />
     </Grid>
     <Grid item xs>
       <Typography variant="label">
         {main_text || formatted_address}
       </Typography>

       <Typography
         variant="subtitle2"
         color="focus"
         noMargin
       >
         + click to add
       </Typography>
     </Grid>
   </Grid>
  );
}

  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <FontAwesomeIcon
          icon="map-marker-alt"
          style={mapMarkerIconStyles}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="label">
          {main_text || formatted_address}
        </Typography>

        {
          secondary_text && (
            <Typography
              variant="subtitle2"
              color="ghost"
              noMargin
            >
              {secondary_text}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
};

export default Option;
