import connectReducers from 'store/connectReducers';

import {
  SET_PROPERTY_VIEWING,
  SET_PROPERTY_VIEWINGS,
  SET_SELECTED_PROPERTY_VIEWING,
  SET_VIEWING_NOTES_MODAL_OPEN,
  SET_VIEWING_NOTES_MODAL_CLOSED,
  SET_REALTOR_VIEWINGS_FOR_FILTER,
  SET_REALTOR_VIEWINGS_FOR_FILTER_COUNT,
  SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER,
} from 'store/actionTypes/propertyViewings';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  viewings: {},
  viewingsByFilter: {},
  viewingsByFilterCount: {},
  selectedViewing: null,
  isViewingNotesModalVisible: false,
};

const reducers = {
  [SET_PROPERTY_VIEWING]: (state, action) => ({
    ...state,
    viewings: {
      ...state.viewings,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_PROPERTY_VIEWINGS]: (state, action) => ({
    ...state,
    viewings: {
      ...state.viewings,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_VIEWINGS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      viewings,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(viewings),
    ]);

    const updatedState = {
      ...state,
      viewingsByFilter: {
        ...state.viewingsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_INFINITE_SCROLL_SHOWING_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      showings,
      resetState,
    } = action.payload;

    const current = !resetState && state.viewingsByFilter[filterId] ? state.viewingsByFilter[filterId] : [];

    const updatedByIds = new Set([
      ...current,
      ...getIds(showings),
    ]);

    const updatedState = {
      ...state,
      viewingsByFilter: {
        ...state.viewingsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_VIEWINGS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    viewingsByFilterCount: {
      ...state.viewingsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_SELECTED_PROPERTY_VIEWING]: (state, action) => ({
    ...state,
    selectedViewing: action.payload,
  }),

  [SET_VIEWING_NOTES_MODAL_OPEN]: (state, action) => ({
    ...state,
    isViewingNotesModalVisible: true,
    selectedViewing: action.payload.viewingId,
  }),

  [SET_VIEWING_NOTES_MODAL_CLOSED]: (state) => ({
    ...state,
    isViewingNotesModalVisible: false,
    selectedViewing: null,
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
