import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getProperties from './getProperties';
import getPropertyById from './getPropertyById';
import updateProperty from './updateProperty';
import updateSelectedProperty from './updateSelectedProperty';
import deleteProperty from './deleteProperty';
import addPropertyRelations from './addPropertyRelations';
import removePropertyRelations from './removePropertyRelations';
import overwritePropertyRelations from './overwritePropertyRelations';
import setSelectedProperty from './setSelectedProperty';
import createProperty from './createProperty';
import createPropertyFromNewPropertyForm from './createPropertyFromNewPropertyForm'; // eslint-disable-line import/no-cycle
import getPropertyAttachments from './getPropertyAttachments';
import getPropertiesForFilter from './getPropertiesForFilter';
import getPropertiesForStoreFilter from './getPropertiesForStoreFilter'; // eslint-disable-line import/no-cycle
import setSelectedPropertyRows from './setSelectedPropertyRows';
import searchProperties from './searchProperties';
import getOverallSummary from './getOverallSummary';
import setProperty from './setProperty';
import setPropertyAttachments from './setPropertyAttachments';

export { getProperties };
export { getPropertyById };
export { updateProperty };
export { deleteProperty };
export { addPropertyRelations };
export { removePropertyRelations };
export { overwritePropertyRelations };
export { setSelectedProperty };
export { updateSelectedProperty };
export { createProperty };
export { createPropertyFromNewPropertyForm };
export { getPropertyAttachments };
export { setSelectedPropertyRows };
export { searchProperties };
export { getPropertiesForStoreFilter };
export { getOverallSummary };

export {
  setProperty,
  setPropertyAttachments,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedPropertyActions = {
  getOverallSummary: memoizeDebounce(
    () => store.dispatch(getOverallSummary()),
    debounceDelay,
    debounceOptions,
  ),
  getPropertyById: memoizeDebounce(
    (propertyId) => store.dispatch(getPropertyById(propertyId)),
    debounceDelay,
    debounceOptions,
  ),
  getPropertyAttachments: memoizeDebounce(
    (propertyId) => store.dispatch(getPropertyAttachments(propertyId)),
    debounceDelay,
    debounceOptions,
  ),
  getPropertiesForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getPropertiesForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getPropertiesForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getPropertiesForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
};
