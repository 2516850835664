import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import NewOfferForm from 'sections/Offers/forms/NewOfferForm';
import PropertyOverview from 'sections/Properties/modules/PropertyOverview';
import PropertyContact from 'sections/Properties/modules/PropertyContact';

const NewOfferModal = (props) => {
  const {
    representing,

    propertyId,

    clientId,
    prospectId,

    buyer,
    seller,
    buyerAgent,
    sellerAgent,

    isVisible,
    closeModal,

    onSuccess,
    updateOnly,
    offerData,
    totalCounters,
    offerId,
    hideOptions,
    attachments,
  } = props;

  const handleSuccess = useCallback((newOfferId) => {
    closeModal();

    if (onSuccess) {
      onSuccess(newOfferId);
    }
  }, [
    closeModal,
    onSuccess,
  ]);

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      width="auto"
      style={{ maxWidth: '1500px' }}
      footer={null}
      title={null}
      noCloseIcon
    >
      <Flex
        fullWidth
        spacing={2}
        flexWrap="wrap"
        alignItems="stretch"
      >
        <FlexItem xs={16}>
          <Elevation
            rounded="s"
            padding="m"
            fullHeight
          >
            <PropertyOverview propertyId={propertyId} />
          </Elevation>
        </FlexItem>

        <FlexItem xs={8}>
          <PropertyContact propertyId={propertyId} />
        </FlexItem>

        <FlexItem fullWidth>
          <Elevation
            rounded="s"
            padding="m"
          >
            <NewOfferForm
              representing={representing}
              propertyId={propertyId}
              clientId={clientId}
              prospectId={prospectId}
              onSuccess={handleSuccess}
              buyer={buyer}
              buyerAgent={buyerAgent}
              seller={seller}
              sellerAgent={sellerAgent}
              offerData={offerData}
              updateOnly={updateOnly}
              currentCounter={totalCounters || null}
              totalCounters={totalCounters}
              offerId={offerId}
              closeModal={closeModal}
              hideOptions={hideOptions}
              attachments={attachments}
            />
          </Elevation>
        </FlexItem>

      </Flex>
    </Modal>
  );
};

NewOfferModal.defaultProps = {
  isVisible: false,

  onSuccess: null,

  clientId: null,
  prospectId: null,

  buyer: null,
  buyerAgent: null,
  seller: null,
  sellerAgent: null,
  updateOnly: false,
  offerData: null,
  totalCounters: null,
  offerId: null,
  hideOptions: false,
  attachments: null,
};

const {
  bool,
  func,
  number,
  oneOf,
  shape,
} = PropTypes;

NewOfferModal.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,

  propertyId: number.isRequired,

  clientId: number,
  prospectId: number,

  buyer: number,
  buyerAgent: number,
  seller: number,
  sellerAgent: number,

  representing: oneOf(['buyer', 'seller']).isRequired,

  onSuccess: func,
  offerData: shape({}),
  totalCounters: number,
  updateOnly: bool,
  offerId: number,
  hideOptions: bool,
  attachments: shape({}),
};

export default NewOfferModal;
