import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'antd';
import { common } from 'constants';
import ButtonShadowAround from 'shared/components/ButtonShadowAround';
import Img from 'react-image';
import getGlobalAttachmentUrlWithToken from 'shared/utils/getGlobalAttachmentUrlWithToken';

import './LoginWorkflow.scss';

const Accounts = (props) => {
  const {
    onSelect,
    token,
    workspaces,
  } = props;

  return (
    <section className="accounts">
      <div className="accounts__cards">
        {workspaces.map((workspace) => {
          const name = workspace.companyName || 'Your Company';
          const { logoUrl } = workspace;

          const logo = getGlobalAttachmentUrlWithToken(logoUrl, token);

          return (
            <ButtonShadowAround
              key={name}
              onClick={onSelect(workspace)}
              className="accounts__card"
            >
              <Card className="accounts__card-component">
                <div className="accounts__logo">
                  <Img
                    alt={`Аccount - ${name}`}
                    src={[logo, common.DEFAULT_LOGO]}
                  />
                </div>
                <h2 className="accounts__name">
                  {name}
                </h2>
              </Card>
            </ButtonShadowAround>
          );
        })}
      </div>
    </section>
  );
};

Accounts.defaultProps = {
  workspaces: [],
};

const {
  arrayOf,
  func,
  shape,
  string,
} = PropTypes;

Accounts.propTypes = {
  onSelect: func.isRequired,
  token: string.isRequired,
  workspaces: arrayOf(shape({
    companyName: string,
    logoUrl: string,
  })),
};

export default Accounts;
