import React, {
  useMemo,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
  Button,
} from './styledItems';

const buttons = [
  {
    title: 'Contacts',
    key: 'contacts',
  },
  {
    title: 'Properties',
    key: 'properties',
  },
  {
    title: 'Leads',
    key: 'leads',
  },
  {
    title: 'Clients',
    key: 'clients',
  },
  {
    title: 'Prospects',
    key: 'prospects',
  },
  {
    title: 'Transactions',
    key: 'transactions',
  },
];

const SearchCategoriesSelector = (props) => {
  const {
    activeCategories,
    categories,
    onChange,
  } = props;

  const buttonsList = useMemo(() => {
    if (!categories) return buttons;

    return buttons.filter((item) => categories.includes(item.key));
  }, [
    categories,
  ]);

  const handleChange = useCallback((key) => (event) => {
    event.preventDefault();
    onChange(key);
  }, [
    onChange,
  ]);

  return (
    <Root>
      {
        buttonsList.map((button) => (
          <Button
            active={activeCategories.includes(button.key)}
            onClick={handleChange(button.key)}
            key={button.key}
          >
            <Typography
              variant="caption2"
              color={activeCategories.includes(button.key) ? 'main-text' : 'descriptor-text'}
              weight={500}
              noMargin
            >
              {button.title}
            </Typography>
          </Button>
        ))
      }
    </Root>
  );
};

SearchCategoriesSelector.defaultProps = {
  activeCategories: [],
  onChange: () => () => {},
  categories: null,
};

const {
  arrayOf,
  string,
  func,
} = PropTypes;

SearchCategoriesSelector.propTypes = {
  activeCategories: arrayOf(string),
  onChange: func,
  categories: arrayOf(string),
};

export default SearchCategoriesSelector;
