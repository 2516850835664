import { connect } from 'react-redux';

const withNewContactFormDefaultValue = (Component) => (fieldName) => {
  const mapStateToProps = (state) => {
    const {
      realtorNewContactForm,
    } = state;

    return {
      value: realtorNewContactForm[fieldName],
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withNewContactFormDefaultValue;
