import { connect } from 'react-redux';

import NewContactArea from 'shared/inputs/NewContactAreaSimple';

import selectors from 'store/selectors';

import {
  setNewLeadFormField,
  setNewLeadNestedFormField,
} from 'store/actions/newRealtorLeadForm';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm,
  } = state;

  const {
    isEmailValid,
    doesEmailExist,
    doesPhoneExist,
    isFirstNameValid,
  } = realtorNewLeadForm.fieldsValidators;

  let emailError = null;

  if (doesEmailExist) {
    emailError = 'Email already exists';
  }
  if (!isEmailValid) {
    emailError = 'Not a valid email';
  }

  let personTitle;
  let personFirstName;
  let personLastName;
  let personEmail;
  let personPhone;

  const contactId = realtorNewLeadForm.selectedContact?.id;

  if (contactId) {
    const contact = selectors.realtorContacts.byId(state, contactId);

    personTitle = contact.title;
    personFirstName = contact.first_name;
    personLastName = contact.last_name;
    personEmail = contact.email_primary;
    personPhone = contact.phone_primary;
  }

  return {
    values: realtorNewLeadForm,
    emailError,
    phoneError: doesPhoneExist ? 'Phone number already exists' : null,
    firstNameError: !isFirstNameValid ? 'First name is required' : null,
    restrictContactChange: realtorNewLeadForm.restrictContactChange,
    contactData: {
      personTitle,
      personFirstName,
      personLastName,
      personEmail,
      personPhone,
    },
  };
};

const fieldDict = {
  first_name: 'personFirstName',
  last_name: 'personLastName',
  person_title: 'personTitle',
  email_primary: 'mainEmail',
  phone_primary: 'mainPhoneNumber',
  first_contact_date: 'firstContactDate',
  is_past_client: 'isPastClient',
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (fieldName) => (value) => {
    dispatch(setNewLeadFormField(fieldDict[fieldName] || fieldName)(value));
  },

  onInvalidField: (fieldName) => (property, value) => {
    dispatch(setNewLeadNestedFormField('fieldsValidators')(property)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContactArea);
