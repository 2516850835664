import { createSelector } from 'reselect';

import selectors from 'store/selectors';

import { // eslint-disable-line import/no-cycle
  optimizedClientActions,
} from 'store/actions/realtorClients';

const lanes = {
  new: {
    name: 'New Clients/Prospects',
    getSubheading: (summary) => `${summary.new || 0} ${+summary.new !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: (list = [], summary) => +summary.new > list.length,
  },
  viewings: {
    name: 'Showings',
    getSubheading: (summary) => `${summary.with_viewings || 0} ${+summary.with_viewings !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: (list = [], summary) => +summary.with_viewings > list.length,
  },
  offers: {
    name: 'Offers',
    getSubheading: (summary) => `${summary.with_offers || 0} ${+summary.with_offers !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: () => false,
  },
  deals: {
    name: 'Deals Agreed',
    getSubheading: (summary) => `${summary.with_deals || 0} ${+summary.with_deals !== 1 ? 'Clients' : 'Client'}`,
    getListHasMore: () => false,
  },
};

const laneOrder = [
  'new',
  'viewings',
  'offers',
  'deals',
];

export default createSelector(
  (state) => state.realtorClients.clients,
  (state) => state.realtorClients.realtorClientsByFilter,
  (state) => selectors.realtorClients.overallSummary(state),
  (clients, realtorClientsByFilter, summary) => laneOrder.map((id) => {
    const {
      name,
      getSubheading,
      getListHasMore,
    } = lanes[id];

    const filter = selectors.realtorClients.clientsFilter(null, {
      stage: id,
    });

    const list = selectors.realtorClients.clientsList(
      {
        realtorClients: {
          clients,
          realtorClientsByFilter,
        },
      },
      filter.filterId,
    );

    const laneData = {
      name,
      id,
      list,
      subheading: getSubheading
        ? getSubheading(summary)
        : null,
      listHasMore: getListHasMore
        ? getListHasMore(list, summary)
        : false,
      loadMore: () => {
        optimizedClientActions.getClientsForFilter(
          filter.filterId,
          filter.filter,
          list.length,
        );
      },
    };

    return laneData;
  }),
);
