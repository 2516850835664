import React, {
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import noOp from 'shared/utils/noOp'

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import PropertyPreviewTile from 'sections/MLS/modules/PropertyPreviewTile';

const tileStyle = {
  maxWidth: '23%', // TODO improve ui/Flex, ui/FlexItem to support intuitive grid
  flex: 1,
};

const PropertiesPreviews = (props) => {
  const {
    myListings,
    featuredProperties,
    onItemClick,
    selected,
    getProperties,
  } = props;

  useEffect(() => {
    getProperties(1, 12);
  }, []);

  return (
    <Flex
      fullWidth
      spacing={2}
      flexDirection="column"
    >
      {
        myListings.length > 0 && (
          <div style={{ width: '100%' }}>
            <Typography
              variant="title3"
              weight="bold"
            >
              My Listings
            </Typography>
            <Flex
              spacing={2}
              flexWrap="wrap"
              fullWidth
            >
              {
                myListings.map(({ id }) => (
                  <div
                    key={id}
                    style={tileStyle}
                  >
                    <PropertyPreviewTile
                      propertyId={id}
                      onClick={onItemClick(id)}
                      isSelected={id === selected}
                    />
                  </div>
                ))
              }
            </Flex>
          </div>
        )
      }
      {
        featuredProperties.length > 0 && (
          <div style={{ width: '100%' }}>
            <Typography
              variant="title3"
              weight="bold"
            >
              Featured Properties
            </Typography>
            <Flex
              spacing={2}
              flexWrap="wrap"
              fullWidth
            >
              {
                featuredProperties.map(({ id }) => (
                  <div
                    key={id}
                    style={tileStyle}
                  >
                    <PropertyPreviewTile
                      propertyId={id}
                      onClick={onItemClick(id)}
                      isSelected={id === selected}
                    />
                  </div>
                ))
              }
            </Flex>
          </div>
        )
      }
    </Flex>
  );
};

PropertiesPreviews.defaultProps = {
  myListings: [],
  featuredProperties: [],
  onItemClick: noOp,
  selected: null
};

const {
  arrayOf,
  func,
  number,
  shape,
} = PropTypes;

PropertiesPreviews.propTypes = {
  myListings: arrayOf(shape({
    id: number.isRequired,
  })),
  featuredProperties: arrayOf(shape({
    id: number.isRequired,
  })),
  onItemClick: func,
  selected: number,
  getProperties: func.isRequired,
};

export default PropertiesPreviews;
