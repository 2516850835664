import { connect } from 'react-redux';

import { isEmpty, last } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateOffer,
  getOfferById,
} from 'store/actions/realtorOffers';

import {
  deleteTransaction,
} from 'store/actions/realtorTransactions';

import Offer from './Offer';

const mapStateToProps = (state, ownProps) => {
  const { offerId } = ownProps;

  const offer = selectors.realtorOffers.byIdWithCounters(state, offerId);

  let lastAmount;
  if (!isEmpty(offer.counters)) {
    const lastCounterId = last(offer.counters);
    lastAmount = selectors.realtorOffers.byId(state, lastCounterId)?.amount;
  }

  const {
    events: timeline,
    isLoading: isTimelineLoading,
  } = selectors.realtorOffers.offerTimeline(state, offerId);

  const isRootOfferDeclined = !!offer.declined_at;
  let hasDeclinedCounter = false;

  const isRootOfferAccepted = !!offer.accepted_at;
  let hasAcceptedCounter = false;

  if (!isEmpty(offer.counters)) {
    hasDeclinedCounter = offer.counters.some((counterId) => {
      const counter = selectors.realtorOffers.byId(state, counterId);
      return !!counter.declined_at;
    });
    hasAcceptedCounter = offer.counters.some((counterId) => {
      const counter = selectors.realtorOffers.byId(state, counterId);
      return !!counter.accepted_at;
    });
  }

  let acceptedAmount;
  let acceptedCounterId;

  if (isRootOfferAccepted) {
    acceptedAmount = offer.amount;
  } else if (hasAcceptedCounter) {
    acceptedCounterId = offer.counters.find((counterId) => {
      const counter = selectors.realtorOffers.byId(state, counterId);
      return !!counter.accepted_at;
    });

    const acceptedCounter = selectors.realtorOffers.byId(state, acceptedCounterId);
    acceptedAmount = acceptedCounter?.amount;
  }

  return {
    amount: lastAmount || offer.amount,
    buyerId: offer.buyer_contact_id,
    buyerAgentId: offer.buyer_agent_contact_id,
    propertyId: offer.realtor_property_id,
    timelineEvents: timeline,
    offerId,
    delta: lastAmount && offer.amount ? lastAmount - offer.amount : null,
    isCancelled: !!offer.cancelled_at,
    isDeclined: isRootOfferDeclined || hasDeclinedCounter,
    isAccepted: isRootOfferAccepted || hasAcceptedCounter,
    acceptedAmount,
    acceptedCounterId,
    dealId: offer.deal_id,
    isTimelineLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancelOffer: () => {
    dispatch(updateOffer(ownProps.offerId)({ cancelled_at: new Date() }));
  },
  onUndoAcceptance: async (id, counterId) => {
    await dispatch(deleteTransaction(id));

    dispatch(getOfferById(ownProps.offerId));
    if (counterId) {
      dispatch(getOfferById(counterId));
    }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onUndoAcceptance: () => {
    dispatchProps.onUndoAcceptance(stateProps.dealId, stateProps.acceptedCounterId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Offer);
