import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { createTransaction } from 'store/actions/realtorTransactions';

import { getOfferById } from 'store/actions/realtorOffers';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import {
  uploadAttachment,
} from 'store/actions/attachmentsV2';

import {
  closeModal,
} from 'store/actions/modals';

import ConfirmOfferAcceptForm from './ConfirmOfferAcceptForm';

const mapStateToProps = (state) => {
  const {
    modals: {
      acceptOfferModal,
    },
  } = state;

  const offer = selectors.realtorOffers.byId(state, acceptOfferModal?.contentOptions?.offerId);

  const {
    expiry_date,
    realtor_client_id,
    buyer_contact_id,
    realtor_prospect_id,
    realtor_property_id,
    buyer_agent_contact_id,
  } = offer;

  return {
    offerId: offer?.id,
    contactId: realtor_client_id || realtor_prospect_id,
    contactType: realtor_client_id ? 'client' : 'prospect',
    propertyId: realtor_property_id,
    date: expiry_date,
    buyer: buyer_contact_id,
    buyerAgent: buyer_agent_contact_id,
    rootOfferId: acceptOfferModal?.contentOptions?.rootOfferId,
    onAfterClose: acceptOfferModal?.onAfterClose,
    offer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadAttachments: async (dealId, attachments) => {
    for (let index = 0; index < attachments.length; index++) {
      const {
        name,
        type,
        url,
        isExternalLink,
        linkUrl,
      } = attachments[index];

      if (isExternalLink) {
        const data = {
          objectId: dealId,
          objectType: 41,
          attachmentType: 13,
          linkUrl,
        };

        await dispatch(uploadAttachment(data, false));
      } else {
        const file = await dataUrlToFile(url, name, type);

        const data = {
          file,
          objectId: dealId,
          objectType: 41,
          attachmentType: 13,
        };

        await dispatch(uploadAttachment(data, false));

        URL.revokeObjectURL(url);
      }
    }

    dispatch(closeModal('acceptOfferModal')());
  },

  onSubmit: async (data) => {
    const dealId = await dispatch(
      createTransaction({
        // eslint-disable-line no-unused-vars
        root_offer_id: data.rootOfferId,
        final_offer_id: data.offer.id,
        realtor_client_id: data.offer.realtor_client_id,
        realtor_property_id: data.offer.realtor_property_id,
        realtor_prospect_id: data.offer.realtor_prospect_id,
        price: data.offer.amount,
        seller_contact_id: data.offer.seller_contact_id,
        seller_agent_contact_id: data.offer.seller_agent_contact_id,
        buyer_contact_id: data.offer.buyer_contact_id,
        buyer_agent_contact_id: data.offer.buyer_agent_contact_id,

        seller_disclosure_expires_at: data.sellerDisclosure,
        inspections_expires_at: data.inspection,
        appraisal_expires_at: data.appraisal,
        loan_expires_at: data.loan,
        final_walkthrough_expires_at: data.walkthrough,
        move_out_date_expires_at: data.moveInDate,
        close_escrow_expires_at: data.closeEscrow,
      }),
    );

    dispatch(getOfferById(data.offer.id));
    dispatch(getOfferById(data.rootOfferId));
    return dealId;
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: async (data) => {
    const dealId = await dispatchProps.onSubmit({
      ...data,
      offer: stateProps.offer,
      rootOfferId: stateProps.rootOfferId,
    });

    await dispatchProps.uploadAttachments(dealId, data.attachments);

    if (stateProps.onAfterClose) {
      stateProps.onAfterClose();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConfirmOfferAcceptForm);
