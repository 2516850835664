import React from 'react';
import PropTypes from 'prop-types';

const CounterRound = ({ count }) => {
  if (!count) return null;

  return (
    <span className="counter-round">
      {count}
    </span>
  );
};

const {
  number,
} = PropTypes;

CounterRound.propTypes = {
  count: number.isRequired,
};

export default CounterRound;
