import React, { useContext, useState, useCallback, useEffect } from 'react';

import ErrorBoundary from 'ui/ErrorBoundary';

import Chip from 'ui/Chip';
import Flex from 'ui/Flex';

import context from 'sections/Integrations/context';

import IntegrationItem from 'sections/Integrations/components/IntegrationItem';

import useICloudAuth from 'sections/Integrations/hooks/useICloudAuth';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';
import useContactsImport from 'sections/Integrations/hooks/useContactsImport';
import useCalendarImport from 'sections/Integrations/hooks/useCalendarImport';
import integrations from 'api/requests/integrations/index';

const ICloud = props => {
  const [contactsTokenId, setContactsTokenId] = useState(null);
  const [calendarTokenId, setCalendarTokenId] = useState(null);

  const { scopes, getIntegrations } = useContext(context);

  const { openAuthModal, modal } = useICloudAuth('contacts', getIntegrations);
  const {
    openAuthModal: calendarOpenAuthModal,
    modal: calendarModal
  } = useICloudAuth('calendar', getIntegrations);

  const { integrationsByScope } = useIntegrations();

  const {
    openContactsImportModal,
    modal: ContactsImportModal
  } = useContactsImport({
    name: 'iCloud',
    icon: 'integrationsIcloud',
    tokenId: contactsTokenId
  });

  const {
    openCalendarImportModal,
    modal: CalendarImportModal
  } = useCalendarImport({
    name: 'iCloud',
    icon: 'integrationsIcloud',
    tokenId: calendarTokenId
  });

  const icloudContactsScope = Array.isArray(integrationsByScope?.contacts)
    ? integrationsByScope.contacts?.find(item => item.provider === 'icloud')
    : null;

  const icloudCalendarScope = Array.isArray(integrationsByScope?.calendar)
    ? integrationsByScope.calendar?.find(item => item.provider === 'icloud')
    : null;

  useEffect(() => {
    setContactsTokenId(icloudContactsScope?.id);
    console.log('setting contacts token id to: ', icloudContactsScope?.id);
  }, [icloudContactsScope?.id]);

  useEffect(() => {
    setCalendarTokenId(icloudCalendarScope?.id);
    console.log('setting contacts token id to: ', icloudCalendarScope?.id);
  }, [icloudCalendarScope?.id]);

  const contactsConnected = scopes.icloud.has('contacts');

  const calendarConnected = scopes.icloud.has('calendar');

  const handleContactsClick = useCallback(() => {
    if (contactsConnected) {
      openContactsImportModal();
      console.log('icloud contacts is connected');
    } else {
      openAuthModal();
      console.log('icloud contacts is NOT connected');
    }
  }, [contactsConnected, openContactsImportModal, openAuthModal]);

  const handleCalendarClick = useCallback(() => {
    if (calendarConnected) {
      console.log('icloud calendar is connected');
      openCalendarImportModal();
    } else {
      console.log('icloud calendar is NOT connected');
      calendarOpenAuthModal();
    }
  }, [calendarConnected, openCalendarImportModal, calendarOpenAuthModal]);

  return (
    <ErrorBoundary>
      <>
        <IntegrationItem
          name="iCloud"
          icon="integrationsIcloud"
          items={
            <Flex fullWidth spacing={1} justifyContent="center">
              <Chip
                variant={contactsConnected ? 'focus' : 'disabled'}
                onClick={handleContactsClick}
              >
                Contacts
              </Chip>
              <Chip
                variant={calendarConnected ? 'focus' : 'disabled'}
                onClick={handleCalendarClick}
              >
                Calendar
              </Chip>
            </Flex>
          }
        />

        {modal}
        {ContactsImportModal}
        {calendarModal}
        {CalendarImportModal}
      </>
    </ErrorBoundary>
  );
};

export default ICloud;
