import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const ContactDescription = (props) => {
  const {
    onChange,
    content,
  } = props;

  return (
    <Textfield
      onChange={onChange}
      value={content}
      textarea
      style={{ maxHeight: 100 }}
    />
  );
};

ContactDescription.defaultProps = {
  content: '<p></p>',
};

const {
  string,
  func,
} = PropTypes;

ContactDescription.propTypes = {
  content: string,
  onChange: func.isRequired,
};

export default ContactDescription;
