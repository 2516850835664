import React, {
  useState,
  useCallback,
} from 'react';

import { NavLink } from 'react-router-dom';

import moment from 'moment';
import { without } from 'shared/utility';

import {
  Grid,
} from '@material-ui/core';

import TodaySummarySelector from 'sections/Today/modules/TodaySummarySelector';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Button from 'ui/Button';

import { NAVIGATION_ROUTES } from 'constants/navigation.constants';

import PrioritiesObjects from './PrioritiesObjects';
import ScheduleTable from './ScheduleTable';
import DateSelector from './DateSelector';

import {
  Root,
  PrioritiesElevation,
  ScheduleElevation,
  TitleContainer,
} from './styledItems';

const noop = () => {};

const TodayTab = (props) => {
  const [selectedFilterKeys, setSelectedFilterKeys] = useState(['viewings', 'transactionItems', 'followUpReminders']);

  const [selectedDate, setSelectedDate] = useState(moment().format());

  const onSelectTodayFilter = useCallback((value) => () => {
    let newKeys = [];
    if (selectedFilterKeys.includes(value)) {
      newKeys = without(selectedFilterKeys, value);
    } else {
      newKeys = [...selectedFilterKeys, value];
    }
    setSelectedFilterKeys(newKeys);
  }, [
    selectedFilterKeys,
  ]);

  return (
    <Root>
      <Grid
        style={{
          height: 'inherit',
        }}
        container
        spacing={2}
      >

        <Grid
          item
          xs={6}
          style={{
            maxHeight: '-webkit-fill-available',
          }}
        >
          <TitleContainer>
            <Typography
              variant="title1"
              weight="bold"
              noMargin
            >
              Schedule:
            </Typography>

            <Flex
              fullWidth
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <DateSelector
                onChange={setSelectedDate}
                selectedDate={selectedDate}
              />

              <NavLink to={NAVIGATION_ROUTES.calendar}>
                <Button
                  variant="secondary"
                  icon="calendar"
                  onClick={noop}
                >
                  Open Calendar
                </Button>
              </NavLink>
            </Flex>
          </TitleContainer>

          <ScheduleElevation rounded>
            <TodaySummarySelector
              inactiveItemBgc="background-flat"
              className="today-summary-selector"
              itemClassName="today-summary-selector-item"
              selectedItemBgc="focus"
              sameFontWeight
              valueProps={{
                variant: 'title1',
                weight: 'normal',
              }}
              titleProps={{
                variant: 'subtitle2',
              }}
              selectedFilterKeys={selectedFilterKeys}
              isInvesedTextSelectedColor
              onSelect={onSelectTodayFilter}
              selectedDate={selectedDate}
            />
            <ScheduleTable
              selectedFilterKeys={selectedFilterKeys}
              selectedDate={selectedDate}
            />
          </ScheduleElevation>
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            maxHeight: '-webkit-fill-available',
          }}
        >
          <TitleContainer>
            <Typography
              variant="title1"
              weight="bold"
              noMargin
            >
              Priorities
            </Typography>
          </TitleContainer>

          <PrioritiesElevation rounded>
            <PrioritiesObjects />

          </PrioritiesElevation>
        </Grid>

      </Grid>
    </Root>
  );
};

export default TodayTab;
