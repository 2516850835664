import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  createDislike,
  updateDislike,
  removeDislikeRelations,
} from 'store/actions/likes-dislikes/dislikes';

import {
  getContactById,
} from 'store/actions/realtorContacts';

import EditableList from 'ui/EditableList';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    realtorContacts: {
      selectedContact,
    },
  } = state;

  const dislikes = selectors.realtorContacts.contactDislikes(state, contactId || selectedContact);

  return {
    items: dislikes,
    modalTitle: 'Edit contact dislikes',
    selectedContact: contactId || selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: debounce(async (dislikeId, dislikeData) => {
    dispatch(updateDislike(dislikeId)(dislikeData));
  }, 1000),

  onCreate: async (contactId, dislikeData) => {
    await dispatch(createDislike(dislikeData));
    dispatch(getContactById(contactId));
  },

  onDelete: async (contactId, dislikeId) => {
    await dispatch(removeDislikeRelations('objects')(dislikeId)({
      object_type: 25,
      object_id: contactId,
    }));
    dispatch(getContactById(contactId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onChange: (dislikeId) => (key) => (value) => {
    dispatchProps.onChange(dislikeId, {
      [key]: value,
      object_type: 25,
      object_id: stateProps.selectedContact,
    });
  },
  onCreate: (dislikeData) => {
    dispatchProps.onCreate(stateProps.selectedContact, {
      ...dislikeData,
      object_type: 25,
      object_id: stateProps.selectedContact,
    });
  },
  onDelete: (dislikeId) => {
    dispatchProps.onDelete(stateProps.selectedContact, dislikeId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditableList);
