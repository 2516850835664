import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import RepairRequestsModal from './RepairRequestsModal';

const closeRepairRequestsModal = closeModal('repairRequests');

const mapStateToProps = (state) => {
  const {
    modals: {
      repairRequests,
    },
  } = state;

  return {
    isVisible: repairRequests?.isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeRepairRequestsModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairRequestsModal);
