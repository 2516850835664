import {
  SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER,
} from 'store/actionTypes/realtorRepairRequests';

const setRepairRequestsForFilter = (filterId) => (repairRequests) => ({
  type: SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER,
  payload: {
    filterId,
    repairRequests,
  },
});

export default setRepairRequestsForFilter;
