import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import ContactFirstNameInput from 'shared/components/UI/ContactFirstNameInput';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm,
  } = state;

  const {
    isFirstNameValid,
  } = realtorNewContactForm.fieldsValidators;

  return {
    error: !isFirstNameValid ? 'First name is required' : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('personFirstName')(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFirstNameInput);
