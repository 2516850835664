import styled from 'styled-components';

export const Root = styled.div`
  height: 100%;
`;

export const ItemsContainer = styled.div`
  margin-top: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar-track: {
    background-color: transparent;
  }
  &::-webkit-scrollbar: {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb: {
    background-color: rgba(128, 128, 128, 0.3);
    borderRadius: 3px;
  }
  &::-webkit-scrollbar-track-piece:end: {
    background-color: rgba(128, 128, 128, 0.2);
    margin-bottom: 15px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:start: {
    background-color: rgba(128, 128, 128, 0.2);
    margin-top: 15px;
    border-radius: 10px;
  }
`;
