import connectReducers from 'store/connectReducers';

import {
  SET_LIKE,
  SET_LIKES,
} from 'store/actionTypes/likes-dislikes';

import {
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  likes: {},
};

const reducers = {
  [SET_LIKE]: (state, action) => ({
    ...state,
    likes: {
      ...state.likes,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_LIKES]: (state, action) => ({
    ...state,
    likes: {
      ...state.likes,
      ...listToObjectByKey(action.payload),
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
