/* eslint-disable no-console */

/**
 * Copies text to clipboard for old browsers
 * @param {string} text - The text to copy
 */

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

/**
 * Copies text to clipboard for modern browsers
 * and calls fallbackCopyTextToClipboard if navigator.clipboard is not supported
 * @param {string} text - The text to copy.
 */

const copyTextToClipboard = async (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error('Async: Could not copy text: ', error);
  }
};

export default copyTextToClipboard;
