import { connect } from 'react-redux';

import {
  setNewLeadNestedFormField,
} from 'store/actions/newRealtorLeadForm';

import ExpectedPurchasePriceInput from './ExpectedPurchasePriceInput';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      expectedPurchasePrice,
    },
  } = state;

  return {
    valueMin: expectedPurchasePrice?.min,
    valueMax: expectedPurchasePrice?.max,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChangeMin: (value) => {
    dispatch(setNewLeadNestedFormField('expectedPurchasePrice')('min')(value));
  },
  handleChangeMax: (value) => {
    dispatch(setNewLeadNestedFormField('expectedPurchasePrice')('max')(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpectedPurchasePriceInput);
