import {
  SET_NEW_PROPERTY_VIEWING_FORM_FIELD,
} from 'store/actionTypes/propertyViewings';

const setNewPropertyViewingFormField = (key) => (value) => ({
  type: SET_NEW_PROPERTY_VIEWING_FORM_FIELD,
  payload: {
    key,
    value,
  },
});

export default setNewPropertyViewingFormField;
