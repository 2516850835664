import {
  SET_DOCUMENT_REQUEST_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorTransactions';

const setNewDocumentRequestFormAttachments = (filesList = []) => ({
  type: SET_DOCUMENT_REQUEST_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setNewDocumentRequestFormAttachments;
