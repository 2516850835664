export const SET_LEADS_SUMMARY_FOR_EMPLOYEE = 'SET_LEADS_SUMMARY_FOR_EMPLOYEE';
export const SET_OVERALL_LEADS_SUMMARY = 'SET_OVERALL_LEADS_SUMMARY';

export const SET_WON_EDIT_MODAL_VISIBILITY = 'SET_WON_EDIT_MODAL_VISIBILITY';

export default {
  SET_LEADS_SUMMARY_FOR_EMPLOYEE,
  SET_OVERALL_LEADS_SUMMARY,

  SET_WON_EDIT_MODAL_VISIBILITY,
};
