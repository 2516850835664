import {
  SET_REALTOR_LEADS_OBJECTS_COUNT_FOR_FILTER,
} from 'store/actionTypes/realtorLeads';

const setLeadsObjectsCountForFilter = (filterId) => (leadsObjectsCountByFilter) => ({
  type: SET_REALTOR_LEADS_OBJECTS_COUNT_FOR_FILTER,
  payload: {
      leadsObjectsCountByFilter,
      filterId,
  },
});

export default setLeadsObjectsCountForFilter;
