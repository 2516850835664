import requests from 'api/requests';

const changeTaskRank = (
  taskId,
  employeeId,
  rank,
  isTaskPlanner = false,
) => async (dispatch) => {
  const body = {
    employeeId,
    rank,
    isTaskPlanner,
  };

  try {
    await requests.tasks.changeTaskRank(taskId)(body);
  } catch (error) {
    console.error(error);
  }
};

export default changeTaskRank;
