/* eslint-disable */
// FlySoft
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
import React from 'react';
import ObjectLink from 'shared/components/ObjectLink'
import URLThumbnails from 'shared/components/URLThumbnails'
/**
 * Render block nodes in editor
 * @param props
 * @param editor
 * @param next
 * @returns {*}
 */
export const renderNode = (props, editor, next) => {
  const {
    attributes,
    children,
    node,
    isFocused,
  } = props;
  const d = node.type.split('/');

  switch (d[0]) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      console.log('111');
      return <ol {...attributes}>{children}</ol>;
    case 'paragraph':
      return <p {...attributes} className={node.data.get('className')}>{children}</p>;
    case 'align-left':
      return <p {...attributes} style={{ textAlign: 'left' }}>{children}</p>;
    case 'align-center':
      // console.log(children, 'children');
      if (d[1]) {
        return <h1 {...attributes} style={{ textAlign: 'center' }} className={node.data.get('className')}>{children}</h1>;
      }
      return <p {...attributes} style={{ textAlign: 'center' }} className={node.data.get('className')}>{children}</p>;
    case 'align-justify':
      return <p {...attributes} style={{ textAlign: 'justify' }}>{children}</p>;
    case 'align-right':
      return <p {...attributes} style={{ textAlign: 'right' }}>{children}</p>;
    case 'link': {
      const { data } = node;
      const link = data.get('link');
      const thumbnailInf = data.get('thumbnailInf');
      if (!thumbnailInf) {
        return (
          <URLThumbnails
            {...attributes}
            url={link}
            isNoThumbnails={true}
          />
        )
      } else {
        return (
          <URLThumbnails
            {...attributes}
            isNoThumbnails={false}
            url={link}
            siteName={thumbnailInf.OG.site_name}
            iconSrc={thumbnailInf.favicon.src}
            title={thumbnailInf.OG.title}
          />
        )
      }
    }
    case 'image': {
      let src = node.data.get('src');
      const loc = node.data.get('loc');
      if (loc === 'internal') {
        const token = localStorage.getItem('token');
        src += `?auth=${token}`;
      }
      return (
        <img
          src={src}
          alt="article-img"
          style={isFocused ? {
            border: '1px solid #40a9ff',
            maxWidth: '100%',
          } : { maxWidth: '100%' }}
        />
      );
    }
    case 'video': {
      let src = node.data.get('src');
      const loc = node.data.get('loc');
      if (loc === 'internal') {
        const token = localStorage.getItem('token');
        src += `?auth=${token}`;
      }
      const type = node.data.get('type');
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          controls
          width="100%"
        >
          <source src={src} type={type} />
        </video>
      );
    }
    case 'objectLink': {
      const link = node.data.get('link')
      console.log(link);
      return <ObjectLink {...attributes} url={link} />
    }
    default:
      return next();
  }
};


/**
 * Render mark nodes in editor
 * @param props
 * @param editor
 * @param next
 * @returns {*}
 */
export const renderMark = (props, editor, next) => {
  const { children, mark, attributes } = props;

  switch (mark.type) {
    case 'bold':
      return <strong {...attributes}>{children}</strong>;
    case 'code':
      return <code {...attributes}>{children}</code>;
    case 'mark':
      return <mark {...attributes}>{children}</mark>;
    case 'italic':
      return <em {...attributes}>{children}</em>;
    case 'strike':
      return <s {...attributes}>{children}</s>;
    case 'underlined':
      return <u {...attributes}>{children}</u>;
    case 'highlight':
      return (
        <span {...attributes} style={{ backgroundColor: '#ffeeba' }}>
          {children}
        </span>
      );
    default:
      return next();
  }
};
