import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useContactPhoneNumbers = (
  contactId,
) => {
  const contactPhoneNumbers = useReselect(selectors.realtorContacts.contactPhoneNumbers, contactId);

  return contactPhoneNumbers;
};

export default useContactPhoneNumbers;
