import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

import {
  optimizedAttachmentsActions,
} from 'store/actions/attachmentsV2';

import getImageUrl from 'shared/utils/getImageUrl';

import { reduce } from 'shared/utility';

const objectType = 34;

export default (id) => createSelector(
  (state) => state.attachmentsV2.attachments,
  (state) => state.realtorInspections.inspections,
  (
    attachmentsCollection,
    collection,
  ) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorInspectionsActions.getInspectionById(id);
      }

      return [];
    }

    if (!item.attachments) {
      optimizedRealtorInspectionsActions.getInspectionAttachments(id);

      return [];
    }

    const attachments = reduce(item.attachments, (accum, attachmentId) => {
      const attachment = attachmentsCollection[attachmentId];

      if (!attachment) {
        if (attachmentId) {
          optimizedAttachmentsActions.getAttachmentsList(objectType, id);
        }

        return accum;
      }

      const imageUrl = getImageUrl(attachment.Location, attachment.Url, attachment.Acl);

      accum.push({
        id: attachmentId,
        src: imageUrl,
        uid: attachmentId,
        name: attachment.OriginalName,
        url: imageUrl,
      });
      return accum;
    }, []);

    return attachments;
  },
);
