import apiConnector, { endpoints } from 'api';

const resync = async () => {
  const endpoint = `${endpoints.client.integrations.calendar}/resync`;

  try {
    const result = await apiConnector.client.get(endpoint);
    console.log('successfully resynced events for active integrations');
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default resync;
