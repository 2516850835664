import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.newFulfillmentForm.products,
  (products) => (products
    ? Object.values(products).reduce((acc, product) => {
      const quantity = product.quantity || product.quantity === 0
        ? product.quantity
        : 1;

      const value = product.price_per_unit || product.value;

      acc += value * quantity; // eslint-disable-line no-param-reassign

      return acc;
    }, 0)
    : 0),
);
