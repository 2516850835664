import connectReducers from 'store/connectReducers';
import { // eslint-disable-line import/no-cycle
  GET_LATEST_MOOD_BY_DEPARTMENTS_SUCCESS,
  GET_LATEST_MOOD_BY_EMPLOYEES_SUCCESS,
  GET_LATEST_MOOD_BY_OFFICES_SUCCESS, GET_MOOD_BY_DAY_SUCCESS, GET_MOOD_SETTINGS_SUCCESS,
  GET_MOOD_STATISTIC_BY_DEPARTMENT_SUCCESS,
  GET_MOOD_STATISTIC_BY_EMPLOYEE_SUCCESS,
  GET_MOOD_STATISTIC_BY_OFFICE_SUCCESS, GET_MOOD_SUCCESS,
  GET_USER_MOOD_SUCCESS,
} from 'store/actions/mood';

const initialState = {
  userMood: {
    mood: null,
    userComment: '',
    hideName: false,
    date: new Date(),
  },
  userMoodLoaded: false,
  moodSettings: {
    MoodSettingDay: 0,
    AllowAnonymous: true,
    MoodSettingTime: null,
  },
  dashboard: {
    employeesMood: [],
    officesMood: [],
    departmentsMood: [],
  },
  details: {
    statistic: {},
    moodDiagramData: [],
    moodTableData: [],
  },
};

const reducers = {
  [GET_USER_MOOD_SUCCESS]: (state, action) => ({
    ...state,
    userMoodLoaded: true,
    userMood: action.mood,
  }),
  [GET_LATEST_MOOD_BY_EMPLOYEES_SUCCESS]: (state, action) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      employeesMood: action.mood,
    },
  }),
  [GET_LATEST_MOOD_BY_OFFICES_SUCCESS]: (state, action) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      officesMood: action.mood,
    },
  }),
  [GET_LATEST_MOOD_BY_DEPARTMENTS_SUCCESS]: (state, action) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      departmentsMood: action.mood,
    },
  }),
  [GET_MOOD_STATISTIC_BY_OFFICE_SUCCESS]: (state, action) => ({
    ...state,
    details: {
      ...state.details,
      statistic: action.statistic,
    },
  }),
  [GET_MOOD_STATISTIC_BY_DEPARTMENT_SUCCESS]: (state, action) => ({
    ...state,
    details: {
      ...state.details,
      statistic: action.statistic,
    },
  }),
  [GET_MOOD_STATISTIC_BY_EMPLOYEE_SUCCESS]: (state, action) => ({
    ...state,
    details: {
      ...state.details,
      statistic: action.statistic,
    },
  }),
  [GET_MOOD_BY_DAY_SUCCESS]: (state, action) => ({
    ...state,
    details: {
      ...state.details,
      moodDiagramData: action.mood,
    },
  }),
  [GET_MOOD_SUCCESS]: (state, action) => ({
    ...state,
    details: {
      ...state.details,
      moodTableData: action.mood,
    },
  }),
  [GET_MOOD_SETTINGS_SUCCESS]: (state, action) => ({
    ...state,
    moodSettings: action.moodSettings,
  }),
};

function mood(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default mood;
