import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Textfield from 'ui/Textfield';

import { Wrapper } from './styledItems';

const DateTimeInput = (props) => {
  const {
    handleChange,
    value,
    label,
    error,
    disablePast,
    flat,
    min,
    max,
  } = props;

  const [dateValue, setDateValue] = useState(value ? moment(value) : null);

  useEffect(() => {
    setDateValue(value ? moment(value) : null);
  }, [
    value,
  ]);

  const onChange = useCallback((dateTime) => {
    const formattedValue = moment(dateTime).format();
    setDateValue(moment(dateTime));
    handleChange(formattedValue || null);
  }, [
    handleChange,
  ]);

    /* eslint-disable react/prop-types */
    const renderInput = useCallback((inputProps) => {
      const formattedValue = dateValue ? moment(dateValue).format('MMM DD, YYYY HH:mm a') : null;

      return (
        <Textfield
          ref={inputProps.inputRef}
          label={label}
          type="text"
          onClick={inputProps.onClick}
          value={formattedValue}
          onChange={inputProps.onChange}
          icon="calendar"
          flat={flat}
        />
      );
    }, [
      dateValue,
      label,
      flat,
    ]);
    /* eslint-enable */

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        <DateTimePicker
          error={error}
          disablePast={disablePast}
          className="first-contact-date-picker"
          label={label}
          value={dateValue}
          onChange={onChange}
          margin="dense"
          inputVariant="outlined"
          variant="inline"
          fullWidth
          showTodayButton
          minutesStep={5}
          autoOk
          TextFieldComponent={renderInput}
          minDate={min}
          maxDate={max}
        />
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

DateTimeInput.defaultProps = {
  value: null,
  disablePast: false,
  label: 'First contact date',
  error: false,
  flat: false,
};

const {
  func,
  string,
  bool,
} = PropTypes;

DateTimeInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
  error: bool,
  disablePast: bool,
  label: string,
  flat: bool,
};

export default DateTimeInput;
