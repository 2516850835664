import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorUnansweredQuestionActions,
} from 'store/actions/realtorUnansweredQuestions';

import getFilter from './filter';

export default (filter) => createSelector(
  (state) => state.realtorUnansweredQuestions.unansweredQuestionsByFilter,
  () => getFilter(filter),
  (listsByFilter, filterObj) => {
    const list = listsByFilter[filterObj.filterId];

    if (!list) {
      optimizedRealtorUnansweredQuestionActions.getForFilter(filterObj.filterId, filterObj.filter);
    }

    return list;
  },
);
