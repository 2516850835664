/* eslint-disable react/require-default-props */

import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'oa/components/Checkbox/index';
import TodosContext from '../TodosContext';

const Item = (props) => {
  const {
    toggleTodo,
  } = useContext(TodosContext);

  const {
    id,
    name,
    isCompleted,
    index,
    className,
    toggleable,
  } = props;

  const handleCheckboxChange = useCallback((event) => {
    toggleTodo(event.currentTarget.name);
  }, [toggleTodo]);

  const label = (
    <span className="todos-list__label">
      <b className="todos-list__number">
        {index + 1}
        .
        {' '}
      </b>
      {name}
    </span>
  );

  const checkbox = (
    <Checkbox
      htmlId={`todo-item-${id}`}
      label={label}
      name={id}
      onChange={handleCheckboxChange}
      checked={isCompleted}
    />
  );

  return (
    <li
      className={classNames('todos-list__item', className, {
        'todos-list__item_completed': isCompleted,
      })}
    >
      {toggleable ? checkbox : label}
    </li>
  );
};

Item.defaultProps = {
  toggleable: true,
};

Item.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  toggleable: PropTypes.bool,
};

export default Item;
