import apiConnector, { endpoints } from 'api';

const create = async (value) => {
  const endpoint = endpoints.client.dictionary.personTitles;

  try {
    const result = await apiConnector.client.post(endpoint, { value });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
