import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedCrmLeadsActions,
} from 'store/actions/crm/crmLeads';

const leads = (state) => state.crmLeads.leads;
const selectedLead = (state) => state.crmLeads.selectedLead;

export default createSelector(
  leads,
  selectedLead,
  (leadItems, selectedLeadId) => {
    const lead = leadItems[selectedLeadId];

    if (!lead) {
      if (selectedLead) {
        optimizedCrmLeadsActions.getLeadById(selectedLeadId);
      }

      return null;
    }

    return lead.value;
  },
);
