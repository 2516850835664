import connectReducers from 'store/connectReducers';

import {
  SET_ARCHIVE_DELETE_LEAD_MODAL_OPEN,
  SET_ARCHIVE_DELETE_LEAD_MODAL_CLOSED,
  SET_ARCHIVE_DELETE_LEAD_MODAL_SELECTED_ACTION,
} from 'store/actionTypes/realtorLeads';

import {
  setValue,
} from 'store/utils';

const initialState = {
  isVisible: false,
  selectedAction: 'archive',
  leadId: null,
  connectedItemType: null,
  connectedItemId: null,
};

const reducers = {
  [SET_ARCHIVE_DELETE_LEAD_MODAL_OPEN]: (state, action) => {
    const {
      leadId,
      connectedItemType,
      connectedItemId,
    } = action;

    return {
      ...state,
      leadId,
      connectedItemType,
      connectedItemId,
      isVisible: true,
    };
  },

  [SET_ARCHIVE_DELETE_LEAD_MODAL_CLOSED]: () => ({
    ...initialState,
  }),

  [SET_ARCHIVE_DELETE_LEAD_MODAL_SELECTED_ACTION]: setValue,
};

function archiveDeleteLeadModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default archiveDeleteLeadModal;
