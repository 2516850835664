import connectReducers from 'store/connectReducers';

import {
  SET_NEW_LEAD_MODAL_OPEN,
  SET_NEW_LEAD_MODAL_CLOSED,
} from 'store/actionTypes/crm';

const initialState = {
  isVisible: false,
};

const reducers = {
  [SET_NEW_LEAD_MODAL_OPEN]: (state) => ({
    ...state,
    isVisible: true,
  }),

  [SET_NEW_LEAD_MODAL_CLOSED]: (state) => ({
    ...state,
    isVisible: false,
  }),
};

function newLeadModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newLeadModal;
