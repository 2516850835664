import requests from 'api/requests';

import setDictionary from './setDictionary';

const getContactLabelsDictionary = () => async (dispatch) => {
  try {
    const response = await requests.dictionary.contactLabels();

    dispatch(setDictionary('contactLabels')(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getContactLabelsDictionary;
