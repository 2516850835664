import styled from 'styled-components';

const placements = {
  top: 'bottom: 100%;',
  bottom: '',
  left: 'right: 100%; top: 0;',
  right: 'left: 100%; top: 0;',
};

export const Wrapper = styled.div`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export const SearchContactArea = styled.div`
  width: 100%;
  min-width: 350px;
  position: absolute;
  ${(props) => placements[props.placement]}
  z-index: 2;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31);
  background-color: #ffffff;
  ${({
    theme,
    border,
  }) => border && (
    `
      border-color: ${theme?.palette?.border || 'grey'};
      border-width: 1px;
      border-style: solid;
    `
  )}
`;

export default {
  Wrapper,
  SearchContactArea,
};
