import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Chip from 'ui/Chip';

const TabLabel = (props) => {
  const {
    children,
    isSelected,
    highlightSelected,
    variant,
    weight,
    weightSelected,
    chipValue,
    chipVariant,
  } = props;

  const getWeight = useCallback(() => {
    if (!isSelected) {
      return weight;
    }
    return weightSelected || weight;
  }, [
    isSelected,
    weight,
    weightSelected,
  ]);

  return (
    <Flex spacing={1} alignItems="baseline">
      <Typography
        variant={variant}
        tag="p"
        weight={getWeight()}
        noMargin
        color={(highlightSelected && isSelected) ? 'highlight' : 'default'}
        style={{
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Typography>
      {
        chipValue && (
          <Chip
            variant={chipVariant}
          >
            {chipValue}
          </Chip>
        )
      }
    </Flex>
  );
};

TabLabel.defaultProps = {
  isSelected: false,
  variant: 'h1',
  chipValue: null,
  chipVariant: 'subtle',
  highlightSelected: true,
  weight: 700,
  weightSelected: null,
};

const {
  bool,
  string,
  number,
  oneOfType,
} = PropTypes;

TabLabel.propTypes = {
  isSelected: bool,
  children: string.isRequired,
  variant: string,
  chipValue: string,
  chipVariant: string,
  highlightSelected: bool,
  weight: oneOfType([string, number]),
  weightSelected: oneOfType([string, number]),
};

export default TabLabel;
