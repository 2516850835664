import {
  SET_CRM_LEAD,
} from 'store/actionTypes/crm';

const setLeads = (lead) => ({
  type: SET_CRM_LEAD,
  payload: lead,
});

export default setLeads;
