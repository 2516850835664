import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { uniqueId } from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';

import ImagesUpload from 'shared/inputs/ImagesUpload';
import UploadImageButton from 'shared/components/UI/UploadImageButton';

import SectionTitle from './SectionTitle';

import ListingTypeSelect from './ListingTypeSelect';
import TempSelect from './TempSelect';
import AddressInput from './AddressInput';
import PriceInput from './PriceInput';
import PropertyTypeSelect from './PropertyTypeSelect';
import PropertyParameters from './PropertyParameters';
import DescriptionInput from './DescriptionInput';
import NotesInput from './NotesInput';
import LinkInput from './LinkInput';

import AgentFirstName from './ListingAgent/AgentFirstName';
import AgentLastName from './ListingAgent/AgentLastName';
import AgentEmail from './ListingAgent/AgentEmail';
import AgentPhone from './ListingAgent/AgentPhone';
import AgentBrokerName from './ListingAgent/AgentBrokerName';

const NewPropertyFormScraper = (props) => {
  const {
    defaultValues,
    onCancel,
    onSubmit: onSubmitProps,
  } = props;

  const [images, setImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(null);
  const [agentAvatar, setAgentAvatar] = useState(null);

  useEffect(() => {
    if (defaultValues?.propertyImages && Array.isArray(defaultValues.propertyImages)) {
      const filelist = defaultValues.propertyImages.map((url) => {
        const filename = url.split('/').pop();

        return {
          name: filename,
          type: 'image',
          uid: uniqueId(),
          url,
        };
      });

      setImages(filelist);
    }
  }, [
    JSON.stringify(defaultValues?.propertyImages),
  ]);

  const onImageUpload = useCallback(({ file }) => {
    const fileUrl = URL.createObjectURL(file);

    const imageObject = {
      name: file.name,
      type: file.type,
      uid: file.uid,
      url: fileUrl,
    };

    setImages(images.concat([imageObject]));
  }, [
    JSON.stringify(images),
  ]);

  const onImageRemove = useCallback(({ uid, url }) => {
    URL.revokeObjectURL(url);

    const newList = images.filter((file) => file.uid !== uid);

    setImages(newList);
  }, [
    JSON.stringify(images),
  ]);

  const onMainImageChange = useCallback(({ index }) => setMainImageIndex(index), []);

  const onAgentAvatarUpload = useCallback((file) => {
    const fileUrl = URL.createObjectURL(file);
    setAgentAvatar({
      name: file.name,
      type: file.type,
      fileUrl,
    });
  }, []);

  const onAgentAvatarRemove = useCallback((fileUrl) => () => {
    URL.revokeObjectURL(fileUrl);
    setAgentAvatar(null);
  }, []);

  const {
    handleSubmit,
    control,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      listingType: 1,
      address: defaultValues?.address || '',
      price: defaultValues?.price || 0,
      bedrooms: Number(defaultValues?.details?.beds) || 0,
      bathrooms: Number(defaultValues?.details?.bathrooms) || 0,
      floorArea: Number(defaultValues?.details?.sqft) || 0,
      plotArea: Number(defaultValues?.details?.acres) || 0,
      description: defaultValues?.description || '',
      notes: defaultValues?.notes || '',
      propertyUrl: defaultValues?.propertyUrl || '',
    },
  });

  const onSubmit = useCallback(async (values) => {
    await onSubmitProps({
      ...values,
      images,
      mainImageIndex,
      agentAvatar,
    });
    onCancel();
  }, [
    JSON.stringify(images),
    mainImageIndex,
    JSON.stringify(agentAvatar),
    onSubmitProps,
    onCancel,
  ]);

  return (
    <form id="property-scraper-form">
      <Flex
        flexDirection="column"
        spacing={4}
        fullWidth
      >
        <Flex
          justifyContent="space-between"
          fullWidth
        >
          <FlexItem>
            <ListingTypeSelect control={control} />
          </FlexItem>
          <FlexItem>
            <TempSelect control={control} />
          </FlexItem>
        </Flex>

        <Flex
          spacing={2}
          fullWidth
          flexWrap="wrap"
        >
          <FlexItem xs={24}>
            <SectionTitle
              title="Property Details"
              icon="check-circle"
            />
          </FlexItem>

          <FlexItem xs={24}>
            <AddressInput control={control} />
          </FlexItem>

          <FlexItem xs={10}>
            <PriceInput control={control} />
          </FlexItem>

          <FlexItem xs={14}>
            <PropertyTypeSelect control={control} />
          </FlexItem>

          <FlexItem xs={24}>
            <PropertyParameters control={control} />
          </FlexItem>

          <FlexItem xs={24}>
            <DescriptionInput control={control} />
          </FlexItem>

          <FlexItem xs={24}>
            <NotesInput control={control} />
          </FlexItem>
        </Flex>

        <Flex
          spacing={2}
          fullWidth
          flexWrap="wrap"
        >
          <FlexItem xs={24}>
            <SectionTitle
              title="Property Photos"
              icon="image"
            />
          </FlexItem>

          <FlexItem xs={24}>
            <ImagesUpload
              fileList={images}
              onUpload={onImageUpload}
              onRemove={onImageRemove}
              label="Drag & drop or click to upload property photos"
              icon
              multiple
              mainImageIndexOverwrite={mainImageIndex}
              onMainImageChange={onMainImageChange}
            />
          </FlexItem>
        </Flex>

        <Flex
          spacing={2}
          fullWidth
          flexWrap="wrap"
        >
          <FlexItem xs={24}>
            <SectionTitle
              title="Property URL"
              icon="globe"
            />
          </FlexItem>

          <FlexItem xs={24}>
            <LinkInput control={control} />
          </FlexItem>
        </Flex>

        <Flex
          spacing={2}
          fullWidth
          flexWrap="wrap"
        >
          <FlexItem xs={24}>
            <SectionTitle
              title="Listing Agent"
              icon="user"
            />
          </FlexItem>

          <FlexItem xs={24}>
            <UploadImageButton
              fileUrl={agentAvatar?.fileUrl || null}
              onUpload={onAgentAvatarUpload}
              clearAvatar={onAgentAvatarRemove}
              buttonText="Upload profile picture"
              appModule="realtor-contact-avatar"
              cropAppModule="realtor-contact-avatar"
              visibleModule="realtor-contact-avatar"
            />
          </FlexItem>
          <FlexItem xs={12}>
            <AgentFirstName control={control} />
          </FlexItem>
          <FlexItem xs={12}>
            <AgentLastName control={control} />
          </FlexItem>
          <FlexItem xs={12}>
            <AgentEmail control={control} />
          </FlexItem>
          <FlexItem xs={12}>
            <AgentPhone control={control} />
          </FlexItem>
          <FlexItem xs={24}>
            <AgentBrokerName control={control} />
          </FlexItem>
        </Flex>

        <Flex
          justifyContent="flex-end"
          spacing={2}
          fullWidth
        >
          <FlexItem>
            <Button
              onClick={onCancel}
              variant="secondary"
              type="button"
            >
              Cancel
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              onClick={handleSubmit(onSubmit)}
              type="button"
            >
              Add to Symplete
            </Button>
          </FlexItem>
        </Flex>

      </Flex>
    </form>
  );
};

NewPropertyFormScraper.defaultProps = {
  defaultValues: {},
};

const {
  arrayOf,
  number,
  oneOfType,
  shape,
  string,
  func,
} = PropTypes;

NewPropertyFormScraper.propTypes = {
  defaultValues: shape({
    price: string,
    address: string,
    propertyImages: arrayOf(string),
    details: shape({
      beds: oneOfType([string, number]),
      bathrooms: oneOfType([string, number]),
      sqft: oneOfType([string, number]),
      acres: oneOfType([string, number]),
    }),
  }),
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

export default NewPropertyFormScraper;
