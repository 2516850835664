import requests from 'api/requests';

import getById from './getById';

const addRelations = (relationType) => (matchId) => (relationId) => async (dispatch) => {
  try {
    await requests.realtor.unansweredQuestions.addRelations(relationType)(matchId)(relationId);

    dispatch(getById(matchId));
  } catch (error) {
    console.error(error);
  }
};

export default addRelations;
