import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import validate from 'shared/utils/validators';

import Field from 'ui/PhoneNumberInput';

const AgentPhone = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'agentPhone',
    control,
    rules: {
      validate: (input) => {
        if (!input) {
          return true;
        }
        return validate.phone(input);
      },
    },
  });

  return (
    <Field
      value={value}
      onChange={onChange}
      label="Phone"
    />
  );
};

const {
  shape,
} = PropTypes;

AgentPhone.propTypes = {
  control: shape({}).isRequired,
};

export default AgentPhone;
