/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import { debounce } from 'shared/utility';

const getDebouncedCallbacks = (props, options) => {
  const debouncedCallbacks = options.reduce((acc, item) => {
    const [callbackName, delay, debounceOptions] = item;

    acc[callbackName] = debounce(props[callbackName], delay, debounceOptions);

    return acc;
  }, {});

  return debouncedCallbacks;
};

const withDebouncedCallbacks = (Component, options) => (props) => {
  const debouncedCallbacks = getDebouncedCallbacks(props, options);

  return (
    <Component
      {...props}
      {...debouncedCallbacks}
    />
  );
};

export default withDebouncedCallbacks;
