import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useToken = () => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getToken = async () => {
    try {
      const result = await requests.calls.token.get();
  
      setToken(result?.data);
    }
    catch(error) {
      
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return {
    token,
    isLoading,
  };
};

export default useToken;
