import { globalUrl as apiUrl } from 'paths';
import { isEmpty, isString } from 'shared/utility';

/**
 * @param {string} url
 * @param {string} token
 * @returns {string}
 * @function
 */
const getAttachmentUrlWithAuth = (url, token) => `${url}?auth=${token}`;

/**
 * @param {string} attachmentLocation
 * @param {string} token
 * @returns {string}
 * @function
 */
const getAttachemntUrlWithToken = (attachmentLocation, token) => {
  if (isEmpty(attachmentLocation)) {
    return null;
  }

  if (!isString(attachmentLocation)) {
    throw new Error('Avatar location must be a string');
  }

  const fileSrc = `${apiUrl}/company-logo${attachmentLocation.slice(4)}`;

  const avatarUrl = getAttachmentUrlWithAuth(fileSrc, token);

  return avatarUrl;
};

export default getAttachemntUrlWithToken;
