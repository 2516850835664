import styled from 'styled-components';

import { isUndefined } from 'shared/utility';

const positions = {
  'top-left': {},
  top: {
    left: '50%',
    transform: 'translateX(-50%)',
  },
  'top-right': {
    right: 0,
  },
  left: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  center: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  right: {
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  'bottom-left': {
    bottom: 0,
  },
  bottom: {
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  'bottom-right': {
    right: 0,
    bottom: 0,
  },
};

const fullWidthPositions = {
  'top-left': {},
  top: {},
  'top-right': {},
  left: {},
  center: {
    top: '50%',
  },
  right: {},
  'bottom-left': {
    bottom: 0,
  },
  bottom: {
    bottom: 0,
  },
  'bottom-right': {
    bottom: 0,
  },
};

export const Root = styled.div`
  position: relative;
  width: fit-content;
`;

export const TagWrapper = styled.div`
  display: inline-flex;
  z-index: 1;
  position: absolute;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  margin-top: ${({ offset }) => offset[0]}px;
  margin-left: ${({ offset }) => offset[1]}px;
  ${({ rotateDeg, fullWidth, position }) => {
    if (fullWidth) {
      return `transform: rotate(${rotateDeg}deg);`;
    }

    return `
        transform: rotate(${rotateDeg}deg) ${(!isUndefined(positions[position].transform) ? positions[position].transform : null)};
      `;
}
  }
  top: ${({ position, fullWidth }) => {
    if (fullWidth) {
      return (!isUndefined(fullWidthPositions[position].top) ? fullWidthPositions[position].top : 'unset');
    }

    return (!isUndefined(positions[position].top) ? positions[position].top : 'unset');
  }};
  left: ${({ position, fullWidth }) => {
    if (fullWidth) {
      return (!isUndefined(fullWidthPositions[position].left) ? fullWidthPositions[position].left : 'unset');
    }

    return (!isUndefined(positions[position].left) ? positions[position].left : 'unset');
  }};
  right: ${({ position, fullWidth }) => {
    if (fullWidth) {
      return (!isUndefined(fullWidthPositions[position].right) ? fullWidthPositions[position].right : 'unset');
    }

    return (!isUndefined(positions[position].right) ? positions[position].right : 'unset');
  }};
  bottom: ${({ position, fullWidth }) => {
    if (fullWidth) {
      return (!isUndefined(fullWidthPositions[position].bottom) ? fullWidthPositions[position].bottom : 'unset');
    }

    return (!isUndefined(positions[position].bottom) ? positions[position].bottom : 'unset');
  }};
  gap: 3px;
`;

export const Tag = styled.div`
  height: fit-content;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  padding: 2px 3px;
  border-top-left-radius: ${({ borderRadius }) => borderRadius.topLeft}px;
  border-top-right-radius: ${({ borderRadius }) => borderRadius.topRight}px;
  border-bottom-left-radius: ${({ borderRadius }) => borderRadius.bottomLeft}px;
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius.bottomRight}px;
  ${({ isBordered }) => (isBordered ? 'border: solid 1.5px #ffffff;' : null)}
  background-color: ${({ color, theme }) => theme?.palette?.[color] || color};
`;

export default {
  Root,
  Tag,
  TagWrapper,
};
