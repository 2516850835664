import {
  SET_REALTOR_FOLLOW_UP_REMINDERS,
} from 'store/actionTypes/followUpReminders';

const setFollowUpReminders = (reminders) => ({
  type: SET_REALTOR_FOLLOW_UP_REMINDERS,
  payload: reminders,
});

export default setFollowUpReminders;
