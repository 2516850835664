import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ShowingTime from 'sections/Showings/modules/ShowingTime';

const mapStateToProps = (state, ownProps) => {
  const matchingProperty = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  return {
    matchingProperty,
    showingId: matchingProperty?.viewing_upcoming_ids?.[0],
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ShowingTime);
