import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import { last } from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Timeline from 'shared/components/UI/Timeline';

import Header from './Header';
import CounterBox from './CounterBox';
import TimelineItem from './TimelineItem';

import {
  Block,
} from './styledItems';

const getNum = (str) => {
  const num = str.replace(/[^0-9]/g, '');
  return parseInt(num, 10);
};

const loadingStateTimeline = [{
  type: 'loading',
  id: 'loading',
}];

const Offer = (props) => {
  const {
    ordinalNumber,
    amount,
    buyerId,
    buyerAgentId,
    propertyId,
    isClient,
    isProspect,
    timelineEvents,
    offerId,
    isCancelled,
    onCancelOffer,
    isDeclined,
    isAccepted,
    delta,
    acceptedAmount,
    onUndoAcceptance,
    isTimelineLoading,
    header,
  } = props;

  const [selectedId, setSelectedId] = useState(null);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (isCancelled) {
      return;
    }
    const lastCounter = last(timelineEvents);
    if (typeof lastCounter?.id === 'string') {
      setSelectedId(getNum(lastCounter?.id));
    } else {
      setSelectedId(lastCounter?.id);
    }
  }, [
    timelineEvents?.length,
    isCancelled,
  ]);

  const selectCounter = useCallback((id) => () => {
    if (isCancelled) {
      return;
    }
    if (typeof id === 'string') {
      setSelectedId(getNum(id));
    } else {
      setSelectedId(id);
    }
  }, [
    isCancelled,
  ]);

  return (
    <Block>
      <Flex
        spacing={2}
        flexWrap="wrap"
      >
        {
          !header && header !== null && (
            <Header
              offerNumber={ordinalNumber}
              amount={amount}
              delta={delta}
              buyerId={buyerId}
              buyerAgentId={buyerAgentId}
              propertyId={propertyId}
              isClient={isClient}
              isProspect={isProspect}
              isCancelled={isCancelled}
              isDeclined={isDeclined}
              isAccepted={isAccepted}
              onCancel={onCancelOffer}
              acceptedAmount={acceptedAmount}
              onUndoAcceptance={onUndoAcceptance}
            />
          )
        }

        {
          !isCancelled && (
            <FlexItem fullWidth>
              <CounterBox
                counterId={selectedId}
                rootOfferId={offerId}
                buyerId={buyerId}
                buyerAgentId={buyerAgentId}
              />
            </FlexItem>
          )
        }

        <FlexItem fullWidth>
          <Timeline
            events={isTimelineLoading ? loadingStateTimeline : timelineEvents}
            showLineBeforeFirst={false}
            showLineAfterLast={!isCancelled && !isDeclined}
            customTimelineItem={TimelineItem}
            selectedElement={selectedId}
            onEventClick={selectCounter}
            selectedItemColor={theme?.palette.focus}
            todayMarkLineHeight={75}
          />
        </FlexItem>
      </Flex>
    </Block>
  );
};

Offer.defaultProps = {
  buyerId: null,
  buyerAgentId: null,
  propertyId: null,
  timelineEvents: [],
  isCancelled: false,
  isDeclined: false,
  isAccepted: false,
  delta: null,
  acceptedAmount: null,
  isTimelineLoading: false,
};

const {
  number,
  arrayOf,
  shape,
  bool,
  func,
} = PropTypes;

Offer.propTypes = {
  offerId: number.isRequired,
  ordinalNumber: number.isRequired,
  amount: number.isRequired,
  buyerId: number,
  buyerAgentId: number,
  propertyId: number,
  isClient: bool.isRequired,
  isProspect: bool.isRequired,
  timelineEvents: arrayOf(shape()),
  isCancelled: bool,
  onCancelOffer: func.isRequired,
  isDeclined: bool,
  isAccepted: bool,
  delta: number,
  acceptedAmount: number,
  onUndoAcceptance: func.isRequired,
  isTimelineLoading: bool,
};

export default Offer;
