import {
  SET_NEW_REALTOR_PROPERTY_FORM,
} from 'store/actionTypes/realtorMls';

const setNewPropertyForm = (values = {}) => ({
  type: SET_NEW_REALTOR_PROPERTY_FORM,
  payload: values,
});

export default setNewPropertyForm;
