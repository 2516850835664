import { SET_ONLINE_USERS } from 'store/actionTypes/call';

const setOnlineUsers = (payload) => (dispatch) => {
  dispatch({
    type: SET_ONLINE_USERS,
    payload,
  });
};

export default setOnlineUsers;
