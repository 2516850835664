import requests from 'api/requests';

import setOverallContactsSummary from './setOverallContactsSummary';

const getOverallContactsSummary = () => async (dispatch) => {
  try {
    const response = await requests.crm.contacts.getSummary();
    dispatch(setOverallContactsSummary(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOverallContactsSummary;
