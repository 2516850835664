export const tickets = {
  ALL_TYPES: {
    ALL_TICKETS: 'Tickets',
    UNASSIGNED: 'Unassigned',
    NEW_ASSIGNED: 'New Assigned',
    IN_PROGRESS: 'In Progress',
    PENDING: 'Pending',
    RESOLVED: 'Resolved',
    OVERDUE: 'Overdue',
    PROBLEM: 'Problem',
    RESOLVED_THIS_WEEK: 'Resolved This Week',
    TOTAL_AGENTS: 'Total Agents',
  },
  TICKET_STATUS_ID: {
    RESOLVED_USER_HAPPY: 6,
    RESOLVED_NOT_SATISFACTORY: 7,
    RESOLVED_WITHOUT_FEEDBACK: 8,
    ARCHIVE: 9,
  },
  EMAIL_OTHER: 'Email / Other',
};

export const RESOLVED = 'Ticket resolved';
export const FEEDBACK_SUBMITTED = 'Your feedback was submitted. All feedback will show inside the ticket.';
export const TICKET_CREATED = 'Ticket created';
export const TICKET_ESCALATED = 'Ticket escalated';
export const TICKET_UPDATED = 'Ticket updated';
export const TUTORIAL_TICKET_ID = 2020;

export const TICKETS_RESOLVED_BY_TYPES = {
  employee: 1,
  customer: 2,
};
