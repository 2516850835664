import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

import InputComponent from './InputComponent';

const PhoneNumberInput = (props) => {
  const {
    value: valueProps,
    onChange,
    label,
    icon,
    onNotNumberInput,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueProps);
  }, [
    valueProps,
  ]);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }, [
    onChange,
  ]);

  return (
    <PhoneInput
      {...props}
      initialValueFormat="national"
      defaultCountry="US"
      value={value}
      onChange={handleChange}
      inputComponent={InputComponent}
      label={label}
      icon={icon}
      onNotNumberInput={onNotNumberInput}
    />
  );
};

PhoneNumberInput.defaultProps = {
  value: '',
  label: 'Phone number',
  icon: null,
  onNotNumberInput: null,
};

const {
  string,
  func,
} = PropTypes;

PhoneNumberInput.propTypes = {
  value: string,
  onChange: func.isRequired,
  label: string,
  icon: string,
  onNotNumberInput: func,
};

export default PhoneNumberInput;
