import apiConnector, { endpoints } from 'api';

const create = (objectId) => (objectTypeId) => async (data) => {
  const endpoint = endpoints.client.followUpReminders.general;

  try {
    const result = await apiConnector.client.post(endpoint, {
      reminder: data,
      objectTypeId,
      objectId,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
