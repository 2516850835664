import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

// import createHtmlMarkup from 'shared/utils/createHtmlMarkup';

import Modal from 'ui/Modal';
import Typography from 'ui/Typography';
import Button from 'ui/Button';
import Textfield from 'ui/Textfield';

// import EditableTextField from 'shared/components/EditableTextField';

import {
  TextWrapper,
  // Extra,
  // NoteText,
} from './styledItems';

const PinnedNoteModal = (props) => {
  const {
    content,
    onNoteChange,
    closeModal,
    isVisible,
    // lastUpdateString,
    objectTypeName,
    theme,
  } = props;

  const [noteText, setNoteText] = useState(null);

  useEffect(() => {
    const text = content === '<p></p>' ? '' : content;
    setNoteText(text);
  }, [content, isVisible]);

  const onSave = useCallback(() => {
    onNoteChange(noteText);
    closeModal();
  }, [noteText]);

  const clearNote = useCallback(() => {
    setNoteText('');
  }, []);

  // const renderValue = useCallback((value) => (
  //   <NoteText>
  //     {createHtmlMarkup(value)}
  //   </NoteText>
  // ), []);

  return (
    <Modal
      theme={theme}
      width={550}
      visible={isVisible}
      onCancel={closeModal}
      onOk={onSave}
      okText="Save"
      destroyOnClose
      title="Pinned Note"
      footer={[
        <Button
          variant="secondary"
          onClick={clearNote}
          icon="trash"
        >
          Clear note
        </Button>,
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={onSave}
        >
          Save
        </Button>,
      ]}
    >
      <Typography variant="body">
        {`Pinned notes will show every time this ${objectTypeName} is viewed.`}
      </Typography>
      <Typography variant="body">
        Clear note to not show it.
      </Typography>

      <TextWrapper>
        <Textfield
          textarea
          // richTextEditor
          onChange={setNoteText}
          value={noteText}
          // placeholder={'<p style="cursor: pointer;">Enter note text...</p>'}
          // renderValue={renderValue}
        />
      </TextWrapper>

      {/* <Extra>
        <Typography variant="body" color="descriptor-text">
          {lastUpdateString}
        </Typography>

        <Button
          variant="tertiary"
          onClick={clearNote}
          icon="trash"
        >
          Clear note
        </Button>
      </Extra> */}
    </Modal>
  );
};

PinnedNoteModal.defaultProps = {
  content: null,
  isVisible: false,
  lastUpdateString: 'Last edited by',
  theme: null,
};

const {
  string,
  bool,
  func,
  shape,
} = PropTypes;

PinnedNoteModal.propTypes = {
  content: string,
  isVisible: bool,
  lastUpdateString: string,
  onNoteChange: func.isRequired,
  closeModal: func.isRequired,
  objectTypeName: string.isRequired,
  theme: shape(),
};

export default PinnedNoteModal;
