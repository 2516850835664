import requests from 'api/requests';

import setFulfillmentAttachments from './setFulfillmentAttachments';

const getFulfillmentAttachments = (fulfillmentId) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(11)(fulfillmentId);
    dispatch(setFulfillmentAttachments(fulfillmentId, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentAttachments;
