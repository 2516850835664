import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyContact from './PropertyContact';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  const isMyListing = property?.listing_agent === 0;

  return {
    isMyListing,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContact);
