import { createSelector } from 'reselect';

const leads = (state) => state.crmLeads.leads;
const selectedLead = (state) => state.crmLeads.selectedLead;

export default createSelector(
  leads,
  selectedLead,
  (items, itemId) => {
    if (!items[itemId]) return null;

    const owner = items[itemId].owners?.employee?.[0];

    return owner;
  },
);
