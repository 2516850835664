import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import OfferNotesModal from 'shared/components/PinnedNote/PinnedNoteModal';
import OfferDetailsHeader from './OfferDetailsHeader';
import OfferDetailsContacts from './OfferDetailsContacts';
import OfferDetailsMainInformation from './OfferDetailsMainInformation';
import Timer from './Timer';
import Delimiter from './Delimiter';

import {
  Root,
} from './styledItems';

const OfferDetails = (props) => {
  const {
    userRole,
    amount,
    isAllCash,
    isNoContingency,
    relatedContacts,
    expiryDate,
    offerId,
    closingDate,
    contigencyPeriod,
    contingencies,
    propertyPrice,
    specialConditions,
    moveOutDate,
    notes,
    onNoteChange,
    toolbar,
    shouldShowAttachments,
  } = props;

  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);

  const openNotesModal = useCallback((event) => {
    event.preventDefault();
    setIsNotesModalVisible(true);
  }, []);

  const closeNotesModal = useCallback(() => {
    setIsNotesModalVisible(false);
  }, []);

  return (
    <Root>
      <Flex
        spacing={2}
        flexWrap="wrap"
      >
        <Flex
          spacing={2}
          fullWidth
          justifyContent="space-between"
        >
          <OfferDetailsHeader
            userRole={userRole}
            amount={amount}
            isAllCash={isAllCash}
            isNoContingency={isNoContingency}
            propertyPrice={propertyPrice}
          />
          {
            toolbar
          }
        </Flex>

        <Flex
          fullWidth
          justifyContent="space-between"
        >
          <OfferDetailsContacts
            relatedContacts={relatedContacts}
          />
          <Timer
            date={expiryDate}
          />
        </Flex>

        <FlexItem fullWidth>
          <Delimiter />
        </FlexItem>

        <FlexItem fullWidth>
          <OfferDetailsMainInformation
            offerId={offerId}
            closingDate={closingDate}
            contigencyPeriod={contigencyPeriod}
            contingencies={contingencies}
            specialConditions={specialConditions}
            moveOutDate={moveOutDate}
            openNotesModal={openNotesModal}
            hasNote={notes}
            shouldShowAttachments={shouldShowAttachments}
          />
        </FlexItem>

        <FlexItem fullWidth>
          <OfferNotesModal
            content={notes}
            onNoteChange={onNoteChange}
            closeModal={closeNotesModal}
            isVisible={isNotesModalVisible}
            objectTypeName="Offer"
          />
        </FlexItem>
      </Flex>
    </Root>
  );
};

OfferDetails.defaultProps = {
  userRole: null,
  amount: null,
  isAllCash: false,
  isNoContingency: false,
  expiryDate: null,
  offerId: null,
  closingDate: null,
  contigencyPeriod: null,
  propertyPrice: null,
  moveOutDate: null,
  notes: null,
  shouldShowAttachments: true,
};

const {
  arrayOf,
  bool,
  element,
  func,
  number,
  string,
} = PropTypes;

OfferDetails.propTypes = {
  userRole: string,
  amount: number,
  isAllCash: bool,
  isNoContingency: bool,
  relatedContacts: arrayOf(element).isRequired,
  expiryDate: string,
  offerId: number,
  closingDate: string,
  contigencyPeriod: string,
  contingencies: arrayOf(string).isRequired,
  propertyPrice: number,
  specialConditions: arrayOf(string).isRequired,
  toolbar: element.isRequired,
  moveOutDate: string,
  notes: string,
  onNoteChange: func.isRequired,
  shouldShowAttachments: bool,
};

export default OfferDetails;
