import {
  SET_PROPERTY_VIEWING_EDIT_FORM_FIELD,
} from 'store/actionTypes/propertyViewings';

const setEditPropertyViewingFormField = (key) => (value) => ({
  type: SET_PROPERTY_VIEWING_EDIT_FORM_FIELD,
  payload: {
    key,
    value,
  },
});

export default setEditPropertyViewingFormField;
