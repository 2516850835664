import requests from 'api/requests';

import setOutOfStockOrder from './setOutOfStockOrder';

const get = (fulfillmentId) => async (dispatch) => {
  try {
    const response = await requests.warehouse.getOutOfStockOrder(fulfillmentId);

    dispatch(setOutOfStockOrder(fulfillmentId)(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default get;
