import getNextYearlyPayment from './getNextYearlyPayment';
import getPreviousMonthlyPayment from './getPreviousMonthlyPayment';
import getNextMonthlyPayment from './getNextMonthlyPayment';
import setBillingPeriodType from './setBillingPeriodType';
import getLicenseUpdateTotal from './getLicenseUpdateTotal';
import getInvoices from './getInvoices';

export default {
  getNextYearlyPayment,
  getPreviousMonthlyPayment,
  getNextMonthlyPayment,
  setBillingPeriodType,
  getLicenseUpdateTotal,
  getInvoices,
};
