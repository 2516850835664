import { connect } from 'react-redux';

import selectors from 'store/selectors';

import TabLabel from 'shared/components/UI/TabLabel';

const mapStateToProps = (state, ownProps) => {
  const { tabKey } = ownProps;

  const summary = selectors.followUpReminders.overallSummary(state);

  const summaryValue = summary[tabKey] || 0;

  return {
    chipValue: String(summaryValue),
  };
};

export default connect(mapStateToProps)(TabLabel);
