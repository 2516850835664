import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import AccordionRoot from 'shared/components/UI/AccordionRoot';

import RequestItemSummary from 'sections/Transactions/components/RequestItemSummary';
import RequestItemStatusBar from 'sections/Transactions/components/RequestItemStatusBar';
import RequestItemDetails from 'sections/Transactions/components/RequestItemDetails';

const noOp = () => {};

const RequestItem = (props) => {
  const {
    contactId,
    contactRole,
    title,
    description,
    createdAt,
    status,
    statusText,
    response,
    responseCreatedAt,
    onSubmit,
    onAcknowledge,

    attachments,
  } = props;

  const statusBar = statusText
    ? (
      <RequestItemStatusBar
        status={status}
      >
        {statusText}
      </RequestItemStatusBar>
    )
    : (
      <></>
    );

  return (
    <AccordionRoot
      summary={(
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RequestItemSummary
              contactId={contactId}
              title={title}
              contactRole={contactRole}
            />
          </Grid>
          {
            statusText && (
              <Grid item xs={12}>
                {statusBar}
              </Grid>
            )
          }
        </Grid>
      )}
      summaryExpanded={statusBar}
      details={(
        <RequestItemDetails
          contactId={contactId}
          contactRole={contactRole}
          title={title}
          description={description}
          createdAt={createdAt}
          status={status}
          response={response}
          responseCreatedAt={responseCreatedAt}
          onSubmit={onSubmit}
          onAcknowledge={onAcknowledge}
          attachments={attachments}
        />
      )}
      onExpand={noOp}
    />
  );
};

RequestItem.defaultProps = {
  attachments: null,
  contactId: null,
  contactRole: null,
  createdAt: null,
  description: null,
  response: null,
  responseCreatedAt: null,
  status: null,
  statusText: null,
  title: null,
};

const {
  element,
  func,
  number,
  string,
} = PropTypes;

RequestItem.propTypes = {
  attachments: element,
  contactId: number,
  contactRole: string,
  createdAt: string,
  description: string,
  onAcknowledge: func.isRequired,
  onSubmit: func.isRequired,
  response: string,
  responseCreatedAt: string,
  status: string,
  statusText: string,
  title: string,
};

export default RequestItem;
