/* eslint-disable react/require-default-props */

import React from 'react';
import { meetingType } from 'oa/types/index';
import CopyText from 'oa/components/CopyText/index';
import { meetingPath } from 'oa/helpers/routes';

const InfoToCopy = (props) => {
  const {
    meeting,
  } = props;

  const meetingCode = meeting ? meeting.code : '';
  const meetingPassword = meeting ? meeting.password : null;
  const meetingLink = `${window.location.host}${meetingPath(meetingCode)}`;

  return (
    <div className="meeting-details-group meeting-details-modal__group">
      <h3 className="meeting-details-group__header">
        Meeting Link and Password
      </h3>

      <ul className="meeting-details-group__list">
        <li className="meeting-details-group__item">
          <CopyText text={meetingLink} />
        </li>

        {meetingPassword && (
          <li className="meeting-details-group__item">
            <CopyText text={meetingPassword} />
          </li>
        )}
      </ul>
    </div>
  );
};

InfoToCopy.propTypes = {
  meeting: meetingType,
};

export default InfoToCopy;
