import getMeetings from './getMeetings';
import createMeeting from './createMeeting';
import getMeeting from './getMeeting';
import updateMeeting from './updateMeeting';
import createDesiredOutcome from './createDesiredOutcome';
import updateDesiredOutcome from './updateDesiredOutcome';
import createInvitations from './createInvitations';

export default {
  getMeetings,
  createMeeting,
  getMeeting,
  updateMeeting,
  createDesiredOutcome,
  updateDesiredOutcome,
  createInvitations,
};
