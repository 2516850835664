import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Divider from 'ui/Divider';

import PropertyPriceInput from 'sections/Properties/modules/PropertyPriceInput';
import PropertyTypeSelect from 'sections/Properties/modules/PropertyTypeSelect';
import PropertySaleTypeSelect from 'sections/Properties/modules/PropertySaleTypeSelect';
import PropertyAddressInput from 'sections/Properties/modules/PropertyAddressInput';
import PropertyParametersMain from 'sections/Properties/modules/PropertyParametersMain';

const SellerLead = (props) => {
  const {
    propertyId,
  } = props;

  return (
    <Flex
      flexDirection="column"
      fullWidth
      spacing={3}
    >
      <Typography
        variant="title2"
        noMargin
      >
        Seller Details
      </Typography>

      <FlexItem fullWidth>
        <PropertyAddressInput
          propertyId={propertyId}
          defaultView="edit"
          placeholder="Enter seller property address"
        />
      </FlexItem>

      <Flex
        fullWidth
        spacing={1}
      >
        <PropertyTypeSelect
          propertyId={propertyId}
        />
        <PropertySaleTypeSelect
          propertyId={propertyId}
        />
      </Flex>

      <Divider />

      <FlexItem>
        <PropertyParametersMain
          propertyId={propertyId}
          defaultView="display"
        />
      </FlexItem>

      <Divider />

      <FlexItem fullWidth>
        <PropertyPriceInput
          propertyId={propertyId}
          defaultView="edit"
          label="Proposed list price"
        />
      </FlexItem>

      <FlexItem fullWidth>
        <PropertyPriceInput
          propertyId={propertyId}
          defaultView="edit"
          priceType="price_avn"
          label="Market $AVN"
        />
      </FlexItem>

      <FlexItem fullWidth>
        <PropertyPriceInput
          propertyId={propertyId}
          defaultView="edit"
          priceType="price_ideal"
          label="Seller ideal list price"
        />
      </FlexItem>

    </Flex>
  );
};

const {
  number,
} = PropTypes;

SellerLead.propTypes = {
  propertyId: number.isRequired,
};

export default SellerLead;
