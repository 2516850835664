import requests from 'api/requests';

import setOrders from './setOrders';

const get = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.orders.get({
      offset,
      limit,
    });

    dispatch(setOrders(response.data.orders));
  } catch (error) {
    console.error(error);
  }
};

export default get;
