import { SET_INVITED_EMAILS } from 'store/actionTypes/call';

const setInvitedEmails = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVITED_EMAILS,
    payload,
  });
};

export default setInvitedEmails;
