import moment from 'moment';

import { createSelector } from 'reselect';

import {
  find,
  isUndefined,
} from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.propertyViewings.viewings,
  (_, id) => id,
  (_, id, contactId = null) => contactId,
  (
    properties,
    viewings,
    propertyId,
    contactId,
  ) => {
    const property = properties[propertyId];

    if (!property) {
      if (propertyId) {
        optimizedPropertyActions.getPropertyById(propertyId);
      }

      return null;
    }

    const numOfEndedEvents = property?.viewings_summary?.total_viewings
      ? property.viewings_summary.total_viewings - property.viewings_summary.upcoming_viewings
      : 0;

    if (numOfEndedEvents < 1) {
      return null;
    }

    const endedEvents = (Array.isArray(property.viewings) && property.viewings.reduce((accum, viewingId) => {
      const viewing = viewings[viewingId];

      if (!viewing) {
        if (viewingId) {
          optimizedPropertyViewingActions.getPropertyViewingById(viewingId);
        }

        return accum;
      }

      if (moment(viewing.date_time).isAfter(moment())) {
        return accum;
      }

      if (contactId) {
        const searchedContactId = find(viewing.contacts);

        if (!isUndefined(searchedContactId)) {
          return accum;
        }
      }

      accum.push(viewing);
      return accum;
    }, [])) || [];

    let lastEvent;

    endedEvents.forEach((viewing) => {
      const {
        date_time,
      } = viewing;

      if (lastEvent) {
        if (moment(date_time).isAfter(moment(lastEvent.date_time))) {
          lastEvent = viewing;
        }
      } else {
        lastEvent = viewing;
      }
    });

    return lastEvent;
  },
);
