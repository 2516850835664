import { callAlert } from 'ui/Alert';

import newApiConnector from 'api';

// TODO - fix dependency cycles
// eslint-disable-next-line import/no-cycle
import LicenseRepository from 'shared/repositiores/myCompany/license';
import { LICENSE_SUCCESS } from 'constants/license-management.constants';
// eslint-disable-next-line import/no-cycle
import { apiConnector } from 'shared/API';

import {
  getLicensesToBeRevoked,
} from 'store/actions/invoices';
// eslint-disable-next-line import/no-cycle
// import { getNextPayment } from '../billing';

const licenseTransactionLog = '/company/employees2licenseLog';
const companyTransactionLog = '/company/company2licenseLog';

export const GET_LICENSES = 'GET_LICENSES';
export const getLicenses = () => (dispatch) => {
  dispatch({ type: GET_LICENSES });

  LicenseRepository.getLicenses()
    .then((res) => {
      const licenses = res.data.map((license) => ({
        id: license.LicenceID,
        name: license.LicenceName,
        price: license.PricePerUser,
        priceYear: license.PricePerUserYear,

        discountPercent: license.Discount || null,
        discountedPrice: license.DiscountedPrice,
        discountedPriceYear: license.DiscountedPriceYear,

        discount: license.Discount
          ? license.PricePerUser * license.DiscountPercent
          : null,

        discountYear: license.Discount
          ? license.PricePerUserYear * license.DiscountPercent
          : null,

        total: license.Employees,
        available: license.Available,
        used: license.Employees - license.Available,
        permission: license.permission,

        StatusName: license.StatusName === 'Coming soon'
          ? 'Coming Soon'
          : license.StatusName,

        // DiscountPercent: license.DiscountPercent || null,
        initialFree: license.InitialFree,
        licenseMonthlyPrice: (license.Employees - license.InitialFree) * license.PricePerUser,
        availableFree: (
          license.InitialFree - (license.Employees - license.Available) > 0
            ? license.InitialFree - (license.Employees - license.Available) : 0
        ),
      }));

      return licenses;
    })
    .then((licenses) => dispatch(getLicensesSuccess(licenses)))
    .catch(() => dispatch(getLicensesError()));
};

export const GET_LICENSES_SUCCESS = 'GET_LICENSES_SUCCESS';
export const getLicensesSuccess = (licenses) => ({
  type: GET_LICENSES_SUCCESS,
  licenses,
});

export const GET_LICENSES_ERROR = 'GET_LICENSES_ERROR';
export const getLicensesError = () => ({
  type: GET_LICENSES_ERROR,
});

export const OPEN_LICENSES_MODAL = 'OPEN_LICENSES_MODAL';
export const openLicensesModalAction = (data) => ({
  type: OPEN_LICENSES_MODAL,
  payload: data,
});

export const CLOSE_LICENSES_MODAL = 'CLOSE_LICENSES_MODAL';
export const closeLicensesModal = () => ({
  type: CLOSE_LICENSES_MODAL,
});

export const OPEN_LICENSES_MODAL_SUCCESS = 'OPEN_LICENSES_MODAL_SUCCESS';
export const openLicensesModalSuccessAction = (data) => ({
  type: OPEN_LICENSES_MODAL_SUCCESS,
  payload: data,
});

export const BUY_LICENSE = 'BUY_LICENSE';
export const buyLicense = ({
  licenses,
  card,
  form,
  closeModal = null,
}) => (dispatch) => {
  dispatch({ type: BUY_LICENSE });

  LicenseRepository.buyLicense(licenses, card)
    .then(() => {
      dispatch(buyLicenseSuccess(form, closeModal));
      callAlert.success(LICENSE_SUCCESS);
    })
    .catch(() => dispatch(buyLicenseError()));
};

export const BUY_LICENSE_SUCCESS = 'BUY_LICENSE_SUCCESS';
export const buyLicenseSuccess = (form, closeModal) => (dispatch) => {
  dispatch({ type: BUY_LICENSE_SUCCESS });
  form.resetFields();
  dispatch(getLicenses());

  if (closeModal) {
    closeModal();
  }
};

export const BUY_LICENSE_ERROR = 'BUY_LICENSE_ERROR';
export const buyLicenseError = () => ({ type: BUY_LICENSE_ERROR });

export const openLicensesModal = (data) => (dispatch) => {
  dispatch(openLicensesModalAction(data));
  // dispatch(openLicensesModalSuccessAction());
};

export const updateLicenses = (data) => async (dispatch) => {
  const endpoint = '/company/license/buy';

  try {
    await newApiConnector.client.post(endpoint, data);
    dispatch(closeLicensesModal());
    dispatch(getLicenses());
    dispatch(getLicensesToBeRevoked());
    //  dispatch(getLicenseCompanyLog());
  } catch (error) {
    console.error(error);
  }
};

export const SHOW_LICENSE_TRANSACTION_LOG = 'SHOW_LICENSE_TRANSACTION_LOG';
export const showLicensetransactionLog = () => ({ type: SHOW_LICENSE_TRANSACTION_LOG });

// get license management transaction logs
export const GET_LICENSE_MANAGEMENT_LOG = 'GET_LICENSE_MANAGEMENT_LOG';
export const GET_LICENSE_MANAGEMENT_LOG_SUCCESS = 'GET_LICENSE_MANAGEMENT_LOG_SUCCESS';
export const GET_LICENSE_MANAGEMENT_LOG_ERROR = 'GET_LICENSE_MANAGEMENT_LOG_ERROR';

export const getLicenseManagementLog = (date = { dateFrom: '', dateTo: '' }) => (dispatch) => {
  dispatch(getLicenseManagementLogAction);
  apiConnector(`${licenseTransactionLog}?dateFrom=${date.dateFrom}&dateTo=${date.dateTo}`, 'GET')
    .then((res) => dispatch(getLicenseManagementLogSuccessAction(res.data)))
    .catch((err) => dispatch(getLicenseManagementLogErrorAction(err)));
};

export const getLicenseManagementLogAction = () => ({
  type: GET_LICENSE_MANAGEMENT_LOG,
});

export const getLicenseManagementLogSuccessAction = (data) => ({
  type: GET_LICENSE_MANAGEMENT_LOG_SUCCESS,
  payload: data,
});

export const getLicenseManagementLogErrorAction = (data) => ({
  type: GET_LICENSE_MANAGEMENT_LOG_ERROR,
  payload: data,
});

// get license company transaction logs
export const GET_LICENSE_COMPANY_LOG = 'GET_LICENSE_COMPANY_LOG';
export const GET_LICENSE_COMPANY_LOG_SUCCESS = 'GET_LICENSE_COMPANY_LOG_SUCCESS';
export const GET_LICENSE_COMPANY_LOG_ERROR = 'GET_LICENSE_COMPANY_LOG_ERROR';

export const getLicenseCompanyLog = (date = { dateFrom: '', dateTo: '' }) => (dispatch) => {
  dispatch(getLicenseCompanyLogAction);
  apiConnector(`${companyTransactionLog}?dateFrom=${date.dateFrom}&dateTo=${date.dateTo}`, 'GET')
    .then((res) => dispatch(getLicenseCompanyLogSuccessAction(res.data)))
    .catch((err) => dispatch(getLicenseCompanyLogErrorAction(err)));
};

export const getLicenseCompanyLogAction = () => ({
  type: GET_LICENSE_COMPANY_LOG,
});

export const getLicenseCompanyLogSuccessAction = (data) => ({
  type: GET_LICENSE_COMPANY_LOG_SUCCESS,
  payload: data,
});

export const getLicenseCompanyLogErrorAction = (data) => ({
  type: GET_LICENSE_COMPANY_LOG_ERROR,
  payload: data,
});
