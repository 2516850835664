import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Input,
} from 'antd';

import RichTextEditor from 'shared/components/RichTextEditor';
import ArticleControls from 'shared/components/ArticleControls';

import {
  EditableTextContainer,
  RichTextEditorContainer,
} from './styledItems';

const { TextArea } = Input;

const rootClass = 'editable-text-field';

class EditableTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      value: props.value || '',
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      value,
    } = this.props;

    const { isEditMode } = this.state;

    if (value !== prevProps.value) {
      this.setState({ value });
    }

    if (isEditMode && prevState.isEditMode !== isEditMode && this.inputRef) {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
  }

  setEditMode = () => {
    const isTextSelect = window.getSelection().toString().length > 0;

    if (isTextSelect) {
      return;
    }

    const {
      isEditMode,
    } = this.state;

    if (!isEditMode) {
      this.setState({ isEditMode: true });
    }
  };

  onCancel = () => {
    const {
      value,
    } = this.props;

    this.setState({
      isEditMode: false,
      value: value || '',
    });
  };

  onConfirm = async () => {
    const {
      onConfirm,
    } = this.props;

    const {
      value,
    } = this.state;

    if (onConfirm) {
      onConfirm(value);
    }

    this.setState({ isEditMode: false });
  };

  onChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleRichText = (content) => {
    const {
      onConfirm,
    } = this.props;
    this.setState({ isEditMode: false });

    if (onConfirm) {
      onConfirm(content);
    }
  }

  render() {
    const {
      className,
      placeholder,
      renderValue,
      richTextEditor,
      isFullRichEditor,
      isBordered,
    } = this.props;

    const {
      isEditMode,
      value,
    } = this.state;

    const renderedValue = value && value.slice(3, -4) ? value : placeholder;

    const displayValue = renderValue
      ? <div className="displayed-value">{renderValue(renderedValue)}</div>
      : <p className="displayed-value">{renderedValue}</p>;

    const fullTextEditor = (
      <RichTextEditorContainer // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, max-len
        onClick={this.setEditMode}
        onKeyDown={this.setEditMode}
        className={classnames(rootClass, {
          [className]: className,
        })}
        isBordered={isBordered}
      >
        {isEditMode
          ? (
            <div>

              <RichTextEditor
                isFullRichEditor={isFullRichEditor}
                onClickPost={this.handleRichText}
                onClickCancel={this.onCancel}
                value={value}
                ControlsComponent={ArticleControls}
              />

            </div>
          )
          : displayValue}
      </RichTextEditorContainer>
    );

    const simpleTextEditor = (
      <EditableTextContainer // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, max-len
        onClick={this.setEditMode}
        onKeyDown={this.setEditMode}
        showEditor={isEditMode}
        className={classnames(rootClass, {
          [className]: className,
        })}
        isBordered={isBordered}
      >
        <div className="text-editor">
          <TextArea
            autoSize={{ minRows: 1, maxRows: 4 }}
            onChange={this.onChange}
            value={value}
            ref={this.inputRef}
          />
          <ArticleControls
            onCancel={this.onCancel}
            onSubmit={this.onConfirm}
          />
        </div>
        {displayValue}
      </EditableTextContainer>
    );

    const textEditor = richTextEditor ? fullTextEditor : simpleTextEditor;

    return textEditor;
  }
}

EditableTextField.defaultProps = {
  className: '',
  placeholder: null,
  richTextEditor: false,
  value: null,
  isFullRichEditor: true,
  isBordered: false,
};

const {
  bool,
  func,
  string,
} = PropTypes;

EditableTextField.propTypes = {
  className: string,
  onConfirm: func.isRequired,
  placeholder: string,
  renderValue: func.isRequired,
  richTextEditor: bool,
  value: string,
  isFullRichEditor: bool,
  isBordered: bool,
};

export default EditableTextField;
