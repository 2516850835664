import { SHOW_ALL_PANELS, SET_PANELS } from '../actions/dashboard-list-view';

const initialState = {
  tablesList: [],
  tablesData: [],
  resolveModal: {
    isOpen: false,
    isLoading: false,
  },
  panels: [],
};

function dashboardListView(state = initialState, action) {
  switch (action.type) {
    case SHOW_ALL_PANELS:
      return { ...state, panels: state.tablesData.map((table) => table.index.toString()) };
    case SET_PANELS:
      return { ...state, panels: action.panels };
    default:
      return state;
  }
}

export default dashboardListView;
