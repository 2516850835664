import connectReducers from 'store/connectReducers';

import {
  SET_PHONE_NUMBER,
  SET_PHONE_NUMBERS,
} from 'store/actionTypes/phoneNumbers';

const initialState = {
  phoneNumbers: {},
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_PHONE_NUMBER]: (state, action) => ({
    ...state,
    phoneNumbers: {
      ...state.phoneNumbers,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_PHONE_NUMBERS]: (state, action) => ({
    ...state,
    phoneNumbers: {
      ...state.phoneNumbers,
      ...listToObject(action.payload),
    },
  }),

};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
