import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updatePhoneNumber,
  createPhoneNumber,
} from 'store/actions/phoneNumbers';

import {
  addContactRelations,
  removeContactRelations,
} from 'store/actions/realtorContacts';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';
import { formatPhoneNumber } from 'shared/utils/phoneNumbers';

import {
  isPossiblePhoneNumber,
} from 'libphonenumber-js';

import MultipleValuesInput from 'ui/MultipleValuesInput';
import PhoneNumberInput from 'ui/PhoneNumberInput';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contactPhoneNumbers = selectors.realtorContacts.contactPhoneNumbers(state, contactId);

  const values = contactPhoneNumbers.map((item) => ({
    id: item.phone_number_id,
    value: item.e164_format,
    isPrimary: !!item.is_primary,
  }));

  return {
    values,
    formatDisplayValue: formatPhoneNumber,
    dataTypeDisplayName: 'phone number',
    InputComponent: PhoneNumberInput,
    inputProps: { label: null },
    placeholder: 'Phone',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: debounce((id, value) => {
    if (value && typeof value === 'string' && isPossiblePhoneNumber(value, 'US')) {
      dispatch(updatePhoneNumber(id)({ e164_format: value }));
    }
  }, 1000),
  onMakePrimary: async (phoneNumberId, setIsLoading) => {
    setIsLoading(true);

    try {
      await dispatch(addContactRelations('phoneNumbers')(ownProps.contactId)({
        relationActionType: 'makePhoneNumberPrimary',
        phoneNumberId,
      }));

      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }

    setIsLoading(false);
  },
  onRemove: async (phoneNumberId, setIsLoading) => {
    setIsLoading(true);

    try {
      await dispatch(removeContactRelations('phoneNumbers')(ownProps.contactId)({ phoneNumberId }));

      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }

    setIsLoading(false);
  },
  onCreate: async (value, setIsLoading) => {
    if (value && typeof value === 'string' && isPossiblePhoneNumber(value, 'US')) {
      setIsLoading(true);

      try {
        const phoneNumberId = await dispatch(createPhoneNumber(value));

        if (phoneNumberId) {
          await dispatch(addContactRelations('phoneNumbers')(ownProps.contactId)({
            relationActionType: 'addOnePhoneNumber',
            phoneNumberId,
          }));
        }

        callAlert.success('Updated successfully!');
      } catch (err) {
        callAlert.error('Could not update!');
      }

      setIsLoading(false);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleValuesInput);
