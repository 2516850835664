import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import {
  ButtonWrapper,
  InnerWrapper,
} from './styledItems';

const KPIItemInteractive = (props) => {
  const {
    value,
    title,
    iconName,
    iconType,
    iconColor,

    disabled,
    selected,
    onClick,
    noWrap,
  } = props;

  return (
    <ButtonWrapper
      onClick={onClick}
      disabled={disabled}
    >
      <InnerWrapper
        rounded="m"
        selected={selected}
        disabled={disabled}
        noElevation={disabled}
      >
        <Flex spacing={3} alignItems="center">
          {
            iconName && (
              <Icon
                color={iconColor}
                name={iconName}
                type={iconType}
                base="circle"
                baseColor={disabled ? 'background-flat' : 'contrast'}
                size="l"
              />
            )
          }
          <Flex spacing={1} alignItems="center">
            <Typography
              variant="title1"
              tag="p"
              noMargin
              color="main-text"
            >
              {value}
            </Typography>

            <Typography
              variant="title2"
              noMargin
              color="main-text"
              noWrap={noWrap}
            >
              {title}
            </Typography>
          </Flex>
        </Flex>
      </InnerWrapper>
    </ButtonWrapper>
  );
};

KPIItemInteractive.defaultProps = {
  iconName: null,
  iconType: undefined,
  iconColor: 'focus',

  onClick: () => {},
  selected: false,
  disabled: false,
  noWrap: false,
};

const {
  bool,
  func,
  number,
  oneOfType,
  string,
} = PropTypes;

KPIItemInteractive.propTypes = {
  value: oneOfType([string, number]).isRequired,
  title: string.isRequired,
  iconName: string,
  iconType: string,
  iconColor: string,

  selected: bool,
  disabled: bool,
  onClick: func,
  noWrap: bool,
};

export default KPIItemInteractive;
