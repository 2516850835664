import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedOfferActions,
} from 'store/actions/realtorOffers';

export default createSelector(
  (state) => state.realtorOffers.offersViewItems,
  (state) => state.realtorOffers.offers,
  (offersViewItems, offersCollection) => {
    const actionColumnItems = [];
    const awaitingResponseColumnItems = [];

    offersViewItems.forEach(({
      id,
      type,
      contact_id,
      realtor_property_id,
      listing_price,
      offers,
    }) => {
      if (type === 'client') {
        const actionColumnItem = {
          id,
          opportunityType: 'client',
          key: `client-${id}`,
          contact_id,
          realtor_property_id,
          price: listing_price,
          counters: [],
        };
        const awaitingResponseItem = {
          id,
          opportunityType: 'client',
          key: `client-${id}`,
          contact_id,
          realtor_property_id,
          price: listing_price,
          counters: [],
        };

        let hasItemsForActionColumn = false;
        let hasItemsForAwaitingResponseColumn = false;

        offers.forEach(({ offerId, rootOfferId }) => {
          const offer = offersCollection[offerId];

          if (!offer) {
            if (offerId) {
              optimizedOfferActions.getOfferById(offerId);
            }
            return;
          }

          if (offer.declined_at) {
            return;
          }

          if (offer.type === 1) {
            actionColumnItem.counters.push({ offerId, rootOfferId });
            hasItemsForActionColumn = true;
          } else {
            awaitingResponseItem.counters.push({ offerId, rootOfferId });
            hasItemsForAwaitingResponseColumn = true;
          }
        });

        if (hasItemsForActionColumn) {
          actionColumnItems.push(actionColumnItem);
        }
        if (hasItemsForAwaitingResponseColumn) {
          awaitingResponseColumnItems.push(awaitingResponseItem);
        }
      }

      if (type === 'prospect') {
        const columnItem = {
          id,
          opportunityType: 'prospect',
          contact_id,
        };

        offers.forEach(({ offerId, rootOfferId }) => {
          const offer = offersCollection[offerId];

          if (!offer) {
            if (offerId) {
              optimizedOfferActions.getOfferById(offerId);
            }
            return;
          }

          if (offer.declined_at) {
            return;
          }

          if (offer.type === 2) {
            actionColumnItems.push({
              ...columnItem,
              realtor_property_id: offer.realtor_property_id,
              key: `prospect-${id}-offer-${offerId}`,
              counters: [{ offerId, rootOfferId }],
            });
          } else {
            awaitingResponseColumnItems.push({
              ...columnItem,
              realtor_property_id: offer.realtor_property_id,
              key: `prospect-${id}-offer-${offerId}`,
              counters: [{ offerId, rootOfferId }],
            });
          }
        });
      }
    });

    return {
      actionColumnItems,
      awaitingResponseColumnItems,
    };
  },
);
