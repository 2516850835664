import React from 'react';
import PropTypes from 'prop-types';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import { List } from './styledItems';

const BulletedList = ({
  content,
  bulletSize,
}) => {
  if (Array.isArray(content)) {
    return (
      <List>
        {
          content.map((item) => (
            <li>
              <DecoratedFieldWrapper
                iconName="circle"
                variant="focus"
                size={bulletSize}
                iconWrapperJustify="flex-start"
              >
                {item}
              </DecoratedFieldWrapper>
            </li>
          ))
        }
      </List>
    );
  }

  return content;
};

BulletedList.defaultProps = {
  content: '',
  bulletSize: 'xxxs',
};

const {
  oneOfType,
  string,
  arrayOf,
} = PropTypes;

BulletedList.propTypes = {
  content: oneOfType([string, arrayOf(string)]),
  bulletSize: string,
};

export default BulletedList;
