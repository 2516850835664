import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import DeviceSetup from 'sections/Calls/modules/DeviceSetup';

const AddPhoneNumber = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
  } = props;

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      theme={theme}
      title="Device Settings"
      width={800}
    >
      <DeviceSetup />
    </Modal>
  );
};

AddPhoneNumber.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
} = PropTypes;

AddPhoneNumber.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
};

export default AddPhoneNumber;
