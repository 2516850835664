import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.realtorOpportunities.opportunities,
  (state, filterId) => state.realtorOpportunities.realtorOpportunitiesByFilter[filterId],
  (items, filteredIds) => (filteredIds
    ? filteredIds.reduce((acc, id) => {
      const item = items[id];

      acc.push({
        ...item,
        key: id,
      });
      return acc;
    }, [])
    : []),
);
