import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'ui/Icon';

import './TicketIcons.scss';

const rootClass = 'ticket-icons-root';

const icons = {
  action: {
    name: 'film',
    color: 'focus'
  },
  problem: {
    name: 'alert-triangle',
    color: 'alert'
  },
  question: {
    name: 'help-circle',
    color: 'warning'
  },
};

const TicketIcons = (props) => {
  const {
    className,
    events,
    onClick,
    size,
    spacing,
  } = props;

  const eventKeys = Object.keys(events);

  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, max-len
      className={classnames(
        rootClass,
        {
          [`${rootClass}--clickable`]: onClick,
          [className]: className,
        },
      )}
      onClick={onClick}
    >
      {eventKeys.map((key) => {
        const icon = icons[key];
        const isEventExistsAndTrue = events[key] && icon;
        if (isEventExistsAndTrue) {
          return (
            <Icon
              type="feather"
              name={icon.name}
              color={icon.color}
              size={size}
            />
          );
        } 
        return null;
      })}
    </div>
  );
};

TicketIcons.defaultProps = {
  events: {
    problem: false,
    action: false,
    question: false,
    comment: false,
  },
  spacing: 7,
  className: '',
  onClick: () => { },
};

const {
  number,
  shape,
  bool,
  oneOf,
  oneOfType,
  func,
  string,
} = PropTypes;

const eventShape = shape({
  problem: bool,
  action: bool,
  question: bool,
  comment: bool,
});

TicketIcons.propTypes = {
  events: eventShape,
  size: oneOfType([
    oneOf(['xs', 'sm', 'md', 'lg']),
    number,
  ]),
  spacing: number,
  className: string,
  onClick: func,
};

export default TicketIcons;