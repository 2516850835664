import requests from 'api/requests';

import setInspections from './setInspections';
import setInspectionsForFilter from './setInspectionsForFilter';
import setInspectionsForFilterCount from './setInspectionsForFilterCount';

const getInspectionsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.inspections.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setInspections(response.data.items));
    dispatch(setInspectionsForFilter(filterId)(response.data.items));
    dispatch(setInspectionsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getInspectionsForFilter;
