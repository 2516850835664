import { common } from 'constants';

export const DEPARTMENT_KEY = JSON.stringify({
  name: 'Department',
  apiName: 'departments#DepartmentID',
  params: {
    type: common.INPUT_TYPES.MULTI_SELECT,
  },
});

export const TEAM_KEY = JSON.stringify({
  name: 'Team',
  apiName: 'teams#TeamID',
  params: {
    type: common.INPUT_TYPES.MULTI_SELECT,
  },
});

export const ALL_STATUSES_ON = 'allStatuses=1';

export const ALL_STATUSES_OFF = 'allStatuses=0';

export const COUNTERS = [
  {
    key: 'totalColleagues',
    name: 'Total Colleagues',
  },
  {
    key: 'present',
    name: 'Present',
  },
];

export const DEBOUNCE = 500;

export const STATUS_KEY = 'EmployeeStatusID';

export const NO_RESULTS_MSG = 'No results for this search. Please try again.';
