import styled from 'styled-components';

import Elevation from 'ui/Elevation';

import HouseDefault from 'assets/icons/property.svg';

export const StyledElevation = styled(Elevation)`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
`;

export const InformationWrapper = styled.div`
  width: 100%;
  padding-left: 8px;
  padding-bottom: 4px;
`;

export const Header = styled.div`
  display: flex;
  padding: 4px 8px 2px 0;
  justify-content: space-between;

  .label-schedule-item {
    padding: 1px 12px;
    background-color: #9013fe;
    border-radius: 8px;
  }
`;

export const PropertyPhoto = styled.div`
  width: 54px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
`;
