/* eslint-disable react/jsx-no-bind */

import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import DeclineOfferForm from 'sections/OffersV2/forms/DeclineOfferForm';

import OffersContext from 'sections/OffersV2/context/OffersContext';

const DeclineModal = (props) => {
  const {
    offerId,
    isVisible,
    closeModal,
  } = props;

  const { getAllOffers } = useContext(OffersContext);

  const handleSuccess = async () => {
    await getAllOffers();
    closeModal();
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      title="Decline Offer"
    >
      <DeclineOfferForm
        offerId={offerId}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
};

DeclineModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  number,
} = PropTypes;

DeclineModal.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
  offerId: number.isRequired,
};

export default DeclineModal;
