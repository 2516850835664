import { connect } from 'react-redux';

import { logError } from 'store/actions/errors';

import NotFoundPage from './NotFound';
import InternalErrorPage from './InternalError';
import FilePermissionPage from './FilePermission';

const mapDispatchToProps = {
  reportError: logError,
};

const NotFound = connect(null, mapDispatchToProps)(NotFoundPage);
const InternalError = connect(null, mapDispatchToProps)(InternalErrorPage);
const FilePermission = connect(null, mapDispatchToProps)(FilePermissionPage);

export {
  NotFound,
  InternalError,
  FilePermission,
};
