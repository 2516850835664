import { SET_CHAT } from 'store/actionTypes/chat';

const setChat = (newChatData) => (dispatch) => {
  dispatch({
    type: SET_CHAT,
    payload: {
      newChatData,
    },
  });
};

export default setChat;
