import { connect } from 'react-redux';

const withNewPropertyFormDefaultValue = (Component) => (fieldName) => {
  const mapStateToProps = (state) => {
    const {
      realtorNewPropertyForm,
    } = state;

    return {
      value: realtorNewPropertyForm[fieldName],
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withNewPropertyFormDefaultValue;
