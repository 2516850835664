import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import InlineInput from 'ui/InlineInput';

import { makeStyles } from '@material-ui/core/styles';

import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  popper: {
    width: 'auto !important',
  },
});

const filter = createFilterOptions();

const InlineInputComponent = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <InlineInput
      {...props.inputProps} // eslint-disable-line react/jsx-props-no-spreading, react/prop-types
      onChange={props.onChange} // eslint-disable-line react/prop-types
      placeholder={props.placeholder} // eslint-disable-line react/prop-types
    />
  </div>
));

const AutocompleteWithAdd = ({
  getAddTitle,
  options,
  onAddNewItemClick,
  onItemSelect,
  onInput,
  label,
  CustomItemComponent,
  value,
  disablePortal,
  textFieldType,
  inline,
  placeholder,
}) => {
  const classes = useStyles();

  const onTextfieldInput = useCallback((value) => {
    onInput(value);
  });

  const onChange = useCallback((event, item) => {
    if (typeof item === 'string') {
      // enter pressed
    } else if (item && item.inputValue) {
      // add new item clicked
      onAddNewItemClick(item.inputValue);
    } else {
      // existing item clicked
      onItemSelect(item);
    }
  });

  const filterOptions = useCallback((nonFilteredOptions, params) => {
    const filtered = filter(nonFilteredOptions, params);

    if (!onAddNewItemClick) {
      return filtered;
    }

    if (params.inputValue !== '') {
      filtered.unshift({
        inputValue: params.inputValue,
        title: (
          <Typography color="focus" noMargin>
            {getAddTitle(params.inputValue)}
          </Typography>
        ),
        listItemType: 'addNewItem',
      });
    }

    return filtered;
  });

  const getOptionLabel = useCallback((option) => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.title;
  });

  const renderOption = useCallback((option, state) => {
    if (!CustomItemComponent) {
      return (
        <Typography noMargin>
          {option.title}
        </Typography>
      );
    }

    return (
      <CustomItemComponent
        {...option} // eslint-disable-line react/jsx-props-no-spreading
        selected={state.selected}
        inputValue={state.inputValue}
      />
    );
  });

  const renderInput = useCallback((params) => {
    if (inline) {
      return (
        <InlineInputComponent
          {...params} // eslint-disable-line react/jsx-props-no-spreading
          ref={params.InputProps.ref}
          placeholder={placeholder}
          label={label}
          type={textFieldType}
          onChange={onTextfieldInput}
        />
      );
    }
    return (
      <Textfield
        {...params} // eslint-disable-line react/jsx-props-no-spreading
        ref={params.InputProps.ref}
        type={textFieldType}
        label={label}
        onChange={onTextfieldInput}
      />
    );
  });

  return (
    <>
      <Autocomplete
        freeSolo
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        onChange={onChange}
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput}
        value={value}
        classes={{
          popper: classes.popper,
        }}
        disablePortal={disablePortal}
      />
    </>
  );
};

AutocompleteWithAdd.defaultProps = {
  CustomItemComponent: null,
  getAddTitle: (value) => `+ Add ${value}`,
  label: null,
  onInput: () => {},
  options: [],
  onAddNewItemClick: null,
  value: null,
  textFieldType: 'text',
  variant: 'outlined',
  placeholder: null,
  disablePortal: true,
  inline: false,
};

const {
 arrayOf, func, oneOf, shape, string, bool,
} = PropTypes;

AutocompleteWithAdd.propTypes = {
  /**
   * custom item component
   * accepts as props all parameters from the provided option item + `selected` + `inputValue`
   */
  CustomItemComponent: func,
  /**
   * function to render the add button title
   * `function(newValue) => string`
   */
  getAddTitle: func,
  /**
   * textfield label
   */
  label: string,
  /**
   * on add new item click callback
   * `function(newValue) => void`
   */
  onAddNewItemClick: func,
  /**
   * on text input callback
   * `function(value) => void`
   * will not render the add new item button of not provided
   */
  onInput: func,
  /**
   * on text input callback
   * `function(optionObject) => void`
   */
  onItemSelect: func.isRequired,
  /**
   * autocomplete options
   */
  options: arrayOf(
    shape({
      title: string,
    }),
  ),
  /**
   * value
   */
  value: string,
  /**
   * textfield type
   */
  textFieldType: oneOf(['text', 'tel', 'email']),
  /**
   * material-ui Autocomplete prop
   */
  disablePortal: bool,
  inline: bool,
  placeholder: string,
};

export default AutocompleteWithAdd;
