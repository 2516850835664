import requests from 'api/requests';

import getSourceTypes from './getSourceTypes';

// eslint-disable-next-line consistent-return
const createSourceType = ({ name }) => async (dispatch) => {
  try {
    const response = await requests.crm.sourceTypes.create({ name });

    dispatch(getSourceTypes());

    return response.data.sourceTypeId;
  } catch (error) {
    console.error(error);
  }
};

export default createSourceType;
