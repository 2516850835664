import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  ${(props) => props.fullWidth && 'width: 100%;'}
  > * {
    position: relative;

    &:not(:first-child) {
      /* margin-left: -1px; */
      ${(props) => props.flat && `
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 1px;
          height: 50%;
          transform: translateY(-50%);
          background-color: ${props.theme.palette['input-border']};
          z-index: 2;
        }      
      `}
    }

    &:not(:last-child) {
      /* margin-left: -1px; */
      input {
        border-right: none;
        &:focus {
          border-right: 1px solid ${(props) => {
            if (props.flat) return props.theme.palette['background-flat'];

            return props.theme.palette.focus;
          }};
        }
      }
    }

    &:not(:last-child):not(:only-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child):not(:only-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
`;

export default {
  Root,
};
