import connectReducers from 'store/connectReducers';

import { union, isEmpty } from 'shared/utility';

import {
  SET_REALTOR_FOLLOW_UP_REMINDERS,
  SET_REALTOR_FOLLOW_UP_REMINDER,
  SET_REALTOR_FOLLOW_UP_REMINDERS_FOR_FILTER,
  SET_REALTOR_FOLLOW_UP_REMINDERS_FOR_FILTER_COUNT,
  SET_REALTOR_OVERALL_FOLLOW_UP_REMINDERS_SUMMARY,
} from 'store/actionTypes/followUpReminders';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  reminders: {},
  remindersByFilter: {},
  remindersCountByFilter: {},
  overallRemindersSummary: null,
};

const reducers = {
  [SET_REALTOR_FOLLOW_UP_REMINDER]: (state, action) => ({
    ...state,
    reminders: {
      ...state.reminders,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_FOLLOW_UP_REMINDERS]: (state, action) => ({
    ...state,
    reminders: {
      ...state.reminders,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_FOLLOW_UP_REMINDERS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      reminders,
    } = action.payload;

    if (!reminders || isEmpty(reminders)) {
      return {
        ...state,
        remindersByFilter: {
          ...state.remindersByFilter,
          [filterId]: [],
        },
      };
    }

    const updatedByIds = new Set([
      ...getIds(reminders),
    ]);

    const current = state.remindersByFilter[filterId] || [];

    const updatedState = {
      ...state,
      remindersByFilter: {
        ...state.remindersByFilter,
        [filterId]: union(current, Array.from(updatedByIds)),
      },
    };

    return updatedState;
  },

  [SET_REALTOR_FOLLOW_UP_REMINDERS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    remindersCountByFilter: {
      ...state.remindersCountByFilter,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_OVERALL_FOLLOW_UP_REMINDERS_SUMMARY]: (state, action) => ({
    ...state,
    overallRemindersSummary: action.payload,
  }),
};

function followUpReminders(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default followUpReminders;
