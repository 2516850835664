import requests from 'api/requests';

import setProspect from './setProspect';

const getProspectById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.prospects.getById(id);
    dispatch(setProspect(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getProspectById;
