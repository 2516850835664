import {
  SET_REALTOR_UNANSWERED_QUESTION_ATTACHMENTS,
} from 'store/actionTypes/realtorUnansweredQuestions';

const setAttachments = (id, attachments) => ({
  type: SET_REALTOR_UNANSWERED_QUESTION_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
