import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PRODUCT_ICONS } from 'constants';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup'; // eslint-disable-line import/no-cycle

import './styles.scss';

const rootClass = 'object-link';


/**
 * This file is only a presentation component, the data is handled in index.js
 * @param {Object} props
 */
const ObjectLink = (props) => {
  const {
    onClick,
    product,
    title,
  } = props;

  const iconName = PRODUCT_ICONS[product];

  const iconWrapperClassnames = classnames(
    `${rootClass}__icon-wrapper`,
    {
      [`${rootClass}__icon-wrapper--${product}`]: product,
    },
  );

  const titleClassnames = classnames(
    `${rootClass}__title`,
    {
      [`${rootClass}__title--no-icon`]: !product,
    },
  );

  return (
    <button
      className={rootClass}
      onClick={onClick}
      type="button"
    >
      {
        iconName && (
          <div
            className={iconWrapperClassnames}
          >
            <FontAwesomeIcon
              color="white"
              icon={iconName}
            />
          </div>
        )
      }
      <span className={titleClassnames}>
        {createHtmlMarkup(title)}
      </span>
    </button>
  );
};


ObjectLink.defaultProps = {
  product: null,
  title: null,
};

const {
  func,
  oneOf,
  string,
} = PropTypes;

const products = [
  null,
  'hr',
  'customer-service',
  'tasks',
  'sales',
];

ObjectLink.propTypes = {
  /** On click callback */
  onClick: func.isRequired,
  /** What product does this link navigate to */
  product: oneOf(products),
  /** Text to show in the link box */
  title: string,
};

export default ObjectLink;
