import apiConnector, { endpoints } from 'api';

const base64 = async (src) => {
  const endpoint = endpoints.client.images.base64;

  try {
    const result = await apiConnector.client.get(endpoint, { src });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default base64;
