import React, {
  useMemo,
  useContext,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import DropdownTextfield from 'ui/DropdownTextfield';

const Selector = (props) => {
  const {
    rawField,
  } = props;

  const context = useContext(DataImportContext);

  const {
    fieldMap,
    selectedTypes,
    matchRawToSymplete,
    fieldMatchOptionsByType: optionsByType,
  } = context;

  const options = useMemo(() => optionsByType[selectedTypes], [
    selectedTypes,
    optionsByType,
  ]);

  const value = fieldMap[rawField] || 'noImport';

  const addToNotes = useCallback(() => {
    matchRawToSymplete(rawField)('notes');
  }, [
    matchRawToSymplete,
  ]);

  const noImport = useCallback(() => {
    matchRawToSymplete(rawField)('noImport');
  }, [
    matchRawToSymplete,
  ]);

  return (
    <Flex spacing={1}>
      <DropdownTextfield
        options={options}
        onChange={matchRawToSymplete(rawField)}
        value={value}
      />
      <Button
        variant="secondary"
        onClick={addToNotes}
        icon="file-text"
        noWrap
        disabled={value === 'notes'}
      >
        To notes
      </Button>
      <Button
        variant="cancel"
        onClick={noImport}
        icon="x"
        disabled={value === 'noImport'}
      />
    </Flex>
  );
};

const {
  string,
} = PropTypes;

Selector.propTypes = {
  rawField: string.isRequired,
};

export default Selector;
