import styled from 'styled-components';

const paddings = {
  none: 0,
  s: '16px 8px',
  sm: '16px 8px',
  m: '20px 14px',
  md: '20px 14px',
};

const rounded = {
  s: '4px',
  m: '20px',
};

const Elevation = styled.div`
  box-shadow: ${(props) => !props.noElevation && (props.theme?.shadows?.elevated || '0 0 5px rgba(0, 0, 0, 0.5)')};
  border-radius: ${(props) => (props.rounded ? rounded[props.rounded] || '20px' : 0)};
  padding: ${(props) => paddings[props.padding] || props.padding || 0};
  ${(props) => props.hideOverflow && 'overflow: hidden;'}
  ${(props) => props.fullHeight && 'height: 100%;'}
`;

export default Elevation;
