import styled from 'styled-components';

import Chip from 'ui/Chip';

export const Wrapper = styled.div`

`;

export const SearchContactArea = styled.div`
  width: 628px;
  position: absolute;
  z-index: 2;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31);
  background-color: #ffffff;
`;

export const StyledChip = styled(Chip)`
  margin: 10px 5px;
`;
