import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getContacts from './getContacts';
import getContactById from './getContactById';
import getContactsForFilter from './getContactsForFilter';
import getContactsForStoreFilter from './getContactsForStoreFilter';

import getContactsLabels from './labels/getContactsLabels';
import createLabel from './labels/createLabel';
import updateLabel from './labels/updateLabel';
import bindLabelToContact from './labels/bindLabelToContact';
import unbindLabelFromContact from './labels/unbindLabelFromContact';

import getLinkedObjectsSummaries from './getLinkedObjectsSummaries';

import setSelectedContact from './setSelectedContact';
import setSelectedContactRows from './setSelectedContactRows';

import createContact from './createContact';
import updateContact from './updateContact';
import updateContactPermissions from './updateContactPermissions';
import updateSelectedContact from './updateSelectedContact';
import updateSelectedContactPermissions from './updateSelectedContactPermissions';
import getSelectedContactChangelogAllTime from './getSelectedContactChangelogAllTime';

import createContactFromNewContactForm from './createContactFromNewContactForm';
import linkObjectsToContact from './linkObjectsToContact';

import getOverallContactsSummary from './getOverallContactsSummary';

export { getContactsForFilter };
export { getContactsForStoreFilter };
export { getContacts };
export { getContactById };

export { setSelectedContact };
export { setSelectedContactRows };

export { createContact };
export { updateContact };
export { updateContactPermissions };
export { updateSelectedContact };
export { updateSelectedContactPermissions };

export { createContactFromNewContactForm };
export { linkObjectsToContact };

export { createLabel };
export { updateLabel };
export { bindLabelToContact };
export { unbindLabelFromContact };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCrmContactsActions = {
  getContactsForFilter: memoizeDebounce(
    // eslint-disable-next-line max-params
    (filterId, filter, offset, limit) => store.dispatch(getContactsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getContactById: memoizeDebounce(
    (contactId) => store.dispatch(getContactById(contactId)),
    debounceDelay,
    debounceOptions,
  ),
  getContactsLabels: memoizeDebounce(
    () => store.dispatch(getContactsLabels()),
    debounceDelay,
    debounceOptions,
  ),
  getLinkedObjectsSummaries: memoizeDebounce(
    (contactId) => store.dispatch(getLinkedObjectsSummaries(contactId)),
    debounceDelay,
    debounceOptions,
  ),
  getSelectedContactChangelogAllTime: memoizeDebounce(
    () => store.dispatch(getSelectedContactChangelogAllTime()),
    debounceDelay,
    debounceOptions,
  ),
  getOverallContactsSummary: memoizeDebounce(
    () => store.dispatch(getOverallContactsSummary()),
    debounceDelay,
    debounceOptions,
  ),
};
