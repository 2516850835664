import BaseApiConnector from 'api/connectors/BaseApiConnector';

const clientApiUrl = process.env.REACT_APP_SYMPLETE_CLIENT_API;

class ExternalApiConnector extends BaseApiConnector {
  constructor(contentType = 'application/json') {
    super(clientApiUrl, contentType, 'externalToken');
  }
}

export default ExternalApiConnector;
