/* eslint-disable*/
//TODO fix import from actions
//TODO fix import types and refactoring code
import { find } from 'shared/utility';
import { setOverdue } from 'shared/utils';
import { CHANGE_AUTH_STATUS_SUCCESS } from '../actions/auth';
import {
  GET_TICKETS_BY_STATUS_SUCCESS,
  SET_TICKETS_TO_TABLE_SUCCESS,
  SET_TICKET_DATA,
  RESOLVE_TICKET_BY_MODAL_SUCCESS,
  SHOW_ALL_PANELS,
  SET_PANELS,
  SHOW_ARCHIVED_TICKETS,
  RATE_TICKET_SUCCESS,
  CHANGE_TICKET_STATUS_SUCCESS,
  SET_TAB,
  OPEN_MODAL,
  HIDE_MODAL,
  SET_FILTER,
  UNRESOLVE_TICKET_SUCCESS,
  SET_ACTIVE_AGENT,
} from '../actions/tickets-list-view';
import {
  CREATE_TICKET_SUCCESS,
  ADD_TICKET_NOTIFICATION,
  REMOVE_TICKET_NOTIFICATION,
  UPDATE_TICKET_PROGRESS_SUCCESS,
  UPDATE_TICKET_FIELD_SUCCESS,
  UPDATE_TICKET_DELVIERY_DATE_SUCCESS,
} from '../actions/tickets';
import { SET_TICKET_EMPLOYEES_SUCCESS } from 'store/actionTypes/ticket-details-modal';
import { updateTicket } from './tickets';

const COMMENT = 'comment';
const REPLY = 'reply';

const initialState = {
  tablesList: [],
  tablesData: [],
  activeTablesData: [],
  resolveModal: {
    isOpen: false,
    isLoading: false,
  },
  ticket: {
    status: null,
    data: null,
  },
  panels: ['0'],
  showArchived: false,
  activeTab: '2',
  activeFilter: 'total',
  activeQueue: {
    name: 'All Queues',
    QueueID: 'All Queues',
    CategoryID: null,
    SubCategoryID: null,
  },
  filterTypes: {
    total: 'total',
    newComments: 'new',
    overdue: 'overdue',
  },
  activeAgent: null,
};

function ticketsListView(state = initialState, action) {
  const tmpTable = state.tablesData;
  switch (action.type) {
    case SET_TICKETS_TO_TABLE_SUCCESS:
      return { ...state, tablesList: action.list };
    case GET_TICKETS_BY_STATUS_SUCCESS:
      const { activeTablesData } = action;
      return {
        ...state,
        tablesData: activeTablesData,
        activeTablesData,
      };
    case SET_TICKET_DATA:
      return {
        ...state,
        ticket: { status: action.status, data: action.ticket },
      };
    case RESOLVE_TICKET_BY_MODAL_SUCCESS:
      const resolveId = state.tablesList.filter(
        (table) => table.name === 'Resolved',
      )[0].id[0];
      const newTablesResolve = state.tablesData.map((table) => {
        if (table.name === action.status) {
          return {
            ...table,
            list: table.list.filter(
              (ticket) => ticket.TicketID !== action.ticket.TicketID,
            ),
          };
        } if (table.name === 'Resolved') {
          return {
            ...table,
            list: [
              ...table.list,
              {
                ...action.ticket,
                TicketStatusID: resolveId,
                TicketStatus: action.newResolvedName,
                Resolved: 1,
              },
            ],
          };
        }
        return table;
      });

      return {
        ...state,
        tablesData: newTablesResolve,
        activeTablesData: newTablesResolve.map((table) => {
          if (state.activeFilter === state.filterTypes.total) {
            return table;
          } if (state.activeFilter === state.filterTypes.newComments) {
            return {
              ...table,
              list: table.list.filter((ticket) => ticket.NewComments === 1),
            };
          } if (state.activeFilter === state.filterTypes.overdue) {
            return { ...table, list: setOverdue(table.list) };
          }

          return null;
        }),
      };
    case SHOW_ALL_PANELS:
      return {
        ...state,
        panels: state.tablesData.map((table) => table.index.toString()),
      };
    case SET_PANELS:
      return { ...state, panels: action.panels };
    case SHOW_ARCHIVED_TICKETS:
      return { ...state, showArchived: !state.showArchived };
    case RATE_TICKET_SUCCESS:
      return {
        ...state,
        tablesData: state.tablesData.map((table) => {
          if (table.name === 'Resolved') {
            return {
              ...table,
              list: table.list.map((ticket) => {
                if (ticket.TicketID === action.ticket.TicketID) {
                  return {
                    ...ticket,
                    Rating: action.data.rateDifficulty,
                    CustomerRate: action.data.rateCustomer,
                    ResolveComment: action.data.resolveComment,
                  };
                }
                return ticket;
              }),
            };
          }
          return table;
        }),
      };
    case CHANGE_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        tablesData: action.tablesData,
        activeTablesData: action.activeTablesData
      }
    case SET_FILTER:
      return {
        ...state,
        ...action,
      };
    case SET_TAB:
      return { ...state, activeTab: action.tab };
    case OPEN_MODAL:
      return {
        ...state,
        resolveModal: { ...state.resolveModal, isOpen: true },
      };
    case HIDE_MODAL:
      return {
        ...state,
        resolveModal: { ...state.resolveModal, isOpen: false },
      };
    case CREATE_TICKET_SUCCESS:
      const {
        userId,
        data: [ticket],
      } = action;
      const { TicketStatusID, employees } = ticket;
      if (find(employees, ['EmployeeID', userId])) {
        if (TicketStatusID === 2) {
          return {
            ...state,
            tablesData: state.tablesData.map((table) => {
              if (table.name === 'New Assigned') {
                return { ...table, list: [...table.list, ticket] };
              }
              return table;
            }),
            activeTablesData: state.activeTablesData.map((table) => {
              if (table.name === 'New Assigned') {
                return { ...table, list: [...table.list, ticket] };
              }
              return table;
            }),
          };
        }
        return state;
      }
      return state;
    case SET_TICKET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        tablesList: [],
        tablesData: [],
        activeTablesData: [],
      };
    case ADD_TICKET_NOTIFICATION:
      return {
        ...state,
        activeTablesData: tmpTable.map((table) => ({
          ...table,
          list: table.list.map((ticket) => {
            if (ticket.TicketID === action.ticketId) {
              if (action.notification === COMMENT) {
                ticket.NewComments = 1;
              } else if (action.notification === REPLY) {
                ticket.NewReplies = 1;
              }
            }
            return ticket;
          }),
        })),
      };
    case REMOVE_TICKET_NOTIFICATION:
      return {
        ...state,
        activeTablesData: tmpTable.map((table) => ({
          ...table,
          list: table.list.map((ticket) => {
            if (ticket.TicketID === action.ticketId) {
              if (action.notification === COMMENT) {
                ticket.NewComments = 0;
              } else if (action.notification === REPLY) {
                ticket.NewReplies = 0;
              }
            }
            return ticket;
          }),
        })),
      };
    case UPDATE_TICKET_DELVIERY_DATE_SUCCESS:
    case UPDATE_TICKET_FIELD_SUCCESS:
    case UPDATE_TICKET_PROGRESS_SUCCESS: {
      const { ticket } = action;
      return synchronizeMyTickets(state, ticket);
    }
    case UNRESOLVE_TICKET_SUCCESS: {
      const clear = state.tablesData.map((table) => ({ ...table, list: [] }));
      const clearActive = state.activeTablesData.map((table) => ({
        ...table,
        list: [],
      }));
      return {
        ...state,
        tablesData: clear,
        activeTablesData: clearActive,
      };
    }
    case SET_ACTIVE_AGENT: {
      const newState = {
        ...state,
        activeAgent: action.agent,
      };

      return newState;
    }
    case CHANGE_AUTH_STATUS_SUCCESS: {
      const activeAgent = state.activeAgent
        ? {
          ...state.activeAgent,
          availability: state.activeAgent.id === action.payload.EmployeeID
            ? action.payload.useravailability
            : state.activeAgent.availability,
        }
        : null;

      return {
        ...state,
        activeAgent,
      };
    }
    default:
      return state;
  }
}

export default ticketsListView;

const setIndex = (data) => data.map((table) => {
  let index = null;
  switch (table.name) {
    case 'New Assigned':
      index = 0;
      break;
    case 'In Progress':
      index = 1;
      break;
    case 'Pending':
      index = 2;
      break;
    case 'Resolved':
      index = 3;
      break;
    default:
      break;
  }
  return { ...table, index };
});

const synchronizeMyTickets = (state, newTicket) => {
  const {
    ticket, tablesList, tablesData, activeTablesData,
  } = state;
  const { TicketStatusID } = newTicket;
  const status = findStatus(tablesList, TicketStatusID);
  if (status) {
    const { name } = status;
    return {
      ...state,
      ticket: { ...ticket, data: newTicket },
      tablesData: updateTables(tablesData, newTicket, name),
      activeTablesData: updateTables(activeTablesData, newTicket, name),
    };
  }
  return state;
};

const findStatus = (statusList, statusId) => statusList.filter((status) => status.id.includes(statusId))[0];

const updateTables = (tables, ticket, statusName) => tables.map((table) => (table.name === statusName ? updateTable(table, ticket) : table));

const updateTable = (table, newTicket) => {
  const { list } = table;
  return {
    ...table,
    list: updateTicket(list, newTicket),
  };
};
