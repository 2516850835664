import {
  SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT,
} from 'store/actionTypes/warehouse';

const set = (fulfillmentId) => (packages) => ({
  type: SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT,
  payload: {
    fulfillmentId,
    packages,
  },
});

export default set;
