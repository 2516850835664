import styled from 'styled-components';

export const Root = styled.div`
  padding: 2px 6px;
  background: ${(props) => props.theme.palette[props.role] || 'red'};
  text-align: center;
`;

export default {
  Root,
};
