import React from 'react';

import Typography from 'ui/Typography';
import Item from 'ui/Item';
import Container from 'ui/Container';

import PreferredLocationInput from 'sections/Leads/forms/NewLeadForm/PreferredLocationInput';
import ExpectedPurchasePriceInput from 'sections/Leads/forms/NewLeadForm/ExpectedPurchasePriceInput';
import LeadPropertyParametersArea from 'sections/Leads/forms/NewLeadForm/LeadPropertyParametersArea';
import PropertyMultiTypeSelect from 'sections/Leads/forms/NewLeadForm/PropertyMultiTypeSelect';

import {
  PropertyDetailsWrapper,
} from './styledItems';

const BuyerSection = (props) => (
  <Container spacing={2}>
    <Item xs={12}>
      <Typography
        variant="h3"
        noMargin
      >
        Complete this section to represent this person as a Buyer.
      </Typography>
    </Item>

    <Item xs={12}>
      <ExpectedPurchasePriceInput />
    </Item>

    <Item xs={12}>
      <PreferredLocationInput />
    </Item>

    <Item xs={12}>
      <PropertyDetailsWrapper>
        <Container spacing={2}>
          <Item xs={12}>
            <Typography
              variant="h4"
            >
              Property Requirements (Buyer)
            </Typography>
          </Item>

          <Item xs={12}>
            <PropertyMultiTypeSelect />
          </Item>

          <Item xs={12}>
            <LeadPropertyParametersArea
              sectionType="buyer"
            />
          </Item>
        </Container>
      </PropertyDetailsWrapper>
    </Item>
  </Container>
);

export default BuyerSection;
