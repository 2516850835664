import store from 'store';

import {
  updateMatchingProperty as updateMatch,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

import {
  getProspectById,
} from 'store/actions/realtorProspects';

const updateMatchingProperty = (matchId, prospectId) => async (updateData) => {
  await store.dispatch(updateMatch(matchId)(updateData));

  prospectId && store.dispatch(getProspectById(prospectId));

  store.dispatch(resetMatchingPropertyFilters());
};

export default updateMatchingProperty;
