import moment from 'moment';

import getLeadChangelog from './getLeadChangelog';

const getLeadChangelogAllTime = (leadId) => (dispatch) => {
  try {
    dispatch(getLeadChangelog(leadId)('2020-11-01', moment().add(1, 'd').format('YYYY-MM-DD')));
  } catch (error) {
    console.error(error);
  }
};

export default getLeadChangelogAllTime;
