import requests from 'api/requests';

import setProperties from './setProperties';
import setPropertiesForFilter from './setPropertiesForFilter';
import setPropertiesForFilterCount from './setPropertiesForFilterCount';

const getPropertiesForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.properties.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setProperties(response.data.items));
    dispatch(setPropertiesForFilter(filterId)(response.data.items));
    dispatch(setPropertiesForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getPropertiesForFilter;
