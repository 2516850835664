import apiConnector, { endpoints } from 'api';

/**
 * Obtains a long-lived facebook access token
 * @param {string} accessToken Short-lived facebook access token
 */
const getLongLivedToken = async (accessToken) => {
  const endpoint = `${endpoints.client.socialIntegrations.facebook}/long-lived-token`;
  try {
    const result = await apiConnector.client.post(endpoint, { accessToken });

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getLongLivedToken;
