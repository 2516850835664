import { connect } from 'react-redux';
import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateTransaction,
} from 'store/actions/realtorTransactions';

import DayViewCalendarItem from 'sections/Calendar/components/DayViewCalendarItem';

const fieldsObj = {
  initial_documents_expires_at: {
    noteField: 'initial_documents_notes',
    title: 'Initial Document',
  },
  seller_disclosure_expires_at: {
    noteField: 'seller_disclosure_notes',
    title: 'Seller Disclosure',
  },
  inspections_expires_at: {
    noteField: 'inspections_notes',
    title: 'Inspections',
  },
  appraisal_expires_at: {
    noteField: 'appraisal_notes',
    title: 'Appraisal',
  },
  loan_expires_at: {
    noteField: 'loan_notes',
    title: 'Loan',
  },
  final_walkthrough_expires_at: {
    noteField: 'final_walkthrough_notes',
    title: 'Final Walkthrough',
  },
  close_escrow_expires_at: {
    noteField: 'close_escrow_notes',
    title: 'Close Escrow',
  },
  move_out_date_expires_at: {
    noteField: 'move_out_date_notes',
    title: 'Move Out',
  },
};

const contactFields2 = [
  'seller_contact_id',
  'buyer_contact_id',
  'seller_agent_contact_id',
  'buyer_agent_contact_id',
  'escrow_agent_id',
  'title_agent_id',
];

const contactFields = [
  {
    fieldName: 'seller_contact_id',
    label: 'Seller',
    labelVariant: 'seller',
  },
  {
    fieldName: 'buyer_contact_id',
    label: 'Buyer',
    labelVariant: 'buyer',
  },
  {
    fieldName: 'seller_agent_contact_id',
    label: 'Seller Agent',
    labelVariant: 'focus',
  },
  {
    fieldName: 'buyer_agent_contact_id',
    label: 'Buyer Agent',
    labelVariant: 'focus',
  },
  {
    fieldName: 'escrow_agent_id',
    label: 'Escrow Agent',
    labelVariant: 'focus',
  },
  {
    fieldName: 'title_agent_id',
    label: 'Title Agent',
    labelVariant: 'focus',
  },
];

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
    transactionField,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const transactionContacts = [];

  contactFields.forEach(({ fieldName, label, labelVariant }) => {
    if (transaction[fieldName]) {
      transactionContacts.push({
        id: transaction[fieldName],
        label: {
          text: label,
          variant: labelVariant,
        },
      });
    }
  });

  const {
    title,
  } = fieldsObj[transactionField];

  return {
    dateTime: transaction[transactionField],
    sectionTitle: transactionField === 'close_escrow_expires_at' ? 'Escrow Closing' : 'Contingency Removal',
    contacts: transactionContacts,
    propertyId: transaction.realtor_property_id,
    contingencyTitle: transactionField === 'close_escrow_expires_at' ? null : title,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    dispatch(updateTransaction(ownProps.transactionId)({
      [ownProps.transactionField]: value,
    }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(DayViewCalendarItem);
