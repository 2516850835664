/* eslint-disable react/jsx-no-bind, camelcase */
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import OfferDeclineReasonInput from './OfferDeclineReasonInput';

const LogOfferForm = (props) => {
  const {
    formId,
    decline_reason,
    readOnly,
    onSubmit: propsOnSubmit,
  } = props;

  const {
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = useCallback((data) => {
    propsOnSubmit(data);
  }, []);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {
              !readOnly ? (
                <OfferDeclineReasonInput
                  control={control}
                />
              ) : (
                <Typography variant="h4">
                  {decline_reason}
                </Typography>
                )
            }
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const {
  string,
  func,
  bool,
} = PropTypes;

LogOfferForm.propTypes = {
  formId: string.isRequired,
  onSubmit: func.isRequired,
  readOnly: bool.isRequired,
  decline_reason: string.isRequired,
};

export default LogOfferForm;
