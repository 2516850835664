import apiConnector, { endpoints } from 'api';

const uploadAttachment = (objectType) => (objectId) => async (attachmentFormData, isPublic = false) => {
  const endpoint = `${endpoints.client.attachments}/${objectType}/${objectId}${isPublic ? '/public' : ''}`;

  try {
    const response = await apiConnector.clientAttachmentsUpload.post(endpoint, attachmentFormData);
    return response;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default uploadAttachment;
