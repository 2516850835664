import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.realtorLeadsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      sort_by: filter.sortBy,
      sort_direction: filter.sortDirection,
      min_property_value: filter.propertyValueRange[0],
      max_property_value: filter.propertyValueRange[1],
      source: filter.source,
      lead_type: filter.leadType,
      lead_status: filter.leadStatus,
      min_created_date: filter.createdAtRange[0],
      max_created_date: filter.createdAtRange[1],
      temp: filter.temp,
      search: filter.search,
      status: filter.status,
    },
  }),
);
