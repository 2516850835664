import apiConnector, { endpoints } from 'api';

const removeRelations = (relationType) => (leadId) => async (relatedItemsIds) => {
  const endpoint = `${endpoints.client.realtor.leadsRelations}/${relationType}/${leadId}`;

  try {
    await apiConnector.client.delete(endpoint, relatedItemsIds);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default removeRelations;
