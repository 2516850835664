import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {string} phoneNumber
 */
const create = async (phoneNumber) => {
  const endpoint = endpoints.client.calls.outgoingCaller;
  try {
    const result = await apiConnector.client.post(endpoint, { phoneNumber });

    return result;
  } catch (error) {
    if (error?.response?.status === 409) {
      throw new Error('already validated', { cause: error });
    } else {
      throw (error);
    }
  }
};

export default create;
