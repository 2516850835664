import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tutorial from '../shared/Tutorial';

import tutorialRoutes from '../tutorialRoutes';

import './SalesTutorial.scss';

const SalesTutorial = (props) => {
  const { isPitchModalVisible } = props;

  const openLead = () => {
    const {
      addTutorialLead,
      handlePitchDetailsModal,
    } = props;
    addTutorialLead();
    handlePitchDetailsModal(true, 0);
  };

  const closeLead = () => {
    const {
      handlePitchDetailsModal,
    } = props;
    handlePitchDetailsModal(false);
  };

  const goToStep = useCallback((index) => {
    const {
      setStepIndex,
      startTutorial,
      endTutorial,
      history: {
        push,
      },
      steps,
    } = props;

    const stepActions = {
      'tutorial-create-sales-team': () => {
        closeLead();
        push('/sales/sales-teams');
        window.history.pushState(null, null, tutorialRoutes.sales.createSalesTeam);
      },
      'tutorial-create-new-lead': () => {
        closeLead();
        push('/sales/leads');
        window.history.pushState(null, null, tutorialRoutes.sales.createNewLead);
      },
      'tutorial-create-new-deal': () => {
        closeLead();
        push('/sales/deals');
        window.history.pushState(null, null, tutorialRoutes.sales.createNewDeal);
      },
      // 'tutorial-take-unassigned-leads': () => {
      //   endTutorial();
      //   closeLead();
      //   push('/sales/my-leads');
      //   startTutorial();
      //   setTimeout(() => {
      //     document.getElementsByClassName('my-leads__link')[0].click();
      //     startTutorial();
      //   }, 1500);
      //   window.history.pushState(null, null, tutorialRoutes.sales.takeUnassignedLeads);
      // },
      'tutorial-update-pitch': () => {
        if (!isPitchModalVisible) {
          endTutorial();
          openLead();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.sales.updatePitch);
      },
      'tutorial-create-meeting': () => {
        if (!isPitchModalVisible) {
          endTutorial();
          openLead();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.sales.createMeeting);
      },
      'tutorial-change-pitch-progress': () => {
        if (!isPitchModalVisible) {
          endTutorial();
          openLead();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.sales.changePitchProgress);
      },
      'tutorial-close-deal': () => {
        if (!isPitchModalVisible) {
          endTutorial();
          openLead();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.sales.closeDeal);
      },
    };

    const currentStep = steps[index].name;

    stepActions[currentStep]();

    setStepIndex(index);
  }, [isPitchModalVisible]);

  const finishTutorial = useCallback(() => {
    const {
      endTutorial,
      hideTutorialLayer,
      history: {
        push,
      },
      setStepIndex,
    } = props;
    endTutorial();
    setStepIndex(0);
    closeLead();
    hideTutorialLayer();
    push('/sales');
  }, []);

  const {
    stepName,
    steps,
  } = props;

  return (
    <Tutorial
      className="sales-tutorial"
      stepName={stepName}
      steps={steps}
      goToStep={goToStep}
      finishTutorial={finishTutorial}
      name="Sales"
    />
  );
};

const {
  any,
  bool,
  string,
  func,
  object,
  arrayOf,
} = PropTypes;

SalesTutorial.defaultProps = {
  stepName: '',
  isPitchModalVisible: false,
};

SalesTutorial.propTypes = {
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  startTutorial: func.isRequired,
  endTutorial: func.isRequired,
  hideTutorialLayer: func.isRequired,
  addTutorialLead: func.isRequired,
  handlePitchDetailsModal: func.isRequired,
  stepName: string,
  isPitchModalVisible: bool,
  setStepIndex: func.isRequired,
  steps: arrayOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
};

export default SalesTutorial;
