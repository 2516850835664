import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';

const SourceDescriptionForm = (props) => {
  const {
    sourceName,
    sourceDescription,
    onChange,
  } = props;

  const handleChange = useCallback((value) => {
    onChange(value);
  }, [
    onChange,
  ]);

  return (
    <form>
      <Typography variant="h2" tag="h1">
        {sourceName}
      </Typography>
      <Typography variant="h3" tag="p">
        Set a description for this source to provide more details
      </Typography>
      <Textfield
        textarea
        onChange={handleChange}
        value={sourceDescription}
      />
    </form>
  );
};

SourceDescriptionForm.defaultProps = {
  sourceName: 'Lead source',
  sourceDescription: '',
};

const {
  func,
  string,
} = PropTypes;

SourceDescriptionForm.propTypes = {
  sourceName: string,
  sourceDescription: string,
  onChange: func.isRequired,
};

export default SourceDescriptionForm;
