import { createSelector } from 'reselect';

import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.lineItems.lineItems,
  (state, itemIds) => itemIds,
  (state, itemIds, parentRowId) => parentRowId,
  (products, lineItems, itemIds, parentRowId) => {
    const items = [];

    itemIds.forEach((id, index) => {
      const lineItem = lineItems[id];

      if (!lineItem) {
        optimizedLineItemsActions.getLineItemById(id);
        return;
      }

      const productObject = products[lineItem.product_id];

      if (!productObject && lineItem.product_id) {
        optimizedCrmProductsActions.getProductById(lineItem.product_id);
      }

      const isPaid = (lineItem.process_status & 1) > 0; // eslint-disable-line no-bitwise

      items.push({
        ...lineItem,
        rowId: `${parentRowId}.${index + 1}`,
        title: productObject?.name,
        quantity: 1,
        is_paid: isPaid,
      });
    });

    return items;
  },
);
