/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import NewExtensionRequestModal from './NewExtensionRequestModal';

const closeNewExtensionRequestModal = closeModal('newExtensionRequest');

const mapStateToProps = (state) => {
  const {
    modals: {
      newExtensionRequest,
    },
  } = state;

  const isVisible = newExtensionRequest?.isVisible;

  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeNewExtensionRequestModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewExtensionRequestModal);
