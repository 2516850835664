import requests from 'api/requests';

import setPayment from './setPayment';
import getPaymentAttachments from './getPaymentAttachments';

const getById = (id) => async (dispatch) => {
  try {
    const response = await requests.customerPayments.getById(id);
    dispatch(setPayment(response.data));
    dispatch(getPaymentAttachments(id));
  } catch (error) {
    console.error(error);
  }
};

export default getById;
