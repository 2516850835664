import React, { Component } from 'react';
import {
  Button,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';

import './CredentialsModal.scss';

class CredentialsModal extends Component {
  closeModal = () => {
    const { triggerCredentialsModal: triggerModal } = this.props;
    triggerModal(false);
  };

  render() {
    const footer = (
      <div className="credentials-modal__footer">
        <Button
          type="primary"
          onClick={this.closeModal}
        >
          Ok
        </Button>
      </div>
    );

    const {
      visible,
      receiveLogout: logout,
    } = this.props;

    return (
      <Modal
        width={360}
        title={null}
        closable={false}
        centered
        footer={footer}
        visible={visible}
        afterClose={logout}
        wrapClassName="credentials-modal"
      >
        <div className="credentials-modal__content">
          Your credentials has been changed. Please login again with new credentials.
        </div>
      </Modal>
    );
  }
}

const {
  bool,
  func,
} = PropTypes;

CredentialsModal.propTypes = {
  triggerCredentialsModal: func.isRequired,
  visible: bool,
  receiveLogout: func.isRequired,
};

CredentialsModal.defaultProps = {
  visible: false,
};

export default CredentialsModal;
