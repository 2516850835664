/**
  * Updates localStorage and dispathes custom 'localStorageUpdate' event
  * @param {('set'|'remove')} updateType Type of the update
  * @param {string} key Item key
  * @param {string} [value=''] Item value
 */
const updateLocalStorageWithCustomEvent = (updateType, key, value = '') => {
  if (!updateType || !key) {
    return;
  }

  if (updateType === 'set') {
    localStorage.setItem(key, value);
  }
  if (updateType === 'remove') {
    localStorage.removeItem(key);
  }

  const event = new Event('localStorageUpdate');
  window.dispatchEvent(event);
};

export default updateLocalStorageWithCustomEvent;
