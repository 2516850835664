import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import RealtorMasterSearch from 'shared/modules/RealtorMasterSearch';

import SearchItemLead from 'shared/modules/SearchItemLead';
import SearchItemContact from 'shared/modules/SearchItemContact';

import SearchItemProspect from 'shared/components/UI/SearchItemProspect';
import withProspectData from 'sections/Prospects/hocs/withProspectData';

import SearchItemClient from 'shared/components/UI/SearchItemClient';
import withClientData from 'sections/Clients/hocs/withClientData';

const SearchItemProspectModule = withProspectData(SearchItemProspect);
const SearchItemClientModule = withClientData(SearchItemClient);

const itemComponents = {
  25: SearchItemContact,
  26: SearchItemLead,
  29: ({ objectId }) => <SearchItemClientModule clientId={objectId} />, // eslint-disable-line react/prop-types
  30: ({ objectId }) => <SearchItemProspectModule prospectId={objectId} />, // eslint-disable-line react/prop-types
};

const allowedCategories = [
  'leads',
  'prospects',
  'clients',
  'contacts',
];

const ObjectSelector = (props) => {
  const {
    onSelect,
    selectedObjectTypeId: selectedObjectTypeIdProps,
    selectedObjectId: selectedObjectIdProps,
  } = props;

  const [
    objectTypeId,
    setObjectTypeId,
  ] = useState(null);

  const [
    objectId,
    setObjectId,
  ] = useState(null);

  useEffect(() => {
    setObjectTypeId(selectedObjectTypeIdProps);
    setObjectId(selectedObjectIdProps);
  }, [
    selectedObjectTypeIdProps,
    selectedObjectIdProps,
  ]);

  const handleObjectSelect = useCallback((selecteObjectTypeId) => (selectedObjectId) => {
    setObjectTypeId(selecteObjectTypeId);
    setObjectId(selectedObjectId);

    onSelect(selecteObjectTypeId)(selectedObjectId);
  }, [
    onSelect,
  ]);

  const SelectedItem = useMemo(() => itemComponents[objectTypeId], [
    objectTypeId,
  ]);

  return (
    <Flex
      spacing={2}
      flexDirection="column"
    >
      <RealtorMasterSearch
        defaultInputStyle
        onSelect={handleObjectSelect}
        filterCategories={allowedCategories}
      />
      {
        objectTypeId && objectId && (
          <div>
            <Typography variant="body1">Reminders for: </Typography>
            <SelectedItem objectId={objectId} />
          </div>
        )
      }
    </Flex>
  );
};

ObjectSelector.defaultProps = {
  selectedObjectTypeId: null,
  selectedObjectId: null,
};

const {
  func,
  number,
} = PropTypes;

ObjectSelector.propTypes = {
  onSelect: func.isRequired,
  selectedObjectTypeId: number,
  selectedObjectId: number,
};

export default ObjectSelector;
