import React, { Component } from 'react';
import {
  Row, Col, Select, Form, Modal, Radio, Checkbox,
} from 'antd';
import { get } from 'shared/utility';

import './SearchFilterForm.scss';

const { Option } = Select;

export const SearchFilterForm = Form.create()(
  class extends Component {
    state = {
      confirmDirty: false,
      autoCompleteResult: [],
      accessibilityValue: 'Public',
      starred: true,
    };

    setFormLabel = (children) => (
      <span className="filter-label-text">{children}</span>
    );

    render() {
      const { onCancel, onCreate, data } = this.props;

      const { getFieldDecorator } = this.props.form;

      // const {
      //   searchFilterModal: {
      //     isVisible,
      //     isLoading
      //   },
      //   kbHelperData: {
      //     categories,
      //     types,
      //     labels,
      //     products,
      //     accessibility,
      //   }
      // } = data;

      const isVisible = get(data, 'searchFilterModal.isVisible', false);
      const isLoading = get(data, 'searchFilterModal.isLoading', false);

      const categories = get(data, 'kbHelperData.categories', null);
      const types = get(data, 'kbHelperData.types', null);
      const labels = get(data, 'kbHelperData.labels', null);
      const products = get(data, 'kbHelperData.products', null);
      const accessibility = get(data, 'kbHelperData.accessibility', null);

      const formItemLayout = {
        // labelCol: {
        //     xs: { span: 24 },
        //     sm: { span: 24 },
        // },
        // wrapperCol: {
        //     xs: { span: 24 },
        //     sm: { span: 16 },
        // },
      };

      return (
        <Modal
          title="Filter"
          okText="Create"
          visible={isVisible}
          onOk={() => {
            onCreate();
          }}
          onCancel={() => {
            onCancel();
          }}
          wrapClassName="search-filter-modal"
        >
          <Form {...formItemLayout} className="filter-form">

            <Form.Item
              colon={false}
              label={this.setFormLabel('Feedback')}
            >
              {getFieldDecorator('feedback', {
                initialValue: [],
              })(
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row>
                    <Col span={9}><Checkbox value="90">Very Helpful</Checkbox></Col>
                    <Col span={9}><Checkbox value="40">Reasonably Helpful</Checkbox></Col>
                    <Col span={9}><Checkbox value="39">Not Helpful</Checkbox></Col>
                  </Row>
                </Checkbox.Group>,
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Keywords')}
            >
              <Row gutter={8}>
                <Col span={18}>
                  {getFieldDecorator('keywords', {
                    rules: [{ required: false, message: 'Please select tags!' }],
                  })(
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder=""
                    />,
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Category')}
            >
              <Row gutter={8}>
                <Col span={18}>
                  {getFieldDecorator('category', {
                    rules: [{ required: false, message: 'Please select category!' }],
                  })(
                    <Select
                      allowClear
                    >
                      {
                        categories
                        && categories.map((category) => (
                          <Option
                            key={category.id}
                            value={category.id}
                          >
                            {category.name}
                          </Option>
                        ))
                      }
                    </Select>,
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Labels')}
            >
              <Row gutter={8}>
                <Col span={18}>
                  {getFieldDecorator('labels', {
                    rules: [{ required: false, message: 'Please select labels!' }],
                  })(
                    <Select
                      mode="labels"
                    >
                      {
                        labels
                        && labels.map((label) => (
                          <Option
                            key={label.id}
                            value={label.id}
                          >
                            {label.label}
                          </Option>
                        ))
                      }
                    </Select>,
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Article Type')}
            >
              <Row gutter={8}>
                <Col span={18}>
                  {getFieldDecorator('types', {
                    rules: [{ required: false, message: 'Please select articles!' }],
                  })(
                    <Select>
                      {
                        types
                        && types.map((type) => (
                          <Option
                            key={type.id}
                            value={type.id}
                          >
                            {type.type}
                          </Option>
                        ))
                      }
                    </Select>,
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Accessibility')}
            >
              {getFieldDecorator('accessibility', {
                initialValue: 1,
              })(
                <Radio.Group>
                  {
                    accessibility
                    && accessibility.map((accessibility) => (
                      <Radio
                        value={accessibility.id}
                        key={accessibility.id}
                        className="accessibility-name"
                      >
                        {accessibility.accessibility}
                      </Radio>
                    ))
                  }
                </Radio.Group>,
              )}
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Related Product')}
            >
              <Row gutter={8}>
                <Col span={18}>
                  {getFieldDecorator('product', {
                    rules: [{ required: false, message: 'Please select category!' }],
                  })(
                    <Select>
                      {
                        products
                        && products.map((product) => (
                          <Option
                            key={product.id}
                            value={product.id}
                          >
                            {product.product}
                          </Option>
                        ))
                      }
                    </Select>,
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              colon={false}
              label={this.setFormLabel('Collaborators')}
            >
              <Row gutter={8}>
                <Col span={18}>
                  {getFieldDecorator('collaborators', {
                    rules: [{ required: false, message: 'Please select Collaborators!' }],
                  })(
                    <Select
                      mode="collaborators"
                      style={{ width: '100%' }}
                      placeholder=""
                    />,
                  )}
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Row gutter={8}>
                <Col span={9}>
                  <Form.Item>

                    {getFieldDecorator('starred', {
                      rules: [{ required: false, message: 'Please select starred!' }],
                    })(
                      <Checkbox>
                        Starred by me
                      </Checkbox>,
                    )}

                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item>
                    {getFieldDecorator('archived', {
                      rules: [{ required: false, message: 'Please select archived!' }],
                    })(
                      <Checkbox>
                        Show archived
                      </Checkbox>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);
