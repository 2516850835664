import { Menu } from 'antd';
import React from 'react';

const { Item } = Menu;


const DropdownMenu = ({
  handleClick, items, className, itemClassName,
}) => (
  <Menu onClick={handleClick} className={className}>
    {items.map((item) => (
      <Item key={item.key} className={itemClassName}>
        {item.text}
      </Item>
    ))}
  </Menu>
);

export default DropdownMenu;
