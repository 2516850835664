import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createQuestion from './create';
import openLogNewQuestionModal from './openLogNewQuestionModal';
import addUnansweredQuestionRelations from './addRelations';

import getUnansweredQuestionById from './getById';
import getUnansweredQuestionForFilter from './getForFilter';

import updateUnansweredQuestion from './update';

import getUnansweredQuestionAttachments from './getAttachments';

import setFormAttachments from './setFormAttachments';

export {
  createQuestion,
  addUnansweredQuestionRelations,
  openLogNewQuestionModal,
  getUnansweredQuestionById,
  getUnansweredQuestionForFilter,
  updateUnansweredQuestion,
  setFormAttachments,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorUnansweredQuestionActions = {
  getForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(
      getUnansweredQuestionForFilter(filterId)(filter)(offset, limit),
    ),
    debounceDelay,
    debounceOptions,
  ),

  getById: memoizeDebounce(
    (requestId) => store.dispatch(getUnansweredQuestionById(requestId)),
    debounceDelay,
    debounceOptions,
  ),

  getAttachments: memoizeDebounce(
    (requestId) => store.dispatch(getUnansweredQuestionAttachments(requestId)),
    debounceDelay,
    debounceOptions,
  ),
};
