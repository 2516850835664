import React, { useContext, useMemo, useEffect } from 'react';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Spin from 'ui/Spin';
import withOptionsMenu from 'ui/withOptionsMenu';

import CallsContext from 'sections/Calls/context/CallsContext';

import { isSafariBrowser, isFirefoxBrowser } from 'shared/utils/handleBrowsers';

const Menu = withOptionsMenu(Button);

const noAudioOutputSupport = isSafariBrowser() || isFirefoxBrowser();

const DeviceSetup = (props) => {
  const context = useContext(CallsContext);

  const {
    device,
    isLoading,

    inputList,
    outputList,
    inputDevice,
    outputDevice,

    setInputDevice,
    setOutputDevice,

    refreshDevicesList,
    stopUsingMic,
  } = context;

  useEffect(() => {
    refreshDevicesList();

    return () => {
      stopUsingMic();
    };
  }, []);

  const hasMic = useMemo(() => inputList && inputList?.length > 0, [
    inputList,
  ]);
  const hasSpeakers = useMemo(() => outputList && outputList?.length > 0, [
    outputList,
  ]);

  return (
    <div>
      {isLoading && (
        <Spin />
      )}

      {device && !isLoading && (
        <Flex flexDirection="column" spacing={2}>
          <Flex spacing={4} flexDirection="column">
            {
              hasMic && (
                <Flex alignItems="center" spacing={1}>
                  <Typography variant="label" noMargin>
                    Input device (mic):
                  </Typography>
                  <Menu
                    variant="secondary"
                    options={inputList}
                    children={inputDevice?.label || 'Select input device'} // eslint-disable-line react/no-children-prop
                    value={inputDevice?.value}
                    onChange={setInputDevice}
                  />
                </Flex>
              )
            }
            {
              !hasMic && (
                <div>
                  <Typography>
                    {/* eslint-disable-next-line max-len */}
                    Sorry, we are having a problem detecting your microphone. Please check if it is connected and switched on.
                  </Typography>
                  <Button
                    variant="secondary"
                    onClick={refreshDevicesList}
                  >
                    Click here if it is not detected automatically
                  </Button>
                </div>
              )
            }
            {
              (hasSpeakers && !noAudioOutputSupport) && ( // safari and firefox do not support output media device select
                <Flex alignItems="center" spacing={1}>
                  <Typography variant="label" noMargin>
                    Output device (speakers):
                  </Typography>
                  <Menu
                    variant="secondary"
                    options={outputList}
                    children={outputDevice?.label || 'Select output device'} // eslint-disable-line react/no-children-prop
                    value={outputDevice?.value}
                    onChange={setOutputDevice}
                  />
                </Flex>
              )
            }
            {
              (!hasSpeakers && !noAudioOutputSupport) && ( // safari and firefox do not support output media device select
                <div>
                  <Typography>
                    {/* eslint-disable-next-line max-len */}
                    Sorry, we are having a problem detecting your audio output device. Please check if it is connected and switched on.
                  </Typography>
                  <Button
                    variant="secondary"
                    onClick={refreshDevicesList}
                  >
                    Click here if it is not detected automatically
                  </Button>
                </div>
              )
            }
          </Flex>
        </Flex>
      )}
    </div>
  );
};

DeviceSetup.defaultProps = {};

export default DeviceSetup;
