import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import FileViewer from 'react-file-viewer';

const imageExtensions = {
  bmp: true,
  gif: true,
  jpg: true,
  jpeg: true,
  png: true,
  svg: true,
};

const PreviewModal = (props) => {
  const {
    isVisible,
    onCancel,
    previewFileUrl,
    previewFileExtension,
    footer,
  } = props;

  const isImage = imageExtensions[previewFileExtension];

  const UnsupportedComponent = useCallback(() => (
    <div>
      Sorry, previews for
      {' '}
      <b>{previewFileExtension}</b>
      {' '}
      file type are not supported yet - we are working on it
    </div>
  ), [
    previewFileExtension,
  ]);

  return (
    <Modal
      width={900}
      visible={isVisible}
      footer={footer}
      onCancel={onCancel}
      destroyOnClose
      noCloseIcon
    >
      {
        isImage && (
          <Zoom>
            <img
              style={{
                width: 840,
              }}
              alt="Attachment preview"
              src={previewFileUrl}
            />
          </Zoom>
        )
      }
      {
        !isImage && (
          <FileViewer
            filePath={previewFileUrl}
            fileType={previewFileExtension}
            unsupportedComponent={UnsupportedComponent}
          />
        )
      }
    </Modal>
  );
};

PreviewModal.defaultProps = {
  isVisible: false,
  onCancel: () => {},
  previewFileUrl: '/assets/img/file.png',
  previewFileExtension: 'png',
  FooterComponent: () => null,
};

const {
  bool,
  func,
  string,
} = PropTypes;

PreviewModal.propTypes = {
  isVisible: bool,
  onCancel: func,
  previewFileUrl: string,
  previewFileExtension: string,
  FooterComponent: func,
};

export default PreviewModal;
