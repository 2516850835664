import requests from 'api/requests';

import setOpportunities from './setOpportunities';
import setOpportunitiesForFilter from './setOpportunitiesForFilter';
import setOpportunitiesForFilterCount from './setOpportunitiesForFilterCount';

const getOpportunitiesForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.opportunities.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setOpportunities(response.data.items));
    dispatch(setOpportunitiesForFilter(filterId)(response.data.items));
    dispatch(setOpportunitiesForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getOpportunitiesForFilter;
