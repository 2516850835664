import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import ImageUploadWrapper from 'shared/components/ImageUploadWrapper';

import {
  Avatar,
  CancelWrapper,
} from './styledItems';

const preventDefaultAction = (event) => event.preventDefault();

const UploadImageButton = (props) => {
  const {
    fileUrl,
    onUpload,
    clearAvatar,
    buttonText,
    appModule,
    cropAppModule,
    visibleModule,
  } = props;

  if (fileUrl) {
    return (
      <Avatar src={fileUrl}>
        <CancelWrapper>
          <Button
            onClick={clearAvatar(fileUrl)}
            icon="x"
            variant="tertiary"
          />
        </CancelWrapper>
      </Avatar>
    );
  }

  return (
    <ImageUploadWrapper
      appModule={appModule}
      cropAppModule={cropAppModule}
      visibleModule={visibleModule}
      objectId={null}
      imageActionHooks={{}}
      uploadNoSubmit={onUpload}
    >
      <Button
        variant="secondary"
        onClick={preventDefaultAction}
        icon="upload"
      >
        {buttonText}
      </Button>
    </ImageUploadWrapper>
  );
};

UploadImageButton.defaultProps = {
  fileUrl: null,
};

const {
  func,
  string,
} = PropTypes;

UploadImageButton.propTypes = {
  buttonText: string.isRequired,
  appModule: string.isRequired,
  cropAppModule: string.isRequired,
  visibleModule: string.isRequired,
  fileUrl: string,
  onUpload: func.isRequired,
  clearAvatar: func.isRequired,
};

export default UploadImageButton;
