import { connect } from 'react-redux';

import deletePropertyViewing from 'store/actions/propertyViewings/deletePropertyViewing';
import updatePropertyViewing from 'store/actions/propertyViewings/updatePropertyViewing';

import {
 resetMatchingPropertyFilters,
  updateMatchingProperty,
} from 'store/actions/realtorMatchingProperties';

import ShowingTime from './ShowingTime';

const mapDispatchToProps = (dispatch, ownProps) => ({
  cancelShowing: async (propertyId) => {
    await dispatch(updateMatchingProperty(propertyId)({
      status: 5,
    }));

    dispatch(deletePropertyViewing(ownProps.showingId));
    dispatch(resetMatchingPropertyFilters());
  },

  rescheduleShowing: async (value) => {
    await dispatch(updatePropertyViewing(ownProps.showingId)({
      date_time: value,
    }));

    dispatch(resetMatchingPropertyFilters());
  },
});

export default connect(null, mapDispatchToProps)(ShowingTime);
