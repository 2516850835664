/**
 *
 * @param {Number} stepsSum completed weights sum
 * @returns {Number} next step weight
 */
const getNextStepFromCompletedSum = (stepsSum) => {
  if (stepsSum === 0) return 1;

  const nextStep = 2 ** (Math.ceil(Math.log2(stepsSum)));

  if (nextStep === stepsSum) return nextStep * 2;

  return nextStep;
};

export default getNextStepFromCompletedSum;
