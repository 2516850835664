import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.newFulfillmentForm,
  (data) => ({
    ...data,

    products: Object.values(data.products).map((item) => ({
      ...item,
      price_per_unit: +item.price_per_unit || +item.value,
      quantity: item.quantity || item.quantity === 0 ? +item.quantity : 1,
    })),
  }),
);
