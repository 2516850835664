import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

import noOp from 'shared/utils/noOp';

const getFiles = async ({
  setIsLoading,
  setFiles,
  setError,
}) => {
  setIsLoading(true);

  try {
    const files = await requests.dataImport.listUploads();
    setFiles(files);
  } catch (error) {
    setError(error);
  } finally {
    setIsLoading(false);
  }
};

const getFile = ({
  setFile,
  onSuccess = noOp,
}) => async (fileId) => {
  // setIsLoading(true);

  try {
    const response = await requests.dataImport.getRawFile(fileId);
    setFile(response.data);

    const data = new Blob([new Uint8Array(response.data.Body.data)]);

    onSuccess(data);
  } catch (error) {
    // setError(error);
  } finally {
    // setIsLoading(false);
  }
};

const useUploadedFiles = () => {
  const [files, setFiles] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [file, setFile] = useState(null);

  const updateList = () => getFiles({
    setFiles,
    setIsLoading,
    setError,
  });

  useEffect(() => {
    updateList();
  }, []);

  const getUploadedFile = (fileId, onSuccess) => getFile({
    setFile,
    onSuccess,
  })(fileId);

  return {
    files,
    isLoading,
    error,
    updateList,
    getUploadedFile,
    currentFile: file,
    setCurrentFile: setFile,
  };
};

export default useUploadedFiles;
