/* eslint-disable no-nested-ternary, max-len */

import styled from 'styled-components';
import Flex from 'ui/Flex';

export const BackgroundHighlight = styled(Flex)`
&& {
  background-color: ${(props) => props.theme.palette.backgroundFlat || ''};
  border-radius: 5px;
  box-shadow: -5px 0px 0px 5px ${(props) => props.theme.palette.backgroundFlat || ''};
}
`;

export const EqualityHighlight = styled.div`
  background-color: ${(props) => (props.show ? props.redHighlight ? 'rgba(206, 37, 37, 0.28)' : 'rgba(64, 185, 154, 0.3)' : '')};
  z-index: 9999;
  border-radius: 2px;
  box-shadow: ${(props) => (props.show ? props.redHighlight ? '0px 0px 0px 2px rgba(206, 37, 37, 0.28)' : ' 0px 0px 0px 2px rgba(64, 185, 154, 0.3)' : '')};
`;

export default {
  BackgroundHighlight,
};
