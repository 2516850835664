import { createSelector } from 'reselect';

import { isEmpty } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import { ROLE_LABELS, ROLE_COLORS } from 'constants/realtor.constants';

export default createSelector(
  (state) => state.realtorLeads.leads,
  (_, id) => id,
  (collection, id) => {
    const defaultItem = {
      id: null,
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorLeadsActions.getLeadById(id);
      }

      return defaultItem;
    }

    let leadRole;
    if (!isEmpty(item.properties) && !isEmpty(item.property_requirements)) {
      leadRole = 3;
    } else if (!isEmpty(item.properties)) {
      leadRole = 2;
    } else if (!isEmpty(item.property_requirements)) {
      leadRole = 1;
    }

    const labels = leadRole
      ? [{ name: ROLE_LABELS[leadRole], color: ROLE_COLORS[leadRole] }]
      : [];

    return {
      ...item,
      leadRole,
      labels,
    };
  },
);
