import React from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import ContactLogModal from 'sections/ContactLogs/ContactLogModal';

import useModal from 'ui/hooks/useModal';

const ContactLogButton = (props) => {
  const {
    buttonProps,
    onAddLog,
    children,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  return (
    <>
      <Button
        {...buttonProps} // eslint-disable-line react/jsx-props-no-spreading
        onClick={openModal}
      >
        {children}
      </Button>
      <ContactLogModal
        isModalVisible={isModalVisible}
        type="add"
        handleLoggingContactModal={closeModal}
        updateContactLog={onAddLog}
      />
    </>
  );
};

ContactLogButton.defaultProps = {
  buttonProps: {},
  children: null,
};

const {
  element,
  func,
  oneOfType,
  shape,
  string,
} = PropTypes;

ContactLogButton.propTypes = {
  buttonProps: shape(),
  onAddLog: func.isRequired,
  children: oneOfType([string, element]),
};

export default ContactLogButton;
