import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Card = styled.div`
  padding: 5px;
  background: ${(props) => props.theme.palette.background};
  border-radius: 10px;
  border-color: ${(props) => (
    props.error
      ? props.theme?.palette?.error
      : props.isSelected
        ? props.theme?.palette?.success
        : props.theme?.palette?.['input-border']
  )};
  border-style: solid;
  border-width: ${(props) => (props.isSelected ? 2 : 1)}px;
`;

export const InlineWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;
