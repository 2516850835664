import styled from 'styled-components';

const UploadWrapper = styled.div`
  .ant-upload-drag {
    height: 50px;
    background: transparent;
    border: 1px dashed #a1bdcb;
    border-radius: 6px;
    text-align: left;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
`;

export default UploadWrapper;
