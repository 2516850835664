import requests from 'api/requests';

import setTask from './setTask';
import getTeamTasksSummary from './getTeamTasksSummary';
import getEmployeeTasksSummary from './getEmployeeTasksSummary';
import getOverallTasksSummary from './getOverallTasksSummary';
import getEmployeeTasksSummaryForPeriod from './getEmployeeTasksSummaryForPeriod';

import clearFilterSummaries from './clearFilterSummaries';

const getTaskAndRefreshRelatedSummaries = (taskId, employeeId = null) => async (dispatch, getState) => {
  let task;

  try {
    const response = await requests.tasks.getTaskById(taskId, employeeId);

    task = response.data;

    dispatch(setTask(task));
  } catch (error) {
    console.error(error);
    return;
  }

  const {
    TeamID,
    Assignees = [],
  } = task;

  dispatch(getOverallTasksSummary());

  if (TeamID) {
    dispatch(getTeamTasksSummary(TeamID));
  }

  const {
    taskPlanner: {
      selectedTimePeriodTop,
      selectedTimePeriodBottom,
    },
  } = getState();

  Assignees.forEach((id) => {
    dispatch(getEmployeeTasksSummary(id));
    dispatch(getEmployeeTasksSummaryForPeriod(
      id,
      selectedTimePeriodTop,
    ));
    dispatch(getEmployeeTasksSummaryForPeriod(
      id,
      selectedTimePeriodBottom,
    ));
  });

  dispatch(clearFilterSummaries());

  try {
    const teamsForAssigneesResponse = await requests.teams.getTeams({
      employeeIds: Assignees,
    });

    teamsForAssigneesResponse.data.teams.forEach((team) => {
      dispatch(getTeamTasksSummary(team.TeamID));
    });
  } catch (error) {
    console.error(error);
  }
};

export default getTaskAndRefreshRelatedSummaries;
