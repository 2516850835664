import styled from 'styled-components';

export const Root = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  height: 24px;
  width: 24px;

  align-items: center;
  justify-content: space-around;

  border-radius: 4px; 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color: ${(props) => (props.checked
      ? props.theme?.palette?.highlight || 'green'
      : 'transparent')};

  border: ${(props) => {
    if (props.disabled) {
      return `1px solid ${props.theme?.palette?.disabled || 'gray'}`;
    }
      return (props.checked
        ? 'none'
        : `1px solid ${props.theme?.palette?.['input-border'] || '#d8e5ff'}`);
    }
  };

  box-sizing: border-box;
`;

export const Label = styled.label`
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')}
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
`;

export default {
  Root,
  Input,
  Label,
};
