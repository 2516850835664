import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_LOG_OFFER_FORM_STATE,
} from 'store/actionTypes/realtorOpportunities';

const openLogOfferModal = openModalWithContentAction('logOfferModal')(
  SET_LOG_OFFER_FORM_STATE,
);

export default openLogOfferModal;
