import { createSelector } from 'reselect';
import { SYMPLETE_ID } from 'constants/index';

const licenses = (state) => state.myCompany.licenses;

const mainLicense = createSelector(
  licenses,
  (items) => {
    const originalLicense = items.find((item) => (
      item.id === SYMPLETE_ID
    ));

    const license = originalLicense || {};

    return license;
  },
);

export default mainLicense;
