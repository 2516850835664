import apiConnector, { endpoints } from 'api';

/**
 * Sends GET request to retrieve the service people from server.
 * @param {Object} queryString - The query string for request.
 * @returns {Promise} A response from server.
 */
const get = async (queryString) => {
  const endpoint = endpoints.client.servicePeople;

  let response;

  try {
    response = await apiConnector.client.get(endpoint, queryString);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default get;
