import {
  SET_REALTOR_OPPORTUNITIES_FOR_FILTER,
} from 'store/actionTypes/realtorOpportunities';

const setOpportunitiesForFilter = (filterId) => (opportunities) => ({
  type: SET_REALTOR_OPPORTUNITIES_FOR_FILTER,
  payload: {
    filterId,
    opportunities,
  },
});

export default setOpportunitiesForFilter;
