import { connect } from 'react-redux';

import {
  setNewPropertyFormField,
} from 'store/actions/newRealtorPropertyForm';

import SearchContactInput from 'shared/inputs/SearchContactInput';

const mapStateToProps = (state) => ({
  label: 'Search in existing contacts',
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (contactId) => {
    ownProps.handleChange(contactId);
  },

  handleDelete: () => {
    dispatch(setNewPropertyFormField('listing_agent')(null));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactInput);
