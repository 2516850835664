import styled from 'styled-components';

export const StyledSelect = styled.ul`
  width: 100%;
  border: none;
  list-style: none;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
`;

export const StyledOption = styled.li`
  padding: 6px 7px;
  border-radius: 10px;
  text-transform: capitalize;
  cursor: pointer;

  .select-list-primary-label-ghost {
    display: none;
  }

  &&:hover {
    background-color: ${(props) => (props.theme?.palette?.['background-flat'])};

    .select-list-primary-label-ghost {
      display: block;
    }
  }
`;

export const CheckIconContainer = styled.div`
  visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
`;
