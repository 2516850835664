import { connect } from 'react-redux';

import {
  receiveLogout,
  triggerRoleModal,
} from 'store/actions/auth';

import RoleModal from './RoleModal';

const mapStateToProps = (state) => ({
  visible: state.auth.roleModal,
});

const mapDispatchToProps = {
  receiveLogout,
  triggerRoleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleModal);
