import requests from 'api/requests';

import setDigitalAccount from './setDigitalAccount';

const getDigitalAccountById = (id) => async (dispatch) => {
  try {
    const response = await requests.customerDigitalAccounts.getById(id);
    dispatch(setDigitalAccount(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getDigitalAccountById;
