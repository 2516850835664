import updateProspect from './updateProspect';

const updateSelectedProspect = (updateData) => async (dispatch, getState) => {
  const {
    realtorProspects: {
      selectedProspect,
    },
  } = getState();

  await dispatch(updateProspect(selectedProspect)(updateData));
};

export default updateSelectedProspect;
