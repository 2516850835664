import createLead from './createLead';

const tempDict = {
  cold: 1,
  warm: 2,
  hot: 3,
};

const createLeadFromNewLeadForm = () => async (dispatch, getState) => {
  const {
    newLeadForm,
    auth: {
      loggedUser: {
        EmployeeID: currentUserId,
      },
    },
  } = getState();

  const leadData = {
    title: newLeadForm.title,
    temp: tempDict[newLeadForm.temp] || null,
    company: newLeadForm.company,
    contact: newLeadForm.contact,
    source: newLeadForm.source,
    owners: {
      teams: newLeadForm.assignedTeams,
      employees: newLeadForm.assignedUsers,
    },
    products: [
      {
        id: newLeadForm.product,
        currency: newLeadForm.currency,
        value: newLeadForm.value,
      },
    ],
    permission_type: 2,
    permissions: {
      // add lead creator to permissions by default
      employees: [...new Set([...newLeadForm.assignedUsers, currentUserId])],
      teams: newLeadForm.assignedTeams,
    },
  };

  try {
    const leadId = await dispatch(createLead(leadData));
    return leadId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createLeadFromNewLeadForm;
