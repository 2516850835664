import requests from 'api/requests';

import setRepairRequestAttachments from './setRepairRequestAttachments';

const getRepairRequestAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(36)(id);

    dispatch(setRepairRequestAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getRepairRequestAttachments;
