import updateClient from './updateClient';

const updateSelectedClient = (updateData) => async (dispatch, getState) => {
  const {
    realtorClients: {
      selectedClient,
    },
  } = getState();

  await dispatch(updateClient(selectedClient)(updateData));
};

export default updateSelectedClient;
