import { connect } from 'react-redux';

import {
  changePassword,
  restoreAccessAndChangePassword,
  triggerChangeModal,
} from 'store/actions/auth';

import ChangePasswordForm from './ChangePasswordForm';

const mapStateToProps = (state) => {
  const {
    auth: {
      changeType,
      userReset,
    },
  } = state;

  return {
    changeType,
    userReset,
  };
};

const mapDispatchToProps = {
  changePassword,
  restoreAccessAndChangePassword,
  triggerChangeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordForm);
