/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Title = (props) => {
  const {
    children,
    className,
  } = props;

  return (
    <h2 className={classNames('modal-window__title', className)}>{children}</h2>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Title;
