/* eslint-disable camelcase */
import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import LogNewQuestionModal from './LogNewQuestionModal';

const closeLogNewQuestionModal = closeModal('newQuestion');

const mapStateToProps = (state) => {
  const {
    modals: {
      newQuestion,
    },
  } = state;

  const isVisible = newQuestion?.isVisible;

  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeLogNewQuestionModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogNewQuestionModal);
