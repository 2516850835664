import { connect } from 'react-redux';

import { triggerIntroModal } from 'store/actions/auth';
import {
  toggleAppTour,
} from 'store/actions/tutorial';

import IntroModal from './IntroModal';

const mapStateToProps = (state) => ({
  visible: state.auth.introModal,
  userName: state.auth.loggedUser.Name,
  tutorial: state.appTour,
});

const mapDispatchToProps = {
  triggerIntroModal,
  toggleAppTour,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroModal);
