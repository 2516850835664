import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import {
  Root,
  IncreaseButton,
  DecreaseButton,
} from './styledItems';

const UpDownButtons = (props) => {
  const {
    onIncrease,
    onDecrease,
  } = props;

  return (
    <Root
      tabIndex="-1"
      className="up-down-buttons"
    >
      <IncreaseButton
        className="up-down-buttons"
        onClick={onIncrease}
      >
        <Icon
          type="feather"
          name="chevron-up"
          color="main-text"
          size="s"
        />
      </IncreaseButton>
      <DecreaseButton
        className="up-down-buttons"
        onClick={onDecrease}
      >
        <Icon
          type="feather"
          name="chevron-down"
          color="main-text"
          size="s"
        />
      </DecreaseButton>
    </Root>
  );
};

UpDownButtons.defaultProps = {
  onIncrease: () => {},
  onDecrease: () => {},
};

const {
  func,
} = PropTypes;

UpDownButtons.propTypes = {
  onIncrease: func,
  onDecrease: func,
};

export default UpDownButtons;
