import newApiConnector, { endpoints } from 'api';
/**
 * Updates employee information
 * @param {number} employeeID Employee ID
 * @param {object} fields New values of the changed fields
 */
const updateEmployeeInfo = async (employeeID, fields) => {
  const endpoint = `${endpoints.client.employeesV2.employees}/${employeeID}`;

  await newApiConnector.client.patch(endpoint, fields);
};

export default updateEmployeeInfo;
