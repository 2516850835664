import {
  SET_CRM_LEADS_FOR_FILTER,
} from 'store/actionTypes/crm';

const setLeadsForFilter = (filterId) => (leads) => ({
  type: SET_CRM_LEADS_FOR_FILTER,
  payload: {
    filterId,
    leads,
  },
});

export default setLeadsForFilter;
