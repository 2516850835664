import requests from 'api/requests';

import setTasks from './setTasks';
import setTasksForTeam from './setTasksForTeam';

const getTeamProblemTasks = (
  isProblem,
) => (
  teamId,
) => (
  offset = 0,
  limit = 50,
) => async (dispatch) => {
  try {
    const tasksResponse = await requests.tasks.getTasks({
      offset,
      limit,
      teamId,
      isProblem,
    });

    dispatch(setTasks(tasksResponse.data.tasks));
    dispatch(setTasksForTeam(teamId)(tasksResponse.data.tasks));
  } catch (error) {
    console.error(error);
  }
};

export default getTeamProblemTasks;
