export const SHOW_ALL_PANELS = 'SHOW_ALL_PANELS';
export const showAllPanels = () => ({ type: SHOW_ALL_PANELS });

export const SET_PANELS = 'SET_PANELS';
export const setPanels = (panels) => ({ type: SET_PANELS, panels });

export const SET_TAB = 'SET_TAB';
export const setTab = (tab) => ({ type: SET_TAB, tab });

export const SET_FILTER = 'SET_FILTER';
export const setFilter = (filterType) => ({ type: SET_FILTER, filterType });

export const OPEN_MODAL = 'OPEN_LIST_VIEW_MODAL';
export const showModal = () => ({ type: OPEN_MODAL });

export const HIDE_MODAL = 'HIDE_LIST_VIEW_MODAL';
export const hideModal = () => ({ type: HIDE_MODAL });
