import requests from 'api/requests';

import setPayments from './setPayments';

const get = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.customerPayments.get({
      offset,
      limit,
    });

    dispatch(setPayments(response.data.customerPayments));
  } catch (error) {
    console.error(error);
  }
};

export default get;
