import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Elevation from 'ui/Elevation';
import ObjectDescriptionArea from 'shared/components/UI/ObjectDescriptionArea';

import {
  BorderedGrid,
} from './styledItems';

const indicators = [
  {
    key: 'leads',
    title: 'Leads',
    iconName: 'users',
  },
  {
    key: 'opportunities',
    title: 'Clients',
    iconName: 'star',
  },
  {
    key: 'offers',
    title: 'Offers',
    iconName: 'briefcase',
  },
  {
    key: 'transactions',
    title: 'Transactions',
    iconName: 'repeat',
  },
];

const CurrentOverview = (props) => {
  const {
    values,
  } = props;

  return (
    <Elevation
      rounded="m"
      padding="s"
    >
      <Grid container spacing={4}>
        {
          indicators.map(({
            key,
            title,
            iconName,
          }) => (
            <BorderedGrid
              key={key}
              item
              xs={3}
            >
              <ObjectDescriptionArea
                title={title}
                iconName={iconName}
                descriptionItems={values[key] || []}
              />
            </BorderedGrid>
          ))
        }
      </Grid>
    </Elevation>
  );
};

const {
  arrayOf,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

CurrentOverview.propTypes = {
  values: objectOf(arrayOf(shape({
    name: string,
    value: oneOfType([string, number]),
    textColor: string,
  }))).isRequired,
};

export default CurrentOverview;
