import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import withContactDataBasic from 'sections/Contacts/hocs/withContactDataBasic';
import RealtorContactAvatar from 'shared/components/UI/RealtorContactAvatar';
import Typography from 'ui/Typography';

const Avatar = withContactDataBasic(RealtorContactAvatar);

const Name = withContactDataBasic(({ firstName, lastName }) => (
  <Typography
    variant="h2"
    tag="p"
    noMargin
  >
    {firstName}
    {' '}
    {lastName}
  </Typography>
));

const Role = ({ children }) => (
  <Typography
    variant="h4"
    tag="p"
    color="focus"
    noMargin
  >
    {children}
  </Typography>
);

const Title = ({ children }) => (
  <Typography
    variant="h3"
    tag="p"
    color="descriptor-text"
    noMargin
  >
    {children}
  </Typography>
);

const RequestItemSummary = (props) => {
  const {
    contactId,
    contactRole,
    title,
  } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      {
        contactId && (
          <Grid item>
            <Avatar
              contactId={contactId}
              size={60}
              round
            />
          </Grid>
        )
      }

      <Grid item>
        <Grid
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="baseline"
            >
              {
                contactId && (
                  <Grid item>
                    <Name contactId={contactId} />
                  </Grid>
                )
              }

              {
                contactRole && (
                  <Grid item>
                    <Role>
                      {contactRole}
                    </Role>
                  </Grid>
                )
              }
            </Grid>
          </Grid>

          {
            title && (
              <Grid item xs={12}>
                <Title>
                  {title}
                </Title>
              </Grid>
            )
          }

        </Grid>
      </Grid>
    </Grid>
  );
};

RequestItemSummary.defaultProps = {
  contactId: null,
  contactRole: null,
  title: null,
};

const {
  number,
  string,
} = PropTypes;

RequestItemSummary.propTypes = {
  contactId: number,
  contactRole: string,
  title: string,
};

export default RequestItemSummary;
