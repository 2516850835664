import { connect } from 'react-redux';

import RealtorMasterSearch from 'shared/components/UI/RealtorMasterSearch';

const mapStateToProps = (state, ownProps) => {
  const {
    router: {
      location: {
        pathname,
      },
    },
  } = state;

  return {
    ...ownProps,
    pathname,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: () => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(RealtorMasterSearch);
