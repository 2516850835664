import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import HCaptcha from '@hcaptcha/react-hcaptcha';

import validate from 'shared/utils/validators';

import ExternalPage from 'shared/components/ExternalPage';

import Button from 'ui/Button';
import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import Elevation from 'ui/Elevation';
import Typography from 'ui/Typography';

import noOp from 'shared/utils/noOp';

const sitekey = process.env.REACT_APP_HCAPTCHA_SITEKEY;

const getExternalToken = (pathname) => {
  if (!pathname) return null;

  const captchaToken = pathname.split('/').pop();

  return captchaToken;
};

const validationErrors = {
  email_primary: 'Invalid email',
};

const renderTextfield = (control) => ({
  /* eslint-disable react/prop-types */
  name,
  label,
  type,
  defaultValue,
  validation,
  errors,
  mask,
  isTextArea,
  inputProps,
  style,
  /* eslint-enable react/prop-types */
}) => (
  <>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Textfield
          {...field} // eslint-disable-line react/jsx-props-no-spreading
          textarea={isTextArea}
          inputProps={inputProps}
          style={style}
          fullWidth
          label={label}
          type={type}
          mask={mask}
          showMask={!!mask}
          error={errors[name] && (validationErrors[name] || 'Invalid field')}
        />
      )}
      rules={
        validation
          ? {
              ...validation,
            }
          : {}
      }
    />
  </>
);

const ExternalLeads = (props) => {
  const location = useLocation();

  const [captchaToken, setCaptchaToken] = useState(null);
  const [mode, setMode] = useState('form');

  const captchaRef = useRef(null);

  useEffect(() => {
    const externalToken = getExternalToken(location.pathname);
    localStorage.setItem('externalToken', externalToken);
  }, [location?.pathname]);

  const { onSubmit: propsOnSubmit } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = useCallback((data) => {
    if (captchaToken) {
      propsOnSubmit(data);
      captchaRef.current.resetCaptcha();
      setMode('response');
    }
  });

  const onExpire = useCallback(() => {});

  const onError = useCallback((error) => {});

  return (
    <ExternalPage backgroundImage="https://www.mycomoxvalleynow.com/wp-content/uploads/2018/11/pexels-photo-106399.jpeg">
      <Flex
        fullWidth
        justifyContent="center"
        alignItems="center"
        style={{
          height: '100%',
          minHeight: 350,
        }}
      >
        {mode === 'response' && (
          <Elevation
            padding="m"
            rounded="m"
            style={{
              backgroundColor: 'white',
            }}
          >
            <Typography
              variant="heading"
              tag="p"
              align="center"
              color="focus"
            >
              Thank you!
            </Typography>

            <Typography
              variant="title1"
              tag="p"
              align="center"
              noMargin
              color="focus"
            >
              The agent will contact you soon.
            </Typography>
          </Elevation>
        )}
        {mode === 'form' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Elevation
              padding="m"
              rounded="m"
              style={{
                backgroundColor: 'white',
              }}
            >
              <Flex
                spacing={4}
                flexDirection="column"
              >
                {renderTextfield(control)({
                  name: 'first_name',
                  label: 'First Name',
                  validation: {
                    // required: true,
                  },
                  errors,
                })}
                {renderTextfield(control)({
                  name: 'last_name',
                  label: 'Last Name',
                  validation: {
                    // required: true,
                  },
                  errors,
                })}
                {renderTextfield(control)({
                  name: 'email_primary',
                  label: 'Email*',
                  type: 'email',
                  validation: {
                    required: true,
                    validate: (value) => validate.email(value),
                  },
                  errors,
                })}
                {renderTextfield(control)({
                  name: 'phone_primary',
                  label: 'Phone',
                  type: 'tel',
                  mask: 'phone',
                  validation: {
                    required: false,
                    validate: (value) => {
                      if (!value) {
                        return true;
                      }
                      return validate.phone(value);
                    },
                  },
                  errors,
                })}

                <HCaptcha
                  sitekey={sitekey}
                  onVerify={setCaptchaToken}
                  onError={onError}
                  onExpire={onExpire}
                  ref={captchaRef}
                />

                <Button
                  type="submit"
                  fullWidth
                  disabled={!captchaToken}
                  onClick={noOp}
                >
                  Submit
                </Button>
              </Flex>
            </Elevation>
          </form>
        )}
      </Flex>
    </ExternalPage>
  );
};

const { func } = PropTypes;

ExternalLeads.propTypes = {
  onSubmit: func.isRequired,
};

export default ExternalLeads;
