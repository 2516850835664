import { createSelector } from 'reselect';

// import {
//   optimizedCallsActions,
// } from 'store/actions/calls';

export default createSelector(
  (state) => state.calls.callLogs,
  (state, filterId) => state.calls.callLogsByFilter[filterId],
  (items, filteredIds) => (filteredIds
    ? filteredIds.reduce((acc, id) => {
      const log = items[id];

      if (!log && id) {
        // optimizedCallsActions.getCallLogById(id);
        return acc;
      }

      acc.push({
        ...log,
        phone_number: log.callee_phone_number,
      });
      return acc;
    }, [])
    : []),
);
