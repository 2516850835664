import { connect } from 'react-redux';
import newApiConnector from 'api';

import {
  saveEditorContent,
  editArticle,
  showLinkToUrl,
  showLinkToImg,
} from 'store/actions/articleDetail';

import { getThumbnailInf } from 'store/actions/thumbnails';

import RichTextEditor from './RichTextEditor';

const createMentions = async (mentionedEmployees, data, comment = null) => {
  const body = {
    employees: mentionedEmployees,
    product: data.product,
    objectId: data.objectId,
    objectName: data.objectName,
    creatorId: data.creatorId,
    comment,
  };
  await newApiConnector.client.post('api/common/employees/mentions', body);
};

const getCurrentObjectData = (objectsData) => {
  const {
    isTaskOpen,
    isTicketOpen,
    isLeadOpen,
    selectedTask,
    selectedTicket,
    lead,

    tasks,
    tickets,

    creatorId,
  } = objectsData;

  if (isTaskOpen) {
    return {
      product: 'tasks',
      objectId: tasks[selectedTask].TaskID,
      objectName: tasks[selectedTask].TaskName,
      creatorId,
    };
  }
  if (isTicketOpen) {
    return {
      product: 'tickets',
      objectId: tickets[selectedTicket].TicketID,
      objectName: tickets[selectedTicket].TicketName,
      creatorId,
    };
  }
  if (isLeadOpen) {
    return {
      product: 'sales',
      objectId: lead.LeadID,
      objectName: lead.Title,
      creatorId,
    };
  }
  return null;
};

const mapStateToProps = (state) => {
  const {
    tasks,
    tasksV2,
    tickets,
    ticketsV2,
    leads,
    auth,
  } = state;

  const objectsData = {
    isTaskOpen: tasks.isModalVisible,
    isTicketOpen: tickets.isModalVisible,
    isLeadOpen: leads.isPitchModalVisible,
    selectedTask: tasksV2.selectedTask,
    selectedTicket: ticketsV2.selectedTicket,
    lead: leads.leadDetails,

    tasks: tasksV2.tasks,
    tickets: ticketsV2.tickets,

    creatorId: auth.loggedUser?.EmployeeID,
  };
  const currentObject = getCurrentObjectData(objectsData);

  return {
    currentObject,
    createMentions,
    article: state.articleDetail.article,
    activeArticleId: state.articleDetail.activeArticleId,
    articleModalMode: state.articleDetail.articleModalMode,
    articleTextServer: state.articleDetail.articleTextServer,
    suggestedEmployees: state.myCompany.employees.map((employee) => (
      {
        name: `${employee.Name} ${employee.Surname}`,
        id: employee.EmployeeID,
      }
    )),
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveEditorContent: (content) => dispatch(saveEditorContent(content)),
  editArticle: (param, value, id) => dispatch(editArticle(param, value, id)),
  showLinkToUrl: () => dispatch(showLinkToUrl()),
  showLinkToImg: () => dispatch(showLinkToImg()),
  getThumbnailInf: (url) => dispatch(getThumbnailInf(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RichTextEditor);
