import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getEmailById from './getEmailById';
import createEmail from './createEmail';
import updateEmail from './updateEmail';

export {
  getEmailById,
  createEmail,
  updateEmail,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedEmailsActions = {
  getEmailById: memoizeDebounce(
    (id) => store.dispatch(getEmailById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
