import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withPlayer = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      contactId,
    } = ownProps;

    const {
      title,
      firstName,
      lastName,
      fullName,
    } = selectors.realtorContacts.contactName(state, contactId, { returnValue: 'object' });

    let avatar;

    if (contactId === 0) {
      const player = selectors.users.currentUser(state);
      avatar = player?.avatar;
    } else {
      const player = selectors.realtorContacts.basicById(state, contactId);
      avatar = player?.avatar;
    }

    return {
      name: firstName,
      surname: lastName,
      fullName,
      personTitle: title,
      avatar,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withPlayer;
