export const attorneyStateOrEscrow = `In an escrow state, an escrow company directs the closing of 
  a real estate transaction. 
  In an attorney state, an attorney takes the place 
  of the escrow company to facilitate closing. 
  Your choice will determine the way your transaction timeline is laid out in Symplete.`;

export const contingencyOrDueDiligence = `In your state, do your transactions rely on 
  contingency periods or due diligence? 
  Your choice will determine the way your 
  transaction timeline is laid out in Symplete.`;

export const leadConversionGoal = `Ideally, how many days should it take you to convert a
  lead to a client?
  This number is used in the leads part of the app 
  to show you a count down timer of days. 
  By default the counter will start at +7 days. When it hits day 0,
  it will start to count with a negative 
  number to show how many days this lead is over your goal to convert.`;
