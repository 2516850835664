import {
  SET_REALTOR_OFFER,
} from 'store/actionTypes/realtorOffers';

const setOffer = (offer) => ({
  type: SET_REALTOR_OFFER,
  payload: offer,
});

export default setOffer;
