import { connect } from 'react-redux';

import SearchContactInput from 'shared/inputs/SearchContactInput';

const mapStateToProps = (state) => {
  const {
    editPropertyViewingForm: {
      sellerAgent,
    },
  } = state;

  return {
    selectedContactId: sellerAgent,
    label: "Add seller's agent",
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (contactId) => {
    ownProps.handleChange(contactId);
  },

  handleDelete: () => {
    ownProps.handleChange(null);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactInput);
