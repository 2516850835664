/* eslint-disable consistent-return, react/jsx-props-no-spreading, react/prefer-stateless-function, no-nested-ternary */
import React from 'react';

import Tag from 'ui/Tag';

import { Wrapper, TagsContainer } from './styledItems';

const withTags = (WrappedComponent) => (tags, options = {}) => (props) => {
  const {
    position,
    size,
  } = options;

  if (!tags) return <WrappedComponent {...props} />;

  return (
    <Wrapper>
      <TagsContainer position={position}>
        {
          Array.isArray(tags)
            ? (
                tags.map((tag) => {
                  if (typeof tag === 'string') {
                    return (
                      <Tag
                        size={size}
                        variant={tag}
                      >
                        {tag.toUpperCase()}
                      </Tag>
                    );
                  }

                  return (
                    <Tag
                      size={size}
                      variant={tag.color || tag.text}
                    >
                      {tag.text.toUpperCase()}
                    </Tag>
                  );
                })
              )
            : typeof tags === 'string'
              ? (
                <Tag
                  size={size}
                  variant={tags}
                >
                  {tags.toUpperCase()}
                </Tag>

                )
              : (
                <Tag
                  size={size}
                  variant={tags.color || tags.text}
                >
                  {tags.text.toUpperCase()}
                </Tag>
                )
        }
      </TagsContainer>
      <WrappedComponent {...props} />
    </Wrapper>
  );
};

 export default withTags;
