import { connect } from 'react-redux';

import { updateContact } from 'store/actions/realtorContacts';

import selectors from 'store/selectors';

import SocialLinks from './SocialLinks';

const mapStateToProps = (state, ownProps) => {
  const contact = selectors.realtorContacts.byId(state, ownProps.contactId);

  return {
    firstName: contact?.first_name,
    links: [
      {
        name: 'facebook',
        url: contact?.facebook_url,
        icon: 'facebook',
      },
      {
        name: 'twitter',
        url: contact?.twitter_url,
        icon: 'twitter',
      },
      {
        name: 'linkedin',
        url: contact?.linkedin_url,
        icon: 'linkedin',
      },
      {
        name: 'instagram',
        url: contact?.instagram_url,
        icon: 'instagram',
      },
      {
        name: 'website',
        url: contact?.website_url,
        icon: 'globe',
      },
    ],
    hasPhoneNumbers: !!contact?.phone_numbers,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateLink: (type, url) => {
    dispatch(updateContact(ownProps.contactId)({ [type]: url }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialLinks);
