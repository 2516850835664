import upload from './upload';
import importFromFile from './importFromFile';
import listUploads from './listUploads';
import getRawFile from './getRawFile';
import getFileConflicts from './getFileConflicts';

export default {
  upload,
  importFromFile,
  listUploads,
  getRawFile,
  getFileConflicts,
};
