import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  setFilterItem,
} from 'store/actions/callLogsFilter';

import {
  getCallLogsForStoreFilter,
} from 'store/actions/calls';

import SearchFilter from 'ui/SearchFilter';

const mapStateToProps = (state) => {
  const {
    callLogsFilter,
  } = state;

  return {
    searchValue: callLogsFilter.search || '',
    withDropdownFilter: false,
    searchInputPlaceholder: 'Search all logged calls',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    dispatch(setFilterItem('search')(search));
    dispatch(getCallLogsForStoreFilter());
  }, 500),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
