import styled from 'styled-components';

export const ChipWrapper = styled.div`
  .select-form-chip {
    cursor: pointer;
    button {
      cursor: pointer;
    }
  }
`;

export default {
  ChipWrapper,
};
