import { createSelector } from 'reselect';

import { forOwn } from 'shared/utility';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.newFulfillmentForm.products,
  (products, selectedProducts) => {
    if (!selectedProducts) {
      return [];
    }

    const items = [];

    forOwn(selectedProducts, (product, key) => {
      const dataObject = products[product.id];

      if (!dataObject && product.id) {
        optimizedCrmProductsActions.getProductById(product.id);
      }

      items.push({
        ...product,
        rowId: key, // product id might change so we need a unique key for rows
        price_per_unit: +product.price_per_unit || +product.value || 0,
        quantity: product.quantity || product.quantity === 0
          ? +product.quantity
          : 1,
        title: dataObject?.name,
      });
    });

    return items;
  },
);
