export const GET_COMMENT_TYPES_SUCCESS = 'GET_COMMENT_TYPES_SUCCESS';
export const GET_COMMENT_TYPES_ERROR = 'GET_COMMENT_TYPES_ERROR';
export const SET_COMMENTS_FOR_OBJECT = 'SET_COMMENTS_FOR_OBJECT';
export const SET_CREATOR = 'SET_CREATOR';

export default {
  GET_COMMENT_TYPES_SUCCESS,
  GET_COMMENT_TYPES_ERROR,
  SET_COMMENTS_FOR_OBJECT,
  SET_CREATOR,
};
