import styled from 'styled-components';

export const RoadMapItemWrapper = styled.div`
  border-radius: 10px;
  width: 160px;
  padding: 33px 20px;
  background-color: ${(props) => props.theme?.palette?.['background-flat-dark']};
`;

export default {
  RoadMapItemWrapper,
};
