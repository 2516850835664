import React from 'react';
import PropTypes from 'prop-types';

import { Button, Popconfirm } from 'antd';

const DeleteButton = (props) => {
  const {
    onDelete,
  } = props;

  return (
    <Popconfirm
      title="Are you sure to delete?"
      placement="topRight"
      onConfirm={onDelete}
    >
      <Button
        type="primary"
        icon="delete"
        size="small"
      />
    </Popconfirm>
  );
};

const {
  func,
} = PropTypes;

DeleteButton.propTypes = {
  onDelete: func.isRequired,
};

export default DeleteButton;
