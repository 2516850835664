import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import Avatar from 'shared/components/UI/RealtorContactAvatar';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    avatar,
    name,
    surname,
  } = selectors.realtorContacts.basicById(state, contactId);

  const avatarImageSrc = getAvatarUrl(avatar);

  return {
    src: avatarImageSrc,
    firstName: name,
    lastName: surname,
    size: 78,
  };
};

export default connect(mapStateToProps, null)(Avatar);
