import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_OPPORTUNITIES,
  SET_REALTOR_OPPORTUNITIES_FOR_FILTER,
  SET_REALTOR_OPPORTUNITIES_FOR_FILTER_COUNT,
  SET_REALTOR_OPPORTUNITIES_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorOpportunities';

const getId = (item) => `${item.type}_${item.id}`;

const listToObjectByKey = (list) => list.reduce((acc, item) => {
  const id = getId(item);

  acc[id] = item;

  return acc;
}, {});

const getIds = (list = []) => list.map(getId);

const initialState = {
  opportunities: {},
  realtorOpportunitiesByFilter: {},
  realtorOpportunitiesByFilterCount: {},
  overallSummary: null,
};

const reducers = {
  [SET_REALTOR_OPPORTUNITIES]: (state, action) => ({
    ...state,
    opportunities: {
      ...state.opportunities,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_OPPORTUNITIES_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      opportunities,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(opportunities),
    ]);

    const updatedState = {
      ...state,
      realtorOpportunitiesByFilter: {
        ...state.realtorOpportunitiesByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_OPPORTUNITIES_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    realtorOpportunitiesByFilterCount: {
      ...state.realtorOpportunitiesByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_OPPORTUNITIES_OVERALL_SUMMARY]: (state, action) => ({
    ...state,
    overallSummary: action.payload,
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
