import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedCrmSourcesActions,
  createSourceType,
  createSource,
} from 'store/actions/crm/crmSources';

import SourceSelect from './SourceSelect';

const mapStateToProps = (state) => {
  const sourceTypes = selectors.crm.sourceTypes(state);

  if (!sourceTypes) {
    optimizedCrmSourcesActions.getSourceTypes();
  }

  return {
    options: sourceTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  createSource: async (sourceData) => {
    const sourceId = await dispatch(createSource(sourceData));

    ownProps.onSourceChange(sourceId);
  },

  createSourceType: async (name) => {
    const newSourceTypeId = await dispatch(createSourceType({ name }));

    return newSourceTypeId;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceSelect);
