import styled from 'styled-components';

import Chip from 'ui/Chip';

export const Wrapper = styled.div`

`;

export const StyledChip = styled(Chip)`
  margin: 10px 5px;
`;
