import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import CommentContext from 'shared/contexts/CommentContext';

import Comment from 'shared/components/CommentV2';
import LogItem from 'shared/components/LogItem';
import CallRecordingLogItem from 'shared/components/CallRecordingLogItem';
import CommentEditModal from 'shared/components/CommentEditModal';

// This dependency cycle is created on purpose to allow infinite thread nesting
import CommentThread from 'shared/components/CommentThreadV2'; // eslint-disable-line import/no-cycle

import { prepareComments } from './utils';

const commentTypesCodesMap = {
  1: 'question',
  2: 'action',
  3: 'summary',
  4: 'problem',
  5: 'idea',
};

const CommentsList = (props) => {
  const {
    comments,
    onLike,
    onDelete,
    onEdit,
    onResolve,
    markAsSeen,
    onUpdate,
    customCallbacks,
  } = props;

  const deleteTicketComment = useCallback((commentId) => () => {
    onDelete(commentId);
  }, [onDelete]);

  const acknowledgeComment = useCallback((commentId) => () => {
    onLike(commentId);
  }, [onLike]);

  const resolveComment = useCallback((commentId) => () => {
    onResolve(commentId);
  }, [onResolve]);

  const [commentThreadVisible, setCommentThreadVisible] = useState(false);

  const [selectedCommentedObjectId, setSelectedCommentedObjectId] = useState(null);
  const [selectedCommentedObjectType, setSelectedCommentedObjectType] = useState('comment');

  const showCommentThreadModal = useCallback((commentedObjectType = 'comment') => (id) => () => {
    setSelectedCommentedObjectType(commentedObjectType);
    setSelectedCommentedObjectId(id);
    setCommentThreadVisible(true);
  }, [
    setSelectedCommentedObjectType,
    setSelectedCommentedObjectId,
    setCommentThreadVisible,
  ]);

  const hideCommentThreadModal = useCallback(() => {
    setCommentThreadVisible(false);
    setSelectedCommentedObjectType('comment');
    setSelectedCommentedObjectId(null);
    onUpdate();
  }, [
    setSelectedCommentedObjectType,
    setSelectedCommentedObjectId,
    setCommentThreadVisible,
  ]);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedCommentText, setSelectedCommentText] = useState(null);

  const showEditModal = useCallback(({ commentId, commentText }) => () => {
    setSelectedCommentedObjectId(commentId);
    setSelectedCommentText(commentText);
    setEditModalVisible(true);
  }, [
    setSelectedCommentedObjectId,
    setSelectedCommentText,
    setEditModalVisible,
  ]);

  const hideEditModal = useCallback(() => {
    setSelectedCommentedObjectId(null);
    setSelectedCommentText(null);
    setEditModalVisible(false);
    onUpdate();
  }, [
    setSelectedCommentedObjectId,
    setSelectedCommentText,
    setEditModalVisible,
  ]);

  const editComment = useCallback(async (commentText) => {
    hideEditModal();

    const commentResponse = await onEdit({
      commentId: selectedCommentedObjectId,
      commentText,
    });
    return commentResponse;
  }, [
    onEdit,
    selectedCommentedObjectId,
  ]);

  const preparedComments = prepareComments(comments);

  return (
    <>
      {
        preparedComments.map((comment) => {
          const {
            render,
          } = comment;

          if (render) return comment.render;

          const {
            type,
            CreatorID,
            CreatorType,
          } = comment;

          const creator = {
            id: CreatorID,
            type: CreatorType,
          };

          if (type === 'log') {
            const {
              LogID,
              AcknowledgedBy,
              ContactType,
              CreatedAt,
              Status,
              Text,
              Title,
              IsOwner,
              RepliesCount,
              ReplyCreators,
            } = comment;

            const commentators = ReplyCreators.map((item) => ({
              id: item.CreatorID,
              type: item.CreatorType,
            }));

            return (
              <LogItem
                createdAt={moment(CreatedAt).format('hh:mm A')}
                creator={creator}
                text={Text}
                status={Status}
                title={Title}
                contactType={ContactType}
                isOwner={IsOwner}
                acknowledgedBy={AcknowledgedBy}
                replyCount={RepliesCount}
                replyCreators={commentators}
                onClickDelete={customCallbacks.log.onDelete(LogID)}
                onClickLike={customCallbacks.log.onLike(LogID)}
                onClickThread={showCommentThreadModal('log')(LogID)}
                onClickReplyCount={showCommentThreadModal('log')(LogID)}
              />
            );
          }

          if (type === 'call recording') {
            const {
              RecordingSid,
              CreatedAt,
              Status,
              ContactId,
            } = comment;

            return (
              <CallRecordingLogItem
                createdAt={moment(CreatedAt).format('hh:mm A')}
                callRecordingSid={RecordingSid}
                status={Status}
                onClickDelete={customCallbacks.callRecording.onDelete(RecordingSid, ContactId)}
              />
            );
          }

          const {
            Comment: commentText,
            CommentID,
            CommentType,
            CreatedAt,
            IsOwner,
            IsPublic,
            RepliesCount,
            ReplyCreators,
            SeenBy,
            AcknowledgedBy,
            ResolvedBy,
          } = comment;

          markAsSeen(CommentID, SeenBy);

          const commentators = ReplyCreators.map((item) => ({
            id: item.CreatorID,
            type: item.CreatorType,
          }));

          const editableItem = {
            commentId: CommentID,
            commentText,
          };

          return (
            <CommentContext.Consumer>
              {
                (commentedObjectType) => {
                  const shouldShowStatus = commentedObjectType === 'ticket' && !IsPublic;
                  const status = shouldShowStatus && 'not visible to customer';

                  return (
                    <Comment
                      key={CommentID}
                      createdAt={moment(CreatedAt).format('hh:mm A')}
                      creator={creator}
                      isOwner={IsOwner}
                      status={status}
                      resolvedBy={ResolvedBy}
                      acknowledgedBy={AcknowledgedBy}
                      onClickDelete={deleteTicketComment(CommentID)}
                      onClickEdit={showEditModal(editableItem)}
                      onClickLike={acknowledgeComment(CommentID)}
                      onClickThread={showCommentThreadModal('comment')(CommentID)}
                      onClickReplyCount={showCommentThreadModal('comment')(CommentID)}
                      onClickResolve={resolveComment(CommentID)}
                      replyCount={RepliesCount}
                      replyCreators={commentators}
                      text={commentText}
                      type={commentTypesCodesMap[CommentType]}
                    />
                  );
                }
              }
            </CommentContext.Consumer>
          );
        })
      }

      <CommentThread
        isVisible={commentThreadVisible}
        onCancel={hideCommentThreadModal}
        commentedObjectType={selectedCommentedObjectType}
        commentedObjectId={selectedCommentedObjectId}
      />

      <CommentEditModal
        isVisible={editModalVisible}
        onCancel={hideEditModal}
        editComment={editComment}
        selectedCommentText={selectedCommentText}
      />
    </>
  );
};

const noOp = () => {};

CommentsList.defaultProps = {
  comments: [],
  onDelete: noOp,
  onEdit: noOp,
  onLike: noOp,
  onResolve: noOp,
  markAsSeen: noOp,
  customCallbacks: {
    log: {
      onDelete: noOp,
      onLike: noOp,
    },
    callRecording: {
      onDelete: noOp,
    },
  },
};

const {
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

const commentCreatorShape = shape({
  CreatorId: number,
  CreatorType: number,
});

const userShape = shape({
  UserId: number,
  UserType: number,
  CreateAt: number,
});

const commentShape = shape({
  Comment: string.isRequired,
  CommentID: number,
  CommentType: number,
  CommentedObjectID: number,
  CommentedObjectType: number,
  CreatedAt: oneOfType([instanceOf(Date), string, number]).isRequired,
  CreatorID: number.isRequired,
  CreatorType: number.isRequired,
  IsOwner: bool,
  IsPublic: bool,
  RepliesCount: number,
  ReplyCreators: arrayOf(commentCreatorShape),
  SeenBy: arrayOf(userShape),
  AcknowledgedBy: arrayOf(userShape),
  ResolvedBy: arrayOf(userShape),
});

const logShape = shape({
  type: string,
  LogID: number,
  CreatedAt: oneOfType([instanceOf(Date), string, number]).isRequired,
  CreatorID: number,
  CreatorType: number,
  Text: string,
  Title: string,
  Status: string,
  ContactType: string,
});

const callRecordingShape = shape({
  type: string,
  CreatedAt: oneOfType([instanceOf(Date), string, number]).isRequired,
  Status: string,
  RecordingSid: string,
  ContactId: number,
});

const customItemShape = shape({
  render: element.isRequired,
});

CommentsList.propTypes = {
  comments: arrayOf(oneOfType([commentShape, logShape, callRecordingShape, customItemShape])),
  onUpdate: func.isRequired,
  onDelete: func,
  onEdit: func,
  onLike: func,
  markAsSeen: func,
  onResolve: func,
  customCallbacks: shape({
    log: shape({
      onDelete: func,
      onLike: func,
    }),
    callRecording: shape({
      onDelete: func,
    }),
  }),
};

export default CommentsList;
