import { createSelector } from 'reselect';

import { optimizedDictionaryActions } from 'store/actions/dictionaryV2';

const titles = (state) => state.dictionaryV2.personTitles;

export default createSelector(
  titles,
  (collection) => {
    if (!collection) {
      optimizedDictionaryActions.getPersonTitlesDictionary();
      return [];
    }

    return collection.map((item) => ({
      id: item.id,
      name: item.name,
      isActive: item.is_active,
    }));
  },
);
