import connectReducers from 'store/connectReducers';

import {
  SET_OFFER_DECLINE_REASON_MODAL_CLOSE,
  SET_OFFER_DECLINE_REASON_MODAL_OPEN,
} from 'store/actionTypes/realtorOpportunities';

const initialState = {
  isVisible: false,
  offerId: null,
  readOnly: false,
};

const reducers = {
  [SET_OFFER_DECLINE_REASON_MODAL_CLOSE]: (state) => ({
    ...state,
    isVisible: false,
  }),

  [SET_OFFER_DECLINE_REASON_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    offerId: action?.payload?.offerId,
    readOnly: action?.payload?.readOnly,
  }),
};

function offerDeclineReasonModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default offerDeclineReasonModal;
