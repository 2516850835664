import store from 'store';

import requests from 'api/requests';

import {
  createClient,
} from 'store/actions/realtorClients';

const getClientId = async (offerFormData) => {
  try {
    if (!offerFormData?.seller) return null;

    let foundOrCreatedClientId = null;

    const clientsResponse = await requests.realtor.clients.get({
      offset: 0,
      limit: 1,
      propertyId: offerFormData.propertyId,
      contactId: offerFormData.seller,
    });

    const clients = clientsResponse.data.items;

    if (clients[0]) {
      foundOrCreatedClientId = clients[0].id;
    } else {
      foundOrCreatedClientId = await store.dispatch(createClient({
        contact_id: offerFormData.seller,
        realtor_property_id: offerFormData.propertyId,
        listing_price: offerFormData.offerAmount,
        is_exclusive: false,
        is_pocket_listing: false,
      }));
    }

    return foundOrCreatedClientId;
  } catch (error) {
    return null;
  }
};

export default getClientId;
