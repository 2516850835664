import { connect } from 'react-redux';

import { isEmpty, union } from 'shared/utility';

import selectors from 'store/selectors';

import ShareModal from './ShareModal';

const mapStateToProps = (state, ownProps) => {
  const {
    contactsIds = [],
  } = ownProps;

  let contactsEmails = [];

  if (!isEmpty(contactsIds)) {
    contactsEmails = contactsIds.reduce((accum, contactId) => {
      if (!contactId) {
        return accum;
      }
      const contact = selectors.realtorContacts.byId(state, contactId);
      const contactEmail = contact?.email_primary;

      if (contactEmail) {
        accum.push(contactEmail);
      }

      return accum;
    }, []);
  }

  return {
    contactsEmails,
  };
};

const mergeProps = (stateProps, _, ownProps) => ({
  ...ownProps,
  emails: union(ownProps.emails.filter((value) => value), stateProps.contactsEmails),
});

export default connect(mapStateToProps, null, mergeProps)(ShareModal);
