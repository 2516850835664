import styled from 'styled-components';

import HouseDefault from 'assets/icons/property.svg';

export const Root = styled.div`
  width: 100%;
  padding: 24px 10px 24px 10px; 
  background-color: white;;
  border-bottom: 1px soild ${(props) => props.theme?.palette?.backgroundFlat};
  gap: 16px;
`;

export const Image = styled.div`
  width: 100%;
  max-width: 80px;
  height: 64px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
`;

export default {
  Root,
};
