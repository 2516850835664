import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 7px;
  border: solid 2px ${(props) => props.theme?.palette?.highlight || '#1584ff'};
  padding: 0 5px;
`;

export default {
  Wrapper,
};
