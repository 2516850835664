import {
  SET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER,
} from 'store/actionTypes/realtorExtensionRequests';

const setForFilter = (filterId) => (items) => ({
  type: SET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER,
  payload: {
    filterId,
    items,
  },
});

export default setForFilter;
