import {
  SET_FULFILLMENT_ATTACHMENTS,
} from 'store/actionTypes/fulfillments';

const setFulfillmentAttachments = (id, attachments) => ({
  type: SET_FULFILLMENT_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setFulfillmentAttachments;
