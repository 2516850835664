const formats = {
  currency: {
    short: '$0.[00]a',
    full: '$0,0[.]00',
  },
  numbers: {
    pretty: {
      int: '0,0',
      dec: '0,0[.]00',
    },
  },
  dateTime: {
    full: 'YYYY-MM-DD HH:mm:ss',
    timeDate: 'hh:mm A, MMM DD',
  },
};

export default formats;
