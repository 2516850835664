import apiConnector, { endpoints } from 'api';

// {
//   "title": "string",
//   "temp": 0,
//   "status": 0,
//   "company": 0,
//   "contact": 0,
//   "source": 0,
//   "claimed_by": 0,
//   "permission_type": 0,
//   "permissions": {
//     "add": {
//       "teams": [
//         0
//       ],
//       "offices": [
//         0
//       ],
//       "employees": [
//         0
//       ]
//     },
//     "remove": {
//       "teams": [
//         0
//       ],
//       "offices": [
//         0
//       ],
//       "employees": [
//         0
//       ]
//     },
//     "overwrite": {
//       "teams": [
//         0
//       ],
//       "offices": [
//         0
//       ],
//       "employees": [
//         0
//       ]
//     }
//   },
//   "owners": {
//     "add": {
//       "teams": [
//         0
//       ],
//       "employees": [
//         0
//       ]
//     },
//     "remove": {
//       "teams": [
//         0
//       ],
//       "employees": [
//         0
//       ]
//     },
//     "overwrite": {
//       "teams": [
//         0
//       ],
//       "employees": [
//         0
//       ]
//     }
//   },
//   "campaigns": {
//     "add": [
//       0
//     ],
//     "remove": [
//       0
//     ],
//     "overwrite": [
//       0
//     ]
//   },
//   "products": {
//     "add": [
//       {}
//     ],
//     "remove": [
//       {}
//     ],
//     "overwrite": [
//       {}
//     ]
//   }
// }
const update = (leadId) => async (updateData) => {
  const endpoint = `${endpoints.client.crm.leads}/${leadId}`;

  try {
    const result = await apiConnector.client.put(endpoint, updateData);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default update;
