import { connect } from 'react-redux';

import NewContactArea from 'shared/inputs/NewContactAreaWithRHF';

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: (onChange) => (value) => {
    onChange(value);
  },

  onInvalidField: (fieldName) => (property, value) => {
    // ownProps.setValue('fieldsValidators')(property)(value);
  },
});

export default connect(null, mapDispatchToProps)(NewContactArea);
