import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  width: 290px;
  padding: 10px 22px;
  border-radius: 10px;
  background-color: ${(props) => props.theme?.palette?.['background-flat']};
`;

export const LocationTagWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme?.palette?.['background-flat-dark']};

`;

export default {
  ProfileWrapper,
  LocationTagWrapper,
};
