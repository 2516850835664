import { connect } from 'react-redux';

const withSelectedContact = (Component) => {
  const mapStateToProps = (state) => {
    const {
      realtorContacts: {
        selectedContact,
      },
    } = state;

    return {
      selectedContact,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withSelectedContact;
