import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_FOLLOW_UP_REMINDERS_MODAL_OPEN,
} from 'store/actionTypes/followUpReminders';

const openFollowUpReminderModal = openModalWithContentAction('followUpReminders')(
  SET_FOLLOW_UP_REMINDERS_MODAL_OPEN,
);

export default openFollowUpReminderModal;
