import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';
import { createSourceFromRealtorNewContactForm } from 'store/actions/realtorContacts';

import SourceDescriptionModal from './SourceDescriptionModal';

const mapDispatchToProps = (dispatch) => ({
  onSave: async () => {
    const sourceId = await dispatch(createSourceFromRealtorNewContactForm());
    dispatch(setNewContactFormField('source')(sourceId));
  },
  onCancel: () => {
    dispatch(setNewContactFormField('sourceDescription')(null));
    dispatch(setNewContactFormField('sourceType')(null));
  },
});

export default connect(null, mapDispatchToProps)(SourceDescriptionModal);
