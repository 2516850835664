import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getInspectionById from './getInspectionById';

const updateInspection = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.inspections.update(id)(updateData);

    callAlert.success('Updated successfully');

    dispatch(getInspectionById(id));
  } catch (error) {
    console.error(error);
    callAlert.error('Could not update');
  }
};

export default updateInspection;
