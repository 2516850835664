export const SET_TEAM = 'SET_TEAM';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAMS_COUNT = 'SET_TEAMS_COUNT';
export const REMOVE_TEAM = 'REMOVE_TEAM';

export default {
  SET_TEAM,
  SET_TEAMS,
  SET_TEAMS_COUNT,
  REMOVE_TEAM,
};
