/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Typography from 'ui/Typography';
import Divider from 'ui/Divider';

import ContactInfoMain from 'sections/Contacts/modules/ContactInfoMain';
import PastClient from 'sections/Contacts/modules/PastClient';
import SocialLinks from 'sections/Contacts/modules/SocialLinks';
import ContactDescription from 'sections/Contacts/modules/ContactDescription';
import ContactLikes from 'sections/Contacts/modules/ContactLikes';
import ContactDisikes from 'sections/Contacts/modules/ContactDislikes';
import ContactLabelsRaw from 'sections/Contacts/modules/ContactLabels';
import ContactStatusRaw from 'sections/Contacts/modules/ContactStatus';
import Options from 'sections/Contacts/modules/OptionsV2';
import Call from 'sections/Contacts/modules/Call';

import EmailButton from 'sections/Contacts/modules/EmailButton';

import FirstContact from 'sections/Leads/modules/FirstContact';
import LastContact from 'sections/Leads/modules/LastContact';
import NextContact from 'sections/Leads/modules/NextContact';
import AssociatedContacts from 'sections/Contacts/modules/AssociatedContacts';

import withInlineLabel from 'shared/hocs/withInlineLabelV2';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

const ContactLabels = withInlineLabel(ContactLabelsRaw);
const ContactStatus = withInlineLabel(ContactStatusRaw);

const ContactInfo = (props) => {
  const {
    contactId,
  } = props;

  return (
    <Flex
      spacing={2}
      flexWrap="wrap"
    >
      <Flex
        fullWidth
        justifyContent="space-between"
      >
        <ContactInfoMain contactId={contactId} />
        <Options
          contactId={contactId}
          buttonProps={{
            variant: 'secondary',
          }}
        />
      </Flex>

      <Divider />

      {
        (!isSectionUnderConstruction('calls') || !isSectionUnderConstruction('integrations')) && (
          <>
            <FlexItem fullWidth>
              <Flex
                justifyContent="center"
                spacing={2}
              >
                <Call
                  showText
                  contactId={contactId}
                />
                <EmailButton
                  showText
                  contactId={contactId}
                />
              </Flex>
            </FlexItem>

            <Divider />
          </>
        )
      }

      <FlexItem fullWidth>
        <Flex justifyContent="space-between" alignItems="baseline">
          <SocialLinks
            contactId={contactId}
          />

          <PastClient contactId={contactId} />
        </Flex>
      </FlexItem>

      <Divider />

      <Flex
        spacing={2}
        fullWidth
        flexDirection="column"
      >
        <FlexItem fullWidth>
          <Typography
            variant="title2"
            tag="h4"
            noMargin
          >
            Attributes
          </Typography>
        </FlexItem>
        <FlexItem fullWidth>
          <ContactStatus
            contactId={contactId}
            label="Status:"
            alignItems="center"
            labelProps={{
              weight: 500,
              noMargin: true,
              style: { marginRight: 10 },
            }}
          />
        </FlexItem>
        <FlexItem fullWidth>
          <ContactLabels
            contactId={contactId}
            label="Labels:"
            alignItems="center"
            labelProps={{
              weight: 500,
              noMargin: true,
              style: { marginRight: 10 },
            }}
          />
        </FlexItem>
      </Flex>

      <FlexItem fullWidth>
        <Typography
          variant="title2"
          tag="h4"
        >
          Notes
        </Typography>
        <div style={{ maxHeight: 140 }}>
          <ContactDescription
            contactId={contactId}
          />
        </div>
      </FlexItem>

      <Flex
        spacing={2}
        fullWidth
      >
        <FlexItem xs={12}>
          <Typography
            variant="title2"
            tag="h4"
          >
            Likes
          </Typography>
          <ContactLikes contactId={contactId} />
        </FlexItem>

        <FlexItem xs={12}>
          <Typography
            variant="title2"
            tag="h4"
          >
            Dislikes
          </Typography>
          <ContactDisikes contactId={contactId} />
        </FlexItem>
      </Flex>

      <section>
        <Typography
          variant="title2"
          tag="h4"
        >
          Details
        </Typography>

        <Flex
          spacing={1}
          flexDirection="column"
        >
          <Flex spacing={1} alignItems="baseline">
            <Typography color="ghost">First Contact:</Typography>
            <FirstContact contactId={contactId} />
          </Flex>
          <Flex spacing={1} alignItems="baseline">
            <Typography color="ghost">Last Contact:</Typography>
            <LastContact contactId={contactId} />
          </Flex>
          <Flex spacing={1} alignItems="baseline">
            <Typography color="ghost">Next Contact:</Typography>
            <NextContact contactId={contactId} />
          </Flex>
        </Flex>
      </section>

      <FlexItem fullWidth>
        <AssociatedContacts contactId={contactId} />
      </FlexItem>
    </Flex>
  );
};

ContactInfo.defaultProps = {
  contactId: null,
};

const {
  number,
} = PropTypes;

ContactInfo.propTypes = {
  contactId: number,
};

export default ContactInfo;
