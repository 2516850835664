import React from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import CancelTransactionForm from 'sections/Transactions/forms/CancelTransactionForm';

const formId = 'cancel-transaction-form';

const CancelTransactionModal = (props) => {
  const {
    isVisible,
    onSubmit,
    closeModal,
    theme,
  } = props;

  return (
    <Modal
      theme={theme}
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      title="Please provide a reason for cancelling this transaction"
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          htmlType="submit"
          form={formId}
        >
          Save
        </Button>,
      ]}
    >
      <CancelTransactionForm
        formId={formId}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

CancelTransactionModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  func,
  bool,
  shape,
} = PropTypes;

CancelTransactionModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
  onSubmit: func.isRequired,
};

export default CancelTransactionModal;
