import styled from 'styled-components';

export const ModalRoot = styled.div`
  width: min-content;
  padding: 15px 30px;
  border-radius: 20px;
  box-shadow: ${(props) => props.theme?.shadows?.modal || 'none'};
  ${(props) => (
    props.borderColor
    ? `border: 4px solid ${props.theme?.palette?.[props.borderColor] || props.borderColor}`
    : '')};
  background-image: ${(props) => props.backgroundImage};
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.dragSectionPlacement === 'bottom' ? 'column-reverse' : 'row')};
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const Divider = styled.hr`
  height: 90%;
  margin-left: 15px;
  border: none;
  border-left: solid 1px;
  border-color: ${(props) => props.theme?.palette?.border || 'gray'};
`;

const dragSectionMargins = {
  left: 'margin-right: 20px',
  bottom: 'margin-top: 20px',
};

export const DragSectionWrapper = styled.div`
  cursor: move;
  ${({ dragSectionPlacement }) => dragSectionMargins[dragSectionPlacement]};
  height: auto;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MicInfoWrapper = styled.div`
  margin: 10px 0 2px;
`;
