import apiConnector, { endpoints } from 'api';

const deleteSyncedEvent = async id => {
  const endpoint = `${endpoints.client.syncedEvents.general}/${id}`;

  try {
    debugger
    const result = await apiConnector.client.delete(endpoint);
    window.location.reload() // Fix this, reafactor the state and handle with onAfterDelete
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default deleteSyncedEvent;
