import React from 'react';

import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyCard from 'sections/Prospects/components/MatchingPropertyCardV2';

import KanbanCardControls from 'sections/Prospects/modules/KanbanCardControls';

const mapStateToProps = (state, ownProps) => {
  const {
    matchId,
    prospectId,
    lane,
    hideOptions,
  } = ownProps;

  const matchingProperty = selectors.realtorMatchingProperties.byId(matchId)(state);

  const {
    realtor_property_id: propertyId,
  } = matchingProperty;

  const property = selectors.properties.byId(state, propertyId);

  const address = selectors.addresses.byId(state, property?.address_id);

  return {
    propertyId,
    price: property?.expected_price,
    address: address?.address || '...',
    controls: (
      <KanbanCardControls
        matchId={matchId}
        prospectId={prospectId}
        lane={lane}
        hideOptions={hideOptions}
      />
    ),
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
