import printJS from 'print-js';
import { saveAs } from 'file-saver';
import { callAlert } from 'ui/Alert';
import moment from 'moment';
// TODO - improve import/no-cycle
// eslint-disable-next-line import/no-cycle
import { apiConnector } from 'shared/API';
// eslint-disable-next-line import/no-cycle
import {
  getHelperData,
  getKBCategories,
} from './knowledgeBase';
import { url } from '../../paths';

const searchArticles = '/kb_helper_data/articles/';
const article = '/article/';
const relate = 'relate/';
const articleReplace = '/article/replace/';
const star = '/article/star/';
const articleRate = '/article/rate/';
const pdf = 'pdf/';

const cyrillicPattern = /[\u0400-\u04FF]/;

// Article
export const SHOW_ARTICLE_DETAILS_MODAL = 'SHOW_ARTICLE_DETAILS_MODAL';

export function showArticleModal(isVisible, status) {
  return (dispatch) => {
    dispatch({
      type: SHOW_ARTICLE_DETAILS_MODAL,
      visibility: isVisible,
      articleModalMode: status,
    });
  };
}

// clear article detail component
export const CLEAR_ARTICLE_DETAIL = 'CLEAR_ARTICLE_DETAIL';
export const clearArticleDetail = () => ({ type: CLEAR_ARTICLE_DETAIL });

// link article
export const LINK_RELATED_ARTICLE = 'LINK_RELATED_ARTICLE';

export function linkRelatedArticle(isVisible) {
  return (dispatch) => {
    dispatch({
      type: LINK_RELATED_ARTICLE,
      visibility: isVisible,
    });
  };
}

// open related article modal
export const OPEN_RELATED_ARTICLE_SUCCESS = 'OPEN_RELATED_ARTICLE_SUCCESS';
export const OPEN_RELATED_ARTICLE_ERROR = 'OPEN_RELATED_ARTICLE_ERROR';
export const OpenRelatedArticleModal = (articleId) => (dispatch) => {
  apiConnector(`${article}${articleId}`, 'GET')
    .then((res) => dispatch(openRelatedArticleActionSuccessAction(res)))
    .catch((err) => dispatch(openRelatedArticleActionErrorAction(err)));
};

export const openRelatedArticleActionSuccessAction = (data) => ({
  type: OPEN_RELATED_ARTICLE_SUCCESS,
  payload: data.basicInfo,
});

export const openRelatedArticleActionErrorAction = (data) => ({
  type: OPEN_RELATED_ARTICLE_ERROR,
  payload: data,
});

// close related article modal
export const CLOSE_RELATED_ARTICLE_SUCCESS = 'CLOSE_RELATED_ARTICLE_SUCCESS';
export const сloseRelatedArticleModal = (articleId) => ({
  type: CLOSE_RELATED_ARTICLE_SUCCESS, payload: articleId,
});

// close related article modal
export const CLEAR_RELATED_ARTICLE_SUCCESS = 'CLEAR_RELATED_ARTICLE_SUCCESS';

// handleArticleSearch
export const SEARCH_ARTICLES = 'SEARCH_ARTICLES';
export const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS';
export const SEARCH_ARTICLES_ERROR = 'SEARCH_ARTICLES_ERROR';

export const handleArticleSearch = (word, productId, articleID) => (dispatch) => {
  dispatch(handleArticleSearchAction);
  apiConnector(`${searchArticles}${articleID}?t=${!cyrillicPattern.test(word) ? word : ''}&p=${productId || ''}`, 'GET')
    .then((res) => dispatch(handleArticleSearchSuccessAction(res)))
    .catch((err) => dispatch(handleArticleSearchErrorAction(err)));
};

export const handleArticleSearchAction = () => ({
  type: SEARCH_ARTICLES,
});

export const handleArticleSearchSuccessAction = (data) => ({
  type: SEARCH_ARTICLES_SUCCESS,
  payload: data,
});

export const handleArticleSearchErrorAction = (data) => ({
  type: SEARCH_ARTICLES_ERROR,
  payload: data,
});

// link article to exist article
export const LINK_ARTICLE = 'LINK_ARTICLE';
export const LINK_ARTICLE_SUCCESS = 'LINK_ARTICLE_SUCCESS';
export const LINK_ARTICLE_ERROR = 'LINK_ARTICLE_ERROR';

export const linkArticle = (sourceID, relatedID) => (dispatch) => {
  const articleRelation = {
    sourceID,
    relatedID,
  };
  dispatch(linkArticleAction);
  apiConnector(`${article}${relate}`, 'POST', articleRelation)
    .then((res) => {
      dispatch(linkArticleSuccessAction(res));
    })
    .catch((err) => dispatch(linkArticleErrorAction(err)));
};

export const linkArticleAction = () => ({
  type: LINK_ARTICLE,
});

export const linkArticleSuccessAction = (data) => ({
  type: LINK_ARTICLE_SUCCESS,
  payload: data,
});

export const linkArticleErrorAction = (data) => ({
  type: LINK_ARTICLE_ERROR,
  payload: data,
});

// delete link article to exist article
export const DELETE_LINK_ARTICLE = 'DELETE_LINK_ARTICLE';
export const DELETE_LINK_ARTICLE_SUCCESS = 'DELETE_LINK_ARTICLE_SUCCESS';
export const DELETE_LINK_ARTICLE_ERROR = 'DELETE_LINK_ARTICLE_ERROR';

export const deleteLinkArticle = (sourceID, relatedID) => (dispatch) => {
  const articleRelation = {
    sourceID,
    relatedID,
  };
  dispatch(deleteLinkArticleAction);
  apiConnector(`${article}${relate}`, 'DELETE', articleRelation)
    .then((res) => dispatch(deleteLinkSuccessAction(res)))
    .catch((err) => dispatch(linkArticleErrorAction(err)));
};

export const deleteLinkArticleAction = () => ({
  type: DELETE_LINK_ARTICLE,
});

export const deleteLinkSuccessAction = (data) => ({
  type: DELETE_LINK_ARTICLE_SUCCESS,
  payload: data,
});

export const deleteLinkErrorAction = (data) => ({
  type: DELETE_LINK_ARTICLE_ERROR,
  payload: data,
});

// Create new article
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR';

export const createNewArticle = (newArticle) => (dispatch) => {
  dispatch(createNewArticleAction);
  dispatch(getHelperData());
  apiConnector(`${article}`, 'POST', newArticle)
    .then((res) => {
      if (res.msg) {
        callAlert.error(res.msg);
        return;
      }
      dispatch(createNewArticleSuccessAction(res));
    })
    .catch((err) => dispatch(createNewArticleErrorAction(err)));
};

export const createNewArticleAction = () => ({
  type: CREATE_ARTICLE,
});

export const createNewArticleSuccessAction = (data) => ({
  type: CREATE_ARTICLE_SUCCESS,
  payload: data,
});

export const createNewArticleErrorAction = (data) => ({
  type: CREATE_ARTICLE_ERROR,
  payload: data,
});

// related article modal
export const SHOW_RELATED_ARTICLE_MODAL = 'SHOW_RELATED_ARTICLE_MODAL';
export const showRelatedArticleModal = () => ({ type: SHOW_RELATED_ARTICLE_MODAL });

// related article modal
export const SET_RELATED_ARTICLE = 'SET_RELATED_ARTICLE';
export const setRelatedArticle = (articleobj) => ({
  type: SET_RELATED_ARTICLE, payload: articleobj,
});

// remove article modal
export const REMOVE_RELATED_ARTICLE = 'REMOVE_RELATED_ARTICLE';
export const removeRelatedArticle = (id) => ({ type: REMOVE_RELATED_ARTICLE, payload: id });

// set article detail name
export const SET_ARTICLE_DETAIL_NAME = 'SET_ARTICLE_DETAIL_NAME';
export const setArticleDetailName = (name) => ({ type: SET_ARTICLE_DETAIL_NAME, payload: name });

// set article detail name
export const SET_ARTICLE_PURPOSE = 'SET_ARTICLE_PURPOSE';
export const setArticlePurpose = (purpose) => ({ type: SET_ARTICLE_PURPOSE, payload: purpose });

// set article content text
export const SET_ARTICLE_CONTENT_TEXT = 'SET_ARTICLE_CONTENT_TEXT';
export const saveEditorContent = (content) => ({
  type: SET_ARTICLE_CONTENT_TEXT, payload: content,
});

// link url modal
export const SHOW_LINK_TO_URL = 'SHOW_LINK_TO_URL';
export const showLinkToUrl = () => ({ type: SHOW_LINK_TO_URL });

// add link for image
export const SHOW_LINK_TO_IMG = 'SHOW_LINK_TO_IMG';
export const showLinkToImg = () => ({ type: SHOW_LINK_TO_IMG });

// move article modal
export const MOVE_ARTICLE_MODAL = 'MOVE_ARTICLE_MODAL';
export const moveArticleModal = () => ({ type: MOVE_ARTICLE_MODAL });

// move article
export const MOVE_ARTICLE_TO_CATEGORY = 'MOVE_ARTICLE_TO_CATEGORY';
export const MOVE_ARTICLE_TO_CATEGORY_SUCCESS = 'MOVE_ARTICLE_TO_CATEGORY_SUCCESS';
export const MOVE_ARTICLE_TO_CATEGORY_ERROR = 'MOVE_ARTICLE_TO_CATEGORY_ERROR';

export const moveArticleToCategory = (categoryId, subcategoryId, id) => (dispatch) => {
  dispatch(moveArticleToCategoryAction);
  apiConnector(`${articleReplace}${id}?subcat=${subcategoryId || ''}&cat=${categoryId}`, 'PATCH')
    .then((res) => {
      dispatch(moveArticleToCategorySuccessAction(res));
      dispatch(getKBCategories());
    })
    .catch((err) => dispatch(moveArticleToCategoryErrorAction(err)));
};

export const moveArticleToCategoryAction = () => ({
  type: MOVE_ARTICLE_TO_CATEGORY,
});

export const moveArticleToCategorySuccessAction = (data) => ({
  type: MOVE_ARTICLE_TO_CATEGORY_SUCCESS,
  payload: data,
});

export const moveArticleToCategoryErrorAction = (data) => ({
  type: MOVE_ARTICLE_TO_CATEGORY_ERROR,
  payload: data,
});

// set active article id
export const SET_ACTIVE_ARTICLE_ID = 'SET_ACTIVE_ARTICLE_ID';
export const setActiveArticleId = (id) => ({ type: SET_ACTIVE_ARTICLE_ID, payload: id });

// open Article
export const OPEN_ARTICLE = 'OPEN_ARTICLE';
export const OPEN_ARTICLE_SUCCESS = 'OPEN_ARTICLE_SUCCESS';
export const OPEN_ARTICLE_ERROR = 'OPEN_ARTICLE_ERROR';
export const openArticle = (id) => (dispatch) => {
  dispatch(openArticleAction);
  dispatch(showArticleModal(true, 'edit'));
  dispatch(setActiveArticleId(id));
  dispatch({ type: CLEAR_RELATED_ARTICLE_SUCCESS });
  apiConnector(`${article}${id}`, 'GET')
    .then((res) => dispatch(openArticleActionSuccessAction(res)))
    .catch((err) => dispatch(openArticleActionErrorAction(err)));
};

export const openArticleAction = () => ({
  type: OPEN_ARTICLE,
});

export const openArticleActionSuccessAction = (data) => ({
  type: OPEN_ARTICLE_SUCCESS,
  payload: data,
});

export const openArticleActionErrorAction = (data) => ({
  type: OPEN_ARTICLE_ERROR,
  payload: data,
});

// edit Article
export const EDIT_ARTICLE = 'EDIT_ARTICLE';
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS';
export const EDIT_ARTICLE_ERROR = 'EDIT_ARTICLE_ERROR';
export const SET_ACTIVE_ARTICLE_REQUEST_STATUS = 'SET_ACTIVE_ARTICLE_REQUEST_STATUS';
export const editArticle = (param, value, id) => (dispatch) => {
  if (value && value !== '') {
    let editable;
    if (param === 'category') {
      editable = {
        categorie_id: value[0],
        subcategorie_id: value[1] || null,
      };
    } else if (param === 'text') {
      editable = {
        [param]: value,
      };
    } else {
      editable = {
        [param]: value,
      };
    }
    dispatch(setArticleStatusAction('saving...'));
    apiConnector(`${article}${id}`, 'PUT', editable)
      .then((res) => {
        setTimeout(() => dispatch(setArticleStatusAction('changes saved.')), 500);
        dispatch(editArticleSuccessAction(res));
        if (res.msg) {
          callAlert.error(res.msg);
          return;
        }
        if (param === 'category' || param === 'is_archived') {
          setTimeout(() => { dispatch(getHelperData()); }, 500);
          setTimeout(() => { dispatch(getKBCategories()); }, 500);
        }
        setTimeout(() => dispatch(clearArticleStatusAction()), 5000);
      })
      .catch((err) => dispatch(editArticleErrorAction(err)));
  } else if (value === '' && param === 'keywords') {
    const editable = {
      [param]: value,
    };
    apiConnector(`${article}${id}`, 'PUT', editable)
      .then((res) => {
        if (res.msg) {
          callAlert.error(res.msg);
          return;
        }
        dispatch(editArticleSuccessAction(res));
      })
      .catch((err) => dispatch(editArticleErrorAction(err)));
  }
};

export const editArticleAction = () => ({
  type: EDIT_ARTICLE,
});

export const editArticleSuccessAction = (data) => ({
  type: EDIT_ARTICLE_SUCCESS,
  payload: data,
});

export const editArticleErrorAction = (data) => ({
  type: EDIT_ARTICLE_ERROR,
  payload: data,
});

export const setArticleStatusAction = (data) => ({
  type: SET_ACTIVE_ARTICLE_REQUEST_STATUS,
  payload: data,
});

export const CLEAR_ACTIVE_ARTICLE_REQUEST_STATUS = 'CLEAR_ACTIVE_ARTICLE_REQUEST_STATUS';
export const clearArticleStatusAction = () => ({ type: CLEAR_ACTIVE_ARTICLE_REQUEST_STATUS });

// star Article
export const STAR_ARTICLE = 'STAR_ARTICLE';
export const STAR_ARTICLE_SUCCESS = 'STAR_ARTICLE_SUCCESS';
export const STAR_ARTICLE_ERROR = 'STAR_ARTICLE_ERROR';
export const starArticle = (id) => (dispatch) => {
  dispatch(starArticleAction);
  apiConnector(`${star}${id}`, 'POST')
    .then((res) => dispatch(starArticleSuccessAction(res)))
    .catch((err) => dispatch(starArticleErrorAction(err)));
};

export const starArticleAction = () => ({
  type: STAR_ARTICLE,
});

export const starArticleSuccessAction = () => ({
  type: STAR_ARTICLE_SUCCESS,
});

export const starArticleErrorAction = () => ({
  type: OPEN_ARTICLE_ERROR,
});

// helpful Article
export const SET_HELPFUL_ARTICLE = 'SET_HELPFUL_ARTICLE';
export const SET_HELPFUL_SUCCESS = 'SET_HELPFUL_SUCCESS';
export const SET_HELPFUL_ERROR = 'SET_HELPFUL_ERROR';
export const setHelpfulArticle = (id, isHelpful) => (dispatch) => {
  dispatch(setHelpfulArticleAction);
  const helpfulRate = { isHelpful };
  apiConnector(`${articleRate}${id}`, 'POST', helpfulRate)
    .then((res) => dispatch(setHelpfulSuccessAction(res, isHelpful)))
    .catch((err) => dispatch(setHelpfulErrorAction(err)));
};

export const setHelpfulArticleAction = () => ({
  type: SET_HELPFUL_ARTICLE,
});

export const setHelpfulSuccessAction = (data, isHelpful) => ({
  type: SET_HELPFUL_SUCCESS,
  payload: isHelpful,
});

export const setHelpfulErrorAction = () => ({
  type: SET_HELPFUL_ERROR,
});

// download Article pdf
export const GET_ARTICLE_PDF = 'GET_ARTICLE_PDF';
export const getArticlePdf = (id) => (dispatch) => {
  dispatch(getArticlePdfAction);
  const token = localStorage.getItem('token');

  return fetch(`${url}${article}${pdf}${id}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
    responseType: 'blob',
  })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, `article_${moment(moment()).format('hh:mm:ss')}.pdf`))
    .catch((error) => { callAlert.error('Error while trying to save', error); });
};

export const getArticlePdfAction = () => ({
  type: GET_ARTICLE_PDF,
});

// print Article pdf
export const PRINT_ARTICLE_PDF = 'PRINT_ARTICLE_PDF';
export const printArticlePdf = (id) => (dispatch) => {
  dispatch(getArticlePdfAction);
  const token = localStorage.getItem('token');

  return fetch(`${url}${article}${pdf}${id}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
    responseType: 'blob',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const urlObject = URL.createObjectURL(blob);

      // let link = document.createElement('a');
      // link.href = url;
      // // link.target = '_blank';
      // console.log(link, 'link');
      // document.body.appendChild(link);
      // link.click();

      printJS(urlObject);
    })
    .catch((error) => { callAlert.error('Error while trying to receive pdf', error); });
};

export const printArticlePdfAction = () => ({
  type: GET_ARTICLE_PDF,
});
