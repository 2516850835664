import styled from 'styled-components';

import Flex from 'ui/Flex';

export const Expansion = styled(Flex)`
  overflow-x: auto;
  max-width: 100%;
  padding: 10px 5px 20px;
`;

export default {
  Expansion,
};
