/* eslint-disable react/jsx-no-bind, radix */

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import RadioImage from 'ui/RadioImage';
import Tooltip from 'ui/Tooltip';
import Flex from 'ui/Flex';
import Badge from 'ui/Badge/Badge';

const PropertySelect = (props) => {
  const {
    imageItems,
    selectProperty,
  } = props;

  const [currentValue, setCurrentValue] = useState(null);

  const handleRadioChange = (value) => {
    if (currentValue === parseInt(value)) {
      setCurrentValue(false);
      selectProperty(null);
      return;
    }
    selectProperty(parseInt(value));
    setCurrentValue(parseInt(value));
  };

  return (
    <Flex
      alignItems="center"
      spacing={1}
    >

      {
      imageItems?.map((item) => (
        <Tooltip title={item.address}>
          <div>
            <Badge
              content={item.needsResponseCount}
              maxNumber={99}
            >
              <RadioImage
                name={item.name}
                value={item.value}
                src={item.src}
                onChange={handleRadioChange}
                onClick={handleRadioChange}
                checked={currentValue}
              />
            </Badge>
          </div>
        </Tooltip>
      ))
    }
    </Flex>
  );
};

PropertySelect.defaultProps = {
  imageItems: null,
  selectProperty: () => {},
};

const {
  shape,
  string,
  number,
  arrayOf,
  func,
} = PropTypes;

PropertySelect.propTypes = {
  imageItems: arrayOf(shape({
    name: number,
    value: number,
    src: string,
  })),
  selectProperty: func,
};

export default PropertySelect;
