import connectReducers from 'store/connectReducers';

import {
  SET_CANCEL_TRANSACTION_MODAL_OPEN,
  SET_CANCEL_TRANSACTION_MODAL_CLOSED,
} from 'store/actionTypes/realtorTransactions';

const initialState = {
  isVisible: false,
  transactionId: null,
  onSave: null,
};

const reducers = {
  [SET_CANCEL_TRANSACTION_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    transactionId: action?.payload?.transactionId,
    onSave: action?.payload?.onSave,
  }),

  [SET_CANCEL_TRANSACTION_MODAL_CLOSED]: (state) => initialState,
};

function cancelTransactionModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default cancelTransactionModal;
