import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import TabContext from 'ui/TabContext';
import TabPanel from 'ui/TabPanel';

import TabComponent from './TabComponent';
import PropertyTab from './PropertyTab';

import muiTabStyles from './tabStyles';

const useStyles = makeStyles(muiTabStyles);

const OfferTabs = (props) => {
  const {
    needsResponseCount,
    awaitingReplyCount,
    declinedCount,
    propertyId,
    tabs,
    accepted,
  } = props;

  const [tab, setTab] = useState(accepted ? 'accepted' : 'needs response');

  const classes = useStyles();

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <TabContext value={tab}>
      {
          accepted
          ? (
              <Tabs
                value={tab}
                TabIndicatorProps={{
                    sx: {
                      height: 0,
                      backgroundColor: 'red',
                    },
                  }}
                onChange={handleTabChange}
                className={classes.tabs}
              >
                <Tab
                  label={<PropertyTab propertyId={propertyId} />}
                  value="address"
                  className={classes.tabWithBorder}
                  disabled
                />
                <Tab
                  disableRipple
                  label={<TabComponent title="Accepted" />}
                  value="accepted"
                  className={classes.tabWithBorder}
                />
                <Tab
                  disableRipple
                  label={<TabComponent title="Declined" count={declinedCount} />}
                  value="declined"
                  className={classes.tab}
                />
              </Tabs>
            )
          : 
            (
              <Tabs
                value={tab}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                  }}
                onChange={handleTabChange}
                className={classes.tabs}
              >
                <Tab
                  disableRipple
                  label={<PropertyTab propertyId={propertyId} />}
                  value="address"
                  className={classes.tabWithBorder}
                  disabled
                />
                <Tab
                  disableRipple
                  label={<TabComponent title="Needs Response" count={needsResponseCount} />}
                  value="needs response"
                  className={classes.tabWithBorder}
                />
                <Tab
                  disableRipple
                  label={<TabComponent title="Awaiting Reply" count={awaitingReplyCount} />}
                  value="awaiting reply"
                  className={classes.tabWithBorder}
                />
                <Tab
                  disableRipple
                  label={<TabComponent title="Declined" count={declinedCount} />}
                  value="declined"
                  className={classes.tab}
                />
              </Tabs>
            )

        }
      {
        tabs.map((item, index) => (
          <TabPanel
            value={item.name}
            index={index}
            key={item.name}
          >
            <div>{item.content ? item.content() : 'Something went wrong'}</div>
          </TabPanel>
        ))
      }
    </TabContext>
  );
};

OfferTabs.defaultProps = {
  needsResponseCount: null,
  awaitingReplyCount: null,
  declinedCount: null,
  tabs: null,
  propertyId: null,
  accepted: null,
};

const {
  number,
  shape,
} = PropTypes;

OfferTabs.propTypes = {
  needsResponseCount: number,
  awaitingReplyCount: number,
  declinedCount: number,
  tabs: shape({}),
  accepted: shape({}),
  propertyId: number,
};

export default OfferTabs;
