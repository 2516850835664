export const auth = {
  SUCCESS: {
    CODE: 200,
    MESSAGE: 'Successfully authorized',
  },
  STATUS_CHANGED: 'Status Changed',
  MESSAGES: {
    SUCCESS: {
      LOGIN: 'You are successfully logged in',
      LOGOUT: 'You are successfully logged out',
      ADD_EMPLOYEE: 'User successfully added',
    },
    FAIL: {
      LOGIN: 'Problem appeared during loggin in',
      LOGOUT: 'Problem appeared during loggin out',
    },
  },
  CHANGE_PASSWORD: 'Password changed successfully',
  RECOVER_SUCCESS: 'Success, please check your email!',
  RECOVER_ERROR: 'The email you provided does not have any workspaces',
  FIRST_TIME_LOGIN: 'firstTimeLogin',
  DEFAULT: 'default',
  FIRST_TIME_LOGIN_MSG:
    'For added security, all first time users are asked to change their password. Please do so now',
  CHANGE_DEFAULT: 'Please enter a new, secure password for your account.',
  DISABLE_ACCOUNT: 'Your account was disabled',
  RESTORE_AFTER_LOCKOUT: 'restoreAfterLockout',
  RESTORE_AFTER_LOCKOUT_MSG: 'Your account has been locked out because of suspicious activity. Please reset your password to get access to your account.',
};
