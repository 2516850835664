import {
  SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorRepairRequests';

const setRepairRequestsForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_REPAIR_REQUESTS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setRepairRequestsForFilterCount;
