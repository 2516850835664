import React from 'react';

import AttachmentsUpload from 'shared/inputs/InlineAttachmentsUpload';

import withDocumentRequestAttachments from 'sections/Transactions/modules/DocumentRequests/hocs/withDocumentRequestAttachments/index';

const DocumentRequestAttachments = withDocumentRequestAttachments(AttachmentsUpload);

export default (props) => (
  <DocumentRequestAttachments
    objectId={props.requestId}
    multiple
    itemSize="m"
  />
);
