import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_PRIORITIES_OBJECTS,
} from 'store/actionTypes/realtorPriorities';

const initialState = {
  prioritiesObjects: [],
};

const reducers = {
  [SET_REALTOR_PRIORITIES_OBJECTS]: (state, action) => ({
    ...state,
    prioritiesObjects: [
      ...action.payload,
    ],
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
