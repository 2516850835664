import axios from 'axios';

const submitHubspotForm = async (portalId, formId, data) => {
  const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  try {
    const result = await axios.post(endpoint, data);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default submitHubspotForm;
