import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  createAddress,
} from 'store/actions/addresses';

import AddressInput from 'ui/AddressInput';

const mapStateToProps = (state, ownProps) => {
  const address = ownProps.addressId
    ? selectors.addresses.byId(state, ownProps.addressId)
    : { address: ownProps.value };

  return {
    value: address?.address,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: async (value) => {
    if (ownProps.raw) {
      ownProps.onChange && ownProps.onChange(value);
    } else {
      const addressId = await dispatch(createAddress(value));
      ownProps.onChange && ownProps.onChange(addressId);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressInput);
