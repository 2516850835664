import styled from 'styled-components';
import Flex from 'ui/Flex';

export const BackgroundHighlight = styled(Flex)`
&& {
  background-color: ${(props) => props.theme.palette.backgroundFlat || ''};
  border-radius: 5px;
  box-shadow: -5px 0px 0px 5px ${(props) => props.theme.palette.backgroundFlat || ''};
}
`;

export default {
  BackgroundHighlight,
};
