import connectReducers from 'store/connectReducers';

import {
  GET_PUBLIC_DATA,
  SET_EDIT_PUBLIC_ARTICLE_FORM_FIELD,
  UPDATE_PUBLIC_ARTICLE,
} from 'store/actionTypes/articlesPublic';

const defaultArticleInformations = {
  createdAt: null,
  folderId: null,
  id: null,
  purpose: null,
  text: null,
  title: null,
  updatedAt: null,
};

const defaultFolderInformations = {
  id: null,
  name: null,
  type: null,
  relatedAppId: null,
  folderParentId: null,
  createdById: null,
  createdAt: null,
  folderArticles: [],
};

const initialState = {
  activeFolder: defaultFolderInformations,
  mainViewType: null,
  articleDisplayType: 'line',
  folderArticles: [],
  editArticleFormFields: {},
  activeArticle: defaultArticleInformations,
};

const reducers = {
  [UPDATE_PUBLIC_ARTICLE]: (state) => ({
    ...state,
    isHelpfulFromPublic: {},
  }),

  [SET_EDIT_PUBLIC_ARTICLE_FORM_FIELD]: (state, actions) => ({
    ...state,
    editArticleFormFields: {
      ...state.editArticleFormFields,
      [actions.key]: actions.value,
    },
  }),

  [GET_PUBLIC_DATA]: (state, actions) => {
    const {
      publicData,
      objectType,
    } = actions;

    if (objectType === 'folder') {
      return {
        ...state,
        activeFolder: publicData,
        mainViewType: objectType,
      };
    } if (objectType === 'article') {
      return {
        ...state,
        activeArticle: publicData,
        mainViewType: objectType,
      };
    }

    return {
      ...state,
    };
  },
};

function articlesPublic(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default articlesPublic;
