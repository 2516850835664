import {
  SET_REALTOR_LEADS_FOR_FILTER,
} from 'store/actionTypes/realtorLeads';

const setLeadsForFilter = (filterId) => (leads) => ({
  type: SET_REALTOR_LEADS_FOR_FILTER,
  payload: {
    filterId,
    leads,
  },
});

export default setLeadsForFilter;
