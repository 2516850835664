import styled from 'styled-components';

import Elevation from 'ui/Elevation';

export const Root = styled(Elevation)`
  border: ${
    (props) => (props.isSelected
      ? `1px solid ${props?.theme?.palette?.focus || 'green'}`
      : 'none')
  };
  background-color: ${
    (props) => (props.isSelected
      ? (props?.theme?.palette?.['background-flat'] || 'green')
      : 'initial')
  };
`;

export default {
  Root,
};
