import {
  SET_NEW_PROPERTY_VIEWING_MODAL_OPEN,
} from 'store/actionTypes/propertyViewings';

const openModal = (
  propertyId = null,
  {
    property,
    buyer,
    buyerAgent,
    sellerAgent,
    contact,
  } = {},
) => ({
    type: SET_NEW_PROPERTY_VIEWING_MODAL_OPEN,
    payload: {
      propertyId,
      buyerField: buyer,
      buyerAgentField: buyerAgent,
      sellerAgentField: sellerAgent,
      propertyField: property,
      contactField: contact,
    },
  });

export default openModal;
