import {
  SET_TICKETS_SUMMARY_FOR_QUEUE,
} from 'store/actionTypes/tickets';

const setTicketsSummaryForQueue = (queueId) => (ticketsSummary) => ({
  type: SET_TICKETS_SUMMARY_FOR_QUEUE,
  payload: {
    queueId,
    ticketsSummary,
  },
});

export default setTicketsSummaryForQueue;
