import {
  SET_CRM_LEADS,
} from 'store/actionTypes/crm';

const setLeads = (leads) => ({
  type: SET_CRM_LEADS,
  payload: leads,
});

export default setLeads;
