import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import {
  Modal as AntdModal,
} from 'antd';

import prettysize from 'prettysize';

import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';

import useAttachments from 'shared/hooks/useAttachments';
import processAttachment from 'shared/utils/attachments/processAttachment';

import PreviewModal from './PreviewModal';
import AttachmentTile from './AttachmentTile';
import LinkInput from './LinkInput';

import {
  PreviewToolbar,
  UploadArea,
  LinkInputContainer,
  ExternalLinksContainer,
  ExternalLink,
} from './styledItems';

const { confirm } = AntdModal;

const DELETE_TITLE = 'Do you want to delete this attachment?';
const placeholderURL = '/assets/img/user-cropped-placeholder.png';
const noop = () => {};

const Attachments = (props) => {
  const {
    attachmentType,
    blockRemove,
    deleteFile,
    fileCompressionProgress,
    objectId,
    objectType,
    preloader,
    uploadFile,
    uploadFileLimit,
    selectedAttachments,
    contentTypeFilter,
    multiple,

    onRefresh,
    onAfterDelete,
    onAfterUpload,

    CustomListWithUpload,
    CustomUploadArea,
    CustomAttachmentItem,
  } = props;

  const [simplePreviewVisible, setSimplePreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewExtension, setPreviewExtension] = useState(null);
  const [stateIsLoading, setStateIsLoading] = useState(true);
  const [previewFileId, setPreviewFileId] = useState(null);
  const [previewedOriginalFileDownloadUrl, setPreviewedOriginalFileDownloadUrl] = useState(0);
  const [previewedOriginalFileSize, setPreviewedOriginalFileSize] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [filesWithPreview, setFilesWithPreview] = useState([]);
  const [externalLinks, setExternalLinks] = useState([]);
  // const [previewFileTitle, setPreviewFileTitle] = useState('')

  const [list, loading] = useAttachments(
    objectType,
    objectId,
    attachmentType,
    shouldRefresh,
  );

  useEffect(() => {
    let filteredList = list;

    if (selectedAttachments || contentTypeFilter) {
      filteredList = list.filter((item) => {
        const isSelected = !selectedAttachments || selectedAttachments.includes(item.attachmentId);
        const isRightType = !contentTypeFilter || (contentTypeFilter && item.contentType === contentTypeFilter);

        return isSelected && isRightType;
      });
    }

    const processedList = filteredList.map(processAttachment).filter((item) => !item.isExternalLink);
    const links = filteredList.reduce((accum, item) => {
      if (item.isExternalLink) {
        accum.push(item.url);
      }
      return accum;
    }, []);

    setFilesWithPreview(processedList);
    onRefresh(processedList);

    setExternalLinks(links);
  }, [
    list,
    selectedAttachments,
    contentTypeFilter,
    onRefresh,
  ]);

  useEffect(() => {
    setStateIsLoading(preloader || loading);
  }, [
    preloader,
    loading,
  ]);

  useEffect(() => () => {
    setSimplePreviewVisible(false);
    setPreviewImage('');
    setFilesWithPreview([]);
    setPreviewExtension(null);
    setStateIsLoading(false);
    setPreviewFileId(null);
    setPreviewedOriginalFileSize(0);
    // setPreviewFileTitle('')
  }, []);

  const refreshList = () => {
    setShouldRefresh(true);
    setShouldRefresh(false);
  };

  const handleUploadFile = useCallback(async (uploadInputData) => {
    const result = await uploadFile({
      objectId,
      objectType,
      attachmentType: attachmentType || 12,
      file: uploadInputData.file,
    });

    refreshList();

    if (onAfterUpload) {
      onAfterUpload(result);
    }
  }, [
    objectId,
    objectType,
    attachmentType,
    uploadFile,
    onAfterUpload,
  ]);

  const handleUploadExternalLink = useCallback(async (linkUrl) => {
    const result = await uploadFile({
      objectId,
      objectType,
      attachmentType: attachmentType || 12,
      linkUrl,
    });

    refreshList();

    if (onAfterUpload) {
      onAfterUpload(result);
    }
  }, [
    objectId,
    objectType,
    attachmentType,
    uploadFile,
    onAfterUpload,
  ]);

  const handleDeleteFile = useCallback(async (attachmentId) => {
    await deleteFile(objectType, objectId, attachmentId);

    refreshList();

    if (onAfterDelete) {
      onAfterDelete(attachmentId, attachmentType);
    }
  }, [
    deleteFile,
    objectId,
    objectType,
    attachmentType,
    onAfterDelete,
  ]);

  const showDeleteConfirm = useCallback((fileId) => () => {
    if (blockRemove) {
      return;
    }

    confirm({
      title: DELETE_TITLE,
      onOk: () => {
        handleDeleteFile(fileId);
        setSimplePreviewVisible(false);
      },
      onCancel: () => {},
    });
  }, [
    objectId,
    objectType,
    blockRemove,
  ]);

  const openPreviewModal = useCallback(() => {
    setSimplePreviewVisible(true);
  });

  const chooseAttachment = useCallback((file) => {
    setPreviewFileId(file.attachmentId);
    setPreviewImage(file.previewUrl);
    setPreviewExtension(file.extension);
    setPreviewedOriginalFileDownloadUrl(file.url);
    setPreviewedOriginalFileSize(file.size);
    // setPreviewFileTitle(file.title || '')
  });

  const handleOpenPreview = useCallback((file) => () => {
    chooseAttachment(file);
    openPreviewModal();
  });

  const hidePreviewModal = useCallback(() => {
    setSimplePreviewVisible(false);
  });

  const downloadAttachment = useCallback(() => {
    const a = document.createElement('a');
    a.href = previewedOriginalFileDownloadUrl;
    a.download = true;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });

  const hasAttachments = filesWithPreview && filesWithPreview?.length > 0;
  const shouldShowUploadArea = filesWithPreview?.length < uploadFileLimit;

  const uploadListType = hasAttachments
    ? 'picture-card'
    : null;

  // eslint-disable-next-line no-nested-ternary
  const uploadButton = hasAttachments
    ? (
      <Icon
        name="plus"
        color="focus"
      />
    )
    : stateIsLoading
      ? null
      : (
        <Button
          icon="upload"
          variant="primary"
          onClick={noop}
        >
          Upload
        </Button>
        );

  const loadingTip = fileCompressionProgress
    ? `Compressing file: ${fileCompressionProgress}%`
    : 'Loading...';

  return (
    <>
      <section>

        <LinkInputContainer>
          <LinkInput onEnter={handleUploadExternalLink} />
          <Typography
            noMargin
            weight="bold"
            style={{ marginTop: 15 }}
          >
            or upload from your computer
          </Typography>
        </LinkInputContainer>

        {
          CustomListWithUpload && (
            <CustomListWithUpload
              files={filesWithPreview}
              handleUploadFile={handleUploadFile}
              isLoading={stateIsLoading}
            />
          )
        }
        {
          !CustomListWithUpload && (
            <Flex
              spacing={1}
              flexWrap="wrap"
            >
              {
                filesWithPreview.map((file) => (
                  <>
                    {
                      (CustomAttachmentItem && file) && (
                        <CustomAttachmentItem
                          file={file}
                          onClick={handleOpenPreview(file)}
                          isLoading={stateIsLoading}
                        />
                      )
                    }
                    {
                      (!CustomAttachmentItem && file) && (
                        <AttachmentTile
                          key={file.attachmentId}
                          onClick={handleOpenPreview(file)}
                          file={file}
                        />
                      )
                    }
                  </>
                ))
              }
              {
                shouldShowUploadArea && (
                  <>
                    {
                      CustomUploadArea && (
                        <CustomUploadArea
                          isLoading={stateIsLoading}
                          handleUploadFile={handleUploadFile}
                        />
                      )
                    }
                    {
                      !CustomUploadArea && (
                        <Spin
                          tip={loadingTip}
                          spinning={stateIsLoading}
                        >
                          <UploadArea
                            listType={uploadListType}
                            customRequest={handleUploadFile}
                            fileList={null}
                            multiple={multiple}
                          >
                            {
                              uploadButton
                            }
                          </UploadArea>
                        </Spin>
                      )
                    }
                  </>
                )
              }
            </Flex>
          )
        }

        <ExternalLinksContainer>
          {
            externalLinks.map((link) => (
              <ExternalLink href={link} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="link"
                >
                  {link}
                </Typography>
              </ExternalLink>
            ))
          }
        </ExternalLinksContainer>
      </section>
      <PreviewModal
        isVisible={simplePreviewVisible}
        onCancel={hidePreviewModal}
        previewFileUrl={previewImage || placeholderURL}
        previewFileExtension={previewExtension}
        footer={(
          <PreviewToolbar>
            <Button
              variant="secondary"
              onClick={showDeleteConfirm(previewFileId)}
              icon="trash2"
            />
            <Button
              onClick={downloadAttachment}
              variant="primary"
              icon="download"
            >
              Download
              {' '}
              {prettysize(previewedOriginalFileSize)}
            </Button>
          </PreviewToolbar>
        )}
      />
    </>
  );
};

Attachments.defaultProps = {
  blockRemove: false,
  preloader: false,
  uploadFileLimit: Infinity,
  fileCompressionProgress: null,
  onRefresh: () => {},
  onAfterUpload: () => {},
  onAfterDelete: () => {},
  attachmentType: null,
  selectedAttachments: null,
  contentTypeFilter: null,
  multiple: true,
  CustomListWithUpload: null,
  CustomUploadArea: null,
  CustomAttachmentItem: null,
};

const {
  arrayOf,
  bool,
  func,
  number,
  string,
} = PropTypes;

Attachments.propTypes = {
  blockRemove: bool,
  deleteFile: func.isRequired,
  objectId: number.isRequired,
  preloader: bool,
  uploadFile: func.isRequired,
  uploadFileLimit: number,
  fileCompressionProgress: number,
  objectType: number.isRequired,
  attachmentType: number,
  onRefresh: func,
  onAfterUpload: func,
  onAfterDelete: func,
  selectedAttachments: arrayOf(string),
  contentTypeFilter: string,
  multiple: bool,
  CustomListWithUpload: func,
  CustomUploadArea: func,
  CustomAttachmentItem: func,
};

export default Attachments;
