import post from './post';
import edit from './edit';
import acknowledge from './acknowledge';
import deleteLog from './delete';
import getForObject from './getForObject';
import markAsSeen from './markAsSeen';

export default {
  post,
  edit,
  delete: deleteLog,
  acknowledge,
  getForObject,
  markAsSeen,
};
