/*
  eslint-disable
  react/button-has-type,
  react/jsx-props-no-spreading,
  react/require-default-props
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dataAttributes from 'oa/helpers/dataAttributes';
import TextContent from './components/TextContent';
import './style.css';

const Button = (props) => {
  const {
    className,
    type,
    htmlType,
    onClick,
    children,
    hideText,
    disabled,
    id,
    style,
    data,
    form,
    append,
    prepend,
  } = props;

  return (
    <button
      type={htmlType}
      className={classNames('button', className, {
        [`button_${type}`]: type && !hideText,
        'button_for-service': hideText,
      })}
      onClick={onClick}
      disabled={disabled}
      id={id}
      form={form}
      style={style}
      {...dataAttributes(data)}
    >
      <TextContent append={append} prepend={prepend} hideText={hideText}>
        {children}
      </TextContent>
    </button>
  );
};

Button.defaultProps = {
  type: 'primary_default',
  htmlType: 'button',
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary_default', 'tertiary_default']),
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  hideText: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.objectOf([PropTypes.string, PropTypes.number]),
  data: PropTypes.objectOf([PropTypes.string, PropTypes.number]),
  form: PropTypes.string,
  children: PropTypes.node.isRequired,
  append: PropTypes.node,
  prepend: PropTypes.node,
};

export default Button;
