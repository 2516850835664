import {
  SET_MODAL_CLOSED,
} from 'store/actionTypes/modals';

import getModalCallbacks from './getModalCallbacks';

const closeModal = (modal) => (modalOptions = {}) => (dispatch) => {
  const modalCallbacks = getModalCallbacks(modalOptions);

  modalCallbacks.onBeforeClose();

  dispatch({
    type: SET_MODAL_CLOSED,
    modal,
    payload: {
      ...modalOptions,
      modalProps: modalOptions?.modalProps,
    },
  });

  modalCallbacks.onAfterClose();
};

export default closeModal;
