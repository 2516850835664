import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import withSelectedContact from 'sections/Contacts/hocs/withSelectedContact';

import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Breadcrumbs from 'sections/Contacts/modules/Breadcrumbs';
import PinnedNote from 'sections/Contacts/modules/PinnedNote';
import Options from 'sections/Contacts/modules/Options';
import Comments from 'sections/Contacts/modules/Comments';

import MakeLead from 'sections/Contacts/modules/MakeLead';
import ShowFullProfileButton from 'sections/Contacts/modules/ShowFullProfileButton';

import FollowUp from 'sections/Contacts/modules/FollowUp';
import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

import ContactInfo from 'sections/Contacts/modules/ContactInfo';
import ContactInfoV2 from 'sections/Contacts/modules/ContactInfoV2';

import {
  Root,
} from './styledItems';

const version = getFeatureVersion('contact page');

const Contact = (props) => {
  const {
    selectedContact,
  } = props;

  const view = useMemo(() => {
    if (version === 1) {
      return (
        <Root>
          <Flex
            spacing={1}
            flexWrap="wrap"
          >
            <FlexItem fullWidth>
              <Breadcrumbs />
            </FlexItem>

            <FlexItem
              fullWidth
            >
              <Elevation
                padding="s"
                rounded="s"
              >
                <ContactInfo
                  contactId={selectedContact}
                  toolbar={(
                    <Flex
                      spacing={2}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <ShowFullProfileButton contactId={selectedContact} />
                      <PinnedNote />
                      <MakeLead contactId={selectedContact} />
                      <FollowUp contactId={selectedContact} />
                      <Options />
                    </Flex>
                  )}
                />
              </Elevation>
            </FlexItem>

            <FlexItem fullWidth>
              <Elevation
                padding="m"
                rounded="m"
              >
                <Comments />
              </Elevation>
            </FlexItem>
          </Flex>
        </Root>
      );
    }

    return (
      <Root>
        <Flex
          spacing={2}
          flexWrap="wrap"
          alignItems="stretch"
        >
          <FlexItem fullWidth>
            <Breadcrumbs />
          </FlexItem>

          <FlexItem
            xs={10}
          >
            <Elevation
              padding="m"
              rounded="m"
              fullHeight
            >
              <ContactInfoV2 contactId={selectedContact} />
            </Elevation>
          </FlexItem>

          <FlexItem
            xs={14}
          >
            <Elevation
              padding="m"
              rounded="m"
              fullHeight
            >
              <Comments />
            </Elevation>
          </FlexItem>
        </Flex>
      </Root>
    );
  }, [selectedContact]);

  return (
    <>
      {view}

      <FollowUpRemindersModal
        objectId={selectedContact}
        objectTypeId={25}
      />
    </>
  );
};

Contact.defaultProps = {
  selectedContact: null,
};

const {
  number,
} = PropTypes;

Contact.propTypes = {
  selectedContact: number,
};

export default withSelectedContact(Contact);
