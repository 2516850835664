import apiConnector, { endpoints } from 'api';

const getById = async (id) => {
  const endpoint = `${endpoints.client.refunds}/${id}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getById;
