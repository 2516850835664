import setFilterItem from './setFilterItem';

const setAll = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('ownership')(null));
};

const setArchived = () => (dispatch) => {
  dispatch(setFilterItem('status')(2));
  dispatch(setFilterItem('ownership')(null));
};

const setMyListings = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('ownership')('mine'));
};

const setFeaturedListings = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('ownership')('featured'));
};

const keyHandlers = {
  all: setAll,
  mine: setMyListings,
  featured: setFeaturedListings,
  archived: setArchived,
};

const handleCustomFilter = (key) => (dispatch) => {
  if (keyHandlers[key]) dispatch(keyHandlers[key]());
};

export default handleCustomFilter;
