import store from 'store';

import requests from 'api/requests';

import {
  createProspect,
} from 'store/actions/realtorProspects';

const getProspectId = async (offerFormData) => {
  if (!offerFormData?.buyer) return null;

  try {
    let foundOrCreatedProspectId = null;

    const prospectsResponse = await requests.realtor.prospects.get({
      offset: 0,
      limit: 1,
      contactId: offerFormData.buyer,
    });

    const prospects = prospectsResponse.data.items;

    if (prospects[0]) {
      foundOrCreatedProspectId = prospects[0].id;
    } else {
      foundOrCreatedProspectId = await store.dispatch(createProspect({
        contact_id: offerFormData.buyer,
        is_exclusive: false,
        is_pocket_listing: false,
      }));
    }

    return foundOrCreatedProspectId;
  } catch (error) {
    return null;
  }
};

export default getProspectId;
