import {
  SET_REALTOR_TRANSACTIONS,
} from 'store/actionTypes/realtorTransactions';

const setTransactions = (transactions) => ({
  type: SET_REALTOR_TRANSACTIONS,
  payload: transactions,
});

export default setTransactions;
