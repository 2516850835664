import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

const defaultItem = {
  id: null,
};

export default (id) => createSelector(
  (state) => state.realtorInspections.inspections,
  (collection) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorInspectionsActions.getInspectionById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
    };
  },
);
