import apiConnector, { endpoints } from 'api';

const resolveTicket = async (ticketId, data) => {
  const endpoint = `${endpoints.client.v2.ticketResolve}/${ticketId}`;

  try {
    const result = await apiConnector.client.patch(endpoint, data);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default resolveTicket;
