import { createSelector } from 'reselect';

import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';

const steps = [
  {
    title: 'Payment Received',
    weight: 1,
    stepName: 'payment',
    iconName: 'dollar-sign',
    tooltip: 'Payment Received',
  },
  {
    title: 'Packaged',
    weight: 2,
    stepName: 'packaged',
    iconName: 'box',
    tooltip: 'Packaged',
  },
  {
    title: 'Item Shipped',
    weight: 4,
    stepName: 'shipping',
    iconName: 'shipping-fast',
    tooltip: 'Item Shipped',
  },
  {
    title: 'Delivery Confirmed',
    weight: 8,
    stepName: 'delivery',
    iconName: 'dolly',
    tooltip: 'Delivery Confirmed',
  },
  {
    title: 'Feedback',
    weight: 16,
    stepName: 'feedback',
    iconName: 'star',
    tooltip: 'Feedback',
  },
];

export default createSelector(
  (state) => state.lineItems.lineItems,
  (state, id) => id,
  (
    lineItems,
    itemId,
  ) => {
    const lineItem = lineItems[itemId];

    if (!lineItem) {
      if (itemId) {
        optimizedLineItemsActions.getLineItemById(itemId);
      }

      return [];
    }

    return steps.map((step) => {
      const status = (lineItem.process_status & step.weight) > 0 // eslint-disable-line no-bitwise, no-nested-ternary
        ? 'complete'
        : lineItem.process_status > step.weight
          ? 'skipped'
          : null;

      return {
        ...step,
        status,
      };
    });
  },
);
