import { connect } from 'react-redux';
import selectors from 'store/selectors';

import { updateCustomizationSettings } from 'store/actions/appCustomization';

import LeadConversionGoal from './LeadConversionGoal';

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    value: settings.lead_conversion_goal_days,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => {
    dispatch((updateCustomizationSettings({
      lead_conversion_goal_days: value,
    })));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadConversionGoal);
