import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.crmLeadsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify(filter),
    filter: {
      search: filter.search,
      title: filter.title,
      temp: filter.temp,
      status: filter.status,
      company: filter.company,
      contact: filter.contact,
      source: filter.source,
      source_type: filter.sourceType,
      permission_type: filter.permissionType,
      created_by: filter.createdBy,
      claimed_by: filter.claimedBy,
      created_at_range: filter.createdAtRange,
      last_contact_range: filter.lastContactRange,
      value_range: filter.valueRange,
      campaigns: filter.campaigns,
      owned_by_teams: filter.ownedByTeams,
      owned_by_employees: filter.ownedByEmployees,
      product_name: filter.productName,
      permissions_for_employees: filter.permissionsForEmployees,
      permissions_for_teams: filter.permissionsForTeams,
      permissions_for_offices: filter.permissionsForOffices,
      sort_by: filter.sortBy,
      sort_direction: filter.sortDirection,
      is_orphaned: filter.isOrphaned,
      is_claimed: filter.isClaimed,
    },
  }),
);
