/* eslint-disable react/jsx-no-bind */

import React, {
  useState,
} from 'react';

import PropTypes from 'prop-types';

import EmailDrawer from 'sections/Contacts/modules/EmailDrawer';
import useContactDefaultEmail from 'shared/hooks/contacts/useContactDefaultEmail';
import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import { StyledButton } from './styledItems';

const EmailButton = (props) => {
  if (isSectionUnderConstruction('integrations')) return null;

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const {
    contactId,
    showText,
    contactType,
    objectId,
  } = props;

  const email = useContactDefaultEmail(contactId);

  return (
    <>
      <EmailDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        contactId={contactId}
        objectId={objectId}
        contactType={contactType}
      />
      <StyledButton
        onClick={toggleDrawer}
        fullWidth={showText}
        icon="mail"
        centered
        disabled={!email}
      >
        {showText ? 'Email' : null}
      </StyledButton>
    </>
  );
};

EmailButton.defaultProps = {
  showText: false,
  contactType: null,
  objectId: null,
};

const {
  number,
  bool,
  string,
} = PropTypes;

EmailButton.propTypes = {
  contactId: number.isRequired,
  objectId: number,
  showText: bool,
  contactType: string,
};

export default EmailButton;
