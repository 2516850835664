import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Typography from 'ui/Typography';
import {
  Root,
} from './styledItems';

const SelectableCounterItem = (props) => {
  const {
    isSelected,
    value,
    title,
    onClick,
    customValueElement,
    fullWidth,
    color,
    titleColor,
    biggerPaddings,
    sameFontWeight,
    className,
    inactiveItemBgc,
    selectedItemBgc,
    isInvesedTextSelectedColor,
    valueProps,
    titleProps,
  } = props;

  const titleTextColor = isSelected && isInvesedTextSelectedColor ? 'main-text-inversed' : titleColor;

  const valueTextColor = isSelected && isInvesedTextSelectedColor ? 'main-text-inversed' : color;

  return (
    <Root
      className={classnames({
        [className]: className,
      })}
      inactiveItemBgc={inactiveItemBgc}
      selectedItemBgc={selectedItemBgc}
      isSelected={isSelected}
      onClick={onClick}
      fullWidth={fullWidth}
      biggerPaddings={biggerPaddings}
    >
      {customValueElement}
      {
        !customValueElement && (
          <Typography
            {...valueProps} // eslint-disable-line react/jsx-props-no-spreading
            color={isInvesedTextSelectedColor ? valueTextColor : color}
            noMargin
            style={{
              marginBottom: 5,
            }}
          >
            {value}
          </Typography>
        )
      }
      <Typography
        {...titleProps} // eslint-disable-line react/jsx-props-no-spreading
        color={isInvesedTextSelectedColor ? titleTextColor : titleColor}
        weight={isSelected || sameFontWeight ? 700 : 400}
        noMargin
      >
        {title}
      </Typography>
    </Root>
  );
};

SelectableCounterItem.defaultProps = {
  isSelected: null,
  title: null,
  value: null,
  customValueElement: null,
  onClick: () => { },
  fullWidth: false,
  color: null,
  className: null,
  titleColor: 'ghost',
  biggerPaddings: false,
  sameFontWeight: false,
  inactiveItemBgc: '#f6f8fa',
  selectedItemBgc: 'transparent',
  valueProps: {
    variant: 'h1',
    tag: 'span',
    weight: 700,
  },
  titleProps: {
    variant: 'h4',
  },
  isInvesedTextSelectedColor: false,
};

const {
  bool,
  element,
  func,
  number,
  oneOfType,
  string,
  shape,
} = PropTypes;

SelectableCounterItem.propTypes = {
  isSelected: bool,
  title: string,
  value: oneOfType([string, number]),
  customValueElement: element,
  onClick: func,
  fullWidth: bool,
  /**
   * Color options as in `Typography` component
   */
  color: string,
  className: string,
  inactiveItemBgc: string,
  selectedItemBgc: string,
  titleColor: string,
  biggerPaddings: bool,
  sameFontWeight: bool,
  isInvesedTextSelectedColor: bool,
  valueProps: shape(),
  titleProps: shape(),
};

export default SelectableCounterItem;
