import { updateSelectedFulfillment } from 'store/actions/fulfillments';
import createProcess from './createProcess';

const create = (data) => async (dispatch, getState) => {
  const processId = await dispatch(createProcess(data));

  await dispatch(updateSelectedFulfillment({
    processes: {
      add: [processId],
    },
  }));

  return processId;
};

export default create;
