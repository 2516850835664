import { useState, useEffect } from 'react';

import requests from 'api/requests';

const useCalendarImportInfo = ({ tokenId }) => {
  const [allCalendarsCount, setAllCalendarsCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getCalendarImportInfo = async tokenID => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requests.integrations.calendar.importInfo({
        tokenId: tokenID
      });
      console.log('response from useCalendarImportInfo: ', response);
      console.log('allCalendarsCount: ', response.data?.allCalendarsCount);

      setAllCalendarsCount(response.data?.allCalendarsCount || 0);
    } catch (newError) {
      setError(newError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tokenId) {
      getCalendarImportInfo(tokenId);
    }
  }, [tokenId]);

  return {
    allCalendarsCount,
    isLoading,
    error
  };
};

export default useCalendarImportInfo;
