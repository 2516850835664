import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import AddressInput from 'sections/Addresses/modules/AddressInput';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    addressId: property?.address_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (addressId) => {
    dispatch(updateProperty(ownProps.propertyId)({
      address_id: addressId,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressInput);
