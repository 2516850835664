import {
  SET_REALTOR_OVERALL_LEADS_SUMMARY,
} from 'store/actionTypes/realtorLeads';

const setOverallLeadsSummary = (summary) => ({
  type: SET_REALTOR_OVERALL_LEADS_SUMMARY,
  payload: summary,
});

export default setOverallLeadsSummary;
