import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { createSourceFromNewLeadForm } from 'store/actions/realtorLeads';

import { updateContact } from 'store/actions/realtorContacts';

import {
  optimizedCrmSourcesActions,
  createSourceType,
} from 'store/actions/crm/crmSources';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import SourceSelect from './SourceSelect';

const mapStateToProps = (state, ownProps) => {
  const {
    crmSources: {
      sources,
    },
    realtorNewLeadForm: {
      newCustomSourceName,
    },
  } = state;

  const { contactId } = ownProps;

  const sourceId = selectors.realtorContacts.byId(state, contactId).source_id;

  const source = sources[sourceId];

  if (!source && sourceId) {
    optimizedCrmSourcesActions.getSourceById(sourceId);
  }

  const sourceTypes = selectors.crm.sourceTypes(state);

  if (!sourceTypes) {
    optimizedCrmSourcesActions.getSourceTypes();
  }

  let sourceType = {
    name: '',
  };

  if (source && sourceTypes) {
    sourceType = sourceTypes.find((item) => item.id === source.type);
  }

  return {
    options: sourceTypes,
    value: sourceType.name,
    newCustomSourceName,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelect: (sourceTypeId) => {
    dispatch(setNewLeadFormField('sourceType')(sourceTypeId));
  },

  addSourceType: async (name) => {
    const { contactId } = ownProps;

    const newSourceTypeId = await dispatch(createSourceType({ name }));
    dispatch(setNewLeadFormField('sourceType')(newSourceTypeId));

    const sourceId = await dispatch(createSourceFromNewLeadForm());

    dispatch(updateContact(contactId)({ source_id: sourceId }));

    dispatch(setNewLeadFormField('source')(null));
    dispatch(setNewLeadFormField('sourceDescription')(null));
    dispatch(setNewLeadFormField('sourceType')(null));
    dispatch(setNewLeadFormField('newCustomSourceName')(null));
  },

  onAddNewSourceTypeClick: (newSourceTypeName) => {
    dispatch(setNewLeadFormField('newCustomSourceName')(newSourceTypeName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceSelect);
