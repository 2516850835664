import requests from 'api/requests';

import setProperties from './setProperties';

const getProperties = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.properties.get({
      offset,
      limit,
    });

    dispatch(setProperties(response.data.properties));
  } catch (error) {
    console.error(error);
  }
};

export default getProperties;
