import { connect } from 'react-redux';

import { openModal } from 'store/actions/modals';
import { getContactById } from 'store/actions/realtorContacts';

import Call from './Call';

const mapDispatchToProps = (dispatch, ownProps) => ({
  openModal: () => {
    dispatch(openModal('call')({ contactId: ownProps.contactId }));
  },
  onAfterRecording: () => {
    dispatch(getContactById(ownProps.contactId));
  },
});

export default connect(null, mapDispatchToProps)(Call);
