import {
  SET_AGREEMENT,
} from 'store/actionTypes/agreements';

const setAgreement = (payload) => ({
  type: SET_AGREEMENT,
  payload,
});

export default setAgreement;
