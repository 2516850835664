import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import OfferDateInput from 'sections/Offers/forms/NewOfferForm/OfferDateInput';

const Expiry = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'closesIn',
    control,
  });

  return (
    <OfferDateInput
      defaultValue={value}
      onChange={onChange}
      max={Infinity}
      type="day"
    />
  );
};

const {
  shape,
} = PropTypes;

Expiry.propTypes = {
  control: shape({}).isRequired,
};

export default Expiry;
