import React from 'react';
import PropTypes from 'prop-types';

import Search from 'sections/MLS/modules/PropertiesTableSearchFilter/Search';

const options = [
  {
    value: 'all',
    label: 'All Properties',
  },
  {
    value: 'mine',
    label: 'My Listings',
  },
  {
    value: 'featured',
    label: 'Featured Properties',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

const PropertiesTableSearchFilter = (props) => (
  <Search
    options={options}
    searchInputPlaceholder={props.searchInputPlaceholder}
    {...props}
  />
);

PropertiesTableSearchFilter.defaultProps = {
  searchInputPlaceholder: "Search all properties"
};

const {
  string
} = PropTypes;

PropertiesTableSearchFilter.propTypes = {
  searchInputPlaceholder: string
};

export default PropertiesTableSearchFilter;
