import requests from 'api/requests';

import getById from './getById';

const removeRelations = (relationType) => (matchId) => (data) => async (dispatch) => {
  try {
    await requests.realtor.matchingProperties.removeRelations(relationType)(matchId)(data);

    dispatch(getById(matchId));
  } catch (error) {
    console.error(error);
  }
};

export default removeRelations;
