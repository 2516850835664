import React from 'react';
import PropTypes from 'prop-types';

import NewLogForm from 'sections/Contacts/forms/NewLogForm';

import {
  Modal,
} from './styledItems';

const NewLogModal = (props) => {
  const {
    isVisible,
    closeModal,
  } = props;

  return (
    <Modal
      width={720}
      visible={isVisible}
      okText="Save"
      onCancel={closeModal}
      onOk={closeModal}
    >
      <NewLogForm />
    </Modal>
  );
};

const {
  bool,
  func,
} = PropTypes;

NewLogModal.propTypes = {
  isVisible: bool.isRequired,
  closeModal: func.isRequired,
};

export default NewLogModal;
