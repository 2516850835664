import { connect } from 'react-redux';

// import {
//   openModal,
// } from 'store/actions/modals';

import CallLog from './CallLog';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLog);
