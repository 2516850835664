import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { debounce } from 'shared/utility';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import ContactDescription from './ContactDescription';

const mapStateToProps = (state, ownProps) => {
  const contact = selectors.realtorContacts.byId(state, ownProps.contactId);

  return {
    content: contact?.description,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: debounce((content) => {
    dispatch(updateContact(ownProps.contactId)({ description: content }));
  }, 500),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDescription);
