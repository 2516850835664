export default {
  meetingsPath: () => '/api/meetings',
  meetingPath: (idOrCode) => `/api/meetings/${idOrCode}`,

  meetingDesiredOutcomesPath: (meetingId) => (
    `/api/meetings/${meetingId}/desired-outcomes`
  ),
  meetingDesiredOutcomePath: (meetingId, id) => (
    `/api/meetings/${meetingId}/desired-outcomes/${id}`
  ),

  meetingInvitationsPath: (meetingId) => (
    `/api/meetings/${meetingId}/invitations`
  ),
};
