import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_EXTENSION_REQUESTS,
  SET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER,
  SET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER_COUNT,

  RESET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER,
  RESET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER_COUNT,

  RESET_REALTOR_EXTENSION_REQUESTS_FILTERS,
  RESET_REALTOR_EXTENSION_REQUESTS_FILTER_COUNTERS,

  SET_REALTOR_EXTENSION_REQUEST_ATTACHMENTS,
} from 'store/actionTypes/realtorExtensionRequests';

import {
  getIds,
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  extensionRequests: {},
  extensionRequestsByFilter: {},
  extensionRequestsCountByFilter: {},
};

const reducers = {
  [SET_REALTOR_EXTENSION_REQUESTS]: (state, action) => ({
      ...state,
      extensionRequests: {
        ...state.extensionRequests,
        ...listToObjectByKey(action.payload),
      },
    }),

  [SET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER]: (state, action) => {
    const existing = state.extensionRequestsByFilter[action.payload.filterId] || [];

    const items = [...new Set([
      ...existing,
      ...getIds(action.payload.items),
    ])];

    return {
      ...state,
      extensionRequestsByFilter: {
        ...state.extensionRequestsByFilter,
        [action.payload.filterId]: items,
      },
    };
  },

  [SET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    extensionRequestsCountByFilter: {
      ...state.extensionRequestsCountByFilter,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [RESET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER]: (state, action) => ({
    ...state,
    extensionRequestsByFilter: {
      ...state.extensionRequestsByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_EXTENSION_REQUESTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    extensionRequestsCountByFilter: {
      ...state.extensionRequestsCountByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_EXTENSION_REQUESTS_FILTERS]: (state, action) => ({
    ...state,
    extensionRequestsByFilter: {},
  }),

  [RESET_REALTOR_EXTENSION_REQUESTS_FILTER_COUNTERS]: (state, action) => ({
    ...state,
    extensionRequestsCountByFilter: {},
  }),

  [SET_REALTOR_EXTENSION_REQUEST_ATTACHMENTS]: (state, action) => ({
    ...state,
    extensionRequests: {
      ...state.extensionRequests,
      [action.payload.id]: {
        ...state.extensionRequests[action.payload.id],
        attachments: action.payload.attachments.map(({ attachmentId }) => attachmentId),
      },
    },
  }),
};

function extensionRequests(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default extensionRequests;
