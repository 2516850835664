import apiConnector, { endpoints } from 'api';

/**
 * Sends POST request with payload to create "meeting".
 * @param {Object} payload - The fields of meeting.
 * @param {string} payload.name - The name of meeting.
 * @param {string} payload.code - The code of meeting.
 * @param {?string} payload.password - The password of meeting.
 * @param {Array} payload.desiredOutcomes - The array of desired outcomes.
 * @returns {Promise} A response from server.
 */

const createMeeting = async (payload) => {
  const endpoint = endpoints.OA.meetingsPath();
  let response;

  try {
    response = await apiConnector.OA.post(endpoint, payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default createMeeting;
