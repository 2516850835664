import billing from './billing';
import dictionary from './dictionary';
import crm from './crm'; // eslint-disable-line import/no-cycle
import users from './users';
import hr from './hr';
import myCompany from './myCompany';
import fulfillments from './fulfillments'; // eslint-disable-line import/no-cycle
import teams from './teams';
import employees from './employees';
import customerInvoices from './customerInvoices';
import servicePeople from './servicePeople';
import orders from './orders';
import customerPayments from './customerPayments';
import shippedItems from './shippedItems';
import addresses from './addresses';
import customerDigitalAccounts from './customerDigitalAccounts';
import deals from './deals';
import serviceProcesses from './serviceProcesses'; // eslint-disable-line import/no-cycle
import cancelledOrders from './cancelledOrders';
import refunds from './refunds';
import creditNotes from './creditNotes';
import customerInformed from './customerInformed';
import warehouse from './warehouse'; // eslint-disable-line import/no-cycle
import agreements from './agreements';
import lineItems from './lineItems';
import realtorLeads from './realtorLeads'; // eslint-disable-line import/no-cycle
import properties from './properties'; // eslint-disable-line import/no-cycle
import propertyRequirements from './propertyRequirements';
import realtorContacts from './realtorContacts'; // eslint-disable-line import/no-cycle
import propertyViewings from './propertyViewings'; // eslint-disable-line import/no-cycle
import realtorClients from './realtorClients'; // eslint-disable-line import/no-cycle
import realtorProspects from './realtorProspects'; // eslint-disable-line import/no-cycle
import realtorOpportunities from './realtorOpportunities'; // eslint-disable-line import/no-cycle
import realtorOffers from './realtorOffers'; // eslint-disable-line import/no-cycle
import realtorSpecialConditions from './realtorSpecialConditions'; // eslint-disable-line import/no-cycle
import realtorMatchingProperties from './realtorMatchingProperties'; // eslint-disable-line import/no-cycle
import realtorTransactions from './realtorTransactions'; // eslint-disable-line import/no-cycle
import realtorInspections from './realtorInspections'; // eslint-disable-line import/no-cycle
import realtorRepairRequests from './realtorRepairRequests';
import realtorDocumentRequests from './realtorDocumentRequests';
import realtorAddendumChanges from './realtorAddendumChanges';
import realtorUnansweredQuestions from './realtorUnansweredQuestions';
import realtorExtensionRequests from './realtorExtensionRequests';
import followUpReminders from './followUpReminders';
import realtorMatched from './realtorMatched'; // eslint-disable-line import/no-cycle
import external from './external';
import appCustomization from './appCustomization';
import calls from './calls';
import phoneNumbers from './phoneNumbers';
import emails from './emails';

export default {
  followUpReminders,
  billing,
  dictionary,
  crm,
  users,
  hr,
  myCompany,
  fulfillments,
  teams,
  employees,
  customerInvoices,
  servicePeople,
  orders,
  customerPayments,
  shippedItems,
  addresses,
  customerDigitalAccounts,
  deals,
  serviceProcesses,
  cancelledOrders,
  refunds,
  creditNotes,
  customerInformed,
  warehouse,
  agreements,
  lineItems,
  realtorLeads,
  properties,
  propertyRequirements,
  realtorContacts,
  propertyViewings,
  realtorClients,
  realtorProspects,
  realtorOpportunities,
  realtorOffers,
  realtorSpecialConditions,
  realtorMatchingProperties,
  realtorTransactions,
  realtorInspections,
  realtorRepairRequests,
  realtorDocumentRequests,
  realtorAddendumChanges,
  realtorUnansweredQuestions,
  realtorExtensionRequests,
  realtorMatched,
  external,
  appCustomization,
  calls,
  phoneNumbers,
  emails,
};
