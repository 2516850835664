import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateClient,
} from 'store/actions/realtorClients';

const withClientData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const client = selectors.realtorClients.byId(state, ownProps.clientId);

    return {
      client,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (fieldName) => (value) => {
      dispatch(updateClient(ownProps.clientId)({ [fieldName]: value }));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withClientData;
