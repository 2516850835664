import requests from 'api/requests';

import setOverallFulfillmentsSummary from './setOverallFulfillmentsSummary';

const getOverallFulfillmentsSummary = () => async (dispatch) => {
  try {
    const response = await requests.fulfillments.getSummary();

    dispatch(setOverallFulfillmentsSummary(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOverallFulfillmentsSummary;
