import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import CheckboxLabelled from 'ui/CheckboxLabelled';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      isPastClient,
    },
  } = state;

  return {
    value: isPastClient,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => {
    dispatch(setNewContactFormField('isPastClient')(value));
  },
  labelText: 'Past Client',
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxLabelled);
