/* eslint-disable react/jsx-no-bind */

import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'ui/Chip/Chip';
import Table from 'ui/Table';

import ExpandedTable from 'sections/OffersV2/modules/Tables/ExpandedTable';

import columns from './columns';

import './tableStyles.scss';

const OffersTable = (props) => {
  const {
    dataSource,
    tableName,
    expiryIndex,
  } = props;

  return (
    <Table
      key="offers-table"
      dataSource={dataSource || []}
      columns={columns(tableName, expiryIndex)}
      pagination={false}
      expandedRowRender={(record, index, indent, expanded) => (
        <ExpandedTable
          dataSource={record.items}
        />
      )}
      expandIcon={(params) => {
        if (!params.record.items.length) {
          return null;
        }

        return (
          <Chip
            variant="subtle"
            onClick={params.onExpand}
          >
            {!params.expanded ? `${params.record.items.length}` : 'X'}
          </Chip>
        );
      }}
      rowClassName={() => tableName}
    />
  );
};

OffersTable.defaultProps = {
  dataSource: null,
  tableName: null,
  expiryIndex: null,
};

const {
  shape,
  string,
} = PropTypes;

OffersTable.propTypes = {
  dataSource: shape({}),
  tableName: string,
  expiryIndex: string,
};

export default OffersTable;
