import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyImage from './PropertyImage';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const images = selectors.properties.propertyImages(state, propertyId);

  const mainImageIndex = images.findIndex((image) => image.isMain);

  const image = images[mainImageIndex > 0 ? mainImageIndex : 0];

  return {
    image,
  };
};

export default connect(mapStateToProps)(PropertyImage);
