import { connect } from 'react-redux';
import NewLeadSuccesfullyAlert from './NewLeadSuccesfullyAlert';


const mapStateToProps = (state) => {
  const {
    newLeadAssigned,
  } = state.leads;

  if (!newLeadAssigned.length) return undefined;

  const {
    Name,
    Surname,
  } = newLeadAssigned[0];
  return {
    Name,
    Surname,
    isAssignedSomeone: true,
  };
};

export default
connect(
  mapStateToProps,
)(NewLeadSuccesfullyAlert);
