import connectReducers from 'store/connectReducers';

import {
  SET_NEW_PROPERTY_VIEWING_MODAL_OPEN,
  SET_NEW_PROPERTY_VIEWING_MODAL_CLOSED,
} from 'store/actionTypes/propertyViewings';

const initialState = {
  isVisible: false,
  propertyId: null,
  buyerField: false,
  buyerAgentField: false,
  sellerAgentField: false,
  propertyField: false,
  contactField: false,
};

const reducers = {
  [SET_NEW_PROPERTY_VIEWING_MODAL_OPEN]: (state, action) => ({
      ...state,
      isVisible: true,
      propertyId: action.payload.propertyId,
      buyerField: !!action.payload.buyerField,
      buyerAgentField: !!action.payload.buyerAgentField,
      sellerAgentField: !!action.payload.sellerAgentField,
      propertyField: !!action.payload.propertyField,
      contactField: !!action.payload.contactField,
    }),

  [SET_NEW_PROPERTY_VIEWING_MODAL_CLOSED]: () => initialState,
};

function newViewingModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newViewingModal;
