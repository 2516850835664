import requests from 'api/requests';

import setTasksSummaryForTeam from './setTasksSummaryForTeam';

const getTeamTasksSummary = (teamId) => async (dispatch) => {
  try {
    const summaryResponse = await requests.tasks.getTasksSummary('team')(teamId);

    dispatch(setTasksSummaryForTeam(teamId)(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};


export default getTeamTasksSummary;
