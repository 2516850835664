import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getRepairRequests from './getRepairRequests';
import getRepairRequestById from './getRepairRequestById';
import getRepairRequestsForFilter from './getRepairRequestsForFilter';

import deleteRepairRequest from './deleteRepairRequest';
import updateRepairRequest from './updateRepairRequest';

import openRepairRequestsModal from './openRepairRequestsModal';
import closeRepairRequestsModal from './closeRepairRequestsModal';

import openRepairRequestNotesModal from './openRepairRequestNotesModal';
import closeRepairRequestNotesModal from './closeRepairRequestNotesModal';

import getRepairRequestAttachments from './getRepairRequestAttachments';

import createRepairRequest from './createRepairRequest';

import openNewRepairRequestModal from './openNewRepairRequestModal';

export {
  getRepairRequests,
  getRepairRequestById,
  getRepairRequestsForFilter,
  getRepairRequestAttachments,

  updateRepairRequest,
  deleteRepairRequest,

  openRepairRequestsModal,
  closeRepairRequestsModal,

  openRepairRequestNotesModal,
  closeRepairRequestNotesModal,

  createRepairRequest,

  openNewRepairRequestModal,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorRepairRequestsActions = {
  getRepairRequestsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getRepairRequestsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getRepairRequestById: memoizeDebounce(
    (id) => store.dispatch(getRepairRequestById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getRepairRequestAttachments: memoizeDebounce(
    (id) => store.dispatch(getRepairRequestAttachments(id)),
    debounceDelay,
    debounceOptions,
  ),
};
