import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getPayments from './getPayments';
import getPaymentById from './getPaymentById';

import createPayment from './createPayment';

import setSelectedPayment from './setSelectedPayment';

import updatePayment from './updatePayment';
import getPaymentAttachments from './getPaymentAttachments';

export { getPayments };
export { getPaymentById };

export { createPayment };

export { setSelectedPayment };

export { updatePayment };
export { getPaymentAttachments };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCustomerPaymentsActions = {
  getPaymentById: memoizeDebounce(
    (id) => store.dispatch(getPaymentById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
