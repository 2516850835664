import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  createAddress,
} from 'store/actions/addresses';

import AddressInput from './AddressInput';

const mapStateToProps = (state, ownProps) => {
  const value = selectors.addresses.byId(state, ownProps.addressId)?.address;

  return {
    value,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (addressData) => {
    const addressId = await dispatch(createAddress(addressData));
    ownProps.handleChange(addressId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressInput);
