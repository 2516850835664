import { createSelector } from 'reselect';

import { optimizedDictionaryActions } from 'store/actions/dictionaryV2';

const saleTypes = (state) => state.dictionaryV2.realtorSaleTypes;

export default createSelector(
  saleTypes,
  (collection) => {
    if (!collection) {
      optimizedDictionaryActions.getRealtorSaleTypesDictionary();
      return [];
    }

    return collection.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  },
);
