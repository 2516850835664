import requests from 'api/requests';

import setMany from './setMany';

const get = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.creditNotes.get({
      offset,
      limit,
    });

    dispatch(setMany(response.data.creditNotes));
  } catch (error) {
    console.error(error);
  }
};

export default get;
