import get from './get';
import getById from './getById';
import create from './create';
import update from './update';
import deleteClient from './deleteClient';
import getOverallSummary from './getOverallSummary';

export default {
  get,
  getById,
  create,
  update,
  deleteClient,
  getOverallSummary,
};
