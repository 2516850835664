import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import CallsContext from 'sections/Calls/context/CallsContext';

import DialpadView from './DialpadView';
import CallEndedView from './CallEndedView';

import { Wrapper } from './styledItems';

const CallViews = (props) => {
  const {
    callEnded,
    contactId,
    setSelectedContact,
    onCancel,
  } = props;

  const [callDurationSeconds, setCallDurationSeconds] = useState(0);
  const [lastCallDurationSeconds, setLastCallDurationSeconds] = useState(0);

  const {
    callStatus,
    refreshDevicesList,
    stopUsingMic,
  } = useContext(CallsContext);

  useEffect(() => {
    refreshDevicesList();

    return () => {
      stopUsingMic();
    };
  }, []);

  useEffect(() => {
    let timer;

    if (callStatus === 'in call') {
      timer = setInterval(() => setCallDurationSeconds((seconds) => seconds + 1), 1000);
    } else {
      setCallDurationSeconds(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [
    callStatus,
  ]);

  useEffect(() => {
    if (callDurationSeconds > 0) {
      setLastCallDurationSeconds(callDurationSeconds);
    }
  }, [
    callDurationSeconds,
  ]);

  return (
    <Wrapper withMinWidth={callEnded}>
      {
        !callEnded && (
          <DialpadView
            onCancel={onCancel}
            contactId={contactId}
            setSelectedContact={setSelectedContact}
            callDuration={callDurationSeconds}
          />
        )
      }
      {
        callEnded && <CallEndedView callDuration={lastCallDurationSeconds} onCancel={onCancel} />
      }
    </Wrapper>
  );
};

CallViews.defaultProps = {
  contactId: null,
};

const {
  number,
  bool,
  func,
} = PropTypes;

CallViews.propTypes = {
  callEnded: bool.isRequired,
  contactId: number,
  onCancel: func.isRequired,
  setSelectedContact: func.isRequired,
};

export default CallViews;
