import { connect } from 'react-redux';

import SearchContactInput from 'shared/inputs/SearchContactInput';

const mapStateToProps = (state, ownProps) => {
  const {
    selectedContactId,
    label,
  } = ownProps;

  return {
    selectedContactId,
    label,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (contactId) => {
    ownProps.setContact(contactId);
  },

  handleDelete: () => {
    ownProps.setContact(null);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContactInput);
