/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import {
  Controller,
  useForm,
} from 'react-hook-form';

import Grid from 'ui/Grid';

import SpaceBetweenBox from 'shared/components/UI/SpaceBetweenBox';
import Typography from 'ui/Typography';
import FormTextfield from 'shared/inputs/FormTextfield';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import NewContactArea from 'sections/Transactions/modules/NewContactArea';
import SearchContactInput from 'sections/Transactions/modules/SearchContactInput';

import InspectionTypeSelect from './InspectionTypeSelect';

import AttachmentsUpload from './AttachmentsUpload';

import {
  MarginBox,
} from './styledItems';

const NewInspectionForm = (props) => {
  const {
    formId,
    onSubmit: propsOnSubmit,

    defaultInspectionType,
    defaultNote,
    defaultInspectionDate,
    defaultInspectorId,
    isReinspection,
  } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // inspector
  const [selectedInspectorId, setInspector] = useState(null);

  useEffect(() => {
    setInspector(defaultInspectorId);
  }, [
    defaultInspectorId,
  ]);

  const onSubmit = useCallback((data) => {
    propsOnSubmit({
      ...data,
      selectedInspectorId,
      isReinspection,
    });
  }, [
    propsOnSubmit,
    selectedInspectorId,
  ]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography
                variant="title3"
                weight={400}
              >
                Inspection type
              </Typography>
              <Controller
                name="inspectionType"
                control={control}
                defaultValue={defaultInspectionType}
                render={({ field }) => (
                  <InspectionTypeSelect
                    {...field}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="title3"
                weight={400}
              >
                Inspector
              </Typography>
              <SearchContactInput
                selectedContactId={selectedInspectorId}
                setContact={setInspector}
                label=""
              />
              {
                !selectedInspectorId && (
                  <div style={{ marginTop: 10 }}>
                    <NewContactArea
                      errors={errors}
                      decorated={false}
                      objectFormName="newInspector"
                      control={control}
                    />
                  </div>
                )
              }
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="title3"
                weight={400}
              >
                Inspection Date
              </Typography>
              <Controller
                name="inspectionDate"
                control={control}
                defaultValue={defaultInspectionDate}
                render={({ field }) => (
                  <DateTimeInput
                    {...field}
                    handleChange={field.onChange}
                    label=""
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="title3"
                weight={400}
              >
                Note
              </Typography>
              <Controller
                name="notes"
                control={control}
                defaultValue={defaultNote}
                render={({ field }) => (
                  <FormTextfield
                    label=""
                    multiline
                    rows={3}
                    {...field}
                    handleChange={field.onChange}
                  />
                )}
                rules={{
                  required: false,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AttachmentsUpload />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

NewInspectionForm.defaultProps = {
  defaultInspectionType: null,
  defaultNote: null,
  defaultInspectionDate: null,
  defaultInspectorId: null,
  isReinspection: false,
};

const {
  bool,
  func,
  number,
  string,
} = PropTypes;

NewInspectionForm.propTypes = {
  formId: string.isRequired,
  onSubmit: func.isRequired,
  defaultInspectionType: number,
  defaultNote: string,
  defaultInspectionDate: string,
  defaultInspectorId: string,
  isReinspection: bool,
};

export default NewInspectionForm;
