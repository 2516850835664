import {
  SET_FULFILLMENT_CHANGELOG,
} from 'store/actionTypes/fulfillments';

const setFulfillmentChangelog = ({ fulfillmentId, changelog }) => ({
  type: SET_FULFILLMENT_CHANGELOG,
  payload: {
    fulfillmentId,
    changelog,
  },
});

export default setFulfillmentChangelog;
