import React, {
  useContext,
  useMemo,
} from 'react';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';

import CallsContext from 'sections/Calls/context/CallsContext';

import PhoneNumber from 'sections/Calls/modules/ActivePhonesList/PhoneNumber';

import {
  List,
  ListItem,
} from './styledItems';

const ActivePhonesList = () => {
  const {
    activePhones,
    isPhoneListLoading: isLoading,
    getActivePhones,
  } = useContext(CallsContext);

  if (isLoading) return <Spin />;

  const hasPhones = useMemo(() => activePhones && activePhones?.length > 0, [
    activePhones,
  ]);

  const listItems = useMemo(() => {
    if (!hasPhones) return [];

    return activePhones.map((item) => (
      <ListItem>
        <PhoneNumber
          phoneNumber={item.value}
        />
      </ListItem>
    ));
  }, [
    hasPhones,
    activePhones,
  ]);

  return (
    <Flex
      flexDirection="column"
      spacing={2}
      fullWidth
    >
      {
        !hasPhones && (
          <Typography align="center">
            There are no mobile numbers added yet.
          </Typography>
        )
      }
      {
        hasPhones && (
          <List>
            { listItems }
          </List>
        )
      }
      <Button
        icon="refresh-cw"
        variant="secondary"
        onClick={getActivePhones}
      />
    </Flex>
  );
};

export default ActivePhonesList;
