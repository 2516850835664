import React, {
  useContext,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import requests from 'api/requests';

import CheckboxLabelled from 'ui/CheckboxLabelled';
import Spin from 'ui/Spin';

import context from 'sections/Contacts/modals/AssignContactModal/ContextProvider/context';

const AllSelector = (props) => {
  const {
    setSelectedContacts,
  } = props;

  const [value, setValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    contactsListFilters,
    contactsListTotalCount,
  } = useContext(context);

  const handleChange = useCallback(async (shouldSelectAll) => {
    setValue(shouldSelectAll);

    if (!shouldSelectAll) {
      setSelectedContacts([]);
      return;
    }

    setLoading(true);

    try {
      const result = await requests.realtor.contacts.getCompleteIdsList(contactsListFilters);
      const ids = result?.data?.items || [];

      setSelectedContacts(ids);
    } catch (err) {
      setValue(false);
    } finally {
      setLoading(false);
    }
  }, [
    setSelectedContacts,
    JSON.stringify(contactsListFilters),
  ]);

  return (
    <Spin spinning={loading}>
      <CheckboxLabelled
        value={value}
        onChange={handleChange}
        labelText={`Select All (${contactsListTotalCount || 0})`}
      />
    </Spin>
  );
};

const { func } = PropTypes;

AllSelector.propTypes = {
  setSelectedContacts: func.isRequired,
};

export default AllSelector;
