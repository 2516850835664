export const SET_NEW_REALTOR_PROSPECT_MODAL_OPEN = 'SET_NEW_REALTOR_PROSPECT_MODAL_OPEN';
export const SET_NEW_REALTOR_PROSPECT_MODAL_CLOSED = 'SET_NEW_REALTOR_PROSPECT_MODAL_CLOSED';

// eslint-disable-next-line max-len
export const SET_NEW_REALTOR_PROSPECT_MODAL_PROPERTY_REQUIREMENT = 'SET_NEW_REALTOR_PROSPECT_MODAL_PROPERTY_REQUIREMENT';
export const SET_NEW_REALTOR_PROSPECT_MODAL_ATTACHMENTS = 'SET_NEW_REALTOR_PROSPECT_MODAL_ATTACHMENTS';

export const SET_REALTOR_PROSPECT = 'SET_REALTOR_PROSPECT';
export const SET_REALTOR_PROSPECTS = 'SET_REALTOR_PROSPECTS';
export const SET_REALTOR_SELECTED_PROSPECT = 'SET_REALTOR_SELECTED_PROSPECT';
export const SET_REALTOR_PROSPECT_SELECTED_VIEW = 'SET_REALTOR_PROSPECT_SELECTED_VIEW';

export const SET_REALTOR_PROSPECTS_FILTER_ITEM = 'SET_REALTOR_PROSPECTS_FILTER_ITEM';
export const RESET_REALTOR_PROSPECTS_FILTER_ITEM = 'RESET_REALTOR_PROSPECTS_FILTER_ITEM';
export const CLEAR_REALTOR_PROSPECTS_FILTER = 'CLEAR_REALTOR_PROSPECTS_FILTER';

export const SET_REALTOR_PROSPECTS_FOR_FILTER = 'SET_REALTOR_PROSPECTS_FOR_FILTER';
export const SET_REALTOR_PROSPECTS_FOR_FILTER_COUNT = 'SET_REALTOR_PROSPECTS_FOR_FILTER_COUNT';

export const SET_REALTOR_PROSPECTS_OVERALL_SUMMARY = 'SET_REALTOR_PROSPECTS_OVERALL_SUMMARY';

export const SET_REALTOR_PROSPECT_MATCHING_PROPERTIES = 'SET_REALTOR_PROSPECT_MATCHING_PROPERTIES';
