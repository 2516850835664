export const SET_STRIPE_CUSTOMER_SECRET = 'SET_STRIPE_CUSTOMER_SECRET';
export const SET_STRIPE_CUSTOMER_ID = 'SET_STRIPE_CUSTOMER_ID';
export const SET_STRIPE_PAYMENT_METHODS = 'SET_STRIPE_PAYMENT_METHODS';
export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';
export const SET_BILLING_CONTACTS = 'SET_BILLING_CONTACTS';
export const SET_YEARLY_CONFIRMATION_ON = 'SET_YEARLY_CONFIRMATION_ON';
export const SET_YEARLY_CONFIRMATION_OFF = 'SET_YEARLY_CONFIRMATION_OFF';
export const SET_MONTHLY_CONFIRMATION_ON = 'SET_MONTHLY_CONFIRMATION_ON';
export const SET_MONTHLY_CONFIRMATION_OFF = 'SET_MONTHLY_CONFIRMATION_OFF';

export default {
  SET_STRIPE_CUSTOMER_SECRET,
  SET_STRIPE_CUSTOMER_ID,
  SET_STRIPE_PAYMENT_METHODS,
  SET_SELECTED_PAYMENT_METHOD,
  SET_BILLING_CONTACTS,
  SET_YEARLY_CONFIRMATION_ON,
  SET_YEARLY_CONFIRMATION_OFF,
  SET_MONTHLY_CONFIRMATION_ON,
  SET_MONTHLY_CONFIRMATION_OFF,
};
