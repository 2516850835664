import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

import { formatPhoneNumber } from 'shared/utils/phoneNumbers';

const usePhoneNumberFormatted = (phoneNumberId) => {
  const phoneNumber = useReselect(selectors.phoneNumbers.byId, phoneNumberId);

  return formatPhoneNumber(phoneNumber?.e164_format);
};

export default usePhoneNumberFormatted;
