import React from 'react';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import RealtorContactAvatar from 'shared/components/UI/RealtorContactAvatar';

const EmptyPlaceholder = () => (
  <Flex
    justifyContent="center"
    alignItems="center"
    spacing={2}
  >
    <RealtorContactAvatar />
    <Typography
      variant="title2"
      color="disabled"
      weight={400}
      fontStyle="italic"
      noMargin
      style={{ lineHeight: 1.6 }}
    >
      Contact information will appear here.
    </Typography>
  </Flex>
);

export default EmptyPlaceholder;
