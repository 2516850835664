import {
  SET_PROPERTIES,
} from 'store/actionTypes/properties';

const setProperties = (properties) => ({
  type: SET_PROPERTIES,
  payload: properties,
});

export default setProperties;
