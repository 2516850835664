import apiConnector, { endpoints } from 'api';

/**
 * Checks the user's password
 * @param {object} data User data
 * */

const comparePassword = async (data) => {
  const result = await apiConnector.global.post(
    endpoints.global.password.compare,
    data,
  );

  return result.data.isPasswordCorrect;
};

export default comparePassword;
