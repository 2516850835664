import { createSelector } from 'reselect';

import moment from 'moment';

import {
  isNumber,
  isArray,
  sum,
  uniq,
}
from 'shared/utility';

import {
  optimizedLineItemsActions,
} from 'store/actions/line-items';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

import {
  optimizedWarehouseActions,
} from 'store/actions/warehouse';

import getNextStepFromCompletedSum from 'shared/utils/steppedProcesses/getNextStepFromCompletedSum';

import getProcessById from './byId'; // eslint-disable-line import/no-cycle

const laneNames = {
  agreement: 'Agreement',
  invoice: 'Invoice',
  payment: 'Awaiting Payment',
  warehouse: 'With Warehouse',
  shipping: 'Item Shipped',
  delivered: 'Delivered',
  feedback: 'Feedback',
};

const laneWeights = {
  payment: 1,
  warehouse: [2, 4],
  shipping: 8,
  delivered: 16,
};

const getItemDescription = (lane, value = '') => {
  const laneTextTemplates = {
    agreement: 'Click to add agreement',
    invoice: 'Add invoice details',
    payment: `$${value} Pending`,
    warehouse: `SKU: ${value}`,
    shipping: `ETA: ${value}`,
    delivered: 'Delivered',
    feedback: null,
  };

  return laneTextTemplates[lane];
};

const shouldShowItemInThisLane = (lane) => (itemStatus) => {
  if (isNumber(laneWeights[lane])) {
    return getNextStepFromCompletedSum(itemStatus) === laneWeights[lane];
  }

  if (isArray(laneWeights[lane])) {
    return laneWeights[lane].includes(getNextStepFromCompletedSum(itemStatus));
  }

  return false;
};

export default createSelector(
  (state) => state.lineItems.lineItems,
  (state, processId) => processId,
  (state, processId, lane) => lane,
  (state) => state,
  (state) => state.crmProducts.products,
  (state) => state.warehouse.packages,
  (state, processId, lane, options) => options,
  (
    lineItems,
    processId,
    lane,
    state,
    products,
    packages,
    options = {
      groupBy: 'product',
    },
  ) => {
    const getLineItemPendingValue = (itemId) => {
      const lineItem = lineItems[itemId];

      if (!lineItem) {
        optimizedLineItemsActions.getLineItemById(itemId);
        return 0;
      }

      const pricePerUnit = parseFloat(lineItem.price_per_unit || 0);
      const discountType = parseFloat(lineItem.discount_type);
      const discountAmount = parseFloat(lineItem.discount_amount || 0);

      const priceWithoutDiscount = pricePerUnit;

      const discount = (
        discountType === 1
          ? (
            priceWithoutDiscount / 100 * discountAmount
          ) : (
            discountAmount
          )
      );

      const totalPrice = Number((priceWithoutDiscount - +discount).toFixed(2));

      return totalPrice > 0 ? totalPrice : 0;
    };

    const getLineItemSku = (itemId) => {
      const lineItem = lineItems[itemId];

      if (!lineItem) {
        optimizedLineItemsActions.getLineItemById(itemId);
        return 'N/A';
      }

      return 'SX43563';
    };

    const getLineItemEta = (itemId) => {
      const lineItem = lineItems[itemId];

      if (!lineItem) {
        optimizedLineItemsActions.getLineItemById(itemId);
        return 'N/A';
      }

      const packageId = JSON.parse(lineItem.packages)[0];

      const pckg = packages[packageId];

      if (!pckg) {
        optimizedWarehouseActions.getWarehousePackageById(packageId);

        return 'N/A';
      }

      if (!pckg?.expected_delivery_date) {
        return 'N/A';
      }

      return moment(pckg?.expected_delivery_date).format('Do MMM, YYYY (ddd)');
    };

    const getBodyTextValue = (laneName) => (itemId) => {
      const getters = {
        payment: getLineItemPendingValue,
        warehouse: getLineItemSku,
        shipping: getLineItemEta,
      };

      if (!getters[laneName]) return null;

      return getters[laneName](itemId);
    };

    const processLineItems = getProcessById(state, processId).line_items || [];

    if (lane === 'agreement' || lane === 'invoice') {
      return {
        id: lane,
        name: laneNames[lane],
        list: [{
          title: `${processLineItems.length} ${processLineItems.length > 1 ? 'items' : 'item'}`,
          bodyText: getItemDescription(lane),
        }],
      };
    }

    if (options?.groupBy === 'product') {
      const lineItemIds = [];

      const laneItems = processLineItems.reduce((acc, itemId) => {
        const lineItem = lineItems[itemId];

        if (!lineItem) {
          optimizedLineItemsActions.getLineItemById(itemId);
          return acc;
        }

        // if (getNextStepFromCompletedSum(lineItem.process_status) === laneWeights[lane]) {
        if (shouldShowItemInThisLane(lane)(lineItem.process_status)) {
          lineItemIds.push(itemId);

          const productId = lineItem.product_id;

          const product = products[productId];

          if (!product && productId) {
            optimizedCrmProductsActions.getProductById(productId);
          }

          if (!acc[productId]) {
            acc[productId] = {
              title: product?.name || 'N/A',
              count: 1,
              lineItemIds: [lineItem.id],
              bodyText: getItemDescription(lane, getBodyTextValue(lane)(itemId)),
              values: [getBodyTextValue(lane)(itemId)],
            };
          } else {
            acc[productId].title = `${product?.name || 'N/A'}`;
            acc[productId].count += 1;
            acc[productId].lineItemIds.push(lineItem.id);

            if (lane === 'payment') {
              acc[productId].values.push(getBodyTextValue(lane)(itemId));
              acc[productId].bodyText = getItemDescription(lane, sum(acc[productId].values));
            }

            if (lane === 'shipping') {
              acc[productId].values.push(getBodyTextValue(lane)(itemId));
              acc[productId].bodyText = getItemDescription(lane, uniq(acc[productId].values).join(', '));
            }
          }
        }

        return acc;
      }, {});

      const laneData = {
        id: lane,
        name: laneNames[lane],
        list: Object.values(laneItems),
        lineItemIds,
      };

      return laneData;
    }

    const laneItems = processLineItems.reduce((acc, itemId) => {
      const lineItem = lineItems[itemId];

      if (!lineItem) {
        optimizedLineItemsActions.getLineItemById(itemId);
        return acc;
      }

      if (shouldShowItemInThisLane(lane)(lineItem.process_status)) {
        const productId = lineItem.product_id;

        const product = products[productId];

        if (!product && productId) {
          optimizedCrmProductsActions.getProductById(productId);
        }

        acc.push({
          ...lineItem,
          title: `${lineItem.id} ${product?.name}` || 'N/A',
          count: 1,
          lineItemIds: [lineItem.id],
          bodyText: getItemDescription(lane, getBodyTextValue(lane)(itemId)),
        });

        return acc;
      }

      return acc;
    }, []);

    const laneData = {
      id: lane,
      name: laneNames[lane],
      list: laneItems,
    };

    return laneData;
  },
);
