import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  NavLink,
} from 'react-router-dom';

import withContactData from 'sections/Contacts/hocs/withContactData';

import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

const ConflictItem = withContactData((props) => {
  const {
    contactId,
    contactData,
  } = props;

  return (
    <NavLink
      to={`/contacts/${contactId}`}
      target="_blank"
    >
      <Typography
        color="main-text-inversed"
        textDecoration="underline"
      >
        {'#'}
        {contactId}
        {': '}
        {contactData?.first_name || ''}
        {' '}
        {contactData?.last_name || ''}
        {contactData?.email_primary && <br />}
        {contactData?.email_primary || ''}
      </Typography>
    </NavLink>
  );
});

const ConflictsList = (props) => {
  const {
    contactIds,
  } = props;

  return (
    <div>
      {
        contactIds.map((item) => (
          <ConflictItem
            key={item}
            contactId={item}
          />
        ))
      }
    </div>
  );
};

const getSympleteField = (fieldMap) => (rawHeader) => fieldMap?.[rawHeader];

const getTitle = (fieldMap, fieldLabelDict) => (item) => {
  const sympleteField = getSympleteField(fieldMap)(item);

  if (!fieldMap || !sympleteField || sympleteField === 'noImport') {
    return (
      <strike>
        {item}
      </strike>
    );
  }

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {`${item} → ${fieldLabelDict[sympleteField]}`}
    </div>
  );
};

const conflictFields = {
  name: new Set([
    'first_name',
    'last_name',
  ]),
  email: new Set([
    'email_primary',
  ]),
};

const getColumns = (fieldMap, fieldLabelDict) => (headers) => headers.map((item) => ({
  title: getTitle(fieldMap, fieldLabelDict)(item),
  dataIndex: item,
  key: item,
  render: (value, record) => {
    const sympleteField = getSympleteField(fieldMap)(item);

    if (conflictFields.name.has(sympleteField) && record.sympleteConflicts?.name) {
      return (
        <Tooltip
          title={() => (
            <div>
              <Typography
                color="main-text-inversed"
              >
                You already have contacts with the same name in your Symplete list:
              </Typography>
              <ConflictsList contactIds={record.sympleteConflicts?.name} />
            </div>
          )}
        >
          <Typography
            variant="cell"
            ellipsis
            color="error"
          >
            {value}
          </Typography>
        </Tooltip>
      );
    }

    if (conflictFields.email.has(sympleteField) && record.sympleteConflicts?.email) {
      return (
        <Tooltip
          title={() => (
            <div>
              <Typography
                color="main-text-inversed"
              >
                You already have contacts with the same email in your Symplete list:
              </Typography>
              <ConflictsList contactIds={record.sympleteConflicts?.email} />
            </div>
          )}
        >
          <Typography
            variant="cell"
            ellipsis
            color="error"
          >
            {value}
          </Typography>
        </Tooltip>
      );
    }

    return (
      <Typography
        variant="cell"
        ellipsis
      >
        {value}
      </Typography>
    );
  },
}));

const getDataSource = (conflicts) => (headers) => (data) => data.map((dataRow, dataIndex) => headers.reduce((acc, item, index) => {
  acc[item] = dataRow[index];
  return acc;
}, {
  sympleteConflicts: conflicts?.summary?.[dataIndex],
  sympleteDataIndex: dataIndex,
}));

const getDataSample = (headers) => (data) => headers.reduce((acc, item) => {
    const values = [...new Set(data.map((dataItem) => dataItem[item]))]; // TODO: optimize for large arrays to stop mapping after N unique values

    acc[item] = values.slice(0, 3).join('\n').trim();

    return acc;
  }, {});

const useRawData = ({
  fieldMap,
  fieldLabelDict,
  conflicts,
}) => {
  const [rawHeaders, setHeaders] = useState([]);
  const [columns, setColumns] = useState([]);

  const [rawData, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [dataSample, setDataSample] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [delimiter, setDelimiter] = useState(null);

  const setRawHeaders = useCallback((headers) => {
    setHeaders(headers);
  });

  const setRawData = useCallback((data) => {
    setData(data);
  });

  const setTableData = useCallback((headers, data) => {
    setHeaders(headers);
    setData(data);
  });

  useEffect(() => {
    setIsLoading(true);

    setColumns(getColumns(fieldMap, fieldLabelDict)(rawHeaders));

    const newDataSource = getDataSource(conflicts)(rawHeaders)(rawData);

    setDataSource(newDataSource);
    setDataSample(getDataSample(rawHeaders)(newDataSource));

    setIsLoading(false);
  }, [
    rawHeaders,
    rawData,
    fieldMap,
    fieldLabelDict,
    conflicts,
  ]);

  const reset = useCallback(() => {
    setRawHeaders([]);
    setColumns([]);
    setData([]);
    setDataSource([]);
    setDataSample([]);
    setDelimiter(null);
  });

  return {
    rawHeaders,
    columns,
    setRawHeaders,
    setRawData,
    rawData,
    dataSource,
    dataSample,
    delimiter,
    setDelimiter,
    setTableData,
    reset,
    isLoading,
  };
};

export default useRawData;
