import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Button from 'ui/Button';

import PointerBox from 'shared/components/UI/PointerBox';
import OfferDetails from 'shared/components/OfferDetails';

import ContactCard from 'shared/modules/ContactCard';
import LessContactCard from 'shared/modules/LessContactCard';

const OfferOverview = (props) => {
  const {
    offerId,
    userRole,
    contactId,
    agentId,
    contactRole,
    agentRole,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <>
      <Typography
        variant="title1"
        weight="bold"
        style={{ textTransform: 'capitalize' }}
      >
        {userRole ? `offer overview | ${userRole}` : 'offer overview'}
      </Typography>

      <PointerBox
        anchorElement={null}
        backgroundColor={theme.palette['background-flat']}
        borderColor={theme.palette['input-border']}
      >
        <OfferDetails
          offerId={offerId}
          shouldShowAttachments={false}
          relatedContacts={[
            <ContactCard contactId={agentId} role={agentRole} />,
            <LessContactCard contactId={contactId} role={contactRole} />,
          ]}
          toolbar={(
            <Flex spacing={1}>
              <Button
                variant="secondary"
              >
                Decline
              </Button>
              <Button variant="secondary">
                Counter
              </Button>
              <Button variant="primary">
                Accept
              </Button>
            </Flex>
          )}
        />
      </PointerBox>
    </>
  );
};

OfferOverview.defaultProps = {
};

const {
  number,
  string,
  // func,
} = PropTypes;

OfferOverview.propTypes = {
  offerId: number.isRequired,
  userRole: string.isRequired,
  contactId: string.isRequired,
  agentId: string.isRequired,
  contactRole: string.isRequired,
  agentRole: string.isRequired,
};

export default OfferOverview;
