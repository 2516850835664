import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

import {
  optimizedAddressesActions,
} from 'store/actions/addresses';

import { getUserOptimized } from 'store/actions/users';

import propertyImages from 'store/selectors/properties/propertyImages'; // eslint-disable-line import/no-cycle

export default createSelector(
  (state) => state,
  (state) => state.propertyViewings.viewings,
  (state) => state.properties.properties,
  (state) => state.addresses.addresses,
  (state) => state.users.realtorContact,
  (state, filterId) => state.propertyViewings.viewingsByFilter[filterId],
  (state, filterId, viewingType) => viewingType,
  (state, viewings, properties, addresses, stateContacts, filteredIds, viewingType) => {
    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((acc, viewingId) => {
      const viewing = viewings[viewingId];

      if (!viewing && viewingId) {
        optimizedPropertyViewingActions.getPropertyViewingById(viewingId);
        return acc;
      }

      const {
        property_id,
        contacts,
        date_time,
        valid_to,
      } = viewing;

      if (valid_to) {
        return acc;
      }

      const property = properties[property_id];

      if (!property && property_id) {
        optimizedPropertyActions.getPropertyById(property_id);
        return acc;
      }

      const { address_id } = property;

      const propertyAddress = addresses[address_id];

      if (!propertyAddress) {
        optimizedAddressesActions.getAddressById(address_id);
        return acc;
      }

      const { address } = propertyAddress;

      const contactsNameArray = contacts.map((item) => {
        const contactId = item?.id || item;
        const contact = stateContacts[contactId];

        if (!contact) {
          getUserOptimized('realtorContact', contactId);

          return '';
        }

        return `${contact.name} ${contact.surname}`;
      });

      const images = propertyImages(state, property_id) || [];

      const attachment = images.find((image) => image.isMain) || images[0];

      acc.push({
        labels: [{ title: viewingType, variant: 'followup' }],
        description: `${contactsNameArray.join(', ')} will go to ${address}`,
        attachment,
        date: date_time,
        objectType: viewingType === 'Pitches' ? 'listingPitches' : 'propertyShowings',
        objectId: viewingId,
      });
      return acc;
    }, []);

    return result;
  },
);
