import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import Call from './Call';

const mapStateToProps = (state) => {
  const {
    modals: {
      call,
    },
  } = state;

  return {
    isVisible: call?.isVisible,
    contactId: call?.contactId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal('call')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Call);
