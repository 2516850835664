import styled from 'styled-components';
import Button from 'shared/components/IconButton';
import Video from 'oa/components/Video/index';
import Modal from 'ui/Modal';

export const HeaderWrapper = styled.div`
  /* padding: 7px 15px; */
  display: flex;
  justify-content: space-between;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MeetingControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #979797;
  padding-right: 10px;
`;

export const ModalControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const LogoWrapper = styled.div`
  border-right: 1px solid #979797;
  padding-right: 10px;
`;

export const MeetingTitleWrapper = styled.div`
  padding-left: 10px;
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 530px;
  overflow: hidden;
`;

export const MainVideo = styled(Video)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const IconButton = styled(Button)`
  margin-left: 8px;
  margin-right: 8px;
`;

export const Title = styled.h1`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #34495e;
  margin-bottom: 0;
`;

export const Logo = styled.img`
  display: block;
  max-width: 200px;
  max-height: 22px;
  width: auto;
  height: auto;
`;

export const PlaceholderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;

export const PlaceholderLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
  }

  span {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 19px;
    font-weight: 100;
    font-style: normal;
    color: #34495e;
  }
`;

export const PlaceholderMessage = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #34495e;
`;

export default {
  HeaderWrapper,
  ControlsWrapper,
  MeetingControlsWrapper,
  ModalControlsWrapper,
  LogoWrapper,
  MeetingTitleWrapper,
  InfoWrapper,
  VideoWrapper,
  IconButton,
  Title,
  Logo,
  PlaceholderWrapper,
  PlaceholderLogo,
  PlaceholderMessage,
};
