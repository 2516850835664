import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import ContactInfo from './ContactInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    avatar,
  } = selectors.realtorContacts.basicById(state, contactId);

  const avatarImageSrc = getAvatarUrl(avatar);

  return {
    avatarImageSrc,
  };
};

export default connect(mapStateToProps)(ContactInfo);
