import {
  SET_CUSTOMER_INFORMED,
} from 'store/actionTypes/customerInformed';

const set = (payload) => ({
  type: SET_CUSTOMER_INFORMED,
  payload,
});

export default set;
