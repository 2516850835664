import newApiConnector from 'api';
/**
 * Changes the account creator information in admin application
 * @param {string} fieldType Which field the user changed
 * @param {string} fieldValue New value of the changed field
 * @param {string} databaseName Name of the client database
 */
const changeAccountCreatorInformationInGlobal = async (fieldType, fieldValue, databaseName) => {
  const availableFields = ['Name', 'Surname', 'Email', 'WorkPhone', 'Role'];
  const globalEndpointURL = '/client/change-account-creator-information';

  if (availableFields.includes(fieldType)) {
    await newApiConnector.global.post(globalEndpointURL, {
      fieldType,
      fieldValue,
      databaseName,
    });
  }
};

export default changeAccountCreatorInformationInGlobal;
