import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  logout,
} from 'store/actions/auth2';

import {
  loginRequest,
} from 'store/actions/auth';

import NewClientLogin from './NewClientLogin';

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  logout,
  login: loginRequest,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NewClientLogin);
