import { connect } from 'react-redux';

import {
  optimizedPropertyActions,
} from 'store/actions/properties';

import selectors from 'store/selectors';

import MlsItem from './MlsItem';

const mapStateToProps = (state, ownProps) => {
  const {
    properties: {
      properties,
    },
  } = state;

  const {
    id,
  } = ownProps;

  const property = properties[id];

  if (!property) {
    optimizedPropertyActions.getPropertyById(id);

    return {
      address: null,
      expected_price: null,
    };
  }

  const {
    address_id,
    expected_price,
  } = property;

  const addressObject = address_id ? selectors.addresses.byId(state, address_id) : null;

  return {
    address: addressObject?.address,
    expected_price,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(MlsItem);
