import { connect } from 'react-redux';

import ObjectRemindersForm from './ObjectRemindersForm';

const mapStateToProps = (state, ownProps) => {
  const modalType = ownProps.onObjectSelect
    ? 'followUpRemindersNew'
    : 'followUpReminders';

  const {
    modals: {
      [modalType]: {
        contentOptions,
      },
    },
  } = state;

  return {
    reminderForm: {
      timeIntervalType: contentOptions?.timeIntervalType || 2,
      timeIntervalValue: contentOptions?.timeIntervalValue,
      isRepeat: contentOptions?.isRepeat,
      scheduledDate: contentOptions?.scheduledDate,
      title: contentOptions?.title,
      comment: contentOptions?.comment,
    },
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectRemindersForm);
