import requests from 'api/requests';

import setContacts from './setContacts';
import setContactsForFilter from './setContactsForFilter';
import setContactsForFilterCount from './setContactsForFilterCount';

const getContactsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.contacts.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setContacts(response.data.items));
    dispatch(setContactsForFilter(filterId)(response.data.items));
    dispatch(setContactsForFilterCount(filterId)(response.data.itemsCount));

    return response.data.items
  } catch (error) {
    console.error(error);
  }
};

export default getContactsForFilter;
