import apiConnector, { endpoints } from 'api';

const removeLineItem = (fulfillmentId) => async (lineItemId) => {
  const endpoint = `${endpoints.client.fulfillmentLineItem}/${fulfillmentId}/${lineItemId}`;

  try {
    const result = await apiConnector.client.delete(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default removeLineItem;
