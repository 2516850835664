import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { PAGES_TITLES } from 'constants/navigation.constants';

import PageTitle from './PageTitle';

const getContactName = (state, id) => {
  const contactName = selectors.realtorContacts.contactName(state, id);
  return contactName;
};

const getPropertyAddress = (state, id) => {
  const property = selectors.properties.byId(state, id);
  const address = selectors.addresses.byId(state, property.address_id);
  return address?.address;
};

const getContactObjectName = (state, id) => ({
  objectType: 'Contact',
  objectName: getContactName(state, id),
});

const getPropertyObjectName = (state, id) => ({
  objectType: 'Property',
  objectName: getPropertyAddress(state, id),
});

const getLeadObjectName = (state, id) => {
  const lead = selectors.realtorLeads.byId(state, id);
  return {
    objectType: 'Lead',
    objectName: getContactName(state, lead?.contact_id),
  };
};

const getClientObjectName = (state, id) => {
  const client = selectors.realtorClients.byId(state, id);
  return {
    objectType: 'Client',
    objectName: getContactName(state, client?.contact_id),
  };
};

const getProspectObjectName = (state, id) => {
  const prospect = selectors.realtorProspects.byId(state, id);
  return {
    objectType: 'Prospect',
    objectName: getContactName(state, prospect?.contact_id),
  };
};

const getTransactionObjectName = (state, id) => {
  let propertyId;
  const transaction = selectors.realtorTransactions.byId(state, id);
  if (transaction.realtor_property_id) {
    propertyId = transaction.realtor_property_id;
  } else if (transaction.realtor_client_id) {
    const client = selectors.realtorClients.byId(state, transaction.realtor_client_id);
    propertyId = client?.realtor_property_id;
  }

  return {
    objectType: 'Transaction',
    objectName: getPropertyAddress(state, propertyId),
  };
};

const sectionObjectFunctions = {
  mls: getPropertyObjectName,
  prospects: getProspectObjectName,
  'clients-buyers': getProspectObjectName,
  clients: getClientObjectName,
  'clients-sellers': getClientObjectName,
  leads: getLeadObjectName,
  transactions: getTransactionObjectName,
  contacts: getContactObjectName,
};

const getCurrentObject = (state, section, objectId) => {
  const objectFunc = sectionObjectFunctions[section];

  if (!objectFunc) {
    return null;
  }

  return objectFunc(state, objectId);
};

const mapStateToProps = (state) => {
  const {
    router: {
      location: {
        pathname,
      },
    },
  } = state;

  let currentSection;
  let currentObject;

  const pathnameArray = pathname.split('/');

  const sectionPathnamePart = pathnameArray[1];
  const idPathnamePart = pathnameArray[2];

  if (!sectionPathnamePart) {
    currentSection = 'Today';
  } else {
    currentSection = PAGES_TITLES[sectionPathnamePart];
  }

  if (idPathnamePart) {
    currentObject = getCurrentObject(state, sectionPathnamePart, idPathnamePart);
  }

  return {
    currentSection,
    currentObject,
    currentObjectId: idPathnamePart,
  };
};

export default connect(mapStateToProps)(PageTitle);
