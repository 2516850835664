import {
  SET_NEW_REALTOR_LEAD_FORM_NESTED_FIELD,
} from 'store/actionTypes/realtorLeads';

const setNewLeadNestesdFormField = (parentKey) => (key) => (value) => ({
  type: SET_NEW_REALTOR_LEAD_FORM_NESTED_FIELD,
  payload: {
    parentKey,
    key,
    value,
  },
});

export default setNewLeadNestesdFormField;
