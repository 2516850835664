import requests from 'api/requests';

import setInspectionAttachments from './setInspectionAttachments';

const getInspectionAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(34)(id);

    dispatch(setInspectionAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getInspectionAttachments;
