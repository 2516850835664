import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import BulletedList from 'ui/BulletedList';

const PropertyType = (props) => {
  const {
    value,
  } = props;

  if (!value) return null;

  if (Array.isArray(value) && value.length > 1) {
    return (
      <Tooltip title={<BulletedList content={value} />}>
        {`${value[0]} + ${value.length - 1}`}
      </Tooltip>
    );
  }

  if (Array.isArray(value) && value.length === 1) {
    return value[0] || null;
  }

  return value;
};

PropertyType.defaultProps = {
  value: 'Not set',
};

const {
  oneOfType,
  arrayOf,
  string,
} = PropTypes;

PropertyType.propTypes = {
  value: oneOfType([arrayOf(string), string]),
};

export default PropertyType;
