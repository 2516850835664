import React, {
  useCallback,
  useState,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';

import {
  Wrapper,
  StyledChip,
} from './styledItems';

const MultivalueTextInput = (props) => {
  const {
    items,
    onAdd,
    onDelete,
    label,
  } = props;

  const [value, setValue] = useState('');

  const prepareString = (input) => {
    let result = input;

    if (value.slice(-1) === ',') {
      result = value.slice(0, -1);
    }
    return result.trim();
  };

  const handleChange = useCallback((input) => {
    setValue(input);
  }, []);

  const handleKeyUp = useCallback((event) => {
    if (event.key === ',' || event.key === 'Enter' || event.key === ' ') {
      const preparedValue = prepareString(value);
      if (preparedValue) {
        onAdd(preparedValue);
      }
      setValue('');
    }
  }, [
    value,
  ]);

  return (
    <Wrapper>
      <Textfield
        value={value}
        label={label}
        onChange={handleChange}
        inputProps={{ onKeyUp: handleKeyUp }}
      />
      <Flex flexWrap="wrap">
        {
          items.map((item, index) => (
            <Fragment key={item}>
              <StyledChip
                onDelete={onDelete(index, item)}
                variant="subtle"
              >
                {item}
              </StyledChip>
            </Fragment>
          ))
        }
      </Flex>
    </Wrapper>
  );
};

MultivalueTextInput.defaultProps = {
  items: [],
  label: null,
  onDelete: () => () => {},
  onAdd: () => {},
};

const {
  arrayOf,
  func,
  string,
} = PropTypes;

MultivalueTextInput.propTypes = {
  items: arrayOf(string),
  label: string,
  onDelete: func,
  onAdd: func,
};

const decoratorProps = {
  iconName: 'null',
  iconType: 'feather',
  align: 'flex-start',
  iconMarginTop: '10px',
};

export default withFieldDecorator(decoratorProps)(MultivalueTextInput);
