import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import CheckboxLabelled from 'ui/CheckboxLabelled';
import Tag from 'ui/Tag';

const CheckboxTag = (props) => {
  const {
    value,
    onChange,
    tag,
  } = props;

  const labelComponent = useMemo(() => <Tag variant={tag}>{tag.toUpperCase()}</Tag>, [
    tag,
  ]);

  return (
    <CheckboxLabelled
      value={value}
      onChange={onChange}
      labelComponent={labelComponent}
    />
  );
};

CheckboxTag.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

CheckboxTag.propTypes = {

};

export default CheckboxTag;
