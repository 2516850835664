import { isPossiblePhoneNumber } from 'libphonenumber-js';
import emailValidator from 'email-validator';

export const phoneNumberValidation = (value = '', country = 'US') => isPossiblePhoneNumber(value || '', country);

export const emailValidation = (value) => emailValidator.validate(value);

const validate = {
  phone: phoneNumberValidation,
  email: emailValidation,
};

export default validate;
