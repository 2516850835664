import {
  OPEN_TUTORIAL_LAUNCH_MODAL,
  CLOSE_TUTORIAL_LAUNCH_MODAL,
  START_TUTORIAL,
  END_TUTORIAL,
  SHOW_TUTORIAL_LAYER,
  HIDE_TUTORIAL_LAYER,
  SET_STEP_INDEX,
  TOGGLE_APP_TOUR,
  SET_AVAILABLE_TUTORIALS,
  SET_APP_TOUR_STEP,
} from 'store/actionTypes/tutorial';

export const openTutorialLaunchModal = () => ({
  type: OPEN_TUTORIAL_LAUNCH_MODAL,
});
export const closeTutorialLaunchModal = () => ({
  type: CLOSE_TUTORIAL_LAUNCH_MODAL,
});
export const startTutorial = () => ({
  type: START_TUTORIAL,
});
export const endTutorial = () => ({
  type: END_TUTORIAL,
});
export const showTutorialLayer = () => ({
  type: SHOW_TUTORIAL_LAYER,
});
export const hideTutorialLayer = () => ({
  type: HIDE_TUTORIAL_LAYER,
});
export const setStepIndex = (payload) => ({
  type: SET_STEP_INDEX,
  payload,
});
export const toggleAppTour = () => ({
  type: TOGGLE_APP_TOUR,
});
export const setAvailableTutorials = (payload) => ({
  type: SET_AVAILABLE_TUTORIALS,
  payload,
});
export const setAppTourStep = (payload) => ({
  type: SET_APP_TOUR_STEP,
  payload,
});
