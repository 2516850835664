import requests from 'api/requests';

import setDealProducts from './setDealProducts';

const getProducts = (dealId) => async (dispatch) => {
  try {
    const response = await requests.deals.getProducts(dealId);

    dispatch(setDealProducts(dealId)(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getProducts;
