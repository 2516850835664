/* eslint-disable camelcase */

import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import KPIItem from 'ui/KPIItem';
import PropertySelect from './PropertySelect/index';

const KPIStyle = {
  padding: '24px 48px',
};

const Summary = (props) => {
  const {
    need_review,
    expiring_today,
    expiring_this_week,
    properties,
    selectProperty,
  } = props;

  return (
    <Flex
      fullWidth
      flexDirection="column"
      spacing={2}
    >
      <Flex>
        <Flex
          spacing={2}
          fullWidth
        >
          <KPIItem
            value={need_review}
            title="Needs Response"
            iconName="users"
            iconColor="black"
            variant="flat"
            customStyles={KPIStyle}
          />
          <KPIItem
            value={expiring_today}
            title="Expiring Today"
            iconName="investment"
            iconType="custom"
            iconColor="black"
            variant="flat"
            customStyles={KPIStyle}
          />
          <KPIItem
            value={expiring_this_week}
            title="Expiring this week"
            iconName="briefcase"
            iconColor="black"
            variant="flat"
            customStyles={KPIStyle}
          />
        </Flex>
      </Flex>

      <Flex
        flexWrap="wrap"
        spacing={1}
      >
        <PropertySelect
          properties={properties}
          selectProperty={selectProperty}
        />
      </Flex>
    </Flex>
  );
};

Summary.defaultProps = {
  need_review: 0,
  expiring_today: 0,
  expiring_this_week: 0,
  properties: [],
  selectProperty: () => {},
};

const {
  number,
  arrayOf,
  func,
} = PropTypes;

Summary.propTypes = {
  need_review: number,
  expiring_today: number,
  expiring_this_week: number,
  properties: arrayOf(number),
  selectProperty: func,

};

export default Summary;
