import React from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import Grid from 'ui/Grid';
import Textfield from 'ui/Textfield';

const AddressDetails = (props) => {
  const {
    officeAddress,
    homeAddress,
    updateProfileData,
  } = props;

  const onChange = (key) => useDebouncedCallback((value) => {
    updateProfileData(key)(value);
  }, 1500);

  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Textfield
          label="Office Address"
          value={officeAddress}
          onChange={onChange('Address1')}
        />
      </Grid>
      <Grid item xs={5}>
        <Textfield
          label="Home Address (optional)"
          value={homeAddress}
          onChange={onChange('Address2')}
        />
      </Grid>
    </Grid>
  );
};

const {
  string,
  func,
} = PropTypes;

AddressDetails.propTypes = {
  officeAddress: string.isRequired,
  homeAddress: string.isRequired,
  updateProfileData: func.isRequired,
};

export default AddressDetails;
