import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  text-transform: uppercase;
  padding: 8px 15px 5px;
  border-radius: 3px;
  background-color: #2ecc71;
`;
