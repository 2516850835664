import { connect } from 'react-redux';

import NewLeadForm from './NewLeadForm';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      types,
      selectedContact,
    },
  } = state;

  return {
    selectedContact,
    selectedTypes: types,
  };
};

export default connect(mapStateToProps, null)(NewLeadForm);
