import requests from 'api/requests';

import getProductById from './getProductById';

// eslint-disable-next-line consistent-return
const createProduct = ({ name }) => async (dispatch) => {
  try {
    const response = await requests.crm.products.create({ name });

    dispatch(getProductById(response.data.productId));

    return response.data.productId;
  } catch (error) {
    console.error(error);
  }
};

export default createProduct;
