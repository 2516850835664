import { createSelector } from 'reselect';

// import { // eslint-disable-line import/no-cycle
//   optimizedProspectActions,
// } from 'store/actions/realtorProspects';

import selectors from 'store/selectors';

export default (idList = []) => createSelector(
  (state) => (id) => selectors.realtorMatchingProperties.byId(id)(state),
  (state) => (id) => selectors.properties.byId(state, id),
  (getMatchingProperty, getProperty) => idList.map((id) => {
      const item = getMatchingProperty(id);

      const property = getProperty(item?.realtor_property_id) || {};

      return {
        property_id: item?.realtor_property_id,
        rejection_reason: item?.rejection_reason || 3,
        ...item,
        ...property,
      };
    }),
);
