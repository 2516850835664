/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import {
  includes,
  isObject,
} from 'shared/utility';

import InfiniteScroll from 'react-infinite-scroller';

import { Spin } from 'antd';
import CenterBox from 'shared/components/UI/CenterBox';

import Typography from 'ui/Typography';

import LeadCard from 'shared/components/UI/RealtorObjectCards/LeadCard';
import ClientCard from 'shared/components/UI/RealtorObjectCards/ClientCard';
import TransactionCard from 'shared/components/UI/RealtorObjectCards/TransactionCard';
import ProspectCard from 'shared/components/UI/RealtorObjectCards/ProspectCard';

import {
  Root,
  ItemsContainer,
} from './styledItems';

const objectComponents = {
  leads: ({ id, type, leadId }) => <LeadCard leadId={leadId} objectId={id} type={type} />,
  prospectClients: (key) => (
      includes(key, 'client') ? (
        <ClientCard variant="viewed" clientId={key.substring(7)} />
        ) : (
          <ProspectCard variant="viewed" prospectId={key.substring(9)} />
        )
    ),
  offers: (key) => (
    includes(key, 'client') ? (
      <ClientCard variant="offers" clientId={key.substring(7)} />
      ) : (
        <ProspectCard variant="offers" prospectId={key.substring(9)} />
      )
  ),
  transactions: (id) => <TransactionCard transactionId={id} />,
};

const ToDoTabColumn = (props) => {
  const {
    title,
    description,
    items,
    objectKey,
    loadMore,
    itemsCount,
  } = props;

  return (
    <Root>
      <Typography
        variant="title1"
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        color="descriptor-text"
      >
        {description}
      </Typography>

      <ItemsContainer>
        <InfiniteScroll
          initialLoad
          pageStart={0}
          loadMore={loadMore(items?.length, 20)} // eslint-disable-line react/jsx-no-bind
          hasMore={itemsCount > items?.length}
          loader={<CenterBox><Spin /></CenterBox>}
          useWindow={false}
        >
          {
              items?.map(
                (objectItem) => {
                  if (!isObject(objectItem)) {
                    return objectComponents[objectKey](objectItem);
                  }
                  return objectComponents[objectKey]({ ...objectItem });
                },
              )
          }
        </InfiniteScroll>
      </ItemsContainer>
    </Root>
  );
};

const {
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

ToDoTabColumn.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  items: oneOfType([
    arrayOf(string),
    arrayOf(shape({
      id: number,
      type: string,
      leadId: number,
    })),
    arrayOf(number),
  ]).isRequired,
  objectKey: string.isRequired,
  loadMore: func.isRequired,
  itemsCount: number.isRequired,
};

export default ToDoTabColumn;
