import moment from 'moment';

const today = moment();
const tomorrow = moment().add(1, 'days');
const nextWeek = moment().add(1, 'weeks');

const isToday = (date) => moment(date).isSame(today, 'day');
const isTomorrow = (date) => moment(date).isSame(tomorrow, 'day');
const isThisWeek = (date) => moment(date).isSame(today, 'week');
const isNextWeek = (date) => moment(date).isSame(nextWeek, 'week');

export const getItemDateSection = (date) => {
  if (isToday(date)) return 'Today';
  if (isTomorrow(date)) return 'Tomorrow';
  if (isThisWeek(date)) return 'This Week';
  if (isNextWeek(date)) return 'Next Week';
  return 'Later';
};

export const isSameSection = (date, section) => {
  if (getItemDateSection(date) === section) {
    return true;
  }
  return false;
};
