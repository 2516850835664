/* eslint-disable import/no-cycle */
import byId from './byId';
import selected from './selected';
import clientsFilter from './clientsFilter';
import clientsList from './clientsList';
import clientNotablePoints from './clientNotablePoints';
import clientDisclosures from './clientDisclosures';
import overallSummary from './overallSummary';
import kanban from './kanban';

export default {
  byId,
  selected,
  clientsFilter,
  clientsList,
  clientNotablePoints,
  clientDisclosures,
  overallSummary,
  kanban,
};
