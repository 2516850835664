import {
  SET_NEW_REALTOR_PROSPECT_MODAL_OPEN,
} from 'store/actionTypes/realtorProspects';

const openNewProspectModal = (propertyRequirementId = null, leadId = null) => ({
  type: SET_NEW_REALTOR_PROSPECT_MODAL_OPEN,
  payload: { propertyRequirementId, leadId },
});

export default openNewProspectModal;
