import {
  SET_REALTOR_SELECTED_LEAD_ROWS,
} from 'store/actionTypes/realtorLeads';

const setSelectedLeadRows = (ids) => ({
  type: SET_REALTOR_SELECTED_LEAD_ROWS,
  payload: ids,
});

export default setSelectedLeadRows;
