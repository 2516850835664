import apiConnector from 'api';

const changeTicketAssignees = (queueId) => (ticketId) => async (assigneeChanges) => {
  const endpoint = `/api/tickets/assignEmployee/${queueId}/${ticketId}`;

  try {
    const result = await apiConnector.client.put(endpoint, assigneeChanges);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default changeTicketAssignees;
