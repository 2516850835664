import {
  SET_TASKS_SUMMARY_FOR_EMPLOYEE,
} from 'store/actionTypes/tasks';

const setTasksSummaryForEmployee = (employeeId) => (tasksSummary) => ({
  type: SET_TASKS_SUMMARY_FOR_EMPLOYEE,
  payload: {
    employeeId,
    tasksSummary,
  },
});

export default setTasksSummaryForEmployee;
