import { useSelector } from 'react-redux';

import selectors from 'store/selectors';

const usePropertyData = (propertyId) => {
  const state = useSelector((currentState) => currentState);

  const property = selectors.properties.byId(state, propertyId) || {};
  const address = selectors.addresses.byId(state, property?.address_id);

  return {
    ...property,
    address,
  };
};

export default usePropertyData;
