import styled from 'styled-components';
import Spin from 'ui/Spin';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  margin-top: 20px;
`;

export const StyledSpin = styled(Spin)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
