import {
  SET_TICKETS_SUMMARY_FOR_EMPLOYEE,
} from 'store/actionTypes/tickets';

const setTicketsSummaryForEmployee = (employeeId) => (ticketsSummary) => ({
  type: SET_TICKETS_SUMMARY_FOR_EMPLOYEE,
  payload: {
    employeeId,
    ticketsSummary,
  },
});

export default setTicketsSummaryForEmployee;
