import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Button from 'ui/Button';

import { Wrapper, Root } from './styledItems';

const viewToFormat = {
  date: undefined,
  month: undefined,
  year: 'YYYY',
};

const DateInputButton = (props) => {
  const {
    onChange,
    value,
    view,
    onReset,
    disabled,
    displayFormat,
  } = props;

  const [dateValue, setDateValue] = useState(value ? moment(value, displayFormat || viewToFormat[view]) : null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setDateValue(value ? moment(value, viewToFormat[view]) : null);
  }, [
    value,
  ]);

  const handleChange = useCallback((dateTime) => {
    const formattedValue = moment(dateTime).format(viewToFormat[view]);
    setDateValue(moment(dateTime, viewToFormat[view]));
    onChange(formattedValue || null, dateTime);
  }, [
    onChange,
    view,
  ]);

  const handleOnFocus = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  /* eslint-disable react/prop-types */
  const renderInput = useCallback((inputProps) => {
    const format = view === 'year' ? 'YYYY' : 'MM/DD/YYYY';
    const formattedValue = dateValue ? moment(dateValue).format(displayFormat || format) : null;

    return (
      <Button
        onClick={inputProps.onClick}
        onFocus={inputProps.onFocus}
        icon="calendar"
        variant="secondary"
        color="focus"
        disabled={disabled}
      >
        {formattedValue}
      </Button>
    );
  }, [
    dateValue,
    view,
    disabled,
  ]);
  /* eslint-enable */

  return (
    <Root>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Wrapper>
          <DatePicker
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            variant="inline"
            views={view === 'year' || view === 'month' ? [view] : undefined}
            value={dateValue}
            onChange={handleChange}
            margin="dense"
            showTodayButton
            TextFieldComponent={renderInput}
            PopoverProps={{
              anchorEl,
            }}
            onFocus={handleOnFocus}
            autoOk
          />
        </Wrapper>
      </MuiPickersUtilsProvider>
      {
        onReset && (
          <Button
            icon="x"
            onClick={onReset}
            variant="tertiary"
            small
          />
        )
      }
    </Root>
  );
};

DateInputButton.defaultProps = {
  value: null,
  view: 'date',
  onReset: null,
  disabled: false,
  displayFormat: null,
};

const {
  func,
  string,
  number,
  oneOf,
  oneOfType,
  bool,
} = PropTypes;

DateInputButton.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, number]),
  view: oneOf(['year', 'month', 'date']),
  onReset: func,
  disabled: bool,
  displayFormat: string,
};

export default DateInputButton;
