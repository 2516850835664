/* eslint-disable react/require-default-props, max-lines */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { meetingType, meetingParticipantType, thumbnailType } from 'oa/types/index';
import MeetingNameList from 'oa/components/MeetingNameList/index';
import MeetingUserList from 'oa/components/MeetingUserList/index';
import MeetingDetailsModal from 'oa/components/MeetingDetailsModal';
import MeetingAgendaModal from 'oa/components/MeetingAgendaModal';
import MoreActions from 'oa/components/RoomHeader/components/MoreActions';
import videoPlaceholder from '../img/video-placeholder.svg';

import {
  InfoWrapper,
  ControlsWrapper,
  MeetingControlsWrapper,
  ModalControlsWrapper,
  LogoWrapper,
  MeetingTitleWrapper,
  VideoWrapper,
  IconButton,
  Title,
  Logo,
  HeaderWrapper,
  PlaceholderWrapper,
  PlaceholderLogo,
  PlaceholderMessage,
  MainVideo,
  StyledModal,
} from './styledItems';

const iconColor = 'rgba(35, 48, 63, 0.87)';
const iconColorInactive = '#ff6446';

const {
  bool,
  func,
  string,
  arrayOf,
  instanceOf,
} = PropTypes;

const Placeholder = (props) => {
  const {
    message,
  } = props;

  return (
    <PlaceholderWrapper>
      <PlaceholderLogo>
        <img src="/assets/img/symplete-dark.png" alt="symplete logo" />
        <span>Communications</span>
      </PlaceholderLogo>
      <PlaceholderMessage>
        {message}
      </PlaceholderMessage>
    </PlaceholderWrapper>
  );
};

Placeholder.defaultProps = {
  message: 'Waiting for others to join...',
};

Placeholder.propTypes = {
  message: string,
};

const MeetingInfo = (props) => {
  const {
    title,
    logoSrc,
  } = props;

  return (
    <InfoWrapper>
      {
        logoSrc && (
          <LogoWrapper>
            <Logo src={logoSrc} />
          </LogoWrapper>
        )
      }
      <MeetingTitleWrapper>
        <Title>
          {title}
        </Title>
      </MeetingTitleWrapper>
    </InfoWrapper>
  );
};

MeetingInfo.defaultProps = {
  title: null,
  logoSrc: null,
};

MeetingInfo.propTypes = {
  title: string,
  logoSrc: string,
};

const LargeModalControls = (props) => {
  const {
    onExit,
    onMic,
    onCamera,
    onShrink,
    isMicActive,
    isCameraActive,
    isCameraAvailable,
    isMicrophoneAvailable,
    pinnedParticipant,
    handleUnpinParticipant,
    isScreenPresenting,
    meeting,
    presentScreen,
    openMeetingDetails,
    openMeetingAgenda,
    meetingParticipants,
  } = props;

  const micColor = isMicActive
    ? iconColor
    : iconColorInactive;

  const cameraColor = isCameraActive
    ? iconColor
    : iconColorInactive;

  return (
    <ControlsWrapper>
      <MeetingControlsWrapper>
        <IconButton
          iconName="sign-out-alt"
          color={iconColor}
          onClick={onExit}
        />

        {
          pinnedParticipant && (
            <IconButton
              iconName="thumbtack"
              color={iconColor}
              onClick={handleUnpinParticipant}
            />
          )
        }

        {
          isMicrophoneAvailable && (
            <IconButton
              iconName="microphone-alt"
              color={micColor}
              onClick={onMic}
            />
          )
        }

        {
          isCameraAvailable && !isScreenPresenting && (
            <IconButton
              iconName="video"
              color={cameraColor}
              onClick={onCamera}
            />
          )
        }
      </MeetingControlsWrapper>
      <ModalControlsWrapper>
        {/* TODO: update more actions dropdown and uncomment this component */}

        {/* <IconButton
          iconName="info-circle"
          color={iconColor}
          onClick={onInfo}
        /> */}

        <MoreActions
          meeting={meeting}
          presentScreen={presentScreen}
          openMeetingDetails={openMeetingDetails}
          openMeetingAgenda={openMeetingAgenda}
          isScreenPresenting={isScreenPresenting}
          meetingParticipants={meetingParticipants}
        />

        <IconButton
          iconName="compress-alt"
          color={iconColor}
          onClick={onShrink}
        />
      </ModalControlsWrapper>
    </ControlsWrapper>
  );
};

LargeModalControls.defaultProps = {
  isMicActive: false,
  isCameraActive: false,
};

LargeModalControls.propTypes = {
  onExit: func.isRequired,
  onMic: func.isRequired,
  onCamera: func.isRequired,
  onShrink: func.isRequired,
  isMicActive: bool,
  isCameraActive: bool,
  pinnedParticipant: meetingParticipantType,
  handleUnpinParticipant: func.isRequired,
  isScreenPresenting: bool.isRequired,
  meeting: meetingType,
  presentScreen: func.isRequired,
  openMeetingDetails: func.isRequired,
  openMeetingAgenda: func.isRequired,
  meetingParticipants: arrayOf(meetingParticipantType),
  isCameraAvailable: bool.isRequired,
  isMicrophoneAvailable: bool.isRequired,
};

const ModalHeader = (props) => {
  const {
    meetingTitle,
    logoSrc,
    onExit,
    onMic,
    onCamera,
    // onInfo,
    onShrink,
    isMicActive,
    isCameraActive,
    isCameraAvailable,
    isMicrophoneAvailable,
    pinnedParticipant,
    handleUnpinParticipant,
    isScreenPresenting,
    meeting,
    presentScreen,
    openMeetingDetails,
    openMeetingAgenda,
    meetingParticipants,
  } = props;

  return (
    <HeaderWrapper>
      <MeetingInfo
        title={meetingTitle}
        logoSrc={logoSrc}
      />
      <LargeModalControls
        onExit={onExit}
        onMic={onMic}
        onCamera={onCamera}
        // onInfo={onInfo}
        onShrink={onShrink}
        isMicActive={isMicActive}
        isCameraActive={isCameraActive}
        isCameraAvailable={isCameraAvailable}
        isMicrophoneAvailable={isMicrophoneAvailable}
        pinnedParticipant={pinnedParticipant}
        handleUnpinParticipant={handleUnpinParticipant}
        isScreenPresenting={isScreenPresenting}
        meeting={meeting}
        presentScreen={presentScreen}
        openMeetingDetails={openMeetingDetails}
        openMeetingAgenda={openMeetingAgenda}
        meetingParticipants={meetingParticipants}
      />
    </HeaderWrapper>
  );
};

ModalHeader.defaultProps = {
  meetingTitle: null,
  logoSrc: null,
  isMicActive: false,
  isCameraActive: false,
};

ModalHeader.propTypes = {
  meetingTitle: string,
  logoSrc: string,
  onExit: func.isRequired,
  onMic: func.isRequired,
  onCamera: func.isRequired,
  // onInfo: func.isRequired,
  onShrink: func.isRequired,
  isMicActive: bool,
  isCameraActive: bool,
  pinnedParticipant: meetingParticipantType,
  handleUnpinParticipant: func.isRequired,
  isScreenPresenting: bool.isRequired,
  meeting: meetingType,
  presentScreen: func.isRequired,
  openMeetingDetails: func.isRequired,
  openMeetingAgenda: func.isRequired,
  meetingParticipants: arrayOf(meetingParticipantType),
  isCameraAvailable: bool.isRequired,
  isMicrophoneAvailable: bool.isRequired,
};

const LargeModal = (props) => {
  const {
    isVisible,
    meetingTitle,
    logoSrc,
    onExit,
    onMic,
    onCamera,
    // onInfo,
    onShrink,
    isMicActive,
    isCameraActive,
    isCameraAvailable,
    isMicrophoneAvailable,
    isUserAloneInMeeting,
    mainStream,
    meetingParticipants,
    remoteParticipants,
    thumbnails,
    pinnedParticipant,
    unpinParticipant,
    pinParticipant,
    isScreenPresenting,
    meeting,
    changeMeetingNotes,
    addDesiredOutcome,
    toggleDesiredOutcome,
    isMeetingAgendaOpen,
    isMeetingDetailsOpen,
    closeMeetingDetails,
    closeMeetingAgenda,
    presentScreen,
    openMeetingDetails,
    openMeetingAgenda,
  } = props;

  const handleUnpinParticipant = useCallback(() => {
    if (pinnedParticipant) {
      unpinParticipant(pinnedParticipant.socketId);
    }
  }, [unpinParticipant, pinnedParticipant]);

  return (
    <StyledModal
      onCancel={onShrink}
      visible={isVisible}
      title={(
        <ModalHeader
          meetingTitle={meetingTitle}
          logoSrc={logoSrc}
          onExit={onExit}
          onMic={onMic}
          onCamera={onCamera}
          // onInfo={onInfo}
          onShrink={onShrink}
          isMicActive={isMicActive}
          isCameraActive={isCameraActive}
          isCameraAvailable={isCameraAvailable}
          isMicrophoneAvailable={isMicrophoneAvailable}
          pinnedParticipant={pinnedParticipant}
          handleUnpinParticipant={handleUnpinParticipant}
          isScreenPresenting={isScreenPresenting}
          meeting={meeting}
          presentScreen={presentScreen}
          openMeetingDetails={openMeetingDetails}
          openMeetingAgenda={openMeetingAgenda}
          meetingParticipants={meetingParticipants}
        />
      )}
      footer={null}
      width={1000}
    >
      <VideoWrapper>
        {
          isUserAloneInMeeting
            ? (
              <Placeholder />
            ) : (
              <>
                <MainVideo
                  srcObject={mainStream}
                  poster={videoPlaceholder}
                  autoPlay
                  playsInline
                  muted
                />

                {
                  meetingParticipants.length > 0 && (
                    <MeetingNameList
                      meetingParticipants={meetingParticipants}
                      className="meeting-name-list_located_top"
                    />
                  )
                }

                {
                  remoteParticipants.length > 0 && (
                    <MeetingUserList
                      remoteParticipants={remoteParticipants}
                      thumbnails={thumbnails}
                      pinnedParticipant={pinnedParticipant}
                      unpinParticipant={unpinParticipant}
                      pinParticipant={pinParticipant}
                    />
                  )
                }

                <MeetingDetailsModal
                  isOpen={isMeetingDetailsOpen}
                  onClose={closeMeetingDetails}
                  meeting={meeting}
                  toggleDesiredOutcome={toggleDesiredOutcome}
                />

                <MeetingAgendaModal
                  isOpen={isMeetingAgendaOpen}
                  onClose={closeMeetingAgenda}
                  meeting={meeting}
                  addDesiredOutcome={addDesiredOutcome}
                  toggleDesiredOutcome={toggleDesiredOutcome}
                  changeMeetingNotes={changeMeetingNotes}
                />
              </>
            )
        }
      </VideoWrapper>
    </StyledModal>
  );
};

LargeModal.defaultProps = {
  isVisible: false,
  meetingTitle: null,
  logoSrc: null,
  isMicActive: false,
  isCameraActive: false,
};

LargeModal.propTypes = {
  isVisible: bool,
  meetingTitle: string,
  logoSrc: string,
  onExit: func.isRequired,
  onMic: func.isRequired,
  onCamera: func.isRequired,
  // onInfo: func.isRequired,
  onShrink: func.isRequired,
  isMicActive: bool,
  isCameraActive: bool,
  isUserAloneInMeeting: bool.isRequired,
  pinnedParticipant: meetingParticipantType,
  isScreenPresenting: bool.isRequired,
  meeting: meetingType,
  presentScreen: func.isRequired,
  openMeetingDetails: func.isRequired,
  openMeetingAgenda: func.isRequired,
  meetingParticipants: arrayOf(meetingParticipantType).isRequired,
  changeMeetingNotes: func.isRequired,
  addDesiredOutcome: func.isRequired,
  toggleDesiredOutcome: func.isRequired,
  isMeetingAgendaOpen: bool.isRequired,
  isMeetingDetailsOpen: bool.isRequired,
  closeMeetingDetails: func.isRequired,
  closeMeetingAgenda: func.isRequired,
  mainStream: instanceOf(MediaStream),
  remoteParticipants: arrayOf(meetingParticipantType).isRequired,
  thumbnails: arrayOf(thumbnailType),
  pinParticipant: func.isRequired,
  unpinParticipant: func.isRequired,
  isCameraAvailable: bool.isRequired,
  isMicrophoneAvailable: bool.isRequired,
};

export default LargeModal;
