import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  includes,
  remove,
} from 'shared/utility';

import MlsItem from './MlsItem';

const MlsList = (props) => {
  const {
    onChangeSelected,
    listData,
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);

  const itemAction = useCallback((id) => (actionType) => (event) => {
    event.preventDefault();

    if (actionType === 'add') {
      setSelectedItems((oldItems) => {
        onChangeSelected([...oldItems, id]);
        return [...oldItems, id];
      });
    } else {
      setSelectedItems((oldItems) => {
        const newSelectedItems = [...oldItems];

        remove(newSelectedItems, (itemId) => itemId === id);

        onChangeSelected(newSelectedItems);
        return newSelectedItems;
      });
    }
  }, []);

  return (
    <>
      <Typography
        variant="body"
        color="ghost"
        weight="bold"
      >
        {listData.length}
        {' '}
        SEARCH RESULT
      </Typography>
      {listData.map((id) => (
        <MlsItem
          isSelected={!includes(selectedItems, id)}
          onAction={itemAction}
          id={id}
        />
      ))}
    </>
  );
};

const {
  arrayOf,
  number,
} = PropTypes;

MlsList.propTypes = {
  onChangeSelected: number.isRequired,
  listData: arrayOf(number).isRequired,
};

export default MlsList;
