import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SearchItemTransaction from './SearchItemTransaction';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const {
    buyer_contact_id,
    seller_contact_id,
  } = selectors.realtorTransactions.byId(state, transactionId);

  const buyer = buyer_contact_id ? selectors.realtorContacts.byId(state, buyer_contact_id) : null;
  const seller = seller_contact_id ? selectors.realtorContacts.byId(state, seller_contact_id) : null;

  return {
    seller,
    buyer,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemTransaction);
