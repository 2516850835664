import React from 'react';
import PropTypes from 'prop-types';

import {
  Image,
} from './styledItems';

const PropertyImage = (props) => {
  const {
    image,
  } = props;

  return (
    <Image
      shape="square"
      icon="picture"
      src={image?.url}
    />
  );
};

PropertyImage.defaultProps = {
  image: null,
};

const {
  shape,
  string,
} = PropTypes;

PropertyImage.propTypes = {
  image: shape({
    id: string,
    url: string,
  }),
};

export default PropertyImage;
