import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import MultiselectTextfield from 'ui/MultiselectTextfield';

const ContactTypeInput = (props) => {
  const {
    onChange,
    value,
    options,
  } = props;

  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(value)) {
      const selectedOptions = options.reduce((accum, item) => {
        if (value.includes(item.value)) {
          accum.push(item);
        }

        return accum;
      }, []);

      setSelectedValue(selectedOptions);
    }
  }, [
    JSON.stringify(value),
    JSON.stringify(options),
  ]);

  const handleChange = useCallback((newValue) => {
    onChange(newValue.map((item) => item.value));
  }, [
    onChange,
  ]);

  return (
    <MultiselectTextfield
      options={options}
      onChange={handleChange}
      label="Contact labels"
      value={selectedValue}
    />
  );
};

ContactTypeInput.defaultProps = {
  value: [],
};

const {
  func,
  arrayOf,
  number,
  shape,
  string,
} = PropTypes;

ContactTypeInput.propTypes = {
  onChange: func.isRequired,
  value: arrayOf(number),
  options: arrayOf(shape({
    value: number,
    label: string,
  })).isRequired,
};

export default ContactTypeInput;
