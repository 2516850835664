import { connect } from 'react-redux';
import selectors from 'store/selectors';

import PersonTitleSelect from './PersonTitleSelect';

const mapStateToProps = (state) => {
  const titles = selectors.dictionary.personTitles(state);

  const options = titles.reduce((accum, item) => {
    if (item.isActive) {
      accum.push({
        value: item.id,
        label: item.name,
      });
    }

    return accum;
  }, []);

  return {
    options,
  };
};

export default connect(mapStateToProps)(PersonTitleSelect);
