import FormReducer from 'store/utils/reducers/FormReducer';

import {
  SET_EXTENSION_REQUEST_FORM_STATE,
  RESET_EXTENSION_REQUEST_FORM_STATE,
  SET_EXTENSION_REQUEST_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorTransactions';

const formReducer = new FormReducer(
  SET_EXTENSION_REQUEST_FORM_STATE,
  RESET_EXTENSION_REQUEST_FORM_STATE,
  SET_EXTENSION_REQUEST_FORM_ATTACHMENTS,
  {
    contactId: null,
    details: null,
    dateTime: null,
    title: null,
  },
);

export default formReducer.getReducers;
