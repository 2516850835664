/* eslint-disable react/jsx-props-no-spreading, react/destructuring-assignment, react/prop-types */

import React from 'react';

import mergeThemes from 'shared/themes/utils/mergeThemes';

const withTheme = (...themes) => (Component) => (props) => {
  const mergedThemes = props.theme
    ? mergeThemes(props.theme, ...themes)
    : mergeThemes(...themes);

  return (
    <Component
      {...props}
      theme={mergedThemes}
    />
  );
};

export default withTheme;
