import requests from 'api/requests';

import setLike from './setLike';

const getLikeById = (id) => async (dispatch) => {
  try {
    const response = await requests.likes.getById(id);
    dispatch(setLike(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getLikeById;
