import {
  SET_CALL_LOGS,
} from 'store/actionTypes/calls';

const setCallLogs = (logs) => ({
  type: SET_CALL_LOGS,
  payload: logs,
});

export default setCallLogs;
