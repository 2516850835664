import { connect } from 'react-redux';

import {
  linkRelatedArticle,
  handleArticleSearch,
  setRelatedArticle,
  showLinkToUrl,
} from 'store/actions/articleDetail';
import {
  searchArticles,
} from 'store/actions/knowledgeBase';

import LinkToURL from './LinkToURL';

const mapStateToProps = (state) => ({
  kbHelperData: state.knowledgeBase.kbHelperData,
  categoriesInfo: state.knowledgeBase.categoriesInfo,
  searchSuggestions: state.knowledgeBase.searchSuggestions,
  linkToUrlModal: state.articleDetail.linkToUrlModal,
  purposeArticles: state.articleDetail.purposeArticles,
  articleModalMode: state.articleDetail.articleModalMode,
  activeArticleId: state.articleDetail.activeArticleId,
});

const mapDispatchToProps = (dispatch) => ({
  triggerModal: () => dispatch(showLinkToUrl()),
  handleArticleSearch: (text, productID, articleID) => dispatch(handleArticleSearch(text, productID, articleID)),
  setRelatedArticle: (articleID) => dispatch(setRelatedArticle(articleID)),
  linkRelatedArticle: (category) => dispatch(linkRelatedArticle(category)),
  searchArticles: (searchWord, filter) => dispatch(searchArticles(searchWord, filter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkToURL);
