import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getProducts from './getProducts';
import getProductById from './getProductById';
import getProductsForFilter from './getProductsForFilter';

import createProduct from './createProduct';

export { getProductsForFilter };
export { getProducts };
export { getProductById };

export { createProduct };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCrmProductsActions = {
  getProductsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getProductsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getProductById: memoizeDebounce(
    (productId) => store.dispatch(getProductById(productId)),
    debounceDelay,
    debounceOptions,
  ),
};
