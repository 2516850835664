import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedCrmSourcesActions,
  createSourceType,
} from 'store/actions/crm/crmSources';

import { createSourceFromNewLeadForm } from 'store/actions/realtorLeads';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import SourceSelect from './SourceSelect';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      newCustomSourceName,
      sourceType,
    },
  } = state;

  const sourceTypes = selectors.crm.sourceTypes(state);

  if (!sourceTypes) {
    optimizedCrmSourcesActions.getSourceTypes();
  }

  const sourceTypeObj = sourceTypes && sourceType
    ? sourceTypes.find((item) => item.id === sourceType)
    : null;

  const value = sourceTypeObj
    ? sourceTypeObj.name
    : '';

  return {
    options: sourceTypes,
    newCustomSourceName,
    value,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSelect: (sourceTypeId) => {
    dispatch(setNewLeadFormField('sourceType')(sourceTypeId));
  },

  addSourceType: async (name) => {
    const newSourceTypeId = await dispatch(createSourceType({ name }));
    dispatch(setNewLeadFormField('sourceType')(newSourceTypeId));

    const sourceId = await dispatch(createSourceFromNewLeadForm());

    dispatch(setNewLeadFormField('source')(sourceId));
  },

  onAddNewSourceTypeClick: (newSourceTypeName) => {
    dispatch(setNewLeadFormField('newCustomSourceName')(newSourceTypeName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceSelect);
