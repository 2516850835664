import styled from 'styled-components';

export const BaseWrapper = styled.div`
  * {
    cursor: pointer !important;
  }
`;

export default {
  BaseWrapper,
};
