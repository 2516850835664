import { createSelector } from 'reselect';

import { optimizedFollowUpRemindersActions } from 'store/actions/followUpReminders';

import { OBJECT_TYPES } from 'constants';

import leadById from '../realtorLeads/byId'; // eslint-disable-line import/no-cycle
import clientById from '../realtorClients/byId'; // eslint-disable-line import/no-cycle
import prospectById from '../realtorProspects/byId'; // eslint-disable-line import/no-cycle

const eventTypeslabels = {
  'followUp': 'Follow Up',
};

const objectTypes2selectors = {
  'realtor lead': leadById,
  'realtor client': clientById,
  'realtor prospect': prospectById,
};

const objectTypes2labels = {
  'realtor lead': 'Lead',
  'realtor client': 'Client',
  'realtor prospect': 'Prospect',
  'realtor contact': 'Contact',
};

export default createSelector(
  (state) => state,
  (state) => state.followUpReminders.reminders,
  (state) => state.followUpReminders.remindersByFilter,
  (_, filterId) => filterId,
  (state, reminders, remindersById, objectFilterId) => {
    const filteredIds = remindersById[objectFilterId];
    if (!filteredIds) {
      return [];
    }
    const result = filteredIds.reduce((accum, id) => {
      const reminder = reminders[id];

      if (!reminder && id) {
        optimizedFollowUpRemindersActions.getFollowUpReminderById(id);
        return accum;
      }

      if (reminder.valid_to) {
        return accum;
      }

      let contactId;

      const relatedObjectType = OBJECT_TYPES[reminder.object_type];

      if (relatedObjectType === 'realtor contact') {
        contactId = reminder.object_id;
      } else if (objectTypes2selectors[relatedObjectType]) {
        const relatedObject = objectTypes2selectors[relatedObjectType](state, reminder.object_id);

        contactId = relatedObject?.contact_id || relatedObject?.realtor_contact_id;
      }

      const statusLabel = {
        title: reminder.status === 6 ? 'Completed' : 'Active',
        variant: reminder.status === 6 ? 'success' : 'new',
      };
      accum.push({
        labels: [
          { title: reminder.event_type? eventTypeslabels[reminder.event_type]: 'Synced Event', variant: reminder.event_type ? reminder.event_type : 'synced' },
          { title: objectTypes2labels[relatedObjectType], variant: 'focus' },
          statusLabel,
        ],
        description: reminder.title,
        date: reminder.scheduled_date,
        contactId,
        objectType: 'followUpReminders',
        eventType: reminder.event_type,
        objectId: reminder.id,
        id: reminder.event_uid,
        location: reminder.meeting_url
      });
      return accum;
    }, []);

    return result;
  },
);
