import apiConnector from 'api';

const updateTaskStatus = async (body) => {
  try {
    const result = await apiConnector.client.post('/Tasks/ChangeTaskStatus', body);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default updateTaskStatus;
