import React, {
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  StyledButton,
} from './styledItems';

const noop = () => {};

const DialpadButton = (props) => {
  const {
    onClick,
    onLongClick,
    onMouseDown,
    onMouseUp,
    value,
    extraValue,
    buttonDown,
    color,
    backgroundColor,
    iconName,
    iconType,
    justifyContent,
    disabled,
    round,
  } = props;

  const timerIdRef = useRef(null);
  const timerClearedRef = useRef(false);

  const handleMouseDown = useCallback(() => {
    onMouseDown();

    timerClearedRef.current = false;
    timerIdRef.current = setTimeout(() => {
      onLongClick(extraValue);
      timerClearedRef.current = true;
    }, 700);
  }, [
    onMouseDown,
    onLongClick,
    extraValue,
  ]);

  const handleMouseUp = useCallback(() => {
    onMouseUp();

    if (!timerClearedRef.current) {
      clearTimeout(timerIdRef.current);
      timerClearedRef.current = true;
      onClick(value);
    }
  }, [
    value,
    onMouseUp,
    onClick,
    timerIdRef.current,
    timerClearedRef.current,
  ]);

  return (
    <StyledButton
      onClick={noop}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      backgroundColor={backgroundColor}
      isDown={buttonDown}
      disabled={disabled}
      round={round}
    >
      <Flex
        fullWidth
        flexDirection="column"
        justifyContent={justifyContent}
        alignItems="center"
        spacing={0}
        style={{ height: '100%' }}
      >
        {
          value && (
            <FlexItem>
              <Typography
                variant="title2"
                fontWeight={500}
                tag="span"
                noMargin
                color={color}
              >
                {value}
              </Typography>
            </FlexItem>
          )
        }

        {
          iconName && (
            <Icon
              name={iconName}
              color={color}
              size="l"
              iconType={iconType}
            />
          )
        }

        {
          extraValue && (
            <FlexItem>
              <Typography
                variant="body2"
                tag="span"
                noMargin
                color={color}
              >
                {extraValue}
              </Typography>
            </FlexItem>
          )
        }
      </Flex>
    </StyledButton>
  );
};

DialpadButton.defaultProps = {
  value: null,
  extraValue: null,
  onClick: noop,
  onMouseDown: noop,
  onMouseUp: noop,
  onLongClick: noop,
  buttonDown: false,
  color: 'contrast',
  backgroundColor: 'transparent',
  iconName: null,
  iconType: 'feather',
  justifyContent: 'flex-start',
  disabled: false,
  round: false,
};

const {
  bool,
  func,
  string,
} = PropTypes;

DialpadButton.propTypes = {
  onClick: func,
  onLongClick: func,
  onMouseDown: func,
  onMouseUp: func,
  value: string,
  extraValue: string,
  buttonDown: bool,
  color: string,
  backgroundColor: string,
  iconName: string,
  iconType: string,
  justifyContent: string,
  disabled: bool,
  round: bool,
};

export default DialpadButton;
