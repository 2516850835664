import create from './create';
import get from './get';
import getById from './getById';
import update from './update';
import getSummary from './getSummary';
import getChangelog from './getChangelog';
import updateLead2Product from './updateLead2Product';
import claim from './claim';
import unclaim from './unclaim';

export default {
  create,
  get,
  getById,
  update,
  getSummary,
  getChangelog,
  updateLead2Product,
  claim,
  unclaim,
};
