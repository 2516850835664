import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import UpDownButtons from 'ui/UpDownButtons';

import Flex from 'ui/Flex';

const TableHead = (props) => {
  const {
    children,
    onSort,
  } = props;

  return (
    <Flex
      alignItems="center"
      spacing={0.5}
    >
      <Typography
        variant="tableHeader"
        noMargin
        color="descriptor-text"
        noWrap
        textTransform="uppercase"
        weight="bold"
      >
        {children}
      </Typography>
      {
        onSort && (
          <UpDownButtons
            onIncrease={onSort('ascend')}
            onDecrease={onSort('descend')}
          />
        )
      }
    </Flex>
  );
};

TableHead.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

TableHead.propTypes = {

};

export default TableHead;
