import React, {
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Table from 'ui/Table';
import Selector from 'sections/DataImport/modules/Match/Selector';

import {
  Root,
} from './styledItems';

const scroll = {
  // x: true,
};

const getColumns = (selectedTypes, onMatch) => [
  {
    title: 'Column header from file',
    dataIndex: 'header',
    key: 'header',
    width: 1,
    render: (value, entry) => (
      <Typography
        variant="cell"
        ellipsis
      >
        {value}
      </Typography>
    ),
  },
  {
    title: 'Preview information',
    dataIndex: 'preview',
    key: 'preview',
    width: 3,
    render: (value, entry) => (
      <Typography
        variant="cell"
        ellipsis
        style={{
          width: 200,
          maxHeight: 150,
          whiteSpace: 'pre-line',
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    title: 'Symplete data',
    dataIndex: 'header',
    key: 'sympleteType',
    width: 1,
    render: (value, entry) => (
      <Selector rawField={value} key={value} />
    ),
  },
];

const getDataSource = (headers, preview) => headers.map((header) => ({
    header,
    preview: preview[header],
  }));

const Match = (props) => {
  const {
    rawHeaders,
    previewDataSample,
  } = useContext(DataImportContext);

  const dataSource = getDataSource(rawHeaders, previewDataSample);

  return (
    <Root>
      <Table
        columns={getColumns()}
        dataSource={dataSource}
        scroll={scroll}
      />
    </Root>
  );
};

Match.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Match.propTypes = {

};

export default Match;
