import connectReducers from 'store/connectReducers';

import {
  SET_INVOICES_LIST,
  SET_INVOICES_TOTAL,
  SET_INVOICES_LOADING_STATUS,
  SET_INVOICES_NEXT_MONTHLY_PAYMENT,
  SET_INVOICES_NEXT_YEARLY_PAYMENT,
  SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT,
  SET_INVOICES_LICENSES_TO_BE_REVOKED,
} from 'store/actionTypes/invoices';

const initialState = {
  invoicesList: [],
  total: 0,
  isLoading: false,
  nextMonthlyPayment: {},
  previousMonthlyPayment: {},
  nextYearlyPayment: {},
  toBeRevoked: {},
};

const reducers = {
  [SET_INVOICES_LIST]: (state, action) => ({
    ...state,
    invoicesList: action.payload,
  }),

  [SET_INVOICES_TOTAL]: (state, action) => ({
    ...state,
    total: action.payload,
  }),

  [SET_INVOICES_LOADING_STATUS]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),

  [SET_INVOICES_NEXT_MONTHLY_PAYMENT]: (state, action) => ({
    ...state,
    nextMonthlyPayment: action.payload,
  }),

  [SET_INVOICES_NEXT_YEARLY_PAYMENT]: (state, action) => ({
    ...state,
    nextYearlyPayment: action.payload,
  }),

  [SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT]: (state, action) => ({
    ...state,
    previousMonthlyPayment: action.payload,
  }),

  [SET_INVOICES_LICENSES_TO_BE_REVOKED]: (state, action) => ({
    ...state,
    toBeRevoked: action.payload,
  }),
};

const payment = (state = initialState, action) => connectReducers(reducers, state, action);

export default payment;
