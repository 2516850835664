import apiConnector from 'api';

const updateTaskAssignees = (taskId) => async ({
  newAssignees,
  removedAssignees,
}) => {
  try {
    const result = await apiConnector.client.post(`/api/v2/taskAssignees/${taskId}`, {
      newAssignees,
      removedAssignees,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default updateTaskAssignees;
