// TODO: fix depedency cycles
import { apiConnector } from 'shared/API'; // eslint-disable-line

export const NOTIFICATIONS_DRAWER_SHOW = 'NOTIFICATIONS_DRAWER_SHOW';
export const NOTIFICATIONS_DRAWER_HIDE = 'NOTIFICATIONS_DRAWER_HIDE';
export const NOTIFICATIONS_DRAWER_SET_ITEMS = 'NOTIFICATIONS_DRAWER_SET_ITEMS';
export const NOTIFICATIONS_DRAWER_SET_TYPES = 'NOTIFICATIONS_DRAWER_SET_TYPES';
export const NOTIFICATIONS_DELETE_ITEM = 'NOTIFICATIONS_DELETE_ITEM';
export const NOTIFICATIONS_TOGGLE_READ = 'NOTIFICATIONS_TOGGLE_READ';
export const NOTIFICATIONS_MARK_ALL_AS_READ = 'NOTIFICATIONS_MARK_ALL_AS_READ';
export const NOTIFICATIONS_TOGGLE_FILTER_ITEM = 'NOTIFICATIONS_TOGGLE_FILTER_ITEM';
export const NOTIFICATIONS_CLEAR_ACTIVE_FILTERS = 'NOTIFICATIONS_CLEAR_ACTIVE_FILTERS';
export const NOTIFICATIONS_GET_COUNTER_NEW = 'NOTIFICATIONS_GET_COUNTER_NEW';
export const NOTIFICATIONS_ITEMS_UNSHIFT = 'NOTIFICATIONS_ITEMS_UNSHIFT';

export const notificationsDrawerShowAction = () => ({
  type: NOTIFICATIONS_DRAWER_SHOW,
});

export const notificationsDrawerHideAction = () => ({
  type: NOTIFICATIONS_DRAWER_HIDE,
});

export const notificationsSetViewedAction = (ids) => () => (
  apiConnector(
    '/Notifications/View',
    'PUT',
    { notificationIds: ids.join(',') },
  )
);

export const notificationsGetCounterNewAction = () => async (dispatch) => {
  const res = await apiConnector(
    '/Notifications/New',
    'GET',
  );

  const {
    total: counterNew,
    1: total_1 = 0,
    2: total_2 = 0,
    3: total_3 = 0,
    4: total_4 = 0,
    5: total_5 = 0,
  } = res;

  dispatch({
    type: NOTIFICATIONS_GET_COUNTER_NEW,
    counterNew,
    counterNewByType: {
      1: total_1,
      2: total_2,
      3: total_3,
      4: total_4,
      5: total_5,
    },
  });
};

export const notificationsUnshiftAction = (data) => (dispatch) => {
  dispatch({
    type: NOTIFICATIONS_ITEMS_UNSHIFT,
    data,
  });
};

export const notificationsToggleFilterItemAction = (itemsKey) => (dispatch) => {
  dispatch({
    type: NOTIFICATIONS_TOGGLE_FILTER_ITEM,
    itemsKey,
  });
  return Promise.resolve();
};

export const notificationsClearFilterItemsAction = () => (dispatch) => {
  dispatch({
    type: NOTIFICATIONS_CLEAR_ACTIVE_FILTERS,
  });
  return Promise.resolve();
};

export const notificationsMarkAllAsReadAction = () => async (dispatch) => {
  await apiConnector(
    '/Notifications/ReadAll',
    'PUT',
  );

  dispatch({
    type: NOTIFICATIONS_MARK_ALL_AS_READ,
  });
};

export const checkHRAction = () => async () => {
  await apiConnector(
    '/Notifications/CheckHr',
    'PUT',
  );
};

export const notificationsToggleReadAction = (id) => async (dispatch) => {
  await apiConnector(
    `/Notifications/Read/${id}`,
    'PUT',
  );

  dispatch({
    type: NOTIFICATIONS_TOGGLE_READ,
    id,
  });
};

export const notificationsDeleteItemAction = (id) => async (dispatch) => {
  await apiConnector(
    `/Notifications/${id}`,
    'DELETE',
  );

  dispatch({
    type: NOTIFICATIONS_DELETE_ITEM,
    id,
  });
};

export const notificationsDrawerSetItemsAction = ({
  count = '',
  limit = 20,
  offset = 0,
  types,
  textSearch = '',
}, push) => async (dispatch) => {
  const result = await apiConnector(
    `/Notifications/?limit=${limit}&offset=${offset}&filter=${textSearch}&count=${count}&product=${types.join(',')}`,
    'GET',
  );

  if (count) {
    const { items, count: total } = result;

    dispatch({
      type: NOTIFICATIONS_DRAWER_SET_ITEMS,
      items,
      total,
      push,
    });
  } else {
    const { items } = result;

    dispatch({
      type: NOTIFICATIONS_DRAWER_SET_ITEMS,
      items,
      push,
    });
  }
};

export const setNotificationsTypesAction = () => async (dispatch) => {
  const types = await apiConnector(
    '/Notifications/ProductTypes',
    'GET',
  );

  dispatch({
    type: NOTIFICATIONS_DRAWER_SET_TYPES,
    types,
  });
};
