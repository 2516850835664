import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%
`;

export const Root = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;
