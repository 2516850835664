import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  padding: 10px 15px;
  background-color: ${(props) => props?.theme?.palette?.background || 'white'};
  box-shadow: 2px 3px 9px 1px rgba(9, 55, 83, 0.21);
  border-radius: 10px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;

  &&.contact-import-card-1 {
    width: 100%;
    top: 70px;
    z-index: 6;

    &&.done-importing {
      transition: top 1.5s, z-index 0.2s 0.1s;
      top: 0px;
      z-index: 7;
    }
  }

  &&.contact-import-card-2 {
    width: 98%;
    top: 110px;
    z-index: 5;

    &&.done-importing {
      transition: top 1.5s, z-index 0.2s 0.1s;
      top: 20px;
      z-index: 8;
    }
  }

  &&.contact-import-card-3 {
    width: 96%;
    top: 140px;
    z-index: 4;

    &&.done-importing {
      transition: top 1.5s, z-index 0.2s 0.1s;
      top: 40px;
      z-index: 9;
    }
  }

  &&.contact-import-card-4 {
    width: 94%;
    top: 165px;
    z-index: 3;

    &&.done-importing {
      transition: top 1.5s, z-index 0.2s 0.1s;
      top: 60px;
      z-index: 10;
    }
  }

  &&.contact-import-card-5 {
    width: 92%;
    top: 190px;
    z-index: 2;

    &&.done-importing {
      transition: top 1.5s, z-index 0.2s 0.1s;
      top: 80px;
      z-index: 11;
    }
  }
`;
