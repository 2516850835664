/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getFollowUpReminderById from './getFollowUpReminderById';
import createFollowUpReminders from './createFollowUpReminders';
import deleteFollowUpReminders from './deleteFollowUpReminders';
import deleteFollowUpReminder from './deleteFollowUpReminder';
import getFollowUpRemindersForFilter from './getFollowUpRemindersForFilter';
import updateFollowUpReminder from './updateFollowUpReminder';
import setFollowUpRemindersForFilter from './setFollowUpRemindersForFilter';
import setFollowUpRemindersForFilterCount from './setFollowUpRemindersForFilterCount';
import getOverallSummary from './getOverallSummary';

export {
  deleteFollowUpReminders,
  deleteFollowUpReminder,
  createFollowUpReminders,
  updateFollowUpReminder,
  getFollowUpReminderById,
  setFollowUpRemindersForFilter,
  setFollowUpRemindersForFilterCount,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedFollowUpRemindersActions = {
  getFollowUpReminderById: memoizeDebounce(
    (id) => store.dispatch(getFollowUpReminderById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getFollowUpRemindersForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getFollowUpRemindersForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getOverallSummary: memoizeDebounce(
    () => store.dispatch(getOverallSummary()),
    debounceDelay,
    debounceOptions,
  ),
};
