import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withContactData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      contactId,
    } = ownProps;

    const contactData = selectors.realtorContacts.byId(state, contactId);

    return {
      contactData,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withContactData;
