import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const RoleLabel = (props) => {
  const {
    children,
  } = props;

  return (
    <Root
      role={children}
    >
      <Typography
        variant="tag2"
        color="contrast"
        textTransform="uppercase"
      >
        {children}
      </Typography>
    </Root>
  );
};

const {
  string,
} = PropTypes;

RoleLabel.propTypes = {
  children: string.isRequired,
};

export default RoleLabel;
