import {
  SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD,
} from 'store/actionTypes/realtorContacts';

const setNewContactNestedFormField = (parentKey) => (key) => (value) => ({
  type: SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD,
  payload: {
    parentKey,
    key,
    value,
  },
});

export default setNewContactNestedFormField;
