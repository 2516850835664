/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Grid from 'ui/Grid';

import CommissionInput from 'shared/inputs/CommissionInput';

const fields = [
  {
    name: 'seller_agent_commission',
    label: 'Seller agent commission',
  },
  {
    name: 'buyer_agent_commission',
    label: 'Buyer agent commission',
  },
  {
    name: 'coop_commission',
    label: 'Co-op commission',
  },
];

const Commission = (props) => {
  const {
    control,
    errors,
    commissionTypes,
    onTypeChange,
  } = props;

  const handleTypeChange = useCallback((field) => (value) => {
    onTypeChange({
      ...commissionTypes,
      [field]: value,
    });
  }, [
    onTypeChange,
    commissionTypes,
  ]);

  return (
    <Grid container spacing={4}>
      {
        fields.map((item) => (
          <Grid item xs={6} key={item.name}>
            <Controller
              name={item.name}
              control={control}
              defaultValue={2.5}
              render={({ field }) => (
                <CommissionInput
                  {...field}
                  label={item.label}
                  onTypeChange={handleTypeChange(item.name)}
                  commissionType={commissionTypes[item.name]}
                  error={!!errors[item.name]}
                  helperText={errors[item.name] ? 'Invalid value' : ''}
                />
              )}
              rules={{
                required: true,
                min: 0,
              }}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

const {
  shape,
  func,
  number,
} = PropTypes;

Commission.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  onTypeChange: func.isRequired,
  commissionTypes: shape({
    seller_agent_commission: number,
    buyer_agent_commission: number,
    coop_commission: number,
  }).isRequired,
};

export default Commission;
