import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_UNANSWERED_QUESTIONS,
  SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER,
  SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT,

  RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER,
  RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT,

  RESET_REALTOR_UNANSWERED_QUESTIONS_FILTERS,
  RESET_REALTOR_UNANSWERED_QUESTIONS_FILTER_COUNTERS,

  SET_REALTOR_UNANSWERED_QUESTION_ATTACHMENTS,
} from 'store/actionTypes/realtorUnansweredQuestions';

import {
  getIds,
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  unansweredQuestions: {},
  unansweredQuestionsByFilter: {},
  unansweredQuestionsCountByFilter: {},
};

const reducers = {
  [SET_REALTOR_UNANSWERED_QUESTIONS]: (state, action) => ({
      ...state,
      unansweredQuestions: {
        ...state.unansweredQuestions,
        ...listToObjectByKey(action.payload),
      },
    }),

  [SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER]: (state, action) => {
    const existing = state.unansweredQuestionsByFilter[action.payload.filterId] || [];

    const items = [...new Set([
      ...existing,
      ...getIds(action.payload.items),
    ])];

    return {
      ...state,
      unansweredQuestionsByFilter: {
        ...state.unansweredQuestionsByFilter,
        [action.payload.filterId]: items,
      },
    };
  },

  [SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    unansweredQuestionsCountByFilter: {
      ...state.unansweredQuestionsCountByFilter,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER]: (state, action) => ({
    ...state,
    unansweredQuestionsByFilter: {
      ...state.unansweredQuestionsByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    unansweredQuestionsCountByFilter: {
      ...state.unansweredQuestionsCountByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_UNANSWERED_QUESTIONS_FILTERS]: (state, action) => ({
    ...state,
    unansweredQuestionsByFilter: {},
  }),

  [RESET_REALTOR_UNANSWERED_QUESTIONS_FILTER_COUNTERS]: (state, action) => ({
    ...state,
    unansweredQuestionsCountByFilter: {},
  }),

  [SET_REALTOR_UNANSWERED_QUESTION_ATTACHMENTS]: (state, action) => ({
    ...state,
    unansweredQuestions: {
      ...state.unansweredQuestions,
      [action.payload.id]: {
        ...state.unansweredQuestions[action.payload.id],
        attachments: action.payload.attachments.map(({ attachmentId }) => attachmentId),
      },
    },
  }),
};

function unansweredQuestions(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default unansweredQuestions;
