import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactControls from './ContactControls';

const mapStateToProps = (state, ownProps) => {
  const { contactId } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    name: `${contact.first_name || ''} ${contact.last_name || ''}`,
    email: contact.email_primary,
    phone: contact.phone_primary,
  };
};

export default connect(mapStateToProps)(ContactControls);
