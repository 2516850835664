import {
  SET_NEW_REALTOR_LEAD_FORM,
} from 'store/actionTypes/realtorLeads';

const setNewLeadForm = (values = {}) => ({
  type: SET_NEW_REALTOR_LEAD_FORM,
  payload: values,
});

export default setNewLeadForm;
