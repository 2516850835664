import imageCompression from 'browser-image-compression';
import isAllowedFormat from './isAllowedFormat';

import getImageDimensionsFromFile from './getImageDimensionsFromFile';

const maxImageResolutionInMP = 100;

const noOp = () => {};

const getMaxWidthOrHeightInPx = (maxResolutionInPx) => (ratio = 1) => Math.sqrt(maxResolutionInPx / ratio);

/**
 * @typedef {Object} ImageDimensions image dimensions object
 * @property {number} width
 * @property {number} height
 * @property {number} resolutionInMP
 * @property {number} ratio smaller side to bigger side ratio
 */

/**
 * @typedef {Object} ImageCompressionOptions image compression option object
 * @property {number} maxWidthOrHeight
 * @property {boolean} useWebWorker
 * @property {Function} onProgress
 */

/**
 *
 * @param {Object} params
 * @param {Number} params.imageRatio
 * @param {Function} params.onProgress
 * @return {ImageCompressionOptions}
 */
const getImageCompressionOptions = ({ imageRatio, onProgress = noOp }) => {
  const maxWidthOrHeight = getMaxWidthOrHeightInPx(maxImageResolutionInMP * 1e6)(imageRatio);

  return {
    // maxSizeInMB: 50,
    maxWidthOrHeight,
    useWebWorker: true,
    onProgress,
  };
};

const normalizeResolution = (options) => async (file) => {
  const fileNormalizedByResolution = await imageCompression(file, getImageCompressionOptions({
    imageRatio: options.ratio,
    onProgress: options.onProgress,
  }));

  return fileNormalizedByResolution;
};


/**
 *
 * @param {File | Blob} file
 * @param {function} onProgress on compression progress callback, accepts progress percentage
 * @returns {File} compressed file
 */
const compressFile = async (file, onProgress = noOp) => {
  if (!isAllowedFormat('image')(file)) {
    throw new Error('cannot compress this file type');
  }

  const imageDimensions = await getImageDimensionsFromFile(file);

  const imageExceedsMaxResolution = imageDimensions.resolutionInMP > maxImageResolutionInMP;

  const blobNormalizedByResolution = imageExceedsMaxResolution
    ? await normalizeResolution({ onProgress, ratio: imageDimensions.ratio })(file)
    : file;

  const compressedFile = new File([blobNormalizedByResolution], file.name, {
    type: blobNormalizedByResolution.type,
  });

  return compressedFile;
};

export default compressFile;
