import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createPropertyDetails = (propertyDetailsData) => async (dispatch) => {
  try {
    const response = await requests.realtor.properties.create(propertyDetailsData);

    return response.data.id;
  } catch (error) {
    console.error(error);
  }
};

export default createPropertyDetails;
