import {
  SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD,
} from 'store/actionTypes/tasks';

const setEmployeeTasksSummaryForPeriod = (employeeId, period) => (tasksSummary) => ({
  type: SET_TASKS_SUMMARY_FOR_EMPLOYEE_FOR_PERIOD,
  payload: {
    employeeId,
    period,
    tasksSummary,
  },
});

export default setEmployeeTasksSummaryForPeriod;
