import apiConnector, { endpoints } from 'api';

const get = async (query) => {
  const endpoint = endpoints.client.orders;

  try {
    const result = await apiConnector.client.get(endpoint, query);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default get;
