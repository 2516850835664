/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Checkbox from 'ui/Checkbox';

const ContingenciesCheckboxes = (props) => {
  const {
    control,
  } = props;

  const handleChange = useCallback((onChange) => (checked) => {
    onChange(checked);
  }, []);

  return (
    <Flex
      fullWidth
      spacing={2}
    >
      <Controller
        name="isAllCash"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <Flex
            spacing={1}
            alignItems="center"
          >
            <Checkbox
              {...field}
              onChange={handleChange(field.onChange)}
            />
            <Typography
              noMargin
              noWrap
            >
              All cash
            </Typography>
          </Flex>
        )}
      />

      <Controller
        name="isNoContingencies"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <Flex
            spacing={1}
            alignItems="center"
          >
            <Checkbox
              {...field}
              onChange={handleChange(field.onChange)}
            />
            <Typography
              noMargin
              noWrap
            >
              No contingencies
            </Typography>
          </Flex>
        )}
      />
    </Flex>
  );
};

const {
  shape,
} = PropTypes;

ContingenciesCheckboxes.propTypes = {
  control: shape().isRequired,
};

export default ContingenciesCheckboxes;
