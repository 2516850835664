import requests from 'api/requests';

import setOverallTasksSummary from './setOverallTasksSummary';

const getOverallTasksSummary = () => async (dispatch) => {
  try {
    const summaryResponse = await requests.tasks.getOverallTasksSummary();

    dispatch(setOverallTasksSummary(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};


export default getOverallTasksSummary;
