import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

const SectionTitle = (props) => {
  const {
    title,
    icon,
  } = props;

  return (
    <Flex spacing={2}>
      <FlexItem>
        <Icon
          name={icon}
          color="focus"
        />
      </FlexItem>
      <FlexItem>
        <Typography
          variant="h3"
          noMargin
        >
          {title}
        </Typography>
      </FlexItem>
    </Flex>
  );
};

const { string } = PropTypes;

SectionTitle.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
};

export default SectionTitle;
