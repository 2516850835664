const addListItem = (state, action) => {
  const list = { ...state[action.payload.key] };

  const now = new Date();

  const id = `id-${now.getTime()}`;

  list[id] = action.payload.newItem;

  const updatedState = {
    ...state,
    [action.payload.key]: list,
  };

  return updatedState;
};

export default addListItem;
