import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import DropdownTextfield from 'ui/DropdownTextfield';

const ContactTitleSelect = (props) => {
  const {
    value,
    handleChange,
    disabled,
    options,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const onChange = useCallback((newValue) => {
    handleChange(newValue);
    setCurrentValue(newValue);
  }, [
    handleChange,
  ]);

  return (
    <DropdownTextfield
      value={currentValue}
      onChange={onChange}
      options={options}
      label="Title"
      disabled={disabled}
      menuItemStyle={{ textTransform: 'capitalize' }}
      style={{ textTransform: 'capitalize' }}
    />
  );
};

ContactTitleSelect.defaultProps = {
  value: undefined,
  disabled: false,
};

const {
  func,
  number,
  bool,
  arrayOf,
  shape,
  string,
} = PropTypes;

ContactTitleSelect.propTypes = {
  handleChange: func.isRequired,
  value: number,
  disabled: bool,
  options: arrayOf(shape({
    value: number.isRequired,
    label: string.isRequired,
  })).isRequired,
};

export default ContactTitleSelect;
