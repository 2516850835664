import requests from 'api/requests';

import setContacts from './setContacts';

const getContacts = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.crm.contacts.get({
      offset,
      limit,
    });

    dispatch(setContacts(response.data.contacts));
  } catch (error) {
    console.error(error);
  }
};

export default getContacts;
