import apiConnector, { endpoints } from 'api';

/**
 * Sends GET request to retrieve the meetings from server.
 * @returns {Promise} A response from server.
 */

const getMeetings = async () => {
  const endpoint = endpoints.OA.meetingsPath();
  let response;

  try {
    response = await apiConnector.OA.get(endpoint);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return response;
};

export default getMeetings;
