import requests from 'api/requests';
import { UPDATE_DESIRED_OUTCOME } from 'store/actionTypes/video-chat';
import shareActiveMeeting from './shareActiveMeeting';

const updateDesiredOutcome = ({ meetingId, id, payload }) => async (dispatch, getState) => {
  try {
    const response = await requests.meetings.updateDesiredOutcome({
      meetingId,
      id,
      payload,
    });

    dispatch({
      type: UPDATE_DESIRED_OUTCOME,
      payload: response.data,
    });

    const { activeMeeting } = getState().videoChat;

    shareActiveMeeting(activeMeeting);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default updateDesiredOutcome;
