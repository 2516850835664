import styled from 'styled-components';

export const Root = styled.div`
  background-color: ${(props) => props.theme?.palette?.['background-flat']};
  padding: 12px;
  border-radius: 14px;
`;

export default {
  Root,
};
