import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import Tag from 'ui/Tag';

import CallsContext from 'sections/Calls/context/CallsContext';

import formatTime from 'sections/Calls/utils/formatCallDuration';

const CallDurationTag = (props) => {
  const {
    callDuration,
  } = props;

  const {
    recordingStatus,
  } = useContext(CallsContext);

  const callRecording = recordingStatus === 'in-progress';

  return (
    <Tag
      variant={callRecording ? 'error' : 'calendarClosingEscrows'}
      typographyProps={{
        weight: 400,
        align: 'center',
        style: { width: '100%' },
      }}
    >
      {`${formatTime(callDuration)} ${callRecording ? 'CALL IS BEING RECORDED' : ''}`}
    </Tag>
  );
};

CallDurationTag.defaultProps = {
  callDuration: 0,
};

const {
  number,
} = PropTypes;

CallDurationTag.propTypes = {
  callDuration: number,
};

export default CallDurationTag;
