import { createSelector } from 'reselect';

import {
  optimizedDealsActions,
} from 'store/actions/deals';

/**
 * Calculates total price for product
 * @param {Object} product - The given product.
 * @param {number} product.quantity - The product quantity.
 * @param {number} product.price_per_unit - The product price per unit.
 * @param {1|2} product.discount_type - The product discount type (percent, amount).
 * @param {number} product.discount_amount - The product discount amount.
 * @returns {number} Product total price with applied discount.
 */
const getProductTotalPrice = (product) => {
  const quantity = parseFloat(product.quantity);
  const pricePerUnit = parseFloat(product.price_per_unit || 0);
  const discountType = parseFloat(product.discount_type);
  const discountAmount = parseFloat(product.discount_amount || 0);

  const priceWithoutDiscount = quantity * pricePerUnit;

  const discount = (
    discountType === 1
      ? (
        priceWithoutDiscount / 100 * discountAmount
      ) : (
        discountAmount
      )
  );

  const totalPrice = Number((priceWithoutDiscount - +discount).toFixed(2));

  return totalPrice > 0 ? totalPrice : 0;
};

export default createSelector(
  (state) => state.deals.dealProducts,
  (_, dealId) => dealId,
  (dealProducts, dealId) => {
    const selectedProducts = dealProducts[dealId];

    if (!selectedProducts) {
      optimizedDealsActions.getDealProducts(dealId);
      return 0;
    }

    const grandTotal = selectedProducts.reduce((accumulator, product) => (
      accumulator + getProductTotalPrice(product)
    ), 0);

    return grandTotal;
  },
);
