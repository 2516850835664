import React, {
  useContext,
} from 'react';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Spin from 'ui/Spin';

import FileItem from './FileItem';

import {
  Expansion,
} from './styledItems';

const UploadedFiles = (props) => {
  const {
    uploadedFiles,
    isUploadedListLoading,
    uploadedListLoadingError,
  } = useContext(DataImportContext);

  return (
    <section style={{ width: '100%' }}>
      <Typography variant="title2">
        Recent Uploads:
      </Typography>
      {
        isUploadedListLoading && (
          <Flex
            fullWidth
            justifyContent="center"
          >
            <Spin />
          </Flex>
        )
      }
      {
        !uploadedListLoadingError && !isUploadedListLoading && uploadedFiles && uploadedFiles?.length > 0 && (
          <Expansion spacing={1}>
            {
              uploadedFiles.map((item) => (
                <FileItem
                  key={item.id}
                  name={item.name}
                  fileId={item.id}
                />
              ))
            }
          </Expansion>
        )
      }
      {
        !uploadedListLoadingError && !isUploadedListLoading && (!uploadedFiles || !uploadedFiles?.length) && (
          <Typography>
            No files uploaded yet
          </Typography>
        )
      }
      {
        uploadedListLoadingError && !isUploadedListLoading && (
          <Typography color="error">
            Can’t fetch uploaded files
          </Typography>
        )
      }
    </section>
  );
};

export default UploadedFiles;
