import styled from 'styled-components'

export const SelectorWrapper = styled.div`
  margin-bottom: 6px;
  .comment-type-select-description {
    margin-top: auto;
    margin-bottom: auto;
  }
`

export default {
  SelectorWrapper
}
