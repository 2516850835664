import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Divider from '@material-ui/core/Divider';
import Button from 'ui/Button';

import NotesModal from 'sections/Transactions/modals/RepairRequestNotesModal';

import RepairRequestsTable from './RepairRequestsTable';
import Overview from './Overview';

const RepairRequests = (props) => {
  const {
    transactionId,
    onNewClick,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4} />
        <Grid item container xs={8} spacing={2} justify="flex-end" alignItems="center">
          <Grid item>
            <Overview transactionId={transactionId} />
          </Grid>
          <Grid item>
            <Button
              icon="plus"
              variant="secondary"
              onClick={onNewClick}
            >
              New
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <RepairRequestsTable transactionId={transactionId} />
      </Grid>

      <NotesModal />
    </Grid>
  );
};

RepairRequests.defaultProps = {
  transactionId: null,
};

const {
  number,
  string,
  oneOfType,
  func,
} = PropTypes;

RepairRequests.propTypes = {
  transactionId: oneOfType([number, string]),
  onNewClick: func.isRequired,
};

export default RepairRequests;
