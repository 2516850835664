import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.realtorTransactionsFilter,
  (filter) => {
    if (!filter.status && !filter.stage) {
      return 'all';
    }
    if (filter.status === 1) {
      return 'active';
    }
    if (filter.status === 2) {
      return 'archived';
    }
    if (filter.status === 7) {
      return 'new';
    }
    if (filter.stage === 'precontingencies') {
      return 'precontingencies';
    }
    if (filter.stage === 'postcontingencies') {
      return 'postcontingencies';
    }
    return 'all';
  },
);
