import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle

import publicIp from 'public-ip';
import { detect } from 'detect-browser';

import { get } from 'shared/utility';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logout = () => async (dispatch, getState) => {
  localStorage.removeItem('token');
  localStorage.removeItem('commentsDrafts');

  const username = get(getState(), 'auth.loggedUser.Email', null);
  const database = get(getState(), 'auth.database', null);

  if (username && database) {
    const url = '/auth/logout';
    const config = { extended: true, global: true };
    const meta = await getMeta();
    const body = {
      ...meta,
      database,
      username,
    };

    apiConnector(url, 'POST', body, config);
  }

  dispatch({ type: LOGOUT_SUCCESS });
};

async function getMeta(obj) {
  const ip = await getIP();
  const browserData = getBrowserData(obj);
  return {
    ip,
    browserName: browserData.name,
    browserVersion: browserData.version,
    os: browserData.os,
  };
}

async function getIP() {
  let ipV4;
  try {
    ipV4 = await publicIp.v4();
  } catch (e) {
    console.error(e);
  }
  return ipV4;
}

function getBrowserData() {
  const browser = detect();
  return browser;
}
