/* eslint-disable react/jsx-no-bind */

import React, {
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import CounterOfferForm from 'sections/OffersV2/forms/CounterOfferForm';

import PropertyOverview from 'sections/Properties/modules/PropertyOverview';
import PropertyContact from 'sections/Properties/modules/PropertyContact';

import OffersContext from 'sections/OffersV2/context/OffersContext';

const CounterOfferModal = (props) => {
  const {
    propertyId,
    offerId,
    offerType,
    totalCounters,
    offerData,
    isVisible,
    closeModal,
    onSuccess,
  } = props;

  const { getAllOffers } = useContext(OffersContext);

  const handleSuccess = async (newOfferId) => {
    await getAllOffers();
    closeModal();

    if (onSuccess) {
      onSuccess(newOfferId);
    }
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={closeModal}
      width="auto"
      style={{ maxWidth: '1500px' }}
      footer={null}
      title={null}
      noCloseIcon
    >
      <Flex
        fullWidth
        spacing={2}
        flexWrap="wrap"
        alignItems="stretch"
      >
        <FlexItem xs={16}>
          <Elevation
            rounded="s"
            padding="m"
            fullHeight
          >
            <PropertyOverview propertyId={propertyId} />
          </Elevation>
        </FlexItem>

        <FlexItem xs={8}>
          <PropertyContact propertyId={propertyId} />
        </FlexItem>

        <FlexItem fullWidth>
          <Elevation
            rounded="s"
            padding="m"
          >
            <CounterOfferForm
              propertyId={propertyId}
              prevOfferId={offerId}
              prevOfferType={offerType}
              onSuccess={handleSuccess}
              currentCounter={totalCounters ? totalCounters + 1 : 1}
              offerData={offerData}
            />
          </Elevation>
        </FlexItem>

      </Flex>
    </Modal>
  );
};

CounterOfferModal.defaultProps = {
  isVisible: false,
  onSuccess: null,
  totalCounters: null,
  offerData: null,
};

const {
  bool,
  func,
  number,
  shape,
} = PropTypes;

CounterOfferModal.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
  propertyId: number.isRequired,
  onSuccess: func,
  offerType: number.isRequired,
  offerId: number.isRequired,
  totalCounters: number,
  offerData: shape({}),
};

export default CounterOfferModal;
