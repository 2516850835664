import apiConnector, { endpoints } from 'api';

const find = async (email) => {
  const endpoint = `${endpoints.client.realtorDirectory.find}/${email}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default find;
