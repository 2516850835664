import requests from 'api/requests';

import set from './set';

const getById = (id) => async (dispatch) => {
  try {
    const response = await requests.creditNotes.getById(id);
    dispatch(set(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getById;
