import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import { Table } from 'antd';

import PropTypes from 'prop-types';

import columns from './columns';

import './RepairRequestsTable.scss';

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} repair requests`;

const RepairRequestsTable = (props) => {
  const {
    repairRequestsList,
    getRepairRequests,
    itemsCount,
    transactionId,
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getRepairRequests(pageNumber, pageSize);
  }, [
    transactionId,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
    getRepairRequests(newPageNumber, newPageSize);
  }, [
    getRepairRequests,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onMouseEnter: (event) => {
      setHoveredRecord(record.id);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    setHoveredRecord,
  ]);

  return (
    <Table
      key="repair-requests-table"
      className="realtor-repair-requests-table"
      dataSource={repairRequestsList}
      columns={columns({ hoveredRecord, transactionId })}
      pagination={{
        total: itemsCount,
        showTotal,
        onChange: onPaginationChange,
        current: pageNumber,
        pageSize,
      }}
      onRow={onRow}
      tableLayout="fixed"
      size="middle"
    />
  );
};

RepairRequestsTable.defaultProps = {
  repairRequestsList: [],
  itemsCount: 0,
};

const {
  arrayOf,
  func,
  number,
  string,
  shape,
  oneOfType,
} = PropTypes;

RepairRequestsTable.propTypes = {
  repairRequestsList: arrayOf(shape({})),
  getRepairRequests: func.isRequired,
  itemsCount: number,
  transactionId: oneOfType([number, string]).isRequired,
};

export default RepairRequestsTable;
