import { Dropdown as AntDropdown, Icon } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownMenu from 'shared/components/Dropdown/DropdownMenu';
import Button from '../Button';
import './Dropdown.scss';

const rootClass = 'dropdown';


export const placementArray = [
  'bottomLeft',
  'bottomCenter',
  'bottomRight',
  'topLeft',
  'topCenter',
  'topRight',
];


class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleClick = (item) => {
    const { MenuProps } = this.props;
    this.setState({
      visible: false,
    });
    MenuProps.handleClick(item);
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  render() {
    const {
      className,
      children,
      type,
      MenuProps,
      trigger,
      placement,
      buttonClassName,
      noDownArrow,
      isButton,
    } = this.props;

    const { visible } = this.state;

    const menu = MenuProps.component
      ? MenuProps.component
      : (
        <DropdownMenu
          items={MenuProps.items}
          handleClick={this.handleClick}// {MenuProps.handleClick}
          className={classnames(
            `${rootClass}__menu`,
            {
              [MenuProps.className]: MenuProps.className,
            },
          )}
          itemClassName={classnames(
            `${rootClass}__menu__item`,
            {
              [MenuProps.itemClassName]: MenuProps.itemClassName,
            },
          )}
        />
      );

    const dropdownElement = isButton ? (
      <Button
        type={type}
        className={classnames(
          `${rootClass}__button`,
          {
            [buttonClassName]: buttonClassName,
          },
        )}
      >
        {children}
        {' '}
        {!noDownArrow && <Icon type="down" />}
      </Button>
    ) : (
      <span>
        { children }
      </span>
    );


    return (
      <AntDropdown
        className={classnames(
          rootClass,
          {
            [className]: className,
          },
        )}
        trigger={[trigger]}
        overlay={menu}
        placement={placement}
        onVisibleChange={this.handleVisibleChange}
        visible={visible}
      >
        { dropdownElement }
      </AntDropdown>
    );
  }
}

export default Dropdown;

Dropdown.defaultProps = {
  className: '',
  type: 'primary',
  trigger: 'click',
  placement: 'bottomLeft',
  buttonClassName: '',
  isButton: true,
  handleClick: () => {},
  MenuProps: {},
  noDownArrow: false,
};

const {
  string,
  number,
  func,
  arrayOf,
  shape,
  oneOfType,
  oneOf,
  element,
  node,
  bool,
} = PropTypes;

Dropdown.propTypes = {
  className: string,
  children: oneOfType([element, string, number]).isRequired,
  type: string,
  handleClick: func,
  MenuProps: shape({
    handleClick: func,
    items: arrayOf(shape({
      key: oneOfType([number, string]).isRequired,
      text: string.isRequired,
    })),
    component: node,
  }),
  trigger: oneOf(['click', 'hover']),
  placement: oneOf(placementArray),
  buttonClassName: string,
  isButton: bool,
  noDownArrow: bool,
};
