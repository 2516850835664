import {
  SET_REALTOR_CONTACTS,
} from 'store/actionTypes/realtorContacts';

const setContacts = (contacts) => ({
  type: SET_REALTOR_CONTACTS,
  payload: contacts,
});

export default setContacts;
