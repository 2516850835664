import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getPhoneNumberById from './getPhoneNumberById';
import createPhoneNumber from './createPhoneNumber';
import updatePhoneNumber from './updatePhoneNumber';

export {
  getPhoneNumberById,
  createPhoneNumber,
  updatePhoneNumber,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedPhoneNumbersActions = {
  getPhoneNumberById: memoizeDebounce(
    (id) => store.dispatch(getPhoneNumberById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
