import styled from 'styled-components';

export const FullRichEditorContainer = styled.div`
  display: ${({ isFullRichEditor }) => (isFullRichEditor ? 'contents' : 'none')};
`;

export const EditToolbarContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => (`${theme.palette['background-flat']}`)};
  border-radius: 4px;
  display: ${({ isFullRichEditor }) => (isFullRichEditor ? 'flex' : 'block')};
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;

  margin: 0 0 10px 0;

  &__pipe {
    width: 1px;
    background-color: #6b88a4;
    height: 18px;
  }

  button {
    padding: 0 8px;
  }

  .search-in-edit {
    bottom: 0;
    position: absolute;
    right: -4px;
    width: 150px;
    z-index: -1;
    opacity: 0;
    transition: 0.2s all ease;

    &_active {
      bottom: -32px;
      opacity: 1;
      z-index: 1;
    }
  }

  .edit-btn:hover {
    color: ${({ theme }) => (`${theme.palette['focus']}`)};
  }

  .edit-btn {
    border: none;
    background-color: transparent;

    &_active {
      color: ${({ theme }) => (`${theme.palette['focus']}`)};
    }
  }
`;

export default {
  FullRichEditorContainer,
  EditToolbarContainer,
};
