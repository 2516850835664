/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Modal } from 'antd';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import './styles.scss';

export const ModalWithXButton = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
    box-shadow: ${(props) => props.theme?.shadows?.modal || 'none'};
    ${(props) => (
      props.borderColor
      ? `border: 4px solid ${props.theme?.palette?.[props.borderColor] || props.borderColor}`
      : '')};
  }

  .ant-modal-body {
    ${(props) => (props.children ? 'padding: 30px;' : 'padding: 0;')}
  }

  .ant-modal-close {
    top: 0px;
    right: 0px;
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px;
      height: auto;
      width: auto;


      &:hover {
        opacity: 0.7
      }
    }

    .anticon {
      svg {
        color: ${(props) => props.theme?.palette?.['main-text'] || '#a1bdcb'};
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    ${(props) => (props.isEmptyTitle ? 'padding-top: 24px' : 'padding: 0')};
    padding-right: 70px;
  }

  .ant-modal-footer {
    border-top: none;
    display: flex;
    justify-content: ${(props) => props.justifyFooterContent};
    align-items: ${(props) => props.alignFooterItems};
  }

  &.ant-modal {
    ${(props) => (props.backgroundInteractive ? 'pointer-events: all;' : '')}
  }
`;

const SympleteModal = (props) => {
  const {
    title,
    subtitle,
    justifyFooterContent,
    alignFooterItems,
    borderColor,
    backgroundInteractive,
    mask,
    maskClosable,
    wrapClassName,
    noCloseIcon,
  } = props;

  return (
    <ModalWithXButton
      {...props}
      justifyFooterContent={justifyFooterContent}
      alignFooterItems={alignFooterItems}
      isEmptyTitle={title}
      title={(
        <>
          {
            title && (
              <Typography
                variant="title1"
                tag="h1"
                color="main-text"
                noMargin
                weight={700}
              >
                {title}
              </Typography>
            )
          }
          {
            subtitle && (
              <Typography
                variant="title4"
                color="descriptor-text"
                tag="p"
                noMargin
                style={{ marginTop: 10 }}
              >
                {subtitle}
              </Typography>
            )
          }
        </>
      )}
      closeIcon={noCloseIcon ? <></> : (
        <Icon
          name="x"
          color="focus"
        />
      )}
      borderColor={borderColor}
      backgroundInteractive={backgroundInteractive}
      maskStyle={{ backgroundColor: 'rgba(9, 30, 66, 0.25)' }}
      mask={backgroundInteractive ? false : mask}
      maskClosable={backgroundInteractive ? false : maskClosable}
      wrapClassName={backgroundInteractive ? `${wrapClassName} interactive-background-modal` : wrapClassName}
    />
  );
};

SympleteModal.defaultProps = {
  title: null,
  subtitle: null,
  justifyFooterContent: 'flex-end',
  alignFooterItems: 'unset',
  borderColor: '',
  backgroundInteractive: false,
  mask: true,
  maskClosable: true,
  wrapClassName: '',
  noCloseIcon: false,
};

const {
  string,
  bool,
} = PropTypes;

SympleteModal.propTypes = {
  justifyFooterContent: string,
  alignFooterItems: string,
  title: string,
  subtitle: string,
  borderColor: string,
  backgroundInteractive: bool,
  mask: bool,
  maskClosable: bool,
  wrapClassName: string,
  noCloseIcon: bool,
};

export default SympleteModal;
