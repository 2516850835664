import { createSelector } from 'reselect';

import {
  optimizedEmailsActions,
} from 'store/actions/emails';

export default createSelector(
  (state, id) => ({
    items: state.emails.emails,
    id,
  }),
  ({ items, id }) => {
    if (!id) return null;

    const dataObject = items[id];

    if (!dataObject) {
      optimizedEmailsActions.getEmailById(id);
    }

    return dataObject || {};
  },
);
