/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';

import withInlineLabel from 'shared/hocs/withInlineLabel';

import {
  Root,
} from './styledItems';

const DateArea = (props) => {
  const {
    value,
    label,
  } = props;

  return (
    <Root>
      <span>
        <Typography
          weight="bold"
          tag="span"
          noMargin
        >
          {`${label}: `}
        </Typography>
        {
          value
            ? (
              <Typography
                variant="body"
                tag="span"
                noMargin
              >
                {`${moment(value).diff(moment(), 'days')} days (${moment(value).format('dddd, MMMM D')})`}
              </Typography>
            )
            : (
              <Typography
                variant="body"
                tag="span"
                noMargin
              >
                Not specified
              </Typography>
            )

        }
      </span>
    </Root>
  );
};

const {
  string,
} = PropTypes;

DateArea.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
};

export default withInlineLabel(DateArea);
