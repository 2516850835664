import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import {
  IconWrapper,
} from './styledItems';

const DecoratedFieldWrapper = (props) => {
  const {
    iconName,
    iconType,
    iconMarginTop,
    iconWrapperJustify,
    children,
    align,
    size,
    variant,
  } = props;

  return (
    <Flex
      alignItems={align || 'center'}
      fullWidth
      style={{ minWidth: 0 }}
    >
      {
        iconName && (
          <IconWrapper
            size={size}
            iconMarginTop={iconMarginTop}
            iconWrapperJustify={iconWrapperJustify}
          >
            <Icon
              name={iconName}
              type={iconType}
              color={variant}
              size={size}
            />
          </IconWrapper>
        )
      }
      <FlexItem flex={1}>
        {children}
      </FlexItem>
    </Flex>
  );
};

DecoratedFieldWrapper.defaultProps = {
  align: 'center',
  iconType: 'fontawesome',
  iconName: null,
  iconMarginTop: null,
  size: 'lg',
  variant: null,
  iconWrapperJustify: 'center',
};

const {
  element,
  oneOf,
  string,
} = PropTypes;

DecoratedFieldWrapper.propTypes = {
  /**
   * input components
   */
  children: element.isRequired,
  /**
   * fortawesome icon name
   * no decorator will show if not provided
   */
  iconName: string,
  /**
   * Icon type props
   */
  iconType: string,
  /**
   * align children and icon (as in flex `align-items`)
   */
  align: oneOf(['center', 'flex-start', 'flex-end', 'baseline']),
  /**
   * icon size
   * sm - 18px
   * lg - 26px
   */
  size: oneOf(['xxxs', 'xxs', 'xs', 'sm', 'lg']),
  /**
   * icon wrapper margin-top
   */
  iconMarginTop: string,
  /**
   * variant - semantic color (is overridden by `iconColor`)
   */
  variant: oneOf([
    'primary',
    'additional',
    'ghost',
    'link',
    'success',
    'error',
    'highlight',
    'disabled',
    'attention',
    'hover',
    'contrast',
    'selected',
  ]),
  /**
   * icon wrapper justify-content
   */
  iconWrapperJustify: string,
};

export default DecoratedFieldWrapper;
