import jwtDecode from 'jwt-decode';

const demoUsers = process.env.REACT_APP_DEMO_USERS
  ? JSON.parse(process.env.REACT_APP_DEMO_USERS)
  : {}

const isDemoAccount = (_token = null) => {
  const token = _token || localStorage.getItem('token');

  const { userLogin } = jwtDecode(token);

  return !!demoUsers[userLogin];
};

export default isDemoAccount;
