import {
  SET_NEW_COUNTER_MODAL_OPEN,
} from 'store/actionTypes/realtorOpportunities';

const openNewCounterModal = (offerId, rootOfferId, onAfterSubmit = null) => ({
  type: SET_NEW_COUNTER_MODAL_OPEN,
  payload: { offerId, rootOfferId, onAfterSubmit },
});

export default openNewCounterModal;
