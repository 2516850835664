import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import {
  deleteFollowUpReminder,
  getFollowUpReminderById,
} from 'store/actions/followUpReminders';

import EditFollowUpModal from './EditFollowUpModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      editFollowUpReminder,
    },
  } = state;

  const reminderId = editFollowUpReminder?.contentOptions?.reminderId;

  return {
    isVisible: editFollowUpReminder?.isVisible,
    reminderId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('editFollowUpReminder')()),
  deleteReminder: async (id) => {
    await dispatch(deleteFollowUpReminder(id));
    dispatch(getFollowUpReminderById(id));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  closeModal: dispatchProps.closeModal,
  deleteReminder: async () => {
    await dispatchProps.deleteReminder(stateProps.reminderId);
    dispatchProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditFollowUpModal);
