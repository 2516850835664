/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
import connectReducers from 'store/connectReducers.js';
import * as AUTH from 'store/actions/auth';
import * as COMPANY from 'store/actions/MyCompany';

const initialState = {
  activeTab: 1,
  changeModal: false,
  changeType: null,
  database: null,
  error: null,
  isAuthenticated: !!localStorage.getItem('token'),
  rememberMe: true,
  isFetching: false,
  loggedUser: null,
  permissionModal: false,
  credentialsModal: false,
  roleModal: false,
  recoverModal: false,
  showCaptchaModal: false,
  tempToken: null,
  twoFactor: {},
  userReset: {
    email: null,
    resetKey: null,
  },
  workspaces: [],
  introModal: false,
  onboardingModal: false,
  externalLogin: null,
  externalPassword: null,
};

const reducers = {
  [AUTH.LOGIN_REQUEST]: (state, action) => ({
    ...state,
    isAuthenticated: false,
    rememberMe: action.rememberMe
  }),

  [AUTH.LOGIN_SUCCESS]: (state) => ({
    ...state,
    isAuthenticated: true,
    tempToken: null,
    error: null,
    twoFactor: {},
  }),

  [AUTH.LOGIN_FAILURE]: (state, action) => ({
    ...state,
    isAuthenticated: false,
    tempToken: null,
    error: action.payload,
  }),

  [AUTH.LOGIN_ERROR_CLEAR]: (state) => ({
    ...state,
    error: null,
    twoFactor: {},
  }),

  [AUTH.GET_ME_SUCCESS]: (state, action) => ({
    ...state,
    loggedUser: action.user,
  }),

  [AUTH.SHOW_TWO_FACTOR]: (state, action) => ({
    ...state,
    twoFactor: action.twoFactor,
  }),

  [AUTH.LOGOUT_SUCCESS]: (state) => ({
    ...state,
    isAuthenticated: false,
    workspaces: [],
    activeTab: 1,
    loggedUser: null,
    error: null,
    twoFactor: {},
  }),

  [AUTH.SET_TEMP_TOKEN]: (state, action) => ({
    ...state,
    tempToken: action.tempToken,
  }),

  [AUTH.SHOW_WORKSPACES]: (state, action) => ({
    ...state,
    workspaces: action.workspaces,
    activeTab: action.activeTab,
  }),

  [AUTH.HIDE_WORKSPACES]: (state) => ({
    ...state,
    workspaces: [],
    activeTab: initialState.activeTab,
  }),

  [AUTH.TRIGGER_RECOVER_MODAL]: (state, action) => ({
    ...state,
    recoverModal: !state.recoverModal,
    userReset: {
      email: action.payload,
      resetKey: null,
    },
  }),

  [AUTH.TRIGGER_CHANGE_MODAL]: (state, action) => ({
    ...state,
    changeModal: !state.changeModal,
    changeType: action.change,
  }),

  [AUTH.TRIGGER_FETCHING]: (state) => ({
    ...state,
    isFetching: !state.isFetching,
  }),

  [AUTH.TRIGGER_PERMISSION_MODAL]: (state, action) => ({
    ...state,
    permissionModal: action.permissionModal || !state.permissionModal,
  }),

  [AUTH.TRIGGER_CREDENTIALS_MODAL]: (state, action) => ({
    ...state,
    credentialsModal: action.credentialsModal || !state.credentialsModal,
  }),

  [AUTH.TRIGGER_ROLE_MODAL]: (state, action) => ({
    ...state,
    roleModal: action.roleModal || !state.roleModal,
  }),

  [COMPANY.GET_LOGGED_USER_SUCCESS]: (state, action) => ({
    ...state,
    loggedUser: action.user,
  }),

  [AUTH.SELECT_WORKSPACE]: (state, action) => ({
    ...state,
    database: action.database,
  }),

  [AUTH.TRIGGER_INTRO_MODAL]: (state, action) => ({
    ...state,
    introModal: action.introModal || !state.introModal,
  }),

  [AUTH.TRIGGER_ONBOARDING_MODAL]: (state, action) => ({
    ...state,
    onboardingModal: action.onboardingModal || !state.onboardingModal,
  }),

  [AUTH.TRIGGER_CAPTCHA_MODAL]: (state) => ({
    ...state,
    showCaptchaModal: !state.showCaptchaModal,
  }),

  [AUTH.CHANGE_AUTH_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    loggedUser: {
      ...state.loggedUser,
      useravailability: action.payload.useravailability,
    },
  }),

  [AUTH.GET_EMPLOYEE_ACTIVITY]: (state, action) => ({
    ...state,
    loginsActivity: action.result,
  }),

  [AUTH.SET_USER_RESET]: (state, action) => ({
    ...state,
    userReset: action.payload,
  }),

  [AUTH.SET_EXTERNAL_CREDENTIALS]: (state, action) => ({
    ...state,
    externalLogin: action.login,
    externalPassword: action.password,
  })
};

function auth(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default auth;
