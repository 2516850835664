import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import CheckboxTag from 'sections/Contacts/components/CheckboxTag';

const SpecialStatuses = (props) => {
  const {
    onChange,
    isVip,
    isPastClient,
  } = props;

  return (
    <Flex spacing={2}>
      <CheckboxTag
        value={isVip}
        onChange={onChange('is_vip')}
        tag="vip"
      />
      <CheckboxTag
        value={isPastClient}
        onChange={onChange('is_past_client')}
        tag="past client"
      />
    </Flex>
  );
};

SpecialStatuses.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

SpecialStatuses.propTypes = {

};

export default SpecialStatuses;
