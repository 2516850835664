import React from 'react';

import htmlParser from 'react-html-parser';
// eslint-disable-next-line import/no-cycle
import ObjectLink from 'shared/components/ObjectLink';
import URLThumbnails from 'shared/components/URLThumbnails';

// disabling this rulke because this is how html parser works
// eslint-disable-next-line consistent-return
const transform = (node) => {
  const isLink = node.type === 'tag' && node.name === 'a';
  if (isLink) {
    const isObjectLink = node.attribs.class === 'objectLink';
    const isClassLink = node.attribs.class === 'simpleLink';
    if (isObjectLink) {
      return (
        <ObjectLink
          url={node.attribs.href}
        />
      );
    }

    if (isClassLink) {
      return (
        <URLThumbnails
          url={node.attribs.href}
        />
      );
    }
  }
};

const createHtmlMarkup = (string) => {
  const parsedText = htmlParser(string, {
    transform,
  });

  return parsedText;
};

export default createHtmlMarkup;
