import { connect } from 'react-redux';

import stableStringify from 'json-stable-stringify';

import {
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import {
  optimizedOpportunityActions,
} from 'store/actions/realtorOpportunities';

import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import selectors from 'store/selectors';

import ToDoTab from './ToDoTab';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      leadsObjectsByFilter,
      leadsObjectsCountByFilter,
    },
    realtorOpportunities: {
      realtorOpportunitiesByFilter,
      realtorOpportunitiesCountByFilter,
    },
    realtorTransactions: {
      transactionsByFilter,
      transactionsByFilterCount,
    },
  } = state;

  const { filterId } = selectors.realtorLeads.leadsObjectsFilter(state);

  const opportunitiesNewViewingsFilterId = stableStringify({
    sort_by: 'priority_filter',
    stage: ['new', 'viewings'],
  });

  const opportunitiesOffersFilterId = stableStringify({
    sort_by: 'priority_filter',
    stage: 'offers',
  });

  const data = [
    {
      title: 'Leads',
      description: 'Keep following up!',
      items: leadsObjectsByFilter[filterId],
      itemsCount: leadsObjectsCountByFilter[filterId],
      objectKey: 'leads',
      loadMore: (offset, limit) => {
        optimizedRealtorLeadsActions.getAllLeadsObjectsForStoreFilter(offset, limit);
      },
    },
    {
      title: 'Prospects & Clients',
      description: 'Let’s get these over the line!',
      items: realtorOpportunitiesByFilter?.[opportunitiesNewViewingsFilterId],
      itemsCount: realtorOpportunitiesCountByFilter?.[opportunitiesNewViewingsFilterId],
      loadMore: (offset, limit) => {
        optimizedOpportunityActions.getOpportunitiesForFilter(opportunitiesNewViewingsFilterId, {
          sort_by: 'priority_filter',
          stage: ['new', 'viewings'],
        }, offset, limit);
      },
      objectKey: 'prospectClients',
    },
    {
      title: 'Offers',
      description: 'These often require fast turnaround!',
      items: realtorOpportunitiesByFilter?.[opportunitiesOffersFilterId],
      itemsCount: realtorOpportunitiesCountByFilter?.[opportunitiesOffersFilterId],
      loadMore: (offset, limit) => {
        optimizedOpportunityActions.getOpportunitiesForFilter(opportunitiesOffersFilterId, {
          sort_by: 'priority_filter',
          stage: 'offers',
        }, offset, limit);
      },
      objectKey: 'offers',
    },
    {
      title: 'Transactions',
      description: 'Home stretch!',
      items: transactionsByFilter?.toDoPageKanban,
      itemsCount: transactionsByFilterCount?.toDoPageKanban,
      loadMore: (offset, limit) => {
        optimizedRealtorTransactionsActions.getInfiniteScrollTransactionsForFilter('toDoPageKanban', {
          sort_by: 'price',
        }, offset, limit);
      },
      objectKey: 'transactions',
    },
  ];

  return {
    columnData: data,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ToDoTab);
