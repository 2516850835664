import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getLeads from './getLeads';
import getLeadById from './getLeadById';
import getLeadsForFilter from './getLeadsForFilter';
import getAllLeadsObjectsForFilter from './getAllLeadsObjectsForFilter';
import getAllLeadsObjectsForStoreFilter from './getAllLeadsObjectsForStoreFilter';
import getLeadsForStoreFilter from './getLeadsForStoreFilter'; // eslint-disable-line import/no-cycle

import getOverallLeadsSummary from './getOverallLeadsSummary';

import createLead from './createLead';
import createLeadFromNewLeadForm from './createLeadFromNewLeadForm'; // eslint-disable-line import/no-cycle

import setSelectedLead from './setSelectedLead';
import setSelectedLeadRows from './setSelectedLeadRows';
import setSelectedLeadProperty from './setSelectedLeadProperty';
import setLeadViewers from './setLeadViewers';

import claimLead from './claimLead';
import claimSelectedLead from './claimSelectedLead';
import unclaimSelectedLead from './unclaimSelectedLead';

import updateLead from './updateLead';
import updateLeadPermissions from './updateLeadPermissions';
import updateSelectedLead from './updateSelectedLead';
import updateSelectedLeadPermissions from './updateSelectedLeadPermissions';

import getSelectedLeadChangelogAllTime from './getSelectedLeadChangelogAllTime';

import createSourceFromNewLeadForm from './createSourceFromNewLeadForm';

import deleteLead from './deleteLead';
import deleteSelectedLead from './deleteSelectedLead';

import searchLeads from './searchLeads';

import removeLeadRelations from './removeLeadRelations';

export { createSourceFromNewLeadForm };

export { getLeadsForFilter };
export { getLeadsForStoreFilter };
export { getLeads };
export { getLeadById };

export { createLead };
export { createLeadFromNewLeadForm };
export { getOverallLeadsSummary };

export { setSelectedLead };
export { setSelectedLeadRows };
export { setSelectedLeadProperty };
export { setLeadViewers };

export { claimLead };
export { claimSelectedLead };
export { unclaimSelectedLead };

export { updateLead };
export { updateLeadPermissions };
export { updateSelectedLead };
export { updateSelectedLeadPermissions };

export { deleteLead };
export { deleteSelectedLead };

export { searchLeads };

export { removeLeadRelations };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorLeadsActions = {
  getAllLeadsObjectsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getAllLeadsObjectsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getAllLeadsObjectsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getAllLeadsObjectsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getLeadsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getLeadsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getLeadById: memoizeDebounce(
    (leadId) => store.dispatch(getLeadById(leadId)),
    debounceDelay,
    debounceOptions,
  ),
  getOverallLeadsSummary: memoizeDebounce(
    () => store.dispatch(getOverallLeadsSummary()),
    debounceDelay,
    debounceOptions,
  ),
  getLeadsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getLeadsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getSelectedLeadChangelogAllTime: memoizeDebounce(
    () => store.dispatch(getSelectedLeadChangelogAllTime()),
    debounceDelay,
    debounceOptions,
  ),
};
