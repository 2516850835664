import styled from 'styled-components';

export const LeadTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Root = styled.section`
  padding: 10px 20px;
`;

export default {
  LeadTitleWrapper,
  Root,
};
