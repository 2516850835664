/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { Controller } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import withInlineLabel from 'shared/hocs/withInlineLabel';

const DateField = (props) => {
  const {
    fieldName,
    control,
    setValue,
    defaultValue,
  } = props;

  const handleDaysInputChange = useCallback((value) => {
    const newDateValue = moment().add(value, 'days').format();
    setValue(fieldName, newDateValue, { shouldValidate: true });
  }, [
    setValue,
  ]);

  useEffect(() => {
    handleDaysInputChange(defaultValue);
  }, [
    defaultValue,
  ]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        const numberValue = field.value
          ? moment(moment(field.value).format('YYYY-MM-DD'))
              .diff(moment(moment().format('YYYY-MM-DD')), 'days')
          : 1;

        return (
          <Flex
            spacing={1}
            alignItems="center"
            fullWidth
          >
            <FlexItem xs={6}>
              <Textfield
                type="number"
                value={numberValue}
                onChange={handleDaysInputChange}
                min={0}
              />
            </FlexItem>
            <Typography
              variant="body"
              noMargin
            >
              {`days (${moment(field.value).format('dddd, MMMM D')})`}
            </Typography>
          </Flex>
        );
      }}
    />
  );
};

DateField.defaultProps = {
  defaultValue: 0,
};

const {
  func,
  shape,
  string,
  number,
} = PropTypes;

DateField.propTypes = {
  control: shape().isRequired,
  setValue: func.isRequired,
  fieldName: string.isRequired,
  defaultValue: number,
};

export default withInlineLabel(DateField);
