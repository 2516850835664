import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Wrapper } from './styledItems';

const LogEventDateInput = (props) => {
  const {
    handleChange,
    value,
  } = props;

  const onChange = useCallback((dateTime) => {
    const formattedValue = moment(dateTime).format();
    handleChange(formattedValue || null);
  }, [
    handleChange,
  ]);

  const dateValue = value ? moment(value) : null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        <DateTimePicker
          label="Event date"
          value={dateValue}
          onChange={onChange}
          margin="dense"
          inputVariant="outlined"
          variant="inline"
          fullWidth
          showTodayButton
          minutesStep={5}
          // style={{ marginTop: 4 }}
        />
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

LogEventDateInput.defaultProps = {
  value: null,
};

const {
  func,
  string,
} = PropTypes;

LogEventDateInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
};

export default LogEventDateInput;
