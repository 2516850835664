import {
  useState,
  useCallback,
} from 'react';

const useFilters = (defaultFilters = {}) => {
  const [filters, setFilters] = useState(defaultFilters);

  const setValue = useCallback((key) => (value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  }, [
    filters,
  ]);

  const reset = () => {
    setFilters({});
  };

  return {
    filters,
    setValue,
    reset,
  };
};

export default useFilters;
