import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import {
  createSourceFromNewLeadForm,
} from 'store/actions/realtorLeads';

import { updateContact } from 'store/actions/realtorContacts';

import SourceDescriptionModal from './SourceDescriptionModal';

const mapStateToProps = (state) => {
  const contactId = selectors.realtorLeads.selected(state).contact_id;

  return {
    contactId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSave: async (contactId) => {
    const sourceId = await dispatch(createSourceFromNewLeadForm());
    dispatch(updateContact(contactId)({ source_id: sourceId }));
  },
  onCancel: () => {
    dispatch(setNewLeadFormField('sourceDescription')(null));
    dispatch(setNewLeadFormField('sourceType')(null));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  onSave: () => dispatchProps.onSave(stateProps.contactId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SourceDescriptionModal);
