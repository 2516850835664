import {
  SET_TASKS_FOR_TEAM,
} from 'store/actionTypes/tasks';

const setTasksForTeam = (teamId) => (tasks) => ({
  type: SET_TASKS_FOR_TEAM,
  payload: {
    teamId,
    tasks,
  },
});

export default setTasksForTeam;
