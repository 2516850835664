import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getCancelledOrders from './get';
import getCancelledOrderById from './getById';
import getCancelledOrderAttachments from './getAttachments';

import createCancelledOrder from './create';

import setSelectedCancelledOrder from './set';

import updateCancelledOrder from './update';

export { getCancelledOrders };
export { getCancelledOrderById };
export { getCancelledOrderAttachments };

export { createCancelledOrder };

export { setSelectedCancelledOrder };

export { updateCancelledOrder };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCancelledOrdersActions = {
  getCancelledOrderById: memoizeDebounce(
    (id) => store.dispatch(getCancelledOrderById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
