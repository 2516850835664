export const SET_INVOICES_LIST = 'SET_INVOICES_LIST';
export const SET_INVOICES_TOTAL = 'SET_INVOICES_TOTAL';
export const SET_INVOICES_LOADING_STATUS = 'SET_INVOICES_LOADING_STATUS';
export const SET_INVOICES_NEXT_MONTHLY_PAYMENT = 'SET_INVOICES_NEXT_MONTHLY_PAYMENT';
export const SET_INVOICES_NEXT_YEARLY_PAYMENT = 'SET_INVOICES_NEXT_YEARLY_PAYMENT';
export const SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT = 'SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT';
export const SET_INVOICES_LICENSES_TO_BE_REVOKED = 'SET_INVOICES_LICENSES_TO_BE_REVOKED';

export default {
  SET_INVOICES_LIST,
  SET_INVOICES_TOTAL,
  SET_INVOICES_LOADING_STATUS,
  SET_INVOICES_NEXT_MONTHLY_PAYMENT,
  SET_INVOICES_NEXT_YEARLY_PAYMENT,
  SET_INVOICES_PREVIOUS_MONTHLY_PAYMENT,
  SET_INVOICES_LICENSES_TO_BE_REVOKED,
};
