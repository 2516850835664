import React, {
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import ClientCard from 'shared/components/UI/RealtorObjectCards/ClientCard';
import TransactionCard from 'shared/components/UI/RealtorObjectCards/TransactionCard';
import ProspectCard from 'shared/components/UI/RealtorObjectCards/ProspectCard';
import Typography from 'ui/Typography';

import emptyPriorities from 'assets/images/realtor/empty-priorities.svg';

import {
  Wrapper,
  EmptyImage,
  EmptyItemsContainer,
} from './styledItems';

const objectCards = {
  transaction: (id) => <TransactionCard fullCardInformation transactionId={id} />,
  client: (id) => <ClientCard variant="viewed" clientId={id} />,
  prospect: (id) => <ProspectCard variant="viewed" prospectId={id} />,
};

const PrioritiesObjects = (props) => {
  const {
    getPrioritiesObjects,
    prioritiesObjects,
  } = props;

  useEffect(() => {
    getPrioritiesObjects();
  }, []);

  return !isEmpty(prioritiesObjects) ? (
    <Wrapper>
      {
        prioritiesObjects.map(({ object_id, object_type }) => objectCards[object_type](object_id))
      }
    </Wrapper>
  ) : (
    <EmptyItemsContainer>
      <EmptyImage
        src={emptyPriorities}
        aria-hidden="true"
        alt=""
      />
      <Typography
        variant="title2"
        weight="normal"
        align="center"
        noMargin
      >
        No current priorities
      </Typography>
    </EmptyItemsContainer>
  );
};

const {
  arrayOf,
  func,
  number,
  string,
  shape,
} = PropTypes;

PrioritiesObjects.propTypes = {
  getPrioritiesObjects: func.isRequired,
  prioritiesObjects: arrayOf(
    shape({
      object_id: number,
      object_type: string,
    }),
  ).isRequired,
};

export default PrioritiesObjects;
