import {
  SET_REALTOR_PROSPECT,
} from 'store/actionTypes/realtorProspects';

const setProspect = (prospect) => ({
  type: SET_REALTOR_PROSPECT,
  payload: prospect,
});

export default setProspect;
