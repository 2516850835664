/* eslint-disable import/no-cycle, no-undef */
import { createSelector } from 'reselect';

import selectors from 'store/selectors';

const lanes = {
  interestedParties: {
    name: 'Interested Buyers',
    getName: (list) => `${list?.items?.length} | Interested Buyers`,
    // getSubheading: (list) => `${list?.items?.length ? `${list?.items?.length} Total` : 'None'}`,
    status: 1,
    canAdd: true,
    getFilter: () => ({}),
  },
  showingsUpcoming: {
    name: 'Upcoming Showings',
    getName: (list) => `${list?.items?.length} | Upcoming Showings`,
    // getSubheading: (list) => `${list?.items?.length ? `${list?.items?.length} Total` : 'None'}`,
    status: 2,
    canAdd: true,
    getFilter: () => ({
      showing_status: 'upcoming',
    }),
  },
  showingsCompleted: {
    name: 'Completed Showings',
    getName: (list) => `${list?.items?.length} | Completed Showings`,
    // getSubheading: (list) => `${list?.items?.length ? `${list?.items?.length} Total` : 'None'}`,
    status: 2,
    canAdd: true,
    getFilter: () => ({
      showing_status: 'completed',
    }),
  },
  offers: {
    name: 'Offers',
    getName: (list) => `${list?.items?.length} | Offers`,
    // getSubheading: (list) => `${list?.items?.length ? `${list?.items?.length} Total` : 'None'}`,
    status: 3,
    canAdd: true,
    getFilter: () => ({}),
  },
  // sold: {
  //   name: 'Sold',
  //   // getSubheading: (list) => `${list?.items?.length ? `${list?.items?.length} Total` : 'None'}`,
  //   status: 4,
  //   getFilter: () => ({}),
  // },
};

const laneOrder = [
  'interestedParties',
  'showingsUpcoming',
  'showingsCompleted',
  'offers',
  // 'sold',
];

export default (propertyId) => createSelector(
  (state) => state.realtorMatchingProperties.matchingPropertiesByFilter,
  (state) => state.realtorMatchingProperties.matchingPropertiesCountByFilter,
  (
    matchingPropertiesByFilter,
    matchingPropertiesCountByFilter,
  ) => laneOrder.map((id) => {
    const {
      name,
      getName,
      getSubheading,
      status,
      canAdd,
      getFilter,
    } = lanes[id];

    const filter = selectors.realtorMatchingProperties.filter({
      property_id: propertyId,
      status,
      ...getFilter(),
    });

    const list = selectors.realtorMatchingProperties.byFilter({
      property_id: propertyId,
      status,
      ...getFilter(),
    })(
      // offset,
      // limit
    )({
      realtorMatchingProperties: {
        matchingPropertiesByFilter,
        matchingPropertiesCountByFilter,
      },
    });

    const laneData = {
      id,
      name: getName
        ? getName(list)
        : name,
      filterId: filter.filterId,
      list: list.items.map((item) => ({
        id: item,
        matchId: item,
        propertyId,
        lane: id,
      })),
      subheading: getSubheading
        ? getSubheading(list)
        : null,
      listHasMore: list.count > list.items?.length,
      loadMore: () => {
        optimizedMatchingPropertiesActions.get(
          filter.filterId,
          filter.filter,
          list.length,
        );
      },
      canAdd,
    };

    return laneData;
  }),
);
