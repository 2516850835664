import React, {
  useEffect,
  useContext,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import KPIs from 'sections/DataImport/modules/KPIs';

import CurrentStep from './CurrentStep';
import Controls from './Controls';

const ImportWizard = (props) => {
  const {
    onEnd,
  } = props;

  const context = useContext(DataImportContext);

  const {
    currentStep,
    previewDataSource,
    // setCurrentFile,
    // importSteps,
    // setWizardStep,
    // setPreviewTableData,
    resetWizard,
  } = context;

  const showKPIs = useMemo(() => currentStep.showKPIs(context), [
    currentStep,
    previewDataSource,
  ]);

  useEffect(() => () => {
    resetWizard();
    // setCurrentFile(null);
    // setWizardStep(importSteps['object type']);
    // setPreviewTableData([], []);
  }, []);

  return (
    <Flex
      flexDirection="column"
      spacing={2}
    >
      {
        showKPIs && <KPIs />
      }
      <CurrentStep />
      <Controls onEnd={onEnd} />
    </Flex>
  );
};

const {
  func,
} = PropTypes;

ImportWizard.propTypes = {
  onEnd: func.isRequired,
};

export default ImportWizard;
