import {
  SET_CUSTOMER_INVOICE,
} from 'store/actionTypes/customerInvoices';

const setInvoice = (payload) => ({
  type: SET_CUSTOMER_INVOICE,
  payload,
});

export default setInvoice;
