import {
  SET_VIEWING_NOTES_MODAL_OPEN,
} from 'store/actionTypes/propertyViewings';

const openViewingNotesModal = (viewingId) => ({
  type: SET_VIEWING_NOTES_MODAL_OPEN,
  payload: { viewingId },
});

export default openViewingNotesModal;
