/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getLikeById from './getLikeById';
import updateLike from './updateLike';
import deleteLike from './deleteLike';
import addLikeRelations from './addLikeRelations';
import removeLikeRelations from './removeLikeRelations';
import createLike from './createLike';

export {
  getLikeById,
  updateLike,
  deleteLike,
  addLikeRelations,
  removeLikeRelations,
  createLike,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedLikesActions = {
  getLikeById: memoizeDebounce(
    (id) => store.dispatch(getLikeById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
