import useSteps from 'sections/DataImport/hooks/useSteps';

const steps = {
  'object type': {
    id: 'object type',
    title: 'Import CSV List',
    next: 'upload',
    isDisabledNext: ({ selectedTypes }) => !selectedTypes || selectedTypes?.length < 1,
    showKPIs: () => false,
  },
  upload: {
    id: 'upload',
    title: 'Import CSV List',
    next: 'match',
    prev: 'object type',
    isDisabledNext: ({ previewDataSource }) => !previewDataSource || previewDataSource?.length < 1,
    showKPIs: ({ previewDataSource }) => previewDataSource && previewDataSource?.length > 0,
  },
  match: {
    id: 'match',
    title: 'Match Heading',
    next: 'preview',
    prev: 'upload',
    showKPIs: () => true,
    isDisabledNext: ({ KPIs }) => {
      const disabled = KPIs?.mappingKPIs?.mapped < 1 && KPIs?.mappingKPIs?.notes < 1;

      return disabled;
    },
  },
  preview: {
    id: 'preview',
    title: 'Preview List',
    // next: 'merge',
    prev: 'match',
    showKPIs: () => true,
    isDisabledNext: ({ selectedRows }) => selectedRows?.length < 1,
  },
  // merge: {
  //   id: 'merge',
  //   title: 'Overwrite',
  //   prev: 'preview'
  // }
};

const useImportSteps = () => {
  const {
    currentStep,
    goToNext,
    goToPrev,
    setStep,
    reset,
  } = useSteps(steps, 'object type');

  return {
    currentStep,
    steps,
    goToNext,
    goToPrev,
    setStep,
    reset,
  };
};

export default useImportSteps;
