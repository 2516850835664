/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import SalePriceInput from 'shared/inputs/SalePriceInput';

const OfferAmountInput = (props) => {
  const {
    control,
    defaultValue,
  } = props;

  return (
    <Controller
      name="offerAmount"
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <SalePriceInput
          label="Offer amount $"
          {...field}
          fullWidth
          handleChange={field.onChange}
        />
      )}
    />
  );
};

const {
  shape,
  number,
} = PropTypes;

OfferAmountInput.propTypes = {
  control: shape().isRequired,
  defaultValue: number.isRequired,
};

export default OfferAmountInput;
