import { connect } from 'react-redux';

import Textfield from 'ui/Textfield';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Textfield);
