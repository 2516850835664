import { connect } from 'react-redux';

import {
  openArticle,
} from 'store/actions/articleDetail';

import {
  showSearchFilterModal,
  searchArticles,
  onSearchSuggestionArticleHover,
  getInitialSearchArticles,
} from 'store/actions/knowledgeBase';

import SearchFilter from './SearchFilter';

const mapStateToProps = (state) => ({
  searchSuggestions: state.knowledgeBase.searchSuggestions,
  kbSearchFilterData: state.knowledgeBase.kbSearchFilterData,
  suggestionHoverArticle: state.knowledgeBase.suggestionHoverArticle,
});

const mapDispatchToProps = (dispatch) => ({
  onShowSearchFilterModal: () => {
    dispatch(showSearchFilterModal());
  },
  searchArticles: (searchWord, filter) => {
    dispatch(searchArticles(searchWord, filter));
  },
  onSearchSuggestionArticleHover: (articleId) => {
    dispatch(onSearchSuggestionArticleHover(articleId));
  },
  getInitialSearchArticles: () => {
    dispatch(getInitialSearchArticles());
  },
  openArticle: (id) => {
    dispatch(openArticle(id));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
