import requests from 'api/requests';

import setCallLogs from './setCallLogs';
import setCallLogsForFilter from './setCallLogsForFilter';
import setCallLogsForFilterCount from './setCallLogsForFilterCount';

const getCallLogsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.calls.logs.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setCallLogs(response.data.items));
    dispatch(setCallLogsForFilter(filterId)(response.data.items));
    dispatch(setCallLogsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getCallLogsForFilter;
