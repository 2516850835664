import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import variables, { palette } from 'shared/components/CRM/UI/variables';

const TempIcon = (props) => {
  const {
    size,
    variant,
    inactive,
    className,
  } = props;

  const iconColor = !inactive && variant
    ? palette[variant]
    : palette.unchecked;

  return (
    <FontAwesomeIcon
      icon={variables.leadTempIcons[variant]}
      size={size}
      style={{ color: iconColor }}
      className={classnames({
        [className]: className,
      })}
    />
  );
};

TempIcon.defaultProps = {
  size: 'lg',
  inactive: false,
};

const {
  bool,
  oneOf,
} = PropTypes;

TempIcon.propTypes = {
  /**
   * lead temp variant
   */
  variant: oneOf(['cold', 'warm', 'hot']).isRequired,
  /**
   * icon size
   */
  size: oneOf(['sm', 'lg', 'xl']),
  /**
   * is inactive - if true, the icon will be greyed out
   */
  inactive: bool,
};

export default TempIcon;
