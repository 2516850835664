import styled from 'styled-components';

export const IndicatorWrapper = styled.div`
  padding: 24px 48px;
  display: flex;
  align-items: center;
  flex: ${(props) => props.flex || 1};
  :not(:last-child) {
    border-right: solid 1px ${(props) => props.theme?.palette?.delimiter || '#f4faff'};
  } 
`;

export default {
  IndicatorWrapper,
};
