import {
  SET_REALTOR_TRANSACTIONS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorTransactions';

const setTransactionsForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_TRANSACTIONS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setTransactionsForFilterCount;
