import { connect } from 'react-redux';

import selectors from 'store/selectors';

import MainLeadInfo from './MainLeadInfo';

const objectSelector = {
  propertyRequirement: selectors.propertyRequirements,
  property: selectors.properties,
};

const tempDict = {
  1: 'cold',
  2: 'warm',
  3: 'hot',
};

const mapStateToProps = (state, ownProps) => {
  const {
    objectId,
    type,
    leadId,
  } = ownProps;

  const {
    expected_price,
    budget_max,
    budget_min,
  } = objectSelector[type].byId(state, objectId);

  const {
    contact_id,
    temp,
  } = selectors.realtorLeads.byId(state, leadId);

  return {
    contactId: contact_id,
    temp: tempDict[temp],
    expectedPrice: expected_price,
    budgetMax: budget_max,
    budgetMin: budget_min,
  };
};

export default connect(mapStateToProps)(MainLeadInfo);
