import apiConnector, { endpoints } from 'api';

const checkIfAccountExists = async (email) => {
  const endpoint = `${endpoints.global.checkIfAccountExists}/${email}`;

  try {
    const response = await apiConnector.global.get(endpoint);

    return response;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default checkIfAccountExists;
