import { connect } from 'react-redux';

import LargeChatModal from 'shared/components/OA/LargeModal';

import {
  shrinkModal,
  closeAllModals,
} from 'store/actions/video-chat';

const mapStateToProps = (state) => {
  const {
    videoChat: {
      isLargeVideoChatOpen,
    },
  } = state;

  return {
    isVisible: isLargeVideoChatOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onShrink: () => {
    dispatch(shrinkModal());
  },
  onExit: () => {
    ownProps.leaveMeeting();
    dispatch(closeAllModals());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LargeChatModal);
