const PERMISSIONS = {
  'Company Settings': 1,
  Symplete: 2,
  Tasks: 4,
  HR: 8,
  'Customer Support': 16,
  Sales: 32,
  Cashflow: 64,
  Surveys: 128,
  CustomerDB: 256,
  'Contact Database': 512,
  Service: 1024,
};

export const PERMISSION_IDS = {
  'Company Settings': 0,
  Symplete: 1,
  Tasks: 2,
  HR: 3,
  'Customer Support': 4,
  Sales: 5,
  Cashflow: 6,
  Surveys: 7,
  CustomerDB: 8,
  'Contact Database': 9,
  Service: 10,
};

export default PERMISSIONS;
