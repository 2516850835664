import { connect } from 'react-redux';

import getEmployeeById from 'shared/utils/getEmployeeById';
import AssignListItem from './AssignListItem';

const mapStateToProps = (state, ownProps) => {
  const { user } = ownProps;

  /**
   * @todo It is bad practice to fetch data in mapStateToProps,
   * use react useEffect instead.
   */
  const employee = getEmployeeById(user) || {};

  return {
    name: `${employee.Name} ${employee.Surname}`,
    role: employee.Role,
    userId: user,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AssignListItem);
