import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useContactsImportInfo = ({ tokenId }) => {
  const [presentationContacts, setPresentationContacts] = useState([]);
  const [allContactsCount, setAllContactsCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getContactsImportInfo = async (tokenID) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requests.integrations.contacts.importInfo({ tokenId: tokenID });

      setPresentationContacts(response.data?.presentationContacts || []);
      setAllContactsCount(response.data?.allContactsCount || 0);
    } catch (newError) {
      setError(newError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tokenId) {
      getContactsImportInfo(tokenId);
    }
  }, [
    tokenId,
  ]);

  return {
    presentationContacts,
    allContactsCount,
    isLoading,
    error,
  };
};

export default useContactsImportInfo;
