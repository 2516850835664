/* eslint-disable react/prop-types */
// TODO - fix proptypes validation
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tabs, Badge } from 'antd';

import Button from 'ui/Button';
import Typography from 'ui/Typography';

import './CommentTabs.scss';

const { TabPane } = Tabs;
const rootClass = 'comment-tabs';

class CommentTabs extends Component {
  onTabClick = (tabKey) => {
    const { onTabClick } = this.props;
    onTabClick(+tabKey);
  }

  render() {
    const {
      className,
      selectedTab,
      unreadMessages = [],
      children,
      handleCallLogClick,
      tabs,
      mainTabName,
      documents,
      selectedType,
    } = this.props;

    const renderNoComments = (
      <div
        className={`${rootClass}__no-comment`}
      >
        <Typography
          variant="subtitle"
          color="descriptor-text"
        >
          No Comments yet
        </Typography>
      </div>
    );

    return (
      <div className={classnames(
        rootClass,
        {
          [className]: className,
        },
      )}
      >
        <Tabs
          onTabClick={this.onTabClick}
          className={`${rootClass}__tabs`}
          tabBarExtraContent={
            handleCallLogClick && (
              documents
                ? (
                  <Button
                    variant="tertiary"
                    onClick={handleCallLogClick}
                  >
                    DOCUMENTS
                  </Button>
                )
                : (
                  <Button
                    variant="tertiary"
                    onClick={handleCallLogClick}
                  >
                    Log New Contact
                  </Button>
                )
            )
          }
        >
          <TabPane
            tab={(
              <Badge count={unreadMessages.all} style={{ backgroundColor: '#1584ff' }}>
                <Typography
                  variant="subtitle"
                  color={selectedTab === 0 ? 'focus' : 'main-text'}
                >
                  {mainTabName}
                </Typography>
              </Badge>
            )}
            key={0}
          >
            {children || renderNoComments}
          </TabPane>
          {children && tabs.map((tab) => (
            <TabPane
              tab={(
                <Badge
                  count={unreadMessages[tab.name.toLowerCase()]}
                  style={{ backgroundColor: '#1584ff' }}
                >
                  <Typography
                    variant="subtitle"
                    color={selectedTab === tab.key ? 'focus' : 'main-text'}
                  >
                    {tab.name}
                  </Typography>
                </Badge>
              )}
              key={tab.key}
            >
              {children || renderNoComments}
            </TabPane>
          )
          )}
        </Tabs>
      </div>
    );
  }
}

const defaultTabs = [
  { name: 'Questions', key: 1 },
  { name: 'Actions', key: 2 },
  { name: 'Problems', key: 4 },
];

CommentTabs.defaultProps = {
  className: 'comment__list',
  unreadMessages: [],
  onTabClick: () => { },
  selectedTab: 0,
  tabs: defaultTabs,
  mainTabName: 'All Comments',
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

const tabShape = shape({
  name: string,
  key: number,
});

CommentTabs.propTypes = {
  className: string,
  mainTabName: string,
  unreadMessages: shape({}),
  onTabClick: func,
  selectedTab: number,
  tabs: arrayOf(tabShape),
};

export default CommentTabs;
