import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_OPEN,
  SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_CLOSED,
} from 'store/actionTypes/realtorMatchingProperties';

const initialState = {
  isVisible: false,
  matchingPropertyId: null,
  onSave: null,
};

const reducers = {
  [SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
    matchingPropertyId: action.payload.matchingPropertyId,
    onSave: action.payload.onSave,
  }),

  [SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_CLOSED]: (state) => initialState,
};

function matchingPropertyRejectionModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default matchingPropertyRejectionModal;
