import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { lighten, darken } from 'polished';

import Typography from 'ui/Typography';

const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px 8px;
  border-radius: 10px;
  background-color: ${({
    theme,
    color,
  }) => lighten(0.3, theme?.palette?.[color] || 'grey')};
`;

const StyledTypography = styled(Typography)`
  color: ${({
    theme,
    color,
  }) => darken(0.3, theme?.palette?.[color] || 'grey')};
`;

const sentimentColors = {
  positive: 'success',
  neutral: 'warning',
  negative: 'alert',
};

const sentiments = {
  1: 'positive',
  2: 'neutral',
  3: 'negative',
};

const Sentiment = (props) => {
  const {
    value,
  } = props;

  const sentimentValue = sentiments[value] || 'neutral';

  return (
    <Label
      color={sentimentColors[sentimentValue]}
    >
      <StyledTypography
        variant="title3"
        tag="span"
        weight={500}
        noMargin
        style={{ textTransform: 'uppercase' }}
        color={sentimentColors[sentimentValue]}
      >
        {sentimentValue}
      </StyledTypography>
    </Label>
  );
};

const {
  number,
} = PropTypes;

Sentiment.propTypes = {
  value: number.isRequired,
};

export default Sentiment;
