import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import ReminderItem from './ReminderItem';

const ReminderList = (props) => {
  const {
    reminders,
    onDeleteReminder,
  } = props;

  return (
    <Flex
      flexDirection="column"
      fullWidth
    >
      {
        Array.isArray(reminders) && reminders.map((reminder, index) => (
          <ReminderItem
            reminder={reminder}
            onDelete={onDeleteReminder(reminder?.id, reminder?.is_new, index)}
          />
        ))
      }
    </Flex>
  );
};

ReminderList.defaultProps = {

};

const {
  arrayOf,
  bool,
  func,
  number,
  string,
} = PropTypes;

ReminderList.propTypes = {
  reminders: arrayOf({
    id: number,
    title: string,
    comment: string,
    scheduled_date: string,
    is_repeat: bool,
    status: number,
  }).isRequired,
  onDeleteReminder: func.isRequired,
};

export default ReminderList;
