import { connect } from 'react-redux';

import {
  checkExistingContact,
} from 'store/actions/realtorContacts';

import PhoneInput from './PhoneInput';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkPhoneExistence: async (value) => {
    if (ownProps.noExistenceCheck) return null;

    let contact;

    if (ownProps.checkPhoneExistence) {
      contact = await ownProps.checkPhoneExistence(value);
    } else {
      contact = await dispatch(checkExistingContact('phone_primary')(value));
    }

    return contact;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInput);
