export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';
export const SET_CALLEE = 'SET_CALLEE';
export const SET_CALLER = 'SET_CALLER';
export const SET_IS_CALL_DECLINED = 'SET_IS_CALL_DECLINED';
export const SET_INVITED_EMAILS = 'SET_INVITED_EMAILS';
export const SET_MEETING_FOR_INVITATIONS = 'SET_MEETING_FOR_INVITATIONS';

export default {
  SET_ONLINE_USERS,
  SET_CALLEE,
  SET_CALLER,
  SET_IS_CALL_DECLINED,
  SET_INVITED_EMAILS,
  SET_MEETING_FOR_INVITATIONS,
};
