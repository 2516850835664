import {
  SET_REALTOR_MATCHING,
} from 'store/actionTypes/realtorMatched';

const setMatching = (matching) => ({
  type: SET_REALTOR_MATCHING,
  payload: matching,
});

export default setMatching;
