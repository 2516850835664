import React from 'react';
import PropTypes from 'prop-types';
import { desiredOutcomeType } from 'oa/types/index';
import Todos from 'oa/components/Todos/index';

const DesiredOutcomes = (props) => {
  const {
    addDesiredOutcome,
    toggleDesiredOutcome,
    desiredOutcomes,
  } = props;

  return (
    <div className="meeting-agenda-modal__desired-outcomes">
      <h3 className="meeting-agenda-modal__secondary-header">Agenda</h3>

      <Todos
        todos={desiredOutcomes}
        addTodo={addDesiredOutcome}
        toggleTodo={toggleDesiredOutcome}
      >
        <Todos.CreationForm />

        <Todos.List>
          {desiredOutcomes.map((item, index) => (
            <Todos.Item
              key={item.id}
              id={item.id}
              name={item.name}
              index={index}
              isCompleted={item.isCompleted}
            />
          ))}
        </Todos.List>
      </Todos>
    </div>
  );
};

DesiredOutcomes.propTypes = {
  addDesiredOutcome: PropTypes.func.isRequired,
  toggleDesiredOutcome: PropTypes.func.isRequired,
  desiredOutcomes: PropTypes.arrayOf(desiredOutcomeType).isRequired,
};

export default DesiredOutcomes;
