/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

const OptionsCell = (props) => {
  const {
    remove,
    openProfile,
  } = props;

  const onRemove = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    remove();
    closeMenu();
  }, [
    remove,
  ]);

  const onViewProfile = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    openProfile();
    closeMenu();
  }, [
    openProfile,
  ]);

  return (
    <OptionsMenu
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {
        ({ closeMenu }) => (
          <>
            <MenuItem onClick={onRemove(closeMenu)}>
              Remove
            </MenuItem>
            <MenuItem onClick={onViewProfile(closeMenu)}>
              View Profile
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

const {
  func,
} = PropTypes;

OptionsCell.propTypes = {
  remove: func.isRequired,
  openProfile: func.isRequired,
};

export default OptionsCell;
