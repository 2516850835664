import apiConnector, { endpoints } from 'api';

const createGroupChat = async () => {
  const endpoint = endpoints.client.chat.general;

  const result = await apiConnector.client.post(endpoint, { chatType: 2 });
  return result;
};

export default createGroupChat;
