import React, {
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import requests from 'api/requests';

const Overwrite = (props) => {
  const {

  } = props;

  // const context = useContext(DataImportContext)

  // const {
  //   fieldMap,
  //   uploadedFileId,
  //   selectedTypes,
  // } = context

  // const importData = useCallback(async () => {
  //   await requests.dataImport.importFromFile(selectedTypes)(fieldMap)(uploadedFileId)
  // }, [
  //   fieldMap,
  //   uploadedFileId,
  //   selectedTypes,
  // ])

  const {
    isImportInProgress,
    importData,
  } = useContext(DataImportContext);

  return (
    <Flex
      fullWidth
      justifyContent="center"
    >
      <Button
        variant="primary"
        onClick={importData}
      >
        Import
      </Button>
    </Flex>
  );
};

Overwrite.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Overwrite.propTypes = {

};

export default Overwrite;
