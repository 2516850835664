import styled from 'styled-components';

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FullHeightContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 3px;
`;

export const CenteredWrapper = styled.div`
  margin: auto 0 auto 0;
`;

export default {
  FlexContainer,
  FullHeightContainer,
  CenteredWrapper,
};
