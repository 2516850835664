import requests from 'api/requests';

import setClients from './setClients';

const getClients = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.clients.get({
      offset,
      limit,
    });

    dispatch(setClients(response.data.clients));
  } catch (error) {
    console.error(error);
  }
};

export default getClients;
