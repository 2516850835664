import React from 'react';
import PropTypes from 'prop-types';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import {
  Root,
  ChildrenWrapper,
  Title,
} from './styledItems';

const SearchCategoryBlock = (props) => {
  const {
    title,
    iconName,
    iconType,
    children,
  } = props;

  return (
    <Root>
      <DecoratedFieldWrapper
        iconName={iconName}
        iconType={iconType}
        size="sm"
        noMargin
        variant="focus"
      >
        <Title
          variant="h6"
          color="descriptor-text"
          noMargin
        >
          {title}
        </Title>
      </DecoratedFieldWrapper>

      <ChildrenWrapper>
        {children}
      </ChildrenWrapper>
    </Root>
  );
};

SearchCategoryBlock.defaultProps = {
  children: null,
  iconType: 'feather',
};

const {
  arrayOf,
  string,
  node,
  oneOfType,
} = PropTypes;

SearchCategoryBlock.propTypes = {
  title: string.isRequired,
  iconName: string.isRequired,
  iconType: string,
  children: oneOfType([node, arrayOf(node)]),
};

export default SearchCategoryBlock;
