import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import ErrorBoundary from 'ui/ErrorBoundary';

import Table from 'ui/Table';

import columns from './columns';

const sortFieldDict = {
  created_at: 'created_at',
  createdAt: 'created_at',
};

const sortDirectionDict = {
  ascend: 'asc',
  descend: 'desc',
};

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} logs`;

const CallLogTable = (props) => {
  const {
    logList,
    getLogs,
    setFilterItem,
    itemsCount,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    onRowSelect,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getLogs(pageNumber, pageSize);

    return () => { // this is the same as componentWillUnmount
      onRowSelect([]);
    };
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onChange = useCallback((pagination, filter, sorter) => {
    const hasPageChaged = pagination && (pagination.current !== pageNumber || pagination.pageSize !== pageSize); // eslint-disable-line max-len
    const hasSortedChanged = sorter.field && (sortBy !== sortFieldDict[sorter.field] || sortDirection !== sortDirectionDict[sorter.order] || 'asc'); // eslint-disable-line max-len

    const shouldGetNewSortedItems = !hasPageChaged && hasSortedChanged;

    if (shouldGetNewSortedItems) {
      setFilterItem('sortBy')(sortFieldDict[sorter.columnKey]);
      setFilterItem('sortDirection')(sortDirectionDict[sorter.order] || 'asc');
      getLogs(pageNumber, pageSize);
      onRowSelect([]);
      setSelectedRowKeys([]);
    }
  }, [
    onRowSelect,
    setSelectedRowKeys,
    setFilterItem,
    getLogs,
  ]);

  const onSort = useCallback((key) => (order) => () => {
    setFilterItem('sortBy')(sortFieldDict[key]);
    setFilterItem('sortDirection')(sortDirectionDict[order] || 'asc');
    getLogs(pageNumber, pageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    onRowSelect,
    setSelectedRowKeys,
    setFilterItem,
    getLogs,
    pageNumber,
    pageSize,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setFilterItem('pageNumber')(newPageNumber);
    setFilterItem('pageSize')(newPageSize);
    getLogs(newPageNumber, newPageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    setFilterItem,
    getLogs,
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onClick: (event) => {
    },
    onMouseEnter: (event) => {
      setHoveredRecord(record.key);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    history,
    setHoveredRecord,
  ]);

  const allKeys = logList.map((item) => item.key);

  return (
    <ErrorBoundary>
      <Table
        key="leads-table"
        className="realtor-leads-table"
        dataSource={logList}
        columns={columns({ hoveredRecord, onSort })}
        onChange={onChange}
        pagination={{
          total: itemsCount,
          showTotal,
          onChange: onPaginationChange,
          current: pageNumber,
          pageSize,
        }}
        onRow={onRow}
        tableLayout="fixed"
        expandIconAsCell={false}
        expandIconColumnIndex={-1}
        size="middle"
        expandedRowKeys={allKeys}
      />
    </ErrorBoundary>
  );
};

CallLogTable.defaultProps = {
  logList: [],
  itemsCount: 0,
  pageNumber: 1,
  pageSize: 10,
  sortBy: 'id',
  sortDirection: 'desc',
  onRowSelect: () => {},
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
  string,
} = PropTypes;

CallLogTable.propTypes = {
  logList: arrayOf(shape({
    key: string,
    leadId: number,
    connectedItemId: number,
    status: string,
    personId: number,
    type: number,
    expectedSalePrice: number,
    expectedPurchasePrice: arrayOf(number),
    propertyType: arrayOf(string),
    location: number,
    createdAt: string,
    children: arrayOf(shape({
      key: string,
      leadId: number,
      connectedItemId: number,
      type: number,
      expectedSalePrice: number,
      expectedPurchasePrice: arrayOf(number),
      propertyType: arrayOf(string),
      location: number,
      createdAt: string,
    })),
  })),
  getLogs: func.isRequired,
  setFilterItem: func.isRequired,
  itemsCount: number,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: oneOf(['asc', 'desc']),
  onRowSelect: func,
};

export default CallLogTable;
