import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const searchClients = (filter) => async (dispatch) => {
  try {
    const response = await requests.realtor.clients.get(
      {
        ...filter,
      },
    );

    return response.data.items;
  } catch (error) {
    console.error(error);
  }
};

export default searchClients;
