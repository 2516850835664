import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import OfferDetails from './OfferDetails';

const mapStateToProps = (state) => {
  const {
    modals: {
      offerDetails,
    },
  } = state;

  return {
    isVisible: offerDetails?.isVisible,
    contentOptions: offerDetails?.contentOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('offerDetails')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
