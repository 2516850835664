import connectReducers from 'store/connectReducers';
// import { groupBy } from 'shared/utility';

import {
  // SET_DEAL,
  // SET_DEALS,
  // SET_SELECTED_DEAL,
  // SET_DEAL_ATTACHMENTS,
  SET_DEAL_PRODUCTS,
} from 'store/actionTypes/deals';

const initialState = {
  deals: {},
  selectedDeal: null,
  dealProducts: {

  },
};

// const listToObject = (items = []) => items.reduce((obj, item) => {
//   // param reassign is used in reduce by design
//   obj[item.id] = item; // eslint-disable-line no-param-reassign
//   return obj;
// }, {});

const reducers = {
  // [SET_SELECTED_DEAL]: (state, action) => ({
  //   ...state,
  //   selectedDeal: action.payload,
  // }),

  // [SET_SELECTED_DEAL]: (state, action) => ({
  //   ...state,
  //   selectedDeal: action.payload,
  // }),

  // [SET_DEAL]: (state, action) => ({
  //   ...state,
  //   deals: {
  //     ...state.deals,
  //     [action.payload.id]: action.payload,
  //   },
  // }),

  // [SET_DEALS]: (state, action) => ({
  //   ...state,
  //   deals: {
  //     ...state.deals,
  //     ...listToObject(action.payload),
  //   },
  // }),

  // [SET_DEAL_ATTACHMENTS]: (state, action) => ({
  //   ...state,
  //   deals: {
  //     ...state.deals,
  //     [action.payload.id]: {
  //       ...state.deals[action.payload.id],
  //       attachments: groupBy(action.payload.attachments, 'attachmentType'),
  //     },
  //   },
  // }),

  [SET_DEAL_PRODUCTS]: (state, action) => ({
    ...state,
    dealProducts: {
      ...state.dealProducts,
      [action.payload.dealId]: action.payload.products,
    },
  }),
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
