import sendTest from './sendTest';
import start from './start';
import pause from './pause';
import unpause from './unpause';

export default {
  sendTest,
  start,
  pause,
  unpause,
};
