import { connect } from 'react-redux';
import { isEmpty } from 'shared/utility';

import {
  getMe,
  receiveLogout,
} from 'store/actions/auth';

import {
  setEmployeeAvailability,
  getGeneral,
} from 'store/actions/MyCompany';

import {
  optimizedGetEmployees,
} from 'store/actions/MyCompany/common';

import {
  appendChatMessage,
} from 'store/actions/chat';

import {
  getCustomizationSettings,
} from 'store/actions/appCustomization';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import MainAppView from './MainAppView';

const mapStateToProps = (state) => {
  const {
    auth: {
      loggedUser,
      rememberMe,
    },
    leads: {
      showNewLeadAlert,
    },
  } = state;

  const isUserLogged = !isEmpty(loggedUser);
  const hasAccessToCompanySettings = checkLoggedEmployeePermissions.isManager('Company Settings');

  return {
    rememberMe,
    isUserLogged,
    showNewLeadAlert,
    hasAccessToCompanySettings,
    getEmployees: optimizedGetEmployees,
  };
};

const mapDispatchToProps = {
  getMe,
  receiveLogout,
  setEmployeeAvailability,
  appendChatMessage,
  getGeneral,
  getCustomizationSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainAppView);
