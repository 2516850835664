import { connect } from 'react-redux';

import { createPropertyViewingFromNewPropertyViewingForm } from 'store/actions/propertyViewings';

import {
  resetNewPropertyViewingForm,
} from 'store/actions/newRealtorPropertyViewingForm';

import Showing from './Showing';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSave: async (title,location,datetime,description,selectedEntryType) => {
    await dispatch(createPropertyViewingFromNewPropertyViewingForm());
    dispatch(resetNewPropertyViewingForm());

    // ownProps.onComplete();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Showing);
