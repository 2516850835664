import moment from 'moment';

import getFulfillmentChangelog from './getFulfillmentChangelog';

const getFulfillmentChangelogAllTime = (fulfillmentId) => (dispatch) => {
  try {
    dispatch(getFulfillmentChangelog(fulfillmentId)('2020-01-01', moment().add(1, 'd').format('YYYY-MM-DD')));
  } catch (error) {
    console.error(error);
  }
};

export default getFulfillmentChangelogAllTime;
