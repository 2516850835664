import { createSelector } from 'reselect';

import selectors from 'store/selectors'; // eslint-disable-line import/no-cycle

import stableStringify from 'json-stable-stringify';

import {
  optimizedRealtorMatchedActions,
} from 'store/actions/realtorMatched';

export default createSelector(
  (state, propertyId) => state.realtorMatched?.matchedByFilter?.[stableStringify({
    realtor_property_id: propertyId,
  })],
  (state, propertyId) => propertyId,
  (state) => (id) => selectors.realtorMatched.byId(id)(state),
  (
    list,
    propertyId,
    getMatchingById,
  ) => {
    if (!list) {
      optimizedRealtorMatchedActions.getMatchedForFilter(stableStringify({
        realtor_property_id: propertyId,
      }),
        {
          realtor_property_id: propertyId,
        });
    }

    let matches = [];

    if (Array.isArray(list)) {
      matches = list.map((id) => getMatchingById(id));
    }

    return matches;
  },
);
