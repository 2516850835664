import apiConnector, { endpoints } from 'api';

const claim = async (email, name) => {
  const endpoint = endpoints.client.realtorDirectory.claim;

  try {
    const result = await apiConnector.client.post(endpoint, { email, name });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default claim;
