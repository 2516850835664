/* eslint-disable */
// FlySoft
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Modal } from 'antd';
import './TicketKnowledgeBaseSearchModal.scss';
import SearchFilter from '../../../components/KnowledgeBase/SearchFilter';
import Typography from '../Typography';

const rootClass = 'new-component';

class TicketKnowledgeBaseSearchModal extends Component {
  render() {
    const {
      isVisible,
      className,
      onShowSearchStandaloneModal,
    } = this.props;

    return (
      <div className={classnames(
        rootClass,
        {
          [className]: className,
        },
      )}
      >
        <Modal
          visible={isVisible}
          footer={null}
          onCancel={onShowSearchStandaloneModal}
          className="kb-search-modal-searchbar"
        >
          <div className="search-modal-searchbar-container">
            <div className="text__container">
              <div className="text__title">Search Knowledge Base</div>
              <div className="text__content">You can use the search bar to find helpful articles in the Knowledge Base to resolve this ticket.</div>
            </div>
            <SearchFilter />
          </div>
        </Modal>
      </div>
    );
  }
}

export default TicketKnowledgeBaseSearchModal;

TicketKnowledgeBaseSearchModal.defaultProps = {

};

const {
  object,
  string,
} = PropTypes;

TicketKnowledgeBaseSearchModal.propTypes = {
  className: string,
};
