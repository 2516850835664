import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import Avatar from 'shared/components/UI/RealtorContactAvatar';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    avatar,
    avatarAcl,
    avatarUrl,
    name,
    surname,
  } = selectors.realtorContacts.basicById(state, contactId);

  const avatarImageSrc = (avatarUrl && avatarAcl === 'public-read')
    ? avatarUrl
    : getAvatarUrl(avatar);

  return {
    src: avatarImageSrc,
    firstName: name,
    lastName: surname,
  };
};

export default connect(mapStateToProps)(Avatar);
