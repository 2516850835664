import requests from 'api/requests';

import getContactById from './getContactById';
import getLinkedObjectsSummaries from './getLinkedObjectsSummaries';

const linkObjectsToContact = (contactId) => (objectsData) => async (dispatch) => {
  try {
    await requests.crm.contacts.linkObjectsToContact(contactId)(objectsData);
    dispatch(getContactById(contactId));
    dispatch(getLinkedObjectsSummaries(contactId));
  } catch (error) {
    console.error(error);
  }
};

export default linkObjectsToContact;
