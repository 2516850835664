import connectReducers from 'store/connectReducers';

import {
  SET_TRIGGERED_LICENSE_ITEM,
  CLEAR_TRIGGERED_LICENSE_ITEM,
  SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
  HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
  SHOW_DELETING_SITE_OWNER_LICENSE_WARNING,
  HIDE_DELETING_SITE_OWNER_LICENSE_WARNING,
  HIDE_ONLY_ONE_SITE_OWNER_WARNING,
  SHOW_ONLY_ONE_SITE_OWNER_WARNING,
} from 'store/actionTypes/license-management';

const initialState = {
  triggeredLicenseItem: null,
  deleteYourLicensePermissionWarningVisibility: false,
  deletingSiteOwnerLicenseWarningVisibility: false,
  onlyOneSiteOwnerWarningVisibility: false,
};

const reducers = {
  [HIDE_ONLY_ONE_SITE_OWNER_WARNING]: (state) => ({
    ...state,
    onlyOneSiteOwnerWarningVisibility: false,
  }),

  [SHOW_ONLY_ONE_SITE_OWNER_WARNING]: (state) => ({
    ...state,
    onlyOneSiteOwnerWarningVisibility: true,
  }),

  [HIDE_DELETING_SITE_OWNER_LICENSE_WARNING]: (state) => ({
    ...state,
    deletingSiteOwnerLicenseWarningVisibility: false,
  }),

  [SHOW_DELETING_SITE_OWNER_LICENSE_WARNING]: (state) => ({
    ...state,
    deletingSiteOwnerLicenseWarningVisibility: true,
  }),

  [HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING]: (state) => ({
    ...state,
    deleteYourLicensePermissionWarningVisibility: false,
  }),

  [SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING]: (state) => ({
    ...state,
    deleteYourLicensePermissionWarningVisibility: true,
  }),

  [SET_TRIGGERED_LICENSE_ITEM]: (state, action) => ({
    ...state,
    triggeredLicenseItem: action.payload,
  }),

  [CLEAR_TRIGGERED_LICENSE_ITEM]: (state) => ({
    ...state,
    triggeredLicenseItem: null,
  }),
};

const licenseManagement = (
  state = initialState,
  action,
) => connectReducers(
  reducers,
  state,
  action,
);

export default licenseManagement;
