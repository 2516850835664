import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyTab from './PropertyTab';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const image = selectors.properties.propertyMainImage(state, propertyId);

  return {
    image: image?.thumbUrl,
  };
};

export default connect(mapStateToProps)(PropertyTab);
