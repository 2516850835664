import {
  SET_CRM_CONTACT,
} from 'store/actionTypes/crm';

const setContact = (contact) => ({
  type: SET_CRM_CONTACT,
  payload: contact,
});

export default setContact;
