/* eslint-disable react/jsx-no-bind */

import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Button from 'ui/Button';

import PreviewModal from 'shared/AttachmentsV4/PreviewModal';

const Agreement = (props) => {
  const {
    file,
  } = props;

  if (!file) return '-';

  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const downloadAttachment = useCallback(() => {
    const a = document.createElement('a');
    a.href = file.url;
    a.download = true;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });

  const fileExtension = file?.name?.substring(file.name.lastIndexOf('.') + 1, file.name.length) || file.name;

  return (
    <>
      <Typography
        variant="cell"
        onClick={toggleModal}
        color="focus"
      >
        {file.name}
      </Typography>

      <PreviewModal
        isVisible={isVisible}
        onCancel={toggleModal}
        previewFileUrl={file.url}
        previewFileExtension={fileExtension}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={toggleModal}
            >
              Close
            </Button>
            <Button
              onClick={downloadAttachment}
              variant="primary"
              icon="download"
            >
              Download
            </Button>
          </>
        )}
      />
    </>
  );
};

Agreement.defaultProps = {
  file: null,
};

const {
  shape,
  string,
} = PropTypes;

Agreement.propTypes = {
  file: shape({
    uid: string,
    name: string,
    url: string,
  }),
};

export default Agreement;
