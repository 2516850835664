import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import PointerBox from 'shared/components/UI/PointerBox';
import OfferDetails from 'shared/components/OfferDetails';

import ContactCard from 'shared/modules/ContactCard';
import LessContactCard from 'shared/modules/LessContactCard';

const CounterBox = (props) => {
  const theme = useContext(ThemeContext);

  const {
    counterId,
    userRole,
    anchor,
    openNewCounterModal,
    openAcceptCounterModal,
    openCreateOfferDeclineReasonModal,
    contactId,
    agentId,
    contactRole,
    agentRole,
  } = props;

  return (
    <PointerBox
      anchorElement={`[data-anchor="${anchor}"]`}
      backgroundColor={theme.palette['background-flat']}
      borderColor={theme.palette['input-border']}
    >
      <OfferDetails
        offerId={counterId}
        relatedContacts={[
          <ContactCard contactId={agentId} role={agentRole} />,
          <LessContactCard contactId={contactId} role={contactRole} />,
        ]}
        toolbar={(
          <Flex spacing={1}>
            <Button
              variant="secondary"
              onClick={openCreateOfferDeclineReasonModal}
            >
              {userRole === 'buyer' ? 'Seller Declined' : 'Buyer Declined'}
            </Button>
            <Button variant="secondary" onClick={openNewCounterModal}>
              {userRole === 'buyer' ? 'New Counter' : 'New Offer'}
            </Button>
            <Button variant="primary" onClick={openAcceptCounterModal}>
              {userRole === 'buyer' ? 'Seller Accepted' : 'Buyer Accepted'}
            </Button>
          </Flex>
        )}
      />
    </PointerBox>
  );
};

CounterBox.defaultProps = {
  anchor: null,
};

const {
 string, number, func,
} = PropTypes;

CounterBox.propTypes = {
  counterId: number.isRequired,
  userRole: string.isRequired,
  anchor: string,
  openNewCounterModal: func.isRequired,
  openAcceptCounterModal: func.isRequired,
  openCreateOfferDeclineReasonModal: func.isRequired,
  contactId: string.isRequired,
  agentId: string.isRequired,
  contactRole: string.isRequired,
  agentRole: string.isRequired,
};

export default CounterBox;
