/* eslint-disable */
// TODO: fix linter errors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createVideoPlugin from 'draft-js-video-plugin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';

import './styles.css';

const videoPlugin = createVideoPlugin();

export default class VideoAdd extends Component {
  constructor(props) {
    super(props);
    // Start the popover closed
    this.state = {
      url: '',
      open: false,
    };
  }

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener('click', this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  };

  openPopover = () => {
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({
        open: true,
      });
    }
  };

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false,
      });
    }

    this.preventNextClose = false;
  };

  addVideo = () => {
    const {
      editorState,
      onChange,
    } = this.props;
    onChange(videoPlugin.addVideo(editorState, { src: this.state.url }));
  };

  changeUrl = (evt) => {
    this.setState({ url: evt.target.value });
  };

  render() {
    const popoverClassName = this.state.open ?
      'addVideoPopover' :
      'addVideoClosedPopover';
    const buttonClassName = this.state.open ?
      'edit-btn edit-btn_active' :
      'edit-btn';

    return (
      <div className="addVideo">
        <Button
          className={buttonClassName}
          onMouseUp={this.openPopover}
          type="button"
        >
          <FontAwesomeIcon
            icon="video"
          />
        </Button>
        <div
          className={popoverClassName}
          onClick={this.onPopoverClick}
        >
          <input
            type="text"
            placeholder="Paste YouTube or Vimeo video url..."
            className='addVideoInput'
            onChange={this.changeUrl}
            value={this.state.url}
          />
          <button
            className='addVideoConfirmButton'
            type="button"
            onClick={this.addVideo}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}


const {
  func,
  objectOf,
  any,
} = PropTypes;

VideoAdd.propTypes = {
  editorState: objectOf(any).isRequired,
  onChange: func.isRequired,
};
