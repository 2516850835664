import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactItem from './ContactItem';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    firstName: contact?.first_name,
    lastName: contact?.last_name,
  };
};

export default connect(mapStateToProps)(ContactItem);
