import {
  SET_REALTOR_SELECTED_CONTACT,
} from 'store/actionTypes/realtorContacts';

const setSelectedContact = (contactId) => ({
  type: SET_REALTOR_SELECTED_CONTACT,
  payload: contactId,
});

export default setSelectedContact;
