import { connect } from 'react-redux';

import { union, without } from 'shared/utility';

import SearchContactInputMultiple from 'shared/inputs/SearchContactInputMultiple';

const mapStateToProps = (state) => {
  const {
    newPropertyViewingForm: {
      contacts,
    },
  } = state;

  return {
    label: 'Contacts',
    selectedContactsIds: contacts,
  };
};

const mergeProps = (stateProps, _, ownProps) => ({
  ...ownProps,
  ...stateProps,
  handleChange: (contactId) => {
    const { selectedContactsIds } = stateProps;
    ownProps.handleChange(union(selectedContactsIds, [contactId]));
  },

  handleDelete: (contactId) => () => {
    const { selectedContactsIds } = stateProps;
    ownProps.handleChange(without(selectedContactsIds, contactId));
  },
});

export default connect(mapStateToProps, null, mergeProps)(SearchContactInputMultiple);
