import { createSelector } from 'reselect';

import stableStringify from 'json-stable-stringify';

const contactNameFilter = (state) => state.newLeadForm.contactNameFilter;

export default createSelector(
  contactNameFilter,
  (nameFilter) => {
    const filter = nameFilter
      ? {
        fullName: nameFilter,
      }
      : null;

    const filterId = filter
      ? stableStringify(filter)
      : null;

    return {
      filter,
      filterId,
    };
  },
);
