import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import NewEventForm from 'sections/Calendar/forms/NewEvent';

const NewEventFormModal = (props) => {
  const {
    date,
    isVisible,
    closeModal
  } = props;

  return (
    <Modal
      width={800}
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
      title={`Add New Calendar Event ${date ? `for ${date.format('MMM DD YYYY')}` : ''}`}
    >
      <NewEventForm
        defaultDate={date}
        onComplete={closeModal}
      />
    </Modal>
  );
};

NewEventFormModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
} = PropTypes;

NewEventFormModal.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
};

export default NewEventFormModal;
