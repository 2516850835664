import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withAddress = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const address = selectors.addresses.byId(state, ownProps.addressId);

    return {
      address: address?.address,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withAddress;
