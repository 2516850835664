import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import InputGroup from 'ui/InputGroup';
import DropdownTextfield from 'ui/DropdownTextfield';
import Textfield from 'ui/Textfield';

const SearchFilter = (props) => {
  const {
    options,
    onSearch,
    handleCustomFilter,
    customFilter: customFilterProps,
    searchValue,
    withDropdownFilter,
    withSearchInput,
    searchInputPlaceholder,
    dropdownFilterProps,
    searchInputProps,
  } = props;

  const handleSearch = useCallback((value) => {
    onSearch(value);
  }, [
    onSearch,
  ]);

  const [customFilter, setCustomFilter] = useState('all');

  useEffect(() => {
    setCustomFilter(customFilterProps);
  }, [
    customFilterProps,
  ]);

  const onCustomFilterChange = useCallback((value) => {
    handleCustomFilter(value);
    setCustomFilter(value);
  }, [
    handleCustomFilter,
  ]);

  return (
    <InputGroup flat fullWidth>
      {
        withDropdownFilter && (
          <DropdownTextfield
            onChange={onCustomFilterChange}
            options={options}
            value={customFilter}
            flat
            {...dropdownFilterProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        )
      }
      {
        withSearchInput && (
          <Textfield
            value={searchValue}
            onChange={handleSearch}
            flat
            fullWidth
            placeholder={searchInputPlaceholder}
            {...searchInputProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        )
      }
    </InputGroup>
  );
};

SearchFilter.defaultProps = {
  options: [],
  withDropdownFilter: true,
  withSearchInput: true,
  onSearch: () => {},
  handleCustomFilter: () => {},
  customFilter: null,
  searchValue: '',
  searchInputPlaceholder: 'Search',
  dropdownFilterProps: {},
  searchInputProps: {},
};

const {
  func,
  string,
  arrayOf,
  shape,
  bool,
} = PropTypes;

SearchFilter.propTypes = {
  onSearch: func,
  handleCustomFilter: func,
  customFilter: string,
  searchValue: string,
  options: arrayOf(shape({
    value: string.isRequired,
    label: string.isRequired,
  })),
  withDropdownFilter: bool,
  withSearchInput: bool,
  searchInputPlaceholder: string,
  dropdownFilterProps: shape({}),
  searchInputProps: shape({}),
};

export default SearchFilter;
