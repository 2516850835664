import {
  useState,
  useCallback,
} from 'react';

const useEditMode = (defaultMode) => {
  const [mode, setMode] = useState(defaultMode);

  const setEditMode = () => {
    setMode('edit');
  };

  const setDisplayMode = useCallback(() => {
    defaultMode === 'display' && setMode('display');
  }, [
    defaultMode,
  ]);

  return {
    mode,
    setMode,
    setEditMode,
    setDisplayMode,
  };
};

export default useEditMode;
