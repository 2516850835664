import styled from 'styled-components';

export const Name = styled.h3`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 27px;
  line-height: 27px;
  font-weight: bold;
  color: #172b4d;
  margin-bottom: 12px;
`;

export const InfoItem = styled.p`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #172b4d;
  margin-bottom: 0;
  text-transform: ${(props) => props.textTransform || 'none'};
`;

export const EditablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
