import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import routes from 'sections/Calendar/routes';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import GeneralCalendar from 'sections/Calendar/components/GeneralCalendar';
import MonthSelector from 'sections/Calendar/components/MonthSelector';

import {
  Root,
} from './styledItems';

const MonthView = (props) => {
  const {
    year,
    month,
    onEmptyDateClick,
  } = props;

  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(moment().format());

  useEffect(() => {
    if (year && month) {
      const newDate = moment({
        year,
        month: month - 1,
      }).format();

      setSelectedDate(newDate);
    }
  }, [
    year,
    month,
  ]);

  const handleDateChange = useCallback((formattedDate, date) => {
    setSelectedDate(formattedDate);

    const newYear = date.getFullYear();
    const newMonth = date.getMonth();
    history.push(`${routes.sectionRoot}/${newYear}/${newMonth + 1}`);
  }, []);

  const handleDaySelect = useCallback((date, isEmpty) => {
    if (isEmpty) {
      onEmptyDateClick(date);
      return;
    }

    const fullDateSelected = moment(date);
    const yearSelected = fullDateSelected.year();
    const monthSelected = fullDateSelected.month();
    const daySelected = fullDateSelected.date();

    history.push(`${routes.sectionRoot}/${yearSelected}/${monthSelected + 1}/${daySelected}`);
  }, [
    year,
    month,
    onEmptyDateClick,
  ]);

  return (
    <>
      <Root>
        <Flex
          fullWidth
          justifyContent="space-between"
        >
          <Typography
            variant="h1"
          >
            {moment(selectedDate).format('MMMM YYYY')}
          </Typography>

          <MonthSelector
            onChange={handleDateChange}
            selectedDate={selectedDate}
          />
        </Flex>

        <GeneralCalendar
          selectedDate={selectedDate}
          onSelect={handleDaySelect}
        />
      </Root>
    </>
  );
};

MonthView.defaultProps = {
  year: null,
  month: null,
};

const {
  func,
  number,
} = PropTypes;

MonthView.propTypes = {
  year: number,
  month: number,
  onEmptyDateClick: func.isRequired,
};

export default MonthView;
