import {
  SET_REALTOR_SELECTED_LEAD,
} from 'store/actionTypes/realtorLeads';

const setSelectedLead = (leadId) => ({
  type: SET_REALTOR_SELECTED_LEAD,
  payload: leadId,
});

export default setSelectedLead;
