export const TASK_CREATED = 'Task created';
export const TASK_COMPLETED = 'Task completed';
export const TASK_UPDATED = 'Task updated';
export const TASK_ARCHIVED = 'Task archived';
export const WORKLOG_ADDED = 'Worklog added';
export const SUBTASK_ADDED = 'Subtask added';
export const TASK_STATUS_ID_TO_TEXT = {
  1: 'Backlog',
  2: 'To Do',
  3: 'In Progress',
  4: 'Require Feedback',
  5: 'Done',
  6: 'Archived',
};

export const tasks = {
  ALL_TYPES: {
    ALL_TASKS: 'Tasks',
    BACKLOG: 'Backlog',
    TO_DO: 'Todo',
    IN_PROGRESS: 'In Progress',
    REQUIRE_FEEDBACK: 'Require Feedback',
    DONE: 'Done',
    OVERDUE: 'Overdue',
    PROBLEM: 'Problem',
    RESOLVED_THIS_WEEK: 'Resolved This Week',
    DUE_THIS_WEEK: 'Due this week',
    DONE_THIS_WEEK: 'Done this week',
    TOTAL_AGENTS: 'Total Agents',
  },
  TASK_STATUS_ID: {
    BACKLOG: 1,
    TODO: 2,
    IN_PROGRESS: 3,
    REQUIRE_FEEDBACK: 4,
    DONE: 5,
    ARCHIVE: 6,
  },
  EMAIL_OTHER: 'Email / Other',
};

export const tutorialTask = {
  TaskID: 0,
  TaskName: '<p>Build Electric Rocket</p>',
  Description: '<p>This task is an example, it will not appear in your list</p>',
  TaskStatusID: 3,
  DeliveryDate: null,
  TaskPriorityID: 3,
  Labels: [],
  Assignees: [],
  Subtasks: [],
  CreationDate: null,
  TimeCompleted: null,
  CompletorID: null,
  CompletorIP: null,
  Resolved: null,
  Rating: null,
  ResolveComment1: null,
  ResolveComment2: null,
  ResolveComment3: null,
  Duration: null,
  MinRemain: null,
  MaxRemain: null,
  Progress: 30,
  TeamID: null,
  TaskOwnerID: null,
  ResolvedAt: null,
  IsProblem: null,
  inProgressDate: null,
  ParentID: null,
  ResolveCustomerExperienceRate: null,
  ResolveCustomerCommunicationRate: null,
  ResolveCustomerFeedback: null,
  ResolveCustomerAdditionalComments: null,
  CommentsSummary: {
    UnresolvedQuestions: 0,
    UnresolvedActions: 0,
    UnresolvedProblems: 0,
    UnseenQuestions: 0,
    UnseenActions: 0,
    UnseenProblems: 0,
    UnseenComments: 0,
  },
};
