import FormReducer from 'store/utils/reducers/FormReducer';

import {
  SET_LOG_NEW_QUESTION_FORM_STATE,
  RESET_LOG_NEW_QUESTION_FORM_STATE,
  SET_LOG_NEW_QUESTION_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorTransactions';

const formReducer = new FormReducer(
  SET_LOG_NEW_QUESTION_FORM_STATE,
  RESET_LOG_NEW_QUESTION_FORM_STATE,
  SET_LOG_NEW_QUESTION_FORM_ATTACHMENTS,
  {
    contactId: null,
    dateTime: null,
    question: null,
  },
);

export default formReducer.getReducers;
