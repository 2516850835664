import {
  SET_SERVICE_PROCESS_ATTACHMENTS,
} from 'store/actionTypes/serviceProcesses';

const setAttachments = (id, attachments) => ({
  type: SET_SERVICE_PROCESS_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAttachments;
