/* eslint-disable consistent-return */
import React from 'react';
import getVideoId from 'get-video-id';

const YOUTUBE_WATCH_PREFIX = 'https://www.youtube.com/watch?v=';
const VIMEO_WATCH_PREFIX = 'https://vimeo.com/';
const YOUTUBE_EMBED_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_EMBED_PREFIX = 'https://player.vimeo.com/video/';

const getEmbedSrc = (src) => {
  const videoData = getVideoId(src);
  if (videoData.service === 'youtube') {
    return `${YOUTUBE_EMBED_PREFIX}${videoData.id}`;
  }
  if (videoData.service === 'vimeo') {
    return `${VIMEO_EMBED_PREFIX}${videoData.id}`;
  }
  return undefined;
};
const getWatchSrc = (src) => {
  const videoData = getVideoId(src);
  if (videoData.service === 'youtube') {
    return `${YOUTUBE_WATCH_PREFIX}${videoData.id}`;
  }
  if (videoData.service === 'vimeo') {
    return `${VIMEO_WATCH_PREFIX}${videoData.id}`;
  }
  return undefined;
};


const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one', icon: 'heading' },
  { label: 'H2', style: 'header-two', icon: 'heading' },
  // {label: 'Blockquote', style: 'blockquote'},
  { label: 'UL', style: 'unordered-list-item', icon: 'list-ul' },
  { label: 'OL', style: 'ordered-list-item', icon: 'list-ol' },
  { label: 'lign-left', style: 't-left', icon: 'align-left' },
  { label: 'Align-center', style: 't-center', icon: 'align-center' },
  { label: 'Align-right', style: 't-right', icon: 'align-right' },
  { label: 'Indent', style: 'indent', icon: 'indent' },
  { label: 'Outdent', style: 'outdent', icon: 'outdent' },
  // {label: 'Code Block', style: 'code-block'},
];

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: 'bold' },
  { label: 'Italic', style: 'ITALIC', icon: 'italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'underline' },
  { label: 'Strikethrough', style: 'STRIKETHROUGH', icon: 'strikethrough' },
  { label: 'Highlight', style: 'HIGHLIGHT', icon: 'highlighter' },
];

const styleMap = {
  HIGHLIGHT: {
    backgroundColor: '#fff892',
  },
};

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 't-left':
      return 't-left';
    case 't-center':
      return 't-center';
    case 't-right':
      return 't-right';
    default:
      return null;
  }
};

const products = [
  'hr',
  'sales',
  'customer-service',
  'tasks',
];

const checkIfObjectLink = (link) => {
  // splice to remove protocol
  const [host, product] = link.split('/').splice(2);

  const sameHost = window.location.host === host;
  const hasProduct = products.includes(product);

  const isObjectLink = sameHost && hasProduct;

  return isObjectLink;
};

const rules = {
  styleToHTML: (style) => {
    if (style === 'HIGHLIGHT') {
      return <span style={{ backgroundColor: '#fff892' }} />;
    }
    if (style === 'STRIKETHROUGH') {
      return <span style={{ textDecoration: 'line-through' }} />;
    }
  },
  blockToHTML: (block) => {
    if (block.type === 't-left') {
      return <p style={{ textAlign: 'left' }} className="t-left" />;
    }
    if (block.type === 't-center') {
      return <p style={{ textAlign: 'center' }} className="t-center" />;
    }
    if (block.type === 't-right') {
      return <p style={{ textAlign: 'right' }} className="t-right" />;
    }
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'mention') {
      return <span className="mention">{originalText}</span>; // eslint-disable-line
    }
    if (entity.type === 'LINK') {
      if (checkIfObjectLink(entity.data.url)) {
        return <a href={entity.data.url} className="objectLink">{originalText}</a>; // eslint-disable-line
      }
      return <a href={entity.data.url} className="simpleLink" target="_blank" rel="noopener noreferrer">{originalText}</a>; // eslint-disable-line
    }
    if (entity.type === 'image') {
      return <img src={entity.data.src} data-loc={entity.data.location} width={`${entity.data.width}%`} style={{ maxWidth: '100%' }} alt="article-img" />; // eslint-disable-line
    }
    if (entity.type === 'draft-js-video-plugin-video') {
      return <iframe src={getEmbedSrc(entity.data.src)} width="420" height="240" className="embedded-video" />; // eslint-disable-line
    }
    return originalText;
  },
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (node.style.backgroundColor === '#fff892' || node.style.backgroundColor === 'rgb(255, 248, 146)') {
      return currentStyle.add('HIGHLIGHT');
    }
    if (node.style.backgroundColor === '#ffeeba' || node.style.backgroundColor === 'rgb(255, 238, 186)') {
      return currentStyle.add('HIGHLIGHT');
    }
    return currentStyle;
  },
  htmlToBlock: (nodeName, node) => {
    if (node.style.textAlign === 'left') {
      return {
        type: 't-left',
        data: {},
      };
    }
    if (node.style.textAlign === 'center') {
      return {
        type: 't-center',
        data: {},
      };
    }
    if (node.style.textAlign === 'right') {
      return {
        type: 't-right',
        data: {},
      };
    }
    if (nodeName === 'img') {
      return {
        type: 'atomic',
        data: {},
      };
    }
    if (nodeName === 'iframe') {
      return {
        type: 'atomic',
        data: {},
      };
    }
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (node.className === 'mention') {
      return createEntity(
        'mention',
        'MUTABLE',
        { mention: { } },
      );
    }
    if (node.className === 'objectLink' || node.className === 'simpleLink') {
      return createEntity(
        'LINK',
        'MUTABLE',
        { url: node.href },
      );
    }
    if (nodeName === 'img') {
      let { width } = node.width;
      if (typeof width === 'string') {
        width = Number(width.replace('%', ''));
      }
      return createEntity(
        'image',
        'IMMUTABLE',
        { src: node.src, location: node.getAttribute('data-loc'), width },
      );
    }
    if (node.className === 'embedded-video') {
      return createEntity(
        'draft-js-video-plugin-video',
        'IMMUTABLE',
        { src: getWatchSrc(node.src) },
      );
    }
  },
};

export {
  BLOCK_TYPES,
  INLINE_STYLES,
  styleMap,
  getBlockStyle,
  checkIfObjectLink,
  rules,
};
