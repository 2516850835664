import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import { Root } from './styledItems';

const WriteNewButton = (props) => {
  const {
    onClick,
    children,
  } = props;

  return (
    <Root
      onClick={onClick}
    >
      <Typography
        variant="label"
        color="ghost"
      >
        {children}
      </Typography>
    </Root>
  );
};

export default WriteNewButton;

WriteNewButton.defaultProps = {
  onClick: () => {},
  children: null,
};

const {
  element,
  oneOfType,
  string,
  func,
} = PropTypes;

WriteNewButton.propTypes = {
  onClick: func,
  children: oneOfType([string, element]),
};
