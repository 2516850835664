import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_LEAD_FORM_FIELD,
  SET_NEW_REALTOR_LEAD_FORM_NESTED_FIELD,
  ADD_NEW_REALTOR_LEAD_FORM_LIST_ITEM,
  REMOVE_NEW_REALTOR_LEAD_FORM_LIST_ITEM,
  UPDATE_NEW_REALTOR_LEAD_FORM_LIST_ITEM,
  RESET_NEW_REALTOR_LEAD_FORM,
  SET_NEW_REALTOR_LEAD_FORM,
} from 'store/actionTypes/realtorLeads';

import {
  setValue,
  setNestedValue,
  addListItem,
  removeListItem,
  updateListItem,
} from 'store/utils';

const initialState = {
  // lead data fields

  // validators
  fieldsValidators: {
    isEmailValid: true,
    doesEmailExist: false,
    doesPhoneExist: false,
    isFirstNameValid: true,
  },

  // general
  temp: null,
  types: [1],

  // person
  selectedContact: {},
  searchedContacts: [],
  personTitle: 1,
  personFirstName: null,
  personLastName: null,
  mainEmail: null,
  mainPhoneNumber: null,
  isPastClient: false,
  restrictContactChange: false,

  // seller
  expectedSalePrice: 0,
  propertyAddress: null,
  sellerPropertyType: null,
  propertyDetails: {
    bedrooms: 0,
    bathrooms: 0,
    floorArea: 0, // sqft
    plotArea: 0, // acre
    garageCapacity: 0,
    comment: null,
  },
  amenitiesPresent: {
    pool: false,
    shed: false,
    basement: false,
    winery: false,
    loft: false,
    parlor: false,
    cellar: false,
    porch: false,
    kennels: false,
  },

  // buyer
  expectedPurchasePrice: {
    min: null,
    max: null,
  },
  buyerPropertyType: [],
  preferredLocation: null,
  propertyRequirements: {
    bedrooms: 0,
    bathrooms: 0,
    floorArea: 0, // sqft
    plotArea: 0, // acre
    garageCapacity: 0,
    comment: null,
  },
  amenitiesRequired: {
    pool: 3, // oneOf(requirementOptions)
    shed: 3,
    basement: 3,
    winery: 3,
    loft: 3,
    parlor: 3,
    cellar: 3,
    porch: 3,
    kennels: 3,
  },

  // misc
  firstContactDate: null,

  source: null,
  sourceType: null,
  newCustomSourceName: null,
  sourceDescription: null,
};

const reducers = {
  [SET_NEW_REALTOR_LEAD_FORM_FIELD]: setValue,
  [SET_NEW_REALTOR_LEAD_FORM_NESTED_FIELD]: setNestedValue,
  [ADD_NEW_REALTOR_LEAD_FORM_LIST_ITEM]: addListItem,
  [REMOVE_NEW_REALTOR_LEAD_FORM_LIST_ITEM]: removeListItem,
  [UPDATE_NEW_REALTOR_LEAD_FORM_LIST_ITEM]: updateListItem,
  [RESET_NEW_REALTOR_LEAD_FORM]: () => initialState,
  [SET_NEW_REALTOR_LEAD_FORM]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
};

function newLeadForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newLeadForm;
