import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import RealtorContactAvatar from 'shared/modules/RealtorContactAvatar';

import withContactDataBasic from 'sections/Contacts/hocs/withContactDataBasic';

import {
  Root,
} from './styledItems';

const Name = withContactDataBasic(({ firstName, lastName }) => (
  <Typography
    variant="title3"
    weight="bold"
    noMargin
  >
    {firstName}
    {' '}
    {lastName}
  </Typography>
));

const SearchItemProspect = (props) => {
  const {
    prospectId,
    prospect,
    role,
  } = props;

  return (
    <Root>
      <Grid
        container
        wrap="nowrap"
        spacing={1}
      >
        <Grid item>
          <RealtorContactAvatar
            size="s"
            contactId={prospect.contact_id}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
        >
          <Grid item>
            <Name
              contactId={prospect.contact_id}
            />
          </Grid>

          <Grid item>
            <Typography
              variant="title4"
              weight="bold"
              color="focus"
              noMargin
            >
              {role}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

SearchItemProspect.defaultProps = {
  prospectId: null,
  prospect: {},
};

const {
  shape,
  number,
  string,
} = PropTypes;

SearchItemProspect.propTypes = {
  prospectId: number,
  role: string.isRequired,
  prospect: shape({
    contact_id: number,
  }),
};

export default SearchItemProspect;
