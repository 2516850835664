import styled from 'styled-components';

export const Wrapper = styled.div`
  .field-description-text {
    margin: auto 0;
  }
`;

export default {
  Wrapper,
};
