import styled from 'styled-components';

export const Wrapper = styled.div`
  gap: 20px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const EmptyImage = styled.img`
  margin: auto auto 20px;
`;

export const EmptyItemsContainer = styled.div`
  width: fit-content;
  margin: auto;
  display: grid;
  padding-top: 96px;
`;

export default {
  Wrapper,
  EmptyImage,
  EmptyItemsContainer,
};
