import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createInspection from './create';
import deleteInspection from './deleteInspection';
import getInspectionById from './getInspectionById';
import getInspectionsForFilter from './getInspectionsForFilter';
import getInspectionAttachments from './getInspectionAttachments';
import updateInspection from './updateInspection';

import closeInspectionNotesModal from './closeInspectionNotesModal';
import openInspectionNotesModal from './openInspectionNotesModal';

import openNewInspectionModal from './openNewInspectionModal';

export {
  getInspectionById,
  createInspection,
  updateInspection,
  deleteInspection,
  closeInspectionNotesModal,
  openInspectionNotesModal,
  openNewInspectionModal,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedRealtorInspectionsActions = {
  getInspectionAttachments: memoizeDebounce(
    (id) => store.dispatch(getInspectionAttachments(id)),
    debounceDelay,
    debounceOptions,
  ),

  getInspectionsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getInspectionsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),

  getInspectionById: memoizeDebounce(
    (inspectionId) => store.dispatch(getInspectionById(inspectionId)),
    debounceDelay,
    debounceOptions,
  ),
};
