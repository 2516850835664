import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SectionWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const SpaceBox = styled.div`
  margin-bottom: 13px;
`;

export const MarginContainer = styled.div`
  margin-left: 38px;
`;
