import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  Form,
  Input,
  Modal,
} from 'antd';
import { callAlert } from 'ui/Alert';
import { common, auth } from 'constants';
import { globalUrl } from 'paths/index';
import { noopFn, getImage } from 'shared/utils/index';
import Typography from 'ui/Typography';
import './RecoverPassword.scss';

const initState = {
  workspaces: [],
  database: null,
  token: null,
};

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  // TODO - rewrite with componentDidUpdate
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      email: oldEmail,
    } = this.props;

    const {
      form,
      email,
    } = nextProps;

    if (email !== oldEmail) {
      form.setFieldsValue({ email });
    }
  }

  recover = () => {
    const {
      form,
      recoverPassword,
    } = this.props;

    const {
      database,
    } = this.state;

    form.validateFields((err, values) => {
      if (!err) {
        recoverPassword({
          ...values,
          database,
        });
      }
    });
  };

  getWorkspaces = () => {
    const {
      form,
      recoverPassword,
    } = this.props;

    // TODO - move this out of the component, handle data via Redux
    form.validateFields((err, values) => {
      if (!err) {
        fetch(`${globalUrl}/auth/get-workspaces`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        })
          .then((res) => res.json())
          .then(({ workspaces, attachmentToken }) => {
            if (!workspaces.length) {
              callAlert.error(auth.RECOVER_ERROR);
            } else if (workspaces.length === 1) {
              const database = workspaces[0].ClientDatabase;
              recoverPassword({ ...values, database });
            } else if (workspaces.length > 1) {
              this.setState({ workspaces, token: attachmentToken });
            }
          })
          .catch(noopFn);
      }
    });
  };

  setDatabase = (database) => () => this.setState({ database });

  afterClose = () => {
    const {
      form,
    } = this.props;

    form.resetFields();
    this.setState(initState);
  };

  render() {
    const {
      form,
      trigger,
      recoverModal,
    } = this.props;

    const {
      database,
      workspaces,
      token,
    } = this.state;

    return (
      <Modal
        centered
        width={420}
        title={null}
        footer={null}
        onCancel={trigger}
        visible={recoverModal}
        afterClose={this.afterClose}
        wrapClassName="recover-password"
      >
        <Typography
          variant="h2"
          tag="h2"
          align="center"
        >
          Forgotten Password
        </Typography>

        <Typography
          className="recover-password__content"
          align="center"
        >
          Please enter your email address and confirm below.
        </Typography>

        <Form layout="vertical">
          <Form.Item>
            {form.getFieldDecorator('email', {
              rules: common.EMAIL_VALIDATOR,
            })(
              <Input
                placeholder="E-mail"
                size="large"
              />,
            )}
          </Form.Item>
        </Form>

        {workspaces.length > 1 && (
          <>
            <Typography
              className="recover-password__title-multi"
              align="center"
            >
              Please select the Symplete account to reset password.
            </Typography>

            <div className="workspaces">
              {workspaces.map((workspace) => (
                <Card
                  key={workspace.ClientDatabase}
                  className="workspaces__single-workspace"
                  onClick={this.setDatabase(workspace.ClientDatabase)}
                >
                  <img
                    alt={`Workspaces_${workspace.ClientDatabase}`}
                    className="workspaces__logo"
                    src={
                      workspace.logoUrl
                        ? getImage(workspace.logoUrl, null, token)
                        : common.DEFAULT_LOGO
                    }
                  />
                </Card>
              ))}
            </div>

            {database && (
              <Typography
                className="recover-password__title-multi"
                align="center"
              >
                Selected workspace:
                {' '}
                <strong>{database}</strong>
              </Typography>
            )}
          </>
        )}

        <div className="recover-password__footer">
          <Button
            data-test="cancel-button"
            onClick={trigger}
          >
            Cancel
          </Button>
          <Button
            data-test="confirm-button"
            type="primary"
            onClick={database ? this.recover : this.getWorkspaces}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    );
  }
}

RecoverPassword.defaultProps = {
  email: '',
};

const {
  element,
  func,
  shape,
  string,
} = PropTypes;

RecoverPassword.propTypes = {
  email: string,
  form: shape({
    setFieldsValue: func.isRequired,
    resetFields: func.isRequired,
    getFieldDecorator: func.isRequired,
  }).isRequired,
  recoverPassword: func.isRequired,
  trigger: func.isRequired,
  recoverModal: element.isRequired,
};

export default Form.create()(RecoverPassword);
