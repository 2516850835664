import { createSelector } from 'reselect';

import stableStringify from 'json-stable-stringify';

const servicePeopleFullNameFilter = (state) => state.servicePeople.servicePeopleFullNameFilter;

export default createSelector(
  servicePeopleFullNameFilter,
  (nameFilter) => {
    const filter = nameFilter
      ? {
        full_name: nameFilter,
      }
      : null;

    const filterId = filter
      ? stableStringify(filter)
      : null;

    return {
      filter,
      filterId,
    };
  },
);
