import {
  useState,
  useCallback,
} from 'react';

import requests from 'api/requests';

const useUpload = () => {
  const [fileId, setFileId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = useCallback(async (file) => {
    setIsLoading(true);

    const { fileId: newFileId } = await requests.dataImport.upload(file);

    setIsLoading(false);
    setFileId(newFileId);

    return newFileId;
  });

  const reset = useCallback(() => {
    setFileId(null);
    setFileName(null);
    setIsLoading(false);
  });

  return {
    fileId,
    fileName,
    isLoading,
    uploadFile,
    setUploadedFileId: setFileId,
    setUploadedFileName: setFileName,
    reset,
  };
};

export default useUpload;
