import {
  SET_DISCLOSURE,
} from 'store/actionTypes/realtor-notable-points-disclosures';

const setDisclosure = (disclosure) => ({
  type: SET_DISCLOSURE,
  payload: disclosure,
});

export default setDisclosure;
