import styled from 'styled-components';

import { ReactSVG } from 'react-svg';

import { lighten } from 'polished';

const baseRadii = {
  circle: '50%',
  rounded: '10%',
  square: '0',
};

export const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > * {
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    border-radius: ${(props) => baseRadii[props.base] || 0};
    height: ${(props) => props.size * 1.66}px;
    width: ${(props) => props.size * 1.66}px;
    background-color: ${(props) => (props.lighten ? lighten(0.385, props.color) : props.color)};
  }
`;

export const CustomSVG = styled(ReactSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;

    color: ${(props) => props.color};
    stroke: ${(props) => props.color};

    path {
      fill: ${(props) => props.color};
    }
  }
`;

export default {
  CustomSVG,
};
