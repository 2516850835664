import { connect } from 'react-redux';

import {
  openModal,
} from 'store/actions/modals';

import MonthView from './MonthView';

const mapStateToProps = (state, ownProps) => {
  const {
    year,
    month,
  } = ownProps.match.params;

  return {
    year: Number(year),
    month: Number(month),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onEmptyDateClick: (date) => {
    dispatch(openModal('newCalendarEntry')({
      modalProps: {
        date,
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthView);
