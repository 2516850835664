import React, {
  useCallback,
  useState,
} from 'react';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Textfield from 'ui/Textfield';

import useModal from 'ui/hooks/useModal';

import requests from 'api/requests';

const useICloudAuth = (scope, onSuccess) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    isModalVisible,
    openModal,
    closeModal,
    Modal,
  } = useModal();

  const auth = useCallback(async () => {
    try {
      await requests.integrations.auth.icloud({
        password,
        email,
        scope,
      });

      onSuccess();
    } catch (error) {
      console.info(error);
    } finally {
      closeModal();
    }
  }, [
    scope,
    password,
    email,
    closeModal,
    onSuccess,
  ]);

  const modal = (
    <Modal
      title="Apple Credentials"
      visible={isModalVisible}
      onCancel={closeModal}
      footer={[
        (
          <Button
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
        ),
        (
          <Button
            variant="primary"
            onClick={auth}
          >
            Confirm
          </Button>
        ),
      ]}
    >
      <Flex
        spacing={2}
        flexDirection="column"
      >
        <Textfield
          fullWidth
          onChange={setEmail}
          label="Email"
        />
        <Textfield.Password
          fullWidth
          onChange={setPassword}
          label="Apple App-Specific Password"
        />
        <a href="https://support.apple.com/en-gb/HT204397">
          Learn more about Apple app-specific passwords
        </a>
      </Flex>
    </Modal>
  );

  return {
    openAuthModal: openModal,
    modal,
  };
};

export default useICloudAuth;
