import {
  SET_PROPERTY_VIEWINGS,
} from 'store/actionTypes/propertyViewings';

const setPropertyViewings = (viewings) => ({
  type: SET_PROPERTY_VIEWINGS,
  payload: viewings,
});

export default setPropertyViewings;
