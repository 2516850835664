import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import Table from 'ui/Table';
import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const paginationDefault = {
  pageSize: 5,
};

const scroll = {
  x: true,
};

const Preview = (props) => {
  const context = useContext(DataImportContext);

  const {
    previewColumns: columns,
    previewDataSource: dataSource,
    selectedRows,
    setSelectedRows,

    getFileConflicts,
    fieldMap,
    uploadedFileId,
    selectedTypes,

    conflictsIsLoading,
  } = context;

  useEffect(() => {
    getFileConflicts(selectedTypes)(fieldMap)(uploadedFileId);
  }, []);

  // const [selectedRows, setSelectedRows] = useState(dataSource.map((item) => item.sympleteDataIndex));
  const [currentPage, setCurrentPage] = useState(1);

  const onRowSelect = useCallback((record, selected) => {
    // const selectedSet = new Set(selectedRows);

    // selected
    //   ? selectedSet.add(record?.sympleteDataIndex)
    //   : selectedSet.delete(record?.sympleteDataIndex);

    // setSelectedRows([...selectedSet]);
    setSelectedRows({
      ...selectedRows,
      [record?.sympleteDataIndex]: !!selected,
    });
  }, [
    currentPage,
    selectedRows,
    setSelectedRows,
  ]);

  const rowSelection = {
    columnTitle: 'Import',
    onSelect: onRowSelect,
    columnWidth: 150,
    getCheckboxProps: (record) =>
      // const selectedSet = new Set(selectedRows);
      ({
        // checked: selectedSet.has(record.sympleteDataIndex),
        checked: selectedRows[record.sympleteDataIndex],
      })
    ,
  };

  const pagination = useMemo(() => ({
    ...paginationDefault,
    onChange: setCurrentPage,
    total: dataSource?.length,
    current: currentPage,
  }), [
    currentPage,
    setCurrentPage,
    dataSource?.length,
  ]);

  return (
    <Root>
      {
        conflictsIsLoading && (
          <Typography align="center">
            Checking for merge conflicts — this may take a while...
          </Typography>
        )
      }
      <Table
        rowClassName={(record) => {
          if (record?.sympleteConflicts?.name) return 'warning';
        }}
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        scroll={scroll}
        rowSelection={rowSelection}
        loading={!!conflictsIsLoading}
      />
    </Root>
  );
};

export default Preview;
