import { createSelector } from 'reselect';

const employees = (state) => state.salesTeams.teams;

export default createSelector(
  employees,
  (items) => (items
    ? items.map((item) => item.SalesTeamId)
    : []),
);
