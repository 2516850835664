/* eslint-disable react/jsx-no-bind */

import React, {
  useEffect,
  useState,
  useContext,
} from 'react';

import Flex from 'ui/Flex';

import Summary from 'sections/OffersV2/modules/Summary';
import Offer from 'sections/OffersV2/modules/Offer';

import OffersContext from './context/OffersContext';

const OffersV2 = () => {
  const { offers } = useContext(OffersContext);

  const [offerData, setOfferData] = useState(null);
  const [propertySelection, setPropertySelection] = useState(null);

  const selectProperty = (value) => {
    if (!value) {
      setOfferData(propertySelection);
      return;
    }
    const offerToShow = offers.find((offer) => offer.realtor_property_id === value);
    setOfferData([offerToShow]);
  };

  useEffect(() => {
    if (offerData?.length === 1) {
      selectProperty(offerData[0].realtor_property_id);
      setPropertySelection(offers);
      return;
    }

    setPropertySelection(offers);
    setOfferData(offers);
  }, [offers]);

  return (
    <Flex
      flexDirection="column"
      fullWidth
      spacing={2}
    >
      <Summary
        properties={propertySelection}
        selectProperty={selectProperty}
      />
      <Flex
        flexDirection="column"
        fullWidth
        spacing={2}
      >
        {
          offerData?.map((offer) => (
            <Offer
              key={offer.realtor_property_id}
              propertyId={offer.realtor_property_id}
              offerData={offer}
            />
          ))
        }
      </Flex>
    </Flex>
  );
};

export default OffersV2;
