/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors

import {
  SHOW_NEW_LEAD_MODAL,
  SET_LEAD_COMPANIES,
  LEADS_GET_LIST_SUCCESS,
  LEADS_LIST_CLEAR,
  SHOW_PITCH_MODAL,
  SET_LEAD_TYPES,
  SET_LEAD_STATUSES,
  SHOW_COMPANY_DETAILS_MODAL,
  SHOW_LOGGING_CONTACT_DETAILS_MODAL,
  SHOW_CONTACTS_OVERVIEW_MODAL,
  LEADS_GET_DETAILS,
  LEADS_COMPANY_UPDATE,
  LEADS_CONTACT_UPDATE,
  UPDATE_CONTACT_LOG,
  GET_CONTACT_LOGS,
  UPDATE_LEAD_MEETING,
  SET_SALESPERSON_ID, SET_USER_MEETINGS, SET_WEEK_USER_MEETINGS,
  CHANGE_SAVED_STATUS,
  CLEAR_SAVED_STATUS,
  NEW_LEAD_ALERT_HIDE_ACTION,
  NEW_LEAD_ALERT_SHOW_ACTION,
  SET_DAY_USER_MEETINGS,
  GET_LEADS_DASHBOARD_INFORMATION,
  ADD_TUTORIAL_LEAD,
  UPDATE_LEAD,
} from 'store/actions/leads';

import {
  SET_LEADS_LABELS,
  SET_LEAD_LABELS,
} from 'store/actions/leadLabels';

import {
  GET_NOTES,
} from 'store/actions/notes';

import {
  SET_SALES_COMPANIES_LABELS,
  SET_SALES_COMPANY_LABELS,
} from 'store/actions/salesCompaniesLabels';


const initialState = {
  isNewLeadModalVisible: false,
  isCompanyDetailsModalVisible: false,
  showNewLeadAlert: false,
  isContactDetailsModalVisible: false,
  isPitchModalVisible: false,
  isSalesDetailsLoggingContactModalVisible: false,
  isContactsOverviewModalVisible: false,
  leadsCompanies: [],
  leadsList: [],
  leadStatuses: [],
  leadTypes: {},
  leadDetails: {},
  leadLabels: [],
  allLeadsLabels: [],
  LeadID: 0,
  comments: [],
  log: [],
  contactLogId: 0,
  notes: [],
  contactDetails: {},
  allSalesCompaniesLabels: [],
  companyLabels: [],
  selectedSalesPerson: {},
  weekUserMeetings: [],
  userMeetingsToday: [],
  userMeetings: [],
  savedStatus: null,
  newLeadAssigned: [],
  salesWidgetOtherInformation: [],
  likelyCloseLeadsCost: 0,
  likelyCloseLeadsCount: 0,
  allLeadTeamCount: 0,
  revenueWonValueThisMonth: 0,
  leadsCommentsSummary: {},
};

const comentsSummaryListToObject = (commentsSumary = []) => commentsSumary.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.LeadID] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

function leads(state = initialState, action) {
  switch (action.type) {
    case NEW_LEAD_ALERT_HIDE_ACTION : {
      return {
        ...state,
        showNewLeadAlert: false
      }
    }

    case NEW_LEAD_ALERT_SHOW_ACTION : {
      return {
        ...state,
        showNewLeadAlert: true,
        newLeadAssigned: action.newLeadAssigned,
      }
    }

    case GET_LEADS_DASHBOARD_INFORMATION : {
      return {
        ...state,
        allLeadTeamCount: action.allLeadTeamCount,
        salesWidgetOtherInformation: action.salesWidgetOtherInformation,
        likelyCloseLeadsCost: action.likelyCloseLeadsCost,
        likelyCloseLeadsCount: action.likelyCloseLeadsCount,
        revenueWonValueThisMonth: action.revenueWonValueThisMonth,
      }
    }

    case SET_DAY_USER_MEETINGS : {
      return {
        ...state,
        userMeetingsToday: action.result,
      }
    }

    case CLEAR_SAVED_STATUS : {
      return {
        ...state,
        savedStatus: null,
      }
    }

    case CHANGE_SAVED_STATUS : {
      return {
        ...state,
        savedStatus: action.payload,
      }
    }

    case SHOW_NEW_LEAD_MODAL: {
      return {
        ...state,
        isNewLeadModalVisible: action.visible,
      };
    }

    case SHOW_PITCH_MODAL: {
      return {
        ...state,
        isPitchModalVisible: action.visible,
        LeadID: action.LeadID,
      };
    }

    case SHOW_COMPANY_DETAILS_MODAL: {
      return {
        ...state,
        isCompanyDetailsModalVisible: action.visible,
      };
    }

    case SHOW_LOGGING_CONTACT_DETAILS_MODAL: {
      return {
        ...state,
        isSalesDetailsLoggingContactModalVisible: action.visible,
        contactLogId: action.contactLogId,
      };
    }

    case SHOW_CONTACTS_OVERVIEW_MODAL: {
      return {
        ...state,
        isContactsOverviewModalVisible: action.visible,
      };
    }

    case SET_LEAD_COMPANIES: {
      return {
        ...state,
        leadsCompanies: action.companies,
      };
    }

    case SET_LEAD_TYPES: {
      return {
        ...state,
        leadTypes: action.leadTypes,
      };
    }

    case SET_LEAD_STATUSES: {
      return {
        ...state,
        leadStatuses: action.leadStatuses,
      };
    }

    case LEADS_LIST_CLEAR: {
      return {
        ...state,
        leadsList: [],
      };
    }

    case LEADS_GET_LIST_SUCCESS: {
      const {
        result: {
          leads,
          LeadsCommentsSummary,
        },
      } = action;
      return {
        ...state,
        leadsList: leads,
        leadsCommentsSummary: {
          ...state.leadsCommentsSummary,
          ...comentsSummaryListToObject(LeadsCommentsSummary),
        },
      };
    }

    case LEADS_GET_DETAILS: {
      return {
        ...state,
        leadDetails: action.result,
      };
    }

    case LEADS_COMPANY_UPDATE: {
      return {
        ...state,
        ...action.result,
      };
    }

    case LEADS_CONTACT_UPDATE: {
      return {
        ...state,
        ...action.result,
      };
    }

    case UPDATE_CONTACT_LOG: {
      return {
        ...state,
        log: action.log,
      };
    }

    case GET_CONTACT_LOGS: {
      return {
        ...state,
        log: action.log,
      };
    }

    case GET_NOTES: {
      return {
        ...state,
        notes: action.payload,
      };
    }

    case UPDATE_LEAD_MEETING: {
      return {
        ...state,
        ...action.result,
      };
    }

    case SET_SALESPERSON_ID: {
      return {
        ...state,
        selectedSalesPerson: action.payload,
      };
    }

    case SET_LEADS_LABELS: {
      return {
        ...state,
        allLeadsLabels: action.payload,
      };
    }

    case SET_LEAD_LABELS: {
      const newleadLabels = action.payload;

      return {
        ...state,
        leadLabels: newleadLabels,
      };
    }

    case SET_SALES_COMPANIES_LABELS: {
      return {
        ...state,
        allSalesCompaniesLabels: action.payload,
      };
    }

    case SET_SALES_COMPANY_LABELS: {
      const { leadDetails } = state;

      return {
        ...state,
        leadDetails: {
          ...leadDetails,
          CompanyObject: {
            ...leadDetails.CompanyObject,
            Labels: action.payload,
          },
        },
      };
    }

    case SET_WEEK_USER_MEETINGS: {
      return {
        ...state,
        weekUserMeetings: action.result,
      };
    }

    case SET_USER_MEETINGS: {
      return {
        ...state,
        userMeetings: action.result,
      };
    }

    case ADD_TUTORIAL_LEAD: {
      const lead = {
        LeadID: 2020,
        Title: 'Pencils',
        Value: 5000,
        LeadTypeID: 1,
        LeadStatusID: 1,
        FirstName: 'Demo',
        LastName: 'User',
        CompanyName: 'Some company LLC',
      }
      return {
        ...state,
        leadDetails: lead,
      };
    }

    case UPDATE_LEAD: {
      const {
        leadId,
        leadParams
      } = action.payload

      const leads = [...state.leadsList]

      const leadIdx = leads.findIndex(item => item.LeadID === leadId)

      leads[leadIdx] = {
        ...leads[leadIdx],
        ...leadParams,
      }

      return {
        ...state,
        leadsList: leads
      }
    }

    default:
      return state;
  }
}

export default leads;
