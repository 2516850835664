import GlobalApiConnector from 'api/connectors/GlobalApiConnector';
import ClientApiConnector from 'api/connectors/ClientApiConnector';
import ExternalApiConnector from 'api/connectors/ExternalApiConnector';
import OAApiConnector from 'api/connectors/OAApiConnector';
import SmartzipApiConnector from 'api/connectors/SmartzipApiConnector';

import globalEndpoints from 'api/endpoints/global';
import clientEndpoints from 'api/endpoints/client';
import externalEndpoints from 'api/endpoints/external';
import OAEndpoints from 'api/endpoints/OA';
import smartzipEndpoints from 'api/endpoints/smartzip';

import sockets from 'api/sockets';

export const endpoints = {
  global: globalEndpoints,
  client: clientEndpoints,
  external: externalEndpoints,
  OA: OAEndpoints,
  smartzip: smartzipEndpoints,
};

const apiConnector = {
  global: new GlobalApiConnector(),
  client: new ClientApiConnector(),
  clientAttachmentsUpload: new ClientApiConnector('multipart/form-data'),
  externalAttachmentsUpload: new ExternalApiConnector('multipart/form-data'),
  OA: new OAApiConnector(),
  external: new ExternalApiConnector(),
  smartzip: new SmartzipApiConnector(),
  sockets,
};

export default apiConnector;
