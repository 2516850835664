import requests from 'api/requests';

import getDigitalAccountById from './getDigitalAccountById';

const updateDigitalAccount = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.customerDigitalAccounts.update(id)(updateData);

    dispatch(getDigitalAccountById(id));
  } catch (error) {
    console.error(error);
  }
};

export default updateDigitalAccount;
