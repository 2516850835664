import styled from 'styled-components';
import { Avatar } from 'antd';

export const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 7px;

  :hover {
    background-color: #f7f9fa;
    cursor: pointer;
  }
`;

export const Image = styled(Avatar)`
  width: 60px;
  height: 45px;
  line-height: 45px;
  font-size: 25px;
`;

// export const SearchedContactWrapper = styled.div`
//   margin-bottom: 5px;
//   display: flex;
//   padding: 5px;
//   border-radius: 7px;
//
//   .contact-email {
//     margin-left: 10px;
//   }
//
//   :hover {
//     background-color: #f7f9fa;
//     cursor: pointer;
//   }
// `;
//
// export default {
//   SearchedContactWrapper,
// };
