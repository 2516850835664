import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import FormTextfield from 'shared/inputs/FormTextfield';

import Typography from 'ui/Typography';
import Button from 'ui/Button';

const Heading = ({ children }) => (
  <Typography variant="ttitle3" tag="h3" noMargin>
    {children}
  </Typography>
);

const RequestItemRespondArea = (props) => {
  const {
    onChange,
    onSubmit,
    onAcknowledge,
    value,
    status,
  } = props;

  const [response, setResponse] = useState(value);

  useEffect(() => {
    setResponse(value);
  }, [
    value,
  ]);

  const handleChange = useCallback((newValue) => {
    onChange(newValue);
    setResponse(newValue);
  }, [
    onChange,
  ]);

  const handleSubmit = useCallback(() => {
    onSubmit(response);
  }, [
    onSubmit,
    response,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container justify="space-between">
          {/* <Grid item>
            <Heading>
              Respond
            </Heading>
          </Grid> */}

          {
            status !== 'acknowledged' && (
              <Grid>
                <Button variant="secondary" onClick={onAcknowledge}>
                  Acknowledge
                </Button>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormTextfield
          label=""
          multiline
          rows={3}
          onChange={handleChange}
          value={response}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="flex-end">
          {/* <Grid item>
            <Button>
              Attach
            </Button>
          </Grid> */}

          <Grid item>
            <Button onClick={handleSubmit}>
              Respond
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const noOp = () => {};

RequestItemRespondArea.defaultProps = {
  value: null,
  onChange: noOp,
  onSubmit: noOp,
  onAcknowledge: noOp,
};

const {
  func,
  string,
} = PropTypes;

RequestItemRespondArea.propTypes = {
  value: string,
  onChange: func,
  onSubmit: func,
  onAcknowledge: func,
};

export default RequestItemRespondArea;
