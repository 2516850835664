import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const createSource = ({ type, description }) => async (dispatch) => {
  try {
    const response = await requests.crm.sources.create({ type, description });
    return response.data.sourceId;
  } catch (error) {
    console.error(error);
  }
};

export default createSource;
