import { getConnector, apiConnector } from 'shared/API';
import LicenseRepository from 'shared/repositiores/myCompany/license';
import getEmployeeById from 'shared/utils/getEmployeeById';

const url = '/api/common/';

class EmployeeRepository {
  static getEmployees = (options = '') => getConnector(`${url}employees${options}`)

  static getEmployee = (employeeId) => getConnector(`${url}employees/${employeeId}`)

  static updateEmployee = (employeeId, data) => {
    const deletionActionId = 8;

    if (data.EmployeeStatusID === deletionActionId) {
      const employee = getEmployeeById(employeeId);
      LicenseRepository.removeLicenses(employee);
    }

    return apiConnector(`${url}employees/${employeeId}`, 'PUT', data, {
      extended: true,
    });
  }

  static updateEmployeeInterviewers = (data) => apiConnector(`${url}employee2interviewers/actions/bulk`, 'POST', data)

  static deleteEmployeeInterviewers = (data) => apiConnector(`${url}employee2interviewers/actions/bulk`, 'DELETE', data)

  static updateEmployeeReporters = (data) => apiConnector(`${url}employee2reporters/actions/bulk`, 'POST', data)

  static deleteEmployeeReporters = (data) => apiConnector(`${url}employee2reporters/actions/bulk`, 'DELETE', data)

  static updateEmployeeEmployers = (data) => apiConnector(`${url}employee2employers/actions/bulk`, 'POST', data)

  static deleteEmployeeEmployerss = (data) => apiConnector(`${url}employee2employers/actions/bulk`, 'DELETE', data)

  static updateDraftEmployee = (employeeId, data) => apiConnector(`${url}draftEmployee/${employeeId}`, 'PUT', data)

  static updateWorkdays = (data) => apiConnector('/api/hr/attendance/workdays', 'PUT', data)

  static addWorkdays = (data) => apiConnector('/api/hr/attendance/workdays', 'POST', data)

  static createEmployee = (CompanyID) => apiConnector(`${url}employees`, 'POST', { CompanyID })
}

export default EmployeeRepository;
