import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import DropdownTextfield from 'ui/DropdownTextfield';

const options = [
  {
    value: 1,
    label: 'Conventional',

  },
  {
    value: 2,
    label: 'Cash',

  },
  {
    value: 3,
    label: 'FHA Loan',

  },
  {
    value: 4,
    label: 'Va Loan',

  },
  {
    value: 5,
    label: 'USDA Loan',

  },
  {
    value: 6,
    label: 'Jumbo Loan',

  },
];
const FinanceType = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'financeType',
    control,
  });

  return (
    <DropdownTextfield
      label="Financing Type"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

const {
  shape,
} = PropTypes;

FinanceType.propTypes = {
  control: shape({}).isRequired,
};

export default FinanceType;
