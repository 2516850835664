export const CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL = 'CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL';
export const OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL = 'OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL';
export const SET_WAREHOUSE_PACKAGE = 'SET_WAREHOUSE_PACKAGE';
export const SET_WAREHOUSE_PACKAGES = 'SET_WAREHOUSE_PACKAGES';
export const SET_SELECTED_WAREHOUSE_PACKAGE = 'SET_SELECTED_WAREHOUSE_PACKAGE';
export const SET_WAREHOUSE_PACKAGE_ATTACHMENTS = 'SET_WAREHOUSE_PACKAGE_ATTACHMENTS';
export const SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT = 'SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT';
export const SET_WAREHOUSE_PENDING_ORDER = 'SET_WAREHOUSE_PENDING_ORDER';
export const SET_WAREHOUSE_OUT_OF_STOCK = 'SET_WAREHOUSE_OUT_OF_STOCK';
export const SET_SELECTED_WAREHOUSE_LINE_ITEMS = 'SET_SELECTED_WAREHOUSE_LINE_ITEMS';
export const CLEAR_SELECTED_WAREHOUSE_LINE_ITEMS = 'CLEAR_SELECTED_WAREHOUSE_LINE_ITEMS';
export const SET_DRAGGED_WAREHOUSE_LINE_ITEMS = 'SET_DRAGGED_WAREHOUSE_LINE_ITEMS';
export const SET_WAREHOUSE_KANBAN = 'SET_WAREHOUSE_KANBAN';
export const REFRESH_WAREHOUSE_KANBAN = 'REFRESH_WAREHOUSE_KANBAN';

export default {
  CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL,
  OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL,
  SET_WAREHOUSE_PACKAGE,
  SET_WAREHOUSE_PACKAGES,
  SET_SELECTED_WAREHOUSE_PACKAGE,
  SET_WAREHOUSE_PACKAGE_ATTACHMENTS,
  SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT,
  SET_WAREHOUSE_PENDING_ORDER,
  SET_WAREHOUSE_OUT_OF_STOCK,
  SET_SELECTED_WAREHOUSE_LINE_ITEMS,
  CLEAR_SELECTED_WAREHOUSE_LINE_ITEMS,
  SET_DRAGGED_WAREHOUSE_LINE_ITEMS,
  SET_WAREHOUSE_KANBAN,
  REFRESH_WAREHOUSE_KANBAN,
};
