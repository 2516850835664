export const SET_CREDIT_NOTE = 'SET_CREDIT_NOTE';
export const SET_CREDIT_NOTES = 'SET_CREDIT_NOTES';
export const SET_SELECTED_CREDIT_NOTE = 'SET_SELECTED_CREDIT_NOTE';
export const SET_CREDIT_NOTE_ATTACHMENTS = 'SET_CREDIT_NOTE_ATTACHMENTS';

export default {
  SET_CREDIT_NOTE,
  SET_CREDIT_NOTES,
  SET_SELECTED_CREDIT_NOTE,
  SET_CREDIT_NOTE_ATTACHMENTS,
};
