import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import {
  IconWrapper,
  Avatar,
  StyledLink,
} from './styledItems';

const Image = withMainPropertyImage(({ url }) => {
  if (!url) {
    return (
      <IconWrapper>
        <Icon
          name="property"
          className="property-icon"
          type="custom"
          color="background-flat"
          size="xs"
        />
      </IconWrapper>
    );
  }

  return (
    <Avatar src={url} />
  );
});

const SearchItemProperty = (props) => {
  const {
    propertyId,
    property,
  } = props;

  return (
    <StyledLink to={`/mls/${propertyId}`}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Image
            propertyId={propertyId}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          justify="space-around"
        >
          <Grid item>
            <Typography
              variant="title3"
              weight="bold"
              noMargin
            >
              {property?.opportunity_name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledLink>
  );
};

SearchItemProperty.defaultProps = {
  propertyId: null,
  property: {},
};

const {
  shape,
  number,
} = PropTypes;

SearchItemProperty.propTypes = {
  propertyId: number,
  property: shape({
    address_id: number,
  }),
};

export default SearchItemProperty;
