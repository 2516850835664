import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

// eslint-disable-next-line import/no-cycle
import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import removeRepairRequest from './removeRepairRequest';

const deleteRepairRequest = (id, transactionId) => async (dispatch) => {
  try {
    await requests.realtor.repairRequests.deleteRepairRequest(id);
    dispatch(removeRepairRequest(id));

    // we must refresh the transaction after deletiion for state change to be reflected
    optimizedRealtorTransactionsActions.getTransactionById(transactionId);

    callAlert.success('Repair request deleted');
  } catch (error) {
    console.error(error);
    callAlert.error('An error has occurred');
  }
};

export default deleteRepairRequest;
