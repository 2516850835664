import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'ui/Typography';

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: ${((props) => props.theme?.palette?.highlight || '#1584ff')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: 8px;
  text-transform: uppercase;
  margin-right: 3px;
`;

export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
`;

export const Initials = styled.div`
  color: white;
  font-size: 17px;
  font-weight: ${((props) => props.theme?.typography?.fontWeight?.bold || 'bold')};
  font-family: ${((props) => props.theme?.typography?.fontFamily?.primary || 'Roboto, Arial, Helvetica, sans-serif;')}; 
`;

export const StyledLink = styled(Link)`
  margin: 2px 0;
  padding: 5px;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: ${((props) => props.theme?.palette?.hover || '##edeef0')};;
  }
`;

export const Name = styled(Typography)`
  margin-bottom: 2px;
  text-transform: capitalize;
`;

export default {
  Avatar,
  IconWrapper,
  Initials,
  StyledLink,
  Name,
};
