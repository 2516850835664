export const employeeSystemPermissionsId = {
  1: 'C-Level',
  2: 'Manager',
  3: 'User',
};

export const employeeAccessTypes = {
  user: 'User',
  manager: 'Manager',
  noAccess: 'No Access',
};

export const userTypeNames = {
  1: 'employee',
  2: 'customer',
  3: 'salesContact',
};

export default {
  employeeSystemPermissionsId,
  employeeAccessTypes,
  userTypeNames,
};
