import { connect } from 'react-redux';
import { history } from 'store';

import {
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  setStepIndex,
} from 'store/actions/tutorial';

import {
  getTaskData,
  showTaskModal,
  clearSelectedTask,
} from 'store/actions/tasks';

import {
  selectTask,
  addTutorialTask,
  removeTutorialTask,
} from 'store/actions/tasksV2';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import initialSteps from './steps';
import filterSteps from '../shared/utils/filterSteps';

import TasksTutorial from './TasksTutorial';

const mapStateToProps = (state) => {
  const hasManagerPermissions = checkLoggedEmployeePermissions.isManager('Tasks');
  const steps = filterSteps(initialSteps, hasManagerPermissions);

  const {
    tutorial: {
      stepIndex,
    },
    tasks: {
      isModalVisible,
    },
    router: {
      location,
    },
  } = state;

  return {
    isModalVisible,
    history,
    location,
    stepIndex,
    steps,
  };
};

const mapDispatchToProps = {
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  getTaskData,
  showTaskModal,
  clearSelectedTask,
  setStepIndex,
  selectTask,
  addTutorialTask,
  removeTutorialTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTutorial);
