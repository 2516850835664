// TODO - fix lint errors where linter is disabled

import { url, globalUrl } from '../../paths';
import { checkStatus } from '../utils'; // eslint-disable-line import/no-cycle

import handleAsyncError from '../utils/handleAsyncError'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

export const getConnector = (apiUrl, external) => {
  let config;
  if (external) {
    config = {
      headers: {
        ...headers,
        Authorization: external.token ? external.token : null,
      },
    };
  } else {
    config = {
      headers: { ...headers, Authorization: localStorage.getItem('token') },
    };
  }
  return fetch(`${url}${apiUrl}`, config)
    .then(checkStatus)
    .then((res) => res.json())
    .catch(handleAsyncError)
};

export const customerToUrlConnector = (apiUrl, method = 'GET', body = {}) => {
  let config = {
    headers: { ...headers, Authorization: localStorage.getItem('token') },
    method,
  };
  config = Object.keys(body).length
    ? { ...config, body: JSON.stringify(body) }
    : config;
  config.method = method;


  const connectionString = `${url}${apiUrl}`;

  return fetch(connectionString, config)
    .then(checkStatus)
    .then((res) => res.json())
    .catch(handleAsyncError)
};

export const apiConnector = (route, method, body, config) => {
  const localHeaders = getLocalHeaders(config);

  const apiUrl = config && config.global ? globalUrl : url;

  const fetchRoute = `${apiUrl}${route}`;
  // console.info(fetchRoute);

  return fetch(fetchRoute, {
    method,
    headers: localHeaders,
    body: JSON.stringify(body),
  })
    .then(checkStatus)
    .then(async (res) => {
      if (config && config.extended) {
        return res;
      }
      const result = await res.text();
      if (result === '') {
        return result;
      }
      return JSON.parse(result);
    })
    .catch(handleAsyncError)
};

export const tokenFetch = (apiUrl, options = {}, isStandard = true) => fetch(apiUrl, {
  ...options,
  headers: {
    Authorization: isStandard
      ? localStorage.getItem('token')
      : localStorage.getItem('token'),
    'Content-Type': 'application/json',
    ...options.headers,
  },
})
.then(checkStatus)
.catch(handleAsyncError);

export const noContentTypeFetch = (apiUrl, options = {}, isStandard = true) => {
  const jwt = isStandard
    ? localStorage.getItem('token')
    : localStorage.getItem('token');

  const methodHeaders = {
    Authorization: jwt,
    ...options.headers,
  };

  return fetch(apiUrl, {
    ...options,
    headers: methodHeaders,
  })
  .then(checkStatus)
  .catch(handleAsyncError)
};

function getLocalHeaders(config) {
  const localHeaders = headers;
  const token = localStorage.getItem('token');

  if (config && config.auth) {
    localHeaders.Authorization = config.auth;
  } else if (token) {
    localHeaders.Authorization = token;
  }

  return localHeaders;
}
