import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  gap: ${(props) => props.gap || '8px'};
`;

export default {
  Root,
};
