import create from './create';
import getById from './getById';
import update from './update';
import deleteLoan from './delete';

export default {
  create,
  getById,
  update,
  deleteLoan,
};
