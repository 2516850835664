import requests from 'api/requests';

import getPaymentById from './getPaymentById';

const update = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.customerPayments.update(id)(updateData);

    dispatch(getPaymentById(id));
  } catch (error) {
    console.error(error);
  }
};

export default update;
