import updateContact from './updateContact';
import getContactChangelogAllTime from './getContactChangelogAllTime';

const updateSelectedContact = (updateData) => async (dispatch, getState) => {
  const {
    crmContacts: {
      selectedContact,
    },
  } = getState();

  await dispatch(updateContact(selectedContact)(updateData));
  dispatch(getContactChangelogAllTime(selectedContact));
};

export default updateSelectedContact;
