import {
  SET_INVOICE_ATTACHMENTS,
} from 'store/actionTypes/customerInvoices';

const setInvoiceAttachments = (id, attachments) => ({
  type: SET_INVOICE_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setInvoiceAttachments;
