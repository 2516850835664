import {
  SET_ORDER,
} from 'store/actionTypes/orders';

const set = (payload) => ({
  type: SET_ORDER,
  payload,
});

export default set;
