import {
  SET_AGREEMENT_ATTACHMENTS,
} from 'store/actionTypes/agreements';

const setAgreementAttachments = (id, attachments) => ({
  type: SET_AGREEMENT_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setAgreementAttachments;
