import { connect } from 'react-redux';

import Presenter from './Presenter';

const mapStateToProps = (state) => {
  const {
  } = state;

  return {
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Presenter);
