import {
  SET_TASKS_COUNT_FOR_TEAM,
} from 'store/actionTypes/tasks';

const setTasksCountForTeam = (teamId) => (tasksCount) => ({
  type: SET_TASKS_COUNT_FOR_TEAM,
  payload: {
    teamId,
    tasksCount,
  },
});

export default setTasksCountForTeam;
