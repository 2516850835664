import React, { PureComponent } from 'react';

import {
  Input,
  Tooltip,
} from 'antd';

import Item from 'ui/Item';
import Container from 'ui/Container';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';

import { string, bool, func } from 'prop-types';

import SocialIcon from 'shared/components/SocialIcon';

class SocialIconModal extends PureComponent {
  // TODO improve react/static-property-placement
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    className: string,
    visible: bool,
    title: string.isRequired,
    type: string.isRequired,
    handleClose: func.isRequired,
    handleSubmit: func.isRequired,
    value: string.isRequired,
  }

  // TODO improve react/static-property-placement
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    className: '',
    visible: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  // TODO improve react/no-deprecated
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      // TODO improve react/prop-types
      // eslint-disable-next-line react/prop-types
      value: nextProps.value || '',
    });
  }

  onChange = (value) => {
    this.setState({
      value,
    });
  };

  handleOk = () => {
    const { value } = this.state;
    const { handleSubmit } = this.props;

    handleSubmit(value);
  };

  searchUrl = (value) => value.search('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)')

  handleNewTab = () => {
    const { value } = this.state;

    const win = window.open(value, '_blank');
    win.focus();
  }

  render() {
    const {
      visible,
      title,
      type,
      handleClose,
    } = this.props;

    const {
      value,
    } = this.state;

    return (
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={handleClose}
        title={title}
        footer={[
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>,
          <Button
            variant="primary"
            onClick={this.handleOk}
          >
            Confirm
          </Button>,
        ]}
      >
        <Container spacing={1} alignItems="center">
          <SocialIcon
            type={type}
          />
          <div style={{ flex: 1, marginLeft: 5 }}>
            <Textfield
              fullWidth
              value={value}
              onChange={this.onChange}
            />
          </div>
          <Tooltip title="Open in a new tab">
            <Button
              onClick={this.handleNewTab}
              icon="external-link"
              variant="tertiary"
            />
          </Tooltip>
        </Container>
      </Modal>
    );
  }
}

export default SocialIconModal;
