import requests from 'api/requests';

// eslint-disable-next-line import/no-cycle
import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import { callAlert } from 'ui/Alert';

import getRepairRequestById from './getRepairRequestById';

const updateRepairRequest = (id) => (updateData, transactionId) => async (dispatch) => {
  try {
    await requests.realtor.repairRequests.update(id)(updateData);
    dispatch(getRepairRequestById(id));
    optimizedRealtorTransactionsActions.getTransactionById(transactionId);

    callAlert.success('Updated successfully');
  } catch (error) {
    console.error(error);
    callAlert.error('Could not update');
  }
};

export default updateRepairRequest;
