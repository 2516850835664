/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TodosContext from '../TodosContext';

const Todos = (props) => {
  const {
    todos,
    addTodo,
    toggleTodo,
    className,
    children,
  } = props;

  const childrenProps = {
    todos,
    addTodo,
    toggleTodo,
  };

  return (
    <div className={classNames('todos', className)}>
      <TodosContext.Provider value={childrenProps}>
        {children}
      </TodosContext.Provider>
    </div>
  );
};

Todos.propTypes = {
  todos: PropTypes.arrayOf(PropTypes.object).isRequired,
  addTodo: PropTypes.func,
  toggleTodo: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Todos;
