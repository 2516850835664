import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import Divider from 'ui/Divider';
import Typography from 'ui/Typography';

import Chip from 'ui/Chip';

const IntegrationItem = (props) => {
  const {
    icon,
    name,
    items,
  } = props;

  return (
    <Elevation
      rounded="m"
      fullHeight
    >
      <Flex
        padding="12px"
        spacing={1}
        alignItems="center"
      >
        {
          icon && (
            <Icon
              name={icon}
              type="custom"
              size="xl"
            />
          )
        }
        {
          name && (
            <Typography
              noMargin
              weight="bold"
            >
              {name}
            </Typography>
          )
        }
      </Flex>
      <Divider />
      <Flex
        padding="12px"
      >
        {
          items || (
            <Flex
              fullWidth
              alignItems="center"
              justifyContent="center"
            >
              <Chip
                variant={'disabled'}
                onClick={() => {}}
              >
                Calendar
              </Chip>
            </Flex>
          )
        }
      </Flex>
    </Elevation>
  );
};

IntegrationItem.defaultProps = {
  icon: null,
  name: null,
  items: null,
};

const {
  element,
  string,
} = PropTypes;

IntegrationItem.propTypes = {
  icon: string,
  name: string,
  items: element,
};

export default IntegrationItem;
