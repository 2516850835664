import { isObject } from 'shared/utility';

const noOp = () => {};

const defaultModalCallbacks = {
  onBeforeOpen: noOp,
  onAfterOpen: noOp,
  onBeforeClose: noOp,
  onAfterClose: noOp,
};

const getModalCallbacks = (modalOptions = {}) => {
  const {
    callbacks,
  } = modalOptions;

  if (!isObject(callbacks)) return defaultModalCallbacks;

  return {
    ...defaultModalCallbacks,
    ...callbacks,
  };
};

export default getModalCallbacks;
