/* eslint-disable react/jsx-no-bind, camelcase */

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import Button from 'ui/Button';
import Modal from 'ui/Modal';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import useProperty from 'shared/hooks/properties/useProperty';

import PropertiesPreviewSelector from 'sections/MLS/modules/PropertiesPreviewSelector';

import PropertyOverview from 'sections/Properties/modules/PropertyOverview';
import PropertyContact from 'sections/Properties/modules/PropertyContact';

import NewOfferForm from 'sections/Offers/forms/NewOfferForm';
import OffersContext from 'sections/OffersV2/context/OffersContext';

const version = getFeatureVersion('new offer modal');

const LogOfferModalUniversal = (props) => {
  const {
    isVisible,
    closeModal,
    onConfirm, // legacy version
  } = props;

  const [selectedPropertyId, setProperty] = useState(null);

  const [view, setView] = useState('property');

  const {
    seller,
    listing_agent,
  } = useProperty(selectedPropertyId);

  useEffect(() => {
    setProperty(null);
  }, [
    isVisible,
  ]);

  // only required for legacy version
  const handleNext = useCallback(() => {
    onConfirm(selectedPropertyId);
  }, [
    selectedPropertyId,
  ]);

  const { getAllOffers } = useContext(OffersContext);

  const handleSuccess = async () => {
    await getAllOffers();
    closeModal();
  };

  // only required for feature control
  const legacyFooterOptions = [
    <Button
      onClick={closeModal}
      variant="secondary"
    >
      Cancel
    </Button>,
    <Button
      variant="primary"
      disabled={!selectedPropertyId}
      onClick={handleNext}
    >
      Next
    </Button>,

  ];

  return (
    <Modal
      width={view === 'property' ? 735 : 1500}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title={view === 'property' ? 'Which property will this offer be for?' : null}
      footer={
        version !== 1
          ? (
              [
                <Button
                  onClick={closeModal}
                  variant="secondary"
                >
                  Cancel
                </Button>,
                view === 'property'
                  ? (
                    <Button
                      variant="primary"
                      icon="arrow-right"
                      iconPosition="right"
                      disabled={!selectedPropertyId}
                      onClick={() => { setView('offer'); }}
                    >
                      Next
                    </Button>
                    )
                  : (
                    <Button
                      variant="secondary"
                      icon="arrow-left"
                      onClick={() => { setView('property'); }}
                    >
                      Back to property selection
                    </Button>
                    ),
              ]
            )
          : legacyFooterOptions
      }
    >
      {
        view === 'property' && (
          <PropertiesPreviewSelector
            onSelect={setProperty}
            selected={selectedPropertyId}
          />
        )
      }
      {
        view === 'offer' && (
          <Flex
            fullWidth
            spacing={2}
            flexWrap="wrap"
            alignItems="stretch"
          >
            <FlexItem xs={16}>
              <Elevation
                rounded="s"
                padding="m"
                fullHeight
              >
                <PropertyOverview propertyId={selectedPropertyId} />
              </Elevation>
            </FlexItem>

            <FlexItem xs={8}>
              <PropertyContact propertyId={selectedPropertyId} />
            </FlexItem>

            <FlexItem fullWidth>
              <Elevation
                rounded="s"
                padding="m"
              >
                <NewOfferForm
                  propertyId={selectedPropertyId}
                  representing={listing_agent === 0 ? 'seller' : 'buyer'}
                  onSuccess={handleSuccess}
                  seller={seller}
                  sellerAgent={listing_agent}
                />
              </Elevation>
            </FlexItem>
          </Flex>
        )
      }
    </Modal>
  );
};

const {
  bool,
  func,
} = PropTypes;

LogOfferModalUniversal.propTypes = {
  isVisible: bool.isRequired,
  closeModal: func.isRequired,
  onConfirm: func.isRequired, // legacy version
};

export default LogOfferModalUniversal;
