import humps from 'humps';

/**
 * Creates serialized object of data attributes
 * @example
 * // returns { foo-bar: 1, hello-world: 2 }
 * dataAttributes({ fooBar: 1, helloWorld: 2 });
 * @param {Object} data - The object with camelized properties.
 * @returns {Object} The object with "kebab-cased" properties.
 */

const dataAttributes = (data) => {
  if (typeof data !== 'object') {
    return {};
  }

  const newData = humps.decamelizeKeys(data, {
    separator: '-',
    process(key, convert, options) {
      return `data-${convert(key, options)}`;
    },
  });

  return newData;
};

export default dataAttributes;
