import React from 'react';

import PropTypes from 'prop-types';

import {
  isEmpty,
} from 'shared/utility';

import ContactItem from '../ContactItem';

const You = (props) => {
  const {
    oneContactSelect,
    selectedContacts,
    onAddContact,
    onRemoveContact,
  } = props;

  return (
    <ContactItem
      contactId={0}
      oneContactSelect={oneContactSelect}
      isEmptyContactSelector={isEmpty(selectedContacts)}
      isSelected={selectedContacts.includes(0)}
      onAdd={onAddContact(0)}
      onRemove={onRemoveContact(0)}
    />
  );
};

You.defaultProps = {
  selectedContacts: [],
};

const {
  number,
  bool,
  arrayOf,
  func,
} = PropTypes;

You.propTypes = {
  oneContactSelect: bool.isRequired,
  selectedContacts: arrayOf(number),
  onAddContact: func.isRequired,
  onRemoveContact: func.isRequired,
};

export default You;
