import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_DOCUMENT_REQUESTS,
  SET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER,
  SET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER_COUNT,

  RESET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER,
  RESET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER_COUNT,

  RESET_REALTOR_DOCUMENT_REQUESTS_FILTERS,
  RESET_REALTOR_DOCUMENT_REQUESTS_FILTER_COUNTERS,

  SET_REALTOR_DOCUMENT_REQUEST_ATTACHMENTS,
} from 'store/actionTypes/realtorDocumentRequests';

import {
  getIds,
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  documentRequests: {},
  documentRequestsByFilter: {},
  documentRequestsCountByFilter: {},
};

const reducers = {
  [SET_REALTOR_DOCUMENT_REQUESTS]: (state, action) => ({
      ...state,
      documentRequests: {
        ...state.documentRequests,
        ...listToObjectByKey(action.payload),
      },
    }),

  [SET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER]: (state, action) => {
    const existing = state.documentRequestsByFilter[action.payload.filterId] || [];

    const items = [...new Set([
      ...existing,
      ...getIds(action.payload.items),
    ])];

    return {
      ...state,
      documentRequestsByFilter: {
        ...state.documentRequestsByFilter,
        [action.payload.filterId]: items,
      },
    };
  },

  [SET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    documentRequestsCountByFilter: {
      ...state.documentRequestsCountByFilter,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [RESET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER]: (state, action) => ({
    ...state,
    documentRequestsByFilter: {
      ...state.documentRequestsByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_DOCUMENT_REQUESTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    documentRequestsCountByFilter: {
      ...state.documentRequestsCountByFilter,
      [action.payload.filterId]: undefined,
    },
  }),

  [RESET_REALTOR_DOCUMENT_REQUESTS_FILTERS]: (state, action) => ({
    ...state,
    documentRequestsByFilter: {},
  }),

  [RESET_REALTOR_DOCUMENT_REQUESTS_FILTER_COUNTERS]: (state, action) => ({
    ...state,
    documentRequestsCountByFilter: {},
  }),

  [SET_REALTOR_DOCUMENT_REQUEST_ATTACHMENTS]: (state, action) => ({
    ...state,
    documentRequests: {
      ...state.documentRequests,
      [action.payload.id]: {
        ...state.documentRequests[action.payload.id],
        attachments: action.payload.attachments.map(({ attachmentId }) => attachmentId),
      },
    },
  }),
};

function documentRequests(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default documentRequests;
