export const SET_DOCUMENT_REQUEST_MODAL_OPEN = 'SET_DOCUMENT_REQUEST_MODAL_OPEN';
export const SET_DOCUMENT_REQUEST_MODAL_CLOSED = 'SET_DOCUMENT_REQUEST_MODAL_CLOSED';

export const SET_DOCUMENT_REQUEST_FORM_STATE = 'SET_DOCUMENT_REQUEST_FORM_STATE';
export const RESET_DOCUMENT_REQUEST_FORM_STATE = 'RESET_DOCUMENT_REQUEST_FORM_STATE';
export const SET_DOCUMENT_REQUEST_FORM_ATTACHMENTS = 'SET_DOCUMENT_REQUEST_FORM_ATTACHMENTS';

export const SET_LOG_NEW_QUESTION_MODAL_OPEN = 'SET_LOG_NEW_QUESTION_MODAL_OPEN';
export const SET_LOG_NEW_QUESTION_MODAL_CLOSED = 'SET_LOG_NEW_QUESTION_MODAL_CLOSED';

export const SET_LOG_NEW_QUESTION_FORM_STATE = 'SET_LOG_NEW_QUESTION_FORM_STATE';
export const RESET_LOG_NEW_QUESTION_FORM_STATE = 'RESET_LOG_NEW_QUESTION_FORM_STATE';
export const SET_LOG_NEW_QUESTION_FORM_ATTACHMENTS = 'SET_LOG_NEW_QUESTION_FORM_ATTACHMENTS';

export const SET_CANCEL_TRANSACTION_MODAL_OPEN = 'SET_CANCEL_TRANSACTION_MODAL_OPEN';
export const SET_CANCEL_TRANSACTION_MODAL_CLOSED = 'SET_CANCEL_TRANSACTION_MODAL_CLOSED';

export const SET_REALTOR_TRANSACTION = 'SET_REALTOR_TRANSACTION';
export const SET_REALTOR_TRANSACTIONS = 'SET_REALTOR_TRANSACTIONS';
export const SET_SELECTED_REALTOR_TRANSACTION = 'SET_SELECTED_REALTOR_TRANSACTION';

export const SET_REALTOR_TRANSACTIONS_FOR_FILTER_COUNT = 'SET_REALTOR_TRANSACTIONS_FOR_FILTER_COUNT';
export const SET_REALTOR_TRANSACTIONS_FOR_FILTER = 'SET_REALTOR_TRANSACTIONS_FOR_FILTER';

export const SET_REALTOR_TRANSACTIONS_FILTER_ITEM = 'SET_REALTOR_TRANSACTIONS_FILTER_ITEM';
export const RESET_REALTOR_TRANSACTIONS_FILTER_ITEM = 'RESET_REALTOR_TRANSACTIONS_FILTER_ITEM';
export const CLEAR_REALTOR_TRANSACTIONS_FILTER = 'CLEAR_REALTOR_TRANSACTIONS_FILTER';

export const SET_ADDENDUM_CHANGE_FORM_STATE = 'SET_ADDENDUM_CHANGE_FORM_STATE';
export const RESET_ADDENDUM_CHANGE_FORM_STATE = 'RESET_ADDENDUM_CHANGE_FORM_STATE';
export const SET_ADDENDUM_CHANGE_FORM_ATTACHMENTS = 'SET_ADDENDUM_CHANGE_FORM_ATTACHMENTS';

export const SET_EXTENSION_REQUEST_FORM_STATE = 'SET_EXTENSION_REQUEST_FORM_STATE';
export const RESET_EXTENSION_REQUEST_FORM_STATE = 'RESET_EXTENSION_REQUEST_FORM_STATE';
export const SET_EXTENSION_REQUEST_FORM_ATTACHMENTS = 'SET_EXTENSION_REQUEST_FORM_ATTACHMENTS';

export const SET_REALTOR_TRANSACTION_ATTACHMENTS = 'SET_REALTOR_TRANSACTION_ATTACHMENTS';

export const SET_REALTOR_TRANSACTIONS_OVERALL_SUMMARY = 'SET_REALTOR_TRANSACTIONS_OVERALL_SUMMARY';

export const SET_REALTOR_INFINITE_SCROLL_TRANSACTIONS_FOR_FILTER = 'SET_REALTOR_INFINITE_SCROLL_TRANSACTIONS_FOR_FILTER';
