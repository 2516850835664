import { SET_DICTIONARY } from 'store/actions/dictionary'; // eslint-disable-line import/no-cycle

const initialState = {
  dictionaries: {},
};

function dictionary(state = initialState, action) {
  switch (action.type) {
    case SET_DICTIONARY:
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          [action.key]: action.value,
        },
      };

    default:
      return state;
  }
}

export default dictionary;
