import React, { Component } from 'react';
import {
  Row, Col, Select, Form, Modal, Input, Cascader,
} from 'antd';

import './LinkToURLForm.scss';

const { Option } = Select;

export const LinkToURLForm = Form.create()(
  class extends Component {
    setFormLabel = (children) => (
      <span className="filter-label-text">{children}</span>
    );

    state = {
      data: [],
      searchValue: undefined,
      categories: [],
    };


    handleChange = (searchValue) => {
      this.setState({ searchValue });
    };

    onSelectCategory = (value) => {
      this.setState({ categories: value });
    };

    render() {
      const { onCancel, onCreate, data } = this.props;

      const { getFieldDecorator } = this.props.form;


      const {
        linkToUrlModal: { isVisible },
        categoriesInfo,
        searchArticles,
        searchSuggestions,
      } = data;


      const categories = categoriesInfo ? categoriesInfo.categories : [];

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      };

      // options for search
      let options = [];
      if (Array.isArray(searchSuggestions) && searchSuggestions) {
        options = searchSuggestions.map((article) => (
          <Option
            key={article.id}
            value={article.id}
          >
            {article.title}
          </Option>
        ));
      }

      return (
        <div>
          <Modal
            title="Link To URL"
            okText="Create"
            visible={isVisible}
            onCancel={() => {
              onCancel();
            }}
            onOk={() => {
              onCreate();
              this.props.data.link(this.state.url);
            }}
            wrapClassName="create-category-modal"
          >
            <Form {...formItemLayout} className="filter-form" hideRequiredMark layout="vertical">

              <Form.Item
                colon={false}
                label={this.setFormLabel('URL')}
              >
                <Row gutter={8}>
                  <Col span={18}>
                    {getFieldDecorator('url', {
                      rules: [{ required: false, message: 'Please enter url.' }],
                    })(
                      <Input
                        onChange={(e) => { this.setState({ url: e.target.value }); }}
                        placeholder="Enter url here"
                      />,
                    )}
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                colon={false}
                label={this.setFormLabel('Search')}
              >
                <Row gutter={8}>
                  <Col span={18}>
                    {getFieldDecorator('article', {
                      rules: [{ required: false, message: 'Please enter category name.' }],
                    })(
                      <Select
                        showSearch
                        allowClear
                        placeholder={this.props.placeholder}
                        style={this.props.style}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={(value) => searchArticles(value, { category: this.state.categories[0], subcategory: this.state.categories[1] })}
                        onChange={this.handleChange}
                        notFoundContent={null}
                      >
                        {options}
                      </Select>,
                    )}
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                colon={false}
                label={this.setFormLabel('Find')}
              >
                <Row gutter={8}>
                  <Col span={18}>
                    {getFieldDecorator('relatedProduct', {
                      rules: [{ required: false, message: 'Please select related product!' }],
                    })(
                      <Cascader
                        options={categories}
                        changeOnSelect
                        onChange={this.onSelectCategory}
                        showSearch
                        notFoundContent="You haven`t any categories"
                        fieldNames={{ label: 'name', value: 'id', children: 'subcategories' }}
                      />,
                    )}
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      );
    }
  },
);
