import apiConnector, { endpoints } from 'api';

const getTodayEmployeeAttendance = async (employeeId) => {
  const endpoint = `${endpoints.client.hr.todayEmployeeAttendance}/${employeeId}`;

  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getTodayEmployeeAttendance;
