import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Checkbox from 'ui/Checkbox';
import Typography from 'ui/Typography';

import { CustomFlex, CustomTypography } from './styledItems';

const CheckboxLabelled = (props) => {
  const {
    value,
    onChange,
    labelComponent,
    labelText,
    highlight,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback((newValue) => {
    onChange(newValue);
    setCurrentValue(newValue);
  }, [
    onChange,
  ]);

  const myLabelComponent = useMemo(() => labelComponent || (
    <Typography
      variant="caption"
      noMargin

    >
      {labelText}
    </Typography>
  ), [
    labelComponent,
    labelText,
  ]);

  return (
    <CustomFlex
      spacing={1}
      alignItems="center"
      checked={!!currentValue}
    >
      <Checkbox
        checked={currentValue}
        onChange={handleChange}
      />
      {
      highlight ? (
        <CustomTypography
          variant="caption"
          noMargin
          checked={!!currentValue}
        >
          {labelText}
        </CustomTypography>
      )
      : myLabelComponent
      }
    </CustomFlex>
  );
};

CheckboxLabelled.defaultProps = {
  value: false,
  labelComponent: null,
  labelText: '',
  highlight: false,
};

const {
  bool,
  element,
  func,
  string,
} = PropTypes;

CheckboxLabelled.propTypes = {
  value: bool,
  onChange: func.isRequired,
  labelComponent: element,
  labelText: string,
  highlight: bool,
};

export default CheckboxLabelled;
