import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getTransactionById,
} from 'store/actions/realtorTransactions';

import DocumentRequests from './DocumentRequests';

const mapStateToProps = (state, ownProps) => {
  const { transactionId } = ownProps;

  const newDocumentRequests = selectors.realtorDocumentRequests.byFilter({
    transactionId,
    status: 1,
  })(state) || [];

  const outstandingDocumentRequests = selectors.realtorDocumentRequests.byFilter({
    transactionId,
    status: 2,
  })(state) || [];

  const respondedDocumentRequests = selectors.realtorDocumentRequests.byFilter({
    transactionId,
    status: 3,
  })(state) || [];

  return {
    newDocumentRequests,
    outstandingDocumentRequests,
    respondedDocumentRequests,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAfterUpdate: () => {
    dispatch(getTransactionById(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRequests);
