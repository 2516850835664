import { connect } from 'react-redux';

import { sortBy } from 'shared/utility';

import selectors from 'store/selectors';

import {
  addPropertyRelations,
  removePropertyRelations,
} from 'store/actions/properties';

import {
  addPropertyRequirementRelations,
  removePropertyRequirementRelations,
} from 'store/actions/propertyRequirements';

import AdditionalAmenities from './AdditionalAmenities';

const mapStateToProps = (state, ownProps) => {
  const {
    itemType,
    itemId,
  } = ownProps;

  const amenities = selectors.dictionary.amenities(state);

  let item = {};

  if (itemType === 'properties') {
    item = selectors.properties.byId(state, itemId);
  }

  if (itemType === 'property_requirements') {
    item = selectors.propertyRequirements.byId(state, itemId);
  }

  const allAmenities = sortBy(amenities, ['name']);

  return {
    allAmenities,
    selectedAmenities: item.amenities || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    itemType,
    itemId,
  } = ownProps;

  return {
    addAmenity: (amenityId) => {
      if (itemType === 'properties') {
        dispatch(addPropertyRelations('amenities')(itemId)([amenityId]));
      }

      if (itemType === 'property_requirements') {
        dispatch(addPropertyRequirementRelations('amenities')(itemId)([amenityId]));
      }
    },
    removeAmenity: (amenityId) => {
      if (itemType === 'properties') {
        dispatch(removePropertyRelations('amenities')(itemId)([amenityId]));
      }

      if (itemType === 'property_requirements') {
        dispatch(removePropertyRequirementRelations('amenities')(itemId)([amenityId]));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAmenities);
