import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import selectors from 'store/selectors';

import ContactInfo from './ContactInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);
  const settings = selectors.appCustomization.settings(state);

  return {
    contactId: contact?.id,
    type: contact?.type,
    isVip: contact?.isVip,
    // labels: contact?.labels,
    withSalutations: !!settings.salutations_on,
    socialLinks: [
      {
        name: 'facebook',
        url: contact?.facebook_url,
        icon: 'facebook',
      },
      {
        name: 'twitter',
        url: contact?.twitter_url,
        icon: 'twitter',
      },
      {
        name: 'linkedin',
        url: contact?.linkedin_url,
        icon: 'linkedin',
      },
      {
        name: 'instagram',
        url: contact?.instagram_url,
        icon: 'instagram',
      },
      {
        name: 'website',
        url: contact?.website_url,
        icon: 'globe',
      },
    ],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => debounce((value) => {
    dispatch(updateContact(ownProps.contactId)({
      [key]: value,
    }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
