import {
  SET_NOTABLE_POINT,
} from 'store/actionTypes/realtor-notable-points-disclosures';

const setNotablePoint = (point) => ({
  type: SET_NOTABLE_POINT,
  payload: point,
});

export default setNotablePoint;
