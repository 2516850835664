import {
  SET_NEW_REALTOR_CONTACT_FORM,
} from 'store/actionTypes/realtorContacts';

const setNewContactForm = (values = {}) => ({
  type: SET_NEW_REALTOR_CONTACT_FORM,
  payload: values,
});

export default setNewContactForm;
