import { createSelector } from 'reselect';
import { isEmpty } from 'shared/utility';

import store from 'store';

import { optimizedRealtorLeadsActions, setSelectedLeadProperty } from 'store/actions/realtorLeads'; // eslint-disable-line import/no-cycle

export default createSelector(
  (state) => state.realtorLeads.leads,
  (state) => state.realtorLeads.selectedLead,
  (state) => state.realtorLeads.selectedProperty,
  (leads, selectedLead, selectedProperty) => {
    if (selectedLead && (!selectedProperty?.id || Number(selectedProperty.leadId) !== Number(selectedLead))) {
      const lead = leads[selectedLead];

      if (!lead) {
        optimizedRealtorLeadsActions.getLeadById(selectedLead);
        return {
          id: null,
          type: null,
          leadId: Number(selectedLead),
        };
      }

      if (!isEmpty(lead.properties)) {
        store.dispatch(setSelectedLeadProperty({
          id: lead.properties[0],
          type: 'properties',
          leadId: Number(selectedLead),
        }));
      }
      if (!isEmpty(lead.property_requirements)) {
        store.dispatch(setSelectedLeadProperty({
          id: lead.property_requirements[0],
          type: 'property_requirements',
          leadId: Number(selectedLead),
        }));
      }

      return {
        id: null,
        type: null,
        leadId: Number(selectedLead),
      };
    }

    return selectedProperty;
  },
);
