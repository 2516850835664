import {
  SET_CRM_CONTACTS,
} from 'store/actionTypes/crm';

const setContacts = (contacts) => ({
  type: SET_CRM_CONTACTS,
  payload: contacts,
});

export default setContacts;
