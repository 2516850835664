import React, {
  Fragment,
} from 'react';

import PropTypes from 'prop-types';

import {
  isEmpty,
} from 'shared/utility';

import Spin from 'ui/Spin';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import InfiniteScroll from 'react-infinite-scroller';

import CenterBox from 'shared/components/UI/CenterBox';

import ContactItem from '../ContactItem';

import {
  ScrollContainer,
} from './styledItems';

const ContactsList = (props) => {
  const {
    oneContactSelect,
    selectedContacts,
    onAddContact,
    onRemoveContact,
    contactsList,
    canLoadMoreContacts,
    loadMoreContacts,
    rows,
  } = props;

  return (
    <ScrollContainer rows={rows}>
      <InfiniteScroll
        loadMore={loadMoreContacts}
        hasMore={canLoadMoreContacts}
        loader={<CenterBox><Spin /></CenterBox>}
        useWindow={false}
        threshold={88}
      >
        <Flex
          spacing={2}
          flexWrap="wrap"
        >
          {
            contactsList.map((contactId) => (
              <Fragment key={contactId}>
                <FlexItem
                  xs={7.5} // weird but this is because of the custom scrollbar
                >
                  <ContactItem
                    contactId={contactId}
                    oneContactSelect={oneContactSelect}
                    isEmptyContactSelector={isEmpty(selectedContacts)}
                    isSelected={selectedContacts.includes(contactId)}
                    onAdd={onAddContact(contactId)}
                    onRemove={onRemoveContact(contactId)}
                  />
                </FlexItem>
              </Fragment>
            ))
          }
        </Flex>
      </InfiniteScroll>
    </ScrollContainer>
  );
};

ContactsList.defaultProps = {
  selectedContacts: [],
  rows: 3,
  contactsList: [],
  canLoadMoreContacts: false,
};

const {
  number,
  bool,
  arrayOf,
  func,
} = PropTypes;

ContactsList.propTypes = {
  oneContactSelect: bool.isRequired,
  selectedContacts: arrayOf(number),
  onAddContact: func.isRequired,
  onRemoveContact: func.isRequired,
  rows: number,
  contactsList: arrayOf(number),
  canLoadMoreContacts: bool,
  loadMoreContacts: func.isRequired,
};

export default ContactsList;
