import { createSelector } from 'reselect';
import stableStringify from 'json-stable-stringify';

const filterObj = (state) => state.realtorLeadsObjectsFilter;

export default createSelector(
  filterObj,
  (filter) => ({
    filterId: stableStringify({
      sortBy: filter.sortBy,
      sortDirection: filter.sortDirection,
    }),
    filter: {
      sort_by: filter.sortBy,
      sort_direction: filter.sortDirection,
    },
  }),
);
