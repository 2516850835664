import React from 'react';

import DescriptionCell from './components/DescriptionCell';
import ProposedFixCell from './components/ProposedFixCell';
import StatusCell from './components/StatusCell';
import ValueCell from './components/ValueCell';
import TitleCell from './components/TitleCell';
import NoteCell from './components/NoteCell';
import DeleteButton from './components/DeleteButton';
import AttachmentsCell from './components/AttachmentsCell';
import InspectionCell from './components/InspectionCell';

const columns = ({
  hoveredRecord,
  transactionId,
}) => ([
  {
    title: 'Title',
    width: 150,
    dataIndex: 'title',
    key: 'title',
    render: (title) => (
      <TitleCell
        title={title}
      />
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200,
    render: (value, row) => (
      <DescriptionCell description={value} requestId={row?.id} />
    ),
  },
  {
    title: 'Related inspection',
    dataIndex: 'inspection_id',
    key: 'inspection_id',
    width: 200,
    render: (value, row) => (
      <InspectionCell inspectionId={value} />
    ),
  },
  {
    title: 'Est. Value',
    width: 150,
    dataIndex: 'value',
    key: 'value',
    render: (value, row) => (
      <ValueCell estimatedValue={value} requestId={row?.id} />
    ),
  },
  {
    title: 'Image/File',
    width: 150,
    dataIndex: 'id',
    key: 'attachments',
    className: 'realtor-repair-requests-table__sm-padding',
    render: (value) => (
      <AttachmentsCell requestId={value} />
    ),
  },
  {
    title: 'Proposed Fix',
    width: 80,
    dataIndex: 'proposed_fix_type_id',
    key: 'proposedFix',
    render: (value, row) => (
      <ProposedFixCell fixType={value} requestId={row?.id} transactionId={transactionId} />
    ),
  },
  {
    title: 'Notes',
    width: 60,
    dataIndex: 'notes',
    key: 'notes',
    className: 'realtor-repair-requests-table__sm-padding',
    render: (value, row) => (
      <NoteCell hasContent={value && value !== '<p></p>'} requestId={row?.id} />
    ),
    align: 'center',
  },
  {
    title: 'Status',
    width: 80,
    dataIndex: 'status',
    key: 'status',
    render: (value, row) => (
      <StatusCell status={value} requestId={row?.id} transactionId={transactionId} />
    ),
  },
  {
    title: '',
    width: 40,
    key: 'delete',
    className: 'realtor-repair-requests-table__sm-padding',
    render: (_, row) => {
      if (hoveredRecord === row.id) {
        return <DeleteButton requestId={row?.id} transactionId={transactionId} />;
      }

      return null;
    },
    align: 'center',
  },
]);

export default columns;
