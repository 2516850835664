import apiConnector, { endpoints } from 'api';

const updateArticle = (articleId) => async (articleInformations) => {
  const endpoint = `${endpoints.client.articles.articleGeneral}/${articleId}`;

  const result = await apiConnector.client.patch(endpoint, articleInformations);

  return result.data;
};

export default updateArticle;
