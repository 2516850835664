import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

const defaultFulfillment = {
  id: null,
  title: null,
  note: null,
  contact_id: null,
  company_id: null,
  invoices: [],
  payments: [],
  agreements: [],
};

export default createSelector(
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.selectedFulfillment,
  (collection, selectedId) => {
    const item = collection[selectedId];

    if (!item) {
      if (selectedId) {
        optimizedFulfillmentsActions.getFulfillmentById(selectedId);
      }

      return defaultFulfillment;
    }

    return item;
  },
);
