import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getAddresses from './getAddresses';
import getAddressById from './getAddressById';

import createAddress from './createAddress';

import setSelectedAddress from './setSelectedAddress';

import updateAddress from './updateAddress';

export { getAddresses };
export { getAddressById };

export { createAddress };

export { setSelectedAddress };

export { updateAddress };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedAddressesActions = {
  getAddressById: memoizeDebounce(
    (id) => store.dispatch(getAddressById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
