import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import moment from 'moment';
import { Col, Row } from 'antd';
import UserAvatar from 'shared/components/UserAvatarV2';
import './SingleNotice.scss';

class SingleNotice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  showOptions = (visible) => () => this.setState({ visible });

  onDeleteNotice = () => {
    const {
      notice,
      deleteNotice,
    } = this.props;

    deleteNotice(notice.NoticeID);
  }

  onAcknowledgeNotice = () => {
    const {
      notice,
      acknowledgeNotice,
    } = this.props;

    acknowledgeNotice(notice.NoticeID);
  }

  render() {
    const {
      notice,
      employees,
      loggedUser,
    } = this.props;

    const {
      visible,
    } = this.state;

    let employee = employees.find(
      (employeeElement) => employeeElement.EmployeeID === notice.CreatedBy,
    );
    employee = employee || {};

    const noticeControlPanel = notice.CreatedBy === loggedUser.EmployeeID
      ? (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          onClick={this.onDeleteNotice}
          onKeyDown={this.onDeleteNotice}
          className="single-notice__delete single-notice__link"
        >
          Seen It, Remove It
        </span>
      )
      : (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          onClick={this.onAcknowledgeNotice}
          onKeyDown={this.onAcknowledgeNotice}
          className="single-notice__acknowledge single-notice__link"
        >
          Acknowledge
        </span>
      );

    return (
      employees && notice
      && (
        <div
          className="single-notice"
          onMouseOver={this.showOptions(true)}
          onFocus={this.showOptions(true)}
          onMouseLeave={this.showOptions(false)}
          onBlur={this.showOptions(false)}
        >
          <Row>
            <Col span={2}>
              <UserAvatar
                userType={1}
                userId={notice.CreatedBy}
                size="xxs"
              />
            </Col>

            <Col span={22}>
              <div>
                <span className="single-notice__user-name">
                  {employee.Name}
                  {' '}
                  {employee.Surname}
                </span>
                <span className="single-notice__date">
                  {' '}
                  {moment(notice.CreatedAt).format('h:mm A dddd, MMMM D')}
                </span>
              </div>

              <div>
                {notice.Description}
              </div>

              <div>
                {
                  visible ? (
                    noticeControlPanel
                  ) : null
                }
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  }
}
const {
  number,
  string,
  shape,
} = PropTypes;

SingleNotice.propTypes = {
  deleteNotice: func.isRequired,
  acknowledgeNotice: func.isRequired,
  notice: shape({
    CreatedBy: number,
    CreatedAt: string,
    Description: string,
    NoticeID: number,
  }).isRequired,
  employees: shape({
    EmployeeID: number,
    Name: string,
    Surname: string,
  }).isRequired,
  loggedUser: shape({
    EmployeeID: number,
  }).isRequired,
};

export default SingleNotice;
