import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Calendar as AntdCalendar } from 'antd';

const StyledCalendar = styled(AntdCalendar)`
  ${(props) => !props.highlightSelected && (
    `.ant-fullcalendar-selected-day {
      .ant-fullcalendar-date {
        background: transparent;
      }
      .ant-fullcalendar-value {
        color: inherit;
      }
    }`
  )}
`;

const Calendar = (props) => {
  const {
    highlightSelected,
  } = props;

  return (
    <StyledCalendar
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      highlightSelected={highlightSelected}
    />
  );
};

Calendar.defaultProps = {
  highlightSelected: false,
};

const {
  bool,
} = PropTypes;

Calendar.propTypes = {
  highlightSelected: bool,
};

export default Calendar;
