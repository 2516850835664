import { connect } from 'react-redux';
import selectors from 'store/selectors';

import AppCustomization from './AppCustomization';

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    salutationsState: settings.salutations_on ? 'ON' : 'OFF',
  };
};

export default connect(mapStateToProps)(AppCustomization);
