import {
  SET_REALTOR_OFFERS_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorOffers';

const setOverallSummary = (summary) => ({
  type: SET_REALTOR_OFFERS_OVERALL_SUMMARY,
  payload: summary,
});

export default setOverallSummary;
