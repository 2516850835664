import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import SelectableCounterItem from 'shared/components/UI/SelectableCounterItem';

import {
  Wrapper,
} from './styledItems';

const SummarySelector = (props) => {
  const {
    onSelect,
    items,
    activeFilter,
    className,
    inactiveItemBgc,
    selectedItemBgc,
    itemClassName,
    sameFontWeight,
    isInvesedTextSelectedColor,
    valueProps,
    titleProps,
  } = props;

  return (
    <Wrapper
      className={classnames({
        [className]: className,
      })}
    >
      <Flex
        container
        fullWidth
        spacing={4}
        spacingStep={2}
      >
        {
          items.map((item) => (
            <Fragment key={item.key}>
              <FlexItem
                fullWidth
              >
                <SelectableCounterItem
                  fullWidth
                  isSelected={Array.isArray(activeFilter) ? activeFilter.includes(item.key) : activeFilter === item.key}
                  title={item.title}
                  value={item.value}
                  onClick={onSelect(item.key)}
                  sameFontWeight={sameFontWeight}
                  inactiveItemBgc={inactiveItemBgc}
                  selectedItemBgc={selectedItemBgc}
                  isInvesedTextSelectedColor={isInvesedTextSelectedColor}
                  className={itemClassName}
                  valueProps={valueProps}
                  titleProps={titleProps}
                />
              </FlexItem>
            </Fragment>
          ))
        }
      </Flex>
    </Wrapper>
  );
};

SummarySelector.defaultProps = {
  items: [],
  className: null,
  activeFilter: '',
  sameFontWeight: false,
  onSelect: () => () => {},
  inactiveItemBgc: '#f6f8fa',
  selectedItemBgc: 'transparent',
  itemClassName: null,
  valueProps: {
    variant: 'h1',
    tag: 'span',
    weight: 700,
  },
  titleProps: {
    variant: 'h4',
  },
  isInvesedTextSelectedColor: false,
};

const {
  arrayOf,
  number,
  shape,
  func,
  bool,
  oneOfType,
  string,
} = PropTypes;

SummarySelector.propTypes = {
  onSelect: func,
  className: string,
  activeFilter: string,
  items: arrayOf(shape({
    key: string,
    title: string,
    value: oneOfType([number, string]),
  })),
  inactiveItemBgc: string,
  selectedItemBgc: string,
  itemClassName: string,
  sameFontWeight: bool,
  valueProps: shape(),
  titleProps: shape(),
  isInvesedTextSelectedColor: bool,
};

export default SummarySelector;
