import styled from 'styled-components';

const signSize = 35;
const signBorderColor = '#dfdfdf';

export const Wrapper = styled.div`
  padding-top: 10px;
`;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${signSize}px;
  width: ${signSize}px;
  border: solid 0.5px ${signBorderColor};
  border-radius: 50%;
  background-color: white;
  font-size: ${signSize * 0.5}px;
  cursor: ${(props) => (props.hasClick ? 'pointer' : 'default')};
  &:hover {
    background-color: #dfdfdf;
  }
`;

export const PopoverRoot = styled.div`
  padding: 15px 21px;
`;

export const PopoverTitle = styled.h1`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: rgba(0, 25, 52, 0.87);

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const PopoverTime = styled.p`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #172b4d;
`;

export const PopoverDescription = styled.p`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;

  &:first-letter {
    text-transform: capitalize;
  }
`;

export default {
  Root,
  PopoverRoot,
  PopoverTitle,
  PopoverTime,
  PopoverDescription,
};
