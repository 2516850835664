import {
  SET_TASKS_COUNT_FOR_EMPLOYEE,
} from 'store/actionTypes/tasks';

const setTasksCountForEmployee = (employeeId) => (tasksCount) => ({
  type: SET_TASKS_COUNT_FOR_EMPLOYEE,
  payload: {
    employeeId,
    tasksCount,
  },
});

export default setTasksCountForEmployee;
