import requests from 'api/requests';

import setOverallTicketsSummary from './setOverallTicketsSummary';

const getOverallTicketsSummary = () => async (dispatch) => {
  try {
    const summaryResponse = await requests.tickets.getOverallTicketsSummary();

    dispatch(setOverallTicketsSummary(summaryResponse.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOverallTicketsSummary;
