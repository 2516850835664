import apiConnector, { endpoints } from 'api';

const getChatById = async (chatId) => {
  const endpoint = `${endpoints.client.chat.chatInformation}/${chatId}`;

  const response = await apiConnector.client.get(endpoint);

  return response;
};

export default getChatById;
