import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Upload,
} from 'antd';

import Grid from 'ui/Grid';

import Button from 'ui/Button';

class ImageUploadControls extends Component {
  constructor(props) {
    super(props);

    this.fileUpload = React.createRef();
  }

  componentDidMount() {
    const {
      showSystemUpload,
    } = this.props;

    if (showSystemUpload) {
      this.fileUpload.current.click();
    }
  }

  onChange = (event) => {
    const { onAdd } = this.props;
    const file = event.target.files[0];

    onAdd({ file });
  }

  render() {
    const {
      onAdd,
      onRemove,
      isShowRemoveButton,
    } = this.props;

    return (
      <Grid container spacing={2} justify="center">
        <input
          type="file"
          id="my_file"
          style={{ display: 'none' }}
          ref={this.fileUpload}
          onChange={this.onChange}
        />
        {
          isShowRemoveButton && (
            <Grid item>
              <Button
                variant="tertiary"
                onClick={onRemove}
              >
                Remove
              </Button>
            </Grid>
          )
        }
        <Grid item>
          <Upload
            showUploadList={false}
            customRequest={onAdd}
          >
            <Button
              variant="secondary"
              onClick={() => {}}
            >
              Add New
            </Button>
          </Upload>
        </Grid>
      </Grid>
    );
  }
}

ImageUploadControls.defaultProps = {
  showSystemUpload: false,
};

const {
  bool,
  func,
} = PropTypes;

ImageUploadControls.propTypes = {
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  showSystemUpload: bool,
  isShowRemoveButton: bool.isRequired,
};

export default ImageUploadControls;
