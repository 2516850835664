import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tutorial from '../shared/Tutorial';

import tutorialRoutes from '../tutorialRoutes';

import './HrTutorial.scss';

const HrTutorial = (props) => {
  const { isVisible } = props;
  const openEmployeeModal = () => {
    const {
      showEmployeeGeneralModal,
    } = props;
    if (!isVisible) showEmployeeGeneralModal();
  };

  const closeEmployeeModal = () => {
    const {
      showEmployeeGeneralModal,
    } = props;
    if (isVisible) showEmployeeGeneralModal();
  };

  const goToStep = useCallback((index) => {
    const {
      setStepIndex,
      startTutorial,
      endTutorial,
      history: {
        push,
      },
      steps,
    } = props;

    const stepActions = {
      'tutorial-add-employees': () => {
        closeEmployeeModal();
        window.history.pushState(null, null, tutorialRoutes.hr.addEmployees);
      },
      'tutorial-create-teams': () => {
        closeEmployeeModal();
        push('/team/departments-teams');
        window.history.pushState(null, null, tutorialRoutes.hr.createTeams);
      },
      'tutorial-process-attendance': () => {
        closeEmployeeModal();
        push('/team/manager-attendance/processing');
        window.history.pushState(null, null, tutorialRoutes.hr.processAttendance);
      },
      'tutorial-attendance-reports': () => {
        closeEmployeeModal();
        push('/team/manager-attendance/reports');
        window.history.pushState(null, null, tutorialRoutes.hr.attendanceReports);
      },
      'tutorial-clock-in-out': () => {
        closeEmployeeModal();
        push('/team/my-attendance');
        window.history.pushState(null, null, tutorialRoutes.hr.clockInOut);
      },
      'tutorial-update-employee-records': () => {
        push('/team/employees');
        openEmployeeModal();
        setTimeout(() => {
          const button = document.querySelector('[data-tutorial="update-employee-records"]');
          button.innerText = 'Demo Employee';
        }, 500);
        endTutorial();
        setTimeout(() => {
          startTutorial();
        }, 1500);
        window.history.pushState(null, null, tutorialRoutes.hr.updateEmployeeRecord);
      },
    };

    const currentStep = steps[index].name;

    stepActions[currentStep]();

    setStepIndex(index);
  }, [isVisible]);

  const finishTutorial = useCallback(() => {
    const {
      endTutorial,
      hideTutorialLayer,
      history: {
        push,
      },
      setStepIndex,
    } = props;
    endTutorial();
    setStepIndex(0);
    closeEmployeeModal();
    hideTutorialLayer();
    push('/team');
  }, [isVisible]);

  const {
    stepName,
    steps,
  } = props;

  return (
    <Tutorial
      className="hr-tutorial"
      stepName={stepName}
      steps={steps}
      goToStep={goToStep}
      finishTutorial={finishTutorial}
      name="Team"
    />
  );
};

const {
  any,
  bool,
  func,
  string,
  objectOf,
  arrayOf,
} = PropTypes;

HrTutorial.defaultProps = {
  isVisible: false,
  stepName: '',
};

HrTutorial.propTypes = {
  history: objectOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
  startTutorial: func.isRequired,
  endTutorial: func.isRequired,
  hideTutorialLayer: func.isRequired,
  isVisible: bool,
  showEmployeeGeneralModal: func.isRequired,
  stepName: string,
  setStepIndex: func.isRequired,
  steps: arrayOf(any).isRequired, // eslint-disable-line react/forbid-prop-types
};

export default HrTutorial;
