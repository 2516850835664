/**
 * Filters steps array according to permissions level
 * @param {array} steps required
 * @param {bool} hasManagerPermissions
 * @param {bool} hasAdminPermissions
 * @return {array}
 */
const filterSteps = (steps, hasManagerPermissions, hasAdminPermissions) => {
  let filteredSteps;
  if (!hasManagerPermissions) {
    filteredSteps = filterManager(steps);
  }
  if (!hasAdminPermissions) {
    filteredSteps = filterAdmin(filteredSteps || steps);
  }
  return filteredSteps || steps;
};

const filterManager = (steps) => {
  const filteredSteps = steps.filter((step) => !step.manager);
  return filteredSteps;
};

const filterAdmin = (steps) => {
  const filteredSteps = steps.filter((step) => !step.admin);
  return filteredSteps;
};

export default filterSteps;
