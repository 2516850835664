import connectReducers from 'store/connectReducers';

// import testLeads from 'store/testData/realtor/leads';

import {
  SET_REALTOR_LEAD,
  SET_REALTOR_LEADS,
  SET_REALTOR_LEADS_FOR_FILTER,
  SET_REALTOR_LEADS_FOR_FILTER_COUNT,
  SET_REALTOR_OVERALL_LEADS_SUMMARY,
  SET_REALTOR_SELECTED_LEAD,
  SET_REALTOR_SELECTED_LEAD_ROWS,
  SET_REALTOR_LEAD_CHANGELOG,
  SET_REALTOR_LEAD_VIEWERS,
  SET_REALTOR_SELECTED_LEAD_PROPERTY,
  SET_REALTOR_LEADS_OBJECTS_FOR_FILTER,
  SET_REALTOR_LEADS_OBJECTS_COUNT_FOR_FILTER,
} from 'store/actionTypes/realtorLeads';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  leads: {},
  leadsObjectsByFilter: {},
  leadsObjectsCountByFilter: {},
  leadsByFilter: {},
  leadsByFilterCount: {},
  overallLeadsSummary: null,
  selectedLead: null,
  selectedProperty: {},
  changelog: {},
  selectedLeadRows: [],
  leadViewers: {},
};

const reducers = {
  [SET_REALTOR_LEAD_VIEWERS]: (state, action) => ({
    ...state,
    leadViewers: {
      [action.payload.leadId]: action.payload.viewers,
    },
  }),

  [SET_REALTOR_SELECTED_LEAD]: (state, action) => ({
    ...state,
    selectedLead: action.payload,
  }),

  [SET_REALTOR_SELECTED_LEAD_PROPERTY]: (state, action) => ({
    ...state,
    selectedProperty: action.payload,
  }),

  [SET_REALTOR_SELECTED_LEAD_ROWS]: (state, action) => ({
    ...state,
    selectedLeadRows: action.payload,
  }),

  [SET_REALTOR_LEADS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    leadsByFilterCount: {
      ...state.leadsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_LEAD]: (state, action) => ({
    ...state,
    leads: {
      ...state.leads,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_LEADS]: (state, action) => ({
    ...state,
    leads: {
      ...state.leads,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_REALTOR_LEADS_OBJECTS_FOR_FILTER]: (state, action) => {
    const current = state.leadsObjectsByFilter[action.payload.filterId] || [];

      return {
        ...state,
        leadsObjectsByFilter: {
          ...state.leadsObjectsByFilter,
          [action.payload.filterId]: [
            ...current,
            ...action.payload.leadsObjectsByFilter,
          ],
        },
      };
  },

  [SET_REALTOR_LEADS_OBJECTS_COUNT_FOR_FILTER]: (state, action) => ({
    ...state,
    leadsObjectsCountByFilter: {
      ...state.leadsObjectsCountByFilter,
      [action.payload.filterId]: action.payload.leadsObjectsCountByFilter,
    },
  }),

  [SET_REALTOR_LEADS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      leads,
    } = action.payload;

    // const filterProductIds = state.leadsByFilter[filterId] || [];

    const updatedByIds = new Set([
      ...getIds(leads), // this order is important for currect updates
      // ...filterProductIds,
    ]);

    const updatedState = {
      ...state,
      leadsByFilter: {
        ...state.leadsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_OVERALL_LEADS_SUMMARY]: (state, action) => ({
    ...state,
    overallLeadsSummary: action.payload,
  }),

  [SET_REALTOR_LEAD_CHANGELOG]: (state, action) => ({
    ...state,
    changelog: {
      ...state,
      [action.payload.leadId]: action.payload.changelog,
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
