/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { refType } from 'oa/types/index';

const Item = (props) => {
  const {
    icon,
    children,
    className,
    onClick,
    allyText,
    btnRef,
  } = props;

  return (
    <li className={classNames('room-actions__item', className)}>
      <button
        className="room-actions__btn"
        type="button"
        onClick={onClick}
        ref={btnRef}
      >
        {allyText && <span className="visually-hidden">{allyText}</span>}
        <FontAwesomeIcon icon={icon} />
      </button>

      {children}
    </li>
  );
};

Item.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  allyText: PropTypes.string.isRequired,
  btnRef: refType,
};

export default Item;
