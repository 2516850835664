import requests from 'api/requests';

import setLinkedObjectsSummaries from './setLinkedObjectsSummaries';

const getLinkedObjectsSummaries = (contactId) => async (dispatch) => {
  try {
    const response = await requests.crm.contacts.getLinkedObjectsSummaries(contactId);

    dispatch(setLinkedObjectsSummaries(contactId, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getLinkedObjectsSummaries;
