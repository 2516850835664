import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_TRANSACTION,
  SET_REALTOR_TRANSACTIONS,
  SET_SELECTED_REALTOR_TRANSACTION,
  SET_REALTOR_TRANSACTIONS_FOR_FILTER_COUNT,
  SET_REALTOR_TRANSACTIONS_FOR_FILTER,
  SET_REALTOR_TRANSACTION_ATTACHMENTS,
  SET_REALTOR_TRANSACTIONS_OVERALL_SUMMARY,
  SET_REALTOR_INFINITE_SCROLL_TRANSACTIONS_FOR_FILTER,
} from 'store/actionTypes/realtorTransactions';

import {
  listToObjectByKey,
  getIds,
} from 'store/utils';

const initialState = {
  transactions: {},
  transactionsByFilter: {},
  transactionsByFilterCount: {},
  selectedTransaction: null,
  overallSummary: null,
};

const reducers = {
  [SET_REALTOR_TRANSACTIONS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    transactionsByFilterCount: {
      ...state.transactionsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_REALTOR_INFINITE_SCROLL_TRANSACTIONS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      transactions,
    } = action.payload;

    const current = state.transactionsByFilter[filterId] || [];

    const updatedByIds = new Set([
      ...current,
      ...getIds(transactions),
    ]);

    const updatedState = {
      ...state,
      transactionsByFilter: {
        ...state.transactionsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_TRANSACTIONS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      transactions,
    } = action.payload;

    const updatedByIds = new Set([
      ...getIds(transactions),
    ]);

    const updatedState = {
      ...state,
      transactionsByFilter: {
        ...state.transactionsByFilter,
        [filterId]: [...updatedByIds],
      },
    };

    return updatedState;
  },

  [SET_REALTOR_TRANSACTION]: (state, action) => ({
    ...state,
    transactions: {
      ...state.transactions,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REALTOR_TRANSACTIONS]: (state, action) => ({
    ...state,
    transactions: {
      ...state.transactions,
      ...listToObjectByKey(action.payload),
    },
  }),

  [SET_SELECTED_REALTOR_TRANSACTION]: (state, action) => ({
    ...state,
    selectedTransaction: action.payload,
  }),

  [SET_REALTOR_TRANSACTION_ATTACHMENTS]: (state, action) => ({
    ...state,
    transactions: {
      ...state.transactions,
      [action.payload.id]: {
        ...state.transactions[action.payload.id],
        attachments: action.payload.attachments.reduce((acc, item) => {
          if (!acc[item?.attachmentType]) {
            acc[item?.attachmentType] = [];
          }

          acc[item?.attachmentType].push(item?.attachmentId);

          return acc;
        }, {}),
      },
    },
  }),

  [SET_REALTOR_TRANSACTIONS_OVERALL_SUMMARY]: (state, action) => ({
      ...state,
      overallSummary: action.payload,
    }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
