import { connect } from 'react-redux';

import {
  create,
} from 'store/actions/external/realtor/leads';

import ExternalLeads from './ExternalLeads';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (leadData) => {
    dispatch(create(leadData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLeads);
