import { connect } from 'react-redux';

import {
  openNewCounterModal,
} from 'store/actions/newRealtorCounterModal';

import getOffersViewItems from 'store/actions/realtorOffers/getOffersViewItems';

import { openModal } from 'store/actions/modals';

import {
  openOfferDeclineReasonModal,
} from 'store/actions/offerDeclineReasonModal';

import Options from './Options';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDecline: () => dispatch(openOfferDeclineReasonModal(ownProps.offerId, false)),

  openNewCounterModal: () => {
    const onAfterSubmit = () => dispatch(getOffersViewItems());

    dispatch(openNewCounterModal(
      ownProps.offerId,
      ownProps.rootOfferId,
      onAfterSubmit,
    ));
  },

  openAcceptCounterModal: () => {
    dispatch(openModal('acceptOfferModal')({
      callbacks: {
        onAfterClose: () => {
          dispatch(getOffersViewItems());
        },
      },
    }, {
      offerId: ownProps.offerId,
      rootOfferId: ownProps.rootOfferId,
    }));
  },
  onDetails: () => {
    dispatch(openModal('offerDetails')({}, { offerId: ownProps.rootOfferId }));
  },
});

export default connect(null, mapDispatchToProps)(Options);
