import { createSelector } from 'reselect';

import { isEmpty } from 'shared/utility';
import moment from 'moment';

const filterObj = (state) => state.crmContactsFilter;
const sourceTypes = (state) => state.crmSources.sourceTypes;
const labels = (state) => state.crmContacts.labels;
const companies = (state) => state.crmCompanies.companies;

const contactTypes = {
  null: 'Not selected',
  all: 'All',
  1: 'General Contact',
  2: 'Customer',
  3: 'Potential Customer',
  4: 'Previous Customer',
  5: 'Reseller',
  6: 'Supplier',
  7: 'Partner',
  8: 'Client',
  9: 'Professional Service',
  10: 'Investor',
  11: 'Press & Media',
};

const getContactTypesChipValue = (contactType = []) => contactType.map((item) => contactTypes[item]).join(', ');

const getDateRangeChipValue = (range = []) => `${moment(range[0]).format('MMM D, YYYY')}–${moment(range[1]).format('MMM D, YYYY')}`; // eslint-disable-line max-len

const getSourcesChipValue = (filterSources, sourceTypesList) => filterSources.map((item) => sourceTypesList.find((type) => type.id === item).name).join(', '); // eslint-disable-line max-len

const getCompaniesChipValue = (company, allCompanies) => company.map((item) => allCompanies[item].Title).join(', '); // eslint-disable-line max-len, no-shadow

const getLabelsChipValue = (label, allLabels) => label.map((item) => allLabels[item].name).join(', '); // eslint-disable-line max-len, no-shadow

export default createSelector(
  filterObj,
  sourceTypes,
  companies,
  labels,
  (filter, sources, allCompanies, allLabels) => { // eslint-disable-line max-params
    const chips = [];

    if (!isEmpty(filter.contactType)) {
      chips.push({
        key: 'contactType',
        title: 'Type',
        value: getContactTypesChipValue(filter.contactType),
      });
    }

    if (!isEmpty(filter.company)) {
      chips.push({
        key: 'company',
        title: 'Company',
        value: getCompaniesChipValue(filter.company, allCompanies),
      });
    }

    if (!isEmpty(filter.lastContactRange)) {
      chips.push({
        key: 'lastContactRange',
        title: 'Last contact',
        value: getDateRangeChipValue(filter.lastContactRange),
      });
    }

    if (!isEmpty(filter.sourceType)) {
      chips.push({
        key: 'sourceType',
        title: 'Sources',
        value: getSourcesChipValue(filter.sourceType, sources),
      });
    }

    if (!isEmpty(filter.labels)) {
      chips.push({
        key: 'labels',
        title: 'Labels',
        value: getLabelsChipValue(filter.labels, allLabels),
      });
    }
    return chips;
  },
);
