import { createSelector } from 'reselect';

const contacts = (state) => state.crmContacts.contacts;

export default createSelector(
  contacts,
  (items) => (items
    ? Object.values(items).map((item) => ({
      id: item.SalesContactsID,
      contactId: item.SalesContactsID,
      title: `${item.FirstName} ${item.LastName}`,
      name: `${item.FirstName} ${item.LastName}`,
      role: item.Role,
    }))
    : null),
);
