import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PriceRangeDisplay from 'ui/PriceRangeDisplay';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    from: propertyRequirementData?.budget_min,
    to: propertyRequirementData?.budget_max,
  };
};

export default connect(mapStateToProps)(PriceRangeDisplay);
