import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette[props.variant] || 'gray'};
  padding: ${(props) => (props.variant === 'info' ? '2px 5px 2px 5px' : null)};
  border-radius: ${(props) => (props.variant === 'info' ? '5px' : null)};
`;

export default {
  Root,
};
