import { createSelector } from 'reselect';

import { optimizedDictionaryActions } from 'store/actions/dictionaryV2';

const labels = (state) => state.dictionaryV2.contactLabels;

export default createSelector(
  labels,
  (_, option) => option,
  (collection, returnValueOption = 'active') => {
    if (!collection) {
      optimizedDictionaryActions.getContactLabelsDictionary();
      return [];
    }

    if (returnValueOption === 'active') {
      return collection.reduce((accum, item) => {
        if (item.is_active) {
          accum.push({
            id: item.id,
            name: item.name,
            category: item.category,
            isActive: item.is_active,
          });
        }

        return accum;
      }, []);
    }

    return collection.map((item) => ({
      id: item.id,
      name: item.name,
      category: item.category,
      isActive: item.is_active,
    }));
  },
);
