import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { openModal } from 'store/actions/modals';

import OptionsCell from './OptionsCell';

const mapStateToProps = (state, ownProps) => {
  let email = '';

  if (ownProps.contactId) {
    const contact = selectors.realtorContacts.byId(state, ownProps.contactId);
    email = contact.email_primary;
  }

  return {
    email,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCall: () => {
    dispatch(openModal('call')({ contactId: ownProps.contactId }));
  },
  onFollowUp: () => {
    dispatch(openModal('followUpRemindersNew')({
      objectTypeId: 25,
      objectId: ownProps.contactId,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsCell);
