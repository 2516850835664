import connectReducers from 'store/connectReducers';

import {
  SET_STRIPE_CUSTOMER_SECRET,
  SET_STRIPE_CUSTOMER_ID,
  SET_STRIPE_PAYMENT_METHODS,
  SET_SELECTED_PAYMENT_METHOD,
  SET_BILLING_CONTACTS,
  SET_YEARLY_CONFIRMATION_ON,
  SET_YEARLY_CONFIRMATION_OFF,
  SET_MONTHLY_CONFIRMATION_ON,
  SET_MONTHLY_CONFIRMATION_OFF,
} from 'store/actionTypes/payment';

const initialState = {
  stripeCustomerId: null,
  stripeCustomerSecret: null,
  stripePaymentMethods: {
    primary: null,
    secondary: [],
  },
  selectedPaymentMethod: null,
  billingContacts: [],
  yearlyConfirmationVisible: false,
  monthlyConfirmationVisible: false,
};

const reducers = {
  [SET_STRIPE_CUSTOMER_SECRET]: (state, action) => ({
    ...state,
    stripeCustomerSecret: action.payload,
  }),

  [SET_STRIPE_CUSTOMER_ID]: (state, action) => ({
    ...state,
    stripeCustomerId: action.payload,
  }),

  [SET_STRIPE_PAYMENT_METHODS]: (state, action) => ({
    ...state,
    stripePaymentMethods: action.payload,
  }),

  [SET_SELECTED_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    selectedPaymentMethod: action.payload,
  }),

  [SET_BILLING_CONTACTS]: (state, action) => ({
    ...state,
    billingContacts: action.payload,
  }),

  [SET_YEARLY_CONFIRMATION_ON]: (state) => ({
    ...state,
    yearlyConfirmationVisible: true,
  }),

  [SET_YEARLY_CONFIRMATION_OFF]: (state) => ({
    ...state,
    yearlyConfirmationVisible: false,
  }),

  [SET_MONTHLY_CONFIRMATION_ON]: (state) => ({
    ...state,
    monthlyConfirmationVisible: true,
  }),

  [SET_MONTHLY_CONFIRMATION_OFF]: (state) => ({
    ...state,
    monthlyConfirmationVisible: false,
  }),
};

const payment = (state = initialState, action) => connectReducers(reducers, state, action);

export default payment;
