import { connect } from 'react-redux';
import {
  hideTaskReminderModal,
  stopTaskReminderInterval,
  startTaskReminderInterval,
  stopTaskTimerAndSendData,
} from 'store/actions/tasks';
import {
  showTicketModal,
  getTicketData,
  updateTicketProgress,
} from 'store/actions/tickets';
import TaskReminder from './TaskReminder';

const mapStateToProps = (state) => ({
  tasksList: state.tasks.tasksList,
  task: state.tasks.taskReminder.taskToRemind,
  startedAt: state.tasks.taskReminder.startedAt,
});

const mapDispatchToProps = (dispatch) => ({
  // openModal: () => dispatch(openModal()),
  // hideModal: () => dispatch(hideModal()),
  onOk: () => dispatch(hideTaskReminderModal()),
  onCancel: () => {
    dispatch(stopTaskTimerAndSendData());
    dispatch(hideTaskReminderModal());
  },
  startTaskReminderInterval: () => dispatch(startTaskReminderInterval()),
  getTicketData: (id) => dispatch(getTicketData(id)),
  // setTicketData: (status, ticket) => dispatch(setTicketData(status, ticket)),
  // updateProgress: (id, progress) => dispatch(updateTicketProgress(id, progress))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskReminder);
