import { connect } from 'react-redux';

import {
  setNewLeadNestedFormField,
} from 'store/actions/newRealtorLeadForm';

import LeadPropertyParametersArea from 'sections/Leads/inputs/LeadPropertyParametersArea';

const objName = {
  seller: 'propertyDetails',
  buyer: 'propertyRequirements',
};

const mapStateToProps = (state, ownProps) => {
  const {
    sectionType,
  } = ownProps;

  const {
    realtorNewLeadForm: {
      [objName[sectionType]]: {
        bedrooms,
        bathrooms,
        floorArea,
        plotArea,
        garageCapacity,
      },
    },
  } = state;

  return {
    parameters: {
      bedrooms,
      bathrooms,
      floor_area: floorArea,
      plot_area: plotArea,
      parking: garageCapacity,
    },
  };
};

const paramsDict = {
  bedrooms: 'bedrooms',
  bathrooms: 'bathrooms',
  floor_area: 'floorArea',
  plot_area: 'plotArea',
  parking: 'garageCapacity',
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChangeProperty: (parameterName, value) => (
    dispatch(setNewLeadNestedFormField(objName[ownProps.sectionType])(paramsDict[parameterName])(value))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadPropertyParametersArea);
