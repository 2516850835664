import connectReducers from 'store/connectReducers';

import {
  SET_LEADS_SUMMARY_FOR_EMPLOYEE,
  SET_OVERALL_LEADS_SUMMARY,
  SET_WON_EDIT_MODAL_VISIBILITY,
} from 'store/actionTypes/leads';

const initialState = {
  leadsSummaryByEmployees: {},
  leadsSummaryOverall: {},

  isWonEditModalVisible: false,
};

const reducers = {
  [SET_LEADS_SUMMARY_FOR_EMPLOYEE]: (state, action) => {
    const {
      employeeId,
      leadsSummary,
    } = action.payload;

    return {
      ...state,
      leadsSummaryByEmployees: {
        ...state.leadsSummaryByEmployees,
        [employeeId]: leadsSummary,
      },
    };
  },

  [SET_OVERALL_LEADS_SUMMARY]: (state, action) => {
    const leadsSummary = action.payload;

    return {
      ...state,
      leadsSummaryOverall: leadsSummary,
    };
  },

  [SET_WON_EDIT_MODAL_VISIBILITY]: (state, action) => {
    const isVisible = action.payload;

    return {
      ...state,
      isWonEditModalVisible: isVisible,
    };
  },
};

const leads = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default leads;
