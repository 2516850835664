import requests from 'api/requests';

import setLeads from './setLeads';
import setLeadsForFilter from './setLeadsForFilter';
import setLeadsForFilterCount from './setLeadsForFilterCount';

const getLeadsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.leads.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setLeads(response.data.items));
    dispatch(setLeadsForFilter(filterId)(response.data.items));
    dispatch(setLeadsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getLeadsForFilter;
