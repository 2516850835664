import {
  SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorUnansweredQuestions';

const setForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_UNANSWERED_QUESTIONS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setForFilterCount;
