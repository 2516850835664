import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ScrollContainer = styled.div`
  ${({ isScrollContainer }) => (isScrollContainer ? `
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
  ` : `
    margin-top: 10px;
  `)}

  padding-right: 10px;

  &::-webkit-scrollbar-track {
    background-color: rgba(128, 128, 128, 0.1);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 3px;
  }
`;
