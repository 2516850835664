import React from 'react';

import isDemoAccount from 'shared/utils/isDemoAccount';

import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import CallsSettings from 'sections/Calls/modules/CallsSettings';
import ImportSettings from 'sections/DataImport/modules/ImportSettings';

import SectionTitle from 'sections/Settings/SectionTitle';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';
import ProfileDetails from './ProfileDetails';
import AddressDetails from './AddressDetails';
import ContactDetails from './ContactDetails';
import AccountSettings from './AccountSettings';

const General = (props) => {
  const {

  } = props;

  return (
    <>
      <Elevation
        rounded="m"
        padding="m"
        style={{ marginBottom: 25 }}
      >
        <Flex
          flexDirection="column"
          spacing={3}
          fullWidth
        >
          <FlexItem fullWidth>
            <SectionTitle
              title="Profile Details"
              tooltipText="Symplete will use your profile information for creating your agent and property websites."
            />
            <ProfileDetails />
          </FlexItem>

          <FlexItem fullWidth>
            <SectionTitle
              title="Address Details"
              variant="title2"
              tooltipText={
                `Symplete will use this data to intelligently route your showings and other
                meetings in a software update later this year. Your digital personal assistant
                will be able to help organize your day.`
              }
            />
            <AddressDetails />
          </FlexItem>

          <FlexItem fullWidth>
            <SectionTitle
              title="Contact Details"
              variant="title2"
              tooltipText={
                `Symplete may use alternative contact details to send you very important
                information as it relates to your work for example a offer was revoked or
                a meeting cancelled last minute.`
              }
            />
            <ContactDetails />
          </FlexItem>
        </Flex>
      </Elevation>

      {
        !isSectionUnderConstruction('dataImport') && (
          <Elevation
            rounded="m"
            padding="m"
            style={{ marginBottom: 25 }}
          >
            <SectionTitle
              title="Data Imports"
            />
            <ImportSettings />
          </Elevation>
        )
      }

      {
        !isSectionUnderConstruction('calls') && (
          <Elevation
            rounded="m"
            padding="m"
            style={{ marginBottom: 25 }}
          >
            <div>
              <SectionTitle
                title="Calling"
              />
              <CallsSettings />
            </div>
          </Elevation>
        )
      }
      {
        isDemoAccount() && (
          <Elevation
            rounded="m"
            padding="m"
            style={{ marginBottom: 25 }}
          >
            <div>
              <SectionTitle
                title="Account settings"
              />
              <AccountSettings />
            </div>
          </Elevation>
        )
      }
    </>
  );
};

export default General;
