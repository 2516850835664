/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import useModal from 'ui/hooks/useModal';

import RealtorLeadItemDetails from 'sections/Opportunities/modules/RealtorLeadItemDetails';
import PropertyParametersFilters from 'sections/Opportunities/modules/PropertyParametersFilters';
import PropertyTypeFilter from 'sections/Opportunities/modules/PropertyTypeFilter';
import MlsSearchResult from 'sections/Opportunities/modules/MlsSearchResult';

import NewPropertyModal from 'sections/Properties/modals/NewPropertyModal/index';

import AddressFilterInput from './AddressFilterInput';

import {
  StyledButton,
} from './styledItems';

const ignoreEmptyValues = (obj = {}) => Object.keys(obj).reduce((acc, key) => {
  if (obj[key]) {
    acc[key] = obj[key];
  }

  return acc;
}, {});

const SearchMLSForm = (props) => {
  const {
    formId,
    onSubmit: propsOnSubmit,
    searchProperty,
    searchedData,
  } = props;

  const {
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm();

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const onSubmit = useCallback((data) => {
    propsOnSubmit(data.selectedProperty);
  }, [
    propsOnSubmit,
  ]);

  const searchMLS = useCallback(() => {
    const filters = watch('filters');

    searchProperty({
      ...filters,
      propertyParameters: ignoreEmptyValues(filters.propertyParameters),
    });
  }, [
    watch,
    searchProperty,
  ]);

  useEffect(() => {
    searchProperty({});
  }, []);

  return (
    <>
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex
          spacing={4}
          flexDirection="column"
        >
          <RealtorLeadItemDetails />

          <Flex
            fullWidth
            justifyContent="space-between"
            spacing={2}
          >
            <FlexItem xs={18}>
              <AddressFilterInput
                control={control}
                searchMLS={searchMLS}
              />
            </FlexItem>

            <StyledButton
              onClick={openModal}
              type="button"
            >
              <Typography
                variant="button"
                color="link"
                weight={600}
                noMargin
              >
                + Create new property
              </Typography>
            </StyledButton>
          </Flex>

          <FlexItem fullWidth>
            <PropertyTypeFilter
              searchMLS={searchMLS}
              control={control}
            />
          </FlexItem>

          <PropertyParametersFilters
            setValue={setValue}
            searchMLS={searchMLS}
            control={control}
          />

          <FlexItem fullWidth>
            <MlsSearchResult
              searchedData={searchedData}
              control={control}
            />
          </FlexItem>
        </Flex>
      </form>

      <NewPropertyModal
        isVisible={isModalVisible}
        closeModal={closeModal}
        onCreateSuccess={(propertyId) => { propsOnSubmit([propertyId]); }}
        onlySave
      />
    </>
  );
};

const {
  arrayOf,
  func,
  number,
  string,
} = PropTypes;

SearchMLSForm.propTypes = {
  formId: string.isRequired,
  onSubmit: func.isRequired,
  searchProperty: func.isRequired,
  searchedData: arrayOf(number).isRequired,
};

export default SearchMLSForm;
