import {
  SET_REALTOR_REPAIR_REQUEST,
} from 'store/actionTypes/realtorRepairRequests';

const set = (item) => ({
  type: SET_REALTOR_REPAIR_REQUEST,
  payload: item,
});

export default set;
