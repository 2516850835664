import create from './create';
import deleteTransaction from './deleteTransaction';
import get from './get';
import getById from './getById';
import update from './update';
import getSummary from './getSummary';
import addRelations from './addRelations';
import removeRelations from './removeRelations';

export default {
  getById,
  get,
  create,
  deleteTransaction,
  update,
  getSummary,
  addRelations,
  removeRelations,
};
