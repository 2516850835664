import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import ChipList from 'ui/ChipList';

import AddressChip from 'sections/Addresses/modules/AddressChip';

const MultipleLocationChips = (props) => {
  const {
    onAdd,
    onRemove,
    addressIds,
  } = props;

  const handleDelete = useCallback((id) => () => {
    onRemove([id]);
  }, [
    onRemove,
  ]);

  return (
    <ChipList
      onRemove={handleDelete}
      onAdd={onAdd}
      items={addressIds}
      ellipsis={1}
      CustomChip={({ item }) => (
        <AddressChip
          addressId={item}
          onDelete={handleDelete(item)}
        />
      )}
    />
  );
};

MultipleLocationChips.defaultProps = {
  onAdd: null,
  onRemove: null,
  addressIds: [],
};

const {
  arrayOf,
  func,
  number,
} = PropTypes;

MultipleLocationChips.propTypes = {
  onAdd: func,
  onRemove: func,
  addressIds: arrayOf(number),
};

export default MultipleLocationChips;
