import requests from 'api/requests';

import getById from './getPropertyById';

const removePropertyRelations = (relationType) => (propertyId) => (data) => async (dispatch) => {
  try {
    await requests.realtor.properties.removeRelations(relationType)(propertyId)(data);

    dispatch(getById(propertyId));
  } catch (error) {
    console.error(error);
  }
};

export default removePropertyRelations;
