import {
  SET_REALTOR_LEAD_CHANGELOG,
} from 'store/actionTypes/realtorLeads';

const setLeadChangelog = ({ leadId, changelog }) => ({
  type: SET_REALTOR_LEAD_CHANGELOG,
  payload: {
    leadId,
    changelog,
  },
});

export default setLeadChangelog;
