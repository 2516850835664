import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

const ListInput = (props) => {
  const {
    value,
    onChange,
    customListItem,
    customHandleChange,
  } = props;

  const handleChange = useCallback((inputIndex) => (newValue) => {
    const itemsList = [...value];

    itemsList[inputIndex] = newValue;

    if (inputIndex === itemsList.length - 1) {
      itemsList.push('');
    }

    onChange(itemsList);
  }, [
    value,
    onChange,
  ]);

  const handleDelete = useCallback((inputIndex) => (event) => {
    event.preventDefault();

    const itemsList = [...value];
    itemsList.splice(inputIndex, 1);

    onChange(itemsList);
  }, [
    value,
    onChange,
  ]);

  const Item = customListItem || ListItem;

  return (
    <div>
      {
        value?.map((inputValue, index) => (
          <Item
            onDelete={handleDelete(index)}
            onChange={customHandleChange ? customHandleChange(index) : handleChange(index)}
            value={inputValue}
          />
        ))
      }
    </div>
  );
};

ListInput.defaultProps = {
  onChange: () => {},
  value: [''],
  customListItem: null,
  customHandleChange: null,
};

const {
  arrayOf,
  func,
  string,
  element,
} = PropTypes;

ListInput.propTypes = {
  value: arrayOf(string),
  onChange: func,
  customListItem: element,
  customHandleChange: func,
};

export default ListInput;
