import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';
import Tag from 'ui/Tag';
import Flex from 'ui/Flex';

const RestValues = (props) => {
  const {
    values,
    formatDisplayValue,
    bareEllipsisStyles,
  } = props;

  const getTooltipContent = useCallback(() => (
    <Flex
      flexDirection="column"
      spacing={2}
      style={{ padding: 10 }}
    >
      {
        values.map((item) => (
          <Tag variant="background-flat">
            <Typography
              variant="tag"
              color="focus"
              weight="regular"
              noWrap
            >
              {formatDisplayValue(item.value)}
            </Typography>
          </Tag>
        ))
      }
    </Flex>
  ), [
    JSON.stringify(values),
  ]);

  return (
    <Tooltip
      title={getTooltipContent()}
      placement="bottom"
    >
      <div>
        {
          bareEllipsisStyles
          ? (
            <Typography
              variant="body"
              color="focus"
              noMargin
            >
              {`+${values.length}`}
            </Typography>
            )
          : (
            <Tag
              variant="background-flat"
              textColor="focus"
              typographyProps={{
                  weight: 'regular',
                }}
            >
              {`+${values.length}`}
            </Tag>
            )

        }
      </div>
    </Tooltip>
  );
};

RestValues.defaultProps = {
  values: [],
  formatDisplayValue: (value) => value,
  bareEllipsisStyles: false,
};

const {
  string,
  arrayOf,
  shape,
  oneOfType,
  number,
  func,
  bool,
} = PropTypes;

RestValues.propTypes = {
  values: arrayOf(shape({
    value: oneOfType([number, string]).isRequired,
  })),
  formatDisplayValue: func,
  bareEllipsisStyles: bool,
};

export default RestValues;
