import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

const defaultContact = {
  id: null,
  description: null,
};

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (state) => state.realtorContacts.selectedContact,
  (collection, selectedId) => {
    const item = collection[selectedId];

    if (!item) {
      if (selectedId) {
        optimizedRealtorContactsActions.getContactById(selectedId);
      }

      return defaultContact;
    }

    return item;
  },
);
