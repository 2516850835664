import connectReducers from 'store/connectReducers';
// TODO - fix dependency cycles
import { // eslint-disable-line import/no-cycle
  ADD_CATEGORY_SUCCESS,
  ADD_SUBCATEGORY_SUCCESS,
  ADD_TEAM_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_TEAM_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_SUCCESS,
  GET_ACTIVE_TEAM,
  GET_TEAM_CATEGORIES_SUCCESS,
  GET_TEAM_DATA_SUCCESS,
  GET_TEAM_PERMISSIONS_SUCCESS,
  GET_TEAM_SETUP_LIST_SUCCESS,
  GET_TEAM_SUBCATEGORIES_SUCCESS,
  HIDE_MODAL,
  OPEN_MODAL,
  SET_ACTIVE_TEAM,
  SET_TEAM_MEMBERS_SUCCESS,
} from 'store/actionTypes/team-setup';

import { // eslint-disable-line import/no-cycle
  CREATE_TASK_SUCCESS,
  UPDATE_TASK_DELVIERY_DATE_SUCCESS,
  UPDATE_TASK_FIELD_SUCCESS,
  UPDATE_TASK_PROGRESS_SUCCESS,
} from '../actions/tasks';

// eslint-disable-next-line import/no-cycle
import { SET_TASK_EMPLOYEES_SUCCESS } from '../actions/task-details-modal';
// eslint-disable-next-line import/no-cycle
import { updateTask } from './tasks';

const initialState = {
  teamSetupList: [],
  teamModal: {
    isOpen: false,
    isLoading: false,
  },
  activeTeam: null,
  permissions: [],
};

const synchronizeTeam = (state, action, teamId) => {
  const {
    data: [, newTeam],
  } = action;
  let newTasks = [];
  let newEmployees = [];
  if (newTeam) {
    const { employees, tasks } = newTeam;
    newTasks = tasks;
    newEmployees = employees;
  }
  return {
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === teamId) {
        return {
          ...team,
          tasks: newTasks,
          members: newEmployees,
        };
      }
      return team;
    }),
  };
};

const synchronizeTeamTasks = (state, newTask) => {
  const { TeamID } = newTask;
  const { teamSetupList } = state;
  return {
    ...state,
    teamSetupList: teamSetupList.map((team) => (team.TeamID === TeamID
      ? updateTeam(team, newTask)
      : team)),
  };
};

const updateTeam = (team, newTask) => {
  const { tasks, members } = team;
  const { employees } = newTask;
  const taskEmployees = employees.map((employee) => employee.EmployeeID);
  return {
    ...team,
    tasks: updateTask(tasks, newTask),
    members: members.map((member) => (taskEmployees.includes(member.EmployeeID)
      ? updateMembers(member, newTask)
      : member)),
  };
};

const updateMembers = (member, newTask) => {
  const { tasks } = member;
  return {
    ...member,
    tasks: updateTask(tasks, newTask),
  };
};


const reducers = {
  [GET_TEAM_SETUP_LIST_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: action.teamSetupList,
  }),

  [GET_TEAM_CATEGORIES_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return { ...team, categories: action.teamCategories };
      }
      return team;
    }),
  }),

  [GET_TEAM_SUBCATEGORIES_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return {
          ...team,
          categories: team.categories.map((category) => {
            if (category.CategoryID === action.categoryId) {
              return {
                ...category,
                subCategories: action.teamSubCategories,
              };
            }
            return category;
          }),
        };
      }
      return team;
    }),
  }),

  [ADD_TEAM_SUCCESS]: (state, action) => ({
    ...state,
  }),

  [DELETE_TEAM_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.filter(
      (team) => team.TeamID !== action.teamId,
    ),
  }),

  [ADD_CATEGORY_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return {
          ...team,
          categories: [...team.categories, action.newCategory],
        };
      }
      return team;
    }),
  }),

  [ADD_SUBCATEGORY_SUCCESS]: (state, action) => {
    const { teamId, categoryId, newSubCategory } = action;
    return {
      ...state,
      teamSetupList: state.teamSetupList.map((team) => {
        if (team.TeamID === teamId) {
          return {
            ...team,
            categories: team.categories.map((category) => {
              if (category.CategoryID === categoryId) {
                return {
                  ...category,
                  subCategories: [...category.subCategories, newSubCategory],
                };
              }
              return category;
            }),
          };
        }
        return team;
      }),
    };
  },

  [EDIT_CATEGORY_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return {
          ...team,
          categories: team.categories.map((category) => {
            if (category.CategoryID === action.categoryId) {
              return { ...category, Category: action.editCategory };
            }
            return category;
          }),
        };
      }
      return team;
    }),
  }),

  [EDIT_SUBCATEGORY_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return {
          ...team,
          categories: team.categories.map((category) => {
            if (category.CategoryID === action.categoryId) {
              return {
                ...category,
                subCategories: category.subCategories.map((subCategory) => {
                  if (subCategory.SubCategoryID === action.subCategoryId) {
                    return {
                      ...subCategory,
                      Product: action.editSubCategory,
                    };
                  }
                  return subCategory;
                }),
              };
            }
            return category;
          }),
        };
      }
      return team;
    }),
  }),

  [DELETE_CATEGORY_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return {
          ...team,
          categories: team.categories.filter(
            (category) => category.CategoryID !== action.categoryId,
          ),
        };
      }
      return team;
    }),
  }),

  [DELETE_SUBCATEGORY_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === action.teamId) {
        return {
          ...team,
          categories: team.categories.map((category) => {
            if (category.CategoryID === action.categoryId) {
              return {
                ...category,
                subCategories: category.subCategories.filter(
                  (subCategory) => subCategory.SubCategoryID !== action.subCategoryId,
                ),
              };
            }
            return category;
          }),
        };
      }
      return team;
    }),
  }),

  [OPEN_MODAL]: (state) => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isOpen: true,
    },
  }),

  [HIDE_MODAL]: (state, action) => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isOpen: false,
    },
  }),

  [SET_ACTIVE_TEAM]: (state, action) => ({
    ...state,
    activeTeam: action.activeTeam,
  }),

  [GET_ACTIVE_TEAM]: (state) => ({
    ...state,
    activeTeam: state.activeTeam,
  }),

  [GET_TEAM_DATA_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === parseInt(action.teamId, 10)) {
        return {
          ...team,
          members: action.members,
          tasks: action.tasks,
        };
      }
      return team;
    }),
  }),

  [SET_TEAM_MEMBERS_SUCCESS]: (state, action) => ({
    ...state,
    teamSetupList: state.teamSetupList.map((team) => {
      if (team.TeamID === parseInt(action.teamId, 10)) {
        return { ...team, members: action.members.employees };
      }
      return team;
    }),
  }),

  [CREATE_TASK_SUCCESS]: (state, action) => synchronizeTeam(
    state,
    action,
    action.teamId,
  ),

  [SET_TASK_EMPLOYEES_SUCCESS]: (state, action) => synchronizeTeam(
    state,
    action,
    action.config.teamId,
  ),

  [UPDATE_TASK_DELVIERY_DATE_SUCCESS]: (state, action) => {
    const { task } = action;
    return synchronizeTeamTasks(state, task);
  },

  [UPDATE_TASK_FIELD_SUCCESS]: (state, action) => {
    const { task } = action;
    return synchronizeTeamTasks(state, task);
  },

  [UPDATE_TASK_PROGRESS_SUCCESS]: (state, action) => {
    const { task } = action;
    return synchronizeTeamTasks(state, task);
  },

  [GET_TEAM_PERMISSIONS_SUCCESS]: (state, action) => ({
    ...state,
    permissions: action.permissions,
  }),
};

const teamSetup = (state = initialState, action) => connectReducers(reducers, state, action);

export default teamSetup;
