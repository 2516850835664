import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import {
  Wrapper,
  Root,
  PopoverRoot,
  PopoverTitle,
  PopoverTime,
  PopoverDescription,
} from './styledItems';

const noOp = () => {};

const TimelineItemSign = (props) => {
  const {
    customSign,
    signIconName,
    signIconColor,
    onClick,
    event,
    customPopover,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = useCallback((eventObject) => {
    setAnchorEl(eventObject.currentTarget);
  });

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  });

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <>
      <Wrapper
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Root
          onClick={onClick || noOp}
          hasClick={onClick}
          className="prevent-drag-scroll"
        >
          {customSign}
          {
            !customSign && (
              <FontAwesomeIcon
                icon={signIconName}
                size="1x"
                style={{ color: signIconColor }}
              />
            )
          }
        </Root>
      </Wrapper>
      {
        (customPopover || event) && (
          <Popper
            id="mouse-over-popover"
            open={isPopoverOpen}
            anchorEl={anchorEl}
            placement="top-start"
          >
            {
              customPopover
            }
            {
              !customPopover && (
                <Paper
                  elevation={6}
                >
                  <PopoverRoot>
                    {
                      event.title && (
                        <PopoverTitle>{event.title}</PopoverTitle>
                      )
                    }
                    {
                      event.time && (
                        <PopoverTime>{event.time}</PopoverTime>
                      )
                    }
                    {
                      event.description && (
                        <PopoverDescription>{event.description}</PopoverDescription>
                      )
                    }
                  </PopoverRoot>
                </Paper>
              )
            }
          </Popper>
        )
      }
    </>
  );
};

TimelineItemSign.defaultProps = {
  signIconColor: 'black',
  signIconName: null,
  customSign: null,
  onClick: null,
  event: null,
  customPopover: null,
};

const {
  element,
  func,
  shape,
  string,
} = PropTypes;

TimelineItemSign.propTypes = {
  signIconColor: string,
  signIconName: string,
  customSign: element,
  event: shape({
    title: string,
    time: string,
    description: string,
  }),
  customPopover: element,
  onClick: func,
};

export default TimelineItemSign;
