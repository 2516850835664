import cashflow from '../assets/images/cashflow.png';
import customerService from '../assets/images/customer-service.png';
import hr from '../assets/images/HR.png';
import knowledgeBase from '../assets/images/KBBlue.png';
import sales from '../assets/images/sales.png';
import surveys from '../assets/images/surveys.png';
import symplete from '../assets/images/symplete.png';
import tasks from '../assets/images/Tasks.png';
import PERMISSIONS from './permissions.constants';

export const USER_AVAILABILITY = {
  Available: 1,
  Away: 2,
  Do_Not_Disturb: 3,
  Offline: 4,
};

const USER_AVAILABILITY_CODES_TO_BADGE_MAP = {
  1: 'success',
  2: 'warning',
  3: 'error',
  4: 'default',
  default: 'default',
};

const USER_AVAILABILITY_CODES_TO_COLORS_MAP = {
  1: '#52C41A',
  2: '#FAAD14',
  3: '#F5222D',
  4: '#D9D9D9',
  null: '#D9D9D9',
  undefined: '#D9D9D9',
};

const USER_AVAILABILITY_CODES_TO_STATUS_MAP = {
  1: 'Available',
  2: 'Away',
  3: 'Do Not Disturb',
  4: 'Offline',
  null: 'Offline',
  undefined: 'Offline',
};

export const USER_AVAILABILITY_TO_BADGE_MAP = {
  Available: 'success',
  Away: 'warning',
  Do_Not_Disturb: 'error',
  Offline: 'default',
};

export const OBJECT_TYPES = {
  1: 'customer support ticket',
  2: 'task',
  3: 'sales lead',
  4: 'comment',
  5: 'log',
  6: 'sales deal',
  7: 'sales contact',
  8: 'sales company',
  9: 'sales campaign',
  10: 'article',
  11: 'fulfillment',
  12: 'customer invoice',
  13: 'customer payment',
  14: 'serviceperson',
  15: 'order',
  16: 'shipped item',
  17: 'service process',
  18: 'customer refund',
  19: 'order cancelled',
  20: 'credit note',
  21: 'customer informed',
  22: 'line item',
  23: 'warehouse package',
  24: 'agreement',
  25: 'realtor contact',
  26: 'realtor lead',
  27: 'realtor property',
  28: 'realtor property requirement',
  29: 'realtor client',
  30: 'realtor prospect',
  31: 'realtor offer',
  32: 'realtor special condition',
  33: 'realtor escrow',
  34: 'realtor inspection',
  35: 'realtor loan',
  36: 'realtor repair request',
  37: 'document request',
  38: 'realtor question',
  39: 'realtor addendum request',
  40: 'realtor extension request',
  41: 'realtor transaction',
  42: 'realtor property showing',
};

export const PRIORITIZATION_ATTRIBUTES = {
  1: 'Due/Closing Dates',
  2: 'Object Potential Value $',
  3: 'VIP Clients',
  4: 'Object Temperature',
  5: 'Starred Objects',
};

export const ATTACHMENT_TYPES = {
  1: 'avatar',
  2: 'customer_invoice',
  3: 'customer_payment',
  4: 'order',
  5: 'shipped item',
  6: 'delivery confirmation',
  7: 'agreement',
  8: 'feedback',
  9: 'customer creditNote',
  10: 'credit note',
  11: 'image',
  12: 'any',
  13: 'realtor transaction initial document',
  14: 'realtor transaction disclosure',
  15: 'realtor transaction appraisal',
  16: 'realtor transaction loan',
  17: 'realtor transaction walkthrough',
  18: 'realtor transaction escrow',
  19: 'listing agreement',
  20: 'pre-approval',
};

export const common = {
  PERMISSIONS,
  DATE: {
    V1: 'DD MMMM, YYYY', // 15 December, 2018
    V2: 'YYYY-MM-DD', // 2018-12-15
    V3: 'MMMM YYYY', // December 2018
    V4: 'MM/DD/YYYY hh:mm:ss', // 02/12/2019 09:43:35
    V5: 'MMMM DD, YYYY', // June 2, 2019
    V6: 'dddd', // Monday
    V7: 'MM-DD-YYYY', // 02/32/2019 US format
    V8: 'MMMM D', // December 15
  },
  ENTER_BUTTON: 13,
  ERROR_MSG: 'Error occured',
  BAD_REQUEST: {
    CODE: 400,
    MSG: 'Bad request!',
  },
  UNAUTHORIZED: {
    CODE: 401,
    MSG: 'Unauthorized!',
  },
  FORBIDDEN: {
    CODE: 403,
    MSG: 'No permissions!',
  },
  NOT_FOUND: {
    CODE: 404,
    MSG: 'Not found!',
  },
  ENTITY_TOO_LARGE: {
    CODE: 413,
    MSG: 'File is too large!',
  },
  INTERNAL_ERROR: {
    CODE: 500,
    MSG: 'Some error on server occured!',
  },
  CUSTOM_ERRORS: {
    E401_1_JTW: '401_1',
    E401_2_LICENSE: '401_2',
    E401_3_USER_ROLE: '401_3',
    E401_6_CREDENTIALS: '401_6',
    E403_2_LICENSE: '403_2',
    E403_3_USER_ROLE: '403_3',
  },
  EMAIL_VALIDATOR: [
    {
      type: 'email',
      message: 'The input is not valid еmail!',
    },
    {
      required: true,
      message: 'Please input your еmail!',
    },
  ],
  EMAIL_VALIDATOR_NOT_REQUIRED: [
    {
      type: 'email',
      message: 'The input is not valid E-mail!',
    },
  ],
  EMPTY: '-',
  WEEK: 7,
  LICENSES: {
    SYMPLETE: {
      id: 1,
      name: 'Symplete',
      img: symplete,
      active: true,
      routerUrl: null,
      permission: PERMISSIONS.Symplete,
      key: 'SYMPLETE',
    },
    TASKS: {
      id: 2,
      name: 'Tasks',
      img: tasks,
      active: true,
      routerUrl: '/tasks',
      permission: PERMISSIONS.Tasks,
      key: 'TASKS',
    },
    HR: {
      id: 3,
      name: 'HR',
      img: hr,
      active: true,
      routerUrl: '/team/employees',
      permission: PERMISSIONS.HR,
      key: 'HR',
    },
    CUSTOMER_SERVICE: {
      id: 4,
      name: 'Customer Support',
      img: customerService,
      active: true,
      routerUrl: '/customer-service',
      permission: PERMISSIONS['Customer Support'],
      key: 'CUSTOMER_SERVICE',
    },
    SALES: {
      id: 5,
      name: 'Sales',
      img: sales,
      active: true,
      routerUrl: '/sales',
      permission: PERMISSIONS.Sales,
      key: 'SALES',
    },
    CASHFLOW: {
      id: 6,
      name: 'Cashflow',
      img: cashflow,
      active: false,
      routerUrl: '/cashflow',
      permission: PERMISSIONS.Cashflow,
      key: 'CASHFLOW',
    },
    SURVEYS: {
      id: 7,
      name: 'Surveys',
      img: surveys,
      active: false,
      routerUrl: '/surveys',
      permission: PERMISSIONS.Surveys,
      key: 'SURVEYS',
    },
    ACCOUNTS: {
      id: 9,
      name: 'Accounts',
      active: false,
      routerUrl: '/accounts',
      permission: null,
      key: 'ACCOUNTS',
    },
    KNOWLEDGE_BASE: {
      id: 8,
      name: 'Knowledge Base',
      img: knowledgeBase,
      active: true,
      routerUrl: '/knowledge-base',
      permission: null,
      key: 'KNOWLEDGE_BASE',
    },
    CUSTOMER_DB: {
      id: 10,
      name: 'Customer DB',
      img: tasks,
      active: false,
      routerUrl: '/customer-db',
      permission: PERMISSIONS.CustomerDB,
      key: 'CUSTOMER_DB',
    },
    ARTICLES: {
      id: 11,
      name: 'Articles',
      img: knowledgeBase,
      active: true,
      routerUrl: '/articles',
      permission: null,
      key: 'ARTICLES',
    },
  },
  DEFAULT_LOGO: '/assets/img/default-logo.png',
  INPUT_TYPES: {
    DATE_PICKER: 'DATE_PICKER',
    TIME_PICKER: 'TIME_PICKER',
    CHECKBOX: 'CHECKBOX',
    INPUT: 'INPUT',
    MULTI_SELECT: 'MULTI_SELECT',
  },
  YES: 'Yes',
  NO: 'No',
  USER_AVAILABILITY,
  USER_AVAILABILITY_CODES_TO_BADGE_MAP,
  USER_AVAILABILITY_CODES_TO_COLORS_MAP,
  USER_AVAILABILITY_CODES_TO_STATUS_MAP,
  OBJECT_TYPES,
  ATTACHMENT_TYPES,
  PRIORITIZATION_ATTRIBUTES,
};

export const SYMPLETE_ID = common.LICENSES.SYMPLETE.id;
export const PERM_ROLES = [
  PERMISSIONS.TasksProduct,
  PERMISSIONS.TicketingProduct,
  PERMISSIONS.HRProduct,
  PERMISSIONS.SurveysProduct,
  PERMISSIONS.SalesProduct,
];

export const ATTENDANCE_TYPES = {
  Present: 'Present',
  Holiday: 'Holiday',
  Sick: 'Sick',
  Other: 'Other',
};
