import styled from 'styled-components';

export const UploadWrapper = styled.div`
  .ant-upload-drag {
    height: 114px;
    border-radius: 10px;
    border: 0;
    background-color: ${(props) => props?.theme?.palette?.['background-flat']};
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
    border: 0;
  }
`;

export const LinkInputContainer = styled.div`
  min-width: 320px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ExternalLinksContainer = styled.div`
  margin-top: 15px;
  overflow-x: hidden;
`;

export const ExternalLink = styled.a`
  display: block;
  margin-bottom: 5px;
`;
