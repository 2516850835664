import get from './get';
import getById from './getById';
import create from './create';
import update from './update';
import remove from './remove';

export default {
  get,
  getById,
  create,
  update,
  remove,
};
