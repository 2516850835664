import apiConnector, { endpoints } from 'api';

const deleteRelation = async (relationId) => {
  const endpoint = `${endpoints.client.labels}/${relationId}`;

  try {
    const result = await apiConnector.client.delete(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default deleteRelation;
