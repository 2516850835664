import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import { Empty } from 'antd';

import SearchedContact from './SearchedContact';

import {
  Border,
} from './styledItems';

const SearchResult = (props) => {
  const {
    searchedContacts,
    onContactClick,
    contactExtraInfo,
  } = props;

  return (
    <div>
      <>
        {!isEmpty(searchedContacts)
          ? searchedContacts.map((contactId) => (
            <>
              <SearchedContact
                onClick={onContactClick(contactId)}
                contactId={contactId}
                showEmail={contactExtraInfo.includes('email')}
                showPhone={contactExtraInfo.includes('phone')}
              />
              <Border />
            </>
          ))
          : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
      </>
    </div>
  );
};

SearchResult.defaultProps = {
  contactExtraInfo: ['email'],
};

const {
  arrayOf,
  func,
  number,
  string,
  oneOf,
} = PropTypes;

SearchResult.propTypes = {
  searchedContacts: arrayOf({
    id: number,
    first_name: string,
    last_name: string,
    email_primary: string,
  }).isRequired,
  onContactClick: func.isRequired,
  contactExtraInfo: arrayOf(oneOf(['email', 'phone'])),
};

export default SearchResult;
