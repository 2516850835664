import React, {
  Fragment,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { includes } from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Button from 'ui/Button';
import Chip from 'ui/Chip';

import Checkbox from 'ui/Checkbox';

import useEditMode from 'ui/hooks/useEditMode';

const AdditionalAmenities = (props) => {
  const {
    allAmenities,
    selectedAmenities,
    addAmenity,
    removeAmenity,
  } = props;

  const {
    mode,
    setMode,
  } = useEditMode('display');

  const handleChange = useCallback((amenityId) => (isChecked) => {
    if (isChecked) {
      addAmenity(amenityId);
    } else {
      removeAmenity(amenityId);
    }
  }, [
    addAmenity,
    removeAmenity,
  ]);

  if (mode === 'display') {
    return (
      <Flex
        alignItems="center"
        spacing={1}
        justifyContent="space-between"
      >
        <Flex
          spacing={1}
          flexWrap="wrap"
        >
          {
            selectedAmenities.map((item) => {
              const name = allAmenities.find((amenity) => amenity.id === item)?.name;

              return (
                <Chip
                  key={item}
                  variant="subtle"
                >
                  {name}
                </Chip>
              );
            })
          }
        </Flex>
        <Button
          variant="tertiary"
          onClick={() => setMode('edit')}
          icon="plus"
        >
          Add/edit
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        fullWidth
        justifyContent="flex-end"
      >
        <Button
          variant="tertiary"
          onClick={() => setMode('display')}
          icon="minus"
        >
          Minimize
        </Button>
      </Flex>
      <Flex
        flexWrap="wrap"
      >
        {
          allAmenities.map((item, index) => (
            <Fragment key={item.id}>
              <FlexItem xs={6} padding="12px 0">
                <Checkbox
                  checked={includes(selectedAmenities, item.id)}
                  onChange={handleChange(item.id)}
                  label={item.name}
                  typographyProps={{
                    textTransform: 'capitalize',
                  }}
                />
              </FlexItem>
            </Fragment>
          ))
        }
      </Flex>
    </>
  );
};

AdditionalAmenities.defaultProps = {
  allAmenities: [],
  selectedAmenities: [],
};

const {
  func,
  arrayOf,
  shape,
  number,
  string,
} = PropTypes;

AdditionalAmenities.propTypes = {
  allAmenities: arrayOf(shape({
    id: number,
    name: string,
  })),
  selectedAmenities: arrayOf(number),
  addAmenity: func.isRequired,
  removeAmenity: func.isRequired,
};

export default AdditionalAmenities;
