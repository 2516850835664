export const SET_TICKET_EMPLOYEES = 'SET_TICKET_EMPLOYEES';
export const SET_TICKET_EMPLOYEES_SUCCESS = 'SET_TICKET_EMPLOYEES_SUCCESS';
export const SET_TICKET_EMPLOYEES_ERROR = 'SET_TICKET_EMPLOYEES_ERROR';
export const SET_TICKET_CATEGORY = 'SET_TICKET_CATEGORY';
export const SET_TICKET_CATEGORY_SUCCESS = 'SET_TICKET_CATEGORY_SUCCESS';
export const SET_TICKET_CATEGORY_ERROR = 'SET_TICKET_CATEGORY_ERROR';
export const SET_TICKET_AGENT_NOTE = 'SET_TICKET_AGENT_NOTE';

export default {
  SET_TICKET_EMPLOYEES,
  SET_TICKET_EMPLOYEES_SUCCESS,
  SET_TICKET_EMPLOYEES_ERROR,
  SET_TICKET_CATEGORY,
  SET_TICKET_CATEGORY_SUCCESS,
  SET_TICKET_CATEGORY_ERROR,
  SET_TICKET_AGENT_NOTE,
};
