import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Divider from 'ui/Divider';
import KPIItem from 'ui/KPIItem';

import RequirementsAddressList from 'sections/Properties/modules/RequirementsAddressList';
import RequirementsPriceRangeInput from 'sections/Properties/modules/RequirementsPriceRangeInput';
import RequirementsParametersMain from 'sections/Properties/modules/RequirementsParametersMain';
import RequirementsPropertyTypeSelect from 'sections/Properties/modules/RequirementsPropertyTypeSelect';

import PreApprovalControl from 'sections/Properties/modules/RequirementsPreApproval/PreApprovalControl';

const BuyerLead = (props) => {
  const {
    itemId,
  } = props;

  return (
    <Flex
      flexDirection="column"
      fullWidth
      spacing={3}
    >
      <Typography
        variant="title2"
        noMargin
      >
        Buyer Requirements
      </Typography>

      <FlexItem fullWidth>
        <RequirementsPriceRangeInput
          itemId={itemId}
        />
      </FlexItem>

      <FlexItem fullWidth>
        <KPIItem
          iconName="bookmark"
          variant="flat"
        >
          <Flex
            alignItems="baseline"
            spacing={4}
          >
            <Typography noMargin>
              Pre-Approved:
            </Typography>
            <PreApprovalControl
              propertyRequirementId={itemId}
            />
          </Flex>
        </KPIItem>
      </FlexItem>

      <Divider />

      <FlexItem fullWidth>
        <Typography
          variant="title3"
        >
          Preferred Locations
        </Typography>
        <RequirementsAddressList
          defaultView="display"
          itemId={itemId}
          placeholder="Enter buyer preferred locations"
        />
      </FlexItem>

      <FlexItem fullWidth>
        <RequirementsPropertyTypeSelect
          itemId={itemId}
          defaultView="display"
        />
      </FlexItem>

      <Divider />

      <FlexItem fullWidth>
        <RequirementsParametersMain
          itemId={itemId}
          defaultView="display"
        />
      </FlexItem>
    </Flex>
  );
};

const {
  number,
} = PropTypes;

BuyerLead.propTypes = {
  itemId: number.isRequired,
};

export default BuyerLead;
