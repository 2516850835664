import connectReducers from 'store/connectReducers';

import {
  SET_DISLIKE,
  SET_DISLIKES,
} from 'store/actionTypes/likes-dislikes';

import {
  listToObjectByKey,
} from 'store/utils';

const initialState = {
  dislikes: {},
};

const reducers = {
  [SET_DISLIKE]: (state, action) => ({
    ...state,
    dislikes: {
      ...state.dislikes,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_DISLIKES]: (state, action) => ({
    ...state,
    dislikes: {
      ...state.dislikes,
      ...listToObjectByKey(action.payload),
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
