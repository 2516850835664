export default {
  auth: {
    login: '/auth/login',
    selectWorkspace: '/auth/select-workspace',
  },
  countries: '/countries',
  password: {
    reset: '/password/reset',
    compare: '/password/compare',
  },
  login: {
    change: '/login/change',
  },
  checkIfAccountExists: '/checkIfAccountExists',
  payment: {
    setBillingPeriodType: '/payment/set-billing-period-type',
  },
  requestContact: {
    onboarding: '/onboarding-request-contact',
  },
  client: {
    updateInformation: '/client/update-client-information',
  },
  realtorWaitlist: 'realtor-marketing/waitlist',
  realtorMarketingContact: 'realtor-marketing/contact',
  smartzipProperties: {
    byZip: '/smartzip',
    details: '/smartzip-details',
    item: '/smartzip-item',
    reserve: '/smartzip-reserve-item',
    unreserve: '/smartzip-unreserve-item',
    listReserved: '/smartzip-list-reserved',
  },
};
