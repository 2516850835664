import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} dealData
 */
const create = async (dealData) => {
  const endpoint = endpoints.client.realtor.deals;
  try {
    const result = await apiConnector.client.post(endpoint, dealData);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
