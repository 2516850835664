import {
  SET_REALTOR_PRIORITIES_OBJECTS,
} from 'store/actionTypes/realtorPriorities';

const setPropertiesObjects = (objects) => ({
  type: SET_REALTOR_PRIORITIES_OBJECTS,
  payload: objects,
});

export default setPropertiesObjects;
