import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import {
  Root,
  Image,
  IconWrapper,
  Label,
} from './styledItems';

const statusColorDict = {
  1: 'available',
  2: 'under offer',
  3: 'in transaction',
  4: 'sold',
  5: 'off market',
};

const statusTitleDict = {
  1: 'available',
  2: 'under offer',
  3: 'in transaction',
  4: 'sold',
  5: 'off market',
};

const PropertyImageCell = (props) => {
  const {
    image,
    status,
    rounded,
    height,
  } = props;

  return (
    <Root
      height={height}
    >
      {
        image ? (
          <Image
            status={status}
            src={image}
            rounded={rounded}
            height={height}
          />
        ) : (
          <IconWrapper
            rounded={rounded}
            height={height}
          >
            <Icon
              name="property"
              className="property-icon"
              type="custom"
              color="background-flat"
              size="xl"
            />
          </IconWrapper>
        )
      }
      {
        status && (
          <Label
            status={statusColorDict[status]}
            rounded={rounded}
          >
            {statusTitleDict[status]}
          </Label>
        )
      }
    </Root>
  );
};

PropertyImageCell.defaultProps = {
  image: null,
  status: null,
  rounded: 'm',
  height: 64,
};

const {
  string,
  number,
  oneOfType,
} = PropTypes;

PropertyImageCell.propTypes = {
  image: string,
  status: string,
  rounded: string,
  height: oneOfType([string, number]),
};

export default React.memo(PropertyImageCell);
