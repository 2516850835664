import { connect } from 'react-redux';

import {
  postComment,
} from 'store/actions/comments';

import Comments from './Comments';

const mapDispatchToProps = (dispatch, ownProps) => ({
  addComment: (data) => {
    const comment = {
      Comment: data.comment,
      CommentType: data.commentType,
      IsPublic: data.isVisibleToCustomer,
    };

    return dispatch(postComment(ownProps.objectType)(ownProps.objectId)(comment));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(Comments);
