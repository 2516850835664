import {
  SET_NEW_REALTOR_PROPERTY_FORM_FIELD,
} from 'store/actionTypes/realtorMls';

const setNewPropertyFormField = (key) => (value) => ({
  type: SET_NEW_REALTOR_PROPERTY_FORM_FIELD,
  payload: {
    key,
    value,
  },
});

export default setNewPropertyFormField;
