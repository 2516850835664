import styled from 'styled-components';

import {
  Upload,
} from 'antd';

import Input from 'shared/inputs/FormTextfield';

export const AttachmentTileTitleWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border-top: solid 1px ${(props) => props?.theme?.palette?.['input-border']};
  background-color: #fff;
`;

export const PreviewToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MediaWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) => props?.theme?.palette?.['background-flat']};
  /* padding: 5px 8px 8px; */
`;

export const AttachmentContainer = styled.div`
  height: 290px;
  width: 100%;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url(${(props) => props.previewUrl});
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DetailsWrapper = styled.div`
  min-height: 30px;
  margin-bottom: 15px;
`;

export const TitleInput = styled(Input)`
  font-size: ${(props) => props?.theme?.fontSize?.title2 || '18px'};
`;

export const UploadArea = styled(Upload)`
  width: auto;

  .ant-upload-list-item,
  .ant-upload.ant-upload-select-picture-card {
    width: 85px;
    height: 85px;
    border-radius: 8px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 85px;
    height: 85px;
  }

  .ant-upload {
    height: 100%;
    min-height: 85px;
    min-width: 85px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
    .ant-upload-text {
      display: none;
    }
  }

  .ant-upload-list-picture-card-container {
    width: 85px;
    height: 85px;
  }

  .ant-upload-list-item-actions {
    a, i {
      margin: 0 1px !important;
      // display: none;
    }
  }
`;

export const LinkInputContainer = styled.div`
  min-width: 320px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ExternalLinksContainer = styled.div`
  margin-top: 15px;
  overflow-x: hidden;
`;

export const ExternalLink = styled.a`
  display: block;
  margin-bottom: 5px;
`;
