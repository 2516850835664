import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPlannedSystemMaintenance } from 'store/actions/systemCheck';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import MainNavigation from './MainNavigation';

const mapStateToProps = (state) => {
  const {
    systemCheck: {
      plannedSystemMaintenance,
    },
    myCompany: {
      employeesTotal,
    },
  } = state;

  const shouldShowCompanySettings = checkLoggedEmployeePermissions.isManager('Company Settings');

  return {
    employeesCount: employeesTotal,
    shouldShowCompanySettings,
    plannedSystemMaintenance,
  };
};

const mapDispatchToProps = {
  getPlannedSystemMaintenance,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MainNavigation);
