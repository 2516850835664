import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const LinkInput = (props) => {
  const {
    onEnter,
  } = props;

  const [inputValue, setInputValue] = useState('');

  const handleEnter = useCallback((event) => {
    event.preventDefault();
    const preparedValue = inputValue.trim();
    if (preparedValue) {
      onEnter(preparedValue);
    }
    setInputValue('');
  }, [
    onEnter,
    inputValue,
  ]);

  return (
    <Textfield
      value={inputValue}
      onChange={setInputValue}
      onEnter={handleEnter}
      placeholder="Paste a document link and press Enter"
    />
  );
};

const {
  func,
} = PropTypes;

LinkInput.propTypes = {
  onEnter: func.isRequired,
};

export default LinkInput;
