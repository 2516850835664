import {
  SET_CRM_PRODUCT,
} from 'store/actionTypes/crm';

const setProducts = (product) => ({
  type: SET_CRM_PRODUCT,
  payload: product,
});

export default setProducts;
