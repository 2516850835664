import {
  SET_SERVICE_PROCESS,
} from 'store/actionTypes/serviceProcesses';

const set = (payload) => ({
  type: SET_SERVICE_PROCESS,
  payload,
});

export default set;
