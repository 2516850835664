import requests from 'api/requests';

import setMatched from './setMatched';
import setMatchedForFilter from './setMatchedForFilter';
import setMatchedForFilterCount from './setMatchedForFilterCount';

const getMatchedForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.matched.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setMatched(response.data.items));
    dispatch(setMatchedForFilter(filterId)(response.data.items));
    dispatch(setMatchedForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getMatchedForFilter;
