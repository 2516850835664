import {
  SET_REALTOR_CLIENTS_FOR_FILTER,
} from 'store/actionTypes/realtorClients';

const setClientsForFilter = (filterId) => (clients) => ({
  type: SET_REALTOR_CLIENTS_FOR_FILTER,
  payload: {
    filterId,
    clients,
  },
});

export default setClientsForFilter;
