import connectReducers from 'store/connectReducers';

import {
  SET_EXTERNAL_TOKEN_ISSUER_USER_DATA,
  SET_EXTERNAL_TOKEN_ISSUER_COMPANY_DATA,
} from 'store/actionTypes/externalAccess';

const initialState = {
  issuerUser: null,
  issuerCompany: null,
};

const reducers = {
  [SET_EXTERNAL_TOKEN_ISSUER_USER_DATA]: (state, action) => ({
    ...state,
    issuerUser: action.payload,
  }),

  [SET_EXTERNAL_TOKEN_ISSUER_COMPANY_DATA]: (state, action) => ({
    ...state,
    issuerCompany: action.payload,
  }),
};

function externalAccess(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default externalAccess;
