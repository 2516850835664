const updateListItem = (state, action) => {
  const {
    id,
    update,
    key,
  } = action.payload;

  const list = { ...state[key] };

  list[id] = {
    ...list[id],
    ...update,
  };

  return {
    ...state,
    [key]: list,
  };
};

export default updateListItem;
