import { createSelector } from 'reselect';

const teams = (state) => state.salesTeams.teams;

export default createSelector(
  teams,
  (items) => (items
    ? items.map((item) => ({
      id: item.SalesTeamId,
      name: item.Title,
    }))
    : []),
);
