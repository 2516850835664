import {
  SET_EMAIL,
} from 'store/actionTypes/emails';

const set = (payload) => ({
  type: SET_EMAIL,
  payload,
});

export default set;
