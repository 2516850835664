import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import CurrencyInput from 'ui/CurrencyInput';

const PriceRange = (props) => {
  const {
    handleChange,
    value,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue([value[0], value[1]]);
  }, [
    value[0],
    value[1],
  ]);

  const onChange = useCallback((type) => (newValue) => {
    const update = type === 'min'
      ? [newValue, currentValue[1]]
      : [currentValue[0], newValue];

    setCurrentValue(update);
    handleChange(update);
  }, [
    setCurrentValue,
    handleChange,
    currentValue,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <CurrencyInput
          className=""
          label="Min."
          onChange={onChange('min')}
          value={currentValue[0]}
          minimumValue={0}
          // maximumValue={currentValue[1]}
          currencySymbol="$"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <CurrencyInput
          className=""
          label="Max."
          onChange={onChange('max')}
          value={currentValue[1]}
          minimumValue={0}
          // minimumValue={currentValue[0]}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const {
  arrayOf,
  func,
  number,
} = PropTypes;

PriceRange.propTypes = {
  handleChange: func.isRequired,
  value: arrayOf(number).isRequired,
};

export default PriceRange;
