import {
  exact,
  number,
  string,
  arrayOf,
  bool,
  oneOfType,
  func,
  any,
  shape,
  instanceOf,
} from 'prop-types';

export const desiredOutcomeType = shape({
  id: number,
  name: string,
  meetingId: number,
  isCompleted: bool,
  createdAt: string,
  updatedAt: string,
});

export const meetingType = shape({
  id: number,
  name: string,
  code: string,
  password: string,
  notes: string,
  desiredOutcomes: arrayOf(desiredOutcomeType),
  createdAt: string,
  updatedAt: string,
});

export const meetingParticipantType = shape({
  socketId: string,
  username: string,
  role: string,
  company: string,
});

export const thumbnailType = shape({
  participantSocketId: string,
  stream: instanceOf(MediaStream),
});

export const userType = shape({
  Name: string,
  Role: string,
  EmployeeID: number,
  PersonalDescription: string,
  office: shape({
    OfficeCountry: string,
  }),
  socketIds: arrayOf(string),
});

export const refType = oneOfType([
  func,
  exact({
    current: any,
  }),
]);
