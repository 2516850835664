import { connect } from 'react-redux';

import {
  checkExistingContact,
} from 'store/actions/realtorContacts';
import EmailInput from './EmailInput';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  checkEmailExistence: async (value) => {
    if (ownProps.noExistenceCheck) return null;

    let contact;

    if (ownProps.checkEmailExistence) {
      contact = await ownProps.checkEmailExistence(value);
    } else {
      contact = await dispatch(checkExistingContact('email_primary')(value));
    }

    return contact;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailInput);
