import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Row,
} from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import Typography from 'ui/Typography';
import './CaptchaModal.scss';

const sitekey = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

class CaptchaModal extends React.Component {
  getCaptchaRef = (ref) => {
    this.captcha = ref;
  }

  onSuccess = () => {
    const {
      loginOnSuccess,
      triggerCaptchaModal,
    } = this.props;

    triggerCaptchaModal();
    loginOnSuccess();

    this.captcha.reset();
  };

  render() {
    const {
      showCaptchaModal,
    } = this.props;

    return (
      <Modal
        okText="Confirm"
        width={600}
        title={null}
        closable={false}
        footer={null}
        centered
        visible={showCaptchaModal}
        wrapClassName="recover-password"
      >
        <div className="captcha-page">
          <Row
            type="flex"
            className="captcha-page__content"
          >
            <img
              src="/assets/img/symplete-dark.png"
              alt=""
              className="captcha-page__logo"
            />
          </Row>

          <Row
            type="flex"
            className="captcha-page__content"
          >
            <Typography color="ghost">
              We have detected suspicious activity from this IP.
            </Typography>
          </Row>

          <Row
            type="flex"
            className="captcha-page__content"
          >
            <Typography color="ghost">
              Complete the captcha below to proceed.
            </Typography>
          </Row>

          <Row
            type="flex"
            className="captcha-page__captcha"
          >
            <ReCAPTCHA
              onChange={this.onSuccess}
              ref={this.getCaptchaRef}
              sitekey={sitekey}
            />
          </Row>
        </div>
      </Modal>
    );
  }
}

const {
  func,
} = PropTypes;

CaptchaModal.propTypes = {
  loginOnSuccess: func.isRequired,
  triggerCaptchaModal: func.isRequired,
  showCaptchaModal: func.isRequired,
};

export default CaptchaModal;
