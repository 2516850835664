import React, {
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import useContactName from 'shared/hooks/contacts/useContactName';

const roleColors = {
  1: 'buyer',
  2: 'seller',
  3: 'seller/buyer',
};

const roleLabels = {
  1: 'Buyer',
  2: 'Seller',
  3: 'Buyer / Seller',
};

const sources = {
  1: {
    iconName: 'gem',
    iconType: 'fontawesome',
    iconColor: 'purchasedOpportunity',
    tooltip: 'Purchased opportunity',
  },
  2: {
    iconName: 'facebook',
    // iconType: 'fontawesome',
    iconColor: 'main-text',
    tooltip: 'Facebook lead',
  },
  3: {
    iconName: 'globe',
    iconType: 'fontawesome',
    tooltip: 'Lead from agent website',
  },
  4: {
    iconName: 'database',
    iconType: 'fontawesome',
    tooltip: 'Imported lead',
  },
  5: {
    iconName: 'info-circle',
    iconType: 'fontawesome',
    tooltip: 'Other source',
  },
};

const ContactCell = (props) => {
  const {
    contactId,
    tags,
    role,
    source,
    type,
  } = props;

  const tagItems = useMemo(() => (tags.length > 0
    ? (
      <Flex spacing={1}>
        {
          tags.map((tag) => (
            <Typography
              color="leadTag"
              variant="tag"
              noMargin
              weight="bold"
            >
              {tag.toUpperCase()}
            </Typography>
          ))
        }
      </Flex>
    )
    : null),
  [
    tags,
  ]);

  const contactName = useContactName(contactId);

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <ContactAvatar
        contactId={contactId}
        size="sm"
        iconColor={roleColors[role]}
      />
      <Flex spacing={1} flexDirection="column">
        <Tooltip title={contactName}>
          <Typography
            ellipsis
            weight="bold"
            variant="cellLarge"
            noWrap
          >
            {contactName}
          </Typography>
        </Tooltip>
        <Flex spacing={1}>
          {
            source && sources[source] && (
              <Tooltip title={sources[source].tooltip || ''}>
                <div style={{ marginRight: 4 }}>
                  <Icon
                    name={sources[source].iconName}
                    type={sources[source].iconType}
                    color={sources[source].iconColor}
                    size="xs"
                    base="rounded"
                    baseColor="iconBackground"
                  />
                </div>
              </Tooltip>
            )
          }
          { tagItems }
          {
            roleLabels[role] && (
              <Typography
                color="descriptorText"
                variant="tag"
              >
                {`${roleLabels[role] } ${type}`}
              </Typography>
            )
          }
        </Flex>
      </Flex>
    </Flex>
  );
};

ContactCell.defaultProps = {
  tags: [],
  type: '',
};

const {
  arrayOf,
  number,
  string,
} = PropTypes;

ContactCell.propTypes = {
  contactId: number.isRequired,
  tags: arrayOf(string),
  type: string,
};

export default ContactCell;
