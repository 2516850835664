import connectReducers from 'store/connectReducers';
// TODO - fix dependency cycles
import { // eslint-disable-line import/no-cycle
  getOverdueTasksList,
  getDueTomorrowTasksList,
  getProblemTasksList,
} from 'shared/utils';

// eslint-disable-next-line import/no-cycle
import { CHANGE_AUTH_STATUS_SUCCESS } from '../actions/auth';

import { // eslint-disable-line import/no-cycle
  GET_TASKS_BY_STATUS_SUCCESS,
  HIDE_RESOLVE_TASK_MODAL,
  OPEN_RESOLVE_TASK_MODAL,
  RATE_TASK_SUCCESS,
  RESOLVE_TASK_BY_MODAL_SUCCESS,
  SET_TASK_DATA,
  SET_TASK_FILTER,
  SET_TASK_PANELS,
  SET_TASKS_TAB,
  SET_TASKS_TO_TABLE_SUCCESS,
  SET_TASKS_VIEW,
  TOGGLE_SHOW_ARCHIVED_TASKS,
  UNRESOLVE_TASK_SUCCESS,
  SET_IS_RESOLVE_MODAL_CANCELED,
} from '../actions/tasks-list-view';

// eslint-disable-next-line import/no-cycle
import { SET_ACTIVE_EMPLOYEE } from '../actions/tasks-view';
// eslint-disable-next-line import/no-cycle
import { SET_TASK_EMPLOYEES_SUCCESS } from '../actions/task-details-modal';

const setIndex = (data) => data.map((table) => {
  let index = null;
  switch (table.name) {
    case 'Todo':
      index = 0;
      break;
    case 'In Progress':
      index = 1;
      break;
    case 'Require Feedback':
      index = 2;
      break;
    case 'Done':
      index = 3;
      break;
    default:
      break;
  }
  return { ...table, index };
});

const initialState = {
  tablesList: [],
  tablesData: [
    { name: 'Todo', list: [] },
    { name: 'In Progress', list: [] },
    { name: 'Require Feedback', list: [] },
    { name: 'Done', list: [] },
  ],
  activeTablesData: [
    { name: 'Todo', list: [] },
    { name: 'In Progress', list: [] },
    { name: 'Require Feedback', list: [] },
    { name: 'Done', list: [] },
  ],
  resolveModal: {
    isOpen: false,
    isLoading: false,
    isCanceled: false,
  },
  task: {
    status: null,
    data: null,
  },
  panels: ['0'],
  showArchived: false,
  activeTab: '1',
  activeFilter: 'activeTasks',
  tasksView: 'board',
  activeQueue: {
    name: 'All Queues',
    QueueID: 'All Queues',
    CategoryID: null,
    SubCategoryID: null,
  },
  activeTeam: {
    TeamName: 'All Teams',
    TeamID: 0,
  },
  filterTypes: {
    activeTasks: 'activeTasks',
    dueTomorrow: 'dueTomorrow',
    problems: 'problems',
    overdue: 'overdue',
  },
  activeEmployee: null,
};

const reducers = {
  [SET_TASK_FILTER]: (state, action) => ({
    ...state,
    ...action,
  }),

  [SET_TASKS_TAB]: (state, action) => ({ ...state, activeTab: action.tab }),

  [SET_TASKS_VIEW]: (state, action) => {
    const { view } = action;
    return {
      ...state,
      tasksView: view,
    };
  },

  [SET_TASK_DATA]: (state, action) => ({
    ...state,
    task: {
      status: action.status,
      data: action.task,
    },
  }),

  [SET_TASK_PANELS]: (state, action) => ({
    ...state,
    panels: action.panels,
  }),

  [OPEN_RESOLVE_TASK_MODAL]: (state) => ({
    ...state,
    resolveModal: {
      ...state.resolveModal,
      isOpen: true,
    },
  }),

  [HIDE_RESOLVE_TASK_MODAL]: (state) => ({
    ...state,
    resolveModal: {
      ...state.resolveModal,
      isOpen: false,
    },
  }),

  [SET_TASKS_TO_TABLE_SUCCESS]: (state, action) => ({
    ...state,
    tablesList: action.list,
  }),

  [GET_TASKS_BY_STATUS_SUCCESS]: (state, action) => {
    const {
      name,
      list,
    } = action;

    const index = state.tablesData.findIndex((table) => table.name === name);

    let activeList = [...list];

    const {
      activeFilter,
      filterTypes,
    } = state;

    if (activeFilter !== filterTypes.activeTasks) {
      if (activeFilter === filterTypes.problems) {
        activeList = getProblemTasksList(list);
      } else if (activeFilter === filterTypes.overdue) {
        activeList = getOverdueTasksList(list);
      } else if (activeFilter === filterTypes.dueTomorrow) {
        activeList = getDueTomorrowTasksList(list);
      }
    }

    if (index === -1) {
      return {
        ...state,
        tablesData: [...state.tablesData, { name, list }],
        activeTablesData: [...state.activeTablesData, { name, list }],
      };
    }

    const data = state.tablesData;
    data[index].list = list;

    const activeData = state.activeTablesData;
    activeData[index].list = activeList;

    return {
      ...state,
      tablesData: setIndex(data),
      activeTablesData: setIndex(activeData),
    };
  },

  [RATE_TASK_SUCCESS]: (state, action) => ({
    ...state,
    tablesData: state.tablesData.map((table) => {
      if (table.name === 'Done') {
        return {
          ...table,
          list: table.list.map((task) => {
            if (task.TaskID === action.task.TaskID) {
              return {
                ...task,
                Rating: action.data.rateDifficulty,
                CustomerRate: action.data.rateCustomer,
                ResolveComment: action.data.resolveComment,
              };
            }
            return task;
          }),
        };
      }
      return table;
    }),
  }),

  [RESOLVE_TASK_BY_MODAL_SUCCESS]: (state, action) => {
    const doneId = state.tablesList
      .filter((table) => table.name === 'Done')[0]
      .id[0];

    const newTablesResolve = state.tablesData.map((table) => {
      if (table.name === action.status) {
        return {
          ...table,
          list: table.list.filter((task) => task.TaskID !== action.task.TaskID),
        };
      } if (table.name === 'Done') {
        return {
          ...table,
          list: [
            ...table.list,
            {
              ...action.task,
              TaskStatusID: doneId,
              TaskStatus: action.newResolvedName,
              Resolved: 1,
            },
          ],
        };
      }
      return table;
    });

    return {
      ...state,
      tablesData: newTablesResolve,
      activeTablesData: newTablesResolve.map((table) => {
        if (state.activeFilter === state.filterTypes.problems) {
          return {
            ...table,
            list: getProblemTasksList(table.list),
          };
        } if (state.activeFilter === state.filterTypes.overdue) {
          return { ...table, list: getOverdueTasksList(table.list) };
        } if (state.activeFilter === state.filterTypes.dueTomorrow) {
          return { ...table, list: getDueTomorrowTasksList(table.list) };
        }

        return table;
      }),
    };
  },

  [UNRESOLVE_TASK_SUCCESS]: (state) => ({
    ...state,
  }),

  [CHANGE_AUTH_STATUS_SUCCESS]: (state, action) => {
    const activeEmployee = state.activeEmployee
      ? {
        ...state.activeEmployee,
        availability:
            state.activeEmployee.id === action.payload.EmployeeID
              ? action.payload.useravailability
              : state.activeEmployee.availability,
      }
      : null;

    return {
      ...state,
      activeEmployee,
    };
  },

  [SET_TASK_EMPLOYEES_SUCCESS]: (state) => ({
    ...state,
    tablesList: [],
    tablesData: [],
    activeTablesData: [],
  }),

  [SET_ACTIVE_EMPLOYEE]: (state, action) => {
    const newState = {
      ...state,
      activeEmployee: action.employee,
    };

    return newState;
  },

  [TOGGLE_SHOW_ARCHIVED_TASKS]: (state) => ({
    ...state,
    showArchived: !state.showArchived,
  }),

  [SET_IS_RESOLVE_MODAL_CANCELED]: (state, action) => {
    const { isCanceled } = action;
    return {
      ...state,
      resolveModal: {
        ...state.resolveModal,
        isCanceled,
      },
    };
  },
};

function tasksListView(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default tasksListView;
