import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import { // eslint-disable-line import/no-cycle
  optimizedLikesActions,
} from 'store/actions/likes-dislikes/likes';

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (state) => state.likes.likes,
  (_, contactId) => contactId,
  (
    contacts,
    likes,
    id,
  ) => {
    const contact = contacts[id];

    if (!contact) {
      if (id) {
        optimizedRealtorContactsActions.getContactById(id);
      }

      return [];
    }

    const likesIds = contact.likes || [];

    const contactLikes = likesIds.reduce((accum, likeId) => {
      const like = likes[likeId];

      if (!like) {
        if (likeId) {
          optimizedLikesActions.getLikeById(likeId);
        }

        return accum;
      }

      accum.push({
        id: like.id,
        title: like.title,
        description: like.description,
      });
      return accum;
    }, []);

    return contactLikes;
  },
);
