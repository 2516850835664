import byId from './byId'; // eslint-disable-line import/no-cycle
import contactsFilter from './contactsFilter';
import contactsList from './contactsList'; // eslint-disable-line import/no-cycle
import basicById from './basicById';
import selected from './selected'; // eslint-disable-line import/no-cycle
import contactLikes from './contactLikes'; // eslint-disable-line import/no-cycle
import contactDislikes from './contactDislikes'; // eslint-disable-line import/no-cycle
import contactCallRecordings from './contactCallRecordings'; // eslint-disable-line import/no-cycle
import contactName from './contactName'; // eslint-disable-line import/no-cycle
import contactPhoneNumbers from './contactPhoneNumbers'; // eslint-disable-line import/no-cycle
import contactEmails from './contactEmails'; // eslint-disable-line import/no-cycle

export default {
  basicById,
  byId,
  contactsFilter,
  contactsList,
  selected,
  contactLikes,
  contactDislikes,
  contactCallRecordings,
  contactName,
  contactPhoneNumbers,
  contactEmails,
};
