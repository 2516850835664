import requests from 'api/requests';

import setClients from './setClients';
import setClientsForFilter from './setClientsForFilter';
import setClientsForFilterCount from './setClientsForFilterCount';

const getClientsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.clients.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setClients(response.data.items));
    dispatch(setClientsForFilter(filterId)(response.data.items));
    dispatch(setClientsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getClientsForFilter;
