import {
  SET_REALTOR_OPPORTUNITIES_OVERALL_SUMMARY,
} from 'store/actionTypes/realtorOpportunities';

const setOpportunitiesOverallSummary = (summary) => ({
  type: SET_REALTOR_OPPORTUNITIES_OVERALL_SUMMARY,
  payload: summary,
});

export default setOpportunitiesOverallSummary;
