import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'ui/Chip';
import withAddress from 'shared/hocs/withAddress';

const AddressChip = (props) => {
  const {
    onDelete,
    address,
  } = props;

  return (
    <Chip
      onDelete={onDelete}
      variant="subtle"
      ellipsis
    >
      {address}
    </Chip>
  );
};

AddressChip.defaultProps = {
  onDelete: null,
  address: null,
};

const {
  func,
  string,
} = PropTypes;

AddressChip.propTypes = {
  onDelete: func,
  address: string,
};

export default withAddress(AddressChip);
