import {
  SET_CRM_LEAD_CHANGELOG,
} from 'store/actionTypes/crm';

const setLeadChangelog = ({ leadId, changelog }) => ({
  type: SET_CRM_LEAD_CHANGELOG,
  payload: {
    leadId,
    changelog,
  },
});

export default setLeadChangelog;
