import { createSelector } from 'reselect';

import {
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

export default createSelector(
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.selectedFulfillment,
  (items, itemId) => {
    if (!items[itemId]) {
      if (itemId) {
        optimizedFulfillmentsActions.getFulfillmentById(itemId);
      }

      return null;
    }

    const owner = items[itemId].owners?.employee?.[0];

    return owner;
  },
);
