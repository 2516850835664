import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  // setSelectedContactRows,
  getContactsForStoreFilter,
} from 'store/actions/realtorContacts';

import {
  setFilterItem,
} from 'store/actions/realtorContactsFilter';

import ContactsTable from './ContactsTable';

const mapStateToProps = (state) => {
  const {
    realtorContacts: {
      contactsByFilterCount,
    },
    realtorContactsFilter: {
      pageNumber,
      pageSize,
      // sortBy,
      // sortDirection,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorContacts.contactsFilter(state);

  const contactsList = selectors.realtorContacts.contactsList(state, filterId);

  return {
    itemsCount: contactsByFilterCount[filterId],
    contactsList,
    pageNumber,
    pageSize,
    sortBy: 'status',
    sortDirection: 'desc',
  };
};

const mapDispatchToProps = (dispatch) => ({
  getContacts: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    dispatch(getContactsForStoreFilter(offset, limit));
  },
  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
  // onRowSelect: (selectedContactIds) => {
  //   dispatch(setSelectedContactRows(selectedContactIds));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsTable);
