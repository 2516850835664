import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_NEW_REALTOR_INSPECTION_FORM_STATE,
} from 'store/actionTypes/realtorInspections';

const openNewInspectionModal = openModalWithContentAction('newInspection')(
  SET_NEW_REALTOR_INSPECTION_FORM_STATE,
);

export default openNewInspectionModal;
