import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getPropertyRequirements from './getPropertyRequirements';
import getPropertyRequirementById from './getPropertyRequirementById';
import updatePropertyRequirement from './updatePropertyRequirement';
import deletePropertyRequirement from './deletePropertyRequirement';
import addPropertyRequirementRelations from './addPropertyRequirementRelations';
import removePropertyRequirementRelations from './removePropertyRequirementRelations';
import overwritePropertyRequirementRelations from './overwritePropertyRequirementRelations';

export { getPropertyRequirements };
export { getPropertyRequirementById };
export { updatePropertyRequirement };
export { deletePropertyRequirement };
export { addPropertyRequirementRelations };
export { removePropertyRequirementRelations };
export { overwritePropertyRequirementRelations };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedPropertyRequirementActions = {
  getPropertyRequirementById: memoizeDebounce(
    (id) => store.dispatch(getPropertyRequirementById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
