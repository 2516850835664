import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import AddPhoneNumber from './AddPhoneNumber';

const mapStateToProps = (state) => {
  const {
    modals: {
      addPhoneNumber,
    },
  } = state;
  return {
    isVisible: addPhoneNumber?.isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => { dispatch(closeModal('addPhoneNumber')()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPhoneNumber);
