export const SET_QUEUE = 'SET_QUEUE';
export const SET_QUEUES = 'SET_QUEUES';
export const SET_QUEUES_COUNT = 'SET_QUEUES_COUNT';
export const REMOVE_QUEUE = 'REMOVE_QUEUE';
export const SET_QUEUES_FOR_MANAGER = 'SET_QUEUES_FOR_MANAGER';
export const CLEAR_QUEUE_DATA = 'CLEAR_QUEUE_DATA';

export default {
  SET_QUEUE,
  SET_QUEUES,
  SET_QUEUES_COUNT,
  REMOVE_QUEUE,
  SET_QUEUES_FOR_MANAGER,
  CLEAR_QUEUE_DATA,
};
