import getFulfillmentChangelogAllTime from './getFulfillmentChangelogAllTime';

const getSelectedFulfillmentChangelogAllTime = () => (dispatch, getState) => {
  const {
    fulfillments: {
      selectedFulfillment,
    },
  } = getState();

  try {
    dispatch(getFulfillmentChangelogAllTime(selectedFulfillment));
  } catch (error) {
    console.error(error);
  }
};

export default getSelectedFulfillmentChangelogAllTime;
