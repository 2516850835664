import React from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import CurrencyTextField from 'ui/CurrencyInput';

import useRangeValues from 'ui/hooks/useRangeValues';

const PriceRange = (props) => {
  const {
    onChange,
    value,
  } = props;

  const {
    min,
    max,
    handleChange,
  } = useRangeValues(value, onChange);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <CurrencyTextField
          className=""
          label="Min."
          onChange={handleChange('min')}
          value={min}
          margin="dense"
          variant="outlined"
          outputFormat="number"
          minimumValue={0}
          currencySymbol="$"
          fullWidth
          decimalPlaces={0}
        />
      </Grid>
      <Grid item xs={6}>
        <CurrencyTextField
          className=""
          label="Max."
          onChange={handleChange('max')}
          value={max}
          margin="dense"
          variant="outlined"
          outputFormat="number"
          minimumValue={0}
          currencySymbol="$"
          fullWidth
          decimalPlaces={0}
        />
      </Grid>
    </Grid>
  );
};

const {
  arrayOf,
  func,
  number,
} = PropTypes;

PriceRange.propTypes = {
  onChange: func.isRequired,
  value: arrayOf(number).isRequired,
};

export default PriceRange;
