import moment from 'moment';

import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

const fiveMinsInMillisecs = 300000;

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.propertyViewings.viewings,
  (_, id) => id,
  (
    properties,
    viewings,
    propertyId,
  ) => {
    const property = properties[propertyId];

    if (!property) {
      if (propertyId) {
        optimizedPropertyActions.getPropertyById(propertyId);
      }

      return null;
    }

    const numOfFutureEvents = property.viewings_summary?.upcoming_viewings || 0;

    if (numOfFutureEvents < 1) {
      return null;
    }

    const upcomingEvents = (Array.isArray(property.viewings) && property.viewings.reduce((accum, viewingId) => {
      const viewing = viewings[viewingId];

      if (!viewing) {
        if (viewingId) {
          optimizedPropertyViewingActions.getPropertyViewingById(viewingId);
        }

        return accum;
      }

      const diff = moment(viewing.date_time).diff(moment());

      if (diff < -fiveMinsInMillisecs) {
        return accum;
      }

      accum.push(viewing);
      return accum;
    }, [])) || [];

    let nearestEvent;

    upcomingEvents.forEach((viewing) => {
      const {
        date_time,
      } = viewing;

      if (nearestEvent) {
        if (moment(date_time).diff(moment(nearestEvent.date_time)) < 0) {
          nearestEvent = viewing;
        }
      } else {
        nearestEvent = viewing;
      }
    });

    return nearestEvent;
  },
);
