import newApiConnector from 'api';

/**
 * Changes the company office information in admin applications
 * @param {Array} offices All company offices
 * @param {Object} newOfficeInformation New office information
 * @param {string} databaseName Name of the client database
 */
const changeMainOfficeInformationInGlobal = async (offices, newOfficeInformation, databaseName) => {
  const globalEndpointURL = '/client/change-office-information';
  let isChangedOfficesMain = false;

  offices.forEach((office) => {
    if (newOfficeInformation.OfficeID === office.OfficeID && office.isMain) {
      isChangedOfficesMain = true;
    }
  });

  if (isChangedOfficesMain) {
    await newApiConnector.global.post(globalEndpointURL, {
      newOfficeInformation,
      databaseName,
    });
  }
};

export default changeMainOfficeInformationInGlobal;
