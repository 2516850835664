import store from 'store'; // eslint-disable-line import/no-cycle

import { memoizeDebounce } from 'shared/utility';

import getTasksWithStatus from './getTasksWithStatus';

import getEmployeeTasksWithStatus from './getEmployeeTasksWithStatus';
import getTeamTasksWithStatus from './getTeamTasksWithStatus';

import getEmployeeTasksSummary from './getEmployeeTasksSummary';
import getTeamTasksSummary from './getTeamTasksSummary';
import getOverallTasksSummary from './getOverallTasksSummary';
import getFilterTasksSummary from './getFilterTasksSummary';

import getEmployeeTasks from './getEmployeeTasks';
import getTeamTasks from './getTeamTasks';
import getFilterTasks from './getFilterTasks';

import getTaskById from './getTaskById';

import getProblemTasks from './getProblemTasks';
import getTeamProblemTasks from './getTeamProblemTasks';

import getDueThisWeekTasks from './getDueThisWeekTasks';
import getTeamDueThisWeekTasks from './getTeamDueThisWeekTasks';
import getEmployeeDueThisWeekTasks from './getEmployeeDueThisWeekTasks';

import getDoneThisWeekTasks from './getDoneThisWeekTasks';
import getTeamDoneThisWeekTasks from './getTeamDoneThisWeekTasks';

import getOverdueTasks from './getOverdueTasks';
import getTeamOverdueTasks from './getTeamOverdueTasks';

import getTaskWorklogs from './getTaskWorklogs';
import getTaskLastUpdateInfo from './getTaskLastUpdateInfo';

import getEmployeeTasksSummaryForPeriod from './getEmployeeTasksSummaryForPeriod';

import setActiveTaskPlannerBacklogFilter from './setActiveTaskPlannerBacklogFilter';

export { getTaskById };
export { getEmployeeTasksSummary };
export { getTeamTasksSummary };
export { getOverallTasksSummary };
export { getTaskWorklogs };
export { getTaskLastUpdateInfo };

export { default as getTaskAndRefreshRelatedSummaries } from './getTaskAndRefreshRelatedSummaries';

export { default as updateTaskStatus } from './updateTaskStatus';
export { default as updateTaskAssignees } from './updateTaskAssignees';
export { default as assignTaskFromBacklog } from './assignTaskFromBacklog';

export { default as changeTaskRank } from './changeTaskRank';

export { default as selectTask } from './selectTask'; // eslint-disable-line import/no-cycle
export { default as setTasksForEmployee } from './setTasksForEmployee';
export { default as addTutorialTask } from './addTutorialTask';
export { default as removeTutorialTask } from './removeTutorialTask';

export { default as setTaskPlannerBacklogFilter } from './setTaskPlannerBacklogFilter';

const getBacklogItems = getTasksWithStatus(1);
const getTodoItems = getTasksWithStatus(2);
const getInProgressItems = getTasksWithStatus(3);
const getRequireFeedbackItems = getTasksWithStatus(4);
const getDoneItems = getTasksWithStatus(5);
const getArchivedItems = getTasksWithStatus(6);

const getBacklogItemsForEmployee = getEmployeeTasksWithStatus(1);
const getTodoItemsForEmployee = getEmployeeTasksWithStatus(2);
const getInProgressItemsForEmployee = getEmployeeTasksWithStatus(3);
const getRequireFeedbackItemsForEmployee = getEmployeeTasksWithStatus(4);
const getDoneItemsForEmployee = getEmployeeTasksWithStatus(5);
const getArchivedItemsForEmployee = getEmployeeTasksWithStatus(6);

const getBacklogItemsForTeam = getTeamTasksWithStatus(1);
const getTodoItemsForTeam = getTeamTasksWithStatus(2);
const getInProgressItemsForTeam = getTeamTasksWithStatus(3);
const getRequireFeedbackItemsForTeam = getTeamTasksWithStatus(4);
const getDoneItemsForTeam = getTeamTasksWithStatus(5);
const getArchivedItemsForTeam = getTeamTasksWithStatus(6);

const getProblemItems = getProblemTasks(1);
const getTeamProblemItems = getTeamProblemTasks(1);

const getDueThisWeekItems = getDueThisWeekTasks(1);
const getTeamDueThisWeekItems = getTeamDueThisWeekTasks(1);
const getEmployeeDueThisWeekItems = getEmployeeDueThisWeekTasks(1);

const getDoneThisWeekItems = getDoneThisWeekTasks(1);
const getTeamDoneThisWeekItems = getTeamDoneThisWeekTasks(1);

const getOverdueItems = getOverdueTasks(1);
const getTeamOverdueItems = getTeamOverdueTasks(1);

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

/* eslint-disable max-len */
export const optimizedTasksActions = {
  getBacklogItems: memoizeDebounce((offset, limit) => store.dispatch(getBacklogItems(offset, limit)), debounceDelay, debounceOptions),
  getTodoItems: memoizeDebounce((offset, limit) => store.dispatch(getTodoItems(offset, limit)), debounceDelay, debounceOptions),
  getInProgressItems: memoizeDebounce((offset, limit) => store.dispatch(getInProgressItems(offset, limit)), debounceDelay, debounceOptions),
  getRequireFeedbackItems: memoizeDebounce((offset, limit) => store.dispatch(getRequireFeedbackItems(offset, limit)), debounceDelay, debounceOptions),
  getDoneItems: memoizeDebounce((offset, limit) => store.dispatch(getDoneItems(offset, limit)), debounceDelay, debounceOptions),
  getArchivedItems: memoizeDebounce((offset, limit) => store.dispatch(getArchivedItems(offset, limit)), debounceDelay, debounceOptions),

  getBacklogItemsForEmployee: memoizeDebounce((id, offset, limit) => store.dispatch(getBacklogItemsForEmployee(id)(offset, limit)), debounceDelay, debounceOptions),
  getTodoItemsForEmployee: memoizeDebounce((id, offset, limit) => store.dispatch(getTodoItemsForEmployee(id)(offset, limit)), debounceDelay, debounceOptions),
  getInProgressItemsForEmployee: memoizeDebounce((id, offset, limit) => store.dispatch(getInProgressItemsForEmployee(id)(offset, limit)), debounceDelay, debounceOptions),
  getRequireFeedbackItemsForEmployee: memoizeDebounce((id, offset, limit) => store.dispatch(getRequireFeedbackItemsForEmployee(id)(offset, limit)), debounceDelay, debounceOptions),
  getDoneItemsForEmployee: memoizeDebounce((id, offset, limit) => store.dispatch(getDoneItemsForEmployee(id)(offset, limit)), debounceDelay, debounceOptions),
  getArchivedItemsForEmployee: memoizeDebounce((id, offset, limit) => store.dispatch(getArchivedItemsForEmployee(id)(offset, limit)), debounceDelay, debounceOptions),

  getBacklogItemsForTeam: memoizeDebounce((id, offset, limit) => store.dispatch(getBacklogItemsForTeam(id)(offset, limit)), debounceDelay, debounceOptions),
  getTodoItemsForTeam: memoizeDebounce((id, offset, limit) => store.dispatch(getTodoItemsForTeam(id)(offset, limit)), debounceDelay, debounceOptions),
  getInProgressItemsForTeam: memoizeDebounce((id, offset, limit) => store.dispatch(getInProgressItemsForTeam(id)(offset, limit)), debounceDelay, debounceOptions),
  getRequireFeedbackItemsForTeam: memoizeDebounce((id, offset, limit) => store.dispatch(getRequireFeedbackItemsForTeam(id)(offset, limit)), debounceDelay, debounceOptions),
  getDoneItemsForTeam: memoizeDebounce((id, offset, limit) => store.dispatch(getDoneItemsForTeam(id)(offset, limit)), debounceDelay, debounceOptions),
  getArchivedItemsForTeam: memoizeDebounce((id, offset, limit) => store.dispatch(getArchivedItemsForTeam(id)(offset, limit)), debounceDelay, debounceOptions),

  getEmployeeTasksSummary: memoizeDebounce((id) => store.dispatch(getEmployeeTasksSummary(id)), 5000, { leading: true, trailing: false }),
  getTeamTasksSummary: memoizeDebounce((id) => store.dispatch(getTeamTasksSummary(id)), 5000, { leading: true, trailing: false }),
  getOverallTasksSummary: memoizeDebounce(() => store.dispatch(getOverallTasksSummary()), 5000, { leading: true, trailing: false }),
  getFilterTasksSummary: memoizeDebounce((filterId, filter) => store.dispatch(getFilterTasksSummary(filterId)(filter)), 1000, debounceOptions),
  getEmployeeTasksSummaryForPeriod: memoizeDebounce((employeeId, section) => store.dispatch(getEmployeeTasksSummaryForPeriod(employeeId, section)), 5000, { leading: true, trailing: false }),

  getEmployeeTasks: memoizeDebounce((id, offset, limit) => store.dispatch(getEmployeeTasks(id)(offset, limit)), 5000, { leading: true, trailing: false }),
  getTeamTasks: memoizeDebounce((id, offset, limit) => store.dispatch(getTeamTasks(id)(offset, limit)), 5000, { leading: true, trailing: false }),
  getFilterTasks: memoizeDebounce((filterId, filter, offset, limit) => store.dispatch(getFilterTasks(filterId)(filter)(offset, limit)), debounceDelay, debounceOptions),

  getProblemItems: memoizeDebounce((offset, limit) => store.dispatch(getProblemItems(offset, limit)), debounceDelay),
  getTeamProblemItems: memoizeDebounce((id, offset, limit) => store.dispatch(getTeamProblemItems(id)(offset, limit)), debounceDelay, debounceOptions),

  getDueThisWeekItems: memoizeDebounce((offset, limit) => store.dispatch(getDueThisWeekItems(offset, limit)), debounceDelay),
  getTeamDueThisWeekItems: memoizeDebounce((id, offset, limit) => store.dispatch(getTeamDueThisWeekItems(id)(offset, limit)), debounceDelay, debounceOptions),
  getEmployeeDueThisWeekItems: memoizeDebounce((id, offset, limit) => store.dispatch(getEmployeeDueThisWeekItems(id)(offset, limit)), debounceDelay, debounceOptions),

  getOverdueItems: memoizeDebounce((offset, limit) => store.dispatch(getOverdueItems(offset, limit)), debounceDelay),
  getTeamOverdueItems: memoizeDebounce((id, offset, limit) => store.dispatch(getTeamOverdueItems(id)(offset, limit)), debounceDelay, debounceOptions),

  getDoneThisWeekItems: memoizeDebounce((offset, limit) => store.dispatch(getDoneThisWeekItems(offset, limit)), debounceDelay),
  getTeamDoneThisWeekItems: memoizeDebounce((id, offset, limit) => store.dispatch(getTeamDoneThisWeekItems(id)(offset, limit)), debounceDelay, debounceOptions),

  getTaskById: memoizeDebounce((id) => store.dispatch(getTaskById(id)), debounceDelay),

  getTaskWorklogs: (taskId) => store.dispatch(getTaskWorklogs(taskId)),
  getTaskLastUpdateInfo: (taskId) => store.dispatch(getTaskLastUpdateInfo(taskId)),

  setActiveTaskPlannerBacklogFilter: (filterId) => store.dispatch(setActiveTaskPlannerBacklogFilter(filterId)),
};
/* eslint-enable max-len */

export default {

};
