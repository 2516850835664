import apiConnector, { endpoints } from 'api';

// TODO reconsider the way we handle attachments

/**
 * Get the list of attachment object for the given object
 * @param {string} objectType
 * @returns
 */
const getFileList = (objectType) => async (objectId) => {
  const endpoint = `${endpoints.client.attachments}/${objectType}/${objectId}`;
  try {
    const response = await apiConnector.client.get(endpoint);
    return response.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getFileList;
