import {
  isPossiblePhoneNumber,
} from 'libphonenumber-js';

import requests from 'api/requests';

// eslint-disable-next-line consistent-return
const create = (phoneNumber) => async (dispatch) => {
  if (typeof phoneNumber !== 'string' || !isPossiblePhoneNumber(phoneNumber, 'US')) {
    return undefined;
  }

  try {
    const response = await requests.phoneNumbers.create({ phoneNumber });

    return response.data.numberId;
  } catch (error) {
    console.error(error);
  }
};

export default create;
