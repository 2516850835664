import requests from 'api/requests';

import setPaymentAttachments from './setPaymentAttachments';

const getPaymentAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(13)(id);

    dispatch(setPaymentAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getPaymentAttachments;
