import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} leadData
 */
const create = async (leadData) => {
  const endpoint = endpoints.client.realtor.leads;
  try {
    const result = await apiConnector.client.post(endpoint, leadData);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
