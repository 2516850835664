/* eslint-disable react/jsx-no-bind */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import styled from 'styled-components';

import EmailDrawer from 'sections/Contacts/modules/EmailDrawer';

import Button from 'ui/Button';
import Icon from 'ui/Icon';
import withOptionsMenu from 'ui/withOptionsMenu';

import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModalNew';

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MenuButton = withOptionsMenu(Button);

const options = [
  {
    value: 'contact',
    label: (
      <Option>
        <Icon
          name="book-open"
          color="primary"
        />
        Contact
      </Option>
    ),
  },
  {
    value: 'property',
    label: (
      <Option>
        <Icon
          name="home"
          color="primary"
        />
        Property
      </Option>
    ),
  },
  {
    value: 'lead',
    label: (
      <Option>
        <Icon
          name="users"
          color="primary"
        />
        Lead
      </Option>
    ),
  },
  {
    value: 'offer',
    label: (
      <Option>
        <Icon
          name="dollar-sign"
          color="primary"
        />
        Offer
      </Option>
    ),
  },
  {
    value: 'followup',
    label: (
      <Option>
        <Icon
          name="phone-forwarded"
          color="primary"
        />
        Follow Up
      </Option>
    ),
  },
];

const New = (props) => {
  const {
    onSelect,
  } = props;

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((value) => !value);
  }, []);

  useEffect(() => {
    options.push(
      {
        value: 'email',
        label: (
          <Option
            onClick={toggleDrawer}
          >
            <Icon
              name="mail"
              color="primary"
            />
            Email
          </Option>
        ),
      },
    );
  }, [options]);

  return (
    <>
      <MenuButton
        style={{
          whiteSpace: 'nowrap',
        }}
        type="primary"
        onChange={onSelect}
        options={options}
        icon="plus"
      >
        New
      </MenuButton>

      <FollowUpRemindersModal />
      <EmailDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

const {
  func,
} = PropTypes;

New.propTypes = {
  onSelect: func.isRequired,
};

export default New;
