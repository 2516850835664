import styled from 'styled-components';

import DefaultModal from 'ui/Modal';

import { palette } from 'shared/components/CRM/UI/variables';

const Modal = styled(DefaultModal)`
  .ant-modal-footer {
    border-top: none;
    .ant-btn {
      border: none;
      font-weight: 700;
      &-primary {
        min-width: 90px;
        background-color: ${palette.primary};
      }
      &:hover {
        color: ${palette.primary};
        &.ant-btn-primary {
          color: white
        }
      }
    }
  }
`;
export default Modal;
