import { connect } from 'react-redux';

import {
  setNewContactFormField,
  setNewContactNestedFormField,
} from 'store/actions/newRealtorContactForm';

import {
  checkExistingContact,
} from 'store/actions/realtorContacts';

import EmailInput from 'shared/inputs/EmailInput';

const mapStateToProps = (state) => {
  const {
    realtorNewContactForm: {
      fieldsValidators: {
        isEmailValid,
        doesEmailExist,
      },
    },
  } = state;

  return {
    isEmailValid,
    doesEmailExist,
    alertText: 'Email already exists in database.',
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('mainEmail')(value)),
  setNotValidEmail: (property, value) => dispatch(setNewContactNestedFormField('fieldsValidators')(property)(value)),
  checkEmailExistence: async (value) => {
    const contact = await dispatch(checkExistingContact('email_primary')(value));

    return contact;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailInput);
