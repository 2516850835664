import {
  SET_EXTERNAL_TOKEN_ISSUER_USER_DATA,
} from 'store/actionTypes/externalAccess';

const setTokenIssuerUserData = (data) => ({
  type: SET_EXTERNAL_TOKEN_ISSUER_USER_DATA,
  payload: data,
});

export default setTokenIssuerUserData;
