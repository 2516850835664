import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

const contacts = (state) => state.realtorContacts.contacts;
const itemsForFilter = (state, filterId) => state.realtorContacts.contactsByFilter[filterId];

export default createSelector(
  contacts,
  itemsForFilter,
  (items, filteredIds) => (filteredIds
    ? filteredIds.reduce((accum, id, index) => {
      const contact = items[id];

      if (!contact && id) {
        optimizedRealtorContactsActions.getContactById(id);
        return accum;
      }

      accum.push({
        key: index + 1,
        ...contact,
      });
      return accum;

      // return {
      //   id: lead.id,
      //   status: tempDict[lead.temp] || 'not set',
      //   title: lead.title,
      //   products: lead.products ? lead.products.map((product) => product.id) : null,
      //   value: lead.value,
      //   company: lead.company,
      //   contact: lead.contact,
      //   activity: '',
      //   source: lead.source,
      //   created: lead.created_at,
      //   addedBy: lead.created_by,
      //   claimedBy: lead.claimed_by,
      // };
    }, [])
    : []),
);
