import {
  optimizedRealtorAddendumChangeActions,
} from 'store/actions/realtorAddendumChanges';

import withAttachmentsList from 'shared/hocs/withAttachmentsList';

const withAddendumChangeAttachments = (Component) => withAttachmentsList(39)({
    callbacks: {
      onAfterUpload: optimizedRealtorAddendumChangeActions.getAttachments,
      onAfterRemove: optimizedRealtorAddendumChangeActions.getAttachments,
    },
    selectorPath: 'realtorAddendumChanges.attachments',
  })(Component);

export default withAddendumChangeAttachments;
