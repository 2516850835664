import { connect } from 'react-redux';

import searchClients from 'store/actions/realtorClients/searchClients';

import ClientsSearch from 'shared/components/UI/RealtorMasterSearch/ClientsSearch';

const mapDispatchToProps = (dispatch) => ({
  onSearch: async (searchQuery) => {
    if (!searchQuery) {
      return [];
    }
    const result = await dispatch(searchClients({ search: searchQuery }));

    return result;
  },
});

export default connect(null, mapDispatchToProps)(ClientsSearch);
