import {
  SET_WAREHOUSE_KANBAN,
} from 'store/actionTypes/warehouse';

const setWarehouseKanban = (column, data) => ({
  type: SET_WAREHOUSE_KANBAN,
  payload: data,
  column,
});

export default setWarehouseKanban;
