import styled from 'styled-components';

import {
  Table as AntdTable,
} from 'antd';

const Table = styled(AntdTable)`
  .ant-table-row {
    &.warning {
      background-color: ${(props) => props.theme?.palette['background-warning'] || 'yellow'};
    }
    td {
      height: 70px;
      padding: 12px !important;
    }
  }

  .ant-table-row-level-0 {
    border-top: 1px solid ${(props) => props.theme?.palette?.border || '#e8e8e8'};
  }
  .ant-table-row-level-1 {
    border-top: none;

    .ant-table-selection-column .ant-checkbox-wrapper {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    border-top: inherit;
    border-bottom: inherit;
  }
  .realtor-leads-table__sm-padding {
    padding: 10px 8px !important;
  }
  .ant-table-tbody tr:first-child {
    border-top: none;
  }
  .ant-table-tbody tr:last-child {
    border-bottom: 1px solid ${(props) => props.theme?.palette?.border || '#e8e8e8'};
  }
  .ant-table-thead > tr > th {
    background: ${(props) => props.theme.palette.background};
    font-family: ${(props) => props.theme.typography.fontFamily.tableHeader};
    font-size: ${(props) => props.theme.typography.fontSize.tableHeader};
    font-weight: ${(props) => props.theme.typography.fontWeight.tableHeader};
    border-bottom: 1px solid ${(props) => props.theme?.palette?.border || '#e8e8e8'};
    padding: 12px !important;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: ${(props) => props.theme.palette['background-flat']}
  }

   * pagination */
  .ant-pagination-total-text {
    font-size: ${(props) => props.theme.typography.fontSize['main-text']};
    color: ${(props) => props.theme.palette['main-text']};
  }

  .ant-pagination-item {
    a {
      font-size: ${(props) => props.theme.typography.fontSize['main-text']};
      color: ${(props) => props.theme.palette['main-text']};
    }
    &-active,
    &:focus {
      background: ${(props) => props.theme.palette.focus};
      border-color: ${(props) => props.theme.palette.focus};
      a {
        font-size: ${(props) => props.theme.typography.fontSize['main-text']};
        color: ${(props) => props.theme.palette['main-text-inversed']};
      }
    }
    &:hover {
      a {
        color: ${(props) => props.theme.palette.focus};
      }
    }
  }

  .ant-pagination {
    &-prev,
    &-next {
      color: ${(props) => props.theme.palette['main-text']};
      &:hover,
      &:focus {
        .ant-pagination-item-link {
          border-color: ${(props) => props.theme.palette.focus};
          color: ${(props) => props.theme.palette.focus};
        }
      }
    }
  }

  .ant-pagination-disabled {
    &:hover {
      a {
        border-color: ${(props) => props.theme.palette.disabled} !important;
        color: rgba(0,0,0,0.25) !important;
      }
    }
  }
  /*  */

  /* table row */
  .ant-table-row {

    &:hover {
      cursor: pointer;
    }
  }

  .ant-table {
    .ant-checkbox {
      &-inner {
        height: 22px;
        width: 22px;
        border-color: ${(props) => props.theme.palette.delimiter};
        border-radius: 4px;

        &::after {
          top: 46%;
          left: 30%;
        }
      }

      &-indeterminate {
        .ant-checkbox-inner::after {
          top: 50%;
          left: 50%;
          height: 2px;
          width: 10px;
        }
      }
    }
  }
  /*  */
`;

export default Table;
