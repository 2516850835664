import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-cycle
import { optimizedRealtorContactsActions } from 'store/actions/realtorContacts';

import getFullName from 'shared/utils/getFullName';

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (_, id) => id,
  (collection, id) => {
    const defaultItem = {
      id: null,
      description: null,
      first_contact_date: null,
      source_id: null,
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedRealtorContactsActions.getContactById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
      fullName: getFullName(item),
    };
  },
);
