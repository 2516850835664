// eslint-disable-next-line import/prefer-default-export
export const timeIntervalTypes = {
  6: {
    value: 6,
    label: 'Minute',
    momentShorthand: 'm',
  },
  1: {
    value: 1,
    label: 'Hours',
    momentShorthand: 'h',
  },
  2: {
    value: 2,
    label: 'Days',
    momentShorthand: 'd',
  },
  3: {
    value: 3,
    label: 'Weeks',
    momentShorthand: 'w',
  },
  4: {
    value: 4,
    label: 'Mouth',
    momentShorthand: 'M',
  },
  5: {
    value: 5,
    label: 'Years',
    momentShorthand: 'y',
  },
};
