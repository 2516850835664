import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedOfferActions,
} from 'store/actions/realtorOffers';

import clientById from 'store/selectors/realtorClients/byId'; // eslint-disable-line import/no-cycle
import propertyById from 'store/selectors/properties/byId'; // eslint-disable-line import/no-cycle
import addressById from 'store/selectors/addresses/byId';

export default createSelector(
  (state) => state.realtorOffers.offers,
  (state) => (id) => clientById(state, id),
  (state) => (id) => propertyById(state, id),
  (state) => (id) => addressById(state, id),
  (_, id) => id,
  (
    collection,
    getClientById,
    getPropertyById,
    getAddressById,
    id,
  ) => {
    const defaultItem = {
      id: null,
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedOfferActions.getOfferById(id);
      }

      return defaultItem;
    }

    const client = getClientById(item.realtor_client_id);
    const property = getPropertyById(item.realtor_property_id || client.realtor_property_id);
    const address = getAddressById(property.address_id);

    return {
      ...item,
      relatedData: {
        client,
        property,
        address,
      },
    };
  },
);
