import styled from 'styled-components';

import HouseDefault from 'assets/icons/property.svg';

export const Wrapper = styled.div`
  width: fit-content;
`;

export const Root = styled.div`
  border-radius: 7px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
`;

export const IconWrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 7px;
  display: flex;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
`;

export default {
  Root,
  Wrapper,
  IconWrapper,
};
