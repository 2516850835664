import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import { Device } from '@twilio/voice-sdk';

import useCallToken from 'sections/Calls/hooks/useCallToken';

const addDeviceListeners = (device) => ({ onRegistered, onError, onDeviceChange }) => {
  device.on('registered', onRegistered);
  device.on('error', onError);
  device.audio.on('deviceChange', onDeviceChange);
};

const getUserMedia = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    window.streamReference = stream;
  } catch (error) {
    console.error(error);
  }
};

const stopAudioStream = async (device) => {
  if (device?.audio) {
    try {
      await device.audio.unsetInputDevice();
    } catch (err) {
      console.error(err);
    }
  }
  if (window.streamReference) {
    window.streamReference.getAudioTracks().forEach((track) => {
      track.stop();
    });
  }
};

const handleDeviceChange = (setInputList, setOutputList) => async (setInputDevice, setOutputDevice) => {
  let mediaDevices;

  try {
    mediaDevices = await navigator.mediaDevices.enumerateDevices();
  } catch (error) {
    console.error(error);
  }

  if (mediaDevices) {
    // input media devices
    const inputMediaDevices = mediaDevices.reduce((accum, mediaDevice) => {
      if (mediaDevice.kind === 'audioinput' && mediaDevice.deviceId) {
        accum.push({
          value: mediaDevice.deviceId,
          label: mediaDevice.label,
        });
      }

      return accum;
    }, []);

    setInputList(inputMediaDevices);

    if (inputMediaDevices.length > 0) {
      setInputDevice(inputMediaDevices[0]);
    } else {
      setInputDevice(null);
    }
    //

    // output media devices
    const outputMediaDevices = mediaDevices.reduce((accum, mediaDevice) => {
      if (mediaDevice.kind === 'audiooutput' && mediaDevice.deviceId) {
        accum.push({
          value: mediaDevice.deviceId,
          label: mediaDevice.label,
        });
      }

      return accum;
    }, []);

    setOutputList(outputMediaDevices);

    if (outputMediaDevices.length > 0) {
      setOutputDevice(outputMediaDevices[0]);
    } else {
      setOutputDevice(null);
    }
    //
  } else {
    setInputList(null);
    setInputDevice(null);
    setOutputList(null);
    setOutputDevice(null);
  }
};

const useDevice = () => {
  const { token } = useCallToken();

  const [device, setDevice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [inputList, setInputList] = useState(null);
  const [outputList, setOutputList] = useState(null);
  const [inputDevice, setInputDeviceState] = useState(null);
  const [outputDevice, setOutputDeviceState] = useState(null);

  const setupDevice = async () => {
    if (token) {
      const newDevice = new Device(token, {
        // logLevel: 1,
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions.
        codecPreferences: ['opus', 'pcmu'],
      });

      addDeviceListeners(newDevice)({
        onRegistered: () => { setIsLoading(false); },
        onError: (error) => { console.error(error); },
        onDeviceChange: () => {
          handleDeviceChange(setInputList, setOutputList)(setInputDeviceState, setOutputDeviceState);
        },
      });

      newDevice.register();

      setDevice(newDevice);
    }
  };

  const refreshDevicesList = useCallback(() => {
    getUserMedia();
  }, []);

  useEffect(() => {
    setupDevice();
  }, [
    token,
  ]);

  const setInputDevice = useCallback((value) => {
    if (typeof (value) === 'string') {
      setInputDeviceState(inputList.find((input) => input.value === value));
    } else {
      setInputDeviceState(value);
    }
  }, [
    inputList,
  ]);

  const setOutputDevice = useCallback((value) => {
    let deviceValue = value;

    if (typeof (value) === 'string') {
      setOutputDeviceState(outputList.find((output) => output.value === value));
    } else {
      deviceValue = value.value;
      setOutputDeviceState(deviceValue);
    }

    device.audio.speakerDevices.set(deviceValue);
    device.audio.speakerDevices.test();
  }, [
    device,
    outputList,
  ]);

  const stopUsingMic = useCallback(() => {
    stopAudioStream(device);
  }, [
    device,
  ]);

  const startUsingMic = useCallback(async () => {
    if (inputDevice?.value) {
      try {
        await device.audio.setInputDevice(inputDevice?.value);
      } catch (err) {
        console.error(err);
        device.audio.unsetInputDevice();
      }
    }
  }, [
    device,
    inputDevice,
  ]);

  return {
    isLoading,
    isMicrophoneAvailable: !!inputDevice?.value,

    device,

    inputList,
    outputList,
    inputDevice,
    outputDevice,

    setInputDevice,
    setOutputDevice,

    refreshDevicesList,
    stopUsingMic,
    startUsingMic,
  };
};

export default useDevice;
