import {
  SET_FULFILLMENTS_FOR_FILTER,
} from 'store/actionTypes/fulfillments';

const setFulfillmentsForFilter = (filterId) => (fulfillments) => ({
  type: SET_FULFILLMENTS_FOR_FILTER,
  payload: {
    filterId,
    fulfillments,
  },
});

export default setFulfillmentsForFilter;
