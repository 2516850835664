import requests from 'api/requests';

import setLineItems from './setLineItems';

const get = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.lineItems.get({
      offset,
      limit,
    });

    dispatch(setLineItems(response.data.lineItems));
  } catch (error) {
    console.error(error);
  }
};

export default get;
