import styled from 'styled-components';

const ContactAvatarWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export {
  ContactAvatarWrapper, // eslint-disable-line import/prefer-default-export
};
