export const SET_TRIGGERED_LICENSE_ITEM = 'SET_TRIGGERED_LICENSE_ITEM';
export const CLEAR_TRIGGERED_LICENSE_ITEM = 'CLEAR_TRIGGERED_LICENSE_ITEM';
export const SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING = 'SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING';
export const HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING = 'HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING';
export const SHOW_DELETING_SITE_OWNER_LICENSE_WARNING = 'SHOW_DELETING_SITE_OWNER_LICENSE_WARNING';
export const HIDE_DELETING_SITE_OWNER_LICENSE_WARNING = 'HIDE_DELETING_SITE_OWNER_LICENSE_WARNING';
export const HIDE_ONLY_ONE_SITE_OWNER_WARNING = 'HIDE_ONLY_ONE_SITE_OWNER_WARNING';
export const SHOW_ONLY_ONE_SITE_OWNER_WARNING = 'SHOW_ONLY_ONE_SITE_OWNER_WARNING';

export default {
  SET_TRIGGERED_LICENSE_ITEM,
  CLEAR_TRIGGERED_LICENSE_ITEM,
  SHOW_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
  HIDE_DELETE_YOUR_LICENSE_PERMISSION_WARNING,
  SHOW_DELETING_SITE_OWNER_LICENSE_WARNING,
  HIDE_DELETING_SITE_OWNER_LICENSE_WARNING,
  HIDE_ONLY_ONE_SITE_OWNER_WARNING,
  SHOW_ONLY_ONE_SITE_OWNER_WARNING,
};
