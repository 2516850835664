import { connect } from 'react-redux';
import selectors from 'store/selectors';

import { updateCustomizationSettings } from 'store/actions/appCustomization';

import { OBJECT_TYPES, PRIORITIZATION_ATTRIBUTES } from 'constants';

import Prioritization from './Prioritization';

const objectTypesLabels = {
  26: 'Leads',
  29: 'Clients',
  31: 'Offers',
  41: 'Transactions',
  42: 'Showings',
};

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  //
  const objectTypesPrioritization = settings.object_types_prioritization;

  const objectTypesItems = objectTypesPrioritization.map((id) => ({
    key: String(id),
    content: objectTypesLabels[id] || OBJECT_TYPES[id],
  }));

  //
  const objectAttributesPrioritization = settings.object_attributes_prioritization;

  const objectAttributesItems = objectAttributesPrioritization.map((id) => ({
    key: String(id),
    content: PRIORITIZATION_ATTRIBUTES[id],
  }));

  return {
    objectTypesItems,

    objectAttributesItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (field) => (items) => {
    const preparedItems = items.map((item) => Number(item.key));

    dispatch((updateCustomizationSettings({
      [field]: JSON.stringify(preparedItems),
    })));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Prioritization);
