import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import FirstContact from 'sections/Leads/modules/FirstContact';
import LastContact from 'sections/Leads/modules/LastContact';
import NextContact from 'sections/Leads/modules/NextContact';
import SourceSelect from 'sections/Leads/modules/SourceSelect';

import ContactDescription from 'sections/Contacts/modules/ContactDescription';
import ContactLikes from 'sections/Contacts/modules/ContactLikes';
import ContactDisikes from 'sections/Contacts/modules/ContactDislikes';

import ContactInfo from './ContactInfo';

const ProfileDetailsModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    contactId,
    isLead,
  } = props;

  return (
    <Modal
      theme={theme}
      width={500}
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContactInfo
            contactId={contactId}
          />
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <FirstContact contactId={contactId} />
          </Grid>
          {
            isLead && (
              <Grid item xs={12}>
                <SourceSelect contactId={contactId} inline />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <LastContact contactId={contactId} />
          </Grid>
          <Grid item xs={12}>
            <NextContact contactId={contactId} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="title2"
            tag="h4"
          >
            Notes
          </Typography>
          <ContactDescription
            contactId={contactId}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="title2"
            tag="h4"
          >
            Likes
          </Typography>
          <ContactLikes contactId={contactId} />
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="title2"
            tag="h4"
          >
            Dislikes
          </Typography>
          <ContactDisikes contactId={contactId} />
        </Grid>

      </Grid>
    </Modal>
  );
};

ProfileDetailsModal.defaultProps = {
  isVisible: false,
  isLead: false,
  theme: null,
};

const {
  bool,
  func,
  string,
  shape,
  number,
  oneOfType,
} = PropTypes;

ProfileDetailsModal.propTypes = {
  isVisible: bool,
  isLead: bool,
  theme: shape(),
  closeModal: func.isRequired,
  contactId: oneOfType([number, string]).isRequired,
};

export default ProfileDetailsModal;
