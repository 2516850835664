import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import validate from 'shared/utils/validators';

import { callAlert } from 'ui/Alert';
import Modal from 'ui/Modal';
import Button from 'ui/Button';

import MultivalueTextInput from 'ui/MultivalueTextInput';

const Share = (props) => {
  const {
    isVisible,
    closeModal,
    title,
    subtitle,
    emails: emailsProps,
    onSend,
  } = props;

  const [emails, setEmails] = useState([]);

  useEffect(() => {
    setEmails(emailsProps);
  }, [
    JSON.stringify(emailsProps),
  ]);

  const addEmail = useCallback((value) => {
    const isValidEmail = validate.email(value);
    if (!isValidEmail) {
      callAlert.error('Not a valid email address');
      return;
    }

    const newEmails = [...emails];
    newEmails.push(value);
    setEmails(newEmails);
  }, [
    JSON.stringify(emails),
  ]);

  const removeEmail = useCallback((index) => () => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  }, [
    JSON.stringify(emails),
  ]);

  const handleSend = useCallback(() => {
    onSend({ emails });
  }, [
    JSON.stringify(emails),
    onSend,
  ]);

  return (
    <Modal
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      title={title}
      subtitle={subtitle}
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          onClick={handleSend}
          variant="primary"
        >
          Send
        </Button>,
      ]}
    >
      <MultivalueTextInput
        items={emails}
        onAdd={addEmail}
        onDelete={removeEmail}
        label="Enter emails separated by comma"
        decorated
        decoratorProps={{
          iconName: 'mail',
        }}
      />
    </Modal>
  );
};

Share.defaultProps = {
  isVisible: false,
  subtitle: '',
  emails: [],
};

const {
  bool,
  string,
  func,
  arrayOf,
} = PropTypes;

Share.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
  title: string.isRequired,
  subtitle: string,
  emails: arrayOf(string),
  onSend: func.isRequired,
};

export default Share;
