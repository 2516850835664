import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const ExpirationTimer = (props) => {
  const {
    days,
    hours,
  } = props;

  const color = days < 1 && hours < 8 ? 'attention' : 'default';

  return (
    <Typography
      variant="title1"
      color={color}
      weight="regular"
      noMargin
    >
      {days}
      <Typography
        variant="body2"
        tag="span"
        color={color}
        noMargin
      >
        d
      </Typography>
      &nbsp;:&nbsp;
      {hours}
      <Typography
        variant="body2"
        tag="span"
        color={color}
        noMargin
      >
        hrs
      </Typography>
    </Typography>
  );
};

ExpirationTimer.defaultProps = {
  days: 0,
  hours: 0,
};

const { number } = PropTypes;

ExpirationTimer.propTypes = {
  days: number,
  hours: number,
};

export default ExpirationTimer;
