import styled from 'styled-components';

export const Root = styled.div`
  padding: 3px 20px 5px 3px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  .MuiAvatar-root {
    margin-bottom: auto;
    margin-top: auto;
  }
`;

export const Data = styled.div`
  margin-left: 15px;
`;

export default {
  Root,
  Row,
  Data,
};
