import { createSelector } from 'reselect';

import { forOwn } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

import {
  optimizedCrmProductsActions,
} from 'store/actions/crm/crmProducts';

export default createSelector(
  (state) => state.crmProducts.products,
  (state) => state.fulfillments.selectedFulfillment,
  (state) => state.fulfillments.lineItems,
  (_, productId) => productId,
  (_, __, parentRowId) => parentRowId,
  (_, __, ___, status) => status,
  (products, selectedFulfillmentId, fulfillmentLineItems, selectedProductId, parentRowId, status) => {
    const selectedProducts = fulfillmentLineItems[selectedFulfillmentId]?.[status]?.[selectedProductId];

    if (!selectedProducts) {
      optimizedFulfillmentsActions.getSelectedFulfillmentProductLineItems(selectedProductId, status);
      return [];
    }

    const items = [];

    const productObject = products[selectedProductId];

    if (!productObject && selectedProductId) {
      optimizedCrmProductsActions.getProductById(selectedProductId);
    }

    forOwn(selectedProducts, (item, key) => {
      items.push({
        ...item,
        rowId: `${parentRowId}.${+key + 1}`,
        title: productObject?.name,
        quantity: 1,
      });
    });

    return items;
  },
);
