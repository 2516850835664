import connectReducers from 'store/connectReducers';

import {
  SET_CRM_CONTACT,
  SET_CRM_CONTACTS,
  SET_CRM_CONTACTS_FOR_FILTER,
  SET_CRM_CONTACTS_FOR_FILTER_COUNT,
  SET_CRM_CONTACT_LABELS,
  SET_CRM_SELECTED_CONTACT,
  SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT,
  SET_CRM_CONTACT_CHANGELOG,
  SET_CRM_SELECTED_CONTACT_ROWS,
  SET_CRM_OVERALL_CONTACTS_SUMMARY,
} from 'store/actionTypes/crm';

const initialState = {
  contacts: {},
  selectedContact: null,
  selectedContactRows: [],
  contactsByFilter: {},
  contactsByFilterCount: {},

  overallContactsSummary: null,

  labels: null,
  linkedObjectsSummariesByContacts: {},
  changelog: {},
};

const contactsListToObject = (contacts = []) => contacts.reduce((contactObj, item) => {
  // param reassign is used in reduce by design
  contactObj[item.SalesContactsID] = item; // eslint-disable-line no-param-reassign
  return contactObj;
}, {});

const labelsListToObject = (labels = []) => labels.reduce((labelsObj, item) => {
  // param reassign is used in reduce by design
  labelsObj[item.id] = { // eslint-disable-line no-param-reassign
    ...item,
    color: `#${item.color}`,
  };
  return labelsObj;
}, {});

const getContactIds = (contacts = []) => contacts.map((item) => item.SalesContactsID);

const reducers = {
  [SET_CRM_CONTACT]: (state, action) => ({
    ...state,
    contacts: {
      ...state.contacts,
      [action.payload.SalesContactsID]: action.payload,
    },
  }),

  [SET_CRM_CONTACTS]: (state, action) => ({
    ...state,
    contacts: {
      ...state.contacts,
      ...contactsListToObject(action.payload),
    },
  }),

  [SET_CRM_SELECTED_CONTACT]: (state, action) => ({
    ...state,
    selectedContact: action.payload,
  }),

  [SET_CRM_CONTACTS_FOR_FILTER]: (state, action) => {
    const {
      filterId,
      contacts,
    } = action.payload;

    const filterProductIds = state.contactsByFilter[filterId] || [];

    const updatedFilterContactsIds = new Set([
      ...filterProductIds,
      ...getContactIds(contacts),
    ]);

    const updatedState = {
      ...state,
      contactsByFilter: {
        ...state.contactsByFilter,
        [filterId]: [...updatedFilterContactsIds],
      },
    };

    return updatedState;
  },

  [SET_CRM_CONTACTS_FOR_FILTER_COUNT]: (state, action) => ({
    ...state,
    contactsByFilterCount: {
      ...state.contactsByFilterCount,
      [action.payload.filterId]: action.payload.count,
    },
  }),

  [SET_CRM_CONTACT_LABELS]: (state, action) => ({
    ...state,
    labels: {
      ...state.labels,
      ...labelsListToObject(action.payload),
    },
  }),

  [SET_CRM_CONTACT_LINKED_OBJECTS_SUMMARIES_FOR_CONTACT]: (state, action) => ({
    ...state,
    linkedObjectsSummariesByContacts: {
      ...state.linkedObjectsSummariesByContacts,
      [action.payload.contactId]: action.payload.summaries,
    },
  }),

  [SET_CRM_CONTACT_CHANGELOG]: (state, action) => ({
    ...state,
    changelog: {
      ...state.changelog,
      [action.payload.contactId]: action.payload.changelog,
    },
  }),

  [SET_CRM_SELECTED_CONTACT_ROWS]: (state, action) => ({
    ...state,
    selectedContactRows: action.payload,
  }),

  [SET_CRM_OVERALL_CONTACTS_SUMMARY]: (state, action) => ({
    ...state,
    overallContactsSummary: action.payload,
  }),
};

function crmCompanies(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default crmCompanies;
