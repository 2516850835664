import React from 'react';
import {
  Alert,
} from 'antd';
import PropTypes from 'prop-types';
import './NewLeadSuccesfullyAlert.scss';

const NewLeadSuccesfullyAlert = (props) => {
  const {
    isShowAlert,
    Name,
    Surname,
    isAssignedSomeone,
  } = props;

  return (
    <>
      {
        isShowAlert && (
          <div
            className="new-lead-alert"
          >
            <Alert

              message={isAssignedSomeone ? (
                `This lead was created and assigned to ${Name} ${Surname}.`
              ) : (
                'This lead has been created and placed in the unassigned queue.'
              )}

              type="success"
              showIcon
              closable
            />
          </div>
        )
      }
    </>
  );
};

NewLeadSuccesfullyAlert.defaultProps = {
  Name: null,
  Surname: null,
  isAssignedSomeone: false,
};

const {
  string,
  bool,
} = PropTypes;

NewLeadSuccesfullyAlert.propTypes = {
  isShowAlert: bool.isRequired,
  Name: string,
  Surname: string,
  isAssignedSomeone: bool,
};

export default NewLeadSuccesfullyAlert;
