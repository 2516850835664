import {
  SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN,
} from 'store/actionTypes/propertyViewings';

const openModal = (viewingId, { buyer, buyerAgent, sellerAgent }) => ({
  type: SET_PROPERTY_VIEWING_EDIT_MODAL_OPEN,
  payload: {
    viewingId,
    buyerField: buyer,
    buyerAgentField: buyerAgent,
    sellerAgentField: sellerAgent,
  },
});

export default openModal;
