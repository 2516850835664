import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import {
  without,
  difference,
} from 'shared/utility';

import noOp from 'shared/utils/noOp';

import ErrorBoundary from 'ui/ErrorBoundary';
import Button from 'ui/Button';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Modal from 'ui/Modal';
import useModal from 'ui/hooks/useModal';

import context from 'sections/Contacts/modals/AssignContactModal/ContextProvider/context';
import ContextProvider from './ContextProvider';

import Search from './Search';
import StatusSelector from './StatusSelector';
import AddContact from './AddContact';
import You from './You';
import LabelsSelector from './LabelsSelector';
import AllSelector from './AllSelector';

import AllContacts from './AllContacts';
import HighlightedContacts from './HighlightedContacts';

import WarningModal from './WarningModal';

const higlightedTitles = {
  'buyer lead': 'Here are your buyer leads',
  'seller lead': 'Here are your seller leads',
  'buyer client': 'Here are your buyer clients',
  'seller client': 'Here are your seller clients',
};

const maxSelectedContactsQty = process.env.REACT_APP_MAX_SELECTED_CONTACTS_QTY || 500;

const AssignContactModal = (props) => {
  const {
    isVisible,
    closeModal,
    oneContactSelect,
    title,
    description,
    selectedContacts: selectedContactsProps,
    onSave,
    canSelectYourself,
    withAddContact,
    onNewContactCreation,
    highlightedContactStatus,
    extraField: {
      ExtraFieldComponent,
      onChange: onChangeExtra,
      value: extraValue,
    },
    selectedContactsQtyWarningDescription,
  } = props;

  const [selectedContacts, setSelectedContacts] = useState(selectedContactsProps);

  useEffect(() => {
    setSelectedContacts(selectedContactsProps);
  }, [
    JSON.stringify(selectedContactsProps),
  ]);

  const {
    isModalVisible: isWarningModalVisible,
    openModal: openWarningModal,
    closeModal: closeWarningModal,
  } = useModal();

  const addContact = useCallback((contactId) => () => {
    if (oneContactSelect) {
      setSelectedContacts([
        contactId,
      ]);
    } else {
      setSelectedContacts([
        ...selectedContacts,
        contactId,
      ]);
    }
  }, [
    selectedContacts,
    oneContactSelect,
  ]);

  const removeContact = useCallback((contactId) => () => {
    setSelectedContacts(
      without(selectedContacts, contactId),
    );
  }, [
    selectedContacts,
  ]);

  const onOk = useCallback(() => {
    const addedContacts = difference(selectedContacts, selectedContactsProps);
    const removedContacts = difference(selectedContactsProps, selectedContacts);

    const selectedContactsQty = addedContacts.length - removedContacts.length;

    if (selectedContactsQty > maxSelectedContactsQty) {
      openWarningModal();
      return;
    }

    onSave(
      {
        addedContacts,
        removedContacts,
      },
    );
  }, [
    JSON.stringify(selectedContactsProps),
    JSON.stringify(selectedContacts),
    onSave,
    openWarningModal,
  ]);

  const onClose = useCallback(() => {
    setSelectedContacts([]);
    closeModal();
  }, [
    closeModal,
  ]);

  const {
    highlightedContactsListFilters,
    highlightedContactsList,
  } = useContext(context);

  return (
    <Modal
      width={840}
      visible={isVisible}
      onCancel={onClose}
      title={title}
      footer={[
        <Button
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={onOk}
        >
          Done
        </Button>,
      ]}
      noCloseIcon={!title}
    >
      <Flex
        spacing={3}
        flexWrap="wrap"
      >
        <div>
          {
            description && (
              <Typography
                noMargin
              >
                {description}
              </Typography>
            )
          }
        </div>

        <Flex
          spacing={2}
          fullWidth
        >
          <Search />
          {
            !!highlightedContactStatus && (
              <StatusSelector />
            )
          }
          {
            withAddContact && <AddContact onSuccess={onNewContactCreation} />
          }
        </Flex>

        <Flex
          spacing={2}
          justifyContent="space-between"
          fullWidth
        >
          <Flex
            spacing={2}
            alignItems="center"
          >
            <Typography noMargin>Groups:</Typography>
            <LabelsSelector />
          </Flex>
          <AllSelector
            setSelectedContacts={setSelectedContacts}
          />
        </Flex>

        {
          canSelectYourself && (
            <FlexItem fullWidth>
              <Typography
                variant="title3"
                weight={500}
              >
                You
              </Typography>
              <You
                selectedContacts={selectedContacts}
                oneContactSelect={oneContactSelect}
                onAddContact={addContact}
                onRemoveContact={removeContact}
              />
            </FlexItem>
          )
        }

        {
          !!highlightedContactStatus && highlightedContactsList?.length > 0 && (
            <FlexItem fullWidth>
              <Typography
                variant="title3"
                weight={500}
              >
                {higlightedTitles[highlightedContactsListFilters?.contactStatus]}
              </Typography>
              <HighlightedContacts
                selectedContacts={selectedContacts}
                oneContactSelect={oneContactSelect}
                onAddContact={addContact}
                onRemoveContact={removeContact}
              />
            </FlexItem>
          )
        }

        <FlexItem fullWidth>
          <Typography
            variant="title3"
            weight={500}
          >
            All contacts
          </Typography>
          <AllContacts
            selectedContacts={selectedContacts}
            oneContactSelect={oneContactSelect}
            onAddContact={addContact}
            onRemoveContact={removeContact}
          />
        </FlexItem>

        <FlexItem fullWidth>
          {
            ExtraFieldComponent && (
              <ExtraFieldComponent
                onChange={onChangeExtra}
                value={extraValue}
              />
            )
          }
        </FlexItem>
      </Flex>

      <WarningModal
        isVisible={isWarningModalVisible}
        closeModal={closeWarningModal}
        description={selectedContactsQtyWarningDescription}
      />

    </Modal>
  );
};

AssignContactModal.defaultProps = {
  isVisible: false,
  oneContactSelect: false,
  selectedContacts: [],
  title: 'Assign contact',
  description: '',
  withAddContact: false,
  onNewContactCreation: noOp,
  canSelectYourself: false,
  highlightedContactStatus: null,
  extraField: {},
  selectedContactsQtyWarningDescription: '',
};

const {
  func,
  bool,
  string,
  number,
  arrayOf,
  oneOf,
  shape,
} = PropTypes;

AssignContactModal.propTypes = {
  closeModal: func.isRequired,
  onSave: func.isRequired,
  oneContactSelect: bool,
  title: string,
  description: string,
  isVisible: bool,
  selectedContacts: arrayOf(number),
  withAddContact: bool,
  onNewContactCreation: func,
  canSelectYourself: bool,
  highlightedContactStatus: oneOf([
    'buyer lead',
    'seller lead',
    'buyer client',
    'seller client',
  ]),
  extraField: shape({
    ExtraFieldComponent: func.isRequired,
    onChange: func.isRequired,
  }),
  selectedContactsQtyWarningDescription: string,
};

/* eslint-disable react/jsx-props-no-spreading */
export default (props) => (
  <ErrorBoundary>
    <ContextProvider {...props}>
      <AssignContactModal {...props} />
    </ContextProvider>
  </ErrorBoundary>
);
/* eslint-enable react/jsx-props-no-spreading */

// export default AssignContactModal
