import { connect } from 'react-redux';

import {
  closeNewLeadModal,
} from 'store/actions/newRealtorLeadModal';

import {
  createLeadFromNewLeadForm,
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import {
  setNewLeadNestedFormField,
  reset as resetNewLeadForm,
  setNewLeadForm,
} from 'store/actions/newRealtorLeadForm';

import { handleModalFormCache } from 'store/actions/modals';

import handleDrafts from 'shared/hooks/useModalsDrafts/handleDrafts';

import NewLeadFormModal from './NewLeadFormModal';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadModal: {
      isVisible,
    },
    realtorNewLeadForm: {
      mainEmail,
      personFirstName,
      selectedContact,
      fieldsValidators: {
        doesPhoneExist,
        doesEmailExist,
      },
    },
    realtorLeadsFilter: {
      pageNumber,
      pageSize,
    },
  } = state;

  return {
    isVisible,
    mainEmail,
    personFirstName,
    doesPhoneExist,
    doesEmailExist,
    selectedContact,
    pageNumber,
    pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: (shouldCache = true) => {
    dispatch(
      handleModalFormCache('realtorNewLeadModal', 'realtorNewLeadForm')(shouldCache ? 'set' : 'clear'),
    );

    dispatch(closeNewLeadModal());
    dispatch(resetNewLeadForm());
  },
  createLead: async () => {
    const id = await dispatch(createLeadFromNewLeadForm());

    if (id) {
      dispatch(resetNewLeadForm());
    }

    return id;
  },
  setNotValidField: (fieldName) => dispatch(setNewLeadNestedFormField('fieldsValidators')(fieldName)(false)),
  getLeads: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    optimizedRealtorLeadsActions.getLeadsForStoreFilter(offset, limit);
  },
  getCachedData: () => {
    const cachedData = handleDrafts.get('realtorNewLeadModal');
    if (cachedData) {
      dispatch(setNewLeadForm(cachedData));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLeadFormModal);
