import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

export default createSelector(
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.selectedFulfillment,
  (
    fulfillments,
    fulfillmentId,
  ) => {
    const defaultProcesses = {
      main: {
        tangibleProcessId: null,
        digitalProcessId: null,
        serviceProcessId: null,
      },
      cancellations: [],
      returns: [],
    };

    const fulfillment = fulfillments[fulfillmentId];

    if (!fulfillment) {
      if (fulfillmentId) {
        optimizedFulfillmentsActions.getFulfillmentById(fulfillmentId);
      }

      return defaultProcesses;
    }

    if (!fulfillment.processes) {
      return defaultProcesses;
    }

    const processes = fulfillment.processes.reduce((acc, item) => {
      if (item.type === 1 && item.product_type === 1 && !acc.main.tangibleProcessId) {
        acc.main.tangibleProcessId = item.id;
        return acc;
      }

      if (item.type === 1 && item.product_type === 2 && !acc.main.digitalProcessId) {
        acc.main.digitalProcessId = item.id;
        return acc;
      }

      if (item.type === 1 && item.product_type === 3 && !acc.main.serviceProcessId) {
        acc.main.serviceProcessId = item.id;
        return acc;
      }

      if (item.type === 3) {
        acc.cancellations.push(item.id);
        return acc;
      }

      if (item.type === 4) {
        acc.returns.push(item.id);
        return acc;
      }

      return acc;
    }, defaultProcesses);

    return processes;
  },
);
