import store, { history } from 'store';

import {
  optimizedRealtorContactsActions,

  updateContact,
} from 'store/actions/realtorContacts';

const remove = async (contactId) => {
  await store.dispatch(updateContact(contactId)({
    status: 2,
  }));
  optimizedRealtorContactsActions.getContactsForStoreFilter();
  history.push('/contacts');
};

export default remove;
