import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import DateInputButton from 'shared/inputs/DateInputButton';

const DateSelector = (props) => {
  const {
    selectedDate,
    onChange,
  } = props;

  const onNextDay = useCallback(() => {
    const newDate = moment(selectedDate).add(1, 'days').format();
    onChange(newDate);
  }, [
    selectedDate,
    onChange,
  ]);

  const onPrevDay = useCallback(() => {
    const newDate = moment(selectedDate).subtract(1, 'days').format();
    onChange(newDate);
  }, [
    selectedDate,
    onChange,
  ]);

  return (
    <Flex
      spacing={1}
    >
      <Button
        onClick={onPrevDay}
        icon="arrow-left"
        variant="secondary"
      />

      <DateInputButton
        onChange={onChange}
        value={selectedDate}
        displayFormat="MMMM D"
      />

      <Button
        onClick={onNextDay}
        icon="arrow-right"
        variant="secondary"
      />
    </Flex>
  );
};

const {
  func,
  string,
} = PropTypes;

DateSelector.propTypes = {
  selectedDate: string.isRequired,
  onChange: func.isRequired,
};

export default DateSelector;
