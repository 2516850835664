/* eslint-disable  react/jsx-props-no-spreading, react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'ui/Modal';

const withModal = (WrappedComponent) => (props) => {
  const containerRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [rect, setRect] = useState({});

  useEffect(() => {
    const elem = containerRef.current.parentElement;
    setRect(elem.getBoundingClientRect());
  }, []);

  const toggleModalVisibility = () => {
    setIsVisible(!isVisible);
  };

  const modalProps = props.modalProps || {};

  return (
    <>
      <Modal
        visible={isVisible}
        style={{
          position: 'absolute',
          top: rect.top,
          left: rect.left,
        }}
        width={rect.width}
        onCancel={toggleModalVisibility}
        footer={null}
        {...modalProps}
      >
        <WrappedComponent {...props} toggleModalVisibility={toggleModalVisibility} isExpanded />
      </Modal>
      <div
        ref={containerRef}
        style={isVisible ? { visibility: 'hidden' } : { visibility: 'visible' }}
      >
        <WrappedComponent {...props} toggleModalVisibility={toggleModalVisibility} />
      </div>
    </>
  );
};

export default withModal;
