/* eslint-disable react/require-default-props, jsx-a11y/media-has-caption */

import React, { forwardRef, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Video = (props, ref) => {
  const {
    className,
    srcObject,
    width,
    height,
    autoPlay,
    playsInline,
    poster,
    muted,
  } = props;

  const videoElementRef = useRef(null);
  const refToUse = ref || videoElementRef;

  useEffect(() => {
    const node = refToUse.current;

    if (!node) return;

    node.srcObject = srcObject;

    node.play();
  }, [refToUse, srcObject]);

  return (
    <video
      ref={refToUse}
      className={className}
      poster={poster}
      width={width}
      height={height}
      autoPlay={autoPlay}
      playsInline={playsInline}
      muted={muted}
    >
      Your browser does not support the video tag.
    </video>
  );
};

Video.propTypes = {
  className: PropTypes.string,
  poster: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  autoPlay: PropTypes.bool,
  playsInline: PropTypes.bool,
  muted: PropTypes.bool,
  srcObject: PropTypes.instanceOf(MediaStream),
};

export default forwardRef(Video);
