/**
 *
 * @param {string} dataUrl string in Data URL format
 * @param {string} fileName name of the output file
 * @param {string} fileType MIME type
 * @returns {File} converted file
 */

const dataUrlToFile = async (dataUrl, fileName, fileType) => {
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return new File([blob], fileName, { type: fileType });
};

export default dataUrlToFile;
