import apiConnector, { endpoints } from 'api';

const update = (items) => async (updateData) => {
  const endpoint = `${endpoints.client.creditNotes}/${items}`;

  try {
    const result = await apiConnector.client.patch(endpoint, updateData);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default update;
