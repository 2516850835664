import connectReducers from 'store/connectReducers';

import {
  SET_LOGS_FOR_OBJECT,
} from 'store/actionTypes/logs';

const initialState = {
  parentObjects: {
    ticket: {},
    task: {},
    lead: {},
    fulfillment: {},
    'realtor lead': {},
    'realtor property': {},
    'realtor property requirement': {},
    'realtor contact': {},
    'realtor prospect': {},
    'realtor client': {},
  },
};

const reducers = {
  [SET_LOGS_FOR_OBJECT]: (state, action) => {
    const {
      parentObjectType,
      parentObjectId,
      logList,
    } = action.payload;

    const objectTypeLogs = { ...state.parentObjects[parentObjectType] };

    objectTypeLogs[parentObjectId] = logList;

    return {
      ...state,
      parentObjects: {
        ...state.parentObjects,
        [parentObjectType]: objectTypeLogs,
      },
    };
  },
};

function logs(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default logs;
