import React from 'react';

import { connect } from 'react-redux';

import OffersTable from '../Tables/OffersTable';

import OfferTabs from './OfferTabs';

const mapStateToProps = (state, ownProps) => {
  const {
    needsResponse,
    awaitingReply,
    declined,
    accepted,
  } = ownProps;

const tabsDefault = [
  {
    name: 'needs response',
    content: () => (
      <OffersTable
        dataSource={needsResponse}
        expiryIndex="expiry_date"
        tableName="needs response"
      />
    ),
  },
  {
    name: 'awaiting reply',
    content: () => (
      <OffersTable
        dataSource={awaitingReply}
        expiryIndex="expiry_date"
        tableName="awaiting reply"
      />
    ),
  },
  {
    name: 'declined',
    content: () => (
      <OffersTable
        dataSource={declined}
        tableName="declined"
        expiryIndex="declined_at"
      />
    ),
  },
];

const tabsAccepted = [
  {
    name: 'accepted',
    content: () => (
      <OffersTable
        dataSource={accepted || null}
        tableName="accepted"
        expiryIndex="accepted_at"
      />
    ),
  },
  {
    name: 'declined',
    content: () => (
      <OffersTable
        dataSource={declined}
        tableName="declined"
        expiryIndex="declined_at"
      />
    ),
  },
];

  return {
    tabs: accepted ? tabsAccepted : tabsDefault,
  };
};

export default connect(mapStateToProps)(OfferTabs);
