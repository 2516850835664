import React, {
  useCallback,
} from 'react';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

import useImportSteps from 'sections/DataImport/hooks/useImportSteps';
import useObjectTypes from 'sections/DataImport/hooks/useObjectTypes';
import useRawData from 'sections/DataImport/hooks/useRawData';
import useFieldMatch from 'sections/DataImport/hooks/useFieldMatch';
import useUpload from 'sections/DataImport/hooks/useUpload';
import useImport from 'sections/DataImport/hooks/useImport';
import useKPIs from 'sections/DataImport/hooks/useKPIs';
import useUploadedFiles from 'sections/DataImport/hooks/useUploadedFiles';
import useFileConflicts from 'sections/DataImport/hooks/useFileConflicts';

const DataImportProvider = (props) => {
  const {
    children,
  } = props;

  const {
    currentStep,
    goToNext,
    goToPrev,
    setStep: setWizardStep,
    steps: importSteps,
    reset: resetUseImportSteps,
  } = useImportSteps();

  const {
    selectedTypes,
    setSelectedTypes,
    objectTypesOptions,
  } = useObjectTypes();

  const {
    fieldMap,
    matchRawToSymplete,
    fieldMatchOptionsByType,
    fieldLabelDict,
    reset: resetUseFieldMatch,
  } = useFieldMatch();

  const {
    getFileConflicts,
    conflicts,
    isLoading: conflictsIsLoading,
    error: conlictsLoadingError,
    reset: resetUseFileConflicts,
  } = useFileConflicts();

  const {
    rawHeaders,
    // setRawHeaders,
    // setRawData,
    columns: previewColumns,
    rawData,
    dataSource: previewDataSource,
    delimiter,
    setDelimiter,
    setTableData: setPreviewTableData,
    dataSample: previewDataSample,
    reset: resetUseRawData,
    isLoading: isPreviewDataLoading,
  } = useRawData({
    fieldMap,
    fieldLabelDict,
    conflicts,
  });

  const {
    fileId: uploadedFileId,
    fileName: uploadedFileName,
    isLoading: isFileBeingUploaded,
    uploadFile,
    setUploadedFileId,
    setUploadedFileName,
    reset: resetUseUpload,
  } = useUpload();

  const {
    isLoading: isImportInProgress,
    importData,
    selectedRows,
    setSelectedRows,
    reset: resetUseImport,
  } = useImport({
    fieldMap,
    uploadedFileId,
    selectedTypes,
    previewDataSource,
  });

  const KPIs = useKPIs({
    fieldMap,
    rawData,
    selectedRows: Object.values(selectedRows),
  });

  const {
    files: uploadedFiles,
    isLoading: isUploadedListLoading,
    error: uploadedListLoadingError,
    getUploadedFile,
    currentFile,
    setCurrentFile,
    updateList: listUploadedFiles,
  } = useUploadedFiles();

  const resetWizard = () => {
    resetUseImportSteps();
    resetUseFieldMatch();
    resetUseFileConflicts();
    resetUseRawData();
    resetUseUpload();
    resetUseImport();
    KPIs.reset();
    setCurrentFile(null);
  };

  return (
    <DataImportContext.Provider
      value={{
        currentStep,
        goToNext,
        goToPrev,
        setWizardStep,
        importSteps,

        selectedTypes,
        setSelectedTypes,
        objectTypesOptions,

        rawHeaders,
        previewColumns,
        rawData,
        // setRawHeaders,
        // setRawData,
        previewDataSource,
        delimiter,
        setDelimiter,
        setPreviewTableData,
        previewDataSample,
        selectedRows,
        setSelectedRows,
        isPreviewDataLoading,

        fieldMap,
        fieldMatchOptionsByType,
        matchRawToSymplete,

        uploadedFileId,
        uploadedFileName,
        isFileBeingUploaded,
        uploadFile,
        setUploadedFileId,
        setUploadedFileName,

        isImportInProgress,
        importData,

        KPIs,

        uploadedFiles,
        isUploadedListLoading,
        uploadedListLoadingError,
        getUploadedFile,
        listUploadedFiles,
        currentFile,
        setCurrentFile,

        getFileConflicts,
        conflicts,
        conflictsIsLoading,
        conlictsLoadingError,

        resetWizard,
      }}
    >
      { children }
    </DataImportContext.Provider>
  );
};

export default DataImportProvider;
