import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  StyledElevation,
} from './styledItems';

const OrderItem = (props) => {
  const {
    children,
  } = props;

  return (
    <StyledElevation>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        fullWidth
      >
        <Typography noMargin>
          {children}
        </Typography>

        <Icon
          name="menu"
          color="disabled"
        />
      </Flex>
    </StyledElevation>
  );
};

const { string } = PropTypes;

OrderItem.propTypes = {
  children: string.isRequired,
};

export default OrderItem;
