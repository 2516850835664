import connectReducers from 'store/connectReducers';

import {
  SET_REALTOR_SPECIAL_CONDITION,
} from 'store/actionTypes/realtorSpecialConditions';

const initialState = {
  items: {},
};

const reducers = {
  [SET_REALTOR_SPECIAL_CONDITION]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.id]: action.payload,
    },
  }),
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
