import { connect } from 'react-redux';
import selectors from 'store/selectors';

import { updateCustomizationSettings } from 'store/actions/appCustomization';

import AttorneyEscrowState from './AttorneyEscrowState';

const mapStateToProps = (state) => {
  const settings = selectors.appCustomization.settings(state);

  return {
    value: settings.attorney_state_or_escrow_state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => {
    dispatch((updateCustomizationSettings({
      attorney_state_or_escrow_state: value,
    })));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AttorneyEscrowState);
