import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import KPIBar from 'sections/Offers/modules/KPIBar';
import OffersItem from 'sections/Offers/components/OffersItem';

import offersActionImg from 'assets/images/realtor/offers-action.svg';
import offersAwaitingResponseImg from 'assets/images/realtor/offers-awaiting-response.svg';

import {
  Root,
  Column,
  EmptyItemsContainer,
  EmptyImage,
} from './styledItems';

const OffersList = (props) => {
  const {
    getItems,
    actionColumnItems,
    awaitingResponseColumnItems,
  } = props;

  useEffect(() => {
    getItems();
  }, []);

  return (
    <Root>
      <Flex spacing={2} flexWrap="wrap">
        <FlexItem fullWidth>
          <KPIBar />
        </FlexItem>
        <Flex
          justifyContent="space-between"
          fullWidth
          spacing={4}
        >
          <Column>
            <Typography
              variant="h2"
              weight="bold"
              align="center"
            >
              Action
            </Typography>

            {
              isEmpty(actionColumnItems) && (
                <EmptyItemsContainer>
                  <EmptyImage
                    src={offersActionImg}
                    aria-hidden="true"
                    alt=""
                  />
                  <Typography
                    variant="title2"
                    weight="normal"
                    align="center"
                    noMargin
                  >
                    Offers that need your immediate attention will
                    <br />
                    be shown in this column.
                  </Typography>
                </EmptyItemsContainer>
              )
            }

            {
              actionColumnItems.map((item) => (
                <Fragment key={item.key}>
                  <OffersItem
                    {...item} // eslint-disable-line react/jsx-props-no-spreading
                    column="action"
                  />
                </Fragment>
              ))
            }
          </Column>

          <Column>
            <Typography
              variant="h2"
              weight="bold"
              align="center"
            >
              Awaiting Response
            </Typography>

            {
              isEmpty(awaitingResponseColumnItems) && (
                <EmptyItemsContainer>
                  <EmptyImage
                    src={offersAwaitingResponseImg}
                    aria-hidden="true"
                    alt=""
                  />
                  <Typography
                    variant="title2"
                    weight="normal"
                    align="center"
                    noMargin
                  >
                    Offers that are awaiting response from
                    <br />
                    another party will be shown in this column.
                  </Typography>
                </EmptyItemsContainer>
              )
            }

            {
              awaitingResponseColumnItems.map((item) => (
                <Fragment key={item.key}>
                  <OffersItem
                    {...item} // eslint-disable-line react/jsx-props-no-spreading
                    column="awaitingResponse"
                  />
                </Fragment>
              ))
            }
          </Column>
        </Flex>
      </Flex>
    </Root>
  );
};

OffersList.defaultProps = {
  actionColumnItems: [],
  awaitingResponseColumnItems: [],
};

const {
  func,
  arrayOf,
  shape,
} = PropTypes;

OffersList.propTypes = {
  getItems: func.isRequired,
  actionColumnItems: arrayOf(shape({})),
  awaitingResponseColumnItems: arrayOf(shape({})),
};

export default OffersList;
