import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

const OfferDetailsHeader = (props) => {
  const {
    relatedContacts,
  } = props;

  return (
    <Flex
      spacing={4}
      justifyContent="space-between"
    >
      {
        relatedContacts.map((item) => item)
      }
    </Flex>
  );
};

OfferDetailsHeader.defaultProps = {
  date: null,
  relatedContacts: [],
};

const {
  arrayOf,
  element,
  string,
} = PropTypes;

OfferDetailsHeader.propTypes = {
  relatedContacts: arrayOf(element).isRequired,
  date: string,
};

export default OfferDetailsHeader;
