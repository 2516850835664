import React from 'react';
import PropTypes from 'prop-types';

import Tag from 'ui/Tag';
import Typography from 'ui/Typography';
import { isEmpty } from 'shared/utility';

import useContact from 'sections/Contacts/hooks/useContact';

/* eslint-disable camelcase */
const getContactStatus = (statusObj) => {
  if (isEmpty(statusObj)) {
    return 'none (contact)';
  }

  const {
    is_seller_client,
    is_buyer_client,
    is_seller_lead,
    is_buyer_lead,
  } = statusObj;

  // client
  if (is_seller_client && is_buyer_client) {
    return 'buyer & seller client';
  }
  if (is_seller_client) {
    return 'client seller';
  }
  if (is_buyer_client) {
    return 'client buyer';
  }

  // lead
  if (is_seller_lead && is_buyer_lead) {
    return 'buyer & seller lead';
  }
  if (is_seller_lead) {
    return 'lead seller';
  }
  if (is_buyer_lead) {
    return 'lead buyer';
  }

  // none
  return 'none (contact)';
};
/* eslint-enable camelcase */

const ContactStatus = (props) => {
  const {
    contactId,
  } = props;

  const contact = useContact(contactId);

  return (
    <Tag variant="client buyer">
      <Typography
        variant="tag"
        color="main-text-inversed"
        weight="regular"
        noWrap
        style={{ textTransform: 'capitalize' }}
      >
        {getContactStatus(contact?.contact_status)}
      </Typography>
    </Tag>
  );
};

const { number } = PropTypes;

ContactStatus.propTypes = {
  contactId: number.isRequired,
};

export default ContactStatus;
