import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import SourceDescriptionForm from '../SourceDescriptionForm';

const SourceDescriptionModal = (props) => {
  const {
    isVisible,
    onSave,
    onCancel,
    handleChangeSourceDescription,
    sourceDescription,
    sourceName,
  } = props;

  return (
    <Modal
      width={400}
      visible={isVisible}
      okText="Save"
      onCancel={onCancel}
      footer={[
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={onSave}
        >
          Save
        </Button>,
      ]}
    >
      <SourceDescriptionForm
        handleChangeSourceDescription={handleChangeSourceDescription}
        sourceDescription={sourceDescription}
        sourceName={sourceName}
      />
    </Modal>
  );
};

SourceDescriptionModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  string,
} = PropTypes;

SourceDescriptionModal.propTypes = {
  isVisible: bool,
  onCancel: func.isRequired,
  onSave: func.isRequired,
  handleChangeSourceDescription: func.isRequired,
  sourceDescription: string.isRequired,
  sourceName: string.isRequired,
};

export default SourceDescriptionModal;
