import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useActivePhones = () => {
  const [activePhones, setActivePhones] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPhone, setSelectedPhone] = useState(null);

  const getActivePhones = async () => {
    const result = await requests.calls.activePhones.get();

    setIsLoading(false);
    setActivePhones(result?.data
      ? result.data.map((item) => ({
        value: item?.phone_number,
        label: item?.phone_number,
      }))
      : null);

    if (result?.data) {
      setSelectedPhone(result?.data?.[0]?.phone_number);
    }
  };

  useEffect(() => {
    getActivePhones();
  }, []);

  return {
    activePhones,
    isLoading,
    selectedPhone,
    setSelectedPhone,
    getActivePhones,
  };
};

export default useActivePhones;
