import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import AccountOwnerInfoExternal from 'shared/modules/AccountOwnerInfoExternal';

const Presenter = (props) => {
  const {
  } = props;

  return (
    <>
      <Typography variant="title1" weight="bold">
        Presented By
      </Typography>

      <AccountOwnerInfoExternal />
    </>
  );
};

Presenter.defaultProps = {
};

const {
  number,
  string,
  func,
} = PropTypes;

Presenter.propTypes = {
};

export default Presenter;
