import { connect } from 'react-redux';
import { showDeleteImageWarningModal } from 'store/actions/attachments';
// eslint-disable-next-line import/no-cycle
import AvatarLogoCrop from './AvatarLogoCrop';

const mapDispatchToProps = {
  showDeleteImageWarningModal,
};

export default connect(null, mapDispatchToProps)(AvatarLogoCrop);
