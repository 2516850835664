import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} data
 */
const create = async (data) => {
  const endpoint = endpoints.client.realtor.matchingProperties;
  try {
    const result = await apiConnector.client.post(endpoint, data);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
