import requests from 'api/requests';

import setProspects from './setProspects';
import setProspectsForFilter from './setProspectsForFilter';
import setProspectsForFilterCount from './setProspectsForFilterCount';

const getProspectsForFilter = (filterId) => (filter) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.realtor.prospects.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setProspects(response.data.items));
    dispatch(setProspectsForFilter(filterId)(response.data.items));
    dispatch(setProspectsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getProspectsForFilter;
