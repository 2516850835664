import { omit } from 'shared/utility';

const removeListItem = (state, action) => {
  const list = { ...state[action.payload.key] };

  const updatedState = {
    ...state,
    [action.payload.key]: omit(list, [action.payload.id]),
  };

  return updatedState;
};
export default removeListItem;
