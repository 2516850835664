import styled from 'styled-components';

import Elevation from 'ui/Elevation';

export const StyledCardElevation = styled(Elevation)`
  padding: 8px 0 8px 8px;
  border-radius: 10px;
  cursor: pointer;
`;

export const Root = styled.div`
  .offers-title-text {
    margin-top: 5px;
  }

  .offers-description-text {
    margin-left: 10px;
  }

  .card-text {
    margin-top: 4px;
  }

  .description-text {
    margin: auto;
  }

  .card-image-wrapper {
    margin: auto 0 auto 0;
  }
`;

export default {
  Root,
  StyledCardElevation,
};
