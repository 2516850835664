import React from 'react';
import PropTypes from 'prop-types';

import Table from 'ui/Table';

import columns from './columns';

const ExpandedTable = (props) => {
  const {
    dataSource,
  } = props;

  return (
    <Table
      key="expanded-offers-table"
      showHeader={false}
      dataSource={dataSource || []}
      columns={columns()}
      pagination={false}
    />
  );
};

ExpandedTable.defaultProps = {
  dataSource: null,
};

const {
  shape,
} = PropTypes;

ExpandedTable.propTypes = {
  dataSource: shape({}),
};

export default ExpandedTable;
