/* eslint-disable max-len, consistent-return */

import apiConnector from 'api';
import requests from 'api/requests';

import {
  GET_COMMENT_TYPES_SUCCESS,
  GET_COMMENT_TYPES_ERROR,
  SET_COMMENTS_FOR_OBJECT,
  // SET_CREATOR,
} from 'store/actionTypes/comments';

import { getTaskAndRefreshRelatedSummaries } from 'store/actions/tasksV2'; // eslint-disable-line import/no-cycle
import getTicketAndRefreshRelatedSummaries from 'store/actions/ticketsV2/getTicketAndRefreshRelatedSummaries'; // eslint-disable-line import/no-cycle

export const getCommentTypes = (isSympleteUser = true) => async (dispatch) => {
  const connector = apiConnector.client.get;

  const endpoint = '/Tickets/ReturnAllCommentTypes';

  try {
    const result = await connector(endpoint);
    dispatch(getCommentTypesSuccess(result));
  } catch (error) {
    dispatch(getCommentTypesError());
  }
};

export const getCommentTypesSuccess = (commentTypes) => ({
  type: GET_COMMENT_TYPES_SUCCESS,
  commentTypes,
});

export const getCommentTypesError = () => ({
  type: GET_COMMENT_TYPES_ERROR,
});

export const getComments = (commentedObjectType) => (commentedObjectId) => async (dispatch) => {
  const res = await requests.comments.getForObject(commentedObjectType)(commentedObjectId);
  dispatch(setCommentsForObject(commentedObjectType)(commentedObjectId)(res.data));
};

export const postComment = (commentedObjectType) => (commentedObjectId) => (commentBody) => async (dispatch) => {
  try {
    const commentResponse = await requests.comments.post(commentedObjectType)(commentedObjectId)(commentBody);
    dispatch(getComments(commentedObjectType)(commentedObjectId));

    if (commentedObjectType === 'task') {
      dispatch(getTaskAndRefreshRelatedSummaries(commentedObjectId));
    }

    if (commentedObjectType === 'ticket') {
      dispatch(getTicketAndRefreshRelatedSummaries(commentedObjectId));
    }

    return commentResponse.data;
  } catch (error) {
    console.error(error);
  }
};

export const editComment = (commentedObjectType) => (commentedObjectId) => (commentId) => (commentText) => async (dispatch) => {
  const comment = {
    Comment: commentText,
  };

  try {
    const commentResponse = await requests.comments.edit(commentId)(comment);
    dispatch(getComments(commentedObjectType)(commentedObjectId));
    return commentResponse.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteComment = (commentedObjectType) => (commentedObjectId) => (commentId) => async (dispatch) => {
  try {
    await requests.comments.delete(commentId);
    dispatch(getComments(commentedObjectType)(commentedObjectId));
  } catch (error) {
    console.error(error);
  }
};

export const acknowledgeComment = (commentedObjectType) => (commentedObjectId) => (commentId) => async (dispatch) => {
  try {
    await requests.comments.acknowledge(commentId);
    dispatch(getComments(commentedObjectType)(commentedObjectId));
  } catch (error) {
    console.error(error);
  }
};

export const resolveComment = (commentedObjectType) => (commentedObjectId) => (commentId) => async (dispatch) => {
  try {
    await requests.comments.resolve(commentId);
    dispatch(getComments(commentedObjectType)(commentedObjectId));

    if (commentedObjectType === 'task') {
      dispatch(getTaskAndRefreshRelatedSummaries(commentedObjectId));
    }

    if (commentedObjectType === 'ticket') {
      dispatch(getTicketAndRefreshRelatedSummaries(commentedObjectId));
    }
  } catch (error) {
    console.error(error);
  }
};

// export const markCommentAsSeen = (commentedObjectType) => (commentedObjectId) => (commentId) => (isSympleteUser = true) => async (dispatch) => {
//   const markAsSeenMethod = isSympleteUser
//     ? acknowledgeCommentAsUser
//     : acknowledgeCommentAsCustomer

//   await markAsSeenMethod(commentId);
// }

export const commentMethodsWithObjectUpdate = (commentedObjectType) => (commentedObjectId) => ({
  get: getComments(commentedObjectType)(commentedObjectId),
  post: postComment(commentedObjectType)(commentedObjectId),
  edit: editComment(commentedObjectType)(commentedObjectId),
  delete: deleteComment(commentedObjectType)(commentedObjectId),
  acknowledge: acknowledgeComment(commentedObjectType)(commentedObjectId),
  resolve: resolveComment(commentedObjectType)(commentedObjectId),
});

export const setCommentsForObject = (commentedObjectType) => (commentedObjectId) => (commentsList) => ({
  type: SET_COMMENTS_FOR_OBJECT,
  payload: {
    commentedObjectType,
    commentedObjectId,
    commentsList,
  },
});

export default commentMethodsWithObjectUpdate;
