import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

const defaultFulfillment = {
  id: null,
  title: null,
  note: null,
  invoices: [],
  payments: [],
  agreements: [],
};

export default createSelector(
  (state) => state.fulfillments.fulfillments,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedFulfillmentsActions.getFulfillmentById(id);
      }

      return defaultFulfillment;
    }

    return item;
  },
);
