import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import routes from 'sections/Calendar/routes';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';

import ScheduleTable from 'sections/Today/components/TodayTab/ScheduleTable';

import FollowUpData from 'sections/FollowUps/components/FollowUpData';
import ViewingData from 'sections/Calendar/modules/ViewingDayViewItem';
import TransactionData from 'sections/Calendar/modules/TransactionDayViewItem';

import emptyPlaceholder from 'assets/images/realtor/magnifier.svg';

import {
  Root,
  EmptyItemsContainer,
  EmptyImage,
} from './styledItems';

const transactionItemTypes = [
  'close_escrow_expires_at',
  'initial_documents_expires_at',
  'seller_disclosure_expires_at',
  'inspections_expires_at',
  'appraisal_expires_at',
  'loan_expires_at',
  'final_walkthrough_expires_at',
  'move_out_date_expires_at',
];

const DayView = (props) => {
  const {
    year,
    month,
    day,

    getShowings,
    getPitches,
    getClosingEscrow,
    getContingenciesDue,
    getFollowUpReminders,
  } = props;

  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(moment().format());

  const [selectedObject, setSelectedObject] = useState({});

  useEffect(() => {
    if (year && month && day) {
      const newDate = moment({
        year,
        month: month - 1,
        day,
      }).format();

      setSelectedDate(newDate);
    }
  }, [
    year,
    month,
    day,
  ]);

  const goBack = useCallback(() => {
    history.push(`${routes.sectionRoot}/${year}/${month}`);
  }, [
    year,
    month,
  ]);

  const onScheduleItemClick = useCallback(({ objectType, objectId }) => {
    setSelectedObject({ objectType, objectId });
  }, []);

  const onAfterItemDelete = useCallback((objectType) => {
    setSelectedObject({});

    const objectTypesGetFunc = {
      listingPitches: getPitches,
      propertyShowings: getShowings,
      followUpReminders: getFollowUpReminders,
    };

    if (objectTypesGetFunc[objectType]) {
      objectTypesGetFunc[objectType](selectedDate);
    }
  }, [
    selectedDate,
    getShowings,
    getPitches,
    getClosingEscrow,
    getContingenciesDue,
    getFollowUpReminders,
  ]);

  return (
    <>
      <Root>
        <Flex
          fullWidth
          spacing={4}
          alignItems="stretch"
          style={{ height: 'inherit' }}
        >
          <FlexItem
            xs={12}
            style={{ height: 'auto' }}
          >
            <Flex
              fullWidth
              justifyContent="space-between"
              style={{ paddingBottom: 25 }}
            >
              <Button
                variant="tertiary"
                color="primary"
                icon="arrow-left"
                onClick={goBack}
              >
                <Typography variant="title1" noMargin>
                  {moment(selectedDate).format('MMMM DD')}
                </Typography>
              </Button>
            </Flex>

            <ScheduleTable
              selectedDate={selectedDate}
              onItemClick={onScheduleItemClick}
              style={{ height: '100%' }}
            />
          </FlexItem>

          <FlexItem
            xs={12}
            alignSelf={selectedObject.objectType ? 'flex-start' : 'center'}
            style={{ height: 'inherit', display: 'flex' }}
          >
            {
              !selectedObject.objectType && (
                <EmptyItemsContainer>
                  <EmptyImage
                    src={emptyPlaceholder}
                    aria-hidden="true"
                    alt=""
                  />
                  <Typography
                    variant="title2"
                    weight="normal"
                    align="center"
                    noMargin
                  >
                    Select an event to see the details
                  </Typography>
                </EmptyItemsContainer>
              )
            }
            {
              selectedObject.objectType === 'followUpReminders' && (
                <FollowUpData
                  reminderId={selectedObject.objectId}
                  showDeleteButton
                  withMainTitle
                  onAfterDelete={onAfterItemDelete}
                />
              )
            }
            {
              (selectedObject.objectType === 'propertyShowings'
              || selectedObject.objectType === 'listingPitches') && (
                <ViewingData
                  viewingId={selectedObject.objectId}
                  objectType={selectedObject.objectType}
                  onAfterDelete={onAfterItemDelete}
                  selectedDate={selectedDate}
                />
              )
            }
            {
              transactionItemTypes.includes(selectedObject.objectType) && (
                <TransactionData
                  transactionId={selectedObject.objectId}
                  transactionField={selectedObject.objectType}
                  showDeleteButton={false}
                />
              )
            }
          </FlexItem>
        </Flex>
      </Root>
    </>
  );
};

DayView.defaultProps = {
  year: null,
  month: null,
  day: null,
};

const { number, func } = PropTypes;

DayView.propTypes = {
  year: number,
  month: number,
  day: number,
  getShowings: func.isRequired,
  getPitches: func.isRequired,
  getClosingEscrow: func.isRequired,
  getContingenciesDue: func.isRequired,
  getFollowUpReminders: func.isRequired,
};

export default DayView;
