import { connect } from 'react-redux';

import { REALTOR_OFFER_TYPES } from 'constants/realtor.constants';

import selectors from 'store/selectors';

import {
  openNewCounterModal,
} from 'store/actions/newRealtorCounterModal';

import getOffersViewItems from 'store/actions/realtorOffers/getOffersViewItems';

import { openModal } from 'store/actions/modals';

import {
  openOfferDeclineReasonModal,
} from 'store/actions/offerDeclineReasonModal';

import Counter from './Counter';

const mapStateToProps = (state, ownProps) => {
  const {
    offerId,
    opportunityType,
  } = ownProps;

  const offer = selectors.realtorOffers.byId(state, offerId);

  const agentContactId = opportunityType === 'client'
    ? offer?.buyer_agent_contact_id
    : offer?.seller_agent_contact_id;

  const tags = [];
  if (offer?.all_cash) {
    tags.push('all cash');
  }
  if (!offer?.contingency_inspection && !offer?.contingency_loan && !offer?.contingency_appraisal) {
    tags.push('no contingency');
  }

  const userRole = REALTOR_OFFER_TYPES[offer?.type] || '';

  return {
    offerValue: offer?.amount,
    tags,
    counterName: `${userRole} | ${userRole === 'buyer' ? 'offer' : 'counter'}`,
    submitTime: offer?.created_at,
    expirationTime: offer?.expiry_date,
    agentContactId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDecline: () => dispatch(openOfferDeclineReasonModal(ownProps.offerId, false)),

  openNewCounterModal: () => {
    const onAfterSubmit = () => dispatch(getOffersViewItems());

    dispatch(openNewCounterModal(
      ownProps.offerId,
      ownProps.rootOfferId,
      onAfterSubmit,
    ));
  },
  openAcceptCounterModal: () => {
    dispatch(openModal('acceptOfferModal')({
      callbacks: {
        onAfterClose: () => {
          dispatch(getOffersViewItems());
        },
      },
    }, {
      offerId: ownProps.offerId,
      rootOfferId: ownProps.rootOfferId,
    }));
  },
  onDetails: () => {
    dispatch(openModal('offerDetails')({}, { offerId: ownProps.rootOfferId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
