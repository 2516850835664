import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

export default createSelector(
  (state) => state.propertyViewings.viewings,
  (_, id) => id,
  (collection, id) => {
    const defaultItem = {
      id: null,
      date_time: null,
      contacts: [],
    };

    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedPropertyViewingActions.getPropertyViewingById(id);
      }

      return defaultItem;
    }

    return {
      ...item,
    };
  },
);
