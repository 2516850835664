import { connect } from 'react-redux';

import moment from 'moment';

import stableStringify from 'json-stable-stringify';

import selectors from 'store/selectors';

import SummarySelector from 'shared/components/UI/SummarySelector';

const startOfDay = (date) => moment(date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
const endOfDay = (date) => moment(date).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');

const filters = {
  showingsFilter: {
    contact_type: 'not prospects',
  },
  pitchesFilter: {
    contact_type: 'prospects',
  },
  closingEscrowsFilter: {
    no_offset: true,
    date_fields: [
      'close_escrow_expires_at',
    ],
  },
  contingenciesDueFilter: {
    no_offset: true,
    date_fields: [
      'initial_documents_expires_at',
      'seller_disclosure_expires_at',
      'inspections_expires_at',
      'appraisal_expires_at',
      'loan_expires_at',
      'final_walkthrough_expires_at',
      'move_out_date_expires_at',
    ],
  },
  followUpRemindersFilter: {
  },
};

const getFilter = (filterName, date) => {
  const dateFrom = startOfDay(date);
  const dateTo = endOfDay(date);

  return {
    ...filters[filterName],
    date_from: dateFrom,
    date_to: dateTo,
    scheduledDate: [dateFrom, dateTo],
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    propertyViewings: {
      viewingsByFilterCount,
    },
    followUpReminders: {
      remindersCountByFilter,
    },
  } = state;

  const {
    selectedFilterKeys,
    selectedDate,
  } = ownProps;

  const showingsFilter = getFilter('showingsFilter', selectedDate);
  const pitchesFilter = getFilter('pitchesFilter', selectedDate);
  const closingEscrowsFilter = getFilter('closingEscrowsFilter', selectedDate);
  const contingenciesDueFilter = getFilter('contingenciesDueFilter', selectedDate);
  const followUpRemindersFilter = getFilter('followUpRemindersFilter', selectedDate);

  const showingsFilterId = stableStringify(showingsFilter);
  const pitchesFilterId = stableStringify(pitchesFilter);
  const followUpRemindersFilterId = stableStringify(followUpRemindersFilter);

  const closingEscrows = selectors.realtorTransactions.todayContingenciesTransactionsList(
    state,
    stableStringify(closingEscrowsFilter),
    closingEscrowsFilter.date_fields,
    selectedDate,
  );
  const contingenciesDue = selectors.realtorTransactions.todayContingenciesTransactionsList(
    state,
    stableStringify(contingenciesDueFilter),
    contingenciesDueFilter.date_fields,
    selectedDate,
  );

  return {
    activeFilter: selectedFilterKeys,
    items: [
      {
        key: 'viewings',
        value: (viewingsByFilterCount[showingsFilterId] + viewingsByFilterCount[pitchesFilterId]) || 0,
        title: 'Showings & Listing Pitches',
      },
      {
        key: 'transactionItems',
        value: (closingEscrows.length + contingenciesDue.length) || 0,
        title: 'Transaction Items',
      },
      {
        key: 'followUpReminders',
        value: remindersCountByFilter[followUpRemindersFilterId] || 0,
        title: 'Follow Ups',
      },
    ],
  };
};

export default connect(mapStateToProps)(SummarySelector);
