import connectReducers from 'store/connectReducers';

import {
  SET_DICTIONARY,
} from 'store/actionTypes/dictionary';

const initialState = {
  currencies: null,
  amenities: null,
  realtorSaleTypes: null,
  realtorViewsFromHome: null,
  realtorSpecialConditions: null,
  rejectedPropertiesReasons: null,
  realtorRepairTypes: null,
  personTitles: null,
  contactLabels: null,
};

const reducers = {
  [SET_DICTIONARY]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),
};

function dictionary(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default dictionary;
