import createPackage from './createPackage';
import getPackages from './getPackages';
import getPackageById from './getPackageById';
import updatePackage from './updatePackage';
import getPendingOrder from './getPendingOrder';
import getOutOfStockOrder from './getOutOfStockOrder';
import getWarehouseItems from './getWarehouseItems';

export default {
  getWarehouseItems,
  createPackage,
  getPackages,
  getPackageById,
  updatePackage,
  getPendingOrder,
  getOutOfStockOrder,
};
