import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Elevation from 'ui/Elevation';
import Divider from 'ui/Divider';
import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';

import ContactInfoConcise from 'sections/Contacts/modules/ContactInfoConcise';
import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';
import SocialLinks from 'sections/Contacts/modules/SocialLinks';

import Call from 'sections/Contacts/modules/Call';
import EmailButton from 'sections/Contacts/modules/EmailButton';

import useModal from 'ui/hooks/useModal';

const sectionStyle = {
  padding: 10,
};

const avatarProps = {
  label: 'listing agent',
};

const PropertyListingAgent = (props) => {
  const {
    contactId,
    onAdd,
    onRemove,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const onSave = useCallback((selectedContacts) => {
    onAdd(selectedContacts?.addedContacts?.[0]);
    closeModal();
  }, [
    onAdd,
    closeModal,
  ]);

  const content = !contactId
    ? (
      <Flex
        fullWidth
        fullHeight
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            tag="h2"
            variant="title2"
          >
            Add listing agent
          </Typography>
          <Tooltip
            title="Add listing agent"
          >
            <Button
              variant="primary"
              icon="plus"
              onClick={openModal}
            />
          </Tooltip>
        </Flex>
      </Flex>
    )
    : (
      <>
        <div style={sectionStyle}>
          <ContactInfoConcise
            contactId={contactId}
            avatarProps={avatarProps}
          />
        </div>
        <Divider />
        <div style={sectionStyle}>
          <Flex
            fullWidth
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <SocialLinks contactId={contactId} />

            <Divider />

            <Flex
              spacing={2}
              alignItems="center"
              fullWidth
            >
              <Call
                showText
                contactId={contactId}
              />
              <EmailButton
                showText
                contactId={contactId}
                contactType={avatarProps.label}
              />
            </Flex>

            <Flex
              flexDirection="column"
              spacing={1}
            >
              <Flex
                spacing={1}
              >
                <Tooltip
                  title="Edit listing agent"
                >
                  <Button
                    variant="secondary"
                    icon="edit"
                    onClick={openModal}
                  />
                </Tooltip>
                <Tooltip
                  title="Remove listing agent"
                >
                  <Button
                    variant="secondary"
                    icon="x"
                    onClick={onRemove}
                  />
                </Tooltip>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </>
    );

  return (
    <ErrorBoundary>
      <>
        <Elevation
          rounded="s"
          fullHeight
        >
          {content}
        </Elevation>

        <AssignContactModal
          title="Edit listing agent"
          isVisible={isModalVisible}
          oneContactSelect
          closeModal={closeModal}
          onSave={onSave}
          withAddContact
        />
      </>
    </ErrorBoundary>
  );
};

const {
  func,
  number,
} = PropTypes;

PropertyListingAgent.propTypes = {
  contactId: number.isRequired,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
};

export default PropertyListingAgent;
