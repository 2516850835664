/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import { Controller } from 'react-hook-form';

import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import EmailInput from 'shared/inputs/EmailInput';
import PhoneInput from 'shared/inputs/PhoneInput';
import PersonTitleSelect from 'shared/inputs/PersonTitleSelect';
import DateTimeInput from 'shared/inputs/DateTimeInput';
import SourceSelect from 'shared/inputs/SourceSelect';

const NewContactArea = (props) => {
  const {
    handleChange,
    objectFormName,
    title,
    control,
    errors,
    decorated,
    watch,
    required,
    withSalutations,
  } = props;

  const [emailValidations, setEmailValidations] = useState({
    doesEmailExist: false,
    isEmailValid: true,
  });

  const [phoneValidations, setPhoneValidations] = useState({
    doesPhoneExist: false,
  });

  const setFieldsValidations = {
    email_primary: setEmailValidations,
    phone_primary: setPhoneValidations,
  };

  const setFieldError = useCallback((fieldName) => (validationRule, value) => {
    setFieldsValidations[fieldName]((oldValues) => ({
      ...oldValues,
      [validationRule]: value,
    }));
  }, []);

  const firstName = watch ? watch(`${objectFormName}.first_name`) : null;
  const lastName = watch ? watch(`${objectFormName}.last_name`) : null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DecoratedFieldWrapper
          iconName={decorated ? 'notNull' : null}
          noMargin
        >
          <Grid container spacing={1}>
            {
              (title && withSalutations) && (
                <Grid item xs={12}>
                  <Typography
                    weight="bold"
                  >
                    {title}
                  </Typography>
                </Grid>
              )
            }

            {
              withSalutations && (
                <Grid item xs={2}>
                  <Controller
                    name={`${objectFormName}.person_title`}
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <PersonTitleSelect
                        {...field}
                        handleChange={handleChange(field.onChange)}
                      />
                    )}
                  />
                </Grid>
              )
            }

            <Grid item xs={4}>
              <Controller
                name={`${objectFormName}.first_name`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Textfield
                    label="First Name"
                    {...field}
                    onChange={handleChange(field.onChange)}
                    error={errors[objectFormName]?.first_name && 'This is a required field'}
                  />
                )}
                rules={{
                  required: required ? !lastName : false,
                }}
              />

            </Grid>
            <Grid item xs={6}>

              <Controller
                name={`${objectFormName}.last_name`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Textfield
                    label="Last Name"
                    {...field}
                    onChange={handleChange(field.onChange)}
                    error={errors[objectFormName]?.last_name && 'This is a required field'}
                  />
                )}
                rules={{
                  required: required
                    ? watch
                      ? !firstName
                      : false
                    : false,
                }}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid item xs={6}>
        <Controller
          name={`${objectFormName}.email_primary`}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <EmailInput
              {...field}
              decorated={decorated}
              decoratorProps={{ noMargin: true }}
              handleChange={handleChange(field.onChange)}
              onInvalidEmail={setFieldError('email_primary')}
              isEmailValid={!(errors[objectFormName]?.email_primary?.type === 'validate')}
              error={errors[objectFormName]?.first_name?.type === 'required' && 'This is a required field'}
            />
          )}
          rules={{
            required: false,
            validate: {
              existenceValidation: () => !emailValidations.doesEmailExist,
              isEmailValid: () => emailValidations.isEmailValid,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name={`${objectFormName}.phone_primary`}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <PhoneInput
              {...field}
              decorated={decorated}
              decoratorProps={{ noMargin: true }}
              handleChange={handleChange(field.onChange)}
              onInvalidPhone={setFieldError('phone_primary')}
              error={
                (errors[objectFormName]?.phone_primary?.type === 'required' && 'This is a required field')
                || (errors[objectFormName]?.phone_primary?.type === 'validate' && 'Not a valid phone number')
              }
            />
          )}
          rules={{
            required: false,
            validate: {
              existenceValidation: () => !phoneValidations.doesPhoneExist,
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <DecoratedFieldWrapper
          iconName={decorated ? 'search' : null}
          iconType="feather"
          variant="highlight"
          noMargin
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name={`${objectFormName}.source`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <SourceSelect
                    onSourceChange={handleChange(field.onChange)}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={`${objectFormName}.first_contact_date`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DateTimeInput
                    handleChange={handleChange(field.onChange)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

    </Grid>
  );
};

NewContactArea.defaultProps = {
  handleChange: (fieldName) => () => { },
  onInvalidField: (fieldName) => () => { },
  title: 'Or create a new contact',
  objectFormName: 'new_contact',
  decorated: true,
  watch: null,
  required: true,
};

const {
  func,
  string,
  shape,
  bool,
} = PropTypes;

NewContactArea.propTypes = {
  handleChange: func,
  onInvalidField: func,
  objectFormName: string,
  title: string,
  control: shape().isRequired,
  errors: shape().isRequired,
  decorated: bool,
  watch: func,
  required: bool,
  withSalutations: bool.isRequired,
};

export default NewContactArea;
