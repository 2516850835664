import { createSelector } from 'reselect';

import { isUndefined } from 'shared/utility';

import { optimizedMatchingPropertiesActions } from 'store/actions/realtorMatchingProperties';

import getFilter from './filter';

export default (filterConditions = {}) => (offset = undefined, limit = undefined) => createSelector(
  (state) => state.realtorMatchingProperties.matchingPropertiesByFilter,
  (state) => state.realtorMatchingProperties.matchingPropertiesCountByFilter,
  () => getFilter(filterConditions)().filterId,
  () => getFilter(filterConditions)().filter,
  (matchingPropertiesByFilter, matchingPropertiesCountByFilter, filterId, filter) => {
    if (isUndefined(matchingPropertiesByFilter[filterId])) {
      optimizedMatchingPropertiesActions.get(filterId, filter, offset, limit);
      return {
        items: [],
        count: 0,
      };
    }

    return {
      items: matchingPropertiesByFilter[filterId],
      count: matchingPropertiesCountByFilter[filterId],
    };
  },
);
