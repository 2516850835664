import {
  SET_NEW_REALTOR_CLIENT_MODAL_OPEN,
} from 'store/actionTypes/realtorClients';

const openNewClientModal = (propertyId = null, leadId = null) => ({
  type: SET_NEW_REALTOR_CLIENT_MODAL_OPEN,
  payload: { propertyId, leadId },
});

export default openNewClientModal;
