import styled from 'styled-components';

export const Root = styled.div`
  border-radius: 8px;
  padding: 2px 16px;
  text-transform: uppercase;
  background: ${({ color, theme }) => theme?.palette?.[color] || color};
  text-align: center;
  width: fit-content;
  letter-spacing: 0.1em
`;

export default {
  Root,
};
