import React from 'react';
import PropTypes from 'prop-types';

import { Root } from './styledItems';

const InputGroup = (props) => {
  const {
    children,
    flat,
    fullWidth,
  } = props;

  return (
    <Root
      flat={flat}
      fullWidth={fullWidth}
    >
      {children}
    </Root>
  );
};

InputGroup.defaultProps = {
  flat: false,
};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

InputGroup.propTypes = {
  children: arrayOf(element).isRequired,
  flat: bool,
};

export default InputGroup;
