import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertySummary from './PropertySummary';

const mapStateToProps = (state, ownProps) => {
  const {
    offerId,
  } = ownProps;

  const offer = selectors.realtorOffers.byId(state, offerId);

  const representingType = offer?.realtor_client_id
    ? 'client'
    : 'prospect';

  let propertyId;

  if (representingType === 'client') {
    const client = selectors.realtorClients.byId(state, offer.realtor_client_id);
    propertyId = client?.realtor_property_id;
  } else {
    propertyId = offer?.realtor_property_id;
  }

  const representingContactId = representingType === 'client'
    ? selectors.realtorClients.byId(state, offer?.realtor_client_id)?.contact_id
    : selectors.realtorProspects.byId(state, offer?.realtor_prospect_id)?.contact_id;

  return {
    propertyId,
    representing: {
      type: representingType,
      contactId: representingContactId,
    },
  };
};

export default connect(mapStateToProps)(PropertySummary);
