import {
  mapValues,
  xorBy,
} from 'shared/utility';

import requests from 'api/requests';

import { APPEND_CHAT_MESSAGE } from 'store/actionTypes/chat';

import setChat from './setChat';

const appendChatMessage = (chatId, message) => async (dispatch, getState) => {
  const {
    chat: {
      activeChatId,
      chatRooms,
      isVisible,
    },
    auth: {
      loggedUser: {
        EmployeeID,
      },
    },
  } = getState();

  if (isVisible) {
    if (activeChatId === chatId) {
      await requests.chat.markMessageAsRead(chatId)(message.id);
    }

    if (chatRooms[chatId]) {
      const newMessageData = {
        ...message,
        read_at: activeChatId === chatId ? message.created_at : null,
      };

      const newPreviewData = chatRooms[chatId].preview;

      newPreviewData.pop();

      newPreviewData.unshift(newMessageData);

      const newChatInf = {
        ...chatRooms[chatId],
        preview: newPreviewData,
      };
      dispatch(setChat(newChatInf));
    } else {
      const {
        data: chatData,
      } = await requests.chat.getChatById(chatId);

      const userChatData = mapValues(chatData, (value, key) => {
        if (key === 'members') {
          const exceptedUsers = [{
            user_type: 1,
            user_id: EmployeeID,
          }];

          const newMembers = xorBy(exceptedUsers, value, 'user_id');

          if (chatData.type === 1) {
            return newMembers[0];
          }
          return newMembers;
        }
        return value;
      });

      dispatch(setChat(userChatData));
    }
  }

  dispatch({
    type: APPEND_CHAT_MESSAGE,
    payload: {
      chatId,
      message,
    },
  });
};

export default appendChatMessage;
