import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  createLike,
  updateLike,
  removeLikeRelations,
} from 'store/actions/likes-dislikes/likes';

import {
  getContactById,
} from 'store/actions/realtorContacts';

import EditableList from 'ui/EditableList';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    realtorContacts: {
      selectedContact,
    },
  } = state;

  const likes = selectors.realtorContacts.contactLikes(state, contactId || selectedContact);

  return {
    items: likes,
    modalTitle: 'Edit contact likes',
    selectedContact: contactId || selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: debounce(async (likeId, likeData) => {
    dispatch(updateLike(likeId)(likeData));
  }, 1000),

  onCreate: async (contactId, likeData) => {
    await dispatch(createLike(likeData));
    dispatch(getContactById(contactId));
  },

  onDelete: async (contactId, likeId) => {
    await dispatch(removeLikeRelations('objects')(likeId)({
      object_type: 25,
      object_id: contactId,
    }));
    dispatch(getContactById(contactId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onChange: (likeId) => (key) => (value) => {
    dispatchProps.onChange(likeId, {
      [key]: value,
      object_type: 25,
      object_id: stateProps.selectedContact,
    });
  },
  onCreate: (likeData) => {
    dispatchProps.onCreate(stateProps.selectedContact, {
      ...likeData,
      object_type: 25,
      object_id: stateProps.selectedContact,
    });
  },
  onDelete: (likeId) => {
    dispatchProps.onDelete(stateProps.selectedContact, likeId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditableList);
