/* eslint-disable */
// TOD - refactor, remove unused methods
// TODO - fix depencency cycles
import { callAlert } from 'ui/Alert';
import { url as defaultUrl } from 'paths';

import { commentsModule } from 'constants';
// eslint-disable-next-line import/no-cycle
import {
  getConnector,
  customerToUrlConnector,
  apiConnector,
  tokenFetch as fetch,
  noContentTypeFetch as fileFetch,
} from 'shared/API';
// eslint-disable-next-line import/no-cycle
import { getFileList } from 'store/actions/attachments';
// eslint-disable-next-line import/no-cycle
import { noopFn } from 'shared/utils/index';
// eslint-disable-next-line import/no-cycle
import {
  getTickets,
  getTicketComments,
} from 'store/actions/tickets';

export const GET_CUSTOMER_TICKET_BY_PASSWORDS = 'GET_CUSTOMER_TICKET_BY_PASSWORDS';
export const getCustomerTicketByPasswords = (
  first,
  second,
  customToken,
) => (dispatch) => {
  getConnector(`/Tickets/ReturnTicketByPassword/${first}/${second}`, {
    token: customToken,
  })
    .then((res) => {
      dispatch(getCustomerTicket(res.results[0].TicketID, customToken));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const GET_CUSTOMER_TICKET_BY_URL = 'GET_CUSTOMER_TICKET_BY_URL';
export const getCustomerTicket = (id, token) => (dispatch) => getConnector(`/api/tickets/${id}`, { token }) // eslint-disable-line max-len
  .then((ticket) => {
    dispatch(getCustomerTicketSuccess(ticket));
  })
  .catch((err) => callAlert.warning('Error occured'));

export const GET_CUSTOMER_TICKET_BY_URL_SUCCESS = 'GET_CUSTOMER_TICKET_BY_URL_SUCCESS';
export function getCustomerTicketSuccess(ticket) {
  return {
    type: GET_CUSTOMER_TICKET_BY_URL_SUCCESS,
    ticket,
  };
}

export const GET_CUSTOMER_TICKET_COMMENTS = 'GET_CUSTOMER_TICKET_COMMENTS';
export const getCustomerTicketComments = (id, isStandard = true) => (dispatch) => {
  const connector = isStandard ? apiConnector : customerToUrlConnector;
  const url = `/api/comments/ticket/${id}`;

  return connector(url)
    .then((res) => {
      dispatch(getCustomerTicketCommentsSuccess(res));
      dispatch(getTickets());
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const GET_CUSTOMER_TICKET_COMMENTS_SUCCESS = 'GET_CUSTOMER_TICKET_COMMENTS_SUCCESS';
export const getCustomerTicketCommentsSuccess = (list) => ({
  type: GET_CUSTOMER_TICKET_COMMENTS_SUCCESS,
  comments: list,
});

export const customerTicketAddImg = (data, res, isStandard = true) => {
  const formData = new FormData();
  formData.append(commentsModule.ATTACHMENT, data.commentImg);

  return fileFetch(
    `${defaultUrl}/api/attachments/workspace/${data.commentType}/${res.CommentID}`,
    {
      method: 'POST',
      body: formData,
    },
    isStandard,
  )
    .then(() => {
      getFileList(data.commentType, data.ticketId, isStandard);
    })
    .catch(() => {});
};

/**
 *
 * @param {Object} data
 * @param {string} data.comment
 * @param {string} data.commentType
 * @param {number} data.ticketId
 * @param {number} data.ParentCommentID
 * @param {number} data.ParentLogID
 * @param {Boolean} isStandard
 */
export const customerTicketAddComment = (
  data,
  isSympleteUser = true,
) => async (dispatch) => {
  const postComment = isSympleteUser
    ? postCommentAsUser
    : postCommentAsCustomer;

  const getComments = isSympleteUser
    ? getTicketCommentsAsUser
    : getTicketCommentsAsCustomer;

  const {
    comment: Comment,
    commentType,
    ticketId,
    isPublic,
  } = data;

  const CommentType = commentType || null;

  const commentBody = {
    Comment,
    CommentType,
    isPrivate: !isPublic,
  };

  try {
    const response = await postComment('ticket')(ticketId)(commentBody);

    if (data.hasOwnProperty('commentImg') && data.commentImg !== null) { // eslint-disable-line no-prototype-builtins
      try {
        await customerTicketAddImg(
          { ...data, commentType: 'ticket-comment' },
          response,
          isSympleteUser,
        );
      } catch (error) {
        noopFn();
      }
    }

    const commentsResponse = await getComments(ticketId);
    dispatch(setCommentsForTicket(ticketId)(commentsResponse.data));
  } catch (error) {
    console.error('ERROR', error);
  }
};

export const customerTicketDeleteComment = (
  data,
  isSympleteUser = true,
) => async (dispatch) => {
  const deleteComment = isSympleteUser
    ? deleteCommentAsUser
    : deleteCommentAsCustomer;

  const {
    commentId,
    ticketId,
  } = data;

  try {
    await deleteComment(commentId);
    dispatch(getTicketComments(ticketId));
  } catch (error) {
    callAlert.warning('Error occured');
  }
};

export const customerTicketReplyDeleteComment = (
  data,
  isStandard = true,
) => (dispatch) => {
  fetch(
    `${defaultUrl}/api/comments/${data.commentId}`,
    {
      method: 'DELETE',
    },
    isStandard,
  )
    .then((res) => {
      dispatch(getTicketComments(data.ticketId));
      // dispatch(getCustomerTicketComments(data.ticketId, isStandard));
    })
    .catch((err) => callAlert.warning('Error occured'));
};

export const customerTicketEditComment = (
  data,
  isSympleteUser = true,
) => async (dispatch) => {
  const editComment = isSympleteUser
    ? editCommentAsUser
    : editCommentAsCustomer;

  const {
    childId,
    commentValue,
  } = data;

  const body = {
    Comment: commentValue,
  };

  try {
    await editComment(childId)(body);
    dispatch(getTicketComments(data.ticketId));
  } catch (error) {
    callAlert.warning('Error occured');
  }
};

export const customerTicketResolveComment = (data) => (dispatch) => {
  const {
    childId,
    messageType,
    ticketId,
    resolvedUserId: ResolvedUserID,
  } = data;

  let commentType;

  if (messageType === commentsModule.COMMENT_TYPE.COMMENT) {
    commentType = commentsModule.COMMENT_TYPE.TICKET;
  } else if (messageType === commentsModule.COMMENT_TYPE.REPLY) {
    commentType = commentsModule.COMMENT_TYPE.COMMENT;
  }

  const url = `/api/comments/${commentType}/${ticketId}/${childId}/resolve`;

  apiConnector(url, 'PUT', { ResolvedUserID })
    // .then((res) => dispatch(getCustomerTicketComments(ticketId)))
    .then((res) => dispatch(getTicketComments(ticketId)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE = 'CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE';
export const customerUpdateTicketAcknowledge = (commentId, isSympleteUser = true) => async (
  dispatch,
  getState,
) => {
  const acknowledgeComment = isSympleteUser
    ? acknowledgeCommentAsUser
    : acknowledgeCommentAsCustomer;

  try {
    await acknowledgeComment(commentId);
    dispatch(getTickets());
    // dispatch(getTicketComments(ticketId));
  } catch {
    callAlert.warning('Error occured');
  }
};

export const CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS = 'CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS'; // eslint-disable-line max-len
export const customerUpdateTicketAcknowledgeSuccess = (id, status) => ({
  type: CUSTOMER_UPDATE_COMMENT_ACKNOWLEDGE_SUCCESS,
  id,
  status,
});

export const SET_TICKET_DATA = 'SET_TICKET_DATA';
export const setTicketData = (status) => ({
  type: SET_TICKET_DATA,
  status,
});

export const getTicketStatusList = (isStandard = true) => (dispatch) => {
  const connector = isStandard ? getConnector : customerToUrlConnector;
  const url = '/Tickets/ReturnAllTicketStatuses';

  return connector(url)
    .then((res) => dispatch(dispatch(getTicketStatusListSuccess(res.ticketstatuses))))
    .catch((err) => dispatch(getTicketStatusListError()));
};

export const GET_TICKET_STATUS_LIST_SUCCESS = 'GET_TICKET_STATUS_LIST_SUCCESS';
export const getTicketStatusListSuccess = (statuses) => ({
  type: GET_TICKET_STATUS_LIST_SUCCESS,
  statuses,
});

export const GET_TICKET_STATUS_LIST_ERROR = 'GET_TICKET_STATUS_LIST_ERROR';
export const getTicketStatusListError = () => ({
  type: GET_TICKET_STATUS_LIST_ERROR,
});


export const TOKEN_TO_STORE = 'TOKEN_TO_STORE';
export const TICKET_TOKEN_TO_STORE = 'TICKET_TOKEN_TO_STORE';
export const tokenToStore = (token) => (dispatch) => {
  localStorage.setItem('token', token);
  dispatch({
    type: TICKET_TOKEN_TO_STORE,
    token,
  });
  dispatch({
    type: TOKEN_TO_STORE,
    token,
  });
};

export const customerResolve = (body) => (dispatch, getState) => {
  const id = getState().externalStore.ticketFromUrl.TicketID;
  const { token } = getState().externalStore;

  return customerToUrlConnector(
    `/Tickets/CustomerResolveTicket/${id}`,
    'POST',
    body,
  )
    .then(() => dispatch(getCustomerTicket(id, token)))
    .catch(() => {});
};

export function getCustomerLeadComments(id, isStandard = true) {
  return (dispatch) => {
    const connector = isStandard ? apiConnector : customerToUrlConnector;
    const url = `/api/comments/lead/${id}`;

    return connector(url)
      .then((res) => {
        dispatch(getCustomerTicketCommentsSuccess(res));
        dispatch(getTickets());
      })
      .catch((err) => callAlert.warning('Error occured'));
  };
}

export const customerLeadAddComment = (
  data,
  isStandard = true,
) => (dispatch) => {
  const connector = isStandard ? apiConnector : customerToUrlConnector;

  const {
    comment: Comment,
    commentType,
    ticketId,
    // customerId: CustomerID,
    isPublic,
    ParentCommentID,
    ParentLogID,
  } = data;

  const CommentType = commentType || null;

  const body = {
    Comment,
    CommentType,
    // CustomerID,
    isAcknowledged: false,
    isPrivate: !isPublic,
    ParentCommentID,
    ParentLogID,
  };

  const id = ticketId;

  connector(`/api/comments/lead/${id}`, 'POST', body)
    .then((res) => {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('commentImg') && data.commentImg !== null) {
        customerLeadAddImg(
          { ...data, commentType: 'ticket-comment' },
          res,
          isStandard,
        )
          .then(() => {
            dispatch(getCustomerLeadComments(data.ticketId, isStandard));
          })
          .catch(noopFn);
      } else {
        dispatch(getCustomerLeadComments(data.ticketId, isStandard));
      }
    })
    .catch((err) => console.error('ERROR', err));
};

export const customerLeadDeleteComment = (
  data,
  isStandard = true,
) => (dispatch) => {
  const {
    parentId, childId, ticketId,
  } = data;

  fetch(
    `${defaultUrl}/api/comments/lead/${parentId}/${childId}`,
    {
      method: 'DELETE',
    },
    isStandard,
  )
    .then((res) => {
      dispatch(getCustomerLeadComments(ticketId, isStandard));
    })
    .catch((err) => callAlert.warning('Error occured'));
};


export const customerLeadEditComment = (data) => (dispatch) => {
  const {
    childId,
    commentValue,
    // messageType,
    parentId,
    ticketId,
  } = data;

  // let commentType;

  // if (messageType === commentsModule.COMMENT_TYPE.COMMENT) {
  //   commentType = commentsModule.COMMENT_TYPE.TICKET;
  // } else if (messageType === commentsModule.COMMENT_TYPE.REPLY) {
  //   commentType = commentsModule.COMMENT_TYPE.COMMENT;
  // }

  const url = `/api/comments/lead/${parentId}/${childId}`;

  apiConnector(url, 'PUT', { Comment: commentValue })
    .then((res) => dispatch(getCustomerLeadComments(ticketId)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const customerLeadResolveComment = (data) => (dispatch) => {
  const {
    childId,
    // messageType,
    ticketId,
    resolvedUserId: ResolvedUserID,
  } = data;

  // let commentType;

  // if (messageType === commentsModule.COMMENT_TYPE.COMMENT) {
  //   commentType = commentsModule.COMMENT_TYPE.TICKET;
  // } else if (messageType === commentsModule.COMMENT_TYPE.REPLY) {
  //   commentType = commentsModule.COMMENT_TYPE.COMMENT;
  // }

  const url = `/api/comments/lead/${ticketId}/${childId}/resolve`;

  apiConnector(url, 'PUT', { ResolvedUserID })
    .then((res) => dispatch(getCustomerLeadComments(ticketId)))
    .catch((err) => callAlert.warning('Error occured'));
};

export const customerLeadAddImg = (data, res, isStandard = true) => {
  const formData = new FormData();
  formData.append(commentsModule.ATTACHMENT, data.commentImg);

  return fileFetch(
    `${defaultUrl}/api/attachments/workspace/lead/${res.CommentID}`,
    {
      method: 'POST',
      body: formData,
    },
    isStandard,
  )
    .then(() => {
      getFileList('lead', data.ticketId, isStandard);
    })
    .catch(() => { });
};

export const customerTicketMarkCommentAsSeen = (commentId) => async (dispatch, getState) => {
  const {
    auth: {
      loggedUser: {
        EmployeeID,
      },
    },
  } = getState();

  const query = `/api/comments/seen/${commentId}/${EmployeeID}`;


  apiConnector(query, 'GET');
};
