export const SET_REALTOR_CONTACT = 'SET_REALTOR_CONTACT';
export const SET_REALTOR_CONTACTS = 'SET_REALTOR_CONTACTS';
export const SET_REALTOR_CONTACTS_FOR_FILTER = 'SET_REALTOR_CONTACTS_FOR_FILTER';
export const SET_REALTOR_CONTACTS_FOR_FILTER_COUNT = 'SET_REALTOR_CONTACTS_FOR_FILTER_COUNT';
export const SET_REALTOR_OVERALL_CONTACTS_SUMMARY = 'SET_REALTOR_OVERALL_CONTACTS_SUMMARY';
export const SET_REALTOR_SELECTED_CONTACT = 'SET_REALTOR_SELECTED_CONTACT';
export const SET_REALTOR_SELECTED_CONTACT_ROWS = 'SET_REALTOR_SELECTED_CONTACT_ROWS';
export const SET_REALTOR_CONTACT_CHANGELOG = 'SET_REALTOR_CONTACT_CHANGELOG';
export const SET_REALTOR_CONTACT_VIEWERS = 'SET_REALTOR_CONTACT_VIEWERS';
export const SET_REALTOR_CONTACTS_FILTER_ITEM = 'SET_REALTOR_CONTACTS_FILTER_ITEM';
export const RESET_REALTOR_CONTACTS_FILTER_ITEM = 'RESET_REALTOR_CONTACTS_FILTER_ITEM';
export const CLEAR_REALTOR_CONTACTS_FILTER = 'CLEAR_REALTOR_CONTACTS_FILTER';

export const SET_NEW_REALTOR_CONTACT_MODAL_CLOSED = 'SET_NEW_REALTOR_CONTACT_MODAL_CLOSED';
export const SET_NEW_REALTOR_CONTACT_MODAL_OPEN = 'SET_NEW_REALTOR_CONTACT_MODAL_OPEN';
export const SET_NEW_REALTOR_CONTACT_FORM_FIELD = 'SET_NEW_REALTOR_CONTACT_FORM_FIELD';
export const SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD = 'SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD';
export const SET_NEW_REALTOR_CONTACT_FORM = 'SET_NEW_REALTOR_CONTACT_FORM';

export const SET_NEW_REALTOR_CONTACT_LOG_MODAL_CLOSED = 'SET_NEW_REALTOR_CONTACT_LOG_MODAL_CLOSED';
export const SET_NEW_REALTOR_CONTACT_LOG_MODAL_OPEN = 'SET_NEW_REALTOR_CONTACT_LOG_MODAL_OPEN';
export const SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD = 'SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD';

export default {
  SET_REALTOR_CONTACT,
  SET_REALTOR_CONTACTS,
  SET_REALTOR_CONTACTS_FOR_FILTER,
  SET_REALTOR_CONTACTS_FOR_FILTER_COUNT,
  SET_REALTOR_OVERALL_CONTACTS_SUMMARY,
  SET_REALTOR_SELECTED_CONTACT,
  SET_REALTOR_SELECTED_CONTACT_ROWS,
  SET_REALTOR_CONTACT_CHANGELOG,
  SET_REALTOR_CONTACT_VIEWERS,
  SET_REALTOR_CONTACTS_FILTER_ITEM,
  RESET_REALTOR_CONTACTS_FILTER_ITEM,
  CLEAR_REALTOR_CONTACTS_FILTER,

  SET_NEW_REALTOR_CONTACT_MODAL_OPEN,
  SET_NEW_REALTOR_CONTACT_MODAL_CLOSED,
  SET_NEW_REALTOR_CONTACT_FORM_FIELD,
  SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD,
  SET_NEW_REALTOR_CONTACT_FORM,

  SET_NEW_REALTOR_CONTACT_LOG_MODAL_CLOSED,
  SET_NEW_REALTOR_CONTACT_LOG_MODAL_OPEN,
  SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD,
};
