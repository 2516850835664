/* eslint-disable react/require-default-props */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Video from 'oa/components/Video/index';
import Button from 'oa/components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { meetingParticipantType } from 'oa/types/index';

const Thumbnail = (props) => {
  const {
    participant,
    stream,
    pinnedParticipant,
    unpinParticipant,
    pinParticipant,
  } = props;

  const isCurrentParticipantPinned = (
    pinnedParticipant && pinnedParticipant.socketId === participant.socketId
  );

  const handleUnpinParticipant = useCallback(() => {
    unpinParticipant(participant.socketId);
  }, [unpinParticipant, participant]);

  const handlePinParticipant = useCallback(() => {
    pinParticipant(participant.socketId);
  }, [pinParticipant, participant]);

  return (
    <li className="meeting-user-list__item meeting-user-thumbnail">
      <Video
        srcObject={stream}
        className="meeting-user-thumbnail__video"
        width="240"
        height="160"
        autoPlay
        playsInline
        poster={videoPoster}
      />

      <div className="meeting-user-thumbnail__overlay">
        <ul className="meeting-user-thumbnail__list">
          <li className="meeting-user-thumbnail__item">
            {
              isCurrentParticipantPinned
                ? (
                  <Button
                    onClick={handleUnpinParticipant}
                    className="meeting-user-thumbnail__action"
                    prepend={(
                      <span className="fa-stack meeting-user-thumbnail__icon-stack">
                        <FontAwesomeIcon icon="thumbtack" className="fa-stack-1x" />
                        <FontAwesomeIcon icon="ban" className="fa-stack-2x" />
                      </span>
                    )}
                    hideText
                  >
                    {`Unpin ${participant.username} user from main screen`}
                  </Button>
                )
                : (
                  <Button
                    onClick={handlePinParticipant}
                    className="meeting-user-thumbnail__action"
                    prepend={(
                      <FontAwesomeIcon
                        icon="thumbtack"
                        className="meeting-user-thumbnail__icon"
                      />
                    )}
                    hideText
                  >
                    {`Pin ${participant.username} user to main screen`}
                  </Button>
                )
            }
          </li>
        </ul>
      </div>
    </li>
  );
};

// eslint-disable-next-line max-len
const videoPoster = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Crect width='100%25' height='100%25' fill='black'/%3E%3Cpath fill='white' fill-opacity='0.3' d='M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z' /%3E%3C/svg%3E";

Thumbnail.propTypes = {
  participant: meetingParticipantType.isRequired,
  stream: PropTypes.instanceOf(MediaStream).isRequired,
  pinnedParticipant: meetingParticipantType,
  unpinParticipant: PropTypes.func.isRequired,
  pinParticipant: PropTypes.func.isRequired,
};

export default Thumbnail;
