import {
  SET_CRM_OVERALL_CONTACTS_SUMMARY,
} from 'store/actionTypes/crm';

const setOverallContactsSummary = (summary) => ({
  type: SET_CRM_OVERALL_CONTACTS_SUMMARY,
  payload: summary,
});

export default setOverallContactsSummary;
