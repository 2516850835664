import connectReducers from 'store/connectReducers';

import {
  GET_LABELS_SUCCESS,
  POST_LABEL_SUCCESS,
  EDIT_LABEL_SUCCESS,
} from 'store/actionTypes/labels';

const initialState = {
  labels: [],
};

const reducers = {
  [GET_LABELS_SUCCESS]: (state, action) => ({
    ...state,
    labels: action.payload.map((item) => ({
      ...item,
      color: `#${item.color}`,
    })),
  }),
  [POST_LABEL_SUCCESS]: (state, action) => {
    state.labels.push({
      ...action.payload,
      color: `#${action.payload.color}`,
    });
    return {
      ...state,
    };
  },
  [EDIT_LABEL_SUCCESS]: (state, action) => {
    const updatedLabels = state.labels.map((item) => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          name: action.payload.name,
          description: action.payload.description,
        };
      }
      return item;
    });
    return {
      ...state,
      labels: updatedLabels,
    };
  },
};

const labels = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default labels;
