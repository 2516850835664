import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import Table from 'ui/Table';

import columns from './columns';

import './ContactsTable.scss';

const sortFieldDict = {
  contactName: 'contact_name',
  type: 'type',
  role: 'role',
  email: 'email_primary',
  phone: 'phone_primary',
  contactTime: 'contact_time',
};

const sortDirectionDict = {
  ascend: 'asc',
  descend: 'desc',
};

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} contacts`;

const ContactsTable = (props) => {
  const {
    contactsList,
    getContacts,
    setFilterItem,
    itemsCount,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    onRowSelect,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getContacts(pageNumber, pageSize);

    return () => { // this is the same as componentWillUnmount
      onRowSelect([]);
    };
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onRowSelectionChange = useCallback((selectedKeys, selectedRows) => {
    onRowSelect(selectedRows.map((item) => item.id));
    setSelectedRowKeys(selectedKeys);
  }, [
    onRowSelect,
  ]);

  const rowSelection = {
    onChange: onRowSelectionChange,

    columnWidth: 0.5,
    selectedRowKeys,
  };

  const onChange = useCallback((pagination, filter, sorter) => {
    const hasPageChaged = pagination && (pagination.current !== pageNumber || pagination.pageSize !== pageSize); // eslint-disable-line max-len
    const hasSortedChanged = sorter.field && (sortBy !== sortFieldDict[sorter.field] || sortDirection !== sortDirectionDict[sorter.order] || 'asc'); // eslint-disable-line max-len

    const shouldGetNewSortedItems = !hasPageChaged && hasSortedChanged;

    if (shouldGetNewSortedItems) {
      setFilterItem('sortBy')(sortFieldDict[sorter.columnKey]);
      setFilterItem('sortDirection')(sortDirectionDict[sorter.order] || 'asc');
      getContacts(pageNumber, pageSize);
      onRowSelect([]);
      setSelectedRowKeys([]);
    }
  }, [
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setFilterItem('pageNumber')(newPageNumber);
    setFilterItem('pageSize')(newPageSize);
    getContacts(newPageNumber, newPageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    setFilterItem,
    getContacts,
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const onSort = useCallback((key) => (order) => () => {
    setFilterItem('sortBy')(sortFieldDict[key]);
    setFilterItem('sortDirection')(sortDirectionDict[order] || 'asc');
    getContacts(pageNumber, pageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    onRowSelect,
    setSelectedRowKeys,
    setFilterItem,
    getContacts,
    pageNumber,
    pageSize,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    // onClick: (event) => {
    //   history.push(`/contacts/${record.id}`);
    // },
    onMouseEnter: (event) => {
      setHoveredRecord(record.key);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    history,
    setHoveredRecord,
  ]);

  return (
    <Table
      key="contacts-table"
      className="realtor-contacts-table"
      dataSource={contactsList}
      columns={columns({ hoveredRecord, onSort })}
      rowSelection={rowSelection}
      onChange={onChange}
      pagination={{
        total: itemsCount,
        showTotal,
        onChange: onPaginationChange,
        current: pageNumber,
        pageSize,
      }}
      onRow={onRow}
      tableLayout="fixed"
      size="middle"
    />
  );
};

ContactsTable.defaultProps = {
  contactsList: [],
  itemsCount: 0,
  pageNumber: 1,
  pageSize: 10,
  sortBy: 'id',
  sortDirection: 'desc',
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
  string,
} = PropTypes;

ContactsTable.propTypes = {
  contactsList: arrayOf(shape({
    key: string,
    leadId: number,
    connectedItemId: number,
    status: string,
    sourceId: number,
    personId: number,
    type: number,
    expectedSalePrice: number,
    expectedPurchasePrice: arrayOf(number),
    propertyType: arrayOf(string),
    location: number,
    createdAt: string,
    children: arrayOf(shape({
      key: string,
      leadId: number,
      connectedItemId: number,
      type: number,
      expectedSalePrice: number,
      expectedPurchasePrice: arrayOf(number),
      propertyType: arrayOf(string),
      location: number,
      createdAt: string,
    })),
  })),
  getContacts: func.isRequired,
  setFilterItem: func.isRequired,
  itemsCount: number,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: oneOf(['asc', 'desc']),
  onRowSelect: func.isRequired,
};

export default ContactsTable;
