import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getTransactionById,
} from 'store/actions/realtorTransactions';

import ExtensionRequests from './ExtensionRequests';

const mapStateToProps = (state, ownProps) => {
  const { transactionId } = ownProps;

  const newItems = selectors.realtorExtensionRequests.byFilter({
    transactionId,
    status: 1,
  })(state) || [];

  const outstandingItems = selectors.realtorExtensionRequests.byFilter({
    transactionId,
    status: 2,
  })(state) || [];

  const respondedItems = selectors.realtorExtensionRequests.byFilter({
    transactionId,
    status: 3,
  })(state) || [];

  return {
    newItems,
    outstandingItems,
    respondedItems,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAfterUpdate: () => {
    dispatch(getTransactionById(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionRequests);
