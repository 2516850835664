import { connect } from 'react-redux';

import { isNull } from 'shared/utility';

import selectors from 'store/selectors';

import MainTransactionInfo from './MainTransactionInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const {
    addendum_requests_summary,
    repair_requests_summary,
    realtor_property_id,
    realtor_client_id,
  } = transaction;

  const property = selectors.properties.byId(state, realtor_property_id);

  const {
    address_id,
    expected_price,
  } = property;

  const address = selectors.addresses.byId(state, address_id);

  const type = !isNull(realtor_client_id) ? 'client' : 'prospect';

  return {
    propertyId: realtor_property_id,
    address: address?.address,
    addendumRequest: (addendum_requests_summary?.new || 0) + (addendum_requests_summary?.outstanding || 0),
    repairRequest: repair_requests_summary?.total_repair_requests || 0,
    type,
    expectedPrice: expected_price,
  };
};

export default connect(mapStateToProps, null)(MainTransactionInfo);
