import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import KeyPeopleItem from './KeyPeopleItem';

const KeyPeople = (props) => {
  const {
    keyPeople,
  } = props;

  return (
    <Flex
      spacing={2}
      flexWrap="wrap"
      fullWidth
    >
      {
        keyPeople.map((item) => {
          const {
            contactId,
            onAdd,
            onClick,
            onRemove,
            label,
          } = item;

          return (
            <FlexItem
              xs={12}
              key={`${label}${contactId}`}
            >
              <KeyPeopleItem
                contactId={contactId}
                onAdd={onAdd}
                onClick={onClick}
                onRemove={onRemove}
                label={label}
              />
            </FlexItem>
          );
        })
      }
    </Flex>
  );
};

KeyPeople.defaultProps = {
  keyPeople: [],
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

KeyPeople.propTypes = {
  keyPeople: arrayOf(shape({
    contactId: number,
    onAdd: func,
    onClick: func,
    onRemove: func,
    label: string,
  })),
};

export default KeyPeople;
