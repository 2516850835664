import React from 'react';
import PropTypes from 'prop-types';

import BookShowing from 'sections/Prospects/modules/BookShowing';

import UpcomingShowingSummary from 'sections/MatchingProperties/modules/UpcomingShowingSummary';
import LogShowFeedback from 'sections/MatchingProperties/modules/LogShowFeedback';

const KanbanCardControls = (props) => {
  const {
    matchId,
    prospectId,
    lane,
    hideOptions,
  } = props;

  if (lane === 'assigned') {
    return (
      <BookShowing
        matchId={matchId}
        prospectId={prospectId}

      />
    );
  }

  if (lane === 'showingsUpcoming') {
    return (
      <UpcomingShowingSummary
        matchId={matchId}
        hideOptions={hideOptions}
      />
    );
  }

  if (lane === 'showingsCompleted') {
    return (
      <LogShowFeedback
        matchId={matchId}
      />
    );
  }

  return null;
};

KanbanCardControls.defaultProps = {
  hideOptions: false,
};

const {
  number,
  oneOf,
  bool,
} = PropTypes;

KanbanCardControls.propTypes = {
  matchId: number.isRequired,
  prospectId: number.isRequired,
  lane: oneOf([
    'assigned',
    'showingsUpcoming',
    'showingsCompleted',
    'offers',
  ]).isRequired,
  hideOptions: bool,
};

export default KanbanCardControls;
