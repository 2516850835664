import { connect } from 'react-redux';

import {
  setLogNewQuestionFormAttachments,
} from 'store/actions/logNewQuestionForm';

import AttachmentsUpload from 'shared/inputs/InlineAttachmentsUpload';

const mapStateToProps = (state) => {
  const {
    logNewQuestionForm: {
      attachments,
    },
  } = state;

  return {
    fileList: attachments,
    multiple: true,
    itemSize: 'm',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpload: (file, fileList) => {
    const fileUrl = URL.createObjectURL(file);

    const fileObject = {
      name: file.name,
      type: file.type,
      uid: file.uid,
      url: fileUrl,
    };

    dispatch(setLogNewQuestionFormAttachments(fileList.concat([fileObject])));
  },
  onRemove: ({ uid, url }, fileList) => {
    URL.revokeObjectURL(url);

    const newList = fileList.filter((file) => file.uid !== uid);

    dispatch(setLogNewQuestionFormAttachments(newList));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onUpload: async ({ file }) => {
    const {
      fileList = [],
    } = stateProps;

    if (fileList.length > 4) {
      return;
    }

    dispatchProps.onUpload(file, fileList);
  },
  onRemove: ({ uid, url }) => {
    const {
      fileList = [],
    } = stateProps;

    dispatchProps.onRemove({ uid, url }, fileList);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AttachmentsUpload);
