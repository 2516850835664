import React, {
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import DataImportContext from 'sections/DataImport/context/DataImportContext';

const Item = ({ label, value }) => (
  <Flex spacing={1} alignItems="center">
    <Typography color="focus" tag="p" variant="h2" noMargin>
      {value}
    </Typography>
    <Typography noMargin>
      {label}
    </Typography>
  </Flex>
);

const KPIs = (props) => {
  const {
    KPIs,
  } = useContext(DataImportContext);

  const {
    totalRows,
    importedRows,
    ignoredRows,
    mappingKPIs: {
      mapped,
      notes,
    },
  } = KPIs;

  return (
    <Flex spacing={4} justifyContent="center" fullWidth>
      <Item
        value={totalRows}
        label="Total records"
      />
      <Item
        value={importedRows}
        label="Importing"
      />
      <Item
        value={ignoredRows}
        label="Not importing"
      />
      <Item
        value={mapped}
        label="Matched columns"
      />
      <Item
        value={notes}
        label="Columns added as notes"
      />
    </Flex>
  );
};

KPIs.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

KPIs.propTypes = {

};

export default KPIs;
