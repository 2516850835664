import React from 'react';
import PropTypes from 'prop-types';

import Tag from 'ui/Tag';
import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const CalendarEvent = (props) => {
  const {
    variant,
    title,
    itemsCount,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    <Root
      variant={variant}
      spacing={2}
      fullWidth
      alignItems="center"
      justifyContent="space-between"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Typography
        variant="cell2"
        noMargin
        ellipsis
        style={{ maxWidth: '100%' }}
      >
        {title}
      </Typography>

      <Tag
        variant={variant}
        size="s"
      >
        {itemsCount}
      </Tag>
    </Root>
  );
};

const noop = () => {};

CalendarEvent.defaultProps = {
  variant: 'primary',
  itemsCount: 0,
  onMouseEnter: noop,
  onMouseLeave: noop,
};

const {
  number,
  string,
  func,
} = PropTypes;

CalendarEvent.propTypes = {
  variant: string,
  title: string.isRequired,
  itemsCount: number,
  onMouseEnter: func,
  onMouseLeave: func,
};

export default CalendarEvent;
