import {
  SET_REALTOR_OPPORTUNITIES,
} from 'store/actionTypes/realtorOpportunities';

const setOpportuinities = (items) => ({
  type: SET_REALTOR_OPPORTUNITIES,
  payload: items,
});

export default setOpportuinities;
