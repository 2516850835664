import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;

export const PropertyDetailsWrapper = styled.div`
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme?.palette?.['input-border'] || '#1584ff'};
  padding: 20px;

  .property-type-select {
    padding-left: 43px;
    margin-bottom: 19px;
    margin-top: 5px;
  }

  .seller-property-description {
    position: relative;
    bottom: 22px;
    left: 8px;
    background-color: white;
    width: fit-content;
    border-radius: 41px;
    padding: 0 5px;
    margin-bottom: -22px;
  }
`;

export default {
  Wrapper,
  PropertyDetailsWrapper,
};
