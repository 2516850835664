import { connect } from 'react-redux';

import selectors from 'store/selectors';

import AgentInfo from 'shared/components/AgentInfo';

const mapStateToProps = (state) => {
  const {
    user,
    company,
  } = selectors.external.general.tokenIssuerData(state);

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    avatarUrl: user.avatarUrl,
    companyName: company.companyName,
    logoUrl: company.logoUrl,
  };
};

export default connect(mapStateToProps)(AgentInfo);
