import connectReducers from 'store/connectReducers';

import {
  SET_CUSTOMER,
} from 'store/actionTypes/customers';

const initialState = {
  customers: {},
};

const reducers = {
  [SET_CUSTOMER]: (state, action) => {
    const customer = action.payload;

    return {
      ...state,
      customers: {
        ...state.customers,
        [customer.CustomerID]: customer,
      },
    };
  },
};

const customers = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default customers;
