import { createSelector } from 'reselect';
import { isEmpty } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

import { // eslint-disable-line import/no-cycle
  optimizedCustomerInvoicesActions,
} from 'store/actions/customer-invoices';

import { // eslint-disable-line import/no-cycle
  optimizedOrderActions,
} from 'store/actions/orders';

import {
  optimizedCustomerPaymentsActions,
} from 'store/actions/customer-payments';

import { // eslint-disable-line import/no-cycle
  optimizedShippedItemsActions,
} from 'store/actions/shipped-items';

export default createSelector(
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.selectedFulfillment,
  (state) => state.customerInvoices.invoices,
  (state) => state.orders.orders,
  (state) => state.customerPayments.customerPayments,
  (state) => state.shippedItems.shippedItems,
  (
    fulfillments,
    fulfillmentId,
    invoices,
    orders,
    payments,
    shippedItems,
  ) => {
    const defaultSteps = [
      {
        title: 'Upload Agreement',
      },
      {
        title: 'Create & Send Invoice',
      },
      {
        title: 'Payment Received',
      },
      {
        title: 'Order Created',
      },
      {
        title: 'Item Shipped',
      },
      {
        title: 'Delivery Confirmed',
      },
      {
        title: 'Feedback',
      },
    ];

    const fulfillment = fulfillments[fulfillmentId];

    if (!fulfillment) {
      if (fulfillmentId) {
        optimizedFulfillmentsActions.getFulfillmentById(fulfillmentId);
      }

      return [];
    }

    if (!isEmpty(fulfillment.attachments?.agreement)) {
      defaultSteps[0].status = 'complete';
    }

    const invoiceId = fulfillment.invoices?.[0]?.id;
    const invoice = invoices[invoiceId];

    if (!invoice) {
      if (invoiceId) {
        optimizedCustomerInvoicesActions.getInvoiceById(invoiceId);
      }
    }

    if (invoice?.invoice_number) {
      defaultSteps[1].status = 'complete';
    }

    const orderId = fulfillment.orders?.[0]?.id;
    const order = orders[orderId];

    if (!order) {
      if (orderId) {
        optimizedOrderActions.getOrderById(orderId);
      }
    }

    if (order?.order_number) {
      defaultSteps[3].status = 'complete';
    }

    const paymentId = fulfillment.payments?.[0]?.id;
    const payment = payments[paymentId];

    if (!payment) {
      if (paymentId) {
        optimizedCustomerPaymentsActions.getPaymentById(paymentId);
      }
    }

    if (payment?.transaction_id || payment?.amount > 0) {
      defaultSteps[2].status = 'complete';
    }

    const shippedItemId = fulfillment.shipped_items?.[0]?.id;
    const shippedItem = shippedItems[shippedItemId];

    if (!shippedItem) {
      if (shippedItemId) {
        optimizedShippedItemsActions.getShippedItemById(shippedItemId);
      }
    }

    if (shippedItem?.tracking_number) {
      defaultSteps[4].status = 'complete';
    }

    if (!isEmpty(fulfillment.attachments?.['delivery confirmation'])) {
      defaultSteps[5].status = 'complete';
    }
    if (!isEmpty(fulfillment.attachments?.feedback)) {
      defaultSteps[6].status = 'complete';
    }

    return defaultSteps;
  },
);
