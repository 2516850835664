/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Tag from 'ui/Tag';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import withTags from 'ui/hocs/withTags';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import PrimaryAddressInput from 'sections/Contacts/modules/PrimaryAddressInput';

import ContactNameInput from 'sections/Contacts/modules/ContactNameInput';
import Birthday from 'sections/Contacts/modules/Birthday';

import ContactPhoneNumbers from 'sections/Contacts/modules/ContactPhoneNumbers';
import ContactEmails from 'sections/Contacts/modules/ContactEmails';

const ContactInfoMain = (props) => {
  const {
    contactId,
    nameTypographyProps,
    label,
    labelProps,
    labels,

    // custom components
    avatar,
    avatarProps,
  } = props;

  let vip;

  if (labels) {
    vip = labels.some((item) => item.label_id === 14);
  }

  const TaggedContactAvatar = withTags(ContactAvatar)(vip ? 'vip' : null, { size: 's' });

  return (
    <Flex
      spacing={2}
    >
      {
        avatar || (
          <TaggedContactAvatar
            contactId={contactId}
            {...avatarProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        )
      }

      <FlexItem
        flex={1}
      >
        <Flex
          spacing={2}
          flexDirection="column"
        >
          <ContactNameInput
            contactId={contactId}
            displayTypographyProps={nameTypographyProps}
            defaultView="display"
          />

          {
            label && (
              <Tag
                {...labelProps} // eslint-disable-line react/jsx-props-no-spreading
              >
                {' '}
                {label}
              </Tag>
            )
          }

          <DecoratedFieldWrapper
            iconName="mail"
            iconType="feather"
            size="sm"
            variant="focus"
          >
            <ContactEmails
              contactId={contactId}
            />
          </DecoratedFieldWrapper>

          <DecoratedFieldWrapper
            iconName="phone"
            iconType="feather"
            variant="focus"
            size="sm"
          >
            <ContactPhoneNumbers
              contactId={contactId}
            />
          </DecoratedFieldWrapper>

          <DecoratedFieldWrapper
            iconName="gift"
            iconType="feather"
            variant="focus"
            size="sm"
          >
            <Birthday
              contactId={contactId}
            />
          </DecoratedFieldWrapper>

          <DecoratedFieldWrapper
            iconName="home"
            iconType="feather"
            variant="focus"
            size="sm"
          >
            <PrimaryAddressInput
              contactId={contactId}
              displayTypographyProps={{
                variant: 'body',
              }}
              defaultView="display"
              placeholder="Primary Address"
            />
          </DecoratedFieldWrapper>
        </Flex>
      </FlexItem>
    </Flex>
  );
};

ContactInfoMain.defaultProps = {
  contactId: null,
  type: null,
  avatar: null,
  editable: true,
  nameTypographyProps: {
    variant: 'title1',
  },
  label: null,
  labelProps: {},
  isPastClient: false,
  avatarProps: {},
  labels: [],
};

const {
  arrayOf,
  bool,
  element,
  number,
  shape,
  string,
} = PropTypes;

ContactInfoMain.propTypes = {
  contactId: number,
  type: arrayOf(number),
  editable: bool,
  nameTypographyProps: shape(),
  label: string,
  labelProps: shape(),
  avatar: element,
  isPastClient: bool,
  avatarProps: shape(),
  labels: arrayOf({}),
};

export default ContactInfoMain;
