import {
  SET_REALTOR_TRANSACTION,
} from 'store/actionTypes/realtorTransactions';

const setTransaction = (transaction) => ({
  type: SET_REALTOR_TRANSACTION,
  payload: transaction,
});

export default setTransaction;
