import React from 'react';

const initialSteps = [
  {
    name: 'tutorial-create-new-task',
    target: '[data-tutorial="create-new-task"]',
    title: 'Create new task',
    content: (
      <ol>
        <li>Click the New Task button</li>
        <li>
          Fill out the task details
          <ol>
            <li>Set a due date</li>
            <li>Assign a user</li>
          </ol>
        </li>
        <li>Close task when done</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 1000,
      },
    },
    spotlightClicks: false,
  },
  {
    name: 'tutorial-create-new-subtask',
    target: '[data-tutorial="create-new-subtask"]',
    title: 'Create new subtask',
    content: (
      <ol>
        <li>Click the New Subtask button</li>
        <li>
          Select an existing task or create a new one
          <ol>
            <li>Set a due date</li>
            <li>Assign a user</li>
          </ol>
        </li>
        <li>Close subtask when done</li>
      </ol>),
    disableBeacon: true,
    placement: 'top-end',
  },
  {
    name: 'tutorial-archive-task',
    target: '[data-tutorial="processing-tickets-archive-task"]',
    title: 'Archive task',
    content: (
      <ol>
        <li>Click the dropdown on the task modal</li>
        <li>
          Select to archive task
          <ol>
            <li>Task can be reopened</li>
          </ol>
        </li>
        <li>
          Archived tasks can be seen using the toggle under the Done column on
          the My Tasks page.
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'bottom',
    spotlightClicks: false,
  },
  {
    name: 'tutorial-complete-task',
    target: '[data-tutorial="processing-tickets-archive-task"]',
    title: 'Complete task',
    content: (
      <ol>
        <li>Click the complete task button</li>
        <li>
          Task will show completed and be seen under the Done column on the My
          Tasks or Team Overview page (managers).
        </li>
      </ol>),
    disableBeacon: true,
    placement: 'left',
    spotlightClicks: false,
  },
  {
    name: 'tutorial-create-teams',
    target: '[data-tutorial="create-task-teams"]',
    title: 'Create task teams',
    content: (
      <ol>
        <li>Click the New Team button.</li>
        <li>
          Set team name and department (if there are no departments set do this
          in Company Settings).
        </li>
        <li>Add Managers and Team Employees.</li>
      </ol>),
    disableBeacon: true,
    placement: 'auto',
    styles: {
      options: {
        zIndex: 1000,
      },
    },
    manager: true,
  },
  {
    name: 'tutorial-assign-tasks',
    target: 'body',
    title: 'Assign tasks to users',
    content: (
      <div>
        <span>There are many ways to assign a task</span>
        <ol>
          <li>Create a task in the backlog on the Team Overview page and drag to users (shown).</li>
          <li>Assign a task as soon as you create it.</li>
          <li>Drag a task to another user on the Team Overview page.</li>
        </ol>
      </div>),
    disableBeacon: true,
    placement: 'center',
    spotlightClicks: false,
    manager: true,
  },
];

export default initialSteps;
