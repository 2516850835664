import styled from 'styled-components';

const Box = styled.div`
position: relative;
width: 100%;
background-color: ${(props) => props.backgroundColor};
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
box-shadow: ${(props) => `0px 0px 0px 1px ${props.borderColor}`};

  ${(props) => (props.arrowSide ? (`
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: ${props.topOffset}px;
      left: ${props.leftOffset}px;
      box-sizing: border-box;
      border: 20px solid ${props.backgroundColor};
      border-color: transparent transparent ${props.backgroundColor} ${props.backgroundColor};
      border-radius: 8px;
      -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(-45deg) ${props.transform || ''};
      -ms-transform: rotate(-45deg) ${props.transform || ''};
      transform: rotate(-45deg) ${props.transform || ''};
      box-shadow: -1px 1px 0px 0 ${props.borderColor};
    }
  `
  ) : null)}
`;

export default Box;
