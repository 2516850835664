import {
  SET_SHIPPED_ITEM,
} from 'store/actionTypes/shippedItems';

const set = (payload) => ({
  type: SET_SHIPPED_ITEM,
  payload,
});

export default set;
