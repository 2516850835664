import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

const withPropertyRequirementData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      propertyRequirementId,
    } = ownProps;

    const propertyRequirement = selectors.propertyRequirements.byId(state, propertyRequirementId);

    return {
      propertyRequirement,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (fieldName) => (value) => {
      dispatch(updatePropertyRequirement(ownProps.propertyRequirementId)({ [fieldName]: value }));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withPropertyRequirementData;
