import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Button from 'ui/Button';
import Icon from 'ui/Icon';

import CallsContext from 'sections/Calls/context/CallsContext';
import MicrophoneAvailabilityIndicator from 'sections/Calls/modules/MicrophoneAvailabilityIndicator';
import DraggableWrapper from 'shared/components/OA/DraggableWrapper';

import usePrevious from 'shared/hooks/usePrevious';

import CallViews from './CallViews';

import {
  ModalRoot,
  InnerWrapper,
  ContentWrapper,
  TitleWrapper,
  Divider,
  DragSectionWrapper,
  MicInfoWrapper,
} from './styledItems';

const callStatusToBackgroundImage = {
  calling: 'linear-gradient(to bottom, #9f59f9, #1b233a)',
  'in call': 'linear-gradient(to bottom, #9f59f9, #1b233a)',
  idle: 'linear-gradient(to bottom, #367bed, #1b233a)',
};

const getBackground = (callStatus, recordingStatus) => {
  if (recordingStatus === 'in-progress') {
    return 'linear-gradient(to bottom, #e45d52, #1b233a)';
  }
  return callStatusToBackgroundImage[callStatus];
};

const DragSection = ({ dragSectionPlacement }) => ( // eslint-disable-line react/prop-types
  <DragSectionWrapper dragSectionPlacement={dragSectionPlacement}>
    <Icon
      name="move"
      color="contrast"
      size="lg"
    />
    {
      dragSectionPlacement === 'left' && <Divider />
    }
  </DragSectionWrapper>
);

const Call = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    contactId,
  } = props;

  if (!isVisible) {
    return null;
  }

  const [callEnded, setCallEnded] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const {
    callStatus,
    recordingStatus,
    hangUp,
    isMicrophoneAvailable,
    setCallTo,
  } = useContext(CallsContext);

  useEffect(() => {
    setSelectedContact(contactId);
  }, [
    contactId,
  ]);

  useEffect(() => {
    if (!selectedContact) {
      setCallTo('');
    }
  }, [
    selectedContact,
  ]);

  const prevCallStatus = usePrevious(callStatus);

  useEffect(() => {
    if (prevCallStatus === 'in call' && callStatus !== 'in call') {
      setCallEnded(true);
    }
  });

  const onCancel = useCallback(() => {
    hangUp();
    closeModal();
  }, [
    hangUp,
    closeModal,
  ]);

  const getTitle = useCallback(() => {
    if (callEnded) {
      return 'Call Ended';
    }
    if (callStatus === 'idle') {
      return 'Make a call';
    }
    if (callStatus === 'in call') {
      return 'Call in progress….';
    }
    return null;
  }, [
    callEnded,
    callStatus,
  ]);

  const getDragSectionPlacement = useCallback(() => {
    if (callEnded) {
      return 'left';
    }
    return 'bottom';
  }, [
    callEnded,
    callStatus,
  ]);

  const title = getTitle();
  const dragSectionPlacement = getDragSectionPlacement();

  const showCloseIconButton = !callEnded && callStatus === 'idle';

  return (
    <DraggableWrapper>
      <ModalRoot
        theme={theme}
        backgroundImage={getBackground(callStatus, recordingStatus)}
      >
        <InnerWrapper
          dragSectionPlacement={dragSectionPlacement}
        >
          <DragSection
            dragSectionPlacement={dragSectionPlacement}
          />

          <ContentWrapper className="prevent-drag">
            {
              title && (
                <TitleWrapper>
                  <Typography
                    variant="title1"
                    tag="h1"
                    color="contrast"
                    noMargin
                    weight={700}
                  >
                    {title}
                  </Typography>

                  {
                    showCloseIconButton && (
                      <Button
                        variant="tertiary"
                        icon="x"
                        iconColor="contrast"
                        style={{ width: 'auto' }}
                        onClick={onCancel}
                      />
                    )
                  }
                </TitleWrapper>
              )
            }

            <CallViews
              callEnded={callEnded}
              contactId={selectedContact}
              setSelectedContact={setSelectedContact}
              onCancel={onCancel}
            />

            {
              !isMicrophoneAvailable && (
                <MicInfoWrapper>
                  <MicrophoneAvailabilityIndicator />
                </MicInfoWrapper>
              )
            }
          </ContentWrapper>
        </InnerWrapper>
      </ModalRoot>
    </DraggableWrapper>
  );
};

Call.defaultProps = {
  theme: null,
  isVisible: false,
  contactId: null,
};

const {
  bool,
  func,
  shape,
  number,
} = PropTypes;

Call.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
  theme: shape(),
  contactId: number,
};

export default Call;
