const validation = {
  WRONG_EMAIL: 'The input is not valid E-mail!',
  EMAIL_EXIST: 'This email already exists',
  PASSWORD_DIFF: 'Passwords are different!',
  INPUT_VALID: 'The input is not valid!',
  INPUT_DUPLICATE: 'This is a duplicate field!',
  ONLY_NUMBERS: 'Only numbers allowed!',
};

export default validation;
