import { createSelector } from 'reselect';

const employees = (state) => state.myCompany.employees;
const selectedEmployeeIds = (state, ids) => ids;

export default createSelector(
  employees,
  selectedEmployeeIds,
  (items = [], selectedItemIds = []) => {
    const namesList = selectedItemIds.reduce((acc, item) => {
      const employee = items.find((employeeRecord) => employeeRecord.EmployeeID === +item);

      if (!employee) return acc;

      acc.push(`${employee.Name} ${employee.Surname}`);

      return acc;
    }, []);

    return namesList;
  },
);
