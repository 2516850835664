/* eslint-disable camelcase */
import moment from 'moment';

const formatContactTime = (last_contact, next_contact) => {
  const last = last_contact ? moment(last_contact).fromNow() : '—';
  const next = next_contact ? moment(next_contact).fromNow() : '—';

  return `${last} / ${next}`;
};

export default formatContactTime;
