import connectReducers from 'store/connectReducers';

import {
  SET_SEARCH_MLS_MODAL_CLOSE,
  SET_SEARCH_MLS_MODAL_OPEN,
  SET_MLS_MODAL_SEARCH_DATA,
} from 'store/actionTypes/realtorOpportunities';

import {
  getIds,
} from 'store/utils';

const initialState = {
  isVisible: false,
  searchedData: [],
};

const reducers = {
  [SET_SEARCH_MLS_MODAL_CLOSE]: (state) => ({
    ...state,
    isVisible: false,

  }),

  [SET_SEARCH_MLS_MODAL_OPEN]: (state, action) => ({
    ...state,
    isVisible: true,
  }),

  [SET_MLS_MODAL_SEARCH_DATA]: (state, action) => {
    const {
      properties,
    } = action.payload;

    const searchedPropertiesIds = new Set([
      ...getIds(properties),
    ]);

    const updatedState = {
      ...state,
      searchedData: [...searchedPropertiesIds],
    };

    return updatedState;
  },
};

function searchMLSModal(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default searchMLSModal;
