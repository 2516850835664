import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import Avatar from './Avatar';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const player = contactId === 0
    ? selectors.users.currentUser(state)
    : selectors.realtorContacts.basicById(state, contactId);

  const avatar = player?.avatar;

  const avatarImageSrc = getAvatarUrl(avatar);

  return {
    src: avatarImageSrc,
  };
};

export default connect(mapStateToProps)(Avatar);
