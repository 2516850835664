import apiConnector, { endpoints } from 'api';

const createArticle = async (articleInformations) => {
  const endpoint = `${endpoints.client.articles.articleGeneral}`;

  const result = await apiConnector.client.post(endpoint, articleInformations);

  return result.data;
};

export default createArticle;
