import { createSelector } from 'reselect';

const contacts = (state) => state.crmContacts.contacts;

export default createSelector(
  contacts,
  (items) => (items
    ? Object.values(items)
      .filter((item) => !!item.Email)
      .map((item) => ({
        id: item.SalesContactsID,
        contactId: item.SalesContactsID,
        title: item.Email,
        email: item.Email,
        name: `${item.FirstName} ${item.LastName}`,
        role: item.Role,
      }))
    : null),
);
