import { connect } from 'react-redux';

// import {
//   closeNewLeadModal,
// } from 'store/actions/newRealtorLeadModal';

import {
  closeModal,
} from 'store/actions/modals';

import {
  createLeadFromNewLeadForm,
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import {
  setNewLeadNestedFormField,
  reset as resetNewLeadForm,
} from 'store/actions/newRealtorLeadForm';

import NewLeadFormSimpleModal from './NewLeadFormSimpleModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      newLeadFormSimple,
    },
    realtorNewLeadForm: {
      mainEmail,
      personFirstName,
      selectedContact,
      fieldsValidators: {
        doesPhoneExist,
        doesEmailExist,
      },
    },
    realtorLeadsFilter: {
      pageNumber,
      pageSize,
    },
  } = state;

  return {
    isVisible: newLeadFormSimple?.isVisible,
    mainEmail,
    personFirstName,
    doesPhoneExist,
    doesEmailExist,
    selectedContact,
    pageNumber,
    pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal('newLeadFormSimple')());
    dispatch(resetNewLeadForm());
  },
  createLead: async () => {
    const id = await dispatch(createLeadFromNewLeadForm());

    if (id) {
      dispatch(resetNewLeadForm());
    }

    return id;
  },
  setNotValidField: (fieldName) => dispatch(setNewLeadNestedFormField('fieldsValidators')(fieldName)(false)),
  getLeads: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    optimizedRealtorLeadsActions.getLeadsForStoreFilter(offset, limit);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLeadFormSimpleModal);
