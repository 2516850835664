import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

import PropertyParametersMain from 'sections/Properties/components/PropertyParametersMain';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    parameters: {
      bedrooms: propertyRequirementData?.bedrooms_min,
      bathrooms: propertyRequirementData?.bathrooms_min,
      floor_area: propertyRequirementData?.floor_area_min,
      plot_area: propertyRequirementData?.plot_area_min,
      parking: propertyRequirementData?.parking_min,
    },
  };
};

const requirementParameters = {
  bedrooms: 'bedrooms_min',
  bathrooms: 'bathrooms_min',
  floor_area: 'floor_area_min',
  plot_area: 'plot_area_min',
  parking: 'parking_min',
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key) => (value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({
      [requirementParameters[key]]: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyParametersMain);
