import {
  SET_PROPERTY_ATTACHMENTS,
} from 'store/actionTypes/properties';

const setPropertyAttachments = (id, attachments) => ({
  type: SET_PROPERTY_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setPropertyAttachments;
