import { connect } from 'react-redux';

import getEmployeeById from 'shared/utils/getEmployeeById';
import getLeadById from 'shared/utils/getLeadById';
import getTicketById from 'shared/utils/getTicketById';

import { get, isEmpty } from 'shared/utility';

import store from 'store';
import { getTaskById } from 'store/actions/tasksV2';
import { getTickets } from 'store/actions/tickets';
import { getLeadsList } from 'store/actions/leads';
import { getEmployees } from 'store/actions/MyCompany';

import ObjectLink from './ObjectLink'; // eslint-disable-line import/no-cycle

const titleField = {
  'customer-service': 'TicketName',
  sales: 'Title',
  tasks: 'TaskName',
};

const mapStateToProps = (state, ownProps) => {
  const {
    leads: {
      leadsList,
    },
    myCompany: {
      employees,
    },
    tasksV2: {
      tasks,
    },
    tickets: {
      ticketsList,
    },
  } = state;

  const {
    url,
  } = ownProps;

  // splice to remove protocol, host etc
  const splitUrl = url.split('/').splice(3);

  // TODO - move these outside mapStateToProps
  const getItem = {
    'customer-service': (itemId) => getTicketById(parseInt(itemId, 10)),
    hr: (itemId) => getEmployeeById(parseInt(itemId, 10)),
    sales: (itemId) => getLeadById(parseInt(itemId, 10)),
    tasks: (itemId) => tasks[parseInt(itemId, 10)],
  };

  const getTitle = (productType, splitUrlArray) => {
    const itemId = splitUrlArray[splitUrlArray.length - 1];
    const item = getItem[productType](itemId);
    const title = get(item, [titleField[productType]], null);

    return title;
  };

  const getName = (splitUrlArray) => {
    const employeeId = splitUrlArray[splitUrlArray.length - 1].split('=')[1];
    const employee = getItem.hr(employeeId);
    const name = `${employee.Name} ${employee.Surname}`;

    return name;
  };
  //

  const [product] = splitUrl;

  // These are to make sure there is the neccessary data for the link
  if (product === 'customer-service' && isEmpty(ticketsList)) {
    store.dispatch(getTickets());
    return {
      product,
      onClick: () => {},
    };
  }

  if (product === 'hr' && isEmpty(employees)) {
    store.dispatch(getEmployees());
    return {
      product,
      onClick: () => {},
    };
  }

  if (product === 'tasks') {
    const id = splitUrl[splitUrl.length - 1];
    if (!tasks[id]) {
      store.dispatch(getTaskById(id));
      return {
        product,
        onClick: () => {},
      };
    }
  }

  if (product === 'sales' && isEmpty(leadsList)) {
    store.dispatch(getLeadsList());
    return {
      product,
      onClick: () => {},
    };
  }
  //

  const title = product === 'hr'
    ? getName(splitUrl)
    : getTitle(product, splitUrl);

  return {
    product,
    title,
    onClick: () => {
      window.open(url);
    },
  };
};

export default connect(mapStateToProps)(ObjectLink);
