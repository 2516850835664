import apiConnector, { endpoints } from 'api';

const remove = async (recordingSid) => {
  const endpoint = `${endpoints.client.calls.callRecordings}/${recordingSid}`;
  try {
    const result = await apiConnector.client.delete(endpoint);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default remove;
