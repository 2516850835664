import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import PreferredLocationInput from './PreferredLocationInput';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      preferredLocation,
    },
  } = state;

  return {
    value: preferredLocation?.address,
  };
};

const mapDispatchToProps = {
  changeAddress: setNewLeadFormField('preferredLocation'),
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferredLocationInput);
