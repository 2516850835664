import requests from 'api/requests';

import getLineItemById from './getLineItemById';

const update = (id) => (updateData) => async (dispatch) => {
  try {
    await requests.lineItems.update(id)(updateData);

    dispatch(getLineItemById(id));
  } catch (error) {
    console.error(error);
  }
};

export default update;
