import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Icon,
  SpecialConditionsItemWrapper,
} from './styledItems';

const SpecialConditionsItem = (props) => {
  const {
    value,
  } = props;

  return (
    <SpecialConditionsItemWrapper>
      <Icon
        icon="circle"
      />
      <Typography
        variant="body"
        className="item-value-text"
        noMargin
      >
        {value}
      </Typography>
    </SpecialConditionsItemWrapper>
  );
};

const {
  shape,
  string,
  bool,
} = PropTypes;

SpecialConditionsItem.propTypes = {
  value: shape({
    value: string,
    isSelected: bool,
  }).isRequired,
};

export default SpecialConditionsItem;
