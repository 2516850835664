import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateEmail,
  createEmail,
} from 'store/actions/emails';

import {
  addContactRelations,
  removeContactRelations,
} from 'store/actions/realtorContacts';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';
import validate from 'shared/utils/validators';

import MultipleValuesInput from 'ui/MultipleValuesInput';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contactEmails = selectors.realtorContacts.contactEmails(state, contactId);

  const values = contactEmails.map((item) => ({
    id: item.email_id,
    value: item.email,
    isPrimary: !!item.is_primary,
  }));

  return {
    values,
    dataTypeDisplayName: 'email',
    placeholder: 'Email',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: debounce((id, value) => {
    if (value && typeof value === 'string' && validate.email(value)) {
      dispatch(updateEmail(id)({ email: value }));
    }
  }, 1000),
  onMakePrimary: async (emailId, setIsLoading) => {
    setIsLoading(true);

    try {
      await dispatch(addContactRelations('emails')(ownProps.contactId)({
        relationActionType: 'makeEmailPrimary',
        emailId,
      }));

      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }

    setIsLoading(false);
  },
  onRemove: async (emailId, setIsLoading) => {
    setIsLoading(true);

    try {
      await dispatch(removeContactRelations('emails')(ownProps.contactId)({ emailId }));

      callAlert.success('Updated successfully!');
    } catch (err) {
      callAlert.error('Could not update!');
    }

    setIsLoading(false);
  },
  onCreate: async (value, setIsLoading) => {
    if (value && typeof value === 'string' && validate.email(value)) {
      setIsLoading(true);

      try {
        const emailId = await dispatch(createEmail(value));

        if (emailId) {
          await dispatch(addContactRelations('emails')(ownProps.contactId)({
            relationActionType: 'addOneEmail',
            emailId,
          }));
        }

        callAlert.success('Updated successfully!');
      } catch (err) {
        callAlert.error('Could not update!');
      }

      setIsLoading(false);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleValuesInput);
