import connectReducers from 'store/connectReducers';
import moment from 'moment';
import { common } from 'constants';
import {
  SET_TOP_SECTION_TIME_PERIOD,
  SET_BOTTOM_SECTION_TIME_PERIOD,
  SET_BACKLOG_ORDER,
  SET_TOTAL_WEEKS,
  SET_DISPLAYED_COMPLETED_SECTION_WEEKS,
  CLEAR_DISPLAYED_WEEKS,
} from 'store/actionTypes/taskPlanner';

const initialState = {
  selectedTimePeriodTop: {
    startDate: moment().startOf('isoWeek').format(common.DATE.V2),
    endDate: moment().endOf('isoWeek').format(common.DATE.V2),
    period: `${moment().startOf('isoWeek').format(common.DATE.V8)} - ${moment().endOf('isoWeek')
      .format(common.DATE.V8)}`,
  },
  selectedTimePeriodBottom: {
    startDate: moment().add(1, 'weeks').startOf('isoWeek').format(common.DATE.V2),
    endDate: moment().add(1, 'weeks').endOf('isoWeek').format(common.DATE.V2),
    period: `${moment().add(1, 'weeks').startOf('isoWeek')
      .format(common.DATE.V8)} - ${moment().add(1, 'weeks').endOf('isoWeek').format(common.DATE.V8)}`,
  },
  backlogOrder: 'None',
  totalWeeksForCompletedSection: null,
  displayedCompletedSectionWeeks: [],
};

const reducers = {
  [SET_TOP_SECTION_TIME_PERIOD]: (state, action) => ({
    ...state,
    selectedTimePeriodTop: action.payload.period,
  }),
  [SET_BOTTOM_SECTION_TIME_PERIOD]: (state, action) => ({
    ...state,
    selectedTimePeriodBottom: action.payload.period,
  }),
  [SET_BACKLOG_ORDER]: (state, action) => ({
    ...state,
    backlogOrder: action.payload,
  }),
  [SET_TOTAL_WEEKS]: (state, action) => ({
    ...state,
    totalWeeksForCompletedSection: action.payload,
  }),
  [SET_DISPLAYED_COMPLETED_SECTION_WEEKS]: (state, action) => ({
    ...state,
    displayedCompletedSectionWeeks: state.displayedCompletedSectionWeeks.concat(action.payload),
  }),
  [CLEAR_DISPLAYED_WEEKS]: (state) => ({
    ...state,
    displayedCompletedSectionWeeks: [],
  }),
};

const taskPlanner = (state = initialState, action) => connectReducers(reducers, state, action);

export default taskPlanner;
