import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  display: flex;
  border-radius: 6px;
  background-color:  ${({ theme }) => theme?.palette?.['background-flat'] || 'rgb(242, 246, 254)'};
  justify-content: space-between;
  height: 29px;
  width: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FullHeightContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 3px;
`;

export const CenteredWrapper = styled.div`
  margin: auto 0 auto 0;
`;

export default {
  DescriptionContainer,
  FullHeightContainer,
  CenteredWrapper,
  FlexContainer,
};
