import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

// eslint-disable-next-line consistent-return
const share = (offerId) => (emails = []) => async (dispatch) => {
  try {
    const response = await requests.realtor.offers.share(offerId, emails);

    callAlert.success('Emails have been sent!');

    return response.data;
  } catch (error) {
    console.error(error);
    callAlert.error('Some error occured. Could not send emails.');
  }
};

export default share;
