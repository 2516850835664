import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SelectContactArea from 'shared/inputs/MultiContactsSelectWithRHF';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const contactList = selectors.realtorTransactions.relatedContacts(transactionId)(state);

  return {
    contactList,
  };
};

export default connect(mapStateToProps, null)(SelectContactArea);
