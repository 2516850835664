import getContactChangelogAllTime from './getContactChangelogAllTime';

const getSelectedContactChangelogAllTime = () => (dispatch, getState) => {
  const {
    crmContacts: {
      selectedContact,
    },
  } = getState();

  try {
    dispatch(getContactChangelogAllTime(selectedContact));
  } catch (error) {
    console.error(error);
  }
};

export default getSelectedContactChangelogAllTime;
