import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import { // eslint-disable-line import/no-cycle
  optimizedProspectActions,
} from 'store/actions/realtorProspects';

export default createSelector(
  (state) => state.realtorProspects.prospects,
  (state) => state.propertyViewings.viewings,
  (_, id) => id,
  (
    prospects,
    viewings,
    prospectId,
  ) => {
    const prospect = prospects[prospectId];

    if (!prospect) {
      if (prospectId) {
        optimizedProspectActions.getProspectById(prospectId);
      }

      return {
        events: [],
        numOfFutureEvents: 0,
      };
    }

    const events = Array.isArray(prospect.viewings) && prospect.viewings.reduce((accum, viewingId, index) => {
      const viewing = viewings[viewingId];

      if (!viewing) {
        if (viewingId) {
          optimizedPropertyViewingActions.getPropertyViewingById(viewingId);
        }

        return accum;
      }

      accum.push({
        key: index + 1,
        ...viewing,
      });
      return accum;
    }, []);

    const numOfFutureEvents = prospect.viewings_summary?.upcoming_viewings || 0;

    return {
      events: events || [],
      numOfFutureEvents,
    };
  },
);
