import { connect } from 'react-redux';

import {
  remove,
  archive,
} from 'sections/Contacts/actions';

import OptionsCell from './OptionsCell';

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    contactId,
  } = ownProps;

  return {
    remove: () => {
      remove(contactId);
    },

    archive: () => {
      archive(contactId);
    },
  };
};

export default connect(null, mapDispatchToProps)(OptionsCell);
