import styled from 'styled-components';

import Elevation from 'ui/Elevation';

// eslint-disable-next-line import/prefer-default-export
export const StyledElevation = styled(Elevation)`
  background-color: ${({ theme }) => (theme?.palette.background || 'white')};
  border-radius: 10px;
  padding: 11px 8px;
`;
