/**
 *
 * @param {[]} list
 * @param {string} by
 * @returns
 */
const listToObjectByKey = (list = [], by = 'id') => list.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item[by]] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

export default listToObjectByKey;
