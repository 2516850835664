import { connect } from 'react-redux';
import selectors from 'store/selectors';

import {
  closeModal,
} from 'store/actions/modals';

import PropertyDetails from 'shared/components/PropertyDetails';

const contactSelector = {
  'client': selectors.realtorClients,
  'prospect': selectors.realtorProspects,
}

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
    propertyId,
    contactType,
  } = ownProps;

  const contact = contactSelector[contactType].byId(state, contactId);
  const property = selectors.properties.byId(state, propertyId);
  const address = selectors.addresses.byId(state, property?.address_id);
  const {
    first_name,
    last_name,
  } = selectors.realtorContacts.byId(state, contact?.contact_id);

  return {
    propertyId: contact?.realtor_property_id,
    contactId,
    contactType,
    address: address?.address,
    price: property?.expected_price,
    name: first_name,
    lastName: last_name,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLinkClick: () => dispatch(closeModal('acceptOfferModal')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
