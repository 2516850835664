import styled from 'styled-components';

import HouseDefault from 'assets/icons/property.svg';

export const Root = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme?.palette?.border || 'gray'};
  width: 100%;
  padding: 8px 8px;
`;

export const PropertyPhoto = styled.div`
  flex-shrink: 0;
  border-radius: 6px;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
`;

export default {
  Root,
  PropertyPhoto,
};
