import { connect } from 'react-redux';

import {
  closeModal,
} from 'store/actions/modals';

import AddendumChangesModal from './AddendumChangesModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      addendumChanges,
    },
  } = state;

  return {
    isVisible: addendumChanges?.isVisible,
    contentOptions: addendumChanges?.contentOptions || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('addendumChanges')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddendumChangesModal);
