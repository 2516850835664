import React from 'react';

// Add a dictionary of block tags.
const BLOCK_TAGS = {
  p: 'paragraph',
  blockquote: 'quote',
  li: 'list-item',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  h1: 'heading-one',
  h2: 'heading-two',
  span: 'align-center',
};

// Add a dictionary of mark tags.
const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underline',
  s: 'strike',
  mark: 'mark',
};

// TODO - improve consistent-return
/*
Disabled the consistent return rule in "rules" object:
- Default value (return '' or return) is breaks the editor.
*/
// rules for html serialize/deserialize
const rules = [
  {
    // eslint-disable-next-line consistent-return
    deserialize(element, next) {
      const isLink = element.tagName.toLowerCase() === 'a';

      const isObjectLink = isLink && element.className === 'objectLink';

      if (isObjectLink) {
        return {
          object: 'inline',
          type: 'objectLink',
          nodes: next(element.childNodes),
          data: {
            link: element.getAttribute('href'),
          },
        };
      }
    },
    // eslint-disable-next-line consistent-return
    serialize(obj, children) {
      if (obj.type === 'objectLink') {
        const link = obj.data.get('link');
        return (
          <a href={link} className="objectLink">
            {link}
          </a>
        );
      }
    },
  },
  {
    // Switch deserialize to handle more blocks...
    // eslint-disable-next-line consistent-return
    deserialize(el, next) {
      let type = BLOCK_TAGS[el.tagName.toLowerCase()];

      let className = '';

      if (el.getAttribute) {
        className = el.getAttribute('class');
      }

      if (className === 't-center-h') {
        type = 'align-center/h';
      }
      if (className === 't-right-h') {
        type = 'align-right/h';
      }
      if (className === 't-center') {
        type = 'align-center';
      }

      if (type) {
        return {
          object: 'block',
          type,
          data: {
            className: el.getAttribute('class'),
            style: el.getAttribute('style'),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    // Switch serialize to handle more blocks...
    // eslint-disable-next-line consistent-return
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph':
            return <p className={obj.data.get('className')}>{children}</p>;
          case 'quote':
            return <blockquote>{children}</blockquote>;
          case 'block-quote':
            return <blockquote>{children}</blockquote>;
          case 'bulleted-list':
            return <ul>{children}</ul>;
          case 'heading-one':
            return <h1>{children}</h1>;
          case 'heading-two':
            return <h2>{children}</h2>;
          case 'list-item':
            return <li>{children}</li>;
          case 'numbered-list':
            return <ol>{children}</ol>;
          case 'align-left':
            return <p style={{ textAlign: 'left' }} className="t-left">{children}</p>;
          case 'align-center':
            return <p style={{ textAlign: 'center' }} className="t-center">{children}</p>;
          case 'align-justify':
            return <p style={{ textAlign: 'justify' }} className="t-justify">{children}</p>;
          case 'align-right':
            return <p style={{ textAlign: 'right' }} className="t-right">{children}</p>;
          case 'align-center/h':
            return <h1 style={{ textAlign: 'center' }} className="t-center-h">{children}</h1>;
          case 'align-right/h':
            return <h1 style={{ textAlign: 'right' }} className="t-right-h">{children}</h1>;
          default:
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    // eslint-disable-next-line consistent-return
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'mark',
          type,
          nodes: next(el.childNodes),
        };
      }
    },
    // eslint-disable-next-line consistent-return
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>;
          case 'italic':
            return <em>{children}</em>;
          case 'underline':
            return <u>{children}</u>;
          case 'code':
            return <code>{children}</code>;
          case 'mark':
            return <mark>{children}</mark>;
          case 'strike':
            return <s>{children}</s>;
          case 'underlined':
            return <u>{children}</u>;
          case 'highlight':
            return (
              <span style={{ backgroundColor: '#ffeeba' }}>
                {children}
              </span>
            );
          default:
        }
      }
    },
  },
  // Special case for images, to grab their src.
  {
    // eslint-disable-next-line consistent-return
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'img') {
        return {
          object: 'block',
          type: 'image',
          nodes: next(el.childNodes),
          data: {
            src: el.getAttribute('src'),
            loc: el.getAttribute('data-loc'),
          },
        };
      }
    },
    // eslint-disable-next-line consistent-return
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'image': {
            const { data } = obj;
            const src = data.get('src');
            const loc = data.get('loc');
            return <img src={src} data-loc={loc} style={{ maxWidth: '100%' }} alt="article-img" />;
          }
          default:
        }
      }
    },
  },
  // Special case for videos, to grab their src.
  {
    // eslint-disable-next-line consistent-return
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'video') {
        return {
          object: 'block',
          type: 'video',
          nodes: next(el.childNodes),
          data: {
            src: el.childNodes[0].getAttribute('src'),
            loc: el.getAttribute('data-loc'),
            type: el.childNodes[0].getAttribute('type'),
          },
        };
      }
    },
    // eslint-disable-next-line consistent-return
    serialize(obj, children) {
      if (obj.type === 'video') {
        const { data } = obj;
        const src = data.get('src');
        const loc = data.get('loc');
        const type = data.get('type');
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video data-loc={loc}>
            <source src={src} type={type} />
          </video>
        );
      }
    },
  },
  // Special case for links, to grab their href.
  {
    // eslint-disable-next-line consistent-return
    deserialize(el, next) {
      const isClassLink = el.className === 'link';
      if (el.tagName.toLowerCase() === 'a' && isClassLink) {
        return {
          object: 'inline',
          type: 'link',
          nodes: next(el.childNodes),
          data: {
            link: el.getAttribute('href'),
          },
        };
      }
    },
    // eslint-disable-next-line consistent-return
    serialize(obj, children) {
      if (obj.type === 'link') {
        const link = obj.data.get('link');
        const thumbnailInf = obj.data.get('thumbnailInf');
        if (!thumbnailInf) {
          return (
            <a
              href={link}
              isNoThumbnails="true"
              className="link"
            >
              {link}
            </a>
          );
        }
        return (
          <a
            href={link}
            siteName={thumbnailInf.OG.site_name}
            iconSrc={thumbnailInf.favicon.src}
            title={thumbnailInf.OG.title}
            className="link"
          >
            {link}
          </a>
        );
      }
    },
  },
];

export default rules;
