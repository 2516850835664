import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import ContactPhoneNumbers from 'sections/Contacts/modules/ContactPhoneNumbers';
import ContactEmails from 'sections/Contacts/modules/ContactEmails';

import useContactName from 'shared/hooks/contacts/useContactName';

import { useHistory } from 'react-router-dom';

import { CONTACT_RELATION_STATUSES } from 'constants/realtor.constants';

import Options from './Options';

import {
  Wrapper,
} from './styledItems';

const ContactCard = (props) => {
  const {
    contactId,
    relationStatusId,
    onRemove,
  } = props;

  const contactName = useContactName(contactId);
  const history = useHistory();

  const openProfile = useCallback(() => {
    history.push(`/contacts/${contactId}`);
  }, [
    contactId,
    history,
  ]);

  return (
    <Wrapper>
      <Flex
        alignItems="center"
        fullWidth
        spacing={1}
      >
        <FlexItem>
          <ContactAvatar
            contactId={contactId}
            size="sm"
            onSave={null}
          />
        </FlexItem>

        <FlexItem flex="1 0">
          <Typography
            variant="title3"
            weight={500}
            noMargin
            style={{ marginBottom: 2 }}
          >
            {contactName}
            {
              relationStatusId && (
                <span style={{ textTransform: 'capitalize' }}>
                  {` | ${CONTACT_RELATION_STATUSES[relationStatusId]}`}
                </span>
              )
            }
          </Typography>

          <Flex
            fullWidth
            spacing={2}
          >
            <FlexItem>
              <DecoratedFieldWrapper
                iconName="mail"
                iconType="feather"
                size="sm"
                variant="focus"
              >
                <ContactEmails
                  contactId={contactId}
                  withEditMode={false}
                  bareEllipsisStyles
                />
              </DecoratedFieldWrapper>
            </FlexItem>

            <FlexItem>
              <DecoratedFieldWrapper
                iconName="phone"
                iconType="feather"
                variant="focus"
                size="sm"
              >
                <ContactPhoneNumbers
                  contactId={contactId}
                  withEditMode={false}
                  bareEllipsisStyles
                />
              </DecoratedFieldWrapper>
            </FlexItem>
          </Flex>
        </FlexItem>

        <FlexItem>
          <Options
            contactId={contactId}
            remove={onRemove}
            openProfile={openProfile}
          />
        </FlexItem>

      </Flex>
    </Wrapper>
  );
};

ContactCard.defaultProps = {
  relationStatusId: null,
};

const {
  number,
  func,
} = PropTypes;

ContactCard.propTypes = {
  contactId: number.isRequired,
  relationStatusId: number,
  onRemove: func.isRequired,
};

export default ContactCard;
