import React from 'react';
import PropTypes from 'prop-types';

import useContact from 'sections/Contacts/hooks/useContact';
import useContactDefaultEmail from 'shared/hooks/contacts/useContactDefaultEmail';

import Chip from 'ui/Chip';

const ContactEmailChip = (props) => {
  const {
    item,
    onDelete,
  } = props;

  const contact = useContact(item);
  const email = useContactDefaultEmail(item);

  if (!contact?.id) {
    return (
      <Chip
        onDelete={onDelete}
        variant="subtle"
      >
        {item}
      </Chip>
    );
  }

  return (
    <Chip
      onDelete={onDelete}
      variant="subtle"
    >
      {`${contact?.fullName} | ${email}`}
    </Chip>
  );
};

const {
  func,
  number,
} = PropTypes;

ContactEmailChip.propTypes = {
  contactId: number.isRequired,
  onDelete: func.isRequired,
};

export default ContactEmailChip;
