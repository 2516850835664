import { createSelector } from 'reselect';

const offices = (state) => state.myCompany.offices;

export default createSelector(
  offices,
  (items) => (items
    ? items.map((item) => ({
      id: item.OfficeID,
      name: item.OfficeName,
    }))
    : []),
);
