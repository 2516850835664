import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';

import SocialIconModal from 'shared/components/SocialIconModal';

import useIntegrations from 'sections/Integrations/hooks/useIntegrations';

// import Email from 'sections/Contacts/modules/SocialLinks/Email';
// import Call from 'sections/Contacts/modules/SocialLinks/Call';

// import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import {
  Root,
  LinkWrapper,
} from './styledItems';

const linkTypeToLinkName = {
  facebook: 'facebook_url',
  twitter: 'twitter_url',
  linkedin: 'linkedin_url',
  instagram: 'instagram_url',
  website: 'website_url',
};

const defaultLinkObject = {
  type: null,
  url: null,
};

const SocialLinks = (props) => {
  const {
    firstName,
    links,
    updateLink,
    // contactId,
    // hasPhoneNumbers,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chosenLink, setChosenLink] = useState(defaultLinkObject);

  const openModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setChosenLink(defaultLinkObject);
  }, []);

  const onClickEdit = useCallback((type, url) => () => {
    setChosenLink({ type, url });
    openModal();
  }, []);

  const handleSubmit = useCallback((type) => (value) => {
    updateLink(linkTypeToLinkName[type], value);
    closeModal();
  }, [
    updateLink,
  ]);

  const {
    integrations,
  } = useIntegrations();

  return (
    <>
      <Root
        spacing={1}
        alignItems="center"
      >
        {
          links.map((item) => {
            const {
              name,
              url,
              icon,
            } = item;

            return (
              <LinkWrapper onClick={onClickEdit(name, url)}>
                <Icon
                  name={icon}
                  color={url ? 'main-text' : 'placeholder'}
                  size="l"
                />
              </LinkWrapper>
            );
          })
        }
        {/* {
          (!isSectionUnderConstruction('integrations') && integrations?.length > 0) && (
            <Email contactId={contactId} />
          )
        }
        {
          (!isSectionUnderConstruction('calls') && hasPhoneNumbers) && (
            <Call contactId={contactId} />
          )
        } */}
      </Root>

      <SocialIconModal
        visible={isModalVisible}
        title={`Enter ${firstName}'s ${chosenLink.type}`}
        type={chosenLink.type}
        value={chosenLink.url}
        handleClose={closeModal}
        handleSubmit={handleSubmit(chosenLink.type)}
      />
    </>
  );
};

SocialLinks.defaultProps = {
  firstName: '',
  links: [
    {
      name: 'facebook',
      url: null,
      icon: 'facebook',
    },
    {
      name: 'twitter',
      url: null,
      icon: 'twitter',
    },
    {
      name: 'linkedin',
      url: null,
      icon: 'linkedin',
    },
    {
      name: 'instagram',
      url: null,
      icon: 'instagram',
    },
    {
      name: 'website',
      url: null,
      icon: 'globe',
    },
  ],
  hasPhoneNumbers: false,
};

const {
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
  bool,
} = PropTypes;

SocialLinks.propTypes = {
  contactId: number.isRequired,
  firstName: string,
  links: arrayOf(shape({
    name: string,
    url: string,
    icon: oneOfType([
      arrayOf(string),
      string,
    ]),
  })),
  updateLink: func.isRequired,
  hasPhoneNumbers: bool,
};

export default SocialLinks;
