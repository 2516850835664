import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useViewing = (viewingId) => {
  const viewing = useReselect(selectors.propertyViewings.byId, viewingId);

  return viewing;
};

export default useViewing;
