import { createSelector } from 'reselect';

import {
  optimizedRealtorExtensionRequestActions,
} from 'store/actions/realtorExtensionRequests';

export default (requestId) => createSelector(
  (state) => state.realtorExtensionRequests.extensionRequests,
  (collection) => {
    const item = collection[requestId];

    if (!item) {
      if (requestId) {
        optimizedRealtorExtensionRequestActions.getById(requestId);
      }

      return null;
    }

    return item;
  },
);
