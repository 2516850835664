import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const List = (props) => {
  const {
    className,
    children,
  } = props;

  const list = (
    <ul className={classNames('todos-list', 'todos__list', className)}>
      {children}
    </ul>
  );

  const noItems = <p className="todos__no-items">No items added.</p>;

  const result = Children.count(children) > 0 ? list : noItems;

  return result;
};

List.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default List;
