import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledButton,
} from './styledItems';

const CreateContactButton = (props) => {
  const {
    onClick,
    disabled,
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      type="default"
      disabled={disabled}
    >
      Create contact
    </StyledButton>
  );
};

const {
  func,
  bool,
} = PropTypes;

CreateContactButton.propTypes = {
  onClick: func.isRequired,
  disabled: bool.isRequired,
};

export default CreateContactButton;
