/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createClient from './createClient';
import getClientById from './getClientById';
import getClients from './getClients';
import getClientsForFilter from './getClientsForFilter';
import getClientsForStoreFilter from './getClientsForStoreFilter';
import getClientsOverallSummary from './getClientsOverallSummary';

import setSelectedClient from './setSelectedClient';
import setSelectedView from './setSelectedView';
import updateClient from './updateClient';
import updateSelectedClient from './updateSelectedClient';
import deleteClient from './deleteClient';
import deleteSelectedClient from './deleteSelectedClient';

export {
  createClient,
  getClientById,
  getClients,
  getClientsForFilter,
  getClientsForStoreFilter,
  setSelectedClient,
  setSelectedView,
  updateClient,
  updateSelectedClient,
  deleteClient,
  deleteSelectedClient,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedClientActions = {
  getClientById: memoizeDebounce(
    (id) => store.dispatch(getClientById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getClientsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getClientsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getClientsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getClientsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getClientsOverallSummary: memoizeDebounce(
    () => store.dispatch(getClientsOverallSummary()),
    debounceDelay,
    debounceOptions,
  ),
};
