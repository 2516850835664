import requests from 'api/requests';

import setTransaction from './setTransaction';

const getTransactionById = (transactionId) => async (dispatch) => {
  try {
    const response = await requests.realtor.transactions.getById(transactionId);
    dispatch(setTransaction(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getTransactionById;
