/* eslint-disable react/require-default-props */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import Button from 'oa/components/Button/index';
import EDITOR_CONFIG from '../editorConfig';

const Notes = (props) => {
  const {
    meetingNotes,
    changeMeetingNotes,
  } = props;

  const [isEditing, setIsEditing] = useState(!meetingNotes);
  const [draftMeetingNotes, setDraftMeetingNotes] = useState(meetingNotes);

  useEffect(() => {
    setDraftMeetingNotes(meetingNotes);
  }, [meetingNotes]);

  const handleSaveClick = useCallback(() => {
    changeMeetingNotes(draftMeetingNotes);
    setIsEditing(false);
  }, [changeMeetingNotes, draftMeetingNotes]);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleCancelClick = useCallback(() => {
    setDraftMeetingNotes(meetingNotes);
    setIsEditing(false);
  }, [meetingNotes]);

  return (
    <div className="meeting-agenda-modal__notes meeting-edit-notes">
      <div className="meeting-edit-notes__header">
        <h3 className="meeting-agenda-modal__secondary-header meeting-edit-notes__title">
          Notes
        </h3>
        <p className="meeting-edit-notes__action-list">
          {isEditing && (
            <Button
              className="meeting-edit-notes__action"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          )}
          {isEditing && meetingNotes && (
            <Button
              type="tertiary_default"
              className="meeting-edit-notes__action"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          )}
          {!isEditing && (
            <Button
              className="meeting-edit-notes__action"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </p>
      </div>

      {
        isEditing
          ? (
            <ReactQuill
              className="meeting-edit-notes__editor"
              theme="snow"
              formats={EDITOR_CONFIG.formats}
              modules={EDITOR_CONFIG.modules}
              value={draftMeetingNotes}
              onChange={setDraftMeetingNotes}
            />
          ) : (
            <div
              className="meeting-edit-notes__inner"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: meetingNotes }}
            />
          )
      }
    </div>
  );
};

Notes.propTypes = {
  meetingNotes: PropTypes.string,
  changeMeetingNotes: PropTypes.func.isRequired,
};

export default Notes;
