import React from 'react';
import PropTypes from 'prop-types';

import {
 Wrapper, BadgeContainer, NotificationBadge, StatusBadge,
} from './styledItems';

const Badge = (props) => {
  const {
    content,
    position,
    color,
    children,
    keepVisible,
    maxNumber,
    variant,
  } = props;

  let badgeContent = content;
  const visible = content ? true : !!keepVisible;

  if (maxNumber) {
    if (typeof content === 'number' && content > maxNumber) {
      badgeContent = `${maxNumber}+`;
    }
  }

  return (
    <Wrapper>
      <BadgeContainer position={position}>
        {
          variant === 'status'
          ? (
            <StatusBadge color={color} visible />
            )
          : (
            <NotificationBadge color={color} visible={visible}>
              { badgeContent || null }
            </NotificationBadge>
            )
        }
      </BadgeContainer>
      {children}
    </Wrapper>
  );
};

Badge.defaultProps = {
  color: 'red',
  content: null,
  position: 'top right',
  keepVisible: false,
  maxNumber: null,
  variant: 'notification',
};

const {
  number,
  string,
  bool,
  element,
  oneOfType,
} = PropTypes;

Badge.propTypes = {
  color: string,
  content: oneOfType([number, element]),
  children: element.isRequired,
  position: string,
  keepVisible: bool,
  maxNumber: number,
  variant: string,
};

export default Badge;
