import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Typography from 'ui/Typography';

import {
  Root,
  Controls,
  StyledButton,
} from './styledItems';

const ContingencyItem = (props) => {
  const {
    onChange,
    value,
    label,
  } = props;

  const handleClick = useCallback((newValue) => (event) => {
    onChange(newValue);
  }, [
    onChange,
  ]);

  return (
    <Root>
      <Typography noMargin>
        {label}
      </Typography>

      <Controls>
        <StyledButton
          onClick={handleClick(true)}
          color={value ? '#2ecc71' : undefined}
          type="button"
        >
          <FontAwesomeIcon
            icon="check"
          />
        </StyledButton>

        <StyledButton
          onClick={handleClick(false)}
          color={!value ? '#ff526b' : undefined}
          type="button"
        >
          <FontAwesomeIcon
            icon="times"
          />
        </StyledButton>
      </Controls>
    </Root>
  );
};

const {
  bool,
  func,
  string,
} = PropTypes;

ContingencyItem.propTypes = {
  onChange: func.isRequired,
  value: bool.isRequired,
  label: string.isRequired,
};

export default ContingencyItem;
