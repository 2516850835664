import { connect } from 'react-redux';

import stableStringify from 'json-stable-stringify';

import selectors from 'store/selectors';

import {
  optimizedRealtorRepairRequestsActions,
} from 'store/actions/realtorRepairRequests';

import RepairRequestsTable from './RepairRequestsTable';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const {
    realtorRepairRequests: {
      repairRequestsByFilterCount,
    },
  } = state;

  if (!transactionId) {
    return {
      itemsCount: 0,
      repairRequestsList: [],
    };
  }

  const filterId = stableStringify({
    transactionId,
  });

  const repairRequestsList = selectors.realtorRepairRequests.repairRequestsList(state, filterId);

  return {
    itemsCount: repairRequestsByFilterCount[filterId],
    repairRequestsList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getRepairRequests: (page, pageSize) => {
    const {
      transactionId,
    } = ownProps;

    if (!transactionId) {
      return;
    }

    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    const filterId = stableStringify({
      transactionId,
    });

    optimizedRealtorRepairRequestsActions
      .getRepairRequestsForFilter(filterId, { transactionId }, offset, limit);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairRequestsTable);
