/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OurModal from 'ui/Modal';

import ModalContext from '../ModalContext';

const Modal = (props) => {
  const {
    isOpen,
    onClose,
    className,
    overlayClassName,
    children,
    size,
  } = props;

  const childrenProps = {
    isOpen,
    onClose,
  };

  return (
    <OurModal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      className={classNames(
        'modal-window__inner',
        `modal-window__inner_size_${size}`,
        className,
      )}
      overlayClassName={classNames('modal-window', overlayClassName)}
    >
      <ModalContext.Provider value={childrenProps}>
        {children}
      </ModalContext.Provider>
    </OurModal>
  );
};

Modal.defaultProps = {
  size: 'md',
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default Modal;
