import { callAlert } from 'ui/Alert';
import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle

import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

export const SET_TEAM_LIST = 'SET_TEAM_LIST';
export const SET_CREATED_TEAM_STATE = 'SET_CREATED_TEAM_STATE';
export const SET_ASSIGN_MODAL_ROLE = 'SET_ASSIGN_MODAL_ROLE';

export const setCreatedTeamState = (variant, payload) => async (dispatch) => {
  dispatch({
    type: SET_CREATED_TEAM_STATE,
    result: { payload, variant },
  });
};

export const setAssignModalRole = (roleType) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_MODAL_ROLE,
    roleType,
  });
};

export const getTeamList = () => async (dispatch) => {
  try {
    const { result } = await apiConnector(
      '/api/salesteams/all',
      'GET',
    );

    dispatch({
      type: SET_TEAM_LIST,
      result,
    });
  } catch (e) {
    callAlert.error(e.message);
  }
};

export const createTeam = (payload) => async (dispatch, getState) => {
  try {
    const { salesTeams } = getState();
    const {
      createdTeam,
    } = salesTeams;
    const { result } = await apiConnector(
      '/api/salesteams/create',
      'POST', payload || createdTeam,
    );

    dispatch({
      type: SET_TEAM_LIST,
      result,
    });
    dispatch({
      type: SET_CREATED_TEAM_STATE,
      result: {
        payload: {
          id: null,
          title: '',
          description: '',
          teamId: null,
          Employees: [],
        },
      },
    });
  } catch (e) {
    callAlert.error(e.message);
  }
};

export const deleteTeam = ({ id, teamId }) => async (dispatch) => {
  try {
    const { result } = await apiConnector(
      '/api/salesteams/delete',
      'DELETE', { id, teamId },
    );

    dispatch({
      type: SET_TEAM_LIST,
      result,
    });
  } catch (e) {
    callAlert.error(e.message);
  }
};

export const updateTeam = (payload) => async (dispatch, getState) => {
  try {
    const {
      salesTeams: {
        createdTeam,
        assignModalMode,
      },
      leads: {
        LeadID,
      },
    } = getState();

    const bodyWithRole = {
      role: assignModalMode,
      LeadID,
      ...createdTeam,
    };

    if (payload) {
      payload.LeadID = LeadID; // eslint-disable-line no-param-reassign
    }

    const { result } = await apiConnector(
      '/api/salesteams/update',
      !assignModalMode ? 'POST' : 'PUT', {
        data: payload || bodyWithRole,
      },
    );

    dispatch({
      type: SET_TEAM_LIST,
      result,
    });

    dispatch({
      type: SET_ASSIGN_MODAL_ROLE,
      roleType: null,
    });
  } catch (e) {
    callAlert.error(e.message);
  }
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedSalesTeamsActions = {
  getTeamList: memoizeDebounce(
    () => store.dispatch(getTeamList()),
    debounceDelay,
    debounceOptions,
  ),
};
