import { connect } from 'react-redux';

import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import PropertyMultiTypeSelect from 'sections/Leads/inputs/PropertyMultiTypeSelect';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      buyerPropertyType,
    },
  } = state;

  return {
    value: buyerPropertyType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => {
    dispatch(setNewLeadFormField('buyerPropertyType')(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyMultiTypeSelect);
