import React from 'react';

import PropTypes from 'prop-types';

import { useForm, useController } from 'react-hook-form';

import Button from 'ui/Button/Button';

import Flex from 'ui/Flex';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';

const DeclineOfferForm = (props) => {
  const {
    onSubmit,
  } = props;

  const { handleSubmit, control } = useForm();

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'declineReason',
    control,
  });

  return (
    <Flex
      spacing={2}
      flexDirection="column"
    >
      <Typography>
        Leave a note which will be stored with this offer
      </Typography>
      <Textfield
        fullWidth
        textarea
        placeholder="Memo"
        onChange={onChange}
        value={value}
      />
      <Button onClick={handleSubmit(onSubmit)}>Confirm Decline</Button>
    </Flex>
  );
};

const {
  func,
} = PropTypes;

DeclineOfferForm.propTypes = {
  onSubmit: func.isRequired,
};

export default DeclineOfferForm;
