import byZip from './byZip';
import details from './details';
import item from './item';
import reserve from './reserve';
import unreserve from './unreserve';
import listReserved from './listReserved';

export default {
  byZip,
  details,
  item,
  reserve,
  unreserve,
  listReserved,
};
