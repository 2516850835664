import { connect } from 'react-redux';

import {
  getGeneral,
  deleteCompanyLogo,
} from 'store/actions/MyCompany';

import Logo from './Logo';

const mapStateToProps = (state) => ({
  general: state.myCompany.general,
  user: state.auth.loggedUser,
});

const mapDispatchToProps = {
  getGeneral,
  deleteCompanyLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
