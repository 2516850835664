import useReselect from 'shared/hooks/useReselect';

import selectors from 'store/selectors';

const useContact = (
  contactId,
) => {
  const contact = useReselect(selectors.realtorContacts.byId, contactId);

  const you = useReselect(selectors.users.currentUser);

  if (contactId === 0) {
    return you;
  }
    return contact;
};

export default useContact;
