import { connect } from 'react-redux';
import { getUserOptimized } from 'store/actions/users';

import selectors from 'store/selectors';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import Avatar from 'shared/components/UI/RealtorContactAvatar';

import {
  uploadAttachment,
} from 'store/actions/attachmentsV2';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    avatar,
    avatarAcl,
    avatarUrl,
    name,
    surname,
  } = selectors.realtorContacts.basicById(state, contactId);

  const avatarImageSrc = (avatarUrl && avatarAcl === 'public-read')
    ? avatarUrl
    : getAvatarUrl(avatar);

  return {
    src: avatarImageSrc,
    firstName: name,
    lastName: surname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSave: async (rawFile) => {
    const fileUrl = URL.createObjectURL(rawFile);

    const file = await dataUrlToFile(fileUrl, rawFile.name, rawFile.type);

    await dispatch(uploadAttachment({
      file,
      objectId: ownProps.contactId,
      objectType: 25,
      attachmentType: 1,
      isPublic: true,
    }));

    URL.revokeObjectURL(fileUrl);

    getUserOptimized('realtorContact', ownProps.contactId);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Avatar);
