import getAvailabilityStatusByCode from './getAvailabilityStatusByCode';
import getAvailabilityCodeByEmployeeId from './getAvailabilityCodeByEmployeeId';

/**
 * @param {string} employeeId
 * @returns {string}
 */
const getAvailabilityBadgeStatus = (employeeId) => {
  const availabilityCode = getAvailabilityCodeByEmployeeId(employeeId);

  if (!availabilityCode) {
    return null;
  }

  const badgeStatus = getAvailabilityStatusByCode(availabilityCode);

  return badgeStatus;
};

export default getAvailabilityBadgeStatus;
