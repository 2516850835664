/* eslint-disable */
// Exposit
// WHOLE FILE IGNORED, TOO MANY PRE-EXISTING ERRORS
// TODO - fix lint errors
import connectReducers from 'store/connectReducers.js';
import {
  GET_PLANNED_SYSTEM_MAINTENANCE
} from 'store/actionTypes/systemCheck';

const initialState = {
  plannedSystemMaintenance: [],
};

const reducers = {
  [GET_PLANNED_SYSTEM_MAINTENANCE]: (state, action) => ({
      ...state,
      plannedSystemMaintenance: action.plannedSystemMaintenance,
    }),
};

function systemCheck(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default systemCheck;
