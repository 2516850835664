/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getMatchingProperties from './get';
import getMatchingPropertyById from './getById';
import getInfiniteScrollMatchingPropertiesForFilter from './getInfiniteScrollMatchingPropertiesForFilter';
import createMatchingProperty from './create';
import updateMatchingProperty from './update';
import removeMatchingProperty from './remove';

import resetMatchingPropertyFilterById from './resetFilterById';
import resetMatchingPropertyFilters from './resetFilters';

import addMatchingPropertiesRelations from './addRelations';
import removeMatchingPropertiesRelations from './removeRelations';

export {
  getMatchingProperties,
  getMatchingPropertyById,
  createMatchingProperty,
  updateMatchingProperty,
  removeMatchingProperty,

  resetMatchingPropertyFilterById,
  resetMatchingPropertyFilters,

  addMatchingPropertiesRelations,
  removeMatchingPropertiesRelations,
};

const debounceDelay = 500;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedMatchingPropertiesActions = {
  get: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getMatchingProperties(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getInfiniteScrollMatchingPropertiesForFilter: memoizeDebounce(
    (filterId, filter, offset, limit, resetStore) => store.dispatch(
      getInfiniteScrollMatchingPropertiesForFilter(filterId)(filter)(offset, limit, resetStore),
    ),
    debounceDelay,
    debounceOptions,
  ),
  getById: memoizeDebounce(
    (matchId) => store.dispatch(getMatchingPropertyById(matchId)),
    debounceDelay,
    debounceOptions,
  ),
};
