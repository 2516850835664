import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import ImagesUpload from 'shared/inputs/ImagesUpload';

const EditImagesModal = (props) => {
  const {
    images,
    onMainImageChange,
    onUpload,
    onRemove,
    theme,
    closeModal,
    isVisible,
    loading,
  } = props;

  return (
    <Modal
      theme={theme}
      width={720}
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      title="Edit property images"
    >
      <ImagesUpload
        fileList={images}
        onMainImageChange={onMainImageChange}
        label="+ Drag & drop or click to upload property photos here (max 5)"
        decorated
        loading={loading}
        onUpload={onUpload}
        onRemove={onRemove}
      />
    </Modal>
  );
};

EditImagesModal.defaultProps = {
  isVisible: false,
  theme: null,
  images: [],
  loading: false,
};

const {
  bool,
  func,
  shape,
  arrayOf,
  string,
} = PropTypes;

EditImagesModal.propTypes = {
  isVisible: bool,
  loading: bool,
  theme: shape(),
  closeModal: func.isRequired,
  onUpload: func.isRequired,
  onRemove: func.isRequired,
  onMainImageChange: func.isRequired,
  images: arrayOf(shape({
    id: string,
    uid: string,
    url: string,
    isMain: bool,
  })),
};

export default EditImagesModal;
