import apiConnector, { endpoints } from 'api';

const importFromFile = (objectType) => (fieldMap) => (selectedRecords) => async (fileId) => {
  try {
    const response = await apiConnector.client.post(
      `${endpoints.client.dataImport.import}/${objectType}`,
      {
        fileId,
        fieldMap,
        selectedRecords,
      },
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default importFromFile;
