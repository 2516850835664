import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import SourceDescriptionModal from './NewSourceModal';

const mapDispatchToProps = (dispatch) => ({
  onCancel: () => {
    dispatch(setNewContactFormField('sourceDescription')(null));
    dispatch(setNewContactFormField('newCustomSourceName')(null));
  },
});

export default connect(null, mapDispatchToProps)(SourceDescriptionModal);
