export const hr = {
  TIME_TYPES: {
    WORK: {
      type: 'work',
      color: '#50e3c2',
    },
    LUNCH: {
      type: 'lunch',
      color: '#ffba4a',
    },
    SICK: {
      type: 'sick',
      color: '#6b88a4',
    },
    OVERTIME: {
      type: 'overtime',
      color: '#ff6446',
    },
    HOLIDAY: {
      type: 'holiday',
      color: '#bd10e0',
    },
    null: {
      type: 'not logged in',
      color: '#e1e1e1',
    },
    [undefined]: {
      type: 'not logged in',
      color: '#e1e1e1',
    },
  },
  NULL_WORK: '#e1e1e1',
};
