import React, { useContext, useCallback } from 'react';
// import PropTypes from 'prop-types';

import RadioGroup from 'ui/RadioGroup';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import RadioColor from 'ui/RadioColor';

import SectionTitle from 'sections/Settings/SectionTitle';
import ThemeContext from 'ui/ThemeProvider/context';

import navbarColors from 'constants/navbarColors.constants';
import updateNavbarColor from 'shared/utils/navbarSettings/updateNavbarColor';

/*
const themeLayoutItems = [
  {
    value: 'light',
    displayedValue: 'Light',
  },
  {
    value: 'dark',
    displayedValue: 'Dark',
  },
  {
    value: 'semi_dark',
    displayedValue: 'Semi Dark',
  },
];
*/

const navbarColorItems = navbarColors.map((colors) => ({
  name: colors.name,
  value: colors.color,
}));

// const noop = () => {};

const ThemeCustomizer = (props) => {
  const { handleNavColorChange } = useContext(ThemeContext);

  const handleRadioChange = useCallback((value) => {
    const { name } = navbarColors.find((object) => object.color === value);
    updateNavbarColor(name);
    handleNavColorChange(name);
  });

  return (
    <Flex
      flexDirection="column"
      spacing={3}
    >
      <FlexItem>
        <Typography>
          Customize your app theme and preview in real time!
        </Typography>
      </FlexItem>
      {/*
    // keeping theme setup for later
      <FlexItem>
        <SectionTitle
          title="Theme Layout"
          variant="title2"
        />
        <RadioGroup
          items={themeLayoutItems}
          defaultValue="light"
          onChange={noop}
          flexDirection="row"
          gap="50px"
          disabled
        />
      </FlexItem>
  */}
      <FlexItem>
        <SectionTitle
          title="Navbar Color"
          variant="title2"
        />
        <RadioGroup
          items={navbarColorItems}
          onChange={handleRadioChange}
          flexDirection="row"
          gap="12px"
          customRadioComponent={RadioColor}
        />
      </FlexItem>
    </Flex>
  );
};

// const {
//   oneOf,
//   func,
// } = PropTypes;

ThemeCustomizer.propTypes = {
};

export default ThemeCustomizer;
