import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedAttachmentsActions,
  uploadAttachment,
  deleteAttachment,
} from 'store/actions/attachmentsV2';

import {
  filePreloader,
} from 'store/actions/attachments';

import {
  optimizedPropertyActions,
  updateProperty,
} from 'store/actions/properties';

import EditImagesModal from './EditImagesModal';

const mapStateToProps = (state, ownProps) => {
  const { propertyId } = ownProps;

  const {
    properties: {
      selectedProperty,
    },
    attachments: {
      preloader,
    },
  } = state;

  const images = propertyId
    ? selectors.properties.propertyImages(state, propertyId)
    : selectors.properties.selectedPropertyImages(state);

  return {
    images,
    propertyId: propertyId || selectedProperty,
    loading: preloader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpload: async (file, propertyId) => {
    const data = {
      file,
      objectId: propertyId,
      objectType: 27,
      attachmentType: 11,
      isPublic: true,
    };

    await dispatch(uploadAttachment(data));
    optimizedAttachmentsActions.getAttachmentsList(27, propertyId);
    optimizedPropertyActions.getPropertyAttachments(propertyId);
  },
  onRemove: async (attachmentId, propertyId) => {
    await dispatch(deleteAttachment(27, propertyId, attachmentId));
    optimizedAttachmentsActions.getAttachmentsList(27, propertyId);
    optimizedPropertyActions.getPropertyAttachments(propertyId);
  },
  onMainImageChange: async (imageId, propertyId) => {
    dispatch(filePreloader(true));
    await dispatch(updateProperty(propertyId)({ main_image_id: imageId }));
    dispatch(filePreloader(false));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onUpload: async ({ file }) => {
    if (stateProps.images.length > 4) {
      return;
    }
    await dispatchProps.onUpload(file, stateProps.propertyId);
  },
  onRemove: async ({ id }) => {
    await dispatchProps.onRemove(id, stateProps.propertyId);
  },
  onMainImageChange: async ({ imageId }) => {
    await dispatchProps.onMainImageChange(imageId, stateProps.propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditImagesModal);
