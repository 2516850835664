import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getWarehousePackages from './get';
import getWarehousePackagesForFulfillment from './getFulfillmentPackages';
import refreshWarehousePackagesForFulfillment from './refreshFulfillmentPackages';
import getWarehousePackageById from './getById';

import createWarehousePackage from './create';

import setSelectedWarehousePackage from './set';

import updateWarehousePackage from './update';

import getPendingOrder from './getPendingOrder';
import getWarehouseKanbanLaneFulfillments from './getWarehouseKanbanLaneFulfillments';
import refreshKanban from './refreshKanban';
import getOutOfStockOrder from './getOutOfStockOrder';

import setSelectedLineItems from './setSelectedLineItems';
import setDraggedLineItems from './setDraggedLineItems';
import clearSelectedLineItems from './clearSelectedLineItems';

import {
  openMovePackageLineItemModal,
  closeMovePackageLineItemModal,
} from './warehouseModalsVisibility';

export { getWarehousePackages };
export { getWarehouseKanbanLaneFulfillments };
export { getWarehousePackagesForFulfillment };
export { getWarehousePackageById };
export { refreshWarehousePackagesForFulfillment };
export { refreshKanban };

export { createWarehousePackage };

export { setSelectedWarehousePackage };

export { updateWarehousePackage };

export { openMovePackageLineItemModal };
export { closeMovePackageLineItemModal };

export { setSelectedLineItems };
export { setDraggedLineItems };
export { clearSelectedLineItems };
export { getPendingOrder };
export { getOutOfStockOrder };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedWarehouseActions = {
  getWarehousePackageById: memoizeDebounce(
    (id) => store.dispatch(getWarehousePackageById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getWarehouseKanbanLaneFulfillments: memoizeDebounce(
    (offset, limit, lane) => store.dispatch(getWarehouseKanbanLaneFulfillments(offset, limit)(lane)),
    debounceDelay,
    debounceOptions,
  ),
  getWarehousePackagesForFulfillment: memoizeDebounce(
    (fulfillmentId, offset, limit) => store.dispatch(getWarehousePackagesForFulfillment(fulfillmentId)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  refreshWarehousePackagesForFulfillment: memoizeDebounce(
    (fulfillmentId) => store.dispatch(refreshWarehousePackagesForFulfillment(fulfillmentId)),
    debounceDelay,
    debounceOptions,
  ),
  getPendingOrder: memoizeDebounce(
    (fulfillmentId) => store.dispatch(getPendingOrder(fulfillmentId)),
    debounceDelay,
    debounceOptions,
  ),
  getOutOfStockOrder: memoizeDebounce(
    (fulfillmentId) => store.dispatch(getOutOfStockOrder(fulfillmentId)),
    debounceDelay,
    debounceOptions,
  ),
};
