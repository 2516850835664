import {
  SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD,
} from 'store/actionTypes/realtorMls';

const setNewPropertyNestesdFormField = (parentKey) => (key) => (value) => ({
  type: SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD,
  payload: {
    parentKey,
    key,
    value,
  },
});

export default setNewPropertyNestesdFormField;
