import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Textfield from 'ui/Textfield';

const nakedInputStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  padding: 0,
};

const Subject = (props) => {
  const {
    onChange,
    value,
  } = props;

  return (
    <Flex
      fullWidth
      spacing={1}
      alignItems="center"
    >
      <Textfield
        fullWidth
        flat
        placeholder="Subject"
        onChange={onChange}
        value={value}
        style={nakedInputStyle}
      />
    </Flex>
  );
};

Subject.defaultProps = {
  value: '',
};

const {
  func,
  string,
} = PropTypes;

Subject.propTypes = {
  onChange: func.isRequired,
  value: string,
};

export default Subject;
