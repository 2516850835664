import {
  SET_EXTENSION_REQUEST_FORM_ATTACHMENTS,
} from 'store/actionTypes/realtorTransactions';

const setFormAttachments = (filesList = []) => ({
  type: SET_EXTENSION_REQUEST_FORM_ATTACHMENTS,
  payload: { filesList },
});

export default setFormAttachments;
