import {
  SET_INSPECTION_REQUEST_ATTACHMENTS,
} from 'store/actionTypes/realtorInspections';

const setInspectionAttachments = (id, attachments) => ({
  type: SET_INSPECTION_REQUEST_ATTACHMENTS,
  payload: {
    id,
    attachments,
  },
});

export default setInspectionAttachments;
