import connectReducers from 'store/connectReducers';
import { groupBy } from 'shared/utility';

import {
  SET_REFUND,
  SET_REFUNDS,
  SET_SELECTED_REFUND,
  SET_REFUND_ATTACHMENTS,
} from 'store/actionTypes/refunds';

const initialState = {
  refunds: {},
  selectedRefund: null,
  attachments: {},
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_SELECTED_REFUND]: (state, action) => ({
    ...state,
    selectedRefund: action.payload,
  }),

  [SET_REFUND]: (state, action) => ({
    ...state,
    refunds: {
      ...state.refunds,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_REFUNDS]: (state, action) => ({
    ...state,
    refunds: {
      ...state.refunds,
      ...listToObject(action.payload),
    },
  }),

  [SET_REFUND_ATTACHMENTS]: (state, action) => {
    const updatedState = {
      ...state,
      attachments: {
        ...state.attachments,
        [action.payload.id]: groupBy(action.payload.attachments, 'attachmentType'),
      },
    };

    return updatedState;
  },
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
