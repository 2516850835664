/* eslint-disable import/no-cycle */
import filter from './filter';
import byFilter from './byFilter';
import kanbanProspect from './kanbanProspect';
import byId from './byId';
import rejectedProperties from './rejectedProperties';
import propertyMatches from './propertyMatches';
import kanbanBuyers from './kanbanBuyers';

export default {
  kanbanBuyers,
  filter,
  byFilter,
  kanbanProspect,
  byId,
  rejectedProperties,
  propertyMatches,
};
