import React, {
  useState,
  useCallback,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import Textfield from './Textfield';
import { VisibilityToggleButton } from './styledItems';

const preventDefaultCb = (event) => {
  event.preventDefault();
};

const Password = forwardRef((props, ref) => {
  const {
    visibilityToggle,
    icon,
    disabled,
  } = props;

  const [visible, setVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setVisible((value) => !value);
  }, []);

  const getControlButton = useCallback(() => (
    <VisibilityToggleButton
      icon={visible ? 'eye' : 'eye-off'}
      onClick={toggleVisibility}
      iconColor="main-text"
      iconSize="xs"
      variant="tertiary"
      disabled={disabled}
      onMouseDown={preventDefaultCb} // Prevent focused state lost
      onMouseUp={preventDefaultCb} // Prevent caret position change
    />
  ), [
    visible,
    disabled,
  ]);

  return (
    <Textfield
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      ref={ref}
      icon={icon}
      iconPosition="left"
      type={visible ? 'text' : 'password'}
      ControlComponent={visibilityToggle ? getControlButton() : null}
    />
  );
});

Password.defaultProps = {
  visibilityToggle: true,
  icon: 'lock',
  disabled: false,
};

const {
  bool,
  string,
} = PropTypes;

Password.propTypes = {
  visibilityToggle: bool,
  icon: string,
  disabled: bool,
};

export default Password;
