import requests from 'api/requests';

import setLeads from './setLeads';

const getLeads = (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const response = await requests.crm.leads.get({
      offset,
      limit,
    });

    dispatch(setLeads(response.data.leads));
  } catch (error) {
    console.error(error);
  }
};

export default getLeads;
