import requests from 'api/requests';

import getById from './getPropertyRequirementById';

const removePropertyRequirementRelations = (relationType) => (requirementId) => (data) => async (dispatch) => {
  console.log(relationType, requirementId, data);
  try {
    await requests.realtor.propertyRequirements.removeRelations(relationType)(requirementId)(data);

    dispatch(getById(requirementId));
  } catch (error) {
    console.error(error);
  }
};

export default removePropertyRequirementRelations;
