import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

const deleteTransaction = (transactionId) => async (dispatch) => {
  try {
    await requests.realtor.transactions.deleteTransaction(transactionId);
    callAlert.success('Deleted');
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteTransaction;
