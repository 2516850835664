import store from 'store';

import { isEmpty } from 'shared/utility';

/**
 * @param {string} ticketId
 * @returns {object}
 */
const getTicketById = (ticketId) => {
  const {
    tickets: {
      ticketsList,
    },
  } = store.getState();

  if (!ticketId || isEmpty(ticketsList)) {
    return null;
  }

  const ticket = ticketsList.find((item) => item.TicketID === ticketId);

  if (!ticket) {
    return null;
  }

  return ticket;
};

export default getTicketById;
