import updateLead2Product from './updateLead2Product';
import getLeadById from './getLeadById';
import getLeadChangelogAllTime from './getLeadChangelogAllTime';

const updateSelectedLeadProducts = (relationId) => (updateData) => async (dispatch, getState) => {
  const {
    crmLeads: {
      selectedLead,
    },
  } = getState();

  await dispatch(updateLead2Product(relationId)(updateData));
  dispatch(getLeadById(selectedLead));
  dispatch(getLeadChangelogAllTime(selectedLead));
};

export default updateSelectedLeadProducts;
