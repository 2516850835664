import {
  SET_REALTOR_PROSPECTS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorProspects';

const setProspectsForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_PROSPECTS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setProspectsForFilterCount;
