import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import validate from 'shared/utils/validators';

import Grid from 'ui/Grid';
import Textfield from 'ui/Textfield';
import Typography from 'ui/Typography';
import PhoneNumberInput from 'ui/PhoneNumberInput';

import Avatar from './Avatar';
import Logo from './Logo';

const ProfileDetails = (props) => {
  const {
    firstName,
    lastName,
    phone,
    updateProfileData,
  } = props;

  const [phoneFieldError, setPhoneFieldError] = useState(null);

  const onChange = (key1, key2) => useDebouncedCallback((value) => {
    updateProfileData(key1, key2)(value);
  }, 1500);

  const validatePhone = useCallback((event) => {
    if (!validate.phone(event.target.value)) {
      setPhoneFieldError('Phone not valid!');
      return;
    }
    setPhoneFieldError(null);
  }, []);

  return (
    <Grid container spacing={6} alignItems="flex-start">
      <Grid
        item
        container
        xs={7}
        spacing={3}
      >
        <Grid item xs={6}>
          <Textfield
            label="First Name"
            value={firstName}
            onChange={onChange('Name', 'FirstName')}
          />
        </Grid>
        <Grid item xs={6}>
          <Textfield
            label="Last Name"
            value={lastName}
            onChange={onChange('Surname', 'LastName')}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneNumberInput
            label="Phone"
            icon="phone"
            value={phone}
            onChange={onChange('WorkPhone', 'PhoneNumber')}
            error={phoneFieldError}
            inputProps={{
              onKeyUp: validatePhone,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={5}
        spacing={5}
      >
        <Grid item xs={5}>
          <Typography variant="label" tag="p" color="descriptor-text">
            Profile photo
          </Typography>
          <Avatar />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="label" tag="p" color="descriptor-text">
            Company Logo
          </Typography>
          <Logo />
        </Grid>
      </Grid>
    </Grid>
  );
};

const {
  string,
  func,
} = PropTypes;

ProfileDetails.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired,
  phone: string.isRequired,
  updateProfileData: func.isRequired,
};

export default ProfileDetails;
