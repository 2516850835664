import {
  SET_TEAM_LIST,
  SET_CREATED_TEAM_STATE,
  SET_ASSIGN_MODAL_ROLE,
} from 'store/actions/salesTeams';

const initialState = {
  teams: [],
  createdTeam: {
    id: null,
    title: '',
    description: '',
    teamId: null,
    Employees: [],
  },
  modalMode: 'create',
  assignModalMode: null,
};

function salesTeams(state = initialState, action) {
  const { result, roleType } = action;
  switch (action.type) {
    case SET_TEAM_LIST: {
      return {
        ...state,
        teams: result,
      };
    }
    case SET_CREATED_TEAM_STATE: {
      const { payload, variant } = result;
      return {
        ...state,
        createdTeam: {
          ...state.createdTeam,
          ...payload,
        },
        modalMode: variant || 'create',
      };
    }
    case SET_ASSIGN_MODAL_ROLE: {
      return {
        ...state,
        assignModalMode: roleType,
      };
    }
    default:
      return state;
  }
}

export default salesTeams;
