import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Buyer from './Buyer';
import BuyerAgent from './BuyerAgent';
import SellerAgent from './SellerAgent';
import NewContactArea from './NewContactArea';

const NewPropertyForm = (props) => {
  const {
    handleChange,
    handleChangeNested,
    showBuyerField,
    showBuyerAgentField,
    showSellerAgentField,
  } = props;

  return (
    <form>
      <Grid container spacing={1}>
        {
          showBuyerField && (
            <Grid item xs={12}>
              <Buyer
                decorated
                handleChange={handleChange('buyer')}
              />
            </Grid>
          )
        }

        {
          showBuyerAgentField && (
            <Grid item xs={12}>
              <BuyerAgent
                decorated
                decoratorProps={{
                  iconName: 'handshake',
                }}
                handleChange={handleChange('buyerAgent')}
              />
            </Grid>
          )
        }

        {
          showSellerAgentField && (
            <Grid item xs={12}>
              <SellerAgent
                decorated
                decoratorProps={{
                  iconName: 'handshake',
                }}
                handleChange={handleChange('sellerAgent')}
              />
            </Grid>
          )
        }

        <Grid item xs={12}>
          <NewContactArea
            areaTitle="Or create a new contact"
            handleChange={handleChangeNested('newContact')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

NewPropertyForm.defaultProps = {
  showBuyerField: false,
  showBuyerAgentField: false,
  showSellerAgentField: false,
};

const {
  func,
  bool,
} = PropTypes;

NewPropertyForm.propTypes = {
  handleChange: func.isRequired,
  handleChangeNested: func.isRequired,
  showBuyerField: bool,
  showBuyerAgentField: bool,
  showSellerAgentField: bool,
};

export default NewPropertyForm;
