import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Selection from 'ui/Selection';

const LeadTypeSelect = (props) => {
  const {
    onSelect,
    selectedTypes,
  } = props;

  const handleSelect = useCallback((newValue) => {
    const updatedValue = newValue.length > 0
      ? newValue
      : selectedTypes;

    onSelect(updatedValue);
  }, [
    onSelect,
    selectedTypes,
  ]);

  return (
    <Selection
      options={[
        {
          id: 1,
          label: 'Seller',
          iconName: 'briefcase',
        },
        {
          id: 2,
          label: 'Buyer',
          iconName: 'investment',
          iconType: 'custom',
        },
      ]}
      value={selectedTypes}
      onChange={handleSelect}
    />
  );
};

const {
  func,
  oneOf,
  arrayOf,
} = PropTypes;

LeadTypeSelect.defaultProps = {
  selectedTypes: [],
};

LeadTypeSelect.propTypes = {
  onSelect: func.isRequired,
  selectedTypes: arrayOf(oneOf([1, 2, 3])),
};

export default LeadTypeSelect;
