import styled from 'styled-components';

import {
  TabPanel,
} from '@material-ui/lab';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div`
  padding: 12px;
  height: inherit;
`;

export const StyledTabPanel = styled(TabPanel)`
  &&{
    padding: 16px 0 24px 0;
    height: calc(100% - 60px);
  }
`;

export const OverviewContainer = styled.div`
  width: inherit;
  margin-bottom: 30px;
`;

export const ContentContainer = styled.div`
  width: inherit;
  height: calc(100% - 139px);
`;
