import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getTokenIssuerData from './getTokenIssuerData';

export {
  getTokenIssuerData,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedExternalAccessGeneralActions = {
  getTokenIssuerData: memoizeDebounce(
    () => store.dispatch(getTokenIssuerData()),
    debounceDelay,
    debounceOptions,
  ),
};
