import {
  SET_TICKET,
} from 'store/actionTypes/tickets';

const setTicket = (ticket) => ({
  type: SET_TICKET,
  payload: ticket,
});

export default setTicket;
