import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getById from './getById';

import resetFilters from './resetFilters';

const update = (itemId) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.unansweredQuestions.update(itemId)(updateData);

    callAlert.success('Updated successfully');

    await dispatch(getById(itemId));

    dispatch(resetFilters());
  } catch (error) {
    console.error(error);
    callAlert.error('Could not update');
  }
};

export default update;
