import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Grid from 'ui/Grid';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';
import PropertyParametersIndicators from 'sections/MLS/modules/PropertyParametersIndicators';

const Property = (props) => {
  const {
    propertyId,
    address,
    price,
    representingContact,
  } = props;

  return (
    <>
      <Typography variant="title1" weight="bold">
        Property
      </Typography>

      <Grid
        container
        spacing={3}
      >
        <Grid item xs={12}>
          <ImageCarousel
            propertyId={propertyId}
            editable={false}
          />
        </Grid>

        <Grid item xs={12}>
          <DecoratedFieldWrapper
            iconName="map-pin"
            iconType="feather"
            variant="focus"
          >
            <Typography
              variant="title1"
              noMargin
            >
              {address}
            </Typography>
          </DecoratedFieldWrapper>
        </Grid>

        <Grid item xs={12}>
          <DecoratedFieldWrapper
            iconName="dollar-sign"
            iconType="feather"
            variant="focus"
          >
            <Typography
              variant="title1"
              noMargin
            >
              {price || '—'}
            </Typography>
          </DecoratedFieldWrapper>
        </Grid>

        <Grid item xs={12} padding="0 0 0 6px">
          <PropertyParametersIndicators propertyId={propertyId} />
        </Grid>

        {
          representingContact && (
            <Grid item xs={12}>
              <Typography
                noMargin
              >
                <strong>Representing:</strong>
                &nbsp;
                {representingContact}
                &nbsp;
                (Seller)
              </Typography>
            </Grid>
          )
        }

      </Grid>
    </>
  );
};

Property.defaultProps = {
  representingContact: null,
};

const {
  number,
  string,
} = PropTypes;

Property.propTypes = {
  propertyId: number.isRequired,
  address: string.isRequired,
  price: number.isRequired,
  representingContact: string,
};

export default Property;
