export const OFFICE_FILTER = 'office';
export const USER_FILTER = 'users';
export const DEPARTMENT_FILTER = 'department';
export const EMPLOYEE_TYPE_FILTER = 'employee type';
export const BUY_MODAL = 'buy-modal';
export const ADD_EMPLOYEE_MODAL = 'add-employee-modal';
export const ADD = 'ADD';
export const ADD_ANOTHER = 'ADD_ANOTHER';
export const LICENSE_SUCCESS = 'License Successfully Added';

export const LICENSE_ALERT_MSG = 'Product managers have read and edit permissions over the entire product that they have a license to.';

export const LICENSE_ACTIONS = {
  EMPTY: 0,
  LICENSE: 1,
  PM_ROLE: 2,
  EX_ROLE: 3,
};

export const LOG_ACTION = {
  ADDED: 'added',
  UNASSIGNED: 'unassigned',
  REMOVED: 'removed',
  ASSIGNED: 'assigned',
};
