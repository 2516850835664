import updatePropertyViewing from './updatePropertyViewing';

const updateSelectedPropertyViewing = (updateData) => async (dispatch, getState) => {
  const {
    propertyViewings: {
      selectedViewing,
    },
  } = getState();

  dispatch(updatePropertyViewing(selectedViewing)(updateData));
};

export default updateSelectedPropertyViewing;
