import { createSelector } from 'reselect';

import getThumbnailUrl from 'shared/utils/getThumbnailUrl';
import getPreviewUrl from 'shared/utils/getPreviewUrl';

import { reduce } from 'shared/utility';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

import {
  optimizedAttachmentsActions,
} from 'store/actions/attachmentsV2';

import getImageUrl from 'shared/utils/getImageUrl';

const propertyObjectType = 27;

export default createSelector(
  (state, propertyId) => state.properties.properties?.[propertyId],
  (state) => state.attachmentsV2.attachments,
  (_, propertyId) => propertyId,
  (
    property,
    attachmentsCollection,
    id,
  ) => {
    if (!property) {
      if (id) {
        optimizedPropertyActions.getPropertyById(id);
      }

      return [];
    }

    const propertyAttachments = property.attachments;

    if (!propertyAttachments) {
      optimizedPropertyActions.getPropertyAttachments(id);
      return [];
    }

    const images = reduce(propertyAttachments?.image, (accum, { attachmentId }) => {
      const attachment = attachmentsCollection[attachmentId];

      if (!attachment) {
        if (attachmentId) {
          optimizedAttachmentsActions.getAttachmentsList(propertyObjectType, id);
        }

        return accum;
      }

      const attachmentLocation = attachment.Location || attachment.location;
      const attachmentUrl = attachment.Url || attachment.url;
      const attachmentAcl = attachment.Acl || attachment.acl;

      const imageUrl = getImageUrl(attachmentLocation, attachmentUrl, attachmentAcl);

      accum.push({
        id: attachmentId,
        src: imageUrl,
        uid: attachmentId,
        name: attachment.OriginalName,
        url: imageUrl,
        thumbUrl: getThumbnailUrl(attachment?.Uuid),
        previewUrl: getPreviewUrl(attachment?.Uuid),
        isMain: attachmentId === property.main_image_id,
      });
      return accum;
    }, []);

    return images;
  },
);
