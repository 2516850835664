import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import MainClientInfo from './MainClientInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    clientId,
    variant,
  } = ownProps;

  const client = selectors.realtorClients.byId(state, clientId);

  const {
    contact_id,
    listing_price,
    realtor_property_id,
    viewings_summary,
    offers_summary,
  } = client;

  const { address_id } = selectors.properties.byId(state, realtor_property_id);

  const address = selectors.addresses.byId(state, address_id);

  const {
    first_name,
    last_name,
  } = selectors.realtorContacts.byId(state, contact_id);

  return {
    name: first_name,
    surname: last_name,
    contactId: contact_id,
    listingPrice: listing_price,
    address: address?.address,
    propertyId: realtor_property_id,
    viewingsCount: viewings_summary?.upcoming_viewings || 0,
    offers: offers_summary?.total_offers || 0,
    counters: offers_summary?.total_counters || 0,
    variant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateViewingClick: (propertyId) => {
    dispatch(openNewPropertyViewingModal(propertyId, { buyer: true, buyerAgent: true }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  onCreateViewingClick: () => {
    const { propertyId } = stateProps;
    dispatchProps.onCreateViewingClick(propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MainClientInfo);
