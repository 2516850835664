import requests from 'api/requests';

import setTasks from './setTasks';

const getTasksWithStatus = (
  status,
) => (
  offset = 0,
  limit = 50,
) => async (
  dispatch,
) => {
  try {
    const tasksResponse = await requests.tasks.getTasks({
      offset,
      limit,
      status,
    });

    dispatch(setTasks(tasksResponse.data.tasks));
  } catch (error) {
    console.error(error);
  }
};

export default getTasksWithStatus;
