/* eslint-disable import/no-cycle */
import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createProspect from './createProspect';
import getProspectById from './getProspectById';
import getProspects from './getProspects';
import getProspectsForFilter from './getProspectsForFilter';
import getProspectsForStoreFilter from './getProspectsForStoreFilter';
import getProspectsOverallSummary from './getProspectsOverallSummary';

import setSelectedProspect from './setSelectedProspect';
import setSelectedView from './setSelectedView';
import updateProspect from './updateProspect';
import updateSelectedProspect from './updateSelectedProspect';
import deleteProspect from './deleteProspect';
import deleteSelectedProspect from './deleteSelectedProspect';

export {
  createProspect,
  getProspectById,
  getProspects,
  getProspectsForFilter,
  getProspectsForStoreFilter,
  setSelectedProspect,
  setSelectedView,
  updateProspect,
  updateSelectedProspect,
  deleteProspect,
  deleteSelectedProspect,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedProspectActions = {
  getProspectById: memoizeDebounce(
    (id) => store.dispatch(getProspectById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getProspectsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getProspectsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getProspectsForStoreFilter: memoizeDebounce(
    (offset, limit) => store.dispatch(getProspectsForStoreFilter(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getProspectsOverallSummary: memoizeDebounce(
    () => store.dispatch(getProspectsOverallSummary()),
    debounceDelay,
    debounceOptions,
  ),
};
