import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    border-top: 0
  }
`;

export const Text = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.57;
  color: #34495e;
  padding: 8px 0 4px;
  margin-bottom: 0
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.18px;
  color: #172b4d;
`;

export default {
  StyledModal,
  Text,
  Title,
};
