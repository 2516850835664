import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';

import getPropertyById from 'store/actions/properties/getPropertyById';
import getById from './getPropertyViewingById';

const notify = debounce((action, text) => {
  callAlert[action](text);
}, 1000, {
  leading: true,
});

const updatePropertyViewing = (viewingId, propertyId = null) => (updateData) => async (dispatch) => {
  try {
    await requests.realtor.propertyViewings.update(viewingId)(updateData);

    notify('success', 'Updated successfully');

    dispatch(getById(viewingId));
    if (propertyId) {
      dispatch(getPropertyById(propertyId));
    }
  } catch (error) {
    console.error(error);
    notify('error', 'Could not update');
  }
};

export default updatePropertyViewing;
