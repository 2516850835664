import connectReducers from 'store/connectReducers';

import {
  SET_ONLINE_USERS,
  SET_CALLEE,
  SET_CALLER,
  SET_IS_CALL_DECLINED,
  SET_INVITED_EMAILS,
  SET_MEETING_FOR_INVITATIONS,
} from 'store/actionTypes/call';

const initialState = {
  onlineUsers: [],
  callee: null,
  caller: null,
  isCallDeclined: false,
  invitedEmails: [],
  meetingForInvitations: null,
};

const reducers = {
  [SET_ONLINE_USERS]: (state, action) => ({
    ...state,
    onlineUsers: action.payload,
  }),

  [SET_CALLEE]: (state, action) => ({
    ...state,
    callee: action.payload,
  }),

  [SET_CALLER]: (state, action) => ({
    ...state,
    caller: action.payload,
  }),

  [SET_IS_CALL_DECLINED]: (state, action) => ({
    ...state,
    isCallDeclined: action.payload,
  }),

  [SET_INVITED_EMAILS]: (state, action) => ({
    ...state,
    invitedEmails: action.payload,
  }),

  [SET_MEETING_FOR_INVITATIONS]: (state, action) => ({
    ...state,
    meetingForInvitations: action.payload,
  }),
};

const call = (state = initialState, action) => (
  connectReducers(reducers, state, action)
);

export default call;
