import {
  SET_REALTOR_LEADS_FOR_FILTER_COUNT,
} from 'store/actionTypes/realtorLeads';

const setLeadsForFilterCount = (filterId) => (count) => ({
  type: SET_REALTOR_LEADS_FOR_FILTER_COUNT,
  payload: {
    filterId,
    count,
  },
});

export default setLeadsForFilterCount;
