/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
} from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import SalePriceInput from 'shared/inputs/SalePriceInput';

const ListingPrice = (props) => {
  const {
    control,
    errors,
    propertyPrice,
  } = props;

  return (
    <Controller
      name="listing_price"
      control={control}
      defaultValue={propertyPrice}
      render={({ field }) => (
        <SalePriceInput
          {...field}
          fullWidth
          handleChange={field.onChange}
          label="Listing price"
          error={!!errors.listing_price}
          helperText={errors.listing_price ? 'This field is required' : ''}
        />
      )}
      rules={{
        // required: true,
        min: 0,
      }}
    />
  );
};

ListingPrice.defaultProps = {
  propertyPrice: null,
};

const {
  shape,
  number,
} = PropTypes;

ListingPrice.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  propertyPrice: number,
};

export default ListingPrice;
