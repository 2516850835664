import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'shared/components/CRM/UI/Modal';
import NewSourceForm from '../NewSourceForm';

const SourceDescriptionModal = (props) => {
  const {
    isVisible,
    onCancel,
    onSave,
    handleChangeCustomSourceName,
    handleChangeSourceDescription,
    sourceDescription,
    newCustomSourceName,
  } = props;

  return (
    <Modal
      width={400}
      visible={isVisible}
      okText="Save"
      onOk={onSave}
      onCancel={onCancel}
    >
      <NewSourceForm
        sourceDescription={sourceDescription}
        newCustomSourceName={newCustomSourceName}
        handleChangeCustomSourceName={handleChangeCustomSourceName}
        handleChangeSourceDescription={handleChangeSourceDescription}
      />
    </Modal>
  );
};

SourceDescriptionModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  string,
} = PropTypes;

SourceDescriptionModal.propTypes = {
  isVisible: bool,
  onCancel: func.isRequired,
  onSave: func.isRequired,
  handleChangeCustomSourceName: func.isRequired,
  handleChangeSourceDescription: func.isRequired,
  sourceDescription: string.isRequired,
  newCustomSourceName: string.isRequired,
};

export default SourceDescriptionModal;
