import { url as apiUrl } from 'paths';
import { isEmpty, isString } from 'shared/utility';

/**
 * @param {string} url
 * @returns {string}
 * @function
 */
const getImageUrlWithAuth = (url) => {
  const token = localStorage.getItem('token');
  return `${url}?auth=${token}`;
};

/**
 * @param {string} imageLocation
 * @param {string} imageUrl
 * @param {('bucket-owner-full-control'|'private'|'public-read')} acl
 * @returns {string}
 * @function
 */
const getImageUrl = (imageLocation, imageUrl = null, acl = 'bucket-owner-full-control') => {
  if (acl === 'public-read') {
    return imageUrl;
  }

  if (isEmpty(imageLocation)) {
    return null;
  }

  if (!isString(imageLocation)) {
    throw new Error('Avatar location must be a string');
  }

  const fileSrc = `${apiUrl}${imageLocation}`;
  const avatarUrl = getImageUrlWithAuth(fileSrc);

  return avatarUrl;
};

export default getImageUrl;
