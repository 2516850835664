import {
  SET_REALTOR_INSPECTIONS,
} from 'store/actionTypes/realtorInspections';

const setInspections = (inspections) => ({
  type: SET_REALTOR_INSPECTIONS,
  payload: inspections,
});

export default setInspections;
