import { connect } from 'react-redux';

import {
  setNewContactFormField,
} from 'store/actions/newRealtorContactForm';

import ContactLastNameInput from 'shared/components/UI/ContactLastNameInput';

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => dispatch(setNewContactFormField('personLastName')(value)),
});

export default connect(null, mapDispatchToProps)(ContactLastNameInput);
