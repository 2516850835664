import getLabels from './getLabels';
import post from './post';
import edit from './edit';
import postRelation from './postRelation';
import deleteRelation from './deleteRelation';
import deleteRelationV2 from './deleteRelationV2';

export default {
  getLabels,
  post,
  edit,
  postRelation,
  deleteRelation,
  deleteRelationV2,
};
