const socialLinksKeys = [
  'facebook_url',
  'linkedin_url',
  'instagram_url',
  'twitter_url',
  'website_url',
];

const socialLinkKeyToName = {
  facebook_url: 'facebook',
  linkedin_url: 'linkedin',
  instagram_url: 'instagram',
  twitter_url: 'twitter',
  website_url: 'website',
};

const formatSocialLinks = (contact = {}) => {
  const links = socialLinksKeys.reduce((accum, key) => {
    if (contact[key]) {
      accum.push({
        name: socialLinkKeyToName[key],
        url: contact[key],
      });
    }
    return accum;
  }, []);

  return links;
};

export default formatSocialLinks;
