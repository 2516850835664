import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { Popconfirm } from 'antd';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import Avatar from './Avatar';

import {
  Root,
  BottomLabel,
  NoContactContainer,
  WithContactContainer,
  ControlButtonsContainer,
} from './styledItems';

const PlayerItem = (props) => {
  const {
    onClick,
    contactId,
    name,
    role,
    hasAvatar,
    onDelete,
  } = props;

  const history = useHistory();

  const [hovered, setHovered] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
    setPopconfirmVisible(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const onRootClick = useCallback((event) => {
    if (contactId) {
      history.push(`/contacts/${contactId}`);
    } else {
      onClick();
    }
  }, [
    contactId,
    onClick,
  ]);

  const handleDelete = useCallback((event) => {
    event.stopPropagation();
    onDelete(contactId, role);
    setPopconfirmVisible(false);
  }, [
    contactId,
    role,
  ]);

  const openDeletePopconfirm = useCallback((event) => {
    event.stopPropagation();
    setPopconfirmVisible(true);
  }, []);

  const closeDeletePopconfirm = useCallback((event) => {
    event.stopPropagation();
    setPopconfirmVisible(false);
  }, []);

  return (
    <Root
      onClick={onRootClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        contactId || contactId === 0
          ? (
            <WithContactContainer>
              {
                (hovered || popconfirmVisible) && (
                  <ControlButtonsContainer>
                    <Popconfirm
                      title="Are you sure you want to remove this key user?"
                      onConfirm={handleDelete}
                      onCancel={closeDeletePopconfirm}
                      okText="Confirm"
                      cancelText="Cancel"
                    >
                      <Button
                        variant="tertiary"
                        icon="trash"
                        iconSize="xs"
                        iconColor="contrast"
                        onClick={openDeletePopconfirm}
                      />
                    </Popconfirm>
                  </ControlButtonsContainer>
                )
              }
              <Avatar contactId={contactId} />
            </WithContactContainer>
          )
          : (
            <NoContactContainer>
              <Icon
                name="plus"
                size="l"
                color="focus"
              />
            </NoContactContainer>
        )
      }

      <BottomLabel
        hasName={contactId || contactId === 0}
      >
        {
          name && (
            <Typography
              variant="subtitle2"
              tag="p"
              weight={700}
              color={
                (contactId || contactId === 0) && hasAvatar
                  ? 'main-text-inversed'
                  : 'focus'
              }
              noMargin
              textAlign="center"
              style={{
                textTransform: 'uppercase',
                marginBottom: 3,
                textShadow: (contactId || contactId === 0) && hasAvatar
                  ? '0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black'
                  : 'none',
                webkitFontSmoothing: 'antialiase',
                letterSpacing: '0.15em',
              }}
            >
              {name}
            </Typography>
          )
        }
        <Typography
          variant="subtitle2"
          tag="p"
          weight={700}
          color={
            (contactId || contactId === 0) && hasAvatar
              ? 'main-text-inversed'
              : 'focus'
          }
          noMargin
          textAlign="center"
          style={{
            textTransform: 'uppercase',
            textShadow: (contactId || contactId === 0) && hasAvatar
              ? '0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black'
              : 'none',
            webkitFontSmoothing: 'antialiase',
            letterSpacing: '0.15em',
          }}
        >
          {role}
        </Typography>
      </BottomLabel>

    </Root>
  );
};

PlayerItem.defaultProps = {
  hasAvatar: false,
  contactId: null,
  name: '',
  onClick: () => {},
  onDelete: () => {},
};

const {
  number,
  string,
  func,
  bool,
} = PropTypes;

PlayerItem.propTypes = {
  onClick: func,
  onDelete: func,
  contactId: number,
  hasAvatar: bool,
  name: string,
  role: string.isRequired,
};

export default PlayerItem;
