import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getLeads from './getLeads';
import getLeadById from './getLeadById';
import getLeadsForFilter from './getLeadsForFilter';
import getLeadsForStoreFilter from './getLeadsForStoreFilter'; // eslint-disable-line import/no-cycle

import getOverallLeadsSummary from './getOverallLeadsSummary';

import createLead from './createLead';
import createLeadFromNewLeadForm from './createLeadFromNewLeadForm';

import setSelectedLead from './setSelectedLead';
import setSelectedLeadRows from './setSelectedLeadRows';
import setSelectedLeadRowsExceptions from './setSelectedLeadRowsExceptions';
import setLeadViewers from './setLeadViewers';

import claimLead from './claimLead';
import claimSelectedLead from './claimSelectedLead';
import unclaimSelectedLead from './unclaimSelectedLead';

import updateLead from './updateLead';
import updateLeadPermissions from './updateLeadPermissions';
import updateSelectedLead from './updateSelectedLead';
import updateSelectedLeadPermissions from './updateSelectedLeadPermissions';
import updateSelectedLeadProducts from './updateSelectedLeadProducts';

import getSelectedLeadChangelogAllTime from './getSelectedLeadChangelogAllTime';

export { getLeadsForFilter };
export { getLeadsForStoreFilter };
export { getLeads };
export { getLeadById };

export { createLead };
export { createLeadFromNewLeadForm };
export { getOverallLeadsSummary };

export { setSelectedLead };
export { setSelectedLeadRows };
export { setSelectedLeadRowsExceptions };
export { setLeadViewers };

export { claimLead };
export { claimSelectedLead };
export { unclaimSelectedLead };

export { updateLead };
export { updateLeadPermissions };
export { updateSelectedLead };
export { updateSelectedLeadPermissions };
export { updateSelectedLeadProducts };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCrmLeadsActions = {
  getLeadsForFilter: memoizeDebounce(
    (filterId, filter, offset, limit) => store.dispatch(getLeadsForFilter(filterId)(filter)(offset, limit)),
    debounceDelay,
    debounceOptions,
  ),
  getLeadById: memoizeDebounce(
    (leadId) => store.dispatch(getLeadById(leadId)),
    debounceDelay,
    debounceOptions,
  ),
  getOverallLeadsSummary: memoizeDebounce(
    () => store.dispatch(getOverallLeadsSummary()),
    debounceDelay,
    debounceOptions,
  ),
  getLeadsForStoreFilter: memoizeDebounce(
    () => store.dispatch(getLeadsForStoreFilter()),
    debounceDelay,
    debounceOptions,
  ),
  getSelectedLeadChangelogAllTime: memoizeDebounce(
    () => store.dispatch(getSelectedLeadChangelogAllTime()),
    debounceDelay,
    debounceOptions,
  ),
};
