import requests from 'api/requests';

import getContactById from '../getContactById';

const unbindLabelFromContact = (contactId) => (labelId) => async (dispatch) => {
  try {
    await requests.labels.deleteRelationV2(labelId)(7)(contactId);

    dispatch(getContactById(contactId));
  } catch (error) {
    console.error(error);
  }
};

export default unbindLabelFromContact;
