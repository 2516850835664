import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'shared/components/UI/PlacesAutocompleteV2';

const PreferredLocationInput = (props) => {
  const {
    changeAddress,
    value,
  } = props;

  const onAddressLocationChange = useCallback((location) => {
    changeAddress({
      address: location?.formatted_address,
    });
  }, []);

  return (
    <PlacesAutocomplete
      label="Preferred location (city or exact address)"
      variant="outlined"
      defaultValue={value}
      onSelect={onAddressLocationChange}
      onChange={onAddressLocationChange}
      selectedValue={value}
    />
  );
};

PreferredLocationInput.defaultProps = {
  value: null,
};

const {
  func,
  string,
} = PropTypes;

PreferredLocationInput.propTypes = {
  changeAddress: func.isRequired,
  value: string,
};

export default PreferredLocationInput;
