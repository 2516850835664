import post from './post';
import edit from './edit';
import acknowledge from './acknowledge';
import deleteComment from './delete';
import getForObject from './getForObject';
import markAsSeen from './markAsSeen';
import resolve from './resolve';

export default {
  post,
  edit,
  delete: deleteComment,
  acknowledge,
  getForObject,
  markAsSeen,
  resolve,
};
