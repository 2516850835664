import React, {
  useState,
  useCallback,
} from 'react';

import {
  TabContext,
  TabPanel,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import Flex from 'ui/Flex';
import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';

import FollowUpTabLabel from 'sections/FollowUps/modules/FollowUpTabLabel';
import ActiveFollowUps from 'sections/FollowUps/modules/ActiveFollowUps';
import CompletedFollowUps from 'sections/FollowUps/modules/CompletedFollowUps';

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  tabPanel: {
    height: '88%',
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(128, 128, 128, 0.3)',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track-piece:end': {
      backgroundColor: 'rgba(128, 128, 128, 0.2)',
      marginBottom: '15px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      backgroundColor: 'rgba(128, 128, 128, 0.2)',
      marginTop: '15px',
      borderRadius: '10px',
    },
  },
});

const FollowUps = () => {
  const [tab, setTab] = useState('active');

  const handleTabChange = useCallback((_, value) => {
    setTab(value);
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Flex
        spacing={2}
      >
        <Icon
          type="fontawesome"
          size="m"
          color="main-text"
          name="bullhorn"
        />
        <div>
          <Typography
            variant="title2"
            color="main-text"
            noMargin
          >
            Follow Ups
          </Typography>
          <Typography
            variant="subtitle"
            color="descriptor-text"
          >
            Be sure to stay in touch!
          </Typography>
        </div>
      </Flex>

      <TabContext value={tab}>
        <Tabs
          variant="fullWidth"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab
            label={(
              <FollowUpTabLabel
                tabKey="active"
                isSelected={tab === 'active'}
                variant="title2"
                weight={tab === 'active' ? 500 : 400}
                highlightSelected={false}
              >
                Active
              </FollowUpTabLabel>
            )}
            value="active"
          />
          <Tab
            label={(
              <FollowUpTabLabel
                tabKey="new"
                isSelected={tab === 'new'}
                variant="title2"
                weight={tab === 'new' ? 500 : 400}
                highlightSelected={false}
              >
                New
              </FollowUpTabLabel>
            )}
            value="new"
          />
          <Tab
            label={(
              <FollowUpTabLabel
                tabKey="completed"
                isSelected={tab === 'completed'}
                variant="title2"
                weight={tab === 'completed' ? 500 : 400}
                highlightSelected={false}
              >
                Completed
              </FollowUpTabLabel>
            )}
            value="completed"
          />
        </Tabs>

        <TabPanel
          value="active"
          index={0}
          classes={{ root: classes.tabPanel }}
        >
          <ActiveFollowUps />
        </TabPanel>
        <TabPanel
          value="new"
          index={1}
          classes={{ root: classes.tabPanel }}
        >
          {null}
        </TabPanel>
        <TabPanel
          value="completed"
          index={2}
          classes={{ root: classes.tabPanel }}
        >
          <CompletedFollowUps />
        </TabPanel>
      </TabContext>

    </div>
  );
};

export default FollowUps;
