import { createSelector } from 'reselect';

import {
  optimizedCustomerInvoicesActions,
} from 'store/actions/customer-invoices';

export default createSelector(
  (state, id) => ({
    invoices: state.customerInvoices.invoices,
    id,
  }),
  ({ invoices, id }) => {
    if (!id) return null;

    const dataObject = invoices[id];

    if (!dataObject) {
      optimizedCustomerInvoicesActions.getInvoiceById(id);
    }

    return dataObject || {};
  },
);
