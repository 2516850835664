import { connect } from 'react-redux';

import selectors from 'store/selectors';
import {
  setNewLeadFormField,
} from 'store/actions/newRealtorLeadForm';

import SourceDescriptionForm from 'shared/components/CRM/Widgets/SourceDescriptionForm';

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm: {
      sourceDescription,
    },
  } = state;

  return {
    sourceName: selectors.crm.sourceTypeById(state),
    sourceDescription: sourceDescription || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (sourceDescription) => {
    dispatch(setNewLeadFormField('sourceDescription')(sourceDescription));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceDescriptionForm);
