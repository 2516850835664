import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import { debounce } from 'shared/utility';

import getById from './getFollowUpReminderById';
import getSummary from './getOverallSummary';

const notify = debounce((action, text) => {
  callAlert[action](text);
}, 1000, {
  leading: true,
});

const updateFollowUpReminder = (reminderId) => (updateData) => async (dispatch) => {
  try {
    await requests.followUpReminders.update(reminderId)(updateData);

    notify('success', 'Updated successfully');

    dispatch(getById(reminderId));
    dispatch(getSummary());
  } catch (error) {
    console.error(error);
    notify('error', 'Could not update');
  }
};

export default updateFollowUpReminder;
