import { createSelector } from 'reselect';

import { isEmpty } from 'shared/utility';

import lineItemById from 'store/selectors/lineItems/byId';
import packageById from 'store/selectors/warehouse/packageById';

export default createSelector(
  (state) => state,
  (_, lineItemsIds) => lineItemsIds,
  (state, lineItemsIds) => {
    const packagesRaw = lineItemsIds.reduce((acc, item) => {
      const lineItem = lineItemById(state, item);

      if (!lineItem) return acc;

      const lineItemPackages = lineItem.packages;

      lineItemPackages.forEach((packageId) => {
        const pckg = packageById(state, packageId);

        if (!isEmpty(pckg)) {
          if (!acc[pckg.id]) {
            acc[pckg.id] = pckg;
          }
        }
      });

      return acc;
    }, {});

    const packages = Object.values(packagesRaw);

    return packages;
  },
);
