import requests from 'api/requests';

import setInspection from './setInspection';

const getInspectionById = (inspectionId) => async (dispatch) => {
  try {
    const response = await requests.realtor.inspections.getById(inspectionId);
    dispatch(setInspection(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getInspectionById;
