import requests from 'api/requests';

import { map } from 'shared/utility';

import { CONTACT_TYPE_LABELS, CONTACT_TYPE_COLORS } from 'constants/realtor.constants';

const getInitials = (firstName = '', lastName = '') => {
  const fname = firstName ? firstName.split('')[0] || '' : '';
  const lname = lastName ? lastName.split('')[0] || '' : '';

  return fname + lname;
};

const getFullname = (firstName = '', lastName = '') => `${firstName || ''} ${lastName || ''}`.trim();

const processData = (contacts = []) => contacts.map((contact = {}) => {
  const {
    id,
    first_name,
    last_name,
    type,
  } = contact;

  const fullName = getFullname(first_name, last_name);
  const initials = getInitials(first_name, last_name);
  const labels = map(type, (typeId) => ({
    name: CONTACT_TYPE_LABELS[typeId],
    color: CONTACT_TYPE_COLORS[typeId],
  }));

  return {
    ...contact,
    id,
    fullName,
    initials,
    labels,
  };
});

// eslint-disable-next-line consistent-return
const searchContact = (value) => async (dispatch) => {
  try {
    const response = await requests.realtor.contacts.search(value);

    return processData(response.data.items);
  } catch (error) {
    console.error(error);
  }
};

export default searchContact;
