import { connect } from 'react-redux';

import stableStringify from 'json-stable-stringify';

import selectors from 'store/selectors';

import {
  optimizedFollowUpRemindersActions,
  setFollowUpRemindersForFilter,
  setFollowUpRemindersForFilterCount,
} from 'store/actions/followUpReminders';

import FollowUpTabPanel from 'sections/FollowUps/components/FollowUpTabPanel';

const filter = {
  status: 6,
  sort_by: 'scheduled_date',
  sort_direction: 'desc',
};

const filterId = stableStringify(filter);

const mapStateToProps = (state) => {
  const {
    followUpReminders: {
      remindersCountByFilter,
    },
  } = state;

  const items = selectors.followUpReminders.reminderListByFilterId(state, filterId, 6);
  const itemsCount = remindersCountByFilter[filterId];

  return {
    tabPanelKey: 'completed',
    items,
    itemsCount,
    loadMore: (offset, limit) => () => {
      optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(filterId, filter, offset, limit);
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearList: () => {
    dispatch(setFollowUpRemindersForFilter(filterId)(null));
    dispatch(setFollowUpRemindersForFilterCount(filterId)(0));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpTabPanel);
