import { url } from 'paths';

export default (uuid, imageUrl = null, acl = 'bucket-owner-full-control') => {
  if (imageUrl && acl === 'public-read') {
    return imageUrl.replace(new RegExp('-original$'), '-preview');
  }

  const token = localStorage.getItem('token');
  return `${url}/api/attachments/previews/${uuid}?auth=${token}`;
};
