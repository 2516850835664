import apiConnector, { endpoints } from 'api';

const createFolder = async (folderInformations) => {
  const endpoint = `${endpoints.client.articles.folderGeneral}`;

  const result = await apiConnector.client.post(endpoint, folderInformations);

  return result.data;
};

export default createFolder;
