export const ICONS = [
  {
    id: 1,
    src: '/assets/img/icons/question.png',
    alt: 'Question',
  },
  {
    id: 2,
    src: '/assets/img/icons/action.png',
    alt: 'Action',
  },
  {
    id: 3,
    src: '/assets/img/icons/summary.png',
    alt: 'Summary',
  },
  {
    id: 4,
    src: '/assets/img/icons/problem.png',
    alt: 'Problem',
  },
  {
    id: 5,
    src: '/assets/img/icons/idea.png',
    alt: 'Idea',
  },
];

export const DELETE_ICON = {
  src: '/assets/img/icons/delete.png',
  alt: 'Delete',
};

export const PROBLEM_ICON = {
  src: '/assets/img/icons/problem.png',
  alt: 'Problem',
};

export const PRODUCT_ICONS = {
  hr: 'users',
  'customer-service': 'headphones-alt',
  tasks: 'file-alt',
  sales: 'dollar-sign',

  // realtor
  today: 'calendar',
  calendar: 'calendar',
  communications: 'repeat',
  mls: 'home',
  properties: 'home',
  leads: 'users',
  prospects: 'star',
  clients: 'star',
  opportunities: 'star',
  transactions: 'repeat',
  contacts: 'book-open',
  offers: 'handshake',
  inAppMarketing: 'repeat',
  opportunitiesListing: 'list',
};
