import requests from 'api/requests';

import setPropertyViewings from './setPropertyViewings';
import setInfiniteScrollShowingsForFilter from './setInfiniteScrollShowingsForFilter';
import setViewingsForFilterCount from './setViewingsForFilterCount';

const getInfiniteScrollShowingsForFilter = (filterId) => (filter) => (
    offset = 0,
    limit = 50,
    resetState = false,
  ) => async (dispatch) => {
  try {
    const response = await requests.realtor.propertyViewings.get({
      offset,
      limit,
      ...filter,
    });

    dispatch(setPropertyViewings(response.data.items));
    dispatch(setInfiniteScrollShowingsForFilter(filterId)(response.data.items)(resetState));
    dispatch(setViewingsForFilterCount(filterId)(response.data.itemsCount));
  } catch (error) {
    console.error(error);
  }
};

export default getInfiniteScrollShowingsForFilter;
