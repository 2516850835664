import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';
import Radio from 'ui/Radio';
import Button from 'ui/Button';

import {
  Root,
  RadioGroup,
  ButtonWrapper,
} from './styledItems';

const Outcome = (props) => {
  const {
    onComplete,
    completionComment,
    isUnsuccessful,
    isCompleted,
  } = props;

  const [radioValue, setRadioValue] = useState('successful');
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(completionComment);
  }, [
    completionComment,
  ]);

  useEffect(() => {
    if (isUnsuccessful) {
      setRadioValue('unsuccessful');
    } else {
      setRadioValue('successful');
    }
  }, [
    isUnsuccessful,
  ]);

  const handleComplete = useCallback(() => {
    onComplete(comment, radioValue === 'unsuccessful');
  }, [
    comment,
    radioValue,
    onComplete,
  ]);

  return (
    <Root>
      <RadioGroup>
        <Radio
          checked={radioValue === 'successful'}
          value="successful"
          name="successful"
          displayedValue="Completed Successfully"
          onChange={setRadioValue}
          disabled={isCompleted}
        />
        <Radio
          checked={radioValue === 'unsuccessful'}
          value="unsuccessful"
          name="unsuccessful"
          displayedValue="Unsuccessful"
          onChange={setRadioValue}
          disabled={isCompleted}
        />
      </RadioGroup>

      <Textfield
        label="Comment"
        value={comment}
        onChange={setComment}
        textarea
        disabled={isCompleted}
      />

      {
        !isCompleted && (
          <ButtonWrapper>
            <Button
              variant="secondary"
              onClick={handleComplete}
            >
              Mark as Completed
            </Button>
          </ButtonWrapper>
        )
      }
    </Root>
  );
};

const {
  string,
  func,
  bool,
} = PropTypes;

Outcome.propTypes = {
  onComplete: func.isRequired,
  completionComment: string.isRequired,
  isUnsuccessful: bool.isRequired,
  isCompleted: bool.isRequired,
};

export default Outcome;
