import deleteLead from './deleteLead';

const deleteSelectedLead = () => async (dispatch, getState) => {
  const {
    realtorLeads: {
      selectedLead,
    },
  } = getState();

  dispatch(deleteLead(selectedLead));
};

export default deleteSelectedLead;
