import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getInvoices from './getInvoices';
import getInvoiceById from './getInvoiceById';

import createInvoice from './createInvoice';

import setSelectedInvoice from './setSelectedInvoice';

import updateInvoice from './updateInvoice';
import getInvoiceAttachments from './getInvoiceAttachments';

export { getInvoices };
export { getInvoiceById };

export { createInvoice };

export { setSelectedInvoice };

export { updateInvoice };
export { getInvoiceAttachments };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedCustomerInvoicesActions = {
  getInvoiceById: memoizeDebounce(
    (invoiceId) => store.dispatch(getInvoiceById(invoiceId)),
    debounceDelay,
    debounceOptions,
  ),
};
