import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} propertyRequirementsData
 */
const create = async (propertyRequirementsData) => {
  const endpoint = endpoints.client.realtor.propertyRequirements;
  try {
    const result = await apiConnector.client.post(endpoint, propertyRequirementsData);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
