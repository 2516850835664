import requests from 'api/requests';

import getLeadById from './getLeadById';
import deleteLead from './deleteLead';

const removeLeadRelations = (relationType) => (leadId) => (relationsItemsIds) => async (dispatch) => {
  try {
    await requests.realtor.leads.removeRelations(relationType)(leadId)(relationsItemsIds);

    const lead = await dispatch(getLeadById(leadId));

    if (!lead?.properties && !lead.property_requirements) {
      dispatch(deleteLead(leadId));
    }
  } catch (error) {
    console.error(error);
  }
};

export default removeLeadRelations;
