import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import RadioGroup from 'ui/RadioGroup';

import TitlesSelect from './TitlesSelect';

const salutationsStateItems = [
  {
    value: 'off',
    displayedValue: 'OFF',
  },
  {
    value: 'on',
    displayedValue: 'ON',
  },
];

const Salutations = (props) => {
  const {
    salutationsState,
    onSalutationsStateChange,
  } = props;

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item>
        <RadioGroup
          items={salutationsStateItems}
          defaultValue={salutationsState}
          onChange={onSalutationsStateChange}
          flexDirection="row"
          gap="30px"
        />
      </Grid>
      {
        salutationsState === 'on' && (
          <Grid item>
            <TitlesSelect />
          </Grid>
        )
      }
    </Grid>
  );
};

const {
  oneOf,
  func,
} = PropTypes;

Salutations.propTypes = {
  salutationsState: oneOf(['on', 'off']).isRequired,
  onSalutationsStateChange: func.isRequired,
};

export default Salutations;
