import connectReducers from 'store/connectReducers';
import { createSelector } from 'reselect';

import {
  SET_BASIC_COMPANY_INFO,
} from 'store/actionTypes/companyInfo';

// TODO - fix dependency cycles
import * as company from '../actions/MyCompany'; // eslint-disable-line import/no-cycle, no-restricted-syntax
import { CHANGE_AUTH_STATUS_SUCCESS } from '../actions/auth'; // eslint-disable-line import/no-cycle
import { // eslint-disable-line import/no-cycle
  OPEN_LICENSES_MODAL,
  CLOSE_LICENSES_MODAL,
  SHOW_LICENSE_TRANSACTION_LOG,
  GET_LICENSE_MANAGEMENT_LOG_SUCCESS,
} from '../actions/MyCompany/license-management';

import {
  PARTIALLY_UPDATE_EMPLOYEE,
} from '../actions/MyCompany/common';

const initialState = {
  companyInfo: {},
  general: null,
  keyMembers: [],
  offices: [],
  employeesTotal: 0,
  officeVisible: false,
  departments: [],
  users: [
    { name: 'All', id: 0 },
    { name: 'Users', id: 1 },
    { name: 'Employees', id: 2 },
    { name: 'Contractors', id: 3 },
  ],
  departmentVisible: false,
  employees: [],
  employeeTypes: [
    { name: 'Active Employees/Contractors', id: 0 },
    { name: 'Employees', id: 1 },
    { name: 'Contractors', id: 2 },
    { name: 'On Leave', id: 3 },
    { name: 'No Longer Employed', id: 4 },
    { name: 'Everyone', id: 5 },
  ],
  filters: ['Employee Type', 'Department', 'Office'],
  licenses: [],
  teams: [],
  licenesModal: false,
  fromLicensePage: false,
  licenseTransactionLogModal: false,
  licenseManTransactionLog: [],
};

const reducers = {
  [SET_BASIC_COMPANY_INFO]: (state, action) => ({ ...state, companyInfo: action.payload }),

  [company.GET_GENERAL_SUCCESS]: (state, action) => ({ ...state, general: action.general }),

  [SHOW_LICENSE_TRANSACTION_LOG]: (state, action) => {
    const { licenseTransactionLogModal } = state;
    return {
      ...state,
      licenseTransactionLogModal: !licenseTransactionLogModal,
    };
  },

  [company.GET_KEY_MEMBERS_SUCCESS]: (state, action) => ({
    ...state,
    keyMembers: action.keyMembers,
  }),

  [company.GET_OFFICES_SUCCESS]: (state, action) => ({ ...state, offices: action.offices }),

  [company.TRIGGER_OFFICE]: (state, action) => ({ ...state, officeVisible: !state.officeVisible }),

  [company.GET_DEPARTMENTS_SUCCESS]: (state, action) => ({
    ...state,
    departments: action.departments,
  }),

  [company.TRIGGER_DEPARTMENT]: (state, action) => ({
    ...state,
    departmentVisible: !state.departmentVisible,
  }),

  [company.GET_EMPLOYEES_SUCCESS]: (state, action) => ({
    ...state,
    employees: action.employees,
    employeesTotal: action.total,
  }),

  [PARTIALLY_UPDATE_EMPLOYEE]: (state, action) => ({
    ...state,
    employees: state.employees.reduce((collection, item) => {
      let newItem = item;

      if (action.payload.EmployeeID === item.EmployeeID) {
        newItem = {
          ...item,
          ...action.payload,
        };
      }

      collection.push(newItem);

      return collection;
    }, []),
  }),

  [company.GET_LICENSES_SUCCESS]: (state, action) => ({ ...state, licenses: action.licenses }),

  [company.GET_TEAMS_SUCCESS]: (state, action) => ({ ...state, teams: action.teams }),

  [OPEN_LICENSES_MODAL]: (state, action) => ({
    ...state,
    licenesModal: true,
    fromLicensePage: action.payload,
  }),

  [CLOSE_LICENSES_MODAL]: (state, action) => ({ ...state, licenesModal: false }),

  [GET_LICENSE_MANAGEMENT_LOG_SUCCESS]: (state, action) => ({
    ...state,
    licenseManTransactionLog: action.payload,
  }),

  [CHANGE_AUTH_STATUS_SUCCESS]: (state, action) => {
    const { employees: authStatusEmployees } = state;

    const companyEmployeeIndex = authStatusEmployees.findIndex((employee) => {
      const id = action.payload.EmployeeID;
      return employee.EmployeeID === id;
    });

    if (companyEmployeeIndex || companyEmployeeIndex === 0) {
      authStatusEmployees[companyEmployeeIndex].useravailability = action.payload.useravailability;
    }

    const updatedState = {
      ...state,
      employees: authStatusEmployees,
    };

    return updatedState;
  },

  [company.SET_EMPLOYEE_AVAILABILITY]: (state, action) => {
    const { employees = [] } = state;

    const employeeIndex = employees.findIndex((employee) => {
      const id = action.employeeId;
      return employee.EmployeeID === id;
    });

    if (employeeIndex || employeeIndex === 0) {
      if (employees[employeeIndex]) {
        employees[employeeIndex].useravailability = action.availabilityStatus;
      }
    }

    const updatedState = {
      ...state,
      employees,
    };

    return updatedState;
  },
};

const myCompany = (state = initialState, action) => connectReducers(
  reducers,
  state,
  action,
);

export default myCompany;

export const teamsByDepartmentSelector = createSelector(
  (state) => state.myCompany.teams,
  (teams, departmentTeams) => departmentTeams.map(({ TeamID }) => TeamID),
  (teams, departmentTeams) => teams.filter((team) => departmentTeams.includes(team.TeamID)),
);

export const teamEmployeesSelector = createSelector(
  (state) => state.myCompany.employees,
  (state, employees) => employees,
  (state, employees, status) => status,
  (allEmployees, employees, status) => {
    const idList = employees.map(({ EmployeeID }) => EmployeeID);
    const teamEmployees = allEmployees.filter(({ EmployeeID }) => idList.includes(EmployeeID));
    const withStatus = teamEmployees.map((employee) => ({
      ...employee,
      ...employees.find(({ EmployeeID }) => EmployeeID === employee.EmployeeID),
    }));

    return withStatus.filter(({ team2employee }) => team2employee[status]);
  },
);

export const departmentEmployeesSelector = createSelector(
  (state) => state.myCompany.employees,
  (state, employees) => employees,
  (state, employees, status) => status,
  (allEmployees, employees, status) => {
    const idList = employees.map(({ EmployeeID }) => EmployeeID);
    const teamEmployees = allEmployees.filter(({ EmployeeID }) => idList.includes(EmployeeID));
    const withStatus = teamEmployees.map((employee) => ({
      ...employee,
      ...employees.find(({ EmployeeID }) => EmployeeID === employee.EmployeeID),
    }));

    return withStatus.filter(
      ({ department2employee }) => department2employee[status],
    );
  },
);
