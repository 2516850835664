/* eslint-disable no-nested-ternary */

import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'shared/utility';

import EditImagesModal from 'sections/MLS/modals/EditImagesModal';

import SympleteIcon from 'ui/Icon';

import {
  Root,
  Carousel,
  IconWrapper,
  Icon,
  EditButton,
} from './styledItems';

const ImageCarousel = (props) => {
  const {
    images,
    propertyId,
    editable,
    externalImages,
  } = props;

  const [isMaximized, setIsMaximized] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [imagesWithMainFirst, setImagesWithMainFirst] = useState([]);

  const onToggleMaximize = useCallback((value) => () => {
    setIsMaximized(value);
  }, []);

  const onToggleEditModalVisibility = useCallback((value) => () => {
    setIsEditModalVisible(value);
  }, []);

  useEffect(() => {
    setImagesWithMainFirst(images.slice().sort((image) => (image.isMain ? -1 : 1)));
  }, [
    images,
  ]);

  return (
    <Root>
      {
        !isEmpty(imagesWithMainFirst)
        ? (
          <Carousel
            images={imagesWithMainFirst}
            isAutoPlaying
            hasMediaButton={isMaximized}
            hasThumbnails={isMaximized}
            hasIndexBoard="bottomRight"
            maxIcon={(
              <Icon
                icon="expand"
                onClick={onToggleMaximize(true)}
              />
              )}
            minIcon={(
              <Icon
                icon="compress"
                onClick={onToggleMaximize(false)}
              />
              )}
          />
          )
        : !isEmpty(externalImages)
          ? (
            <Carousel
              images={externalImages}
              isAutoPlaying
              hasMediaButton={isMaximized}
              hasThumbnails={isMaximized}
              hasIndexBoard="bottomRight"
              maxIcon={(
                <Icon
                  icon="expand"
                  onClick={onToggleMaximize(true)}
                />
                )}
              minIcon={(
                <Icon
                  icon="compress"
                  onClick={onToggleMaximize(false)}
                />
                )}
            />
            )
            : (
              <IconWrapper>
                <SympleteIcon
                  name="property"
                  type="custom"
                  color="background-flat"
                  size="xl"
                />
              </IconWrapper>
            )
      }
      {/* <Label>prop label</Label> */}

      {
        editable && (
          <>
            <EditButton onClick={onToggleEditModalVisibility(true)}>
              <Icon icon="edit" />
            </EditButton>

            <EditImagesModal
              isVisible={isEditModalVisible}
              closeModal={onToggleEditModalVisibility(false)}
              propertyId={propertyId}
            />
          </>
        )
      }
    </Root>
  );
};

ImageCarousel.defaultProps = {
  images: [],
  propertyId: undefined,
  editable: true,
  externalImages: [],
};

const {
  arrayOf,
  shape,
  string,
  number,
  bool,
} = PropTypes;

ImageCarousel.propTypes = {
  images: arrayOf(shape({
    id: string,
    url: string,
  })),
  propertyId: number,
  editable: bool,
  externalImages: arrayOf(shape({
    src: string,
  })),
};

export default ImageCarousel;
