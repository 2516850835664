import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyParametersIndicators from 'shared/components/UI/PropertyParameters/PropertyParametersIndicators';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  const data = {
    bedrooms: property.bedrooms || undefined,
    bathrooms: property.bathrooms || undefined,
    garageCapacity: property.parking || undefined,
    floorArea: property.floor_area || undefined,
  };

  return {
    data,
  };
};

export default connect(mapStateToProps)(PropertyParametersIndicators);
