import requests from 'api/requests';

import setOverallSummary from './setOverallSummary';

const getOverallSummary = () => async (dispatch) => {
  try {
    const response = await requests.realtor.offers.getSummary();
    dispatch(setOverallSummary(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getOverallSummary;
