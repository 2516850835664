import requests from 'api/requests';

import setClientsOverallSummary from './setClientsOverallSummary';

const getClientsOverallSummary = () => async (dispatch) => {
  try {
    const response = await requests.realtor.clients.getOverallSummary();

    dispatch(setClientsOverallSummary(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getClientsOverallSummary;
