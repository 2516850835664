import connectReducers from 'store/connectReducers';

import {
  SET_NEW_PROPERTY_VIEWING_FORM_FIELD,
  SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD,
  RESET_NEW_PROPERTY_VIEWING_FORM,
} from 'store/actionTypes/propertyViewings';

import {
  setValue,
  setNestedValue,
} from 'store/utils';

const initialState = {
  property_id: null,
  date_time: null,
  contacts: [],
  buyer: null,
  buyerAgent: null,
  sellerAgent: null,
  newContact: {},
  newContactFieldsValidators: {
    isEmailValid: false,
    doesEmailExist: true,
    doesPhoneExist: true,
  },
  minTime: null,
  maxTime: null,
  location: null,
  title: null,
  description: null,
  eventType: null
};

const reducers = {
  [SET_NEW_PROPERTY_VIEWING_FORM_FIELD]: setValue,
  [SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD]: setNestedValue,
  [RESET_NEW_PROPERTY_VIEWING_FORM]: () => initialState,
};

function newViewingForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newViewingForm;
