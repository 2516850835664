import {
  SET_CREDIT_NOTE,
} from 'store/actionTypes/creditNotes';

const set = (payload) => ({
  type: SET_CREDIT_NOTE,
  payload,
});

export default set;
