import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import validate from 'shared/utils/validators';

import Grid from 'ui/Grid';
import Textfield from 'ui/Textfield';
import PhoneNumberInput from 'ui/PhoneNumberInput';

const ContactDetails = (props) => {
  const {
    homePhone,
    workEmail,
    personalEmail,
    updateProfileData,
  } = props;

  const [phoneFieldError, setPhoneFieldError] = useState(null);
  const [emailFieldError, setEmailFieldError] = useState(null);

  const onChange = (key) => useDebouncedCallback((value) => {
    updateProfileData(key)(value);
  }, 1500);

  const validatePhone = useCallback((event) => {
    if (!validate.phone(event.target.value)) {
      setPhoneFieldError('Phone not valid!');
      return;
    }
    setPhoneFieldError(null);
  }, []);

  const validateEmail = useCallback((event) => {
    if (!validate.email(event.target.value)) {
      setEmailFieldError('Email not valid!');
      return;
    }
    setEmailFieldError(null);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <PhoneNumberInput
          label="Home Phone"
          icon="phone"
          value={homePhone}
          onChange={onChange('HomePhone')}
          error={phoneFieldError}
          inputProps={{
            onKeyUp: validatePhone,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Textfield
          label="Work Email"
          icon="mail"
          value={workEmail}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <Textfield
          label="Personal Email"
          icon="mail"
          value={personalEmail}
          onChange={onChange('PersonalEmail')}
          error={emailFieldError}
          inputProps={{
            onKeyUp: validateEmail,
          }}
        />
      </Grid>
    </Grid>
  );
};

const {
  string,
  func,
} = PropTypes;

ContactDetails.propTypes = {
  homePhone: string.isRequired,
  workEmail: string.isRequired,
  personalEmail: string.isRequired,
  updateProfileData: func.isRequired,
};

export default ContactDetails;
