import { connect } from 'react-redux';

import SignedAgreement from './SignedAgreement';

const mapStateToProps = (state) => {
  const {
  } = state;

  return {
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SignedAgreement);
