/* eslint-disable max-len, react/jsx-no-bind */
import React, { PureComponent } from 'react';
import {
  Checkbox, Col, Modal, Row, Tooltip,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import WriteNewButton from 'shared/components/WriteNewButtonV2';
import ArticleEditor from 'shared/components/ArticleEditor'; // eslint-disable-line import/no-cycle
import Typography from 'shared/components/Typography';
import Button from 'shared/components/Button';
import moodTypes from './index';

import './MoodUserModal.scss';

class MoodUserModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showEditor: !!(props.mood && props.mood.MoodComment),
      hideName: props.mood ? props.mood.HideUserInfo : false,
      selectedMood: props.mood ? props.mood.MoodType : null,
      userComment: props.mood ? props.mood.MoodComment : null,
    };
  }

  onConfirm = () => {
    const {
      mood,
      onSubmit,
    } = this.props;
    const {
      hideName,
      selectedMood,
      userComment,
    } = this.state;
    const result = {
      HideUserInfo: hideName,
      MoodComment: userComment,
      MoodType: selectedMood,
      MoodDate: new Date(),
      MoodID: mood.MoodID,
    };

    return onSubmit && onSubmit(result);
  };

  toggleEditor = () => {
    this.setState(({ showEditor }) => ({ showEditor: !showEditor }));
  };

  onHideNameChange = () => {
    const { hideName } = this.state;

    this.setState({
      hideName: !hideName,
    });
  };

  onSelectMood = (mood) => () => {
    this.setState({
      selectedMood: mood,
    });
  };

  onCommentChange = (comment) => {
    this.setState({
      userComment: comment,
    });
  };

  render() {
    const {
      visible,
      user,
      allowAnonymous,
      onCancel,
    } = this.props;
    const {
      showEditor,
      hideName,
      selectedMood,
      userComment,
    } = this.state;
    const tooltipText = 'Managers will not see your name or profile picture but can still see other data like department';
    const textEditorPlaceholder = 'Have anything to add?';
    const modalTitleText = `How are you, ${user.Name}?`;

    return (
      <Modal
        className="mood-user__modal"
        closable
        visible={visible}
        onCancel={onCancel}
        title={(
          <Typography
            variant="modal-title"
          >
            {modalTitleText}
          </Typography>
        )}
        footer={[
          <Row key={0}>
            <Col span={7}>
              {allowAnonymous
              && (
                <>
                  <Checkbox
                    checked={hideName}
                    onChange={this.onHideNameChange}
                  >
                    Hide my name
                  </Checkbox>
                  <Tooltip
                    placement="bottom"
                    title={tooltipText}
                  >
                    <FontAwesomeIcon
                      icon="info-circle"
                      size="sm"
                      style={{ color: '#6b88a4' }}
                    />
                  </Tooltip>
                </>
              )}
            </Col>
            <Col span={17}>
              <Button
                type="primary"
                variant="wide"
                onClick={this.onConfirm}
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row className="mood-icons-container">
          {moodTypes.map((mood) => (
            <Col
              span={4}
              key={mood.type}
              className={classnames(
                'mood-icon-element',
                {
                  selected: selectedMood === mood.type,
                },
              )}
              onClick={this.onSelectMood(mood.type)}
            >
              <div className={classnames(
                'mood-icon',
                `mood-icon--${mood.icon}`,
              )}
              />
              <div className="mood-title">
                {mood.title}
              </div>
            </Col>
          ))}
        </Row>
        {!showEditor && (
          <WriteNewButton
            onClick={this.toggleEditor}
          >
            {textEditorPlaceholder}
          </WriteNewButton>
        )}
        {showEditor
        && (
          <ArticleEditor
            mini
            value={userComment}
            onChange={this.onCommentChange}
            onTypeChange={() => {
            }}
            onCancel={() => {
            }}
          />
        )}
      </Modal>
    );
  }
}

const {
  func,
  bool,
  shape,
  number,
  string,
} = PropTypes;

MoodUserModal.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  visible: bool.isRequired,
  user: shape({
    Name: string,
  }).isRequired,
  mood: shape({
    MoodType: number,
    MoodComment: string,
    HideUserInfo: bool,
  }).isRequired,
  allowAnonymous: bool.isRequired,
};

export default MoodUserModal;
