import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedFulfillmentsActions,
} from 'store/actions/fulfillments';

export default createSelector(
  (state) => state.fulfillments.fulfillments,
  (state) => state.fulfillments.selectedFulfillment,
  (items, itemId) => {
    if (!items[itemId]) {
      if (itemId) {
        optimizedFulfillmentsActions.getFulfillmentById(itemId);
      }

      return null;
    }

    const salespersonId = items[itemId].salesperson_id;

    return salespersonId;
  },
);
