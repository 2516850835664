import requests from 'api/requests';

import setAttachments from './setAttachments';

const getAttachments = (id) => async (dispatch) => {
  try {
    const response = await requests.attachments.getObjectAttachments(39)(id);
    dispatch(setAttachments(id, response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getAttachments;
