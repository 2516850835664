import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import DocumentRequests from 'sections/Transactions/modules/DocumentRequests';

const DocumentRequestsModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    contentOptions,
  } = props;

  return (
    <Modal
      theme={theme}
      width={700}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title="Document requests"
      footer={null}
    >
      <DocumentRequests
        {...contentOptions}
      />
    </Modal>
  );
};

DocumentRequestsModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  func,
  bool,
  shape,
} = PropTypes;

DocumentRequestsModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
};

export default DocumentRequestsModal;
