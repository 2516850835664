import openModal from 'store/actions/modals/openModal';

const openModalWithContentAction = (modal) => (
  SET_CONTENT_OPTIONS_ACTION,
) => (
  contentOptions = {},
  modalOptions = {},
) => (dispatch) => {
  if (SET_CONTENT_OPTIONS_ACTION) {
    dispatch({
      type: SET_CONTENT_OPTIONS_ACTION,
      payload: contentOptions,
    });
  }

  dispatch(openModal(modal)(modalOptions, contentOptions));
};

export default openModalWithContentAction;
