/* eslint-disable react/require-default-props */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import TodosContext from '../TodosContext';

const CreationForm = (props) => {
  const {
    className,
  } = props;

  const {
    todos,
    addTodo,
  } = useContext(TodosContext);

  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm();

  const todosNames = todos.map((todo) => todo.name);

  const onSubmit = (values) => {
    addTodo({
      name: values.name,
      isCompleted: false,
    });

    reset({ name: '' });
  };

  const isUniqueName = (value) => {
    if (todosNames.indexOf(value) > -1) {
      return 'The item name must be unique.';
    }
    return true;
  };

  return (
    <form
      className={classNames(
        'todo-creation-form',
        'todos__creation-form',
        className,
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="todo-creation-form__item">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="visually-hidden" htmlFor="create-new-todo">
          Todo name:
        </label>

        <input
          className="todo-creation-form__input"
          name="name"
          type="text"
          id="create-new-todo"
          placeholder="Add a new item and press enter"
          ref={register({
            required: 'This field is required.',
            validate: isUniqueName,
          })}
        />

        {errors.name && errors.name.message && (
          <span className="todo-creation-form__validation-error">
            {errors.name.message}
          </span>
        )}
      </p>
    </form>
  );
};

CreationForm.propTypes = {
  className: PropTypes.string,
};

export default CreationForm;
