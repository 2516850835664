import { connect } from 'react-redux';
import {
  triggerCaptchaModal,
} from 'store/actions/auth';
import CaptchaModal from './CaptchaModal';

const mapStateToProps = (state) => ({
  showCaptchaModal: state.auth.showCaptchaModal,
});

const mapDispatchToProps = {
  triggerCaptchaModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaptchaModal);
