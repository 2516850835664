/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

const positions = {
  'top left': '-5px auto auto -5px',
  'top right': '-5px -5px auto auto',
  'bottom left': 'auto auto -5px -5px',
  'bottom right': 'auto -5px -5px auto',
};

export const Wrapper = styled.div`
  position: relative;
`;

export const BadgeContainer = styled.div`
  display: flex;
  z-index: 999;
  position: absolute;
  inset: ${(props) => positions[props.position]};
`;

export const NotificationBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;

  background-color: ${
    (props) => (CSS.supports('background-color', props.color)
      ? props.color
      : props.theme?.palette?.[props.color])
  };

  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
  color: white;
`;

export const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  border: 2px solid white;

  background-color: ${
    (props) => (CSS.supports('background-color', props.color)
      ? props.color
      : props.theme?.palette?.[props.color] || 'grey')
  };

  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
  color: white;
`;

export default {
  Wrapper,
  BadgeContainer,
  NotificationBadge,
  StatusBadge,
};
