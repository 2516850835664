import requests from 'api/requests';

import setMatching from './setMatching';

const getMatchingById = (id) => async (dispatch) => {
  try {
    const response = await requests.realtor.matched.getById(id);
    dispatch(setMatching(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getMatchingById;
