import { connect } from 'react-redux';

import { REALTOR_OFFER_TYPES } from 'constants/realtor.constants';

import { isEmpty } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateOffer,
} from 'store/actions/realtorOffers';

import OfferDetails from './OfferDetails';

const contigencyToTextValue = {
  contingency_inspection: 'Inspection',
  contingency_appraisal: 'Appraisal',
  contingency_loan: 'Loan',
};

const mapStateToProps = (state, ownProps) => {
  const {
    offerId,
  } = ownProps;

  const offer = selectors.realtorOffers.byId(state, offerId);

  const contingencies = Object.keys(contigencyToTextValue).reduce((accum, key) => {
    if (offer[key]) {
      accum.push(contigencyToTextValue[key]);
    }
    return accum;
  }, []);

  let specialConditions;

  if (!isEmpty(offer.special_conditions)) {
    const specialConditionsDict = selectors.dictionary.realtorSpecialConditions(state);

    specialConditions = specialConditionsDict.reduce((accum, item) => {
      if (offer.special_conditions.includes(item.id)) {
        accum.push(item.name);
      }
      return accum;
    }, []);
  }

  const {
    amount,
    type,
    all_cash,
    expiry_date,
    closing_date,
    contingency_period_end,
    move_out_date,
    note,
    contingency_inspection,
    contingency_loan,
    contingency_appraisal,
  } = offer;

  return {
    userRole: REALTOR_OFFER_TYPES[type],
    amount,
    isAllCash: all_cash,
    isNoContingency: !contingency_inspection && !contingency_loan && !contingency_appraisal,
    expiryDate: expiry_date,
    offerId,

    closingDate: closing_date,
    contigencyPeriod: contingency_period_end,
    contingencies,
    specialConditions: specialConditions || [],
    moveOutDate: move_out_date,
    notes: note,

    propertyPrice: offer?.relatedData?.property?.expected_price,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNoteChange: (value) => {
    dispatch(updateOffer(ownProps.offerId)({ note: value }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
