/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

const ThreeDots = (props) => {
  const { style, className } = props;

  return (
    <div className={classNames('three-dots', className)} style={style}>
      <span className="three-dots__item" />
      <span className="three-dots__item" />
      <span className="three-dots__item" />
    </div>
  );
};

ThreeDots.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf([PropTypes.string, PropTypes.number]),
};

export default ThreeDots;
