import store from 'store';

import { memoizeDebounce } from 'shared/utility';
import getDealProducts from './getDealProducts';

import createDealProduct from './createDealProduct';
import createSelectedDealProduct from './createSelectedDealProduct';

import updateDealProduct from './updateDealProduct';
import updateSelectedDealProduct from './updateSelectedDealProduct';

import removeDealProduct from './removeDealProduct';
import removeSelectedDealProduct from './removeSelectedDealProduct';

export { createDealProduct };
export { createSelectedDealProduct };

export { updateDealProduct };
export { updateSelectedDealProduct };

export { removeDealProduct };
export { removeSelectedDealProduct };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedDealsActions = {
  getDealProducts: memoizeDebounce(
    (id) => store.dispatch(getDealProducts(id)),
    debounceDelay,
    debounceOptions,
  ),
};
