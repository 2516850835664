import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';

import validate from 'shared/utils/validators';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import LogOfferForm from 'sections/Opportunities/forms/LogOfferForm';

import ShareModal from 'shared/modals/ShareModal';

const logOfferFormId = 'log-offer-form';

const LogOfferModal = (props) => {
  const {
    isVisible,
    closeModal,
    theme,
    title,
    label,
    shareOffer,
  } = props;

  const [contactsFields, setContactsFields] = useState({
    contactId: null,
    agentId: null,
    newContactEmail: null,
    newAgentEmail: null,
  });
  const [offerProperty, setOfferProperty] = useState(null);
  const [offerValue, setOfferValue] = useState(null);

  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [shouldShare, setShouldShare] = useState(false);
  const [createdOfferId, setCreatedOfferId] = useState(null);

  const onNewContactsChange = useDebouncedCallback((fields) => {
    const contact = fields.new_contact_buyer || fields.new_contact_seller;
    const agent = fields.new_contact_buyer_agent || fields.new_contact_seller_agent;

    if (validate.email(contact?.email_primary) && contactsFields.newContactEmail !== contact?.email_primary) {
      setContactsFields((oldFields) => ({
        ...oldFields,
        newContactEmail: contact.email_primary,
      }));
    }
    if (validate.email(agent?.email_primary) && contactsFields.newAgentEmail !== agent?.email_primary) {
      setContactsFields((oldFields) => ({
        ...oldFields,
        newAgentEmail: agent.email_primary,
      }));
    }
  }, 1000);

  const onExistingContactsChange = useCallback((values) => {
    const {
      selectedBuyerContactId,
      selectedSellerContactId,
      selectedBuyerAgentId,
      selectedSellerAgentId,
    } = values;

    const contactId = selectedBuyerContactId || selectedSellerContactId;
    const agentId = selectedBuyerAgentId || selectedSellerAgentId;

    setContactsFields((oldFields) => ({
      ...oldFields,
      contactId,
      agentId,
    }));
  }, []);

  const canShare = offerProperty
    && offerValue
    && (
      (contactsFields.contactId || contactsFields.newContactEmail)
      && (contactsFields.agentId || contactsFields.newAgentEmail)
    );

  const onCreateAndShare = useCallback(() => {
    setShouldShare(true);
  }, []);

  const onAfterSubmit = useCallback((offerId) => {
    if (shouldShare) {
      setCreatedOfferId(offerId);
      setIsShareModalVisible(true);
    } else {
      closeModal();
    }
  }, [
    shouldShare,
    closeModal,
  ]);

  const onShareConfirm = useCallback(({ emails }) => {
    shareOffer(createdOfferId)(emails);
    setIsShareModalVisible(false);
    closeModal();
  }, [
    createdOfferId,
    closeModal,
    shareOffer,
  ]);

  const onShareCancel = useCallback(() => {
    setIsShareModalVisible(false);
    closeModal();
  }, [
    closeModal,
  ]);

  return (
    <Modal
      theme={theme}
      width={700}
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      title={title}
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          disabled={!canShare}
          onClick={onCreateAndShare}
          htmlType="submit"
          form={logOfferFormId}
        >
          Create & Share
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={logOfferFormId}
        >
          {label}
        </Button>,
      ]}
    >
      <LogOfferForm
        formId={logOfferFormId}
        onAfterSubmit={onAfterSubmit}
        onNewContactsChange={onNewContactsChange}
        onExistingContactsChange={onExistingContactsChange}
        onPropertyChange={setOfferProperty}
        onOfferValueChange={setOfferValue}
      />

      <ShareModal
        isVisible={isShareModalVisible}
        closeModal={onShareCancel}
        title="Share Offer"
        subtitle="Share this offer to email addresses and phone numbers."
        emails={[contactsFields.newContactEmail, contactsFields.newAgentEmail]}
        contactsIds={[contactsFields.contactId, contactsFields.agentId]}
        onSend={onShareConfirm}
      />
    </Modal>
  );
};

LogOfferModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  func,
  bool,
  shape,
  string,
} = PropTypes;

LogOfferModal.propTypes = {
  closeModal: func.isRequired,
  isVisible: bool,
  theme: shape(),
  label: string.isRequired,
  title: string.isRequired,
  shareOffer: func.isRequired,
};

export default LogOfferModal;
