import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import createOffer from './create';
import getOfferById from './getOfferById';
import getOfferWithCountersById from './getOfferWithCountersById';
import getOfferAttachments from './getOfferAttachments';
import setOfferAttachments from './setOfferAttachments';
import getOverallSummary from './getOverallSummary';
import updateOffer from './updateOffer';
import share from './share';

export {
  createOffer,
  getOfferById,
  getOfferWithCountersById,
  updateOffer,
  setOfferAttachments,
  share,
  getOverallSummary,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedOfferActions = {
  getOverallSummary: memoizeDebounce(
    () => store.dispatch(getOverallSummary()),
    debounceDelay,
    debounceOptions,
  ),
  getOfferAttachments: memoizeDebounce(
    (id) => store.dispatch(getOfferAttachments(id)),
    debounceDelay,
    debounceOptions,
  ),
  getOfferById: memoizeDebounce(
    (id) => store.dispatch(getOfferById(id)),
    debounceDelay,
    debounceOptions,
  ),
  getOfferWithCountersById: memoizeDebounce(
    (id) => store.dispatch(getOfferWithCountersById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
