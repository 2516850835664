import {
  SET_FULFILLMENTS,
} from 'store/actionTypes/fulfillments';

const setFulfillments = (fulfillments) => ({
  type: SET_FULFILLMENTS,
  payload: fulfillments,
});

export default setFulfillments;
