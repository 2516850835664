import { connect } from 'react-redux';

import { recoverPassword, triggerRecoverModal } from 'store/actions/auth';
import RecoverPassword from './RecoverPassword';

const mapStateToProps = (state) => {
  const {
    auth: {
      recoverModal,
      userReset: {
        email,
      },
    },
  } = state;

  return {
    recoverModal,
    email,
  };
};

const mapDispatchToProps = {
  recoverPassword,
  trigger: triggerRecoverModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecoverPassword);
