import { SET_CALLEE } from 'store/actionTypes/call';

const setCallee = (payload) => (dispatch) => {
  dispatch({
    type: SET_CALLEE,
    payload,
  });
};

export default setCallee;
