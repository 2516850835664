import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import NewClientForm from 'sections/Clients/forms/NewClientForm';

const newClientFormId = 'new-realtor-client-form';

const NewClientModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    title,
  } = props;

  return (
    <Modal
      destroyOnClose
      theme={theme}
      width={600}
      visible={isVisible}
      onCancel={closeModal}
      title={title}
      footer={[
        <Button
          onClick={closeModal}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={newClientFormId}
        >
          Create Client
        </Button>,
      ]}
    >
      <NewClientForm formId={newClientFormId} />
    </Modal>
  );
};

NewClientModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

NewClientModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
};

export default NewClientModal;
