// eslint-disable-next-line import/no-cycle
import {
  getConnector,
  apiConnector,
  tokenFetch as fetch,
} from 'shared/API';

import { callAlert } from 'ui/Alert';
import { url } from 'paths';

import requests from 'api/requests';

import { // eslint-disable-line import/no-cycle
  optimizedQueueActions,
  removeQueue,
} from 'store/actions/queuesV2';

export const GET_QUEUE_SETUP_LIST = 'GET_QUEUE_SETUP_LIST';
export function getQueueSetupList() {
  return {
    type: GET_QUEUE_SETUP_LIST,
  };
}

export const GET_QUEUE_SETUP_LIST_SUCCESS = 'GET_QUEUE_SETUP_LIST_SUCCESS';
export function getQueueSetupListSuccess(list) {
  return {
    type: GET_QUEUE_SETUP_LIST_SUCCESS,
    queueSetupList: list,
  };
}

export const GET_QUEUE_SETUP_LIST_ERROR = 'GET_QUEUE_SETUP_LIST_ERROR';
export function getQueueSetupListError() {
  return {
    type: GET_QUEUE_SETUP_LIST_ERROR,
  };
}

export const GET_QUEUE_CATEGORIES = 'GET_QUEUE_CATEGORIES';
export function getQueueCategories() {
  return {
    type: GET_QUEUE_CATEGORIES,
  };
}

export const GET_QUEUE_CATEGORIES_SUCCESS = 'GET_QUEUE_CATEGORIES_SUCCESS';
export function getQueueCategoriesSuccess(id, categories) {
  return {
    type: GET_QUEUE_CATEGORIES_SUCCESS,
    queueId: id,
    queueCategories: categories,
  };
}

export const GET_QUEUE_CATEGORIES_ERROR = 'GET_QUEUE_CATEGORIES_ERROR';
export function getQueueCategoriesError() {
  return {
    type: GET_QUEUE_CATEGORIES_ERROR,
  };
}

export const GET_QUEUE_SUBCATEGORIES = 'GET_QUEUE_SUBCATEGORIES';
export function getQueueSubCategories() {
  return {
    type: GET_QUEUE_SUBCATEGORIES,
  };
}

export const GET_QUEUE_SUBCATEGORIES_SUCCESS = 'GET_QUEUE_SUBCATEGORIES_SUCCESS';
export function getQueueSubCategoriesSuccess(id, categoryId, subCategories) {
  return {
    type: GET_QUEUE_SUBCATEGORIES_SUCCESS,
    queueId: id,
    categoryId,
    queueSubCategories: subCategories,
  };
}

export const GET_QUEUE_SUBCATEGORIES_ERROR = 'GET_QUEUE_SUBCATEGORIES_ERROR';
export function getQueueSubCategoriesError() {
  return {
    type: GET_QUEUE_SUBCATEGORIES_ERROR,
  };
}

export const ADD_QUEUE = 'ADD_QUEUE';
export function addQueue(queue) {
  return {
    type: ADD_QUEUE,
    queue,
  };
}

export const ADD_QUEUE_SUCCESS = 'ADD_QUEUE_SUCCESS';
export function addQueueSuccess(queue) {
  return {
    type: ADD_QUEUE_SUCCESS,
    newQueue: queue,
  };
}

export const ADD_QUEUE_ERROR = 'ADD_QUEUE_ERROR';
export function addQueueError() {
  return {
    type: ADD_QUEUE_ERROR,
  };
}

export const DELETE_QUEUE = 'DELETE_QUEUE';
export function deleteQueue() {
  return {
    type: DELETE_QUEUE,
  };
}

export const DELETE_QUEUE_SUCCESS = 'DELETE_QUEUE_SUCCESS';
export function deleteQueueSuccess(queueId) {
  return {
    type: DELETE_QUEUE_SUCCESS,
    queueId,
  };
}

export const DELETE_QUEUE_ERROR = 'DELETE_QUEUE_ERROR';
export function deleteQueueError() {
  return {
    type: DELETE_QUEUE_ERROR,
  };
}

export const ADD_CATEGORY = 'ADD_CATEGORY';
export function addCategory(category) {
  return {
    type: ADD_CATEGORY,
    category,
  };
}

export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export function addCategorySuccess(queueId, category) {
  return {
    type: ADD_CATEGORY_SUCCESS,
    queueId,
    newCategory: category,
  };
}

export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export function addCategoryError() {
  return {
    type: ADD_CATEGORY_ERROR,
  };
}

export const EIDT_CATEGORY = 'EIDT_CATEGORY';
export function editCategory() {
  return {
    type: EIDT_CATEGORY,
  };
}

export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export function editCategorySuccess(queueId, categoryId, category) {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    queueId,
    categoryId,
    editCategory: category,
  };
}

export const EIDT_CATEGORY_ERROR = 'EIDT_CATEGORY_ERROR';
export function editCategoryError() {
  return {
    type: EIDT_CATEGORY_ERROR,
  };
}

export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY';
export function addSubCategory(subcategory) {
  return {
    type: ADD_SUBCATEGORY,
    subcategory,
  };
}

export const ADD_SUBCATEGORY_SUCCESS = 'ADD_SUBCATEGORY_SUCCESS';
export function addSubCategorySuccess(queueId, categoryId, subCategory) {
  return {
    type: ADD_SUBCATEGORY_SUCCESS,
    queueId,
    categoryId,
    newSubCategory: subCategory,
  };
}

export const ADD_SUBCATEGORY_ERROR = 'ADD_SUBCATEGORY_ERROR';
export function addSubCategoryError() {
  return {
    type: ADD_SUBCATEGORY_ERROR,
  };
}

export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY';
export function editSubCategory() {
  return {
    type: EDIT_SUBCATEGORY,
  };
}

export const EDIT_SUBCATEGORY_SUCCESS = 'EDIT_SUBCATEGORY_SUCCESS';
// eslint-disable-next-line max-params
export function editSubCategorySuccess(queueId, categoryId, subCategoryId, subCategory) {
  return {
    type: EDIT_SUBCATEGORY_SUCCESS,
    queueId,
    categoryId,
    subCategoryId,
    editSubCategory: subCategory,
  };
}

export const EDIT_SUBCATEGORY_ERROR = 'EDIT_SUBCATEGORY_ERROR';
export function editSubCategoryError() {
  return {
    type: EDIT_SUBCATEGORY_ERROR,
  };
}

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export function deleteCategory() {
  return {
    type: DELETE_CATEGORY,
  };
}

export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export function deleteCategorySuccess(queueId, category) {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    queueId,
    categoryId: category,
  };
}

export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export function deleteCategoryError() {
  return {
    type: DELETE_CATEGORY_ERROR,
  };
}

export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';
export function deleteSubCategory() {
  return {
    type: DELETE_SUBCATEGORY,
  };
}

export const DELETE_SUBCATEGORY_SUCCESS = 'DELETE_SUBCATEGORY_SUCCESS';
export function deleteSubCategorySuccess(queueId, categoryId, subCategory) {
  return {
    type: DELETE_SUBCATEGORY_SUCCESS,
    queueId,
    categoryId,
    subCategoryId: subCategory,
  };
}

export const DELETE_SUBCATEGORY_ERROR = 'DELETE_SUBCATEGORY_ERROR';
export function deleteSubCategoryError() {
  return {
    type: DELETE_SUBCATEGORY_ERROR,
  };
}

export const OPEN_MODAL = 'OPEN_QUEUE_MODAL';
export function openModal() {
  return {
    type: OPEN_MODAL,
  };
}

export const HIDE_MODAL = 'HIDE_QUEUE_MODAL';
export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

export const SET_ACTIVE_QUEUE = 'SET_ACTIVE_QUEUE';
export function setActiveQueue(activeQueue) {
  return {
    type: SET_ACTIVE_QUEUE,
    activeQueue,
  };
}

export const GET_ACTIVE_QUEUE = 'GET_ACTIVE_QUEUE';
export function getActiveQueue(activeQueue) {
  return {
    type: GET_ACTIVE_QUEUE,
    activeQueue,
  };
}

export const GET_QUEUE_DATA = 'GET_QUEUE_DATA';
export function getQueueData() {
  return { type: GET_QUEUE_DATA };
}

export const GET_QUEUE_DATA_SUCCESS = 'GET_QUEUE_DATA_SUCCESS';
export function getQueueDataSuccess(queueId, members, tickets) {
  return {
    type: GET_QUEUE_DATA_SUCCESS,
    queueId,
    members,
    tickets,
  };
}

export const GET_QUEUE_DATA_ERROR = 'GET_QUEUE_DATA_ERROR';
export function getQueueDataError() {
  return { type: GET_QUEUE_DATA_ERROR };
}

export const SET_QUEUE_MEMBERS = 'SET_QUEUE_MEMBERS';
export const setQueueMembers = () => ({ type: SET_QUEUE_MEMBERS });

export const SET_QUEUE_MEMBERS_SUCCESS = 'SET_QUEUE_MEMBERS_SUCCESS';
export const setQueueMembersSuccess = (queueId, members) => (dispatch) => {
  dispatch({
    type: SET_QUEUE_MEMBERS_SUCCESS,
    queueId,
    members,
  });
  dispatch(getQueuePermissions());
};

export const SET_QUEUE_MEMBERS_ERROR = 'SET_QUEUE_MEMBERS_ERROR';
export const setQueueMembersError = () => ({ type: SET_QUEUE_MEMBERS_ERROR });

export const GET_QUEUE_PERMISSIONS = 'GET_QUEUE_PERMISSIONS';
export const getQueuePermissions = () => (dispatch) => {
  dispatch({ type: GET_QUEUE_PERMISSIONS });
  getConnector('/api/tickets/getEmployeeQueue')
    .then((permissions) => dispatch(getQueuePermissionsSuccess(permissions)))
    .catch((err) => dispatch(getQueuePermissionsError()));
};

export const GET_QUEUE_PERMISSIONS_SUCCESS = 'GET_QUEUE_PERMISSIONS_SUCCESS';
export const getQueuePermissionsSuccess = (permissions) => ({
  type: GET_QUEUE_PERMISSIONS_SUCCESS,
  permissions,
});

export const GET_QUEUE_PERMISSIONS_ERROR = 'GET_QUEUE_PERMISSIONS_ERROR';
export const getQueuePermissionsError = () => ({ type: GET_QUEUE_PERMISSIONS_ERROR });

export const UPDATE_TICKET_QUEUE = 'UPDATE_TICKET_QUEUE';
export const updateTicketQueue = (payload) => (dispatch, getState) => ({
  type: UPDATE_TICKET_QUEUE,
  payload,
});

export const addQueueApi = (queue) => (dispatch) => {
  dispatch(addQueue());
  fetch(`${url}/Tickets/AddQueue`, {
    method: 'POST',
    body: JSON.stringify({ queue }),
  })
    .then((res) => res.json())
  // eslint-disable-next-line consistent-return
    .then((json) => {
      if (json.err && json.err.code) {
        return callAlert.error('This name is already in use');
      }

      optimizedQueueActions.getAllQueues();
      dispatch(hideModal());
    })
    .catch((err) => dispatch(addQueueError()));
};

export const getQueuesFromApi = (isStandard = true) => (dispatch, getState) => getQueues(
  dispatch, isStandard, getState,
);

/*
  Why is this called `getDeleteQueueFromApi` - that's a super weird name?
  Why do we use a post request to delete something?
*/
export const getDeleteQueueFromApi = (id) => (dispatch) => {
  const deleteURL = '/Tickets/DeleteQueue';
  dispatch(deleteQueue());
  return apiConnector(deleteURL, 'POST', { queue: { QueueID: id } })
    .then((res) => { removeQueue(id); })
    .catch((err) => dispatch(deleteQueueError()));
};

export const getQueues = (dispatch, isStandard, getState) => {
  dispatch(getQueueSetupList());
  return fetch(`${url}/Tickets/ReturnAllQueues`, {}, isStandard)
    .then((res) => res.json())
    .then((json) => {
      const updatedQueues = json.queues.map((queue, index) => ({
        ...queue,
        categories: [],
        tickets: [],
        members: [],
      }));

      dispatch(getQueueSetupListSuccess(updatedQueues));
      return json.queues;
    })
    .then((queues) => {
      const queuesId = [];
      const queueDataTab = [];
      const categoriesTab = [];
      queues.forEach((queue) => {
        const { QueueID } = queue;
        queuesId.push(QueueID);

        const queueData = fetch(
          `${url}/api/queues/queue/${QueueID}`,
          {},
          isStandard,
        ).then((res) => res.json());

        queueDataTab.push(queueData);
        dispatch(getQueueData());
        const categories = fetch(
          `${url}/Tickets/QueueReturnAllCategories/${QueueID}`,
          {},
          isStandard,
        ).then((res) => res.json());

        categoriesTab.push(categories);
        dispatch(getQueueCategories());
      });

      Promise.all(queueDataTab)
        .then((queueData) => queueData.forEach((queue) => {
          const { QueueID, employees, tickets } = queue;
          dispatch(getQueueDataSuccess(QueueID, employees, tickets));
        }))
        .catch((err) => dispatch(getQueueDataError()));

      Promise.all(categoriesTab)
        .then((queueCategories) => queueCategories.forEach((queueCategory, index) => {
          const { categories } = queueCategory;
          dispatch(getQueueCategoriesSuccess(queuesId[index], categories.map(
            (category) => ({ ...category, subCategories: [] }),
          )));
          const categoriesId = [];
          const subCategoriesTab = [];
          categories.forEach((category) => {
            const { CategoryID } = category;
            categoriesId.push(CategoryID);

            const subCategory = fetch(
              `${url}/Tickets/CategoryReturnAllSubCategories/${CategoryID}`,
              {},
              isStandard,
            ).then((res) => res.json());

            subCategoriesTab.push(subCategory);
            dispatch(getQueueSubCategories());
          });
          Promise.all(subCategoriesTab)
            .then((subCategories) => {
              subCategories.forEach((subCategory, i) => {
                const { subcategories } = subCategory;
                dispatch(getQueueSubCategoriesSuccess(
                  queuesId[index],
                  categoriesId[i],
                  subcategories,
                ));
              });
            })
            .catch((err) => dispatch(getQueueSubCategoriesError()));
        }))
        .catch((err) => dispatch(getQueueCategoriesError()));
    })
    .catch((error) => dispatch(getQueueSetupListError()));
};

export const updateAssignedUsers = (role) => (queueId) => (userList) => async (dispatch) => {
  try {
    await requests.queues.updateAssignedUsers[role](queueId)(userList);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(setQueueMembersError());
  }
};

export const editQueue = ({ id, name, description }) => async (dispatch) => {
  try {
    await requests.queues.edit(id)({ name, description });
    optimizedQueueActions.getQueueById(id);
  } catch (error) {
    dispatch(addQueueError());
  }
};

export const createSubqueue = ({ queueId, subqueueName }) => async (dispatch) => {
  try {
    await requests.queues.addSubqueue(queueId)(subqueueName);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(addCategoryError());
  }
};

export const deleteSubqueue = (subqueueId, queueId) => async (dispatch) => {
  try {
    await requests.queues.deleteSubqueue(subqueueId);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(addCategoryError());
  }
};

export const editSubqueue = ({ id, name, queueId }) => async (dispatch) => {
  try {
    await requests.queues.editSubqueue(id)(name);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(editCategoryError());
  }
};

export const createSubcategory = ({ subqueueId, subcategoryName, queueId }) => async (dispatch) => {
  try {
    await requests.queues.addSubcategory(subqueueId)(subcategoryName);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(addCategoryError());
  }
};

export const deleteSubcategory = (subcategoryId, queueId) => async (dispatch) => {
  try {
    await requests.queues.deleteSubcategory(subcategoryId);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(addCategoryError());
  }
};

export const editSubcategory = ({ id, name, queueId }) => async (dispatch) => {
  try {
    await requests.queues.editSubcategory(id)(name);
    optimizedQueueActions.getQueueById(queueId);
  } catch (error) {
    dispatch(editCategoryError());
  }
};
