import apiConnector, { endpoints } from 'api';

const getQueues = async (query) => {
  const endpoint = endpoints.client.v2.queues;

  try {
    const result = await apiConnector.client.get(endpoint, query);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getQueues;
