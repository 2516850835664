import connectReducers from 'store/connectReducers';

import {
  SET_APP_CUSTOMIZATION_SETTINGS,
} from 'store/actionTypes/appCustomization';

const initialState = {
  settings: {},
};

const reducers = {
  [SET_APP_CUSTOMIZATION_SETTINGS]: (state, action) => ({
    ...state,
    settings: {
      ...action.payload,
    },
  }),
};

function connector(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default connector;
