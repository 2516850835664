export const sentiments = {
  1: 'positive',
  2: 'neutral',
  3: 'negative',
};

export const sentimentIcons = {
  positive: 'thumbs-up',
  neutral: 'meh',
  negative: 'thumbs-down',
};
