import styled from 'styled-components';

const size = 50;

export const CancelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(232, 232, 232, 0.8);
  visibility: hidden;
`;

export const Avatar = styled.div`
  width: ${size}px;
  height: ${size}px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: 30px;
  overflow: hidden;

  &:hover ${CancelWrapper} {
    visibility: visible;
  }
`;

export default {
  Avatar,
  CancelWrapper,
};
