import { createSelector } from 'reselect';

const offices = (state) => state.myCompany.offices;

const fullAddress = createSelector(
  offices,
  (items) => {
    const fields = {};

    if (items) {
      const office = items.find((item) => (
        item.OfficeAddress1 || item.OfficeAddress2
      ));

      if (office) {
        const address = office.OfficeAddress1 || office.OfficeAddress2;
        const zip = office.OfficeZip;
        const country = office.OfficeCountry;

        if (address) {
          fields.address = address;
        }

        if (zip) {
          fields.zip = zip;
        }

        if (country) {
          fields.country = country;
        }
      }
    }

    return fields;
  },
);

export default fullAddress;
