import styled from 'styled-components';

export const Root = styled.div`
  padding: 5px;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: ${((props) => props.theme?.palette?.hover || '##edeef0')};;
  }
`;

export default {
  Root,
};
