import { connect } from 'react-redux';

import selectors from 'store/selectors';
import BuyerLead from './BuyerLead';

const mapStateToProps = (state, ownProps) => {
  const options = selectors.realtorLeads.leadDetailsOptions(state, ownProps.leadId);

  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  return {
    itemId: options[0].itemId,
    contactId: lead?.contact_id,
  };
};

export default connect(mapStateToProps, null)(BuyerLead);
