export const SET_NEW_REALTOR_PROPERTY_FORM_FIELD = 'SET_NEW_REALTOR_PROPERTY_FORM_FIELD';
export const SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD = 'SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD';
export const ADD_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM = 'ADD_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM';
export const REMOVE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM = 'REMOVE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM';
export const UPDATE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM = 'UPDATE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM';
export const RESET_NEW_REALTOR_PROPERTY_FORM = 'RESET_NEW_REALTOR_PROPERTY_FORM';
export const SET_NEW_REALTOR_PROPERTY_FORM = 'SET_NEW_REALTOR_PROPERTY_FORM';
export const SET_NEW_REALTOR_PROPERTY_MODAL_OPEN = 'SET_NEW_REALTOR_PROPERTY_MODAL_OPEN';
export const SET_NEW_REALTOR_PROPERTY_MODAL_CLOSED = 'SET_NEW_REALTOR_PROPERTY_MODAL_CLOSED';
export const SET_REALTOR_PROPERTY = 'SET_REALTOR_PROPERTY';
export const SET_REALTOR_PROPERTIES = 'SET_REALTOR_PROPERTIES';
export const SET_REALTOR_PROPERTIES_FOR_FILTER = 'SET_REALTOR_PROPERTIES_FOR_FILTER';
export const SET_REALTOR_PROPERTIES_FOR_FILTER_COUNT = 'SET_REALTOR_PROPERTIES_FOR_FILTER_COUNT';
export const SET_REALTOR_OVERALL_PROPERTIES_SUMMARY = 'SET_REALTOR_OVERALL_PROPERTIES_SUMMARY';
export const SET_REALTOR_SELECTED_PROPERTY = 'SET_REALTOR_SELECTED_PROPERTY';
export const SET_REALTOR_SELECTED_PROPERTY_ROWS = 'SET_REALTOR_SELECTED_PROPERTY_ROWS';
export const SET_REALTOR_SELECTED_PROPERTY_PROPERTY = 'SET_REALTOR_SELECTED_PROPERTY_PROPERTY';
export const SET_REALTOR_PROPERTY_CHANGELOG = 'SET_REALTOR_PROPERTY_CHANGELOG';
export const SET_REALTOR_PROPERTY_VIEWERS = 'SET_REALTOR_PROPERTY_VIEWERS';

export const SET_ARCHIVE_DELETE_PROPERTY_MODAL_OPEN = 'SET_ARCHIVE_DELETE_PROPERTY_MODAL_OPEN';
export const SET_ARCHIVE_DELETE_PROPERTY_MODAL_CLOSED = 'SET_ARCHIVE_DELETE_PROPERTY_MODAL_CLOSED';
export const SET_ARCHIVE_DELETE_PROPERTY_MODAL_SELECTED_ACTION = 'SET_ARCHIVE_DELETE_PROPERTY_MODAL_SELECTED_ACTION';

export const SET_PROPERTY_INTO_PROSPECT_MODAL_OPEN = 'SET_PROPERTY_INTO_PROSPECT_MODAL_OPEN';
export const SET_PROPERTY_INTO_PROSPECT_MODAL_CLOSED = 'SET_PROPERTY_INTO_PROSPECT_MODAL_CLOSED';

export const SET_REALTOR_PROPERTIES_FILTER_ITEM = 'SET_REALTOR_PROPERTIES_FILTER_ITEM';
export const RESET_REALTOR_PROPERTIES_FILTER_ITEM = 'RESET_REALTOR_PROPERTIES_FILTER_ITEM';
export const CLEAR_REALTOR_PROPERTIES_FILTER = 'CLEAR_REALTOR_PROPERTIES_FILTER';

export default {
  SET_NEW_REALTOR_PROPERTY_FORM_FIELD,
  SET_NEW_REALTOR_PROPERTY_FORM_NESTED_FIELD,

  ADD_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM,
  REMOVE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM,
  UPDATE_NEW_REALTOR_PROPERTY_FORM_LIST_ITEM,
  RESET_NEW_REALTOR_PROPERTY_FORM,
  SET_NEW_REALTOR_PROPERTY_FORM,

  SET_NEW_REALTOR_PROPERTY_MODAL_OPEN,
  SET_NEW_REALTOR_PROPERTY_MODAL_CLOSED,

  SET_REALTOR_PROPERTY,
  SET_REALTOR_PROPERTIES,
  SET_REALTOR_PROPERTIES_FOR_FILTER,
  SET_REALTOR_PROPERTIES_FOR_FILTER_COUNT,
  SET_REALTOR_OVERALL_PROPERTIES_SUMMARY,
  SET_REALTOR_SELECTED_PROPERTY,
  SET_REALTOR_SELECTED_PROPERTY_ROWS,
  SET_REALTOR_SELECTED_PROPERTY_PROPERTY,
  SET_REALTOR_PROPERTY_CHANGELOG,
  SET_REALTOR_PROPERTY_VIEWERS,

  SET_ARCHIVE_DELETE_PROPERTY_MODAL_OPEN,
  SET_ARCHIVE_DELETE_PROPERTY_MODAL_CLOSED,
  SET_ARCHIVE_DELETE_PROPERTY_MODAL_SELECTED_ACTION,

  SET_PROPERTY_INTO_PROSPECT_MODAL_OPEN,
  SET_PROPERTY_INTO_PROSPECT_MODAL_CLOSED,

  SET_REALTOR_PROPERTIES_FILTER_ITEM,
  RESET_REALTOR_PROPERTIES_FILTER_ITEM,
  CLEAR_REALTOR_PROPERTIES_FILTER,
};
