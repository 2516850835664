const moodTypes = [
  {
    type: 1,
    icon: 'bad',
    title: 'Bad',
  },
  {
    type: 2,
    icon: 'stressed',
    title: 'Stressed',
  },
  {
    type: 3,
    icon: 'ok',
    title: 'OK',
  },
  {
    type: 4,
    icon: 'good',
    title: 'Good',
  },
  {
    type: 5,
    icon: 'excellent',
    title: 'Excellent',
  },
];

export default moodTypes;
