import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedPropertyActions,
} from 'store/actions/properties';

const defaultProperty = {
  id: null,
  address_id: null,
};

export default createSelector(
  (state) => state.properties.properties,
  (state) => state.properties.selectedProperty,
  (collection, selectedId) => {
    const item = collection[selectedId];

    if (!item) {
      if (selectedId) {
        optimizedPropertyActions.getPropertyById(selectedId);
      }

      return defaultProperty;
    }

    return item;
  },
);
