// TODO: move to usePropertyDisclosures hook

import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  createDisclosure,
  updateDisclosure,
  removeDisclosureRelations,
} from 'store/actions/realtorNotablePointsAndDisclosures/disclosures';

import PropertyDisclosures from './PropertyDisclosures';

const mapStateToProps = (state, ownProps) => ({
    selectedProperty: ownProps.propertyId,
  });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate: async (disclosureData) => {
    await dispatch(createDisclosure({
      ...disclosureData,
      object_type: 27,
      object_id: ownProps.propertyId,
    }));
  },

  onChange: debounce(async (disclosureId, disclosureData, onAfter) => {
    await dispatch(updateDisclosure(disclosureId)({
      ...disclosureData,
      object_type: 27,
      object_id: ownProps.propertyId,
    }));
    onAfter();
  }, 1000),

  onDelete: async (disclosureId) => {
    await dispatch(removeDisclosureRelations('objects')(disclosureId)({
      object_type: 27,
      object_id: ownProps.propertyId,
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onChange: (disclosureId) => (key) => (value) => (onAfter) => {
    dispatchProps.onChange(
      disclosureId,
      {
        [key]: value,
      },
      onAfter,
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyDisclosures);
