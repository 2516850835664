import React from 'react';
import { Modal, Button } from 'antd';

import '../PermissionModal/PermissionModal.scss';
import './ReportModal.scss';

const ReportModal = ({ isOpen, handleReload, toggleModal }) => (
  <Modal
    onCancel={toggleModal}
    mask={false}
    wrapClassName="report-modal"
    footer={[
      <Button
        key="back"
        shape="round"
        size="small"
        type="primary"
        onClick={handleReload}
      >
          Reload
      </Button>,
    ]}
    visible={isOpen}
  >
    <p>
        Thank you for your feedback.
    </p>
    <p>
        We have logged this error and are working to fix it.
    </p>
  </Modal>
);

export default ReportModal;
