function detectInactivity(timeoutInMinutes = 1, onEnd = () => { alert("You've been idle for too long"); }) {
  const timeoutInMiliseconds = timeoutInMinutes * 60 * 1000;
  let timeoutId;

  function startTimer() {
    timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds);
  }

  function resetTimer() {
    window.clearTimeout(timeoutId);
    startTimer();
  }

  function doInactive() {
    onEnd();
  }

  function setupTimers() {
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('mousedown', resetTimer, false);
    document.addEventListener('keypress', resetTimer, false);
    document.addEventListener('touchmove', resetTimer, false);

    startTimer();
  }

  setupTimers();
}

export default detectInactivity;
