/**
 * @typedef {Object} ImageDimensions image dimensions object
 * @property {number} width
 * @property {number} height
 * @property {number} resolutionInMP
 * @property {number} ratio smaller side to bigger side ratio
 */

/**
 *
 * @param {File | Blob} file
 * @return {ImageDimensions}
 */
const getImageDimensionsFromFile = (file) => new Promise((resolve, reject) => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const base64data = reader.result;
      const image = new Image();
      image.src = base64data;
      image.onload = () => {
        const rawRatio = image.width / image.height;

        resolve({
          width: image.width,
          height: image.height,
          resolutionInMP: image.width * image.height / 1e6,
          ratio: rawRatio < 1
            ? rawRatio
            : 1 / rawRatio,
        });
      };
    };
  } catch (error) {
    reject(error);
  }
});

export default getImageDimensionsFromFile;
