import styled from 'styled-components';

import { palette } from 'shared/components/CRM/UI/variables';

// eslint-disable-next-line import/prefer-default-export
export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: 'flex-start';
  padding-top: 30px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  /* background-color: ${palette.avatarBackground}; */
  background-color: ${(props) => props.theme.palette['background-flat']};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: ${(props) => (props.round ? '50%' : '8px')};
  text-transform: uppercase;
`;
