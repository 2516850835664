import requests from 'api/requests';

import setProduct from './setProduct';

const getProductById = (productId) => async (dispatch) => {
  try {
    const response = await requests.crm.products.getById(productId);

    dispatch(setProduct(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getProductById;
