import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {number} offerId
 * @param {Array} emails
 */
const share = async (offerId, emails) => {
  const endpoint = endpoints.client.realtor.shareOffer;
  try {
    const result = await apiConnector.client.post(endpoint, { offerId, emails });

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default share;
