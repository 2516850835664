import { createSelector } from 'reselect';

import { isEmpty, random } from 'shared/utility';

import { optimizedRealtorLeadsActions } from 'store/actions/realtorLeads'; // eslint-disable-line import/no-cycle
import { optimizedPropertyActions } from 'store/actions/properties';
import { optimizedPropertyRequirementActions } from 'store/actions/propertyRequirements';

import appCustomizationSettings from 'store/selectors/appCustomization/settings';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import getDaysUntilClose from 'sections/Leads/utils/getDaysUntilClose';

const tempDict = {
  1: 'cold',
  2: 'warm',
  3: 'hot',
};

export default createSelector(
  (state) => state.realtorLeads.leads,
  (state) => state.properties.properties,
  (state) => state.propertyRequirements.requirements,
  (state, filterId) => state.realtorLeads.leadsByFilter[filterId],
  (state) => appCustomizationSettings(state)?.lead_conversion_goal_days,
  (leads, properties, requirements, filteredIds, leadConversionGoal) => {
    if (!filteredIds) {
      return [];
    }

    const result = filteredIds.reduce((accum, leadId, topLevelIndex) => {
      const lead = leads[leadId];

      if (!lead && leadId) {
        optimizedRealtorLeadsActions.getLeadById(leadId);
        return accum;
      }

      const topLevelData = {
        children: [],
      };

      const leadType = new Set();

      const leadObjects = [];

      if (!isEmpty(lead.properties)) {
        lead.properties.forEach((id) => {
          leadObjects.push({ type: 'properties', id });
        });
      }
      if (!isEmpty(lead.property_requirements)) {
        lead.property_requirements.forEach((id) => {
          leadObjects.push({ type: 'property_requirements', id });
        });
      }

      if (isEmpty(leadObjects)) {
        return accum;
      }

      leadObjects.forEach((itemObject, index) => {
        const item = itemObject.type === 'properties'
          ? properties[itemObject.id]
          : requirements[itemObject.id];

          if (!item && itemObject.id) {
            if (itemObject.type === 'properties') {
              optimizedPropertyActions.getPropertyById(itemObject.id);
          }
          if (itemObject.type === 'property_requirements') {
            optimizedPropertyRequirementActions.getPropertyRequirementById(itemObject.id);
          }
          return;
        }

        const daysUntilClose = getDaysUntilClose(item, leadConversionGoal);

        if (index === 0) {
          topLevelData.key = `${topLevelIndex + 1}`;
          topLevelData.leadId = lead.id;
          topLevelData.status = tempDict[lead.temp] || 'not set';
          topLevelData.leadStatus = lead.status; // TODO this is confusing, need to rename `status` to `temp` or something
          topLevelData.personId = lead.contact_id;
          topLevelData.isNew = !!lead.isNew;

          topLevelData.type = itemObject.type === 'properties' ? 2 : 1;

          leadType.add(topLevelData.type);

          topLevelData.expectedSalePrice = itemObject.type === 'properties' ? item?.expected_price : null;
          topLevelData.expectedPurchasePrice = itemObject.type === 'property_requirements'
            ? [item?.budget_min, item?.budget_max] : null;
          topLevelData.propertyType = itemObject.type === 'properties'
            ? [PROPERTY_TYPES[item.property_type]]
            : Array.isArray(item.property_type) && item.property_type.map((type) => PROPERTY_TYPES[type]);
          topLevelData.location = item.address_id || item.addresses;
          topLevelData.createdAt = item.created_at;
          topLevelData.connectedItemType = itemObject.type;
          topLevelData.connectedItemId = item.id;
          topLevelData.opportunityName = item.opportunity_name;
          topLevelData.isTop = true;

          // metrics
          topLevelData.daysSinceLastContact = lead.daysSinceLastContact != null
            ? lead.daysSinceLastContact
            : 'new';
          topLevelData.listingPitchArranged = `${random(1, 8)}d`;
          topLevelData.meetingArranged = `${random(1, 8)}d`;
          topLevelData.daysUntilClose = daysUntilClose;
          topLevelData.nextPriority = `${random(1, 5)}`;

          return;
        }

        const childType = itemObject.type === 'properties' ? 2 : 1;

        leadType.add(childType);

        topLevelData.children.push({
          key: `${topLevelIndex + 1}.${index}`,
          type: childType,
          expectedSalePrice: itemObject.type === 'properties' ? item?.expected_price : null,
          expectedPurchasePrice: itemObject.type === 'property_requirements'
            ? [item?.budget_min, item?.budget_max] : null,
          propertyType: itemObject.type === 'properties'
            ? [PROPERTY_TYPES[item.property_type]]
            : Array.isArray(item.property_type) && item.property_type.map((type) => PROPERTY_TYPES[type]),
          location: item.address_id || item.addresses,
          createdAt: item.created_at,
          leadId: lead.id,
          connectedItemId: item.id,
          connectedItemType: itemObject.type,
          opportunityName: item.opportunity_name,

          // metrics
          daysSinceLastContact: topLevelData.daysSinceLastContact,
          listingPitchArranged: `${random(1, 8)}d`,
          meetingArranged: `${random(1, 8)}d`,
          daysUntilClose,
          nextPriority: `${random(1, 5)}`,
        });
      });

      if (!topLevelData.key) {
        return accum;
      }

      if (leadType.size > 1) {
        topLevelData.leadType = 3;
      } else {
        topLevelData.leadType = leadType.values().next().value;
      }

      accum.push(topLevelData);
      return accum;
    }, []);

    return result;
  },
);
