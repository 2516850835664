import React from 'react';
import { Modal, Button } from 'antd';

import './LeavingInfo.scss';

const LeavingInfo = (props) => (
  <Modal
    visible
    centered
    width={545}
    title={null}
    footer={null}
    onCancel={props.onCancel}
    wrapClassName="leaving-info"
  >
    <span className="leaving-info__info">{props.message}</span>

    <div className="leaving-info__bottom">
      <Button onClick={props.onCancel}>Cancel</Button>

      <Button type="primary" onClick={props.onConfirm}>
        I’m Sure
      </Button>
    </div>
  </Modal>
);

export default LeavingInfo;
