import {
  SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD,
} from 'store/actionTypes/propertyViewings';

const setNewPropertyViewingNestedFormField = (parentKey) => (key) => (value) => ({
  type: SET_NEW_PROPERTY_VIEWING_FORM_NESTED_FIELD,
  payload: {
    parentKey,
    key,
    value,
  },
});

export default setNewPropertyViewingNestedFormField;
