import { connect } from 'react-redux';

import {
  openModal,
} from 'store/actions/modals';
import CallsSettings from './CallsSettings';

const mapDispatchToProps = (dispatch) => ({
  openAddNumberModal: () => { dispatch(openModal('addPhoneNumber')()); },
  openDeviceSetupModal: () => { dispatch(openModal('callDeviceSetup')()); },
});

export default connect(null, mapDispatchToProps)(CallsSettings);
