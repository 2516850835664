import { createSelector } from 'reselect';

import byId from './byId';

export default createSelector(
  (state) => (id) => byId(state, id),
  (state) => state.realtorProspects.selectedProspect,
  (getById, selectedId) => {
    const item = getById(selectedId);

    return item;
  },
);
