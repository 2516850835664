import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import {
  setFilterItem,
} from 'store/actions/realtorContactsFilter';

import {
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import Search from './Search';

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    dispatch(setFilterItem('search')(search));

    optimizedRealtorContactsActions.getContactsForStoreFilter();
  }, 500),
});

export default connect(null, mapDispatchToProps)(Search);
