import styled from 'styled-components';

import Typography from 'ui/Typography';

export const TitleText = styled(Typography)`
  padding: ${(props) => (props.compact ? '2px 5px 0' : '5px 10px 0')}
`;

export default {
  TitleText,
};
