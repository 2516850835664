import requests from 'api/requests';

import setTasksCountForTeam from './setTasksCountForTeam';
import setTasks from './setTasks';
import setTasksForTeam from './setTasksForTeam';

const getTeamTasks = (teamId) => (offset = 0, limit = 50) => async (dispatch) => {
  try {
    const tasksResponse = await requests.tasks.getTasks({
      offset,
      limit,
      teamId,
    });

    dispatch(setTasksCountForTeam(teamId)(tasksResponse.data.tasksCount));
    dispatch(setTasks(tasksResponse.data.tasks));
    dispatch(setTasksForTeam(teamId)(tasksResponse.data.tasks));
  } catch (error) {
    console.error(error);
  }
};

export default getTeamTasks;
