import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import RealtorLeadItemDetails from 'shared/components/UI/RealtorLeadItemDetails';

const itemTypeToSelector = {
  properties: 'properties',
  property_requirements: 'propertyRequirements',
};

const mapStateToProps = (state, ownProps) => {
  const {
    leadId,
    connectedItemType,
    connectedItemId,
  } = ownProps;

  if (!leadId || !connectedItemType || !connectedItemId) {
    return null;
  }

  // eslint-disable-next-line no-unused-vars
  const lead = selectors.realtorLeads.byId(state, leadId);
  const connectedItem = selectors[itemTypeToSelector[connectedItemType]].byId(state, connectedItemId);

  const contact = selectors.realtorContacts.byId(state, lead.contact_id);
  const address = selectors.addresses.byId(state, connectedItem.address_id);

  const data = {
    contactName: `${contact?.first_name || ''} ${contact?.last_name || ''}`,
    location: address?.address,
    role: connectedItemType === 'properties' ? 2 : 1,
    expectedSalePrice: connectedItem.expected_price,
    expectedPurchasePrice: [connectedItem.budget_min, connectedItem.budget_max],
    propertyType: connectedItemType === 'properties'
      ? [PROPERTY_TYPES[connectedItem.property_type]]
      : Array.isArray(connectedItem.property_type) && connectedItem.property_type.map((type) => PROPERTY_TYPES[type]),
  };

  return data;
};

export default connect(mapStateToProps)(RealtorLeadItemDetails);
