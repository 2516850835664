import styled from 'styled-components';

export const Root = styled.div`
  border-radius: 9px;
  background-color: ${((props) => props.theme.palette[props.variant] || 'gray')};

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 40px;

  width: 100%;
`;

export default {
  Root,
};
