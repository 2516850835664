import store from 'store';
import { memoizeDebounce } from 'shared/utility';

import getCustomizationSettings from './getCustomizationSettings';
import updateCustomizationSettings from './updateCustomizationSettings';

export {
  getCustomizationSettings,
  updateCustomizationSettings,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedAppCustomizationSettings = {
  getCustomizationSettings: memoizeDebounce(
    () => store.dispatch(getCustomizationSettings()),
    debounceDelay,
    debounceOptions,
  ),
};
