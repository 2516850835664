import styled from 'styled-components';
import Typography from 'ui/Typography';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 0 0 30px;
  gap: 4px;
`;

export const Title = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 1px
`;

export default {
  Root,
  ChildrenWrapper,
  Title,
};
