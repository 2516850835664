import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyItem from './PropertyItem';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  const address = selectors.addresses.byId(state, property?.address_id)?.address;

  const image = selectors.properties.mainPropertyImage(state, propertyId);

  return {
    image,
    address,
  };
};

export default connect(mapStateToProps)(PropertyItem);
