import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedCrmContactsActions,
} from 'store/actions/crm/crmContacts';

const defaultContact = {
  SalesContactsID: null,
  FirstName: null,
  LastName: null,
  MobilePhone: null,
  Email: null,
};

export default createSelector(
  (state) => state.crmContacts.contacts,
  (_, id) => id,
  (collection, id) => {
    const item = collection[id];

    if (!item) {
      if (id) {
        optimizedCrmContactsActions.getContactById(id);
      }

      return defaultContact;
    }

    return item;
  },
);
