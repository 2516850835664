import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';

const Search = (props) => {
  const {
    onSearch,
  } = props;

  const handleSearch = useCallback((value) => {
    onSearch(value);
  });

  return (
    <Textfield
      fullWidth
      placeholder="Search contacts"
      onChange={handleSearch}
      iconPosition="left"
      icon="search"
      flat
    />
  );
};

const {
  func,
} = PropTypes;

Search.propTypes = {
  onSearch: func.isRequired,
};

export default Search;
