import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export default {
  Wrapper,
};
