import deleteClient from './deleteClient';

const deleteSelectedClient = () => async (dispatch, getState) => {
  const {
    realtorClients: {
      selectedClient,
    },
  } = getState();

  dispatch(deleteClient(selectedClient));
};

export default deleteSelectedClient;
