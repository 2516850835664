import styled from 'styled-components';

export const Root = styled.div`
  padding: 12px;
  border: 1px solid;
  border-color: ${(props) => props.theme?.palette?.focus || 'grey'};
  border-radius: 10px;
  background-color: ${(props) => props.theme?.palette?.['background-flat'] || 'white'};
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
