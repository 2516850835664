/* eslint-disable */
// TODO
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { callAlert } from 'ui/Alert';

import Modal from 'ui/Modal'
import Button from 'ui/Button'
import Spin from 'ui/Spin';

import { common } from 'constants';

import ImageUploadWithPreview from 'shared/components/ImageUploadWithPreview';

import './AvatarLogoCrop.scss';

const AvatarLogoCrop = (props) => {
  const {
    showDeleteImageWarningModal,
    hideModal,
    appModule,
    getFileList,
    createAttachment,
    imageActionHooks,
    handleOnUpload,
    imageObjectId,
    fileId,
    src,
    employee: {
      Name,
      Surname,
    },
    circularCrop,
    visible,
  } = props;

  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [loading, setLoadingStatus] = useState(false);

  useEffect(() => {
    setCroppedImageUrl(src);
  },
  [
    src,
    visible,
  ]);

  const afterFileUpload = (res) => {
    setLoadingStatus(false);

    const sortedByDate = res.sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));
    const currentImg = sortedByDate[0];

    if (currentImg) {
      if (imageActionHooks && imageActionHooks.onAdd) imageActionHooks.onAdd(currentImg.Location);
    }

    if (handleOnUpload) {
      handleOnUpload();
    }
  };

  const uploadFile = async () => {
    setLoadingStatus(true);

    await createAttachment(croppedImageFile);

    getFileList(afterFileUpload);
  };

  const handleOk = useCallback(async () => {
    const isImageDeleted = src && !croppedImageUrl

    if (isImageDeleted) {
      showDeleteImageWarningModal(appModule, imageObjectId, fileId);
    } else if (croppedImageUrl) {
      await uploadFile();
      hideModal(false, appModule);
    } else {
      callAlert.warning('You didn\'t select a file!');
    }
  },
  [
    croppedImageUrl,
    hideModal,
    appModule,
    fileId,
    imageObjectId,
    showDeleteImageWarningModal,
  ]);

  const handleCancel = useCallback(() => {
    hideModal(false, appModule);
  },
  [
    hideModal,
    appModule,
  ]);

  const onImageChange = useCallback(({
    imageUrl,
    imageFile,
  }) => {
    setCroppedImageFile(imageFile);
    setCroppedImageUrl(imageUrl);
  });

  const hideCrop = false;

  let cardTitle;
  let placeholder;

  if (appModule === 'employee-logo') {
    cardTitle = 'Profile Picture';
    placeholder = loading
      ? '/assets/img/blank.png'
      : '/assets/img/user-cropped-placeholder.png';
  } else if (appModule === 'contact-leads-logo' || appModule === 'realtor-contact-avatar') {
    cardTitle = 'Contact Avatar';
    placeholder = loading
      ? '/assets/img/blank.png'
      : '/assets/img/user-cropped-placeholder.png';
  } else {
    cardTitle = 'Company Logo';
    placeholder = loading
      ? '/assets/img/blank.png'
      : common.DEFAULT_LOGO;
  }

  const userFullName = `${Name || ''} ${Surname || ''}`;


  return (
    <Modal
      visible={visible}
      keyboard={false}
      maskClosable={false}
      width={800}
      onCancel={handleCancel}
      destroyOnClose
      title={`Crop & Edit ${cardTitle}`}
      footer={[
        <Button
          onClick={handleCancel}
          variant="tertiary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={handleOk}
          disabled={loading}
        >
          Finalize
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <ImageUploadWithPreview
          onImageChange={onImageChange}
          circularCrop={circularCrop}
          noCrop={hideCrop}
          defaultSrc={croppedImageUrl}
          placeholder={placeholder}
        />
      </Spin>
    </Modal>
  );
};

const noOp = () => { };

AvatarLogoCrop.defaultProps = {
  visible: false,
  circularCrop: true,
  imageActionHooks: {
    onAdd: noOp,
    onDelete: noOp,
    onCancel: noOp,
  },
  employee: {
    Name: null,
    Surname: null,
    Role: null,
    WorkPhone: null,
    Email: null,
  },
};

const {
  bool,
  func,
  shape,
  string,
  number
} = PropTypes;

AvatarLogoCrop.propTypes = {
  showDeleteImageWarningModal: func.isRequired,
  appModule: string.isRequired,
  createAttachment: func.isRequired,
  circularCrop: bool,
  employee: shape({
    Name: string,
    Surname: string,
  }),
  getFileList: func.isRequired,
  fileId: string.isRequired,
  imageObjectId: number.isRequired,
  handleOnUpload: func.isRequired,
  hideModal: func.isRequired,

  imageActionHooks: shape({
    onAdd: func,
    onDelete: func,
    onCancel: func,
  }),

  src: string.isRequired,
  visible: bool,
};

export default AvatarLogoCrop;
