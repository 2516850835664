import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import PropertyNotablePoints from 'sections/Properties/modules/PropertyNotablePoints';
import PropertyDisclosures from 'sections/Properties/modules/PropertyDisclosures';
import PropertyTypeSelect from 'sections/Properties/modules/PropertyTypeSelect';

import ListingPriceInput from 'sections/Clients/modules/ListingPriceInput';

import PropertyAddressInput from 'sections/Properties/modules/PropertyAddressInput';
import PropertyParametersMain from 'sections/Properties/modules/PropertyParametersMain';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';

const ClientSeller = (props) => {
  const {
    clientId,
    propertyId,
  } = props;

  return (
    <Flex
      flexDirection="column"
      fullWidth
      spacing={3}
    >
      <Typography
        variant="title2"
        noMargin
      >
        Property Details
      </Typography>

      <FlexItem fullWidth>
        <ImageCarousel propertyId={propertyId} />
      </FlexItem>

      <FlexItem fullWidth>
        <PropertyAddressInput
          propertyId={propertyId}
          defaultView="edit"
          label="Address"
          placeholder="Enter seller property address"
        />
      </FlexItem>

      <FlexItem fullWidth>
        <ListingPriceInput
          clientId={clientId}
          defaultView="edit"
        />
      </FlexItem>

      <FlexItem fullWidth>
        <PropertyTypeSelect
          propertyId={propertyId}
        />
      </FlexItem>

      <FlexItem fullWidth>
        <PropertyParametersMain
          propertyId={propertyId}
          defaultView="display"
        />
      </FlexItem>

      <FlexItem fullWidth>
        <Typography
          variant="title3"
        >
          Notable Selling Points
        </Typography>
        <PropertyNotablePoints
          propertyId={propertyId}
          ellipsis={2}
        />
      </FlexItem>

      <FlexItem fullWidth>
        <Typography
          variant="title3"
        >
          Disclosures
        </Typography>
        <PropertyDisclosures
          propertyId={propertyId}
          ellipsis={2}
        />
      </FlexItem>
    </Flex>
  );
};

const {
  number,
} = PropTypes;

ClientSeller.propTypes = {
  propertyId: number.isRequired,
  clientId: number.isRequired,

};

export default ClientSeller;
