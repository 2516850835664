import apiConnector, { endpoints } from 'api';

/**
 * Creates a new entry about the company logo in the global database
 * @param {number} ticketID ticket element ID
 * @param {string} newAgentNote new agent note
 */
const editAgentNote = async (ticketID, newAgentNote) => {
  const url = `${endpoints.client.ticket.setAgentNote}/${ticketID}`;
  await apiConnector.client.put(url, {
    newAgentNote,
  });
};

export default editAgentNote;
