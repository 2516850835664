import requests from '../../../api/requests';

// eslint-disable-next-line consistent-return
const updateNavbarColor = async (value) => {
    try {
        await requests.navbarSettings.update({ body: value });
    } catch (error) {
        console.error(error);
    }
};

export default updateNavbarColor;
