import connectReducers from 'store/connectReducers';

import {
  SET_DIGITAL_ACCOUNT,
  SET_DIGITAL_ACCOUNTS,
} from 'store/actionTypes/customerDigitalAccounts';

const initialState = {
  accounts: {},
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_DIGITAL_ACCOUNT]: (state, action) => ({
    ...state,
    accounts: {
      ...state.accounts,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_DIGITAL_ACCOUNTS]: (state, action) => ({
    ...state,
    accounts: {
      ...state.accounts,
      ...listToObject(action.payload),
    },
  }),
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
