import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCopy as farFaCopy,
  faCheckCircle as farFaCheckCircle,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAddressBook,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowsAltV,
  faAt,
  faBan,
  faBars,
  faBath,
  faBed,
  faBell,
  faBold,
  faBookReader,
  faBox,
  faBug,
  faBuilding,
  faBullhorn,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCar,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboardList,
  faClock,
  faClone,
  faCog,
  faComment,
  faCommentAlt,
  faCommentDots,
  faComments,
  faCompress,
  faCompressAlt,
  faCreditCard,
  faCubes,
  faDatabase,
  faGem,
  faDollarSign,
  faDolly,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faExchangeAlt,
  faExpand,
  faExpandAlt,
  faEye,
  faFileAlt,
  faFileContract,
  faFileInvoiceDollar,
  faFilter,
  faFire,
  faFlag,
  faFlagCheckered,
  faFolder,
  faFrown,
  faFunnelDollar,
  faGlobe,
  faHammer,
  faHandHoldingUsd,
  faHandshake,
  faHeading,
  faHeadphonesAlt,
  faHighlighter,
  faHome,
  faHourglassEnd,
  faIdBadge,
  faImage,
  faIndent,
  faInfoCircle,
  faItalic,
  faKey,
  faLaptop,
  faLink,
  faList,
  faListOl,
  faListUl,
  faLockOpen,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faMeh,
  faMicrophone,
  faMicrophoneAlt,
  faMinus,
  faMousePointer,
  faNewspaper,
  faOutdent,
  faPaperclip,
  faPen,
  faPeopleCarry,
  faPhone,
  faPiggyBank,
  faPlay,
  faPlus,
  faPlusCircle,
  faPrint,
  faProjectDiagram,
  faQuestionCircle,
  faReceipt,
  faRedoAlt,
  faReply,
  faReplyAll,
  faSearch,
  faSearchDollar,
  faShippingFast,
  faShoppingCart,
  faSign,
  faSignOutAlt,
  faSmile,
  faSnowflake,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faStickyNote,
  faStop,
  faStrikethrough,
  faSuitcase,
  faSun,
  faSync,
  faTag,
  faTags,
  faThLarge,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTrash,
  faTrashAlt,
  faUnderline,
  faUser,
  faUserFriends,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUserTie,
  faVideo,
  faWindowClose,
  faTimesCircle,
  faDotCircle,
  faStopCircle,
  faPlayCircle,
  faPauseCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faProductHunt,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const addIcons = () => {
  library.add(
    faAddressBook,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArchive,
    faArrowsAltV,
    faAt,
    faBan,
    faBars,
    faBath,
    faBed,
    faBell,
    faBold,
    faBookReader,
    faBox,
    faBug,
    faBuilding,
    faBullhorn,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarDay,
    faCalendarPlus,
    faCar,
    faChartArea,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClipboardList,
    faClock,
    faClone,
    faCog,
    faComment,
    faCommentAlt,
    faCommentDots,
    faComments,
    faCompress,
    faCompressAlt,
    faCreditCard,
    faCubes,
    faDatabase,
    faGem,
    faDollarSign,
    faDolly,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faExchangeAlt,
    faExpand,
    faExpandAlt,
    faEye,
    faFacebookF,
    faFileAlt,
    faFileContract,
    faFileInvoiceDollar,
    faFilter,
    faFire,
    faFlag,
    faFlagCheckered,
    faFolder,
    faFrown,
    faFunnelDollar,
    faGlobe,
    faHammer,
    faHandHoldingUsd,
    faHandshake,
    faHeading,
    faHeadphonesAlt,
    faHighlighter,
    faHome,
    faHourglassEnd,
    faHourglassEnd,
    faIdBadge,
    faImage,
    faIndent,
    faInfoCircle,
    faInstagram,
    faItalic,
    faKey,
    faLaptop,
    faLink,
    faLinkedinIn,
    faList,
    faListOl,
    faListUl,
    faLockOpen,
    faLongArrowAltRight,
    faMapMarkerAlt,
    faMeh,
    faMicrophone,
    faMicrophoneAlt,
    faMinus,
    faMousePointer,
    faNewspaper,
    faOutdent,
    faPaperclip,
    faPen,
    faPeopleCarry,
    faPhone,
    faPiggyBank,
    faPlay,
    faPlus,
    faPlusCircle,
    faPrint,
    faProductHunt,
    faProjectDiagram,
    faQuestionCircle,
    faReceipt,
    faRedoAlt,
    faReply,
    faReplyAll,
    farFaCheckCircle,
    farFaCopy,
    faSearch,
    faSearchDollar,
    faShippingFast,
    faShoppingCart,
    faSign,
    faSignOutAlt,
    faSmile,
    faSnowflake,
    faSort,
    faSortDown,
    faSortUp,
    faStar,
    faStickyNote,
    faStop,
    faStrikethrough,
    faSuitcase,
    faSun,
    faSync,
    faTag,
    faTags,
    faThLarge,
    faThumbsDown,
    faThumbsUp,
    faThumbtack,
    faTimes,
    faTrash,
    faTrashAlt,
    faTwitter,
    faUnderline,
    faUser,
    faUserFriends,
    faUserMinus,
    faUserPlus,
    faUsers,
    faUserTie,
    faVideo,
    faWindowClose,
    faTimesCircle,
    faDotCircle,
    faStopCircle,
    faPlayCircle,
    faPauseCircle,
  );
};

export default addIcons;
