import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import useContactName from 'shared/hooks/contacts/useContactName';
import usePhoneNumberFormatted from 'shared/hooks/phoneNumbers/usePhoneNumberFormatted';

import {
  SearchedContactWrapper,
} from './styledItems';

const SearchedContact = (props) => {
  const {
    contactId,
    contact,
    onClick,
    showEmail,
    showPhone,
    defaultPhoneNumberId,
  } = props;

  const {
    email_primary,
  } = contact;

  const contactName = useContactName(contactId);
  const defaultPhone = usePhoneNumberFormatted(defaultPhoneNumberId);

  return (
    <SearchedContactWrapper onClick={onClick}>
      <Typography
        variant="body"
        noMargin
      >
        {contactName}
      </Typography>
      {
        (showEmail && email_primary) && (
          <Typography
            variant="body"
            className="contact-email"
            noMargin
          >
            {email_primary}
          </Typography>
        )
      }
      {
        (showPhone && defaultPhone) && (
          <Typography
            variant="body"
            className="contact-email"
            noMargin
          >
            {defaultPhone}
          </Typography>
        )
      }
    </SearchedContactWrapper>
  );
};

SearchedContact.defaultProps = {
  showEmail: true,
  showPhone: false,
  defaultPhoneNumberId: null,
};

const {
  number,
  shape,
  func,
  string,
  bool,
} = PropTypes;

SearchedContact.propTypes = {
  contactId: number.isRequired,
  contact: shape({
    email_primary: string,
  }).isRequired,
  defaultPhoneNumberId: number,
  onClick: func.isRequired,
  showEmail: bool,
  showPhone: bool,
};

export default SearchedContact;
