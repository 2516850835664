import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
} from 'libphonenumber-js';

const formatPhoneNumber = (value) => {
  if (!value) {
    return '';
  }

  const inputType = typeof value;
  if (inputType !== 'string' && inputType !== 'number') {
    return '';
  }

  if (!isPossiblePhoneNumber(value, 'US')) {
    return '';
  }

  return parsePhoneNumber(value, 'US').formatNational();
};

export default formatPhoneNumber;
