import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeModal,
} from 'store/actions/modals';

import ProfileDetailsModal from './ProfileDetailsModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      profileDetails,
    },
  } = state;

  const leadId = profileDetails?.contentOptions?.leadId || null;

  let contactId = profileDetails?.contentOptions?.contactId || null;

  if (leadId && !contactId) {
    const lead = selectors.realtorLeads.byId(state, leadId);
    contactId = lead.contact_id || null;
  }

  return {
    isVisible: profileDetails?.isVisible,
    isLead: !!leadId,
    contactId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeModal('profileDetails')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailsModal);
