import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Flex from 'ui/Flex';
import BaseControl from 'shared/components/UI/PropertyParameters/BaseControl';

const PropertyParameters = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeBedrooms,
      value: bedrooms,
    },
  } = useController({
    name: 'bedrooms',
    control,
  });

  const {
    field: {
      onChange: onChangeBathrooms,
      value: bathrooms,
    },
  } = useController({
    name: 'bathrooms',
    control,
  });

  const {
    field: {
      onChange: onChangeFloorArea,
      value: floorArea,
    },
  } = useController({
    name: 'floorArea',
    control,
  });

  const {
    field: {
      onChange: onChangePlotArea,
      value: plotArea,
    },
  } = useController({
    name: 'plotArea',
    control,
  });

  return (
    <Flex
      spacing={3}
      justifyContent="flex-start"
      flexWrap="wrap"
      padding="20px 0"
    >
      <BaseControl
        icon="bed"
        iconColor="focus"
        value={bedrooms}
        iconType="custom"
        handleChange={onChangeBedrooms}
      />
      <BaseControl
        icon="bathroom"
        iconType="custom"
        iconColor="focus"
        value={bathrooms}
        handleChange={onChangeBathrooms}
      />
      <BaseControl
        style={{ minWidth: 75 }}
        icon="height"
        iconColor="focus"
        iconType="custom"
        description="sqft"
        value={floorArea}
        step={50}
        handleChange={onChangeFloorArea}
      />
      <BaseControl
        style={{ minWidth: 75 }}
        icon="maximize"
        iconColor="focus"
        description="acres"
        value={plotArea}
        handleChange={onChangePlotArea}
      />
    </Flex>
  );
};

const {
  shape,
} = PropTypes;

PropertyParameters.propTypes = {
  control: shape({}).isRequired,
};

export default PropertyParameters;
