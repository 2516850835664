import styled from 'styled-components';

export const Root = styled.div`
  padding: 10px;
  height: inherit;
`;

export const EmptyImage = styled.img`
  margin: auto auto 20px;
`;

export const EmptyItemsContainer = styled.div`
  width: fit-content;
  margin: auto;
`;
