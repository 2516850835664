const formatCallDuration = (durationInSeconds) => {
  let seconds = durationInSeconds % 60;
  let minutes = parseInt(durationInSeconds / 60, 10);

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${minutes}:${seconds}`;
};

export default formatCallDuration;
