import connectReducers from 'store/connectReducers';
import { groupBy } from 'shared/utility';

import {
  SET_WAREHOUSE_PACKAGE,
  SET_WAREHOUSE_PACKAGES,
  SET_SELECTED_WAREHOUSE_PACKAGE,
  SET_WAREHOUSE_PACKAGE_ATTACHMENTS,
  SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT,
  CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL,
  OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL,
  SET_WAREHOUSE_PENDING_ORDER,
  SET_WAREHOUSE_OUT_OF_STOCK,
  SET_SELECTED_WAREHOUSE_LINE_ITEMS,
  CLEAR_SELECTED_WAREHOUSE_LINE_ITEMS,
  SET_DRAGGED_WAREHOUSE_LINE_ITEMS,
  SET_WAREHOUSE_KANBAN,
  REFRESH_WAREHOUSE_KANBAN,
} from 'store/actionTypes/warehouse';

const initialState = {
  packages: {},
  selectedPackage: null,
  attachments: {},
  isMovePackageLineItemModalVisible: false,
  fulfillmentPackages: {},
  pendingOrders: {},
  outOfStockOrders: {},
  selectedLineItems: [],
  draggedLineItems: [],
  warehouseKanban: {
    create_order_pickup: {
      fulfillmentList: [],
      count: 1,
    },
    in_progress: {
      fulfillmentList: [],
      count: 1,
    },
    shipped: {
      fulfillmentList: [],
      count: 1,
    },
  },
};

const listToObject = (items = []) => items.reduce((obj, item) => {
  // param reassign is used in reduce by design
  obj[item.id] = item; // eslint-disable-line no-param-reassign
  return obj;
}, {});

const reducers = {
  [SET_DRAGGED_WAREHOUSE_LINE_ITEMS]: (state, action) => ({
    ...state,
    draggedLineItems: action.payload,
  }),

  [SET_SELECTED_WAREHOUSE_PACKAGE]: (state, action) => ({
    ...state,
    selectedPackage: action.payload,
  }),

  [REFRESH_WAREHOUSE_KANBAN]: (state, action) => ({
    ...state,
    warehouseKanban: {
      create_order_pickup: {
        fulfillmentList: [],
        count: 1,
      },
      in_progress: {
        fulfillmentList: [],
        count: 1,
      },
      shipped: {
        fulfillmentList: [],
        count: 1,
      },
    },
  }),

  [SET_WAREHOUSE_KANBAN]: (state, action) => ({
    ...state,
    warehouseKanban: {
      ...state.warehouseKanban,
      [action.column]: {
        fulfillmentList: [...action.payload.fulfillmentList, ...state.warehouseKanban[action.column].fulfillmentList],
        count: action.payload.count,
      },
    },
  }),

  [SET_SELECTED_WAREHOUSE_LINE_ITEMS]: (state, action) => ({
    ...state,
    selectedLineItems: action.payload,
  }),

  [CLEAR_SELECTED_WAREHOUSE_LINE_ITEMS]: (state) => ({
    ...state,
    selectedLineItems: [],
  }),

  [SET_WAREHOUSE_PACKAGE]: (state, action) => ({
    ...state,
    packages: {
      ...state.packages,
      [action.payload.id]: action.payload,
    },
  }),

  [SET_WAREHOUSE_PACKAGES]: (state, action) => ({
    ...state,
    packages: {
      ...state.packages,
      ...listToObject(action.payload),
    },
  }),

  [SET_WAREHOUSE_PACKAGES_FOR_FULFILLMENT]: (state, action) => {
    const {
      packages = [],
      fulfillmentId,
    } = action.payload;

    return {
      ...state,
      fulfillmentPackages: {
        ...state.fulfillmentPackages,
        [fulfillmentId]: packages.map((item) => item.id),
      },
    };
  },

  [SET_WAREHOUSE_PENDING_ORDER]: (state, action) => {
    const {
      items = [],
      fulfillmentId,
    } = action.payload;

    return {
      ...state,
      pendingOrders: {
        ...state.pendingOrders,
        [fulfillmentId]: items,
      },
    };
  },

  [SET_WAREHOUSE_OUT_OF_STOCK]: (state, action) => {
    const {
      items = [],
      fulfillmentId,
    } = action.payload;

    return {
      ...state,
      outOfStockOrders: {
        ...state.outOfStockOrders,
        [fulfillmentId]: items,
      },
    };
  },

  [SET_WAREHOUSE_PACKAGE_ATTACHMENTS]: (state, action) => {
    const updatedState = {
      ...state,
      attachments: {
        ...state.attachments,
        [action.payload.id]: groupBy(action.payload.attachments, 'attachmentType'),
      },
    };

    return updatedState;
  },

  [CLOSE_MOVE_PACKAGE_LINE_ITEM_MODAL]: (state, action) => ({
    ...state,
    isMovePackageLineItemModalVisible: false,
  }),

  [OPEN_MOVE_PACKAGE_LINE_ITEM_MODAL]: (state, action) => ({
    ...state,
    isMovePackageLineItemModalVisible: true,
  }),
};

function reducer(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default reducer;
