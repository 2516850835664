import { connect } from 'react-redux';
import {
  hideDeleteImageWarningModal,
  deleteFile,
  triggerAvatarCropModal,
} from 'store/actions/attachments';

import { deleteUserAvatar } from 'store/actions/users';

import { deleteCompanyLogo } from 'store/actions/MyCompany/information';
import DeleteImageWarningModal from './DeleteImageWarningModal';

const mapStateToProps = (state) => {
  const {
    attachments: {
      isDeleteImageWarningModalVisible,
      openedImageModalInformation,
    },
  } = state;

  return {
    isDeleteImageWarningModalVisible,
    openedImageModalInformation,
  };
};

const mapDispatchToProps = {
  hideDeleteImageWarningModal,
  deleteFile,
  triggerAvatarCropModal,
  deleteCompanyLogo,
  deleteUserAvatar,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteImageWarningModal);
