/* eslint-disable camelcase */
import React from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import FormTextfield from 'shared/inputs/FormTextfield';
import EmailInput from 'shared/inputs/EmailInput';
import PhoneInput from 'shared/inputs/PhoneInput';
import PersonTitleSelect from 'shared/inputs/PersonTitleSelect';
import DateTimeInput from 'shared/inputs/DateTimeInput';

import CreateContactButton from './CreateContactButton';

const NewContactArea = (props) => {
  const {
    handleChange,
    onInvalidField,
    areaTitle,
    contact,
    setContactAfterCreation,
  } = props;

  const {
    title,
    first_name,
    last_name,
    email_primary,
    phone_primary,
    first_contact_date,
  } = contact;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DecoratedFieldWrapper
          iconName="notNull"
          noMargin
        >
          {
            areaTitle && (
              <Typography
                weight="bold"
                noMargin
              >
                {areaTitle}
              </Typography>
            )
          }

          <Grid container spacing={1}>
            <Grid item xs={2}>
              <PersonTitleSelect
                value={title}
                handleChange={handleChange('title')}
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextfield
                label="First Name"
                value={first_name}
                onChange={handleChange('first_name')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextfield
                label="Last Name"
                value={last_name}
                onChange={handleChange('last_name')}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid item xs={12}>
        <EmailInput
          decorated
          decoratorProps={{ noMargin: true }}
          value={email_primary}
          handleChange={handleChange('email_primary')}
          onInvalidEmail={onInvalidField}
        />
      </Grid>

      <Grid item xs={12}>
        <PhoneInput
          decorated
          decoratorProps={{ noMargin: true }}
          value={phone_primary}
          handleChange={handleChange('phone_primary')}
          onInvalidPhone={onInvalidField}
        />
      </Grid>

      <Grid item xs={12}>
        <DecoratedFieldWrapper
          iconName="search"
          variant="highlight"
          noMargin
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DateTimeInput
                value={first_contact_date}
                handleChange={handleChange('first_contact_date')}
              />
            </Grid>
          </Grid>
        </DecoratedFieldWrapper>
      </Grid>

      <Grid item>
        <CreateContactButton setContactAfterCreation={setContactAfterCreation} />
      </Grid>

    </Grid>
  );
};

NewContactArea.defaultProps = {
  areaTitle: 'Or create a new contact',
  contact: {},
  setContactAfterCreation: true,
};

const {
  func,
  string,
  shape,
  bool,
} = PropTypes;

NewContactArea.propTypes = {
  handleChange: func.isRequired,
  onInvalidField: func.isRequired,
  areaTitle: string,
  contact: shape({
    title: string,
    first_name: string,
    last_name: string,
    email_primary: string,
    phone_primary: string,
    first_contact_date: string,
  }),
  setContactAfterCreation: bool,
};

export default NewContactArea;
