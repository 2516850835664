import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import {
  NAVIGATION_ROUTES,
} from 'constants/navigation.constants';

import { without } from 'shared/utility';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import SelectList from 'ui/SelectList';

const categoriesOrder = ['my labels', 'general', 'agents', 'services'];

const ModalDescription = (
  <>
    Labels help group your contacts. Your custom labels can be created on the
    {' '}
    <NavLink
      to={NAVIGATION_ROUTES.settings}
    >
      settings page
    </NavLink>
    .
  </>
);

const EditModal = (props) => {
  const {
    isVisible,
    onSave,
    onCancel,
    selectedValues,
    primaryValue,
    options,
  } = props;

  const [selectedValuesState, setSelectedValuesState] = useState([]);
  const [valueSelectedAsPrimary, setValueSelectedAsPrimary] = useState(null);

  useEffect(() => {
    setSelectedValuesState(selectedValues);
  }, [
    JSON.stringify(selectedValues),
  ]);

  useEffect(() => {
    setValueSelectedAsPrimary(primaryValue);
  }, [
    primaryValue,
  ]);

  const onAdd = useCallback((value, isPrimary) => {
    if (isPrimary) {
      setValueSelectedAsPrimary(value);
    }

    if (!selectedValuesState.includes(value)) {
      setSelectedValuesState((items) => [...items, value]);
    }
  }, [
    JSON.stringify(selectedValuesState),
  ]);

  const onRemove = useCallback((value) => {
    const newValues = without(selectedValuesState, value);

    if (value === valueSelectedAsPrimary) {
      setValueSelectedAsPrimary(null);
    }

    setSelectedValuesState(newValues);
  }, [
    JSON.stringify(selectedValuesState),
    valueSelectedAsPrimary,
  ]);

  const handleSave = useCallback(() => {
    const valuesToSubmit = selectedValuesState.map((value) => ({
      label_id: value,
      is_primary: valueSelectedAsPrimary === value ? 1 : 0,
    }));

    onSave(valuesToSubmit);
    onCancel();
  }, [
    JSON.stringify(selectedValuesState),
    valueSelectedAsPrimary,
    onSave,
    onCancel,
  ]);

  const handleCancel = useCallback(() => {
    setSelectedValuesState(selectedValues);
    setValueSelectedAsPrimary(primaryValue);
    onCancel();
  }, [
    onCancel,
    JSON.stringify(selectedValues),
    primaryValue,
  ]);

  return (
    <Modal
      width={400}
      visible={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button
          variant="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <SelectList
        label="Contact Labels"
        description={ModalDescription}
        options={options}
        withPrimaryValue
        selectedValues={selectedValuesState}
        primaryValue={valueSelectedAsPrimary}
        groupOptionsByCategory
        categoriesOrder={categoriesOrder}
        onSelect={onAdd}
        onDeselect={onRemove}
      />
    </Modal>
  );
};

const {
  bool,
  func,
  number,
  arrayOf,
  shape,
  string,
} = PropTypes;

EditModal.propTypes = {
  isVisible: bool.isRequired,
  onCancel: func.isRequired,
  onSave: func.isRequired,
  selectedValues: arrayOf(number).isRequired,
  primaryValue: number.isRequired,
  options: arrayOf(shape({
    value: number,
    label: string,
    category: string,
  })).isRequired,
};

export default EditModal;
