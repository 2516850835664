import {
  SET_REALTOR_FOLLOW_UP_REMINDERS_FOR_FILTER,
} from 'store/actionTypes/followUpReminders';

const setFollowUpRemindersForFilter = (filterId) => (reminders) => ({
  type: SET_REALTOR_FOLLOW_UP_REMINDERS_FOR_FILTER,
  payload: {
    filterId,
    reminders,
  },
});

export default setFollowUpRemindersForFilter;
