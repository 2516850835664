import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'antd';
import './Button.scss';

class OurButton extends Component {
  render() {
    const {
      children,
      className,
      variant,
      type,
      onClick,
      ...otherProps
    } = this.props;

    return (
      <Button
        className={classnames(
          'button-root',
          `button-root--${variant}`,
          {
            [className]: className,
          },
        )}
        type={type}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </Button>
    );
  }
}

export default OurButton;

OurButton.defaultProps = {
  type: 'primary',
};

const {
  string,
  func,
} = PropTypes;

OurButton.propTypes = {
  className: string,
  type: string,
  variant: string,
  onClick: func,
};
