import apiConnector, { endpoints } from 'api';

const editSubcategory = (subcategoryId) => async (subcategoryName) => {
  const endpoint = `${endpoints.client.subqueues}/EditSubCategory/${subcategoryId}`;

  try {
    const result = await apiConnector.client.put(endpoint, {
      SubCategory: subcategoryName,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default editSubcategory;
