import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  paperRoot: {
    width: 470,
    minHeight: 100,
    padding: '12px 9px',
    overflow: 'hidden',
  },
});

const MasterSearchDropdown = forwardRef((props, ref) => {
  const {
    anchorEl,
    children,
  } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'master-search-popper' : undefined;

  const classes = useStyles();

  return (
    <Popper
      id={id}
      open={open}
      placement="bottom-start"
      anchorEl={anchorEl}
      popperRef={ref}
      modifiers={{
        offset: {
          offset: '0, 9',
        },
      }}
      disablePortal
      style={{
        zIndex: 1000,
      }}
    >
      <Paper
        classes={{ root: classes.paperRoot }}
        variant="outlined"
      >
        {children}
      </Paper>
    </Popper>
  );
});

MasterSearchDropdown.defaultProps = {
  anchorEl: null,
  children: null,
};

const {
  Element,
  node,
} = PropTypes;

MasterSearchDropdown.propTypes = {
  anchorEl: Element,
  children: node,
};

export default MasterSearchDropdown;
