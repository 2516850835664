import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import RequestItemSummaryExpanded from 'sections/Transactions/components/RequestItemSummaryExpanded';
import RequestItemRespondArea from 'sections/Transactions/components/RequestItemRespondArea';
import RequestItemResponse from 'sections/Transactions/components/RequestItemResponse';

const Heading = ({ children }) => (
  <Typography
    variant="h3"
    tag="h3"
    noMargin
  >
    {children}
  </Typography>
);

const RequestItemDetails = (props) => {
  const {
    title,
    description,
    createdAt,
    status,
    contactId,
    contactRole,
    response,
    responseCreatedAt,
    onSubmit,
    onAcknowledge,
    attachments,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RequestItemSummaryExpanded
          contactId={contactId}
          contactRole={contactRole}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="baseline">
              <Grid item>
                <Heading>
                  {title}
                </Heading>
              </Grid>

              {
                createdAt && (
                  <Grid item>
                    <Typography variant="label" tag="p" color="descriptor-text">
                      {moment(createdAt).format('MMM DD, YYYY HH:MMa')}
                    </Typography>
                  </Grid>
                )
              }
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {
        status !== 'responded' && (
          <Grid item xs={12}>
            <RequestItemRespondArea
              onSubmit={onSubmit}
              onAcknowledge={onAcknowledge}
              status={status}
            />
          </Grid>
        )
      }
      {
        status === 'responded' && (
          <Grid item xs={12}>
            <RequestItemResponse
              response={response}
              createdAt={responseCreatedAt}
            />
          </Grid>
        )
      }
      {
        !!attachments && (
          <Grid
            item
            xs={12}
          >
            {attachments}
          </Grid>
      )
}
    </Grid>
  );
};

RequestItemDetails.defaultProps = {
  title: null,
  description: null,
  createdAt: null,
  status: null,
  Attachments: null,
};

const {
  func,
  string,
} = PropTypes;

RequestItemDetails.propTypes = {
  title: string,
  description: string,
  createdAt: string,
  status: string,
  Attachments: func,
};

export default RequestItemDetails;
