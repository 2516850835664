import { connect } from 'react-redux';

// import selectors from 'store/selectors';

import { openModal } from 'store/actions/modals';
// import { getContactById } from 'store/actions/realtorContacts';

import CallTriggerButton from './CallTriggerButton';

const mapStateToProps = (state) => { // eslint-disable-line arrow-body-style
  // const contact = selectors.realtorContacts.selected(state);

  return {
    // callTo: contact?.phone_primary,
    // name: contact?.first_name,
    // contactId: contact?.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (name, contactId) => {
    dispatch(openModal('call')({ name, contactId }));
  },
  // onAfterRecording: (contactId) => {
  //   dispatch(getContactById(contactId));
  // },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  openModal: () => dispatchProps.openModal(stateProps.name, stateProps.contactId),
  // onAfterRecording: () => dispatchProps.onAfterRecording(stateProps.contactId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CallTriggerButton);
