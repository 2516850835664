import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import ImportWizard from 'sections/DataImport/modules/ImportWizard';

const ImportWizardModal = (props) => {
  const {
    isVisible,
    closeModal,
  } = props;

  return (
    <Modal
      title="Import CSV List"
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
      width={1200}
    >
      <ImportWizard
        onEnd={closeModal}
      />
    </Modal>
  );
};

ImportWizardModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
} = PropTypes;

ImportWizardModal.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
};

export default ImportWizardModal;
