import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import BaseControl from 'shared/components/UI/PropertyParameters/BaseControl';

const PropertyParametersMain = (props) => {
  const {
    parameters = {},
    onChange,
    defaultView,
  } = props;

  const {
    bedrooms,
    bathrooms,
    floor_area,
    plot_area,
    parking,
  } = parameters;

  const changePropertyDetails = useCallback((parameterName) => (value) => {
    if (parameterName === 'bathrooms') {
      const decimalPart = (Number(value) % 1).toFixed(1);
      if (Number(decimalPart) !== 0 && Number(decimalPart) !== 0.5) {
        value -= Number(decimalPart); // eslint-disable-line no-param-reassign
      }
    }
    onChange(parameterName)(value);
  }, [onChange]);

  return (
    <Flex
      spacing={defaultView === 'edit' ? 2 : 4}
      alignItems="center"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      <BaseControl
        icon="bed"
        iconColor="focus"
        value={bedrooms}
        iconType="custom"
        handleChange={changePropertyDetails('bedrooms')}
        tooltip="Bedrooms"
        defaultView={defaultView}
      />
      <BaseControl
        icon="bathroom"
        iconType="custom"
        iconColor="focus"
        value={bathrooms}
        step={0.5}
        handleChange={changePropertyDetails('bathrooms')}
        tooltip="Bathrooms"
        defaultView={defaultView}
      />
      <BaseControl
        icon="garage"
        iconColor="focus"
        iconType="custom"
        value={parking}
        handleChange={changePropertyDetails('parking')}
        tooltip="Parking spaces"
        defaultView={defaultView}
      />
      <BaseControl
        style={{ minWidth: 75 }}
        icon="height"
        iconColor="focus"
        iconType="custom"
        description="sqft"
        value={floor_area}
        step={50}
        handleChange={changePropertyDetails('floor_area')}
        tooltip="Floor area"
        defaultView={defaultView}
      />
      <BaseControl
        style={{ minWidth: 75 }}
        icon="maximize"
        iconColor="focus"
        description="acres"
        value={plot_area}
        handleChange={changePropertyDetails('plot_area')}
        tooltip="Plot area"
        defaultView={defaultView}
      />
    </Flex>
  );
};

PropertyParametersMain.defaultProps = {
  defaultView: 'edit',
};

const {
  func,
  number,
  oneOf,
  shape,
} = PropTypes;

PropertyParametersMain.propTypes = {
  parameters: shape({
    bedrooms: number,
    bathrooms: number,
    floor_area: number,
    plot_area: number,
    parking: number,
  }).isRequired,
  handleChangeProperty: func.isRequired,
  defaultView: oneOf(['edit', 'display']),
};

export default PropertyParametersMain;
