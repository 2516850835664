import { createSelector } from 'reselect';

import byId from 'store/selectors/realtorLeads/byId';

export default createSelector(
  (state, leadId) => byId(state, leadId)?.properties,
  (state, leadId) => byId(state, leadId)?.property_requirements,
  (properties = [], propertyRequirements = []) => {
    const options = [];

    properties && properties.forEach((item) => {
      options.push({
        type: 'property',
        itemId: item,
      });
    });
    propertyRequirements && propertyRequirements.forEach((item) => {
      options.push({
        type: 'propertyRequirement',
        itemId: item,
      });
    });

    return options;
  },
);
