import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getOrders from './getOrders';
import getOrderById from './getOrderById';
import getOrderAttachments from './getOrderAttachments';

import createOrder from './createOrder';

import setSelectedOrder from './setSelectedOrder';

import updateOrder from './updateOrder';

export { getOrders };
export { getOrderById };
export { getOrderAttachments };

export { createOrder };

export { setSelectedOrder };

export { updateOrder };

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedOrderActions = {
  getOrderById: memoizeDebounce(
    (id) => store.dispatch(getOrderById(id)),
    debounceDelay,
    debounceOptions,
  ),
};
