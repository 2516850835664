import React from 'react';

import getAvatarUrl from 'shared/utils/getAvatarUrl';

import Avatar from 'shared/components/UI/RealtorContactAvatar';

import withPlayer from 'shared/hocs/withPlayer';

const Item = (props) => {
  const {
    name,
    surname,
    avatar,
  } = props;

  const avatarImageSrc = getAvatarUrl(avatar);

  return (
    <Avatar
      src={avatarImageSrc}
      firstName={name}
      lastName={surname}
      size={35}
    />
  );
};

export default withPlayer(Item);
