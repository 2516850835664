import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

const EditableArea = (props) => {
  const {
    children,
    getEditComponent,
    fullWidth,
    disabled,
    label,
  } = props;

  const [editMode, setEditMode] = useState(false);

  const editModeOn = useCallback(() => {
    if (disabled) {
      return;
    }

    setEditMode(true);
  }, [
    setEditMode,
    disabled,
  ]);

  const editModeOff = useCallback(() => {
    setEditMode(false);
  }, [
    setEditMode,
  ]);

  return (
    <div
      style={{ width: fullWidth ? '100%' : 'auto' }}
      onClick={editModeOn}
    >
      {
        !editMode && children
      }
      {
        editMode && getEditComponent(editModeOff)
      }
    </div>
  );
};

EditableArea.defaultProps = {
  fullWidth: false,
  disabled: false,
};

const {
  element,
  func,
  oneOfType,
  string,
  bool,
} = PropTypes;

EditableArea.propTypes = {
  children: oneOfType([element, string]).isRequired,
  getEditComponent: func.isRequired,
  fullWidth: bool,
  disabled: bool,
};

export default EditableArea;
