import React, { useCallback } from 'react';

import Button from 'ui/Button';
import Typography from 'ui/Typography';
import useModal from 'ui/hooks/useModal';

import useAccountReset from 'sections/Settings/General/AccountSettings/useAccountReset';
import useDemoDataSeed from 'sections/Settings/General/AccountSettings/useDemoDataSeed';

const AccountSettings = () => {
  const {
    openModal,
    closeModal,
    isModalVisible,
    Modal,
  } = useModal();

  const {
    reset,
  } = useAccountReset();

  const {
    seed,
  } = useDemoDataSeed();

  const resetAccount = useCallback(async () => {
    try {
      await reset();
    } catch (error) {

    } finally {
      closeModal();
    }
  }, [
    closeModal,
    reset,
  ]);

  const addDemoData = useCallback(async () => {
    try {
      await seed();
    } catch (error) {

    } finally {
      closeModal();
    }
  }, [
    closeModal,
    seed,
  ]);

  return (
    <>
      <Button
        variant="cancel"
        onClick={openModal}
      >
        Reset Account
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        noCloseIcon
        footer={[
          <Button
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>,
          <Button
            variant="cancel"
            onClick={resetAccount}
          >
            Reset account
          </Button>,
          <Button
            variant="primary"
            onClick={addDemoData}
          >
            Add demo data
          </Button>,
        ]}
      >
        <Typography>
          This will remove all your data but for the account owner information and fill it with demo data instead.
        </Typography>
        <Typography>
          This action is irreversible.
        </Typography>
        <Typography>
          Are you sure you want to proceed?
        </Typography>
      </Modal>
    </>
  );
};

export default AccountSettings;
