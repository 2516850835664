import {
  SET_REALTOR_ADDENDUM_CHANGES,
} from 'store/actionTypes/realtorAddendumChanges';

const setByFilter = (items) => ({
  type: SET_REALTOR_ADDENDUM_CHANGES,
  payload: items,
});

export default setByFilter;
