import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD,
} from 'store/actionTypes/realtorContacts';

import {
  setValue,
} from 'store/utils';

const initialState = {
  eventDate: null,
  propertyAddress: null,
  type: null,
  propertyStatus: null,
  notes: null,
};

const reducers = {
  [SET_NEW_REALTOR_CONTACT_LOG_FORM_FIELD]: setValue,
};

function newLogForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newLogForm;
