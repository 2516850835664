import React from 'react';

import Typography from 'ui/Typography';
import Item from 'ui/Item';
import Container from 'ui/Container';

import PropertyAddressInput from 'sections/Leads/forms/NewLeadForm/PropertyAddressInput';
import ExpectedSalePriceInput from 'sections/Leads/forms/NewLeadForm/ExpectedSalePriceInput';
import LeadPropertyParametersArea from 'sections/Leads/forms/NewLeadForm/LeadPropertyParametersArea';
import PropertyTypeSelect from 'sections/Leads/forms/NewLeadForm/PropertyTypeSelect';

import {
  PropertyDetailsWrapper,
} from './styledItems';

const SellerSection = (props) => (
  <Container spacing={2}>
    <Item xs={12}>
      <Typography
        variant="h3"
        noMargin
      >
        Complete this section to represent this person as a Seller.
      </Typography>
    </Item>

    <Item xs={12}>
      <ExpectedSalePriceInput />
    </Item>

    <Item xs={12}>
      <PropertyAddressInput />
    </Item>

    <Item xs={12}>
      <PropertyDetailsWrapper>
        <Container spacing={2}>
          <Item xs={12}>
            <Typography
              variant="h4"
              noMargin
            >
              Property Details (Seller)
            </Typography>
          </Item>

          <Item xs={12}>
            <PropertyTypeSelect />
          </Item>

          <Item xs={12}>
            <LeadPropertyParametersArea
              sectionType="seller"
            />
          </Item>
        </Container>
      </PropertyDetailsWrapper>
    </Item>
  </Container>
);

export default SellerSection;
