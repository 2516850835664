import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Modal from 'ui/Modal';

import IconButton from '../IconButton';

import Button from 'ui/Button';

import './CommentActionPanel.scss';


class CommentActionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalVisible: false,
    };
  }

  triggerDeleteModal = () => {
    this.setState((state) => ({
      deleteModalVisible: !state.deleteModalVisible,
    }));
  }

  onClickDelete = () => {
    const { onClickDelete } = this.props;
    this.triggerDeleteModal();
    onClickDelete();
  }

  render() {
    const {
      className,
      isConfirmed,
      isOwner,
      onClickDelete,
      onClickEdit,
      onClickLike,
      onClickThread,
    } = this.props;
    const { deleteModalVisible } = this.state;

    return (
      <>
        <div
          className={classnames(
            'comment-action-panel-root',
            {
              [className]: className,
            },
          )}
        >
          {
            !!onClickLike && (
              <Button
                variant={isConfirmed ? 'primary' : 'secondary'}
                icon="thumbs-up"
                onClick={onClickLike}
              />
            )
          }
          {
            !!onClickThread && (
              <Button
                variant="secondary"
                icon="message-circle"
                onClick={onClickThread}
              />
            )
          }
          {
            !!onClickEdit && isOwner && (
              <Button
                variant="secondary"
                icon="edit"
                onClick={onClickEdit}
              />
            )
          }
          {
            !!onClickDelete && isOwner && (
              <Button
                variant="secondary"
                icon="trash"
                onClick={this.triggerDeleteModal}
              />
            )
          }
        </div>
        <Modal
          title="Are you sure?"
          visible={deleteModalVisible}
          centered
          onCancel={this.triggerDeleteModal}
          footer={[
            <Button
              onClick={this.triggerDeleteModal}
              variant="secondary"
            >
              No
            </Button>,
            <Button
              variant="primary"
              onClick={this.onClickDelete}
            >
              Yes
            </Button>,
          ]}
        />
      </>
    );
  }
}

CommentActionPanel.defaultProps = {
  className: '',
  onClickLike: null,
  onClickThread: null,
  onClickDelete: null,
  onClickEdit: null,
  isOwner: false,
  isConfirmed: false,
};

const {
  bool,
  string,
  func,
} = PropTypes;

CommentActionPanel.propTypes = {
  className: string,
  isConfirmed: bool,
  isOwner: bool,
  onClickDelete: func,
  onClickEdit: func,
  onClickLike: func,
  onClickThread: func,
};

export default CommentActionPanel;
