import { callAlert } from 'ui/Alert';
import { apiConnector } from '../../shared/API'; // eslint-disable-line import/no-cycle

// URLs
const helperdataUrl = '/kb_helper_data';
const categoryUrl = '/category';
const subCategoryEditUrl = '/category/sub/';
const subCategoryDeleteUrl = '/category/sub/';
const categoryDeleteUrl = '/category/';
const subCategoryUrl = '/category/sub/';
const articleUrl = '/article/';
const articleReplace = '/article/replace';
const firstSearchArticleUrl = '/kb/search/first';
const searchArticlesUrl = '/kb/search/';
const suggestionArticleHoverUrl = '/kb/search/hover/';
const categoryInfo = '/kb_helper_data/category?cat=';
const subCategoryInfo = '/kb_helper_data/category?subcat=';
const sort = '/kb/sort?';

const cyrillicPattern = /[\u0400-\u04FF]/;

export const GET_HELPER_DATA = 'GET_HELPER_DATA';
export const GET_HELPER_DATA_SUCCESS = 'GET_HELPER_DATA_SUCCESS';
export const GET_HELPER_DATA_ERROR = 'GET_HELPER_DATA_ERROR';

export const SHOW_CATEGORY_CREATE_MODAL = 'SHOW_CATEGORY_CREATE_MODAL';
export const showCategoryCreateModal = () => ({ type: SHOW_CATEGORY_CREATE_MODAL });

// show article detail modal
export const SHOW_ARTICLE_DETAIL_MODAL = 'SHOW_ARTICLE_DETAIL_MODAL';
export const showArticleDetailModal = () => ({ type: SHOW_ARTICLE_DETAIL_MODAL });

// Get articles from parent
export const GET_ARTICLES_FROM_PARENT = 'GET_ARTICLES_FROM_PARENT';
export const getArticlesFromParent = () => ({ type: GET_ARTICLES_FROM_PARENT });

export const SHOW_DELETE_CATEGORY_WARNING_MODAL = 'SHOW_DELETE_CATEGORY_WARNING_MODAL';
export const showDeleteCategoryWarningModal = () => ({ type: SHOW_DELETE_CATEGORY_WARNING_MODAL });

// Show edit category modal and write active category data
export const SHOW_CATEGORY_EDIT_MODAL = 'SHOW_CATEGORY_EDIT_MODAL';
export const SHOW_CATEGORY_EDIT_MODAL_SUCCESS = 'SHOW_CATEGORY_EDIT_MODAL_SUCCESS';
export const SHOW_CATEGORY_EDIT_MODAL_ERROR = 'SHOW_CATEGORY_EDIT_MODAL_ERROR';

export const showCategoryEditModal = (active) => (dispatch) => {
  dispatch(showCategoryEditModalAction);

  if (active && typeof (active.subcategoryId) === 'number') {
    apiConnector(`${subCategoryInfo}${active.subcategoryId}`, 'GET')
      .then((res) => dispatch(showCategoryEditModalSuccessAction(res)))
      .catch((err) => dispatch(showCategoryEditModalErrorAction(err)));
  } else if (active && active.categoryId && active.subcategoryId === undefined) {
    apiConnector(`${categoryInfo}${active.categoryId}`, 'GET')
      .then((res) => dispatch(showCategoryEditModalSuccessAction(res)))
      .catch((err) => dispatch(showCategoryEditModalErrorAction(err)));
  } else {
    dispatch(showCategoryEditModalSuccessAction({}));
  }
};

export const showCategoryEditModalAction = () => ({
  type: SHOW_CATEGORY_EDIT_MODAL,
});

export const showCategoryEditModalSuccessAction = (data) => ({
  type: SHOW_CATEGORY_EDIT_MODAL_SUCCESS,
  payload: data,
});

export const showCategoryEditModalErrorAction = (data) => ({
  type: SHOW_CATEGORY_EDIT_MODAL_ERROR,
  payload: data,
});

// Het helper data info
export const getHelperData = () => (dispatch) => {
  dispatch(getHelperDataAction);
  apiConnector(helperdataUrl, 'GET')
    .then((res) => dispatch(getHelperDataSuccessAction(res)))
    .catch((err) => dispatch(getHelperDataErrorAction(err)));
};

export const getHelperDataAction = () => ({
  type: GET_HELPER_DATA,
});

export const getHelperDataSuccessAction = (data) => ({
  type: GET_HELPER_DATA_SUCCESS,
  payload: data,
});

export const getHelperDataErrorAction = (data) => ({
  type: GET_HELPER_DATA_ERROR,
  payload: data,
});

// Create categories
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const createCategory = (category) => (dispatch) => {
  dispatch(createCategoryAction);
  if (category.location !== 'parent') {
    apiConnector(`${subCategoryUrl}${category.location}`, 'POST', {
      subcat: category.name,
      accesabilityId: category.accessibility,
      productID: category.relatedProduct,
    })
      .then((res) => {
        if (res.msg) {
          callAlert.error(res.msg);
        } else {
          dispatch(createCategorySuccessAction(res));
        }
      })
      .catch((err) => dispatch(createCategoryErrorAction(err)));
  } else {
    apiConnector(categoryUrl, 'POST', {
      category: category.name,
      accesabilityId: category.accessibility,
      productID: category.relatedProduct,
    })
      .then((res) => {
        if (res.msg) {
          callAlert.error(res.msg);
        } else {
          dispatch(createCategorySuccessAction(res));
        }
      })
      .catch((err) => dispatch(createCategoryErrorAction(err)));
  }
};

export const createCategoryAction = () => ({
  type: CREATE_CATEGORY,
});

export const createCategorySuccessAction = (data) => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload: data,
});

export const createCategoryErrorAction = (data) => ({
  type: CREATE_CATEGORY_ERROR,
  payload: data,
});

// Edit categories
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

export const editCategory = (category, active) => (dispatch) => {
  dispatch(editCategoryAction);
  if (active.subcategoryId) {
    const body = {
      categoryId: category.location,
      accesabilityId: category.accessibility,
      productID: category.relatedProduct,
      name: category.name,
    };
    apiConnector(`${subCategoryEditUrl}${active.subcategoryId}`, 'PUT', body)
      .then((res) => {
        if (res.msg) {
          callAlert.error(res.msg);
        } else {
          dispatch(editCategorySuccessAction(res));
        }
      })
      .catch((err) => dispatch(editCategoryErrorAction(err)));
  } else {
    apiConnector(`${categoryUrl}/${active.categoryId}`, 'PUT', {
      name: category.name,
      accesabilityId: category.accessibility,
      productID: category.relatedProduct,
    })
      .then((res) => {
        if (res.msg) {
          callAlert.error(res.msg);
        } else {
          dispatch(editCategorySuccessAction(res));
        }
      })
      .catch((err) => dispatch(editCategoryErrorAction(err)));
  }
};

export const editCategoryAction = () => ({
  type: EDIT_CATEGORY,
});

export const editCategorySuccessAction = (data) => ({
  type: EDIT_CATEGORY_SUCCESS,
  payload: data,
});

export const editCategoryErrorAction = (data) => ({
  type: EDIT_CATEGORY_ERROR,
  payload: data,
});

// GET all categories
export const GET_KB_CATEGORIES = 'GET_KB_CATEGORIES';
export const GET_KB_CATEGORIES_SUCCESS = 'GET_KB_CATEGORIES_SUCCESS';
export const GET_KB_CATEGORIES_ERROR = 'GET_KB_CATEGORIES_ERROR';

export const getKBCategories = () => (dispatch) => {
  dispatch(getKBCategoriesAction);
  apiConnector(categoryUrl, 'GET')
    .then((res) => dispatch(getKBCategoriesSuccessAction(res)))
    .catch((err) => dispatch(getKBCategoriesErrorAction(err)));
};

export const getKBCategoriesAction = () => ({
  type: GET_KB_CATEGORIES,
});

export const getKBCategoriesSuccessAction = (data) => ({
  type: GET_KB_CATEGORIES_SUCCESS,
  payload: data,
});

export const getKBCategoriesErrorAction = (data) => ({
  type: GET_KB_CATEGORIES_ERROR,
  payload: data,
});

// DELETE all categories
export const DELETE_KB_CATEGORY = 'DELETE_KB_CATEGORY';
export const DELETE_KB_CATEGORY_SUCCESS = 'DELETE_KB_CATEGORY_SUCCESS';
export const DELETE_KB_CATEGORY_ERROR = 'DELETE_KB_CATEGORY_ERROR';

export const deleteKBCategory = (active) => (dispatch) => {
  dispatch(deleteKBCategoryAction);

  if (active.subcategoryId) {
    apiConnector(`${subCategoryDeleteUrl}${active.subcategoryId}`, 'DELETE')
      .then((res) => {
        if (res.msg) {
          dispatch(showDeleteCategoryWarningModal());
        } else {
          dispatch(deleteKBCategorySuccessAction(res));
        }
      })
      .catch((err) => {
        dispatch(deleteKBCategoryErrorAction(err));
      });
  } else {
    apiConnector(`${categoryDeleteUrl}${active.categoryId}`, 'DELETE')
      .then((res) => {
        if (res.msg) {
          dispatch(showDeleteCategoryWarningModal());
        } else {
          dispatch(deleteKBCategorySuccessAction(res));
        }
      })
      .catch((err) => {
        dispatch(deleteKBCategoryErrorAction(err));
      });
  }
};

export const deleteKBCategoryAction = () => ({
  type: DELETE_KB_CATEGORY,
});

export const deleteKBCategorySuccessAction = (data) => ({
  type: DELETE_KB_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteKBCategoryErrorAction = (data) => ({
  type: DELETE_KB_CATEGORY_ERROR,
  payload: data,
});

// GET articles
export const GET_KB_ARTICLES = 'GET_KB_ARTICLES';
export const GET_KB_ARTICLES_SUCCESS = 'GET_KB_ARTICLES_SUCCESS';
export const GET_KB_ARTICLES_ERROR = 'GET_KB_ARTICLES_ERROR';

export const getKBArticles = (subCatId, catId) => (dispatch) => {
  dispatch(getKBArticlesAction);

  const route = `${articleUrl}?subcatId=${subCatId}&catId=${catId}`;

  apiConnector(route, 'GET')
    .then((res) => dispatch(getKBArticlesSuccessAction(res)))
    .catch((err) => dispatch(getKBArticlesErrorAction(err)));
};

export const getKBArticlesAction = () => ({
  type: GET_KB_ARTICLES,
});

export const getKBArticlesSuccessAction = (data) => ({
  type: GET_KB_ARTICLES_SUCCESS,
  payload: data,
});

export const getKBArticlesErrorAction = (data) => ({
  type: GET_KB_ARTICLES_ERROR,
  payload: data,
});

// Move articles to category
export const MOVE_ARTICLES_TO_CATEGORY = 'MOVE_ARTICLES_TO_CATEGORY';
export const MOVE_ARTICLES_TO_CATEGORY_SUCCESS = 'MOVE_ARTICLES_TO_CATEGORY_SUCCESS';
export const MOVE_ARTICLES_TO_CATEGORY_ERROR = 'MOVE_ARTICLES_TO_CATEGORY_ERROR';

export const moveArticlesToCategory = (categoryId, subcategoryId, deleteCatId, deleteSubCatId) => (dispatch) => {
  dispatch(moveArticlesToCategoryAction);
  apiConnector(`${articleReplace}`, 'PATCH', {
    subcategoryId: subcategoryId || null,
    categoryId,
    deleteCatId,
    deleteSubCatId,
  })
    .then((res) => dispatch(moveArticlesToCategorySuccessAction(res)))
    .catch((err) => dispatch(moveArticlesToCategoryErrorAction(err)));
};

export const moveArticlesToCategoryAction = () => ({
  type: MOVE_ARTICLES_TO_CATEGORY,
});

export const moveArticlesToCategorySuccessAction = (data) => ({
  type: MOVE_ARTICLES_TO_CATEGORY_SUCCESS,
  payload: data,
});

export const moveArticlesToCategoryErrorAction = (data) => ({
  type: MOVE_ARTICLES_TO_CATEGORY_ERROR,
  payload: data,
});

// Set active subcategory
export const SET_ACTIVE_SUB_CATEGORY = 'SET_ACTIVE_SUB_CATEGORY';

export const setActiveSubCategory = (categoryId, subcategoryId) => (dispatch) => {
  dispatch(setActiveSubCategoryAction(categoryId, subcategoryId));
};

export const setActiveSubCategoryAction = (categoryId, subcategoryId) => ({
  type: SET_ACTIVE_SUB_CATEGORY,
  payload: { categoryId, subcategoryId },
});

// Set active category
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';

export const setActiveCategory = (id) => (dispatch) => {
  dispatch(setActiveCategoryAction(id));
};

export const setActiveCategoryAction = (id) => ({
  type: SET_ACTIVE_CATEGORY,
  payload: id,
});

// Save KB search filter
export const SAVE_SEARCH_FILTER = 'SAVE_SEARCH_FILTER';

export const saveSearchFilter = (filter) => (dispatch) => {
  dispatch(saveSearchFilterAction(filter));
};

export const saveSearchFilterAction = (filter) => ({
  type: SAVE_SEARCH_FILTER,
  payload: filter,
});

// KB articles for first click
export const GET_INITIAL_SEARCH_ARTICLES = 'GET_INITIAL_SEARCH_ARTICLES';
export const GET_INITIAL_SEARCH_ARTICLES_SUCCESS = 'GET_INITIAL_SEARCH_ARTICLES_SUCCESS';
export const GET_INITIAL_SEARCH_ARTICLES_ERROR = 'GET_INITIAL_SEARCH_ARTICLES';

export const getInitialSearchArticles = () => (dispatch) => {
  dispatch(getInitialSearchArticlesAction);
  apiConnector(firstSearchArticleUrl, 'GET')
    .then((res) => dispatch(getInitialSearchArticlesSuccessAction(res)))
    .catch((err) => dispatch(getInitialSearchArticlesErrorAction(err)));
};

export const getInitialSearchArticlesAction = () => ({
  type: GET_INITIAL_SEARCH_ARTICLES,
});

export const getInitialSearchArticlesSuccessAction = (data) => ({
  type: GET_INITIAL_SEARCH_ARTICLES_SUCCESS,
  payload: data,
});

export const getInitialSearchArticlesErrorAction = (data) => ({
  type: GET_INITIAL_SEARCH_ARTICLES_ERROR,
  payload: data,
});

// Search articles
export const SEARCH_ARTICLES = 'SEARCH_ARTICLES';
export const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS';
export const SEARCH_ARTICLES_ERROR = 'SEARCH_ARTICLES_ERROR';

export const searchArticles = (word, filter) => (dispatch) => {
  dispatch(searchArticlesAction);
  const {
    feedback, keywords, category, labels, types, accessibility, product, starred, collaborators, archived, subcategory,
  } = filter;
  const rate = {};
  if (feedback) {
    feedback.map((elem) => {
      if (elem === '39') {
        rate.v39 = true;
      }
      if (elem === '40') {
        rate.v40 = true;
      }
      if (elem === '90') {
        rate.v90 = true;
      }
    });
  }

  let feedbackQuery = '';
  if (rate.v39 && rate.v40 && rate.v90) {
    feedbackQuery = ''; // <=100
  } else if (rate.v39 && rate.v40 && !rate.v90) {
    feedbackQuery = '&helplte=90';// <=90
  } else if (!rate.v39 && rate.v40 && rate.v90) {
    feedbackQuery = '&helpgte=40'; // =>40
  } else if (rate.v39 && !rate.v40 && !rate.v90) {
    feedbackQuery = '&helplte=40'; // <40
  } else if (rate.v39 && !rate.v40 && rate.v90) {
    feedbackQuery = '&helpgte=40&helplte=90'; // <40 && >90
  } else if (!rate.v39 && !rate.v40 && rate.v90) {
    feedbackQuery = '&helpgte=90'; // >90
  } else if (!rate.v39 && rate.v40 && !rate.v90) {
    feedbackQuery = '&helplte=90&helpgte=40'; // >40 && <90
  } else {
    feedbackQuery = '';
  }

  const keywordsQuery = keywords !== undefined ? keywords.join(';') : '';
  const categoryQuery = category !== undefined ? category : '';
  const subCategoryQuery = subcategory !== undefined ? subcategory : '';
  const labelsQuery = labels !== undefined ? labels.join(';') : '';
  const typesQuery = types !== undefined ? types : '';
  const accessibilityQuery = accessibility !== undefined ? accessibility : '';
  const productQuery = product !== undefined ? product : '';
  const collaboratorsQuery = collaborators !== undefined ? collaborators.join(';') : '';
  const starredQuery = starred !== undefined ? starred : '';
  const archivedQuery = archived !== undefined ? archived : '';

  /*
   * @type {string}
   * @w - check title, description, text
   * @helpgte - rate more than *
   * @helplte - rate less than *
   * @key {array[string]} - keyword
   * @cat- Category ID
   * @label - Label ID
   * @type - type ID
   * @access - accessability ID
   * @product - product ID
   * @collab - collaborators
   * @starred {boolean}- starred articles
   */

  // eslint-disable-next-line max-len
  const queryParams = `?w=${!cyrillicPattern.test(word) ? word : ''}${feedbackQuery}&key=${keywordsQuery}&cat=${categoryQuery}&sub=${subCategoryQuery}&label=${labelsQuery}&type=${typesQuery}&access=${accessibilityQuery}&product=${productQuery}&collab=${collaboratorsQuery}&starred=${starredQuery}&archived=${archivedQuery}`;

  if (word !== '') {
    apiConnector(`${searchArticlesUrl}${queryParams}`, 'GET')
      .then((res) => {
        dispatch(searchArticlesSuccessAction(res));
      })
      .catch((err) => dispatch(searchArticlesErrorAction(err)));
  }
};

export const searchArticlesAction = () => ({
  type: SEARCH_ARTICLES,
});

export const searchArticlesSuccessAction = (data) => ({
  type: SEARCH_ARTICLES_SUCCESS,
  payload: data,
});

export const searchArticlesErrorAction = (data) => ({
  type: SEARCH_ARTICLES_ERROR,
  payload: data,
});

// hover suggestion article
export const SUGGESTION_ARTICLE_HOVER = 'SUGGESTION_ARTICLE_HOVER';
export const SUGGESTION_ARTICLE_HOVER_SUCCESS = 'SUGGESTION_ARTICLE_HOVER_SUCCESS';
export const SUGGESTION_ARTICLE_HOVER_ERROR = 'SUGGESTION_ARTICLE_HOVER_ERROR';

export const onSearchSuggestionArticleHover = (articleId) => (dispatch) => {
  dispatch(searchSuggestionArticleHoverAction);
  apiConnector(`${suggestionArticleHoverUrl}${articleId}`, 'GET')
    .then((res) => dispatch(searchSuggestionArticleHoverSuccessAction(res)))
    .catch((err) => dispatch(searchSuggestionArticleHoverErrorAction(err)));
};

export const searchSuggestionArticleHoverAction = () => ({
  type: SUGGESTION_ARTICLE_HOVER,
});

export const searchSuggestionArticleHoverSuccessAction = (data) => ({
  type: SUGGESTION_ARTICLE_HOVER_SUCCESS,
  payload: data,
});

export const searchSuggestionArticleHoverErrorAction = (data) => ({
  type: SUGGESTION_ARTICLE_HOVER_ERROR,
  payload: data,
});

// sort articles in category
export const SORT_ARTICLE = 'SORT_ARTICLE';
export const SORT_ARTICLE_SUCCESS = 'SORT_ARTICLE_SUCCESS';
export const SORT_ARTICLE_ERROR = 'SORT_ARTICLE_ERROR';

/**
 *
 * @param subCatId - active subcategory id
 * @param catId - active category id
 * @param param [created_at, title, helpfulRate] - sort parameters with order
 * @returns {Function}
 */
export const sortKBArticles = (param, catId, subCatId) => (dispatch) => {
  dispatch(sortKBArticlesAction);
  const sortURL = `${sort}subId=${subCatId}&catId=${catId}&param=${param}`;
  apiConnector(`${sortURL}`, 'GET')
    .then((res) => dispatch(sortKBArticlesSuccessAction(res)))
    .catch((err) => dispatch(sortKBArticlesErrorAction(err)));
};

export const sortKBArticlesAction = () => ({
  type: SORT_ARTICLE,
});

export const sortKBArticlesSuccessAction = (data) => ({
  type: SORT_ARTICLE_SUCCESS,
  payload: data,
});

export const sortKBArticlesErrorAction = (data) => ({
  type: SORT_ARTICLE_ERROR,
  payload: data,
});

// Search filter modal
export const SHOW_SEARCH_FILTER_MODAL = 'SHOW_SEARCH_FILTER_MODAL';
export const showSearchFilterModal = () => ({ type: SHOW_SEARCH_FILTER_MODAL });

// Show search standalone modal
export const SHOW_SEARCH_STANDALONE_MODAL = 'SHOW_SEARCH_STANDALONE_MODAL';
export const showSearchStandaloneModal = () => ({ type: SHOW_SEARCH_STANDALONE_MODAL });

// if category creation is necessary before creating an article
export const TOGGLE_PROMPT_TO_CREATE_CATEGORY = 'TOGGLE_PROMPT_TO_CREATE_CATEGORY';
export const togglePromptToCreateCategory = () => ({ type: TOGGLE_PROMPT_TO_CREATE_CATEGORY });
