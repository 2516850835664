import apiConnector, { endpoints } from 'api';

const getEmployeeTasksSummaryForPeriod = (employeeId) => async (startDate, endDate) => {
  const endpoint = `${endpoints.client.v2.employeeTasksSummaryForPeriod}/${employeeId}`;
  const query = {
    startDate,
    endDate,
  };

  try {
    const result = await apiConnector.client.get(endpoint, query);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default getEmployeeTasksSummaryForPeriod;
