/* eslint-disable max-len */

import uploadExternalAttachment from 'api/requests/attachments/uploadExternalAttachment';

import createAttachmentFormData from 'shared/utils/attachments/createAttachmentFormData';
import { callAlert } from 'ui/Alert/Alert';

const uploadAttachments = (offerId) => async (files) => {
  const requests = files.map(async (file) => {
    const attachmentsData = {
      file,
      objectId: offerId,
      objectType: 31,
      attachmentType: 12,
    };

    const formData = await createAttachmentFormData(attachmentsData.file, {}, () => {});

    await uploadExternalAttachment(attachmentsData.attachmentType)(attachmentsData.objectType)(attachmentsData.objectId)(formData);
  });

  try {
    await Promise.all(requests);
  } catch (error) {
    callAlert.error('Something went wrong!');
  }
};

export default uploadAttachments;
