import deleteProspect from './deleteProspect';

const deleteSelectedProspect = () => async (dispatch, getState) => {
  const {
    realtorProspects: {
      selectedProspect,
    },
  } = getState();

  dispatch(deleteProspect(selectedProspect));
};

export default deleteSelectedProspect;
