import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import Flex from 'ui/Flex';

import {
  Checkbox,
  Col,
  Row,
  Tooltip,
} from 'antd';

import {
  ButtonWrapper,
} from './styledItems';

const CommentControls = (props) => {
  const {
    onSubmit,
    onCancel,
    togglePostPublic,
    hideCustomerVisibilitySelector,
    isVisibleToCustomer,
  } = props;

  const [isChecked, setIsChecked] = useState(isVisibleToCustomer || false);

  const checkPostPublic = useCallback(() => {
    togglePostPublic();
    setIsChecked(!isChecked);
  }, [isChecked]);

  return (
    <>
      {
        !hideCustomerVisibilitySelector && (
          <Row
            className="post-public-checkbox"
            gutter={2}
            type="flex"
            justify="start"
          >
            <Col>
              <Checkbox
                onChange={checkPostPublic}
                checked={isChecked}
              >
                Visible to customer
              </Checkbox>
            </Col>
            <Col>
              <Tooltip placement="bottom" title="If checked, this post will be visible to the customer">
                <div style={{
                  textDecoration: 'underline',
                  verticalAlign: 'top',
                  fontSize: '10px',
                }}
                >
                  ?
                </div>
              </Tooltip>
            </Col>
          </Row>
        )
      }

      <ButtonWrapper>
        <Flex spacing={1}>
          <Button
            variant="primary"
            onClick={onSubmit}
          >
            Save
          </Button>

          <Button
            variant="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Flex>
      </ButtonWrapper>
    </>
  );
};

const {
  func,
  bool,
} = PropTypes;

CommentControls.defaultProps = {
  togglePostPublic: null,
  hideCustomerVisibilitySelector: false,
  isVisibleToCustomer: false,
};

CommentControls.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  togglePostPublic: func,
  hideCustomerVisibilitySelector: bool,
  isVisibleToCustomer: bool,
};

export default CommentControls;
