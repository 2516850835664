// TODO - fix dependency cycles
import { apiConnector } from 'shared/API'; // eslint-disable-line import/no-cycle
import { // eslint-disable-line import/no-cycle
  getTasks,
  tasksStatus,
} from './tasks';

export const SET_TASK_EMPLOYEES = 'SET_TASK_EMPLOYEES';
export const setTaskEmployees = () => ({ type: SET_TASK_EMPLOYEES });

export const SET_TASK_EMPLOYEES_SUCCESS = 'SET_TASK_EMPLOYEES_SUCCESS';
export const setTaskEmployeesSuccess = (config, data) => (
  dispatch,
  getState,
) => {
  dispatch({ type: SET_TASK_EMPLOYEES_SUCCESS, config, data });
  tasksStatus(dispatch, getState);
  dispatch(getTasks());
};

export const SET_TASK_EMPLOYEES_ERROR = 'SET_TASK_EMPLOYEES_ERROR';
export const setTaskEmployeesError = () => ({ type: SET_TASK_EMPLOYEES_ERROR });

export const SET_TASK_CATEGORY = 'SET_TASK_CATEGORY';
export const setTaskCategory = (taskId, data) => (dispatch) => {
  const updateURL = `/api/tasks/updateCategory/${taskId}`;
  dispatch({ type: SET_TASK_CATEGORY });
  apiConnector(updateURL, 'PUT', data)
    .then((task) => dispatch(setTaskCategorySuccess(task)))
    .catch((err) => dispatch(setTaskCategoryError()));
};

export const SET_TASK_CATEGORY_SUCCESS = 'SET_TASK_CATEGORY_SUCCESS';
export const setTaskCategorySuccess = (task) => ({
  type: SET_TASK_CATEGORY_SUCCESS,
  task,
});

export const SET_TASK_CATEGORY_ERROR = 'SET_TASK_CATEGORY_ERROR';
export const setTaskCategoryError = () => ({ type: SET_TASK_CATEGORY_ERROR });
