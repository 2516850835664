import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tutorial from '../shared/Tutorial';

import tutorialRoutes from '../tutorialRoutes';

import './TasksTutorial.scss';


const TasksTutorial = (props) => {
  const { isModalVisible } = props;
  const finishTutorial = useCallback(() => {
    const {
      endTutorial,
      hideTutorialLayer,
      history: {
        push,
      },
      setStepIndex,
    } = props;
    endTutorial();
    setStepIndex(0);
    closeTask();
    hideTutorialLayer();
    push('/tasks');
  }, [isModalVisible]);

  const openTask = () => {
    const {
      showTaskModal,
      selectTask,
      addTutorialTask,
    } = props;
    if (!isModalVisible) {
      addTutorialTask();
      selectTask('tutorialTask');
      showTaskModal(true);
    }
  };

  const closeTask = () => {
    const {
      showTaskModal,
      removeTutorialTask,
    } = props;
    showTaskModal(false);
    removeTutorialTask();
  };

  const goToStep = useCallback((index) => {
    const {
      setStepIndex,
      startTutorial,
      endTutorial,
      history: {
        push,
      },
      steps,
    } = props;

    const stepActions = {
      'tutorial-create-new-task': () => {
        closeTask();
        window.history.pushState(null, null, tutorialRoutes.tasks.createNewTask);
      },
      'tutorial-create-new-subtask': () => {
        if (!isModalVisible) {
          endTutorial();
          openTask();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.tasks.createNewSubtask);
      },
      'tutorial-archive-task': () => {
        if (!isModalVisible) {
          endTutorial();
          openTask();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.tasks.archiveTask);
      },
      'tutorial-complete-task': () => {
        if (!isModalVisible) {
          endTutorial();
          openTask();
          setTimeout(() => {
            startTutorial();
          }, 1500);
        }
        window.history.pushState(null, null, tutorialRoutes.tasks.completeTask);
      },
      'tutorial-create-teams': () => {
        closeTask();
        push('/tasks/team-setup');
        window.history.pushState(null, null, tutorialRoutes.tasks.createTeams);
      },
      'tutorial-assign-tasks': () => {
        closeTask();
        push('/tasks/team-overview');
        window.history.pushState(null, null, tutorialRoutes.tasks.assignTasks);
      },
    };

    const currentStep = steps[index].name;

    stepActions[currentStep]();

    setStepIndex(index);
  }, [isModalVisible]);

  const {
    stepName,
    steps,
  } = props;

  return (
    <Tutorial
      className="tasks-tutorial"
      stepName={stepName}
      steps={steps}
      goToStep={goToStep}
      finishTutorial={finishTutorial}
      name="Tasks"
    />
  );
};

const {
  any,
  bool,
  string,
  func,
  object,
  arrayOf,
} = PropTypes;

TasksTutorial.defaultProps = {
  isModalVisible: false,
  stepName: '',
};

TasksTutorial.propTypes = {
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  startTutorial: func.isRequired,
  endTutorial: func.isRequired,
  hideTutorialLayer: func.isRequired,
  selectTask: func.isRequired,
  showTaskModal: func.isRequired,
  stepName: string,
  isModalVisible: bool,
  setStepIndex: func.isRequired,
  steps: arrayOf(any).isRequired,
  addTutorialTask: func.isRequired,
  removeTutorialTask: func.isRequired,
};

export default TasksTutorial;
