import {
  SET_DICTIONARY,
} from 'store/actionTypes/dictionary';

const setDictionary = (dictionaryName) => (dictionary) => ({
  type: SET_DICTIONARY,
  payload: {
    key: dictionaryName,
    value: dictionary,
  },
});

export default setDictionary;
