import requests from 'api/requests';

import { callAlert } from 'ui/Alert';

import getOpportunitiesOverallSummary from '../realtorOpportunities/getOpportunitiesOverallSummary';

const deleteClient = (clientId) => async (dispatch) => {
  try {
    await requests.realtor.clients.deleteClient(clientId);
    dispatch(getOpportunitiesOverallSummary());
    callAlert.success('Deleted');
  } catch (error) {
    console.error(error);
    callAlert.error('Could not delete');
  }
};

export default deleteClient;
