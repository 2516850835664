/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const contingenciesTypesIds = {
  1: 'Inspection',
  2: 'Appraisal',
  3: 'Loan',
};

const Contingencies = (props) => {
  const {
    contingenciesTypes,
  } = props;

  return (
    <span>
      <Typography
        weight="bold"
        tag="span"
        noMargin
      >
        {'Contingencies: '}
      </Typography>
      <Typography
        variant="body"
        tag="span"
        noMargin
      >
        {
          contingenciesTypes.length > 0
            ? contingenciesTypes
              .map((contingenciesType) => contingenciesTypesIds[contingenciesType])
              .join(', ')
            : '—'
        }
      </Typography>
    </span>
  );
};

const {
  arrayOf,
  number,
} = PropTypes;

Contingencies.propTypes = {
  contingenciesTypes: arrayOf(number).isRequired,
};

export default Contingencies;
