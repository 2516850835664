import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import TagLabel from 'shared/components/TagLabel';

import {
  Avatar,
  Initials,
  Name,
} from './styledItems';

const getInitials = (firstName = '', lastName = '') => {
  const fname = firstName ? firstName.split('')[0] || '' : '';
  const lname = lastName ? lastName.split('')[0] || '' : '';

  return fname + lname;
};

const PersonItem = (props) => {
  const {
    firstName,
    lastName,
    name,
    avatarUrl,
    label,
  } = props;

  return (
    <Grid
      container
      wrap="nowrap"
      spacing={1}
    >
      <Grid item>
        <Avatar src={avatarUrl}>
          {
            !avatarUrl && (
              <Initials>{getInitials(firstName, lastName)}</Initials>
            )
          }
        </Avatar>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justify="space-around"
      >
        <Grid item>
          <Name
            variant="h4"
            weight={600}
            style={{ marginBottom: '3px' }}
          >
            {name}
          </Name>
        </Grid>

        <Grid item>
          <TagLabel
            color={label.color}
          >
            { label.title?.toUpperCase() }
          </TagLabel>
        </Grid>
      </Grid>
    </Grid>
  );
};

PersonItem.defaultProps = {
  firstName: '',
  lastName: '',
  avatarUrl: null,
};

const {
  string,
  shape,
} = PropTypes;

PersonItem.propTypes = {
  firstName: string,
  lastName: string,
  avatarUrl: string,
  label: shape({
    title: string,
    color: string,
  }).isRequired,
};

export default PersonItem;
