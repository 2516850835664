import { connect } from 'react-redux';
import stableStringify from 'json-stable-stringify';
import moment from 'moment';

import {
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import {
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

import DayView from './DayView';

const momentFormat = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

const startOfDay = (date) => moment(date).startOf('day').utc();
const endOfDay = (date) => moment(date).endOf('day').utc();

const filters = {
  showingsFilter: {
    contact_type: 'not prospects',
  },
  pitchesFilter: {
    contact_type: 'prospects',
  },
  closingEscrowsFilter: {
    no_offset: true,
    date_fields: [
      'close_escrow_expires_at',
    ],
  },
  contingenciesDueFilter: {
    no_offset: true,
    date_fields: [
      'initial_documents_expires_at',
      'seller_disclosure_expires_at',
      'inspections_expires_at',
      'appraisal_expires_at',
      'loan_expires_at',
      'final_walkthrough_expires_at',
      'move_out_date_expires_at',
    ],
  },
  followUpRemindersFilter: {
  },
};

const getFilter = (filterName, date) => {
  const dateFrom = momentFormat(startOfDay(date));
  const dateTo = momentFormat(endOfDay(date));

  return {
    ...filters[filterName],
    date_from: dateFrom,
    date_to: dateTo,
    scheduledDate: [dateFrom, dateTo],
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    year,
    month,
    day,
  } = ownProps.match.params;

  return {
    year: Number(year),
    month: Number(month),
    day: Number(day),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getShowings: (date) => {
    const showingsFilter = getFilter('showingsFilter', date);
    optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(showingsFilter), showingsFilter);
  },

  getPitches: (date) => {
    const pitchesFilter = getFilter('pitchesFilter', date);
    optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(pitchesFilter), pitchesFilter);
  },

  getClosingEscrow: (date) => {
    const closingEscrowsFilter = getFilter('closingEscrowsFilter', date);

    optimizedRealtorTransactionsActions.getTransactionsForFilter(
      stableStringify(closingEscrowsFilter),
      closingEscrowsFilter,
    );
  },

  getContingenciesDue: (date) => {
    const contingenciesDueFilter = getFilter('contingenciesDueFilter', date);

    optimizedRealtorTransactionsActions.getTransactionsForFilter(
      stableStringify(contingenciesDueFilter),
      contingenciesDueFilter,
    );
  },

  getFollowUpReminders: (date) => {
    const remindersFilter = getFilter('followUpReminders', date);

    optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(
      stableStringify(remindersFilter),
      remindersFilter,
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DayView);
