import apiConnector, { endpoints } from 'api';

/**
 * Changes the user's password
 * @param {object} data User data
 * */

const resetPassword = async (data) => {
  await apiConnector.global.post(
    endpoints.global.password.reset,
    data,
  );
};

export default resetPassword;
