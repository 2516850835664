import apiConnector, { endpoints } from 'api';

const uploadLinkAttachment = (attachmentType) => (objectType) => (objectId) => async (url, isPublic = false) => {
  // eslint-disable-next-line max-len
  const endpoint = `${endpoints.client.attachFile}/${attachmentType}/${objectType}/${objectId}${isPublic ? '/public' : ''}`;

  try {
    const response = await apiConnector.client.post(endpoint, { url, isExternalLink: true });
    return response.data;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default uploadLinkAttachment;
