import requests from 'api/requests';

import { isEmpty } from 'shared/utility';

// eslint-disable-next-line consistent-return
const getLeadByContactId = (contactId) => async (dispatch) => {
  try {
    const response = await requests.realtor.leads.getByContactId(contactId);

    return !isEmpty(response.data.items) ? response.data.items[0] : null;
  } catch (error) {
    console.error(error);
  }
};

export default getLeadByContactId;
