import { createSelector } from 'reselect';

export default createSelector(
  (state) => state.propertiesFilter,
  (propertiesFilter) => {
    if (!propertiesFilter.status && !propertiesFilter.ownership) {
      return 'all';
    }
    if (propertiesFilter.ownership === 'mine') {
      return 'mine';
    }
    if (propertiesFilter.ownership === 'featured') {
      return 'featured';
    }
    if (propertiesFilter.status === 2) {
      return 'archived';
    }
    return 'all';
  },
);
