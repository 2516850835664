import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

export default (transactionId) => createSelector(
  (state) => state.realtorTransactions.transactions,
  (state) => state.realtorContacts.contacts,
  (collection, contacts) => {
    const item = collection[transactionId];

    if (!item) {
      if (transactionId) {
        optimizedRealtorTransactionsActions.getTransactionById(transactionId);
      }

      return [];
    }

    const relatedContactIds = [
      {
        id: item.seller_contact_id,
        transactionRole: 'Seller',
      },
      {
        id: item.seller_agent_contact_id,
        transactionRole: 'Seller agent',
      },
      {
        id: item.buyer_contact_id,
        transactionRole: 'Buyer',
      },
      {
        id: item.buyer_agent_contact_id,
        transactionRole: 'Buyer agent',
      },
      {
        id: item.escrow_agent_id,
        transactionRole: 'Escr. agent',
      },
      {
        id: item.title_agent_id,
        transactionRole: 'Title agent',
      },
    ];

    const relatedContacts = relatedContactIds
      .filter(({ id }) => id)
      .reduce((acc, { id, transactionRole }) => {
        const contact = contacts[id];

        if (!contact && id) {
          optimizedRealtorContactsActions.getContactById(id);
          return acc;
        }

        acc.push({
          ...contact,
          role: transactionRole,
        });
        return acc;
    }, []);

    return relatedContacts;
  },
);
