import { connect } from 'react-redux';

import selectors from 'store/selectors';

import LogShowFeedback from './LogShowFeedback';

const mapStateToProps = (state, ownProps) => {
  const matchingProperty = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  return {
    sentiment: matchingProperty?.feedback_sentiment,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LogShowFeedback);
