import apiConnector, { endpoints } from 'api';

const listUploads = async () => {
  try {
    const response = await apiConnector.client.get(
      endpoints.client.dataImport.upload,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default listUploads;
