import apiConnector, { endpoints } from 'api';

const editLog = (commentId) => async (commentBody) => {
  const endpoint = `${endpoints.client.logs}/item/${commentId}`;

  try {
    const result = await apiConnector.client.put(endpoint, commentBody);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default editLog;
