import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import useContactName from 'shared/hooks/contacts/useContactName';

const contactsLink = '/contacts';

const Breadcrumbs = (props) => {
  const {
    contactId,
  } = props;

  const history = useHistory();

  const handleClick = useCallback((event) => {
    event.preventDefault();
    history.push(contactsLink);
  });

  const name = useContactName(contactId);

  return (
    <MUIBreadcrumbs>
      <Link
        color="inherit"
        href={contactsLink}
        onClick={handleClick}
      >
        Contacts
      </Link>
      <Typography color="textPrimary">
        {name}
      </Typography>
    </MUIBreadcrumbs>
  );
};

const {
  number,
} = PropTypes;

Breadcrumbs.propTypes = {
  contactId: number.isRequired,
};

export default Breadcrumbs;
