import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Field from 'sections/Properties/forms/NewPropertyFormV2/TempSelect';

const TempSelect = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name: 'temp',
    control,
  });

  return (
    <Field
      value={value}
      onChange={onChange}
    />
  );
};

const {
  shape,
} = PropTypes;

TempSelect.propTypes = {
  control: shape({}).isRequired,
};

export default TempSelect;
