import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props?.theme?.palette?.['background-flat'] || '#f2f6fe'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Root,
};
