import React from 'react';
import PropTypes from 'prop-types';

import Textfield from 'ui/Textfield';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

import {
  Wrapper,
} from './styledItems';

const SpecialConditionsItem = (props) => {
  const {
    onDelete,
    onChange,
    value,
  } = props;

  return (
    <Wrapper>
      <Icon
        name="circle"
        color="highlight"
        size="xxs"
      />
      <Textfield
        onChange={onChange}
        value={value}
        style={{ margin: '5px 10px' }}
      />
      {
        value && (
          <Button
            variant="tertiary"
            icon="x"
            iconColor="default"
            iconSize="s"
            onClick={onDelete}
            style={{ marginLeft: 10 }}
          />
        )
      }
    </Wrapper>
  );
};

const {
  func,
  string,
} = PropTypes;

SpecialConditionsItem.propTypes = {
  value: string.isRequired,
  onDelete: func.isRequired,
  onChange: func.isRequired,
};

export default SpecialConditionsItem;
