import connectReducers from 'store/connectReducers';
import { // eslint-disable-line import/no-cycle
  TODOLIST_DRAWER_SHOW,
  TODOLIST_DRAWER_HIDE,
  TODOLIST_SETTINGS_SHOW,
  TODOLIST_SETTINGS_HIDE,
  TODOLIST_SHOW_ITEM,
  NEW_REMINDER,
  REMINDER_DELETING,
  CHANGE_IMPORTANCE,
  CHANGE_FULFILLMENT,
  CHANGE_TITLE,
  SET_INVITE_EMPLOYEE,
  SET_INVITE_EMPLOYEES_SUCCESS,
  SET_INVITE_EMPLOYEES_ERROR,
  TODOLIST_SAVE_SETTINGS,
  SET_INVITERS_GROUP,
  SET_INVITERS_GROUP_SUCCESS,
  SET_INVITERS_GROUP_ERROR,
  CHANGE_NUMBER_IN_LIST,
} from 'store/actions/to-do-list';

const initialState = {
  toDoListDrawerIsVisible: false,
  toDoListSettingsIsVisible: false,
  toDoListAssignModelIsVisible: false,
  remindersInf: [],
  assigneeBy: [],
  toDoListEmployeesSettings: {},
  result: {},
  employeeInvitersList: [],
  employeeSettingsInf: {},
  inviteEmployeesSuccessResponse: {},
};

const reducers = {
  [CHANGE_NUMBER_IN_LIST]: (state) => ({
    ...state,
  }),
  [SET_INVITERS_GROUP]: (state) => ({
    ...state,
  }),
  [SET_INVITERS_GROUP_SUCCESS]: (state, action) => ({
    ...state,
    result: action.result,
  }),
  [SET_INVITERS_GROUP_ERROR]: (state) => ({
    ...state,
  }),
  [TODOLIST_SAVE_SETTINGS]: (state, action) => ({
    ...state,
    result: action.result,
  }),
  [SET_INVITE_EMPLOYEE]: (state) => ({
    ...state,
  }),
  [SET_INVITE_EMPLOYEES_SUCCESS]: (state, action) => ({
    ...state,
    inviteEmployeesSuccessResponse: action.response,
  }),
  [SET_INVITE_EMPLOYEES_ERROR]: (state) => ({
    ...state,
  }),
  [TODOLIST_DRAWER_SHOW]: (state) => ({
    ...state,
    toDoListDrawerIsVisible: true,
  }),
  [TODOLIST_DRAWER_HIDE]: (state) => ({
    ...state,
    toDoListDrawerIsVisible: false,
  }),
  [TODOLIST_SETTINGS_SHOW]: (state) => ({
    ...state,
    toDoListSettingsIsVisible: true,
  }),
  [TODOLIST_SETTINGS_HIDE]: (state) => ({
    ...state,
    toDoListSettingsIsVisible: false,
  }),
  [TODOLIST_SHOW_ITEM]: (state, action) => (
    {
      ...state,
      remindersInf: action.remindersInf,
      assigneeBy: action.assigneeBy,
      employeeSettingsInf: action.employeeSettingsInf,
      employeeInvitersList: action.employeeInvitersList,
      toDoListEmployeesSettings: action.toDoListEmployeesSettings,
    }
  ),
  [NEW_REMINDER]: (state, action) => (
    {
      ...state,
      result: action.result,
    }
  ),
  [REMINDER_DELETING]: (state, action) => (
    {
      ...state,
      result: action.result,
    }
  ),
  [CHANGE_IMPORTANCE]: (state, action) => (
    {
      ...state,
      result: action.result,
    }
  ),
  [CHANGE_FULFILLMENT]: (state, action) => (
    {
      ...state,
      result: action.result,
    }
  ),
  [CHANGE_TITLE]: (state, action) => (
    {
      ...state,
      result: action.result,
    }
  ),
};


function todolist(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default todolist;
