import connectReducers from 'store/connectReducers';

import {
  SET_NEW_REALTOR_CONTACT_FORM_FIELD,
  SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD,
  SET_NEW_REALTOR_CONTACT_FORM,
} from 'store/actionTypes/realtorContacts';

import {
  setValue,
  setNestedValue,
} from 'store/utils';

const initialState = {
  // contact data fields

  // validators
  fieldsValidators: {
    isEmailValid: true,
    doesEmailExist: false,
    doesPhoneExist: false,
    isFirstNameValid: true,
  },

  // attachment
  avatar: null,

  // contact
  personTitle: 1,
  personFirstName: null,
  personLastName: null,
  jobRole: null,
  mainEmail: null,
  mainPhoneNumber: null,
  companyName: null,
  contactType: [],
  contactLabels: [],

  // source
  source: null,
  sourceType: null,
  newCustomSourceName: null,
  sourceDescription: null,

  // misc
  firstContactDate: null,
  contactNote: null,

  // past client marker
  isPastClient: false,
};

const reducers = {
  [SET_NEW_REALTOR_CONTACT_FORM_FIELD]: setValue,
  [SET_NEW_REALTOR_CONTACT_FORM_NESTED_FIELD]: setNestedValue,
  [SET_NEW_REALTOR_CONTACT_FORM]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

};

function newContactForm(state = initialState, action) {
  return connectReducers(reducers, state, action);
}

export default newContactForm;
