/* eslint-disable import/no-cycle */
import byId from './byId';
import selected from './selected';
import selectedPropertyViewings from './selectedPropertyViewings';
import selectedPropertyImages from './selectedPropertyImages';
import propertyImages from './propertyImages';
import propertiesFilter from './propertiesFilter';
import propertiesFilterKey from './propertiesFilterKey';
import propertiesList from './propertiesList';
import propertyViewings from './propertyViewings';
import propertyNearestViewing from './propertyNearestViewing';
import propertyLastEndedViewing from './propertyLastEndedViewing';
import mainPropertyImage from './mainPropertyImage';
import propertyMainImage from './propertyMainImage';
import overallSummary from './overallSummary';
import itemSummary from './itemSummary';
import propertyNotablePoints from './propertyNotablePoints';
import propertyDisclosures from './propertyDisclosures';

export default {
  overallSummary,
  propertyDisclosures,
  propertyNotablePoints,
  itemSummary,
  byId,
  selected,
  selectedPropertyViewings,
  selectedPropertyImages,
  propertyImages,
  propertiesFilter,
  propertiesFilterKey,
  propertiesList,
  propertyViewings,
  propertyNearestViewing,
  propertyLastEndedViewing,
  mainPropertyImage,
  propertyMainImage,
};
