import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

const DetailsSection = ({
  icon,
  iconType,
  title,
  children,
}) => (
  <Flex
    fullWidth
    spacing={1}
  >
    <Icon
      name={icon}
      type={iconType || 'feather'}
      size="l"
      color="focus"
    />
    <FlexItem flex={1}>
      <Flex spacing={2} flexDirection="column" fullWidth>
        <Typography
          variant="title2"
          noMargin
        >
          {title}
        </Typography>
        <FlexItem fullWidth>
          {children}
        </FlexItem>
      </Flex>
    </FlexItem>
  </Flex>
);

DetailsSection.defaultProps = {
  iconType: 'feather',
};

const {
  element,
  string,
} = PropTypes;

DetailsSection.propTypes = {
  icon: string.isRequired,
  iconType: string,
  title: string.isRequired,
  children: element,
};

export default DetailsSection;
