import { connect } from 'react-redux';

import selectors from 'store/selectors';

import KeyPeopleItem from './KeyPeopleItem';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    name: contact?.fullName,
  };
};

export default connect(mapStateToProps)(KeyPeopleItem);
