import {
  SET_REALTOR_SELECTED_PROSPECT,
} from 'store/actionTypes/realtorProspects';

const setSelectedProspect = (prospectId) => ({
  type: SET_REALTOR_SELECTED_PROSPECT,
  payload: prospectId,
});

export default setSelectedProspect;
