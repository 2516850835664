import React from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';

import PhoneInput from 'shared/inputs/PhoneInput';
import EmailInput from 'shared/inputs/EmailInput';

const YourDetails = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeFirstName,
      value: firstName,
    },
  } = useController({
    name: 'firstName',
    control,
  });

  const {
    field: {
      onChange: onChangeLastName,
      value: lastName,
    },
  } = useController({
    name: 'lastName',
    control,
  });

  const {
    field: {
      onChange: onChangePhone,
      value: phone,
    },
  } = useController({
    name: 'phone',
    control,
  });

  const {
    field: {
      onChange: onChangeEmail,
      value: email,
    },
  } = useController({
    name: 'email',
    control,
  });

  const {
    field: {
      onChange: onChangeBroker,
      value: broker,
    },
  } = useController({
    name: 'broker',
    control,
  });

  return (
    <Flex
      flexDirection="column"
      spacing={2}
    >
      <Flex spacing={2}>
        <Textfield
          placeholder="First name"
          onChange={onChangeFirstName}
          value={firstName}
        />
        <Textfield
          placeholder="Last name"
          onChange={onChangeLastName}
          value={lastName}
        />
        <PhoneInput
          placeholder="Phone"
          value={phone}
          handleChange={onChangePhone}
        />
      </Flex>
      <Flex spacing={2}>
        <EmailInput
          placeholder="Email"
          handleChange={onChangeEmail}
          value={email}
        />
        <Textfield
          placeholder="Broker"
          onChange={onChangeBroker}
          value={broker}
        />
      </Flex>
    </Flex>
  );
};

const {
  shape,
} = PropTypes;

YourDetails.propTypes = {
  control: shape({}).isRequired,
};

export default YourDetails;
