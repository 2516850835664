import {
  SET_TASKS_FOR_FILTER,
} from 'store/actionTypes/tasks';

const setTasksForFilter = (filterId) => (tasks) => ({
  type: SET_TASKS_FOR_FILTER,
  payload: {
    filterId,
    tasks,
  },
});

export default setTasksForFilter;
