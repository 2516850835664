/* eslint-disable react/jsx-no-bind */

import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import ListInput from 'ui/ListInput';
import Typography from 'ui/Typography';
import FormSection from 'ui/FormSection';

import DateField from 'sections/Opportunities/components/DateField';

import SearchPropertyInput from 'sections/Opportunities/modules/SearchPropertyInput';
import AdditionalNotesInput from 'sections/Opportunities/components/AdditionalNotesInput';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal/AssignInterestedPartyModal';

import ContingenciesTypesSelect from './ContingenciesTypesSelect';
import ContingenciesCheckboxes from './ContingenciesCheckboxes';
import OfferAmountInput from './OfferAmountInput';
import AttachmentsUpload from './AttachmentsUpload';
import PropertyPreview from './PropertyPreview';
import KeyPeople from './KeyPeople';

const goToContact = (contactId) => (event) => {
  event.preventDefault();
  window.open(`/contacts/${contactId}`, '_blank');
};

const LogOfferForm = (props) => {
  const {
    formId,
    onSubmit: propsOnSubmit,

    // defaultValue
    propertyId,
    propertyPrice,

    // showBuyerSelector,
    // showSellerSelector,
    // showBuyerAgentSelector,
    // showSellerAgentSelector,

    buyerContactId,
    sellerContactId,
    buyerAgentContactId,
    sellerAgentContactId,

    onNewContactsChange,
    onExistingContactsChange,
    onPropertyChange,
    onOfferValueChange,
  } = props;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const [specialConditions, setSpecialConditions] = useState(['']);

  // contact
  const [selectedBuyerContactId, setBuyerContact] = useState(null);
  const [selectedSellerContactId, setSellerContact] = useState(null);

  const setContact = useCallback(type => (newContactId) => {
    if (type === 'buyer') {
      setBuyerContact(newContactId);
    }
    if (type === 'seller') {
      setSellerContact(newContactId);
    }
  }, []);

  // agent
  const [selectedBuyerAgentId, setBuyerAgent] = useState(null);
  const [selectedSellerAgentId, setSellerAgent] = useState(null);

  const setAgent = useCallback(type => (newContactId) => {
    if (type === 'buyer') {
      setBuyerAgent(newContactId);
    }
    if (type === 'seller') {
      setSellerAgent(newContactId);
    }
  });

  // property
  const [selectedPropertyId, setProperty] = useState(null);

  useEffect(() => {
    setProperty(propertyId);
  }, [
    propertyId,
  ]);

  useEffect(() => {
    setContact('buyer')(buyerContactId);
  }, [
    buyerContactId,
  ]);
  useEffect(() => {
    setContact('seller')(sellerContactId);
  }, [
    sellerContactId,
  ]);

  useEffect(() => {
    setAgent('buyer')(buyerAgentContactId);
  }, [
    buyerAgentContactId,
  ]);

  useEffect(() => {
    setAgent('seller')(sellerAgentContactId);
  }, [
    sellerAgentContactId,
  ]);

  const onSubmit = useCallback((data) => {
    propsOnSubmit({
      ...data,
      specialConditions,
      selectedBuyerContactId,
      selectedSellerContactId,
      selectedBuyerAgentId,
      selectedSellerAgentId,
      selectedPropertyId,
    });
  }, [
    specialConditions,
    propsOnSubmit,
    selectedBuyerContactId,
    selectedSellerContactId,
    selectedBuyerAgentId,
    selectedSellerAgentId,
    selectedPropertyId,
  ]);

  useEffect(() => {
    onExistingContactsChange({
      selectedBuyerContactId,
      selectedSellerContactId,
      selectedBuyerAgentId,
      selectedSellerAgentId,
    });
  }, [
    selectedBuyerContactId,
    selectedSellerContactId,
    selectedBuyerAgentId,
    selectedSellerAgentId,
  ]);

  useEffect(() => {
    onPropertyChange(selectedPropertyId);
  }, [
    selectedPropertyId,
  ]);

  const noContingencies = watch('isNoContingencies', false);
  onNewContactsChange(
    watch(['new_contact_buyer', 'new_contact_seller', 'new_contact_buyer_agent', 'new_contact_seller_agent']),
  );
  onOfferValueChange(watch('offerAmount', propertyPrice));

  const [isAssignKeyPersonVisible, setIsAssignKeyPersonVisible] = useState(false);
  const [assignedRole, setAssignedRole] = useState(null);

  const onAddKeyPerson = useCallback((role) => (event) => {
    event.preventDefault();
    setIsAssignKeyPersonVisible(true);
    setAssignedRole(role);
  });

  const selectedKeyPerson = useMemo(() => {
    const assigned = {
      buyer: selectedBuyerContactId,
      seller: selectedSellerContactId,
      buyerAgent: selectedBuyerAgentId,
      sellerAgent: selectedSellerAgentId,
    };

    if (!assignedRole || !assigned[assignedRole]) return [];

    return [assigned[assignedRole]];
  }, [
    assignedRole,

    selectedBuyerContactId,
    selectedSellerContactId,
    selectedBuyerAgentId,
    selectedSellerAgentId,
  ]);

  const setKeyPerson = useCallback(({
    addedContacts,
  }) => {
    const cbs = {
      buyer: setContact('buyer'),
      seller: setContact('seller'),
      buyerAgent: setAgent('buyer'),
      sellerAgent: setAgent('seller'),
    };

    const cb = cbs[assignedRole];

    cb(addedContacts[0]);

    setIsAssignKeyPersonVisible(false);
  }, [
    assignedRole,
    setContact,
    setAgent,
  ]);

  return (
    <>
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Flex
          fullWidth
          flexDirection="column"
          spacing={4}
        >
          {
            propertyId && (
              <FlexItem fullWidth>
                <PropertyPreview propertyId={selectedPropertyId} />
              </FlexItem>
            )
          }
          {
            !propertyId && (
              <FlexItem fullWidth>
                <SearchPropertyInput
                  decorated
                  label="Search for property by address"
                  selectedPropertyId={selectedPropertyId}
                  handleChange={setProperty}
                />
              </FlexItem>
            )
          }

          <FormSection
            iconName="dollar-sign"
            iconContainerStyle={{ paddingTop: 8 }}
          >
            <OfferAmountInput
              control={control}
              defaultValue={propertyPrice}
            />
          </FormSection>

          <FormSection
            iconName="user"
            title="Key People"
          >
            <KeyPeople
              keyPeople={[
                {
                  label: 'Buyer',
                  contactId: selectedBuyerContactId,
                  onAdd: onAddKeyPerson('buyer'),
                  onRemove: () => setContact('buyer')(null),
                  onClick: goToContact(selectedBuyerContactId),
                },
                {
                  label: 'Seller',
                  contactId: selectedSellerContactId,
                  onAdd: onAddKeyPerson('seller'),
                  onRemove: () => setContact('seller')(null),
                  onClick: goToContact(selectedSellerContactId),
                },
                {
                  label: 'Buyer’s agent',
                  contactId: selectedBuyerAgentId,
                  onAdd: onAddKeyPerson('buyerAgent'),
                  onRemove: () => setAgent('buyer')(null),
                  onClick: goToContact(selectedBuyerAgentId),
                },
                {
                  label: 'Seller’s agent',
                  contactId: selectedSellerAgentId,
                  onAdd: onAddKeyPerson('sellerAgent'),
                  onRemove: () => setAgent('seller')(null),
                  onClick: goToContact(selectedSellerAgentId),
                },
              ]}
            />
          </FormSection>

          <FormSection
            iconName="calendar"
            title="Contingencies"
          >
            <ContingenciesCheckboxes control={control} />

            {
              !noContingencies && <ContingenciesTypesSelect control={control} />
            }

            <Flex
              spacing={1}
              fullWidth
              alignItems="baseline"
            >
              <FlexItem xs={6}>
                <Typography
                  weight="bold"
                  noWrap
                  noMargin
                >
                  Contingency Period:
                </Typography>
              </FlexItem>

              <FlexItem xs={18}>
                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="contingency_period"
                  variant="inline"
                  defaultValue={21}
                />
              </FlexItem>
            </Flex>

            <Flex
              spacing={1}
              fullWidth
              alignItems="baseline"
            >
              <FlexItem xs={6}>
                <Typography
                  weight="bold"
                  noWrap
                  noMargin
                >
                  Closing Date:
                </Typography>
              </FlexItem>

              <FlexItem xs={18}>
                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="closing_date"
                  variant="inline"
                  defaultValue={21}
                />
              </FlexItem>
            </Flex>

            <Flex
              spacing={1}
              fullWidth
              alignItems="baseline"
            >
              <FlexItem xs={6}>
                <Typography
                  weight="bold"
                  noWrap
                  noMargin
                >
                  Expiry Date:
                </Typography>
              </FlexItem>

              <FlexItem xs={18}>
                <DateField
                  control={control}
                  setValue={setValue}
                  fieldName="expiry_date"
                  variant="inline"
                  defaultValue={21}
                />
              </FlexItem>
            </Flex>
          </FormSection>

          <FormSection
            iconName="info"
            title="Special Conditions"
          >
            <ListInput
              value={specialConditions}
              onChange={setSpecialConditions}
            />
          </FormSection>

          <FormSection
            iconName="file"
            title="Offer document"
          >
            <AttachmentsUpload />
          </FormSection>

          <FormSection
            iconName="edit2"
            title="Notes"
          >
            <AdditionalNotesInput
              control={control}
            />
          </FormSection>
        </Flex>
      </form>

      <AssignInterestedPartyModal
        title={null}
        isVisible={isAssignKeyPersonVisible}
        oneContactSelect
        withAddContact
        selectedContacts={selectedKeyPerson}
        onSave={setKeyPerson}
        closeModal={() => { setIsAssignKeyPersonVisible(false); }}
        onNewContactCreation={(newContactId) => setKeyPerson({ addedContacts: [newContactId] })}
      />
    </>
  );
};

LogOfferForm.defaultProps = {
  buyerContactId: null,
  sellerContactId: null,
  buyerAgentContactId: null,
  sellerAgentContactId: null,
  propertyId: null,
  propertyPrice: null,
  showBuyerSelector: false,
  showSellerSelector: false,
  showBuyerAgentSelector: false,
  showSellerAgentSelector: false,
};

const {
  bool,
  func,
  number,
  string,
} = PropTypes;

LogOfferForm.propTypes = {
  /**
   * is required to bind the form to submit controls
   */
  formId: string.isRequired,

  /**
   * on submit callback
   */
  onSubmit: func.isRequired,
  // default values

  /**
   * id of property the offer refers to
   */
  propertyId: number,
  /**
   * id of buyer contact
   */
  buyerContactId: number,
  /**
   * id of seller contact
   */
  sellerContactId: number,
  /**
   * id of buyer agent contact
   */
  buyerAgentContactId: number,
  /**
   * id of seller agent contact
   */
  sellerAgentContactId: number,
  /**
   * propertyPrice
   */
  propertyPrice: number,

  showBuyerSelector: bool,
  showSellerSelector: bool,
  showBuyerAgentSelector: bool,
  showSellerAgentSelector: bool,

  onNewContactsChange: func.isRequired,
  onExistingContactsChange: func.isRequired,
  onPropertyChange: func.isRequired,
  onOfferValueChange: func.isRequired,
};

export default LogOfferForm;
