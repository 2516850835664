import store from 'store'; // eslint-disable-line import/no-cycle
import { memoizeDebounce } from 'shared/utility';

import getAttachmentsList from './getAttachmentsList';
import getObjectAttachments from './getObjectAttachments';
import uploadAttachment from './uploadAttachment';
import deleteAttachment from './deleteAttachment';

export {
  getAttachmentsList,
  getObjectAttachments,
  uploadAttachment,
  deleteAttachment,
};

const debounceDelay = 2000;
const debounceOptions = {
  leading: true,
  trailing: false,
};

export const optimizedAttachmentsActions = {
  getAttachmentsList: memoizeDebounce(
    (objectType, objectId) => store.dispatch(getAttachmentsList(objectType)(objectId)),
    debounceDelay,
    debounceOptions,
  ),
};
