import { connect } from 'react-redux';

import NewContactArea from 'shared/inputs/NewContactArea';

import {
  setNewLeadFormField,
  setNewLeadNestedFormField,
} from 'store/actions/newRealtorLeadForm';

const fieldDict = {
  first_name: 'personFirstName',
  last_name: 'personLastName',
  person_title: 'personTitle',
  email_primary: 'mainEmail',
  phone_primary: 'mainPhoneNumber',
  first_contact_date: 'firstContactDate',
  is_past_client: 'isPastClient',
};

const mapStateToProps = (state) => {
  const {
    realtorNewLeadForm,
  } = state;

  const {
    isEmailValid,
    doesEmailExist,
    doesPhoneExist,
    isFirstNameValid,
  } = realtorNewLeadForm.fieldsValidators;

  let emailError = null;

  if (doesEmailExist) {
    emailError = 'Email already exists';
  }
  if (!isEmailValid) {
    emailError = 'Not a valid email';
  }

  const values = {};

  Object.entries(fieldDict).forEach(([key, value]) => {
    values[key] = realtorNewLeadForm[value];
  });

  return {
    values,
    emailError,
    phoneError: doesPhoneExist ? 'Phone number already exists' : null,
    firstNameError: !isFirstNameValid ? 'First name is required' : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (fieldName) => (value) => {
    dispatch(setNewLeadFormField(fieldDict[fieldName] || fieldName)(value));
  },

  onInvalidField: (fieldName) => (property, value) => {
    dispatch(setNewLeadNestedFormField('fieldsValidators')(property)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContactArea);
