import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Elevation from 'ui/Elevation';

import OfferTabs from '../OfferTabs';

const Offer = (props) => {
  const {
    propertyId,
    offerData,
   } = props;

   const {
    needsResponse,
    awaitingReply,
    declined,
    accepted,
   } = offerData;

  return (

    <Flex
      fullWidth
    >
      <Elevation
        style={{ width: '100%' }}
        rounded
      >
        {
            accepted.length
            ? (
              <OfferTabs
                declinedCount={declined.length}
                propertyId={propertyId}
                accepted={accepted}
                declined={declined}
              />
              )
            : (
              <OfferTabs
                needsResponseCount={needsResponse.length}
                awaitingReplyCount={awaitingReply.length}
                declinedCount={declined.length}
                propertyId={propertyId}
                needsResponse={needsResponse}
                declined={declined}
                awaitingReply={awaitingReply}
              />
              )
          }
      </Elevation>
    </Flex>
  );
};

Offer.defaultProps = {
  offerData: null,
  propertyId: null,
};

const {
  shape,
  number,
} = PropTypes;

Offer.propTypes = {
  offerData: shape({}),
  propertyId: number,
};

export default Offer;
