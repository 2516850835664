import apiConnector, { endpoints } from 'api';

const updatePublicArticle = (routData) => async (articleInformations) => {
  const {
    databaseName,
    objectId,
    objectType,
  } = routData;

  const endpoint = `/public/${databaseName}/${objectType}/${objectId}`;

  const result = await apiConnector.global.patch(endpoint, articleInformations);

  return result.data;
};

export default updatePublicArticle;
