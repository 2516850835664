import { connect } from 'react-redux';
import { history } from 'store';

import {
  closeTutorialLaunchModal,
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  setStepIndex,
} from 'store/actions/tutorial';

import {
  addTutorialTicket,
  showTicketModal,
} from 'store/actions/tickets';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';

import initialSteps from './steps';
import filterSteps from '../shared/utils/filterSteps';


import TicketsTutorial from './TicketsTutorial';

const mapStateToProps = (state) => {
  const hasManagerPermissions = checkLoggedEmployeePermissions.isManager('Customer Support');
  const hasAdminPermissions = checkLoggedEmployeePermissions.isOwner();
  const steps = filterSteps(initialSteps, hasManagerPermissions, hasAdminPermissions);

  const {
    tutorial: {
      stepIndex,
    },
    tickets: {
      isModalVisible,
    },
    router: {
      location,
    },
  } = state;

  return {
    stepIndex,
    history,
    location,
    isModalVisible,
    steps,
  };
};

const mapDispatchToProps = {
  setStepIndex,
  closeTutorialLaunchModal,
  startTutorial,
  endTutorial,
  hideTutorialLayer,
  addTutorialTicket,
  showTicketModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsTutorial);
