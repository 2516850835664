import React from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import Modal from 'ui/Modal';

import OfferDeclineReasonForm from 'sections/Opportunities/forms/OfferDeclineReasonForm';

const offerDeclineReasonFormId = 'offer-decline-reason-form';

const OfferDeclineReasonModal = (props) => {
  const {
    isVisible,
    readOnly,
    offerId,
    onSave,
    closeModal,
    theme,
  } = props;

  return (
    <Modal
      theme={theme}
      width={600}
      visible={isVisible}
      centered
      onCancel={closeModal}
      title={!readOnly ? 'Decline Offer?' : 'Offer Decline Reason'}
      footer={!readOnly ? [
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          htmlType="submit"
          form={offerDeclineReasonFormId}
        >
          Save
        </Button>,
      ] : [
        <Button
          variant="primary"
          onClick={closeModal}
        >
          OK
        </Button>,
      ]}
    >
      <OfferDeclineReasonForm
        formId={offerDeclineReasonFormId}
        onSubmit={onSave}
        offerId={offerId}
        readOnly={readOnly}
      />
    </Modal>
  );
};

OfferDeclineReasonModal.defaultProps = {
  isVisible: false,
  readOnly: false,
  theme: null,
};

const {
  func,
  bool,
  shape,
  number,
} = PropTypes;

OfferDeclineReasonModal.propTypes = {
  closeModal: func.isRequired,
  onSave: func.isRequired,
  offerId: number.isRequired,
  isVisible: bool,
  readOnly: bool,
  theme: shape(),
};

export default OfferDeclineReasonModal;
