export const buttonsList = [ // eslint-disable-line import/prefer-default-export
  {
    value: '1',
  },
  {
    value: '2',
    extraValue: 'ABC',
  },
  {
    value: '3',
    extraValue: 'DEF',
  },
  {
    value: '4',
    extraValue: 'GHI',
  },
  {
    value: '5',
    extraValue: 'JKL',
  },
  {
    value: '6',
    extraValue: 'MNO',
  },
  {
    value: '7',
    extraValue: 'PQRS',
  },
  {
    value: '8',
    extraValue: 'TUV',
  },
  {
    value: '9',
    extraValue: 'WXYZ',
  },
  {
    value: '*',
  },
  {
    value: '0',
    extraValue: '+',
  },
  {
    value: '#',
  },
];

export const possibleNumberChars = [

];
