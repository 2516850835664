import apiConnector, { endpoints } from 'api';

const updateEmployeeDepartments = async (employeeId, departmentsList) => {
  const endpoint = endpoints.client.departments.updateForEmployee;

  const response = await apiConnector.client.put(`${endpoint}/${employeeId}`, { departmentsList });

  return response;
};

export default updateEmployeeDepartments;
