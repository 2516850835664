import {
  SET_OVERALL_TICKETS_SUMMARY,
} from 'store/actionTypes/tickets';

const setOverallTicketsSummary = (ticketsSummary) => ({
  type: SET_OVERALL_TICKETS_SUMMARY,
  payload: ticketsSummary,
});

export default setOverallTicketsSummary;
