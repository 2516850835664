import { createSelector } from 'reselect';

import moment from 'moment';

import { getUserOptimized } from 'store/actions/users';

const dateFormat = 'hh:mm A MMM DD YYYY';

const selectedLead = (state) => state.crmLeads.selectedLead;
const leads = (state) => state.crmLeads.leads;
const changelog = (state) => state.crmLeads.changelog;
const employees = (state) => state.users.employee;

const timelineEventTypes = {
  'owner assigned': true,
  'owner removed': true,
  'deal won': true,
  'deal lost': true,
};

const timelineEventsWithEmployeeAsValue = {
  'owner assigned': true,
  'owner removed': true,
};

const leadEvents2timelineEvents = {
  'owner assigned': 'addPerson',
  'owner removed': 'removePerson',
  'deal won': 'success',
  'deal lost': 'fail',
};

const statuses = {
  archived: '4',
  'not interested': '5',
};

const eventTitles = {
  'owner assigned': 'Owner assigned',
  'owner removed': 'Owner removed',
  archived: 'Lead archived',
  'not interested': 'Lead status set to \'Not interested\'',
  'deal won': 'Deal won',
  'deal lost': 'Deal lost',
};

const eventDescriptions = {
  'owner assigned': (who, by, isSame) => (isSame
    ? `${who} assigned themself as Owner`
    : `${who} was assigned as Owner by ${by}`),

  'owner removed': (who, by, isSame) => (isSame
    ? `${who} usassigned themself as Owner`
    : `${who} was unassigned as Owner by ${by}`),

  archived: (by) => `Lead was archived by ${by}`,
  'not interested': (by) => `Lead status was set to \'Not interested\' by ${by}`,
};

// const formatTimelineEvents = (acc, item) => {
const formatTimelineEvents = (events, users) => events.reduce((acc, item) => {
  let doneByName = '';
  let newEmployeeName = null;
  let isSamePerson = false;

  const actor = users[item.done_by_id];

  if (!actor) {
    getUserOptimized(1, item.done_by_id);
  } else {
    doneByName = `${actor.name} ${actor.surname}`;
  }

  if (timelineEventsWithEmployeeAsValue[item.event_type]) {
    const newEmployee = users[item.new_value?.id];

    if (!newEmployee) {
      getUserOptimized(1, item.new_value?.id);
    } else {
      newEmployeeName = `${newEmployee.name} ${newEmployee.surname}`;
    }

    isSamePerson = item.done_by_id === item.new_value?.id;
  }

  if (timelineEventTypes[item.event_type]) {
    acc.push({
      createdAt: item.created_at,
      type: leadEvents2timelineEvents[item.event_type],
      details: {
        time: moment(item.created_at).format(dateFormat),
        title: eventTitles[item.event_type],
        description: newEmployeeName
          ? eventDescriptions[item.event_type](
            newEmployeeName,
            doneByName,
            isSamePerson,
          )
          : null,
      },
    });
  }

  if (item.event_type === 'status') {
    if (item.new_value === statuses.archived) {
      acc.push({
        createdAt: item.created_at,
        type: 'archived',
        details: {
          time: moment(item.created_at).format(dateFormat),
          title: eventTitles.archived,
          description: eventDescriptions.archived(item.doneByName),
        },
      });
    }

    if (item.new_value === statuses['not interested']) {
      acc.push({
        createdAt: item.created_at,
        type: 'emotionMeh',
        details: {
          time: moment(item.created_at).format(dateFormat),
          title: eventTitles['not interested'],
          description: eventDescriptions['not interested'](item.doneByName),
        },
      });
    }
  }

  return acc;
}, []);

export default createSelector(
  selectedLead,
  leads,
  changelog,
  employees,
  (leadId, leadsDict, changelogItems = {}, users) => { // eslint-disable-line max-params
    if (!leadId || !leadsDict || !leadsDict[leadId]) {
      return [];
    }

    const lead = leadsDict[leadId];

    const createdByUser = users[lead.created_by];

    if (!createdByUser) {
      getUserOptimized(1, lead.created_by);
    }

    const created = {
      type: 'created',
      createdAt: lead.created_at,
      details: {
        title: 'Lead created',
        time: moment(lead.created_at).format(dateFormat),
        description: createdByUser
          ? `Lead was created by ${createdByUser.name} ${createdByUser.surname}`
          : null,
      },
    };

    const leadEvents = changelogItems[leadId] || [];

    const timelineEvents = formatTimelineEvents(leadEvents, users);

    const timeline = [created].concat(timelineEvents.reverse()); // this is necessary for correct order

    return timeline;
  },
);
