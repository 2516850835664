import requests from 'api/requests';

import setTask from './setTask';

const getTaskById = (taskId, employeeId = null) => async (dispatch) => {
  try {
    const response = await requests.tasks.getTaskById(taskId, employeeId);
    dispatch(setTask(response.data));
  } catch (error) {
    console.error(error);
  }
};

export default getTaskById;
