import React from 'react';
import PropTypes from 'prop-types';

import PropertyListingAgent from 'sections/Properties/modules/PropertyListingAgent';
import PropertySeller from 'sections/Properties/modules/PropertySeller';

const PropertyContact = (props) => {
  const {
    isMyListing,
    propertyId,
  } = props;

  if (!isMyListing) {
    return (
      <PropertyListingAgent propertyId={propertyId} />
    );
  }

  return (
    <PropertySeller propertyId={propertyId} />
  );
};

PropertyContact.defaultProps = {
  isMyListing: false,
};

const {
  bool,
  number,
} = PropTypes;

PropertyContact.propTypes = {
  isMyListing: bool,
  propertyId: number.isRequired,
};

export default PropertyContact;
